<template>
  <div>
    <v-container class="">
      <v-row class="justify-center pt-0">
        <v-card class="rounded-xl" elevation="" style="width: 400px">
          <v-img :src="require(`@/assets/loginForm/${filename}`)" height="200" dark>
            <!-- <v-card-title primary-title class="justify-center pt-10">
                <h2>เข้าสู่ระบบ (Login)</h2>
              </v-card-title> -->

            <div class="showdescription">
              <v-btn icon @click="show = !show"
                ><v-icon color="primary" style="font-size: 40px">
                  mdi-chevron-down
                </v-icon></v-btn
              >
            </div>
          </v-img>

          <v-card-text pa-0>
            <v-expand-transition>
              <div v-show="show">
                <span class="primary--text">
                  {{ description }}
                </span>
              </div>
            </v-expand-transition>
            <v-form ref="form" v-model="valid" @submit.prevent="submit" lazy-validation>
              <v-text-field
                pt-0
                v-model="loginCredential.username"
                :label="username_caption_display"
                required
                prepend-icon="mdi-account"
                color="primary"
                :rules="usernameRules"
              >
              </v-text-field>
              <!-- visibility , visibility_off คือชื่อ icon -->
              <v-text-field
                v-model="loginCredential.password"
                label="รหัสผ่าน (Password)"
                :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
                @click:append="isShowPassword = !isShowPassword"
                :type="isShowPassword ? 'text' : 'password'"
                counter
                required
                prepend-icon="mdi-key-variant"
                color="primary"
              >
              </v-text-field>
              <slot name="schools"></slot>

              <v-row class="flex-column px-10 mt-5">
                <!-- <v-checkbox v-model="lsRememberMe" label="Remember me"></v-checkbox> -->
                <v-btn
                  class="rounded-pill"
                  color="#E8AA42"
                  type="submit"
                  :loading="loadingbtn"
                  dark
                >
                  <span>เข้าสู่ระบบ (Login)</span>
                </v-btn>

                <slot class="mt-5" name="employer"></slot>
                <v-btn text color="primary" @click.prevent="recoveryindentify()" dark>
                  ลืมรหัสผ่าน?
                </v-btn>
              </v-row>
              <v-row class="justify-center py-4">
                <!--<v-btn text
                                         color="secondary"
                                         @click.prevent="$router.push('/register')"
                                         dark>
                                      ลงทะเบียนผู้ใช้งานใหม่
                                  </v-btn>-->
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { usernameRules, passwordRules } from "@/ValidationHelper.js";
import { AuthenticationProvider } from "@/services/AuthenticationProvider";
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
var schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var authenticationProvider = new AuthenticationProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "LoginForm",
  props: ["filename", "description", "loadingbtn", "username_caption"],
  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      this.$store.dispatch({ type: "restoreLogin" });
    }
  },
  computed: {
    username_caption_display() {
      if (this.username_caption) {
        return this.username_caption;
      } else {
        return "ชื่อผู้ใช้ (Username)";
      }
    },
  },
  data() {
    return {
      isShowPassword: false,
      valid: false,
      allowSpaces: false,
      lsRememberMe: false,
      show: false,
      loginCredential: {
        username: "",
        password: "",
      },
      usernameRules: usernameRules,
      passwordRules: passwordRules,
      SchoolRanges: [],
    };
  },
  methods: {
    async recoveryindentify() {
      this.$emit("recovery-indentify");
    },
    async submit() {
      let validate = this.$refs.form.validate();
      if (validate) {
        this.$emit("submit-login", this.loginCredential);
      }
    },
  },
  async created() {},
};
</script>

<style scoped>
.headertext {
  font-size: 22pt !important;
}

.showdescription {
  display: flex;
  justify-content: center;
  margin-top: 165px;
}
/* d-flex justify-center bottom pt-16 mt-16 */
</style>
