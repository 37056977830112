import TestReport from "@/views/Report/TestReport.vue";
import ReportListView_Admin from "@/views/Report/ReportListView_Admin.vue";
import ReportListView_Manager from "@/views/Report/ReportListView_Manager.vue";
import ReportListView_SchoolStaff from "@/views/Report/ReportListView_SchoolStaff.vue";
import GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report_DetailView";
import GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report_DetailView";
import GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report_DetailView";
import GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView";
import GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView";
import GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report_DetailView";
import GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView";
import GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView";
import School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView";
import School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView";
import School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView";
import School_SchoolStudentList_Report from "@/views/Report/School_SchoolStudentList_Report";
import SchoolStudentList_Report from "@/views/Report/SchoolStudentList_Report";
import School_Report1_DetailView from "@/views/Report/School_Reports/School_Report1_DetailView";
import School_Report2_DetailView from "@/views/Report/School_Reports/School_Report2_DetailView";
import School_Report3_DetailView from "@/views/Report/School_Reports/School_Report3_DetailView";
import School_Report4_DetailView from "@/views/Report/School_Reports/School_Report4_DetailView";
import School_Report5_DetailView from "@/views/Report/School_Reports/School_Report5_DetailView";
import School_ConclusionOfJobTracking_Report from "@/views/Report/School_Reports/School_ConclusionOfJobTracking_Report";
import CenterStaff_ConclusionOfJobTracking_Report from "@/views/Report/GraduatedStudentJobTrackingReport/CenterStaff_ConclusionOfJobTracking_Report";
import GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince_Report_DetailView";
import GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion_Report_DetailView from "@/views/Report/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion_Report_DetailView";
import ReportGroupListView from "@/views/Report/ReportGroupListView.vue";

export default [
  {
    path: "/Report",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Report/TestReport",
        name: "TestReport",
        component: TestReport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView",
        name: "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView",
        component:
          School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/School_Reports/School_Report5_DetailView",
        name: "School_Report5_DetailView",
        component: School_Report5_DetailView,
        meta: {
          requiresAuth: true,
          nameTh:
            "รายงานการติดตามภาวะผู้มีงานทำของสถานศึกษา (จำแนกตามสาขาวิชา)",
        },
      },
      {
        path: "/Report/ReportListView_Admin",
        name: "ReportListView_Admin",
        component: ReportListView_Admin,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/ReportGroupListView",
        name: "ReportGroupListView",
        component: ReportGroupListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายงาน",
        },
      },
      {
        path: "/Report/ReportListView_Manager",
        name: "ReportListView_Manager",
        component: ReportListView_Manager,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/ReportListView_SchoolStaff",
        name: "ReportListView_SchoolStaff",
        component: ReportListView_SchoolStaff,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report_DetailView",
        name: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh:
            "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา (รายภาค)",
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report_DetailView",
        name: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh:
            "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา (รายจังหวัด)",
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report_DetailView",
        name: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh:
            "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา (รายสถาบันการอาชีวศึกษา)",
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView",
        name: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report_DetailView",
        name: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh:
            "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา (รายสถานศึกษา)",
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
        name: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh:
            "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา (รายประเภทวิชา)",
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView",
        name: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh:
            "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา (รายสาขาวิชา)",
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView",
        name: "GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานสรุปผลการติดตาม (รายบุคคล)",
        },
      },
      {
        path: "/Report/School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
        name: "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
        component:
          School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView",
        name: "School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView",
        component:
          School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/School_SchoolStudentList_Report",
        name: "School_SchoolStudentList_Report",
        component: School_SchoolStudentList_Report,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Report/SchoolStudentList_Report",
        name: "SchoolStudentList_Report",
        component: SchoolStudentList_Report,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานรายชื่อนักศึกษา",
        },
      },
      {
        path: "/Report/School_Reports/School_Report1_DetailView",
        name: "School_Report1_DetailView",
        component: School_Report1_DetailView,
        meta: {
          requiresAuth: true,
          nameTh: "สรุปรายชื่อนักเรียนผู้สำเร็จการศึกษา",
        },
      },
      {
        path: "/Report/School_Reports/School_Report2_DetailView",
        name: "School_Report2_DetailView",
        component: School_Report2_DetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานสรุปผลการติดตาม (รายบุคคล)",
        },
      },
      {
        path: "/Report/School_Reports/School_Report3_DetailView",
        name: "School_Report3_DetailView",
        component: School_Report3_DetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานสถิตินักเรียนที่สำเร็จการศึกษา (จำแนกตามสาขาวิชา)",
        },
      },
      {
        path: "/Report/School_Reports/School_Report4_DetailView",
        name: "School_Report4_DetailView",
        component: School_Report4_DetailView,
        meta: {
          requiresAuth: true,
          nameTh:
            "รายงานสถิตินักเรียนผู้พิการที่สำเร็จการศึกษา (จำแนกตามสาขาวิชา)",
        },
      },
      {
        path: "/Report/School_Reports/School_ConclusionOfJobTracking_Report",
        name: "School_ConclusionOfJobTracking_Report",
        component: School_ConclusionOfJobTracking_Report,
        meta: {
          requiresAuth: true,
          nameTh: "สรุปรายงานของแต่ละสถานศึกษา",
        },
      },
      {
        path: "/Report/CenterStaff_Reports/CenterStaff_ConclusionOfJobTracking_Report",
        name: "CenterStaff_ConclusionOfJobTracking_Report",
        component: CenterStaff_ConclusionOfJobTracking_Report,
        meta: {
          requiresAuth: true,
          nameTh: "สรุปรายงานเจ้าหน้าที่ส่วนกลาง",
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince_Report_DetailView",
        name: "GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานสรุปผลการส่งรายงาน รายจังหวัด",
        },
      },
      {
        path: "/Report/GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion_Report_DetailView",
        name: "GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion_Report_DetailView",
        component:
          GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion_Report_DetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานสรุปผลการส่งรายงาน รายภาค",
        },
      },
    ],
  },
];
