<template>
  <div class="pa-4">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-card rounded elevation="1" class="px-5 py-5">
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-row>
            <v-col lg="3" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">ภูมิภาค</h4>
              <v-select
                :label="selectRegion ? '' : 'ภูมิภาค'"
                :items="regionsNames"
                clearable
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="selectRegion"
                @change="onSelected"
              >
              </v-select>
            </v-col>
            <v-col lg="3" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">จังหวัด</h4>
              <v-autocomplete
                :label="selectprovinceid ? '' : 'จังหวัด'"
                :items="provinces"
                item-text="provincethai"
                item-value="provinceid"
                clearable
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                @change="onSelected"
                v-model="selectprovinceid"
              ></v-autocomplete>
            </v-col>
            <v-col lg="3" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">อำเภอ/เขต</h4>
              <v-select
                :label="selectDistrict ? '' : 'อำเภอ/เขต'"
                :items="districts"
                item-text="districtthai"
                item-value="districtid"
                clearable
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="selectDistrict"
                @change="onSelected"
                :disabled="hidedistricts"
              >
              </v-select>
            </v-col>
            <v-col lg="3" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">
                เฉพาะที่มีข้อมูลกรมพัฒนาธุรกิจการค้า
              </h4>
              <!-- <v-checkbox
            class="ml-3 my-0 py-0"
            label="รับสมัครงานด่วน"
          ></v-checkbox> -->
              <v-row no-gutters align-content="end">
                <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1 mb-2"
                >
                  <v-btn
                    :color="selectedDBD === true ? 'primary' : 'input'"
                    @click="
                      selectedDBD = !selectedDBD;
                      onSelected();
                    "
                    height="52px"
                    width="100%"
                  >
                    <!-- <v-icon left v-if="selectedIsargent === true">
                  mdi-checkbox-marked </v-icon
                ><v-icon left v-else> mdi-checkbox-blank-outline </v-icon> -->
                    <v-img
                      alt="VEC Logo"
                      class="shrink ml-2"
                      contain
                      src="@/assets/DBDlogo.png"
                      transition="scale-transition"
                      width="40"
                    />
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-1">
      <v-col cols="12" class="d-flex justify-start">
        <v-btn
          @click="openCreatEmployerProfileView()"
          dark
          color="primary"
          class="my-2"
        >
          <v-icon class="mr-2">mdi-plus</v-icon> เพิ่มข้อมูลสถานประกอบการ
        </v-btn>
      </v-col>
    </v-row>
    <SearchableDataTablePaging
      :data_items="employerprofiles"
      :datatable_headers="employerprofiles_data_table_headers"
      table_caption="รายชื่อสถานประกอบการ"
      :itemlength="itemlength"
      :search="keyword"
      @onkeywordchanged="onKeywordChanged"
      @pageChanged="pageChanged"
      @tableOptionsChanged="tableOptionsChanged"
    >
      <template v-slot:item.action="{ item }">
        <v-row class="custom-width">
          <SetChangePasswordWhenLogon
            :EmployeeProfileOid="item.oid"
          ></SetChangePasswordWhenLogon>
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openEditEmployerProfileView(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-file-edit</v-icon>
              </v-btn>
            </template>
            <span>แสดงรายละเอียด</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="deleteEmployerProfile(item)"
                color="error"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ลบข้อมูล</span>
          </v-tooltip></v-row
        >
      </template>
      <template v-slot:item.dbdstatus="{ item }">
        <DBDStatusDisplay :employer-profile="item"></DBDStatusDisplay>
      </template>
    </SearchableDataTablePaging>
    <v-row class="mt-2 ml-2">
      <v-icon size="large" color="success" class="mr-1"
        >mdi-checkbox-blank-circle</v-icon
      >
      <span> : ยังดำเนินกิจการอยู่ (นิติบุคคลที่จดทะเบียนกับ DBD)</span></v-row
    >
    <v-row class="ml-2">
      <v-icon size="large" color="info" class="mr-1"
        >mdi-checkbox-blank-circle</v-icon
      >
      <span>
        : เสร็จการชำระบัญชี (เลิก/ปิดกิจการ)
        นิติบุคลที่จดทะเบียนเลิกและจดทะเบียนเสร็จการขำระบัญชีเป็นผลให้สภาพนิติบุคคลสิ้นสุดลงโดยสมบูรณ์</span
      >
    </v-row>

    <v-row class="ml-2">
      <v-icon size="large" class="mr-1">mdi-checkbox-blank-circle</v-icon>
      <span>
        : ร้าง (นิติบุคคลที่ถูกขีดชื่อออกจากทะเบียน
        เนื่องจากเชื่อว่าไม่ประกอบกิจการ เช่น ไม่ยื่นงบการเงิน 3ปีต่อกัน)</span
      >
    </v-row>
  </div>
</template>

<script>
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import DBDStatusDisplay from "@/components/Employer/DBDStatusDisplay.vue";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import SetChangePasswordWhenLogon from "@/components/Employer/SetChangePasswordWhenLogon.vue";
import { formatDateThai } from "@/DateTimeHelper";
import moment from "moment";
import { EmployerProfilesRequest } from "@/models/Employer/EmployerProfilesRequest";

var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "EmployerProfileListView",
  components: {
    SearchableDataTable,
    SetChangePasswordWhenLogon,
    SearchableDataTablePaging,
    DBDStatusDisplay,
  },
  data() {
    return {
      moment: moment,
      favorites: [],
      employerprofile_types: undefined,
      selected_employerprofile_type: undefined,
      employerprofiles: undefined,
      employerprofiles_data_table_headers: [
        // { "text": 'วันที่ลงทะเบียน', "value": 'createddate' },
        { text: "ชื่อสถานประกอบการ", value: "nameth" },
        { text: "จังหวัด", value: "province.provincethai" },
        { text: "ผู้ติดต่อ", value: "coordinator_firstname" },
        { text: "ชื่อบัญชีผู้ใช้งาน", value: "username" },
        // { text: "สถานะ", value: "employerprofilestatus_nameth" },
        { text: "ตัวเลือก", value: "action" , sortable: false},
        { text: "", value: "dbdstatus" },
      ],
      search: "",
      regionsNames: undefined,
      provinces: undefined,
      districts: undefined,
      itemlength: 0,
      request_employerProfile: new EmployerProfilesRequest(),
      selectRegion: "",
      selectprovinceid: "",
      selectDistrict: "",
      tableOptions: undefined,
      keyword: "",
      selectedDBD: "",
      hidedistricts : false
    };
  },
  methods: {
    async loadEmployerProfiles() {
      this.$store.dispatch("showLoading");
      await employerProfileDataProvider
        .loadEmployerProfiles(this.request_employerProfile)
        .then((result) => {
          this.employerprofiles = result.data.items;
          this.itemlength = result.data.quantity;
          console.log(this.employerprofiles);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },

    async openCreatEmployerProfileView() {
      this.$router
        .push({ name: "CreateEmployerProfileView" })
        .catch((err) => {});
    },

    async openEditEmployerProfileView(item) {
      var oid = item.oid;
      this.$router.push({ name: "UpdateEmployerProfileView", params: { oid } });
    },

    async deleteEmployerProfile(item) {
      if (confirm("ยืนยัน")) {
        const oid = item.oid;
        await employerProfileDataProvider
          .deleteEmployerProfile(oid)
          .then((result) => {
            this.loadEmployerProfiles();
          });
      }
    },

    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },
    async loadRegions() {
      await commonDataProvider.getRegionAsVECNames().then((result) => {
        this.regionsNames = result.data;
      });
    },
    async loadProvinces() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
    },
    async loadDistricts() {
      if (this.selectprovinceid) {
        await masterDataProvider
          .loadGetDistricts(this.selectprovinceid)
          .then((result) => {
            this.districts = result.data;
            console.log(this.districts);
          });
      } else {
        this.hidedistricts = true
      }
    },
    pageChanged(pageChangeValue) {
      this.request_employerProfile.PageNumber = pageChangeValue;
      this.loadEmployerProfiles();
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
      this.request_employerProfile.ItemsPerPage = newtableOptions.itemsPerPage;
    },
    async onSelected() {
      if (this.selectprovinceid)
       {
        this.hidedistricts = false
       }
      if (this.selectRegion) {
        this.request_employerProfile.RegionAsVEC = this.selectRegion;
        masterDataProvider
          .loadGetProvincesByRegionAsVECName(this.selectRegion)
          .then((result) => {
            this.provinces = result.data;
          });
      } else if (!this.selectRegion) {
        this.request_employerProfile.RegionAsVEC = "";
        masterDataProvider.loadGetProvinces().then((result) => {
          this.provinces = result.data;
        });
      }

      if (this.selectprovinceid) {
        this.request_employerProfile.ProvinceID = this.selectprovinceid;
        await masterDataProvider
          .loadGetDistricts(this.selectprovinceid)
          .then((result) => {
            this.districts = result.data;
          });
      } else {
        this.request_employerProfile.ProvinceID = "";
      }

      if (this.selectDistrict) {
        this.request_employerProfile.DistrictID = this.selectDistrict;
      } else {
        this.request_employerProfile.DistrictID = "";
      }

      if (this.selectedDBD) {
        this.request_employerProfile.CheckDBD = this.selectedDBD;
      } else {
        this.request_employerProfile.CheckDBD = false;
      }

      this.loadEmployerProfiles();
    },
    async onKeywordChanged(newKeyword) {
      this.request_employerProfile.Keyword = newKeyword;
      await this.loadEmployerProfiles();
    },
  },
  async created() {
    await this.loadEmployerProfiles();
    await this.loadRegions();
    await this.loadProvinces();
    await this.loadDistricts();

  },
};
</script>

<style scoped>
.custom-width {
  width: 140px;
}
</style>
