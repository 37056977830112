<template>
  <div class="container">
    <v-card class="pa-4" v-if="ProvinceDetail">
      <v-row class="mb-4 mt-2">
        <h3 class="primary--text">
          <v-icon class="primary--text">mdi-map</v-icon>
          {{ ProvinceDetail.provincethai }} : {{ DistrictDetail.districtthai }}
        </h3>
      </v-row>
      <v-divider class="mt-4 mx-0 mb-4"></v-divider>
      <v-row>
        <v-col>
          <label class="heading"
            >ประเภท :
            <label>{{ ProvinceDetail.provincetype }}</label>
          </label>
          <label class="heading ml-6">
            ภาค :
            <label>{{ ProvinceDetail.regionascommon }}</label>
          </label>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mx-0 mb-4"></v-divider>
      <v-row>
        <v-col>
          <label class="heading"
            >จังหวัด (English) :
            <span>{{ ProvinceDetail.provinceeng }}</span>
          </label>
          <label class="heading ml-8"
            >อำเภอ (English) :
            <label>{{ DistrictDetail.districtengshort }}</label>
          </label>
        </v-col>
      </v-row>
    </v-card>
    <SearchableDataTable
      v-if="tambons"
      table_caption="ตำบล"
      :datatable_headers="tambon_data_table_headers"
      :data_items="tambons"
    >
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: { SearchableDataTable },
  name: "TambonListView",
  data() {
    return {
      tambons: [],
      tambon_data_table_headers: [
        { text: "รหัส (id)", value: "tambonid" },
        { text: "ชื่อตำบล (ไทย)", value: "tambonthai" },
        { text: "ชื่อตำบล (Eng)", value: "tamboneng" },
        { text: "รหัสไปรษณีย์", value: "postcodemain" },
      ],
      districtID: "",
      ProvinceDetail: [],
      DistrictDetail: [],
    };
  },
  methods: {
    async loadTambons() {
      await masterDataProvider
        .loadGetTambons(this.districtID)
        .then((result) => {
          this.ProvinceDetail = result.data[0].district.province;
          this.DistrictDetail = result.data[0].district;
          this.tambons = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  async created() {
    this.districtID = this.$route.params.districtID;
    await this.loadTambons();
  },
};
</script>

<style></style>
