import SalaryrangesDetailView from "@/views/Salaryranges/SalaryrangesDetailView.vue";
import SalaryrangesListView from "@/views/Salaryranges/SalaryrangesListView.vue";

export default [
  {
    path: "/Salaryranges",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Salaryranges/SalaryrangesListView",
        name: "SalaryrangesListView",
        component: SalaryrangesListView,
        meta: {
          requiresAuth: true,
          nameTh: "ระดับเงินเดือน",
        },
      },
      {
        path: "/Salaryranges/SalaryrangesDetailView",
        name: "SalaryrangesDetailView",
        component: SalaryrangesDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดระดับเงินเดือน",
        },
      },
    ],
  },
];
