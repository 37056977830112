<template>
  <v-container class="pa-4">
    <v-form class="elevation-4">
      <v-card class="pa-4">
        <v-row class="justify-center mb-4 mt-2">
          <h3>รายละเอียดสถานศึกษา</h3>
        </v-row>
        <v-divider class="mt-4 mx-0 mb-4"></v-divider>

        <v-row class="pa-2 pt-5">
          <label class="heading"
            >ชื่อโรงเรียน(TH) :
            <span>{{ school.schoolname }} </span>
          </label>
        </v-row>

        <v-row class="pa-2">
          <label class="heading"
            >ชื่อโรงเรียน(EN) :
            <label class="data">{{ school.schoolnameen }} </label>
          </label>
        </v-row>

        <v-row class="pa-2">
          <label class="heading"
            >รหัสโรงเรียน :
            <label class="data">{{ school.schoolid }}</label>
          </label>
        </v-row>

        <v-row class="pa-2 mt-5">
          <h4>ที่อยู่</h4>
        </v-row>
        <v-divider
          class="mt-3 mb-3 mx-0 border-opacity-25"
          style="width: 250px"
        ></v-divider>

        <v-row class="pa-2">
          <label class="data"
            >เลขที่ {{ school.housenumber }} ซอย{{ school.soi }} หมู่
            {{ school.villagenumber }} ถนน{{ school.street }} ตำบล{{
              school.tambon?.tambonthai
            }}
            อำเภอ{{ school.tambon?.district?.districtthaishort }} จังหวัด{{
              school.tambon?.district?.province.provincethai
            }}
            รหัสไปรษณีย์ {{ school.zipcode }}</label
          >
        </v-row>

        <v-row class="pa-3 mt-5">
          <label class="font-weight-bold">ช่องทางการติดต่อ</label>
        </v-row>
        <v-divider
          class="mt-2 mb-2 mx-0 border-opacity-25"
          style="width: 250px"
        ></v-divider>

        <v-row class="pa-2">
          <label class="heading"
            >อีเมล :
            <label class="data">{{ school.email }}</label>
          </label>
        </v-row>

        <v-row class="pa-2">
          <label class="heading"
            >เบอร์โทร :
            <label class="data">{{ school.telephone }}</label>
          </label>
        </v-row>

        <v-row class="pa-2">
          <label class="heading"
            >Fax :
            <label class="data">{{ school.fax }}</label>
          </label>
        </v-row>

        <v-row class="pa-2">
          <label class="heading"
            >website :
            <a target="_blank" :href="school.website">{{ school.website }}</a>
          </label>
        </v-row>
        <v-row class="justify-center text-center mt-4 mb-4">
          <v-col cols="12">
            <v-btn color="" @click="$router.go(-1)" class="ma-2 justify-center">
              ย้อนกลับ</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
var schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  name: "infoSchoolDetailView",
  data() {
    return {
      schoolID: "",
      school: [],
    };
  },

  methods: {
    async loadSchool(schoolID = "") {
      await schoolDataProvider
        .loadSchool(schoolID)
        .then((response) => {
          this.school = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },

  async created() {
    this.schoolID = this.$route.params.schoolID;
    await this.loadSchool(this.schoolID);
  },
};
</script>

<style>
input[type="text"][disabled] {
  color: black;
}

/* .heading {
  color: #808080;
} */

/* .data {
  color: '';
} */
</style>
