<template>
  <div class="pa-4">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-book</v-icon> ประเภทวิชา
      </h3>
      <v-spacer></v-spacer>
    </v-row>
    <SearchableDataTable
      v-if="subjecttypes != undefined"
      table_caption="ประเภทวิชา"
      :datatable_headers="subjecttypes_data_table_headers"
      :data_items="subjecttypes"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToDetailView(item)" color="info" icon>
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "SubjectTypeListView",
  data() {
    return {
      isImporting: false,
      subjecttypesoid: "",
      subjecttypes: undefined,
      subjecttypes_data_table_headers: [
        //{ text: "รหัส PK สาขาวิชา", value: "subjecttypeid" },
        { text: "รหัสสาขาวิชา", value: "subjecttypecode" },
        { text: "ชื่อสาขาวิชา (ไทย)", value: "subjecttypenameth" },
        { text: "ชื่อสาขาวิชา (อังกฤษ)", value: "subjecttypenameen" },
        { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    async importSubjectTypes() {
      this.isImporting = true;
      await subjectTypeDataProvider
        .importSubjectTypes()
        .then((result) => {
          this.onImportSubjectTypesCompleted();
        })
        .catch((err) => {
          this.isImporting = false;
          alert(err);
        });
    },
    async loadsubjecttypes() {
      await subjectTypeDataProvider
        .loadSubjectTypes()
        .then((result) => {
          this.subjecttypes = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToDetailView: function (item) {
      var subjecttypeID = item.subjecttypeid;
      this.$router
        .push({
          name: "SubjectTypeDetailView",
          params: { subjecttypeID: subjecttypeID },
        })
        .catch((err) => {});
    },
    onImportSubjectTypesCompleted() {
      this.isImporting = false;
      this.loadsubjecttypes();
    },
  },
  async created() {
    await this.loadsubjecttypes();
  },
};
</script>

<style></style>
