import Profile from "@/views/Profile/Profile.vue";

//Administrator profile
import AdministratorProfileListView from "@/views/Profile/AdministratorProfileListView";
import CreateAdministratorProfileDetailView from "@/views/Profile/CreateAdministratorProfileDetailView";
import UpdateAdministratorProfileDetailView from "@/views/Profile/UpdateAdministratorProfileDetailView";

//Employee Profile
import SchoolEmployeeProfileListView from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView.vue";
import SchoolEmployeeProfileListView_Center_Admin from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_Center_Admin.vue";
import SchoolEmployeeProfileListView_Center_Officer from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_Center_Officer.vue";
import SchoolEmployeeProfileListView_Center_Manager from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_Center_Manager.vue";
import SchoolEmployeeProfileListView_School_Manager from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_School_Manager.vue";
import SchoolEmployeeProfileListView_School_Admin from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_School_Admin.vue";
import SchoolEmployeeProfileListView_School_Partner_Officer from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_School_Partner_Officer.vue";
import SchoolEmployeeProfileListView_School_Guidance_Teacher from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_School_Guidance_Teacher.vue";
import SchoolEmployeeProfileListView_ProvinceSchool_Officer from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_ProvinceSchool_Officer.vue";
import SchoolEmployeeProfileListView_Insitution_Officer from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileListView_Insitution_Officer.vue";

import SchoolAdmin_SchoolEmployeeProfileListView_School_Manager from "@/views/Profile/SchoolEmployeeProfiles/SchoolAdmin_SchoolEmployeeProfileListView_School_Manager.vue";
import SchoolAdmin_SchoolEmployeeProfileListView_School_Admin from "@/views/Profile/SchoolEmployeeProfiles/SchoolAdmin_SchoolEmployeeProfileListView_School_Admin.vue";
import SchoolAdmin_SchoolEmployeeProfileListView_School_Partner_Officer from "@/views/Profile/SchoolEmployeeProfiles/SchoolAdmin_SchoolEmployeeProfileListView_School_Partner_Officer.vue";
import SchoolAdmin_SchoolEmployeeProfileListView_School_Guidance_Teacher from "@/views/Profile/SchoolEmployeeProfiles/SchoolAdmin_SchoolEmployeeProfileListView_School_Guidance_Teacher.vue";
import CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Manager from "@/views/Profile/SchoolEmployeeProfiles/CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Manager.vue";
import CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Admin from "@/views/Profile/SchoolEmployeeProfiles/CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Admin.vue";
import CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Partner_Officer from "@/views/Profile/SchoolEmployeeProfiles/CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Partner_Officer.vue";
import CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Guidance_Teacher from "@/views/Profile/SchoolEmployeeProfiles/CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Guidance_Teacher.vue";

import Current_SchoolEmployeeProfileDetailView from "@/views/Profile/SchoolEmployeeProfiles/Current_SchoolEmployeeProfileDetailView.vue";
import SchoolEmployeeProfileDetailView from "@/views/Profile/SchoolEmployeeProfiles/SchoolEmployeeProfileDetailView";

import CreateSchoolEmployeeProfileDetailView from "@/views/Profile/SchoolEmployeeProfiles/CreateSchoolEmployeeProfileDetailView";

import AccountDetailView from "@/views/Profile/AccountDetailView.vue";
import EmployerEditTheirProfileView from "@/views/Employer/EmployerEditTheirProfileView";

import ProfileOverview from "@/views/Profile/ProfileOverview.vue";

export default [
  {
    path: "/Profile",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Profile",
        name: "Profile",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/Profile/AccountDetailView/:username",
        name: "AccountDetailView",
        component: AccountDetailView,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/Profile/AdministratorProfileListView",
        name: "AdministratorProfileListView",
        component: AdministratorProfileListView,
        meta: {
          requiresAuth: true,
          nameTh: "ผู้ดูแลระบบ",
        },
      },

      {
        path: "/Profile/CreateAdministratorProfileDetailView",
        name: "CreateAdministratorProfileDetailView",
        component: CreateAdministratorProfileDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "เพิ่มข้อมูลผู้ดูแลระบบ",
        },
      },

      {
        path: "/Profile/UpdateAdministratorProfileDetailView/:oid",
        name: "UpdateAdministratorProfileDetailView",
        component: UpdateAdministratorProfileDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "แก้ไขข้อมูลผู้ดูแลระบบ",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView",
        name: "SchoolEmployeeProfileListView",
        component: SchoolEmployeeProfileListView,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลผู้ใช้งานระบบทั้งหมด",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_Center_Admin",
        name: "SchoolEmployeeProfileListView_Center_Admin",
        component: SchoolEmployeeProfileListView_Center_Admin,
        meta: {
          requiresAuth: true,
          nameTh: "ผู้ดูแลระบบส่วนกลาง",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_Center_Officer",
        name: "SchoolEmployeeProfileListView_Center_Officer",
        component: SchoolEmployeeProfileListView_Center_Officer,
        meta: {
          requiresAuth: true,
          nameTh: "บุคลากรส่วนกลาง",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_Center_Manager",
        name: "SchoolEmployeeProfileListView_Center_Manager",
        component: SchoolEmployeeProfileListView_Center_Manager,
        meta: {
          requiresAuth: true,
          nameTh: "ผู้บริหารส่วนกลาง",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_School_Manager",
        name: "SchoolEmployeeProfileListView_School_Manager",
        component: SchoolEmployeeProfileListView_School_Manager,
        meta: {
          requiresAuth: true,
          nameTh: "ผู้บริหารสถานศึกษา",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_School_Admin",
        name: "SchoolEmployeeProfileListView_School_Admin",
        component: SchoolEmployeeProfileListView_School_Admin,
        meta: {
          requiresAuth: true,
          nameTh: "บุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_School_Partner_Officer",
        name: "SchoolEmployeeProfileListView_School_Partner_Officer",
        component: SchoolEmployeeProfileListView_School_Partner_Officer,
        meta: {
          requiresAuth: true,
          nameTh: "บุคลากรในสถานศึกษา (ทวิภาคี)",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_School_Guidance_Teacher",
        name: "SchoolEmployeeProfileListView_School_Guidance_Teacher",
        component: SchoolEmployeeProfileListView_School_Guidance_Teacher,
        meta: {
          requiresAuth: true,
          nameTh: "บุคลากรในสถานศึกษา (ครูแนะแนว)",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_ProvinceSchool_Officer",
        name: "SchoolEmployeeProfileListView_ProvinceSchool_Officer",
        component: SchoolEmployeeProfileListView_ProvinceSchool_Officer,
        meta: {
          requiresAuth: true,
          nameTh: "บุคลากรในอาชีวศึกษาจังหวัด",
        },
      },

      {
        path: "/Profile/SchoolEmployeeProfileListView_Insitution_Officer",
        name: "SchoolEmployeeProfileListView_Insitution_Officer",
        component: SchoolEmployeeProfileListView_Insitution_Officer,
        meta: {
          requiresAuth: true,
          nameTh: "บุคลากรในสถาบันการอาชีวศึกษา",
        },
      },

      {
        path: "/Profile/CreateSchoolEmployeeProfileDetailView",
        name: "CreateSchoolEmployeeProfileDetailView",
        component: CreateSchoolEmployeeProfileDetailView,
      },
      {
        path: "/Profile/SchoolEmployeeProfileDetailView/:oid",
        name: "SchoolEmployeeProfileDetailView",
        component: SchoolEmployeeProfileDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลบุคลากร",
        },
      },
      {
        path: "/Profile/Current_SchoolEmployeeProfileDetailView",
        name: "Current_SchoolEmployeeProfileDetailView",
        component: Current_SchoolEmployeeProfileDetailView,
        meta: {
          nameTh: "ข้อมูลส่วนตัว",
        },
      },

      {
        path: "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Admin",
        name: "SchoolAdmin_SchoolEmployeeProfileListView_School_Admin",
        component: SchoolAdmin_SchoolEmployeeProfileListView_School_Admin,
        meta: {
          nameTh: "ข้อมูลบุคลากรสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)",
        },
      },

      {
        path: "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Manager",
        name: "SchoolAdmin_SchoolEmployeeProfileListView_School_Manager",
        component: SchoolAdmin_SchoolEmployeeProfileListView_School_Manager,
        meta: {
          nameTh: "ข้อมูลผู้บริหารสถานศึกษา",
        },
      },

      {
        path: "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Partner_Officer",
        name: "SchoolAdmin_SchoolEmployeeProfileListView_School_Partner_Officer",
        component:
          SchoolAdmin_SchoolEmployeeProfileListView_School_Partner_Officer,
        meta: {
          nameTh: "ข้อมูลบุคลากรสถานศึกษา (ทวิภาคี)",
        },
      },

      {
        path: "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Guidance_Teacher",
        name: "SchoolAdmin_SchoolEmployeeProfileListView_School_Guidance_Teacher",
        component:
          SchoolAdmin_SchoolEmployeeProfileListView_School_Guidance_Teacher,
        meta: {
          nameTh: "ข้อมูลบุคลากรสถานศึกษา (ครูแนะแนว)",
        },
      },
      {
        path: "/Profile/CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Admin",
        name: "CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Admin",
        component:
          CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Admin,
        meta: {
          nameTh: "เพิ่มข้อมูลบุคลากร",
        },
      },

      {
        path: "/Profile/CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Manager",
        name: "CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Manager",
        component:
          CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Manager,
        meta: {
          nameTh: "เพิ่มข้อมูลผู้บริหาร",
        },
      },

      {
        path: "/Profile/CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Partner_Officer",
        name: "CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Partner_Officer",
        component:
          CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Partner_Officer,
        meta: {
          nameTh: "เพิ่มข้อมูลบุคลากร",
        },
      },

      {
        path: "/Profile/CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Guidance_Teacher",
        name: "CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Guidance_Teacher",
        component:
          CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Guidance_Teacher,
        meta: {
          nameTh: "เพิ่มข้อมูลบุคลากร",
        },
      },
      {
        path: "/Profile/ProfileOverview",
        name: "ProfileOverview",
        component: ProfileOverview,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลบุคลากรทั้งหมด",
        },
      },
    ],
  },
];
