<template>
  <v-card>
    <v-card-title>สถานะการได้งานทำ </v-card-title>
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </v-card>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import { DashboardDataSourceProvider } from "@/services/DashboardDataSourceProvider";
import { backendapiUrl } from "@/services/constants";
import { GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request";

var dashboardDataSourceProvider = new DashboardDataSourceProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import { EmploymentStatusCodes } from "@/Constants";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    SelectedTargetGraduatedYearTH: {
      default: "",
      type: String,
    },
    SelectedRoundNumber: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      chartData: {
        labels: ["ศึกษาต่อ", "บริษัทเอกชน", "หน่วยงานราชการ", "อาชีพอิสระ"],
        datasets: [
          {
            backgroundColor: ["#3366CC", "#DC3912", "#FF9900", "#109618"],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  watch: {
    SelectedTargetGraduatedYearTH(newValue, OldValue) {
      this.loadEmploymentStatus();
    },
    SelectedRoundNumber(newValue, OldValue) {
      this.loadEmploymentStatus();
    },
  },
  methods: {
    CodeToThai(item) {
      return item.label == EmploymentStatusCodes.Study
        ? "ศึกษาต่อ"
        : item.label == EmploymentStatusCodes.WorkForCompany
        ? "บริษัทเอกชน"
        : item.label == EmploymentStatusCodes.Government
        ? "หน่วยงานราชการ"
        : item.label == EmploymentStatusCodes.Freelance
        ? "อาชีพอิสระ"
        : "อื่นๆ";
    },
    async initialize() {
      this.targetEmploymentStatusCodes = [];
      this.targetEmploymentStatusCodes.push(
        EmploymentStatusCodes.Study,
        EmploymentStatusCodes.WorkForCompany,
        EmploymentStatusCodes.Government,
        EmploymentStatusCodes.Freelance
      );
    },
    async loadEmploymentStatus() {
      const request = new GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request();
      request.TargetGraduatedYearTH = this.SelectedTargetGraduatedYearTH;
      request.RoundNumber = this.SelectedRoundNumber;
      request.TargetEmploymentStatusCodes = this.targetEmploymentStatusCodes;
      await dashboardDataSourceProvider
        .GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource(request)
        .then((result) => {
          let dataArray = [];
          for (var index = 0; index < result.data.labels.length; index++) {
            dataArray.push(result.data.data[index]);
          }
          this.chartData.datasets[0].data = dataArray;
        });
    },
  },
  mounted() {
    this.initialize();
    this.loadEmploymentStatus();
  },
};
</script>
