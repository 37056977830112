<template>
  <GmapMap
    id="map"
    :center="{ lat: 13.755851529461598, lng: 100.50180801534428 }"
    :zoom="12"
    map-type-id="terrain"
    style="width: 100%; height: 314px"
  >
    <GmapMarker
      :position="position"
      :clickable="true"
      :draggable="true"
      @dragend="getMarkerPosition($event)"
    />
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  data() {
    return {
      position: { lat: 13.755851529461598, lng: 100.50180801534428 },
    };
  },
  methods: {
    getMarkerPosition(event) {
      this.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.$emit("OnMarkedMap", this.position);
    },
  },
  computed: {
    google: gmapApi,
  },
};
</script>

<style></style>
