<template>
  <div class="pa-4 mt-4 mb-4">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-app-bar>
      <div class="mr-4">
        <v-avatar color="grey" size="50">
          <v-img :src="avatarphoto"></v-img>
        </v-avatar>
      </div>
      <div>
        <v-list-item-content>
          <v-list-item-title class="text-h6 primary--text font-weight-bold">
            {{ student.firstname }} {{ student.surname }}
          </v-list-item-title>
          <v-list-item-subtitle class="success--text">
            <v-icon color="success" class="mr-1">mdi-school-outline</v-icon>
            <span class="font-weight-bold">{{ student.studentstatusname }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </div>
    </v-app-bar>
    <v-card class="pa-4 mt-5">
      <v-form ref="form" v-model="valid" @submit.prevent="save" lazy-validation>
        <v-row>
          <v-col cols="12" lg="9" md="12" sm="12">
            <v-card outlined class="pa-2 mb-5 mt-5">
              <h3 class="primary--text">
                <v-icon color="primary" class="mr-1">mdi-account-circle-outline</v-icon
                >ข้อมูลส่วนตัว
              </h3>
            </v-card>
            <v-container>
              <!-- <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="student.studentcode"
                  label="รหัสนักศึกษา"
                  filled
                  rounded
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row> -->
              <v-row>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-autocomplete
                    v-model="student.titlenameid"
                    :items="titlenamelist"
                    :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                    label="คำนำหน้าชื่อ"
                    item-text="titlenameth"
                    item-value="titlenameid"
                    required
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-text-field
                    v-model="student.firstname"
                    label="ชื่อ"
                    :rules="nameRules"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.surname"
                    label="นามสกุล"
                    :rules="nameRules"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.idcard"
                    label="เลขประจำตัวประชาชน"
                    readonly
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="student.birthday"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="txtBirthday"
                        :rules="[(v) => !!v || 'กรุณาระบุวันเกิด']"
                        label="เกิดวันที่"
                        v-model="formattedBirthday"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        filled
                        rounded
                        dense
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="th-TH"
                      v-model="student.birthday"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="" @click="menu = false"> ยกเลิก </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(student.birthday)"
                      >
                        ตกลง
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-radio-group
                    :rules="[(v) => !!v || 'กรุณาระบุเพศ']"
                    v-model="student.gender"
                    row
                  >
                    <template v-slot:label>
                      <div><strong>เพศ</strong></div>
                    </template>
                    <v-radio label="ชาย" color="blue" value="M"></v-radio>
                    <v-radio label="หญิง" color="pink" value="F"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-spacer></v-spacer>
                  <v-radio-group
                    :rules="[(v) => v != null || 'ระบุสถานะการสมรส']"
                    v-model="student.marital"
                    row
                  >
                    <template v-slot:label>
                      <div><strong>สถานะการสมรส</strong></div>
                    </template>
                    <v-radio label="โสด" color="blue" :value="0"></v-radio>
                    <v-radio label="แต่งงาน" color="pink" :value="1"></v-radio>
                    <v-radio label="หย่าร้าง" color="green" :value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-select
                    v-model="student.nationalityid"
                    :items="nationalitylist"
                    label="สัญชาติ"
                    item-text="nationalityth"
                    item-value="nationalityid"
                    filled
                    rounded
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-select
                    v-model="student.religionid"
                    :rules="[(v) => !!v || 'กรุณาระบุศาสนา']"
                    :items="religionlist"
                    label="ศาสนา"
                    item-text="religionth"
                    item-value="religionid"
                    filled
                    rounded
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.hight"
                    :rules="hight_weightStudendRules"
                    label="ส่วนสูง (ซม.)"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.weight"
                    :rules="hight_weightStudendRules"
                    label="น้ำหนัก (กก.)"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-select
                    v-model="student.domicile"
                    :rules="[(v) => !!v || 'กรุณาระบุภูมิลำเนา']"
                    :items="domicile"
                    item-value="provinceid"
                    item-text="provincethai"
                    label="ภูมิลำเนา"
                    filled
                    rounded
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col v-if="student.gender == 'M'" cols="12" sm="12" md="6" lg="6">
                  <v-select
                    v-model="student.military"
                    :items="military"
                    label="สถานภาพทางทหาร"
                    filled
                    rounded
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-card outlined class="pa-2 mb-5 mt-5">
              <h3 class="primary--text">
                <v-icon color="primary" class="mr-1">mdi-map-marker-circle</v-icon>ที่อยู่
              </h3>
            </v-card>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.housenumber"
                    :rules="[(v) => !!v || 'กรุณาระบุรายละเอียดที่อยู่']"
                    label="บ้านเลขที่"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.moo"
                    :rules="[(v) => !!v || 'กรุณาระบุรายละเอียดที่อยู่']"
                    label="หมู่ที่/หมู่บ้าน/อาคาร"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.soi"
                    :rules="[(v) => !!v || 'กรุณาระบุรายละเอียดที่อยู่']"
                    label="ซอย"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field v-model="student.street" label="ถนน" filled rounded dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-autocomplete
                    v-model="student.provinceid"
                    :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                    v-on:change="changedprovince"
                    :items="provinces"
                    item-value="provinceid"
                    item-text="provincethai"
                    label="จังหวัด"
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-autocomplete
                    v-model="student.districtid"
                    :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                    v-on:change="changeddistrict"
                    :items="districts"
                    item-value="districtid"
                    item-text="districtthai"
                    label="อำเภอ/เขต"
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-autocomplete
                    v-model="student.tambonid"
                    :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                    v-on:change="changedtambon"
                    :items="tambons"
                    item-value="tambonid"
                    item-text="tambonthai"
                    label="ตำบล/แขวง"
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="selectedpostcode"
                    label="รหัสไปรษณีย์"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card outlined class="pa-2 mb-5 mt-5">
              <v-row>
                <v-col>
                  <h3 class="primary--text">
                    <v-icon color="primary" class="mr-2"
                      >mdi-card-account-phone-outline</v-icon
                    >ข้อมูลติดต่อ
                  </h3>
                </v-col>
                <v-col>
                  <v-btn dark color="secondary" outlined @click="duplicateAddress">
                    <v-icon color="secondary" class="mr-2"
                      >mdi-note-multiple-outline</v-icon
                    >คัดลอกข้อมูลที่อยู่จากด้านบน</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.contacthousenumber"
                    :rules="[(v) => !!v || 'กรุณาระบุข้อมูลติดต่อ']"
                    label="บ้านเลขที่"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.contactmoo"
                    :rules="[(v) => !!v || 'กรุณาระบุข้อมูลติดต่อ']"
                    label="หมู่ที่/หมู่บ้าน/อาคาร"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.contactsoi"
                    :rules="[(v) => !!v || 'กรุณาระบุข้อมูลติดต่อ']"
                    label="ซอย"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="student.contactstreet"
                    label="ถนน"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-autocomplete
                    v-model="student.contactprovinceid"
                    :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                    v-on:change="changedcontactprovince"
                    :items="contactprovinces"
                    item-value="provinceid"
                    item-text="provincethai"
                    label="จังหวัด"
                    filled
                    rounded
                    dense
                    clearable
                    @click:clear="clearprovince"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-autocomplete
                    v-model="student.contactdistrictid"
                    :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                    v-on:change="changedcontactdistrict"
                    :items="contactdistricts"
                    item-value="districtid"
                    item-text="districtthai"
                    label="อำเภอ/เขต"
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-autocomplete
                    v-model="student.contacttambonid"
                    :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                    v-on:change="changedcontacttambon"
                    :items="contacttambons"
                    item-value="tambonid"
                    item-text="tambonthai"
                    label="ตำบล/แขวง"
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="selectedcontactpostcode"
                    label="รหัสไปรษณีย์"
                    filled
                    rounded
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col d-flex cols="12" sm="12" md="6" lg="6">
                  <WarningTextbox
                    v-model="student.email"
                    value="student.email"
                    front_icon="mdi-email"
                    text_name="อีเมล"
                    back_icon="mdi-alert-circle-outline"
                    text_warn="กรุณากรอก email ที่ใช้งานจริง เพื่อการยืนยันตัวตนในอนาคต"
                    :rules="emailRules"
                  >
                  </WarningTextbox>
                </v-col>

                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    :rules="phoneStudentRules"
                    v-model="student.tel"
                    label="เบอร์โทร"
                    filled
                    rounded
                    dense
                    prepend-inner-icon="mdi-phone"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card outlined class="pa-2 mb-5 mt-5">
              <h3 class="primary--text">
                <v-icon color="primary" class="mr-2"
                  >mdi-card-account-details-outline</v-icon
                >ประเภทงานที่ต้องการ
              </h3>
            </v-card>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <PostedJobSwitch
                    v-if="student_oid"
                    :student_oid="student_oid"
                  ></PostedJobSwitch>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="4">
                  <v-autocomplete
                    v-model="lookingforjob.interestedjob1"
                    :items="JobSegment"
                    label="ประเภทงานที่สนใจ 1"
                    :rules="[(v) => !!v || 'กรุณาเลือกประเภทงานที่สนใจ (1)']"
                    item-text="name"
                    item-value="name"
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="4">
                  <v-autocomplete
                    v-model="lookingforjob.interestedjob2"
                    :items="JobSegment"
                    :rules="[(v) => !!v || 'กรุณาเลือกประเภทงานที่สนใจ (2)']"
                    label="ประเภทงานที่สนใจ 2"
                    item-text="name"
                    item-value="name"
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="4">
                  <v-autocomplete
                    v-model="lookingforjob.interestedjob3"
                    :rules="[(v) => !!v || 'กรุณาเลือกประเภทงานที่สนใจ (3)']"
                    :items="JobSegment"
                    label="ประเภทงานที่สนใจ 3"
                    item-text="name"
                    item-value="name"
                    filled
                    rounded
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-row>
                    <v-col>สถานะการหางาน</v-col>
                  </v-row>
                  <v-radio-group v-model="lookingforjob.lookingforjobstatus" row>
                    <v-radio
                      class="mx-2"
                      label="ไม่ต้องการเรียนต่อและไม่หางาน"
                      color="primary"
                      :value="0"
                    ></v-radio>
                    <v-radio
                      class="mx-2"
                      label="ต้องการเรียนต่อ"
                      color="primary"
                      :value="1"
                    >
                    </v-radio>
                    <v-radio class="mx-2" label="ต้องการหางาน" color="primary" :value="2">
                    </v-radio>
                    <v-radio
                      class="mx-2"
                      label="ต้องการเรียนต่อและหางาน"
                      color="primary"
                      :value="3"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-row>
                    <v-col>ลักษณะงานที่ต้องการ</v-col>
                  </v-row>
                  <v-row class="d-flex flex-row">
                    <div class="mx-4">
                      <v-checkbox
                        v-model="lookingforjob.fulltime"
                        label="งานประจำ(Full Time)"
                        class="width_full"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="mx-4">
                      <v-checkbox
                        v-model="lookingforjob.parttime"
                        label="งานนอกเวลา (Part Time)"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="mx-4">
                      <v-checkbox
                        v-model="lookingforjob.internship"
                        label="ฝึกงาน"
                        hide-details
                      >
                      </v-checkbox>
                    </div>
                    <div class="mx-4">
                      <v-checkbox
                        v-model="lookingforjob.freelance"
                        label="งานอิสระ  (Freelance)"
                        hide-details
                      ></v-checkbox>
                    </div>
                    <div class="mx-4">
                      <v-checkbox
                        v-model="lookingforjob.byday"
                        label="งานจ้างรายวัน"
                        hide-details
                      >
                      </v-checkbox>
                    </div>
                    <div class="mx-4">
                      <v-checkbox
                        v-model="lookingforjob.temporary"
                        label="งานจ้างชั่วคราว"
                        hide-details
                      >
                      </v-checkbox>
                    </div>
                    <div class="mx-4">
                      <v-checkbox
                        v-model="lookingforjob.workfromhome"
                        label="รับงานไปทำที่บ้าน"
                        hide-details
                      >
                      </v-checkbox>
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <!-- <v-text-field
                  v-model="lookingforjob.rangesalary"
                  label="ระดับเงินเดือนที่ต้องการ"
                  filled
                  rounded
                  dense
                >
                </v-text-field> -->
                  <!-- <v-select
                  v-model="lookingforjob.rangesalary"
                  :items="rangesalary"
                  label="ระดับเงินเดือนที่ต้องการ"
                  required
                  filled
                  rounded
                  dense
                >
                </v-select> -->
                  <v-select
                    v-model="lookingforjob.rangesalary"
                    :items="SalaryRanges"
                    item-value="salary_range"
                    item-text="salary_range"
                    label="ระดับเงินเดือนที่ต้องการ"
                    filled
                    rounded
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-list color="cardinfo">
                    <template v-for="(item, index) in listFileResume">
                      <v-list-item v-if="item" :key="index">
                        <span>ไฟล์ เรซูเม่</span>
                        <v-list-item-action>
                          <v-btn
                            :href="
                              host +
                              '/api/Student/DownloadFile?fileattachmentoid=' +
                              item.fileattachment.fileattachmentoid
                            "
                            target="_blank"
                            text
                            color="primary"
                          >
                            <v-icon>mdi-file-certificate</v-icon>
                            {{ item.fileattachment.filename }}
                            {{ item.fileattachment.extension }}
                          </v-btn>
                        </v-list-item-action>

                        <v-btn
                          color="red"
                          text
                          @click="
                            DeleteResumeStudentFile(item.fileattachment.fileattachmentoid)
                          "
                          ><v-icon>mdi-close-box</v-icon></v-btn
                        >
                      </v-list-item>
                    </template>
                    <v-list-item-title v-if="listFileResume.length == 0">
                      <v-file-input
                        label="เรซูเม่"
                        prepend-inner-icon="mdi-file-account"
                        chips
                        color="pink"
                        v-model="ResumeFile"
                        @change="onResumeChange"
                        filled
                        rounded
                        dense
                      >
                      </v-file-input>
                    </v-list-item-title>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>

            <EducationStudentDataTable
              v-if="this.idcard"
              :cardID="this.idcard"
            ></EducationStudentDataTable>
            <EducationStudentTable
              v-if="studentoid"
              :studentoid="studentoid"
            ></EducationStudentTable>
            <ExperienceJobStudentTable
              v-if="studentoid"
              :studentoid="studentoid"
            ></ExperienceJobStudentTable>
            <RewardStudentTable
              v-if="student_oid"
              :student_oid="student_oid"
            ></RewardStudentTable>
            <!-- <v-card outlined class="pa-2 mb-5 mt-5">
            <h3 class="primary--text">
              <v-icon color="primary" class="mr-1">mdi-school-outline</v-icon
              >รางวัล
            </h3>
          </v-card> -->
            <v-container>
              <!-- รางวัล -->
            </v-container>
          </v-col>
          <v-col order="first" cols="12" lg="3" md="12" sm="12">
            <v-row justify="center">
              <v-col>
                <v-col>
                  <div class="d-flex justify-center">
                    <div>
                      <v-avatar color="grey" size="240">
                        <v-img :src="avatarphoto"></v-img>
                      </v-avatar>
                    </div>
                  </div>
                </v-col>
                <v-row class="justify-center text-center">
                  <v-col lg="7" md="8" sm="10">
                    <v-file-input
                      accept="image/*"
                      label="รูปภาพ"
                      prepend-inner-icon="mdi-image-plus"
                      chips
                      color="pink"
                      v-model="file"
                      @change="UploadFile"
                      filled
                      rounded
                      dense
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row class="justify-center text-center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6 primary--text font-weight-bold">
                      <v-icon class="mr-1" color="primary">mdi-account</v-icon
                      >{{ student.firstname }}
                      {{ student.surname }}
                    </v-list-item-title>
                    <!-- <v-list-item-subtitle class="secondary--text"
                    >รหัสนักศึกษา : {{ student.studentcode }}
                  </v-list-item-subtitle> -->
                    <template v-if="student.email ? false : true"> </template>
                    <template v-else>
                      <v-list-item-title class="text-h6 secondary--text">
                        <v-icon class="mr-1" color="secondary">mdi-email</v-icon
                        >{{ student.email }}
                      </v-list-item-title>
                    </template>
                    <template v-if="student.tel ? false : true"> </template>
                    <template v-else>
                      <v-list-item-title class="text-h6 secondary--text">
                        <v-icon class="mr-1" color="secondary">mdi-phone</v-icon
                        >{{ student.tel }}
                      </v-list-item-title>
                    </template>
                  </v-list-item-content>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mb-4 mt-4 mx-0"></v-divider>
        <v-row class="justify-center text-center mt-4 mb-4">
          <v-col cols="12">
            <v-btn color="secondary" type="submit" class="ma-2"
              ><v-icon> mdi-content-save </v-icon>
              บันทึก
            </v-btn>
            <v-btn color="" @click="$router.go(-1)" class="ma-2"> ย้อนกลับ</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import {
  emailRules,
  idStudendRules,
  nameRules,
  hight_weightStudendRules,
  phoneStudentRules,
} from "@/ValidationHelper.js";
import PostedJobSwitch from "@/components/Student/PostedJobSwitch.vue";
import { backendapiUrl } from "@/services/constants";
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import moment from "moment";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { RoleCodes } from "@/services/constants";
import EducationStudentTable from "@/components/Student/Education/EducationStudentTable.vue";
import EducationStudentDataTable from "@/components/Student/Education/EducationStudentDataTable.vue";
import ExperienceJobStudentTable from "@/components/Student/Experience/ExperienceJobStudentTable.vue";
import RewardStudentTable from "@/components/Student/Reward/RewardStudentTable.vue";
import WarningTextbox from "@/components/common/WarningTextbox.vue";
var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  name: "StudentDetailView",
  components: {
    TextFieldDatePicker,
    EducationStudentTable,
    EducationStudentDataTable,
    ExperienceJobStudentTable,
    WarningTextbox,
    RewardStudentTable,
    PostedJobSwitch,
  },
  data() {
    return {
      moment: moment,
      studentoid: "",
      idcard: "",
      student: [],
      lookingforjob: [],
      titlenamelist: [],
      nationalitylist: [],
      religionlist: [],
      domicile: [],
      provinces: [],
      districts: [],
      tambons: [],
      selectedpostcode: "",
      contactprovinces: [],
      contactdistricts: [],
      contacttambons: [],
      selectedcontactpostcode: "",
      military: [
        { text: "ยังไม่ผ่านการเกณฑ์ทหาร", value: 0 },
        { text: "ผ่านการเกณฑ์ทหาร", value: 1 },
        { text: "อยู่ระหว่างรับราชการทหาร", value: 2 },
        { text: "ได้รับการยกเว้น(สำเร็จหลักสูตรรักษาดินแดน)", value: 3 },
        {
          text: "ได้รับการยกเว้น(จับฉลากได้ใบดำหรือ ร่างกายไม่ได้ขนาด)",
          value: 4,
        },
      ],

      yearvocationlist: [],
      JobSegment: [],

      menu: undefined,
      valid: true,
      file: [],
      ResumeFile: [],
      readers: [],
      ImgStudentformData: new FormData(),
      ResumeStudentformData: new FormData(),
      host: "",
      listFileResume: [],
      lookingforexperience: [],
      studentoid: "",
      SalaryRanges: [],
      show: false,
      student_oid: "",
      emailRules: emailRules,
      idStudendRules: idStudendRules,
      nameRules: nameRules,
      hight_weightStudendRules: hight_weightStudendRules,
      phoneStudentRules: phoneStudentRules,
      studentEducations: [],
    };
  },
  computed: {
    avatarphoto() {
      return this.student.base64thrumnail
        ? "data:image/jpeg;base64," + this.student.base64thrumnail
        : require("@/assets/user-img.jpg");
    },
    formattedBirthday: {
      get() {
        return this.student.birthday != ""
          ? moment(this.student.birthday).add(543, "year").locale("TH").format("LL")
          : "";
      },
      set(value) {
        console.log(this.student.birthday);
        this.student.birthday = value
          ? moment(value, "LL").subtract(543, "year").toDate()
          : null;
      },
    },
  },
  watch: {
    "lookingforjob.interestedjob1": function (newVal, oldVal) {
      if (newVal) {
        let index = this.JobSegment.findIndex((p) => p.name == newVal);
        this.JobSegment[index].disabled = true;
      }
      if (oldVal) {
        let index = this.JobSegment.findIndex((p) => p.name == oldVal);
        this.JobSegment[index].disabled = false;
      }
    },
    "lookingforjob.interestedjob2": function (newVal, oldVal) {
      if (newVal) {
        let index = this.JobSegment.findIndex((p) => p.name == newVal);
        this.JobSegment[index].disabled = true;
      }
      if (oldVal) {
        let index = this.JobSegment.findIndex((p) => p.name == oldVal);
        this.JobSegment[index].disabled = false;
      }
    },
    "lookingforjob.interestedjob3": function (newVal, oldVal) {
      if (newVal) {
        let index = this.JobSegment.findIndex((p) => p.name == newVal);
        this.JobSegment[index].disabled = true;
      }
      if (oldVal) {
        let index = this.JobSegment.findIndex((p) => p.name == oldVal);
        this.JobSegment[index].disabled = false;
      }
    },
  },
  methods: {
    async loadStudent(studentoid = "") {
      await studentDataProvider
        .loadStudent(studentoid)
        .then(async (response) => {
          var stdres = response.data;
          this.studentoid = response.data.oid;
          this.student_oid = response.data.oid;
          this.student = stdres;
          this.idcard = this.student.idcard;
          if (stdres?.tambon) {
            this.loadGetDistricts(stdres.tambon.district.province.provinceid);
            this.loadGetTambons(stdres.tambon.district.districtid);
            this.student.provinceid = stdres.tambon.district.province.provinceid;
            this.student.districtid = stdres.tambon.district.districtid;
            this.student.tambonid = stdres.tambon.tambonid;

            this.selectedpostcode = stdres.tambon.postcodemain;
            this.student.postcode = stdres.tambon.postcodemain;
          }
          if (stdres?.contacttambon) {
            this.loadGetContactDistricts(
              stdres.contacttambon.district.province.provinceid
            );
            this.loadGeContactTambons(stdres.contacttambon.district.districtid);
            this.student.contactprovinceid =
              stdres.contacttambon.district.province.provinceid;
            this.student.contactdistrictid = stdres.contacttambon.district.districtid;
            this.student.contacttambonid = stdres.contacttambon.tambonid;

            this.selectedcontactpostcode = stdres.contacttambon.postcodemain;
            this.student.contactpostcode = stdres.contacttambon.postcodemain;
          }
          if (response.data?.lookingforjob) {
            this.lookingforjob = response.data.lookingforjob;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadGetProvinces() {
      await masterDataProvider
        .loadGetProvinces()
        .then((response) => {
          this.provinces = response.data;
          this.contactprovinces = response.data;
          this.domicile = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetDistricts(provinceid) {
      await masterDataProvider
        .loadGetDistricts(provinceid)
        .then((response) => {
          this.districts = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetTambons(districtid) {
      await masterDataProvider
        .loadGetTambons(districtid)
        .then((response) => {
          this.tambons = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetContactDistricts(provinceid) {
      await masterDataProvider
        .loadGetDistricts(provinceid)
        .then((response) => {
          this.contactdistricts = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGeContactTambons(districtid) {
      await masterDataProvider
        .loadGetTambons(districtid)
        .then((response) => {
          this.contacttambons = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async UploadFile() {
      this.ImgStudentformData.append("File", this.file);
      this.ImgStudentformData.append("Oid", this.studentoid);
      var fileToLoad = this.file;
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        this.uploadImgStudent();
        var strImage = data.replace(/^data:image\/[a-z]+;base64,/, "");
        this.student.base64thrumnail = strImage;
      });
    },
    async save() {
      let validate = this.$refs.form.validate();
      if (validate) {
        this.student.lookingforjob = this.lookingforjob;
        this.UpdateStudent();
      } else {
        this.$toast.error("กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน");
      }
    },
    async UpdateStudent() {
      this.$store.dispatch("showLoading");
      studentDataProvider
        .UpdateStudent(this.student)
        .then((res) => {
          this.$toast.success("บันทึกข้อมูลสำเร็จ");
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว !!!" + res);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },
    async duplicateAddress() {
      this.loadGetContactDistricts(this.student.provinceid);
      this.loadGeContactTambons(this.student.districtid);
      this.student.contacthousenumber = this.student.housenumber;
      this.student.contactmoo = this.student.moo;
      this.student.contactsoi = this.student.soi;
      this.student.contactstreet = this.student.street;
      this.student.contactprovinceid = this.student.provinceid;
      this.student.contactdistrictid = this.student.districtid;
      this.student.contacttambonid = this.student.tambonid;

      this.student.contactpostcode = this.student.postcode;
      this.selectedcontactpostcode = this.selectedpostcode;
      this.$toast.success("ทำการดึงข้อมูลเรียบร้อย");
    },
    async changedprovince(provinceid) {
      this.student.districtid = "";
      this.student.tambonid = "";
      this.student.postcode = "";
      await this.loadGetDistricts(provinceid);
    },
    async changeddistrict(districtid) {
      this.student.tambonid = "";
      this.student.postcode = "";
      await this.loadGetTambons(districtid);
    },
    async changedtambon(tambonid) {
      var filtertambon = await this.tambons.filter(function (el) {
        return el.tambonid == tambonid;
      });
      this.selectedpostcode = await filtertambon[0].postcodemain;
      this.student.postcode = this.selectedpostcode;
    },
    async changedcontactprovince(provinceid) {
      this.student.contactdistrictid = "";
      this.student.contacttambonid = "";
      this.student.contactpostcode = "";
      await this.loadGetContactDistricts(provinceid);
    },
    async changedcontactdistrict(districtid) {
      this.student.contacttambonid = "";
      this.student.contactpostcode = "";
      await this.loadGeContactTambons(districtid);
    },
    async changedcontacttambon(tambonid) {
      var filtertambon = this.contacttambons.filter(function (el) {
        return el.tambonid == tambonid;
      });
      this.selectedcontactpostcode = filtertambon[0].postcodemain;
      this.student.contactpostcode = this.selectedcontactpostcode;
    },
    async genyear() {
      var year = new Date().getFullYear() + 543;
      for (var i = 0; i < 10; i++) this.yearvocationlist.push((year - i).toString());
    },
    async loadTitleName() {
      await masterDataProvider
        .getActiveTitleNames()
        .then((response) => {
          this.titlenamelist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadNationalities() {
      await masterDataProvider
        .getNationalities()
        .then((response) => {
          this.nationalitylist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadReligions() {
      await masterDataProvider
        .getReligions()
        .then((response) => {
          this.religionlist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async uploadImgStudent() {
      studentDataProvider
        .uploadImageStudent(this.ImgStudentformData)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("อัปโหลดรูปภาพเรียบร้อย !");
            this.file = [];
          }
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว" + res);
        });
    },
    async onResumeChange() {
      this.ResumeStudentformData.append("Oid", this.studentoid);
      this.ResumeStudentformData.append("File", this.ResumeFile);

      await studentDataProvider
        .uploadResumeFile(this.ResumeStudentformData)
        .then((result) => {
          this.$toast.success("อัปโหลด เรซูเม่ เรียบร้อย!");
          this.ResumeFile = [];
          this.loadResumeStudentFile(this.studentoid);
        })
        .catch((result) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    async loadResumeStudentFile(oid) {
      await studentDataProvider
        .LoadResumeFile(oid)
        .then((res) => {
          this.listFileResume = res.data;
        })
        .catch((res) => {
          this.$toast.error("โหลดเรซูเม่ ล้มเหลว!" + res);
        });
    },
    async DeleteResumeStudentFile(oid) {
      if (confirm("ต้องการลบเรซูเม่หรือไม่ ?")) {
        await studentDataProvider
          .DeleteResumeFile(oid)
          .then((res) => {
            this.listFileResume = [];
            this.$toast.success("ลบเรซูเม่ เรียบร้อย!");
          })
          .catch((res) => {
            this.$toast.error("ล้มเหลว!" + res);
          });
      }
    },
    async loadSalaryRanges() {
      masterDataProvider.loadSalaryRanges().then((response) => {
        this.SalaryRanges = response.data;
      });
    },
    async loadJobSegment() {
      masterDataProvider.getJobSegment().then((response) => {
        this.JobSegment = response.data;
      });
    },
    async clearprovince() {
      this.student.contactprovinceid = "";
    },
  },
  async created() {
    try {
      this.$store.dispatch("showLoading");
      this.studentoid = this.$route.params.studentoid;
      this.host = backendapiUrl;
      await this.loadJobSegment();
      await this.loadStudent(this.studentoid);

      await this.loadGetProvinces();
      await this.loadTitleName();
      await this.loadReligions();
      await this.loadNationalities();
      await this.genyear();
      await this.loadResumeStudentFile(this.studentoid);
      await this.loadSalaryRanges();
    } catch {
    } finally {
      this.$store.dispatch("hideLoading");
    }
  },
};
</script>

<style></style>
