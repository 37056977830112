<template>
  <div class="pa-4">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-book</v-icon> ศาสนา
      </h3>
    </v-row>
    <SearchableDataTable
      v-if="religions != undefined"
      table_caption="ศาสนา"
      :datatable_headers="majors_data_table_headers"
      :data_items="religions"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToDetailView(item)" icon color="info">
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "ReligionsListView",
  data() {
    return {
      isImporting: false,
      religions: undefined,
      majors_data_table_headers: [
        //{ text: "รหัส PK สาขาวิชา", value: "majorid" },
        { text: "รหัสศาสนา", value: "religionid" },
        { text: "ชื่อศาสนา (ไทย)", value: "religionth" },
        { text: "ชื่อศาสนา (อังกฤษ)", value: "religioneng" },
        { text: "ตัวเลือก", value: "action", align: "center" , sortable: false},
      ],
    };
  },
  methods: {
    async importReligions() {
      this.isImporting = true;
      await masterDataProvider
        .getReligions()
        .then((result) => {
          this.onImportReligionsCompleted();
        })
        .catch((err) => {
          this.isImporting = false;
          alert(err);
        });
    },
    async loadReligions() {
      await masterDataProvider
        .getReligions()
        .then((result) => {
          this.religions = result.data;
          console.log(this.religions);
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToDetailView: function (item) {
      this.$router
        .push({
          name: "ReligionsDetailView",
          params: { oid: item.oid },
        })
        .catch((err) => {});
    },
    onImportReligionsCompleted() {
      this.isImporting = false;
      this.loadReligions();
    },
  },
  async created() {
    await this.loadReligions();
  },
};
</script>

<style></style>
