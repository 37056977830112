<template>
    <label v-if="importstudentdata_status_statusname"><v-icon :color="dynamicColor">{{ dynamicIcon }}</v-icon> {{ importstudentdata_status_statusname
    }}</label>
</template>

<script>
export default {
    name: 'GraduatedStudentJobTrackingReport_ImportStudent_Status_Label',
    data() {
        return {}
    },
    props: ['importstudentdata_status', 'importstudentdata_status_statusname'],
    computed: {
        dynamicIcon() {
            switch (this.importstudentdata_status) {
                case 'WaitingToStartImportingProcess':
                    return "mdi-progress-clock";
                case 'Imported':
                    return "mdi-check-circle";
                case 'NoGraduateStudent':
                    return "mdi-account-alert";
                default:
                    return '';
            }
        },
        dynamicColor(){
            switch (this.importstudentdata_status) {
                case 'WaitingToStartImportingProcess':
                    return "info";
                case 'Imported':
                    return "success";
                case 'NoGraduateStudent':
                    return "warning";
                default:
                    return '';
            }
        }
    }
}
</script>

<style></style>