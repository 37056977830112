<template>
  <div class="pa-4 mt-4 d-flex justify-center">
    <v-row class="d-flex justify-center pt-5">
      <v-col cols="12">
        <v-card class="px-5 py-10" style="border-radius: 10px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-3 px-2">
              <v-icon class="pr-2">mdi-chart-areaspline-variant</v-icon>
              <h4>รายงานภาวะการมีงานทำ (รายบุคคล)</h4>
            </v-row>
            <v-row class="px-3 py-1" style="margin-top: 1%; margin-bottom: 2%">
              <v-divider></v-divider>
            </v-row>

            <v-row class="pb-6 pl-3">
              <span style="font-weight: bold">เกณฑ์การค้นหา</span>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
                xs="12"
                class="custom-col px-5 py-6"
              >
                <v-row class="pl-1">
                  <span class="primary--text font-weight-bold"
                    >ประเภทสถานศึกษา</span
                  >
                </v-row>

                <v-row>
                  <v-col class="px-0 py-0">
                    <v-btn
                      v-for="item in schooltypelist"
                      :key="item.value"
                      :color="
                        selectedSchoolType === item.value
                          ? 'primary'
                          : 'default'
                      "
                      @click="OnSchoolTypeChange(item.value)"
                      class="mx-1 my-1"
                      :block="$vuetify.breakpoint.smAndDown"
                    >
                      {{ item.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="8" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >จังหวัด</span
                >
                <v-autocomplete
                  :items="provinces"
                  placeholder="กรุณาระบุจังหวัด"
                  v-model="selectedProvince"
                  item-text="provincethai"
                  item-value="provinceid"
                  @change="OnOptionChanged()"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="8" md="8" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >สถานศึกษา</span
                >
                <v-autocomplete
                  :items="schools"
                  placeholder="กรุณาเลือกสถานศึกษา"
                  v-model="selectedSchool"
                  item-text="schoolname"
                  item-value="schoolid"
                  @change="OnOptionChanged()"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col lg="4" md="4" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >สถานะการมีงานทำ</span
                >
                <v-autocomplete
                  :items="employmentStatuses"
                  placeholder="กรุณาระบุสถานะการมีงานทำ"
                  v-model="selectedEmployementStatus"
                  item-text="name"
                  item-value="code"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >ปีที่สำเร็จการศึกษา<span style="color: red">*</span></span
                >
                <v-autocomplete
                  :items="yearTH_Choices"
                  placeholder="กรุณาเลือกปีที่สำเร็จการศึกษา"
                  v-model="selectedYearTH"
                  @change="onYearChanged()"
                  :rules="[(v) => !!v || 'กรุณาระบุปีที่สำเร็จการศึกษา']"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col lg="6" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >รอบที่ติดตาม<span style="color: red">*</span></span
                >
                <v-autocomplete
                  :items="Round_Choices"
                  placeholder="กรุณาระบุรอบการติดตาม"
                  v-model="selectedRound"
                  :rules="[(v) => !!v || 'กรุณาระบุรอบ']"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                  :disabled="selectedYearTH ? false : true"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
                xs="12"
                class="custom-col px-5 py-6"
              >
                <v-row class="pl-1">
                  <span class="primary--text font-weight-bold">เพศ</span>
                </v-row>

                <v-row>
                  <v-col class="px-0 py-0">
                    <v-btn
                      v-for="item in genderlist"
                      :key="item.value"
                      :color="
                        selectedGender === item.value ? 'primary' : 'default'
                      "
                      @click="OnGenderChange(item.value)"
                      class="mx-1 my-1"
                      :block="$vuetify.breakpoint.smAndDown"
                    >
                      {{ item.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="8" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >ประเภทนักศึกษา</span
                >
                <v-autocomplete
                  :items="studenttypes"
                  placeholder="กรุณาระบุประเภทนักศึกษา"
                  v-model="selectedStudentType"
                  item-value="studenttypecode"
                  item-text="studenttypename"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row class="py-0">
                  <v-col
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                    class="custom-col mb-0 py-0"
                  >
                    <span class="primary--text py-0" style="font-weight: bold"
                      >ระดับการศึกษา</span
                    >
                    <v-autocomplete
                      class="d-flex align-center"
                      :items="certificateTypes"
                      placeholder="กรุณาระบุระดับการศึกษา"
                      v-model="selectedCertificateTypeCode"
                      @change="OnOptionChanged()"
                      item-text="nameth"
                      item-value="code"
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                      clearable
                      dense
                      persistent-hint
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    lg="8"
                    md="8"
                    sm="12"
                    xs="12"
                    class="custom-col mb-0 py-0"
                  >
                    <span class="primary--text py-0" style="font-weight: bold"
                      >สาขาวิชา</span
                    >
                    <v-autocomplete
                      :items="majors"
                      placeholder="กรุณาระบุสาขาวิชา"
                      v-model="selectedMajor"
                      item-value="majornameth"
                      item-text="majornameth"
                      @change="OnOptionChanged()"
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                      clearable
                      dense
                      persistent-hint
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  color=""
                  @click="$router.go(-1)"
                  class="justify-center mr-3"
                  style="border-radius: 10px"
                >
                  ย้อนกลับ</v-btn
                >
                <v-btn
                  dark
                  class="color-btnCard"
                  style="border-radius: 10px"
                  v-on:click="create_reportParameters()"
                  >แสดงรายงาน<v-icon>mdi-logout-variant</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="document_viewer_dialog" width="50%">
      <template>
        <v-card height="auto" class="px-10 py-10">
          <ReportExportFromQueue
            :report_name="report_name"
            :report_parameters="report_parameters"
            @close_Dialog="document_viewer_dialog = false"
          ></ReportExportFromQueue>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import DocumentViewer from "../../../components/Report/DocumentViewer.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";

import ReportExport from "@/components/Report/ReportExport.vue";
import ReportExportFromQueue from "@/components/Report/ReportExportFromQueue.vue";

var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "GraduatedStudentJobTrackingReportMain_StudentDetail_Report",
  props: [],
  data() {
    return {
      document_viewer_key: 1,
      document_viewer_dialog: false,
      report_name: "GraduatedStudentJobTrackingReportMain_StudentDetail_Report",
      report_parameters: [],
      yearTH_Choices: undefined,
      selectedYearTH: undefined,
      certificateTypes: undefined,
      selectedCertificateTypeCode: "",
      schooltypelist: [
        { text: "ทั้งหมด", value: "" },
        { text: "ภาครัฐ", value: "ภาครัฐ" },
        { text: "ภาคเอกชน", value: "ภาคเอกชน" },
      ],
      selectedSchoolType: "",
      genderlist: [
        { text: "ทั้งหมด", value: "" },
        { text: "ชาย", value: "M" },
        { text: "หญิง", value: "F" },
      ],
      selectedGender: "",
      provinces: undefined,
      majors: undefined,
      schools: undefined,
      genders: undefined,
      request_data: new GetSchools_Reqeust(),
      studenttypes: undefined,
      employmentStatuses: undefined,
      selectedEmployementStatus: "",
      JobSegment: undefined,
      valid: true,
      Round_Choices: [],
      selectedRound: "",
      selectedSchool: "",
      selectedMajor: "",
      selectedStudentType: "",
      selectedRegion: "",
      selectedProvince: "",
      selectedJobSegment: "",
    };
  },
  methods: {
    onYearChanged() {
      this.loadListOfJobTrackingRoundFromGraduatedYearTH();
    },
    loadListOfGraduatedYearFromJobTrackingReport() {
      commonDataProvider
        .loadListOfGraduatedYearFromJobTrackingReport()
        .then((resp) => {
          this.yearTH_Choices = resp.data;
        });
    },

    loadListOfJobTrackingRoundFromGraduatedYearTH() {
      commonDataProvider
        .loadListOfJobTrackingRoundFromGraduatedYearTH(this.selectedYearTH)
        .then((resp) => {
          this.Round_Choices = resp.data;
        });
    },
    async initialize() {
      await masterDataProvider.getCertificateTypes().then((result) => {
        this.certificateTypes = result.data;
      });
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
      await majorDataProvider
        .getMajorThNamesFromGraduatedStudentInVECEducationInformation()
        .then((result) => {
          this.majors = result.data;
        });

      await schoolDataProvider
        .getSchoolsFromGraduatedStudentJobTrackingReportExport(
          this.selectedSchoolType,
          this.selectedProvince
        )
        .then((result) => {
          this.schools = result.data;
        });

      await masterDataProvider.loadEmploymentStatuses().then((result) => {
        this.employmentStatuses = result.data;
      });

      await masterDataProvider.getGenders().then((result) => {
        this.genders = result.data;
      });

      await commonDataProvider.getStudentTypes().then((result) => {
        this.studenttypes = result.data;
      });

      await masterDataProvider.getJobSegment().then((response) => {
        if (response?.status == 200) {
          this.JobSegment = response.data;
        }
      });

      this.loadListOfGraduatedYearFromJobTrackingReport();
    },
    create_reportParameters() {
      if (this.$refs.form.validate()) {
        this.document_viewer_key += 1;
        this.report_parameters = [];
        if (this.selectedYearTH != undefined) {
          this.report_parameters.push("prm_YearTH=" + this.selectedYearTH);
        }
        if (this.selectedRound > 0) {
          this.report_parameters.push("prm_Round=" + this.selectedRound);
        }
        if (this.selectedSchool) {
          this.report_parameters.push("prm_SchoolID=" + this.selectedSchool);
        }

        if (this.selectedSchoolType) {
          this.report_parameters.push(
            "prm_SchoolType=" + this.selectedSchoolType
          );
        }
        if (this.selectedProvince) {
          this.report_parameters.push(
            "prm_ProvinceID=" + this.selectedProvince
          );
        }
        if (this.selectedStudentType) {
          this.report_parameters.push(
            "prm_StudentTypeCode=" + this.selectedStudentType
          );
        }
        if (this.selectedEmployementStatus) {
          this.report_parameters.push(
            "prm_EmploymentStatus=" + this.selectedEmployementStatus
          );
        }
        if (this.selectedCertificateTypeCode) {
          this.report_parameters.push(
            "prm_CertificateType=" + this.selectedCertificateTypeCode
          );
        }
        if (this.selectedMajor) {
          this.report_parameters.push("prm_MajorNameTh=" + this.selectedMajor);
        }
        if (this.selectedGender) {
          this.report_parameters.push("prm_Gender=" + this.selectedGender);
        }
        this.document_viewer_dialog = true;
      }
    },
    async OnSchoolTypeChange(value) {
      this.selectedSchoolType = value;
      this.loadListOfGraduatedYearFromJobTrackingReport();
      await this.OnOptionChanged();
    },
    OnGenderChange(value) {
      this.selectedGender = value;
      this.loadListOfGraduatedYearFromJobTrackingReport();
    },
    async OnOptionChanged() {
      await schoolDataProvider
        .getSchoolsFromGraduatedStudentJobTrackingReportExport(
          this.selectedSchoolType,
          this.selectedProvince
        )
        .then((result) => {
          this.schools = result.data;
        });

      if (this.selectedSchool) {
        await majorDataProvider
          .getMajorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
            this.selectedSchool
          )
          .then((result) => {
            this.majors = result.data;
            console.log(this.majors, "  this.majors1");
          });
      } else {
        await majorDataProvider
          .getMajorThNamesFromGraduatedStudentInVECEducationInformation()
          .then((result) => {
            this.majors = result.data;
            console.log(this.majors, "  this.majors2");
          });
      }

      if (this.selectedSchool) {
        await masterDataProvider
          .getCertificateTypesByVECEducationInformationsFromSchoolId(
            this.selectedSchool
          )
          .then((result) => {
            this.certificateTypes = result.data;
          });
      } else {
        await masterDataProvider.getCertificateTypes().then((result) => {
          this.certificateTypes = result.data;
        });
      }
    },
  },
  watch: {
    tableOptions: {
      handler(newValue, oldValue) {
        this.loadListOfGraduatedYearFromJobTrackingReport();
      },
      deep: true,
    },
  },
  async created() {
    await this.initialize();
  },
  components: { DocumentViewer, ReportExport, ReportExportFromQueue },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.color-btnCard {
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 60%,
    rgba(63, 81, 181, 1) 100%
  );
}
</style>
