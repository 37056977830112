<template>
  <div class="container">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-currency-usd</v-icon> ระดับเงินเดือน
      </h3>
    </v-row>
    <SearchableDataTable
      v-if="salaryranges"
      table_caption="ระดับเงินเดือน"
      :datatable_headers="salaryranges_data_table_headers"
      :data_items="salaryranges"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToSalaryrangesDetailView(item)" icon color="info">
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "SalaryrangesListView",
  data() {
    return {
      salaryranges: [],
      salaryranges_data_table_headers: [
        // { text: "รหัส (id)", value: "order" },
        { text: "ช่วงเงินเดือน", value: "salary_range" },
        { text: "ตัวเลือก", value: "action", align: "center" , sortable: false},
      ],
    };
  },
  methods: {
    async loadSalaryRange() {
      await masterDataProvider
        .loadSalaryRanges()
        .then((result) => {
          this.salaryranges = result.data;
          console.log(this.salaryranges);
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToSalaryrangesDetailView: function (item) {
      //   var Order = item.order;
      this.$router
        .push({ name: "SalaryrangesDetailView", params: { Order: item } })
        .catch((err) => {});
    },
  },
  async created() {
    await this.loadSalaryRange();
  },
};
</script>

<style></style>
