<template>
  <div class="container">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-map</v-icon> ประเทศไทย
      </h3>
    </v-row>
    <SearchableDataTable
      v-if="provinces"
      table_caption="จังหวัด"
      :datatable_headers="province_data_table_headers"
      :data_items="provinces"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToDistrictListView(item)" icon color="info">
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: { SearchableDataTable },
  name: "ProvinceListView",
  data() {
    return {
      provinces: [],
      province_data_table_headers: [
        { text: "รหัส (id)", value: "provinceid" },
        { text: "ชื่อจังหวัด (ไทย)", value: "provincethai" },
        { text: "ภาค", value: "regionascommon" },
        { text: "ประเภท", value: "provincetype" },
        { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    async loadProvince() {
      await masterDataProvider
        .loadGetProvinces()
        .then((result) => {
          this.provinces = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToDistrictListView: function (item) {
      var provinceID = item.provinceid;
      this.$router
        .push({ name: "DistrictListView", params: { provinceID: provinceID } })
        .catch((err) => {});
    },
  },
  async created() {
    await this.loadProvince();
  },
};
</script>

<style></style>
