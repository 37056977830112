<template>
  <div class="d-flex justify-center">
    <v-container class="mt-4">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <v-row class="d-flex justify-center pt-5">
        <v-col cols="12" lg="12" sm="12">
          <v-card class="pt-6 pb-6 px-10 mb-12" style="border-radius: 10px">
      <v-row class="d-flex justify-end">
        <v-col cols="auto" class="text-right">
          <span class="">
            อัปเดตล่าสุด: {{
              student.profileupdateddate
                ? moment(student.profileupdateddate)
                    .add(543, 'year')
                    .locale('th')
                    .format("DD MMMM YYYY")
                : "-"
            }}
          </span>
        </v-col>
      </v-row>
            <v-row justify="center">
              <v-col cols="12" xl="12" lg="10" md="12" sm="12" xs="12" class="justify-center">
                <v-row
                  class="d-flex justify-center pt-5 pb-5 pl-6 pr-6"
                  v-if="isStudentOrSchool_Guidance_Teacher"
                >
                  <v-col
                    cols="12"
                    lg="2"
                    md="6"
                    sm="12"
                    class="pa-0"
                    style="display: flex; justify-content: center"
                  >
                    <v-avatar
                      style="max-width: 170px; aspect-ratio: 1; width: 100%; height: auto"
                    >
                      <v-img :src="avatarphoto" cover></v-img>
                    </v-avatar>
                  </v-col>
                  <!-- กรณีหน้าจอใหญ่ -->
                  <v-col
                    v-if="$vuetify.breakpoint.lgAndUp && $vuetify.breakpoint.mdAndUp"
                    cols="12"
                    lg="7"
                    md="6"
                    sm="12"
                  >
                    <div
                      class="pl-5"
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                      "
                    >
                      <v-row class="pb-2" align="center">
                        <v-col class="pa-0" cols="auto">
                          <v-list-item-title class="text-h6 font-weight-bold">
                            {{ student.firstname }} {{ student.surname }}
                          </v-list-item-title>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="auto">
                          <v-chip v-if="selectedLabelJob" color="success">
                            {{ selectedLabelJob }}
                          </v-chip>
                        </v-col>
                      </v-row>

                      <v-row v-if="student.birthday">
                        <v-col class="pa-0 ma-0 display-center">
                          <label class="pr-2"
                            >{{
                              moment(student.birthday)
                                .add(543, "year")
                                .locale("th")
                                .format("DD MMMM YYYY")
                            }}
                          </label>
                        </v-col>
                        <label class="pa-0 ma-0 display-center"
                          >( {{ calculateAge(student.birthday) }} ปี )</label
                        >
                      </v-row>
                      <v-row v-if="student.tel">
                        <v-col class="pa-0 ma-0 display-center">
                          <v-icon class="pr-1">mdi-phone</v-icon>
                          <label>{{ student.tel }}</label>
                        </v-col>
                      </v-row>
                      <v-row v-if="student.email">
                        <v-col class="pa-0 ma-0 display-center">
                          <v-icon class="pr-1">mdi-email</v-icon>
                          <label>{{ student.email }}</label>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <!-- กรณีหน้าจอเล็ก -->
                  <v-col
                    v-else-if="
                      !$vuetify.breakpoint.lgAndUp && !$vuetify.breakpoint.mdAndUp
                    "
                    cols="12"
                    
                  >
                    <div
                      style="display: flex; flex-direction: column; align-items: center"
                    >
                      <v-row class="pb-2" align="center">
                        <v-col class="pa-0" cols="12" lg="auto" sm="12">
                          <v-list-item-title class="text-h6 font-weight-bold text-center">
                            {{ student.firstname }} {{ student.surname }}
                          </v-list-item-title>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="12"
                          lg="auto"
                          sm="12">
                          <v-chip v-if="selectedLabelJob" color="success">
                            {{ selectedLabelJob }}
                          </v-chip>
                        </v-col>
                      </v-row>

                      <v-row v-if="student.birthday">
                        <v-col class="pa-0 ma-0 display-center">
                          <label class="pr-2"
                            >{{
                              moment(student.birthday)
                                .add(543, "year")
                                .locale("th")
                                .format("DD MMMM YYYY")
                            }}
                          </label>
                        </v-col>
                        <label class="pa-0 ma-0 display-center"
                          >( {{ calculateAge(student.birthday) }} ปี )</label
                        >
                      </v-row>
                      <v-row v-if="student.tel">
                        <v-col class="pa-0 ma-0 display-center">
                          <v-icon>mdi-phone</v-icon>
                          <label>{{ student.tel }}</label>
                        </v-col>
                      </v-row>
                      <v-row v-if="student.email">
                        <v-col class="pa-0 ma-0 display-center">
                          <v-icon>mdi-email</v-icon>
                          <label>{{ student.email }}</label>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <!-- กรณีหน้าจออื่นๆ-->
                  <v-col
                    v-else
                    cols="12"
                    lg="7"
                    md="6"
                    sm="12"
                  >
                    <div
                      class="pl-5"
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                      "
                    >
                      <v-row class="pb-2" align="center">
                        <v-col class="pa-0" cols="auto">
                          <v-list-item-title class="text-h6 font-weight-bold">
                            {{ student.firstname }} {{ student.surname }}
                          </v-list-item-title>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="auto">
                          <v-chip v-if="selectedLabelJob" color="success">
                            {{ selectedLabelJob }}
                          </v-chip>
                        </v-col>
                      </v-row>

                      <v-row v-if="student.birthday">
                        <v-col class="pa-0 ma-0 display-center">
                          <label class="pr-2"
                            >{{
                              moment(student.birthday)
                                .add(543, "year")
                                .locale("th")
                                .format("DD MMMM YYYY")
                            }}
                          </label>
                        </v-col>
                        <label class="pa-0 ma-0 display-center"
                          >( {{ calculateAge(student.birthday) }} ปี )</label
                        >
                      </v-row>
                      <v-row v-if="student.tel">
                        <v-col class="pa-0 ma-0 display-center">
                          <v-icon class="pr-1">mdi-phone</v-icon>
                          <label>{{ student.tel }}</label>
                        </v-col>
                      </v-row>
                      <v-row v-if="student.email">
                        <v-col class="pa-0 ma-0 display-center">
                          <v-icon class="pr-1">mdi-email</v-icon>
                          <label>{{ student.email }}</label>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3" md="12" sm="12">
                    <v-btn
                      class="color-btnCard150 mt-2 equal-height-btn"
                      dark
                      :style="{
                        height: $vuetify.breakpoint.mdAndDown ? '35px' : '50px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }"
                      @click="triggerFileInput"
                    >
                      เปลี่ยนรูปโปรไฟล์
                    </v-btn>
                    <input
                      type="file"
                      ref="fileInput"
                      accept="image/*"
                      style="display: none"
                      @change="UploadFile"
                    />

                    <v-btn
                      @click="handleChangePath()"
                      class="color-btnCard300 mt-2 equal-height-btn"
                      dark
                      :style="{
                        height: $vuetify.breakpoint.mdAndDown ? '35px' : '50px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }"
                    >
                      แก้ไขข้อมูล
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center pt-5 pb-5" v-else>
                  <v-col style="display: flex; justify-content: center">
                    <v-avatar color="grey" size="170">
                      <v-img :src="avatarphoto"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12">
                    <div
                      style="display: flex; flex-direction: column; align-items: center"
                    >
                      <v-row class="pb-2" align="center">
                        <v-col class="pa-0" cols="12" lg="auto" sm="12">
                          <v-list-item-title class="text-h6 font-weight-bold text-center">
                            {{ student.firstname }} {{ student.surname }}
                          </v-list-item-title>
                        </v-col>
                        <v-col
                          v-if="selectedLabelJob"
                          class="d-flex justify-center"
                          cols="12"
                          lg="auto"
                          sm="12"
                        >
                          <v-chip color="success">
                            {{ selectedLabelJob }}
                          </v-chip>
                        </v-col>
                      </v-row>

                      <v-row v-if="student.birthday && $vuetify.breakpoint.lgAndUp && $vuetify.breakpoint.mdAndUp">
                        <v-col class="pa-0 ma-0 display-center">
                          <label class="pr-2"
                            >{{
                              moment(student.birthday)
                                .add(543, "year")
                                .locale("th")
                                .format("D MMMM YYYY")
                            }}
                          </label>
                        </v-col>
                        <label class="pa-0 ma-0 display-center"
                          >( {{ calculateAge(student.birthday) }} ปี )</label
                        >
                      </v-row>
                      <v-row v-if="student.birthday && !$vuetify.breakpoint.lgAndUp && !$vuetify.breakpoint.mdAndUp">
                        <v-col cols="12" class="pa-0 ma-0 display-center">
                          <label class="pr-2"
                            >{{
                              moment(student.birthday)
                                .add(543, "year")
                                .locale("th")
                                .format("D MMMM YYYY")
                            }}
                          </label>
                        </v-col>
                        
                      </v-row>
                      <v-row v-if="student.birthday && !$vuetify.breakpoint.lgAndUp && !$vuetify.breakpoint.mdAndUp">
                        <v-col cols="12" class="">
                          <label class=""
                          >( {{ calculateAge(student.birthday) }} ปี )</label
                        >
                        </v-col>
                        
                      </v-row>
                      <v-row v-if="student.tel">
                        <v-col cols="12" class="pa-0 ma-0 display-center">
                          <v-icon>mdi-phone</v-icon>
                          <label>{{ student.tel }}</label>
                        </v-col>
                      </v-row>
                      <v-row v-if="student.email">
                        <v-col class="pa-0 ma-0 display-center">
                          <v-icon>mdi-email</v-icon>
                          <label>{{ student.email }}</label>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="pt-2 px-6">
                  <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                  <h3 class="primary--text font-weight-bold">ข้อมูลส่วนตัว</h3>
                </v-row>
                <v-row class="pl-6 pr-6" style="margin-top: 1%; margin-bottom: 2%">
                  <v-divider class="mt-3"></v-divider>
                </v-row>
                <v-row class="pl-6 pr-6">
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">หมายเลขประจำตัวประชาชน</h4>
                    <span>{{ student.idcard }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">วัน / เดือน / ปี (พ.ศ.) เกิด</h4>

                    <span>{{
                      student.birthday
                        ? moment(student.birthday)
                            .add(543, "year")
                            .locale("th")
                            .format("D MMMM YYYY")
                        : "-"
                    }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">เพศ</h4>
                    <span v-if="selectedLabelGender">{{ selectedLabelGender }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">สถานะการสมรส</h4>
                    <span v-if="selectedLableMarital">{{ selectedLableMarital }}</span>
                  </v-col>
                </v-row>
                <v-row class="pl-6 pr-6">
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">สัญชาติ</h4>
                    <span>{{ selectedNationalitylist }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">ศาสนา</h4>
                    <span>{{ selectedReligionlist }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">ส่วนสูง</h4>
                    <span>{{ student.hight ? student.hight : "-" }} ซม.</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">น้ำหนัก</h4>
                    <span>{{ student.weight ? student.weight : "-" }} กก.</span>
                  </v-col>
                </v-row>
                <v-row class="pl-6 pr-6">
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">ภูมิลำเนา</h4>
                    <span>{{ selectedDomicileProvince }}</span>
                  </v-col>
                </v-row>
                <v-row class="pt-2 pl-6 pr-6">
                  <v-icon class="pr-2" color="primary">mdi-map-marker</v-icon>
                  <h3 class="primary--text font-weight-bold">ที่อยู่</h3>
                </v-row>
                <v-row class="pl-6 pr-6" style="margin-top: 1%; margin-bottom: 2%">
                  <v-divider class="mt-3"></v-divider>
                </v-row>
                <v-row class="pl-6 pr-6">
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">เลขที่อยู่</h4>
                    <span>{{ student.housenumber ? student.housenumber : "-" }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">หมู่ที่</h4>
                    <span>{{ student.moo ? student.moo : "-" }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">ซอย</h4>
                    <span>{{ student.soi ? student.soi : "-" }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">ถนน</h4>
                    <span>{{ student.street ? student.street : "-" }}</span>
                  </v-col>
                </v-row>

                <v-row class="pl-6 pr-6">
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">แขวง / ตำบล</h4>
                    <span>{{ selectedTambons }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">อำเภอ / เขต</h4>
                    <span>{{ selectedDistricts }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">จังหวัด</h4>
                    <span>{{ selectedProvinces }}</span>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" sm="12">
                    <h4 class="primary--text">รหัสไปรษณีย์</h4>
                    <span>{{ selectedpostcode }} </span>
                  </v-col>
                </v-row>
                <v-row class="pt-2 pl-6 pr-6">
                  <v-icon class="pr-2" color="primary">mdi-briefcase</v-icon>
                  <h3 class="primary--text">ประเภทงานที่ต้องการ</h3>
                </v-row>
                <v-row class="pl-6 pr-6" style="margin-top: 1%; margin-bottom: 2%">
                  <v-divider class="mt-3"></v-divider>
                </v-row>
                <v-row class="pl-6 pr-6">
                  <v-col class="pl-6 pr-6" cols="12" lg="6" md="6" sm="12">
                    <v-row class="pb-2">
                      <h4 class="primary--text">ประเภทงานที่สนใจ</h4>
                    </v-row>
                    <v-row class="pb-3">
                      <v-col>
                        <v-row>
                          <v-chip class="" color="success">ลำดับที่ 1 </v-chip>
                        </v-row>
                        <v-row>
                          <span>{{
                            lookingforjob.interestedjob1
                              ? lookingforjob.interestedjob1
                              : "-"
                          }}</span>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row class="pb-3">
                      <v-col>
                        <v-row>
                          <v-chip class="" color="success">ลำดับที่ 2 </v-chip>
                        </v-row>
                        <v-row>
                          <span>{{
                            lookingforjob.interestedjob2
                              ? lookingforjob.interestedjob2
                              : "-"
                          }}</span>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row class="pb-3">
                      <v-col>
                        <v-row>
                          <v-chip class="" color="success">ลำดับที่ 3 </v-chip>
                        </v-row>
                        <v-row>
                          <span>{{
                            lookingforjob.interestedjob3
                              ? lookingforjob.interestedjob3
                              : "-"
                          }}</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-col class="pl-0">
                      <h4 class="primary--text">ลักษณะงานที่ต้องการ</h4>
                      <div
                        v-for="(chip, index) in generateJobChips()"
                        :key="index"
                        class="pl-0"
                      >
                        <v-chip class="float-start" color="#3F51B533">
                          {{ chip }}
                        </v-chip>
                      </div>
                      <v-col class="pl-0 float-end">
                        <h4 class="primary--text">เงินเดือนที่ต้องการ</h4>
                        {{ lookingforjob.rangesalary ? lookingforjob.rangesalary : "-" }}
                        บาท / เดือน
                      </v-col>
                      <v-col class="pl-0">
                        <h4 class="primary--text">เรซูเม่</h4>
                        <template v-if="listFileResume && listFileResume.length > 0">
                          <template v-for="(item, index) in listFileResume">
                            <v-list-item class="pa-0" v-if="item" :key="index">
                              <v-btn
                                :href="
                                  getResumeDownloadLink(
                                    item.fileattachment.fileattachmentoid
                                  )
                                "
                                target="_blank"
                                text
                                color="primary"
                                class="pa-0"
                              >
                                คลิกเพื่อเปิดไฟล์
                              </v-btn>
                              <!-- <v-btn @click="downloadFile(item.fileattachment.fileattachmentoid)">
                        xxxxx
                      </v-btn> -->
                            </v-list-item>
                          </template>
                        </template>
                        <v-col class="pl-0" v-else>ไม่มีไฟล์ข้อมูล</v-col>
                      </v-col>
                    </v-col>
                  </v-col>
                </v-row>

                <PreviewEducationStudentDataTable
                  v-if="this.idcard"
                  :cardID="this.idcard"
                ></PreviewEducationStudentDataTable>

                <PreviewEducationStudentTable
                  v-if="studentoid"
                  :studentoid="studentoid"
                  :isInputDisabled="isInputDisabled"
                ></PreviewEducationStudentTable>

                <PreviewExperienceJobStudentTable
                  v-if="studentoid"
                  :studentoid="studentoid"
                  :isInputDisabled="isInputDisabled"
                ></PreviewExperienceJobStudentTable>

                <PreviewRewardStudentTable
                  v-if="student_oid"
                  :student_oid="student_oid"
                  :isInputDisabled="isInputDisabled"
                ></PreviewRewardStudentTable>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import EducationStudentTable from "@/components/Student/Education/EducationStudentTable.vue";
import PreviewEducationStudentTable from "@/components/Student/Education/PreviewEducationStudentTable.vue";
import PreviewEducationStudentDataTable from "@/components/Student/Education/PreviewEducationStudentDataTable.vue";
import EducationStudentDataTable from "@/components/Student/Education/EducationStudentDataTable.vue";
import PreviewExperienceJobStudentTable from "@/components/Student/Experience/PreviewExperienceJobStudentTable.vue";
import ExperienceJobStudentTable from "@/components/Student/Experience/ExperienceJobStudentTable.vue";
import PreviewRewardStudentTable from "@/components/Student/Reward/PreviewRewardStudentTable.vue";
import RewardStudentTable from "@/components/Student/Reward/RewardStudentTable.vue";
import FormatDate from "@/components/common/FormatDate.vue";
import { ApplicationRoleCodes } from "@/Constants";
import moment from "moment";
import "moment-timezone";
import FormatDateView from "@/components/common/FormatDateView.vue";
var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  name: "StudentDetailOverView",
  components: {
    EducationStudentTable,
    PreviewEducationStudentTable,
    PreviewEducationStudentDataTable,
    PreviewExperienceJobStudentTable,
    EducationStudentDataTable,
    ExperienceJobStudentTable,
    PreviewRewardStudentTable,
    RewardStudentTable,
    FormatDate,
    FormatDateView,
  },
  data() {
    return {
      moment: moment,
      studentoid: "",
      idcard: "",
      student: [],
      lookingforjob: [],
      titlenamelist: [],
      nationalitylist: [],
      religionlist: [],
      domicile: [],
      provinces: [],
      districts: [],
      tambons: [],
      selectedpostcode: "",
      contactprovinces: [],
      contactdistricts: [],
      contacttambons: [],
      selectedcontactpostcode: "",
      military: [
        { text: "ยังไม่ผ่านการเกณฑ์ทหาร", value: 0 },
        { text: "ผ่านการเกณฑ์ทหาร", value: 1 },
        { text: "อยู่ระหว่างรับราชการทหาร", value: 2 },
        { text: "ได้รับการยกเว้น(สำเร็จหลักสูตรรักษาดินแดน)", value: 3 },
        {
          text: "ได้รับการยกเว้น(จับฉลากได้ใบดำหรือ ร่างกายไม่ได้ขนาด)",
          value: 4,
        },
      ],

      yearvocationlist: [],
      JobSegment: [],
      menu: undefined,
      valid: true,
      file: null,
      ResumeFile: [],
      readers: [],
      ImgStudentformData: new FormData(),
      ResumeStudentformData: new FormData(),
      host: "",
      listFileResume: [],
      lookingforexperience: [],
      studentoid: "",
      SalaryRanges: [],
      show: false,
      student_oid: "",
      studentEducations: [],
      isInputDisabled: true,
      studentAge: null,
      radioOptionsJob: [
        { label: "ไม่ต้องการเรียนต่อและไม่หางาน", value: 0 },
        { label: "ต้องการเรียนต่อ", value: 1 },
        { label: "ต้องการหางาน", value: 2 },
        { label: "ต้องการเรียนต่อและหางาน", value: 3 },
      ],
      radioOptionsGender: [
        { label: "ชาย", value: "M" },
        { label: "หญิง", value: "F" },
      ],

      radioOptionsMarital: [
        { label: "โสด", value: 0 },
        { label: "แต่งงาน", value: 1 },
        { label: "หย่าร้าง", value: 2 },
      ],
      resumeurl: "",
    };
  },
  computed: {
    avatarphoto() {
      return this.student.base64thrumnail
        ? "data:image/jpeg;base64," + this.student.base64thrumnail
        : require("@/assets/user-img.jpg");
    },
    selectedLabelJob() {
      const selectedOption = this.radioOptionsJob.find(
        (option) => option.value === this.lookingforjob.lookingforjobstatus
      );
      return selectedOption ? selectedOption.label : "";
    },
    selectedLabelGender() {
      const selectedOption = this.radioOptionsGender.find(
        (option) => option.value === this.student.gender
      );
      return selectedOption ? selectedOption.label : "-";
    },
    selectedLableMarital() {
      const selectedOption = this.radioOptionsMarital.find(
        (option) => option.value === this.student.marital
      );
      return selectedOption ? selectedOption.label : "-";
    },
    selectedDomicileProvince() {
      const selectedProvince = this.domicile.find(
        (province) => province.provinceid === this.student.domicile
      );
      return selectedProvince ? selectedProvince.provincethai : "-";
    },
    selectedNationalitylist() {
      const selectedNatioanal = this.nationalitylist.find(
        (nationality) => nationality.nationalityid === this.student.nationalityid
      );
      return selectedNatioanal ? selectedNatioanal.nationalityth : "-";
    },
    selectedReligionlist() {
      const selectedReligion = this.religionlist.find(
        (religion) => religion.religionid === this.student.religionid
      );
      return selectedReligion ? selectedReligion.religionth : "-";
    },
    selectedTambons() {
      const selectedTambon = this.tambons.find(
        (tambon) => tambon.tambonid === this.student.tambonid
      );
      return selectedTambon ? selectedTambon.tambonthai : "-";
    },
    selectedDistricts() {
      const selectDistrict = this.districts.find(
        (district) => district.districtid === this.student.districtid
      );
      return selectDistrict ? selectDistrict.districtthai : "-";
    },
    selectedProvinces() {
      const selectProvince = this.provinces.find(
        (province) => province.provinceid === this.student.provinceid
      );
      return selectProvince ? selectProvince.provincethai : "-";
    },
    isStudentOrSchool_Guidance_Teacher() {
      const roles = this.$store.getters.rolecodes;
      return (
        roles.includes(ApplicationRoleCodes.Student) ||
        roles.includes(ApplicationRoleCodes.School_Guidance_Teacher)
      );
    },
  },

  methods: {
    async loadCurrentStudent() {
      this.$store.dispatch("showLoading");
      await studentDataProvider
        .loadCurrentStudentProfile()
        .then(async (response) => {
          this.studentoid = response.data.oid;
          this.student_oid = response.data.oid;
          this.student = response.data;
          this.idcard = this.student.idcard;
          this.studentAge = this.calculateAge(stdres.birthday);

          console.log(this.student);

          if (stdres?.tambon) {
            this.loadGetDistricts(stdres.tambon.district.province.provinceid);
            this.loadGetTambons(stdres.tambon.district.districtid);
            this.student.provinceid = stdres.tambon.district.province.provinceid;
            this.student.districtid = stdres.tambon.district.districtid;
            this.student.tambonid = stdres.tambon.tambonid;

            this.selectedpostcode = stdres.tambon.postcodemain;
            this.student.postcode = stdres.tambon.postcodemain;
          }
          if (stdres?.contacttambon) {
            this.loadGetContactDistricts(
              stdres.contacttambon.district.province.provinceid
            );
            this.loadGeContactTambons(stdres.contacttambon.district.districtid);
            this.student.contactprovinceid =
              stdres.contacttambon.district.province.provinceid;
            this.student.contactdistrictid = stdres.contacttambon.district.districtid;
            this.student.contacttambonid = stdres.contacttambon.tambonid;

            this.selectedcontactpostcode = stdres.contacttambon.postcodemain;
            this.student.contactpostcode = stdres.contacttambon.postcodemain;
          }
          if (response.data?.lookingforjob) {
            this.lookingforjob = response.data.lookingforjob;
          }
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },
    async loadStudent(studentoid = "") {
      this.$store.dispatch("showLoading");
      await studentDataProvider
        .loadStudent(studentoid)
        .then(async (response) => {
          var stdres = response.data;
          this.studentoid = response.data.oid;
          this.student_oid = response.data.oid;
          this.student = stdres;
          this.idcard = this.student.idcard;
          this.studentAge = this.calculateAge(stdres.birthday);

          console.log(this.student);

          if (stdres?.tambon) {
            this.loadGetDistricts(stdres.tambon.district.province.provinceid);
            this.loadGetTambons(stdres.tambon.district.districtid);
            this.student.provinceid = stdres.tambon.district.province.provinceid;
            this.student.districtid = stdres.tambon.district.districtid;
            this.student.tambonid = stdres.tambon.tambonid;

            this.selectedpostcode = stdres.tambon.postcodemain;
            this.student.postcode = stdres.tambon.postcodemain;
          }
          if (stdres?.contacttambon) {
            this.loadGetContactDistricts(
              stdres.contacttambon.district.province.provinceid
            );
            this.loadGeContactTambons(stdres.contacttambon.district.districtid);
            this.student.contactprovinceid =
              stdres.contacttambon.district.province.provinceid;
            this.student.contactdistrictid = stdres.contacttambon.district.districtid;
            this.student.contacttambonid = stdres.contacttambon.tambonid;

            this.selectedcontactpostcode = stdres.contacttambon.postcodemain;
            this.student.contactpostcode = stdres.contacttambon.postcodemain;
          }
          if (response.data?.lookingforjob) {
            this.lookingforjob = response.data.lookingforjob;
          }
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },

    async loadGetProvinces() {
      try {
        const response = await masterDataProvider.loadGetProvinces();
        this.provinces = response.data;
        this.contactprovinces = response.data;
        this.domicile = response.data;
      } catch (err) {
        alert(err);
      }
    },
    async loadGetDistricts(provinceid) {
      await masterDataProvider
        .loadGetDistricts(provinceid)
        .then((response) => {
          this.districts = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetTambons(districtid) {
      await masterDataProvider
        .loadGetTambons(districtid)
        .then((response) => {
          this.tambons = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetContactDistricts(provinceid) {
      await masterDataProvider
        .loadGetDistricts(provinceid)
        .then((response) => {
          this.contactdistricts = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGeContactTambons(districtid) {
      await masterDataProvider
        .loadGetTambons(districtid)
        .then((response) => {
          this.contacttambons = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async genyear() {
      var year = new Date().getFullYear() + 543;
      for (var i = 0; i < 10; i++) this.yearvocationlist.push((year - i).toString());
    },
    async loadTitleName() {
      await masterDataProvider
        .getActiveTitleNames()
        .then((response) => {
          this.titlenamelist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadNationalities() {
      await masterDataProvider
        .getNationalities()
        .then((response) => {
          this.nationalitylist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadReligions() {
      await masterDataProvider
        .getReligions()
        .then((response) => {
          this.religionlist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadResumeStudentFile(oid) {
      await studentDataProvider
        .LoadResumeFile(oid)
        .then((res) => {
          this.listFileResume = res.data;
        })
        .catch((res) => {
          this.$toast.error("โหลดเรซูเม่ ล้มเหลว!" + res);
        });
    },

    async loadSalaryRanges() {
      masterDataProvider.loadSalaryRanges().then((response) => {
        this.SalaryRanges = response.data;
      });
    },
    async loadJobSegment() {
      masterDataProvider.getJobSegment().then((response) => {
        this.JobSegment = response.data;
      });
    },

    calculateAge(birthday) {
      if (!birthday) return "";
      console.log(birthday);
      const birthdayDate = moment(birthday, "YYYY-MM-DD");
      const age = moment().diff(birthdayDate, "years");
      return age;
    },
    generateJobChips() {
      const jobChips = [];
      //เพิ่มลง array แล้วแสดงผล
      if (this.lookingforjob.fulltime) {
        jobChips.push("งานประจำ (Full Time)");
      }
      if (this.lookingforjob.parttime) {
        jobChips.push("งานนอกเวลา (Part Time)");
      }
      if (this.lookingforjob.internship) {
        jobChips.push("ฝึกงาน");
      }
      if (this.lookingforjob.freelance) {
        jobChips.push("งานอิสระ (Freelance)");
      }
      if (this.lookingforjob.byday) {
        jobChips.push("งานจ้างรายวัน");
      }
      if (this.lookingforjob.temporary) {
        jobChips.push("งานจ้างชั่วคราว");
      }
      if (this.lookingforjob.workfromhome) {
        jobChips.push("รับงานไปทำที่บ้าน");
      }
      //กรณีไม่มีการเลือก
      if (jobChips.length === 0) {
        jobChips.push("ไม่มีการเลือก");
      }

      return jobChips;
    },

    getResumeDownloadLink(fileattachmentoid) {
      return `${this.host}/api/Student/DownloadFile?fileattachmentoid=${fileattachmentoid}`;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async UploadFile(event) {
      const file = event.target.files[0];
      if (file) {
        this.ImgStudentformData.append("File", file);
        this.ImgStudentformData.append("Oid", this.studentoid);

        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            resolve(e.target.result);
          };
          reader.readAsDataURL(file);
        }).then((data) => {
          this.uploadImgStudent();
          const strImage = data.replace(/^data:image\/[a-z]+;base64,/, "");
          this.student.base64thrumnail = strImage;
        });
      }
    },
    async uploadImgStudent() {
      studentDataProvider
        .uploadImageStudent(this.ImgStudentformData)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("อัปโหลดรูปภาพเรียบร้อย !");
            this.file = [];
          }
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว" + res);
        });
    },

    handleChangePath() {
      this.$router.push("/Student/StudentEditProfileView/" + this.studentoid);
    },

    openStudentEditProfileView(item) {
      const studentid = item.studentid;
      this.$router
        .push({
          name: "StudentEditProfileView",
          params: { studentid: studentid },
        })
        .catch((err) => {});
    },
  },

  async created() {
    if (this.$route.params.studentoid != undefined) {
      this.studentoid = this.$route.params.studentoid;
      await this.loadStudent(this.studentoid);
    } else {
      await this.loadCurrentStudent();
    }
    this.host = backendapiUrl;
    await this.loadJobSegment();

    await this.loadGetProvinces();
    await this.loadTitleName();
    await this.loadReligions();
    await this.loadNationalities();
    await this.genyear();
    await this.loadResumeStudentFile(this.studentoid);
    await this.loadSalaryRanges();
  },
};
</script>

<style scoped>
.rounded-circle {
  border-radius: 50%;
  overflow: hidden;
  width: 10vw;
  height: 10vw;
  max-width: 60px;
  max-height: 60px;
}
.display-center {
  display: flex;
  align-items: center;
}
.color-btnCard150 {
  background: linear-gradient(
    150deg,
    rgb(17, 168, 255) 5%,
    rgb(46, 126, 220) 45%,
    rgb(62, 83, 183) 100%
  );
}
.color-btnCard300 {
  background: linear-gradient(
    300deg,
    rgb(17, 168, 255) 5%,
    rgb(46, 126, 220) 45%,
    rgb(62, 83, 183) 100%
  );
}
</style>
