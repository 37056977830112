import TitleNamesDetailView from "@/views/TitleNames/TitleNamesDetailView.vue";
import TitleNamesListView from "@/views/TitleNames/TitleNamesListView.vue";

export default [
  {
    path: "/TitleNames",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/TitleNames/TitleNamesListView",
        name: "TitleNamesListView",
        component: TitleNamesListView,
        meta: {
          requiresAuth: true,
          nameTh: "คำนำหน้าชื่อ",
        },
      },
      {
        path: "/TitleNames/TitleNamesDetailView/:oid",
        name: "TitleNamesDetailView",
        component: TitleNamesDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดคำนำหน้าชื่อ",
        },
      },
    ],
  },
];
