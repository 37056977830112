<template>
  <div class="d-flex justify-center">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="12" md="12" sm="12" xs="12">
          <v-card>
            <v-card-text class="d-flex justify-center">
              <v-col cols="12" class="mt-0 pt-0">
                <div>
                  <v-tabs
                    v-model="tab"
                    active-class="active-tab white--text"
                    grow
                    slider-size="10"
                  >
                    <v-tabs-slider class="custom-slider"></v-tabs-slider>
                    <v-tab class="z-index-10" :ripple="false"
                      >ข้อมูลส่วนตัว</v-tab
                    >
                    <v-tab class="z-index-10" :ripple="false"
                      >ที่อยู่ / ข้อมูลติดต่อ</v-tab
                    >
                    <v-tab class="z-index-10" :ripple="false"
                      >ประเภทงานที่ต้องการ</v-tab
                    >
                    <v-tab class="z-index-10" :ripple="false">การศึกษา</v-tab>
                    <v-tab class="z-index-10" :ripple="false"
                      >ประวัติการทำงาน</v-tab
                    >
                    <v-tab class="z-index-10" :ripple="false">ผลงาน</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item class="my-5" eager>
                      <v-form ref="tabForm1" lazy-validation>
                        <v-row class="mt-5">
                          <v-col
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <div>
                              <v-card
                                style="border-radius: 15px"
                                class="mx-auto d-flex flex-column align-center pb-2 mb-5 mt-2"
                                variant="outlined"
                                max-width="250"
                              >
                                <label class="mt-3 mb-2"
                                  >ความสมบูรณ์ของประวัติ :</label
                                ><CompletionPercentage
                                  v-if="this.completionPercentage"
                                  :percen="this.completionPercentage"
                                ></CompletionPercentage
                              ></v-card>
                            </div>
                            <div class="d-flex justify-center">
                              <div>
                                <v-avatar color="grey" size="200">
                                  <v-img :src="avatarphoto"></v-img>
                                </v-avatar>
                              </div>
                            </div>
                            <v-row class="justify-center text-center"
                              ><v-btn
                                dark
                                @click="openFileInput"
                                prepend-icon="mdi-image-plus"
                                dense
                                class="ma-5 color-buton"
                              >
                                อัปโหลดรูปภาพ
                              </v-btn>

                              <input
                                type="file"
                                ref="fileInput"
                                accept="image/*"
                                style="display: none"
                                @change="UploadFile"
                            /></v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="9"
                            md="12"
                            sm="12"
                            class="mt-0 pt-0"
                          >
                            <v-row>
                              <v-col cols="12" sm="12" md="3" lg="3">
                                <h4 class="primary--text mb-2">คำนำหน้าชื่อ</h4>
                                <v-select
                                  filled
                                  background-color="input"
                                  label="คำนำหน้าชื่อ"
                                  :rules="[
                                    (v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ',
                                  ]"
                                  :items="titlenames"
                                  item-text="titlenameth"
                                  item-value="titlenameid"
                                  v-model="student.titlenameid"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" sm="12" md="4" lg="4">
                                <h4 class="primary--text mb-2">ชื่อจริง</h4>
                                <v-text-field
                                  background-color="input"
                                  filled
                                  clearable
                                  placeholder="ชื่อจริง"
                                  :rules="nameRules"
                                  v-model="student.firstname"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="5" lg="5">
                                <h4 class="primary--text mb-2">นามสกุล</h4>
                                <v-text-field
                                  background-color="input"
                                  filled
                                  clearable
                                  placeholder="นามสกุล"
                                  :rules="nameRules"
                                  v-model="student.surname"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">
                                  เลขประจำตัวประชาชน
                                </h4>
                                <v-text-field
                                  background-color="input"
                                  filled
                                  v-model="student.idcard"
                                  placeholder="เลขประจำตัวประชาชน"
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">
                                  วัน / เดือน / ปี (พ.ศ.) เกิด
                                </h4>
                                <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="student.birthday"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      ref="txtBirthday"
                                      :rules="[
                                        (v) => !!v || 'กรุณาระบุวันเกิด',
                                      ]"
                                      placeholder="วัน / เดือน / ปี (พ.ศ.) เกิด"
                                      v-model="formattedBirthday"
                                      v-bind="attrs"
                                      v-on="on"
                                      filled
                                      background-color="input"
                                    >
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    locale="th-TH"
                                    v-model="student.birthday"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="" @click="menu = false">
                                      ยกเลิก
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save(student.birthday)"
                                    >
                                      ตกลง
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">เพศ</h4>
                                <v-radio-group
                                  :rules="[(v) => !!v || 'กรุณาระบุเพศ']"
                                  v-model="student.gender"
                                  row
                                >
                                  <v-radio
                                    label="ชาย"
                                    color="blue"
                                    value="M"
                                  ></v-radio>
                                  <v-radio
                                    label="หญิง"
                                    color="pink"
                                    value="F"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">สถานะการสมรส</h4>
                                <v-radio-group
                                  :rules="[
                                    (v) => v != null || 'กรุณาระบุสถานะการสมรส',
                                  ]"
                                  v-model="student.marital"
                                  row
                                >
                                  <v-radio
                                    label="โสด"
                                    color="blue"
                                    :value="0"
                                  ></v-radio>
                                  <v-radio
                                    label="แต่งงาน"
                                    color="pink"
                                    :value="1"
                                  ></v-radio>
                                  <v-radio
                                    label="หย่าร้าง"
                                    color="green"
                                    :value="2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">สัญชาติ</h4>
                                <v-select
                                  filled
                                  background-color="input"
                                  placeholder="สัญชาติ"
                                  :items="nationalitylist"
                                  item-text="nationalityth"
                                  item-value="nationalityid"
                                  :rules="[
                                    (v) => v != null || 'กรุณาระบุสัญชาติ',
                                  ]"
                                  v-model="student.nationalityid"
                                ></v-select>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">ศาสนา</h4>
                                <v-select
                                  filled
                                  background-color="input"
                                  placeholder="ศาสนา"
                                  :items="religionlist"
                                  item-text="religionth"
                                  item-value="religionid"
                                  v-model="student.religionid"
                                  :rules="[(v) => !!v || 'กรุณาระบุศาสนา']"
                                ></v-select>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">ภูมิลำเนา</h4>
                                <v-select
                                  filled
                                  background-color="input"
                                  :items="domicile"
                                  item-value="provinceid"
                                  item-text="provincethai"
                                  placeholder="ภูมิลำเนา"
                                  v-model="student.domicile"
                                  :rules="[(v) => !!v || 'กรุณาระบุภูมิลำเนา']"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row v-if="student.gender == 'M'">
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">ส่วนสูง</h4>
                                <v-text-field
                                  background-color="input"
                                  filled
                                  clearable
                                  placeholder="ส่วนสูง (ซม.)"
                                  v-model="student.hight"
                                  :rules="hightStudendRules"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">น้ำหนัก</h4>
                                <v-text-field
                                  background-color="input"
                                  filled
                                  clearable
                                  placeholder="น้ำหนัก (กก.)"
                                  v-model="student.weight"
                                  :rules="weightStudendRules"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">
                                  สถานภาพทางทหาร
                                </h4>

                                <v-select
                                  filled
                                  background-color="input"
                                  v-model="student.military"
                                  :items="military"
                                  placeholder="สถานภาพทางทหาร"
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                            <v-row v-else>
                              <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">ส่วนสูง</h4>
                                <v-text-field
                                  background-color="input"
                                  filled
                                  clearable
                                  label="ส่วนสูง (ซม.)"
                                  v-model="student.hight"
                                  :rules="hightStudendRules"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                class="mt-0 pt-0"
                              >
                                <h4 class="primary--text mb-2">น้ำหนัก</h4>
                                <v-text-field
                                  background-color="input"
                                  filled
                                  clearable
                                  label="น้ำหนัก (กก.)"
                                  v-model="student.weight"
                                  :rules="weightStudendRules"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col> </v-row
                      ></v-form>
                    </v-tab-item>

                    <v-tab-item class="my-5" eager>
                      <v-form ref="tabForm2" lazy-validation v-show="tab === 1">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ที่อยู่
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-2">
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">บ้านเลขที่</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="student.housenumber"
                              :rules="[
                                (v) => !!v || 'กรุณาระบุรายละเอียดที่อยู่',
                              ]"
                              placeholder="บ้านเลขที่"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">หมู่ที่</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="student.moo"
                              placeholder="หมู่ที่/หมู่บ้าน/อาคาร"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">ซอย</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="student.soi"
                              placeholder="ซอย"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">ถนน</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="student.street"
                              placeholder="ถนน"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">จังหวัด</h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              v-model="student.provinceid"
                              :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                              v-on:change="changedprovince"
                              :items="provinces"
                              item-value="provinceid"
                              item-text="provincethai"
                              placeholder="จังหวัด"
                            ></v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">อำเภอ / เขต</h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              v-model="student.districtid"
                              :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                              v-on:change="changeddistrict"
                              :items="districts"
                              item-value="districtid"
                              item-text="districtthai"
                              placeholder="อำเภอ/เขต"
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">ตำบล / แขวง</h4>
                            <v-select
                              filled
                              background-color="input"
                              clearable
                              v-model="student.tambonid"
                              :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                              v-on:change="changedtambon"
                              :items="tambons"
                              item-value="tambonid"
                              item-text="tambonthai"
                              placeholder="ตำบล/แขวง"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">รหัสไปรษณีย์</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="selectedpostcode"
                              placeholder="รหัสไปรษณีย์"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ที่อยู่สามารถติดต่อได้
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-checkbox
                          v-model="isAddressDuplicated"
                          @change="duplicateAddress"
                          label="คัดลอกข้อมูลที่อยู่จากด้านบน"
                        >
                        </v-checkbox>
                        <v-row>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">บ้านเลขที่</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="student.contacthousenumber"
                              :rules="[(v) => !!v || 'กรุณาระบุข้อมูลติดต่อ']"
                              placeholder="บ้านเลขที่"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">หมู่ที่</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="student.contactmoo"
                              placeholder="หมู่ที่/หมู่บ้าน/อาคาร"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">ซอย</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="student.contactsoi"
                              placeholder="ซอย"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">ถนน</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              v-model="student.contactstreet"
                              placeholder="ถนน"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">จังหวัด</h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              v-model="student.contactprovinceid"
                              :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                              v-on:change="changedcontactprovince"
                              :items="contactprovinces"
                              item-value="provinceid"
                              item-text="provincethai"
                              placeholder="จังหวัด"
                              @click:clear="clearprovince"
                            ></v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">อำเภอ / เขต</h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              v-model="student.contactdistrictid"
                              :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                              v-on:change="changedcontactdistrict"
                              :items="contactdistricts"
                              item-value="districtid"
                              item-text="districtthai"
                              placeholder="อำเภอ/เขต"
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">ตำบล / แขวง</h4>
                            <v-select
                              filled
                              background-color="input"
                              clearable
                              v-model="student.contacttambonid"
                              :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                              v-on:change="changedcontacttambon"
                              :items="contacttambons"
                              item-value="tambonid"
                              item-text="tambonthai"
                              placeholder="ตำบล/แขวง"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">รหัสไปรษณีย์</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              v-model="selectedcontactpostcode"
                              placeholder="รหัสไปรษณีย์"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            lg="6"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">อีเมล</h4>
                            <WarningTextbox
                              v-model="student.email"
                              value="student.email"
                              text_name="อีเมล"
                              back_icon="mdi-alert-circle-outline"
                              text_warn="กรุณากรอก email ที่ใช้งานจริง เพื่อการยืนยันตัวตนในอนาคต"
                              :rules="emailRules"
                            ></WarningTextbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            lg="6"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">เบอร์โทร</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              :rules="phoneStudentRules"
                              v-model="student.tel"
                              placeholder="เบอร์โทร"
                            ></v-text-field>
                          </v-col> </v-row
                      ></v-form>
                    </v-tab-item>

                    <v-tab-item class="my-5" eager>
                      <v-form ref="tabForm3" lazy-validation v-show="tab === 2">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ประเภทงานที่ต้องการ
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="ml-1">
                          <!-- <PostedJobSwitch
                            v-if="student_oid"
                            :student_oid="student_oid"
                            @onupdatepost="UpdateStudent"
                            :form-ref="formRef"
                          ></PostedJobSwitch> -->
                          <div>
                            <v-col>
                              <v-switch
                                inset
                                @change="OnPostJobStatusChange"
                                v-model="student.publishprofilestatus"
                                :label="`สถานะการประกาศหางาน: ${
                                  student.publishprofilestatus
                                    ? 'เปิดใช้งาน'
                                    : 'ปิดใช้งาน'
                                }`"
                              >
                              </v-switch>
                            </v-col>
                            <v-col class="mt-0 pt-0">
                              <span style="color: red"
                                >*สถานะการประกาศหางาน หมายถึง
                                การอนุญาตและยินยอมให้สถานประกอบการสามารถสืบค้นเพื่อเข้าถึงข้อมูลส่วนบุคคลของผู้ประกาศหางาน
                                ตามที่จัดเก็บอยู่ในฐานข้อมูลของระบบ
                                เพื่อนำไปใช้ในการติดต่อและพิจารณาคุณสมบัติในการรับสมัครงานเท่านั้น</span
                              >
                            </v-col>
                          </div>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">
                              ประเภทงานที่สนใจลำดับ 1
                            </h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              v-model="lookingforjob.interestedjob1"
                              :items="JobSegment"
                              placeholder="ประเภทงานที่สนใจลำดับ 1"
                              :rules="
                                student.publishprofilestatus
                                  ? [
                                      (v) =>
                                        !!v || 'กรุณาเลือกประเภทงานที่สนใจ (1)',
                                    ]
                                  : []
                              "
                              item-text="name"
                              item-value="name"
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">
                              ประเภทงานที่สนใจลำดับ 2
                            </h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              v-model="lookingforjob.interestedjob2"
                              :items="JobSegment"
                              placeholder="ประเภทงานที่สนใจลำดับ 2"
                              item-text="name"
                              item-value="name"
                              :rules="
                                student.publishprofilestatus
                                  ? [
                                      (v) =>
                                        !!v || 'กรุณาเลือกประเภทงานที่สนใจ (2)',
                                    ]
                                  : []
                              "
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            class="mt-0 pt-0"
                          >
                            <h4 class="primary--text mb-2">
                              ประเภทงานที่สนใจลำดับ 3
                            </h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              v-model="lookingforjob.interestedjob3"
                              :items="JobSegment"
                              placeholder="ประเภทงานที่สนใจลำดับ 3"
                              item-text="name"
                              item-value="name"
                              :rules="
                                student.publishprofilestatus
                                  ? [
                                      (v) =>
                                        !!v || 'กรุณาเลือกประเภทงานที่สนใจ (3)',
                                    ]
                                  : []
                              "
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" class="mt-0 pt-0">
                            <h4 class="primary--text mb-2">สถานะการหางาน</h4>
                          </v-col>
                        </v-row>
                        <v-radio-group
                          v-model="lookingforjob.lookingforjobstatus"
                          row
                        >
                          <v-radio
                            class="mx-2"
                            label="ไม่ต้องการเรียนต่อและไม่หางาน"
                            color="primary"
                            :value="0"
                          ></v-radio>
                          <v-radio
                            class="mx-2"
                            label="ต้องการเรียนต่อ"
                            color="primary"
                            :value="1"
                          >
                          </v-radio>
                          <v-radio
                            class="mx-2"
                            label="ต้องการหางาน"
                            color="primary"
                            :value="2"
                          >
                          </v-radio>
                          <v-radio
                            class="mx-2"
                            label="ต้องการเรียนต่อและหางาน"
                            color="primary"
                            :value="3"
                          ></v-radio>
                        </v-radio-group>
                        <v-row class="mt-2">
                          <v-col cols="12" sm="12" class="mt-0 pt-0">
                            <h4 class="primary--text mb-2">
                              ลักษณะงานที่ต้องการ
                            </h4>
                          </v-col>
                          <v-col cols="12" sm="12" class="mt-0 pt-0">
                            <span
                              v-if="
                                jobTypeRules && student.publishprofilestatus
                              "
                              style="color: red"
                              >กรุณาระบุลักษณะงานที่ต้องการ</span
                            ></v-col
                          >
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <v-checkbox
                              v-model="lookingforjob.fulltime"
                              label="งานประจำ(Full Time)"
                              class="width_full"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <v-checkbox
                              v-model="lookingforjob.parttime"
                              label="งานนอกเวลา (Part Time)"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <v-checkbox
                              v-model="lookingforjob.internship"
                              label="ฝึกงาน"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <v-checkbox
                              v-model="lookingforjob.freelance"
                              label="งานอิสระ  (Freelance)"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <v-checkbox
                              v-model="lookingforjob.byday"
                              label="งานจ้างรายวัน"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <v-checkbox
                              v-model="lookingforjob.temporary"
                              label="งานจ้างชั่วคราว"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            class="mt-0 pt-0"
                          >
                            <v-checkbox
                              v-model="lookingforjob.workfromhome"
                              label="รับงานไปทำที่บ้าน"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            lg="6"
                            class="mt-2 pt-2"
                          >
                            <h4 class="primary--text mb-2">
                              ระดับเงินเดือนที่ต้องการ
                            </h4>
                            <v-select
                              filled
                              background-color="input"
                              clearable
                              v-model="lookingforjob.rangesalary"
                              :items="SalaryRanges"
                              item-value="salary_range"
                              item-text="salary_range"
                              placeholder="ระดับเงินเดือนที่ต้องการ"
                            ></v-select> </v-col
                        ></v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <h4 class="primary--text mb-2">เรซูเม่ / Resume</h4>
                            <v-list color="cardinfo">
                              <template v-for="(item, index) in listFileResume">
                                <v-list-item
                                  v-if="item"
                                  :key="index"
                                  class="px-0"
                                >
                                  <!-- <v-list-item-action class="px-0"> -->

                                  <v-btn
                                    :href="
                                      host +
                                      '/api/Student/DownloadFile?fileattachmentoid=' +
                                      item.fileattachment.fileattachmentoid
                                    "
                                    target="_blank"
                                    text
                                    color="primary"
                                    class="px-0"
                                  >
                                    <v-icon>mdi-file-certificate</v-icon>
                                    {{
                                      truncateFilename(
                                        item.fileattachment.filename
                                      )
                                    }}
                                    {{ item.fileattachment.extension }}
                                  </v-btn>
                                  <!-- </v-list-item-action> -->

                                  <v-btn
                                    color="red"
                                    text
                                    @click="
                                      DeleteResumeStudentFile(
                                        item.fileattachment.fileattachmentoid
                                      )
                                    "
                                  >
                                    <v-icon>mdi-close-box</v-icon>
                                  </v-btn>
                                </v-list-item>
                              </template>

                              <v-list-item v-if="listFileResume.length === 0">
                                <v-list-item-title>
                                  <v-file-input
                                    placeholder="เรซูเม่"
                                    chips
                                    v-model="ResumeFile"
                                    @change="onResumeChange()"
                                    filled
                                    background-color="input"
                                  />
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-col>
                        </v-row>
                        <!-- <v-row>
                          <v-col cols="12" sm="12" md="6" lg="6" class="mt-0 pt-0">
                            <h4 class="primary--text mb-2">เรซูเม่ / Resume</h4>
                            <v-list color="cardinfo">
                              <template v-for="(item, index) in listFileResume">
                                <v-list-item v-if="item" :key="index">
                                  <span>ไฟล์ เรซูเม่</span>
                                  <v-list-item-action>
                                    <v-btn
                                      :href="
                                        host +
                                        '/api/Student/DownloadFile?fileattachmentoid=' +
                                        item.fileattachment.fileattachmentoid
                                      "
                                      target="_blank"
                                      text
                                      color="primary"
                                    >
                                      <v-icon>mdi-file-certificate</v-icon>
                                      {{ item.fileattachment.filename }}
                                      {{ item.fileattachment.extension }}
                                    </v-btn>
                                  </v-list-item-action>

                                  <v-btn
                                    color="red"
                                    text
                                    @click="
                                      DeleteResumeStudentFile(
                                        item.fileattachment.fileattachmentoid
                                      )
                                    "
                                    ><v-icon>mdi-close-box</v-icon></v-btn
                                  >
                                </v-list-item>
                              </template>
                              <v-list-item-title v-if="listFileResume.length == 0">
                                <v-file-input
                                  placeholder="เรซูเม่"
                                  chips
                                  v-model="ResumeFile"
                                  @change="onResumeChange"
                                  dense
                                  filled
                                  background-color="input"
                                >
                                </v-file-input>
                              </v-list-item-title>
                            </v-list> </v-col
                        ></v-row>  -->
                      </v-form></v-tab-item
                    >

                    <v-tab-item class="my-5" eager>
                      <v-form ref="tabForm4" v-show="tab === 3">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ข้อมูลการศึกษา
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>

                        <EducationStudentDataTable
                          v-if="this.idcard"
                          :cardID="this.idcard"
                        ></EducationStudentDataTable>

                        <v-row class="mt-8">
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ประวัติการศึกษา
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>

                        <EducationStudentTable
                          v-if="studentoid"
                          :studentoid="studentoid"
                        ></EducationStudentTable>
                      </v-form>
                    </v-tab-item>

                    <v-tab-item class="my-5" eager>
                      <v-form ref="tabForm5" v-show="tab === 4">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ประวัติการทำงาน
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>

                        <ExperienceJobStudentTable
                          v-if="studentoid"
                          :studentoid="studentoid"
                        ></ExperienceJobStudentTable>
                      </v-form>
                    </v-tab-item>

                    <v-tab-item class="my-5" eager>
                      <v-form ref="tabForm6" v-show="tab === 5">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ผลงาน
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <!-- <v-card width="200px" height="200px">รางวัล</v-card> -->
                        <RewardStudentCard
                          v-if="student_oid"
                          :student_oid="student_oid"
                        >
                        </RewardStudentCard>
                      </v-form>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-col>
            </v-card-text>
          </v-card>

          <v-row class="my-5 justify-end">
            <v-btn class="mx-3" @click="cancel()">ยกเลิก</v-btn>
            <!-- <v-btn v-if="tab != 0" class="mx-3" @click="tab--">ย้อนกลับ</v-btn> -->
            <v-btn
              dark
              class="mx-3 color-buton"
              @click="save()"
              v-if="tab === 0 || tab === 1 || tab === 2"
              >บันทึก<v-icon class="ml-2"> mdi-content-save</v-icon></v-btn
            >
          </v-row>
        </v-col>

        <!-- โค้ดเก่าาาา -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  emailRules,
  idStudendRules,
  nameRules,
  hightStudendRules,
  phoneStudentRules,
  weightStudendRules,
} from "@/ValidationHelper.js";
import PostedJobSwitch from "@/components/Student/PostedJobSwitch.vue";
import { backendapiUrl } from "@/services/constants";
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import moment from "moment";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { RoleCodes } from "@/services/constants";
import EducationStudentTable from "@/components/Student/Education/EducationStudentTable.vue";
import ExperienceJobStudentTable from "@/components/Student/Experience/ExperienceJobStudentTable.vue";
import RewardStudentTable from "@/components/Student/Reward/RewardStudentTable.vue";
import RewardStudentCard from "@/components/Student/Reward/RewardStudentCard.vue";

import WarningTextbox from "@/components/common/WarningTextbox.vue";
import EducationStudentDataTable from "@/components/Student/Education/EducationStudentDataTable.vue";
import CompletionPercentage from "@/components/Profile/CompletionPercentage.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";

var studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "StudentEditProfileView",
  components: {
    TextFieldDatePicker,
    EducationStudentTable,
    EducationStudentDataTable,
    ExperienceJobStudentTable,
    WarningTextbox,
    RewardStudentTable,
    RewardStudentCard,
    PostedJobSwitch,
    CompletionPercentage,
  },
  data() {
    return {
      moment: moment,
      studentoid: "",
      idcard: "",
      student: [],
      lookingforjob: [],
      titlenamelist: [],
      nationalitylist: [],
      religionlist: [],
      domicile: [],
      provinces: [],
      districts: [],
      tambons: [],
      selectedpostcode: "",
      contactprovinces: [],
      contactdistricts: [],
      contacttambons: [],
      selectedcontactpostcode: "",
      military: [
        { text: "ยังไม่ผ่านการเกณฑ์ทหาร", value: 0 },
        { text: "ผ่านการเกณฑ์ทหาร", value: 1 },
        { text: "อยู่ระหว่างรับราชการทหาร", value: 2 },
        { text: "ได้รับการยกเว้น(สำเร็จหลักสูตรรักษาดินแดน)", value: 3 },
        {
          text: "ได้รับการยกเว้น(จับฉลากได้ใบดำหรือ ร่างกายไม่ได้ขนาด)",
          value: 4,
        },
      ],
      gpa_headers: [
        { text: "สถานศึกษา", value: "intitute" },
        { text: "ปีที่สมัคร", value: "yearregis" },
        { text: "ระดับชั้น", value: "level" },
        { text: "สาขาวิชา", value: "major" },
        { text: "สาขางาน", value: "job" },
        { text: "ปีการศึกษา	", value: "year" },
        { text: "เทอม", value: "semeter" },
        { text: "เกรดเฉลี่ย", value: "grade" },
        { text: "", value: "action" , sortable: false},
      ],
      yearvocationlist: [],
      JobSegment: [],
      gpa: [],
      menu: undefined,
      valid: true,
      file: [],
      ResumeFile: [],
      readers: [],
      ImgStudentformData: new FormData(),
      ResumeStudentformData: new FormData(),
      host: "",
      listFileResume: [],
      lookingforexperience: [],
      studentoid: "",
      SalaryRanges: [],
      show: false,
      student_oid: "",
      completionPercentage: "",
      emailRules: emailRules,
      idStudendRules: idStudendRules,
      nameRules: nameRules,
      hightStudendRules: hightStudendRules,
      phoneStudentRules: phoneStudentRules,
      weightStudendRules: weightStudendRules,
      tab: 0,
      file: null,
      isAddressDuplicated: false,
      titlenames: undefined,
      postjobstatus: false,
      jobTypeRules: false,
      lastValidTab: 0,
    };
  },
  computed: {
    avatarphoto() {
      return this.student.base64thrumnail
        ? "data:image/jpeg;base64," + this.student.base64thrumnail
        : require("@/assets/user-img.jpg");
    },
    formattedBirthday: {
      get() {
        return this.student.birthday != ""
          ? moment(this.student.birthday)
              .add(543, "year")
              .locale("TH")
              .format("LL")
          : "";
      },
      set(value) {
        console.log(this.student.birthday);
        this.student.birthday = value
          ? moment(value, "LL").subtract(543, "year").toDate()
          : null;
      },
    },
    formRef() {
      return this.$refs.form;
    },
  },
  watch: {
    "lookingforjob.interestedjob1": function (newVal, oldVal) {
      if (newVal) {
        let index = this.JobSegment.findIndex((p) => p.name == newVal);
        this.JobSegment[index].disabled = true;
      }
      if (oldVal) {
        let index = this.JobSegment.findIndex((p) => p.name == oldVal);
        this.JobSegment[index].disabled = false;
      }
    },
    "lookingforjob.interestedjob2": function (newVal, oldVal) {
      if (newVal) {
        let index = this.JobSegment.findIndex((p) => p.name == newVal);
        this.JobSegment[index].disabled = true;
      }
      if (oldVal) {
        let index = this.JobSegment.findIndex((p) => p.name == oldVal);
        this.JobSegment[index].disabled = false;
      }
    },
    "lookingforjob.interestedjob3": function (newVal, oldVal) {
      if (newVal) {
        let index = this.JobSegment.findIndex((p) => p.name == newVal);
        this.JobSegment[index].disabled = true;
      }
      if (oldVal) {
        let index = this.JobSegment.findIndex((p) => p.name == oldVal);
        this.JobSegment[index].disabled = false;
      }
    },
    lookingforjob: {
      handler() {
        // ตรวจสอบว่ามีอย่างน้อยหนึ่งตัวเลือกถูกเลือก
        this.jobTypeRules = !(
          this.lookingforjob.fulltime ||
          this.lookingforjob.parttime ||
          this.lookingforjob.internship ||
          this.lookingforjob.freelance ||
          this.lookingforjob.byday ||
          this.lookingforjob.temporary ||
          this.lookingforjob.workfromhome
        );
      },
      deep: true, // ใช้ deep เพื่อให้ watcher ตรวจสอบทุกการเปลี่ยนแปลงใน object
    },
    async tab(newTab, oldTab) {
      if (this.validationInProgress || oldTab === newTab) return;
      const formMapping = {
        0: {
          formRef: "tabForm1",
          errorMsg: "กรุณากรอกข้อมูลส่วนตัวให้ครบถ้วน",
        },
        1: {
          formRef: "tabForm2",
          errorMsg: "กรุณากรอกข้อมูลที่อยู่/ข้อมูลติดต่อให้ครบถ้วน",
        },
        2: {
          formRef: "tabForm3",
          errorMsg: "กรุณากรอกประเภทงานที่ต้องการให้ครบถ้วน",
        },
      };

      if (this.lastValidTab !== newTab) {
        const currentForm = formMapping[this.lastValidTab];
        console.log(oldTab, "oldTab");
        if (currentForm) {
          const isValid = await this.validateForm(
            currentForm.formRef,
            currentForm.errorMsg
          );
          if (!isValid) {
            this.tab = this.lastValidTab;
            return;
          }
        }
        this.tab = newTab;
        this.lastValidTab = newTab;
      }
    },
  },
  methods: {
    async loadStudent(studentoid = "") {
      await studentDataProvider
        .loadStudentByOid(studentoid)
        // .loadCurrentStudentProfile()
        .then(async (dataStudent) => {
          this.student = dataStudent.data;
          this.studentoid = dataStudent.data.oid;
          this.student_oid = dataStudent.data.oid;

          if (this.student?.tambon) {
            this.loadGetDistricts(
              this.student.tambon.district.province.provinceid
            );
            this.loadGetTambons(this.student.tambon.district.districtid);
            this.student.provinceid =
              this.student.tambon.district.province.provinceid;
            this.student.districtid = this.student.tambon.district.districtid;
            this.student.tambonid = this.student.tambon.tambonid;

            this.selectedpostcode = this.student.tambon.postcodemain;
            this.student.postcode = this.student.tambon.postcodemain;
          }
          if (this.student?.contacttambon) {
            this.loadGetContactDistricts(
              this.student.contacttambon.district.province.provinceid
            );
            this.loadGeContactTambons(
              this.student.contacttambon.district.districtid
            );
            this.student.contactprovinceid =
              this.student.contacttambon.district.province.provinceid;
            this.student.contactdistrictid =
              this.student.contacttambon.district.districtid;
            this.student.contacttambonid = this.student.contacttambon.tambonid;

            this.selectedcontactpostcode =
              this.student.contacttambon.postcodemain;
            this.student.contactpostcode =
              this.student.contacttambon.postcodemain;
          }
          if (this.student.lookingforjob) {
            this.lookingforjob = this.student.lookingforjob;
          }

          await this.loadResumeStudentFile(this.studentoid);
        })
        .catch((err) => {});
      this.idcard = this.student.idcard;
    },
    async loadGetProvinces() {
      await masterDataProvider
        .loadGetProvinces()
        .then((response) => {
          this.provinces = response.data;
          this.contactprovinces = response.data;
          this.domicile = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetDistricts(provinceid) {
      await masterDataProvider
        .loadGetDistricts(provinceid)
        .then((response) => {
          this.districts = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetTambons(districtid) {
      await masterDataProvider
        .loadGetTambons(districtid)
        .then((response) => {
          this.tambons = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetContactDistricts(provinceid) {
      await masterDataProvider
        .loadGetDistricts(provinceid)
        .then((response) => {
          this.contactdistricts = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGeContactTambons(districtid) {
      await masterDataProvider
        .loadGetTambons(districtid)
        .then((response) => {
          this.contacttambons = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    // async UploadFile() {
    //   this.ImgStudentformData.append("File", this.file);
    //   this.ImgStudentformData.append("Oid", this.studentoid);
    //   var fileToLoad = this.file;
    //   new Promise((resolve) => {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       resolve(e.target.result);
    //     };
    //     reader.readAsDataURL(fileToLoad);
    //   }).then((data) => {
    //     this.uploadImgStudent();
    //     var strImage = data.replace(/^data:image\/[a-z]+;base64,/, "");
    //     this.student.base64thrumnail = strImage;
    //   });
    // },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    async UploadFile(event) {
      this.file = event.target.files[0];
      this.ImgStudentformData.append("File", this.file);
      this.ImgStudentformData.append("Oid", this.studentoid);
      var fileToLoad = this.file;
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        this.uploadImgStudent();
        var strImage = data.replace(/^data:image\/[a-z]+;base64,/, "");
        this.student.base64thrumnail = strImage;
      });
    },
    async save() {
      const formRefs = ["tabForm1", "tabForm2", "tabForm3"];
      let isValid = true;

      for (let i = 0; i < formRefs.length; i++) {
        let form = this.$refs[formRefs[i]];
        if (form && typeof form.validate === "function") {
          // Check if the ref exists and has a validate method
          let validate = await form.validate();
          if (!validate) {
            isValid = false;
            this.tab = i; // Move to the tab with invalid form
            this.$toast.error("กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน");
            break;
          }
        } else {
          console.error(
            `Form reference ${formRefs[i]} not found or does not have validate method`
          );
          isValid = false;
          break;
        }
      }

      if (isValid) {
        this.$store.dispatch("showLoading");
        try {
          const isFormTab3Valid = this.$refs.tabForm3.validate();
          if (isFormTab3Valid && !this.jobTypeRules) {
            this.student.lookingforjob = this.lookingforjob;
            await this.UpdateStudent();
            this.$router.push({ path: "/SchoolStudent/StudentDetailOverview" });
          } else {
            console.log(this.student.publishprofilestatus);

            if (this.student.publishprofilestatus) {
              this.$toast.error("กรุณากรอกข้อมูลประเภทงานที่ต้องการครบถ้วน");
              this.tab = 2;
            } else {
              await this.UpdateStudent();
              this.$router.push({
                path: "/SchoolStudent/StudentDetailOverview",
              });
            }
          }
        } catch (error) {
          console.error("Error during update:", error);
          this.$toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        } finally {
          this.$store.dispatch("hideLoading");
          console.log("Save process completed.");
        }
      }
    },
    async validateForm(formRef, errorMsg) {
      const form = this.$refs[formRef];
      if (form) {
        const isValid = await form.validate();
        if (!isValid) {
          this.$toast.error(errorMsg);
        }
        return isValid;
      }
      return false;
    },
    cancel() {
      this.$router.push({ path: "/SchoolStudent/StudentDetailOverview" });
    },
    async UpdateStudent() {
      this.$store.dispatch("showLoading");
      console.log(this.student);
      studentDataProvider
        .UpdateStudent(this.student)
        .then((res) => {
          this.$store.dispatch({ type: "doSetIsLoadingProfile" });
          this.$toast.success("บันทึกข้อมูลสำเร็จ");
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว !!!" + res);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },
    async duplicateAddress() {
      if (this.isAddressDuplicated) {
        if (this.student.provinceid) {
          this.loadGetContactDistricts(this.student.provinceid);
        }
        if (this.student.districtid) {
          this.loadGeContactTambons(this.student.districtid);
        }

        this.student.contacthousenumber = this.student.housenumber;
        this.student.contactmoo = this.student.moo;
        this.student.contactsoi = this.student.soi;
        this.student.contactstreet = this.student.street;
        this.student.contactprovinceid = this.student.provinceid;
        this.student.contactdistrictid = this.student.districtid;
        this.student.contacttambonid = this.student.tambonid;
        this.student.contactpostcode = this.student.postcode;
        this.selectedcontactpostcode = this.selectedpostcode;
        this.$toast.success("ทำการดึงข้อมูลเรียบร้อย");
      } else {
        this.student.contacthousenumber = "";
        this.student.contactmoo = "";
        this.student.contactsoi = "";
        this.student.contactstreet = "";
        this.student.contactprovinceid = null;
        this.student.contactdistrictid = null;
        this.student.contacttambonid = null;
        this.student.contactpostcode = "";
        this.selectedcontactpostcode = "";
      }
    },
    async changedprovince(provinceid) {
      this.student.districtid = "";
      this.student.tambonid = "";
      this.student.postcode = "";
      await this.loadGetDistricts(provinceid);
    },
    async changeddistrict(districtid) {
      this.student.tambonid = "";
      this.student.postcode = "";
      await this.loadGetTambons(districtid);
    },
    async changedtambon(tambonid) {
      var filtertambon = await this.tambons.filter(function (el) {
        return el.tambonid == tambonid;
      });
      this.selectedpostcode = await filtertambon[0].postcodemain;
      this.student.postcode = this.selectedpostcode;
    },
    async changedcontactprovince(provinceid) {
      this.student.contactdistrictid = "";
      this.student.contacttambonid = "";
      this.student.contactpostcode = "";
      await this.loadGetContactDistricts(provinceid);
    },
    async changedcontactdistrict(districtid) {
      this.student.contacttambonid = "";
      this.student.contactpostcode = "";
      await this.loadGeContactTambons(districtid);
    },
    async changedcontacttambon(tambonid) {
      var filtertambon = this.contacttambons.filter(function (el) {
        return el.tambonid == tambonid;
      });
      this.selectedcontactpostcode = filtertambon[0].postcodemain;
      this.student.contactpostcode = this.selectedcontactpostcode;
    },
    async genyear() {
      var year = new Date().getFullYear() + 543;
      for (var i = 0; i < 10; i++)
        this.yearvocationlist.push((year - i).toString());
    },

    async loadNationalities() {
      await masterDataProvider
        .getNationalities()
        .then((response) => {
          this.nationalitylist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadReligions() {
      await masterDataProvider
        .getReligions()
        .then((response) => {
          this.religionlist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async uploadImgStudent() {
      studentDataProvider
        .uploadImageStudent(this.ImgStudentformData)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("อัปโหลดรูปภาพเรียบร้อย !");
            this.file = [];
          }
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว" + res);
        });
    },
    truncateFilename(filename, maxLength = 20) {
      if (filename.length > maxLength) {
        return filename.substring(0, maxLength) + "...";
      }
      return filename;
    },
    // async onResumeChange() {
    //   this.ResumeStudentformData.append("Oid", this.studentoid);
    //   this.ResumeStudentformData.append("File", this.ResumeFile);

    //   await studentDataProvider
    //     .uploadResumeFile(this.ResumeStudentformData)
    //     .then((result) => {
    //       this.$toast.success("อัปโหลด เรซูเม่ เรียบร้อย!");
    //       this.ResumeFile = [];
    //       this.loadResumeStudentFile(this.studentoid);
    //     })
    //     .catch((result) => {
    //       this.$toast.error("ล้มเหลว!");
    //     });
    // },
    async onResumeChange() {
      if (!this.ResumeFile) {
        this.$toast.error("กรุณาเลือกไฟล์เรซูเม่ก่อน!");
        return;
      }

      this.ResumeStudentformData = new FormData();

      this.ResumeStudentformData.append("Oid", this.studentoid);
      this.ResumeStudentformData.append("File", this.ResumeFile);

      try {
        const result = await studentDataProvider.uploadResumeFile(
          this.ResumeStudentformData
        );
        this.$toast.success("อัปโหลด เรซูเม่ เรียบร้อย!");
        this.ResumeFile = null; // รีเซ็ต ResumeFile ให้เป็น null
        this.loadResumeStudentFile(this.studentoid);
      } catch (error) {
        console.error("Error uploading resume:", error);
        this.$toast.error("ล้มเหลวในการอัปโหลด!");
      }
    },

    async loadResumeStudentFile(oid) {
      await studentDataProvider
        .LoadResumeFile(oid)
        .then((res) => {
          this.listFileResume = res.data;
        })
        .catch((res) => {
          this.$toast.error("โหลดเรซูเม่ ล้มเหลว!" + res);
        });
    },
    async DeleteResumeStudentFile(oid) {
      if (confirm("ต้องการลบเรซูเม่หรือไม่ ?")) {
        await studentDataProvider
          .DeleteResumeFile(oid)
          .then((res) => {
            this.listFileResume = [];
            this.$toast.success("ลบเรซูเม่ เรียบร้อย!");
          })
          .catch((res) => {
            this.$toast.error("ล้มเหลว!" + res);
          });
      }
    },
    async loadSalaryRanges() {
      masterDataProvider.loadSalaryRanges().then((response) => {
        this.SalaryRanges = response.data;
      });
    },
    async loadJobSegment() {
      masterDataProvider.getJobSegment().then((response) => {
        this.JobSegment = response.data;
      });
    },
    async clearprovince() {
      this.student.contactprovinceid = "";
    },
    async loadCompletionPercentage() {
      await studentDataProvider
        .getCompletionPercentage(this.studentoid)
        .then((result) => {
          this.completionPercentage = result.data;
        });
    },
    async loadTitleNames() {
      await masterDataProvider
        .getActiveTitleNames()
        .then((response) => {
          this.titlenames = response.data;
          // console.log(this.titlenamelist)
        })
        .catch((err) => {
          alert(err);
        });
    },
    async OnPostJobStatusChange() {
      const formRefs = ["formTab1", "formTab2"];
      let isValid = true;

      for (let i = 0; i < formRefs.length; i++) {
        if (this.$refs[formRefs[i]]) {
          let validate = await this.$refs[formRefs[i]].validate();

          if (!validate) {
            isValid = false;
            this.tab = i;
            this.$toast.error("กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน");
            this.student.publishprofilestatus = false;
            break;
          }

          if (validate) {
            continue;
          }
        }
      }

      if (isValid) {
        // this.student.publishprofilestatus = true;
        console.log(this.student.publishprofilestatus);
        if (this.student.publishprofilestatus) {
          const isFormTab3Valid = this.$refs.formTab3.validate();
          console.log(isFormTab3Valid);
          if (!isFormTab3Valid) {
            // this.$toast.error("กรุณากรอกข้อมูลประเภทงานที่ต้องการครบถ้วน");
            this.tab = 2;
          }
        }
      }
    },
  },

  async created() {
    this.$store.dispatch("showLoading");
    this.studentoid = this.$route.params.oid;
    console.log(this.studentoid);
    this.host = backendapiUrl;
    await this.loadJobSegment();
    await this.loadStudent(this.studentoid);

    await this.loadGetProvinces();
    await this.loadReligions();
    await this.loadNationalities();
    await this.genyear();
    await this.loadSalaryRanges();
    await this.loadCompletionPercentage();
    await this.loadTitleNames();
    this.$store.dispatch("hideLoading");
  },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.z-index-10 {
  z-index: 10;
}
.v-tabs-bar {
  position: relative;
}

.active-tab {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  z-index: 10;
}

.v-tab {
  transition: all 0.3s ease; /* ทำให้การเปลี่ยนแปลงมีความนุ่มนวล */
}

.v-tabs-bar::before {
  display: block;
  content: "";

  background-color: black;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  z-index: 0;
}
.v-tabs-bar::after {
  display: block;
  content: "";

  background: rgba(33, 150, 243, 1);
  background: -moz-linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c32294",endColorstr="#fd2da8",GradientType=1);
  mix-blend-mode: lighten;
  position: absolute;
  bottom: -40px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.v-slide-group__wrapper {
  /* overflow: visible; */
  contain: unset;
}
.custom-slider {
  bottom: -40px;
  position: relative;
  background-color: transparent;
}
.custom-slider::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -78px;
  height: 48px;
  background-color: black;
  border-radius: 50px;
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
.custom-slider::after {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
}
</style>
