import { render, staticRenderFns } from "./SchoolDetailView.vue?vue&type=template&id=7b870ecf&scoped=true"
import script from "./SchoolDetailView.vue?vue&type=script&lang=js"
export * from "./SchoolDetailView.vue?vue&type=script&lang=js"
import style0 from "./SchoolDetailView.vue?vue&type=style&index=0&id=7b870ecf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b870ecf",
  null
  
)

export default component.exports