import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { CreateAdministratorProfile_Request } from "@/models/AdministratorProfiles/CreateAdministratorProfile_Request";
import { UpdateAdministratorProfile_Request } from "@/models/AdministratorProfiles/UpdateAdministratorProfile_Request";

export class AdministratorProfileDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  getAdministratorProfiles() {
    const header = create_default_request_header();
    const endpointUrl =
      "/api/AdministratorProfile/GetAdministratorProfiles";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  getAdministratorProfile(oid = "") {
    const header = create_default_request_header();
    const endpointUrl =
      "/api/AdministratorProfile/GetAdministratorProfile/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  createAdministratorProfile(
    createAdministratorProfile_Request = new CreateAdministratorProfile_Request()
  ) {
    const header = create_default_request_header();
    const data = JSON.stringify(createAdministratorProfile_Request);
    const endpointUrl =
      "/api/AdministratorProfile/CreateAdministratorProfile";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, createAdministratorProfile_Request, header);
  }

updateAdministratorProfile(
    updateAdministratorProfile = new UpdateAdministratorProfile_Request()
  ) {
    const header = create_default_request_header();
    const data = JSON.stringify(updateAdministratorProfile);
    const endpointUrl =
      "/api/AdministratorProfile/UpdateAdministratorProfile";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, updateAdministratorProfile, header);
  }

  deleteAdministratorProfile(oid = ''){
    const header = create_default_request_header();
    const endpointUrl =
      "/api/AdministratorProfile/DeleteAdministratorProfile/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL,null, header);
  }
}
