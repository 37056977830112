import axios from "axios"
import { create_default_request_header } from "./constants"
import { createEndpointUrl } from "@/common/api_connectivity_helper"
import { GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request"
import { GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource_Request"
import { GetGraduatedStudentJobTrackingReport_EmploymentStatusAsRegionDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTracking_EmploymentStatusAsRegionDashboardDataSource_Request"
import { GetGraduatedStudent_DashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudent_DashboardDataSource_Request"
export class DashboardDataSourceProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL
  }

  GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource(
    request = GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request()
  ) {
    const headers = create_default_request_header()
    var endpointPath =
      "/api/DashboardDataSource/GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource"
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath)
    var data = JSON.stringify(request)
    return axios.post(combinedURL, data, headers)
  }

  GetGraduatedStudentJobTrackingReport_EmploymentStatusAsRegionDashboardDataSource(
    request = GetGraduatedStudentJobTrackingReport_EmploymentStatusAsRegionDashboardDataSource_Request()
  ) {
    const headers = create_default_request_header()
    var endpointPath =
      "/api/DashboardDataSource/GetGraduatedStudentJobTrackingReport_EmploymentStatusAsRegionDashboardDataSource"
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath)
    var data = JSON.stringify(request)
    return axios.post(combinedURL, data, headers)
  }

  GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource(
    request = GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource_Request()
  ) {
    const headers = create_default_request_header()
    var endpointPath =
      "/api/DashboardDataSource/GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource"
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath)
    var data = JSON.stringify(request)
    return axios.post(combinedURL, data, headers)
  }

  GetGraduatedStudent_DashboardDataSource(
    request = new GetGraduatedStudent_DashboardDataSource_Request()
  ) {
    const headers = create_default_request_header()
    var endpointPath =
      "/api/DashboardDataSource/GetGraduatedStudent_DashboardDataSource"
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath)
    var data = JSON.stringify(request)
    return axios.post(combinedURL, data, headers)
  }

  GetPercentJobTrackingByRound(oid) {
    const headers = create_default_request_header()
    var endpointPath =
      "/api/DashboardDataSource/GetPercentJobTrackingByRound/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath)
    return axios.get(combinedURL, headers)
  }
}
