import axios from "axios";
import { create_default_request_header } from "./constants";
import { create_default_request_header_for_blob_response } from  "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";

export class ReportServerProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  getReportExport(reportName = "", exportFileType = "", reportParameter = "") {
    let obj = {
      'ReportName': reportName,
      'ExportFileType': exportFileType,
      'ReportParameters': reportParameter
    };
    let data = JSON.stringify(obj);
    const header = create_default_request_header_for_blob_response();
    const endpointUrl =
      "api/ReportExport/GetReport";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    // return axios.post(combinedURL, { method:'post' ,data : data, responseType: "blob" });
    return axios.post(combinedURL, data, header);
  }

  getReportByQueue(reportName = "", exportFileType = "", reportParameter = "",applicationUserOid = '') {
    let obj = {
      'ReportName': reportName,
      'ExportFileType': exportFileType,
      'ReportParameters': reportParameter,
      'ApplicationUser_Oid' : applicationUserOid,
    };
    let data = JSON.stringify(obj);
    const header = create_default_request_header_for_blob_response();
    const endpointUrl =
      "api/ReportExport/GetReportByQueue";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    // return axios.post(combinedURL, { method:'post' ,data : data, responseType: "blob" });
    return axios.post(combinedURL, data, header);
  }

  getReportFromCache(getReportRequest){
    const data = JSON.stringify(getReportRequest);
    const header = create_default_request_header_for_blob_response();
    const endpointUrl =
      "api/ReportExport/GetReportFromCache";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    // return axios.post(combinedURL, { method:'post' ,data : data, responseType: "blob" });
    return axios.post(combinedURL, data, header);
  }
}
