<template>
  <v-dialog v-model="VerifyManagerDetailView_Dialog" width="850">
    <v-card class="pa-10">
      <h2 class="primary--text pt-5">รายละเอียดผู้บริหาร</h2>
      <v-divider class="mt-4 mb-5 mx-0"></v-divider>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              label="สถานศึกษา"
              v-model="manager.schoolid"
              :items="schools"
              item-text="schoolname"
              item-value="schoolid"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="align-center" height="10">
          <v-col cols="12">
            <v-autocomplete
              v-model="manager.titlename_oid"
              label="เลือกคำนำหน้า"
              item-text="titlenameth"
              item-value="oid"
              :items="titlename"
              :rules="[(v) => !!v || 'กรุณาเลือกคำนำหน้า']"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="manager.name"
              :rules="[(v) => !!v || 'กรุณาระบุ ชื่อ']"
              label="ชื่อ"
              required
              background-color="input"
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="manager.surname"
              :rules="[(v) => !!v || 'กรุณาระบุ นามสกุล']"
              label="นามสกุล"
              required
              background-color="input"
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="manager.position"
              :rules="[(v) => !!v || 'กรุณาระบุ ตำแหน่ง']"
              label="ตำแหน่ง"
              required
              background-color="input"
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn @click="onCancelClick">ยกเลิก</v-btn>
            <v-btn class="ml-3 color-button2" @click="onButtonClick">
              ตกลง <v-icon>mdi-content-save</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import { VerifyManagerDataProvider } from "@/services/VerifyManagerDataProvider";
import DialogCreateVerifyManagerForm from "@/components/VerifyManager/DialogCreateVerifyManagerForm.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { title } from "devexpress-reporting/dx-webdocumentviewer";

const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const verifyManagerDataProvider = new VerifyManagerDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "DialogCreateVerifyManagerForm",
  props: { VerifyManagerDetailView_Dialog: Boolean, oid: String },
  data() {
    return {
      titlename: [],
      schools: [],
      selected_schoolID: "",
      valid: true,
      manager: [],
    };
  },
  watch: {
    VerifyManagerDetailView_Dialog(value) {
      if (value) {
        this.loadTitleName();
        this.loadSchools();
        if (this.oid != "-1") {
          verifyManagerDataProvider
            .loadgetVerifyManager(this.oid)
            .then((res) => {
              this.manager.oid = res.data.oid;
              this.manager = res.data;
              this.manager.titlename_oid = res.data.titlename?.oid;
            });
        } else {
          this.manager = [];
          this.manager.oid = "-1";
        }
      }
    },
  },
  methods: {
    async loadTitleName() {
      await masterDataProvider.getActiveTitleNames().then((result) => {
        this.titlename = result.data;
      });
    },
    async loadSchools() {
      await schoolDataProvider
        .getSchoolsBy_CurrentApplicationUser()
        .then((result) => {
          this.schools = result.data;
          if (this.schools.length === 1) {
            this.manager.schoolid = this.schools[0].schoolid;
          }
        });
    },
    onButtonClick() {
      if (this.$refs.form.validate()) {
        this.$emit("closeDialog", this.manager, true);
      }
    },
    onCancelClick() {
      this.$emit("closeDialog", "", false);
    },
  },
  async created() {
    await this.loadSchools();
    await this.loadTitleName();
  },
  mounted() {},
  components: { TextFieldDatePicker },
};
</script>

<style></style>
