import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
import { SaveInterestedJobPostingBookmark_Request } from '@/models/StudentUserBookmarks/SaveInterestedJobPostingBookmark_Request';
import {  DeleteInterestedInJobPostingBookmark_Request } from '@/models/StudentUserBookmarks/DeleteInterestedInJobPostingBookmark_Request';

export class StudentUserBookmarkDataProvider 
{
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }   

    getInterestedJobPostingBookmarks(){
        const header = create_default_request_header()
        const endpointUrl = "/api/StudentJobPostingBookmark/GetInterestedJobPostingBookmarks";
        const data = JSON.stringify(request);
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.get(combinedURL, header)
    }

    getInterestedJobPostingBookmarks_As_JobPostingData(){
        const header = create_default_request_header()
        const endpointUrl = "/api/StudentJobPostingBookmark/getInterestedJobPostingBookmarks_As_JobPostingData";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.get(combinedURL, header)
    }

    saveInterestedJobPostingBookmark(request = new SaveInterestedJobPostingBookmark_Request){
        const header = create_default_request_header()
        const endpointUrl = "/api/StudentJobPostingBookmark/SaveInterestedJobPostingBookmark";
        const data = JSON.stringify(request);
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.post(combinedURL,data, header)
    }

    isJobPostingExistInBookmark(jobposting_oid = ''){
        const header = create_default_request_header()
        const endpointUrl = "/api/StudentJobPostingBookmark/IsJobPostingExistInBookmark/" + jobposting_oid;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.post(combinedURL,null, header)
    }

    DeleteInterestedInJobPostingBookmark(request = new DeleteInterestedInJobPostingBookmark_Request){
        const header = create_default_request_header()
        const endpointUrl = "/api/StudentJobPostingBookmark/DeleteInterestedInJobPostingBookmark";
        const data = JSON.stringify(request);
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.post(combinedURL,data, header)
    }
}