<template>
    <label :style="labelStyle">{{ status.statusname }}</label>
</template>

<script>
export default {
    name: "JobTrackingReportStatus",
    data() {
        return {
            labelColor: undefined
        }
    },
    props: ['status'],
    methods: {},
    computed: {
        labelStyle() {
            if (this.status.statuscode == "CREATED") {
                return {
                    "color": "gray"
                }
            } else if (this.status.statuscode == "FILLING_STUDENT_DATA") {
                return {
                    "color": "blue"
                }
            } else {
                return {
                    "color": "black"
                }
            }
        }
    }
}
</script>

