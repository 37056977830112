<template>
  <div class="d-flex justify-center">
    <v-card class="ma-8 pa-5 custom-container">
      <v-row class="mx-1 my-1">
        <v-col align-self="center" class="pa-0" cols="12">
          <h3 class="primary--text">
            <v-icon color="primary">mdi-bell</v-icon> การแจ้งเตือน
          </h3>
        </v-col>
      </v-row>

      <v-row class="justify-center align-center" id="flex-search">
        <v-col xs="12" sm="12" md="12" lg="10" xl="10">
          <v-text-field
            class="flex-full-width mb-1 custom-searchautocomplete"
            prepend-inner-icon="mdi-magnify"
            label="ค้นหา"
            v-model="search"
            rounded
          ></v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="12" lg="2" xl="2" class="d-flex justify-end">
          <v-btn
            @click="desc = !desc"
            style="background-color: rgba(240, 248, 255, 0)"
            :elevation="0"
          >
            <v-icon color="primary" style="cursor: pointer"
              >mdi-swap-vertical-bold</v-icon
            >
            <v-col class="mt-2 primary--text">จัดเรียง</v-col>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-column justify-center mb-3">
        <v-col class="justify-center">
          <v-sheet min-height="400">
            <v-expansion-panels v-model="panel" focusable>
              <v-expansion-panel
                v-for="item in allNoti"
                :key="item.notificationid"
                :disabled="disabled"
                @click="openPanel(item.notificationid)"
              >
                <v-expansion-panel-header
                  :style="
                    item.isread
                      ? 'background: #ffffff9c'
                      : 'background: rgb(250, 201, 127)'
                  "
                  class="mt-1"
                >
                  <div class="d-flex justify-space-between">
                    <div>
                      <a>
                        <v-icon
                          :color="IconTypeMessage(item.notificationeventkey)"
                          >mdi-circle</v-icon
                        >
                      </a>

                      {{ item.title }}
                    </div>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <generated-report-notify
                    v-if="item.notificationeventkey == 'GeneratedReportNotify'"
                    :notification="item"
                  ></generated-report-notify>

                  <div
                    v-if="item.notificationeventkey != 'GeneratedReportNotify'"
                  >
                    <v-row class="ma-1">
                      <label v-html="item.message" class="ql-editor"></label>
                    </v-row>
                    <v-row class="ma-1 justify-space-between">
                      <v-col class="d-flex justify-start px-0">
                        <a
                          v-if="item.viewtype"
                          @click="openDetailNotification(item)"
                          >ไปยังหน้า
                          <v-icon color="primary"
                            >mdi-arrow-right-bold-circle-outline</v-icon
                          >
                        </a></v-col
                      ><v-col class="d-flex justify-end px-0"
                        ><label class="word-wrap-NotiHistory"
                          >(
                          {{
                            moment(item.createdate).locale("TH").format("lll")
                          }}
                          )</label
                        >
                      </v-col></v-row
                    >
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-sheet>
        </v-col>
      </v-row>

      <div>
        <v-pagination
          class="text-center"
          v-model="page"
          :length="length"
          :total-visible="10"
        ></v-pagination>
        <v-col class="text-end">
          <p style="font-size: 13px">ทั้งหมด : {{ total }}</p>
        </v-col>
      </div>
      <p style="font-size: 13px">
        <v-row class="mx-2">
          <v-icon class="pr-2" color="#2196f3">mdi-circle</v-icon>ทั่วไป
        </v-row>
        <v-row class="mx-2">
          <v-icon class="pr-2" color="#00afa1">mdi-circle</v-icon>ระบบติดตามฯ
        </v-row>
        <v-row class="mx-2">
          <v-icon class="pr-2" color="#a4c126">mdi-circle</v-icon
          >ศูนย์เครือข่ายกำลังคนอาชีวศึกษา
        </v-row>
        <v-row class="mx-2">
          <v-icon class="pr-2" color="#186F65">mdi-circle</v-icon
          >เกี่ยวกับการสร้างรายงาน
        </v-row>
      </p>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

import store from "@/store";
import { NotificationDataProvider } from "@/services/SignalR/NotificationDataProvider";
import { generateRouteObject } from "@/services/NotificationRouteCreateHelper";
import { NotificationHelper } from "@/services/NotificationColorHelper";
import GeneratedReportNotify from "../Report/GeneratedReportNotify.vue";
import { GetNotificationByUser_Request } from "@/models/Notifications/GetNotificationByUser_Request";

const notificationDataProvider = new NotificationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: { GeneratedReportNotify },
  name: "NotificationHistory",
  data() {
    return {
      containerStyle: {
        background: "rgba(170, 169, 169, 0.267)",
        borderRadius: "8px",
        maxWidth: "1400px",
        maxHeight: "30%",
      },
      benched: 0,
      moment: moment,
      items: [],
      messages: [],
      sortOptions: [
        { text: "เรียงเก่าไปใหม่", value: "asc" },
        { text: "เรียงใหม่ไปเก่า", value: "desc" },
      ],
      selectedItem: null,
      desc: true,
      search: "",
      page: 1,
      length: 0,
      itemsperpage: 10,
      notificationID: "",
      disabled: false,
      total: 0,
      request: new GetNotificationByUser_Request(),
      notification: [],
      tempNotification: [],
    };
  },
  methods: {
    //item.viewtype!=""
    async initialize() {
      notificationDataProvider
        .loadNotificationByUser()
        .then((result) => {
          this.total = result.data.length;
          this.length = Math.ceil(this.total / this.itemsperpage);
        })

        .catch((err) => {
          console.error("เกิดข้อผิดพลาดในการโหลดการแจ้งเตือน:", err);
          this.$store.dispatch("hideLoading");
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },
    async loadNotification() {
      this.request.page = this.page;
      this.request.search = this.search;
      this.request.pageSize = this.itemsperpage;
      await notificationDataProvider
        .getNotificationByUserPagination(this.request)
        .then((result) => {
          this.notification = result.data;
          console.log(this.notification, " this.notification");
          this.tempNotification = this.notification;
        });
    },
    IconTypeMessage(item) {
      let routerObj = NotificationHelper(item);
      return routerObj;
    },
    isPanelActive(panelId) {
      return 1;
    },
    OnClikReadAllMessage() {
      notificationDataProvider
        .MarkAllAsReadNoti()
        .then(() => {
          this.loadCountUnRead();
          store.dispatch({ type: "doMarkAllAsReadNotification" });
        })
        .catch((err) => {});
    },
    async goToMessages() {
      this.$router.push({ name: "NotificationHistory" }).catch((err) => {});
    },
    openDetailNotification(item) {
      const routerObj = generateRouteObject(item);
      this.$router.push(routerObj).catch((err) => {});
    },
    openPanel(id) {
      notificationDataProvider
        .MarkAsRead(id)
        .then((result) => {
          console.log("success :" + result.data);
          this.notification.find((x) => x.notificationid == id).isread = true;
          store.dispatch({
            type: "doMarkAsReadNotification",
            id: id,
          });
        })
        .catch(() => {});
    },
  },

  created() {
    this.notificationID = this.$route.params.notificationID;
    this.initialize();
    this.loadNotification();
  },

  computed: {
    panel: {
      get() {
        return this.$store.getters["selectedIndex"];
      },
      set(value) {},
    },
    allNoti() {
      // const startIndex = (this.page - 1) * this.itemsperpage;
      // const endIndex = startIndex + this.itemsperpage;

      // console.log(this.notification);
      // let sorted = [];
      // // Sorting in ascending order
      // if (this.desc == false) {
      //   const sortedAsc = this.notification
      //     .slice()
      //     .sort((a, b) => new Date(a.createdate) - new Date(b.createdate));
      //   sorted = sortedAsc;
      // }
      // // Sorting in descending order
      // else {
      //   const sortedDesc = this.notification
      //     .slice()
      //     .sort((a, b) => new Date(b.createdate) - new Date(a.createdate));

      //   sorted = sortedDesc;
      // }
      // return sorted.slice(startIndex, endIndex);
      return this.notification;
    },

    // totalPages() {
    //   return Math.ceil(this.notification.length / this.itemsperpage);
    // },
  },
  watch: {
    search(value) {
      if (value) {
        this.notification = this.tempNotification.filter(
          (x) =>
            x.message.toLowerCase().includes(value) ||
            x.title.toLowerCase().includes(value)
        );
      } else {
        return this.tempNotification;
      }
      this.page = 1;
    },
    page: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.request.page = newVal;
          this.request.pageSize = this.itemsperpage;
          this.loadNotification();
        }
      },
    },
  },
};
</script>

<style scoped>
.IsActive {
  background: #bab9b9;
}

.card-header h3 {
  font-weight: bold;
}

.card-body p {
  color: black;
}

.Messagecard {
  cursor: pointer;
  background: #ffffff7c;
  border-radius: 8px;
  transition: 0.2s ease;
}

.Messagecard:hover {
  background: #bab9b9;
}

.custom-searchautocomplete {
  background-color: rgba(238, 233, 233, 0.093);
  box-shadow: 2px 2px 1px rgba(170, 169, 169, 0.267);
  height: 50px;
}

.custom-container {
  /* background: rgba(170, 169, 169, 0.267); */
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(170, 169, 169, 0.267);
  max-width: 1400px;
  max-height: 30%;
}

.word-wrap-NotiHistory {
  white-space: normal !important;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1180px) {
  #flex-search {
    flex-direction: column;
  }

  .custom-searchautocomplete {
    width: 100%;
  }

  .custom-container {
    padding: 16px;
  }

  .word-wrap-NotiHistory {
    max-width: 100%;
  }
}

/* @media (min-width: 601px) and (max-width: 1200px) {
  .custom-container {
    max-width: 80%;
  }
} */
@media (min-width: 360px) and (max-width: 1200px) {
  .custom-container {
    max-width: 80%;
  }
}

@media (max-width: 1920px) {
  .custom-container {
    width: 1400px;
  }
}
</style>
