import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
export class MinorDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  loadMinors() {
    try {
      const cachedData = localStorage.getItem(
        `Minors_${process.env.VUE_APP_VERSION}`
      );
      if (cachedData) {
        const data = JSON.parse(cachedData);

        return new Promise((resolve) => {
          let response = [];
          response.status = 200;
          response.data = data;
          resolve(response);
        });
      } else {
        var header = create_default_request_header();
        var endpointUrl = "/api/Minor/GetMinors";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return new Promise((resolve, reject) => {
          axios
            .get(combinedURL, header)
            .then((response) => {
              if (response?.status == 200) {
                resolve(response);
                localStorage.setItem(
                  `Minors_${process.env.VUE_APP_VERSION}`,
                  JSON.stringify(response.data)
                );
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }

  loadMinor(minorID) {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/Minor/GetMinor/" + minorID;
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.get(combinedURL, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }

  getMinorsFromVECEducationInformationBySchoolID(schoolID) {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/Minor/GetMinorsFromVECEducationInformationBySchoolID/" + schoolID;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  importMinors() {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/Minor/ImportMinorDataFromWebAPI";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.get(combinedURL, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }
}
