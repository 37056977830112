import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { AddApplicationUserRole_Request } from "@/models/ApplicationUsers/AddApplicationUserRole_Request";
import { DeleteApplicationUserRole_Request } from "@/models/ApplicationUsers/DeleteApplicationUserRole_Request";
import {CurrentUserChangeThierOwnPasswordRequest } from "@/models/ApplicationUsers/CurrentUserChangeThierOwnPasswordRequest";
export class ApplicationUserDataProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }

  getConcurrentUserCount() {
    const header = create_default_request_header();
    var endpointPath =
      "/api/ApplicationUser/getConcurrentUserCount";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getApplicationUserRoles(applicationUser_oid = "") {
    const header = create_default_request_header();
    var endpointPath =
      "/api/ApplicationUser/GetApplicationUserRoles/" + applicationUser_oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, "", header);
  }

  addApplicationUserRole(request = new AddApplicationUserRole_Request()) {
    const header = create_default_request_header();
    var endpointPath = "/api/ApplicationUser/addApplicationUserRole";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, request, header);
  }

  deleteApplicationUserRole(request = new DeleteApplicationUserRole_Request()) {
    const header = create_default_request_header();
    var endpointPath = "/api/ApplicationUser/DeleteApplicationUserRole";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, request, header);
  }

  GetCurrentApplicationUser() {
    const header = create_default_request_header();
    var endpointPath = "/api/ApplicationUser/GetCurrentApplicationUser";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  currentUserChangeThierOwnPassword(
    currentUserChangeThierOwnPasswordRequest = new CurrentUserChangeThierOwnPasswordRequest()
  ) {
    const header = create_default_request_header();
    var endpointPath = "/api/ApplicationUser/CurrentUserChangeThierOwnPassword";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(
      combinedURL,
      currentUserChangeThierOwnPasswordRequest,
      header
    );
  }

  isUsernameUsed(username = ''){
    if (username == '') username = '-';
    const header = create_default_request_header();
    var endpointPath = "/api/ApplicationUser/IsUsernameUsed/" + username;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL,null, header);
  }

  isEmailUsed(email){
    const header = create_default_request_header();
    var endpointPath = "/api/ApplicationUser/isEmailUsed/" + email;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL,null, header);
  }

  isIdentityIDUsed(identityid = ''){
    const header = create_default_request_header();
    var endpointPath = "/api/ApplicationUser/isIdentityIDUsed/" + identityid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }
}
