import { render, staticRenderFns } from "./VcopNewsListView.vue?vue&type=template&id=22d6f680&scoped=true"
import script from "./VcopNewsListView.vue?vue&type=script&lang=js"
export * from "./VcopNewsListView.vue?vue&type=script&lang=js"
import style0 from "./VcopNewsListView.vue?vue&type=style&index=0&id=22d6f680&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d6f680",
  null
  
)

export default component.exports