<template>
  <div>
    <v-card v-if="reportMain !== undefined" class="pa-8">
      <!-- บรรทัดแรก -->
      <v-row>
        <v-col>
          <h3 class="primary--text" style="font-weight: bold">
            รายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา
          </h3>
          <v-divider class="mt-4 mx-0 mb-4"></v-divider>
          <b>
            ปีการศึกษา
            <label class="text--info text--darken-4">{{
              reportMain.targetgraduatedyearth
            }}</label>
            รอบที่
            <label class="text--info"> {{ reportMain.roundnumber }}</label>
          </b>
          <br />
          <b>
            วันที่เปิดให้ติดตาม :
            <label>{{ convertDateFormat(reportMain.trackingstartdate) }}</label>
            -
            <label>{{ convertDateFormat(reportMain.trackingenddate) }}</label>
          </b>

          <div>
            <label v-if="isCountingForTrackingStartDate" class="greenText">
              การติดตามจะเริ่มใน : {{ timeRemaining.days }} วัน
              {{ timeRemaining.hours }} ชั่วโมง {{ timeRemaining.minutes }} นาที
              {{ timeRemaining.seconds }} วินาที
            </label>
            <label v-if="isCountingForTrackingEndDate" class="redText">
              สิ้นสุดการติดตามใน : {{ timeRemaining.days }} วัน
              {{ timeRemaining.hours }} ชั่วโมง {{ timeRemaining.minutes }} นาที
              {{ timeRemaining.seconds }} วินาที
            </label>
          </div>
        </v-col>
      </v-row>

      <!-- แบ่งเป็นสองคอลัมน์ -->
      <v-row class="mt-0 pt-0">
        <!-- คอลัมน์ซ้าย -->
        <v-col sm="12" md="12" lg="6">
          <b>
            <label class="ml-1" style="color: #374389">
              สถานะการนำเข้าข้อมูลนักศึกษา :
            </label>
          </b>
          <div>
            <v-icon :color="importstudentdata_status_dynamicColor">{{
              importstudentdata_status_dynamicIcon
            }}</v-icon>
            {{ reportMain.importstudentdata_status_statusname }}
          </div>

          <!-- สถานะการนำเข้าข้อมูลนักศึกษา -->
          <v-row class="mt-2">
            <v-col class="pb-1">
              <v-btn
                @click="
                  setActive(1);
                  onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute(
                    ''
                  );
                "
                :class="[
                  'custom-btn',
                  { 'primary--background': selectedImportStatus === '' },
                ]"
                :outlined="activeButton !== 1"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: normal;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                "
                class="light-gray-border mb-0 pb-0"
                :color="activeButton === 1 ? 'primary' : ''"
              >
                <v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 1 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-town-hall</v-icon
                    >
                    จำนวนสถานศึกษาทั้งหมด:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 1 }"
                      >{{ reportMain.quantity_of_targetschools }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-1 pt-0">
              <v-btn
                @click="
                  setActive(2);
                  onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute(
                    'WaitingToStartImportingProcess'
                  );
                "
                :class="[
                  'custom-btn',
                  {
                    'primary--background':
                      selectedImportStatus === 'WaitingToStartImportingProcess',
                  },
                ]"
                :outlined="activeButton !== 2"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="light-gray-border"
                :color="activeButton === 2 ? 'primary' : ''"
                ><v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 2 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-progress-clock</v-icon
                    >
                    อยู่ระหว่างดำเนินการนำเข้าข้อมูลนักศึกษา:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 2 }"
                      >{{
                        reportMain.quantity_of_waitingtostartimportingprocess_schools
                      }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-1 pt-0">
              <v-btn
                @click="
                  setActive(3);
                  onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute(
                    'Imported'
                  );
                "
                :class="[
                  'custom-btn',
                  {
                    'primary--background': selectedImportStatus === 'Imported',
                  },
                ]"
                :outlined="activeButton !== 3"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="light-gray-border"
                :color="activeButton === 3 ? 'primary' : ''"
                ><v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 3 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-check-circle</v-icon
                    >
                    นำเข้าข้อมูลนักศึกษาแล้ว:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 3 }"
                      >{{
                        reportMain.quantity_of_importedstudentdata_schools
                      }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-1 pt-0">
              <v-btn
                @click="
                  setActive(4);
                  onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute(
                    'NoGraduateStudent'
                  );
                "
                :class="[
                  'custom-btn',
                  {
                    'primary--background':
                      selectedImportStatus === 'NoGraduateStudent',
                  },
                ]"
                :outlined="activeButton !== 4"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="light-gray-border"
                :color="activeButton === 4 ? 'primary' : ''"
                ><v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 4 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-account-alert</v-icon
                    >
                    ไม่มีนักศึกษาสำเร็จการศึกษา:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 4 }"
                      >{{
                        reportMain.quantity_of_hasnograduatestudent_schools
                      }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- คอลัมน์ขวา -->
        <v-col sm="12" md="12" lg="6">
          <b>
            <label style="color: #374389">สถานะการติดตาม :</label>
          </b>

          <div>
            <v-icon
              class="mr-2"
              :color="jobtrackingreport_status_dynamicColor"
              >{{ jobtrackingreport_status_dynamicIcon }}</v-icon
            >{{ reportMain.jobtracking_status_statusname }}
          </div>

          <!-- สถานะการติดตาม -->
          <v-row class="d-flex justify-end mt-2">
            <v-col class="pb-1">
              <v-btn
                @click="
                  setActive(5);
                  onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute(
                    'NotStartedTrackingProcess'
                  );
                "
                :class="[
                  'custom-btn',
                  {
                    'primary--background':
                      selectedTrackingStatus === 'NotStartedTrackingProcess',
                  },
                ]"
                :outlined="activeButton !== 5"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="light-gray-border"
                :color="activeButton === 5 ? 'primary' : ''"
                ><v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 5 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-clock-edit</v-icon
                    >
                    ยังไม่มีการบันทึกข้อมูลการติดตามฯ:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 5 }"
                      >{{
                        reportMain.quantity_of_notstartedtrackingprocess_schools
                      }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col class="pb-1 pt-0">
              <v-btn
                @click="
                  setActive(6);
                  onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute(
                    'InProcessOfTracking'
                  );
                "
                :class="[
                  'custom-btn',
                  {
                    'primary--background':
                      selectedTrackingStatus === 'InProcessOfTracking',
                  },
                ]"
                :outlined="activeButton !== 6"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="light-gray-border"
                :color="activeButton === 6 ? 'primary' : ''"
                ><v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 6 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-progress-pencil</v-icon
                    >
                    อยู่ระหว่างติดตามภาวะการมีงานทำ:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 6 }"
                      >{{
                        reportMain.quantity_of_inprocessoftracking_schools
                      }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col class="pb-1 pt-0">
              <v-btn
                @click="
                  setActive(7);
                  onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute(
                    'Verified'
                  );
                "
                :class="[
                  'custom-btn',
                  {
                    'primary--background':
                      selectedTrackingStatus === 'Verified',
                  },
                ]"
                :outlined="activeButton !== 7"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="light-gray-border"
                :color="activeButton === 7 ? 'primary' : ''"
                ><v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 7 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-check-decagram</v-icon
                    >
                    ส่งรายงานแล้ว:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 7 }"
                      >{{ reportMain.quantity_of_verified_schools }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col class="pb-1 pt-0">
              <v-btn
                @click="
                  setActive(8);
                  onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute(
                    'AllowedToEdit'
                  );
                "
                :class="[
                  'custom-btn',
                  {
                    'primary--background':
                      selectedTrackingStatus === 'AllowedToEdit',
                  },
                ]"
                :outlined="activeButton !== 8"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="light-gray-border"
                :color="activeButton === 8 ? 'primary' : ''"
                ><v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 8 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-pencil-circle</v-icon
                    >
                    อนุญาตให้แก้ไข:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 8 }"
                      >{{ reportMain.quantity_of_allowedtoedit_schools }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col class="pb-1 pt-0">
              <v-btn
                @click="
                  setActive(9);
                  onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute(
                    'NotInTime'
                  );
                "
                :class="[
                  'custom-btn',
                  {
                    'primary--background':
                      selectedTrackingStatus === 'NotInTime',
                  },
                ]"
                :outlined="activeButton !== 9"
                style="
                  border-radius: 8px;
                  width: 100%;
                  justify-content: start;
                  border-color: rgba(0, 0, 0, 0.2);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="light-gray-border"
                :color="activeButton === 9 ? 'primary' : ''"
                ><v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon
                      :color="activeButton === 9 ? '' : 'primary'"
                      class="mr-2"
                      >mdi-timer-sand-complete</v-icon
                    >
                    หมดเวลาในการติดตาม:
                  </v-col>
                  <v-col class="text-right">
                    <span :class="{ 'primary--text': activeButton !== 9 }"
                      >{{ reportMain.quantity_of_notintime_schools }} </span
                    >สถานศึกษา
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { formatDateThai } from "@/DateTimeHelper";
import "moment-timezone";
import moment from "moment";
const graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );

export default {
  name: "GraduatedStudentJobTrackingReportMainInfo",
  props: ["oid", "graduatedstudentjobtrackingreportmain"],
  data() {
    return {
      Oid: "",
      dateOptions: { year: "numeric", month: "long", day: "numeric" },
      countDown: 10,
      countDownDate: undefined,

      current_time: undefined,
      timeleft: undefined,
      timeRemaining: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      reportMain: undefined,
      selectedTrackingStatus: undefined,
      isCountingForTrackingStartDate: false,
      isCountingForTrackingEndDate: false,
      TimeOutid: undefined,
      activeButton: 1,
      selectedImportStatus: undefined,
    };
  },
  computed: {
    importstudentdata_status_dynamicIcon() {
      switch (
        this.graduatedstudentjobtrackingreportmain
          .importstudentdata_status_statuscode
      ) {
        case "WaitingToStartImportingProcess":
          return "mdi-clock-edit";
        case "Importing":
          return "mdi-progress-clock";
        case "Imported":
          return "mdi-check-circle";
        default:
          return "";
      }
    },
    importstudentdata_status_dynamicColor() {
      switch (
        this.graduatedstudentjobtrackingreportmain
          .importstudentdata_status_statuscode
      ) {
        case "WaitingToStartImportingProcess":
          return "grey";
        case "Importing":
          return "info";
        case "Imported":
          return "success";
        default:
          return "";
      }
    },
    jobtrackingreport_status_dynamicIcon() {
      switch (
        this.graduatedstudentjobtrackingreportmain.jobtracking_status_statuscode
      ) {
        case "NotStartedTrackingProcess":
          return "mdi-clock-edit";
        case "InProcessOfTracking":
          return "mdi-progress-pencil";
        case "TrackingProcessIsCompleted":
          return "mdi-check-decagram";
        default:
          return "";
      }
    },
    jobtrackingreport_status_dynamicColor() {
      switch (
        this.graduatedstudentjobtrackingreportmain.jobtracking_status_statuscode
      ) {
        case "NotStartedTrackingProcess":
          return "grey";
        case "InProcessOfTracking":
          return "info";
        case "TrackingProcessIsCompleted":
          return "success";
        default:
          return "";
      }
    },
    btnClass(statusCode) {
      return {
        "primary--background": this.selectedImportStatus === statusCode,
        "custom-btn": true, // คลาสอื่นๆ ที่ใช้
      };
    },
  },
  methods: {
    async initialize() {
      if (this.graduatedstudentjobtrackingreportmain != undefined) {
        this.reportMain = this.graduatedstudentjobtrackingreportmain;
      } else {
        if (this.oid != undefined) {
          this.Oid = this.oid;
          await graduatedStudentJobTrackingReportReportDataProvider
            .LoadGraduatedStudentJobTrackingReportMain(this.Oid)
            .then((result) => {
              this.reportMain = result.data;
            });
        }
      }
      this.configdatetimecondition();
    },
    configdatetimecondition() {
      this.current_time = new Date(this.reportMain.currentdatetime);
      let start_time = new Date(this.reportMain.trackingstartdate);
      let end_time = new Date(this.reportMain.trackingenddate);
      if (this.current_time < start_time) {
        this.isCountingForTrackingStartDate = true;
        this.countDownRemainingDate(start_time);
      } else {
        if (this.current_time < end_time) {
          this.isCountingForTrackingEndDate = true;
          this.countDownRemainingDate(end_time.setDate(end_time.getDate() + 1));
        }
      }
    },

    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },

    countDownRemainingDate(target) {
      const now = new Date();
      const distance = target - now;
      if (distance < 0) {
        this.timeRemaining = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      } else {
        this.TimeOutid = setTimeout(() => {
          this.timeRemaining = {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
          };
          this.countDownRemainingDate(target);
        }, 1000);
      }
    },

    onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute(
      StatusCode = ""
    ) {
      this.$emit(
        "onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute",
        StatusCode
      );
    },
    onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute(
      StatusCode = ""
    ) {
      this.$emit(
        "onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute",
        StatusCode
      );
    },
    setActive(buttonNumber) {
      this.activeButton = buttonNumber;
    },
  },
  watch: {
    "graduatedstudentjobtrackingreportmain.trackingstartdate"(newVal) {
      this.isCountingForTrackingStartDate = false;
      this.isCountingForTrackingEndDate = false;
      this.reportMain.trackingstartdate = newVal;
      clearTimeout(this.TimeOutid);
      this.configdatetimecondition();
    },
    "graduatedstudentjobtrackingreportmain.trackingenddate"(newVal) {
      this.isCountingForTrackingStartDate = false;
      this.isCountingForTrackingEndDate = false;
      this.reportMain.trackingenddate = newVal;
      clearTimeout(this.TimeOutid);
      this.configdatetimecondition();
    },
  },
  async mounted() {
    await this.initialize();
    this.configdatetimecondition();
  },
};
</script>

<style>
.redText {
  color: red;
}

.greenText {
  color: green;
}

.highlighText {
  color: #e74c3c;
}
</style>
