<template>
  <div>
    <v-img
      max-width="50"
      contain
      v-bind:lazy-src="'data:image/jpg;base64,' + base64Str"
      v-bind:src="'data:image/jpg;base64,' + base64Str"
      @click="showPreview"
      alt="Image"
    >
    </v-img>
    <v-dialog v-model="dialog" width="70%">
      <template>
        <v-card>
          <v-card-title class="d-flex justify-end mt-2 mb-2">
            <span class="close-icon primary--text" @click="hidePreview"
              >&times;</span
            >
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            <img
              width="70%"
              height="100%"
              :src="'data:image/jpg;base64,' + base64Str"
              alt="Image Preview"
            />
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { FileAttachmentDataProvider } from "@/services/FileAttachmentDataProvider";
const fileAttachmentDataProvider = new FileAttachmentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "FileAttachmentBase64Img",
  props: ["Oid"],
  data() {
    return {
      dialog: false,
      base64Str: "",
      showFullImage: false,
    };
  },
  methods: {
    async initialize() {
      await fileAttachmentDataProvider
        .getFileAttachmentAsBase64(this.Oid)
        .then((result) => {
          this.base64Str = result.data;
        })
        .catch((result) => {
          this.$toast.error("ไม่พบไฟล์หลักฐานการจดทะเบียน");
        });
    },
    showPreview() {
      this.dialog = true;
    },
    hidePreview() {
      this.dialog = false;
    },
  },
  async created() {
    this.initialize();
  },
};
</script>

<style>
.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 95%;
  height: 95%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-container {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}

.scrollable-content {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  /* Enable content scrolling */
}

img {
  max-width: 100%;
  max-height: 100%;
}
</style>
