<template>
  <div class="pa-4 mt-4 d-flex justify-center">
    <v-row class="d-flex justify-center pt-5">
      <v-col cols="12">
        <v-card class="px-5 py-10" style="border-radius: 10px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-3 px-2">
              <v-icon class="pr-2">mdi-chart-areaspline-variant</v-icon>
              <h4>รายงานสรุปผลการติดตาม (รายบุคคล)</h4>
            </v-row>
            <v-row class="px-3 py-1" style="margin-top: 1%; margin-bottom: 2%">
              <v-divider></v-divider>
            </v-row>

            <v-row class="pb-6 pl-3">
              <span style="font-weight: bold">เกณฑ์การค้นหา</span>
            </v-row>
            <v-row>
              <v-col cols="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >สถานศึกษา<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาเลือกสถานศึกษา"
                  item-text="schoolname"
                  item-value="schoolid"
                  :items="filtered_schools"
                  v-model="selected_schoolid"
                  :rules="[(v) => !!v || 'กรุณาเลือกสถานศึกษา']"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col lg="4" md="4" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >ปีที่สำเร็จการศึกษา<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาเลือกปีที่สำเร็จการศึกษา"
                  v-model="selectedYearTH"
                  :items="yearTH_Choices"
                  @change="onYearChanged()"
                  :rules="[(v) => !!v || 'กรุณาระบุปีที่สำเร็จการศึกษา']"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col lg="4" md="4" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >รอบที่ติดตาม<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาระบุรอบการติดตาม"
                  v-model="selectedRound"
                  :items="Round_Choices"
                  :disabled="selectedYearTH ? false : true"
                  :rules="[(v) => !!v || 'กรุณาระบุรอบ']"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                ></v-autocomplete>
              </v-col>
              <v-col lg="4" md="4" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >สถานะการมีงานทำ</span
                >
                <v-autocomplete
                  placeholder="กรุณาระบุสถานะการมีงานทำ"
                  :items="employmentStatuses"
                  item-text="name"
                  item-value="code"
                  v-model="selectedEmployementStatus"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="py-0">
                  <v-col
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    class="custom-col mb-0 py-0"
                  >
                    <span class="primary--text py-0" style="font-weight: bold"
                      >ระดับการศึกษา</span
                    >
                    <v-autocomplete
                      class="d-flex align-center"
                      placeholder="กรุณาระบุระดับการศึกษา"
                      item-text="nameth"
                      item-value="code"
                      :items="certificateTypes"
                      v-model="selectedCertificateTypeCode"
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                      clearable
                      dense
                      persistent-hint
                    ></v-autocomplete>
                  </v-col>

                  <v-col
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    class="custom-col mb-0 py-0"
                  >
                    <span class="primary--text py-0" style="font-weight: bold"
                      >สาขาวิชา</span
                    >
                    <v-autocomplete
                      placeholder="กรุณาระบุสาขาวิชา"
                      :items="majors"
                      item-value="majornameth"
                      item-text="majornameth"
                      v-model="selectedMajor"
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                      clearable
                      dense
                      persistent-hint
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="custom-col px-5 py-6"
                v-if="checkRoles()"
              >
                <v-row class="pl-1">
                  <span class="primary--text font-weight-bold"
                    >ประเภทสถานศึกษา</span
                  >
                </v-row>
                <v-row>
                  <v-col class="px-0 py-0">
                    <v-btn
                      v-for="item in schooltypelist"
                      :key="item.value"
                      :color="
                        selectedSchoolType === item.value
                          ? 'primary'
                          : 'default'
                      "
                      @click="OnSchoolTypeChange(item.value)"
                      class="mx-1 my-1"
                      :block="$vuetify.breakpoint.smAndDown"
                    >
                      {{ item.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="custom-col px-5 py-6"
              >
                <v-row class="pl-1">
                  <span class="primary--text font-weight-bold">เพศ</span>
                </v-row>
                <v-row>
                  <v-col class="px-0 py-0">
                    <v-btn
                      v-for="item in genderlist"
                      :key="item.value"
                      :color="
                        selectedGender === item.value ? 'primary' : 'default'
                      "
                      @click="OnGenderChange(item.value)"
                      class="mx-1 my-1"
                      :block="$vuetify.breakpoint.smAndDown"
                    >
                      {{ item.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  color=""
                  @click="$router.go(-1)"
                  class="justify-center mr-3"
                  style="border-radius: 10px"
                >
                  ย้อนกลับ</v-btn
                >
                <v-btn
                  dark
                  class="color-btnCard"
                  style="border-radius: 10px"
                  v-on:click="create_reportParameters()"
                  >แสดงรายงาน<v-icon>mdi-logout-variant</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row class="d-flex pa-3 pb-4">
      <h3 class="primary--text">
        <i class="fa fa-line-chart"></i>รายงานสรุปผลการติดตาม (รายบุคคล)
      </h3>
    </v-row>
    <v-row>
      <v-card class="col-12 pa-4">
        <v-card-title>
          <p>เกณฑ์การค้นหา</p>
        </v-card-title>
        <v-divider class="mt-0 mx-0 mb-4"></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="custom-col">
                <v-autocomplete
                  hint="สถานศึกษา"
                  :rules="[(v) => !!v || 'กรุณาเลือกสถานศึกษา']"
                  persistent-hint
                  item-text="schoolname"
                  item-value="schoolid"
                  :items="filtered_schools"
                  v-model="selected_schoolid"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="custom-col">
                <v-select
                  hint="ปีการศึกษา"
                  :rules="[(v) => !!v || 'กรุณาระบุปีการศึกษา']"
                  persistent-hint
                  clearable
                  v-model="selectedYearTH"
                  :items="yearTH_Choices"
                  @change="onYearChanged"
                ></v-select>
              </v-col>
              <v-col cols="3" class="custom-col">
                <v-select
                  hint="รอบที่"
                  :rules="[(v) => !!v || 'กรุณาระบุรอบ']"
                  persistent-hint
                  v-model="selectedRound"
                  :items="Round_Choices"
                  :disabled="selectedYearTH ? false : true"
                ></v-select>
              </v-col>
              <v-col cols="6" class="custom-col">
                <v-select
                  :items="employmentStatuses"
                  hint="สถานะการมีงานทำ"
                  persistent-hint
                  item-text="name"
                  item-value="code"
                  clearable
                  v-model="selectedEmployementStatus"
                ></v-select>
              </v-col>
              <v-col cols="3" class="custom-col">
                <v-select
                  hint="ระดับการศึกษา"
                  persistent-hint
                  item-text="nameth"
                  item-value="code"
                  :items="certificateTypes"
                  clearable
                  v-model="selectedCertificateTypeCode"
                ></v-select>
              </v-col>
              <v-col cols="3" class="custom-col">
                <v-autocomplete
                  :items="majors"
                  hint="สาขาวิชา"
                  item-value="majornameth"
                  item-text="majorid"
                  persistent-hint
                  clearable
                  v-model="selectedMajor"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" class="custom-col">
                <v-autocomplete
                  :items="studenttypes"
                  hint="ประเภทนักศึกษา"
                  item-value="studenttypecode"
                  item-text="studenttypename"
                  persistent-hint
                  clearable
                  v-model="selectedStudentType"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" class="custom-col">
                <v-select
                  hint="เพศ"
                  persistent-hint
                  :items="genders"
                  item-text="name"
                  item-value="code"
                  v-model="selectedGender"
                  clearable
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="mt-9">
      <v-col cols="12 text-center">
        <v-btn color="warning" v-on:click="create_reportParameters()"
          >แสดงรายงาน</v-btn
        >
      </v-col>
    </v-row> -->

    <v-dialog v-model="document_viewer_dialog" width="50%">
      <template>
        <v-card height="auto" class="px-10 py-10">
          <ReportExportFromQueue
            :report_name="report_name"
            :report_parameters="report_parameters"
            @close_Dialog="document_viewer_dialog = false"
          ></ReportExportFromQueue>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import DocumentViewer from "../../../components/Report/DocumentViewer.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { MajorDataProvider } from "@/services/MajorDataProvider";

import ReportExport from "@/components/Report/ReportExport.vue";
import ReportExportFromQueue from "@/components/Report/ReportExportFromQueue.vue";
import { ApplicationRoleCodes } from "@/Constants";

const majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report",
  props: [],
  data() {
    return {
      document_viewer_key: 1,
      document_viewer_dialog: false,
      report_name:
        "School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report",
      report_parameters: [],
      yearTH_Choices: undefined,
      selected_schoolid: undefined,
      selectedYearTH: undefined,
      certificateTypes: undefined,
      selectedCertificateTypeCode: "",
      schooltypelist: [
        { text: "ทั้งหมด", value: "" },
        { text: "ภาครัฐ", value: "ภาครัฐ" },
        { text: "ภาคเอกชน", value: "ภาคเอกชน" },
      ],
      selectedSchoolType: "",
      genderlist: [
        { text: "ทั้งหมด", value: "" },
        { text: "ชาย", value: "M" },
        { text: "หญิง", value: "F" },
      ],
      selectedGender: "",
      provinces: undefined,
      majors: undefined,
      studenttypes: undefined,
      genders: undefined,
      request_data: new GetSchools_Reqeust(),
      schools: undefined,
      currentPermissionSchoolIDs: [],
      employmentStatuses: undefined,
      selectedEmployementStatus: "",
      valid: true,
      Round_Choices: [],
      selectedRound: "",
      selectedSchool: "",
      selectedMajor: undefined,
      selectedStudentType: "",
      selectedRegion: "",
      selectedProvince: "",
      roles: [],
      isShowSchoolType: true,
    };
  },
  computed: {
    filtered_schools() {
      if (this.schools != undefined) {
        return this.schools.filter((x) =>
          this.currentPermissionSchoolIDs.includes(x.schoolid)
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    onYearChanged() {
      this.loadListOfJobTrackingRoundFromGraduatedYearTH();
    },
    loadListOfGraduatedYearFromJobTrackingReport() {
      commonDataProvider
        .loadListOfGraduatedYearFromJobTrackingReport()
        .then((resp) => {
          this.yearTH_Choices = resp.data;
        });
    },

    loadListOfJobTrackingRoundFromGraduatedYearTH() {
      commonDataProvider
        .loadListOfJobTrackingRoundFromGraduatedYearTH(this.selectedYearTH)
        .then((resp) => {
          this.Round_Choices = resp.data;
        });
    },
    checkRoles() {
      if (
        this.roles?.includes(
          ApplicationRoleCodes.School_Admin ||
            ApplicationRoleCodes.School_Guidance_Teacher ||
            ApplicationRoleCodes.School_Manager ||
            ApplicationRoleCodes.School_Partner_Officer
        )
      )
        return (this.isShowSchoolType = false);
    },
    async initialize() {
      await schoolEmployeeProfileDataProvider
        .getCurrentPermissionSchoolIDs()
        .then((result) => {
          this.currentPermissionSchoolIDs = result.data;
          if (this.currentPermissionSchoolIDs.length > 0) {
            this.selected_schoolid = this.currentPermissionSchoolIDs[0];
          }
        });
      await masterDataProvider
        .getCertificateTypesByVECEducationInformationsFromSchoolId(
          this.selected_schoolid
        )
        .then((result) => {
          this.certificateTypes = result.data;
        });

      await majorDataProvider
        .getMajorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
          this.selected_schoolid
        )
        .then((result) => {
          this.majors = result.data;
        });
      await schoolDataProvider.loadSchools(this.request_data).then((result) => {
        this.schools = result.data;
      });
      await masterDataProvider.loadEmploymentStatuses().then((result) => {
        this.employmentStatuses = result.data;
      });

      await masterDataProvider.getGenders().then((result) => {
        this.genders = result.data;
      });

      this.loadListOfGraduatedYearFromJobTrackingReport();
    },
    create_reportParameters() {
      if (this.$refs.form.validate()) {
        this.document_viewer_key += 1;
        this.report_parameters = [];
        if (this.selectedYearTH != undefined) {
          this.report_parameters.push("prm_YearTH=" + this.selectedYearTH);
        }
        if (this.selectedRound > 0) {
          this.report_parameters.push("prm_Round=" + this.selectedRound);
        }
        if (this.selected_schoolid) {
          this.report_parameters.push("prm_SchoolID=" + this.selected_schoolid);
        }
        if (this.selectedSchoolType) {
          this.report_parameters.push(
            "prm_SchoolType=" + this.selectedSchoolType
          );
        }
        if (this.selectedEmployementStatus) {
          this.report_parameters.push(
            "prm_EmploymentStatus=" + this.selectedEmployementStatus
          );
        }
        if (this.selectedCertificateTypeCode) {
          this.report_parameters.push(
            "prm_CertificateType=" + this.selectedCertificateTypeCode
          );
        }
        if (this.selectedMajor != undefined) {
          this.report_parameters.push("prm_MajorNameTh=" + this.selectedMajor);
        }
        if (this.selectedGender) {
          this.report_parameters.push("prm_Gender=" + this.selectedGender);
        }
        this.document_viewer_dialog = true;
        console.log(this.report_parameters, "  this.report_parameters");
      }
    },
    OnSchoolTypeChange(value) {
      this.selectedSchoolType = value;
      this.loadListOfGraduatedYearFromJobTrackingReport();
    },
    OnGenderChange(value) {
      this.selectedGender = value;
      this.loadListOfGraduatedYearFromJobTrackingReport();
    },
  },
  async created() {
    await this.initialize();
    this.roles = this.$store.getters.rolecodes;
  },
  components: { DocumentViewer, ReportExport, ReportExportFromQueue },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.color-btnCard {
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 60%,
    rgba(63, 81, 181, 1) 100%
  );
}
</style>
