<template>
  <div class="pa-4 mt-4 d-flex justify-center">
    <v-row class="d-flex justify-center pt-5">
      <v-col cols="12">
        <v-card class="px-5 py-10" style="border-radius: 10px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-3 px-2">
              <v-icon class="pr-2">mdi-chart-areaspline-variant</v-icon>
              <h4>รายงานภาวะการมีงานทำ (รายประเภทวิชา)</h4>
            </v-row>
            <v-row class="px-3 py-1" style="margin-top: 1%; margin-bottom: 2%">
              <v-divider></v-divider>
            </v-row>

            <v-row class="pb-6 pl-3">
              <span style="font-weight: bold">เกณฑ์การค้นหา</span>
            </v-row>
            <v-row>
              <v-col cols="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >สถานศึกษา<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาเลือกสถานศึกษา"
                  :rules="[(v) => !!v || 'กรุณาเลือกสถานศึกษา']"
                  item-text="schoolname"
                  item-value="schoolid"
                  :items="filtered_schools"
                  v-model="selected_schoolid"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="4" md="4" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >ปีที่สำเร็จการศึกษา<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาเลือกปีที่สำเร็จการศึกษา"
                  @change="onYearChanged()"
                  :rules="[(v) => !!v || 'กรุณาระบุปีที่สำเร็จการศึกษา']"
                  v-model="selectedYearTH"
                  :items="yearTH_Choices"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col lg="4" md="4" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >รอบที่ติดตาม<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาระบุรอบการติดตาม"
                  :rules="[(v) => !!v || 'กรุณาระบุรอบ']"
                  v-model="selectedRound"
                  :items="Round_Choices"
                  :disabled="selectedYearTH ? false : true"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                ></v-autocomplete>
              </v-col>
              <v-col lg="4" md="4" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >ระดับการศึกษา</span
                >
                <v-autocomplete
                  class="d-flex align-center"
                  placeholder="กรุณาระบุระดับการศึกษา"
                  item-text="nameth"
                  item-value="code"
                  :items="certificateTypes"
                  v-model="selectedCertificateTypeCode"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  color=""
                  @click="$router.go(-1)"
                  class="justify-center mr-3"
                  style="border-radius: 10px"
                >
                  ย้อนกลับ</v-btn
                >
                <v-btn
                  dark
                  class="color-btnCard"
                  style="border-radius: 10px"
                  v-on:click="create_reportParameters()"
                  >แสดงรายงาน<v-icon>mdi-logout-variant</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="document_viewer_dialog" width="50%">
      <template>
        <v-card height="auto" class="px-10 py-10">
          <ReportExportFromQueue
            :report_name="report_name"
            :report_parameters="report_parameters"
            @close_Dialog="document_viewer_dialog = false"
          ></ReportExportFromQueue>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import DocumentViewer from "../../../components/Report/DocumentViewer.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";

import ReportExport from "@/components/Report/ReportExport.vue";
import ReportExportFromQueue from "@/components/Report/ReportExportFromQueue.vue";

var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
  props: [],
  data() {
    return {
      document_viewer_key: 1,
      document_viewer_dialog: false,
      report_name:
        "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report",
      report_parameters: [],
      yearTH_Choices: [],
      selected_schoolid: undefined,
      selectedYearTH: undefined,
      certificateTypes: undefined,
      selectedCertificateTypeCode: "",
      regionsNames: undefined,
      schooltypelist: [
        { text: "ภาครัฐ/ภาคเอกชน", value: "" },
        { text: "ภาครัฐ", value: "ภาครัฐ" },
        { text: "ภาคเอกชน", value: "ภาคเอกชน" },
      ],
      provinces: undefined,
      getSchools_Reqeust: new GetSchools_Reqeust(),
      schools: undefined,
      currentPermissionSchoolIDs: [],
      regionAsVEC: undefined,
      valid: true,
      Round_Choices: [],
      selectedRound: "",
      selectedSubJectTypeId: "",
      selectedMajor: [],
      selectedmajorId: "",
      selectedSchoolType: "",
      selectedRegion: "",
      selectedProvince: "",
    };
  },
  computed: {
    filtered_schools() {
      if (this.schools != undefined) {
        return this.schools.filter((x) =>
          this.currentPermissionSchoolIDs.includes(x.schoolid)
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    onYearChanged() {
      this.loadListOfJobTrackingRoundFromGraduatedYearTH();
    },
    loadListOfGraduatedYearFromJobTrackingReport() {
      commonDataProvider
        .loadListOfGraduatedYearFromJobTrackingReport()
        .then((resp) => {
          this.yearTH_Choices = resp.data;
        });
    },

    loadListOfJobTrackingRoundFromGraduatedYearTH() {
      commonDataProvider
        .loadListOfJobTrackingRoundFromGraduatedYearTH(this.selectedYearTH)
        .then((resp) => {
          this.Round_Choices = resp.data;
        });
    },
    async initialize() {
      await schoolEmployeeProfileDataProvider
        .getCurrentPermissionSchoolIDs()
        .then((result) => {
          this.currentPermissionSchoolIDs = result.data;
          if (this.currentPermissionSchoolIDs.length > 0) {
            this.selected_schoolid = this.currentPermissionSchoolIDs[0];
          }
        });

      await masterDataProvider
        .getCertificateTypesByVECEducationInformationsFromSchoolId(
          this.selected_schoolid
        )
        .then((result) => {
          this.certificateTypes = result.data;
        });
      await schoolDataProvider
        .loadSchools(this.getSchools_Reqeust)
        .then((result) => {
          this.schools = result.data;
        });

      this.loadListOfGraduatedYearFromJobTrackingReport();
    },
    create_reportParameters() {
      if (this.$refs.form.validate()) {
        this.document_viewer_key += 1;
        this.report_parameters = [];
        if (this.selected_schoolid != undefined) {
          this.report_parameters.push("prm_SchoolID=" + this.selected_schoolid);
        }
        if (this.selectedYearTH != undefined) {
          this.report_parameters.push("prm_YearTH=" + this.selectedYearTH);
        }
        if (this.selectedRound > 0) {
          this.report_parameters.push("prm_Round=" + this.selectedRound);
        }
        if (this.selectedCertificateTypeCode) {
          this.report_parameters.push(
            "prm_CertificateType=" + this.selectedCertificateTypeCode
          );
        }

        this.document_viewer_dialog = true;
      }
    },
  },
  async created() {
    await this.initialize();
  },
  components: { DocumentViewer, ReportExport, ReportExportFromQueue },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.color-btnCard {
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 60%,
    rgba(63, 81, 181, 1) 100%
  );
}
</style>
