import SearchJob from "@/views/SearchJob/SearchJob.vue";
import SearchJobDetails from "@/views/SearchJob/SearchJobDetails.vue";

export default [
  {
    path: "/SearchJob",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/SearchJob/SearchJob",
        name: "SearchJob",
        component: SearchJob,
        meta: {
          requiresAuth: true,
          nameTh: "ค้นหาตำแหน่งงาน/ฝึกงาน",
        },
      },

      {
        path: "/SearchJob/SearchJobDetails",
        name: "SearchJobDetails",
        component: SearchJobDetails,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดงาน",
        },
      },
    ],
  },
];
