<template>
  <div>
    <v-app-bar color="indigo" app dark>
      <div class="d-flex align-center">
        <router-link to="/home">
          <v-img
            alt="VEC Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="50"
          />
        </router-link>
        <router-link to="/home">
          <v-img
            alt="สำนักงานคณะกรรมการการอาชีวศึกษา"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            src="@/assets/logo-name.png"
            width="100%"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <template>
        <div class="pr-3" v-if="$vuetify.theme.dark">
          <v-switch
            v-model="$vuetify.theme.dark"
            label="โหมดกลางคืน"
            inset
            persistent-hint
            hint="สลับโหมดกลางวัน/กลางคืน"
            color="primary"
          >
          </v-switch>
        </div>
        <div class="pr-3" v-else>
          <v-switch
            v-model="$vuetify.theme.dark"
            label="โหมดกลางวัน"
            inset
            persistent-hint
            hint="สลับโหมดกลางวัน/กลางคืน"
            color="primary"
          >
          </v-switch>
        </div>
      </template>
    </v-app-bar>
    <v-container>
      <v-card
        elevation="2"
        class="mx-auto my-12 justify-center text-center pa-4"
        max-width="450"
      >
        <div class="pt-5">
          <h3>ลืมรหัสผ่าน (Forget Password)</h3>
          <v-divider class="mt-4 mb-4 mx-0"></v-divider>
          <div class="py-4">
            <span class="secondary--text font-size:16px"
              >ป้อนชื่อผู้ใช้ (Username) ของคุณ</span
            >
          </div>
        </div>

        <div>
          <v-alert
            v-if="isChangePasswordSuccess == true"
            class="mb-10"
            outlined
            type="success"
            text
          >
            <div class="mb-2">
              ระบบทำการส่งรายละเอียดการเปลี่ยนรหัสผ่านไปที่อีเมล
              <strong> {{ email }}</strong>
            </div>
            <div>
              <span class="grey--text"
                >หมายเหตุ หากไม่ได้รับอีเมล ลองตรวจอบอีเมลที่กล่องอีเมลขยะ
                (junkmail)</span
              >
            </div>
          </v-alert>
          <v-alert
            v-if="isChangePasswordSuccess == false"
            class="mb-10"
            outlined
            type="warning"
            prominent
            border="left"
          >
            {{ isChangePasswrodErrorMsg }}
          </v-alert>
        </div>

        <div>
          <v-form ref="form" v-model="valid" @submit.prevent="submit" lazy-validation>
            <v-text-field
              v-model="account.username"
              label="ชื่อผู้ใช้ (Username)"
              required
              prepend-icon="mdi-account"
              :rules="usernameRules"
            >
            </v-text-field>
            <div class="my-4">
              <v-btn color="secondary" block type="submit" dark class="my-4">
                <span>ลืมรหัสผ่าน</span>
              </v-btn>
              <v-btn
                color="secondary"
                text
                block
                dark
                class="my-4"
                @click="$router.go(-1)"
              >
                <span>กลับหน้า Login</span>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
      <!-- <div>
        <a :href="link">{{ link }}</a>
      </div> -->
    </v-container>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { PasswordManagementDataProvider } from "@/services/PasswordManagementDataProvider";
var passwordManagementDataProvider = new PasswordManagementDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import { EmailSenderDataProvider } from "@/services/EmailSenderDataProvider";
// import { link } from 'fs';
var emailSenderDataProvider = new EmailSenderDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "recoveryindentifySchoolEmployee",
  mounted() {},
  data() {
    return {
      valid: false,
      allowSpaces: false,
      account: {
        username: "",
        password: "",
      },
      usernameRules: [
        (v) => !!v || "กรุณาป้อนชื่อผู้ใช้ (Username)",
        (v) => (v || "").indexOf(" ") < 0 || "ชื่อผู้ใช้ (Username) ต้องไม่มีเว้นวรรค",
        // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      link: undefined,
      useroid: "",
      email: "",
      displayname: "",
      token: "",
      isChangePasswordSuccess: undefined,
      isChangePasswrodErrorMsg: undefined,
      loginpage:"",
    };
  },
  methods: {
    async submit() {
      var validate = this.$refs.form.validate();
      if (validate) {
        await passwordManagementDataProvider
          .GenerateTokenForSchoolEmployee(this.account.username)
          .then((result) => {
            this.token = result.data.token.token;
            this.email = result.data.email;
            this.loginpage = this.$route.params.loginpage;
            this.displayname =
              result.data.titlename + " " + result.data.name + " " + result.data.surname;
            this.isChangePasswordSuccess = true;
            var base_url = `${window.location.origin}/renewpassword?login=${this.loginpage}&token=${this.token}`;
            this.link = base_url;
            this.sendEmail(this.email, this.displayname, this.link);
          })
          .catch((err) => {
            this.isChangePasswordSuccess = false;
            this.isChangePasswrodErrorMsg = "ไม่พบข้อมูลชื่อผู้ใช้งานของคุณในระบบ ";
            //this.$toast.error("ไม่พบผู้ใช้งาน" + err)
          });
      }
    },
    async sendEmail(email, display, url) {
      await emailSenderDataProvider
        .SendForgotPasswordEmail(email, display, url)
        .then((result) => {})
        .catch((err) => {});
    },
    // gotoLogin() {
    //   this.$router.push("/Login");
    // },
  },
  components: {},
};
</script>

<style></style>
