export class GraduatedStudentJobTrackingReportsRequest {
  GraduatedStudentJobTrackingReport_Oid = "";
  selected_subjecttypecode = "";
  selected_subjecttypenameth  = "";
  gender = "";
  page = 1;
  itemPerPage = 10;
  search = "";
  selected_majorcode = "";
  selected_majornameth = "";
  selected_employmentstatus_code = "";
  selected_certificatetype_code = "";
  selected_certificatetype_nameth = "";
  isTracked = undefined;
  sortBy = [];
  sortDesc = [];
}
