<template>
  <div>
    <v-container v-if="NewsContent">
      <v-row>
        <v-col cols="12">
          <h1>ข่าวประชาสัมพันธ์</h1>
          <h4 class="grey--text mb-10">ข่าวประชาสัมพันธ์ ข่าวแนะแนว</h4>
          <v-card>
            <v-card-text>
              <v-img

              height="720"
              :src="
                'data:image/jpeg;base64,' + NewsContent.bannerthumbnailbase64
              "
              contain
            ></v-img>
            <div class="mt-2 post-date">
              <span class="day">{{ formattedDateday }}</span>
              <span class="month">{{ formattedDatemonth }}</span>
            </div>
            </v-card-text>
            <v-card-title>
              {{ NewsContent.title }}
            </v-card-title>
            <v-card-subtitle class="mt-2">
              <v-icon>mdi-calendar-blank</v-icon>
              {{ formattedDate }} &nbsp; By <v-icon>mdi-account</v-icon>
              {{ NewsContent.writerfullname }} &nbsp; <v-icon>mdi-folder</v-icon
              >{{ NewsContent.category?.name }} &nbsp;
              <v-icon>mdi-tag</v-icon>
              <span v-for="(tag, index) in NewsTags" :key="index">
                {{ tag }}
                <template v-if="index !== NewsTags.length - 1">, </template>
              </span>
              &nbsp;
              <v-icon>mdi-eye</v-icon>{{ NewsContent.viewcouter }} viewer
              <br />

              <!-- <div id="dnn_ctr388_View_ArticleRatingDefault3888" class="rating">
                <div
                  id="dnn_ctr388_View_ctl01"
                  style="cursor: pointer; width: 100px"
                >
                  <v-rating
                    background-color="warning lighten-1"
                    color="warning"
                    empty-icon="$mdiStarOutline"
                    full-icon="$mdiStar"
                    half-icon="$mdiStarHalfFull"
                    hover
                    length="5"
                    size="43"
                    value="3"
                  ></v-rating>
                </div>
              </div> -->
            </v-card-subtitle>
            <v-card-text>
              <div v-html="NewsContent.htmlcontent"></div>
              <!-- <br />
<span style="float: left; padding-top: 5px;padding-bottom: 5px;">
    <v-icon color="primary">mdi-facebook</v-icon>
                  &nbsp;แชร์ไปยัง Facebook</span
                > -->
            </v-card-text>
            <v-card-actions>
              <v-btn block text color="primary" @click="()=>this.$router.go(-1)">ย้อนกลับ</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { NewsDataProvider } from "@/services/NewsDataProvider";
const newsDataProvider = new NewsDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "VcopNewsDetailView",
  components: {},
  data() {
    return {
      oid: "",
      host: "",
      NewsContent: [],
      NewsTags: [],
      itemlength: 0,
      date:"",
    };
  },
  methods: {
    async loadNewDetail(oid = "") {
      await newsDataProvider
        .loadNewsPublishDetail(oid)
        .then((result) => {
          this.oid = result.data.oid;
          this.NewsContent = result.data;
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadNewsTags(oid = "") {
      await newsDataProvider
        .loadTagsNews(oid)
        .then((result) => {
          this.oid = result.data.oid;
          this.NewsTags = result.data;
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  async created() {
    this.oid = this.$route.params.oid;
    await this.loadNewDetail(this.oid);
    await this.loadNewsTags(this.oid);
  },
  computed: {
    formattedDate() {
      if (!this.NewsContent) return "";
      const date = new Date(this.NewsContent.createddate);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleString("th-TH", options);
    },
    formattedDateday() {
      if (!this.NewsContent) return "";
      const date = new Date(this.NewsContent.createddate);
      const options = { day: "numeric" };
      return date.toLocaleString("th-TH", options);
    },
    formattedDatemonth() {
      if (!this.NewsContent) return "";
      const date = new Date(this.NewsContent.createddate);
      const options = { month: "long" };
      return date.toLocaleString("th-TH", options);
    },
  },
};
</script>

<style scoped>
.v-img {
  border-radius: 20px;
  margin-bottom: 5%;
}
.v-card-title {
  color: rgb(33, 150, 243);
  font-size: 32px;
  white-space: normal;
  line-height: 1.2;
}
.v-card-subtitle,
.v-list-item-title,
.show-text-all {
  white-space: normal;
  overflow: auto;
}
.v-card-text {
  display: inline;
  color: rgb(0, 0, 0);
}

.v-card-title:hover,
.v-img:hover {
  cursor: pointer;
}
.v-card-title:hover {
  color: rgb(82, 159, 222);
}
.post-date {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  float: left;
  margin-right: 10px;
  text-align: center;
}
.post-date .day {
  background: #f4f4f4;
  border-radius: 2px 2px 0 0;
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  color: rgb(82, 159, 222);
}
.post-date .month {
  background: rgb(82, 159, 222);
  border-radius: 0 0 2px 2px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07) inset;
  color: #fff;
  font-size: 0.9em;
  padding: 0 10px 2px;
}
</style>
