<template>
  <div>
    <v-btn color="secondary" @click="OngetRecieveSatusJobsClick"><v-icon>mdi-refresh</v-icon>ติดตาม Real-time</v-btn>
    <SearchableDataTable
      v-if="SuccessJobDetails.length > 0"
      :datatable_headers="datatable_headers"
      :data_items="SuccessJobDetails">
    </SearchableDataTable>
  </div>
</template>

<script>
// import { importServiceHubConnection } from "@/services/SignalR/ImportServiceHub"
// var importServiceHubconnection = new importServiceHubConnection(
//   process.env.VUE_APP_BACKEND_API_URL + "/importServiceHub"
// )
import SearchableDataTable from "@/components/common/SearchableDataTable.vue"
import moment from "moment"
export default {
  components: { SearchableDataTable },
  name: "TrackingAllStatusjobs",
  data() {
    return {
      datatable_headers: [
        { value: "jobid", text: "รหัส" },
        { value: "job", text: "ชื่อ" },
        { value: "state", text: "สถานะ" }
      ],
      SuccessJobDetails: []
    }
  },
  async mounted() {
    // await this.OngetRecieveSatusJobsClick()
  },
  methods: {
    OngetRecieveSatusJobsClick() {
      importServiceHubconnection
        .onCallAllStatusJobs()
        .then((data) => {
          this.SuccessJobDetails = JSON.parse(data)
        })
        .catch((err) => {})
    }
  }
}
</script>

<style></style>
