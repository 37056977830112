<template>
  <div>
    <v-expansion-panels v-model="isExpand">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="primary--text" style="font-weight: bold">
            รายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <h4 class="mb-2">
                {{ graduatedStudentJobTrackingReport.schoolid }}
                {{ graduatedStudentJobTrackingReport.schoolname }}
              </h4>

              <b>
                ปีการศึกษา :
                <label class="text--info text--darken-4">{{
                  graduatedStudentJobTrackingReport.targetgraduatedyearth
                }}</label>
                รอบที่
                <label class="text--info">
                  {{ graduatedStudentJobTrackingReport.roundnumber }}</label
                >
              </b>
              <br />
              <b>
                วันที่เปิดให้ติดตาม :
                <label class=""
                  >{{
                    convertDateFormat(
                      graduatedStudentJobTrackingReport.trackingstartdate
                    )
                  }}
                  -
                  {{
                    convertDateFormat(
                      graduatedStudentJobTrackingReport.trackingenddate
                    )
                  }}
                </label>
              </b>
              <div>
                <label v-if="isCountingForTrackingStartDate" class="greenText">
                  การติดตามจะเริ่มใน : {{ timeRemaining.days }} วัน
                  {{ timeRemaining.hours }} ชั่วโมง
                  {{ timeRemaining.minutes }} นาที
                  {{ timeRemaining.seconds }} วินาที
                </label>
                <label v-if="isCountingForTrackingEndDate" class="redText">
                  สิ้นสุดการติดตามใน : {{ timeRemaining.days }} วัน
                  {{ timeRemaining.hours }} ชั่วโมง
                  {{ timeRemaining.minutes }} นาที
                  {{ timeRemaining.seconds }} วินาที
                </label>
              </div>
            </v-col>
          </v-row>

          <!-- แบ่งเป็นสองคอลัมน์ -->
          <v-row class="mt-0 pt-0">
            <!-- คอลัมน์ซ้าย -->
            <v-col sm="12" md="12" lg="6">
              <b>
                <label class="ml-1" style="color: #374389">
                  สถานะการนำเข้าข้อมูลนักศึกษา :
                </label>
              </b>
              <div>
                <v-icon :color="importstudentdata_status_dynamicColor">{{
                  importstudentdata_status_dynamicIcon
                }}</v-icon>
                {{
                  graduatedStudentJobTrackingReport.importstudentdata_status_statusname
                }}
              </div>

              <!-- สถานะการนำเข้าข้อมูลนักศึกษา -->
              {{
                moment(
                  graduatedStudentJobTrackingReport.importstudentdata_status_updateddate
                )
                  .add(543, "year")
                  .locale("TH")
                  .format(" D MMMM YYYY เวลา HH:mm")
              }}
              น.
              <div>
                <label v-if="graduatedStudentJobTrackingReport"
                  >จำนวนการติดตาม :
                  <label class=""
                    >{{
                      graduatedStudentJobTrackingReport.countstudentjobtracked
                    }}
                    /
                    {{
                      graduatedStudentJobTrackingReport.quantityofgraduatedstudentjobtrackingreport_studentdetails
                    }}
                  </label>
                </label>
              </div>

              <v-row class="mt-2">
                <v-col class="pb-1" cols="6">
                  <v-btn
                    @click="
                      setActive(1);
                      onFilteringGraduatedStudentJobTrackingReportInfo(true);
                    "
                    :outlined="activeButton !== 1"
                    style="
                      border-radius: 8px;
                      width: 100%;
                      justify-content: start;
                      border-color: rgba(0, 0, 0, 0.2);
                      white-space: normal;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    class="light-gray-border mb-0 pb-0"
                    :color="activeButton === 1 ? 'primary' : ''"
                  >
                    <v-row no-gutters class="align-center">
                      <v-col cols="auto">
                        <v-icon
                          :color="activeButton === 1 ? '' : 'primary'"
                          class="mr-2"
                          >mdi-account-check</v-icon
                        >
                        ติดตามแล้ว :
                      </v-col>
                      <v-col class="text-right">
                        <span :class="{ 'primary--text': activeButton !== 1 }"
                          >{{
                            graduatedStudentJobTrackingReport.countstudentjobtracked
                          }} </span
                        >คน
                      </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row class="my-2">
                <v-col class="pb-1" cols="6">
                  <v-btn
                    @click="
                      setActive(2);
                      onFilteringGraduatedStudentJobTrackingReportInfo(false);
                    "
                    :outlined="activeButton !== 2"
                    style="
                      border-radius: 8px;
                      width: 100%;
                      border-color: rgba(0, 0, 0, 0.2);
                      justify-content: start;
                      white-space: normal;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    class="light-gray-border mb-0 pb-0"
                    :color="activeButton === 2 ? 'primary' : ''"
                  >
                    <v-row no-gutters class="align-center">
                      <v-col cols="auto">
                        <v-icon
                          :color="activeButton === 2 ? '' : 'primary'"
                          class="mr-2"
                        >
                          mdi-account-alert
                        </v-icon>
                        ยังไม่ได้ติดตาม :
                      </v-col>
                      <v-col class="text-right">
                        <span :class="{ 'primary--text': activeButton !== 2 }">
                          {{ resultnontracking }} </span
                        >คน
                      </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <!-- คอลัมน์ขวา -->
            <v-col sm="12" md="12" lg="6">
              <b>
                <label style="color: #374389">สถานะการติดตาม :</label>
              </b>

              <div>
                <GraduatedStudentJobTrackingReport_JobTracking_Status_Label
                  :jobtrackingreport="graduatedStudentJobTrackingReport"
                >
                </GraduatedStudentJobTrackingReport_JobTracking_Status_Label>
              </div>

              <!-- สถานะการติดตาม -->
              <div>
                <label
                  >วัน/เดือน/ปี ที่ส่งรายงาน :
                  <span
                    v-if="
                      graduatedStudentJobTrackingReport.jobtracking_status_statuscode ==
                      'Verified'
                    "
                    >{{
                      moment(
                        graduatedStudentJobTrackingReport.jobtracking_status_updateddate
                      )
                        .add(543, "year")
                        .locale("TH")
                        .format("lll")
                    }}
                  </span>
                  <span v-else> - </span>
                </label>
              </div>
              <div>
                <label
                  v-if="graduatedStudentJobTrackingReport.verifymanager_name"
                  >ชื่อผู้รับรองรายงาน :

                  {{
                    graduatedStudentJobTrackingReport.verifymanager_titlenameth
                  }}
                  {{ graduatedStudentJobTrackingReport.verifymanager_name }}
                  {{ graduatedStudentJobTrackingReport.verifymanager_surname }}
                </label>
                <label v-else>ชื่อผู้รับรองรายงาน : - </label>
              </div>
              <div>
                <label v-if="graduatedStudentJobTrackingReport.sender_name"
                  >ชื่อผู้บันทึกและส่งรายงาน :
                  {{ graduatedStudentJobTrackingReport.sender_name }}
                  {{ graduatedStudentJobTrackingReport.sender_lastname }}
                </label>
                <label v-else>ชื่อผู้บันทึกและส่งรายงาน : - </label>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { formatDateThai } from "@/DateTimeHelper";
import moment from "moment";
import "moment-timezone";
import GraduatedStudentJobTrackingReport_JobTracking_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_JobTracking_Status_Label.vue";
const graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
export default {
  name: "GraduatedStudentJobTrackingReportInfo",
  components: { GraduatedStudentJobTrackingReport_JobTracking_Status_Label },
  props: ["oid", "graduatedStudentJobTrackingReport"],
  data() {
    return {
      isExpand: 0,
      moment: moment,
      Oid: "",
      quantityOfStudentDetail: undefined,
      dateOptions: { year: "numeric", month: "long", day: "numeric" },
      countDown: 10,
      countDownDate: undefined,

      current_time: undefined,
      timeleft: undefined,
      timeRemaining: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,

      isCountingForTrackingStartDate: false,
      isCountingForTrackingEndDate: false,
      TimeOutid: undefined,
      resultnontracking: undefined,
      activeButton: null,
    };
  },
  computed: {
    importstudentdata_status_dynamicIcon() {
      if (this.graduatedStudentJobTrackingReport == undefined) {
        return "";
      }
      switch (
        this.graduatedStudentJobTrackingReport
          .importstudentdata_status_statuscode
      ) {
        case "WaitingToStartImportingProcess":
          return "mdi-progress-clock";
        case "Imported":
          return "mdi-check-circle";
        case "NoGraduateStudent":
          return "mdi-account-alert";
        default:
          return "";
      }
    },
    importstudentdata_status_dynamicColor() {
      if (this.graduatedStudentJobTrackingReport == undefined) {
        return "";
      }
      switch (
        this.graduatedStudentJobTrackingReport
          .importstudentdata_status_statuscode
      ) {
        case "WaitingToStartImportingProcess":
          return "info";
        case "Imported":
          return "success";
        case "NoGraduateStudent":
          return "warning";
        default:
          return "";
      }
    },
    jobtrackingreport_status_dynamicIcon() {
      if (this.graduatedStudentJobTrackingReport == undefined) {
        return "";
      }
      switch (
        this.graduatedStudentJobTrackingReport.jobtracking_status_statuscode
      ) {
        case "NotStartedTrackingProcess":
          return "mdi-clock-edit";
        case "InProcessOfTracking":
          return "mdi-progress-pencil";
        case "Verified":
          return "mdi-check-decagram";
        case "NotInTime":
          return "mdi-timer-sand-complete";
        case "AllowedToEdit":
          return "mdi-folder-edit";
        default:
          return "";
      }
    },
    jobtrackingreport_status_dynamicColor() {
      if (this.graduatedStudentJobTrackingReport == undefined) {
        return "";
      }
      switch (
        this.graduatedStudentJobTrackingReport.jobtracking_status_statuscode
      ) {
        case "NotStartedTrackingProcess":
          return "grey";
        case "InProcessOfTracking":
          return "info";
        case "Verified":
          return "success";
        case "NotInTime":
          return "error";
        case "AllowedToEdit":
          return "info";
        default:
          return "";
      }
    },
  },
  methods: {
    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },

    configdatetimecondition() {
      this.current_time = new Date(
        this.graduatedStudentJobTrackingReport.currentdatetime
      );
      let start_time = new Date(
        this.graduatedStudentJobTrackingReport.trackingstartdate
      );
      let end_time = new Date(
        this.graduatedStudentJobTrackingReport.trackingenddate
      );
      if (this.current_time < start_time) {
        this.isCountingForTrackingStartDate = true;
        this.countDownRemainingDate(start_time);
      } else {
        if (this.current_time < end_time) {
          this.isCountingForTrackingEndDate = true;
          this.countDownRemainingDate(end_time.setDate(end_time.getDate() + 1));
        }
      }
    },

    countDownRemainingDate(target) {
      const now = new Date();
      const distance = target - now;
      if (distance < 0) {
        this.timeRemaining = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      } else {
        this.TimeOutid = setTimeout(() => {
          this.timeRemaining = {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
          };
          this.countDownRemainingDate(target);
        }, 1000);
      }
    },
    loadnontrackingreport() {
      this.resultnontracking =
        this.graduatedStudentJobTrackingReport
          .quantityofgraduatedstudentjobtrackingreport_studentdetails -
        this.graduatedStudentJobTrackingReport.countstudentjobtracked;
    },
    onFilteringGraduatedStudentJobTrackingReportInfo(isTracked = false) {
      this.$emit("onFilteringGraduatedStudentJobTrackingReportInfo", isTracked);
    },
    setActive(buttonNumber) {
      this.activeButton = buttonNumber;
    },
  },
  watch: {
    "graduatedStudentJobTrackingReport.trackingstartdate"(newVal) {
      this.isCountingForTrackingStartDate = false;
      this.isCountingForTrackingEndDate = false;
      this.graduatedStudentJobTrackingReport.trackingstartdate = newVal;
      clearTimeout(this.TimeOutid);
      this.configdatetimecondition();
    },
    "graduatedStudentJobTrackingReport.trackingenddate"(newVal) {
      this.isCountingForTrackingStartDate = false;
      this.isCountingForTrackingEndDate = false;
      this.graduatedStudentJobTrackingReport.trackingenddate = newVal;
      clearTimeout(this.TimeOutid);
      this.configdatetimecondition();
    },
  },
  mounted() {
    this.loadnontrackingreport();
    this.configdatetimecondition();
    console.log(this.graduatedStudentJobTrackingReport);
  },
};
</script>

<style></style>
