import { NavigationItemStructure } from "./NavigationItemStructure";
import { NavigationCategory } from "@/Constants";

export function getMainNavigationIconByNavigationNames(navName = "") {
  var navNames = navName.split("|");
  var rootNavName = navNames[0];
  switch (rootNavName) {
    case NavigationItems.mainNavigation_MasterData_Name:
      return NavigationItems.mainNavigation_MasterData_Icon;
    case NavigationItems.mainNavigation_Report_Name:
      return NavigationItems.mainNavigation_Report_Icon;
    case NavigationItems.mainNavigation_Employer_Name:
      return NavigationItems.mainNavigation_Employer_Icon;
    case NavigationItems.mainNavigation_Setup_Name:
      return NavigationItems.mainNavigation_Setup_Icon;
    case NavigationItems.mainNavigation_FindingJob_Name:
      return NavigationItems.mainNavigation_FindingJob_Icon;
    case NavigationItems.mainNavigation_FindingStudent_Name:
      return NavigationItems.mainNavigation_FindingStudent_Icon;
    case NavigationItems.mainNavigation_AnnouncementJob_Name:
      return NavigationItems.mainNavigation_AnnouncementJob_Icon;
    case NavigationItems.mainNavigation_JobPost_Name:
      return NavigationItems.mainNavigation_JobPost_Icon;
    case NavigationItems.mainNavigation_JobTracking_Name:
      return NavigationItems.mainNavigation_JobTracking_Icon;
    case NavigationItems.mainNavigation_JobPosting_Name:
      return NavigationItems.mainNavigation_JobPosting_Icon;
    case NavigationItems.mainNavigation_Tracking_Name:
      return NavigationItems.mainNavigation_Tracking_Icon;
    case NavigationItems.mainNavigation_News_Name:
      return NavigationItems.mainNavigation_News_Icon;
  }
}

export class NavigationItems {
  static mainNavigation_MasterData_Icon = "mdi-database-cog";
  static mainNavigation_MasterData_Name = NavigationCategory.masterData;
  static mainNavigation_Report_Icon = "mdi-chart-line";
  static mainNavigation_Report_Name = NavigationCategory.report;
  static mainNavigation_Employer_Icon = "mdi-account-cog-outline";
  static mainNavigation_Employer_Name = NavigationCategory.userRole;
  static mainNavigation_Setup_Icon = "mdi-cog";
  static mainNavigation_Setup_Name = "ตั้งค่า";
  static mainNavigation_FindingJob_Icon = "mdi-briefcase-search-outline";
  static mainNavigation_FindingJob_Name = "ค้นหางาน";
  static mainNavigation_FindingStudent_Icon = "mdi-file-account-outline";
  static mainNavigation_FindingStudent_Name = "ประวัติคนหางาน";
  static mainNavigation_AnnouncementJob_Icon = "mdi-file-account-outline";
  static mainNavigation_AnnouncementJob_Name = "ประกาศงานหาคน";
  static mainNavigation_JobPost_Icon = "mdi-file-search-outline";
  static mainNavigation_JobPost_Name =
    NavigationCategory.workforceNetworkCenter;
  static mainNavigation_JobTracking_Icon = "mdi-file-clock-outline";
  static mainNavigation_JobTracking_Name = "รายงานการติดตามภาวะการมีงานทำ";
  static mainNavigation_JobPosting_Icon = "mdi-file-document-outline";
  static mainNavigation_JobPosting_Name = "ประกาศรับสมัครงาน";
  static mainNavigation_Tracking_Icon = "mdi-home-city";
  static mainNavigation_Tracking_Name =
    NavigationCategory.employmentStatusTrackingSystem;

  static mainNavigation_News_Icon = "mdi-newspaper-variant-outline";
  static mainNavigation_News_Name = "จัดการข่าวประชาสัมพันธ์";

  //Dashboard

  static Dashboard_Nav_DashboardView_ForAdmin = new NavigationItemStructure(
    "mdi-monitor-dashboard",
    "/Dashboard/DashboardView_ForAdmin",
    "ภาพรวม"
  );
  static Dashboard_Nav_DashboardView_Manager = new NavigationItemStructure(
    "mdi-monitor-dashboard",
    "Dashboard/DashboardView_Manager",
    "ภาพรวม"
  );
  static Dashboard_Nav_DashboardView_SchoolStaff = new NavigationItemStructure(
    "mdi-monitor-dashboard",
    "/Dashboard/DashboardView_SchoolStaff",
    "ภาพรวม"
  );

  //school
  static School_Nav_SchoolListView = new NavigationItemStructure(
    "mdi-town-hall",
    "/School/SchoolListView",
    "สถานศึกษา"
  );

  static School_Nav_StudentListView = new NavigationItemStructure(
    "mdi-account-group-outline",
    "/School/SchoolStudentListView",
    "รายชื่อนักศึกษา"
  );

  //Graduated student
  static SchoolGraduatedStudent_Nav_SchoolGraduatedStudent_ListView =
    new NavigationItemStructure(
      "mdi-account-group",
      "/GraduatedStudent/GraduatedStudentListView",
      "นักศึกษาที่สำเร็จการศึกษา"
    );

  //SchoolEmployee
  static SchoolEmployee_Nav_SchoolEmployer_Listview =
    new NavigationItemStructure(
      "mdi-account-group-outline",
      "/SchoolEmployee/SchoolEmployee_SchoolListView",
      "รายชื่อบุคลากร"
    );

  //SchoolGraduatedStudent
  static SchoolGraduatedStudent_Nav_SchoolGraduatedStudent_ListView =
    new NavigationItemStructure(
      "mdi-account-group-outline",
      "/graduatedstudent/SchoolGraduatedStudentListView",
      "รายชื่อนักศึกษาที่สำเร็จการศึกษา"
    );

  //Graduated student job tracking report
  static GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportMainListView =
    new NavigationItemStructure(
      "mdi-file-document-outline",
      "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainListView",
      "สร้างรายงานการติดตาม"
    );
  static GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportListView =
    new NavigationItemStructure(
      "mdi-file-document-outline",
      "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView",
      "รายงานการติดตาม"
    );
  static GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportListView_GuidanceTeacher_InProcess =
    new NavigationItemStructure(
      "mdi-timer-sand",
      "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_GuidanceTeacher_InProcess",
      "รายงานการติดตามภาวะการมีงานทำ|กำลังดำเนินการ"
    );
  static GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified =
    new NavigationItemStructure(
      "mdi-check-circle",
      "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified",
      "รายงานการติดตามภาวะการมีงานทำ|รับรองรายงานแล้ว"
    );
  static GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportListView_ForCertification =
    new NavigationItemStructure(
      "mdi-clipboard-check-outline",
      "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_ForCertification",
      "การรับรอง"
    );

  static VerifyManager_Nav_VerifyManagerListView = new NavigationItemStructure(
    "mdi-account-tie",
    "/VerifyManager/VerifyManagerListView",
    "รายชื่อผู้รับรอง"
  );

  //Main navigations.

  //Report navigations
  static Report_Nav_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report_DetailView",
      `${NavigationCategory.report}|รายงานภาวะการมีงานทำ (รายภาค)`
    );
  static Report_Nav_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report_DetailView",
      `${NavigationCategory.report}|รายงานภาวะการมีงานทำ (รายจังหวัด)`
    );
  static Report_Nav_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report_DetailView",
      `${NavigationCategory.report}|รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)`
    );
  static Report_Nav_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView",
      `${NavigationCategory.report}|รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)`
    );

  static Report_Nav_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report_DetailView",
      `${NavigationCategory.report}|รายงานภาวะการมีงานทำ (รายสถานศึกษา)`
    );
  static Report_Nav_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
      `${NavigationCategory.report}|รายงานภาวะการมีงานทำ (รายประเภทวิชา)`
    );
  static Report_Nav_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView",
      `${NavigationCategory.report}|รายงานการติดตามภาวะผู้มีงานทำของสถานศึกษา (จำแนกตามสาขาวิชา)`
    );
  static Report_Nav_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView",
      `${NavigationCategory.report}|รายงานสรุปผลการติดตาม (รายบุคคล)`
    );
  static Report_Nav_School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
      `${NavigationCategory.report}|รายงานภาวะการมีงานทำ (รายประเภทวิชา)`
    );
  static Report_Nav_School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView",
      `${NavigationCategory.report}|รายงานการติดตามภาวะผู้มีงานทำของสถานศึกษา (จำแนกตามสาขาวิชา)`
    );

    static Report_Nav_ReportGroupListView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/ReportGroupListView",
      "รายงาน"
    );

  static Report_Nav_School_SchoolStudentList_Report =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/School_SchoolStudentList_Report",
      "รายงาน|รายงานรายชื่อนักศึกษา"
    );

  static Report_Nav_SchoolStudentList_Report = new NavigationItemStructure(
    "mdi-file-chart-outline",
    "/Report/SchoolStudentList_Report",
    "รายงาน|รายงานรายชื่อนักศึกษา"
  );
  static Report_Nav_School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView",
      `${NavigationCategory.report}|รายงานสรุปผลการติดตาม (รายบุคคล)`
    );
  static Report_Nav_GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince_Report_DetailView",
      `${NavigationCategory.report}|รายงานสรุปผลการส่งรายงาน รายจังหวัด`
    );
  static Report_Nav_GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion_Report_DetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion_Report_DetailView",
      `${NavigationCategory.report}|รายงานสรุปผลการส่งรายงาน รายภาค`
    );

  static Report_Nav_School_Report1_DetailView = new NavigationItemStructure(
    "mdi-file-chart-outline",
    "/Report/School_Reports/School_Report1_DetailView",
    `${NavigationCategory.report}|รายชื่อนักเรียนผู้สำเร็จการศึกษา`
  );
  static Report_Nav_School_Report2_DetailView = new NavigationItemStructure(
    "mdi-file-chart-outline",
    "/Report/School_Reports/School_Report2_DetailView",
    `${NavigationCategory.report}|รายงานสรุปผลการติดตาม (รายบุคคล)`
  );
  static Report_Nav_School_Report3_DetailView = new NavigationItemStructure(
    "mdi-file-chart-outline",
    "/Report/School_Reports/School_Report3_DetailView",
    `${NavigationCategory.report}|รายงานสถิตินักเรียนที่สำเร็จการศึกษา (จำแนกตามสาขาวิชา)`
  );
  static Report_Nav_School_Report4_DetailView = new NavigationItemStructure(
    "mdi-file-chart-outline",
    "/Report/School_Reports/School_Report4_DetailView",
    `${NavigationCategory.report}|รายงานสถิตินักเรียนผู้พิการที่สำเร็จการศึกษา (จำแนกตามสาขาวิชา)`
  );
  static Report_Nav_School_Report5_DetailView = new NavigationItemStructure(
    "mdi-file-chart-outline",
    "/Report/School_Reports/School_Report5_DetailView",
    `${NavigationCategory.report}|รายงานการติดตามภาวะผู้มีงานทำของสถานศึกษา (จำแนกตามสาขาวิชา)`
  );

  //Master data
  static MasterData_Nav_ProvinceListView = new NavigationItemStructure(
    "mdi-book-check-outline",
    "/Province/ProvinceListView",
    `${NavigationCategory.masterData}|จังหวัด`
  );
  static MasterData_Nav_CurriculumListView = new NavigationItemStructure(
    "mdi-book-check-outline",
    "/Curriculum/CurriculumListView",
    `${NavigationCategory.masterData}|หลักสูตร`
  );
  static MasterData_Nav_SubjectTypeListView = new NavigationItemStructure(
    "mdi-book-outline",
    "/SubjectType/SubjectTypeListView",
    `${NavigationCategory.masterData}|ประเภทวิชา`
  );
  static MasterData_Nav_MajorListView = new NavigationItemStructure(
    "mdi-book-outline",
    "/Major/MajorListView",
    `${NavigationCategory.masterData}|สาขาวิชา`
  );
  static MasterData_Nav_MinorListView = new NavigationItemStructure(
    "mdi-book-outline",
    "/Minor/MinorListView",
    `${NavigationCategory.masterData}|สาขางาน`
  );
  static MasterData_Nav_SalaryrangesListView = new NavigationItemStructure(
    "mdi-book-outline",
    "/Salaryranges/SalaryrangesListView",
    `${NavigationCategory.masterData}|ระดับเงินเดือน`
  );
  static MasterData_Nav_EmployerMainBusinessTypesListView =
    new NavigationItemStructure(
      "mdi-book-outline",
      "/EmployerMainBusinessTypes/EmployerMainBusinessTypesListView",
      `${NavigationCategory.masterData}|ประเภทวิสาหกิจ`
    );
  static MasterData_Nav_TitleNamesListView = new NavigationItemStructure(
    "mdi-book-outline",
    "/TitleNames/TitleNamesListView",
    `${NavigationCategory.masterData}|คำนำหน้าชื่อ`
  );
  static MasterData_Nav_NationalitiesListView = new NavigationItemStructure(
    "mdi-book-outline",
    "/Nationalities/NationalitiesListView",
    `${NavigationCategory.masterData}|สัญชาติ`
  );
  static MasterData_Nav_ReligionsListView = new NavigationItemStructure(
    "mdi-book-outline",
    "/Religions/ReligionsListView",
    `${NavigationCategory.masterData}|ศาสนา`
  );
  static MasterData_Nav_JobSegmentListView = new NavigationItemStructure(
    "mdi-book-outline",
    "/JobSegment/JobSegmentListView",
    `${NavigationCategory.masterData}|ประเภทงาน`
  );

  //Employer
  static Employer_Nav_EmployerProfileListView = new NavigationItemStructure(
    "mdi-home-city",
    "/Employer/EmployerProfileListView",
    "สถานประกอบการ"
  );
  static EmployerSummary_Nav_EmployerSummaryListView =
    new NavigationItemStructure(
      "mdi-home-city",
      "/EmployerSummary/EmployerSummaryListView",
      "ข้อมูลตั้งต้นสถานประกอบการ/สถานศึกษา"
    );

  static Employer_Nav_EmployerEditTheirProfileView =
    new NavigationItemStructure(
      "mdi-account-circle",
      "/Employer/EmployerEditTheirProfileView",
      "ข้อมูลส่วนตัว"
    );

  //Tracking
  static Tracking_Nav_SchoolListView = new NavigationItemStructure(
    "mdi-town-hall",
    "/School/SchoolListView",
    `${NavigationCategory.employmentStatusTrackingSystem}|สถานศึกษา`
  );

  static Tracking_Nav_SchoolEmployeeSchoolListView =
    new NavigationItemStructure(
      "mdi-account-group-outline",
      "/SchoolEmployee/SchoolEmployee_SchoolListView",
      `${NavigationCategory.employmentStatusTrackingSystem}|รายชื่อบุคลากร`
    );

  static Tracking_Nav_SchoolGraduatedStudentListView =
    new NavigationItemStructure(
      "mdi-account-group-outline",
      "/graduatedstudent/SchoolGraduatedStudentListView",
      `${NavigationCategory.employmentStatusTrackingSystem}|รายชื่อนักศึกษาที่สำเร็จการศึกษา`
    );

  static Tracking_Nav_GraduatedStudentJobTrackingReportMainListView =
    new NavigationItemStructure(
      "mdi-file-document-outline",
      "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainListView",
      `${NavigationCategory.employmentStatusTrackingSystem}|สร้างรายงานการติดตาม`
    );

  static Tracking_Nav_ConcludeEmploymentStatusByRegionReportDetailView =
    new NavigationItemStructure(
      "mdi-file-chart-outline",
      "/Report/ReportGroupListView",
      `${NavigationCategory.employmentStatusTrackingSystem}|รายงาน`
    );

    

  //JopPosting

  static Center_Nav_CenterStaffJobPostingListView = new NavigationItemStructure(
    "mdi-file-document-outline",
    "/JobPosting/CenterStaffJobPostingListView",
    `${NavigationCategory.workforceNetworkCenter}|ข้อมูลประกาศงาน`
  );
  static Center_Nav_CenterStaffJobApplicationListView =
    new NavigationItemStructure(
      "mdi-account-file-text",
      "/JobPosting/CenterStaffJobApplicationListView",
      `${NavigationCategory.workforceNetworkCenter}|ข้อมูลใบสมัครงาน`
    );

  static Center_Nav_EmployerProfileListView = new NavigationItemStructure(
    "mdi-home-city",
    "/Employer/EmployerProfileListView",
    `${NavigationCategory.workforceNetworkCenter}|สถานประกอบการ`
  );

  // static Employer_Nav_EmployerEditTheirProfileView = new NavigationItemStructure('mdi-account-circle','/RegisterEmployer/UpdateRegisterEmployer','ข้อมูลส่วนตัว');
  // static Employer_Nav_SearchStudentResumeListView = new NavigationItemStructure('mdi-book-outline','/Employer/Employer_Nav_SearchStudentResumeListView','ค้นหานักศึกษาตามคุณสมบัติ');
  static Employer_Nav_SearchStudentResumeListView = new NavigationItemStructure(
    "mdi-account-search",
    "/SearchStudentResume/SearchStudentResumeListView",
    "ประวัติคนหางาน|ค้นประวัติคนหางาน"
  );
  static Employer_Nav_EmployerBookmarkedStudentListView =
    new NavigationItemStructure(
      "mdi-heart",
      "/Employer/EmployerBookmarkedStudentListView",
      "ประวัติคนหางาน|ประวัติคนหางานที่เก็บไว้"
    );

  static Employer_Nav_JobPostingListView_Draft = new NavigationItemStructure(
    "mdi-clipboard-text",
    "/Employer/EmployerJobPostingListView_Draft",
    "ประกาศงานหาคน|ประกาศรับสมัครงาน"
  );
  static Employer_Nav_JobPostingListView_InProcess =
    new NavigationItemStructure(
      "mdi-progress-clock",
      "/Employer/EmployerJobPostingListView_InProcess",
      "ประกาศงานหาคน|อยู่ระหว่างรับสมัคร"
    );
  static Employer_Nav_JobPostingListView_Expired = new NavigationItemStructure(
    "mdi-timer-sand-complete",
    "/Employer/EmployerJobPostingListView_Expired",
    "ประกาศงานหาคน|หมดอายุ"
  );
  static Employer_Nav_JobPostingListView_History = new NavigationItemStructure(
    "mdi-database",
    "/Employer/EmployerJobPostingListView_History",
    "ประกาศงานหาคน|ประวัติประกาศงาน"
  );

  static School_Partner_Officer_Nav_EmployerProfileListView =
    new NavigationItemStructure(
      "mdi-home-city",
      "/Employer/SchoolPartnerOfficer_EmployerProfileListView",
      "สถานประกอบการ"
    );

  //SchoolEmployeeProfile
  static SchoolEmployeeProfile_CurrentSchoolEmployeeProfile =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/Current_SchoolEmployeeProfileDetailView",
      "ข้อมูลส่วนตัว"
    );

  //User_Roles
  static UserRole_Nav_AdministratorProfileListView =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/AdministratorProfileListView",
      `${NavigationCategory.userRole}|Administrators`
    );

  static UserRole_Nav_SchoolEmployeeProfileListView =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView",
      `${NavigationCategory.userRole}|ผู้ใช้งานระบบทั้งหมด`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_CenterAdmin =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_Center_Admin",
      `${NavigationCategory.userRole}|ผู้ดูแลระบบส่วนกลาง`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_Center_Officer =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_Center_Officer",
      `${NavigationCategory.userRole}|บุคลากรส่วนกลาง`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_Center_Manager =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_Center_Manager",
      `${NavigationCategory.userRole}|ผู้บริหารส่วนกลาง`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_School_Manager =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_School_Manager",
      `${NavigationCategory.userRole}|ผู้บริหารสถานศึกษา`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_School_Admin =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_School_Admin",
      `${NavigationCategory.userRole}|บุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_School_Partner_Officer =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_School_Partner_Officer",
      `${NavigationCategory.userRole}|บุคลากรในสถานศึกษา (ทวิภาคี)`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_School_Guidance_Teacher =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_School_Guidance_Teacher",
      `${NavigationCategory.userRole}|บุคลากรในสถานศึกษา (ครูแนะแนว)`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_ProvinceSchool_Officer =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_ProvinceSchool_Officer",
      `${NavigationCategory.userRole}|บุคลากรในอาชีวศึกษาจังหวัด`
    );
  static UserRole_Nav_SchoolEmployeeProfileListView_Insitution_Officer =
    new NavigationItemStructure(
      "mdi-account-cog-outline",
      "/Profile/SchoolEmployeeProfileListView_Insitution_Officer",
      `${NavigationCategory.userRole}|บุคลากรในสถาบันการอาชีวศึกษา`
    );
  static UserRole_Nav_ProfileOverview = new NavigationItemStructure(
    "mdi-account-cog-outline",
    "/Profile/ProfileOverview",
    `${NavigationCategory.userRole}|บุคลากรทั้งหมด`
  );

  //School admin user role navigation items.
  static SchoolAdmin_Nav_SchoolEmployeeProfileListView_School_Manager =
    new NavigationItemStructure(
      "mdi-account-cog",
      "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Manager",
      `${NavigationCategory.userRole}|ผู้บริหารสถานศึกษา`
    );
  static SchoolAdmin_Nav_SchoolEmployeeProfileListView_School_Admin =
    new NavigationItemStructure(
      "mdi-account-cog",
      "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Admin",
      `${NavigationCategory.userRole}|บุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)`
    );
  static SchoolAdmin_Nav_SchoolEmployeeProfileListView_School_Partner_Officer =
    new NavigationItemStructure(
      "mdi-account-cog",
      "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Partner_Officer",
      `${NavigationCategory.userRole}|บุคลากรในสถานศึกษา (ทวิภาคี)`
    );
  static SchoolAdmin_Nav_SchoolEmployeeProfileListView_School_Guidance_Teacher =
    new NavigationItemStructure(
      "mdi-account-cog",
      "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Guidance_Teacher",
      `${NavigationCategory.userRole}|บุคลากรในสถานศึกษา (ครูแนะแนว)`
    );
  //Institute
  static Institute_Nav_InstituteListView = new NavigationItemStructure(
    "mdi-folder-home-outline",
    "/Institute/InstituteListView",
    "สถาบันการอาชีวศึกษา"
  );

  //Student

  static Student_Nav_StudentDetailOverview = new NavigationItemStructure(
    "mdi-account",
    `/SchoolStudent/StudentDetailOverview`,
    "ข้อมูลส่วนตัว"
  );
  static Student_Nav_StudentEditProfileView = new NavigationItemStructure(
    "mdi-account",
    `/Student/StudentEditProfileView/oid`,
    "ข้อมูลส่วนตัว"
  );
  static Student_Nav_StudentSearchJobPostingView = new NavigationItemStructure(
    "mdi-file-find",
    "/Student/StudentSearchJobPostingView",
    "ค้นหางาน|ค้นหาตำแหน่งงาน/ฝึกงาน"
  );
  static Student_Nav_StudentSearchJobPosting_Argent_View =
    new NavigationItemStructure(
      "mdi-file-plus",
      "/Student/StudentSearchJobPosting_Argent_View",
      "ค้นหางาน|ตำแหน่งงานที่รับสมัครด่วน"
    );
  static Student_Nav_StudentSearchJobPosting_Monthly_View =
    new NavigationItemStructure(
      "mdi-calendar-clock",
      "/Student/StudentSearchJobPosting_Monthly_View",
      "ค้นหางาน|ตำแหน่งงานใหม่ประจำเดือน"
    );
  static Student_Nav_RegisteredPostingJobListView = new NavigationItemStructure(
    "mdi-file-check",
    "/Student/StudentTrackCurrentJobApplicationsView",
    "ตำแหน่งงานที่สมัคร"
  );
  static Student_Nav_StudentAndEmployerInteractions =
    new NavigationItemStructure(
      "mdi-email",
      "/Student/StudentAndEmployerInteractions",
      "การติดต่อจากสถานประกอบการ"
    );
  static Student_Nav_BookmarkedJobPostings_ListView =
    new NavigationItemStructure(
      "mdi-heart",
      "/Student/StudentBookmarkedJobPostings_ListView",
      "ตำแหน่งงานที่สนใจ"
    );

  //Setup
  static Setup_Nav_DefaultJobPostingSetupDetailView =
    new NavigationItemStructure(
      "mdi-cog",
      "/DefaultJobPostingSetup/DefaultJobPostingSetupDetailView",
      "ตั้งค่า|ตั้งค่าเกี่ยวกับการประกาศงาน"
    );
  static Setup_Nav_EmailConfigurationDetailView = new NavigationItemStructure(
    "mdi-email",
    "/EmailConfiguration/EmailConfigurationDetailView",
    "ตั้งค่า|ตั้งค่าเกี่ยวกับอีเมล"
  );

  static Setup_Nav_NotifySetup = new NavigationItemStructure(
    "mdi-cog",
    "/Notification/NotifySetup",
    "ตั้งค่า|ตั้งค่าการแจ้งเตือน"
  );

  //Notification
  static Notification_Nav_CommonNotification = new NavigationItemStructure(
    "mdi-bullhorn",
    "/Notification/CommonNotifyRequest",
    "ประกาศแจ้งเตือนทั่วไป"
  );
  //News
  static NewsManagementListView = new NavigationItemStructure(
    "mdi-post-outline",
    "/News/NewsManagementListView",
    "จัดการข่าวประชาสัมพันธ์| จัดการข่าวประชาสัมพันธ์"
  );

  static NewsManagementListView_SchoolEmployee = new NavigationItemStructure(
    "mdi-post-outline",
    "/News/NewsManagementListView_SchoolEmployee",
    "จัดการข่าวประชาสัมพันธ์| ข่าวประชาสัมพันธ์ภายในสถานศึกษา"
  );
  //Notification
  static Notification_Nav_CommonNotification = new NavigationItemStructure(
    "mdi-bullhorn",
    "/Notification/CommonNotifyRequest",
    "ประกาศแจ้งเตือนทั่วไป"
  );

  //Search
  static Search_Nav_SearchStudent = new NavigationItemStructure(
    "mdi-magnify",
    "/Search/SearchStudentList",
    "ค้นหานักศึกษา"
  );
}
