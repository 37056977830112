import MajorDetailView from "@/views/Major/MajorDetailView.vue";
import MajorListView from "@/views/Major/MajorListView.vue";

export default [
  {
    path: "/Major",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Major/MajorListView",
        name: "MajorListView",
        component: MajorListView,
        meta: {
          requiresAuth: true,
          nameTh: "สาขาวิชา",
        },
      },
      {
        path: "/Major/MajorDetailView/:majorID",
        name: "MajorDetailView",
        component: MajorDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดสาขาวิชา",
        },
      },
    ],
  },
];
