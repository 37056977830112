import VerifyManagerListView from "@/views/VerifyManager/VerifyManagerListView.vue";

export default [
  {
    path: "/VerifyManager",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/VerifyManager/VerifyManagerListView",
        name: "VerifyManagerListView",
        component: VerifyManagerListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายชื่อผู้รับรองรายงานการติดตามภาวะการมีงานทำ",
        },
      },
    ],
  },
];
