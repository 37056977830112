<template>
  <div class="pa-4 mt-2">
    <v-app-bar>
      <h3 class="primary--text">หน้าแสดงสถานะงานเบื้องหลัง</h3>
    </v-app-bar>
    <v-container>
      <template>
        <v-item-group v-model="selected">
          <div>
            <v-row>
              <v-col
                v-for="item in items"
                :key="item.key"
                cols="12"
                md="3"
                @click="clickAction(item.key)"
              >
                <v-item v-slot:default="{ active, toggle }">
                  <v-card
                    :color="active ? '' : ''"
                    class="d-flex align-center"
                    @click="toggle($event), clickAction"
                    :flat="!active"
                  >
                    <v-list-item :style="{ 'background-color': item.background }">
                      <v-list-item-avatar :color="item.color">
                        <v-icon style="font-size: 30px" :dark="active">{{
                          item.icon
                        }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="headline">{{
                          item.title
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >จำนวน: <b>{{ item.key }}</b></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-scroll-y-transition>
                      <div v-if="active" class="display-3 flex-grow-1 text-center"></div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
            <div>
              <SearchableDataTable
                :data_items="service"
                :datatable_headers="datatable_headers"
                class="elevation-1"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip
                    class="ma-2"
                    :color="getColor(item.status)"
                    dark
                    v-text="item.status"
                  >
                  </v-chip>
                </template>

                <template class="d‑flex" v-slot:item.action="{ item }">
                  <v-tooltip v-if="isActive" top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="black"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="isActive = !isActive"
                      >
                        <v-icon>mdi-pause</v-icon>
                      </v-btn>
                    </template>
                    <span>pause</span>
                  </v-tooltip>
                  <v-tooltip v-else-if="!isActive" top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="isActive = !isActive"
                      >
                        <v-icon>mdi-play</v-icon>
                      </v-btn>
                    </template>
                    <span>start</span>
                  </v-tooltip>
                  <v-tooltip top color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteService(item)"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>ยกเลิกงาน</span>
                  </v-tooltip>
                </template>
              </SearchableDataTable>
            </div>

            <RecurringJob
              v-if="$store.getters['username'] == 'administrator'"
            ></RecurringJob>
            <TrackingAllStatusjobs
              v-if="$store.getters['username'] == 'administrator'"
            ></TrackingAllStatusjobs>
          </div>
        </v-item-group>
      </template>
    </v-container>
  </div>
</template>
<script>
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
var graduatedStudentJobTrackingReportReportDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import RecurringJob from "@/components/BackgroundService/RecurringJob.vue";
import TrackingAllStatusjobs from "@/components/BackgroundService/TrackingAllStatusjobs.vue";
export default {
  components: { SearchableDataTable, RecurringJob, TrackingAllStatusjobs },
  name: "BackgroundService",
  data() {
    return {
      datatable_headers: [
        { value: "servicename", text: "รายการงานเบื้องหลัง" },
        { value: "cratedate", text: "วันที่สร้าง" },
        { value: "status", text: "สถานะ" },
        { value: "action", text: "" },
      ],
      items: [
        {
          background: "#fbd351",
          title: "Total",
          icon: "mdi-playlist-check",
          key: "10",
        },
        {
          background: "#50c021",
          title: "Success",
          icon: "mdi-checkbox-multiple-marked-circle-outline",
          key: "7",
        },
        {
          background: "#f59515",
          title: "In progress",
          icon: "mdi-cached",
          key: "2",
        },
        {
          background: "#f43e3e",
          title: "Fail",
          icon: "mdi-alert-circle-outline",
          key: "1",
        },
      ],
      isLoading: false,
      isActive: true,
      service: [],
      selected: "",
    };
  },
  created() {
    this.initialize();
  },
  async mounted() {},
  methods: {
    clickAction(item) {},
    initialize() {
      this.service = [
        {
          servicename: "สร้างรายงานการติดตาม",
          cratedate: "12-03-2566",
          status: "Success",
        },
        {
          servicename: "นำเข้าข้อมูลนักศึกษา",
          cratedate: "13-03-2566",
          status: "Fail",
        },
        {
          servicename: "นำเข้าข้อมูลบุคลากร",
          cratedate: "14-03-2566",
          status: "In progress",
        },
      ];
    },
    getColor(status) {
      if (status == "Success") return "green";
      else if (status == "In progress") return "orange";
      else return "red";
    },
    deleteService(item) {
      this.service.splice(this.service.indexOf(item.servicename));
    },
  },
};
</script>
<style scope>
.background1 {
  background: linear-gradient(#a4e887, 367e18);
}
</style>
