s
<template>
  <div class="pa-4 mb-5 mt-5">
    <GraduatedStudentJobTrackingReportInfo
      v-if="graudatedStudentJobTrackingReport"
      :graduatedStudentJobTrackingReport="graudatedStudentJobTrackingReport"
      @onFilteringGraduatedStudentJobTrackingReportInfo="
        onFilteringGraduatedStudentJobTrackingReportInfo
      "
    >
    </GraduatedStudentJobTrackingReportInfo>

    <div>
      <v-card rounded elevation="1" class="pa-4 my-5">
        <div class="mx-5">
          <v-row v-if="related_CertificateTypes.length > 0">
            <v-col lg="12" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">ระดับชั้น</h4>
              <v-row no-gutters align="center" wrap>
                <v-btn
                  :color="selectedCertificateTypeCode === '' ? 'primary' : ''"
                  @click="
                    selectedCertificateTypeCode = '';
                    onSelected();
                  "
                  class="mr-2 d-flex align-center py-0 mb-2"
                >
                  ทั้งหมด
                </v-btn>

                <v-col
                  v-for="(certificate, index) in related_CertificateTypes"
                  :key="certificate.code"
                  cols="auto"
                  class="d-flex mb-2"
                >
                  <v-btn
                    :color="
                      selectedCertificateTypeCode === certificate.code
                        ? 'primary'
                        : ''
                    "
                    @click="
                      selectedCertificateTypeCode = certificate.code;
                      onSelected();
                    "
                    class="mr-2"
                    style="background-color: #f2f4f8"
                  >
                    {{ certificate.nameth }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              lg="6"
              md="12"
              sm="12"
              xs="12"
              class="custom-col mb-0 pb-0"
              v-if="studentDetails"
            >
              <h4 class="primary--text mb-2">สาขาวิชา</h4>
              <v-autocomplete
                clearable
                :items="related_Majors"
                item-text="majornameth"
                item-value="majornameth"
                label="สาขาวิชา"
                @change="onSelected()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="request.selected_majornameth"
              >
              </v-autocomplete>
            </v-col>

            <v-col
              lg="6"
              md="12"
              sm="12"
              xs="12"
              class="custom-col mb-0 pb-0"
              v-if="studentDetails"
            >
              <h4 class="primary--text mb-2">สถานะการมีงานทำ</h4>
              <v-select
                clearable
                v-model="selectedEmploymentStatusCode"
                :items="related_EmploymentStatuses"
                label="สถานะการมีงานทำ"
                item-text="name"
                item-value="code"
                @change="onSelected()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <SearchableDataTablePaging
        table_caption="รายชื่อนักศึกษาที่ติดตาม"
        :data_items="studentDetails"
        :datatable_headers="studentDetails_headers"
        :itemlength="itemlength"
        :search="keyword"
        @onkeywordchanged="onKeywordChanged"
        @pageChanged="pageChanged"
        @tableOptionsChanged="tableOptionsChanged"
      >
        <template v-slot:item.employmentstatusname="{ item }">
          <v-text-field
            readonly
            v-model="item.employmentstatusname"
            :error-messages="
              isSentValidate == true ? item.employmentstatus_errormessage : ''
            "
          >
          </v-text-field>
        </template>
        <template v-slot:item.student_firstname="{ item }">
          <span>{{ item.student_firstname }} {{ item.student_surname }}</span>
        </template>
        <template v-slot:item.editdatedisplay="{ item }">
          <div v-if="item.editdatedisplay">
            <FormatDate :date_value="item.editdatedisplay"></FormatDate>
          </div>
        </template>
        <template v-slot:item.action="{ item, index }">
          <v-row class="custom-width">
            <v-btn
              color="warning"
              dark
              @click="
                (JobTracking_dialog = true), (selectedstudentoid = item.oid)
              "
              icon
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>

            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                  color="secondary"
                  @click="openStudentDetailView(item)"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <span>รายละเอียด</span>
            </v-tooltip></v-row
          >
        </template>
      </SearchableDataTablePaging>
    </div>

    <DialogFormJobTracking
      :dialog="JobTracking_dialog"
      :oid="selectedstudentoid"
      @closeDialogFormJobTracking="OnCloseDialogJobTracking"
    ></DialogFormJobTracking>

    <v-dialog v-model="report_dialog" max-width="500px">
      <!-- Dialog content -->
      <v-card>
        <v-card-title>
          <h3>รายชื่อรายงาน</h3>
        </v-card-title>
        <v-card-text>
          <ul>
            <li v-for="report in reports" :key="report.id">
              <a @click="onReportItemClick(report)" target="_blank">{{
                report.name
              }}</a>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="report_dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";

import DialogFormJobTracking from "@/components/GraduatedStudentJobTrackingReport/DialogFormJobTracking.vue";
import ExtendTrackingEndDate from "@/components/GraduatedStudentJobTrackingReport/ExtendTrackingEndDate.vue";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { GraduatedStudentJobTrackingReportValidator } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportValidator";
import { ExcelExportService } from "@/services/ExcelExportService";
import { formatDateThai } from "@/DateTimeHelper";
import GraduatedStudentJobTrackingReportInfo from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportInfo.vue";
// import XLSX from "xlsx";
// import { RoleCodes } from "@/Constants";
// XLSX = require("xlsx");
import moment from "moment";
import GraduatedStudentJobTrackingReport_JobTracking_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_JobTracking_Status_Label.vue";
import { GraduatedStudentJobTrackingReportsRequest } from "@/models/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReports_Request";
import { GraduatedStudentJobTrackingReportFilterOptionDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportFilterOptionDataProvider";
import FormatDate from "@/components/common/FormatDate.vue";
import { MajorDataProvider } from "@/services/MajorDataProvider";

const graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
const graduatedStudentJobTrackingReportValidator =
  new GraduatedStudentJobTrackingReportValidator(
    process.env.VUE_APP_BACKEND_API_URL
  );
const excelExportService = new ExcelExportService(
  process.env.VUE_APP_BACKEND_API_URL
);
const headers = { "Content-Type": "application/json" };

const graduatedStudentJobTrackingReportFilterOptionDataProvider =
  new GraduatedStudentJobTrackingReportFilterOptionDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );

const majorDataProvider = new MajorDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  components: {
    SearchableDataTable,
    SearchableDataTablePaging,
    DialogFormJobTracking,
    ExtendTrackingEndDate,
    GraduatedStudentJobTrackingReportInfo,
    GraduatedStudentJobTrackingReport_JobTracking_Status_Label,
    FormatDate,
  },
  name: "GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher_Verified",
  data() {
    return {
      show: true,
      moment: moment,
      jobTrackingReportOid: "",
      graudatedStudentJobTrackingReport: "",
      studentDetails_headers: [
        {
          value: "student_veceducationinformation_studentcode",
          text: "รหัสนักศึกษา",
        },
        { value: "student_firstname", text: "ชื่อ-นามสกุล" },
        {
          value: "student_veceducationinformation_gradenameth",
          text: "ระดับการศึกษา",
        },
        {
          value: "student_veceducationinformation_majornameth",
          text: "สาขาวิชา",
        },
        { value: "editdatedisplay", text: "วันที่แก้ไข" },
        { value: "employmentstatusname", text: "ภาวะการมีงานทำ" },
        { value: "action", text: "ตัวเลือก", align: "center" },
      ],
      validateobjectresults: undefined,
      excelDataSource: undefined,
      studentDetails: undefined,
      isLoading: false,
      isExporting: false,
      excel_importDialog: false,
      inputfile: undefined,
      isImporting: false,
      isSentValidate: undefined,

      JobTracking_dialog: false,
      update_trackingenddate: "",
      report_dialog: false,
      reports: [
        {
          id: 1,
          name: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          report_name:
            "GraduatedStudentJobTrackingReport_ConcludeEmploymentStatus_Report",
        },
        {
          id: 2,
          name: "รายงานภาวะการมีงานทำตามรายชื่อนักเรียน / นักศึกษา",
          report_name: "GraduatedStudentJobTrackingReport_StudentDetail_Report",
        },
      ],
      selectedstudentoid: "",

      request: new GraduatedStudentJobTrackingReportsRequest(),
      keyword: "",
      search: "",
      itemlength: 0,
      gender: undefined,
      selectedGender: "",
      tableOptions: undefined,

      selectedMajorCode: "",
      related_Majors: undefined,
      selectedCertificateTypeCode: "",
      related_CertificateTypes: undefined,
      selectedEmploymentStatusCode: "",
      related_EmploymentStatuses: undefined,
      filtering_jobtracking_isTracked: undefined,
    };
  },
  methods: {
    async loadFilterOptions() {
      await graduatedStudentJobTrackingReportFilterOptionDataProvider
        .GetRelatedCertificateTypes(this.jobTrackingReportOid)
        .then((result) => {
          this.related_CertificateTypes = result.data;
        });

      await majorDataProvider
        .getMajorNamesByGraduatedStudentJobTrackingReport(this.jobTrackingReportOid,this.selectedCertificateTypeCode)
        .then(result => {
            this.related_Majors = result.data;
        })

      await graduatedStudentJobTrackingReportFilterOptionDataProvider
        .GetRelatedEmploymentStatuses(this.jobTrackingReportOid)
        .then((result) => {
          this.related_EmploymentStatuses = result.data;
        });
    },
    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },
    openStudentDetailView(item) {
      var studentoid = item.student_oid;
      this.$router.push({
        name: "StudentDetailView",
        params: { studentoid: studentoid },
      });
    },
    async loadGraduatedStudentJobTrackingReport(oid = "") {
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          this.graudatedStudentJobTrackingReport = result.data;
          this.request.GraduatedStudentJobTrackingReport_Oid =
            this.graudatedStudentJobTrackingReport.oid;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadStudentDetails() {
      this.request.search = this.keyword;
      this.request.isTracked = this.filtering_jobtracking_isTracked;
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReportStudentDetailsWithPagination(
          this.request
        )
        .then((response) => {
          this.studentDetails = response.data.items;
          this.itemlength = response.data.quantity;
        })
        .catch((err) => err);
    },

    async onSelected() {
      this.$store.dispatch("showLoading");
      this.request.selected_certificatetype_code =
        this.selectedCertificateTypeCode;
      this.request.selected_majorcode = this.selectedMajorCode;
      this.request.selected_employmentstatus_code =
        this.selectedEmploymentStatusCode;

      await majorDataProvider
        .getMajorNamesByGraduatedStudentJobTrackingReport(this.jobTrackingReportOid,this.selectedCertificateTypeCode)
        .then(result => {
            this.related_Majors = result.data;
      })

      await this.loadStudentDetails();
      this.$store.dispatch("hideLoading");
    },

    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
      this.request.itemPerPage = newtableOptions.itemsPerPage;
    },
    async onKeywordChanged(newKeyword) {
      this.keyword = newKeyword;
      await this.loadStudentDetails();
    },
    pageChanged(pageChangeValue) {
      if (this.IsComponentCreatedFirstime) {
        this.request.page = pageChangeValue;
        this.loadStudentDetails();
      } else {
        this.IsComponentCreatedFirstime = true;
      }
    },

    onReportItemClick(item) {
      const report_name = item.report_name;
      this.openReportView(report_name);
    },

    openReportView(report_name = "") {
      this.$router.push({
        name: "GraduatedStudentJobTrackingReportDetailView_Report",
        params: {
          Oid: this.jobTrackingReportOid,
          report_name: report_name,
        },
      });
    },

    async validate() {
      this.isSentValidate = true;
      var oid = this.jobTrackingReportOid;
      await graduatedStudentJobTrackingReportValidator
        .validateGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          const returnData = result.data;
          if (returnData.validateresult == false) {
            this.$toast.error("กรุณากรอกข้อมูลให้ครบถ้วน และถูกต้อง");
          } else {
            this.$toast.success("รายงานการติดตามนี้ถูกต้องและครบถ้วน");
          }
        });
    },

    OnCloseDialogJobTracking(isComplete, employmentstatusname) {
      this.JobTracking_dialog = false;
      if (isComplete) {
        let index = this.studentDetails.findIndex(
          (el) => el.oid === this.selectedstudentoid
        );
        this.studentDetails[index].editdatedisplay = Date.now();
        this.studentDetails[index].employmentstatusname = employmentstatusname;
        this.graudatedStudentJobTrackingReport.countstudentjobtracked++;
      }
    },
    async onFilteringGraduatedStudentJobTrackingReportInfo(isTracked) {
      this.filtering_jobtracking_isTracked = isTracked;
      await this.loadStudentDetails();
    },
  },

  async created() {
    this.$store.dispatch("showLoading");
    this.jobTrackingReportOid = this.$route.params.trackingReportOid;
    await this.loadGraduatedStudentJobTrackingReport(this.jobTrackingReportOid);
    await this.loadStudentDetails();
    await this.loadFilterOptions();
    this.$store.dispatch("hideLoading");
  },
};
</script>

<style scoped>
.custom-width {
  width: 120px;
  gap: 10px;
}
</style>