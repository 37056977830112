
<template>
    <v-container class="pa-4">
        <v-form class="elevation-4">
            <v-container>
                <v-row class="justify-center mb-4 mt-2">
                    <h4>สถาบันการอาชีวศึกษา</h4>
                </v-row>
                <v-row>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.email" label="email" disabled>
                        </v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.fax" label="fax" disabled>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.housenumber" label="houseNumber" disabled>
                        </v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.institutecode" label="instituteCode" disabled>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.instituteid" label="instituteID" disabled>
                        </v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.institutenameth" label="instituteNameTh" disabled>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.soi" label="soi" disabled>
                        </v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.street" label="street" disabled>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.telephone" label="telephone" disabled>
                        </v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.villagenumber" label="villageNumber" disabled>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.website" label="website" disabled>
                        </v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                        <v-text-field v-model="institute.zipcode" label="zipcode" disabled>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-container>
</template>
    
<script>
import { backendapiUrl } from '@/services/constants';
import { InstituteDataProvider } from '@/services/InstituteDataProvider';
var instituteDataProvider = new InstituteDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
    name: "InstituteDetailView",
    data() {
        return {
            instituteID: '',
            institute: [],
        }
    },

    methods: {
        async loadInstitute(instituteID = '') {
            await instituteDataProvider.loadInstitute(instituteID).then(response => {
                this.institute = response.data;
            }).catch(err => { alert(err); });
        },
    },

    async created() {
        this.instituteID = this.$route.params.instituteID;
        await this.loadInstitute(this.instituteID);
    }
}
</script>
    
<style>
input[type="text"][disabled] {
    color: black;
}
</style>