<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2"> mdi-account-hard-hat </v-icon
            >ประเภทวิสาหกิจ</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <!-- <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="employerMainBusinessTypeDetail.code"
                label="Code"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="employerMainBusinessTypeDetail.nameth"
                label="ชื่อประเภทวิสาหกิจ"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { MasterDataProvider } from "@/services/MasterDataProvider";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "EmployerMainBusinessTypesDetailView",
  data() {
    return {
      oid: "",
      employerMainBusinessTypeDetail: {},
    };
  },
  methods: {
    async loadEmployerMainBusinessTypeDetails() {
      await masterDataProvider
        .getEmployerBusinessTypesByOid(this.oid)
        .then((result) => {
          this.employerMainBusinessTypeDetail = result.data;
          console.log(this.employerMainBusinessTypeDetail);
        });
    },
  },
  async created() {
    this.oid = this.$route.params.oid;
    await this.loadEmployerMainBusinessTypeDetails();
  },
};
</script>
<style></style>
