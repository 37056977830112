<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="11">
        <div class="pa-4 my-4">
          <v-card class="elevation-2">
            <v-card-title class="color-HeaderCard">
              <h4>รายละเอียดข่าวประชาสัมพันธ์</h4>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row class="mx-4 mt-2">
                <v-col col="6">
                  <v-autocomplete
                    v-if="$store.getters['rolecodes']"
                    @change="onSelectedNewsOrigin"
                    label="ข่าวเกี่ยวกับ"
                    :rules="[(v) => !!v || 'กรุณาระบุความเกี่ยวข้อง']"
                    :items="newsOriginTypeSelection"
                    v-model="selectedneworigincode"
                    item-text="name"
                    item-value="code"
                    background-color="input"
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col col="6" v-if="!isAdminOrCenter()">
                  <v-autocomplete
                    v-if="selectedneworigincode == newsOriginTypeCodes.School"
                    label="สถานศึกษา"
                    :rules="[(v) => !!v || 'กรุณาระบุสถานศึกษา']"
                    :items="schools"
                    v-model="selectedreforiginid"
                    item-text="permissiondisplayvalue"
                    item-value="permissionvalue"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="selectedneworigincode == newsOriginTypeCodes.Institute"
                    label="สถาบันอาชีวะ"
                    :rules="[(v) => !!v || 'กรุณาระบุสถาบันอาชีวะ']"
                    :items="institutes"
                    v-model="selectedreforiginid"
                    item-text="permissiondisplayvalue"
                    item-value="permissionvalue"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="selectedneworigincode == newsOriginTypeCodes.ProvinceSchool"
                    label="จังหวัด"
                    :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                    :items="provinces"
                    v-model="selectedreforiginid"
                    item-text="permissiondisplayvalue"
                    item-value="permissionvalue"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                </v-col>
                <v-col col="6" v-else>
                  <v-autocomplete
                    v-if="selectedneworigincode == newsOriginTypeCodes.School"
                    label="สถานศึกษา"
                    :rules="[(v) => !!v || 'กรุณาระบุสถานศึกษา']"
                    :items="schools"
                    v-model="selectedreforiginid"
                    item-text="schoolname"
                    item-value="schoolid"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="
                      selectedneworigincode == newsOriginTypeCodes.Institute
                    "
                    label="สถาบันอาชีวะ"
                    :rules="[(v) => !!v || 'กรุณาระบุสถาบันอาชีวะ']"
                    :items="institutes"
                    v-model="selectedreforiginid"
                    item-text="institutenameth"
                    item-value="instituteid"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="
                      selectedneworigincode ==
                      newsOriginTypeCodes.ProvinceSchool
                    "
                    label="จังหวัด"
                    :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                    :items="provinces"
                    v-model="selectedreforiginid"
                    item-text="provincethai"
                    item-value="provinceid"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                  <!-- <v-autocomplete v-if="selectedneworigincode == newsOriginTypeCodes.Employer"
                    label="สถานประกอบการ"
                   :rules="[(v) => !!v || 'กรุณาระบุสถานประกอบการ']"
                    :items="companys"
                    v-model="selectedreforiginid"
                    item-text="nameth"
                    item-value="identityid"
                  ></v-autocomplete> -->
                </v-col>
              </v-row>
              <v-row class="mx-4 mt-2">
                <v-col col="6">
                  <TextFieldDatePicker
                    v-model="selected_startdate"
                    :rules="[(v) => !!v || 'กรุณาระบุวันที่ประกาศข่าว']"
                    :value="selected_startdate"
                    caption="วันที่ประกาศข่าว"
                    @closeAndUpdate="this.closeAndUpdateTextFieldDatePicker_StartDate"
                    background-color="input"
                  >
                  </TextFieldDatePicker>
                </v-col>
                <v-col col="6">
                  <TextFieldDatePicker
                    v-model="selected_expiredate"
                    :rules="[(v) => !!v || 'กรุณาระบุวันที่สิ้นสุดการประกาศ']"
                    :value="selected_expiredate"
                    caption="วันที่สิ้นสุดการประกาศ"
                    @closeAndUpdate="this.closeAndUpdateTextFieldDatePicker_ExpireDate"
                    background-color="input"
                  >
                  </TextFieldDatePicker>
                </v-col>
              </v-row>
              <v-row class="mx-4">
                <v-col sm="12" md="12" lg="12">
                  <v-text-field
                    v-model="request.title"
                    label="หัวข้อ"
                    :rules="[
                      (v) => !!v || 'กรุณาระบุหัวข้อ',
                      (v) =>
                        (v && v.length <= 120) ||
                        'กรุณาระบุหัวข้อเพียง 120 ตัวอักษร',
                    ]"
                    filled
                    dense
                    required
                    background-color="input"
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="12" md="12" lg="12">
                  <div v-if="imageBannerUrl" class="position-relative">
                    <div class="bgcolorphotozone">
                      <v-btn icon color="white" class="close-btn" @click="clearImage"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                      <v-img contain height="360" :src="imageBannerUrl" />
                    </div>
                  </div>

                  <div v-else>
                    <v-card
                      rounded="8px"
                      color="#D9D9D9"
                      width="auto"
                      height="360px"
                      outlined
                      class="d-flex flex-column align-center justify-center black-border"
                      @click="triggerFileInput"
                    >
                      <v-btn icon class="mr-2">
                        <v-icon size="100px" color="white">mdi-link-variant</v-icon>
                      </v-btn>
                      <p class="text-center mt-8">เพิ่มรูปข่าวประชาสัมพันธ์</p>
                    </v-card>
                  </div>

                  <input
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    @change="onFileChange($event.target.files[0])"
                  />
                </v-col>
                <v-col sm="12" md="12" lg="12">
                  <v-text-field
                    v-model="request.description"
                    label="รายละเอียด"
                    :rules="[(v) => !!v || 'กรุณาระบุรายละเอียด']"
                    filled
                    required
                    dense
                    background-color="input"
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="request.writter"
                    label="ชื่อผู้เขียน"
                    :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้เขียน']"
                    filled
                    required
                    dense
                    background-color="input"
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <v-autocomplete
                    @change="() => (newcategory = request.category)"
                    :items="categoryselector"
                    item-text="name"
                    item-value="name"
                    v-model="request.category"
                    label="หมวดหมู่"
                    :rules="[(v) => !!v || 'กรุณาระบุหมวดหมู่']"
                    filled
                    required
                    dense
                    background-color="input"
                    append-icon="mdi-chevron-down"
                  >
                    <template slot="prepend-item" class="grey--text">
                      <v-row class="align-center justify-center">
                        <v-col col="8" class="ml-3">
                          <v-text-field
                            :rules="[(u) => !!u || 'กรุณาระบุ ประเภท/หมวดหมู่']"
                            label="เพิ่ม ประเภท/หมวดหมู่"
                            v-model="newcategory"
                            background-color="input"
                          ></v-text-field>
                        </v-col>
                        <v-col col="4" class="mr-3">
                          <v-btn @click="OnclickaddedCategory" block color="success"
                            >ตกลง</v-btn
                          >
                        </v-col>
                      </v-row>
                    </template>
                    <!-- <template slot="selection" slot-scope="{ item, selected }"></template> -->
                    <template slot="item" slot-scope="{ item, tile }">
                      <div>
                        {{ item.name }}
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-btn v-if="!htmlmode" color="primary" @click="htmlmode = true"
                    >สลับโหมด html</v-btn
                  >
                  <v-btn v-if="htmlmode" color="primary" @click="htmlmode = false"
                    >สลับโหมด Preview</v-btn
                  >
                </v-col>
                <v-col cols="12" v-if="!htmlmode">
                  <vue-editor
                    v-model="request.htmlcontent"
                    use-custom-image-handler
                    @image-added="handleImageAdded"
                    placeholder="เนื้อหา"
                  />
                </v-col>
                <v-col cols="12" v-else>
                  <v-textarea
                    v-model="request.htmlcontent"
                    label="Html-Syntax"
                    background-color="input"
                    filled
                  ></v-textarea>
                </v-col>

                <!-- <EntityHashtagManagement
                    v-if="IsLoadingComplete"
                    @update_tags="onSelectedTag"
                  >
                  </EntityHashtagManagement> -->
              </v-row>
              <v-row class="justify-center text-center">
                <v-col>
                  <v-btn @click="$router.go(-1)" class="ma-2"> ยกเลิก </v-btn>
                  <v-btn @click="submit" dark class="ma-3 color-button2">
                    บันทึก <v-icon class="ml-2">mdi-content-save</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createCanvas, loadImage } from "canvas";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import { NewsDataProvider } from "@/services/NewsDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { InstituteDataProvider } from "@/services/InstituteDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";

import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import EntityHashtagManagement from "@/components/EntityHashtag/EntityHashtagManagement.vue";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import {
  ApplicationRoleCodes,
  NewsOriginTypeCodes,
  NewsOriginTypeSelection,
} from "@/Constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { EmployerProfilesRequest } from "@/models/Employer/EmployerProfilesRequest";
import { AccountProfileDataProvider } from "@/services/Profile/AccountProfileDataProvider";
const accountProfileDataProvider = new AccountProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const newsDataProvider = new NewsDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const instituteDataProvider = new InstituteDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "NewsManagementDetailView",
  data() {
    return {
      request: [],
      requestFormData: new FormData(),
      valid: true,
      imageBanner: undefined,
      imageBannerUrl: "",
      newcategory: "",
      categoryselector: [],
      selected_expiredate: "",
      selected_startdate: "",
      selected_tags: [],
      IsLoadingComplete: false,
      htmlmode: false,
      getSchools_Reqeust: new GetSchools_Reqeust(),
      applicationRoleCodes: ApplicationRoleCodes,
      newsoriginlist: NewsOriginTypeSelection,
      selectedneworigincode: "",
      newsOriginTypeCodes: NewsOriginTypeCodes,
      selectedreforiginid: "",
      companys: [],
      provinces: [],
      institutes: [],
      schools: [],
    };
  },
  components: { VueEditor, TextFieldDatePicker, EntityHashtagManagement },
  methods: {
    handleImageAdded(file, Editor, cursorLocation) {
      var vheight = prompt(
        "กรุณาระบุขนาดรูปภาพ (pixel) *หากไม่ระบุ ขนาดจะอยู่ที่ 720 pixel ?"
      );
      var conf = confirm(
        "ยืนยันขนาดภาพ: " + (vheight != null ? vheight : "720") + " pixel"
      );
      if (conf === true && vheight != null && vheight < 2000) {
        const maxWidth = vheight ? vheight : 720;
        const maxHeight = vheight ? vheight : 720;

        let reader = new FileReader();
        reader.onload = (e) => {
          //---
          const imagew = new Image();
          imagew.src = reader.result;
          imagew.onload = async () => {
            const ratio = Math.min(maxWidth / imagew.width, maxHeight / imagew.height);
            const width = (imagew.width * ratio + 0.5) | 0;
            const height = (imagew.height * ratio + 0.5) | 0;
            const canvas = createCanvas(width, height); // Set your desired dimensions
            const ctx = canvas.getContext("2d");
            ctx.drawImage(imagew, 0, 0, width, height); // Resize the image

            // Convert canvas to base64
            const resizedBase64 = canvas.toDataURL("image/jpeg");

            Editor.insertEmbed(cursorLocation, "image", resizedBase64);
          };
          // let image = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.$toast.error("ขนาดของรูปภาพใหญ่เกินไป !!!");
      }
    },
    createImage(file) {
      this.requestFormData.append("File", file);
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageBannerUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);

      file = "";
    },
    clearImage() {
      this.imageBannerUrl = "";
      this.$refs.fileInput.value = "";
    },
    OnclickaddedCategory() {
      this.categoryselector.push({ name: this.newcategory });
      this.request.category = this.newcategory;
    },
    loadCategory() {
      newsDataProvider
        .getCategoryList()
        .then((result) => {
          this.categoryselector = result.data;
        })
        .catch((err) => {});
    },
    async loadSchools() {
      await schoolDataProvider
        .loadSchools(this.request_data)
        .then((result) => {
          this.schools = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadInstitutes() {
      await instituteDataProvider
        .loadInstitutes()
        .then((result) => {
          this.institutes = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadProvinces() {
      await masterDataProvider
        .loadGetProvinces()
        .then((result) => {
          this.provinces = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadCompany() {
      let request = new EmployerProfilesRequest();
      await employerProfileDataProvider
        .loadEmployerProfiles(request)
        .then((result) => {
          this.companys = result.data.items;
        })
        .catch((err) => {
          alert(err);
        });
    },
    submit() {
      // for (const [key, value] of this.requestFormData) {
      // }
      if (this.$refs.form.validate()) {
        if (this.imageBannerUrl) {
          if (this.request.editeddate === "0001-01-01T00:00:00") {
            this.request.editeddate = this.request.createddate;
          }
          this.requestFormData.append("title", this.request.title);
          this.requestFormData.append("htmlcontent", this.request.htmlcontent);
          this.requestFormData.append("description", this.request.description);
          this.requestFormData.append("category", this.request.category);
          this.requestFormData.append("expiredate", this.selected_expiredate);
          this.requestFormData.append("startdate", this.selected_startdate);
          this.requestFormData.append("writerfullname", this.request.writter);
          this.requestFormData.append("newsoriginid", this.selectedreforiginid);
          this.requestFormData.append("newsorigintypecode", this.selectedneworigincode);
          newsDataProvider
            .createNewsContent(this.requestFormData)
            .then((result) => {
              this.$toast.success("บันทึกข้อมูลเรียบร้อย");
              this.$router.go(-1);
            })
            .catch((err) => {
              this.$toast.error("ล้มเหลว" + err);
            });
        } else {
          this.$toast.error("กรุณาเพิ่มรูปข่าวประชาสัมพันธ์");
        }
      }
    },
    closeAndUpdateTextFieldDatePicker_ExpireDate(selectedDate = "") {
      this.selected_expiredate = selectedDate;
    },
    closeAndUpdateTextFieldDatePicker_StartDate(selectedDate = "") {
      this.selected_startdate = selectedDate;
    },
    onSelectedTag(Tags) {
      this.requestFormData.delete("Tags[]");
      Tags.forEach((value) => {
        this.requestFormData.append("Tags[]", value);
      });
    },
    onSelectedNewsOrigin() {
      if (this.selectedneworigincode == NewsOriginTypeCodes.Institute) {
        if (this.isAdminOrCenter()) {
          this.loadInstitutes();
        } else {
          this.institutes = this.permissionCustomList.Insitution;
        }
      }
      if (this.selectedneworigincode == NewsOriginTypeCodes.ProvinceSchool) {
        if (this.isAdminOrCenter()) {
          this.loadProvinces();
        } else {
          this.provinces = this.permissionCustomList.Province;
        }
      }
      if (this.selectedneworigincode == NewsOriginTypeCodes.School) {
        if (this.isAdminOrCenter()) {
          this.loadSchools();
        } else {
          this.schools = this.permissionCustomList.School;
        }
      }

      this.newsEditRequest.newsorigintypecode = this.selectedneworigincode;
    },
    onSelectedNewsOriginOid() {
      this.newsEditRequest.newsoriginid = this.selectedreforiginid;
    },
    groupByPermissionType(data) {
      return data.reduce((result, item) => {
        const key = item.permissiondatatypename;

        if (!result[key]) {
          result[key] = [];
        }

        result[key].push(item);

        return result;
      }, {});
    },
    isAdminOrCenter() {
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Administrator
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Center_Admin
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Center_Officer
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Center_Manager
        )
      ) {
        return true;
      }
    },
  },
  computed: {
    newsOriginTypeSelection() {
      let Selection = [];
      if (this.isAdminOrCenter()) {
        Selection = this.newsoriginlist;
      }
      if (this.$store.getters["rolecodes"].includes(this.applicationRoleCodes.Employer)) {
        Selection.push({
          code: "Employer",
          name: "สถานประกอบการ",
          color: "#03a9f4",
        });
      }
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.School_Manager
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.School_Admin
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.School_Partner_Officer
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.School_Guidance_Teacher
        )
      ) {
        Selection.push({ code: "School", name: "สถานศึกษา", color: "#03a9f4" });
      }
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.ProvinceSchool_Officer
        )
      ) {
        Selection.push({
          code: "ProvinceSchool",
          name: "อาชีวศึกษาจังหวัด",
          color: "#03a9f4",
        });
      }
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Insitution_Officer
        )
      ) {
        Selection.push({
          code: "Institute",
          name: "อาชีวศึกษา",
          color: "#03a9f4",
        });
      }

      return Selection;
    },
  },
  async created() {
    await accountProfileDataProvider
      .getPermissionList()
      .then((result) => {
        this.permissionCustomList = this.groupByPermissionType(result.data);
      })
      .catch((err) => {});
    this.loadCategory();
    this.IsLoadingComplete = true;
  },
};
</script>

<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.bgcolorphotozone {
  background-color: #f2f4f8;
  border-radius: 8px;
}
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgb(62, 83, 183) 20%,
    rgb(66, 81, 167) 30%,
    rgb(88, 123, 201) 50%,
    rgb(46, 126, 220) 60%,
    rgb(84, 170, 235) 80%,
    rgb(127, 202, 246) 100%
  );
}
.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.position-relative {
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
</style>
