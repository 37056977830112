<template>
  <v-container class="pa-4 mt-4">
    <v-row>
      <h3><i class="fa fa-line-chart"></i>สรุปรายงานของแต่ละสถานศึกษา</h3>
    </v-row>
    <v-row>
      <v-card class="col-12">
        <v-card-title>
          <p>เกณฑ์การค้นหา</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-select
                label="ปี"
                v-model="selected_YearTH"
                item-text="txtyear"
                item-value="year"
                :items="yearsth_Choice"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                label="เทอม"
                v-model="selected_Semester"
                :items="semester_Choices"
              ></v-select>
            </v-col>
            <v-col cols="7">
              <v-autocomplete
                label="สถานศึกษา"
                item-text="schoolname"
                item-value="schoolid"
                :items="School_Choices"
                v-model="selected_SchoolID"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="12 text-center">
        <v-btn
          color="warning"
          v-on:click="create_reportParameters(), (document_viewer_dialog = true)"
          >แสดงรายงาน</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog v-model="document_viewer_dialog" width="1200">
      <template>
        <v-card height="8000" width="1200">
          <DocumentViewer
            :key="document_viewer_key"
            :report_name="report_name"
            :report_parameters="report_parameters"
          >
          </DocumentViewer>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import DocumentViewer from "../../../components/Report/DocumentViewer.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";

var schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  name: "",
  props: [],
  data() {
    return {
      document_viewer_key: 1,
      document_viewer_dialog: false,
      report_name: "graduatedstudentjobtrackingreport_report?",
      report_parameters: [],
      selected_YearTH: undefined,
      yearsth_Choice: [],
      School_Choices: [],
      selected_SchoolID: undefined,
      enable_SchoolSelect: true,
      semester_Choices: [],
      selected_Semester: undefined,
    };
  },
  methods: {
    async initialize() {
      await this.loadyear();
      await this.loadschool();
      await this.loadSemesters();
    },
    async loadyear() {
      commonDataProvider.getListOfYearTH().then((response) => {
        this.yearsth_Choice = response.data;
      });
    },
    async loadSemesters() {
      commonDataProvider.getListOfSemesters().then((response) => {
        this.semester_Choices = response.data;
      });
    },
    async loadschool() {
      schoolDataProvider.loadSchools().then((response) => {
        this.School_Choices = response.data;
      });
    },
    create_reportParameters() {
      this.document_viewer_key += 1;
      this.report_parameters = [];
      if (this.selected_YearTH != undefined) {
        this.report_parameters.push("prm_YearTH=" + this.selected_YearTH);
      }
      if (this.selected_Semester != undefined) {
        this.report_parameters.push("prm_Semester=" + this.selected_Semester);
      }
      if (this.selected_SchoolID != undefined) {
        this.report_parameters.push("prm_SchoolID=" + this.selected_SchoolID);
      }
    },
  },
  async created() {
    await this.initialize();
  },
  components: { DocumentViewer },
};
</script>

<style></style>
