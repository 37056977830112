export class RegisterEmployerRequest {
  selected_employerprofilestatuscode = "";
  selected_employerprofiletypecode = "";
  identityid = "";
  nameth = "";
  email = "";
  phone = "";
  fax = "";
  selected_employermainbusinesstypecode = "";
  selected_employerbussinesstypecode = "";
  description = "";
  benefit_description = "";
  social_security_number = "";
  websiteurl = "";
  isrequiredjobapplicanttoregisterthemselforviapost = false;
  isopenforonlineapplicant = false;
  address_no = "";
  selected_province_id = "";
  selected_district_id = "";
  selected_tambon_id = "";
  location_latitude = "";
  location_longtitude = "";
  employerschoolcontactrequests = [];

  username = "";
  password = "";
  confirm_password = "";

  coordinator_email = "";
  coordinator_titleName_id = "";
  coordinator_firstName = "";
  coordinator_lastName = "";
  coordinator_phone = "";
  coordinator_fax = "";
  coordinator_positionname = "";

  generateFormData() {
    const formData = new FormData();
    formData.append(
      "selected_employerprofilestatuscode",
      this.selected_employerprofilestatuscode
    );
    formData.append(
      "selected_employerprofiletypecode",
      this.selected_employerprofiletypecode
    );
    formData.append("identityid", this.identityid);
    formData.append("nameth", this.nameth);
    formData.append("email", this.email);
    formData.append("phone", this.phone);
    formData.append("fax", this.fax);
    formData.append(
      "selected_employermainbusinesstypecode",
      this.selected_employermainbusinesstypecode
    );
    formData.append(
      "selected_employerbussinesstypecode",
      this.selected_employerbussinesstypecode
    );
    formData.append("description", this.description);
    formData.append("benefit_description", this.benefit_description);
    formData.append("social_security_number", this.social_security_number);
    formData.append("websiteurl", this.websiteurl);
    formData.append(
      "isrequiredjobapplicanttoregisterthemselforviapost",
      this.isrequiredjobapplicanttoregisterthemselforviapost
    );
    formData.append("isopenforonlineapplicant", this.isopenforonlineapplicant);
    formData.append("address_no", this.address_no);
    formData.append("selected_province_id", this.selected_province_id);
    formData.append("selected_district_id", this.selected_district_id);
    formData.append("selected_tambon_id", this.selected_tambon_id);
    formData.append("location_latitude", this.location_latitude);
    formData.append("location_longtitude", this.location_longtitude);
    formData.append("username", this.username);
    formData.append("password", this.password);
    formData.append("confirm_password", this.confirm_password);
    formData.append("coordinator_email", this.coordinator_email);
    formData.append("coordinator_titleName_id", this.coordinator_titleName_id);
    formData.append("coordinator_firstName", this.coordinator_firstName);
    formData.append("coordinator_lastName", this.coordinator_lastName);
    formData.append("coordinator_phone", this.coordinator_phone);
    formData.append("coordinator_fax", this.coordinator_fax);
    formData.append("coordinator_positionname", this.coordinator_positionname);

    this.employerschoolcontactrequests.forEach((x) => {
        formData.append(
          "employerschoolcontactrequests_as_json",
          JSON.stringify(x)
        );
      });


    return formData;
  }
}
