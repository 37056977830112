<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2"> mdi-file-document-edit-outline </v-icon>
            คำนำหน้าชื่อ</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="titleNameDetails.oid"
                label="รหัสคำนำหน้าชื่อ"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="titleNameDetails.titlenameth"
                label="ชื่อคำนำหน้าชื่อ (ไทย)"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="titleNameDetails.titlenameeng"
                label="ชื่อคำนำหน้าชื่อ (อังกฤษ)"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "TitleNamesDetailView",
  data() {
    return {
      oid: "",
      titleNameDetails: {},
    };
  },

  methods: {
    async loadTitleNameDetails() {
      await masterDataProvider.getTitleNameByOid(this.oid).then((result) => {
        this.titleNameDetails = result.data;
        console.log(this.titleNameDetails);
      });
    },
  },

  async created() {
    this.oid = this.$route.params.oid;
    await this.loadTitleNameDetails();
  },
};
</script>
<style></style>
