import TambonListView from "@/views/Province/TambonListView";
export default [
  {
    path: "/Tambon",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Tambon/TambonListView/:districtID",
        name: "TambonListView",
        component: TambonListView,
        meta: {
          requiresAuth: true,
          nameTh: "ตำบล",
        },
      },
    ],
  },
];
