import EmployerMainBusinessTypesDetailView from "@/views/EmployerMainBusinessTypes/EmployerMainBusinessTypesDetailView.vue";
import EmployerMainBusinessTypesListView from "@/views/EmployerMainBusinessTypes/EmployerMainBusinessTypesListView.vue";

export default [
  {
    path: "/EmployerMainBusinessTypes",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/EmployerMainBusinessTypes/EmployerMainBusinessTypesListView",
        name: "EmployerMainBusinessTypesListView",
        component: EmployerMainBusinessTypesListView,
        meta: {
          requiresAuth: true,
          nameTh: "ประเภทวิสาหกิจ",
        },
      },
      {
        path: "/EmployerMainBusinessTypes/EmployerMainBusinessTypesDetailView/:oid",
        name: "EmployerMainBusinessTypesDetailView",
        component: EmployerMainBusinessTypesDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดประเภทวิสาหกิจ",
        },
      },
    ],
  },
];
