<template>
  <div class="pa-4">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-book</v-icon> คำนำหน้าชื่อ
      </h3>
    </v-row>
    <SearchableDataTable v-if="titleNames != undefined" table_caption="คำนำหน้าชื่อ"
      :datatable_headers="titleNames_data_table_headers" :data_items="titleNames">
      <template v-slot:item.isactive="{ item }">
        <v-row class="d-flex align-center">
          <v-switch v-model="item.isactive" color="success" @change="updateStatus(item)" inset></v-switch>
          <span v-if="item.isactive == true"> เปิดใช้งาน </span>
          <span v-else> ปิดใช้งาน </span></v-row>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToDetailView(item)" icon color="info">
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { TitlenameActive } from "@/models/Titlename/TitlenameActive";
import { ResetPasswordRequest } from "@/models/Employer/ResetPasswordRequest";

import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "TitleNamesListView",
  data() {
    return {
      isImporting: false,
      titleNames: undefined,
      activeTitleNames: [],
      titleNames_data_table_headers: [
        { text: "รหัสคำนำหน้าชื่อ", value: "oid", align: "center" },
        { text: "ชื่อคำนำหน้าชื่อ (ไทย)", value: "titlenameth" },
        { text: "ชื่อคำนำหน้าชื่อ (อังกฤษ)", value: "titlenameeng" },
        { text: "สถานะการใช้งาน", value: "isactive" },
        { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
      ],
      vm: new TitlenameActive(),
    };
  },
  methods: {
    async importReligions() {
      this.isImporting = true;
      await masterDataProvider
        .getTitleNames()
        .then((result) => {
          this.onImportTitleNamesCompleted();
        })
        .catch((err) => {
          this.isImporting = false;
          alert(err);
        });
    },
    async loadTitleNames() {
      await masterDataProvider
        .getTitleNames()
        .then((result) => {
          this.titleNames = result.data;
          //ตั้ง default titleNames
          [2, 3, 4, 5, 6].forEach((targetOid) => {
            const foundItem = this.titleNames.find((item) => item.oid == targetOid);
            if (foundItem) {
              foundItem.isactive = true;

              this.vm.Oid = foundItem.oid;
              this.vm.ActiveStatus = foundItem.isactive;
              masterDataProvider
                .setTitleNameActiveStatus(this.vm)
                .then(() => {
                  this.updateActiveTitleNames();
                })
                .catch((err) => {
                  item.isactive = !item.isactive;
                });
            }
          });

        })
        .catch((err) => {
          alert(err);
        });
    },
    goToDetailView: function (item) {
      this.$router
        .push({
          name: "TitleNamesDetailView",
          params: { oid: item.oid },
        })
        .catch((err) => { });
    },
    onImportTitleNamesCompleted() {
      this.isImporting = false;
      this.loadTitleNames();
    },
    updateActiveTitleNames() {
      this.activeTitleNames = this.titleNames.filter((item) => item.isactive);
    },
    async updateStatus(item) {
      // const vm = {
      //   titlenameid: item.oid,
      //   titlenameth: item.titlenameth,
      //   titlenameeng: item.titlenameeng,
      //   isactive: item.isactive,
      // };
      this.vm.Oid = item.oid;
      this.vm.ActiveStatus = item.isactive;
      console.log(this.vm);

      await masterDataProvider
        .setTitleNameActiveStatus(this.vm)
        .then(() => {
          this.updateActiveTitleNames();
        })
        .catch((err) => {
          item.isactive = !item.isactive;
        });
    },
  },
  async created() {
    await this.loadTitleNames();
  },
};
</script>

<style></style>
