<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="11" md="12" sm="12" xs="12">
          <v-card>
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >ติดตามสถานะการรับสมัครงาน</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text v-if="jobposting" class="d-flex justify-center">
              <v-col cols="10" class="mt-5">
                <h2>{{ jobposting.positionname }}</h2>
                <v-row no-gutters align="center" class="mt-2">
                  <v-col cols="auto" class="mr-2">
                    <v-avatar color="grey" size="50" class="my-2">
                      <v-img
                        :src="
                          'data:image/jpg;base64,' +
                          jobposting.employerprofile.logo_photobase64
                        "
                      ></v-img>
                    </v-avatar>
                  </v-col>

                  <v-col>
                    <div>
                      <span class="primary--text">{{
                        jobposting.employerprofile.nameth
                      }}</span>
                      <v-icon class="ml-1 mr-0">mdi-map-marker-outline</v-icon>
                      <span v-if="jobposting.workingplace_district">
                        {{ jobposting.workingplace_district.districtthaishort }},
                      </span>
                      <span v-if="jobposting.workingplace_province.provincethai">
                        {{ jobposting.workingplace_province.provincethai }}
                      </span>
                    </div>
                    <div class="mt-1" v-if="jobposting.employmentcategory">
                      <v-chip style="background-color: rgba(63, 81, 181, 0.2)" small>
                        {{ jobposting.employmentcategory.name }}
                      </v-chip>
                    </div>
                  </v-col>

                  <v-col class="text-right">
                    <v-row no-gutters class="align-center" justify="end">
                      <h4 class="mr-2 primary--text">สถานะ :</h4>
                      <v-chip color="green" text-color="white">
                        {{ jobposting.jobpostingstatus.statusname }}
                      </v-chip>
                    </v-row>
                    <div class="mt-1">
                      <span> วันที่ประกาศ : </span>
                      <FormatDateView :date_value="jobposting.startdate"></FormatDateView>
                      -
                      <FormatDateView :date_value="jobposting.enddate"></FormatDateView>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-3">
                  <v-col cols="12">
                    <span>รหัสประกาศงาน : </span>
                    <a @click="openEmployerUpdateJobPostingView()">{{
                      jobposting.jobposting_id
                    }}</a>
                  </v-col>
                  <v-col cols="12">
                    <span>ประเภทงาน : {{ jobposting.jobsegmentname_todisplay }}</span>
                  </v-col>
                  <v-col cols="12">
                    <span>อัตราที่รับ : {{ jobposting.unit_todisplay }} คน</span>
                  </v-col>
                  <v-col cols="12">
                    <span>เงินเดือน : {{ jobposting.wagetodisplay }}</span>
                  </v-col>
                </v-row>

                <v-row class="my-3">
                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-4 pa-2 light-gray-border"
                      rounded
                      :outlined="activeButton !== 1"
                      :color="activeButton === 1 ? 'primary' : ''"
                      style="
                        border-radius: 8px;
                        height: 66.66%;
                        width: 100%;
                        font-weight: bold;
                      "
                      @click="
                        setActive(1);
                        filterJobApplicationsByJobApplicationStatusCode('');
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 1 }"
                        >จำนวนใบสมัครทั้งหมด {{ quantity_of_all_jobapplication }}</span
                      >
                    </v-btn>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-2 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 2"
                      :color="activeButton === 2 ? 'primary' : ''"
                      style="
                        border-radius: 8px;
                        height: 33.33%;
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-weight: bold;
                      "
                      @click="
                        setActive(2);
                        filterJobApplicationsByJobApplicationStatusCode('Pending');
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 2 }"
                        >ยื่นใบสมัคร/รอการตรวจสอบ
                        {{ quantity_of_pending_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="mb-2 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 3"
                      :color="activeButton === 3 ? 'primary' : ''"
                      style="
                        border-radius: 8px;
                        height: 33.33%;
                        width: 100%;
                        font-weight: bold;
                      "
                      @click="
                        setActive(3);
                        filterJobApplicationsByJobApplicationStatusCode('UnderReview');
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 3 }"
                        >อยู่ระหว่างพิจารณา
                        {{ quantity_of_underreview_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 4"
                      :color="activeButton === 4 ? 'primary' : ''"
                      style="
                        border-radius: 8px;
                        height: 33.33%;
                        width: 100%;
                        font-weight: bold;
                      "
                      @click="
                        setActive(4);
                        filterJobApplicationsByJobApplicationStatusCode(
                          'InterviewScheduled'
                        );
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 4 }"
                        >นัดหมายสัมภาษณ์
                        {{ quantity_of_interviewscheduled_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-4 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 5"
                      :color="activeButton === 5 ? 'primary' : ''"
                      style="
                        border-radius: 8px;
                        height: 50%;
                        width: 100%;
                        font-weight: bold;
                      "
                      @click="
                        setActive(5);
                        filterJobApplicationsByJobApplicationStatusCode('Accecpted');
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 5 }"
                        >รับเข้าทำงาน {{ quantity_of_accecpted_jobapplication }} คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 6"
                      :color="activeButton === 6 ? 'primary' : ''"
                      style="
                        border-radius: 8px;
                        height: 50%;
                        width: 100%;
                        font-weight: bold;
                      "
                      @click="
                        setActive(6);
                        filterJobApplicationsByJobApplicationStatusCode('Rejected');
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 6 }"
                        >ไม่ผ่านการพิจารณา
                        {{ quantity_of_rejected_jobapplications }}
                        คน</span
                      >
                    </v-btn>
                  </v-col>
                </v-row>
                <SearchableDataTable
                  table_caption="ใบสมัครงาน"
                  :data_items="filteredItems"
                  :datatable_headers="datatable_headers"
                >
                  <template v-slot:item.createddate="{ item }">
                    <FormatDate :date_value="item.createddate"></FormatDate>
                  </template>
                  <template v-slot:item.jobapplicationstatus.statusname="{ item }">
                    <div class="d-flex justify-center">
                      <JobApplicationStatus_Label
                        :JobApplication="item"
                      ></JobApplicationStatus_Label>
                    </div>
                  </template>
                  <template v-slot:item.student.firstname="{ item }">
                    <span>{{ item.student.firstname }}</span>
                    {{ item.student.surname }}
                  </template>

                  <template v-slot:item.student.birthday="{ item }">
                    <span>{{ moment().diff(item.student.birthday, "years") }}</span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top color="info">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openJobApplicationDetailView(item)"
                          color="info"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-file</v-icon>
                        </v-btn>
                      </template>
                      <span>แสดงรายละเอียดใบสมัครงาน</span>
                    </v-tooltip>
                    <v-tooltip top color="warning">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openJobApplicationStatusManagementDialog(item)"
                          color="warning"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-file-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>อัปเดตสถานะใบสมัครงาน</span>
                    </v-tooltip>
                  </template>
                </SearchableDataTable>
                <v-dialog v-model="dialog" max-width="700px">
                  <template>
                    <JobApplicatiionStatusManagement
                      :JobApplication="selected_jobapplication"
                      :sentopenBack="false"
                      @finished="onJobApplicationStatusManagementFinished"
                    ></JobApplicatiionStatusManagement>
                  </template>
                </v-dialog>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <!-- <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <h3 class="primary--text mt-3">ติดตามสถานะการรับสมัครงาน</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="d-flex-column justify-start">
        <div v-if="jobposting">
          <label style="font-weight: bold"> รหัสประกาศงาน : </label
          ><a @click="openEmployerUpdateJobPostingView()">{{
            jobposting.jobposting_id
          }}</a>
        </div>
        <div>
          <label style="font-weight: bold">ชื่อตำแหน่ง : </label
          ><label>{{ jobposting?.positionname }} </label>
        </div>
        <div>
          <label style="font-weight: bold">สถานประกอบการ: </label
          ><label>{{ jobposting?.employerprofile?.nameth }} </label>
        </div>
        <div v-if="jobposting">
          <label style="font-weight: bold">ประเภทงาน : </label
          ><label>{{ jobposting?.jobsegmentname_todisplay }} </label>
        </div>
        <div>
          <label style="font-weight: bold">อัตราที่รับ : </label
          ><label>{{ jobposting?.unit_todisplay }} คน</label>
        </div>
        <div>
          <label style="font-weight: bold">ค่าจ้าง/ค่าตอบแทน/เงินเดือน : </label
          ><label>{{ jobposting?.wagetodisplay }} </label>
        </div>
        <div>
          <label style="font-weight: bold">วันที่ประกาศ : </label
          ><FormatDate :date_value="jobposting?.startdate"></FormatDate> -
          <FormatDate :date_value="jobposting?.enddate"></FormatDate>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="d-flex justify-end">
          <label>สถานะ : </label>
          <JobPostingStatus_Label
            v-if="jobposting"
            :JobPosting="jobposting"
          ></JobPostingStatus_Label>
        </div>

        <div class="d-flex justify-end">
          <label>
            จำนวนใบสมัครทั้งหมด
            <a @click="filterJobApplicationsByJobApplicationStatusCode('')">{{
              quantity_of_all_jobapplication
            }}</a>
            คน <v-icon color="grey">mdi-account</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label
            >ยื่นใบสมัคร/รอการตรวจสอบ
            <a
              @click="
                filterJobApplicationsByJobApplicationStatusCode('Pending')
              "
              >{{ quantity_of_pending_jobapplication }}</a
            >
            คน <v-icon color="grey">mdi-account-clock</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            อยู่ระหว่างพิจารณา
            <a
              @click="
                filterJobApplicationsByJobApplicationStatusCode('UnderReview')
              "
              >{{ quantity_of_underreview_jobapplication }}</a
            >
            คน <v-icon color="grey">mdi-clock</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            นัดหมายสัมภาษณ์
            <a
              @click="
                filterJobApplicationsByJobApplicationStatusCode(
                  'InterviewScheduled'
                )
              "
              >{{ quantity_of_interviewscheduled_jobapplication }}</a
            >
            คน <v-icon color="info">mdi-calendar-today</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            รับเข้าทำงาน
            <a
              @click="
                filterJobApplicationsByJobApplicationStatusCode('Accepted')
              "
              >{{ quantity_of_accecpted_jobapplication }}</a
            >
            คน <v-icon color="success">mdi-check-circle</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            ไม่ผ่านการพิจารณา
            <a
              @click="
                filterJobApplicationsByJobApplicationStatusCode('Rejected')
              "
              >{{ quantity_of_rejected_jobapplications }}</a
            >
            คน <v-icon color="error">mdi-close-octagon</v-icon></label
          >
        </div>
      </v-col>
    </v-row>

    <SearchableDataTable
      table_caption="ใบสมัครงาน"
      :data_items="filteredItems"
      :datatable_headers="datatable_headers"
    >
      <template v-slot:item.createddate="{ item }">
        <FormatDate :date_value="item.createddate"></FormatDate>
      </template>
      <template v-slot:item.jobapplicationstatus.statusname="{ item }">
        <JobApplicationStatus_Label
          :JobApplication="item"
        ></JobApplicationStatus_Label>
      </template>
      <template v-slot:item.student.firstname="{ item }">
        <span>{{ item.student.firstname }}</span> {{ item.student.surname }}
      </template>

      <template v-slot:item.student.birthday="{ item }">
        <span>{{ moment().diff(item.student.birthday, "years") }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openJobApplicationDetailView(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file</v-icon>
            </v-btn>
          </template>
          <span>แสดงรายละเอียดใบสมัครงาน</span>
        </v-tooltip>
        <v-tooltip top color="warning">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openJobApplicationStatusManagementDialog(item)"
              color="warning"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file-edit</v-icon>
            </v-btn>
          </template>
          <span>อัปเดตสถานะใบสมัครงาน</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
    <v-dialog v-model="dialog" max-width="700px">
      <template>
        <JobApplicatiionStatusManagement
          :JobApplication="selected_jobapplication"
          :sentopenBack="false"
          @finished="onJobApplicationStatusManagementFinished"
        ></JobApplicatiionStatusManagement>
      </template>
    </v-dialog>
  </v-container> -->
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
import JobPostingStatus_Label from "@/components/JobPostings/JobPostingStatus_Label.vue";
import JobApplicationStatus_Label from "@/components/JobPostings/JobApplicationStatus_Label.vue";
import JobApplicatiionStatusManagement from "../๋JobPostings/JobApplicatiionStatusManagement.vue";
import moment from "moment";

import FormatDateView from "@/components/common/FormatDateView.vue";

const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: {
    SearchableDataTable,
    JobPostingStatus_Label,
    JobApplicationStatus_Label,
    JobApplicatiionStatusManagement,
    FormatDateView,
  },
  name: "EmployerJobPosting_Applications_View",
  data() {
    return {
      oid: "",
      dialog: false,
      jobposting: undefined,
      jobapplications: [],
      selected_jobapplication: undefined,
      jobapplication_statuscode: "",
      quantity_of_all_jobapplication: 0,
      quantity_of_pending_jobapplication: 0,
      quantity_of_underreview_jobapplication: 0,
      quantity_of_interviewscheduled_jobapplication: 0,
      quantity_of_accecpted_jobapplication: 0,
      quantity_of_rejected_jobapplications: 0,
      datatable_headers: [
        { value: "createddate", text: "วันที่ยื่นใบสมัคร" },
        { value: "student.firstname", text: "ชื่อ-นามสกุล" },
        { value: "student.gendername_todisplay", text: "เพศ" },
        { value: "student.birthday", text: "อายุ" },
        { value: "student.majornameth", text: "สาขาวิชา" },
        { value: "jobapplicationstatus.statusname", text: "สถานะใบสมัคร" },
        { value: "actions", text: "ตัวเลือก" },
      ],
      moment: moment,
      birthday: "",
      activeButton: null,
    };
  },
  computed: {
    filteredItems() {
      if (this.jobapplication_statuscode == "") {
        return this.jobapplications;
      } else {
        return this.jobapplications.filter(
          (x) =>
            x.jobapplicationstatus.statuscode == this.jobapplication_statuscode
        );
      }
    },
  },
  methods: {
    async loadJobPosting() {
      await employerJobPostingDataProvider
        .getJobPosting(this.oid)
        .then((result) => {
          this.jobposting = result.data;
        });
    },
    async loadJobApplications() {
      await employerJobPostingDataProvider
        .getJobApplicationsByJobPostingOid(this.oid)
        .then((result) => {
          this.jobapplications = result.data;
        });
    },
    // openJobApplicationInfo(item) {
    //   const oid = item.oid;
    //   this.$router.push({ name: "JobApplicationInfo", params: { oid } });
    // },
    openJobApplicationDetailView(item) {
      const oid = item.oid;
      this.$router.push({
        name: "JobApplicationDetailView",
        params: { oid, sentopenBack: true },
      });
    },
    openJobApplicationStatusManagementDialog(item) {
      this.selected_jobapplication = item;
      this.dialog = true;
    },
    async onJobApplicationStatusManagementFinished() {
      this.dialog = false;
      this.filterJobApplicationsByJobApplicationStatusCode("");
      await this.loadJobApplications();
    },
    updateAboutQuantityOfJobApplications() {
      this.quantity_of_all_jobapplication = this.jobapplications.length;
      this.quantity_of_pending_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Pending"
      ).length;
      this.quantity_of_interviewscheduled_jobapplication =
        this.jobapplications.filter(
          (x) => x.jobapplicationstatus.statuscode == "InterviewScheduled"
        ).length;
      this.quantity_of_underreview_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "UnderReview"
      ).length;
      this.quantity_of_accecpted_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Accecpted"
      ).length;
      this.quantity_of_rejected_jobapplications = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Rejected"
      ).length;
    },
    filterJobApplicationsByJobApplicationStatusCode(statuscode = "") {
      this.jobapplication_statuscode = statuscode;
    },
    openEmployerUpdateJobPostingView() {
      const oid = this.oid;
      this.$router.push({
        name: "EmployerUpdateJobPostingView",
        params: { oid },
      });
    },
    setActive(buttonNumber) {
      this.activeButton = buttonNumber;
    },
    setActive(buttonNumber) {
      this.activeButton = buttonNumber;
    },
  },
  async created() {
    if (this.$route.params.oid != undefined) {
      this.oid = this.$route.params.oid;
    }
    await this.loadJobPosting();
    await this.loadJobApplications();
    this.updateAboutQuantityOfJobApplications();
  },
  mounted() {
    this.daysDifference = moment().diff("1999-07-28", "years");
  },
};
</script>

<style></style>
