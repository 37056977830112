import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { CreateGraduatedStudentJobTrackingReportMain_Request } from "@/models/GraduatedStudentJobTrackingReport/CreateGraduatedStudentJobTrackingReportMain_Request";
import { GetGraduatedStudentJobTrackingReports_Request } from "@/models/GraduatedStudentJobTrackingReport/GetGraduatedStudentJobTrackingReports_Request";
import { GraduatedStudentJobTrackingReportsRequest } from "@/models/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReports_Request";
import { GetGraduatedStudentJobTrackingReportsAndFilters_Request } from "@/models/GraduatedStudentJobTrackingReport/GetGraduatedStudentJobTrackingReportsAndFilter_Request";
import { EditTrackingMainDate_Request } from "@/models/GraduatedStudentJobTrackingReport/EditTrackingMainDate_Request";
import { GraduatedStudentJobTrackingReportsMain_Request } from "@/models/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportsMain_Request";
import { ExtendTrackingMainEndDate_Request } from "@/models/GraduatedStudentJobTrackingReport/ExtendTrackingMainEndDate_Request";

import axios from "axios";
import { create_default_request_header } from "../constants";
export class GraduatedStudentJobTrackingReportReportDataProvider {
  apiBaseURL = "";
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  CreateGraduatedStudentJobTrackingReportMain(
    request = new CreateGraduatedStudentJobTrackingReportMain_Request()
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/CreateGraduatedStudentJobTrackingReportMain";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(request);
    return axios.post(combinedURL, data, headers);
  }

  importStudentDetailsFromGraduatedStudentJobTrackingMain(oid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/StartImportStudentDetailsFromGraduatedStudentJobTrackingMain/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  LoadGraduatedStudentJobTrackingReportMains(
    GraduatedStudentJobTrackingReportsMain_Request = new GraduatedStudentJobTrackingReportsMain_Request()
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/LoadGraduatedStudentJobTrackingReportMains";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(GraduatedStudentJobTrackingReportsMain_Request);
    return axios.post(combinedURL, data, headers);
  }

  LoadGraduatedStudentJobTrackingReportMain(oid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/LoadGraduatedStudentJobTrackingReportMain/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  deleteGraduatedStudentJobTrackingReportMain(oid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/DeleteGraduatedStudentJobTrackingReportMain/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  loadGraduatedStudentJobTrackingReport(oid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/LoadGraduatedStudentJobTrackingReport/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  importGraduatedStudents(oid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/ImportGraduatedStudents/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  importGraduatedStudentsBackgroundservice(oid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/backgroundapi/GraduatedStudentJobTrackingReportImport/ImportStudentDetails/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  importGraduatedStudentsFromPreviousDataBackgroundservice(oid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/backgroundapi/GraduatedStudentJobTrackingReportImport/ImportPreviousStudentDetails/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  LoadReports(
    getGraduatedStudentJobTrackingReports_Request = new GetGraduatedStudentJobTrackingReports_Request()
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/LoadGraduatedStudentJobTrackingReports";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(getGraduatedStudentJobTrackingReports_Request);
    return axios.post(combinedURL, data, headers);
  }

  LoadReportsAndFilter(request) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/LoadGraduatedStudentJobTrackingReportsAndFilter";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(request);
    return axios.post(combinedURL, data, headers);
  }

  LoadFilterReports(request_filter) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/LoadFilterGraduatedStudentJobTrackingReports";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(
      combinedURL,
      {
        schooltype: request_filter.schooltype,
        targetgraduatedyearth: request_filter.targetgraduatedyearth,
        roundnumber: request_filter.roundnumber,
      },
      headers
    );
  }

  loadGraduatedStudentJobTrackingReportStudentDetailsWithPagination(
    request = new GraduatedStudentJobTrackingReportsRequest()
  ) {
    let jsonData = JSON.stringify(request);
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/loadGraduatedStudentJobTrackingReportStudentDetailsWithPagination";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, jsonData, headers);
  }

  loadGraduatedStudentDetail(oid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/LoadGraduatedStudentDetail/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  saveGraduatedStudentJobTrackingReportStudentDetailsFromExcelEditor(
    reportDetails
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/SaveGraduatedStudentJobTrackingReportStudentDetailsFromExcelEditor";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(reportDetails);
    return axios.post(combinedURL, data, headers);
  }

  assignManagerProfile(AssignCertify) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/AssignManagerJobTrackingReport";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(
      combinedURL,
      {
        jobtrackingreportoid: AssignCertify.oid,
        verifymanageroid: AssignCertify.verifymanageroid,
        profileoid: AssignCertify.profileoid,
      },
      headers
    );
  }

  loadGraduatedStudentJobTrackingReportByProfile() {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportByProfile";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, null, headers);
  }

  CerifyGraduatedStudentJobTrackingReport(oid, profileoid, certifydate) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/CerifyGraduatedStudentJobTrackingReport";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(
      combinedURL,
      {
        oid: oid,
        profileoid: profileoid,
        certifydate: certifydate,
      },
      headers
    );
  }
  unCerifyGraduatedStudentJobTrackingReport(oid) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/unCerifyGraduatedStudentJobTrackingReport/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(
      combinedURL,
      {
        oid: oid,
      },
      headers
    );
  }

  async SaveGraduatedStudentJobTrackingReportStudentDetail(reportDetails) {
    // console.log("reportDetails: ", reportDetails);
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/SaveGraduatedStudentJobTrackingReportStudentDetail";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(reportDetails);
    // console.log("data: ", data);
    // return axios.post(combinedURL, data, headers);
    return axios.post(combinedURL, data, headers);
    // console.log(res);
  }

  extendTrackingEndDate(request) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/ExtendTrackingEndDate";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(request);
    return axios.post(combinedURL, data, headers);
  }

  getQuantityOfStudentDetail(graduatedStudentJobTrackingReportOid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/GetQuantityOfStudentDetail/" +
      graduatedStudentJobTrackingReportOid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  EditTrackingMainDate(request = new EditTrackingMainDate_Request()) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReportTimeManagement/EditTrackingMainDate";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(request);
    return axios.post(combinedURL, data, headers);
  }

  ExtendTrackingMainEndDate(request = new ExtendTrackingMainEndDate_Request()) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReportTimeManagement/ExtendTrackingMainEndDate";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(request);
    return axios.post(combinedURL, data, headers);
  }

  allowEditStudentDetail(graduatedStudentJobTrackingReportOid = "") {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/AllowEditStudentDetail/" +
      graduatedStudentJobTrackingReportOid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  mark_GraduatedStudentJobTrackingReportMain_TrackingProcessIsCompleted(
    oid = ""
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/MarkGraduatedStudentJobTrackingReportMainTrackingProcessIsCompleted/" +
      oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  loadFilterForGraduatedStudentDetail(
    GraduatedStudentJobTrackingReport_Oid = ""
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReport/LoadFilterForGraduatedStudentDetail/" +
      GraduatedStudentJobTrackingReport_Oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, null, headers);
  }
}
