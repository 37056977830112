<template>
  <div>
    <div class="mb-2 mt-4">
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn @click="openAddApplicationRole_dialog = true" color="info"
            ><v-icon>mdi-plus</v-icon> เพิ่มสิทธิการใช้งาน</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <SearchableDataTable
      :data_items="applicationUserRoles"
      table_caption="สิทธิการใช้งาน"
      :datatable_headers="applicationUserRoles_data_table_headers"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="onDelete(item)" color="error" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบ</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>

    <v-dialog v-model="openAddApplicationRole_dialog">
      <template>
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="เลือก"
                  :items="applicationRoles"
                  item-text="description"
                  item-value="code"
                  v-model="selected_application_rolecode"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn color="secondary" @click="onSave"
                  ><v-icon> mdi-content-save </v-icon>บันทึก</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "../common/SearchableDataTable.vue";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
import { AddApplicationUserRole_Request } from "@/models/ApplicationUsers/AddApplicationUserRole_Request";
import { DeleteApplicationUserRole_Request } from "@/models/ApplicationUsers/DeleteApplicationUserRole_Request";

const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: { SearchableDataTable },
  name: "ApplicationUserRoleManagement",
  props: ["applicationuser_oid"],
  data() {
    return {
      openAddApplicationRole_dialog: false,
      selected_application_rolecode: "",

      applicationRoles: undefined,
      applicationUserRoles: undefined,
      applicationUserRoles_data_table_headers: [
        { text: "ชื่อสิทธิ", value: "description" },
        { text: "", value: "action" , sortable: false},
      ],
    };
  },

  methods: {
    async getApplicationRoles() {
      await masterDataProvider.getApplicationRoles().then((result) => {
        this.applicationRoles = result.data;
      });
    },

    async loadApplicationUserRoles() {
      await applicationUserDataProvider
        .getApplicationUserRoles(this.applicationuser_oid)
        .then((result) => {
          this.applicationUserRoles = result.data;
        });
    },

    async onSave() {
      if (this.selected_application_rolecode != "") {
        const request = new AddApplicationUserRole_Request();
        request.applicationuser_oid = this.applicationuser_oid;
        request.role_code = this.selected_application_rolecode;
        await applicationUserDataProvider
          .addApplicationUserRole(request)
          .then((result) => {
            this.$toast.success("บันทึกข้อมูลเรียบร้อย");
            this.loadApplicationUserRoles();
          });
        this.openAddApplicationRole_dialog = false;
      }
    },

    async onDelete(item) {
      if (confirm("ยืนยัน?")) {
        const role_code = item.code;
        const request = new DeleteApplicationUserRole_Request();
        request.applicationuser_oid = this.applicationuser_oid;
        request.role_code = role_code;
        await applicationUserDataProvider
          .deleteApplicationUserRole(request)
          .then((result) => {
            this.$toast.success("ลบเรียบร้อย");
            this.loadApplicationUserRoles();
          });
      }
    },
  },

  async created() {
    await this.getApplicationRoles();
    await this.loadApplicationUserRoles();
  },
};
</script>

<style></style>
