export class JobRecord {
    oid = '-1'
    student_oid = ''
    startdate = ''
    enddate = ''
    organizationtype = ''
    selected_organizationtype_code = ''
    organizationname = ''
    positionname = ''
    jobdescription = ''
    salary_range = ''
    relatedwithgraudatedfieldstatus = ''
}