import { render, staticRenderFns } from "./EmployerResetPassword.vue?vue&type=template&id=38e7f2bd&scoped=true"
import script from "./EmployerResetPassword.vue?vue&type=script&lang=js"
export * from "./EmployerResetPassword.vue?vue&type=script&lang=js"
import style0 from "./EmployerResetPassword.vue?vue&type=style&index=0&id=38e7f2bd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e7f2bd",
  null
  
)

export default component.exports