<template>
  <v-dialog v-model="ExperienceStudentDetailView_Dialog" width="850" persistent>
    <v-card class="pa-10">
      <h2 class="primary--text">ประวัติการทำงาน</h2>
      <v-divider class="mt-4 mb-5 mx-0"></v-divider>
      <v-card-text class="px-0">
        <v-form ref="form" v-model="valid">
          <v-row class="align-center mt-2 mx-0" height="10">
            <TextFieldDatePicker
              :value="selected_startdate"
              caption="วันที่เริ่มทำงาน"
              :isDisplay="ExperienceStudentDetailView_Dialog"
              @closeAndUpdate="this.closeAndUpdateTextFieldDatePicker_Startdate"
              filled
              background-color="input"
            >
            </TextFieldDatePicker>
          </v-row>
          <v-row class="align-center mx-0">
            <TextFieldDatePicker
              :value="selected_enddate"
              caption="วันที่สิ้นสุดการทำงาน"
              :isDisplay="ExperienceStudentDetailView_Dialog"
              @closeAndUpdate="this.closeAndUpdateTextFieldDatePicker_Enddate"
              filled
              background-color="input"
            >
            </TextFieldDatePicker>
          </v-row>
          <v-radio-group
            :rules="[(v) => !!v || 'กรุณาระบุประเภทสถานประกอบการ']"
            v-model="vm.selected_organizationtype_code"
            row
          >
            <template v-slot:label>
              <div><strong>ประเภทสถานประกอบการ</strong></div>
            </template>
            <v-radio label="รัฐ" color="blue" value="government"></v-radio>
            <v-radio
              label="เอกชน"
              color="blue"
              value="private_organization"
            ></v-radio>
          </v-radio-group>
          <v-row class="align-center mx-0">
            <v-text-field
              label="ชื่อสถานประกอบการ"
              filled
              background-color="input"
              dense
              v-model="vm.organizationname"
              :rules="[(v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ']"
            >
            </v-text-field>
          </v-row>
          <v-row class="align-center mx-0">
            <v-text-field
              label="ตำแหน่งงาน"
              filled
              background-color="input"
              dense
              v-model="vm.positionname"
              :rules="[(v) => !!v || 'กรุณาระบุตำแหน่งงาน']"
            >
            </v-text-field>
          </v-row>
          <v-row class="align-center mx-0">
            <v-text-field
              label="รายละเอียดงาน"
              filled
              background-color="input"
              dense
              v-model="vm.jobdescription"
            >
            </v-text-field>
          </v-row>

          <v-row class="align-center mx-0">
            <v-select
              v-model="vm.salary_range"
              :items="SalaryRanges"
              item-value="salary_range"
              item-text="salary_range"
              label="ระดับเงินเดือนค่าตอบแทน"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
              dense
            >
            </v-select>
          </v-row>
          <v-radio-group
            @change="onSelectedRelatedwithgraudatedfieldstatus"
            :rules="[(v) => !!v || 'กรุณาระบุสาขา']"
            v-model="vm.relatedwithgraudatedfieldstatus"
            row
          >
            <template v-slot:label>
              <div><strong>งานที่ตรงกับวุฒิหรือไม่</strong></div>
            </template>
            <v-radio label="ตรงตามวุฒิ" color="blue" :value="1"></v-radio>
            <v-radio label="ไม่ตรงตามวุฒิ" color="blue" :value="2"></v-radio>
          </v-radio-group>
          <v-row class="justify-center text-center mt-7 mx-0">
            <v-col cols="12">
              <v-btn @click="onCancelClick()" class="mx-2" color="">
                ยกเลิก
              </v-btn>
              <v-btn
                :loading="isInCreatingProcess"
                @click="onSaveClick()"
                dark
                class="mx-3 color-buton"
                >บันทึก<v-icon class="ml-2"> mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="confirm_dialog" width="400">
            <template>
              <v-card class="pa-6">
                <v-row class="justify-center text-center">
                  <v-card-title> ยืนยันเพิ่มประวัติการทำงาน ? </v-card-title>
                  <v-col cols="12">
                    <v-btn
                      @click="confirm_dialog = false"
                      class="ma-2 back-button"
                      >ยกเลิก</v-btn
                    >
                    <v-btn @click="onButtonClick()" class="ma-2 color-button2">
                      ยืนยัน
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-dialog>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import { CreateExperienceStudentDetailViewMain_Request } from "@/models/School/CreateExperienceStudentDetailViewMain_Request";
import { JobRecord } from "@/models/Student/JobRecord";
import { MasterDataProvider } from "@/services/MasterDataProvider";
var studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "ExperienceStudentDetailView",
  props: {
    ExperienceStudentDetailView_Dialog: Boolean,
    oid: Number,
    selected_startdate: String,
    selected_enddate: String,
  },
  data() {
    return {
      vm: new JobRecord(),
      isInCreatingProcess: false,
      confirm_dialog: false,
      confirm_result: false,
      SalaryRanges: [],
      valid: false,
    };
  },
  watch: {
    ExperienceStudentDetailView_Dialog(value) {
      if (value) {
        if (this.oid != -1) {
          this.loadStudentJobRecord(this.oid);
        } else {
          this.vm = new JobRecord();
          this.selected_startdate = "";
          this.selected_enddate = "";
        }
      }
    },
  },
  methods: {
    onSelectedRelatedwithgraudatedfieldstatus(value) {
      console.log(value);
    },
    closeAndUpdateTextFieldDatePicker_Startdate(selectedDate = "") {
      this.vm.startdate = selectedDate;
    },

    closeAndUpdateTextFieldDatePicker_Enddate(selectedDate = "") {
      this.vm.enddate = selectedDate;
    },

    async loadStudentJobRecord(oid) {
      await studentDataProvider
        .getStudentJobRecord(oid)
        .then((response) => {
          this.vm = response.data;
          console.log(this.vm);
          this.vm.selected_organizationtype_code =
            this.vm.organizationtype.code;
          this.vm.salary_range = response.data.salary_range;
          this.selected_startdate = this.vm.startdate;
          this.selected_enddate = this.vm.enddate;
        })
        .catch((response) => {
          console.log(response);
          this.$toast.error("ล้มเหลว!");
        });
    },
    onSaveClick() {
      if (this.$refs.form.validate()) {
        this.confirm_dialog = true;
      }
    },
    onButtonClick() {
      if (this.$refs.form.validate()) {
        this.isInCreatingProcess = false;
        this.$emit("closeDialog", this.vm, true);
        this.resetForm();
      }
    },
    onCancelClick() {
      this.resetForm();
      this.$emit("closeDialog", this.vm, false);
    },
    resetForm() {
      this.vm = new JobRecord();
      this.selected_RewardDate = "";
      this.$refs.form.reset();
    },
    async loadSalaryRanges() {
      masterDataProvider.loadSalaryRanges().then((response) => {
        this.SalaryRanges = response.data;
      });
    },
  },
  async created() {
    await this.loadSalaryRanges();
  },
  mounted() {},
  components: { TextFieldDatePicker },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
