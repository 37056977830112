<template>
  <v-form>
    <!-- <v-container>
      <h4>{{ jobapplication.jobposting.jobposting_id }}</h4>
    </v-container> -->
    <v-row class="d-flex justify-center pt-5">
      <v-col cols="11">
        <div id="element-to-convert">
          <v-card style="border-radius: 15px">
            <v-card-text>
              <v-row>
                <v-col cols="12 pl-5">
                  <v-row>
                    <v-col class="d-flex flex-column align-start pa-2 pt-3" cols="12">
                      <h4>
                        รหัสประกาศงาน : {{ jobapplication.jobposting.jobposting_id }}
                      </h4>
                    </v-col>
                    <v-col
                      class="d-flex flex-column align-center pa-2 pt-8 pb-4"
                      cols="12"
                    >
                      <v-row class="">
                        <h2 class="pb-2">
                          {{ jobapplication.jobposting.positionname }}
                        </h2>
                      </v-row>
                      <v-row class="">
                        <label>{{
                          jobapplication.jobposting.employerprofile.nameth
                        }}</label>
                      </v-row>
                      <v-row class="">
                        <div style="font-style: italic">
                          <v-icon>mdi-map-marker</v-icon
                          ><label>{{
                            jobapplication.jobposting.employerprofile.district.province
                              .provincethai
                          }}</label>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br />
                  <v-col>
                    <v-divider :thickness="3" class="border-opacity-75" color="secondary">
                    </v-divider>
                  </v-col>

                  <br />
                  <v-row>
                    <v-col class="d-flex flex-column align-end" cols="3">
                      <v-row class="pa-1">
                        <label>อัตราที่รับสมัคร :</label>
                      </v-row>
                      <v-row class="pa-1">
                        <label>ประเภทงาน :</label>
                      </v-row>
                      <v-row class="pa-1">
                        <label>เงินเดือน :</label>
                      </v-row>
                      <v-row class="pa-1">
                        <label>ระดับการศึกษาขั้นต่ำ :</label>
                      </v-row>
                      <v-row class="pa-1">
                        <label>วันที่ประกาศ :</label>
                      </v-row>
                    </v-col>

                    <v-col cols="9">
                      <v-row class="pa-1">
                        <label>{{ jobapplication.jobposting.unit }}</label>
                      </v-row>
                      <v-row class="pa-1">
                        <label
                          v-if="
                            this.jobapplication.jobposting.jobsegmentname_todisplay !== ''
                          "
                          >{{ jobapplication.jobposting.jobsegmentname_todisplay }}</label
                        >
                        <label
                          v-if="this.jobapplication.jobposting.employmentcategory == ''"
                          >-</label
                        >
                      </v-row>
                      <v-row class="pa-1">
                        <label>{{ jobapplication.jobposting.wagetodisplay }}</label>
                      </v-row>
                      <v-row class="pa-1">
                        <label>{{
                          jobapplication.jobposting.applicant_requiredcertificatetype
                            .nameth
                        }}</label>
                      </v-row>
                      <v-row class="pa-1">
                        <label
                          >{{ jobapplication.jobposting.startdate }} -
                          {{ jobapplication.jobposting.enddate }}</label
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <div
                style="
                  background-color: rgba(63, 81, 181, 0.8);
                  color: white;
                  border-radius: 20px;
                  padding: 5px 10px;
                  font-size: 16px;
                  margin: 40px 0px 30px;
                "
                class="text-center"
              >
                คุณสมบัติผู้สมัคร
              </div>

              <v-row>
                <v-col>
                  <v-row class="benefit pa-2 d-flex flex-column pl-5">
                    {{ jobapplication.jobposting.applicant_specification }} <br />
                  </v-row>
                </v-col>
              </v-row>

              <div
                style="
                  background-color: rgba(63, 81, 181, 0.8);
                  color: white;
                  border-radius: 20px;
                  padding: 5px 10px;
                  font-size: 16px;
                  margin: 40px 0px 30px;
                "
                class="text-center"
              >
                หน้าที่และรายละเอียดของงาน
              </div>
              <v-row>
                <v-col>
                  <v-row class="pa-2">
                    <div class="d-flex flex-column pl-4">
                      <label class="">
                        {{ jobapplication.jobposting.jobdescription }}
                      </label>
                    </div>
                  </v-row>
                </v-col>
              </v-row>

              <div
                style="
                  background-color: rgba(63, 81, 181, 0.8);
                  color: white;
                  border-radius: 20px;
                  padding: 5px 10px;
                  font-size: 16px;
                  margin: 40px 0px 30px;
                "
                class="text-center"
              >
                สวัสดิการ
              </div>
              <v-row>
                <v-col>
                  <v-row class="benefit pa-2 d-flex flex-column pl-5">
                    {{ jobapplication.jobposting.employerprofile.benefitdescription }}
                    <br />
                  </v-row>
                </v-col>
              </v-row>
              <div
                style="
                  background-color: rgba(63, 81, 181, 0.8);
                  color: white;
                  border-radius: 20px;
                  padding: 5px 10px;
                  font-size: 16px;
                  margin: 40px 0px 30px;
                "
                class="text-center"
              >
                ข้อมูลผู้ประสานงานของสถานประกอบการ
              </div>
              <v-row>
                <v-col>
                  <v-row class="benefit pa-2 d-flex flex-column pl-5">
                    ชื่อ-นามสกุล : {{ jobapplication.jobposting.coordinator_fullname }}
                  </v-row>
                  <v-row class="benefit pa-2 d-flex flex-column pl-5">
                    เบอร์โทรศัพท์ : {{ jobapplication.jobposting.coordinator_phoneno }}
                  </v-row>
                  <v-row class="benefit pa-2 d-flex flex-column pl-5 pb-5">
                    อีเมล : {{ jobapplication.jobposting.coordinator_email }}
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <div class="html2pdf__page-break"></div>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { JobApplicationDataProvider } from "@/services/JobApplicationDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
import moment from "moment";
const jobApplicationDataProvider = new JobApplicationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import html2pdf from "html2pdf.js";
import JobApplicatiionStatusManagement from "./JobApplicatiionStatusManagement.vue";

export default {
  name: "JobApplicationJobPostingDetailView",
  components: { JobApplicatiionStatusManagement },
  data() {
    return {
      jobapplication_oid: "",
      jobapplication: undefined,
      jobapplicationentitystatuses: undefined,
      selected_jobapplicationentitystatus_code: "",
      logo_photobase64: "",
      moment: moment,
      entitystatuses: undefined,
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider.getJobApplicationEntityStatuses().then((result) => {
        this.jobapplicationentitystatuses = result.data;
      });

      await jobApplicationDataProvider
        .getJobApplication(this.jobapplication_oid)
        .then((result) => {
          this.jobapplication = result.data;
          this.selected_jobapplicationentitystatus_code = this.jobapplication.jobapplicationstatus.statuscode;
          this.logo_photobase64 = result.data.student.base64thrumnail;
        });
    },
    print() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: "JobApplicationInfo.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        enableLinks: true,
        pagebreak: { mode: ["css", "legacy"] },
      });
    },
    openSearchStudentDetails: function () {
      const student_oid = this.jobapplication.student.oid;
      this.$router
        .push({ name: "SearchStudentDetails", params: { student_oid } })
        .catch((err) => {});
    },
    async onSaveSelectedJobApplicationStatus() {
      await employerJobPostingDataProvider
        .setJobApplicationStatus(
          this.jobapplication_oid,
          this.selected_jobapplicationentitystatus_code
        )
        .then((result) => {
          this.$toast.success("บันทึกสถานะใบสมัครงานเรียบร้อย");
        });
    },
  },
  mounted() {
    // var datenow = new Date
    this.daysDifference = moment().diff("2000-02-28", "years");
  },
  async created() {
    if (this.$route.params.oid != undefined) {
      this.jobapplication_oid = this.$route.params.oid;
      await this.initialize();
    }
  },
};
</script>

<style scoped>
.shrink {
  border-radius: 200px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 10px rgba(15, 15, 15, 0.5);
  border: 1px solid grey;
}
</style>
