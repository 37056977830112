<template>
  <v-app-bar color="indigo" app dark>
    <div class="d-flex align-center">
      <router-link to="/home">
        <v-img
          alt="VEC Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="50"
        />
      </router-link>
      <router-link to="/home">
        <v-img
          alt="สำนักงานคณะกรรมการการอาชีวศึกษา++++++"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          src="@/assets/logo-name.png"
          width="100%"
        />
      </router-link>
    </div>
    <v-spacer></v-spacer>
    <template>
      <div class="pr-3" v-if="$vuetify.theme.dark">
        <v-switch
          v-model="$vuetify.theme.dark"
          label="โหมดกลางคืน"
          inset
          persistent-hint
          hint="สลับโหมดกลางวัน/กลางคืน"
          color="primary"
        >
        </v-switch>
      </div>
      <div class="pr-3" v-else>
        <v-switch
          v-model="$vuetify.theme.dark"
          label="โหมดกลางวัน"
          inset
          persistent-hint
          hint="สลับโหมดกลางวัน/กลางคืน"
          color="primary"
        >
        </v-switch>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style></style>
