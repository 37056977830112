import CreateNewsManagementDetailView from "@/views/News/CreateNewsManagementDetailView.vue";
import NewsManagementListView from "@/views/News/NewsManagementListView.vue";
import EditNewsManagementDetailView_SchoolEmployee from "@/views/News/EditNewsManagementDetailView_SchoolEmployee.vue";
import EditNewsManagementDetailView from "@/views/News/EditNewsManagementDetailView.vue";
import NewsManagementListView_SchoolEmployee from "@/views/News/NewsManagementListView_SchoolEmployee.vue"
import NewsDetail from "@/views/News/NewsDetail.vue"
export default [
  {
    path: "/News",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/News/NewsManagementListView",
        name: "NewsManagementListView",
        component: NewsManagementListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายการข่าวประชาสัมพันธ์",
        },
      },
      {
        path: "/News/NewsManagementListView_SchoolEmployee",
        name: "NewsManagementListView_SchoolEmployee",
        component: NewsManagementListView_SchoolEmployee,
        meta: {
          requiresAuth: true,
          nameTh: "รายการข่าวประชาสัมพันธ์",
        },
      },
      {
        path: "/News/CreateNewsManagementDetailView",
        name: "CreateNewsManagementDetailView",
        component: CreateNewsManagementDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "สร้างข่าวประชาสัมพันธ์",
        },
      },
      {
        path: "/News/EditNewsManagementDetailView_SchoolEmployee/:oid",
        name: "EditNewsManagementDetailView_SchoolEmployee",
        component: EditNewsManagementDetailView_SchoolEmployee,
      },
      {
        path: "/News/EditNewsManagementDetailView/:oid",
        name: "EditNewsManagementDetailView",
        component: EditNewsManagementDetailView,
      },
      {
        path: '/previewNews/:oid',
        name: 'NewsDetail',
        component: NewsDetail,
      },
    
      
    ],
  },
];
