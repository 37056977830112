<template>
  <v-container class="pa-5">
    <SearchableDataTable v-if="(students != undefined)" table_caption="ข้อมูลนักศึกษา"
      :datatable_headers="students_data_table_headers" :data_items="students"></SearchableDataTable>
  </v-container>
</template>

<script>
import { backendapiUrl } from '@/services/constants';
import { StudentDataProvider } from '@/services/StudentDataProvider';
import SearchableDataTable from '@/components/common/SearchableDataTable.vue';
var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: { SearchableDataTable },
  name: 'StudentListView',
  data() {
    return {
      schoolID: '',
      students: undefined,
      students_data_table_headers: [
        { text: "รหัสนักศึกษา", value: "studentcode" },
        { text: "ชื่อ", value: "firstname" },
        { text: 'นามสกุล', value: 'surname' },
        { text: "ระดับการศึกษา", value: "gradenameth" },
        { text: "", value: "action" , sortable: false},
      ],
    }
  },
  methods: {
    async loadStudents(schoolID = '') {
      await studentDataProvider.loadStudents(schoolID).then(result => {
        this.students = result.data;
      }).catch(err => { alert(err); });
    }
  },
  async created() {
    this.schoolID = this.$route.params.schoolID;
    if (this.schoolID != '') {
      await this.loadStudents(this.schoolID);
    }
  }
}
</script>

<style>
</style>

