<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <!-- dialogNotFound -->
        <div v-if="dialogNotFound">
          <template>
            <v-card class="pa-2">
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" icon @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <h2 class="black--text">ไม่พบข้อมูลนิติบุคคล</h2>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-img max-width="100" src="../../assets/checkNotFound.png"></v-img>
                  </v-col>

                  <v-col cols="12" class="d-flex justify-center my-0 py-0">
                    <h3>{{ employerName }}</h3>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center mt-2 py-0">
                    <h3 style="color: #ff3333">ไม่พบข้อมูล</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn outlined @click="closeDialog" class="mt-3" color=""
                      >ตกลง</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </div>
        <!-- dialogFound -->
        <div v-if="dialogFound">
          <template>
            <v-card class="pa-2">
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" icon @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <h2 class="black--text">ตรวจพบข้อมูล</h2>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-img max-width="100" src="../../assets/checkFound.png"></v-img>
                  </v-col>

                  <v-col cols="12" class="d-flex justify-center my-0 py-0">
                    <h3>{{ employerName }}</h3>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center mt-2 py-0">
                    <h3 v-if="status" :style="{ color: chipcard_status.color }">
                      {{ status }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn outlined @click="closeDialog" class="mt-3" color=""
                      >ตกลง</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </div>
        <!-- dialogFail -->
        <div v-if="dialogFail">
          <template>
            <v-card class="pa-2">
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" icon @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <h2 class="black--text">แจ้งระบบขัดข้อง</h2>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-img max-width="100" src="../../assets/checkFail.png"></v-img>
                  </v-col>

                  <v-col cols="12" class="d-flex justify-center my-0 py-0">
                    <h3>เนื่องจากขณะนี้ ระบบขัดข้องชั่วคราว</h3>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center mt-2 py-0">
                    <h3>กรุณาลองใหม่อีกครั้ง</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn outlined @click="closeDialog" class="mt-3" color=""
                      >ตกลง</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </div>
        <!-- </v-row> -->
        <!-- <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn outlined @click="closeDialog">ตกลง</v-btn>
          </v-col>
        </v-row> -->
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialogFound" width="30%">
      <template>
        <v-card class="pa-2">
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" icon @click="dialogFound = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <h2 class="black--text">ตรวจพบข้อมูล</h2>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  max-width="100"
                  src="../../assets/checkFound.png"
                ></v-img>
              </v-col>

              <v-col cols="12" class="d-flex justify-center my-0 py-0">
                <h3>{{ employerName }}</h3>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-2 py-0">
                <h3 v-if="status" :style="{ color: chipcard_status.color }">
                  {{ status }}
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  outlined
                  @click="dialogFound = false"
                  class="mt-3"
                  color=""
                  >ตกลง</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="dialogNotFound" width="30%">
      <template>
        <v-card class="pa-2">
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" icon @click="dialogNotFound = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <h2 class="black--text">ไม่พบข้อมูลนิติบุคคล</h2>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  max-width="100"
                  src="../../assets/checkNotFound.png"
                ></v-img>
              </v-col>

              <v-col cols="12" class="d-flex justify-center my-0 py-0">
                <h3>{{ employerName }}</h3>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-2 py-0">
                <h3 v-if="status" style=" color: #ff3333 ">
                  ไม่พบข้อมูล
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  outlined
                  @click="dialogNotFound = false"
                  class="mt-3"
                  color=""
                  >ตกลง</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="dialogFail" width="30%">
      <template>
        <v-card class="pa-2">
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" icon @click="dialogFail = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <h2 class="black--text">แจ้งระบบขัดข้อง</h2>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  max-width="100"
                  src="../../assets/checkFail.png"
                ></v-img>
              </v-col>

              <v-col cols="12" class="d-flex justify-center my-0 py-0">
                <h3>เนื่องจากขณะนี้ ระบบขัดข้องชั่วคราว</h3>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-2 py-0">
                <h3 >
                  กรุณาลองใหม่อีกครั้ง
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  outlined
                  @click="dialogFail = false"
                  class="mt-3"
                  color=""
                  >ตกลง</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog> -->
  </div>
</template>
<script>
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { EmailSenderDataProvider } from "@/services/EmailSenderDataProvider";
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const emailSenderDataProvider = new EmailSenderDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "BtnVerifyEmployerDBD",
  // props: ["dialogFound", "employerName", "status" , "dialogNotFound", "dialogFail"],
  props: {
    dialogNotFound: Boolean,
    dialogFound: Boolean,
    dialogFail: Boolean,
    showDialog: Boolean,
    status : String,
    employerName : String
  },
  data() {
    return {
      // isProcessing: false,
      // dialogFound: false,
      // dialogNotFound: false,
      // dialogFail: false
      dialog: false,
    };
  },
  computed: {
    chipcard_status() {
      let color = "";
      let textcolor = "white";

      if (this.status === "ร้าง") {
        color = "#000";
        textcolor = "white";
      } else if (this.status === "เสร็จการชำระบัญชี") {
        color = "#ff3333";
        textcolor = "white";
      } else if (this.status === "ยังดำเนินกิจการอยู่") {
        color = "#4caf50";
        textcolor = "white";
      }
      return { color, textcolor };
    },
  },
  watch: {
    showDialog(val) {
      this.dialog = val;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("resetDialog");
    },
  },
};
</script>

<style></style>
