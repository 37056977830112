import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
export class JobPositionDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    loadAllJobPosition(keyword,maxrow) {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPosition/GetJobPosition";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,{
        keyword:keyword,
        maxrow:maxrow
        }, header);
    }
}