<template>
  <div class="d-flex align-center" v-if="employerProfile.dbd_organizationjuristicstatus">
    <v-tooltip top color="info">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" @click="dialog = true" class="clickable-container">
          <v-img
            alt="VEC Logo"
            class="shrink ml-2"
            contain
            src="@/assets/DBDlogo.png"
            transition="scale-transition"
            width="40"
          />
          <v-icon
            size="x-small"
            :color="iconColor"
          >
            mdi-checkbox-blank-circle
          </v-icon>
        </div>
      </template>
      <span>คลิกเพื่อดูข้อมูลเพิ่มเติม</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="primary--text">รายละเอียดข้อมูลจากกรมพัฒนาธุรกิจการค้า</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p><strong>สถานะ:</strong> 
                <span :style="{ color: iconColor }">
                  {{ employerProfile.dbd_organizationjuristicstatus }}
                </span>
              </p>
              <p><strong>ชื่อภาษาไทย:</strong> {{ employerProfile.dbd_organizationjuristicnameth|| 'ไม่มีข้อมูล' }}</p>
              <p><strong>ชื่อภาษาอังกฤษ:</strong> {{ employerProfile.dbd_organizationjuristicnameen || 'ไม่มีข้อมูล' }}</p>
              <p><strong>ประเภทนิติบุคคล:</strong> {{ employerProfile.dbd_organizationjuristictype }}</p>
              <p><strong>วันที่จดทะเบียน:</strong> <FormatDateView :date_value="employerProfile.dbd_organizationjuristicregisterdate" /></p>
              <p><strong>ทุนจดทะเบียน:</strong> {{ formattedRegisterCapital }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">ปิด</v-btn>
          <v-btn color="info" text :href="'https://datawarehouse.dbd.go.th/index'" target="_blank">
            ดูข้อมูลเพิ่มเติม
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormatDateView from "@/components/common/FormatDateView.vue";

export default {
  name: "DBDStatusDisplay",
  components: {
    FormatDateView,
  },
  props: {
    employerProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    iconColor() {
      switch (this.employerProfile.dbd_organizationjuristicstatus) {
        case "ยังดำเนินกิจการอยู่":
          return "green";
        case "ร้าง":
          return "";
        case "เสร็จการชำระบัญชี":
          return "info";
        default:
          return "";
      }
    },
    formattedRegisterCapital() {
      return this.employerProfile.dbd_organizationjuristicregistercapital
        ? new Intl.NumberFormat().format(this.employerProfile.dbd_organizationjuristicregistercapital) + ' บาท'
        : 'ไม่มีข้อมูล';
    },
  },
};
</script>

<style scoped>
.shrink {
  width: 40px;
}

.clickable-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.clickable-container:hover {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>