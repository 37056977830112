<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <div class="d-flex-column justify-center">
            <h1>
              <v-icon color="success">mdi-check-circle</v-icon>
              ลงทะเบียนสถานประกอบการเรียบร้อย!
            </h1>
            <p>เรียน {{ employerProfile.nameth }}</p>
            <p>ระบบได้ทำการบันทึกข้อมูลที่ลงทะเบียนไว้เรียบร้อยแล้ว หากต้องการอัปเดตข้อมูลเพิ่มเติม </p>
            <p>กรุณาเข้าใช้งานระบบด้วย username และ password ที่ได้ลงทะเบียนไว้</p>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center pt-2">
        <div v-if="!redirected">
          <v-col>
            <label>ระบบจะไปยังหน้าเข้าสู่ระบบใน {{ countdown }} วินาที...</label>
          </v-col>
        </div>
        <div v-else>
          <v-col>
            <label>ระบบจะไปยังหน้าเข้าสู่ระบบใน 0 วินาที...</label>
          </v-col>
        </div>
      </v-row>
      <v-row class="justify-center pt-5">
        <div>
          <v-col>
            <v-btn @click="openLogin()" dark color="primary" class="rounded-pill"
              ><v-icon class="mr-2">mdi-home</v-icon>ไปยังหน้าจอเข้าสู่ระบบ
            </v-btn>
          </v-col>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { RegisterEmployerRequest } from "@/models/RegisterEmployer/RegisterEmployerRequest";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "RedirectCountdown",
  data() {
    return {
      vm: new RegisterEmployerRequest(),
      countdown: 15,
      redirected: false,

      employerProfile: [],
      oid: "",
    };
  },
  methods: {
    startCountdown() {
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
          this.redirect();
        }
      }, 1000);
    },
    redirect() {
      this.redirected = true;
      setTimeout(() => {
        this.$router.push("/LoginEmployer");
      }, 1000);
    },
    async openLogin() {
      this.$router.push({ name: "LoginEmployer" }).catch((err) => {});
    },
    async loadEmployerProfile() {
      await employerProfileDataProvider.loadEmployerProfile(this.oid).then((result) => {
        this.employerProfile = result.data;
      });
    },
  },
  async created() {
    this.oid = this.$route.params.oid;
    this.startCountdown();
    await this.loadEmployerProfile();
  },
};
</script>

<style>
#card {
  background-color: #bdd4e7;
  background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
  opacity: 1;
  width: 950px;
}

#icon-account {
  color: #e64a19;
  font-size: 180px;
}

#header {
  color: #fdfefe;
}

#iconheader {
  color: #fdfefe;
  font-size: 35px;
}

#colheader {
  background: #145a32;
}

#wordThank {
  /* font-family: "Comic Neue", cursive; */
  font-size: 45px;
  font-weight: bold;
}

h1 {
  color: #333333;
  margin-top: 0;
}

p {
  color: #666666;
  line-height: 1.5;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}
</style>
