import SearchStudentResumeListView from "@/views/SearchStudentResume/SearchStudentResumeListView";
export default [
  {
    path: "/SearchStudentResume",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/SearchStudentResume/SearchStudentResumeListView",
        name: "SearchStudentResumeListView",
        component: SearchStudentResumeListView,
        meta: {
          requiresAuth: true,
          nameTh: "ค้นประวัติคนหางาน",
        },
      },
    ],
  },
];
