<template>
  <div class="pa-4">
    <v-card rounded elevation="1" class="pa-4">
      <v-row class="d-flex justify-center">
        <v-col lg="11" md="12" sm="12" xs="12">
          <v-row class="mb-4">
            <v-col cols="12" class="d-flex flex-wrap">
              <div class="d-flex flex-wrap">
                <BtnStartImportStudentDetailsFromGraduatedStudentJobTrackingMain
                  class="mt-2 mr-3"
                  v-if="trackingReportMain"
                  :graduatedStudentJobTrackingReportMain="trackingReportMain"
                  @start_importstudentdata_completed="
                    start_importstudentdata_completed
                  "
                >
                </BtnStartImportStudentDetailsFromGraduatedStudentJobTrackingMain>
                <EditTrackingDate
                  v-if="trackingReportMain != undefined"
                  :GraduatedStudentJobTrackingReportMain="trackingReportMain"
                  :editTrackingDate_DialogStatus="this.editTrackingDate_Dialog"
                  @closeEditTrackingDate_Dialog="
                    closeEditTrackingDate_DialogComplete
                  "
                  @close_Dialog="close_DialogComplete"
                >
                </EditTrackingDate>
              </div>
              <div class="d-flex flex-wrap">
                <ExtendTrackingMainEndDate
                  v-if="trackingReportMain != undefined"
                  :GraduatedStudentJobTrackingReportMain="trackingReportMain"
                  :extendTrackingMainEndDate_DialogStatus="
                    this.extendTrackingMainEndDate_Dialog
                  "
                  @closeExtendTrackingMainEndDate_Dialog="
                    closeExtendTrackingMainEndDate_DialogComplete
                  "
                >
                </ExtendTrackingMainEndDate>

                <BtnMark_TrackingProcessIsCompletedVue
                  class="mt-2 mr-3"
                  v-if="trackingReportMain != undefined"
                  :graduatedStudentJobTrackingReportMain="trackingReportMain"
                  @Marked_TrackingProcessIsCompleted="
                    Marked_TrackingProcessIsCompleted
                  "
                >
                </BtnMark_TrackingProcessIsCompletedVue>
              </div>
            </v-col>
          </v-row>

          <GraduatedStudentJobTrackingReportMainInfo
            v-if="trackingReportMain != undefined"
            :graduatedstudentjobtrackingreportmain="trackingReportMain"
            @onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute="
              onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute
            "
            @onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute="
              onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute
            "
          >
          </GraduatedStudentJobTrackingReportMainInfo>

          <v-card v-if="tableOptions" rounded elevation="1" class="pa-4 mt-5">
            <div class="mx-5">
              <v-row>
                <v-col
                  lg="4"
                  md="12"
                  sm="12"
                  xs="12"
                  class="custom-col mb-0 pb-0"
                >
                  <h4 class="primary--text mb-2">สังกัด</h4>
                  <v-row no-gutters align-content="end">
                    <v-col
                      cols="12"
                      lg="4"
                      md="4"
                      class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                    >
                      <v-btn
                        :color="
                          !tableOptions.schooltype === true ||
                          tableOptions.schooltype === ''
                            ? 'primary'
                            : 'input'
                        "
                        @click="
                          tableOptions.schooltype = 0;
                          OnSchoolTypeChange();
                        "
                        width="100%"
                        height="52px"
                      >
                        ทั้งหมด
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="4"
                      md="4"
                      class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                    >
                      <v-btn
                        :color="
                          tableOptions.schooltype === 1 ? 'primary' : 'input'
                        "
                        @click="
                          tableOptions.schooltype = 1;
                          OnSchoolTypeChange();
                        "
                        width="100%"
                        height="52px"
                      >
                        ภาครัฐ
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="4"
                      md="4"
                      class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                    >
                      <v-btn
                        :color="
                          tableOptions.schooltype === 2 ? 'primary' : 'input'
                        "
                        @click="
                          tableOptions.schooltype = 2;
                          OnSchoolTypeChange();
                        "
                        height="52px"
                        width="100%"
                      >
                        ภาคเอกชน
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  lg="4"
                  md="12"
                  sm="12"
                  xs="12"
                  class="custom-col mb-0 pb-0"
                >
                  <h4 class="primary--text mb-2">ภูมิภาค</h4>
                  <v-select
                    :label="tableOptions.regionasvec ? '' : 'ภูมิภาค'"
                    :items="regionsNames"
                    clearable
                    v-model="tableOptions.regionasvec"
                    @change="loadGraduatedStudentJobTrackingReports()"
                    background-color="input"
                    filled
                    append-icon="mdi-chevron-down"
                  ></v-select>
                </v-col>
                <v-col
                  lg="4"
                  md="12"
                  sm="12"
                  xs="12"
                  class="custom-col mb-0 pb-0"
                >
                  <h4 class="primary--text mb-2">จังหวัด</h4>
                  <v-autocomplete
                    :label="tableOptions.provinceid ? '' : 'จังหวัด'"
                    :items="provincelist"
                    item-text="provincethai"
                    item-value="provinceid"
                    clearable
                    background-color="input"
                    filled
                    append-icon="mdi-chevron-down"
                    v-model="tableOptions.provinceid"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <SearchableDataTablePaging
              table_caption="สถานศึกษาที่ต้องติดตาม"
              :data_items="job_tracking_reports"
              :itemlength="itemlength"
              :datatable_headers="datatable_headers"
              :search="keyword"
              @pageChanged="pageChanged"
              @onkeywordchanged="onKeywordChanged"
              @tableOptionsChanged="tableOptionsChanged"
              :header_disable_sort="false"
            >
              <v-layout justify-center> </v-layout>
              <template
                v-slot:item.quantityofgraduatedstudentjobtrackingreport_studentdetails="{
                  item,
                }"
              >
                <span
                  :class="[
                    item.countstudentjobtracked ==
                    item.quantityofgraduatedstudentjobtrackingreport_studentdetails
                      ? 'success--text'
                      : 'red--text',
                  ]"
                  >{{ item.countstudentjobtracked }}</span
                >/{{
                  item.quantityofgraduatedstudentjobtrackingreport_studentdetails
                }}
              </template>
              <template
                v-slot:item.importstudentdata_status_statusname="{ item }"
              >
                <GraduatedStudentJobTrackingReport_ImportStudent_Status_Label
                  :importstudentdata_status="
                    item.importstudentdata_status_statuscode
                  "
                  :importstudentdata_status_statusname="
                    item.importstudentdata_status_statusname
                  "
                >
                </GraduatedStudentJobTrackingReport_ImportStudent_Status_Label>
              </template>
              <template v-slot:item.jobtracking_status_statusname="{ item }">
                <GraduatedStudentJobTrackingReport_JobTracking_Status_Label
                  v-if="item"
                  :jobtrackingreport="item"
                >
                </GraduatedStudentJobTrackingReport_JobTracking_Status_Label>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip top color="info">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="openJobTrackingReportDetailView(item)"
                      dark
                      color="info"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="my-2"
                    >
                      <v-icon class="mr-1">mdi-folder-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>แสดงรายละเอียด</span>
                </v-tooltip>
              </template>
            </SearchableDataTablePaging>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { GetGraduatedStudentJobTrackingReportsAndFilters_Request } from "@/models/GraduatedStudentJobTrackingReport/GetGraduatedStudentJobTrackingReportsAndFilter_Request";
import { GraduatedStudentJobTrackingReportMain } from "@/models/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain";
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import JobTrackingReportStatus from "@/components/GraduatedStudentJobTrackingReport/JobTrackingReportStatus.vue";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import PanelPercentJobTrackingByRound from "@/components/Chart/PanelPercentJobTrackingByRound.vue";
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { formatDateThai } from "@/DateTimeHelper";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import EditTrackingDate from "@/components/GraduatedStudentJobTrackingReport/EditTrackingDate.vue";
import ExtendTrackingMainEndDate from "@/components/GraduatedStudentJobTrackingReport/ExtendTrackingMainEndDate.vue";
import GraduatedStudentJobTrackingReportMainInfo from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainInfo.vue";
import GraduatedStudentJobTrackingReport_ImportStudent_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_ImportStudent_Status_Label.vue";
import GraduatedStudentJobTrackingReport_JobTracking_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_JobTracking_Status_Label.vue";
import BtnStartImportStudentDetailsFromGraduatedStudentJobTrackingMain from "@/components/GraduatedStudentJobTrackingReport/BtnStartImportStudentDetailsFromGraduatedStudentJobTrackingMain.vue";
import BtnMark_TrackingProcessIsCompletedVue from "@/components/GraduatedStudentJobTrackingReport/BtnMark_TrackingProcessIsCompleted.vue";

var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

var dataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: {
    TextFieldDatePicker,
    JobTrackingReportStatus,
    SearchableDataTable,
    PanelPercentJobTrackingByRound,
    EditTrackingDate,
    ExtendTrackingMainEndDate,
    GraduatedStudentJobTrackingReportMainInfo,
    GraduatedStudentJobTrackingReport_ImportStudent_Status_Label,
    GraduatedStudentJobTrackingReport_JobTracking_Status_Label,
    BtnStartImportStudentDetailsFromGraduatedStudentJobTrackingMain,
    BtnMark_TrackingProcessIsCompletedVue,
    SearchableDataTablePaging,
  },
  name: "GraduatedStudentJobTrackingReportMainDetailView",
  data() {
    return {
      provincelist: [],
      regionsNames: [],
      graduatedStudentJobTrackingReportMain: undefined,
      trackingReportMain: undefined,
      schooltypelist: [
        { text: "ภาครัฐ/ภาคเอกชน", value: 0 },
        { text: "ภาครัฐ", value: 1 },
        { text: "ภาคเอกชน", value: 2 },
      ],
      request: new GetGraduatedStudentJobTrackingReportsAndFilters_Request(),
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      datatable_headers: [
        { value: "schoolid", text: "รหัสสถานศึกษา",  },
        { value: "schoolname", text: "ชื่อสถานศึกษา",  },
        {
          value: "regionascommon",
          text: "ภาค",
          sortable: false,
        },
        {
          value: "provincethai",
          text: "จังหวัด",
          sortable: false,
        },
        // { value: "targetgraduatedyearth", text: "ปีการศึกษา" },
        // { value: "roundnumber", text: "รอบ" },
        {
          value: "quantityofgraduatedstudentjobtrackingreport_studentdetails",
          text: "จำนวนนักศึกษาที่ติดตามแล้ว",
          sortable: false,
        },
        {
          value: "importstudentdata_status_statusname",
          text: "สถานะการนำเข้าข้อมูล",
          sortable: false,
        },
        {
          value: "jobtracking_status_statusname",
          text: "สถานะการติดตาม",
          sortable: false,
        },
        { value: "action", text: "ตัวเลือก", sortable: false, align: "center", sortable: false },
      ],
      keyword: "",
      pageNumber: 1,
      job_tracking_reports: undefined,
      itemlength: undefined,
      filtering_importstudentdata_statuscode: "",
      filtering_jobtracking_statuscode: "",
      editTrackingDate_Dialog: false,
      extendTrackingMainEndDate_Dialog: false,
      oid: "",
    };
  },
  computed: {
    filteredItems() {
      if (this.filtering_importstudentdata_statuscode != "") {
        return this.job_tracking_reports.filter(
          (item) =>
            item.importstudentdata_status_statuscode ==
            this.filtering_importstudentdata_statuscode
        );
      }
      if (this.filtering_jobtracking_statuscode != "") {
        if (
          this.filtering_jobtracking_statuscode == "NotStartedTrackingProcess"
        ) {
          return this.job_tracking_reports.filter(
            (item) =>
              item.jobtracking_status_statuscode ==
                this.filtering_jobtracking_statuscode &&
              item.importstudentdata_status_statuscode != "NoGraduateStudent" &&
              item.importstudentdata_status_statuscode == "Imported"
          );
        }
        return this.job_tracking_reports.filter(
          (item) =>
            item.jobtracking_status_statuscode ==
            this.filtering_jobtracking_statuscode
        );
      }
      return this.job_tracking_reports;
    },
  },
  watch: {
    tableOptions: {
      handler(newValue, oldValue) {
        this.loadGraduatedStudentJobTrackingReports();
      },
      deep: true,
    },
    loadProvinces: {
      handler(newValue, oldValue) {
        this.loadGraduatedStudentJobTrackingReports();
      },
      deep: true,
    },
  },
  methods: {
    async loadGraduatedStudentJobTrackingReportMain() {
      dataProvider
        .LoadGraduatedStudentJobTrackingReportMain(this.oid)
        .then((result) => {
          this.trackingReportMain = result.data;
          this.request.graduatedyearth =
            this.trackingReportMain.targetgraduatedyearth;
          this.request.roundnumber = this.trackingReportMain.roundnumber;
          this.graduatedStudentJobTrackingReportMain =
            GraduatedStudentJobTrackingReportMain.fromJson(result.data);
          //Load reports
          this.loadGraduatedStudentJobTrackingReports();
        })
        .catch((err) => {
          alert(err);
        });
    },
    async start_importstudentdata_completed() {
      await this.loadGraduatedStudentJobTrackingReportMain();
    },
    async Marked_TrackingProcessIsCompleted() {
      await this.loadGraduatedStudentJobTrackingReportMain();
      await this.loadGraduatedStudentJobTrackingReports();
      this.$router.push(
        "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainListView"
      );
    },
    OnSchoolTypeChange(value) {
      this.request.schooltype = value;
      this.loadGraduatedStudentJobTrackingReports();
    },
    pageChanged(pageChangeValue) {
      this.pageNumber = pageChangeValue;
      this.loadGraduatedStudentJobTrackingReports();
    },
    async onKeywordChanged(newKeyword) {
      this.tableOptions.search = newKeyword;
      await this.loadGraduatedStudentJobTrackingReports();
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions.page = newtableOptions.page;
      this.tableOptions.itemsPerPage = newtableOptions.itemsPerPage;
      // page: 1,
      // itemsPerPage: 10,
      // sortBy: [],
      // sortDesc: [],
      // groupBy: [],
      // groupDesc: [],
      // multiSort: false,
      // mustSort: false,
    },

    async loadGraduatedStudentJobTrackingReports() {
      this.$store.dispatch("showLoading");

      if (this.graduatedStudentJobTrackingReportMain) {
        this.tableOptions.graduatedStudenetJobTrackingReportMain_Oid =
          this.graduatedStudentJobTrackingReportMain.oid;
        this.tableOptions.roundnumber =
          this.graduatedStudentJobTrackingReportMain.roundnumber;
        this.tableOptions.graduatedyearth =
          this.graduatedStudentJobTrackingReportMain.targetgraduatedyearth;
        await dataProvider
          .LoadReportsAndFilter(this.tableOptions)
          .then((result) => {
            this.job_tracking_reports = result.data.items;
            console.log(
              this.job_tracking_reports,
              "this.job_tracking_reports "
            );
            this.itemlength = result.data.quantity;
            this.loadProvinces();
          })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            this.$store.dispatch("hideLoading");
          });
      }
    },

    openJobTrackingReportDetailView(item) {
      this.$router.push({
        name: "GraduatedStudentJobTrackingReportDetailView",
        params: { trackingReportOid: item.oid },
      });
    },
    openReportView() {
      this.$router.push({
        name: "GraduatedStudentJobTrackingReportMainDetailView_Report",
        params: { Oid: this.trackingReportMain.oid },
      });
    },
    async loadProvinces() {
      //
      if (this.tableOptions.regionasvec) {
        await masterDataProvider
          .loadGetProvincesByRegionAsVECName(this.tableOptions.regionasvec)
          .then((result) => {
            this.provincelist = result.data;
          });
      } else if (!this.tableOptions.regionasvec) {
        await masterDataProvider.loadGetProvinces().then((result) => {
          this.provincelist = result.data;
        });
      }
    },
    async loadRegions() {
      await commonDataProvider.getRegionAsVECNames().then((result) => {
        this.regionsNames = result.data;
      });
    },
    async closeEditTrackingDate_DialogComplete(
      selected_trackingstartdate,
      selected_trackingenddate,
      save
    ) {
      if (save) {
        this.trackingReportMain.trackingstartdate = selected_trackingstartdate;
        this.trackingReportMain.trackingenddate = selected_trackingenddate;
      }
      this.editTrackingDate_Dialog = false;
    },

    async close_DialogComplete(close) {
      if (close) {
      }
      this.editTrackingDate_Dialog = false;
    },

    closeExtendTrackingMainEndDate_DialogComplete(
      selected_new_trackingEndDate,
      save
    ) {
      this.extendTrackingMainEndDate_Dialog = false;
      if (selected_new_trackingEndDate && save) {
        this.trackingReportMain.trackingenddate = selected_new_trackingEndDate;
      }
    },

    async onFilteringGraduatedStudentJobTrackingReports_ByImportStudentData_StatusCode_Execute(
      statusCode = ""
    ) {
      this.tableOptions.ImportStudentData_StatusCode = statusCode;
      this.tableOptions.JobTracking_StatusCode = "";

      if (this.tableOptions.ImportStudentData_StatusCode == "") {
        this.tableOptions.JobTracking_StatusCode = "";
      }

      await this.loadGraduatedStudentJobTrackingReports();
    },
    async onFilteringGraduatedStudentJobTrackingReports_ByJobTracking_StatusCode_Execute(
      statusCode = ""
    ) {
      this.filtering_jobtracking_statuscode = statusCode;
      this.tableOptions.JobTracking_StatusCode = statusCode;
      this.tableOptions.ImportStudentData_StatusCode = "";
      this.filtering_importstudentdata_statuscode = "";
      await this.loadGraduatedStudentJobTrackingReports();
    },
  },
  async mounted() {
    this.oid = this.$route.params.oid;
    await this.loadProvinces();
    await this.loadRegions();
    await this.loadGraduatedStudentJobTrackingReportMain();
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
