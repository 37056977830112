import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
import { GetSchools_Reqeust } from '@/models/School/GetSchools_Request';

export class SchoolEmployeeDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    loadSchoolEmployees(schoolID) {
        const header = create_default_request_header();
        var endpointPath = "/api/SchoolEmployee/GetSchoolEmployees/" + schoolID;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, header);
    }

    importSchoolEmployees(schoolID) {
        const header = create_default_request_header();
        var endpointPath = "/api/SchoolEmployee/ImportSchoolEmployees/" + schoolID;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, header);
    }
}