import axios from "axios";
import { create_default_request_header } from "@/services/constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { CreateUpdateSchoolEmployeeProfile_Request } from "@/models/Profile/SchoolEmployeeProfile/CreateUpdateSchoolEmployeeProfile_Request";
import { SearchAllSchoolEmployeeProfiles_Request } from "@/models/Profile/SearchAllSchoolEmployeeProfiles_Request";

export class SchoolEmployeeProfileDataProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }

  getCurrentSchoolEmployeeProfile() {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolEmployeeProfile/GetCurrentSchoolEmployeeProfile";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getCurrentPermissionSchoolIDs() {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolEmployeeProfile/getCurrentPermissionSchoolIDs";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getCurrentPermissionInstituteIDs() {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolEmployeeProfile/GetCurrentPermissionInstituteIDs";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  createSchoolEmployeeProfile(
    vm = new CreateUpdateSchoolEmployeeProfile_Request()
  ) {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolEmployeeProfile/CreateSchoolEmployeeProfile";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  updateSchoolEmployeeProfile(
    vm = new CreateUpdateSchoolEmployeeProfile_Request()
  ) {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolEmployeeProfile/UpdateSchoolEmployeeProfile";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  getSchoolEmployeeProfiles() {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolEmployeeProfile/GetSchoolEmployeeProfiles";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }

  getAllSchoolEmployeeProfiles(
    vm = new SearchAllSchoolEmployeeProfiles_Request()
  ) {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolEmployeeProfile/GetAllSchoolEmployeeProfiles";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  getSchoolEmployeeProfile_By_ApplicationRoleCode(applicationRoleCode = "") {
    const header = create_default_request_header();
    const endpointUrl =
      "/api/SchoolEmployeeProfile/GetSchoolEmployeeProfile_By_ApplicationRoleCode/" +
      applicationRoleCode;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }

  getSchoolEmployeeProfile_By_ApplicationRoleCodes(applicationRoleCodes = []) {
    const data = JSON.stringify(applicationRoleCodes);
    const header = create_default_request_header();
    const endpointUrl =
      "/api/SchoolEmployeeProfile/GetSchoolEmployeeProfile_By_ApplicationRoleCodes";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, data, header);
  }

  getSchoolEmployeeProfiles_BySchoolEmployeeTypeCodes(
    schoolEmployeeTypeCode = ""
  ) {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolEmployeeProfile/GetSchoolEmployeeProfiles_BySchoolEmployeeTypeCodes/" +
      schoolEmployeeTypeCode;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }

  getSchoolEmployeeProfile(oid = "") {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolEmployeeProfile/GetSchoolEmployeeProfile/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  deleteSchoolEmployeeProfile(oid = "") {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolEmployeeProfile/deleteSchoolEmployeeProfile/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }

  uploadSchoolEmployeeProfile(formData = new FormData()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolEmployeeProfile/UploadSchoolEmployeeProfile";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, formData, header);
  }

  getSchoolEmployeeProfile_RoleAndPermission_Management(
    schoolEmployeeProfile_Oid = ""
  ) {
    const header = create_default_request_header();
    const endpointUrl =
      "/api/SchoolEmployeeProfile/GetSchoolEmployeeProfile_RoleAndPermission_Management_Response/" +
      schoolEmployeeProfile_Oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  SaveSchoolEmployeeProfile_RoleAndPermission_Management_Response(
    request = []
  ) {
    const header = create_default_request_header();
    const data = JSON.stringify(request);
    const endpointUrl =
      "/api/SchoolEmployeeProfile/SaveSchoolEmployeeProfile_RoleAndPermission_Management_Response";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, data, header);
  }

  loadChangePasswordWhenLogon(oid = "") {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolEmployeeProfile/Set_ChangePasswordWhenLogon/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }

  // get_School_Guidance_Teacher_SchoolEmployeeProfiles(applicationRoleCode = ''){
  //   const header = create_default_request_header();
  //   const endpointUrl = "/api/SchoolAdmin_SchoolEmployeeProfile/Get_School_Guidance_Teacher_SchoolEmployeeProfiles"+ applicationRoleCode;
  //   const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
  //   return axios.post(combinedURL, "", header);
  // }

  get_School_Guidance_Teacher_SchoolEmployeeProfiles() {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolAdmin_SchoolEmployeeProfile/Get_School_Guidance_Teacher_SchoolEmployeeProfiles";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }

  get_SchoolManager_SchoolEmployeeProfiles() {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolAdmin_SchoolEmployeeProfile/Get_SchoolManager_SchoolEmployeeProfiles";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }

  get_SchoolAdmin_SchoolEmployeeProfiles() {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolAdmin_SchoolEmployeeProfile/Get_SchoolAdmin_SchoolEmployeeProfiles";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }

  get_School_Partner_Officer_SchoolEmployeeProfiles() {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/SchoolAdmin_SchoolEmployeeProfile/Get_School_Partner_Officer_SchoolEmployeeProfiles";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, "", header);
  }
}
