import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { CreateJobPosting_Request } from "@/models/EmployerJobPosting/CreateJobPosting_Request";
import { UpdateJobPosting_Request } from "@/models/EmployerJobPosting/UpdateJobPosting_Request";
import { SetJobApplicationStatusRequest } from "@/models/JobApplications/SetJobApplicationStatusRequest";
export class EmployerJobPostingDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    loadEmployerJobPostings(){
        const header = create_default_request_header()
        const endpointUrl = "/api/EmployerJobPosting/GetJobPostings";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header)                                                                                                                                                                                                                                                                                                                                                                          
    }

    getInDraftJobPostings(){
        const header = create_default_request_header()
        const endpointUrl = "/api/EmployerJobPosting/getInDraftJobPostings";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header)                                                                                                                                                                                                                                                                                                                                                                          
    }

    getInProcessJobPostings(){
        const header = create_default_request_header()
        const endpointUrl = "/api/EmployerJobPosting/getInProcessJobPostings";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header)                                                                                                                                                                                                                                                                                                                                                                          
    }

    getExpiredJobPostings(){
        const header = create_default_request_header()
        const endpointUrl = "/api/EmployerJobPosting/getExpiredJobPostings";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header)                                                                                                                                                                                                                                                                                                                                                                          
    }

    getHistoryJobPostings(){
        const header = create_default_request_header()
        const endpointUrl = "/api/EmployerJobPosting/getHistoryJobPostings";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header)                                                                                                                                                                                                                                                                                                                                                                          
    }

    getJobPosting(oid = ''){
        const header = create_default_request_header()
        const endpointUrl = "/api/EmployerJobPosting/getJobPosting/" + oid;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header)      
    }

    getJobPostingByJobPostingID(jobpostingid){
        const header = create_default_request_header()
        const endpointUrl = "/api/EmployerJobPosting/GetJobPostingByJobPostingID/" + jobpostingid;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header)
    }

    getJobApplicationsByJobPostingOid(jobposting_oid = ''){
        const header = create_default_request_header()
        const endpointUrl = "/api/EmployerJobPosting/GetJobApplicationsByJobPostingOid/" + jobposting_oid;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header)
    }

    createEmployerJobPosting(vm = new CreateJobPosting_Request()) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerJobPosting/CreateJobPosting";
        var combinedURL = createEndpointUrl(this.apiBaseURL,endpointUrl);
        return axios.post(combinedURL, vm, header);
    }

    updateEmployerJobPosting(vm = new UpdateJobPosting_Request()){
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerJobPosting/UpdateJobPosting";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, vm,  header );
    }

    deleteEmployerJobPosting(oid) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerJobPosting/DeleteJobPosting/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null, header );
    }

    setJobPosting_ActiveStatus(oid) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerJobPosting/SetJobPosting_ActiveStatus/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null, header );
    }

    setJobPosting_OnHoldStatus(oid){
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerJobPosting/SetJobPosting_OnHoldStatus/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null, header );
    }

    setJobPosting_CancelStatus(oid){
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerJobPosting/SetJobPosting_CancelStatus/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null, header );
    }

    setJobPosting_ClosedStatus(oid){
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerJobPosting/SetJobPosting_ClosedStatus/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null, header );
    }

    setJobApplicationStatus(request = new SetJobApplicationStatusRequest())
    {
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerJobPosting/SetJobApplicationStatus";
        const data = JSON.stringify(request);
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, data, header);
    }
}