<template>
  <div>
    <v-row outlined class="pa-2 mb-3 mt-2 d-flex align-end">
      <h4 class="primary--text ml-2">ประวัติการศึกษา</h4>
      <v-spacer></v-spacer>
      <v-btn
        @click="StudyStudentDetailView_Dialog = true"
        class="mr-2"
        :disabled="isInputDisabled"
        outlined
      >
        เพิ่มข้อมูล<v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>

    <v-data-table
      :headers="education_headers"
      :items="study"
      hide-default-footer
      class="elevation-1 custom-header-gradient mx-1"
    >
   
      <template v-slot:item.majornameth="{ item }">
        <span>
         {{ item.majornameth || "-" }} 
        </span>
        
      </template>
      <template v-slot:item.minornameth="{ item }">
        <span>
         {{ item.minornameth || "-" }} 
        </span>
        
      </template>
      <template v-slot:item.gpa="{ item }">
        <span>
         {{ item.gpa || "-" }} 
        </span>
        
      </template>

      <template v-slot:item.action="{ item }">
        <v-row class="custom-width">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="onclickEducationStudent(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขประวัติการศึกษา</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="deleteStudentStudyRecord(item)"
                color="error"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ลบประวัติการศึกษา</span>
          </v-tooltip></v-row
        >
      </template>
    </v-data-table>

    <EducationStudentDialog
      :StudyStudentDetailView_Dialog="StudyStudentDetailView_Dialog"
      :oid="Studyoid"
      @closeDialog="onStudyProcessIsCompleted"
    ></EducationStudentDialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import EducationStudentDialog from "@/components/Student/Education/EducationStudentDialog.vue";
import { StudyRecord } from "@/models/Student/StudyRecord";

var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  props: ["studentoid", "isInputDisabled"],
  components: {
    EducationStudentDialog,
  },
  data() {
    return {
      StudyStudentDetailView_Dialog: false,
      education_headers: [
        { text: "ปีที่สำเร็จการศึกษา", value: "graduatedyear" },
        {
          text: "วุฒิการศึกษา",
          value: "educationalqualification.nameth",
        },
        { text: "สถานศึกษา", value: "schoolname" },
        { text: "คณะ", value: "majornameth" },
        { text: "สาขาวิชา", value: "minornameth" },
        { text: "เกรดเฉลี่ย", value: "gpa" },
        { text: "", value: "action" , sortable: false},
      ],
      study: [],
      vm: new StudyRecord(),
      Studyoid: "-1",
    };
  },
  methods: {
    async LoadStudentStudy(studentoid) {
      studentDataProvider
        .getStudentStudyRecords(studentoid)
        .then((res) => {
          this.study = res.data;
        })
        .catch((res) => {});
    },
    async saveStudentStudy(value) {
      value.student_oid = this.studentoid;
      studentDataProvider
        .saveStudentStudyRecord(value)
        .then((res) => {
          this.$toast.success("สร้าง ประวัติการศึกษา เรียบร้อย!");
          const studentoid = this.studentoid;
          this.LoadStudentStudy(studentoid);
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    async UpdateStudentStudy(value) {
      studentDataProvider
        .editStudentStudyRecord(value)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("บันทึกข้อมูลสำเร็จ");
            const studentoid = this.studentoid;
            this.LoadStudentStudy(studentoid);
          }
        })
        .catch((res) => {
          this.$toast.error("แก้ไขข้อมูลล้มเหลว !!!" + res);
        });
    },

    onclickEducationStudent(item) {
      this.Studyoid = String(item.oid);
      this.StudyStudentDetailView_Dialog = true;
    },

    async deleteStudentStudyRecord(item) {
      if (confirm("ต้องการประวัติการศึกษาหรือไม่ ?")) {
        await studentDataProvider
          .deleteStudentStudyRecord(item.oid)
          .then((res) => {
            this.experience = [];
            this.$toast.success("ลบประวัติการศึกษา เรียบร้อย!");
            const studentoid = this.studentoid;
            this.LoadStudentStudy(studentoid);
          })
          .catch((res) => {
            this.$toast.error("ลบประวัติการศึกษาล้มเหลว!" + res);
          });
      }
    },

    async onStudyProcessIsCompleted(value, save) {
      if (save) {
        this.StudyStudentDetailView_Dialog = false;
        if (value.oid != "-1") {
          await this.UpdateStudentStudy(value);
        } else {
          await this.saveStudentStudy(value);
        }
      } else {
        this.StudyStudentDetailView_Dialog = false;
      }
      this.Studyoid = "-1";
    },
  },

  async mounted() {
    const studentoid = this.studentoid;
    await this.LoadStudentStudy(studentoid);
  },
};
</script>

<style scoped>
.custom-width {
  width: 100px;
  gap: 10px;
}
.custom-header-gradient .v-data-table-header {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.custom-header-gradient th {
  color: white !important;
}
</style>
