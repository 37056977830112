import axios from "axios";
import {
  create_default_request_header,
  create_default_request_header_no_token,
  backendapiUrl
} from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";

export class AuthenticationProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }

  IsThisUserMustChangeFirstLogon(account) {
    const headers = create_default_request_header_no_token();
    var data = JSON.stringify(account);
    var endpointPath = "/api/authentication/IsThisUserMustChangeFirstLogon";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, data,  headers );
  }

  SetPassword(username, password) {
    const headers = create_default_request_header_no_token();
    var data = JSON.stringify({ Username: username, Password: password });
    var endpointPath = "/api/authentication/SetPassword";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, data, headers);
  }
}
