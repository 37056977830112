import ProvinceListView from "@/views/Province/ProvinceListView";
export default [
  {
    path: "/Province",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Province/ProvinceListView",
        name: "ProvinceListView",
        component: ProvinceListView,
        meta: {
          requiresAuth: true,
          nameTh: "จังหวัด",
        },
      },
    ],
  },
];
