import { createEndpointUrl } from '@/common/api_connectivity_helper';
import axios from 'axios';
import { create_default_request_header } from '../constants';

export class GuidanceTeacher_GraduatedStudentJobTrackingDataProvider
{
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    get_InProcess_GraduatedStudentJobTrackingReports(){
        const headers = create_default_request_header();
        const endpointPath = "/api/GraduatedStudentJobTrackingReportGuidanceTeacher/GetGraduatedStudentJobTrackingReports";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, null, headers);
    }

    get_Verified_GraduatedStudentJobTrackingReports(){
        const headers = create_default_request_header();
        const endpointPath = "/api/GraduatedStudentJobTrackingReportGuidanceTeacher/GetVerifiedGraduatedStudentJobTrackingReports";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, null, headers);
    }
}