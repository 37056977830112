<template>
    <v-row>
        <v-col cols="10">
            <v-text-field v-model="email" @change="onChanged()"></v-text-field>
        </v-col>
        <v-col cols="2">
            <label>{{ checkedMessage }}</label>
        </v-col>
    </v-row>
</template>


<script>
import { ApplicationUserDataProvider } from '@/services/ApplicationUserDataProvider'
const applicationUserDataProvider = new ApplicationUserDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
    name: 'EmailTextField',
    props: ['Email'],
    data() {
        return {
            isUsed: undefined,
            email: '',
        }
    },
    computed: {
        checkedColor() {
            if (this.isUsed == true) {
                return 'error';
            } else if (this.isUsed == false) {
                return 'success';
            }
        },
        checkedMessage() {
            if (this.isUsed == true) {
                return 'ไม่สามารถใช้ username นี้ได้';
            } else if (this.isUsed == false) {
                return 'สามาถใช้ username นี้ได้';
            }
        }
    },
    methods: {
        async onChanged() {
            await applicationUserDataProvider.isEmailUsed(this.email).then(result => {
                this.isUsed = result.data;
            })
        }
    },
    async created() {
        this.email = this.Email;
    }

}
</script>

<style></style>