<template>
  <div>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-form>
      <v-row class="d-flex justify-center pt-5">
        <v-col cols="10">
          <div id="element-to-convert">
            <v-card style="border-radius: 15px" class="mt-5 d-flex justify-center">
              <v-col cols="10" class="">
                <v-card-text>
                  <v-row class="d-flex justify-center pt-5 pb-5 pl-12 pr-12">
                    <v-col style="display: flex; justify-content: center">
                      <v-row
                        v-if="logo_photobase64"
                        class="d-flex align-center justify-center"
                      >
                        <v-img
                          alt="test"
                          class="shrink fill-height mr-2"
                          contain
                          transition="scale-transition"
                          aspect-ratio="1/1"
                          cover
                          v-bind:lazy-src="'data:image/jpg;base64,' + logo_photobase64"
                          v-bind:src="'data:image/jpg;base64,' + logo_photobase64"
                        />
                      </v-row>
                      <v-row v-else class="d-flex align-center justify-center">
                        <v-img
                          alt="test"
                          class="shrink fill-height mr-2"
                          contain
                          transition="scale-transition"
                          aspect-ratio="1/1"
                          cover
                          :src="require(`@/assets/user-img.jpg`)"
                        />
                      </v-row>
                    </v-col>
                    <v-col cols="8">
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                        "
                      >
                        <v-row class="pb-2" align="center">
                          <v-col class="pa-0" cols="auto">
                            <v-list-item-title class="text-h6 font-weight-bold">
                              {{ jobapplication.student.firstname }}
                              {{ jobapplication.student.surname }}
                            </v-list-item-title>
                          </v-col>
                        </v-row>

                        <v-row v-if="jobapplication.student.birthday">
                          <v-col class="pa-0 ma-0 display-center">
                            <label class="pr-2"
                              >{{
                                moment(jobapplication.student.birthday)
                                  .add(543, "year")
                                  .locale("th")
                                  .format("DD MMMM YYYY")
                              }}
                            </label>
                          </v-col>
                          <label class="pa-0 ma-0 display-center"
                            >(
                            {{ calculateAge(jobapplication.student.birthday) }}
                            ปี )</label
                          >
                        </v-row>
                        <v-row v-if="jobapplication.student.tel">
                          <v-col class="pa-0 ma-0 display-center">
                            <v-icon>mdi-phone</v-icon>
                            <label>{{ jobapplication.student.tel }}</label>
                          </v-col>
                        </v-row>
                        <v-row v-if="jobapplication.student.email">
                          <v-col class="pa-0 ma-0 display-center">
                            <v-icon>mdi-email</v-icon>
                            <label>{{ jobapplication.student.email }}</label>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pt-2 px-6">
                    <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                    <h3 class="primary--text">ข้อมูลส่วนตัว</h3>
                  </v-row>
                  <v-row class="pl-6 pr-6" style="margin-top: 2%; margin-bottom: 2%">
                    <v-divider></v-divider>
                  </v-row>

                  <v-row class="pl-6 pr-6">
                    <v-col>
                      <v-row class="pb-5">
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">รหัสนักศึกษา</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.student.studentcode }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">วุฒิการศึกษา</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.student.gradenameth }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">เกรดเฉลี่ย</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.student.gpax }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">เพศ</h4>
                          </v-row>
                          <v-row>
                            <label v-if="this.jobapplication.student.gender == 'F'"
                              >หญิง</label
                            >
                            <label v-if="this.jobapplication.student.gender == 'M'"
                              >ชาย</label
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="pb-5">
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">สัญชาติ</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.selectedNationalitylist }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">ศาสนา</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.selectedReligionlist }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">ส่วนสูง</h4>
                          </v-row>
                          <v-row>
                            <span
                              >{{
                                jobapplication.student.hight
                                  ? jobapplication.student.hight
                                  : "-"
                              }}
                              ซม.</span
                            >
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">น้ำหนัก</h4>
                          </v-row>
                          <v-row>
                            <span
                              >{{
                                jobapplication.student.weight
                                  ? jobapplication.student.weight
                                  : "-"
                              }}
                              กก.</span
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="pb-5">
                        <v-col cols="3">
                          <v-row>
                            <h4 class="primary--text">สถานภาพทางทหาร</h4>
                          </v-row>
                          <v-row>
                            <label v-if="this.jobapplication.student.military == '0'"
                              >ยังไม่ผ่านการเกณฑ์ทหาร</label
                            >
                          </v-row>
                        </v-col>
                        <v-col cols="3">
                          <v-row>
                            <h4 class="primary--text">สถานะความพิการ</h4>
                          </v-row>
                          <v-row>
                            <label class=""
                              >{{ jobapplication.student.disability }}
                            </label>
                          </v-row>
                        </v-col>
                        <v-col cols="3">
                          <v-row>
                            <h4 class="primary--text">สถานภาพ</h4>
                          </v-row>
                          <v-row>
                            <label class="">{{ jobapplication.student.status }} </label>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row class="pt-5 pl-6 pr-6">
                    <v-icon class="pr-2" color="primary">mdi-map-marker</v-icon>
                    <h3 class="primary--text">ที่อยู่</h3>
                  </v-row>
                  <v-row class="pl-6 pr-6" style="margin-top: 2%; margin-bottom: 2%">
                    <v-divider></v-divider>
                  </v-row>

                  <v-row class="pl-6 pr-6">
                    <v-col>
                      <v-row class="pb-5">
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">เลขที่อยู่</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.housenumber
                                ? jobapplication.student.housenumber
                                : "-"
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">หมู่ที่</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.moo
                                ? jobapplication.student.moo
                                : "-"
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">ซอย</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.soi
                                ? jobapplication.student.soi
                                : "-"
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">ถนน</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.street
                                ? jobapplication.student.street
                                : "-"
                            }}</span>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="pb-5">
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">แขวง / ตำบล</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.selectedTambons }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">อำเภอ / เขต</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.selectedDistricts }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">จังหวัด</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.selectedProvinces }}</span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <h4 class="primary--text">รหัสไปรษณีย์</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.selectedpostcode }} </span>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row justify="center" class="my-10">
                    <v-btn
                      outlined
                      rounded
                      width="100%"
                      @click="moreInformation()"
                      style="font-weight: bold"
                      ><v-icon class="mr-1">mdi-account-search</v-icon>
                      ข้อมูลนักศึกษาเพิ่มเติม</v-btn
                    >
                  </v-row>
                </v-card-text></v-col
              >
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center mb-2">
        <v-col cols="10">
          <JobApplicatiionStatusManagement
            v-if="jobapplication"
            :JobApplication="jobapplication"
          ></JobApplicatiionStatusManagement>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { JobApplicationDataProvider } from "@/services/JobApplicationDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
import moment from "moment";
import FormatDate from "@/components/common/FormatDate.vue";

const jobApplicationDataProvider = new JobApplicationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import html2pdf from "html2pdf.js";
import JobApplicatiionStatusManagement from "./JobApplicatiionStatusManagement.vue";

export default {
  name: "JobApplicationDetailView",
  components: { JobApplicatiionStatusManagement, FormatDate },
  data() {
    return {
      jobapplication_oid: "",
      jobapplication: undefined,
      jobapplicationentitystatuses: undefined,
      selected_jobapplicationentitystatus_code: "",
      logo_photobase64: "",
      moment: moment,
      entitystatuses: undefined,
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider
        .getJobApplicationEntityStatuses()
        .then((result) => {
          this.jobapplicationentitystatuses = result.data;
        });

      await jobApplicationDataProvider
        .getJobApplication(this.jobapplication_oid)
        .then((result) => {
          this.jobapplication = result.data;
          this.selected_jobapplicationentitystatus_code =
            this.jobapplication.jobapplicationstatus.statuscode;
          this.logo_photobase64 = result.data.student.base64thrumnail;
        });
    },
    print() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: "JobApplicationInfo.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        enableLinks: true,
        pagebreak: { mode: ["css", "legacy"] },
      });
    },
    openSearchStudentDetails: function () {
      const student_oid = this.jobapplication.student.oid;
      this.$router
        .push({ name: "SearchStudentDetails", params: { student_oid } })
        .catch((err) => {});
    },
    async onSaveSelectedJobApplicationStatus() {
      await employerJobPostingDataProvider
        .setJobApplicationStatus(
          this.jobapplication_oid,
          this.selected_jobapplicationentitystatus_code
        )
        .then((result) => {
          this.$toast.success("บันทึกสถานะใบสมัครงานเรียบร้อย");
        });
    },
    calculateAge(birthday) {
      if (!birthday) return "";
      console.log(birthday);
      const birthdayDate = moment(birthday, "DD MMMM YYYY");
      const age = moment().diff(birthdayDate, "years");
      return age;
    },
    moreInformation() {
      var oid = this.jobapplication?.student?.oid;
      console.log(oid);
      this.$router.push({
        name: "StudentDetailOverview",
        params: { studentoid: oid },
      });
    },
  },
  mounted() {
    // var datenow = new Date
    this.daysDifference = moment().diff("2000-02-28", "years");
  },
  async created() {
    if (this.$route.params.oid != undefined) {
      this.jobapplication_oid = this.$route.params.oid;
      await this.initialize();
    }
  },
};
</script>

<style scoped>
.shrink {
  border-radius: 200px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 10px rgba(15, 15, 15, 0.5);
  border: 1px solid grey;
}
</style>
