<template>
  <v-container class="">
    <v-row>
      <v-col>
        <v-img src="@/assets/temp/StudentList.png"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
