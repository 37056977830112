export const JobPostingStatus = [
  {
    color: "#AAAAAA",
    statuscode: "Draft",
    statusname: "ร่าง",
  },
  {
    color: "#34C759",
    statuscode: "Active",
    statusname: "เปิดรับสมัครงาน",
  },
  {
    color: "#FF9800",
    statuscode: "OnHold",
    statusname: "ปิดรับสมัครชั่วคราว",
  },
  {
    color: "#484848",
    statuscode: "Expired",
    statusname: "หมดอายุ",
  },
  {
    color: "#DC3545",
    statuscode: "Cancelled",
    statusname: "ยกเลิก",
  },
  {
    color: "#FF9800",
    statuscode: "Closed",
    statusname: "ปิดการประกาศรับสมัครงาน",
  },
];

export const MaritalStatus = [
  {
    nameTH: "โสด",
    text: "single",
    value: 0,
  },
  {
    nameTH: "แต่งงาน",
    text: "married",
    value: 1,
  },
  {
    nameTH: "หย่าร้าง",
    text: "separated",
    value: 2,
  },
];

export const MilitaryStatus = [
  {
    nameTH: "ยังไม่ผ่านการเกณฑ์ทหาร",
    text: "nonpass",
    value: 0,
  },
  {
    nameTH: "ผ่านการเกณฑ์ทหาร",
    text: "pass",
    value: 1,
  },
  {
    nameTH: "อยู่ระหว่างรับราชการทหาร",
    text: "inprogress",
    value: 2,
  },
  {
    nameTH: "ได้รับการยกเว้น (สำหรับหลักสูตรรักษาดินแดน)",
    text: "exceptlearned",
    value: 3,
  },
  {
    nameTH: "ได้รับการยกเว้น (จับฉลากได้ใบดำ หรือ ร่างการไม่ขนาด)",
    text: "exceptgotblackcard",
    value: 4,
  },
];

export const employmentType = [
  {
    nameth: "งานประจำ (Full Time)",
    code: "fulltime",
  },
  {
    nameth: "งานนอกเวลา (Part Time)",
    code: "parttime",
  },
  {
    nameth: "ฝึกงาน",
    code: "internship",
  },
  {
    nameth: "งานอิสระ (Freelance)",
    code: "freelance",
  },
  {
    nameth: "งานจ้างรายวัน",
    code: "byday",
  },
  {
    nameth: "งานจ้างชั่วคราว",
    code: "temporary",
  },
  {
    nameth: "รับงานไปทำที่บ้าน",
    code: "workfromhome",
  },
]
