var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"800","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-container',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('h2',[_c('v-icon',[_vm._v("mdi-account-group")]),_vm._v(" จัดการสิทธิการใช้งาน")],1)])],1),_c('v-list',[_c('v-card',{staticClass:"mt-5"},[(_vm.isUsedForSchoolAdmin == false)?_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                        /*active*/
                      }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                            _vm.roleAndPermission_Management.is_center_admin
                          ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_center_admin", $$v)},expression:"\n                            roleAndPermission_Management.is_center_admin\n                          "}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("ผู้ดูแลระบบส่วนกลาง")]),_c('v-list-item-subtitle',[_vm._v("ผู้ดูแลระบบมีหน้าที่ในการจัดการข้อมูลพื้นฐานต่าง ๆ ในระบบ รวมถึงสามารถจัดการข้อมูลผู้ใช้งานระบบ"),_c('br'),_vm._v("ทั้งหมดในระบบได้")])],1)]}}],null,false,1519098014)}),_c('v-divider')]],2):_vm._e(),(_vm.isUsedForSchoolAdmin == false)?_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                        /*active*/
                      }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                            _vm.roleAndPermission_Management.is_center_officer
                          ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_center_officer", $$v)},expression:"\n                            roleAndPermission_Management.is_center_officer\n                          "}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("บุคลากรส่วนกลาง")])],1)]}}],null,false,3528640991)}),_c('v-divider')]],2):_vm._e(),(_vm.isUsedForSchoolAdmin == false)?_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                      /*active*/
                    }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                          _vm.roleAndPermission_Management.is_center_manager
                        ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_center_manager", $$v)},expression:"\n                          roleAndPermission_Management.is_center_manager\n                        "}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("ผู้บริหารส่วนกลาง")])],1)]}}],null,false,1626533942)}),_c('v-divider')],1):_vm._e(),_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                        /*active*/
                      }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                            _vm.roleAndPermission_Management.is_school_manager
                          ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_school_manager", $$v)},expression:"\n                            roleAndPermission_Management.is_school_manager\n                          "}})],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-list-item-title',[_vm._v("ผู้บริหารสถานศึกษา")])],1),_c('div',{staticClass:"d-flex justify-start mt-2"},[(
                              _vm.roleAndPermission_Management.is_school_manager
                            )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.school_manager_permissions_dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" เลือกสถานศึกษา")],1):_vm._e()],1),(
                            _vm.roleAndPermission_Management.is_school_manager
                          )?_c('SearchableTable',{attrs:{"table_caption":"สิทธิผู้บริหารสถานศึกษา","data_items":_vm.roleAndPermission_Management.school_manager_permissions,"datatable_headers":_vm.school_manager_permissions_data_table_header},scopedSlots:_vm._u([{key:"item.permissionvalue",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convert_SchoolID_To_SchoolName( item.permissionvalue )))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.delete_school_manager_permissions(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("ลบ")])])]}}],null,true)}):_vm._e()],1)]}}])}),_c('v-divider')]],2),_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                        /*active*/
                      }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                            _vm.roleAndPermission_Management.is_school_admin
                          ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_school_admin", $$v)},expression:"\n                            roleAndPermission_Management.is_school_admin\n                          "}})],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-end mt-5"},[_c('v-list-item-title',[_vm._v("บุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)")])],1),_c('div',{staticClass:"d-flex justify-start mt-2"},[(
                              _vm.roleAndPermission_Management.is_school_admin
                            )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.school_admin_permissions_dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("เลือกสถานศึกษา")],1):_vm._e()],1),(_vm.roleAndPermission_Management.is_school_admin)?_c('SearchableTable',{attrs:{"table_caption":"สิทธิบุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)","data_items":_vm.roleAndPermission_Management.school_admin_permissions,"datatable_headers":_vm.school_admin_permissions_data_table_header},scopedSlots:_vm._u([{key:"item.permissionvalue",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convert_SchoolID_To_SchoolName( item.permissionvalue )))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.delete_school_admin_permissions(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("ลบ")])])]}}],null,true)}):_vm._e()],1)]}}])}),_c('v-divider')]],2),_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                        /*active*/
                      }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                            _vm.roleAndPermission_Management.is_school_partner_officer
                          ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_school_partner_officer", $$v)},expression:"\n                            roleAndPermission_Management.is_school_partner_officer\n                          "}})],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-list-item-title',[_vm._v("บุคลากรในสถานศึกษา (ทวิภาคี)")])],1),_c('div',{staticClass:"d-flex justify-start mt-2"},[(
                              _vm.roleAndPermission_Management.is_school_partner_officer
                            )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.school_partner_officer_permissions_dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("เลือกสถานศึกษา")],1):_vm._e()],1),(
                            _vm.roleAndPermission_Management.is_school_partner_officer
                          )?_c('SearchableTable',{attrs:{"table_caption":"สิทธิบุคลากรในสถานศึกษา (ทวิภาคี)","data_items":_vm.roleAndPermission_Management.school_partner_officer_permissions,"datatable_headers":_vm.school_partner_officer_permissions_data_table_header},scopedSlots:_vm._u([{key:"item.permissionvalue",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convert_SchoolID_To_SchoolName( item.permissionvalue )))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.delete_school_partner_officer_permissions(
                                      item
                                    )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("ลบ")])])]}}],null,true)}):_vm._e()],1)]}}])}),_c('v-divider')]],2),_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                        /*active*/
                      }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                            _vm.roleAndPermission_Management.is_school_guidance_teacher
                          ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_school_guidance_teacher", $$v)},expression:"\n                            roleAndPermission_Management.is_school_guidance_teacher\n                          "}})],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-list-item-title',[_vm._v("บุคลากรในสถานศึกษา (ครูแนะแนว)")])],1),_c('div',{staticClass:"d-flex justify-start mt-2"},[(
                              _vm.roleAndPermission_Management.is_school_guidance_teacher
                            )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.school_guidance_teacher_permissions_dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("เลือกสถานศึกษา")],1):_vm._e()],1),(
                            _vm.roleAndPermission_Management.is_school_guidance_teacher
                          )?_c('SearchableTable',{attrs:{"table_caption":"สิทธิบุคลากรในสถานศึกษา (ครูแนะแนว)","data_items":_vm.roleAndPermission_Management.school_guidance_teacher_permissions,"datatable_headers":_vm.school_guidance_teacher_permissions_data_table_header},scopedSlots:_vm._u([{key:"item.permissionvalue",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convert_SchoolID_To_SchoolName( item.permissionvalue )))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.delete_school_guidance_teacher_permissions(
                                      item
                                    )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("ลบ")])])]}}],null,true)}):_vm._e()],1)]}}])}),_c('v-divider')]],2),(_vm.isUsedForSchoolAdmin == false)?_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                        /*active*/
                      }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                            _vm.roleAndPermission_Management.is_provinceschool_officer
                          ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_provinceschool_officer", $$v)},expression:"\n                            roleAndPermission_Management.is_provinceschool_officer\n                          "}})],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-list-item-title',[_vm._v("บุคลากรในอาชีวศึกษาจังหวัด")])],1),_c('div',{staticClass:"d-flex justify-start mt-2"},[(
                              _vm.roleAndPermission_Management.is_provinceschool_officer
                            )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.provinceschool_officer_permissions_dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" เลือกจังหวัด")],1):_vm._e()],1),(
                            _vm.roleAndPermission_Management.is_provinceschool_officer
                          )?_c('SearchableTable',{attrs:{"table_caption":"สิทธิบุคลากรในอาชีวศึกษาจังหวัด","data_items":_vm.roleAndPermission_Management.provinceschool_officer_permissions,"datatable_headers":_vm.provinceschool_officer_permissions_data_table_header},scopedSlots:_vm._u([{key:"item.permissionvalue",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convert_ProvinceID_To_ProvinceName( item.permissionvalue )))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.delete_provinceschool_officer_permissions(
                                      item
                                    )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("ลบ")])])]}}],null,true)}):_vm._e()],1)]}}],null,false,2472225794)}),_c('v-divider')]],2):_vm._e(),(_vm.isUsedForSchoolAdmin == false)?_c('v-list-item-group',{staticStyle:{"border-color":"blue"},attrs:{"active-class":"primary--text","multiple":""}},[[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function({
                        /*active*/
                      }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"inset":""},model:{value:(
                            _vm.roleAndPermission_Management.is_insitution_officer
                          ),callback:function ($$v) {_vm.$set(_vm.roleAndPermission_Management, "is_insitution_officer", $$v)},expression:"\n                            roleAndPermission_Management.is_insitution_officer\n                          "}})],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-list-item-title',[_vm._v("บุคลากรในสถาบันการอาชีวศึกษา")])],1),_c('div',{staticClass:"d-flex justify-start mt-2"},[(
                              _vm.roleAndPermission_Management.is_insitution_officer
                            )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.insitution_officer_permissions_dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" เลือกสถาบันการอาชีวศึกษา")],1):_vm._e()],1),(
                            _vm.roleAndPermission_Management.is_insitution_officer
                          )?_c('SearchableTable',{attrs:{"table_caption":"สิทธิบุคลากรในสถาบันการอาชีวศึกษา","data_items":_vm.roleAndPermission_Management.insitution_officer_permissions,"datatable_headers":_vm.insitution_officer_permissions_data_table_header},scopedSlots:_vm._u([{key:"item.permissionvalue",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convert_InsitutionID_To_InsitutionName( item.permissionvalue )))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.delete_insitution_officer_permissions(
                                      item
                                    )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("ลบ")])])]}}],null,true)}):_vm._e()],1)]}}],null,false,2116200052)}),_c('v-divider')]],2):_vm._e()],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ma-2",on:{"click":_vm.onclosedialogclick}},[_vm._v("ยกเลิก")]),_c('v-btn',{staticClass:"ma-2 color-button2",on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" บันทึก")],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.school_manager_permissions_dialog),callback:function ($$v) {_vm.school_manager_permissions_dialog=$$v},expression:"school_manager_permissions_dialog"}},[[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('h4',[_vm._v("สิทธิผู้บริหารสถานศึกษา")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.schools,"item-text":"schoolname","item-value":"schoolid"},model:{value:(
                          _vm.selected_schoolid_for_school_manager_permission
                        ),callback:function ($$v) {
                          _vm.selected_schoolid_for_school_manager_permission
                        =$$v},expression:"\n                          selected_schoolid_for_school_manager_permission\n                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"color-button2",on:{"click":function($event){return _vm.add_school_manager_permissions()}}},[_vm._v(" บันทึก "),_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1)],1)]],2),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.school_admin_permissions_dialog),callback:function ($$v) {_vm.school_admin_permissions_dialog=$$v},expression:"school_admin_permissions_dialog"}},[[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('h4',[_vm._v("สิทธิบุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.schools,"item-text":"schoolname","item-value":"schoolid"},model:{value:(
                          _vm.selected_schoolid_for_school_admin_permissions
                        ),callback:function ($$v) {
                          _vm.selected_schoolid_for_school_admin_permissions
                        =$$v},expression:"\n                          selected_schoolid_for_school_admin_permissions\n                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"color-button2",on:{"click":function($event){return _vm.add_school_admin_permissions()}}},[_vm._v(" บันทึก "),_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1)],1)]],2),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.school_partner_officer_permissions_dialog),callback:function ($$v) {_vm.school_partner_officer_permissions_dialog=$$v},expression:"school_partner_officer_permissions_dialog"}},[[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('h4',[_vm._v("สิทธิบุคลากรในสถานศึกษา (ทวิภาคี)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.schools,"item-text":"schoolname","item-value":"schoolid"},model:{value:(
                          _vm.selected_schoolid_for_school_partner_officer_permissions
                        ),callback:function ($$v) {
                          _vm.selected_schoolid_for_school_partner_officer_permissions
                        =$$v},expression:"\n                          selected_schoolid_for_school_partner_officer_permissions\n                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"color-button2",on:{"click":function($event){return _vm.add_school_partner_officer_permissions()}}},[_vm._v(" บันทึก "),_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1)],1)]],2),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.school_guidance_teacher_permissions_dialog),callback:function ($$v) {_vm.school_guidance_teacher_permissions_dialog=$$v},expression:"school_guidance_teacher_permissions_dialog"}},[[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('h4',[_vm._v("สิทธิบุคลากรในสถานศึกษา (ครูแนะแนว)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.schools,"item-text":"schoolname","item-value":"schoolid"},model:{value:(
                          _vm.selected_schoolid_for_school_guidance_teacher_permissions
                        ),callback:function ($$v) {
                          _vm.selected_schoolid_for_school_guidance_teacher_permissions
                        =$$v},expression:"\n                          selected_schoolid_for_school_guidance_teacher_permissions\n                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"color-button2",on:{"click":function($event){return _vm.add_school_guidance_teacher_permissions()}}},[_vm._v("บันทึก "),_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1)],1)]],2),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.provinceschool_officer_permissions_dialog),callback:function ($$v) {_vm.provinceschool_officer_permissions_dialog=$$v},expression:"provinceschool_officer_permissions_dialog"}},[[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('h4',[_vm._v("สิทธิบุคลากรในอาชีวศึกษาจังหวัด")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.provinces,"item-text":"provincethai","item-value":"provinceid"},model:{value:(
                          _vm.selected_provinceid_for_provinceschool_officer_permissions
                        ),callback:function ($$v) {
                          _vm.selected_provinceid_for_provinceschool_officer_permissions
                        =$$v},expression:"\n                          selected_provinceid_for_provinceschool_officer_permissions\n                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"color-button2",on:{"click":function($event){return _vm.add_provinceschool_officer_permissions()}}},[_vm._v(" บันทึก "),_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1)],1)]],2),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.insitution_officer_permissions_dialog),callback:function ($$v) {_vm.insitution_officer_permissions_dialog=$$v},expression:"insitution_officer_permissions_dialog"}},[[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('h4',[_vm._v("สิทธิบุคลากรในสถาบันการอาชีวศึกษา")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.institues,"item-text":"institutenameth","item-value":"instituteid"},model:{value:(
                          _vm.selected_insitutionid_for_insitution_officer_permissions
                        ),callback:function ($$v) {
                          _vm.selected_insitutionid_for_insitution_officer_permissions
                        =$$v},expression:"\n                          selected_insitutionid_for_insitution_officer_permissions\n                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"color-button2",on:{"click":function($event){return _vm.add_insitution_officer_permissions()}}},[_vm._v(" บันทึก "),_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1)],1)]],2)],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }