<style></style>
<template>
  <div>
    <DocumentViewer
      :report_name="'JobTrackingReport_ConcludeEmploymentStatus_Report?'"
      :report_parameters="['param_GraduatedStudentJobTrackingReportOid=8193','']"
    ></DocumentViewer>
  </div>
</template>

<script>
import DocumentViewer from '@/components/Report/DocumentViewer.vue';
export default {
  name: "TestReport",
  data() {
    return {
    };
  },
  components: { DocumentViewer },
};
</script>
