<template>
  <div class="pa-5">
    <v-card rounded elevation="1" class="px-5 py-5">
      <v-row class="d-flex justify-center pb-2">
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0 custom-col">
          <h4 class="primary--text mb-2">ประเภทวิชา</h4>
          <v-autocomplete
            :label="selectedSubject ? '' : 'ประเภทวิชา'"
            clearable
            :items="subject"
            item-text="subjecttypenameth"
            item-value="subjecttypeid"
            v-model="selectedSubject"
            @change="onSelected()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            hide-details="true"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0 custom-col">
          <h4 class="primary--text mb-2">สาขาวิชา</h4>
          <v-autocomplete
            :label="selectedMajor ? '' : 'สาขาวิชา'"
            clearable
            :items="major"
            item-text="majorname"
            item-value="majorcode"
            v-model="selectedMajor"
            @change="onSelected()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            hide-details="true"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0 custom-col">
          <h4 class="primary--text mb-2">ระดับการศึกษา</h4>
          <v-autocomplete
            :label="selectedCertificateType ? '' : 'ระดับการศึกษา'"
            clearable
            :items="certificateType"
            item-text="nameth"
            item-value="code"
            v-model="selectedCertificateType"
            @change="onSelected()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            hide-details="true"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0 custom-col">
          <h4 class="primary--text mb-2">สถานะนักศึกษา</h4>

          <v-row no-gutters align-content="end">
            <!-- <v-btn
                :color="
                  selectedStudentStatus === ''
                    ? 'primary'
                    : ''
                "
                @click="
                  selectedStudentStatus = '';
                  onSelected();
                "
                :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                style="background-color: #f2f4f8"
              >
                ทั้งหมด
              </v-btn> -->
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1 mb-2"
            >
              <v-btn
                :color="selectedStudentStatus === '09' ? 'primary' : 'input'"
                @click="
                  selectedStudentStatus =
                    selectedStudentStatus === '09' ? '' : '09';
                  onSelected();
                "
                height="52px"
                width="100%"
              >
                สำเร็จการศึกษา
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
            >
              <v-btn
                :color="selectedStudentStatus === '03' ? 'primary' : 'input'"
                @click="
                  selectedStudentStatus =
                    selectedStudentStatus === '03' ? '' : '03';
                  onSelected();
                "
                height="52px"
                width="100%"
              >
                อยู่ระหว่าง <br />การศึกษา
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-autocomplete
              label="สถานะนักศึกษา"
              clearable
              solo
              :items="studentStatus"
              item-value="studentstatuscode"
              item-text="studentstatusname"
              v-model="selectedStudentStatus"
              @change="onSelected"
            ></v-autocomplete> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-2 custom-col">
          <h4 class="primary--text mb-2">ปีที่จบการศึกษา</h4>
          <v-autocomplete
            :label="selectedGraduatedYear ? '' : 'ปีที่จบการศึกษา'"
            clearable
            :items="graduatedYear"
            v-model="selectedGraduatedYear"
            @change="onSelected()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            hide-details="true"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" lg="6" md="6" sm="12" class="mb-0 pb-0 custom-col">
          <h4 class="primary--text mb-2">สถานศึกษา</h4>
          <v-autocomplete
            :label="selectedSchool ? '' : 'สถานศึกษา'"
            clearable
            :items="school"
            item-text="schoolname"
            item-value="schoolid"
            v-model="selectedSchool"
            @change="onSelected()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            hide-details="true"
          >
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="12"
          sm="12"
          xs="12"
          class="mb-0 pb-0 custom-col"
        >
          <h4 class="primary--text mb-2">สถานะการอัปเดตโปรไฟล์</h4>
          <v-row no-gutters align-content="end">
            <!-- <v-btn
                :color="
                  tableOptions.selected_studentupdateprofilevalue === ''
                    ? 'primary'
                    : ''
                "
                @click="
                  tableOptions.selected_studentupdateprofilevalue = '';
                  loadStudentsWithDividePaging();
                "
                :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                style="background-color: #f2f4f8"
              >
                ทั้งหมด
              </v-btn> -->
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1 mb-1"
            >
              <v-btn
                :color="
                  selectedStudentUpdatedProfileStatus === true
                    ? 'primary'
                    : 'input'
                "
                @click="
                  selectedStudentUpdatedProfileStatus =
                    selectedStudentUpdatedProfileStatus === true ? '' : true;
                  onSelected();
                "
                :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                height="52px"
                width="100%"
              >
                อัปเดตแล้ว
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
            >
              <v-btn
                class="{ 'px-2': $vuetify.breakpoint.lg }"
                :color="
                  selectedStudentUpdatedProfileStatus === false
                    ? 'primary'
                    : 'input'
                "
                @click="
                  selectedStudentUpdatedProfileStatus =
                    selectedStudentUpdatedProfileStatus === false ? '' : false;
                  onSelected();
                "
                height="52px"
                width="100%"
              >
                ยังไม่มี<br />การอัปเดต
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-autocomplete
              label="สถานะการอัปเดตโปรไฟล์"
              clearable
              solo
              :items="studentUpdatedProfileStatus"
              item-text="name"
              item-value="value"
              v-model="selectedStudentUpdatedProfileStatus"
              @change="onSelected"
            ></v-autocomplete> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          lg="3"
          md="12"
          sm="12"
          xs="12"
          class="mb-0 pb-0 custom-col"
        >
          <h4 class="primary--text mb-2">เพศ</h4>
          <v-row no-gutters>
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
            >
              <v-btn
                :color="selectedGender === '' ? 'primary' : 'input'"
                @click="
                  selectedGender = '';
                  onSelected();
                "
                height="52px"
                class="mr-5"
                width="100%"
              >
                ทั้งหมด
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1 mb-1"
            >
              <v-btn
                :color="selectedGender === 'M' ? 'primary' : 'input'"
                @click="
                  selectedGender = 'M';
                  onSelected();
                "
                height="52px"
                width="100%"
              >
                ชาย
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
            >
              <v-btn
                :color="selectedGender === 'F' ? 'primary' : 'input'"
                @click="
                  selectedGender = 'F';
                  onSelected();
                "
                height="52px"
                width="100%"
              >
                หญิง
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-autocomplete
              label="เพศ"
              solo
              clearable
              :items="genderlist"
              item-text="name"
              item-value="code"
              v-model="selectedGender"
              @change="onSelected"
            >
            </v-autocomplete> -->
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="12"
          sm="12"
          xs="12"
          class="mb-0 pb-0 custom-col"
        >
          <h4 class="primary--text mb-2">ประเภทสถานศึกษา</h4>
          <v-row no-gutters align-content="end">
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
            >
              <v-btn
                :color="selectedSchooltype === 0 ? 'primary' : 'input'"
                @click="
                  selectedSchooltype = 0;
                  onSelected();
                "
                height="52px"
                width="100%"
              >
                ทั้งหมด
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
            >
              <v-btn
                :color="selectedSchooltype === 1 ? 'primary' : 'input'"
                @click="
                  selectedSchooltype = 1;
                  onSelected();
                "
                height="52px"
                width="100%"
              >
                รัฐบาล
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
            >
              <v-btn
                :color="selectedSchooltype === 2 ? 'primary' : 'input'"
                @click="
                  selectedSchooltype = 2;
                  onSelected();
                "
                height="52px"
                width="100%"
              >
                เอกชน
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-autocomplete
              solo
              v-model="selectedSchooltype"
              :items="schoolTypeList"
              text="text"
              value="value"
              @change="onSelected"
            ></v-autocomplete> -->
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="6"
          sm="12"
          xs="12"
          class="mb-0 pb-0 custom-col"
        >
          <h4 class="primary--text mb-2">ภูมิภาค</h4>
          <v-select
            :label="selectedRegions ? '' : 'ภูมิภาค'"
            clearable
            :items="regions"
            v-model="selectedRegions"
            @change="onSelected()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
          >
          </v-select>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="6"
          sm="12"
          xs="12"
          class="mb-0 pb-0 custom-col"
        >
          <h4 class="primary--text mb-2">จังหวัด</h4>

          <v-autocomplete
            :label="selectedProvinces ? '' : 'จังหวัด'"
            clearable
            :items="provinces"
            item-text="provincethai"
            item-value="provinceid"
            v-model="selectedProvinces"
            @change="onSelected()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="initialized">
      <SearchableDataTablePaging
        :datatable_headers="studentSearching_data_table_headers"
        class="elevation-1"
        :itemlength="itemlength"
        :data_items="students"
        table_caption="ผลการค้นหา"
        :search="keyword"
        @onkeywordchanged="onKeywordChanged"
        @pageChanged="pageChanged"
        @tableOptionsChanged="tableOptionsChanged"
      >
        <template v-slot:item.student_completionpercentage="{ item }">
          <v-row>
            <v-col cols="6" class="d-flex justify-center align-center">
              <CompletionPercentageNumber
                :student_veceducationinformation="item"
              >
              </CompletionPercentageNumber>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.firstname="{ item }">
          <span>{{ item.firstname }} {{ item.surname }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-row>
            <v-col cols="6">
              <v-tooltip top color="info">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="openStudentDetailOverview(item)"
                    color="info"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="my-1"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
                <span>ข้อมูลนักศึกษา</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </SearchableDataTablePaging>
    </div>
  </div>
</template>

<script>
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { AdminSearchStudentService } from "@/services/AdminSearchStudentService";
import { AdminSearchStudentRequest } from "@/models/Student/AdminSearchStudentRequest";
import CompletionPercentageNumber from "@/components/Profile/CompletionPercentageNumber.vue";
const adminSearchStudentService = new AdminSearchStudentService(
  process.env.VUE_APP_BACKEND_API_URL
);

const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const majorlDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "SearchStudentList",
  components: { SearchableDataTablePaging, CompletionPercentageNumber },
  data() {
    return {
      studentSearching_data_table_headers: [
        {
          text: "สถานศึกษา",
          value: "schoolname",
        },
        {
          text: "จังหวัด",
          value: "provincethai",
        },
        {
          text: "ปีที่จบการศึกษา",
          value: "gradautedyearth",
        },

        {
          text: "ชื่อ-นามสกุล",
          value: "firstname",
        },
        {
          text: "ระดับการศึกษา",
          value: "certificatetype_name",
        },
        {
          text: "ประเภทวิชา",
          value: "subjecttypenameth",
        },
        {
          text: "สาขาวิชา",
          value: "majornameth",
        },
        {
          text: "ความสมบูรณ์ของข้อมูล",
          value: "student_completionpercentage",
        },
        { text: "ตัวเลือก", value: "action", sortable: false },
      ],
      itemlength: 0,
      search: "",
      keyword: "",
      schoolTypeList: [
        { text: "ภาครัฐ/ภาคเอกชน", value: 0 },
        { text: "ภาครัฐ", value: 1 },
        { text: "ภาคเอกชน", value: 2 },
      ],

      school: undefined,
      subject: undefined,
      major: undefined,
      provinces: undefined,
      regions: undefined,
      graduatedYear: undefined,
      genderlist: [
        {
          name: "ชาย",
          code: "M",
        },
        {
          name: "หญิง",
          code: "F",
        },
      ],
      studentStatus: undefined,
      studentUpdatedProfileStatus: undefined,
      certificateType: undefined,

      request_school_data: new GetSchools_Reqeust(),
      request_student_data: new AdminSearchStudentRequest(),
      students: undefined,
      initialized: false,

      selectedSchool: "",
      selectedSubject: "",
      selectedMajor: "",
      selectedProvinces: "",
      selectedRegions: "",
      selectedGraduatedYear: "",
      selectedGender: "",
      selectedStudentStatus: "",
      selectedStudentUpdatedProfileStatus: "",
      selectedCertificateType: "",
      selectedSchooltype: 0,
      tableOptions: undefined,
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });

      await commonDataProvider.getRegionAsVECNames().then((result) => {
        this.regions = result.data;
      });

      await commonDataProvider.getListOfYearTH().then((result) => {
        this.graduatedYear = result.data;
      });

      await commonDataProvider.getStudentTypes().then((result) => {
        this.studentType = result.data;
      });

      await masterDataProvider.getGenders().then((result) => {
        this.gender = result.data;
      });

      await commonDataProvider.getStudentStatuses().then((result) => {
        this.studentStatus = result.data;
      });
      await masterDataProvider
        .getStudentUpdateProfileStatuses()
        .then((result) => {
          this.studentUpdatedProfileStatus = result.data;
        });
      await masterDataProvider.getCertificateTypes().then((result) => {
        this.certificateType = result.data;
      });
      await schoolDataProvider
        .loadSchools(this.request_school_data)
        .then((result) => {
          this.school = result.data;
        });
      await masterDataProvider.getStudentMajors().then((result) => {
        this.major = result.data;
      });
      await subjectTypeDataProvider.loadSubjectTypes().then((result) => {
        this.subject = result.data;
      });
    },
    async searchStudent() {
      this.$store.dispatch("showLoading");

      await adminSearchStudentService
        .searchStudent(this.request_student_data)
        .then((result) => {
          this.students = result.data.items;
          console.log(this.students, "  this.students ");
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },
    openStudentDetailOverview(item) {
      var studentOid = item.student_oid;

      // Generate the path from the route name and params
      const routeData = this.$router.resolve({
        name: "StudentDetailOverview",
        params: { studentoid: studentOid },
      });

      // Open the generated path in a new tab
      window.open(routeData.href, "_blank");
    },
    openNewTab() {
      // Open a page from the same domain with a specific path
      const path = "/new-page"; // Define the relative path here
      const url = window.location.origin + path; // Construct the full URL with the same domain
      window.open(url, "_blank");
    },
    async onSelected() {
      this.request_student_data.Gender = this.selectedGender;
      this.request_student_data.CertificateTypeCode =
        this.selectedCertificateType;
      this.request_student_data.GraduatedYearTH = this.selectedGraduatedYear;
      this.request_student_data.MajorCode = this.selectedMajor;

      this.request_student_data.RegionAsVEC = this.selectedRegions;
      if (this.selectedRegions) {
        masterDataProvider
          .loadGetProvincesByRegionAsVECName(this.selectedRegions)
          .then((result) => {
            this.provinces = result.data;
          });
      } else {
        await masterDataProvider.loadGetProvinces().then((result) => {
          this.provinces = result.data;
        });
      }
      this.request_student_data.ProvinceID = this.selectedProvinces;
      this.request_student_data.SchoolID = this.selectedSchool;
      this.request_student_data.IsProfileUpdated =
        this.selectedStudentUpdatedProfileStatus;
      this.request_student_data.StudentStatusCode = this.selectedStudentStatus;
      this.request_student_data.SubjectTypeCode = this.selectedSubject;
      if (this.request_student_data.SubjectTypeCode) {
        await masterDataProvider
          .getStudentMajorsFromSubjectTypeID(
            this.request_student_data.SubjectTypeCode
          )
          .then((result) => {
            this.major = result.data;
          });
      } else {
        await masterDataProvider.getStudentMajors().then((result) => {
          this.major = result.data;
        });
      }

      this.request_student_data.SchoolType = this.selectedSchooltype;
      await schoolDataProvider
        .getSchoolsFromGraduatedStudentJobTrackingReportExport(
          this.request_student_data.SchoolType,
          this.request_student_data.ProvinceID
        )
        .then((result) => {
          this.school = result.data;
        });

      await this.searchStudent();
    },
    async onKeywordChanged(newKeyword) {
      this.request_student_data.keyword = newKeyword;
      await this.searchStudent();
    },
    pageChanged(pageChangeValue) {
      if (this.initialized) {
        this.request_student_data.PageNumber = pageChangeValue;
        this.searchStudent();
      }
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
      this.request_student_data.ItemsPerPage = newtableOptions.itemsPerPage;
      this.request_student_data.SortBy = newtableOptions.sortBy;
      this.request_student_data.SortDesc = newtableOptions.sortDesc;
      this.searchStudent();
    },
  },

  async created() {
    await this.initialize().then(() => {
      this.initialized = true;
    });
  },
};
</script>

<style scoped>
/* @media (max-width: 1630px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
} */
@media (max-width: 1630px) {
  .responsive-btn {
    font-size: 12px; /* Smaller size for screens <= 1630px */
  }
}
@media (max-width: 1400px) {
  .responsive-btn {
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word !important; /* Break long words if necessary */
  }
}
</style>
