import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
import { GetJobPositionMatching_Request } from '@/models/JobPositionMatching/GetJobPositionMatching_Request';
import { SaveJobPositionMatching_Request } from '@/models/JobPositionMatching/SaveJobPositionMatching_Request';
export class JobPositionMatchingDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    loadAllDistinctPositionNames() {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPositionMatching/loadAllDistinctPositionNames";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, header);
    }

    getInsertedJobPositionMatchingNames(request = new GetJobPositionMatching_Request()) {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPositionMatching/GetInsertedJobPositionMatchingNames";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, request, header);
    }

    loadJobPositionMatchingNames(request = new GetJobPositionMatching_Request()) {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPositionMatching/GetJobPositionMatchingNames";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, request, header);
    }

    LoadJobPositionNames() {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPositionMatching/LoadJobPositionNames";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, header);
    }

    saveJobPositionMatchings(requests = []) {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPositionMatching/SaveJobPositionMatchings";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, requests, header);
    }

    saveJobPositionMatching(request = new SaveJobPositionMatching_Request()) {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPositionMatching/SaveJobPositionMatching";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, request, header);
    }
}