import SchoolListView from "@/views/Schools/SchoolListView";
import InfoSchoolDetailView from "@/views/Schools/infoSchoolDetailView";
import SchoolDetailView from "@/views/Schools/SchoolDetailView";

import SchoolStudentsView from "@/views/SchoolStudent/SchoolStudentsView.vue";
import SchoolStudentListView from "@/views/SchoolStudent/SchoolStudentListView.vue";
import StudentDetailView from "@/views/SchoolStudent/StudentDetailView.vue";
import StudentDetailOverview from "@/views/SchoolStudent/StudentDetailOverview.vue";
export default [
  {
    path: "/School",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/School/InfoSchoolDetailView",
        name: "InfoSchoolDetailView",
        component: InfoSchoolDetailView,
      },

      {
        path: "/School/SchoolDetailView/:schoolID",
        name: "SchoolDetailView",
        component: SchoolDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดสถานศึกษา",
        },
      },

      {
        path: "/School/SchoolListView",
        name: "SchoolListView",
        component: SchoolListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายชื่อสถานศึกษา",
        },
      },
      {
        path: "/SchoolStudent/SchoolStudentsView/:schoolID",
        name: "SchoolStudentsView",
        component: SchoolStudentsView,
        meta: {
          requiresAuth: true,
          nameTh: "รายชื่อนักศึกษา",
        },
      },
      {
        path: "/School/SchoolStudentListView",
        name: "SchoolStudentListView",
        component: SchoolStudentListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายชื่อนักศึกษา",
        },
      },
      {
        path: "/SchoolStudent/StudentDetailView/:studentoid",
        name: "StudentDetailView",
        component: StudentDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดนักเรียน",
        },
      },
      {
        path: "/SchoolStudent/StudentDetailOverview/:studentoid",
        name: "StudentDetailOverview",
        component: StudentDetailOverview,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดนักเรียน",
        },
      },
      {
        path: "/SchoolStudent/StudentDetailOverview",
        name: "StudentDetailOverview",
        component: StudentDetailOverview,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดนักเรียน",
        },
      },
    ],
  },
];
