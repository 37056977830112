<template>
  <div class="pa-4">
    <v-app-bar>
      <h3 class="primary--text">ส่งรายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา</h3>
    </v-app-bar>
    <!-- <v-container>
            <v-progress-linear v-if="isLoading == true" indeterminate color="success"></v-progress-linear>
        </v-container> -->
    <SearchableDataTable
      :data_items="job_tracking_reports"
      :datatable_headers="datatable_headers"
      table_caption="รายงานที่ต้องส่ง"
    >
      <template v-slot:item.iscertified="{ item }">
        <div v-if="item.iscertified === true">
          <v-tooltip top color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon medium color="green darken-2"> mdi-check-circle </v-icon>
              </v-btn>
            </template>
            <span>ยืนยันการส่งรายงาน</span>
          </v-tooltip>
          <span class="success--text">ยืนยันการส่งรายงาน</span>
        </div>
        <div v-else>
          <v-tooltip top color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon dark color="warning"> mdi-progress-clock </v-icon>
              </v-btn>
            </template>
            <span>รอยืนยันการส่งรายงาน</span> </v-tooltip
          ><span class="warning--text">รอยืนยันการส่งรายงาน</span>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          @click="CertificationDetail(item)"
          outlined
          dark
          color="success"
          class="ma-2"
        >
          <v-icon class="mr-1">mdi-file-sign</v-icon>ส่งรายงาน</v-btn
        >
      </template>
    </SearchableDataTable>
  </div>
</template>
<script>
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
var graduatedStudentJobTrackingReportReportDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
export default {
  components: { SearchableDataTable },
  name: "GraduatedStudentJobTrackingReportListView_ForCertification",
  data() {
    return {
      jobTrackingReportOid: "",
      datatable_headers: [
        { value: "schoolid", text: "รหัสสถานศึกษา" },
        { value: "schoolname", text: "ชื่อสถานศึกษา" },
        { value: "schoolprovincename", text: "จังหวัด" },
        { value: "targetgraduatedyearth", text: "ปีการศึกษา" },
        { value: "roundnumber", text: "รอบ" },
        {
          value: "quantityofgraduatedstudentjobtrackingreport_studentdetails",
          text: "จำนวนนักศึกษาที่สำเร็จการศึกษา",
        },
        { value: "iscertified", text: "สถานะ" },
        { value: "action", text: "" },
      ],
      job_tracking_reports: undefined,
      isLoading: false,
    };
  },
  methods: {
    async CertificationDetail(item) {
      this.$router.push({
        name: "GraduatedStudentJobTrackingReportDetailView_ForCertification",
        params: { oid: item.oid },
      });
    },
    async loadTrackingReports() {
      this.isLoading = true;
      this.job_tracking_reports = undefined;
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReportByProfile()
        .then((result) => {
          this.job_tracking_reports = result.data;
        })
        .catch((err) => {
          alert(err);
        });
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.loadTrackingReports();
  },
};
</script>
<style></style>
