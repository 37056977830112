<template>
  <v-card>
    <v-card-title
      >แยกตามภูมิภาค
      <span v-if="SelectedTargetGraduatedYearTH"
        >&nbsp; ปี {{ SelectedTargetGraduatedYearTH }}</span
      ><span v-if="SelectedRoundNumber">&nbsp; เทอม {{ SelectedRoundNumber }} </span
      ><v-spacer></v-spacer
    ></v-card-title>
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </v-card>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

import { backendapiUrl } from "@/services/constants";
import { CommonDataProvider } from "@/services/CommonDataProvider";

import { DashboardDataSourceProvider } from "@/services/DashboardDataSourceProvider";
var dashboardDataSourceProvider = new DashboardDataSourceProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

import { GetGraduatedStudentJobTrackingReport_EmploymentStatusAsRegionDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTracking_EmploymentStatusAsRegionDashboardDataSource_Request";
var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
import {
  EmploymentStatusCodes,
  JobTrackingReportStatusCodes,
  RegionTHNames,
} from "@/Constants";
export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    SelectedTargetGraduatedYearTH: {
      default: "",
      type: String,
    },
    SelectedRoundNumber: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      targetEmploymentStatusCodes: [],
      selectedyear: [],
      years: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: "ศึกษาต่อ",
            borderColor: "#3366CC",
            backgroundColor: "#3366CC",
          },
          {
            label: "บริษัทเอกชน",
            borderColor: "#DC3912",
            backgroundColor: "#DC3912",
          },
          {
            label: "หน่วยงานราชการ",
            borderColor: "#FF9900",
            backgroundColor: "#FF9900",
          },
          {
            label: "อาชีพอิสระ",
            borderColor: "#109618",
            backgroundColor: "#109618",
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.targetEmploymentStatusCodes.push(
      EmploymentStatusCodes.Study,
      EmploymentStatusCodes.WorkForCompany,
      EmploymentStatusCodes.Government,
      EmploymentStatusCodes.Freelance
    );

    this.loadRegionTHNames();
    this.init();
  },
  watch: {
    SelectedTargetGraduatedYearTH(newValue, OldValue) {
      this.EmploymentStatusAsRegionDashboardDataSource();
    },
    SelectedRoundNumber(newValue, OldValue) {
      this.EmploymentStatusAsRegionDashboardDataSource();
    },
  },
  methods: {
    async init() {
      await commonDataProvider
        .getEmploymentStatusNameByCodes(this.targetEmploymentStatusCodes)
        .then((result) => {
          this.EmploymentStatusNames = result.data;
        });
      this.EmploymentStatusAsRegionDashboardDataSource();
    },
    async loadRegionTHNames() {
      await commonDataProvider.getRegionTHNames().then((result) => {
        this.chartData.labels = result.data;
      });
    },
    async EmploymentStatusAsRegionDashboardDataSource() {
      const request = new GetGraduatedStudentJobTrackingReport_EmploymentStatusAsRegionDashboardDataSource_Request();
      request.TargetGraduatedYearTH = this.SelectedTargetGraduatedYearTH;
      request.RoundNumber = this.SelectedRoundNumber;

      var statusIndex = 0;
      for (const statusCode of this.targetEmploymentStatusCodes) {
        request.EmploymentStatusCode = statusCode;
        await dashboardDataSourceProvider
          .GetGraduatedStudentJobTrackingReport_EmploymentStatusAsRegionDashboardDataSource(
            request
          )
          .then((result) => {
            this.chartData.datasets[statusIndex].label = this.EmploymentStatusNames[
              statusIndex
            ];
            this.chartData.datasets[statusIndex].data = result.data.data;
            this.chartData.labels = result.data.labels;
          });
        statusIndex += 1;
      }
    },
  },
};
</script>
