<template>
  <div class="pa-4">
    <SearchableDataTable
      table_caption="ตำแหน่งงานที่สมัคร"
      :datatable_headers="jobposting_data_table_headers"
      :data_items="jobpostings"
    >
      <template v-slot:item.position="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <a v-bind="attrs" v-on="on" href="">{{
              item.position
            }}</a>
          </template>
          <span>รายละเอียด</span>
        </v-tooltip>
      </template>
      <template v-slot:item.registerdate="{ item }">
        {{
          moment(item.registerdate).add(543, "year").locale("TH").format("LL")
        }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status=='ยื่นใบสมัคร'">{{ item.status }}</span>
        <span v-if="item.status=='นัดสัมภาษณ์'" class="info--text">{{ item.status }}</span>
        <span v-if="item.status=='กำลังพิจารณา'" class="warning--text">{{ item.status }}</span>
        <span v-if="item.status=='รับเข้าทำงาน'" class="success--text">{{ item.status }}</span>
        <span v-if="item.status=='ปฏิเสธการรับเข้าทำงาน'" class="red--text">{{ item.status }}</span>
      </template>
      <template v-slot:item.jobmatching="{ item }">
        <v-row align="center">
          <v-checkbox
            class="pt-6 pl-3"
            readonly
            v-model="item.jobmatching == 1 ? true : false"
            color="success"
            label="ได้ทำงาน"
          ></v-checkbox>
          <v-checkbox
            class="pt-6 pl-3"
            readonly
             color="red"
            v-model="item.jobmatching == 2 ? true : false"
            label="ไม่ได้ทำงาน"
          ></v-checkbox>
        </v-row>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="dialog = true,selectedjobposting=item " color="red" v-bind="attrs" v-on="on" icon
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </template>
          <span>ลบ</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
       <v-dialog
      v-model="dialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h6">
          ต้องการลบตำแหน่งงานที่สมัครหรือไม่?
        </v-card-title>
        <v-card-actions class="center">
          <v-btn dark
            color="green lignten-1"
            text
            @click="onclickdeleteconfirm"
          >
            ตกลง (ทำการลบตำแหน่งงานที่สมัคร)
          </v-btn>
          <v-btn
            text
            @click="dialog = false"
          >
            ยกเลิก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue"
import moment from "moment"
// var studentDataProvider = new StudentDataProvider(
//   process.env.VUE_APP_BACKEND_API_URL
// )
export default {
  components: { SearchableDataTable },
  name: "RegisteredJobPostingListView",
  data() {
    return {
      moment: moment,
      jobpostings: [
        {
          position: "UX/UI designer",
          corporation: "TTA co,th",
          registerdate: "05-01-2023",
          status: "ยื่นใบสมัคร",
          jobmatching: 0
        },
        {
          position: "Font-end developer",
          corporation: "BSCE co,th",
          registerdate: "05-02-2023",
          status: "นัดสัมภาษณ์",
          jobmatching: 0
        },
        {
          position: "Back-end developer",
          corporation: "ERX co,th",
          registerdate: "05-03-2023",
          status: "กำลังพิจารณา",
          jobmatching: 0
        },
        {
          position: "Developer",
          corporation: "ABCF co,th",
          registerdate: "05-07-2023",
          status: "รับเข้าทำงาน",
          jobmatching: 1
        },
        {
          position: "Programmer",
          corporation: "AGX co,th",
          registerdate: "05-16-2023",
          status: "ปฏิเสธการรับเข้าทำงาน",
          jobmatching: 2
        }
      ],
      jobposting_data_table_headers: [
        { text: "ตำแหน่งงาน	", value: "position" },
        { text: "บริษัท", value: "corporation" },
        { text: "วันที่สมัคร", value: "registerdate" },
        { text: "สถานะ", value: "status" },
        { text: "การจับคู่งาน", value: "jobmatching" },
        { text: "", value: "action" }
      ],
      dialog:false,
      selectedjobposting:[]
    }
  },
  methods: {
    onclickdeleteconfirm()
    {
        this.Index = this.jobpostings.indexOf(this.selectedjobposting)
        this.jobpostings.splice(this.Index, 1)
        this.dialog = false
    }
  },
  async created() {}
}
</script>

<style></style>
