import { render, staticRenderFns } from "./BtnVerifyEmployerDBD.vue?vue&type=template&id=33e9c1f9"
import script from "./BtnVerifyEmployerDBD.vue?vue&type=script&lang=js"
export * from "./BtnVerifyEmployerDBD.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports