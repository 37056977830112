export const LocalStorageKey_Schools = "Schools";
export const LocalStorageKey_SalaryRages = "SalaryRages";
export const LocalStorageKey_EmployerProfileTypes = "EmployerProfileTypes";
export const LocalStorageKey_EmployerMainBusinessTypes = "EmployerMainBusinessTypes";
export const LocalStorageKey_EmployerProfileStatuses = "EmployerProfileStatuses";
export const LocalStorageKey_EmploymentStatusNames = "EmploymentStatusNames";
export const LocalStorageKey_ContinueToStudyInRelatedFieldStatusNames = "ContinueToStudyInRelatedFieldStatusNames";
export const LocalStorageKey_StudyCourseTypeNames  = "StudyCourseTypeNames";
export const LocalStorageKey_JobThatRelatedWithStudiedFieldStatusNames = "JobThatRelatedWithStudiedFieldStatusNames";
export const LocalStorageKey_Provinces = "Provinces";
export const LocalStorageKey_TitleNames = "TitleNames";
export const LocalStorageKey_Nationalities = "Nationalitiesl";
export const LocalStorageKey_Religions = "Religions";
export const LocalStorageKey_JobSegments = "JobSegments";
export const LocalStorageKey_EmploymentCategories = "EmploymentCategories";
export const LocalStorageKey_Genders = "Genders";
export const LocalStorageKey_PayRates = "PayRates";
export const LocalStorageKey_PayFrequencies = "PayFrequencies";
export const LocalStorageKey_CertificateTypes = "CertificateTypes";
export const LocalStorageKey_JobApplicationEntityStatuses = "JobApplicationEntityStatuses";
