<template>
  <v-container class="mt-3 mb-3">
    <v-row class="justify-center">
      <v-col cols="8">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="pa-4 mt-5">
            <div class="pa-3">
              <v-card-text>
              <v-row>
                <v-col cols="12" class="d-flex justify-start">
                  <h3 class="primary--text">
                    <v-icon class="primary--text">mdi-account</v-icon>
                    ข้อมูลบุคลากรสถานศึกษา
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="d-flex justify-center">
                    <div>
                      <v-avatar color="grey" size="150">
                        <v-img :src="avatarphoto"></v-img>
                      </v-avatar>
                    </div>
                  </div>
                </v-col>

                <v-col class="d-flex align-center" cols="6">
                  <v-file-input
                    accept="image/*"
                    label="รูปภาพ"
                    prepend-inner-icon="mdi-image-plus"
                    chips
                    color="pink"
                    v-model="file"
                    @change="UploadFile"
                    filled
                    rounded
                    dense
                  >
                  </v-file-input>
                </v-col>
              </v-row>
              <!-- <v-row class="d-flex align-end">
                <v-col cols="6" class="d-flex justify-center">
                  <v-radio-group
                    :rules="[(v) => !!v || 'กรุณาระบุเพศ']"
                    v-model="vm.gender"
                    row
                  >
                    <template v-slot:label>
                      <div><strong>เพศ</strong></div>
                    </template>
                    <v-radio label="ชาย" color="blue" value="male"></v-radio>
                    <v-radio label="หญิง" color="pink" value="female"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">คำนำหน้าชื่อ</h4>

                  <v-autocomplete
                    background-color="#F2F4F8"
                    filled
                    v-model="vm.titlenameid"
                    :items="titlenamelist"
                    :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                    label="เลือกคำนำหน้าชื่อ"
                    item-text="titlenameth"
                    item-value="titlenameid"
                    required
                  ></v-autocomplete>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">ชื่อ</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    label="ชื่อ"
                    required
                    :rules="nameRules"
                    v-model="vm.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">นามสกุล</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    label="นามสกุล"
                    required
                    :rules="nameRules"
                    v-model="vm.lastname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">ตำแหน่ง</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    label="ตำแหน่ง"
                    required
                    :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                    v-model="vm.position"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">เลขประจำตัวประชาชน</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    required
                    :rules="idcardRules"
                    readonly
                    label="เลขประจำตัวประชาชน"
                    v-model="vm.idcard"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">อีเมล</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    label="อีเมล"
                    ref="txtEmail"
                    required
                    :rules="custom_emailRules"
                    v-model="vm.email"
                    @input="checkEmailEdited"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">หมายเลขโทรศัพท์</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    label="หมายเลขโทรศัพท์"
                    :rules="phoneRules"
                    v-model="vm.phonenumber"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
              <v-col col="12">
                <v-text-field label="สถานะ" v-model="vm.isactive"></v-text-field>
              </v-col>
            </v-row> -->
          </v-card-text></div>
          </v-card>
          <v-card class="mt-4 pa-4">
            <div class="pa-3">
            <v-card-text>
              <v-row>
                <v-col cols="12" class="d-flex justify-start">
                  <h3 class="primary--text">
                    <v-icon class="primary--text">mdi-account</v-icon>
                    เกี่ยวกับการเข้าใช้งาน
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" >
                  <h4 class="primary--text mb-2">ชื่อผู้ใช้งาน (username)</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    v-if="schoolemployeeprofile"
                    readonly
                    class="mr-2"
                    label="ชื่อผู้ใช้งาน (username)"
                    v-model="schoolemployeeprofile.applicationuser.username"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text></div>
          </v-card>
          <v-row class="justify-center text-center mt-4 mb-4">
            <v-col cols="12">
              <v-btn
                color=""
                @click="$router.go(-1)"
                class="ma-2 justify-center"
              >
                ย้อนกลับ</v-btn
              >
              <v-btn color="color-buton" @click="save">
                 บันทึก<v-icon>mdi-content-save</v-icon>
              </v-btn>
              
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  phoneRules,
  emailRules,
  idcardRules,
  nameRules,
} from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import { CreateUpdateSchoolEmployeeProfile_Request } from "@/models/Profile/SchoolEmployeeProfile/CreateUpdateSchoolEmployeeProfile_Request";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import ApplicationUserRoleManagement from "@/components/ApplicationUser/ApplicationUserRoleManagement.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable, ApplicationUserRoleManagement },
  name: "SchoolEmployeeProfileDetailView",
  data() {
    return {
      oid: "",
      schoolemployeeprofile: undefined,
      vm: new CreateUpdateSchoolEmployeeProfile_Request(),
      titlenamelist: [],
      schoolEmployeeProfileGroupTypes: undefined,
      schoolEmployeeProfile_SchoolRights: [],
      schoolEmployeeProfile_SchoolRights_table_headers: [
        { text: "สถานศึกษา", value: "school.schoolname" },
        {
          text: "จังหวัด",
          value: "school.tambon.district.province.provincethai",
        },
        { text: "", value: "action" , sortable: false},
      ],
      schools: undefined,
      selected_schoolCode: undefined,
      file: [],
      ImgSchoolEmployeeformData: new FormData(),
      valid: true,
      phoneRules: phoneRules,
      emailRules: emailRules,
      idcardRules: idcardRules,
      nameRules: nameRules,
      emailEdited: false, // เพิ่มตัวแปรสำหรับเก็บสถานะการแก้ไขอีเมล
      originalEmail: "", // เพิ่มตัวแปรสำหรับเก็บค่าเดิมของอีเมล
    };
  },
  computed: {
    avatarphoto() {
      return this.vm.userphoto_base64string
        ? "data:image/jpeg;base64," + this.vm.userphoto_base64string
        : require("@/assets/user-img.jpg");
    },
    custom_emailRules() {
      return [
        ...emailRules,
        (v) =>
          !this.emailEdited ||
          (!this.emailExistResult ? true : "อีเมลนี้ถูกใช้งานแล้ว"), // กฎเพิ่มเติมสำหรับการตรวจสอบความมีอยู่ของอีเมล ตรวจสอบเมื่อมีการแก้ไขอีเมลเท่านั้น
      ];
    },
  },
  methods: {
    async initilize() {
      const request = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(request).then((result) => {
        this.schools = result.data;
      });

      await this.loadTitleName();
    },
    async loadTitleName() {
      await masterDataProvider
        .getActiveTitleNames()
        .then((response) => {
          this.titlenamelist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async getSchoolEmployeeProfile() {
      await schoolEmployeeProfileDataProvider
        .getSchoolEmployeeProfile(this.oid)
        .then((result) => {
          this.schoolemployeeprofile = result.data;
          this.vm = CreateUpdateSchoolEmployeeProfile_Request.fromJson(result.data);
          // กำหนดค่าเดิมของอีเมล
          this.originalEmail = this.vm.email;
        });
    },

    async save() {
      if (this.$refs.form.validate()) {
        if (confirm("ต้องการบันทึกข้อมูลหรือไม่ ?")) {
          this.emailEdited = true; // เมื่อมีการแก้ไขอีเมล
          await schoolEmployeeProfileDataProvider
            .updateSchoolEmployeeProfile(this.vm)
            .then((result) => {
              this.$store.dispatch({ type: "doSetIsLoadingProfile" });
              this.$toast.success("บันทึกข้อมูลเรียบร้อย");
            });
        }
      }
    },

    onSelectSchool() {
      if (this.vm.selected_schoolcode != undefined) {
        const selectedSchool = this.schools.find(
          (x) => x.schoolid == this.vm.selected_schoolcode
        );
        this.vm.school = selectedSchool;
      }
    },

    async uploadprofileschoolemployee() {
      await schoolEmployeeProfileDataProvider
        .uploadSchoolEmployeeProfile(this.ImgSchoolEmployeeformData)
        .then((result) => {
          this.$toast.success("บันทึกข้อมูลเรียบร้อย");
        })
        .catch((result) => {
          this.$toast.success("ล้มเหลว!");
        });
    },
    async UploadFile() {
      this.ImgSchoolEmployeeformData.append("File", this.file);
      this.ImgSchoolEmployeeformData.append("oid", this.oid);
      var fileToLoad = this.file;
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        this.uploadprofileschoolemployee();
        var strImage = data.replace(/^data:image\/[a-z]+;base64,/, "");
        this.vm.userphoto_base64string = strImage;
      });
    },
    checkEmailEdited() {
      this.emailEdited = this.vm.email !== this.originalEmail;
    },
  },
  watch: {
    "vm.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isEmailUsed(newVal)
            .then((result) => {
              this.emailExistResult = result.data;
              this.$refs.txtEmail.validate();
            });
        }
      },
      deep: true,
    },
  },
  async created() {
    if (this.$route.params.oid != undefined) {
      this.oid = this.$route.params.oid;
    }
    await this.initilize();
    await this.getSchoolEmployeeProfile();
  },
};
</script>

<style scoped>

.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  color: white !important;
}
</style>
