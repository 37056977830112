<template>
  <v-container class="pa-4 ma-2">
    <!-- Selection  -->
    <div>
      <v-row class="optionSelectRow">
        <v-col cols="12">
          <h4>แสดงภาพรวม</h4>
        </v-col>
        <v-col cols="6">
          <v-select label="ปีการศึกษา" :items="YearTHChoices" v-model="SelectedYearTH"
            v-on:change="onYearTHChoiceChange"></v-select>
        </v-col>
        <v-col cols="6">
          <v-select label="เทอม" :items="SemesterChoices" v-model="SelectedSemester"
            v-on:change="onSemesterChoiceChange"></v-select>
        </v-col>
      </v-row>
    </div>

    <v-row class="">
      <v-col cols="6">
        <div class="chartStyle">
          <canvas id="chart1"> </canvas>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="chartStyle">
          <canvas id="chart3"></canvas>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="chartStyle">
          <canvas id="chart2"></canvas>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { DashboardDataSourceProvider } from "@/services/DashboardDataSourceProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request";
import { GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource_Request";
import { GetGraduatedStudentJobTrackingReport_EmploymentStatusAsRegionDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTracking_EmploymentStatusAsRegionDashboardDataSource_Request";
import { GetGraduatedStudent_DashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudent_DashboardDataSource_Request";
import { default_colors } from "@/services/ColorService";
import { EmploymentStatusCodes, RegionTHNames } from "@/Constants";
//import Chart from "chart.js";
import Chart from "chart.js/auto";
var dashboardDataSourceProvider = new DashboardDataSourceProvider(process.env.VUE_APP_BACKEND_API_URL);
var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  name: "DashboardView_SchoolStaff",
  data() {
    return {
      targetSchoolID: "",
      targetEmploymentStatusCodes: undefined,
      EmploymentStatusNames: undefined,
      RegionTHNames: undefined,
      YearTHChoices: undefined,
      SelectedYearTH: "",
      SemesterChoices: undefined,
      SelectedSemester: "",
      EmploymentStatusDashboardDataSource_chartData: undefined,
      chart1: null,
      GraduatedStudentDashboardDataSource_chartData: undefined,
      chart2: null,
    };
  },
  methods: {
    async initialize() {
      this.targetEmploymentStatusCodes = [];
      this.targetEmploymentStatusCodes.push(
        EmploymentStatusCodes.Study,
        EmploymentStatusCodes.WorkForCompany,
        EmploymentStatusCodes.Government,
        EmploymentStatusCodes.Freelance
      );

      await commonDataProvider
        .getEmploymentStatusNameByCodes(this.targetEmploymentStatusCodes)
        .then((result) => {
          this.EmploymentStatusNames = result.data;
        });
    },

    async loadYearTHChoices() {
      await commonDataProvider.getListOfYearTH().then((result) => {
        this.YearTHChoices = result.data;
      });
    },
    async loadSemesterChoices() {
      await commonDataProvider.getListOfSemesters().then((result) => {
        this.SemesterChoices = result.data;
      });
    },

    async loadRegionTHNames() {
      await commonDataProvider.getRegionTHNames().then((result) => {
        this.RegionTHNames = result.data;
      });
    },

    async onYearTHChoiceChange() {
      await this.loadGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource();
      await this.loadGraduatedStudent_DashboardDataSource();
    },
    async onSemesterChoiceChange() {
      await this.loadGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource();
      await this.loadGraduatedStudent_DashboardDataSource();
    },
    async loadGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource() {
      const request =
        new GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request();
      request.YearTH = this.SelectedYearTH;
      request.Semester = this.SelectedSemester;
      request.TargetEmploymentStatusCodes = this.targetEmploymentStatusCodes;
      request.SchoolID = this.targetSchoolID;
      await dashboardDataSourceProvider
        .GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource(
          request
        )
        .then((result) => {
          this.EmploymentStatusDashboardDataSource_chartData = {
            labels: result.data.labels,
            datasets: [
              { data: result.data.data, backgroundColor: default_colors },
            ],
          };
        });

      const ctx = document.getElementById("chart1");
      if (this.chart1) {
        this.chart1.destroy();
      }
      if (this.chart1 != null) {
        this.chart1.destroy();
      }

      this.chart1 = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: this.EmploymentStatusNames,
          datasets: this.EmploymentStatusDashboardDataSource_chartData.datasets,
        },
        options: {
          plugins: {
            title: { display: true, text: 'สถานะการได้งานทำ' }
          },
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateRotate: false,
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        },
      });

      this.chart1.update();
    },

    async loadGraduatedStudent_DashboardDataSource() {
      const request = new GetGraduatedStudent_DashboardDataSource_Request();
      request.YearTH = this.SelectedYearTH;
      request.Semester = this.SelectedSemester;
      request.SchoolID = this.targetSchoolID;

      await dashboardDataSourceProvider
        .GetGraduatedStudent_DashboardDataSource(request)
        .then((result) => {
          this.GraduatedStudentDashboardDataSource_chartData = {
            labels: result.data.labels,
            datasets: [
              { data: result.data.data, backgroundColor: default_colors },
            ],
          };
        });

      const ctx = document.getElementById("chart2");
      if (this.chart2) {
        this.chart2.destroy();
      }
      if (this.chart2 != null) {
        this.chart2.destroy();
      }

      this.chart2 = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.GraduatedStudentDashboardDataSource_chartData.labels,
          datasets: this.GraduatedStudentDashboardDataSource_chartData.datasets,
        },
        options: {
          plugins: {
            title: { display: true, text: 'นักศึกษาที่จบการศึกษา' }
          },
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateRotate: false,
          },
        },
      });

      this.chart2.update();
    },
  },

  async created() {
    this.targetSchoolID = "";

    await this.initialize();
    await this.loadRegionTHNames();
    await this.loadYearTHChoices();
    await this.loadSemesterChoices();
    await this.loadGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource();
    await this.loadGraduatedStudent_DashboardDataSource();
  },
};
</script>

<style src="../../assets/css/dashboard/dashboardStyle.css">

</style>