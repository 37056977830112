<template>
  <v-card class="mt-5">
    <v-data-table
      class="mt-2 custom-header-gradient white-label"
      v-bind="$attrs"
      v-on="$listeners"
      :items="data_items"
      :headers="datatable_headers"
      :loading="data_items == undefined"
      loading-text="Loading"
      :server-items-length="itemlength"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions,
      }"
      @update:items-per-page="itemPerpageChange"
      @update:options.sync="optionsChanged"
      dense
    >
      <!-- pass through scoped slots -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData"></slot>
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName"></slot>
      </template>
      <template v-slot:top>
        <div class="pb-lg-5 pt-lg-3">
          <v-toolbar
            :height="$vuetify.breakpoint.smAndDown ? '140px' : '80px'"
            flat
            dense
          >
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="12" class="">
                <h3 class="primary--text">{{ local_table_caption }}</h3>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="">
                <v-text-field
                  v-model="local_search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  color="info"
                  class="white-label"
                  @keyup="onKeywordChanged"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "SearchableDataTable",
  props: [
    "datatable_headers",
    "data_items",
    "table_caption",
    "itemlength",
    "search",
  ],
  data() {
    return {
      local_table_caption: this.table_caption,
      local_datatable_headers: this.datatable_headers,
      local_data_items: this.data_items,
      local_data_itemlength: this.itemlength,
      local_search: this.search,
      options: {
        page: 1,
        sortBy: [],
        sortDesc: [],
        itemsPerPage: 10,
        mustSort: false,
        multiSort: false,
        groupDesc: true,
        groupBy: [],
      },
      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
      debouncedSearch: "",
      debounceTimeout: null,
    };
  },
  watch: {
    options: {
      handler(newValue, oldValue) {
        this.$emit("tableOptionsChanged", { ...newValue });
      },
      deep: true,
    },
    "options.page": {
      handler(newValue, oldValue) {
        this.$emit("pageChanged", newValue);
      },
      deep: true,
    },
  },
  methods: {
    onKeywordChanged() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.$emit("onkeywordchanged", this.local_search);
      }, 1000);
    },
    updateSearch() {
      this.$emit("update:search", this.local_search);
    },
    itemPerpageChange(newItemsPerPage) {
      this.$emit("itemperpage", newItemsPerPage);
    },
  },
  mounted() {
    this.local_table_caption = this.table_caption;
    this.local_data_items = this.data_items;
    this.local_datatable_headers = this.datatable_headers;
    this.local_data_itemlength = this.itemlength;
    this.local_search = this.search;
  },
};
</script>

<style scoped>
.custom-header-gradient .v-data-table-header {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.custom-header-gradient th {
  color: white !important;
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>
