<template>
  <div class="d-flex justify-center">
    <v-container>
      <v-row class="d-flex justify-center mt-2 mb-2">
        <v-col cols="12" lg="8">
          <h3 class="primary--text mb-2">ตั้งค่าเกี่ยวกับอีเมล</h3>
          <v-divider :thickness="3" class="border-opacity-75"> </v-divider>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col lg="8" md="12" sm="12" xs="12">
          <v-row class="align-center">
            <v-col cols="6" lg="6" class="pb-0">
              <h4 class="primary--text mb-1">ทดสอบส่งอีเมล</h4>
              <v-text-field
                ref="testEmailField"
                background-color="input"
                filled
                clearable
                :rules="emailRules"
                label="ระบุ email"
                v-model="sendEmail"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="1" lg="1" class="mt-4">
              <v-btn color="primary" @click="onSend()" outlined block>
                <v-icon class="pr-2">mdi-email</v-icon>send
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-form v-if="request" ref="form" v-model="valid">
        <v-row class="d-flex justify-center mt-0 pt-0">
          <v-col lg="8" md="12" sm="12" xs="12">
            <v-card class="mt-5">
              <v-toolbar class="color-HeaderCard" height="50px" flat dark>
                <v-toolbar-title
                  ><v-icon class="mr-2"> mdi-email </v-icon>Email
                  Configuration</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text>
                <v-row class="pt-4 align-center">
                  <v-col cols="12" lg="6" class="py-0">
                    <h4 class="primary--text mb-1">From Email Address</h4>
                    <v-text-field
                      background-color="input"
                      filled
                      clearable
                      :rules="emailRules"
                      v-model="request.fromEmailAddress"
                      label="From Email Address"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-0">
                    <v-checkbox
                      label="SMTPAuthen."
                      v-model="request.smtpAuthen"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="12" lg="6" class="py-0">
                    <h4 class="primary--text mb-1">From Email Display Name</h4>
                    <v-text-field
                      background-color="input"
                      filled
                      clearable
                      label="From Email Display Name"
                      v-model="request.fromEmailDisplayName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-0">
                    <v-checkbox
                      label="SMTPSSL"
                      v-model="request.smtpSSL"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" class="py-0">
                    <h4 class="primary--text mb-1">SMTPHost</h4>
                    <v-text-field
                      background-color="input"
                      filled
                      clearable
                      label="SMTPHost"
                      v-model="request.smtpHost"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-0">
                    <h4 class="primary--text mb-1">SMTPUser</h4>
                    <v-text-field
                      background-color="input"
                      filled
                      clearable
                      label="SMTPUser"
                      v-model="request.smtpUser"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" class="py-0">
                    <h4 class="primary--text mb-1">SMTPPost</h4>
                    <v-text-field
                      background-color="input"
                      filled
                      clearable
                      label="SMTPPost"
                      v-model="request.smtpPort"
                      :rules="[(v) => !!v || 'กรุณาระบุ SMTPPost']"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-0">
                    <h4 class="primary--text mb-1">SMTPPassword</h4>
                    <v-text-field
                      background-color="input"
                      filled
                      clearable
                      label="SMTPPassword"
                      v-model="request.smtpPassword"
                      :append-icon="
                        isShowPassword ? 'visibility' : 'visibility_off'
                      "
                      @click:append="isShowPassword = !isShowPassword"
                      :type="isShowPassword ? 'text' : 'password'"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="justify-center text-center mt-4 mb-4">
            <v-btn
              color=""
              @click="$router.push({ name: 'EmployerProfileListView' })"
              class="ma-2 justify-center"
            >
              ย้อนกลับ</v-btn
            >
            <v-btn
              class="ma-2 color-button2"
              style="color: white"
              @click="onSave()"
              >บันทึก <v-icon color="white"> mdi-content-save </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { emailRules } from "@/ValidationHelper.js";
import { EmailConfigurationDataProvider } from "@/services/EmailConfigurationDataProvider";
import { SaveEmailConfigurationRequest } from "@/models/EmailConfigurations/SaveEmailConfigurationRequest";

const emailConfigurationDataProvider = new EmailConfigurationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "EmailConfigurationDetailView",
  data() {
    return {
      isShowPassword: false,
      request: new SaveEmailConfigurationRequest(),
      sendEmail: "",
      emailRules: emailRules,
      valid: true,
    };
  },

  methods: {
    async getCurrentEmailConfiguration() {
      await emailConfigurationDataProvider
        .getCurrentEmailConfiguration()
        .then((result) => {
          this.request = SaveEmailConfigurationRequest.fromJson(result.data);
        });
    },
    async onSave() {
      if (this.$refs.form.validate()) {
        if (confirm("ยืนยัน")) {
          await emailConfigurationDataProvider
            .saveEmailConfiguration(this.request)
            .then((result) => {
              this.$toast.success("บันทึกตั้งค่าเกี่ยวกับอีเมลเรียบร้อย");
            });
        }
      } else {
        this.$toast.error("กรุณากรอกข้อมูลให้ถูกต้อง");
      }
    },
    async onSend() {
  // ตรวจสอบการกรอกข้อมูลในฟอร์มทั้งหมดก่อน
  if (this.$refs.form.validate()) {
    // ตรวจสอบเฉพาะฟิลด์ testEmailField ว่าถูกต้องหรือไม่
    if (this.$refs.testEmailField.validate()) {
      if (confirm("ยืนยัน")) {
        this.$store.dispatch("showLoading");
        await emailConfigurationDataProvider
          .send_TestEmail(this.sendEmail)
          .then((result) => {
            if (result.data == true) {
              this.$toast.success("ส่งอีเมลเรียบร้อย");
            }
          })
          .catch((result) => {
            this.$toast.error("ส่งอีเมลล้มเหลว!" + result);
          })
          .finally((result) => {
            this.$store.dispatch("hideLoading");
          });
      }
    } else {
      // ถ้า testEmailField ไม่ถูกต้อง
      this.$toast.error("กรุณาระบุอีเมลสำหรับการทดสอบ");
    }
  } else {
    // ถ้าฟอร์มทั้งหมดไม่ผ่านการตรวจสอบ
    this.$toast.error("กรุณากรอกข้อมูลให้ถูกต้อง");
  }
}

  },
  async created() {
    await this.getCurrentEmailConfiguration();
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 964px) {
  .row-two {
    flex-basis: 100%;
    max-width: 50%;
  }
}
</style>
