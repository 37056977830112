<template>
    <label><v-icon :color="dynamicColor">{{ dynamicIcon }}</v-icon> {{ dynamicStatusName }}</label>
</template>

<script>
export default {
    name: 'JostPostingStatusLabel',
    props: ['JobPosting'],
    data() {
        return {

        }
    },
    computed: {
        dynamicIcon() {
            switch (this.JobPosting.jobpostingstatus.statuscode) {
                case 'Draft':
                    return 'mdi-file-document-outline';
                case 'Active':
                    return 'mdi-progress-clock';
                case 'OnHold':
                    return 'mdi-stop-circle';
                case 'Expired':
                    return 'mdi-timer-sand-complete'
                case 'Cancelled':
                    return 'mdi-cancel'
                case 'Closed':
                    return 'mdi-check-decagram-outline'
                default:
                    return '';
            }
        },
        dynamicColor() {
            switch (this.JobPosting.jobpostingstatus.statuscode) {
                case 'Draft':
                    return 'grey';
                case 'Active':
                    return 'green';
                case 'OnHold':
                    return 'grey';
                case 'Expired':
                    return 'red'
                case 'Cancelled':
                    return 'orange'
                case 'Closed':
                    return 'green'
                default:
                    return '';
            }
        },
        dynamicStatusName() {
            return this.JobPosting.jobpostingstatus.statusname;
        },
    },
    methods: {

    },
    async created() {

    }
}
</script>

<style></style>