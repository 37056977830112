<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
      >
        <v-row class="d-flex justify-center">
          <v-col lg="10" md="12" sm="12" xs="12">
            <v-card>
              <v-toolbar class="color-HeaderCard" height="50px" flat dark>
                <v-toolbar-title
                  ><v-icon class="mr-2"> mdi-account-circle </v-icon
                  >เพิ่มข้อมูลสถานประกอบการ</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text class="d-flex justify-center">
                <v-col cols="10" class="mt-0">
                  <v-row class="d-flex align-center justify-space-between">
                    <v-col lg="9" md="6" sm="12" xs="12">
                      <h4 class="primary--text mb-2">เลขประจำตัวผู้เสียภาษี</h4>

                      <v-text-field
                        ref="txtIdentityid"
                        label="เลขประจำตัวผู้เสียภาษี"
                        v-model="formData.identityid"
                        :rules="custom_identityidRules"
                        :disabled="formData.dontknowidentityid"
                        background-color="input"
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col
                      lg="3"
                      md="6"
                      sm="12"
                      xs="12"
                      class="d-flex justify-end"
                    >
                      <v-btn class="mx-3" outlined @click="fetchData"
                        >ดึงข้อมูลจาก
                        <v-img
                          alt="VEC Logo"
                          class="shrink ml-2"
                          contain
                          src="@/assets/DBDlogo.png"
                          transition="scale-transition"
                          width="40"
                      /></v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="12" class="my-0 pt-0">
                      <v-checkbox
                        label="ไม่มีเลขทะเบียนนิติบุคคล/เลขผู้เสียภาษี"
                        v-model="formData.dontknowidentityid"
                        @change="ClearIdentityID"
                      ></v-checkbox>
                      <div style="padding-left: 33px">
                        <label class="v-label"
                          >*ตัวเลือกนี้ควรเลือกใช้เฉพาะในกรณีที่ไม่สามารถระบุ
                          "เลขทะเบียนนิติบุคคล/เลขผู้เสียภาษี" ได้จริง ๆ
                          เท่านั้น</label
                        >
                      </div></v-col
                    >
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="12" class="my-0 py-0">
                      <h4 class="primary--text mb-2">ชื่อสถานประกอบการ</h4>

                      <v-text-field
                        ref="txtEmployername"
                        label="ชื่อสถานประกอบการ"
                        v-model="formData.nameth"
                        :rules="custom_employername"
                        background-color="input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12" class="my-0 py-0">
                      <h4 class="primary--text mb-2">ที่อยู่</h4>
                      <v-text-field
                        label="ที่อยู่"
                        v-model="formData.address"
                        :rules="[(v) => !!v || 'กรุณากรอกที่อยู่สถานประกอบการ']"
                        background-color="input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12" class="my-0 py-0">
                      <h4 class="primary--text mb-2">จังหวัด</h4>
                      <v-autocomplete
                        label="จังหวัด"
                        :items="provinces"
                        item-text="provincethai"
                        item-value="provinceid"
                        v-model="formData.selected_province_id"
                        background-color="input"
                        filled
                        required
                        append-icon="mdi-chevron-down"
                        :rules="[(v) => !!v || 'กรุณาเลือกจังหวัด']"
                      ></v-autocomplete
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-btn @click="$router.go(-1)" color="" class="mx-3">
                        <span class="mx-3">ยกเลิก</span>
                      </v-btn>
                      <v-btn
                        @click="onSubmit"
                        color="secondary"
                        class="mx-3 color-buton"
                      >
                        บันทึก<v-icon class="ml-2"
                          >mdi-content-save</v-icon
                        ></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>

            <v-row class="my-5 justify-end"> </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { identityidRules } from "@/ValidationHelper.js";
import { CreateEmployerSummaryRequest } from "@/models/EmployerSummaries/CreateEmployerSummaryRequest";
import { EmployerSummaryDataProvider } from "@/services/EmployerSummaryDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { EmployerExternalApiDataProvider } from "@/services/EmployerExternalApiDataProvider";
const employerExternalApiDataProvider = new EmployerExternalApiDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerSummaryDataProvider = new EmployerSummaryDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "CreateEmployerSummaryView",
  data() {
    return {
      valid: false,
      provinces: undefined,
      formData: new CreateEmployerSummaryRequest(),
      identityidRules: identityidRules,
      identityidExistingResult: false,
      originalidentityid: "",
      checkidentityid: false,
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
    },
    async onSubmit() {
      console.log(this.formData);
      if (this.$refs.form.validate()) {
        if (confirm("ต้องการบันทึกข้อมูลหรือไม่ ?")) {
          console.log(this.formData);
          await employerSummaryDataProvider
            .createEmployerSummary(this.formData)
            .then((result) => {
              this.$toast.success("เพิ่มข้อมูลสถานประกอบการเรียบร้อย");
              this.$router.back();
            })
            .catch((error) => {
              console.log(error);
              this.$toast.error(error.response.data);
            });
        }
      }
    },
    async isCheck() {
      this.formData.dontknowidentityid = false;
    },
    ClearIdentityID() {
      if (this.formData.dontknowidentityid) {
        this.formData.identityid = "";
      }
    },
    assignDataFromExtenalAPI(fetchedData) {
      console.log(fetchedData);

      const juristicPerson =
        fetchedData.data[0]["cdOrganizationJuristicPerson"];
      const address =
        juristicPerson["cdOrganizationJuristicAddress"]["crAddressType"];

      this.formData.nameth = juristicPerson["cdOrganizationJuristicNameTH"];

      this.formData.address =
        address["cdAddressNo"] +
        " หมู่ที่" +
        address["cdMoo"] +
        " ถนน" +
        address["cdRoad"] +
        " ซอย" +
        address["cdSoi"];

      this.provinceText =
        address["cdCountrySubDivision"]["crCountrySubDivisionTextTH"];

      if (this.provinceText) {
        const province = this.provinces.find(
          (p) => p.provincethai === this.provinceText
        );
        if (province) {
          this.selectedProvinceID = province.provinceid;
          this.formData.selected_province_id = this.selectedProvinceID;
          console.log(this.selectedProvinceID);
        }
      }
    },
    // async fetchData() {
    //   console.log(this.formData.identityid);

    //   if (!this.formData.identityid || this.formData.identityid.trim() === "") {
    //     this.$toast.error("กรุณากรอกเลขประจำตัวผู้เสียภาษี");
    //     return;
    //   }

    //   try {
    //     // เรียกข้อมูล
    //     const result =
    //       await employerSummaryDataProvider.getPublicEmployerInformationByJuristicID(
    //         this.formData.identityid
    //       );

    //     // ตรวจสอบข้อมูล
    //     if (result?.status?.code === "1000" && result?.data?.length > 0) {
    //       this.assignDataFromExtenalAPI(result.data[0]);
    //       this.$toast.success("ดึงข้อมูลจาก DBD สำเร็จ");
    //     } else {
    //       console.warn("ข้อมูลที่ได้ไม่ถูกต้องหรือไม่มีข้อมูล:", result);
    //       this.$toast.error("ไม่พบข้อมูลในระบบ");
    //     }
    //   } catch (err) {
    //     console.error("Error fetching data:", err);
    //     this.$toast.error("เกิดข้อผิดพลาดในการดึงข้อมูล");
    //   }
    // },

    async fetchData() {
      console.log(this.formData.identityid);
      if (this.formData.identityid != "") {
        await employerSummaryDataProvider
          .getPublicEmployerInformationByJuristicID(this.formData.identityid)
          .then((result) => {
            this.assignDataFromExtenalAPI(result.data);
            this.$toast.success("ดึงข้อมูลจาก dbd");
          })
          .catch((err) => {
            console.error("Error fetching data:", err);
            this.$toast.error("ไม่พบข้อมูลในระบบ");
            // const result = employerExternalApiDataProvider.getTestEmployerData();
            // this.assignDataFromExtenalAPI(result);
          });
      } else {
        this.$toast.error("กรุณากรอกเลขประจำตัวผู้เสียภาษี");
      }
    },
  },
  computed: {
    custom_identityidRules() {
      return [
        (v) =>
          !!v ||
          this.formData.dontknowidentityid ||
          "กรุณาระบุเลขประจำตัวผู้เสียภาษี",
        (v) =>
          !this.identityidExistingResult ||
          "เลขประจำตัวผู้เสียภาษีนี้ถูกใช้งานแล้ว",
      ];
    },
    custom_employername() {
      return [
        (v) => !!v || "กรุณากรอกชื่อสถานประกอบการ",
        (v) => !v.startsWith("-") || "กรุณากรอกเป็นตัวอักษร",
        (v) => !v.startsWith(".") || "กรุณากรอกเป็นตัวอักษร",
        (v) => v.length >= 3 || "ชื่อสถานประกอบการต้องมีอย่างน้อย 3 ตัวอักษร",
        (v) =>
          this.employernameExistResult === false ||
          "ชื่อสถานประกอบการนี้ถูกใช้งานแล้ว",
      ];
    },
  },
  watch: {
    "formData.identityid": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await employerSummaryDataProvider
            .IsIdentityIDUsed(newVal)
            .then((result) => {
              this.identityidExistingResult = result.data;
              console.log(this.identityidExistingResult);
              this.$refs.txtIdentityid.validate();
            });
        }
      },
      deep: true,
    },
    "formData.nameth": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await employerSummaryDataProvider
            .isEmployerSummaryUsed(newVal)
            .then((result) => {
              this.employernameExistResult = result.data;
              this.$refs.txtEmployername.validate();
            });
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
