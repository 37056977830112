import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
export class CommonDataProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }

  getListOfYearTH() {
    const header = create_default_request_header();
    var endpointPath = "/api/common/getlistofyearth";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, header);
  }

  getListOfSemesters() {
    const header = create_default_request_header();
    var endpointPath = "/api/common/getlistofsemesters/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, header);
  }

  getQuantityOfSchool() {
    const header = create_default_request_header();
    var endpointPath = "/api/common/getquantityofschool/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, header);
  }

  getQuantityOfStudent(yearth = "", semesters = "") {
    const header = create_default_request_header();
    var endpointPath =
      "/api/common/GetQuantityOfGraduatedStudent/" + yearth + "/" + semesters;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, header);
  }

  getRegionTHNames() {
    const header = create_default_request_header();
    var endpointPath = "/api/common/GetRegionNameTHs/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getRegionAsVECNames() {
    const header = create_default_request_header();
    var endpointPath = "/api/common/GetRegionAsVECNames/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getEmploymentStatusNameByCodes(codes) {
    const header = create_default_request_header();
    var endpointPath = "/api/common/GetEmploymentStatusNameByCodes/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, codes, header);
  }

  getJobTrackingReportStatusNameByCodes(codes) {
    const header = create_default_request_header();
    var endpointPath = "/api/common/GetJobTrackingReportStatusNameByCodes/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, codes, header);
  }

  getMainGradeTHNames() {
    const header = create_default_request_header();
    var endpointPath = "/api/common/GetMainGradeTHNames/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, header);
  }

  getNextNumberOfRound(targetGraduatedYearTH) {
    const header = create_default_request_header();
    var endpointPath =
      "/api/common/getNextNumberOfRound/" + targetGraduatedYearTH;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, header);
  }

  loadListOfGraduatedYearFromJobTrackingReport() {
    const header = create_default_request_header();
    var endpointPath =
      "/api/common/GetListOfGraduatedYearFromJobTrackingReport";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }
  loadListOfJobTrackingRoundFromGraduatedYearTH(targetGraduatedYearTH) {
    const header = create_default_request_header();
    var endpointPath =
      "/api/common/GetListOfJobTrackingRoundFromGraduatedYearTH/" +
      targetGraduatedYearTH;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getStudentTypes() {
    const header = create_default_request_header();
    const endpointPath = "/api/common/getStudentTypes";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getStudentStatuses() {
    const header = create_default_request_header();
    const endpointPath = "/api/common/getStudentStatuses";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, header);
  }

  getCurrentTime() {
    const header = create_default_request_header();
    const endpointPath = "/api/common/GetCurrentTime";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }
}
