<template>
  <div class="pa-7">
    <v-row class="d-flex pa-3 pb-4">
      <h3 class="primary--text">
        <i class="fa fa-line-chart"></i>รายงานสรุปผลการส่งรายงาน รายจังหวัด
      </h3>
    </v-row>

    <v-row>
      <v-card class="col-12">
        <v-card-title>
          <p>เกณฑ์การค้นหา</p>
        </v-card-title>
        <v-divider class="mt-0 mx-0 mb-4"></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="3">
                <v-select
                  hint="ปีที่สำเร็จการศึกษา"
                  :rules="[(v) => !!v || 'กรุณาระบุปีที่สำเร็จการศึกษา']"
                  persistent-hint
                  clearable
                  v-model="selectedYearTH"
                  :items="yearTH_Choices"
                  @change="onYearChanged"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  hint="รอบที่"
                  :rules="[(v) => !!v || 'กรุณาระบุรอบ']"
                  persistent-hint
                  v-model="selectedRound"
                  :items="Round_Choices"
                  clearable
                  :disabled="selectedYearTH ? false : true"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row> </v-row>
    <v-row class="mt-9">
      <v-col cols="12 text-center">
        <v-btn color="warning" v-on:click="create_reportParameters()">แสดงรายงาน</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="document_viewer_dialog" width="40%">
      <template>
        <v-card height="500">
          <ReportExportFromQueue
            :report_name="report_name"
            :report_parameters="report_parameters"
          ></ReportExportFromQueue>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import DocumentViewer from "../../../components/Report/DocumentViewer.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";

import ReportExport from "@/components/Report/ReportExport.vue";
import ReportExportFromQueue from "@/components/Report/ReportExportFromQueue.vue";

var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var majorDataProvider = new MajorDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  name: "GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince_Report_DetailView",
  props: [],
  data() {
    return {
      document_viewer_key: 1,
      document_viewer_dialog: false,
      report_name: "GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince",
      report_parameters: [],
      yearTH_Choices: undefined,
      selectedYearTH: undefined,
      certificateTypes: undefined,
      selectedCertificateTypeCode: "",
      majors: undefined,
      subjecttypes: undefined,
      schooltypelist: [
        { text: "ภาครัฐ/ภาคเอกชน", value: "" },
        { text: "ภาครัฐ", value: "ภาครัฐ" },
        { text: "ภาคเอกชน", value: "ภาคเอกชน" },
      ],
      valid: true,
      Round_Choices: [],
      selectedRound: "",
      selectedSubJectTypeId: "",
      selectedMajor: [],
      selectedmajorId: "",
      selectedSchoolType: "",
    };
  },
  methods: {
    loadStudentMajors() {
      masterDataProvider
        .getStudentMajorsFromSubjectTypeID(this.selectedSubJectTypeId)
        .then((res) => {
          this.selectedMajor = [];
          this.selectedmajorId = "";
          this.majors = res.data;
        });
    },
    onYearChanged() {
      this.loadListOfJobTrackingRoundFromGraduatedYearTH();
    },
    loadListOfGraduatedYearFromJobTrackingReport() {
      commonDataProvider.loadListOfGraduatedYearFromJobTrackingReport().then((resp) => {
        this.yearTH_Choices = resp.data;
      });
    },

    loadListOfJobTrackingRoundFromGraduatedYearTH() {
      commonDataProvider
        .loadListOfJobTrackingRoundFromGraduatedYearTH(this.selectedYearTH)
        .then((resp) => {
          this.Round_Choices = resp.data;
        });
    },
    async initialize() {
      await masterDataProvider.getCertificateTypes().then((result) => {
        this.certificateTypes = result.data;
      });
      await subjectTypeDataProvider.loadSubjectTypes().then((result) => {
        this.subjecttypes = result.data;
      });
      this.loadListOfGraduatedYearFromJobTrackingReport();
    },
    create_reportParameters() {
      if (this.$refs.form.validate()) {
        this.document_viewer_key += 1;
        this.report_parameters = [];
        if (this.selectedYearTH != undefined) {
          this.report_parameters.push("prm_YearTH=" + this.selectedYearTH);
        }
        if (this.selectedRound > 0) {
          this.report_parameters.push("prm_RoundNumber=" + this.selectedRound);
        }
        this.document_viewer_dialog = true;
      }
    },
  },
  async created() {
    await this.initialize();
  },
  components: { DocumentViewer, ReportExport,  ReportExportFromQueue },
};
</script>

<style></style>
