v-autocomplete
<template>
  <div class="pa-5">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-card rounded elevation="1" class="px-5 py-5">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-account-search</v-icon>
        ผู้ประกาศหางาน
      </h3>
      <v-divider class="mt-1"></v-divider>
      <v-row class="mt-2 align-center" justify="space-between">
        <v-col cols="auto">
          <h4 class="ml-1">จำนวนผู้หางาน {{ totalstudent }} คน</h4>
        </v-col>
        <v-col cols="auto">
          <v-btn @click="isFilter = !isFilter" color="input">
            <v-icon v-if="!isFilter">mdi-filter-multiple</v-icon>
            <v-icon class="mr-2" v-else>mdi-restart</v-icon>
            {{ isFilter ? "ซ่อนการกรอง" : "กรองข้อมูล" }}</v-btn
          >
        </v-col>
      </v-row>
      <v-card rounded elevation="1" class="px-5 py-5 mt-5" v-if="isFilter">
        <div class="mx-5">
          <v-row>
            <v-col lg="3" md="6" sm="12" class="mb-0 pb-0">
              <h4 class="primary--text mb-2">เพศ</h4>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
                >
                  <v-btn
                    :color="Selectedgender === '' ? 'primary' : 'input'"
                    @click="
                      Selectedgender = '';
                      OnSelectedChoice();
                    "
                    height="52px"
                    class="mr-5"
                    width="100%"
                  >
                    ทั้งหมด
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
                >
                  <v-btn
                    :color="Selectedgender === 'M' ? 'primary' : 'input'"
                    @click="
                      Selectedgender = 'M';
                      OnSelectedChoice();
                    "
                    height="52px"
                    width="100%"
                  >
                    ชาย
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
                >
                  <v-btn
                    :color="Selectedgender === 'F' ? 'primary' : 'input'"
                    @click="
                      Selectedgender = 'F';
                      OnSelectedChoice();
                    "
                    height="52px"
                    width="100%"
                  >
                    หญิง
                  </v-btn>
                </v-col>
              </v-row>

              <!-- <v-autocomplete
              label="เพศ"
              v-model="Selectedgender"
              item-text="name"
              item-value="code"
              :items="genderlist"
              solo
              clearable
              @change="OnSelectedChoice"
            >
            </v-autocomplete> -->
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
              <h4 class="primary--text mb-2">ระดับชั้น</h4>
              <v-select
                label="ระดับชั้น"
                v-model="Selectedgradelevel"
                :items="gradelevellist"
                background-color="input"
                filled
                clearable
                @change="OnSelectedChoice"
              >
              </v-select>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
              <h4 class="primary--text mb-2">ปีที่จบการศึกษา</h4>
              <v-select
                label="ปีที่จบการศึกษา"
                v-model="Selectedgraduateyear"
                :items="yearTHlist"
                background-color="input"
                clearable
                filled
                @change="OnSelectedChoice"
              >
              </v-select>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
              <h4 class="primary--text mb-2">จังหวัด</h4>
              <v-autocomplete
                label="จังหวัด"
                item-text="provincethai"
                item-value="provinceid"
                :items="provincelist"
                v-model="Selectedprovince"
                background-color="input"
                clearable
                filled
                @change="OnSelectedChoice"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12" class="mb-0 pb-0">
              <h4 class="primary--text mb-2">สถานศึกษา</h4>
              <v-autocomplete
                label="สถานศึกษา"
                v-model="Selectedschool"
                item-text="schoolname"
                item-value="schoolid"
                :items="schoolllist"
                background-color="input"
                clearable
                filled
                @change="OnSelectedChoice"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12" class="mb-0 pb-0">
              <h4 class="primary--text mb-2">สาขาวิชา</h4>
              <v-autocomplete
                label="สาขาวิชา"
                v-model="Selectedmajor"
                :item-text="
                  (item) => item.subjecttypename + ' - ' + item.minorname
                "
                item-value="majorcode"
                :items="majorlist"
                background-color="input"
                clearable
                filled
                @change="OnSelectedChoice"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12" class="mb-0 pb-0">
              <h4 class="primary--text mb-2">ประเภทงาน</h4>
              <v-autocomplete
                label="ประเภทงาน"
                v-model="SelectedJobSegment"
                :items="JobSegment"
                item-text="name"
                item-value="name"
                background-color="input"
                clearable
                filled
                @change="OnSelectedChoice"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12" class="mb-0 pb-0">
              <h4 class="primary--text mb-2">ประเภทการจ้างงาน</h4>
              <v-select
                label="ประเภทการจ้างงาน"
                v-model="Selectedeemploymenttype"
                :items="employmentType"
                item-text="nameth"
                item-value="code"
                background-color="input"
                clearable
                filled
                @change="OnSelectedChoice"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <v-row v-for="item in students" :key="item.oid" class="my-5">
        <v-col>
          <v-card>
            <v-container>
              <v-row>
                <v-col lg="6" md="6" sm="6" class="justify-start">
                  <p>ID : {{ item.oid }}</p>
                </v-col>
                <v-col lg="6" md="6" sm="6" class="justify-end text-right">
                  <p>
                    อัปเดตล่าสุด:
                    <FormatDateView
                      v-if="item.profileupdateddate !== '0001-01-01T00:00:00'"
                      :date_value="item.profileupdateddate"
                    ></FormatDateView>
                    <span
                      v-if="item.profileupdateddate === '0001-01-01T00:00:00'"
                      >ยังไม่ได้อัปเดต</span
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row class="mx-2">
                <v-row class="mx-2">
                  <v-col
                    v-if="item.base64thrumnail"
                    class="d-flex align-center justify-center"
                    cols="12"
                    lg="3"
                    md="12"
                  >
                    <v-img
                      alt="test"
                      class="shrink fill-height"
                      contain
                      transition="scale-transition"
                      aspect-ratio="1/1"
                      cover
                      v-bind:lazy-src="
                        'data:image/jpg;base64,' + item.base64thrumnail
                      "
                      v-bind:src="
                        'data:image/jpg;base64,' + item.base64thrumnail
                      "
                    />
                  </v-col>
                  <v-col
                    v-else
                    class="d-flex justify-center"
                    cols="12"
                    lg="3"
                    md="12"
                  >
                    <v-img
                      alt="test"
                      class="shrink fill-height"
                      contain
                      transition="scale-transition"
                      aspect-ratio="1/1"
                      cover
                      :src="require(`@/assets/user-img.jpg`)"
                    />
                  </v-col>
                  <!-- </v-row>
                <v-row> -->

                  <v-col cols="12" md="6" lg="3" class="pa-0">
                    <h3
                      class="primary--text d-flex justify-lg-start justify-md-start justify-sm-center"
                      style="font-weight: bold"
                    >
                      {{ item.titlename?.titlenameth }} {{ item.firstname }}
                      {{ item.surname }}
                    </h3>
                    <p class="mt-2">
                      เพศ : <span v-if="item.gender == 'F'"> หญิง </span>
                      <span v-if="item.gender == 'M'"> ชาย </span>
                    </p>

                    <p>อายุ : {{ moment().diff(item.birthday, "years") }}</p>

                    <p>ระดับชั้น : {{ item.gradenameth }}</p>

                    <p>สาขาวิชา : {{ item.majornameth }}</p>

                    <p v-if="item.tambon">
                      จังหวัด :
                      {{ item.tambon.district.province.provincethai }}
                    </p>
                  </v-col>
                  <!-- </v-row>
                <v-row> -->
                  <v-col cols="12" lg="6" md="6" class="pa-0">
                    <p>
                      ประเภทงานที่ต้องการ :
                      <v-chip
                        v-for="type in employmentType"
                        :key="type.code"
                        v-if="item[type.code]"
                        class="ma-2"
                        color="chip"
                        :small="true"
                      >
                        {{ type.nameth }}
                      </v-chip>
                    </p>

                    <p>ประเภทงานที่ต้องการสมัคร :</p>

                    <p>1. {{ item.interestedjob1 }}</p>
                    <p>2. {{ item.interestedjob2 }}</p>
                    <p>3. {{ item.interestedjob3 }}</p>
                  </v-col>
                </v-row>
              </v-row>
            </v-container>

            <v-row align="center" justify="space-between" class="mx-10 mt-1">
              <v-col align-self="end" cols="12" xs="6" sm="12" md="12" lg="8">
                <v-row justify="end">
                  <span style="color: #374389; font-weight: bold">
                    {{ item.completionpercentage }}% </span
                  >&nbsp;<span> ความสมบูรณ์ของโปรไฟล์ </span>
                </v-row>
                <v-row>
                  <v-progress-linear
                    v-model="item.completionpercentage"
                    rounded
                    background-color="#CCC"
                    color="green"
                    height="10"
                    class="mb-3"
                  ></v-progress-linear>
                </v-row>
              </v-col>

              <v-col cols="12" lg="4" sm="12">
                <v-row>
                  <v-col
                    v-if="item.rangesalary"
                    cols=""
                    class="py-0 mt-3 d-flex justify-lg-end justify-center"
                    ><b style="color: #374389">{{ item.rangesalary }}</b> /
                    เดือน</v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-lg-end justify-center">
                    <BtnFavoriteToggle :student="item"></BtnFavoriteToggle>
                    <v-btn
                      class="color-button2 ml-2 justify-sm-center text-xs-caption"
                      width="50%"
                      @click="goToSearchStudentDetails(item)"
                      >รายละเอียดเพิ่มเติม</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-pagination
          class="pl-6"
          v-if="students.length > 0"
          v-model="page"
          :length="length"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import { SearchStudentService } from "@/services/SearchStudentService.js";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { CurriculumDataProvider } from "@/services/CurriculumDataProvider";

import BtnFavoriteToggle from "@/components/SearchStudentResume/BtnFavoriteToggle.vue";
import StudentSimpleResumeCard from "@/components/Student/StudentSimpleResumeCard.vue";
import moment from "moment";
import "moment/locale/th";
import FormatDateView from "@/components/common/FormatDateView.vue";

var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var curriculumDataProvider = new CurriculumDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var searchStudentService = new SearchStudentService(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "SearchStudentResume",
  components: {
    BtnFavoriteToggle,
    StudentSimpleResumeCard,
    FormatDateView,
  },
  props: [],
  data() {
    return {
      provincelist: [],
      selectedSchooltype: 0,
      request_filter: [],
      datatable_headers: [
        {
          value: "school.tambon.district.province.provincethai",
          text: "จังหวัด",
        },
        { value: "action", text: "" },
      ],
      Selectedprovince: "",
      Selectedeemploymenttype: "",
      Selectedgraduateyear: "",
      Selectedmajor: "",
      Selectedgradelevel: "",
      Selectedschool: "",
      Selectedgender: "",
      SelectedJobSegment: "",
      schoolllist: [],
      schoolRequest: new GetSchools_Reqeust(),
      mainGradelist: [],
      gradelevellist: [
        "ประกาศนียบัตรวิชาชีพ 3",
        "ประกาศนียบัตรวิชาชีพชั้นสูง 2",
      ],
      genderlist: [
        {
          name: "ชาย",
          code: "M",
        },
        {
          name: "หญิง",
          code: "F",
        },
      ],
      majorlist: [],
      yearTHlist: [],
      employmentType: [
        {
          nameth: "งานประจำ (Full Time)",
          code: "fulltime",
        },
        {
          nameth: "งานนอกเวลา (Part Time)",
          code: "parttime",
        },
        {
          nameth: "ฝึกงาน",
          code: "internship",
        },
        {
          nameth: "งานอิสระ (Freelance)",
          code: "freelance",
        },
        {
          nameth: "งานจ้างรายวัน",
          code: "byday",
        },
        {
          nameth: "งานจ้างชั่วคราว",
          code: "temporary",
        },
        {
          nameth: "รับงานไปทำที่บ้าน",
          code: "workfromhome",
        },
      ],
      students: [],
      page: 1,
      length: 0,
      itemperpage: 10,
      request: [],
      totalstudent: 0,
      completionPercentage: "10",
      moment: moment,
      isFilter: true,
      JobSegment: [],
    };
  },
  watch: {
    page: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.request.page = newVal;
          this.request.itemperpage = this.itemperpage;
          this.searchStudent();
        } else {
          this.searchStudent();
        }
      },
    },
  },
  methods: {
    async OnSelectedChoice() {
      this.request.page = 1;
      this.request.itemperpage = this.itemperpage;
      this.request.province = this.Selectedprovince;

      if (this.Selectedeemploymenttype == "fulltime") {
        this.request.fulltime = true;
      } else {
        this.request.fulltime = false;
      }
      if (this.Selectedeemploymenttype == "parttime") {
        this.request.parttime = true;
      } else {
        this.request.parttime = false;
      }
      if (this.Selectedeemploymenttype == "internship") {
        this.request.internship = true;
      } else {
        this.request.internship = false;
      }
      if (this.Selectedeemploymenttype == "freelance") {
        this.request.freelance = true;
      } else {
        this.request.freelance = false;
      }
      if (this.Selectedeemploymenttype == "byday") {
        this.request.byday = true;
      } else {
        this.request.byday = false;
      }
      if (this.Selectedeemploymenttype == "temporary") {
        this.request.temporary = true;
      } else {
        this.request.temporary = false;
      }
      if (this.Selectedeemploymenttype == "workfromhome") {
        this.request.workfromhome = true;
      } else {
        this.request.workfromhome = false;
      }
      this.request.gender = this.Selectedgender;
      this.request.graduateyear = this.Selectedgraduateyear;
      this.request.majorcode = this.Selectedmajor;
      this.request.gradelevel = this.Selectedgradelevel;
      this.request.schoolid = this.Selectedschool;
      this.request.lookingforjob = this.SelectedJobSegment;

      this.searchStudent();
    },
    async searchStudent() {
      this.$store.dispatch("showLoading");
      await searchStudentService
        .searchStudents(this.request)
        .then((response) => {
          this.students = response.data.students;
          console.log(this.students);
          this.totalstudent = response.data.totalstudent;
          this.length = Math.ceil(this.totalstudent / this.itemperpage);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },
    async loadJobSegment() {
      await masterDataProvider.getJobSegment().then((result) => {
        this.JobSegment = result.data;
      });
    },
    async loadProvinces() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provincelist = result.data;
      });
    },
    async loadSchools() {
      this.schoolRequest.ProvinceID = this.Selectedprovince;
      await schoolDataProvider
        .loadSchools(this.schoolRequest)
        .then((result) => {
          this.schoolllist = result.data;
        });
    },
    async loadMainGradeTHNames() {
      await commonDataProvider.getMainGradeTHNames().then((result) => {
        this.mainGradelist = result.data;
      });
    },
    async loadmajor() {
      await curriculumDataProvider.loadCurriculums().then((result) => {
        this.majorlist = result.data;
      });
    },
    async loadListOfYearTH() {
      await commonDataProvider.getListOfYearTH().then((result) => {
        this.yearTHlist = result.data;
      });
    },

    goToSearchStudentDetails(item) {
      var studentOid = item.oid;

      const routeData = this.$router.resolve({
        name: "StudentDetailOverview",
        params: { studentoid: studentOid },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    // var datenow = new Date
    this.daysDifference = moment().diff("2000-02-28", "years");
  },
  async created() {
    await this.loadProvinces();
    await this.loadSchools();
    // await this.loadMainGradeTHNames();
    await this.loadJobSegment();
    await this.loadmajor();
    await this.loadListOfYearTH();

    this.request.page = 1;
    this.request.itemperpage = this.itemperpage;
    await this.searchStudent();
  },
};
</script>

<style scoped>
.shrink {
  border-radius: 200px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 10px rgba(15, 15, 15, 0.5);
  border: 1px solid grey;
}
</style>
