import axios from "axios"
import { create_default_request_header } from "./constants"
import { createEndpointUrl } from "@/common/api_connectivity_helper"

import { TitlenameRecord } from "@/models/Titlename/TitlenameRecord"
export class TitlenameDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL
  }

  saveTitlenameRecord(vm = new TitlenameRecord()) {
    const header = create_default_request_header()
    const endpointUrl = "/api/Titlename/CreateTitlename/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.post(combinedURL, {
      titlenameid: vm.titlenameid,
      titlenameth: vm.titlenameth,
      titlenameeng: vm.titlenameeng,
    }, {header})
  }

  editTitlenameRecord(vm) {
    const header = create_default_request_header()
    const endpointUrl = "/api/Titlename/UpdateTitlename/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.put(combinedURL, {
      oid: vm.oid,
      titlenameid: vm.titlenameid,
      titlenameth: vm.titlenameth,
      titlenameeng: vm.titlenameeng,
    }, {header})
  }

  deleteTitlenameRecord(oid) {
    var header = create_default_request_header()
    var endpointUrl = "/api/Titlename/DaleteTitlename/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.delete(combinedURL, header)
  }

    updateTitlenameRecord(vm = new TitlenameRecord()) {
    const header = create_default_request_header()
    const endpointUrl = "/api/Titlename/UpdateTitlename/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.post(combinedURL, vm, header)
  }

  getTitlenameRecords(oid = '') {
    const header = create_default_request_header()
    const endpointUrl = "/api/Titlename/GetTitlenames/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

  getTitlenameRecord(oid = '') {
    const header = create_default_request_header()
    const endpointUrl = "/api/Titlename/GetTitlename/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

}
