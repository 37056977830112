<template>
    <v-row>
        <v-col cols="10">
            <v-text-field v-model="username" @change="onChanged()"></v-text-field>
        </v-col>
        <v-col cols="2">
            <label>{{ checkedMessage }}</label>
        </v-col>
    </v-row>
</template>

<script>
import { ApplicationUserDataProvider } from '@/services/ApplicationUserDataProvider'
const applicationUserDataProvider = new ApplicationUserDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
    name: 'UsernameTextField',
    props: ['Username'],
    data() {
        return {
            isUsed: undefined,
            username : '',
        }
    },
    computed: {
        checkedColor() {
            if (this.isUsed == true) {
               return 'error'; 
            } else if (this.isUsed == false) {
                return 'success';
            }
        },
        checkedMessage() {
            if (this.isUsed == true) {
               return 'ไม่สามารถใช้ username นี้ได้'; 
            } else if (this.isUsed == false) {
                return 'สามาถใช้ username นี้ได้';
            }
        }
    },
    methods: {
        async onChanged() {
            await applicationUserDataProvider.isUsernameUsed(this.username).then(result => {
                this.isUsed = result.data;
            })
        }
    },
    async created(){
        this.username = this.Username;
    }
}
</script>

<style></style>