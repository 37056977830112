<template>
  <v-container fluid v-if="NewsContent.length">
    <!-- <div class="guidance-news"> -->
    <!-- <div class="background-overlay"></div> -->

    <v-carousel
      hide-delimiter-background
      cycle
      height="500"
      class="align-center justify-center"
    >
      <v-row>
        <v-col>
          <v-carousel-item
            v-for="item in NewsContent"
            :key="item.oid"
            reverse-transition="fade-transition"
            transition="fade-transition"
            transition-duration="1000"
            reverse-transition-duration="1000"
            @click="goToNewsDetail(item.oid)"
          >
            <div class="carousel-item-wrapper">
              <v-img
                :src="'data:image/jpeg;base64,' + item.bannerthumbnailbase64"
                class="blur-background"
                height="100%"
                width="100%"
                cover
              ></v-img>
              <v-img
                :src="'data:image/jpeg;base64,' + item.bannerthumbnailbase64"
                class="foreground-image"
                aspect-ratio="1.77"
                height="100%"
                width="100%"
                contain
              ></v-img>
            </div>
          </v-carousel-item> </v-col
      ></v-row>
    </v-carousel>

    <br />
    <v-row justify="end"
      ><v-btn color="info" class="ma-7" @click="goToNewsReadmore">
        Read more >>
      </v-btn></v-row
    >
  </v-container>
</template>

<script>
import { NewsDataProvider } from "@/services/NewsDataProvider";

const newsDataProvider = new NewsDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "VcopCarouselNews",
  components: {},
  data() {
    return {
      oid: "",
      NewsContent: [],
      itemlength: 0,
      newsOid: undefined,
    };
  },
  methods: {
    goToNewsDetail(item) {
      console.log(item);
      this.$router.push({ name: "VcopNewsDetailView", params: { oid: item } });
    },
    async loadNewmore() {
      await newsDataProvider
        .loadNewsPublishList(this.oid)
        .then((result) => {
          this.NewsContent = result.data;
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToNewsReadmore() {
      this.$router.push({ name: "VcopNewsListView" });
    },
  },
  async mounted() {
    await this.loadNewmore();
  },
  computed: {
    formattedDate() {
      if (!this.NewsContent) return "";
      const date = new Date(this.NewsContent[0].createddate);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleString("th-TH", options);
    },
  },
};
</script>

<style>
.carousel-item-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  filter: blur(10px);
  z-index: 1;
}
.foreground-image {
  position: relative;
  z-index: 2;
}
.foreground-image:hover {
  cursor: pointer;
}
.v-carousel .v-carousel__controls .v-btn {
  background-color: rgba(255, 255, 255, 0.658);
}
</style>
