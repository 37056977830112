<template>
    <label v-if="jobtrackingreport"><v-icon :color="dynamicColor">{{ dynamicIcon }}</v-icon> {{
        jobtrackingreport.importstudentdata_status_statusname
    }}</label>
</template>
<script>
export default {
    name: 'GraduatedStudentJobTrackingReportMain_ImportStudent_Status_Label',
    props: ['jobtrackingreport'],
    computed: {
        dynamicIcon() {
            switch (this.jobtrackingreport.importstudentdata_status_statuscode) {
                case 'WaitingToStartImportingProcess':
                    return "mdi-clock-edit";
                case 'Imported':
                    return "mdi-check-circle";
                case 'Importing':
                    return "mdi-progress-clock";
                default:
                    return '';
            }
        },
        dynamicColor() {
            switch (this.jobtrackingreport.importstudentdata_status_statuscode) {
                case 'WaitingToStartImportingProcess':
                    return "grey";
                case 'Imported':
                    return "success";
                case 'Importing':
                    return "info";
                default:
                    return '';
            }
        }
    }
}
</script>

<style></style>