<template>
  <div>
    <appbar></appbar>
    <v-container class="">
      <v-row class="justify-center pt-10">
        <LoginFormforstudent
          :loadingbtn="loading"
          @recovery-indentify="onclickforgetpassword"
          @submit-login="onsubmitcomplete"
          filename="Lstudent.jpg"
          description="นักเรียน / นักศึกษา"
          username_caption="รหัสนักศึกษา"
        >
          <template v-slot:schools>
            <v-autocomplete
              v-model="SelectedSchool"
              :items="SchoolRanges"
              item-text="schoolname"
              item-value="schoolid"
              label="--กรุณาเลือกสถานศึกษา--"
              filled
              rounded
              dense
              class="mt-3"
            ></v-autocomplete>
          </template>
        </LoginFormforstudent>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
import LoginFormforstudent from "@/components/LoginForm/LoginFormforstudent.vue";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";

import { AuthenticationProvider } from "@/services/AuthenticationProvider";
var authenticationProvider = new AuthenticationProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

var schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  name: "LoginStudent",

  components: {
    appbar,
    LoginFormforstudent,
  },
  data() {
    return {
      SchoolRanges: [],
      request_data: new GetSchools_Reqeust(),
      SelectedSchool: "",
      loading: false,
    };
  },
  methods: {
    async onclickforgetpassword() {
      this.$router.push("/recoveryindentifyStudent");
    },
    async onsubmitcomplete(account) {
      this.loading = true;
      account.schoolcode = this.SelectedSchool;
      const isChangePasswordOnLogon = await authenticationProvider.IsThisUserMustChangeFirstLogon(
        account
      );
      if (isChangePasswordOnLogon.data == true) {
        this.loading = false;
        const username = account.username;
        this.$router.push("/SetPassword");
        this.$router
          .push({
            name: "SetPassword",
            params: { username },
          })
          .catch((err) => {
            this.loading = false;
            this.$toast.error("Incorrect username or password. ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง");
          });
      } else {
        this.$store.dispatch({ type: "doLoginStudent", account }).then((response) => {
          if (response?.status == 200) {
            this.loading = false;
            this.$toast.success("เข้าสู่ระบบเรียบร้อย !");
            this.$store.dispatch({ type: "gotoFirstPage" });
            if (this.lsRememberMe == true) {
              localStorage.setItem("username", account.username);
            } else {
              localStorage.setItem("username", (account.username = ""));
            }
          } 
        }).catch(err => {
            this.loading = false;
            this.$toast.error("Incorrect username or password. ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง");
        });
      }
    },
    async loadSchools() {
      await schoolDataProvider.loadSchools(this.request_data).then((response) => {
        this.SchoolRanges = response.data;
      });
    },
  },
  async created() {
    await this.loadSchools();
  },
};
</script>

<style scoped>
.headertext {
  font-size: 22pt !important;
}
</style>
