<!-- components/Loading.vue -->
<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="spinner">
      <div class="hourglass">
        <div class="top">
          <div class="topBg"></div>
          <div class="topProgress"></div>
        </div>
        <div class="line"></div>
        <div class="bottom">
          <div class="bottomBg"></div>
          <div class="bottomProgress"></div>
        </div>
      </div>
      <p class="loading-text">{{ loadingText }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loadingText: "loading .",
      textIndex: 0,
      loadingIntervals: ["loading .", "loading ..", "loading ..."],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.startLoadingAnimation();
      } else {
        this.stopLoadingAnimation();
      }
    },
  },
  methods: {
    startLoadingAnimation() {
      this.loadingInterval = setInterval(() => {
        this.textIndex = (this.textIndex + 1) % this.loadingIntervals.length;
        this.loadingText = this.loadingIntervals[this.textIndex];
      }, 500); // Adjust the speed as needed
    },
    stopLoadingAnimation() {
      clearInterval(this.loadingInterval);
      this.loadingText = "loading ."; // Reset to initial state if desired
    },
  },
  beforeDestroy() {
    this.stopLoadingAnimation();
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  flex-direction: column;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-image {
  width: 50px;
  /* Adjust the width as needed */
  height: 50px;
  /* Adjust the height as needed */
}

.loading-text {
  color: #fff;
  margin-top: 5px;
  font-size: 1.2em;
  /* Adjust the font size as needed */
}

body {
  background: blue;
}

* {
  box-sizing: border-box;
}

@keyframes rotate {
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes topProgress {
  3% {
    transform: scale(1) translateY(0);
  }
  90% {
    transform: scale(0) translateY(0);
  }
  100% {
    transform: scale(0) translateY(0);
  }
}

@keyframes lineProgress {
  10% {
    height: 35px;
  }
  90% {
    height: 35px;
  }
  100% {
    height: 0;
  }
}

@keyframes bottomProgress {
  10% {
    transform: scale(0) translateY(0);
  }
  90% {
    transform: scale(1) translateY(0);
  }
  100% {
    transform: scale(1) translateY(-15px);
  }
}

.hourglass {
  height: 40px;
  width: 41px;
  margin: 20px auto;
  animation: rotate 3s cubic-bezier(0.7, 0, 0.2, 1) infinite;
}

.top {
  position: relative;
}

.topBg {
  position: absolute;
  z-index: 0;
  top: -18px;
  width: 100%;
  height: 20px;
  border-top: 5px solid #3f51b5;
  background: white;

  &:after {
    content: "";
    position: absolute;
    bottom: -20px;
    border-top: 20px solid white;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
  }
}

.topProgress {
  border-top: 20px solid #ff9800;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  height: 0;
  width: 1px;
  transform: scale(1) translateY(0);
  transform-origin: 50% 100%;
  animation: topProgress 3s linear infinite;
}

.line {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;
  height: 0;
  width: 0;
  border-left: 1px solid #ff9800;
  animation: lineProgress 3s linear infinite;
}

.bottom {
  position: relative;
}

.bottomBg {
  position: absolute;
  z-index: 0;
  bottom: -5px;
  width: 100%;
  height: 20px;
  border-bottom: 5px solid #3f51b5;
  background: white;

  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: -20px;
    border-bottom: 20px solid white;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
  }
}

.bottomProgress {
  border-right: 20px solid transparent;
  border-bottom: 20px solid #ff9800;
  border-left: 20px solid transparent;
  height: 0;
  width: 1px;
  margin-top: 15px;
  transform: scale(0) translateY(0);
  transform-origin: 50% 100%;
  animation: bottomProgress 3s linear infinite;
}
</style>
