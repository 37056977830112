<template>
  <div>
    <v-container>
      <v-row class="pt-6 px-3">
        <v-icon class="pr-2" color="primary">mdi-briefcase</v-icon>
        <h3 class="primary--text">ประวัติการทำงาน</h3>
      </v-row>
      <v-row class="px-3" style="margin-top: 1%; margin-bottom: 2%">
        <v-divider></v-divider>
      </v-row>
      <v-row
        v-if="
          experience.length > 0 &&
          $vuetify.breakpoint.lgAndUp &&
          $vuetify.breakpoint.mdAndUp
        "
      >
        <v-col v-for="(item, index) in experience" :key="index" cols="6">
          <v-card class="py-3">
            <v-row class="px-9 py-5">
              <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <v-row class="pb-3">
                  <h3>{{ item.organizationname ? item.organizationname : "-" }}</h3>
                </v-row>
                <v-row>
                  <span>
                    ระยะเวลา :
                    <template v-if="item.startdate && item.enddate">
                      {{ formatDate(item.startdate) }} - {{ formatDate(item.enddate) }}
                    </template>
                    <template v-else-if="item.startdate || item.enddate">
                      {{ formatDate(item.startdate || item.enddate) }} - ไม่ระบุ
                    </template>
                    <template v-else> ไม่ระบุ </template>
                  </span>
                </v-row>
                <v-row>
                  <span>ตำแหน่ง : {{ item.positionname ? item.positionname : "-" }}</span>
                </v-row>
                <v-row>
                  <span
                    >เงินเดือน :
                    {{ item.salaryrange ? item.salaryrange.salary_range : "-" }}
                  </span>
                </v-row>
                <v-row>
                  <span>หน้าที่รับผิดชอบ : {{ item.jobdescription ? item.jobdescription : "-" }}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          experience.length > 0 &&
          !$vuetify.breakpoint.lgAndUp &&
          !$vuetify.breakpoint.mdAndUp
        "
      >
        <v-col v-for="(item, index) in experience" :key="index" cols="12">
          <v-card class="py-3">
            <v-row class="px-9 py-5">
              <v-col cols="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <v-row class="pb-3">
                  <h3>{{ item.organizationname ? item.organizationname : "-" }}</h3>
                </v-row>
                <v-row>
                  <span>
                    ระยะเวลา :
                    <template v-if="item.startdate && item.enddate">
                      {{ formatDate(item.startdate) }} - {{ formatDate(item.enddate) }}
                    </template>
                    <template v-else-if="item.startdate || item.enddate">
                      {{ formatDate(item.startdate || item.enddate) }} - ไม่ระบุ
                    </template>
                    <template v-else> ไม่ระบุ </template>
                  </span>
                </v-row>
                <v-row>
                  <span>ตำแหน่ง : {{ item.positionname }}</span>
                </v-row>
                <v-row>
                  <span
                    >เงินเดือน :
                    {{ item.salaryrange ? item.salaryrange.salary_range : "ไม่ระบุ" }}
                  </span>
                </v-row>
                <v-row>
                  <span>หน้าที่รับผิดชอบ : {{ item.jobdescription }}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="">
        <v-col cols="12">
          <v-card class="py-16 px-3 d-flex justify-center" max-width="100%"
            ><span>ไม่มีข้อมูล</span></v-card
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { StudentDataProvider } from "@/services/StudentDataProvider";

var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  props: ["studentoid", "isInputDisabled"],
  components: {},
  data() {
    return {
      ExperienceStudentDetailView_Dialog: false,
      experience: [],
      Experienceoid: -1,
    };
  },
  methods: {
    async LoadExperienceStudent(studentoid) {
      try {
        const res = await studentDataProvider.getStudentJobRecords(studentoid);
        this.experience = res.data;
      } catch (error) {
        console.error("Failed to load experience records", error);
      }
    },
    async saveExperienceStudent(value) {
      value.student_oid = this.studentoid;
      try {
        await studentDataProvider.saveStudentJobRecord(value);
        this.$toast.success("สร้างประวัติเรียบร้อย!");
        this.LoadExperienceStudent(this.studentoid);
      } catch {
        this.$toast.error("ล้มเหลว!");
      }
    },
    async onExperienceProcessIsCompleted(value, save) {
      if (save) {
        this.ExperienceStudentDetailView_Dialog = false;
        if (value.oid != -1) {
          await this.UpdateExperienceStudent(value);
        } else {
          await this.saveExperienceStudent(value);
        }
      } else {
        this.ExperienceStudentDetailView_Dialog = false;
      }
      this.Experienceoid = -1;
    },
    onclickEditExperienceStudent(item) {
      this.Experienceoid = item.oid;
      this.ExperienceStudentDetailView_Dialog = true;
    },
    async UpdateExperienceStudent(value) {
      try {
        await studentDataProvider.editStudentJobRecord(value);
        this.$toast.success("บันทึกข้อมูลสำเร็จ");
        this.LoadExperienceStudent(this.studentoid);
      } catch {
        this.$toast.error("แก้ไขข้อมูลล้มเหลว!");
      }
    },
    async deleteExperienceStudent(item) {
      if (confirm("ต้องการลบประวัติการทำงานหรือไม่ ?")) {
        try {
          await studentDataProvider.deleteStudentJobRecord(item.oid);
          this.$toast.success("ลบประวัติการทำงานเรียบร้อย!");
          this.LoadExperienceStudent(this.studentoid);
        } catch {
          this.$toast.error("ลบประวัติการทำงานล้มเหลว!");
        }
      }
    },
    formatDate(dateString) {
      if (!dateString) return "-";
      return dateString.replace(/-/g, "/");
    },
  },
  mounted() {
    this.LoadExperienceStudent(this.studentoid);
  },
};
</script>

<style scoped>
.v-card {
  width: 100%;
  margin: 0 auto;
}
</style>
