export const RegionTHNames = {
  central_Region: "ภาคกลาง",
  western_Region: "ภาคตะวันตก",
  eastern_Region: "ภาคตะวันออก",
  northeast_Region: "ภาคตะวันออกเฉียงเหนือ",
  south_Region: "ภาคใต้",
  north_Region: "ภาคเหนือ"
}

export const EmploymentStatusCodes = {
  Study: "Study",
  WaitingForJobs: "WaitingForJobs",
  Government: "Government",
  StateEnterprice: "StateEnterprice",
  Paperwork: "Paperwork",
  Freelance: "Freelance",
  WorkForCompany: "WorkForCompany",
  Other: "Other"
}

export const JobTrackingReportStatusCodes = {
  CREATED: "CREATED",
  FILLING_STUDENT_DATA: "FILLING_STUDENT_DATA",
  NOT_SENT: "NOT_SENT",
  SENT: "SENT"
}

export const MenuProfileNames = {
  STC: "เจ้าหน้าที่ส่วนกลาง",
  GTC: "เจ้าหน้าที่ติดตามภาวะการมีงานทำ",
  STS: "เจ้าหน้าที่ศูนย์ข้อมูลของสถานศึกษา",
  STT: "เจ้าหน้าที่สถานศึกษา(ทวิภาคี)",
  MNG: "เจ้าหน้าที่ส่งรายงาน"
}

export const ApplicationRoleCodes = {
  Administrator: "Administrator",
  Center_Admin: "Center_Admin",
  Center_Officer: "Center_Officer",
  Center_Manager: "Center_Manager",
  School_Manager: "School_Manager",
  School_Admin: "School_Admin",
  School_Partner_Officer: "School_Partner_Officer",
  School_Guidance_Teacher: "School_Guidance_Teacher",
  ProvinceSchool_Officer: "ProvinceSchool_Officer",
  Insitution_Officer: "Insitution_Officer",
  Employer: "Employer",
  Student: "Student"
}

export const NotificationHelper = {
  NotificationEventKey_ReportInComming:
    "GraduatedStudentJobTrackingReportInComming_Notify",
  NotificationEventKey_ReportDueDate:
    "GraduatedStudentJobTrackingReportDueDate_Notify",
  NotificationEventKey_ReportStudentImported:
    "GraduatedStudentJobTrackingReportStudentImported",
  NotificationEventKey_StudentRegistration:
    "JobPostingHasNewStudentRegistration"
}

export const NewsStatusCodes = {
  Draft: "Draft",
  WaitingApprove: "WaitingApprove",
  Publish: "Publish",
  unPublish: "unPublish"
}

export const NewsStatusItems = [
  { code: "Draft", name: "ร่าง", color: "#D9DCF0" },
  { code: "WaitingApprove", name: "รอการอนุมัติ", color: "#03a9f4" },
  { code: "Publish", name: "เผยแพร่", color: "#4caf50" },
  { code: "unPublish", name: "ยกเลิกการเผยแพร่", color: "#B00020" }
]

export const NavigationCategory = {
  report: "รายงาน",
  workforceNetworkCenter: "ศูนย์เครือข่ายกำลังคน",
  employmentStatusTrackingSystem: "ระบบติดตามภาวะการมีงานทำ",
  masterData: "ข้อมูลตั้งต้น",
  userRole: "ผู้ใช้งานระบบ"
}

export const NewsOriginTypeCodes = {
  Center: "Center",
  School: "School",
  Institute: "Institute",
  ProvinceSchool: "ProvinceSchool",
  // Employer: "Employer"
}

export const NewsOriginTypeSelection = [
  { code: "Center", name: "ส่วนกลาง", color: "#03a9f4" },
  { code: "School", name: "สถานศึกษา", color: "#03a9f4" },
  { code: "ProvinceSchool", name: "อาชีวศึกษาจังหวัด", color: "#03a9f4" },
  { code: "Institute", name: "อาชีวศึกษา", color: "#03a9f4" },
  // { code: "Employer", name: "สถานประกอบการ", color: "#03a9f4" },
]
