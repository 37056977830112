  export class TempDataHelper {
    static TempJobs = [
        {
          "jobposition": "ตำแหน่งงาน: พนักงานขาย",
          "companyname": "บริษัท ABC จำกัด",
          "provincethai": "จังหวัด: กรุงเทพมหานคร",
          "posted_date": "วันที่โพสต์: 20 พฤษภาคม 2566",
          "visited_number": "ผู้เข้าชม: 100 ครั้ง",
          "salary": "เงินเดือน: 25,000 บาท",
          "is_argent": "ด่วน",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 3 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท ABC จำกัด กำลังมีความต้องการสำหรับตำแหน่ง พนักงานขาย ในพื้นที่จังหวัดกรุงเทพมหานคร โดยต้องการพนักงานที่มีประสบการณ์ในการขายสินค้าด้านเทคโนโลยี จำนวน 3 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 02-XXXXXXX"
        },
        {
          "jobposition": "ตำแหน่งงาน: วิศวกรรมซอฟต์แวร์",
          "companyname": "บริษัท XYZ จำกัด",
          "provincethai": "จังหวัด: เชียงใหม่",
          "posted_date": "วันที่โพสต์: 15 พฤษภาคม 2566",
          "visited_number": "ผู้เข้าชม: 50 ครั้ง",
          "salary": "เงินเดือน: 35,000 บาท",
          "is_argent": "",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 2 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท XYZ จำกัด กำลังมีความต้องการสำหรับตำแหน่ง วิศวกรรมซอฟต์แวร์ ในพื้นที่จังหวัดเชียงใหม่ โดยต้องการวิศวกรรมที่มีความเชี่ยวชาญในการพัฒนาโปรแกรมภาษา C++ จำนวน 2 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 053-XXXXXX"
        },
        {
          "jobposition": "ตำแหน่งงาน: ผู้จัดการโครงการ",
          "companyname": "บริษัท 123 จำกัด",
          "provincethai": "จังหวัด: ภูเก็ต",
          "posted_date": "วันที่โพสต์: 10 พฤษภาคม 2566",
          "visited_number": "ผู้เข้าชม: 80 ครั้ง",
          "salary": "เงินเดือน: 50,000 บาท",
          "is_argent": "ด่วน",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 1 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท 123 จำกัด กำลังมีความต้องการสำหรับตำแหน่ง ผู้จัดการโครงการ ในพื้นที่จังหวัดภูเก็ต โดยต้องการผู้จัดการที่มีประสบการณ์ในการดูแลโครงการต่างๆ จำนวน 1 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 076-XXXXXX"
        },
        {
          "jobposition": "ตำแหน่งงาน: ช่างไฟฟ้า",
          "companyname": "บริษัท UVW จำกัด",
          "provincethai": "จังหวัด: นครราชสีมา",
          "posted_date": "วันที่โพสต์: 5 พฤษภาคม 2566",
          "visited_number": "ผู้เข้าชม: 70 ครั้ง",
          "salary": "เงินเดือน: 20,000 บาท",
          "is_argent": "",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 5 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท UVW จำกัด กำลังมีความต้องการสำหรับตำแหน่ง ช่างไฟฟ้า ในพื้นที่จังหวัดนครราชสีมา โดยต้องการช่างไฟฟ้าที่มีความเชี่ยวชาญในการติดตั้งและซ่อมบำรุงระบบไฟฟ้า จำนวน 5 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 044-XXXXXX"
        },
        {
          "jobposition": "ตำแหน่งงาน: นักตลาด",
          "companyname": "บริษัท LMN จำกัด",
          "provincethai": "จังหวัด: สมุทรปราการ",
          "posted_date": "วันที่โพสต์: 1 พฤษภาคม 2566",
          "visited_number": "ผู้เข้าชม: 90 ครั้ง",
          "salary": "เงินเดือน: 30,000 บาท",
          "is_argent": "ด่วน",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 2 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท LMN จำกัด กำลังมีความต้องการสำหรับตำแหน่ง นักตลาด ในพื้นที่จังหวัดสมุทรปราการ โดยต้องการนักตลาดที่มีประสบการณ์ในการตลาดสินค้าและบริการ จำนวน 2 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 02-XXXXXX"
        },
        {
          "jobposition": "ตำแหน่งงาน: พนักงานบัญชี",
          "companyname": "บริษัท EFG จำกัด",
          "provincethai": "จังหวัด: เพชรบูรณ์",
          "posted_date": "วันที่โพสต์: 25 เมษายน 2566",
          "visited_number": "ผู้เข้าชม: 60 ครั้ง",
          "salary": "เงินเดือน: 18,000 บาท",
          "is_argent": "",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 3 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท EFG จำกัด กำลังมีความต้องการสำหรับตำแหน่ง พนักงานบัญชี ในพื้นที่จังหวัดเพชรบูรณ์ โดยต้องการพนักงานบัญชีที่มีความเชี่ยวชาญในการจัดทำรายงานบัญชีและภาษี จำนวน 3 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 055-XXXXXX"
        },
        {
          "jobposition": "ตำแหน่งงาน: นักออกแบบกราฟิก",
          "companyname": "บริษัท HIJ จำกัด",
          "provincethai": "จังหวัด: สุราษฎร์ธานี",
          "posted_date": "วันที่โพสต์: 20 เมษายน 2566",
          "visited_number": "ผู้เข้าชม: 75 ครั้ง",
          "salary": "เงินเดือน: 28,000 บาท",
          "is_argent": "",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 1 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท HIJ จำกัด กำลังมีความต้องการสำหรับตำแหน่ง นักออกแบบกราฟิก ในพื้นที่จังหวัดสุราษฎร์ธานี โดยต้องการนักออกแบบที่มีความเชี่ยวชาญในการสร้างสรรค์งานกราฟิก จำนวน 1 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 077-XXXXXX"
        },
        {
          "jobposition": "ตำแหน่งงาน: พนักงานบริการลูกค้า",
          "companyname": "บริษัท KLM จำกัด",
          "provincethai": "จังหวัด: ชลบุรี",
          "posted_date": "วันที่โพสต์: 15 เมษายน 2566",
          "visited_number": "ผู้เข้าชม: 85 ครั้ง",
          "salary": "เงินเดือน: 15,000 บาท",
          "is_argent": "ด่วน",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 2 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท KLM จำกัด กำลังมีความต้องการสำหรับตำแหน่ง พนักงานบริการลูกค้า ในพื้นที่จังหวัดชลบุรี โดยต้องการพนักงานบริการลูกค้าที่มีความสามารถในการให้คำแนะนำและบริการลูกค้า จำนวน 2 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 038-XXXXXX"
        },
        {
          "jobposition": "ตำแหน่งงาน: วิศวกรรมสิ่งแวดล้อม",
          "companyname": "บริษัท NOP จำกัด",
          "provincethai": "จังหวัด: ขอนแก่น",
          "posted_date": "วันที่โพสต์: 10 เมษายน 2566",
          "visited_number": "ผู้เข้าชม: 65 ครั้ง",
          "salary": "เงินเดือน: 40,000 บาท",
          "is_argent": "ด่วน",
          "quantity_of_position": "จำนวนตำแหน่งงาน: 2 ตำแหน่ง",
          "description": "รายละเอียดงาน: บริษัท NOP จำกัด กำลังมีความต้องการสำหรับตำแหน่ง วิศวกรรมสิ่งแวดล้อม ในพื้นที่จังหวัดขอนแก่น โดยต้องการวิศวกรที่มีความเชี่ยวชาญในการวิเคราะห์และประเมินผลสิ่งแวดล้อม จำนวน 2 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 043-XXXXXX"
        },
        {
            "jobposition": "ตำแหน่งงาน: วิศวกรรมสิ่งแวดล้อม",
            "companyname": "บริษัท KNG จำกัด",
            "provincethai": "จังหวัด: เชียงใหม่",
            "posted_date": "วันที่โพสต์: 10 เมษายน 2566",
            "visited_number": "ผู้เข้าชม: 65 ครั้ง",
            "salary": "เงินเดือน: 40,000 บาท",
            "is_argent": "ด่วน",
            "quantity_of_position": "จำนวนตำแหน่งงาน: 2 ตำแหน่ง",
            "description": "รายละเอียดงาน: บริษัท NOP จำกัด กำลังมีความต้องการสำหรับตำแหน่ง วิศวกรรมสิ่งแวดล้อม ในพื้นที่จังหวัดขอนแก่น โดยต้องการวิศวกรที่มีความเชี่ยวชาญในการวิเคราะห์และประเมินผลสิ่งแวดล้อม จำนวน 2 ตำแหน่ง รายละเอียดเพิ่มเติมสามารถติดต่อได้ที่หมายเลขโทรศัพท์ 043-XXXXXX"
          }
      ]
  }
  