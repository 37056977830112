<template>
  <div class="pa-5">
    <v-card rounded elevation="1" class="px-5 py-5">
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-row>
            <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">สังกัด</h4>
              <v-row no-gutters align-content="end">
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="request_data.SchoolType === 0 ? 'primary' : 'input'"
                    @click="
                      request_data.SchoolType = 0;
                      loadSchools();
                    "
                    height="52px"
                    width="100%"
                  >
                    ทั้งหมด
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="request_data.SchoolType === 1 ? 'primary' : 'input'"
                    @click="
                      request_data.SchoolType = 1;
                      loadSchools();
                    "
                    width="100%"
                    height="52px"
                  >
                    ภาครัฐ
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="request_data.SchoolType === 2 ? 'primary' : 'input'"
                    @click="
                      request_data.SchoolType = 2;
                      loadSchools();
                    "
                    width="100%"
                    height="52px"
                  >
                    ภาคเอกชน
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <!-- <v-col md="4" class="custom-col mb-0 pb-0">
              <v-select
                :items="schooltypelist"
                v-model="request_data.SchoolType"
                text="text"
                value="value"
                @change="loadSchools()"
                solo
              ></v-select>
            </v-col> -->
            <v-col lg="4" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">ภูมิภาค</h4>

              <v-select
                :label="request_data.RegionAsVEC ? '' : 'ภูมิภาค'"
                :items="regionsNames"
                clearable
                v-model="request_data.RegionAsVEC"
                @change="loadSchools()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
              >
              </v-select>
            </v-col>
            <v-col lg="4" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">จังหวัด</h4>
              <v-autocomplete
                :label="request_data.ProvinceID ? '' : 'จังหวัด'"
                :items="provinces"
                item-text="provincethai"
                item-value="provinceid"
                clearable
                v-model="request_data.ProvinceID"
                @change="loadSchools()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card rounded elevation="0">
      <SearchableDataTable
        table_caption="รายชื่อสถานศึกษา"
        :data_items="schools"
        :datatable_headers="schools_data_table_headers"
      >
        <template v-slot:item.action="{ item }">
          <v-row class="my-1 custom-width">
            <v-tooltip top color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="goToSchoolStudentsView(item)"
                  color="info"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-account-group</v-icon>
                </v-btn>
              </template>
              <span>รายชื่อนักศึกษา</span>
            </v-tooltip>
            <v-tooltip top color="warning">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="goToSchoolDetailView(item)"
                  color="warning"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-edit</v-icon>
                </v-btn>
              </template>
              <span>แก้ไขข้อมูลสถานศึกษา</span>
            </v-tooltip>
          </v-row>
        </template>
      </SearchableDataTable>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";

const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "infoSchoolListView",
  data: () => ({
    schoolType: undefined,
    selected_schoolType: undefined,
    regionsNames: undefined,
    selected_regionName: undefined,
    provinces: undefined,
    selected_province: undefined,
    request_data: new GetSchools_Reqeust(),
    search: "",
    isImporting: false,

    search: "",

    schooltypelist: [
      { text: "ภาครัฐ/ภาคเอกชน", value: 0 },
      { text: "ภาครัฐ", value: 1 },
      { text: "ภาคเอกชน", value: 2 },
    ],

    schools_data_table_headers: [
      { text: "รหัส", value: "schoolid" },
      { text: "ชื่อ", value: "schoolname" },
      { text: "Email", value: "email" },
      { text: "Website", value: "website" },
      { text: "ตัวเลือก", value: "action", justify: "center", sortable: false },
    ],
    schools: undefined,
  }),
  methods: {
    async importSchools() {
      this.isImporting = true;
      await schoolDataProvider
        .importSchools()
        .then((result) => {
          this.onImportSchoolsCompleted();
        })
        .catch((err) => {
          this.isImporting = false;
          alert(err);
        });
    },

    async loadRegions() {
      await commonDataProvider.getRegionAsVECNames().then((result) => {
        this.regionsNames = result.data;
      });
    },

    async loadProvinces() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
    },

    async loadSchools() {
      localStorage.removeItem("Schools");
      this.$store.dispatch("showLoading");
      await schoolDataProvider
        .loadSchools(this.request_data)
        .then((result) => {
          this.schools = result.data;
          if (this.request_data.RegionAsVEC) {
            masterDataProvider
              .loadGetProvincesByRegionAsVECName(this.request_data.RegionAsVEC)
              .then((result) => {
                this.provinces = result.data;
              });
          } else if (!this.request_data.RegionAsVEC) {
            masterDataProvider.loadGetProvinces().then((result) => {
              this.provinces = result.data;
            });
          }
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },

    goToSchoolDetailView: function (school) {
      var schoolID = school.schoolid;
      this.$router
        .push({ name: "SchoolDetailView", params: { schoolID: schoolID } })
        .catch((err) => {});
    },
    goToSchoolStudentsView: function (school) {
      var schoolID = school.schoolid;
      this.$router
        .push({ name: "SchoolStudentsView", params: { schoolID: schoolID } })
        .catch((err) => {});
    },
    onImportSchoolsCompleted() {
      this.isImporting = false;
      this.loadSchools();
    },
  },
  async created() {
    await this.loadRegions();
    await this.loadProvinces();
    await this.loadSchools();
    this.request_data.SchoolType = 0;
    console.log(this.$store.getters.rolecodes);
  },
  components: { SearchableDataTable },
};
</script>

<style scoped>
.custom-width {
  width: 100px;
  gap: 20px;
}
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
