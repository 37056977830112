<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="d-flex justify-center mt-2 mb-2">
          <h2 class="">ข้อมูลสถานประกอบการ</h2>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="8">
            <v-card>
              <v-toolbar
                class="d-flex align-center"
                style="height: 50px"
                flat
                color="primary"
                dark
              >
                <v-toolbar-title class="d-flex align-center"
                  >ข้อมูลสถานประกอบการ</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      :rules="[(v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ']"
                      label="ชื่อสถานประกอบการ *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="ประเภทสมาชิก"
                      :items="['บุคลลธรรมดา', 'นิติบุคคล', 'หน่วยงานราชการ']"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="เลขบัตรประชาชน *"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="ประกันสังคมเลขที่"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12">
                    <v-select
                      :rules="[(v) => !!v || 'กรุณาระบุประเภทวิสาหกิจ']"
                      label="ประเภทวิสาหกิจ"
                      :items="employerMainBusinessTypes"
                      item-value="code"
                      item-text="nameth"
                      v-model="vm.selected_employermainbusinesstypecode"
                      @change="loadEmployerBusinessTypes"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" sm="12">
                    <v-select
                      clearable
                      label="ประเภทธุรกิจ"
                      :items="employerBusinessTypes"
                      item-value="code"
                      item-text="nameth"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea label="รายละเอียดสถานประกอบการ"> </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea label="สวัสดิการ"> </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12" md="6" lg="6">
                    <v-file-input
                      label="หลักฐานการจดทะเบียน"
                      @change="onPhotoChange"
                      v-model="photoFiles"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- <v-row class="mt-2">
              <v-col cols="12">
                <div class="d-flex justify-end">
                  <v-btn color="primary" @click="openPopupCreateContact"
                    ><v-icon left> mdi-plus </v-icon>เพิ่มผู้ประสานงาน</v-btn
                  >
                </div>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="8">
            <v-card>
              <v-toolbar
                class="d-flex align-center"
                style="height: 50px"
                flat
                color="primary"
                dark
              >
                <v-toolbar-title>ที่อยู่สถานประกอบการ</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="ที่อยู่"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                      clearable
                      label="จังหวัด"
                      :items="provinces"
                      item-text="provincethai"
                      item-value="provinceid"
                      @change="loadDisticts"
                      v-model="vm.selected_province_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      clearable
                      label="อำเภอ"
                      :items="districts"
                      item-text="districtthai"
                      item-value="districtid"
                      @change="loadTambons"
                      v-model="vm.selected_district_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      clearable
                      label="ตำบล"
                      :items="tambons"
                      item-text="tambonthai"
                      item-value="tambonid"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="รหัสไปรษณีย์" readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="โทรศัพท์"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="โทรสาร"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="อีเมล *"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="ยืนยันอีเมล *"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="Website"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6" md="6" lg="6">
                    <v-file-input
                      accept="image/png, image/jpeg"
                      prepend-icon="mdi-folder-multiple-image"
                      label="โลโก้"
                      @change="onPhotoChange"
                      v-model="photoFiles"
                    ></v-file-input>
                  </v-col>
                  <v-col sm="6" md="6" lg="6">
                    <v-file-input
                      prepend-icon="mdi-map-marker-radius"
                      label="แผนที่"
                      @change="onPhotoChange"
                      v-model="photoFiles"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="ละติจูด"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="ลองติจูด"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="8">
            <v-card>
              <v-toolbar
                class="d-flex align-center"
                style="height: 50px"
                flat
                color="primary"
                dark
              >
                <v-toolbar-title>ข้อมูลผู้ประสานงานของสถานประกอบการ</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      clearable
                      label="คำนำหน้าชื่อ"
                      :items="titlename"
                      item-text="titlenameth"
                      item-value="titlenameid"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="ชื่อ *"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="นามสกุล *"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="ตำแหน่ง"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="โทรศัพท์"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row class="justify-center text-center mt-4 mb-4">
              <v-col cols="12">
                <v-btn color="secondary" class="ma-2" @click="save"
                  ><v-icon>mdi-content-save</v-icon> บันทึก
                </v-btn>
                <v-btn color="" @click="$router.go(-1)" class="ma-2"> ย้อนกลับ</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { RegisterEmployerRequest } from "@/models/RegisterEmployer/RegisterEmployerRequest";
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  name: "RegisterCompany",
  data() {
    return {
      vm: new RegisterEmployerRequest(),
      provinces: undefined,
      districts: undefined,
      tambons: undefined,
      postcode: undefined,
      employerMainBusinessTypes: undefined,
      employerBusinessTypes: undefined,
      titlename: undefined,
      valid: true,
      photoFiles: [],
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        if (confirm("ต้องการบันทึกหรือไม่?")) {
        }
      }
    },
    onPhotoChange() {},
    async initialize() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
      await masterDataProvider.loadEmployerMainBusinessTypes().then((result) => {
        this.employerMainBusinessTypes = result.data;
      });
      await masterDataProvider.getActiveTitleNames().then((result) => {
        this.titlename = result.data;
      });
    },
    async loadDisticts() {
      if (this.vm.selected_province_id != undefined) {
        await masterDataProvider
          .loadGetDistricts(this.vm.selected_province_id)
          .then((result) => {
            this.districts = result.data;
          });
      }
    },
    async loadTambons() {
      if (this.vm.selected_district_id != undefined) {
        await masterDataProvider
          .loadGetTambons(this.vm.selected_district_id)
          .then((result) => {
            this.tambons = result.data;
          });
      }
    },
    async loadEmployerBusinessTypes() {
      if (this.vm.selected_employermainbusinesstypecode != undefined) {
        await masterDataProvider
          .loadEmployerBusinessTypes(this.vm.selected_employermainbusinesstypecode)
          .then((result) => {
            this.employerBusinessTypes = result.data;
          });
      }
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.v-file-input {
  cursor: pointer;
}
</style>
