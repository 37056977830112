import EmailConfigurationDetailView from "@/views/EmailConfiguration/EmailConfigurationDetailView.vue";

export default [
  {
    path: "/EmailConfiguration",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/EmailConfiguration/EmailConfigurationDetailView",
        name: "EmailConfigurationDetailView",
        component: EmailConfigurationDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "ตั้งค่าเกี่ยวกับอีเมล",
        },
      },
    ],
  },
];
