var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center"},[_c('v-container',{staticClass:"ma-4"},[_c('v-card',[_c('v-card-title',[_c('h4',[_c('v-icon',[_vm._v("mdi-bell")]),_vm._v(" ตั้งค่าการแจ้งเตือน")],1)]),(_vm.notificationSetups)?_c('v-card-text',[_c('v-row',{staticClass:"my-3"},[_c('v-col',{staticClass:"custom-col",attrs:{"cols":"7"}}),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('h3',{staticStyle:{"font-weight":"bold"}},[_vm._v("อีเมล")])])],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('h3',{staticStyle:{"font-weight":"bold"}},[_vm._v("ผ่านแอปพลิเคชัน")])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticStyle:{"font-weight":"bold"}},[_vm._v(" การแจ้งเตือนเกี่ยวกับการติดตามภาวะการมีงานทำ ")])])],1),_vm._l((_vm.notificationSetups.filter(
            (x) =>
              x.notificationeventkey ==
              'GraduatedStudentJobTrackingReportInComming_Notify'
          )),function(item){return _c('v-row',{key:item.notificationeventkey,staticClass:"my-3"},[_c('v-col',{staticClass:"custom-col",attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.notificationeventname)+" ")]),_c('span',{},[_vm._v(_vm._s(item.description))])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseEmailNotifyStatus(item)}},model:{value:(item.useemailnotification),callback:function ($$v) {_vm.$set(item, "useemailnotification", $$v)},expression:"item.useemailnotification"}})],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseInApplicationNotifyStatus(item)}},model:{value:(item.useinappnotification),callback:function ($$v) {_vm.$set(item, "useinappnotification", $$v)},expression:"item.useinappnotification"}})],1)],1)}),_vm._l((_vm.notificationSetups.filter(
            (x) =>
              x.notificationeventkey ==
              'GraduatedStudentJobTrackingReportStudentImported'
          )),function(item){return _c('v-row',{key:item.notificationeventkey,staticClass:"my-3"},[_c('v-col',{staticClass:"custom-col",attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.notificationeventname)+" ")]),_c('span',{},[_vm._v(_vm._s(item.description))])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseEmailNotifyStatus(item)}},model:{value:(item.useemailnotification),callback:function ($$v) {_vm.$set(item, "useemailnotification", $$v)},expression:"item.useemailnotification"}})],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseInApplicationNotifyStatus(item)}},model:{value:(item.useinappnotification),callback:function ($$v) {_vm.$set(item, "useinappnotification", $$v)},expression:"item.useinappnotification"}})],1)],1)}),_vm._l((_vm.notificationSetups.filter(
            (x) =>
              x.notificationeventkey ==
              'GraduatedStudentJobTrackingReport15DaysLeft_Notify'
          )),function(item){return _c('v-row',{key:item.notificationeventkey,staticClass:"my-3"},[_c('v-col',{staticClass:"custom-col",attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.notificationeventname)+" ")]),_c('span',{},[_vm._v(_vm._s(item.description))])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseEmailNotifyStatus(item)}},model:{value:(item.useemailnotification),callback:function ($$v) {_vm.$set(item, "useemailnotification", $$v)},expression:"item.useemailnotification"}})],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseInApplicationNotifyStatus(item)}},model:{value:(item.useinappnotification),callback:function ($$v) {_vm.$set(item, "useinappnotification", $$v)},expression:"item.useinappnotification"}})],1)],1)}),_vm._l((_vm.notificationSetups.filter(
            (x) =>
              x.notificationeventkey ==
              'GraduatedStudentJobTrackingReport7DaysLeft_Notify'
          )),function(item){return _c('v-row',{key:item.notificationeventkey,staticClass:"my-3"},[_c('v-col',{staticClass:"custom-col",attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.notificationeventname)+" ")]),_c('span',{},[_vm._v(_vm._s(item.description))])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseEmailNotifyStatus(item)}},model:{value:(item.useemailnotification),callback:function ($$v) {_vm.$set(item, "useemailnotification", $$v)},expression:"item.useemailnotification"}})],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseInApplicationNotifyStatus(item)}},model:{value:(item.useinappnotification),callback:function ($$v) {_vm.$set(item, "useinappnotification", $$v)},expression:"item.useinappnotification"}})],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticStyle:{"font-weight":"bold"}},[_vm._v(" การแจ้งเตือนเกี่ยวกับระบบศูนย์เครือข่ายกำลังคน ")])])],1),_vm._l((_vm.notificationSetups.filter(
            (x) => x.notificationeventkey == 'JobPostingHasNewStudentRegistration'
          )),function(item){return _c('v-row',{key:item.notificationeventkey,staticClass:"my-3"},[_c('v-col',{staticClass:"custom-col",attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.notificationeventname)+" ")]),_c('span',{},[_vm._v(_vm._s(item.description))])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseEmailNotifyStatus(item)}},model:{value:(item.useemailnotification),callback:function ($$v) {_vm.$set(item, "useemailnotification", $$v)},expression:"item.useemailnotification"}})],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseInApplicationNotifyStatus(item)}},model:{value:(item.useinappnotification),callback:function ($$v) {_vm.$set(item, "useinappnotification", $$v)},expression:"item.useinappnotification"}})],1)],1)}),_vm._l((_vm.notificationSetups.filter(
            (x) => x.notificationeventkey == 'JobPostingNotCompletedStatusNotification'
          )),function(item){return _c('v-row',{key:item.notificationeventkey,staticClass:"my-3"},[_c('v-col',{staticClass:"custom-col",attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.notificationeventname)+" ")]),_c('span',{},[_vm._v(_vm._s(item.description))])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseEmailNotifyStatus(item)}},model:{value:(item.useemailnotification),callback:function ($$v) {_vm.$set(item, "useemailnotification", $$v)},expression:"item.useemailnotification"}})],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseInApplicationNotifyStatus(item)}},model:{value:(item.useinappnotification),callback:function ($$v) {_vm.$set(item, "useinappnotification", $$v)},expression:"item.useinappnotification"}})],1)],1)}),_vm._l((_vm.notificationSetups.filter(
            (x) => x.notificationeventkey == 'JobApplicationHasChangeStatus'
          )),function(item){return _c('v-row',{key:item.notificationeventkey,staticClass:"my-3"},[_c('v-col',{staticClass:"custom-col",attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.notificationeventname)+" ")]),_c('span',{},[_vm._v(_vm._s(item.description))])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseEmailNotifyStatus(item)}},model:{value:(item.useemailnotification),callback:function ($$v) {_vm.$set(item, "useemailnotification", $$v)},expression:"item.useemailnotification"}})],1),_c('v-col',{staticClass:"d-flex justify-center ml-2"},[_c('v-switch',{attrs:{"color":"success","inset":""},on:{"change":function($event){return _vm.updateUseInApplicationNotifyStatus(item)}},model:{value:(item.useinappnotification),callback:function ($$v) {_vm.$set(item, "useinappnotification", $$v)},expression:"item.useinappnotification"}})],1)],1)})],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }