<template>
  <div>
    <!-- <appbar></appbar> -->
    <v-container class="d-flex justify-center mt-5">
      <v-col sm="12" md="12" lg="12" xl="8" cols="12">
        <v-row>
          <v-col
            v-for="item in filteredListMenu"
            :key="item.id"
            cols="12"
            lg="6"
            md="6"
            sm="12"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                rounded="lg"
                class="pa-5 ma-2 hover-card d-flex align-center"
                :elevation="hover ? 12 : 2"
                @click="navigate(item.path)"
                :class="{ 'on-hover': hover }"
                link
                height="120px"
              >
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="1"
                      class="truncate d-flex align-center justify-center"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-col>
                    <v-col>
                      <div>{{ item.title }} {{ item.title2 }}</div>
                      <!-- <span class="ml-2">{{ item.title }}</span>
                      <br />
                      <span class="ml-2" v-if="item.title2">{{
                        item.title2
                      }}</span> -->
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    :opacity="0"
                    color="#fff"
                    class="primary--text align-end"
                  >
                    <!-- <v-card-title class="menu-card-title">
                    <v-text>{{ item.title }}</v-text>
                  </v-card-title> -->
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </v-hover>
          </v-col>
        </v-row></v-col
      >
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
import { title } from "devexpress-reporting/dx-webdocumentviewer";
export default {
  name: "ReportGroupListView",
  components: { appbar },
  data() {
    return {
      // items: Array.from({ length: 8 }) // สร้าง array ที่มี 8 รายการ
      listMenu: [
        {
          id: 1,
          icon: "mdi-file",
          title: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          title2: "(รายภาค)",
          path: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report_DetailView",
        },
        {
          id: 2,
          icon: "mdi-file-chart",
          title: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          title2: "(รายจังหวัด)",
          path: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report_DetailView",
        },
        {
          id: 3,
          icon: "mdi-file-chart",
          title: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          title2: "(รายสถาบันการอาชีวศึกษา)",
          path: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report_DetailView",
        },
        {
          id: 4,
          icon: "mdi-file-chart",
          title: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          title2: "(รายสถานศึกษา)",
          path: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report_DetailView",
        },
        {
          id: 5,
          icon: "mdi-file-document",
          title: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          title2: "(รายประเภทวิชา)",
          path: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
        },
        {
          id: 6,
          icon: "mdi-file-document",
          title: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          title2: "(จำแนกตามสาขาวิชา)",
          path: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView",
        },
        {
          id: 7,
          icon: "mdi-file-account",
          title: "รายงานสรุปผลการติดตาม (รายบุคคล)",
          path: "GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView",
        },
        {
          id: 8,
          icon: "mdi-file-account",
          title: "รายงานรายชื่อนักศึกษา",
          path: "SchoolStudentList_Report",
        },
        {
          id: 9,
          icon: "mdi-file-chart-outline",
          title: "รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)",
          path: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView",
        },
        {
          id: 10,
          icon: "mdi-file-document",
          title: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          title2: "(รายประเภทวิชา)",
          path: "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report_DetailView",
        },
        {
          id: 11,
          icon: "mdi-file-document",
          title: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          title2: "(จำแนกตามสาขาวิชา)",
          path: "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report_DetailView",
        },
        {
          id: 12,
          icon: "mdi-file-account",
          title: "รายงานสรุปผลการติดตาม (รายบุคคล)",
          path: "School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report_DetailView",
        },
        {
          id: 13,
          icon: "mdi-file-account",
          title: "รายงานรายชื่อนักศึกษา",
          path: "School_SchoolStudentList_Report",
        },
      ],
      roleIdMap: {
        Administrator: [1, 2, 3, 4, 5, 6, 7, 8],
        Center_Admin: [1, 2, 4, 5, 6, 7, 8],
        Center_Manager: [1, 2, 4, 5, 6, 7],
        Center_Officer: [1, 2, 4, 5, 6, 7],
        Insitution_Officer: [9],
        ProvinceSchool_Officer: [2, 4, 10, 11, 12],
        School_Admin: [10, 11, 12, 13],
        School_Guidance_Teacher: [10, 11, 12, 13],
        School_Manager: [6, 7, 10],
        School_Partner_Officer: [10, 11, 12],
      },
    };
  },
  methods: {
    navigate(to) {
      this.$router.push(to);
    },
  },
  computed: {
    filteredListMenu() {
      const uniqueIds = new Set();
      const rolecodes = this.$store.getters.rolecodes || [];

      rolecodes.forEach((role) => {
        if (this.roleIdMap[role]) {
          this.roleIdMap[role].forEach((id) => uniqueIds.add(id));
        }
      });

      return this.listMenu.filter((item) => uniqueIds.has(item.id));
    },
  },
  created() {
    console.log("Filtered listMenu: ", this.filteredListMenu);
  },
};
</script>

<style scoped>
.menuCarditile {
  justify-content: center;
  align-self: center;
  margin-top: 3px;
}
.v-card-title {
  display: flex;
  align-items: center;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
