import axios from "axios";
import { create_default_request_header } from "@/services/constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";

export class SchoolUserProfileDataProvider {
    constructor(apiBaseURL = "") {
        this.apiBaseURL = apiBaseURL;
    }

    loadSchoolUserProfile() {
        try {
            var header = create_default_request_header();
            var endpointUrl = "/api/AccountProfile/GetSchoolUserProfile";
            var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
            return axios.post(combinedURL, header);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    store.dispatch({ type: "doLogout" });
                }
                //  error: AxiosError<any, any>
                return error.message;
            } else {
                return "An unexpected error occurred";
            }
        }
    }
}