<template>
    <v-container class="pa-5">
        <SearchableDataTable table_caption="กล่องข้อความ" :data_items="employerInteractions"
            :datatable_headers="employerInteractions_table_headers">

            <template v-slot:item.position="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <span @click="goToSearchJobDetails(item)" class="info--text" style="cursor:pointer" v-bind="attrs" v-on="on">{{ item.position }}</span>
                    </template>
                    <span>รายละเอียด</span>
                </v-tooltip>
                
            </template>

            <template v-slot:item.status="{ item }">
                <v-chip class="ma-2" dark :color="getColor(item.status)">
                    {{ item.status }}
                </v-chip>
            </template>

            <template v-slot:item.action="{ item }">
                <v-tooltip top color="info">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="dialog = true" color="info" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi mdi-email</v-icon>
                        </v-btn>
                    </template>
                    <span>ข้อความตอบกลับ</span>
                </v-tooltip>
            </template>

            
        </SearchableDataTable>
         <v-dialog
                v-model="dialog"
                persistent
                max-width="450"
                >
                <template>
                    <v-card class="pa-6">
                        <v-row class="justify-center text-center">
                        <v-card-title> ข้อความตอบกลับ </v-card-title>
                        <v-col cols="12">
                            <v-btn @click="dialog = false" class="ma-2" color="grey" elevation="2" outlined>ตกลง</v-btn>
                    </v-col>
                    </v-row>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";

export default {
    name : 'StudentAndEmployerInteractions',
    data: () => ({
        schoolType: undefined,
        selected_schoolType: undefined,
        search: "",
        isImporting: false,

        search: "",

        employerInteractions_table_headers: [
            { text: "ตำแหน่ง", value: "position" },
            { text: "ชื่อสถานประกอบการ", value: "employer", },
            { text: "วันที่", value: "date", align : 'center' },
            { text: "สถานะ", value: "status", align : 'center' },
            { text: "ข้อความตอบกลับ", value: "action", align : 'center' }
        ],
        employerInteractions: [
          {
            position: 'ช่างยนต์ ออโตคลิก แยกสี่กอ กะทู้',
            employer: 'บริษัท ออโตคอร์ป โฮลดิ้ง จำกัด (มหาชน)',
            date: '1 พฤษภาคม 2566',
            status: 'นัดสัมภาษณ์',
          },
          {
            position: 'เจ้าหน้าที่ประสานงาน',
            employer: 'บริษัท ออโตคอร์ป โฮลดิ้ง จำกัด (มหาชน)',
            date: '5 พฤษภาคม 2566',
            status: 'กำลังพิจารณา',
          },
          {
            position: 'Programmer',
            employer: 'AGX co,th',
            date: '16 พฤษภาคม 2566',
            status: 'ปฏิเสธการรับเข้าทำงาน',
          },
        ],
        schools: undefined,
        dialog: false
    }),
    methods: {
        getColor (status) {
        if (status == 'นัดสัมภาษณ์') return 'info'
        else if (status == 'กำลังพิจารณา') return 'orange'
        else if (status == 'รับเข้าทำงาน') return 'green'
        else if (status == 'ปฏิเสธการรับเข้าทำงาน') return 'red'
        else return 'black'
      },
      goToSearchJobDetails: function () {
      this.$router.push({ name: "SearchJobDetails" }).catch((err) => {});
    },
    },
    components: { SearchableDataTable }
}
</script>

<style scoped>
.red-text {
  color: red;
}

</style>