import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';


export class ExcelExportService {
    constructor(apiBaseURL = '') {
        this.apiBaseURL = apiBaseURL;
    }

    createExcelFromJson(json) {
        const headers = json.headers;
        const headers_array = [];
        headers_array.push(headers);
        const datasource = json.datasource;
        const filename = json.filename;
        const dataWS = XLSX.utils.json_to_sheet(datasource, { skipHeader: true, origin: 'A2' });
        //Setting the comlumn
        dataWS['!cols'] = [];
        // dataWS['!cols'][1] = { };
        //Expand all columns
        // dataWS["!cols"] = [{ hidden: true }, { wch: 30 }, { wch: 30 }];
        let columnSettings = []
        for (let i = 0; i < headers.length; i++) {
            if (i == 0) {
                columnSettings.push({ hidden: true })
            } else {
                columnSettings.push({ wch: 30 })
            }
        }
        dataWS["!cols"] = columnSettings;

        const wb = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(dataWS, headers_array, { origin: 'A1' }); //Add header
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(wb, filename + '.xlsx')
    }

    createExcelTemplateFromJson(json){
             const headers = json.headers;
        const headers_array = [];
        headers_array.push(headers);
        const datasource = json.datasource;
        const filename = json.filename;
        const dataWS = XLSX.utils.json_to_sheet(datasource, { skipHeader: true, origin: 'A2' });
        //Setting the comlumn
        dataWS['!cols'] = [];
        // dataWS['!cols'][1] = { };
        //Expand all columns
        // dataWS["!cols"] = [{ hidden: true }, { wch: 30 }, { wch: 30 }];
        let columnSettings = []
        for (let i = 0; i < headers.length; i++) {
                columnSettings.push({ wch: 30 })
        }
        dataWS["!cols"] = columnSettings;

        const wb = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(dataWS, headers_array, { origin: 'A1' }); //Add header
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(wb, filename + '.xlsx')
    }

    GetGraduatedStudentJobTrackingReportStudentDetails(graduatedStudentJobTrackingReportOid = '') {
        const header = create_default_request_header();
        var endpointPath = "/api/excelexport/ExportGraduatedStudentJobTrackingReportStudentDetails/" + graduatedStudentJobTrackingReportOid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, header);
    }

    DownloadCenterStaffExcelTemplate(){
        const header = create_default_request_header();
        var endpointPath = "/api/excelexport/ExportCenterStaffProfileExcelTemplate";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.get(combinedURL, header);

    }

    DownloadGuidanceTeacherProfileExcelTemplate(){
        const header = create_default_request_header();
        var endpointPath = "/api/excelexport/ExportGuidanceTeacherProfileExcelTemplate";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.get(combinedURL, header);

    }

    DownloadManagerProfileExcelTemplate(){
        const header = create_default_request_header();
        var endpointPath = "/api/excelexport/ExportManagerProfileExcelTemplate";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.get(combinedURL, header);

    }

    DownloadSchoolStaffProfileExcelTemplate(){
        const header = create_default_request_header();
        var endpointPath = "/api/excelexport/ExportSchoolStaffProfileExcelTemplate";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.get(combinedURL, header);

    }

    DownloadTStaffProfileExcelTemplate(){
        const header = create_default_request_header();
        var endpointPath = "/api/excelexport/ExportTStaffProfileExcelTemplate";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.get(combinedURL, header);

    }
}