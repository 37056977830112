<template>
  <div>
    <v-app-bar color="indigo" app dark>
      <div class="d-flex align-center">
        <router-link to="/home">
          <v-img
            alt="VEC Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="50"
          />
        </router-link>
        <router-link to="/home">
          <v-img
            alt="สำนักงานคณะกรรมการการอาชีวศึกษา"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            src="@/assets/logo-name.png"
            width="100%"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <template>
        <div class="pr-3" v-if="$vuetify.theme.dark">
          <v-switch
            v-model="$vuetify.theme.dark"
            label="โหมดกลางคืน"
            inset
            persistent-hint
            hint="สลับโหมดกลางวัน/กลางคืน"
            color="primary"
          >
          </v-switch>
        </div>
        <div class="pr-3" v-else>
          <v-switch
            v-model="$vuetify.theme.dark"
            label="โหมดกลางวัน"
            inset
            persistent-hint
            hint="สลับโหมดกลางวัน/กลางคืน"
            color="primary"
          >
          </v-switch>
        </div>
      </template>
    </v-app-bar>
    <v-container v-if="validstate == 1">
      <v-alert
        class="mb-10"
        outlined
        icon="mdi-shield"
        type="info"
        prominent
        border="left"
      >
        <span>กำลังตรวจสอบ url กรุณารอสักครู่ ...</span>
      </v-alert>
    </v-container>
    <v-container v-if="validstate == 2">
      <v-alert class="mb-10" outlined type="warning" prominent border="left">
        <span
          >url ไม่ถูกต้อง หรือ หมดอายุการใช้งาน หากท่านต้องการเปลี่ยนรหัสผ่าน
          กรุณาส่งคำขอใหม่</span
        >
      </v-alert>
    </v-container>
    <v-container v-if="validstate == 3">
      <v-card
        elevation="2"
        class="mx-auto my-12 justify-center text-center pa-4"
        max-width="450"
      >
        <div class="pt-5">
          <h3>กำหนดรหัสผ่าน (Reset Password)</h3>
          <v-divider class="mt-4 mb-4 mx-0"></v-divider>
          <div class="py-4">
            <span class="secondary--text font-size:16px"
              >ป้อนรหัสผ่านใหม่ของคุณ</span
            >
          </div>
        </div>

        <div>
          <v-alert
            v-if="isChangePasswordSuccess == true"
            class="mb-10"
            outlined
            type="success"
            text
          >
            <span>เปลี่ยนรหัสผ่านเรียบร้อยแล้ว</span>
          </v-alert>
          <v-alert
            v-if="isChangePasswordSuccess == false"
            class="mb-10"
            outlined
            type="warning"
            prominent
            border="left"
          >
            <span>เปลี่ยนรหัสผ่านไม่สำเร็จ</span>
          </v-alert>
        </div>

        <div>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            lazy-validation
          >
            <v-text-field
              v-model="username"
              :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้ใช้']"
              label="ชื่อบัญชีผู้ใช้"
              prepend-icon="mdi-account"
              disabled
            >
            </v-text-field>
            <v-text-field
              v-model="account.newpassword"
              label="รหัสผ่าน"
              name="newpassword"
              prepend-icon="mdi-key"
              :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
              @click:append="isShowPassword = !isShowPassword"
              :type="isShowPassword ? 'text' : 'password'"
              :rules="[
                (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                (v) => (v && v.length >= 6) || 'ต้องมีอย่างน้อย 6 ตัวอักษร',
              ]"
            >
            </v-text-field>
            <v-text-field
              v-model="account.confirmNewPassword"
              :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
              @click:append="isShowPassword = !isShowPassword"
              :type="isShowPassword ? 'text' : 'password'"
              prepend-icon="mdi-key"
              label="ยืนยันรหัสผ่าน"
              :rules="[
                (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                (v) => (v && v.length >= 6) || 'ต้องมีอย่างน้อย 6 ตัวอักษร',
                (v) => v === account.newpassword || 'รหัสผ่านไม่ตรง',
              ]"
              name="confirmNewPassword"
            >
            </v-text-field>

            <div class="mt-10 mb-5">
              <v-btn color="success" block type="submit" class="my-4">
                <v-icon class="mr-2">mdi-check-circle-outline</v-icon
                ><span>ยืนยัน</span>
              </v-btn>
              <v-btn
                color="secondary"
                text
                block
                dark
                class="my-4"
                @click="gotoLogin()"
              >
                <span>เข้าสู่ระบบติดตามภาวะการมีงานทำ</span>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { PasswordManagementDataProvider } from "@/services/PasswordManagementDataProvider";
var passwordManagementDataProvider = new PasswordManagementDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import router from "../../router";
export default {
  name: "renewpassword",
  async mounted() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let token = this.$route.query.token;
    this.token = token;
    this.loginpage = this.$route.query.login;
    try {
      await passwordManagementDataProvider
        .validationToken(this.token)
        .then((response) => {
          setTimeout(() => {
            this.validstate = 3;
          }, 1000);
          this.username = response.data.username;
        })
        .catch((response) => {
          setTimeout(() => {
            this.validstate = 2;
          }, 1000);
        });
    } catch (error) {}
  },
  data() {
    return {
      isShowPassword: false,
      valid: false,
      allowSpaces: false,
      account: [],
      token: "",
      page: true,
      username: "",
      isChangePasswordSuccess: undefined,
      validstate: 1,
      loginpage: "",
    };
  },
  watch: {},
  methods: {
    async submit() {
      var validate = this.$refs.form.validate();

      if (validate) {
        await passwordManagementDataProvider
          .resetpassword(this.account, this.token)
          .then((result) => {
            this.page = false;
            this.isChangePasswordSuccess = true;
            this.$router.push({
              name: "RedirectLogin",
              params: { loginpage: this.loginpage },
            });
            //this.$toast.success("เปลี่ยนรหัสผ่านเรียบร้อย")
          })
          .catch((err) => {
            this.isChangePasswordSuccess = false;
            this.$toast.error("เปลี่ยนรหัสผ่านไม่สำเร็จ: " + err);
          });
      }
    },
    gotoLogin() {
      this.$router.push("/home");
    },
  },
  components: {},
};
</script>

<style></style>
