import Login from "@/views/Login/Login.vue";
import LoginOfficer from "@/views/Login/LoginOfficer.vue";
import LoginTeacher from "@/views/Login/LoginTeacher.vue";
import LoginStudent from "@/views/Login/LoginStudent.vue";
import LoginEmployer from "@/views/Login/LoginEmployer.vue";
import SetPassword from "@/views/Login/SetPassword.vue";
import Register from "@/views/Login/Register.vue";
import recoveryindentifySchoolEmployee from "@/views/Login/recoveryindentifySchoolEmployee.vue";
import recoveryindentifyEmployee from "@/views/Login/recoveryindentifyEmployee.vue";
import recoveryindentifyStudent from "@/views/Login/recoveryindentifyStudent.vue";
import renewpassword from "@/views/Login/renewpassword.vue";
import EmployerResetPassword from "@/views/Login/EmployerResetPassword.vue";
import RedirectLogin from "@/views/Login/redirectLogin.vue"

export default [
  {
    path: "/",
    component: () => import("@/layouts/default/DefaultTemplate.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/LoginOfficer",
        name: "LoginOfficer",
        component: LoginOfficer,
      },
      {
        path: "/LoginTeacher",
        name: "LoginTeacher",
        component: LoginTeacher,
      },
      {
        path: "/LoginStudent",
        name: "LoginStudent",
        component: LoginStudent,
      },
      {
        path: "/LoginEmployer",
        name: "LoginEmployer",
        component: LoginEmployer,
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
      },
      {
        path: "/recoveryindentifySchoolEmployee/:loginpage",
        name: "recoveryindentifySchoolEmployee",
        component: recoveryindentifySchoolEmployee,
      },
      {
        path: "/recoveryindentifyEmployee/:loginpage",
        name: "recoveryindentifyEmployee",
        component: recoveryindentifyEmployee,
      },
      {
        path: "/recoveryindentifyStudent",
        name: "recoveryindentifyStudent",
        component: recoveryindentifyStudent,
      },
      {
        path: "/SetPassword/:username",
        name: "SetPassword",
        component: SetPassword,
      },
      {
        path: "/renewpassword/",
        name: "renewpassword",
        component: renewpassword,
      },
      {
        path: "/EmployerResetPassword",
        name: "EmployerResetPassword",
        component: EmployerResetPassword,
      },
      {
        path: "/RedirectLogin/:loginpage",
        name: "RedirectLogin",
        component: RedirectLogin,
      },
    ],
  },
];
