<template>
  <div class="pa-4">
    <v-card class="pa-4">
      <h3 class="primary--text pb-5">
        นักศึกษาที่สำเร็จการศึกษา :
        <span v-if="schoolGraduatedStudent != undefined">{{
          schoolGraduatedStudent.school.schoolname
        }}</span>
      </h3>
      <v-row cols="12">
        <v-col cols="6" md="4">
          <v-select
            label="ระดับการศึกษา"
            :items="GradeNames"
            v-on:change="changeditem"
            v-model="selected.GradeNames"
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            label="ปีการศึกษาที่สมัคร"
            :items="EntranceYears"
            v-on:change="changeditem"
            v-model="selected.EntranceYears"
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            label="ปีการศึกษาที่สำเร็จการศึกษา"
            :items="GraduatedYears"
            v-on:change="changeditem"
            v-model="selected.GraduatedYears"
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            label="ประเภทวิชา"
            :items="subjectTypes"
            v-on:change="changeditem"
            v-model="selected.subjectTypes"
            item-value="subjecttypeid"
            item-text="subjecttypenameth"
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="4">
          <v-autocomplete
            label="สาขาวิชา"
            :items="majorNames"
            v-on:change="changeditem"
            v-model="selected.majorNames"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            label="ภาวะการมีงานทำ"
            v-on:change="changeditem"
            :items="employmentstatusnames"
            v-model="selected.employmentstatusnames"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row class="justify-end ma-2" cols="12">
        <v-btn class="ma-2" @click="resetfilter" color="secondary">ล้างการค้นหา</v-btn>
        <!-- <v-btn @click="(dialog = true)" dark color="success">
                    <v-icon>mdi-import</v-icon> นำเข้าข้อมูลนักศึกษาที่สำเร็จการศึกษา
                </v-btn> -->
      </v-row>
    </v-card>

    <SearchableDataTable
      v-if="graduatedStudents != undefined"
      table_caption="รายชื่อนักศึกษาที่สำเร็จการศึกษา"
      :data_items="graduatedStudents"
      :datatable_headers="graduatedStudents_data_table_headers"
    >
      <template v-slot:item.fullname="{ item }">
        <span>{{ item.firstname }} {{ item.surname }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openStudentDetailView(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>รายละเอียด</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
    <v-dialog v-model="dialog" width="550">
      <v-card>
        <v-card-text>
          <ImportGraduatedStudent
            v-if="schoolGraduatedStudent != undefined"
            :schoolGraduatedStudentOid="schoolGraduatedStudentId"
            :schoolID="schoolGraduatedStudent.schoolcode"
            :semesters="selected_semesters"
            :yearth="selected_yearth"
            @emit_onImportingCompleted="onImportingCompleted"
          >
          </ImportGraduatedStudent>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { GraduatedStudentDataProvider } from "@/services/GraduatedStudentDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import ImportGraduatedStudent from "@/components/GraduatedStudent/ImportGraduatedStudent.vue";

var graduatedStudentDataProvider = new GraduatedStudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "GraduatedStudentListView",
  props: [],
  data() {
    return {
      dialog: false,
      schoolGraduatedStudentId: "",
      schoolGraduatedStudent: undefined,
      graduatedStudents: undefined,
      graduatedStudents_data_table_headers: [
        { text: "รหัสนักศึกษา", value: "studentid" },
        { text: "ชื่อ-นามสกุล", value: "fullname" },
        { text: "ระดับการศึกษา", value: "gradenameth" },
        { text: "สถานะ", value: "studentstatusname" },
        { text: "ปีการศึกษาที่สำเร็จการศึกษา", value: "graduatedyearth" },
        { text: "ภาวะการมีงานทำ", value: "employmentstatusname" },
        { text: "บันทึกล่าสุด", value: "editdateemploymentstatus" },
        { text: "", value: "action" , sortable: false},
      ],
      yearth_choices: [],
      semesters_choices: ["1", "2"],
      GradeNames: [],
      EntranceYears: [],
      GraduatedYears: [],
      majorNames: [],
      subjectTypes: [],
      employmentstatusnames: [],
      selected_yearth: "",
      selected_semesters: "",
      selected: [],
    };
  },
  watch: {
    selected() {},
  },
  methods: {
    async resetfilter() {
      this.selected = [];
      this.loadGraduatedStudents(this.schoolGraduatedStudentId);
    },
    async changeditem(item) {
      await graduatedStudentDataProvider
        .filterGraduatedStudents(this.selected)
        .then((result) => {
          this.graduatedStudents = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadSchoolGraduatedStudent(schoolGraduatedStudentId = "") {
      await graduatedStudentDataProvider
        .GetSchoolGraduatedStudentFromSchoolID(schoolGraduatedStudentId)
        .then((result) => {
          this.schoolGraduatedStudent = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadGraduatedStudents(schoolGraduatedStudentId = "") {
      await graduatedStudentDataProvider
        .loadGetGraduatedStudentsFromSchoolId(schoolGraduatedStudentId)
        .then((result) => {
          this.graduatedStudents = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    openStudentDetailView(student) {
      var studentID = student.studentid;
      this.$router.push({ name: "StudentDetailView", params: { studentID: studentID } });
    },
    onImportingCompleted() {
      this.dialog = false;
      this.loadGraduatedStudents(this.schoolGraduatedStudentId);
    },
    Createyearth_choices() {
      var max = new Date().getFullYear() + 543;
      var min = max - 9;
      for (var i = max; i >= min; i--) {
        this.yearth_choices.push(i.toString());
      }
    },
    async loadgetgradeNameTh() {
      await graduatedStudentDataProvider
        .GetGradeNameThFromGraduatedStudents()
        .then((result) => {
          this.GradeNames = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetEntranceYear() {
      await graduatedStudentDataProvider
        .GetEntranceYearFromGraduatedStudents()
        .then((result) => {
          this.EntranceYears = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetGraduatedYearTH() {
      await graduatedStudentDataProvider
        .GetGraduatedYearTHFromGraduatedStudents()
        .then((result) => {
          this.GraduatedYears = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetmajorNameTh() {
      await graduatedStudentDataProvider
        .GetmajorNameThFromGraduatedStudents()
        .then((result) => {
          this.majorNames = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetemploymentstatusname() {
      await graduatedStudentDataProvider
        .GetemploymentstatusnameFromGraduatedStudents()
        .then((result) => {
          this.employmentstatusnames = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadSubjectTypes() {
      await subjectTypeDataProvider
        .loadSubjectTypes()
        .then((result) => {
          this.subjectTypes = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async selectedFilter() {
      this.$toast.success("xxxxx");
    },
  },
  async created() {
    this.Createyearth_choices();
    this.schoolGraduatedStudentId = this.$route.params.schoolid;
    await this.loadSchoolGraduatedStudent(this.schoolGraduatedStudentId);
    await this.loadGraduatedStudents(this.schoolGraduatedStudentId);
    await this.loadgetgradeNameTh();

    await this.loadGetEntranceYear();
    await this.loadGetGraduatedYearTH();
    await this.loadGetmajorNameTh();
    await this.loadGetemploymentstatusname();
    await this.loadSubjectTypes();
  },
  components: { SearchableDataTable, ImportGraduatedStudent },
};
</script>
