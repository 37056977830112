<template>
  <v-card 
    ><v-system-bar color="green darken-2" dark>สถานะการติดตาม</v-system-bar
    >
     <v-card-title  id="conteudo">{{percentperround}}% ของทั้งหมด</v-card-title>
  </v-card>
</template>

<script>
import { DashboardDataSourceProvider } from "@/services/DashboardDataSourceProvider"
var dashboardDataSourceProvider = new DashboardDataSourceProvider(
  process.env.VUE_APP_BACKEND_API_URL
)
export default {
  props: {
    oid: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      percentperround: 0.0
    }
  },
  watch: {
    oid(newValue, OldValue) {
    }
  },
  methods: {
    async initialize() {
      this.loadPercentJobTrackingByRound()
    },
    async loadPercentJobTrackingByRound() {
        dashboardDataSourceProvider.GetPercentJobTrackingByRound(this.oid).then(res=>{
            this.percentperround = res.data.toFixed(2)
        }).catch(res=>{

        })
    },
  },
  mounted() {
    this.initialize()
  }
}
</script>

<style scope>
#conteudo {
    width: 300px;
    height: 60px;
}
</style>
