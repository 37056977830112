import axios from "axios";
import { create_default_request_header } from "./constants";

export class EmployerExternalApiDataProvider {
  async loadEmployerData(organizationJuristicID) {
    const header = create_default_request_header();
    const apiBaseURL = "https://openapi.dbd.go.th";
    const endpointUrl = `/api/v1/juristic_person/${organizationJuristicID}`;
    return axios.get(apiBaseURL + endpointUrl, header);  
  }

  getTestEmployerData() {
    return {
      status: {
        code: "1000",
        description: "Success",
      },
      data: [
        {
          "cd:OrganizationJuristicPerson": {
            "cd:OrganizationJuristicID": "0105548038400",
            "cd:OrganizationJuristicNameTH": "บริษัท ทดสอบระบบ",
            "cd:OrganizationJuristicNameEN": "TEST AEC LIMITED",
            "cd:OrganizationJuristicType": "บริษัทจำกัด",
            "cd:OrganizationJuristicRegisterDate": "20140128",
            "cd:OrganizationJuristicStatus": "ร้าง",
            "cd:OrganizationJuristicObjective": {
              "td:JuristicObjective": {
                "td:JuristicObjectiveCode": "46315",
                "td:JuristicObjectiveTextTH":
                  "การขายส่งข้าวและผลิตภัณฑ์ที่ได้จากการโรงสีข้าว",
                "td:JuristicObjectiveTextEN":
                  "Wholesale of rice and rice milling products",
              },
            },
            "cd:OrganizationJuristicRegisterCapital": "5000000",
            "cd:OrganizationJuristicBranchName": "สำนักงานใหญ่",
            "cd:OrganizationJuristicAddress": {
              "cr:AddressType": {
                "cd:Building": "2",
                "cd:RoomNo": "5",
                "cd:Floor": "3",
                "cd:AddressNo": "2536522",
                "cd:Moo": "6",
                "cd:Yaek": null,
                "cd:Soi": "ซอยทดสอบ 1",
                "cd:Trok": null,
                "cd:Village": "5",
                "cd:Road": "9",
                "cd:CitySubDivision": {
                  "cr:CitySubDivisionCode": "11010100",
                  "cr:CitySubDivisionTextTH": "ปากน้ำ",
                },
                "cd:City": {
                  "cr:CityCode": "1101",
                  "cr:CityTextTH": "เมืองสมุทรปราการ",
                },
                "cd:CountrySubDivision": {
                  "cr:CountrySubDivisionCode": "TH-11",
                  "cr:CountrySubDivisionTextTH": "สมุทรปราการ",
                },
              },
            },
          },
        },
      ],
    };
  }
}
