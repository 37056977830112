<template>
  <div>
    <appbar></appbar>
    <v-container class="">
      <v-row class="mt-3">
        <v-col cols="12" class="d-block">
          <div class="d-flex justify-center">
            <h2 class="appInfo">ระบบศูนย์เครือข่ายกำลังคนอาชีวศึกษา</h2>
          </div>
          <div class="d-flex justify-center">
            <h2 class="appInfo">Vocational Co-operation Center (VCOP)</h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          @click="
            $router.push({
              path: 'Login',
            })
          "
          :class="[$vuetify.breakpoint.smAndDown ? 'col-6' : 'col-3']"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              color="orange"
              style="cursor: pointer"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                src="@/assets/login/i_manager.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)"
                height="360px"
                contain
              >
                <v-card-title
                  v-if="hover"
                  v-text="'ผู้บริหาร'"
                  class="justify-center"
                ></v-card-title>
              </v-img>
              <v-fade-transition>
                <v-overlay
                  :opacity="0.3"
                  v-if="!hover"
                  absolute
                  color="#252525"
                  class="white--text align-end"
                >
                  <v-card-title
                    v-text="'ผู้บริหาร'"
                    class="justify-center"
                  ></v-card-title>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col
          @click="
            $router.push({
              path: 'LoginOfficer',
            })
          "
          :class="[$vuetify.breakpoint.smAndDown ? 'col-6' : 'col-3']"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              color="#833133"
              style="cursor: pointer"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                src="@/assets/login/i_officer.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)"
                height="360px"
                contain
              >
                <v-card-title
                  v-if="hover"
                  v-text="'บุคลากรส่วนกลาง'"
                  class="justify-center"
                ></v-card-title>
              </v-img>
              <v-fade-transition>
                <v-overlay
                  :opacity="0.3"
                  v-if="!hover"
                  absolute
                  color="#252525"
                  class="white--text align-end"
                >
                  <v-card-title
                    v-text="'บุคลากรส่วนกลาง'"
                    class="justify-center"
                  ></v-card-title>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col
          @click="
            $router.push({
              path: 'LoginTeacher',
            })
          "
          :class="[$vuetify.breakpoint.smAndDown ? 'col-6' : 'col-3']"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              color="green"
              style="cursor: pointer"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                src="@/assets/login/i_teacher.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)"
                height="360px"
                contain
              >
                <v-card-title
                  v-if="hover"
                  v-text="'บุคลากรสถานศึกษา'"
                  class="justify-center"
                ></v-card-title>
              </v-img>
              <v-fade-transition>
                <v-overlay
                  :opacity="0.3"
                  v-if="!hover"
                  absolute
                  color="#252525"
                  class="white--text align-end"
                >
                  <v-card-title
                    v-text="'บุคลากรสถานศึกษา'"
                    class="justify-center"
                  ></v-card-title>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col
          @click="
            $router.push({
              path: 'LoginStudent',
            })
          "
          :class="[$vuetify.breakpoint.smAndDown ? 'col-6' : 'col-3']"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              color="primary"
              style="cursor: pointer"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                src="@/assets/login/i_student.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)"
                height="360px"
                contain
              >
                <v-card-title
                  v-if="hover"
                  v-text="'นักศึกษา'"
                  class="justify-center"
                ></v-card-title>
              </v-img>
              <v-fade-transition>
                <v-overlay
                  :opacity="0.3"
                  v-if="!hover"
                  absolute
                  color="#252525"
                  class="white--text align-end"
                >
                  <v-card-title
                    v-text="'นักศึกษา'"
                    class="justify-center"
                  ></v-card-title>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
export default {
  name: "HomeView",
  components: { appbar },
  mounted() {
    this.$store.dispatch("setLoginPath", true);
    this.$store.dispatch("updateCollapseTrackingMenu", true);
    const token = localStorage.getItem("token");
    if (token) {
      this.$store.dispatch({ type: "restoreLogin" });
    }
  },

  computed: {
    collapseTrackingMenu() {
      return this.$store.getters.collapseTrackingMenu;
    }
  },
};
</script>

<style>
.appInfo {
  color: #3f51b5;
}

.v-application .indigo {
  background-color: #374389 !important;
  border-color: #374389 !important;
}
</style>
