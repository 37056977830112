<template>
  <div>
    <div class="pa-5">
      <v-card v-if="tableOptions" rounded elevation="1" class="pa-5">
        <v-row class="d-flex justify-space-between">
          <v-col cols="12" lg="6" md="8" sm="12">
            <h3 class="primary--text">{{ school?.schoolname }}</h3>
          </v-col>
          <v-col cols="12" lg="6" md="4" sm="12" class="d-flex justify-end"
            ><p
              class="secondary--text px-2"
              style="cursor: pointer"
              @click="report_totalstudent"
            >
              ไปยังหน้ารายงาน
              <v-icon color="secondary">mdi-arrow-top-right</v-icon>
            </p></v-col
          >
        </v-row>

        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="6"
            sm="12"
            xs="12"
            class="custom-col mb-0 pb-0"
          >
            <h4 class="primary--text mb-2">ระดับการศึกษา</h4>

            <v-select
              label="ระดับการศึกษา"
              item-text="nameth"
              item-value="code"
              :items="certificateTypes"
              v-model="tableOptions.selected_certificatetypecode"
              clearable
              @change="loadStudentsWithDividePaging"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="6"
            sm="12"
            xs="12"
            class="custom-col mb-0 pb-0"
          >
            <h4 class="primary--text mb-2">ปีที่เข้าศึกษา</h4>

            <v-autocomplete
              label="ปีที่เข้าศึกษา"
              :items="entranceYears"
              v-model="tableOptions.entranceYear"
              clearable
              class="select"
              @change="loadStudentsWithDividePaging"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="6" md="3" class="custom-col mb-0 pb-0">
            <v-autocomplete
              label="สถานะนักศึกษา"
              :items="studentStatuses"
              v-model="tableOptions.selected_studentstatuscode"
              item-text="studentstatusname"
              item-value="studentstatuscode"
              clearable
              class="select"
              solo
              @change="loadStudentsWithDividePaging"
            ></v-autocomplete>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
            <h4 class="primary--text mb-2">ประเภทวิชา</h4>

            <v-autocomplete
              label="ประเภทวิชา"
              :items="subjecttypes"
              v-model="tableOptions.selected_subjecttypenameth"
              clearable
              @change="loadStudentsWithDividePaging"
              background-color="input"
              item-text="subjecttypenameth"
              item-value="subjecttypenameth"
              filled
              append-icon="mdi-chevron-down"
            >
            </v-autocomplete>
          </v-col>
          <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
            <h4 class="primary--text mb-2">สาขาวิชา</h4>

            <v-autocomplete
              label="สาขาวิชา"
              :items="majors"
              item-text="majornameth"
              item-value="majornameth"
              v-model="tableOptions.selected_majornameth"
              clearable
              @change="loadStudentsWithDividePaging"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            >
            </v-autocomplete>
          </v-col>
          <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
            <h4 class="primary--text mb-2">สาขางาน</h4>

            <v-autocomplete
              label="สาขางาน"
              :items="minors"
              item-text="minornameth"
              item-value="minornameth"
              v-model="tableOptions.selected_minornameth"
              clearable
              class="select"
              @change="loadStudentsWithDividePaging"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="d-flex">
          <v-col lg="4" md="12" sm="12" xs="12">
            <h4 class="primary--text mb-2">สถานะการอัปเดตโปรไฟล์</h4>
            <v-row no-gutters align-content="end">
              <v-col
                cols="12"
                lg="4"
                md="4"
                class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
              >
                <v-btn
                  :color="
                    tableOptions.selected_studentupdateprofilevalue === false ||
                    tableOptions.selected_studentupdateprofilevalue === true
                      ? 'input'
                      : 'primary'
                  "
                  @click="
                    tableOptions.selected_studentupdateprofilevalue = '';
                    loadStudentsWithDividePaging();
                  "
                  :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                  height="52px"
                  width="100%"
                >
                  ทั้งหมด
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="4"
                class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                ><v-btn
                  :color="
                    tableOptions.selected_studentupdateprofilevalue === true
                      ? 'primary'
                      : 'input'
                  "
                  @click="
                    tableOptions.selected_studentupdateprofilevalue = true;
                    loadStudentsWithDividePaging();
                  "
                  :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                  width="100%"
                  height="52px"
                >
                  อัปเดตแล้ว
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="4"
                class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
              >
                <v-btn
                  :color="
                    tableOptions.selected_studentupdateprofilevalue === false
                      ? 'primary'
                      : 'input'
                  "
                  @click="
                    tableOptions.selected_studentupdateprofilevalue = false;
                    loadStudentsWithDividePaging();
                  "
                  style="width: 100%; height: 52px"
                >
                  <template
                    v-if="
                      $vuetify.breakpoint.width <= 1600 &&
                      $vuetify.breakpoint.width >= 1260
                    "
                  >
                    ยังไม่มี<br />การอัปเดต
                  </template>
                  <template v-else> ยังไม่มีการอัปเดต </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="4" md="12" sm="12" xs="12">
            <h4 class="primary--text mb-2">สถานะนักศึกษา</h4>
            <v-row no-gutters align-content="end">
              <v-col
                cols="12"
                lg="4"
                md="4"
                class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
              >
                <v-btn
                  :color="
                    !tableOptions.selected_studentstatuscode ||
                    tableOptions.selected_studentstatuscode === true
                      ? 'primary'
                      : 'input'
                  "
                  @click="
                    tableOptions.selected_studentstatuscode = '';
                    loadStudentsWithDividePaging();
                  "
                  :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                  width="100%"
                  height="52px"
                >
                  ทั้งหมด
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="4"
                class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
              >
                <v-btn
                  :color="
                    tableOptions.selected_studentstatuscode === '09'
                      ? 'primary'
                      : 'input'
                  "
                  @click="
                    tableOptions.selected_studentstatuscode = '09';
                    loadStudentsWithDividePaging();
                  "
                  :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                  width="100%"
                  height="52px"
                >
                  สำเร็จการศึกษา
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                lg="4"
                md="4"
                class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
              >
                <v-btn
                  :color="
                    tableOptions.selected_studentstatuscode === '03'
                      ? 'primary'
                      : 'input'
                  "
                  @click="
                    tableOptions.selected_studentstatuscode = '03';
                    loadStudentsWithDividePaging();
                  "
                  width="100%"
                  height="52px"
                >
                  <template
                    v-if="
                      $vuetify.breakpoint.width <= 1600 &&
                      $vuetify.breakpoint.width >= 1260
                    "
                  >
                    อยู่ระหว่าง <br />
                    การศึกษา
                  </template>
                  <template v-else> อยู่ระหว่างการศึกษา </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            xl="4"
            lg="4"
            md="12"
            sm="12"
            xs="12"
            class="mt-3 d-flex justify-end align-end custom-col"
          >
            <div class="d-flex align-items-end">
              <p class="mr-2">จำนวนนักศึกษาที่อัปเดตข้อมูล [</p>
              <span
                class=""
                :class="[
                  this.countstudentIsUpdated.updatedstudent ==
                  this.countstudentIsUpdated.totalstudent
                    ? 'success--text'
                    : 'red--text',
                ]"
                >{{ this.countstudentIsUpdated.updatedstudent }}</span
              >/{{ this.countstudentIsUpdated.totalstudent }} ]
            </div>
          </v-col>
        </v-row>
      </v-card>

      <SearchableDataTablePaging
        :datatable_headers="students_data_table_headers"
        :data_items="students"
        :itemlength="itemlength"
        class="elevation-1"
        :search="keyword"
        table_caption="รายชื่อนักศึกษา"
        @pageChanged="pageChanged"
        @onkeywordchanged="onKeywordChanged"
        @tableOptionsChanged="tableOptionsChanged"
      >
        <template v-slot:top>
          <div class="pb-5 pt-3">
            <v-toolbar flat dense>
              <v-row>
                <v-col cols="12">
                  <h4 class="primary--text">
                    รายชื่อนักศึกษาที่กำลังศึกษาอยู่
                  </h4>
                </v-col>
              </v-row>

              <div class="mr-10"></div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                color="info"
              ></v-text-field>
            </v-toolbar>
          </div>
        </template>
        <template v-slot:item.student_completionpercentage="{ item }">
          <v-row>
            <v-col cols="6" class="d-flex justify-center align-center">
              <CompletionPercentageNumberVue
                :student_veceducationinformation="item"
              >
              </CompletionPercentageNumberVue>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.action="{ item }">
          <v-row>
            <v-col cols="6">
              <v-tooltip top color="info">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="openStudentDetailOverview(item)"
                    color="info"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
                <span>ข้อมูลนักศึกษา</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </SearchableDataTablePaging>
    </div>
  </div>
</template>

<script>
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { MinorDataProvider } from "@/services/MinorDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import CompletionPercentageNumberVue from "@/components/Profile/CompletionPercentageNumber.vue";

const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const minorDataProvider = new MinorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: {
    CompletionPercentageNumberVue,
    SearchableDataTablePaging,
    CompletionPercentageNumberVue,
  },
  name: "SchoolStudentListView",
  data() {
    return {
      currentSchoolPermissionCode: {},
      school: undefined,
      students_data_table_headers: [
        { text: "รหัสนักศึกษา", value: "studentcode" },
        { text: "ชื่อ", value: "student_firstname" },
        { text: "นามสกุล", value: "student_surname" },
        { text: "ระดับการศึกษา", value: "gradenameth" },
        { text: "ปีที่เข้าศึกษา", value: "entranceyear" },
        { text: "ประเภทวิชา", value: "subjecttypenameth" },
        { text: "สาขา", value: "majornameth" },
        { text: "สาขางาน", value: "minornameth" },
        { text: "ความสมบูรณ์ของข้อมูล", value: "student_completionpercentage" },
        { text: "ตัวเลือก", value: "action", sortable: false },
      ],
      students: undefined,
      maxstudent: 0,
      itemlength: 0,
      search: "",
      keyword: "",
      pageNumber: 1,
      tableOptions: undefined,
      certificateTypes: undefined,
      entranceYears: undefined,
      minors: undefined,
      majors: undefined,
      subjecttypes: undefined,
      studentStatuses: undefined,
      studentupdateprofilestatuses: undefined,
      countstudentIsUpdated: 0,
    };
  },
  computed: {
    updatedprofile_student_quantity() {
      return this.countstudentIsUpdated;
    },
  },
  watch: {
    tableOptions: {
      handler(newValue, oldValue) {
        this.loadStudentsWithDividePaging();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      await schoolEmployeeProfileDataProvider
        .getCurrentPermissionSchoolIDs()
        .then((result) => {
          this.currentSchoolPermissionCode = result.data[0];
          this.tableOptions.SchoolID = this.currentSchoolPermissionCode;
        });
      await schoolDataProvider
        .loadSchool(this.currentSchoolPermissionCode)
        .then((result) => {
          this.school = result.data;
        });

      await masterDataProvider
        .getCertificateTypesByVECEducationInformationsFromSchoolId(
          this.tableOptions.SchoolID
        )
        .then((result) => {
          this.certificateTypes = result.data;
        });
      await masterDataProvider
        .getStudentUpdateProfileStatuses()
        .then((result) => {
          this.studentupdateprofilestatuses = result.data;
        });

      await majorDataProvider
        .getMajorsFromVECEducationInformationBySchoolID(
          this.currentSchoolPermissionCode
        )
        .then((result) => {
          this.majors = result.data;
        });

      await studentDataProvider
        .getAllEntranceYears(this.currentSchoolPermissionCode)
        .then((result) => {
          this.entranceYears = result.data;
        });
      await masterDataProvider
        .getMinorsByMajorNameThBySchoolID(
          this.currentSchoolPermissionCode,
          this.tableOptions.selected_majornameth,
          this.tableOptions.selected_subjecttypenameth
        )
        .then((result) => {
          this.minors = result.data;
        });
      await subjectTypeDataProvider
        .getSubjectTypesFromVECEducationInformationBySchoolID(
          this.currentSchoolPermissionCode
        )
        .then((result) => {
          this.subjecttypes = result.data;
          console.log(this.subjecttypes);
        });

      await commonDataProvider.getStudentStatuses().then((result) => {
        this.studentStatuses = result.data;
      });
    },
    async loadStudentsWithDividePaging() {
      this.$store.dispatch("showLoading");
      this.tableOptions.SchoolID = this.currentSchoolPermissionCode;
      this.tableOptions.search = this.keyword;
      this.tableOptions.page = this.pageNumber;
      // this.tableOptions.selectedCertificateType = this.selectedCertificateType;
      // this.tableOptions.selectedSubJectTypeID = this.selectedSubJectTypeID;
      // this.tableOptions.selectedMajorID = this.selectedMajorID;
      // this.tableOptions.selectedStudentStatuses = this.selectedStudentStatuses;

      if (this.tableOptions.selected_subjecttypenameth) {
        await masterDataProvider
          .getMajorsBySubjectTypeNameThBySchoolID(
            this.currentSchoolPermissionCode,
            this.tableOptions.selected_subjecttypenameth
          )
          .then((result) => {
            this.majors = result.data;
          });
      } else {
        await majorDataProvider
          .getMajorsFromVECEducationInformationBySchoolID(
            this.currentSchoolPermissionCode
          )
          .then((result) => {
            this.majors = result.data;
          });
      }

      if (
        this.tableOptions.selected_majornameth ||
        this.tableOptions.selected_subjecttypenameth
      ) {
        await masterDataProvider
          .getMinorsByMajorNameThBySchoolID(
            this.currentSchoolPermissionCode,
            this.tableOptions.selected_majornameth,
            this.tableOptions.selected_subjecttypenameth
          )
          .then((result) => {
            this.minors = result.data;
          });
      } else {
        await masterDataProvider
          .getMinorsByMajorNameThBySchoolID(
            this.currentSchoolPermissionCode,
            this.tableOptions.selected_majornameth,
            this.tableOptions.selected_subjecttypenameth
          )
          .then((result) => {
            this.minors = result.data;
          });
      }
      await studentDataProvider
        .getStudentVECEducationInformationssWithDividePaging(this.tableOptions)
        .then((result) => {
          this.students = result.data.items;
          this.itemlength = result.data.quantity;
          this.percantageallstudent = Math.ceil((13 / this.itemlength) * 100);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },
    openStudentDetailOverview(item) {
      let studentOid = item.student_oid;
      let schoolName = this.school.schoolname;

      // Generate the path from the route name and params
      const routeData = this.$router.resolve({
        name: "StudentDetailOverview",
        params: { studentoid: studentOid, schoolname: schoolName },
      });

      // Open the generated path in a new tab
      window.open(routeData.href, "_blank");
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = { ...this.tableOptions, ...newtableOptions };
      this.pageNumber = 1;
      this.loadStudentsWithDividePaging();
    },
    async onKeywordChanged(newKeyword) {
      this.keyword = newKeyword;
      this.pageNumber = 1;
      await this.loadStudentsWithDividePaging();
    },
    pageChanged(pageChangeValue) {
      if (this.pageNumber !== pageChangeValue) {
        this.pageNumber = pageChangeValue;
        this.loadStudentsWithDividePaging();
      }
    },
    loadCountStudentUpdatedProfile() {
      studentDataProvider
        .LoadCountStudentUpdatedBySchoolId(this.currentSchoolPermissionCode)
        .then((result) => {
          this.countstudentIsUpdated = result.data;
        })
        .catch(() => {});
    },
    report_totalstudent() {
      this.$router
        .push({ name: "School_SchoolStudentList_Report" })
        .catch((err) => {});
    },
  },
  async created() {
    await this.initialize();
    await this.loadStudentsWithDividePaging();
    await this.loadCountStudentUpdatedProfile();
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
