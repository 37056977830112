<template>
  <v-container>
    <v-combobox
      v-model="selected_tags_data"
      @change="onChange"
      :items="existing_tags"
      label="Hashtag"
      item-text="tag"
      item-value="oid"
      multiple
      filled
      background-color="input"
      chips
    >
      <template v-slot:selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :model-value="data.selected"
          close
          @click:close="removetag(data.item)"
        >
          {{ data.item }}
        </v-chip>
      </template>
    </v-combobox>
  </v-container>
</template>
  
  <script>
import { EntityTagDataProvider } from "@/services/EntityTagDataProvider";

const entityTagDataProvider = new EntityTagDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "EntityHashtagManagement",
  props: ["selected_tags"],
  data() {
    return {
      selected_tags_data: [],
      existing_tags: undefined,
      filling_tag: "",
    };
  },
  methods: {
    async load_existing_tags() {
      await entityTagDataProvider.GetTagsOnlyText(this.oid).then((result) => {
        this.existing_tags = result.data;
      });
    },
    removetag(tag) {
      const index = this.selected_tags_data.indexOf(tag);
      if (index !== -1) {
        this.selected_tags_data.splice(index, 1);
      }
    },
    onChange() {
      this.$emit("update_tags", this.selected_tags_data);
    },
  },

  async mounted() {
    await this.load_existing_tags();
    this.selected_tags_data = this.selected_tags;
  },
  computed: {
    compute_selected_tags_data() {
      return this.selected_tags_data;
    },
  },
};
</script>
  
  <style>
</style>