<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" class="ml-0 pl-1">
        <v-btn
          @click="open_createSchoolEmployeeProfileDetailView"
          dark
          color="primary"
          class="ma-2"
        >
          <v-icon class="mr-2">mdi-account-plus</v-icon>
          เพิ่มข้อมูลผู้บริหารสถานศึกษา
        </v-btn>
      </v-col>
    </v-row>
    <SearchableDataTable
      :data_items="schoolEmployeeProfiles"
      :datatable_headers="schoolEmployeeProfiles_table_headers"
      table_caption="ข้อมูลผู้บริหารสถานศึกษา"
    >
      <template v-slot:item.action="{ item }">
        <v-row class="custom-width">
          <ChangePasswordWhenLogon
            :SchoolEmployeeProfileOid="item.oid"
          ></ChangePasswordWhenLogon>
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="open_RoleAndPermissionDialog(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-group</v-icon>
              </v-btn>
            </template>
            <span>จัดการสิทธิใช้งาน</span>
          </v-tooltip>
          <v-tooltip top color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="open_SchoolEmployeeProfileDetailView(item)"
                color="warning"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขข้อมูล</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="deleteSchoolEmployeeProfile(item)"
                color="error"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ลบ</span>
          </v-tooltip>
        </v-row>
      </template>
    </SearchableDataTable>

    <RoleAndPermission_ManagementVue
      :isUsedForSchoolAdmin="true"
      :fixedSchoolPermissionCodes="schoolPermissionIDs"
      :SchoolEmployeeProfileOid="currentSchoolEmployeeProfile_Oid"
      :dialog="roleAndPermission_Management_Dialog"
      @closeDialog="onSaveIsComplete"
    >
    </RoleAndPermission_ManagementVue>
  </div>
</template>

<script>
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { ApplicationRoleCodes } from "@/Constants";
import RoleAndPermission_ManagementVue from "@/components/SchoolEmployeeProfiles/RoleAndPermission_Management.vue";
import ChangePasswordWhenLogon from "@/components/SchoolEmployeeProfiles/ChangePasswordWhenLogon.vue";
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: {
    SearchableDataTable,
    RoleAndPermission_ManagementVue,
    ChangePasswordWhenLogon,
  },
  name: "SchoolEmployeeProfileListView_School_Manager",
  data() {
    return {
      changePasswordDialog: false,
      roleAndPermission_Management_Dialog: false,
      schoolPermissionIDs: [],
      currentSchoolEmployeeProfile_Oid: "",
      schoolEmployeeProfiles: undefined,
      schoolEmployeeProfiles_table_headers: [
        { text: "ชื่อ", value: "name" },
        { text: "นามสกุล", value: "lastname" },
        { text: "เลขประจำตัวประชาชน", value: "idcard" },
        { text: "ตำแหน่ง", value: "position" },
        { text: "ชื่อผู้ใช้งาน", value: "username" },
        { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    async initialize() {
      schoolEmployeeProfileDataProvider
        .getCurrentPermissionSchoolIDs()
        .then((result) => {
          this.schoolPermissionIDs = result.data;
        });
    },
    onSaveIsComplete(value, isSave) {
      this.roleAndPermission_Management_Dialog = false;
      if (isSave) {
      }
    },
    onSaveChangePassword(value, changePassword) {
      this.changePasswordDialog = false;
      if (changePassword) {
      }
    },
    async loadSchoolEmployeeProfiles() {
      await schoolEmployeeProfileDataProvider
        .get_SchoolManager_SchoolEmployeeProfiles()
        .then((result) => {
          this.schoolEmployeeProfiles = result.data;
        });
    },

    open_createSchoolEmployeeProfileDetailView() {
      this.$router
        .push({
          name: "CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Manager",
        })
        .catch((err) => {});
    },

    open_SchoolEmployeeProfileDetailView(item) {
      const oid = item.oid;
      this.$router
        .push({ name: "SchoolEmployeeProfileDetailView", params: { oid: oid } })
        .catch((err) => {});
    },

    async deleteSchoolEmployeeProfile(item) {
      if (confirm("ต้องการลบข้อมูลหรือไม่ ?")) {
        var oid = item.oid;
        await schoolEmployeeProfileDataProvider
          .deleteSchoolEmployeeProfile(oid)
          .then((result) => {
            this.$toast.success("ลบข้อมูลเรียบร้อย");
            this.loadSchoolEmployeeProfiles();
          });
      }
    },
    open_RoleAndPermissionDialog(item) {
      var oid = item.oid;
      this.currentSchoolEmployeeProfile_Oid = oid;
      this.roleAndPermission_Management_Dialog = true;
    },
    open_ChangePasswordDialog(item) {
      var oid = item.oid;
      this.currentSchoolEmployeeProfile_Oid = oid;
      this.changePasswordDialog = true;
    },
    closeDialogComplete(save) {
      if (save) {
      }
      this.changePasswordDialog = false;
    },
  },
  async created() {
    await this.initialize();
    await this.loadSchoolEmployeeProfiles();
  },
};
</script>

<style scoped>
.custom-width {
  width: 150px;
}
</style>
