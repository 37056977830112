import NotificationHistory from "@/components/Notification/NotificationHistory";
import CommonNotifyRequest from "@/components/Notification/CommonNotifyRequest";
import NotifySetup from "@/components/Notification/NotifySetup";

export default [
  {
    path: "/Notification",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Notification/NotificationHistory",
        name: "NotificationHistory",
        component: NotificationHistory,
        meta: {
          requiresAuth: true,
          nameTh: "การแจ้งเตือน",
        },
      },
      {
        path: "/Notification/CommonNotifyRequest",
        name: "CommonNotifyRequest",
        component: CommonNotifyRequest,
        meta: {
          requiresAuth: true,
          nameTh: "ประกาศแจ้งเตือนทั่วไป",
        },
      },
      {
        path: "/Notification/NotifySetup",
        name: "NotifySetup",
        component: NotifySetup,
        meta: {
          requiresAuth: true,
          nameTh: "ตั้งค่าการแจ้งเตือน",
        },
      },
    ],
  },
];
