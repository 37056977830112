export class UpdateEmployerSummaryRequest {
  oid = "";
  identityid = "";
  nameth = "";
  branchno = "";
  address = "";
  selected_province_id = "";
  dontknowidentityid = false;
  dbd_organizationjuristicstatus = "";
  dbd_lastverifydate = "";
  dbd_status = "";

  static fromJson(jsonObject = "") {
    const vm = new UpdateEmployerSummaryRequest();
    var convertedJson = JSON.stringify(jsonObject);
    vm.oid = jsonObject.oid;
    if (jsonObject.identityid != undefined) {
      vm.identityid = jsonObject.identityid;
    }
    if (jsonObject.nameth != undefined) {
      vm.nameth = jsonObject.nameth;
    }
    if (jsonObject.branchno != undefined) {
      vm.branchno = jsonObject.branchno;
    }
    if (jsonObject.address != undefined) {
      vm.address = jsonObject.address;
    }
    if (jsonObject.address != undefined) {
      vm.address = jsonObject.address;
    }
    if (jsonObject.province != undefined) {
      vm.selected_province_id = jsonObject.province.provinceid;
    }
    if (jsonObject.dontknowidentityid != undefined) {
      vm.dontknowidentityid = jsonObject.dontknowidentityid;
    }
    if (jsonObject.dbd_organizationjuristicstatus != undefined) {
      vm.dbd_organizationjuristicstatus =
        jsonObject.dbd_organizationjuristicstatus;
    }
    if (jsonObject.dbd_lastverifydate != undefined) {
      vm.dbd_lastverifydate = jsonObject.dbd_lastverifydate;
    }
    if (jsonObject.dbd_status != undefined) {
      vm.dbd_status = jsonObject.dbd_status;
    }
    return vm;
  }
}
