<template>
  <v-dialog v-model="titlename_Dialog" width="850" persistent>
    <v-card class="pa-10">
      <h2 class="primary--text pt-5">คำนำหน้าชื่อ</h2>
      <v-divider class="mt-4 mb-5 mx-0"></v-divider>
      <v-form>
        <v-row class="align-center">
          <v-text-field
            label="คำนำหน้าชื่อ"
            filled
            rounded
            dense
            v-model="vm.titlenameth"
          >
          </v-text-field>
        </v-row>
        <v-row class="align-center">
          <v-text-field
            label="คำนำหน้าชื่อ(Eng)"
            filled
            rounded
            dense
            v-model="vm.titlenameeng"
          >
          </v-text-field>
        </v-row>
        <v-row class="align-center">
          <v-text-field
            label="รหัสคำนำหน้าชื่อ"
            filled
            rounded
            dense
            v-model="vm.titlenameid"
          >
          </v-text-field>
        </v-row>
        <v-row class="align-content-center text-center mt-3">
          <v-col>
            <v-btn
              :loading="isInCreatingProcess"
              @click="confirm_dialog = true"
              class="ma-2"
              color="secondary"
              ><v-icon>mdi-content-save</v-icon>
              บันทึก
            </v-btn>
            <v-btn @click="onCancelClick()" class="ma-2" color=""> ยกเลิก </v-btn>
          </v-col>
        </v-row>
        <v-dialog v-model="confirm_dialog" width="400">
          <template>
            <v-card class="pa-6">
              <v-row class="justify-center text-center">
                <v-card-title> ยืนยันเพิ่มคำนำหน้าชื่อ ? </v-card-title>
                <v-col cols="12">
                  <v-btn @click="onButtonClick" class="ma-2" color="success">
                    ยืนยัน
                  </v-btn>
                  <v-btn
                    @click="confirm_dialog = false"
                    class="ma-2"
                    color=""
                    elevation="2"
                    >ยกเลิก</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-dialog>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import { TitlenameRecord } from "@/models/Titlename/TitlenameRecord";
import { TitlenameDataProvider } from "@/services/TitlenameDataProvider";
var titlenameDataProvider = new TitlenameDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "TitlenameDialog",
  props: { titlename_Dialog: Boolean, oid: String },
  data() {
    return {
      vm: new TitlenameRecord(),
      isInCreatingProcess: false,
      confirm_dialog: false,
      confirm_result: false,
    };
  },
  watch: {
    titlename_Dialog(value) {
      if (value) {
        if (this.oid != "-1") {
          this.loadTitlenameRecord(this.oid);
        } else {
          this.vm = new TitlenameRecord();
        }
      }
    },
  },
  methods: {
    async loadTitlenameRecord(oid) {
      await titlenameDataProvider
        .getTitlenameRecord(oid)
        .then(async (response) => {
          this.vm = response.data;
          //bind start date
        })
        .catch((response) => {
          this.$toast.error("ล้มเหลว!!");
        });
    },

    onButtonClick() {
      this.isInCreatingProcess = false;
      this.$emit("closeDialog", this.vm, true);
    },
    onCancelClick() {
      this.$emit("closeDialog", this.vm, false);
    },
  },

  mounted() {},
  components: { TextFieldDatePicker },
};
</script>

<style></style>
