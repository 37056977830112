<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="11" md="12" sm="12" xs="12">
          <v-card>
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >ติดตามสถานะตำแหน่งงานที่สมัคร</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text v-if="this.student" class="d-flex justify-center">
              <v-col cols="10">
                <v-row class="mt-5">
                  <v-col cols="6">
                    <h1 class="mt-3 primary--text">
                      <v-icon color="primary">mdi-account</v-icon>
                      {{ student.firstname }} {{ student.surname }}
                    </h1>
                    <div class="mt-5">
                      <label
                        style="font-weight: bold"
                        class="primary--text"
                        v-if="student"
                      >
                        <v-icon color="primary">mdi-town-hall</v-icon> สถานศึกษา :
                      </label>
                      <label style="color: black">{{ school?.schoolname }} </label>
                    </div>
                    <div class="mt-3">
                      <label
                        style="font-weight: bold"
                        class="primary--text"
                        v-if="student"
                      >
                        <v-icon color="primary">mdi-certificate</v-icon> สาขาวิชา :
                      </label>
                      <label style="color: black">{{
                        student.veceducationinformations[0].minornameth
                      }}</label>
                    </div>
                    <div class="mt-3">
                      <i style="font-weight: bold" class="primary--text" v-if="student">
                        <v-icon color="primary">mdi-account-school</v-icon>
                        ระดับชั้น :
                      </i>
                      <i style="color: black">{{
                        student.veceducationinformations[0].gradenameth
                      }}</i>
                    </div>
                  </v-col>

                  <v-col cols="6" class="d-flex justify-end">
                    <v-card
                      style="border-radius: 15px"
                      class="d-flex flex-column align-center pb-2"
                      variant="outlined"
                      width="250"
                    >
                      <label class="mt-3 mb-2">ความสมบูรณ์ของประวัติ</label>
                      <CompletionPercentage
                        v-if="this.student?.completionpercentage"
                        :percen="this.student?.completionpercentage"
                        :size="120"
                      >
                      </CompletionPercentage>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="my-3">
                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-4 pa-2 light-gray-border"
                      rounded
                      :outlined="activeButton !== 1"
                      :color="activeButton === 1 ? 'primary' : ''"
                      style="border-radius: 8px; height: 66.66%; width: 100%"
                      @click="
                        setActive(1);
                        filterJobApplicationByJobApplicationStatusCode('');
                      "
                    >
                      <span
                        :class="{ 'primary--text': activeButton !== 1 }"
                        style="font-weight: bold"
                        >จำนวนใบสมัครทั้งหมด {{ quantity_of_all_jobapplication }}</span
                      >
                    </v-btn>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-2 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 2"
                      :color="activeButton === 2 ? 'primary' : ''"
                      style="
                        border-radius: 8px;
                        height: 33.33%;
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                      "
                      @click="
                        setActive(2);
                        filterJobApplicationByJobApplicationStatusCode('Pending');
                      "
                    >
                      <span
                        :class="{ 'primary--text': activeButton !== 2 }"
                        style="font-weight: bold"
                        >ยื่นใบสมัคร/รอการตรวจสอบ
                        {{ quantity_of_pending_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="mb-2 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 3"
                      :color="activeButton === 3 ? 'primary' : ''"
                      style="border-radius: 8px; height: 33.33%; width: 100%"
                      @click="
                        setActive(3);
                        filterJobApplicationByJobApplicationStatusCode('UnderReview');
                      "
                    >
                      <span
                        :class="{ 'primary--text': activeButton !== 3 }"
                        style="font-weight: bold"
                        >อยู่ระหว่างพิจารณา
                        {{ quantity_of_underreview_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 4"
                      :color="activeButton === 4 ? 'primary' : ''"
                      style="border-radius: 8px; height: 33.33%; width: 100%"
                      @click="
                        setActive(4);
                        filterJobApplicationByJobApplicationStatusCode(
                          'InterviewScheduled'
                        );
                      "
                    >
                      <span
                        :class="{ 'primary--text': activeButton !== 4 }"
                        style="font-weight: bold"
                        >นัดหมายสัมภาษณ์
                        {{ quantity_of_interviewschduled_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-4 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 5"
                      :color="activeButton === 5 ? 'primary' : ''"
                      style="border-radius: 8px; height: 50%; width: 100%"
                      @click="
                        setActive(5);
                        filterJobApplicationByJobApplicationStatusCode('Accecpted');
                      "
                    >
                      <span
                        :class="{ 'primary--text': activeButton !== 5 }"
                        style="font-weight: bold"
                        >รับเข้าทำงาน {{ quantity_of_accepted_jobapplication }} คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 6"
                      :color="activeButton === 6 ? 'primary' : ''"
                      style="border-radius: 8px; height: 50%; width: 100%"
                      @click="
                        setActive(6);
                        filterJobApplicationByJobApplicationStatusCode('Rejected');
                      "
                    >
                      <span
                        :class="{ 'primary--text': activeButton !== 6 }"
                        style="font-weight: bold"
                        >ไม่ผ่านการพิจารณา
                        {{ quantity_of_rejected_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                  </v-col>
                </v-row>
                <SearchableDataTable
                  table_caption="ตำแหน่งงานที่ยื่นสมัคร"
                  :data_items="filteredItems"
                  :datatable_headers="datatable_headers"
                >
                  <template v-slot:item.createddate="{ item }">
                    <FormatDate :date_value="item.createddate"></FormatDate>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top color="info">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openJobApplicationInfo(item)"
                          color="info"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex justify-end"
                        >
                          <v-icon>mdi-file</v-icon>
                        </v-btn>
                      </template>
                      <span>แสดงรายละเอียดใบสมัครงาน</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.jobapplicationstatus.statusname="{ item }">
                    <div class="d-flex justify-center">
                      <v-tooltip top color="info">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="openJobApplicationStatusInfoDialog(item)"
                            icon
                            class="d-flex justify-center"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <JobApplicationStatus_Label :JobApplication="item">
                            </JobApplicationStatus_Label>
                          </v-btn>
                        </template>
                        <span>แสดงรายละเอียด</span>
                      </v-tooltip>
                    </div>
                  </template>
                </SearchableDataTable>
                <v-dialog v-model="dialog" width="70%">
                  <template>
                    <v-card>
                      <v-card-text>
                        <StudentJobApplicationStatusInfo
                          :JobApplication="selected_jobapplication"
                          @readclicked="
                            async () => await this.loadJobApplications(), (dialog = false)
                          "
                          @closed="dialog = false"
                        >
                        </StudentJobApplicationStatusInfo>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <!-- <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <h4>ติดตามสถานะตำแหน่งงานที่ยื่นสมัคร</h4>
      </v-col>
    </v-row>
    <v-row v-if="this.student">
      <v-col cols="6" class="d-flex-column justify-start">
        <v-card
          style="border-radius: 15px"
          class="d-flex flex-column align-center pb-2"
          variant="outlined"
          max-width="250"
        >
          <label class="mt-3 mb-2">ความสมบูรณ์ของประวัติ:</label>
          <CompletionPercentage
            v-if="this.student?.completionpercentage"
            :percen="this.student?.completionpercentage"
          >
          </CompletionPercentage>
        </v-card>
        <div class="mt-2">
          <label style="font-weight: bold" v-if="student"
            ><v-icon color="grey">mdi-account</v-icon> ชื่อ-นามสกุล : </label
          ><label>{{ student.firstname }} {{ student.surname }}</label>
        </div>
        <div>
          <label style="font-weight: bold" v-if="student"
            ><v-icon color="grey">mdi-town-hall</v-icon> สถานศึกษา : </label
          ><label>{{ school?.schoolname }} </label>
        </div>
        <div>
          <label style="font-weight: bold" v-if="student"
            ><v-icon color="grey">mdi-account-school</v-icon> ระดับชั้น : </label
          ><label>{{ student.veceducationinformations[0].gradenameth }} </label>
        </div>
        <div>
          <label style="font-weight: bold" v-if="student"
            ><v-icon color="grey">mdi-certificate</v-icon> สาขาวิชา : </label
          ><label>{{ student.veceducationinformations[0].minornameth }} </label>
        </div>
      </v-col>
      <v-col cols="6" class="justify-end align-self-end">
        <div class="d-flex justify-end">
          <label>
            จำนวนใบสมัครที่ยื่นทั้งหมด
            <a @click="filterJobApplicationByJobApplicationStatusCode('')"
              >{{ quantity_of_all_jobapplication }} </a
            >ตำแหน่ง
            <v-icon color="grey">mdi-file-settings-outline</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            ยื่นใบสมัคร/รอการตรวจสอบ
            <a
              @click="filterJobApplicationByJobApplicationStatusCode('Pending')"
              >{{ quantity_of_pending_jobapplication }} </a
            >ตำแหน่ง
            <v-icon color="grey">mdi-file-document-outline</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            อยู่ระหว่างพิจารณา
            <a
              @click="
                filterJobApplicationByJobApplicationStatusCode('UnderReview')
              "
              >{{ quantity_of_underreview_jobapplication }} </a
            >ตำแหน่ง <v-icon color="grey">mdi-clock</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            นัดหมายสัมภาษณ์
            <a
              @click="
                filterJobApplicationByJobApplicationStatusCode(
                  'InterviewScheduled'
                )
              "
              >{{ quantity_of_interviewschduled_jobapplication }} </a
            >ตำแหน่ง <v-icon color="info">mdi-calendar-today</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            รับเข้าทำงาน
            <a
              @click="
                filterJobApplicationByJobApplicationStatusCode('Accecpted')
              "
              >{{ quantity_of_accepted_jobapplication }} </a
            >ตำแหน่ง <v-icon color="success">mdi-check-circle</v-icon></label
          >
        </div>
        <div class="d-flex justify-end">
          <label>
            ไม่ผ่านการพิจารณา
            <a
              @click="
                filterJobApplicationByJobApplicationStatusCode('Rejected')
              "
              >{{ quantity_of_rejected_jobapplication }} </a
            >ตำแหน่ง <v-icon color="error">mdi-close-octagon</v-icon></label
          >
        </div>
      </v-col>
    </v-row>

    <SearchableDataTable
      table_caption="ตำแหน่งงานที่ยื่นสมัคร"
      :data_items="filteredItems"
      :datatable_headers="datatable_headers"
    >
      <template v-slot:item.createddate="{ item }">
        <FormatDate :date_value="item.createddate"></FormatDate>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openJobApplicationInfo(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
              class="d-flex justify-end"
            >
              <v-icon>mdi-file</v-icon>
            </v-btn>
          </template>
          <span>แสดงรายละเอียดใบสมัครงาน</span>
        </v-tooltip>
      </template>
      <template v-slot:item.jobapplicationstatus.statusname="{ item }">
        <div class="d-flex justify-center">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openJobApplicationStatusInfoDialog(item)"
                icon
                class="d-flex justify-center"
                v-bind="attrs"
                v-on="on"
              >
                <JobApplicationStatus_Label :JobApplication="item">
                </JobApplicationStatus_Label>
              </v-btn>
            </template>
            <span>แสดงรายละเอียด</span>
          </v-tooltip>
        </div>
      </template>
    </SearchableDataTable>
    <v-dialog v-model="dialog" width="70%">
      <template>
        <v-card>
          <v-card-text>
            <StudentJobApplicationStatusInfo
              :JobApplication="selected_jobapplication"
              @readclicked="
                async () => await this.loadJobApplications(), (dialog = false)
              "
              @closed="dialog = false"
            >
            </StudentJobApplicationStatusInfo>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </v-container> -->
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import JobPostingStatus_Label from "@/components/JobPostings/JobPostingStatus_Label.vue";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import CompletionPercentage from "@/components/Profile/CompletionPercentage.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { StudentJobPostingDataProvider } from "@/services/StudentJobPostingDataProvider";
import JobApplicationStatus_Label from "@/components/JobPostings/JobApplicationStatus_Label.vue";
import StudentJobApplicationStatusInfo from "@/components/JobPostings/StudentJobApplicationStatusInfo.vue";
import FormatDate from "@/components/common/FormatDate.vue";

const studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const studentJobPostingDataProvider = new StudentJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: {
    SearchableDataTable,
    JobPostingStatus_Label,
    CompletionPercentage,
    JobApplicationStatus_Label,
    StudentJobApplicationStatusInfo,
    FormatDate,
  },
  name: "StudentTrackCurrentJobApplicationsView",
  data() {
    return {
      timer: null,
      student: undefined,
      school: undefined,
      jobapplications: [],
      jobapplicationstatus_code: "",
      dialog: false,
      selected_jobapplication_oid: "",
      selected_jobapplication: "",
      quantity_of_all_jobapplication: 0,
      quantity_of_pending_jobapplication: 0,
      quantity_of_underreview_jobapplication: 0,
      quantity_of_interviewschduled_jobapplication: 0,
      quantity_of_accepted_jobapplication: 0,
      quantity_of_rejected_jobapplication: 0,
      datatable_headers: [
        {
          value: "jobposting.employerprofile.nameth",
          text: "ชื่อสถานประกอบการ",
        },
        { value: "jobposting.positionname", text: "ชื่อตำแหน่งงาน" },
        {
          value: "jobposting.employmentcategory.name",
          text: "รูปแบบการจ้างงาน",
        },
        { value: "createddate", text: "วันที่ยื่นใบสมัคร" },
        { value: "jobapplicationstatus.statusname", text: "สถานะใบสมัคร" },
        { value: "actions", text: "ตัวเลือก", sortable: false },
      ],
      activeButton: 1,
    };
  },
  computed: {
    filteredItems() {
      if (this.jobapplicationstatus_code == "") {
        return this.jobapplications;
      } else {
        return this.jobapplications.filter(
          (x) =>
            x.jobapplicationstatus.statuscode == this.jobapplicationstatus_code
        );
      }
    },
  },
  methods: {
    async initilize() {
      await this.loadCurrentStudent();
      await this.loadSchool();
      await this.loadJobApplications();
      this.updateAboutQuantityOfJobApplications();
    },
    async loadSchool() {
      console.log(this.student);
      if (this.student.veceducationinformations[0]) {
        var schoolid = this.student.veceducationinformations[0].schoolid;
        schoolDataProvider.loadSchool(schoolid).then((result) => {
          this.school = result.data;
        });
      }
    },
    async loadCurrentStudent() {
      await studentDataProvider.getCurrentStudent().then((result) => {
        this.student = result.data;
      });
    },
    async loadJobApplications() {
      await studentJobPostingDataProvider
        .getJobApplications()
        .then((result) => {
          this.jobapplications = result.data;
        });
    },
    openJobApplicationInfo(item) {
      const oid = item.oid;
      this.$router.push({ name: "JobApplicationInfo", params: { oid, item } });
    },
    updateAboutQuantityOfJobApplications() {
      this.quantity_of_all_jobapplication = this.jobapplications.length;
      this.quantity_of_pending_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Pending"
      ).length;
      this.quantity_of_underreview_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "UnderReview"
      ).length;
      this.quantity_of_interviewschduled_jobapplication =
        this.jobapplications.filter(
          (x) => x.jobapplicationstatus.statuscode == "InterviewScheduled"
        ).length;
      this.quantity_of_accepted_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Accecpted"
      ).length;
      this.quantity_of_rejected_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Rejected"
      ).length;
    },

    filterJobApplicationByJobApplicationStatusCode(statuscode = "") {
      this.jobapplicationstatus_code = statuscode;
    },
    openJobApplicationStatusInfoDialog(item) {
      this.selected_jobapplication = item;
      this.dialog = true;
    },
    startTimer() {
      const timer = setInterval(() => {
        this.updateJobApplicationsTime--;
        if (this.updateJobApplicationsTime <= 0) {
          clearInterval(timer);
          this.redirect();
        }
      }, 1000);
    },
    setActive(buttonNumber) {
      this.activeButton = buttonNumber;
    },
  },
  async created() {
    await this.initilize();
    // this.timer = setInterval(this.initilize, 5000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.custom-width {
  width: 100px;
  gap: 20px;
}
</style>
