import { isDateBetween, IsDateLaterThanEndDate } from "./DateTimeHelper";
import { CommonDataProvider } from "@/services/CommonDataProvider";
var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export async function isInCaseOfCurrentDateLaterThanTrackingEndDate(
  jobtrackingreport
) {
  if (jobtrackingreport.jobtracking_status_statuscode) {
    if (
      jobtrackingreport.jobtracking_status_statuscode ==
        "NotStartedTrackingProcess" ||
      jobtrackingreport.jobtracking_status_statuscode == "InProcessOfTracking"
    ) {
      let result = await commonDataProvider.getCurrentTime();
      const currentDate = new Date(result.data);
      let trackingEndDate = new Date(jobtrackingreport.trackingenddate);
      let trackingEndDat2e = trackingEndDate.setDate(trackingEndDate.getDate() + 1);
      return IsDateLaterThanEndDate(trackingEndDate, currentDate);
    }
    return false;
  } else {
    if (
      jobtrackingreport.jobtracking_status.statuscode ==
        "NotStartedTrackingProcess" ||
      jobtrackingreport.jobtracking_status.statuscode == "InProcessOfTracking"
    ) {
      let result = await commonDataProvider.getCurrentTime();
      const currentDate = new Date(result.data);
      let trackingEndDate = new Date(jobtrackingreport.trackingenddate);
      trackingEndDate = trackingEndDate.setDate(trackingEndDate.getDate() + 1);
      return IsDateLaterThanEndDate(trackingEndDate, currentDate);
    }
    return false;
  }
}

export async function isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate(
  jobtrackingreport
) {
  let result = await commonDataProvider.getCurrentTime();
  const currentDate = new Date(result.data);
  const trackingStartDate = new Date(jobtrackingreport.trackingstartdate);
  let trackingEndDate = new Date(jobtrackingreport.trackingenddate);
  trackingEndDate = trackingEndDate.setDate(trackingEndDate.getDate() + 1);
  return isDateBetween(trackingStartDate, trackingEndDate, currentDate);
}
