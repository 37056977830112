export function formatDateThai(dateString) {
  const monthsThai = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];
  
  const date = new Date(dateString);
  const year = date.getFullYear();
  const yearTH = date.getFullYear() + 543;
  const month = monthsThai[date.getMonth()];
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  
//   return `${day} ${month} ${year} เวลา ${hours}:${minutes}:${seconds}`;
  return `${day} ${month} ${yearTH} `;
}


export function isDateBetween(startDate, endDate, dateToCheck) {
  return dateToCheck >= startDate && dateToCheck <= endDate;
}

export function IsDateLaterThanEndDate(endDate,dateToCheck){
  return dateToCheck >= endDate;
}
