<template>
  <div>
    <template>
      <v-btn
        :disabled="isBtnDialogDisabled"
        class="mt-2 mr-3"
        color="warning"
        @click="extendTrackingMainEndDate_Dialog = true"
      >
        <v-icon class="mr-1">mdi-clock-outline</v-icon>
        ขยายเวลาวันที่สิ้นสุดการติดตาม</v-btn
      >
    </template>
    <v-dialog v-model="extendTrackingMainEndDate_Dialog" width="700">
      <template>
        <div>
          <v-card class="pa-10">
            <h2 class="primary--text">
              <v-icon class="primary--text">mdi-clock-outline</v-icon>
              ขยายเวลาสิ้นสุดการติดตาม
            </h2>
            <v-divider class="mt-4 mb-5 mx-0"></v-divider>
            <label class=""
              >สามารถขยายวันที่สิ้นสุดการติดตามของรอบการติดตามได้
              โดยการแก้ไขนี้จะมีผลกับระยะเวลาของการรายงานการติดตามของทุกสถานศึกษา</label
            >
            <v-row>
              <v-col cols="12" class="mt-5">
                <label
                  >วันที่สิ้นสุดการติดตามเดิม :
                  {{ this.convertDateFormat(main_tracking_enddate) }}</label
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="main_tracking_enddate">
                <TextFieldDatePicker
                  :value="main_tracking_enddate"
                  caption="กำหนดวันที่สิ้นสุดการติดตามใหม่"
                  @closeAndUpdate="this.closeAndUpdateTextFieldDatePicker_TrackingEndDate"
                >
                </TextFieldDatePicker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 justify-center d-flex">
                <v-btn @click="onCancelClick">ยกเลิก</v-btn>
                <v-btn
                  :loading="isInCreatingProcess"
                  @click="confirm_dialog = true"
                  class="mx-5 color-button2"
                >
                  ยืนยัน <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-dialog v-model="confirm_dialog" width="500">
              <template>
                <v-card class="pa-6">
                  <v-row class="justify-center text-center">
                    <v-card-title>
                      ยืนยันการขยายเวลาวันที่สิ้นสุดการติดตาม ?
                    </v-card-title>
                    <v-col cols="12">
                      <v-btn
                        @click="confirm_dialog = false"
                        class="ma-2"
                        elevation="2"
                        >ยกเลิก</v-btn
                      >
                      <v-btn @click="save()" class="ma-2 color-button2"> ยืนยัน </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-dialog>
          </v-card>
        </div>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";

import { ExtendTrackingMainEndDate_Request } from "@/models/GraduatedStudentJobTrackingReport/ExtendTrackingMainEndDate_Request";

import TextFieldDatePicker from "../common/TextFieldDatePicker.vue";

import { formatDateThai } from "@/DateTimeHelper";

const jobTrackingDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  components: { TextFieldDatePicker },
  name: "ExtendTrackingEndDate",
  props: [
    "GraduatedStudentJobTrackingReportMain",
    "extendTrackingMainEndDate_DialogStatus",
  ],
  data() {
    return {
      GraduatedStudentJobTrackingReportMainOid: "",
      extendTrackingMainEndDate_Dialog: false,
      graduatedStudentJobTrackingReport: [],
      selected_new_trackingEndDate: undefined,
      selected_trackingenddate: "",
      main_tracking_enddate: "",
      isInCreatingProcess: false,
      confirm_dialog: false,
    };
  },
  computed: {
    isBtnDialogDisabled() {
      if (this.GraduatedStudentJobTrackingReportMain == undefined) {
        return true;
      }
      return (
        this.GraduatedStudentJobTrackingReportMain.jobtracking_status_statuscode ==
        "TrackingProcessIsCompleted"
      );
    },
  },
  methods: {
    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },

    closeAndUpdateTextFieldDatePicker_TrackingEndDate(selectedDate = "") {
      this.selected_new_trackingEndDate = selectedDate;
    },

    save() {
      this.isInCreatingProcess = true;
      const request = new ExtendTrackingMainEndDate_Request();
      request.jobtrackingmainreport_oid = this.GraduatedStudentJobTrackingReportMain.oid;
      request.main_tracking_enddate = this.selected_new_trackingEndDate;

      jobTrackingDataProvider
        .ExtendTrackingMainEndDate(request)
        .then((response) => {
          if (response?.status == 200) {
            this.$toast.success(
              "บันทึกข้อมูลเปลี่ยนแปลง วันที่สิ้นสุดการติดตามเรียบร้อย"
            );
            this.$emit(
              "closeExtendTrackingMainEndDate_Dialog",
              this.selected_new_trackingEndDate,
              true
            );
            // this.extendTrackingMainEndDate_DialogStatus = false;
            this.isInCreatingProcess = false;
            this.extendTrackingMainEndDate_Dialog = false;
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data);
        });
    },
    onCancelClick() {
      this.extendTrackingMainEndDate_Dialog = false;
      this.$emit("close_Dialog", false);
    },
  },
  async created() {
    this.GraduatedStudentJobTrackingReportMainOid = this.GraduatedStudentJobTrackingReportMain.oid;
  },
  watch: {
    extendTrackingMainEndDate_DialogStatus(newVal) {
      this.extendTrackingMainEndDate_Dialog = newVal;
    },
    async extendTrackingMainEndDate_Dialog(newVal, oldVal) {
      if (newVal) {
        await jobTrackingDataProvider
          .LoadGraduatedStudentJobTrackingReportMain(
            this.GraduatedStudentJobTrackingReportMain.oid
          )
          .then((result) => {
            this.main_tracking_enddate = result.data.trackingenddate;
          })
          .catch((err) => {
            alert(err);
          });
      } else if (oldVal) {
        this.$emit(
          "closeExtendTrackingMainEndDate_Dialog",
          this.selected_new_trackingEndDate,
          false
        );
      }
    },
  },
};
</script>

<style></style>
