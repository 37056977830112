<template>
  <v-card class="mt-5">
    <v-data-table
      class="mt-2 custom-header-gradient"
      v-bind="$attrs"
      v-on="$listeners"
      :search="debouncedSearch"
      :items="data_items"
      :headers="datatable_headers"
      multi-sort
      :loading="data_items == undefined"
      :group-by="datatable_groupby"
      loading-text="Loading"
      dense
      :disablePagination="is_disable_pagination"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions,
      }"
    >
      <!-- pass through scoped slots -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData"></slot>
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName"></slot>
      </template>

      <template v-slot:top>
        <div class="pb-5 pt-3">
          <v-toolbar flat dense>
            <v-row>
              <v-col>
                <h3 class="primary--text">{{ local_table_caption }}</h3>
              </v-col>
            </v-row>
            <div class="mr-10"></div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              color="info"
            ></v-text-field>
          </v-toolbar>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "SearchableDataTable",
  props: {
    datatable_headers: undefined,
    data_items: undefined,
    table_caption: undefined,
    datatable_groupby: undefined,
    is_disable_pagination: { type: Boolean },
  },
  data() {
    return {
      search: "",
      debouncedSearch: "",
      debounceTimeout: null,
      local_table_caption: this.table_caption,
      local_datatable_headers: this.datatable_headers,
      local_data_items: this.data_items,
      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
    };
  },
  watch: {
    search(newSearch) {
      this.debounceSearch(newSearch);
    },
  },
  methods: {
    debounceSearch(newSearch) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.debouncedSearch = newSearch;
      }, 1000);
    },
  },

  mounted() {
    this.local_data_items = this.data_items;
    this.local_datatable_headers = this.datatable_headers;
  },
};
</script>

<style>
.custom-header-gradient .v-data-table-header {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.custom-header-gradient th {
  color: white !important;
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>
