<template>
  <v-container class="pa-5">
    <v-form class="elevation-1" ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2"> mdi-briefcase </v-icon
            >แก้ไขประเภทงาน</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col sm="12" md="6" lg="6">
              <v-text-field
                v-model="vm.code"
                label="รหัส"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="6" lg="6">
              <v-text-field
                v-model="vm.name"
                :rules="[(v) => !!v || 'กรุณาระบุชื่อประเภทงาน']"
                label="ชื่อประเภทงาน"
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center mb-4 mt-2">
            <v-btn @click="$router.go(-1)" class="ma-2"
              >ย้อนกลับ</v-btn
            >
            <v-btn class="ma-2 color-button2" @click="EditJobSegmen"
              >บันทึก <v-icon>mdi-content-save</v-icon></v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { JobSegmentDataProvider } from "@/services/JobSegmentDataProvider";
import { UpdateJobSegment_Request } from "@/models/JobSegment/UpdateJobSegment_Request";
var jobSegmentDataProvider = new JobSegmentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "EditJobSegmentDetailView",
  data() {
    return {
      valid: true,
      jobSegment_oid: "",
      vm: new UpdateJobSegment_Request(),
    };
  },

  methods: {
    async loadJobSegmen(jobSegment_oid = "") {
      await jobSegmentDataProvider
        .getJobSegmentById(jobSegment_oid)
        .then((response) => {
          this.vm.code = response.data.code;
          this.vm.oid = response.data.oid;
          this.vm.name = response.data.name;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async EditJobSegmen() {
      if (this.$refs.form.validate()) {
        await jobSegmentDataProvider
          .updateJobSegment(this.vm)
          .then((response) => {
            this.$toast.success("แก้ไขประเภทงานเรียบร้อย !");
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
  },

  async created() {
    this.jobSegment_oid = this.$route.params.oid;
    await this.loadJobSegmen(this.jobSegment_oid);
  },
};
</script>

<style>
input[type="text"][disabled] {
  color: black;
}
</style>
