
<template>
  <div class="pa-10">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-card rounded elevation="1" class="px-5 py-5">
      <v-row class="mt-1">
        <h3 class="primary--text" style="font-weight: bold">
          <v-icon color="primary" class="ml-2"
            >mdi-newspaper-variant-multiple-outline</v-icon
          >
          รายการข่าวประชาสัมพันธ์
        </h3>
      </v-row>
      <v-divider class="my-4"></v-divider>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <h4 class="primary--text mb-2">ค้นหา</h4>
          <v-text-field
            clearable
            placeholder="ค้นหา"
            filled
            v-model="search"
            background-color="input"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="6" md="6" sm="12">
          <h4 class="primary--text mb-2">สถานะการเผยแพร่ข่าวสาร</h4>
          <v-autocomplete
            clearable
            placeholder="สถานะการเผยแพร่ข่าวสาร"
            :items="ListStatusNewsSelection"
            item-text="name"
            item-value="code"
            v-model="selectedStatus"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col
          cols="12"
          lg="2"
          md="12"
          sm="12"
          align-self="center"
          class="d-flex justify-center"
          ><v-btn color="primary" @click="gotocreatenews"
            ><v-icon class="mr-2">mdi-plus</v-icon> เพิ่มข่าวประชาสัมพันธ์</v-btn
          ></v-col
        > -->
      </v-row>
      <v-row align-self="center">
        <v-col cols="12" lg="6" md="6" sm="12" align-self="center">
          <h4 style="font-weight: bold">
            ข่าวประชาสัมพันธ์ทั้งหมด {{ itemlength }} รายการ
          </h4>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          class="d-flex justify-md-end justify-start"
          ><v-btn
            color="primary"
            @click="gotocreatenews"
            class="d-flex justify-end"
          >
            เพิ่มข่าวประชาสัมพันธ์ <v-icon class="ml-1">mdi-plus</v-icon></v-btn
          ></v-col
        >
      </v-row>

      <v-row>
        <v-col
          cols="12"
          lg="4"
          md="6"
          sm="12"
          v-for="item in newscontents"
          :key="item.oid"
          class="mb-5"
        >
          <v-card height="600px" class="d-flex flex-column">
            <v-container class="fill-height d-flex flex-row">
              <v-row>
                <v-col cols="12" class="d-flex justify-end">
                  <p>
                    ประกาศ ณ วันที่
                    {{ convertDateFormat(item.startdate) }}
                  </p>
                </v-col>
              </v-row>
              <v-row justify="center"
                ><v-col
                  v-if="item.bannerthumbnailbase64"
                  class="d-flex align-center justify-center pa-0"
                  cols="12"
                >
                  <v-card
                    flat
                    style="
                      height: 250px;
                      width: 100%;
                      background-color: #f2f4f8;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      overflow: hidden;
                    "
                  >
                    <v-img
                      alt="test"
                      class="shrink fill-height"
                      contain
                      transition="scale-transition"
                      aspect-ratio="1/1"
                      cover
                      v-bind:lazy-src="
                        'data:image/jpg;base64,' + item.bannerthumbnailbase64
                      "
                      v-bind:src="
                        'data:image/jpg;base64,' + item.bannerthumbnailbase64
                      "
                    />
                  </v-card>
                </v-col>
                <v-col v-else class="d-flex justify-center" cols="12">
                  <v-img
                    alt="test"
                    class="shrink fill-height"
                    contain
                    transition="scale-transition"
                    aspect-ratio="1/1"
                    cover
                    :src="require(`@/assets/user-img.jpg`)"
                  /> </v-col
              ></v-row>
              <v-row class="" style="height: 40%">
                <v-col class="px-10" cols="12">
                  <v-row>
                    <h4 class="primary--text" style="font-weight: bold">
                      {{ truncatedTitle(item.title) }}
                      <v-chip class="ml-2" :small="true">
                        {{ getNewsStatus(item.publishstatus) }}
                      </v-chip>
                      <v-chip
                        v-if="item.isbignews === true"
                        color="error"
                        class="ml-2"
                        :small="true"
                      >
                        ข่าวสำคัญ
                      </v-chip>
                    </h4>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col class="pa-0">
                      <p>แหล่งที่มา : {{ item.reforiginname ? item.reforiginname : "ส่วนกลาง" }} </p>
                      <p>
                        วันที่สร้าง : {{ convertDateFormat(item.createddate) }}
                      </p>

                      <p>
                        วันที่สิ้นสุด :
                        {{ convertDateFormat(item.expiredate) }}
                      </p>
                      <p>ผู้เขียน : {{ item.writerfullname }}</p>
                      <p>
                        แก้ไขล่าสุด : {{ convertDateFormat(item.editeddate) }}
                      </p>
                      <!-- <p>
                        สถานะการเผยแพร่ :
                        <v-chip>
                          {{ getNewsStatus(item.publishstatus) }}
                        </v-chip>
                      </p> -->
                      <!-- <p>รายละเอียด : {{ item.description }}</p> -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-row justify="center" class="mt-auto mb-1">
              <v-col cols="12" class="d-flex justify-center">
                <v-btn @click="OnclickDelete(item)" outlined class="mx-4">
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  @click="openNewsManagementDetailView(item)"
                  class="color-button2 mr-4"
                >
                  รายละเอียดเพิ่มเติม
                </v-btn></v-col
              >
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <div class="text-center">
        <v-pagination
          class="pl-6"
          v-if="itemlength > 0"
          v-model="pageChanged"
          :length="pagelength"
          :total-visible="itemsPerPage"
          @input="handlePageChange"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>


<script>
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { NewsDataProvider } from "@/services/NewsDataProvider";
import { formatDateThai } from "@/DateTimeHelper";
import { NewsStatusItems } from "@/Constants";
import { NewsPagination_request } from "@/models/News/NewsPagination_request";

const newsDataProvider = new NewsDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTablePaging },
  name: "NewsManagementListView",

  data() {
    return {
      newscontents: [],
      itemlength: 0,
      News_data_table_headers: [
        {
          text: "",
          value: "bannerthumbnailbase64",
          align: "center",
          align: " d-none",
        },
        { text: "หัวข้อ/เรื่อง", value: "title", align: " d-none" },
        { text: "คำอธิบาย", value: "description", align: " d-none" },
        {
          text: "วันที่สร้าง",
          value: "createddate",
          align: "center",
          align: " d-none",
        },
        {
          text: "วันที่สิ้นสุด",
          value: "expiredate",
          align: "center",
          align: " d-none",
        },
        {
          text: "ชื่อสถาบัน",
          value: "schoolname",
          align: "center",
          align: " d-none",
        },
        {
          text: "สถานะการเผยแพร่",
          value: "publishstatus",
          align: "center",
          align: " d-none",
        },
        { text: "", value: "actions" },
      ],
      ListStatusNewsSelection: NewsStatusItems,
      selectedStatus: "",
      itemsPerPage: 9,
      pageChanged: 1,
      request: new NewsPagination_request(),
      pagelength: 0,
      selectedSchooltype: "",
      search: "",
    };
  },
  methods: {
    openNewsManagementDetailView(item) {
      const oid = item.oid;
      this.$router.push({
        name: "EditNewsManagementDetailView_SchoolEmployee",
        params: { oid },
      });
    },
    OnclickDelete(item) {
      if (confirm("ต้องการลบข่าวนี้หรือไม่ ?")) {
        newsDataProvider
          .deleteNewsContent(item.oid)
          .then(() => {
            this.$toast.success("ลบข้อมูลเรียบร้อย");
            this.LoadNewsInitList();
          })
          .catch((err) => {
            this.$toast.error("ล้มเหลว" + err);
          });
      }
    },
    gotocreatenews() {
      this.$router.push({
        name: "CreateNewsManagementDetailView",
      });
    },
    async LoadNewsInitList() {
      this.request.statuscode = this.selectedStatus;
      this.request.search = this.search;
      this.request.schooltype = this.selectedSchooltype;
      this.request.itemperpage = this.itemsPerPage;
      this.request.page = this.pageChanged;
      await newsDataProvider
        .loadNewsPaginationListForSchoolEmployee(this.request)
        .then((result) => {
          console.log(result.data, "RESULT");
          this.newscontents = result.data.news.map((news) => ({
            ...news,
            editeddate: news.editeddate === "0001-01-01T00:00:00" || !news.editeddate ? news.createddate : news.editeddate,
          }));
          this.itemlength = result.data.total;
          this.$emit("itemlength", this.itemlength);
          this.pagelength = Math.ceil(this.itemlength / this.itemsPerPage);
          console.log(this.pagelength, "PAGELENGTH");
        })
        .catch((err) => {
          this.$toast.error("ล้มเหลว" + err);
        });
    },
    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },

    getNewsStatus(statusCode) {
      const status = NewsStatusItems.find(
        (status) => status.code === statusCode
      );
      return status ? status.name : "ไม่ทราบสถานะ";
    },
    handlePageChange(page) {
      this.pageChanged = page;

      console.log(this.pageChanged);
      this.LoadNewsInitList();
    },
  },
  mounted() {
    this.LoadNewsInitList();
  },
  watch: {
    selectedStatus(value) {
      this.LoadNewsInitList();
    },
    search(keyword) {
      this.request.search = keyword;
      this.LoadNewsInitList();
    },
  },
  computed: {
    filteredNewsContents() {
      return this.newscontents;
    },
    truncatedTitle() {
      return (title) => {
        if (title.length > 120) {
          return title.substring(0, 120) + "...";
        }
        return title;
      };
    },
  },
};
</script>

<style scoped>
.vertical-table .v-data-table__wrapper {
  display: flex;
  flex-direction: column;
}
.vertical-row {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}
.image-cell {
  flex: 0 0 auto;
  margin-right: 20px;
}
.info-cell {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.vertical-cell {
  padding: 5px 0;
}
.actions-cell {
  display: flex;
  gap: 10px;
}
p {
  font-size: 12px;
  margin-bottom: 4px;
}
</style>