<template>
  <div class="container">
    <v-row class="pa-3 mb-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-map</v-icon> ประเทศไทย
      </h3>
    </v-row>
    <v-card class="pa-4" v-if="provinceDetail">
      <v-row class="mb-4 mt-2">
        <h3 class="primary--text">
          <v-icon class="primary--text">mdi-map</v-icon> {{ provinceDetail.provincethai }}
        </h3>
      </v-row>
      <v-divider class="mt-4 mx-0 mb-4"></v-divider>
      <v-row>
        <v-col>
          <label class="heading"
            >ประเภท :
            <label>{{ provinceDetail.provincetype }}</label>
          </label>
          <label class="heading ml-8"
            >ภาค :
            <label>{{ provinceDetail.regionascommon }}</label>
          </label>
          <label class="heading ml-8"
            >จังหวัด (English) :
            <span>{{ provinceDetail.provinceeng }}</span>
          </label>
        </v-col>
      </v-row>
    </v-card>
    <SearchableDataTable
      v-if="districts"
      table_caption="อำเภอ"
      :datatable_headers="district_data_table_headers"
      :data_items="districts"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToTambonListView(item)" icon color="info">
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: { SearchableDataTable },
  name: "DistrictListView",
  data() {
    return {
      districts: [],
      district_data_table_headers: [
        { text: "รหัส (id)", value: "districtid" },
        { text: "ชื่อออำเภอ (ไทย)", value: "districtthai" },
        { text: "ชื่อออำเภอ (Eng)", value: "districteng" },
        { text: "", value: "action" , sortable: false},
      ],
      provinceID: "",
      provinceDetail: [],
    };
  },
  methods: {
    async loadDistricts() {
      await masterDataProvider
        .loadGetDistricts(this.provinceID)
        .then((result) => {
          this.provinceDetail = result.data[0].province;
          this.districts = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToTambonListView: function (item) {
      var districtID = item.districtid;
      this.$router
        .push({ name: "TambonListView", params: { districtID: districtID } })
        .catch((err) => {});
    },
  },
  async created() {
    this.provinceID = this.$route.params.provinceID;
    await this.loadDistricts();
  },
};
</script>

<style></style>
