<template>
  <div class="pa-4">
    <v-card rounded elevation="1" class="pa-2 my-0 mb-2">
      <div class="d-flex flex-row">
        <v-row class="d-flex">
          <v-col class="pt-5" xl="2" md="2" lg="2" sm="12" xs="12">
            <h4 class="mb-2 mb-0 ml-2 primary--text px-2">กรองข้อมูล :</h4>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-text-field
            class="mr-3 ml-3"
            clearable
            label="ค้นหา : ชื่อตำแหน่ง/ชื่อบริษัท"
            variant="solo-filled"
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-select
            label="สถานที่ทำงานทั้งหมด"
            item-text="provincethai"
            item-value="provincethai"
            :items="provincelist"
            v-model="Selectedprovince"
            class="mr-3"
            solo
            clearable
          >
          </v-select>
        </v-col>

        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-select
            label="ประเภทการจ้างงาน"
            v-model="Selectedemployment"
            :items="employmentType"
            class="mr-3"
            solo
            clearable
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-select
            label="ระดับชั้น"
            v-model="SelectedmainGrade"
            item-text="mainGradelist"
            item-value="mainGradelist"
            :items="mainGradelist"
            class="mr-3"
            solo
            clearable
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card>
    <v-card rounded elevation="1" class="pa-2 my-0 mb-2">
      <div class="d-flex flex-row">
        <v-row class="d-flex">
          <v-col class="pt-5" xl="2" md="2" lg="2" sm="12" xs="12">
            <h4 class="mb-2 mb-0 ml-2 primary--text px-2">ผลลัพธ์การค้นหา "10" รายการ</h4>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-row>
      <v-col v-for="job in foundJobs" :key="job.oid" cols="12" md="6">
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="5" md="5">
                <div class="primary--text">
                  <h3>
                    <b>{{ job.jobposition }}</b>
                  </h3>
                </div>

                <br />
                <span>{{ job.companyname }}</span> <br /><br />
                <span>{{ job.provincethai }}</span
                ><br /><br />
                <span>{{ job.posted_date }} {{ job.visited_number }}</span>
              </v-col>

              <v-divider vertical></v-divider>
              <v-col cols="5" md="5">
                <v-col cols="12" md="12 mt-10">
                  <span>{{ job.quantity_of_position }}</span
                  ><br /><br />
                  <span>{{ job.salary }}</span
                  ><br /><br />

                  <br />
                </v-col>
              </v-col>
              <v-col cols="2" md="2">
                <v-col cols="12" md="12">
                  <v-chip class="ma-2" color="error" text-color="white">
                    {{ job.is_argent }}
                  </v-chip>
                </v-col>
              </v-col>

              <v-btn
                block
                style="background-cokir"
                color="primary"
                @click="goToSearchJobDetails(item)"
                ><v-icon>mdi-account</v-icon>รายละเอียดเพิ่มเติม</v-btn
              >
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SearchStudentService } from "@/services/SearchStudentService.js";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { TempDataHelper } from "@/TempDataHelper";

var masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var searchStudentService = new SearchStudentService(process.env.VUE_APP_BACKEND_API_URL);

export default {
  name: "SearchJob",
  props: [],
  data() {
    return {
      provincelist: [],
      selectedSchooltype: 0,
      request_filter: [],
      datatable_headers: [
        {
          value: "school.tambon.district.province.provincethai",
          text: "จังหวัด",
        },
        { value: "action", text: "" },
      ],
      Selectedprovince: "",
      schoolRequest: new GetSchools_Reqeust(),
      mainGradelist: [],
      Selectedemployment: "",
      SelectedmainGrade: "",

      employmentType: [
        "งานประจำ (Full Time)",
        "งานนอกเวลา (Part Time)",
        "ฝึกงาน",
        "งานอิสระ (Freelance)",
        "งานจ้างรายวัน",
        "งานจ้างชั่วคราว",
        "รับงานไปทำที่บ้าน",
      ],
      foundJobs: TempDataHelper.TempJobs,
      page: 1,
      length: 8,
      request: [],
    };
  },
  watch: {
    page: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.request.page = newVal;
          this.request.itemperpage = this.length;
        } else {
        }
      },
    },
    Selectedprovince: {
      handler(newVal, oldVal) {
        if (newVal) {
        } else {
        }
      },
    },
  },
  methods: {
    async loadProvinces() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provincelist = result.data;
      });
    },

    async loadMainGradeTHNames() {
      await commonDataProvider.getMainGradeTHNames().then((result) => {
        this.mainGradelist = result.data;
      });
    },
    goToSearchJobDetails: function () {
      this.$router.push({ name: "SearchJobDetails" }).catch((err) => {});
    },
  },
  async created() {
    await this.loadProvinces();
    await this.loadMainGradeTHNames();

    this.request.page = 1;
    this.request.itemperpage = this.length;
  },
};
</script>
