<template>
  <v-card height="300"
    ><v-system-bar color="green darken-2" dark></v-system-bar
    ><v-card-title>สถานะการได้งานทำ</v-card-title>
    <v-list-item
      v-if="item.label"
      v-for="item in listJobTrackingStatus"
      :key="item.label"
    >
      <v-list-item-title>
        {{ CodeToThai(item) }}
      </v-list-item-title>
      <v-list-item-title>{{ item.count }} รายการ</v-list-item-title>
    </v-list-item>
  </v-card>
</template>

<script>
import { DashboardDataSourceProvider } from "@/services/DashboardDataSourceProvider";
import { backendapiUrl } from "@/services/constants";
import { GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request";

var dashboardDataSourceProvider = new DashboardDataSourceProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import { EmploymentStatusCodes } from "@/Constants";
export default {
  props: {
    SelectedTargetGraduatedYearTH: {
      default: "",
      type: String,
    },
    SelectedRoundNumber: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      targetEmploymentStatusCodes: [],
      listJobTrackingStatus: [
        { label: "", count: 0 },
        { label: "", count: 0 },
        { label: "", count: 0 },
        { label: "", count: 0 },
        { label: "", count: 0 },
      ],
    };
  },
  watch: {
    SelectedTargetGraduatedYearTH(newValue, OldValue) {
      this.loadEmploymentStatus();
    },
    SelectedRoundNumber(newValue, OldValue) {
      this.loadEmploymentStatus();
    },
  },
  methods: {
    CodeToThai(item) {
      return item.label == EmploymentStatusCodes.Study
        ? "ศึกษาต่อ"
        : item.label == EmploymentStatusCodes.WorkForCompany
        ? "บริษัทเอกชน"
        : item.label == EmploymentStatusCodes.Government
        ? "หน่วยงานราชการ"
        : item.label == EmploymentStatusCodes.Freelance
        ? "อาชีพอิสระ"
        : "อื่นๆ";
    },
    async initialize() {
      this.targetEmploymentStatusCodes = [];
      this.targetEmploymentStatusCodes.push(
        EmploymentStatusCodes.Study,
        EmploymentStatusCodes.WorkForCompany,
        EmploymentStatusCodes.Government,
        EmploymentStatusCodes.Freelance
      );
    },
    async loadEmploymentStatus() {
      const request = new GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource_Request();
      request.TargetGraduatedYearTH = this.SelectedTargetGraduatedYearTH;
      request.RoundNumber = this.SelectedRoundNumber;
      request.TargetEmploymentStatusCodes = this.targetEmploymentStatusCodes;
      await dashboardDataSourceProvider
        .GetGraduatedStudentJobTrackingReport_EmploymentStatusDashboardDataSource(request)
        .then((result) => {
          for (var index = 0; index < result.data.labels.length; index++) {
            (this.listJobTrackingStatus[index].label = result.data.labels[index]),
              (this.listJobTrackingStatus[index].count = result.data.data[index]);
          }
        });
    },
  },
  mounted() {
    this.initialize();
    this.loadEmploymentStatus();
  },
};
</script>

<style></style>
