<template>
  <div style="menu-container">
    <header></header>
    <v-container>
      <v-row class="menu-row" no-gutters>
        <v-col cols="12" md="6" lg="4" class="menu-col">
          <v-hover v-slot="{ hover }">
            <!-- <v-card
              class="outer-card"
              rounded="xl"
              style="padding: 1%"
              :color="IsDisableMenu1 ? 'secondary' : ''"
            > -->
            <v-card
              rounded="xl"
              @click="expandTrackingMenu"
              class="menu-card no-border"
              max-width="100%"
              max-height="100%"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                class="menu-img"
                width="450px"
                src="@/assets/Working-Tracking-Icon.png"
              >
              </v-img>

              <v-card-title class="primary--text menu-title">
                <div>ระบบติดตามภาวะการมีงานทำและ</div>
                <div>การศึกษาต่อของผู้สำเร็จการศึกษา</div>
              </v-card-title>
            </v-card>
            <!-- </v-card> -->
          </v-hover>
        </v-col>

        <v-col cols="12" md="6" lg="4" class="menu-col">
          <v-hover v-slot="{ hover }">
            <!-- <v-card
              rounded="xl"
              style="padding: 1%"
              :color="IsDisableMenu2 ? 'secondary' : ''"
            > -->
            <v-card
              rounded="xl"
              @click="collapseTrackingMenu"
              class="menu-card"
              max-width="100%"
              max-height="100%"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img class="menu-img" width="450px" src="@/assets/VCOP-Link-Icon.png">
              </v-img>

              <v-card-title class="primary--text menu-title">
                <div>ศูนย์เครือข่ายกำลังคนอาชีวศึกษา</div>
              </v-card-title>
            </v-card>
            <!-- </v-card> -->
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
import router from "../router";
export default {
  name: "HomeMenu",
  components: { appbar },
  methods: {
    expandTrackingMenu() {
      // this.$vuetify.theme.themes.light.vec = '#873132'
      // this.$vuetify.theme.themes.dark.vec = '#873132'
      // localStorage.setItem('expandTrackingMenu',true)
      this.$store.dispatch({ type: "expandTrackingMenu" });
      // this.$emit("expand-sub-menu", { menu: "SchoolListView" });
      this.$router.push({ path: "/School/SchoolListView" });
    },
    collapseTrackingMenu() {
      // this.$vuetify.theme.themes.light.vec = '#136D15'
      // this.$vuetify.theme.themes.dark.vec = '#136D15'
      // localStorage.setItem('expandTrackingMenu',false)
      this.$store.dispatch({ type: "collapseTrackingMenu" });
      this.$router.push({ path: "/JobPosting/CenterStaffJobPostingListView" });
    },
  },
  computed: {
    IsDisableMenu1() {
      return this.$store.getters["isTrackingMenuExpanded"];
    },
    IsDisableMenu2() {
      return !this.$store.getters["isTrackingMenuExpanded"];
    },
  },
};
</script>

<style scoped>
.menu-container {
  margin-top: 10%;
}
.menu-row {
  display: flex;
  justify-content: center;
}
.menu-col {
  display: flex;
  justify-content: center;
  margin: 1%;
}
.menu-card {
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 0px solid transparent;
  box-shadow: none;
}
.menu-img {
  width: 100%;
}
.menu-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

@media (max-width: 600px) {
  .menu-card {
    width: 100%;
  }
  .menu-img {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .menu-card {
    width: 100%;
  }
  .menu-img {
    width: 100%;
  }
}

@media (min-width: 960px) {
  .menu-card {
    width: 450px;
  }
  .menu-img {
    width: 100%;
  }
}
</style>
