<template>
  <v-container class="">
    <v-row>
      <v-col cols="12"
        ><bread-crumb
          title="รายชื่อนักศึกษาแบ่งตามสถานศึกษา"
          icon="mdi-home-city"
        ></bread-crumb
      ></v-col>
    </v-row>
    <v-row>
      <v-col lg="3" md="6" sm="12" cols="12">
        <graduate-tracing-card
          overline="สำเร็จการศึกษา"
          header="100,000"
          description="คน"
          icon="mdi-account"
          backgroundColor="#00a65a"
        ></graduate-tracing-card>
      </v-col>
      <v-col lg="3" md="6" sm="12" cols="12">
        <graduate-tracing-card
          overline="ติดตามสถานะแล้ว"
          header="70,000"
          description="คน"
          icon="mdi-account"
          backgroundColor="blue"
        ></graduate-tracing-card>
      </v-col>
      <v-col lg="3" md="6" sm="12" cols="12">
        <graduate-tracing-card
          overline="ไม่สามารถติดตามได้"
          header="30,000"
          description="คน"
          icon="mdi-account"
          backgroundColor="#bb4b39"
        ></graduate-tracing-card>
      </v-col>
      <v-col lg="3" md="6" sm="12" cols="12">
        <graduate-tracing-card
          overline="การส่งรายงาน"
          header="250/400"
          description="แห่ง/สถานศึกษาทั้งหมด"
          icon="mdi-home-city"
          backgroundColor="#f39c12"
        ></graduate-tracing-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <!-- <v-data-table
            :headers="[
              { text: 'ชื่อ', value: 'firstname' },
              { text: 'นามสกุล', value: 'lastname' },
            ]"
            :items="[
              { firstname: 'xxxxx', lastname: 'xxxxx' },
              { firstname: 'RRRRRR', lastname: 'RRRRRR' },
            ]"
          >
          </v-data-table> -->
          <v-data-table :search="search" :headers="headers" :items="dataArray">
            <!-- temoate ค้นหา  -->
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>สถานศึกษา</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="ค้นหาข้อมูล"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  @click="$router.push('/graduate/tracking/create')"
                  color="primary"
                  dark
                  class="mb-2"
                >
                  <v-icon left>add</v-icon>
                  <span>สถานศึกษา</span>
                </v-btn>
              </v-toolbar>
            </template>
            <!-- template row,column -->
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>
                  <v-img
                    v-bind:src="item.image | urlImage"
                    max-height="50"
                    max-width="50"
                    lazy-src="http://localhost:3000/images/cam_ico.png"
                    aspect-ratio="1"
                  ></v-img>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.price | currency("฿") }}</td>
                <td>{{ item.stock | number("0,0") }} pcs.</td>
                <td>
                  <v-icon class="mr-2" @click="editItem(item)"> edit </v-icon>
                  <span class="ma-1"></span>
                  <v-icon @click="deleteItem(item)"> delete </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GraduateTracingCard from "@/components/card/GraduateTracingCard.vue";
import BreadCrumb from "@/layouts/BreadCrumb.vue";
import Axios from "axios";

export default {
  name: "GraduateTracingCardView",
  components: {
    GraduateTracingCard,
    BreadCrumb,
  },
  data() {
    return {
      search: "",
      dataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "Image", value: "image" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Stock", value: "stock" },
        { text: "Action", value: "action" },
      ],
    };
  },
  mounted() {
    Axios.get("http://localhost:3000/api/v1/graduate").then((result) => {
      this.dataArray = result.data;
    });
  },
};
</script>

<style scoped>
.BreadCrumb {
  padding-left: 10px;
}
.BreadCrumb .Title {
  line-height: 65px;
}
.BreadCrumb .Nav {
  line-height: 40px;
}
</style>
