<template>
  <v-container class="ma-4">
    <v-row class="justify-center">
      <v-col lg="10" md="12" sm="12" xs="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar class="toolbar-Header" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2">mdi-account-circle </v-icon>
                เพิ่มข้อมูลบุคลากร
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="py-6 px-12 d-flex justify-center">
              <v-col cols="10" class="my-7">
                <v-row class="">
                  <v-icon class="pr-2" color="primary">mdi-account</v-icon>
                  <h3 class="primary--text">
                    ข้อมูลบุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)
                  </h3>
                </v-row>
                <v-row class="py-2" style="margin-top: 1%; margin-bottom: 2%">
                  <v-divider></v-divider>
                </v-row>
                <v-row class="d-flex align-end">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-autocomplete
                      v-model="selected_schoolCode"
                      item-value="schoolid"
                      item-text="schoolname"
                      :items="schools"
                      :rules="[(v) => !!v || 'กรุณาเลือกสถานศึกษา']"
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="py-0">
                    <h4 class="primary--text mb-2">
                      เพศ<span style="color: red">*</span>
                    </h4>
                    <v-radio-group
                      :rules="[(v) => !!v || 'กรุณาระบุเพศ']"
                      v-model="vm.gender"
                      row
                    >
                      <v-radio label="ชาย" color="blue" value="male"></v-radio>
                      <v-radio
                        label="หญิง"
                        color="pink"
                        value="female"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="4" md="4" sm="12" xs="12">
                    <h4 class="primary--text mb-2">
                      คำนำหน้าชื่อ<span style="color: red">*</span>
                    </h4>
                    <v-autocomplete
                      v-model="vm.titlenameid"
                      :items="titlenamelist"
                      :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                      placeholder="คำนำหน้าชื่อ"
                      item-text="titlenameth"
                      item-value="titlenameid"
                      required
                      clearable
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="4" md="4" sm="12" xs="12">
                    <h4 class="primary--text mb-2">
                      ชื่อจริง<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      placeholder="ชื่อจริง"
                      required
                      :rules="firstnameRules"
                      v-model="vm.name"
                      background-color="input"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col lg="4" md="4" sm="12" xs="12">
                    <h4 class="primary--text mb-2">
                      นามสกุล<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      placeholder="นามสกุล"
                      required
                      :rules="lastnameRules"
                      v-model="vm.lastname"
                      background-color="input"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6" sm="12" xs="12">
                    <h4 class="primary--text mb-2">
                      ตำแหน่ง<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      placeholder="ตำแหน่ง"
                      required
                      :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                      v-model="vm.position"
                      background-color="input"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" md="6" sm="12" xs="12">
                    <h4 class="primary--text mb-2">
                      เลขประจำตัวประชาชน<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      placeholder="เลขประจำตัวประชาชน"
                      required
                      :rules="idRules"
                      v-model="vm.idcard"
                      background-color="input"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6" sm="12" xs="12">
                    <h4 class="primary--text mb-2">
                      อีเมล<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      placeholder="อีเมล"
                      required
                      :rules="emailRules"
                      v-model="vm.email"
                      background-color="input"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" md="6" sm="12" xs="12">
                    <h4 class="primary--text mb-2">
                      หมายเลขโทรศัพท์<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      placeholder="หมายเลขโทรศัพท์"
                      required
                      :rules="phoneRules"
                      v-model="vm.phonenumber"
                      background-color="input"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-start">
                    <h3 class="primary--text">
                      <v-icon class="primary--text">mdi-account-box</v-icon>
                      เกี่ยวกับการเข้าใช้งาน
                    </h3>
                  </v-col>
                </v-row>

                <v-divider
                  class="py-2"
                  style="margin-top: 1%; margin-bottom: 2%"
                ></v-divider>

                <v-row>
                  <v-col cols="12">
                    <h4 class="primary--text mb-2">
                      ชื่อบัญชีผู้ใช้ (Username)<span style="color: red"
                        >*</span
                      >
                    </h4>
                    <v-text-field
                      :rules="custom_usernameRules"
                      v-model="vm.username"
                      placeholder="ชื่อผู้ใช้งาน (Username)"
                      background-color="input"
                      filled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h4 class="primary--text mb-2">
                      รหัสผ่าน (Password)<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      autocomplete="new-password"
                      v-model="vm.password"
                      name="password"
                      :rules="passwordRules"
                      placeholder="รหัสผ่าน"
                      background-color="input"
                      filled
                      :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isShowPassword = !isShowPassword"
                      :type="isShowPassword ? 'text' : 'password'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h4 class="primary--text mb-2">
                      ยืนยันรหัสผ่าน (Confirm Password)<span style="color: red"
                        >*</span
                      >
                    </h4>
                    <v-text-field
                      v-model="vm.confirm_password"
                      :rules="[
                        (v) => !!v || 'กรุณายืนยันรหัสผ่าน (Confirm Password)',
                        (v) => v === vm.password || 'รหัสผ่านไม่ตรง',
                      ]"
                      name="confirmPassword"
                      :type="isConfirmShowPassword ? 'text' : 'password'"
                      placeholder="ยืนยันรหัสผ่าน"
                      background-color="input"
                      filled
                      :append-icon="
                        isConfirmShowPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="
                        isConfirmShowPassword = !isConfirmShowPassword
                      "
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>

          <v-row class="mt-4 mb-4" justify="end">
            <v-col cols="4" class="d-flex justify-end">
              <v-btn @click="$router.go(-1)" class="mx-2">
                ย้อนกลับ</v-btn
              >
              <v-btn class="color-button2" @click="save">
                บันทึก <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CreateSchoolAdminProfile_Request } from "@/models/Profile/SchoolAdminProfile/CreateSchoolAdminProfile_Request";
import { SchoolAdminProfileDataProvider } from "@/services/Profile/SchoolAdminProfile/SchoolAdminProfileDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
import {
  emailRules,
  firstnameRules,
  lastnameRules,
  passwordRules,
  phoneRules,
  usernameRules,
} from "@/ValidationHelper";

const schoolAdminProfileDataProvider = new SchoolAdminProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Admin",
  data() {
    return {
      oid: "",
      vm: new CreateSchoolAdminProfile_Request(),
      titlenamelist: [],
      schoolEmployeeProfile_SchoolRights_table_headers: [
        { text: "สถานศึกษา", value: "school.schoolname" },
        {
          text: "จังหวัด",
          value: "school.tambon.district.province.provincethai",
        },
        { text: "", value: "action" , sortable: false},
      ],
      schools: [],
      selected_schoolCode: undefined,
      valid: true,
      roleAndPermission_request: [],
      colorbutton: "success",
      isShowPassword: false,
      isConfirmShowPassword: false,
      emailRules: emailRules,
      phoneRules: phoneRules,
      passwordRules: passwordRules,
      usernameRules: usernameRules,
      firstnameRules: firstnameRules,
      lastnameRules: lastnameRules,
      usernameExistResult: undefined,
      emailExistResult: false,
      identityidExistResult: false,
      idRules: [
        (v) => !!v || "กรุณาระบุเลขประจำตัวประชาชน",
        (v) => this.checkID(v) || "รูปแบบหมายเลขไม่ถูกต้อง",
        (v) =>
          this.identityidExistResult !== true || "หมายเลขที่กรอกไม่ถูกต้อง",
      ],
    };
  },
  watch: {
    selected_schoolCode(newvalue) {
      if (newvalue) {
        this.vm.schoolid = newvalue;
      }
    },
    "vm.username": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isUsernameUsed(newVal)
            .then((result) => {
              this.usernameExistResult = result.data;
              this.$refs.txtUsername.validate();
            });
        }
      },
      deep: true,
    },
    "vm.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isEmailUsed(newVal)
            .then((result) => {
              this.emailExistResult = result.data;
              this.$refs.txtEmail.validate();
            });
        }
      },
      deep: true,
    },
    "vm.idcard": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isIdentityIDUsed(newVal)
            .then((result) => {
              this.identityidExistResult = result.data;
              this.$refs.txtIdentityid.validate();
            });
        }
      },
      deep: true,
    },
  },
  methods: {
    async initilize() {
      const request = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(request).then((result) => {
        this.schools = result.data;
      });
      await this.loadTitleName();
      await this.loadSchoolsBy_CurrentApplicationUser();
    },
    async loadSchoolsBy_CurrentApplicationUser() {
      await schoolDataProvider
        .getSchoolsBy_CurrentApplicationUser()
        .then((result) => {
          this.schools = result.data;
          if (this.schools.length === 1) {
            this.selected_schoolCode = this.schools[0].schoolid;
          }
        });
    },
    async loadTitleName() {
      await masterDataProvider
        .getActiveTitleNames()
        .then((response) => {
          this.titlenamelist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (confirm("ต้องการบันทึกหรือไม่?")) {
          await schoolAdminProfileDataProvider
            .createSchoolAdminProfile_School_Admin(this.vm)
            .then((result) => {
              this.$store.dispatch({ type: "doSetIsLoadingProfile" });
              this.$toast.success("บันทึกข้อมูลเรียบร้อย");
              this.$router.push(
                "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Admin"
              );
            })
            .catch((err) => {
              this.$toast.error(err);
            });
        }
      }
    },
    checkID(id) {
      if (id.length !== 13) return false;
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        sum += parseFloat(id.charAt(i)) * (13 - i);
      }
      const test = (11 - (sum % 11)) % 10 === parseFloat(id.charAt(12));
      return test;
    },
  },
  computed: {
    custom_usernameRules() {
      return [
        ...usernameRules,
        (v) =>
          this.usernameExistResult === false ||
          "ชื่อผู้ใช้งาน (Username) ถูกใช้งานแล้ว",
      ];
    },
    custom_emailRules() {
      return [
        ...emailRules,
        (v) => this.emailExistResult === false || "อีเมลนี้ถูกใช้งานแล้ว",
      ];
    },
  },

  async created() {
    await this.initilize();
  },
};
</script>

<style></style>
