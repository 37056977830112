<template>
  <div class="pa-4">
    <v-row class="pa-3">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-briefcase</v-icon> ประเภทงาน
      </h3>
    </v-row>
    <v-row class="pa-3">
      <v-btn @click="newJobSegment" color="primary"
        ><v-icon>mdi-plus</v-icon>เพิ่มประเภทงาน</v-btn
      >
    </v-row>
    <SearchableDataTable
      v-if="jobsegments != undefined"
      table_caption="ประเภทงาน"
      :datatable_headers="jobsegment_data_table_headers"
      :data_items="jobsegments"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="info"
              @click="goToDetailView(item)"
              ><v-icon>mdi-file-edit</v-icon>
            </v-btn>
          </template>
          <span>แก้ไขประเภทงาน</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="red"
              @click="DeleteJobSegments(item)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <span>ลบ</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { JobSegmentDataProvider } from "@/services/JobSegmentDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var jobSegmentDataProvider = new JobSegmentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "JobSegmentListView",
  data() {
    return {
      jobsegments: [],
      jobsegment_data_table_headers: [
        { text: "รหัส", value: "code" },
        { text: "ชื่อประเภทงาน", value: "name" },
        { text: "ตัวเลือก", value: "action", align: "center" , sortable: false},
      ],
    };
  },
  methods: {
    newJobSegment() {
      this.$router
        .push({ name: "CreateJobSegmentDetailView" })
        .catch((err) => {});
    },
    async loadJobSegments() {
      await jobSegmentDataProvider
        .getJobSegmentList()
        .then((result) => {
          this.jobsegments = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToDetailView: function (item) {
      var oid = item.oid;
      this.$router
        .push({ name: "EditJobSegmentDetailView", params: { oid: oid } })
        .catch((err) => {});
    },
    async DeleteJobSegments(item) {
      var oid = item.oid;
      if (confirm("ต้องการลบข้อมูลหรือไม่ ?")) {
        await jobSegmentDataProvider
          .deleteJobSegment(oid)
          .then((result) => {
            this.$toast.success("ลบข้อมูลเรียบร้อบ !");
            this.loadJobSegments();
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
  },
  async created() {
    this.loadJobSegments();
  },
};
</script>

<style></style>
