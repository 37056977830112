<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2">mdi-file-document-edit-outline</v-icon
            >สาขาวิชา</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="major.majorid"
                label="รหัส PK สาขาวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="major.majorcode"
                label="รหัสสาขาวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="major.majormoecode"
                label="รหัสสาขาวิชา (MOE)"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="major.majornameth"
                label="ชื่อสาขาวิชา (ไทย)"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="major.majornameen"
                label="ชื่อสาขาวิชา (อังกฤษ)"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MajorDataProvider } from "@/services/MajorDataProvider";
var majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "MajorDetailView",
  data() {
    return {
      majorID: "",
      major: [],
    };
  },

  methods: {
    async loadMajor(majorID = "") {
      await majorDataProvider
        .loadMajor(majorID)
        .then((response) => {
          this.major = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },

  async created() {
    this.majorID = this.$route.params.majorID;
    await this.loadMajor(this.majorID);
  },
};
</script>
<style></style>
