<template>
  <div>
    <v-row class="mt-3">
      <v-col v-if="reward" v-for="(item, index) in reward" :key="index" cols="12" md="3">
        <v-card class="pa-3 mx-1" width="auto" height="150px">
          <v-img
            :lazy-src="'data:image/jpg;base64,' + base64Str"
            :src="'data:image/jpg;base64,' + base64Str"
            alt="Image"
            max-height="150"
            max-width="auto"
          ></v-img>
          <div class="mt-3">
            <h4>{{ item.topic ? item.topic : "ไม่มีชื่อ" }}</h4>
            วันที่ได้รับ<FormatDate :date_value="item.rewarddate"></FormatDate>
            <div class="mt-2 d-flex justify-end">
              <v-menu offset-overflow>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="onclickEditRewardStudent(item)">
                    <v-icon color="info">mdi-file-edit</v-icon>
                  </v-list-item>

                  <v-list-item @click="deleteRewardStudent(item)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card
          rounded="8px"
          color="#D9D9D9"
          width="auto"
          height="150px"
          outlined
          class="d-flex align-center justify-center black-border"
          @click="RewardStudent_Dialog = true"
        >
          <v-btn icon class="mr-2">
            <v-icon size="80px" color="white">mdi-plus</v-icon>
          </v-btn>
        </v-card>
        <div class="mt-3 primary--text text-center" style="text-decoration: underline">
          เพิ่มผลงาน / รางวัล
        </div>
      </v-col>
    </v-row>

    <RewardStudentDialog
      :RewardStudent_Dialog="RewardStudent_Dialog"
      :oid="Rewardoid"
      @closeDialog="onRewardProcessIsCompleted"
    ></RewardStudentDialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);
import RewardStudentDialog from "@/components/Student/Reward/RewardStudentDialog.vue";
import { RewardRecord } from "@/models/Student/RewardRecord";
import FormatDate from "@/components/common/FormatDate.vue";

export default {
  props: ["student_oid", "isInputDisabled"],
  components: {
    FormatDate,
    RewardStudentDialog,
  },
  data() {
    return {
      RewardStudent_Dialog: false,
      reward_headers: [
        { text: "รายการรางวัล", value: "topic" },
        { text: "วันที่ได้รับ", value: "rewarddate" },
        { text: "", value: "action" },
      ],
      reward: [],

      vm: new RewardRecord(),
      base64Str: "",
      Rewardoid: "-1",
    };
  },
  methods: {
    async LoadRewardStudent(student_oid) {
      studentDataProvider
        .getStudentRewardRecords(student_oid)
        .then((res) => {
          this.reward = res.data;
        })
        .catch((res) => {});
    },

    async saveRewardStudent(value) {
      value.studentoid = this.student_oid;
      studentDataProvider
        .saveStudentRewardRecord(value)
        .then((res) => {
          this.$toast.success("สร้าง ประวัติ เรียบร้อย!");
          const student_oid = this.student_oid;
          this.LoadRewardStudent(student_oid);
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว!");
        });
    },

    async onRewardProcessIsCompleted(value, save) {
      if (save) {
        this.RewardStudent_Dialog = false;
        if (value.oid != "-1") {
          await this.UpdateRewardStudent(value);
        } else {
          await this.saveRewardStudent(value);
        }
      } else {
        this.RewardStudent_Dialog = false;
      }
      this.Rewardoid = "-1";
    },

    onclickEditRewardStudent(item) {
      this.Rewardoid = item.oid;
      this.RewardStudent_Dialog = true;
    },
    async UpdateRewardStudent(value) {
      studentDataProvider
        .editStudentRewardRecord(value)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("บันทึกข้อมูลสำเร็จ");
            const student_oid = this.student_oid;
            this.LoadRewardStudent(student_oid);
          }
        })
        .catch((res) => {
          this.$toast.error("แก้ไขข้อมูลล้มเหลว !!!" + res);
        });
    },

    async deleteRewardStudent(item) {
      if (confirm("ต้องการประวัติการทำงานหรือไม่ ?")) {
        await studentDataProvider
          .deleteStudentRewardRecord(item.oid)
          .then((res) => {
            this.reward = [];
            this.$toast.success("ลบประวัติการทำงาน เรียบร้อย!");
            const student_oid = this.student_oid;
            this.LoadRewardStudent(student_oid);
          })
          .catch((res) => {
            this.$toast.error("ลบประวัติการทำงานล้มเหลว!" + res);
          });
      }
    },
  },
  async mounted() {
    const student_oid = this.student_oid;
    await this.LoadRewardStudent(student_oid);
  },
};
</script>

<style scoped>
.custom-width {
  width: 100px;
  gap: 10px;
}
</style>
