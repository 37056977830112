import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { CreateSchoolGraduatedStudentRequest } from "@/models/GraduatedStudent/CreateSchoolGraduatedStudentRequest";
import {
  ImportGraduatedStudent,
  ImportGraduatedStudentRequest,
} from "@/models/GraduatedStudent/ImportGraduatedStudentsRequest";
export class GraduatedStudentDataProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }

  loadSchoolGraduatedStudents() {
    const headers = create_default_request_header();
    var endpointPath = "/api/GraduatedStudent/GetSchoolGraduatedStudents";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, headers);
  }

  loadSchoolGraduatedStudentsFromSchoolID(schoolID = "") {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudent/GetSchoolGraduatedStudentsFromSchoolID/" +
      schoolID;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, headers);
  }

  loadSchoolGraduatedStudent(oid = "") {
    const headers = create_default_request_header();
    var endpointPath = "/api/GraduatedStudent/GetSchoolGraduatedStudent/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, headers);
  }

  GetSchoolGraduatedStudentFromSchoolID(schoolID = "") {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudent/GetSchoolGraduatedStudentFromSchoolID/" + schoolID;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, "", headers);
  }

  createSchoolGraduatedStudent(
    createSchoolGraduatedStudentRequest = new CreateSchoolGraduatedStudentRequest()
  ) {
    const headers = create_default_request_header();
    var endpointPath = "/api/GraduatedStudent/CreateSchoolGraduatedStudent";
    var data = JSON.stringify(createSchoolGraduatedStudentRequest);
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, data, headers);
  }

  loadGraduatedStudents(graduatedStudentRequest) {
    const headers = create_default_request_header();
    var endpointPath = "/api/GraduatedStudent/GetGraduatedStudents";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify(graduatedStudentRequest);
    // return axios.post(combinedURL,{
    //     "school_oid": graduatedStudentRequest.school_oid,
    //     "entranceyear": graduatedStudentRequest.entranceyear,
    //     "gradautedyearth": graduatedStudentRequest.gradautedyearth,
    //     "isprofileupdated": graduatedStudentRequest.isprofileupdated,
    //     "gradenameth": graduatedStudentRequest.gradenameth,
    //     "majorcode": graduatedStudentRequest.majorcode,
    //     "minorcode": graduatedStudentRequest.minorcode,
    //     "subjecttypecode": graduatedStudentRequest.subjecttypecode,
    //     "gender": graduatedStudentRequest.gender,
    //     "itemPerPage": graduatedStudentRequest.itemPerPage,
    //     "page": graduatedStudentRequest.page,
    //     "search": graduatedStudentRequest.search,
    //     "sortBy": graduatedStudentRequest.sortBy,
    //     "sortDesc": graduatedStudentRequest.sortDesc,
    // }, headers);
    return axios.post(combinedURL, data, headers);
  }

  loadGetGraduatedStudentsFromSchoolId(schoolid = "") {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudent/GetGraduatedStudentsFromSchoolId/" + schoolid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, headers);
  }

  importGraduatedStudents(request = ImportGraduatedStudentRequest) {
    const headers = create_default_request_header();
    const endpointPath = "/api/GraduatedStudent/ImportGraduatedStudents";
    const data = JSON.stringify(request);
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, data, headers);
  }

  filterGraduatedStudents(selectedItem) {
    const headers = create_default_request_header();
    var endpointPath = "/api/GraduatedStudent/FilterGraduatedStudents";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(
      combinedURL,
      {
        EntranceYear: selectedItem.EntranceYears,
        GradeName: selectedItem.GradeNames,
        GraduatedYear: selectedItem.GraduatedYears,
        employmentstatusname: selectedItem.employmentstatusnames,
        majorName: selectedItem.majorNames,
        subjectType: selectedItem.subjectTypes,
      },
      headers
    );
  }

  loadFilterSchoolGraduatedStudents(schoolGraduatedStudentsFilter) {
    const headers = create_default_request_header();
    var endpointPath = "/api/GraduatedStudent/GetSchoolGraduatedStudentsFilter";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(
      combinedURL,
      {
        schooltype: schoolGraduatedStudentsFilter.schooltype,
        graduatedyearth: schoolGraduatedStudentsFilter.yearth,
        regionasvec: schoolGraduatedStudentsFilter.RegionAsVEC,
        provinceid: schoolGraduatedStudentsFilter.province,
        page: schoolGraduatedStudentsFilter.page,
        itemPerPage: schoolGraduatedStudentsFilter.itemPerPage,
        schoolid: schoolGraduatedStudentsFilter.schoolid,
        search: schoolGraduatedStudentsFilter.search,
        sortBy: schoolGraduatedStudentsFilter.sortBy,
        sortDesc: schoolGraduatedStudentsFilter.sortDesc,
      },
      headers
    );
  }

  GetGradeNameThFromGraduatedStudents() {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudent/GetGradeNameThFromGraduatedStudents";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, headers);
  }

  GetEntranceYearFromGraduatedStudents() {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudent/GetEntranceYearFromGraduatedStudents";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, headers);
  }

  GetGraduatedYearTHFromGraduatedStudents() {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudent/GetGraduatedYearTHFromGraduatedStudents";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, headers);
  }

  GetmajorNameThFromGraduatedStudents() {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudent/GetmajorNameThFromGraduatedStudents";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, headers);
  }

  GetemploymentstatusnameFromGraduatedStudents() {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudent/GetemploymentstatusnameFromGraduatedStudents";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, headers);
  }
  GetFilterItemGraduatedStudent() {
    const headers = create_default_request_header();
    var endpointPath = "/api/GraduatedStudent/GetFilterItemGraduatedStudent";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, headers);
  }
}
