<template>
  <div class="pa-5">
    <!-- <v-app-bar>
            <h3 class="primary--text">รายงานการติดตามภาวะการมีงานทำ</h3>
            <v-spacer></v-spacer>

        </v-app-bar> -->
    <v-card rounded elevation="1" class="px-5 py-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xs="12">
          <h4 class="primary--text mb-2">ปีที่ติดตาม</h4>
          <v-autocomplete
            background-color="input"
            filled
            clearable
            :items="trackingYearList"
            v-model="request.GraduatedYearTH"
            @change="handleYearChange()"
            append-icon="mdi-chevron-down"
            :label="request.GraduatedYearTH ? '' : 'ปีที่ติดตาม'"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xs="12">
          <h4 class="primary--text mb-2">รอบที่ติดตาม</h4>
          <v-autocomplete
            background-color="input"
            filled
            clearable
            :items="roundTrackingList"
            v-model="request.RoundNumber"
            @change="loadGraduatedStudentJobTrackingReportMains()"
            append-icon="mdi-chevron-down"
            :disabled="request.GraduatedYearTH === ''"
            :label="request.RoundNumber ? '' : 'รอบที่ติดตาม'"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-1">
      <v-col cols="12" class="d-flex justify-start">
        <v-btn
          @click="createGraduatedStudentJobTrackingReportMain_Dialog = true"
          dark
          color="primary"
          class="my-2"
        >
          <v-icon class="mr-2">mdi-folder-plus</v-icon> สร้างรายงานการติดตาม
        </v-btn>
      </v-col>
    </v-row>

    <!-- :datatable_groupby="
        GraduatedStudentJobTrackingReportMains_DataTable_GroupBy
      " -->
    <SearchableDataTable
      table_caption="รายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา"
      :data_items="GraduatedStudentJobTrackingReportMains"
      :datatable_headers="
        GraduatedStudentJobTrackingReportMains_DataTable_Header
      "
    >
      <!-- <template v-slot:group.header="{items, group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>
          </v-btn>
          {{ group }} ({{ items.length }})
        </td>
      </template> -->
      <!-- 
      <template v-slot:item.roundnumber="{ item }">
        <span>{{ item.targetgraduatedyearth }}</span> รอบที่
        {{ item.roundnumber }}
      </template> -->
      <template v-slot:item.trackingstartdate="{ item }">
        <FormatDate :date_value="item.trackingstartdate"></FormatDate> -
        <FormatDate :date_value="item.trackingenddate"></FormatDate>
      </template>

      <template v-slot:item.importstudentdata_status_statusname="{ item }">
        <GraduatedStudentJobTrackingReportMain_ImportStudentData_Status_Label
          :jobtrackingreport="item"
        >
        </GraduatedStudentJobTrackingReportMain_ImportStudentData_Status_Label>
      </template>
      <template v-slot:item.jobtracking_status_statusname="{ item }">
        <GraduatedStudentJobTrackingReportMain_JobTracking_Status_Label
          :jobtrackingreport="item"
        >
        </GraduatedStudentJobTrackingReportMain_JobTracking_Status_Label>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openGraduatedStudentJobTrackingReportMainDetailView(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-folder</v-icon>
            </v-btn>
          </template>
          <span>แสดงรายละเอียดรายงาน</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deleteGraduatedStudentJobTrackingReportMain(item)"
              color="error"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบรายงานการติดตาม</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
    <v-dialog
      v-model="createGraduatedStudentJobTrackingReportMain_Dialog"
      width="850"
    >
      <template>
        <div>
          <CreateGraduatedStudentJobTrackingReportMain
            @onEmitCreatingProcessCompleted="
              this.onReportMainCreatingProcessIsCompleted
            "
          >
          </CreateGraduatedStudentJobTrackingReportMain>
        </div>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import CreateGraduatedStudentJobTrackingReportMain from "@/components/GraduatedStudentJobTrackingReport/CreateGraduatedStudentJobTrackingReportMain.vue";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import GraduatedStudentJobTrackingReportMain_JobTracking_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_JobTracking_Status_Label.vue";
import GraduatedStudentJobTrackingReportMain_ImportStudentData_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMain_ImportStudent_Status_Label.vue";
import FormatDate from "@/components/common/FormatDate.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { GraduatedStudentJobTrackingReportsMain_Request } from "@/models/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportsMain_Request";
import { MasterDataProvider } from "@/services/MasterDataProvider";
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "GraduatedStudentJobTrackingReportMainListView",
  components: {
    CreateGraduatedStudentJobTrackingReportMain,
    SearchableDataTable,
    GraduatedStudentJobTrackingReportMain_JobTracking_Status_Label,
    GraduatedStudentJobTrackingReportMain_ImportStudentData_Status_Label,
    FormatDate,
  },
  data() {
    return {
      createGraduatedStudentJobTrackingReportMain_Dialog: false,

      GraduatedStudentJobTrackingReportMains: undefined,
      GraduatedStudentJobTrackingReportMains_DataTable_Header: [
        {
          value: "targetgraduatedyearth",
          text: "ปีที่ติดตาม",
          align: "center",
        },
        { value: "roundnumber", text: "รอบที่ติดตาม", align: "center" },

        { value: "trackingstartdate", text: "วันที่เริ่มติดตาม" },
        {
          value: "importstudentdata_status_statusname",
          text: "สถานะการนำเข้าข้อมูล",
        },
        { value: "jobtracking_status_statusname", text: "สถานะการติดตาม" },
        { value: "action", text: "ตัวเลือก", width: "130px", align: "center", sortable: false },
      ],
      moment: moment,
      trackingYearList: undefined,
      roundTrackingList: undefined,
      request: new GraduatedStudentJobTrackingReportsMain_Request(),
    };
  },
  methods: {
    async loadGraduatedStudentJobTrackingReportMains() {
      await graduatedStudentJobTrackingReportReportDataProvider
        .LoadGraduatedStudentJobTrackingReportMains(this.request)
        .then((result) => {
          this.GraduatedStudentJobTrackingReportMains = result.data;
        });
    },

    openGraduatedStudentJobTrackingReportMainDetailView(report) {
      var oid = report.oid;
      this.$router.push({
        name: "GraduatedStudentJobTrackingReportMainDetailView",
        params: { oid },
      });
    },
    async initialize() {
      await masterDataProvider
        .getAllGraduatedStudentJobTrackingReportMainTHYears()
        .then((result) => {
          this.trackingYearList = result.data;
          //เอาปีสุดท้ายที่ทำการออกรายการ

          if (
            this.trackingYearList.length > 0 &&
            !this.request.GraduatedYearTH
          ) {
            this.request.GraduatedYearTH = this.trackingYearList[0];
            // this.request.GraduatedYearTH =
            //   this.trackingYearList[this.trackingYearList.length - 1];
          }
        });
      if (this.request.GraduatedYearTH) {
        await masterDataProvider
          .getAllGraduatedStudentJobTrackingReportMainRoundNumbers(
            this.request.GraduatedYearTH
          )
          .then((result) => {
            this.roundTrackingList = result.data;
            this.loadGraduatedStudentJobTrackingReportMains();
          });
      }
    },
    handleYearChange() {
      if (!this.request.GraduatedYearTH) {
        this.request.GraduatedYearTH = "";
        this.loadGraduatedStudentJobTrackingReportMains();
      } else {
        this.initialize();
      }
    },
    // async loadRoundTracking() {
    //   await masterDataProvider
    //     .getAllGraduatedStudentJobTrackingReportMainRoundNumbers(
    //       this.request.GraduatedYearTH
    //     )
    //     .then((result) => {
    //       this.roundTrackingList = result.data;
    //     });
    // },

    deleteGraduatedStudentJobTrackingReportMain(report) {
      if (confirm("ยืนยัน?")) {
        this.$store.dispatch("showLoading");
        var oid = report.oid;
        var graduatedStudentJobTrackingReportReportDataProvider =
          new GraduatedStudentJobTrackingReportReportDataProvider(
            process.env.VUE_APP_BACKEND_API_URL
          );
        graduatedStudentJobTrackingReportReportDataProvider
          .deleteGraduatedStudentJobTrackingReportMain(oid)
          .then((result) => {
            this.$toast.success("ลบข้อมูลเรียบร้อย");
            this.loadGraduatedStudentJobTrackingReportMains();
          })
          .finally(() => {
            this.$store.dispatch("hideLoading");
          });
      }
    },

    onReportMainCreatingProcessIsCompleted() {
      this.createGraduatedStudentJobTrackingReportMain_Dialog = false;
      this.loadGraduatedStudentJobTrackingReportMains();
    },
  },
  mounted() {
    this.loadGraduatedStudentJobTrackingReportMains();
    this.initialize();
  },
};
</script>
