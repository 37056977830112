<template>
  <v-card outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          {{ overline }}
        </div>
        <v-list-item-title class="text-h5 mb-1">
          {header}
        </v-list-item-title>
        <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar tile size="80" :color="backgroundColor">
        <v-icon color="white" size="50">{{ icon }}</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "GraduateTracingCard",
  props: [
    "overline",
    "header",
    "description",
    "backgroundColor",
    "icon",
  ],
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
