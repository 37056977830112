import NationalitiesDetailView from "@/views/Nationalities/NationalitiesDetailView.vue";
import NationalitiesListView from "@/views/Nationalities/NationalitiesListView.vue";

export default [
  {
    path: "/Nationalities",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Nationalities/NationalitiesListView",
        name: "NationalitiesListView",
        component: NationalitiesListView,
        meta: {
          requiresAuth: true,
          nameTh: "สัญชาติ",
        },
      },
      {
        path: "/Nationalities/NationalitiesDetailView/:oid",
        name: "NationalitiesDetailView",
        component: NationalitiesDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดสัญชาติ",
        },
      },
    ],
  },
];
