<template>
  <div class="d-flex justify-center">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-container class="ma-4">
      <v-row class="d-flex justify-center mt-2 mb-2">
        <h3 class="primary--text">แก้ไขข้อมูลสถานประกอบการ (ทวิภาคี)</h3>
      </v-row>
      <div class="pb-4">
        <v-card rounded elevation="1" class="pa-2 my-0">
          <v-row class="d-flex" style="justify-content: space-between">
            <v-col cols="12" sm="12" md="8" lg="6" xl="6" class="pt-5">
              <div class="d-sm-none">
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-if="
                    dbdStatus === false &&
                    dbdLastverifydate !== '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) :
                  {{
                    moment(dbdLastverifydate)
                      .add(543, "year")
                      .locale("TH")
                      .format("lll")
                  }}
                  น.
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="
                    dbdStatus === false &&
                    dbdLastverifydate === '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) : -
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="
                    dbdStatus === true &&
                    dbdLastverifydate !== '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) :
                  {{
                    moment(dbdLastverifydate)
                      .add(543, "year")
                      .locale("TH")
                      .format("lll")
                  }}
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="dbdStatus === true"
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) :
                  {{ savedDate }}
                </p>
              </div>
              <!-- larger screens -->
              <div class="d-none d-sm-flex align-center">
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-if="
                    dbdStatus === false &&
                    dbdLastverifydate !== '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) :
                  {{
                    moment(dbdLastverifydate)
                      .add(543, "year")
                      .locale("TH")
                      .format("lll")
                  }}
                  น.
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="
                    dbdStatus === false &&
                    dbdLastverifydate === '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) : -
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="
                    dbdStatus === true &&
                    dbdLastverifydate !== '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) :
                  {{
                    moment(dbdLastverifydate)
                      .add(543, "year")
                      .locale("TH")
                      .format("lll")
                  }}
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="dbdStatus === true"
                >
                  อัพเดตวันที่ตรวจสอบ(DBD) :
                  {{ savedDate }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              lg="6"
              xl="6"
              class="d-flex justify-end pt-5"
            >
              <v-btn @click="checkEmployerProfile" color="secondary" style=""
                >ตรวจสอบ(DBD)</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-alert v-if="loading" type="info">กำลังค้นหาข้อมูล...</v-alert>
              <v-alert v-else-if="employerStatus" type="success"
                >พบข้อมูลนิติบุคคล</v-alert
              >
              <v-alert v-else-if="error" type="error"
                >ไม่พบข้อมูลนิติบุคคล</v-alert
              >
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar flat color="primary" dark>
              <v-toolbar-title>บัญชีผู้ใช้งาน</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ชื่อบัญชีผู้ใช้ (username)"
                    readonly
                    v-model="vm.username"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-row justify="center" class="d-flex align-center">
            <v-col>
              <v-card
                style="border-radius: 15px"
                class="mx-auto d-flex flex-column align-center pb-2"
                variant="outlined"
              >
                <label class="mt-3 mb-2">ความสมบูรณ์ของประวัติ</label>
                <CompletionPercentage
                  v-if="this.completionPercentage"
                  :percen="this.completionPercentage"
                >
                </CompletionPercentage>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar flat color="primary" dark>
              <v-row class="d-flex" style="justify-content: space-between">
                <v-col>
                  <v-toolbar-title>ข้อมูลทั่วไป</v-toolbar-title>
                </v-col>
                <v-col class="d-flex justify-end"
                  ><v-chip
                    v-if="employerStatus"
                    :color="chipcard_status.color"
                    :text-color="chipcard_status.textcolor"
                    >{{ employerStatus }}</v-chip
                  ></v-col
                >
              </v-row>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col sm="12" md="6">
                  <v-select
                    label="ประเภทสถานประกอบการ"
                    :items="employerprofiletypes"
                    item-value="code"
                    item-text="nameth"
                    clearable
                    v-model="vm.selected_employerprofiletypecode"
                  ></v-select>
                </v-col>
                <v-col sm="11" xs="11" md="5">
                  <v-text-field
                    label="เลขประจำตัวประชาชน/เลขนิติบุคคล"
                    v-model="vm.identityid"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center justify-center" sm="1" md="1">
                  <v-tooltip top color="success" v-if="employerStatus">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon style="font-size: 30px"
                          >mdi-information-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>ได้รับการตรวจสอบจากกรมพัฒนาธุรกิจการค้า</span>
                  </v-tooltip>
                  <v-tooltip top color="error" v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon style="font-size: 30px"
                          >mdi-information-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>ยังไม่ได้รับการตรวจสอบจากกรมพัฒนาธุรกิจการค้า</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ชื่อสถานประกอบการ"
                    :rules="[(v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ']"
                    v-model="vm.nameth"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="อีเมล"
                    v-model="vm.email"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="12">
                  <v-select
                    label="ประเภทวิสาหกิจ"
                    :items="employerMainBusinessTypes"
                    :rules="[(v) => !!v || 'กรุณาระบุประเภทวิสาหกิจ']"
                    item-value="code"
                    item-text="nameth"
                    v-model="vm.selected_employermainbusinesstypecode"
                    @change="loadEmployerBusinessTypes"
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="12">
                  <v-select
                    label="ประเภทธุรกิจ"
                    :items="employerBusinessTypes"
                    :rules="[(v) => !!v || 'กรุณาระบุประเภทประเภทธุรกิจ']"
                    item-value="code"
                    item-text="nameth"
                    v-model="vm.selected_employerbussinesstypecode"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    :rules="[(v) => !!v || 'กรุณาระบุรายละเอียดสถานประกอบการ']"
                    label="รายละเอียดสถานประกอบการ (ลักษณะงาน)"
                    v-model="vm.description"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="สวัสดิการ"
                    :rules="[(v) => !!v || 'กรุณาระบุสวัสดิการ']"
                    v-model="vm.benefit_description"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="6" lg="6">
                  <v-text-field
                    label="หมายเลขประกันสังคม"
                    v-model="vm.social_security_number"
                    :rules="security_numberRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <v-text-field label="เว็บไซต์" v-model="vm.websiteurl">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="12" md="6" lg="6">
                  <v-checkbox
                    label="ให้ผู้สมัครงานไปสมัครด้วยตนเอง หรือ ส่งใบสมัครทางไปรษณีย์"
                    v-model="
                      vm.isrequiredjobapplicanttoregisterthemselforviapost
                    "
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="12" md="6" lg="6">
                  <v-checkbox
                    label="เปิดรับสมัคร online"
                    v-model="vm.isopenforonlineapplicant"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ที่อยู่"
                    :rules="[(v) => !!v || 'กรุณาระบุที่อยู่']"
                    v-model="vm.address_no"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                    label="จังหวัด"
                    :items="provinces"
                    item-text="provincethai"
                    item-value="provinceid"
                    @change="loadDistricts"
                    v-model="vm.selected_province_id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                    label="อำเภอ"
                    :items="districts"
                    item-text="districtthai"
                    item-value="districtid"
                    @change="loadTambons"
                    v-model="vm.selected_district_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    clearable
                    label="ตำบล"
                    :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                    :items="tambons"
                    item-text="tambonthai"
                    item-value="tambonid"
                    v-model="vm.selected_tambon_id"
                    @change="onTambonSelected"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="รหัสไปรษณีย์"
                    readonly
                    v-model="postcode"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-6">
            <v-toolbar flat color="primary" dark>
              <v-toolbar-title>ผู้ติดต่อ/ผู้ประสานงาน</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    label="คำนำหน้าชื่อ"
                    :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                    :items="titlenames"
                    item-text="titlenameth"
                    item-value="titlenameid"
                    v-model="vm.coordinator_titlename_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="ชื่อ"
                    :rules="nameRules"
                    v-model="vm.coordinator_firstname"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="นามสกุล"
                    :rules="nameRules"
                    v-model="vm.coordinator_lastname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="อีเมล"
                    :rules="emailRules"
                    v-model="vm.coordinator_email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="เบอร์โทร"
                    v-model="vm.coordinator_phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Fax"
                    v-model="vm.coordinator_fax"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row class="mt-2">
            <v-col cols="12">
              <div class="d-flex justify-start">
                <v-btn color="primary" @click="openPopupCreateContact"
                  ><v-icon> mdi-plus </v-icon>เพิ่มผู้ประสานงาน</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <SearchableDataTable
            class="mt-2"
            table_caption="ผู้ประสานงานของสถานศึกษา"
            :data_items="vm.employerschoolcontactrequests"
            :datatable_headers="
              vm_employerschoolcontactrequests_data_table_headers
            "
          >
            <template v-slot:item.action="{ item }">
              <v-tooltip top color="error">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="deleteContact(item)"
                    color="error"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>ลบ</span>
              </v-tooltip>
            </template>
          </SearchableDataTable>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>โลโก้</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        prepend-icon="mdi-flag"
                        accept="image/png, image/jpeg"
                        label="รูปภาพโลโก้"
                        @change="onlogoPhotoChange"
                        v-model="logoFiles"
                      ></v-file-input>
                    </v-col>
                    <v-col v-if="logo_photobase64" cols="12" sm="12">
                      <div class="d-flex justify-center">
                        <v-img
                          height="180"
                          contain
                          v-bind:lazy-src="
                            'data:image/jpg;base64,' + logo_photobase64
                          "
                          v-bind:src="
                            'data:image/jpg;base64,' + logo_photobase64
                          "
                        >
                        </v-img>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>ข้อมูลการจดทะเบียน</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <EmployerFileAttachments
                    v-if="employerProfile"
                    :EmployerProfile="employerProfile"
                  ></EmployerFileAttachments>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>เกี่ยวกับสถานที่ตั้ง</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        prepend-icon="mdi-map"
                        accept="image/png, image/jpeg"
                        label="รูปภาพแผนที่"
                        @change="onPhotoChange"
                        v-model="photoFiles"
                      ></v-file-input>
                    </v-col>
                    <v-col v-if="location_photobase64" cols="12" sm="12">
                      <div class="d-flex justify-center">
                        <v-img
                          height="180"
                          contain
                          v-bind:lazy-src="
                            'data:image/jpg;base64,' + location_photobase64
                          "
                          v-bind:src="
                            'data:image/jpg;base64,' + location_photobase64
                          "
                        >
                        </v-img>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        label="ละติจูด"
                        v-model="vm.location_latitude"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="ลองติจูด"
                        v-model="vm.location_longtitude"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <MarkerGmap @OnMarkedMap="OnMarkMapComplete"></MarkerGmap>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          :cols="
            $vuetify.breakpoint.xs ||
            $vuetify.breakpoint.sm ||
            $vuetify.breakpoint.md
              ? 12
              : 8
          "
          class="justify-center text-center mt-4 mb-4"
        >
          <v-btn color="secondary" @click="onSave"
            ><v-icon> mdi-content-save </v-icon>บันทึก</v-btn
          >
          <v-btn color="" @click="$router.go(-1)" class="ma-2 justify-center">
            ย้อนกลับ</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="isOpenCreateContactDialog" width="700">
      <template>
        <v-card class="pa-6">
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="สถานศึกษา"
                  :items="schools"
                  item-text="schoolname"
                  item-value="schoolid"
                  v-model="newContact.selected_schoolid"
                  @change="onSchoolSelected"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="ชื่อ-นามสกุล"
                  v-model="newContact.fullname"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="เบอร์ติดต่อ"
                  v-model="newContact.phone"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="justify-center text-center">
                <v-btn @click="createContact" class="ma-2" color="success">
                  <v-icon class="mr-2">mdi-check-circle-outline</v-icon>ยืนยัน
                </v-btn>
                <v-btn
                  outline
                  @click="isOpenCreateContactDialog = false"
                  class="ma-2"
                  color=""
                  elevation="2"
                  >ยกเลิก</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import {
  nameRules,
  emailRules,
  security_numberRules,
} from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import MarkerGmap from "@/components/GoogleMap/MarkerGmap.vue";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { UpdateEmployerProfileRequest } from "@/models/Employer/UpdateEmployerProfileRequest";
import { EmployerSchoolContactRequest } from "@/models/Employer/EmployerSchoolContactRequest";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import CompletionPercentage from "@/components/Profile/CompletionPercentage.vue";
import EmployerFileAttachments from "@/components/Employer/EmployerFileAttachments.vue";
import { EmployerExternalApiDataProvider } from "@/services/EmployerExternalApiDataProvider";
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

import moment from "moment";
import { formatDateThai } from "@/DateTimeHelper";

export default {
  name: "SchoolPartnerOfficer_UpdateEmployerProfileView",
  components: {
    SearchableDataTable,
    MarkerGmap,
    CompletionPercentage,
    EmployerFileAttachments,
  },
  data() {
    return {
      moment: moment,
      oid: undefined,
      employerProfile: undefined,
      vm: new UpdateEmployerProfileRequest(),

      vm_employerschoolcontactrequests_data_table_headers: [
        { text: "สถานศึกษา", value: "selected_schoolname" },
        { text: "ชื่อ-นามสกุล", value: "fullname" },
        { text: "เบอร์ติดต่อ", value: "phone" },
        { text: "", value: "action" , sortable: false},
      ],

      employerProfileStatuses: [],

      employerprofiletypes: [],

      employerMainBusinessTypes: [],

      employerBusinessTypes: [],

      titlenames: [],

      provinces: [],

      districts: [],

      tambons: [],

      postcode: undefined,

      isOpenCreateContactDialog: false,

      schools: [],

      newContact: new EmployerSchoolContactRequest(),

      documentFiles: [],
      photoFiles: [],
      UpdateLocationData: new FormData(),
      UpdateDocumentData: new FormData(),
      logo_photobase64: "",
      logoFiles: [],
      UpdateLogoData: new FormData(),
      location_photobase64: "",
      listFileDocument: [],
      host: "",
      completionPercentage: "",
      security_numberRules: security_numberRules,
      nameRules: nameRules,
      emailRules: emailRules,
      employerData: null,
      loading: false,
      error: undefined,
      employerStatus: undefined,
      employerExternalApiDataProvider: new EmployerExternalApiDataProvider(),
      savedDate: null,
      thaiMonths: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      dbdLastverifydate: undefined,
      dbdStatus: true,
    };
  },
  methods: {
    async OnMarkMapComplete(position) {
      if (position) {
        this.vm.location_latitude = position.lat;
        this.vm.location_longtitude = position.lng;
      }
    },
    async initialize() {
      await masterDataProvider.getActiveTitleNames().then((result) => {
        this.titlenames = result.data;
      });

      await masterDataProvider.loadEmployerProfileStatuses().then((result) => {
        this.employerProfileStatuses = result.data;
      });

      await masterDataProvider.loadEmployerProfileTypes().then((result) => {
        this.employerprofiletypes = result.data;
      });

      await masterDataProvider
        .loadEmployerMainBusinessTypes()
        .then((result) => {
          this.employerMainBusinessTypes = result.data;
        });

      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });

      const getSchoolRequest = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(getSchoolRequest).then((result) => {
        this.schools = result.data;
      });
    },

    async loadEmployerProfile() {
      await employerProfileDataProvider
        .loadEmployerProfile(this.oid)
        .then((result) => {
          this.employerProfile = result.data;
          const employerProfileJson = result.data;
          this.location_photobase64 = employerProfileJson.location_photobase64;
          this.logo_photobase64 = employerProfileJson.logo_photobase64;
          this.listFileDocument = employerProfileJson.employerfileattachments;
          this.vm = UpdateEmployerProfileRequest.fromJson(employerProfileJson);
          this.vm.username = this.employerProfile.username;
          console.log(this.vm);
          this.dbdLastverifydate = this.employerProfile.dbd_lastverifydate;
          this.employerStatus =
            this.employerProfile.dbd_organizationjuristicstatus;
          this.dbdStatus = this.employerProfile.dbd_status;
        });
      await employerProfileDataProvider
        .getCompletionPercentage(this.oid)
        .then((result) => {
          this.completionPercentage = result.data;
        });
    },

    async loadEmployerBusinessTypes() {
      if (this.vm.selected_employermainbusinesstypecode != "") {
        await masterDataProvider
          .loadEmployerBusinessTypes(
            this.vm.selected_employermainbusinesstypecode
          )
          .then((result) => {
            this.employerBusinessTypes = result.data;
          });
      }
    },

    async loadDistricts() {
      if (this.vm.selected_province_id != undefined) {
        await masterDataProvider
          .loadGetDistricts(this.vm.selected_province_id)
          .then((result) => {
            this.districts = result.data;
          });
      }
    },

    async loadTambons() {
      if (this.vm.selected_district_id != undefined) {
        await masterDataProvider
          .loadGetTambons(this.vm.selected_district_id)
          .then((result) => {
            this.tambons = result.data;
          });
      }
    },

    async onTambonSelected() {
      if (this.vm.selected_tambon_id != "") {
        const vm_selected_tambon_id = this.vm.selected_tambon_id;
        const selected_tambon = this.tambons.find(
          (x) => x.tambonid == vm_selected_tambon_id
        );
        this.postcode = selected_tambon.postcodemain;
      }
    },

    async openPopupCreateContact() {
      this.newContact = new EmployerSchoolContactRequest();
      this.isOpenCreateContactDialog = true;
    },

    async onSchoolSelected() {
      if (this.newContact.selected_schoolid != "") {
        const selectedID = this.newContact.selected_schoolid;
        const school = this.schools.find((x) => x.schoolid == selectedID);
        this.newContact.selected_schoolname = school.schoolname;
      }
    },

    async createContact() {
      this.vm.employerschoolcontactrequests.push(this.newContact);
      this.isOpenCreateContactDialog = false;
    },

    async deleteContact(item) {
      if (confirm("ยืนยัน")) {
        await employerProfileDataProvider
          .deleteEmployerSchoolContact(item.oid)
          .then((result) => {
            const index = this.vm.employerschoolcontactrequests.indexOf(item);
            this.vm.employerschoolcontactrequests.splice(index, 1);
          });
      }
    },

    async onFileChange() {
      this.UpdateDocumentData.append("oid", this.oid);
      this.UpdateDocumentData.append("filedoc", this.documentFiles);

      await employerProfileDataProvider
        .uploadDocumentFile(this.UpdateDocumentData)
        .then((result) => {
          this.$toast.success("อัปโหลด ข้อมูลการจดทะเบียน เรียบร้อย!");
          this.loadEmpDocumentFile();
        })
        .catch((result) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    async onlogoPhotoChange() {
      this.UpdateLogoData.append("oid", this.oid);
      this.UpdateLogoData.append("filelogo", this.logoFiles);

      await employerProfileDataProvider
        .uploadLogoPhoto(this.UpdateLogoData)
        .then((result) => {
          var fileToLoad = this.logoFiles;
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(fileToLoad);
          }).then((data) => {
            const base64 = data.split(",")[1];
            this.logo_photobase64 = base64;
          });

          this.$toast.success("อัปโหลด โลโก้ เรียบร้อย!");
        })
        .catch((result) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    async onPhotoChange() {
      this.UpdateLocationData.append("oid", this.oid);
      this.UpdateLocationData.append("filephoto", this.photoFiles);

      await employerProfileDataProvider
        .uploadLocationPhoto(this.UpdateLocationData)
        .then((result) => {
          var fileToLoad = this.photoFiles;
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(fileToLoad);
          }).then((data) => {
            const base64 = data.split(",")[1];
            this.location_photobase64 = base64;
          });

          this.$toast.success("อัปโหลด สถานที่ตั้ง เรียบร้อย!");
        })
        .catch((result) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    async DeleteDocumentFile(oid) {
      if (confirm("ต้องการลบ ข้อมูลการจดทะเบียน หรือไม่ ?")) {
        await employerProfileDataProvider
          .DeleteDocumentFile(oid)
          .then((result) => {
            this.$toast.success("ลบ ข้อมูลการจดทะเบียน เรียบร้อย!");
            this.loadEmpDocumentFile();
          })
          .catch((result) => {
            this.$toast.error("ล้มเหลว!");
          });
      }
    },
    async loadEmpDocumentFile() {
      await employerProfileDataProvider
        .LoadDocumentFile(this.oid)
        .then((result) => {
          this.listFileDocument = result.data;
        });
    },
    async onSave() {
      if (confirm("ยืนยัน")) {
        await employerProfileDataProvider
          .updateEmployerProfile(this.vm)
          .then((result) => {
            this.$toast.success("บันทึกข้อมูลสถานประกอบการเรียบร้อย");
            this.$router.push(
              "/Employer/SchoolPartnerOfficer_EmployerProfileListView"
            );
          });
      }
    },
    async checkEmployerProfile() {
      if (this.vm.identityid != "") {
        this.loading = true;
        this.error = false;
        this.employerData = null;

        try {
          const delay = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms));

          const fetchDataPromise = this.employerExternalApiDataProvider
            .loadEmployerData(this.vm.identityid)
            .then((response) => {
              this.savedDate = this.currentDate();
              this.dbdLastverifydate = this.dbd_lastverifydate;

              const result =
                this.employerExternalApiDataProvider.getTestEmployerData();
              const organizationData =
                result.data[0]["cd:OrganizationJuristicPerson"];

              if (
                organizationData["cd:OrganizationJuristicID"] ===
                this.vm.identityid
              ) {
                console.log("พบข้อมูลนิติบุคคล:", organizationData);
                this.employerData =
                  organizationData["cd:OrganizationJuristicID"];
                this.employerStatus =
                  organizationData["cd:OrganizationJuristicStatus"];
                this.dbdStatus = true;
              } else {
                console.log("ไม่พบข้อมูลนิติบุคคล");
                this.error = true;
                this.employerStatus = null;
                this.dbdStatus = false;
              }
            })
            .catch((err) => {
              console.error("Error fetching data:", err);
              this.savedDate = this.currentDate();
              this.dbdLastverifydate = this.dbd_lastverifydate;

              const result =
                this.employerExternalApiDataProvider.getTestEmployerData();
              const organizationData =
                result.data[0]["cd:OrganizationJuristicPerson"];

              if (
                organizationData["cd:OrganizationJuristicID"] ===
                this.vm.identityid
              ) {
                console.log(
                  "พบข้อมูลนิติบุคคล (ข้อมูลจำลอง):",
                  organizationData
                );
                this.employerData =
                  organizationData["cd:OrganizationJuristicID"];
                this.employerStatus =
                  organizationData["cd:OrganizationJuristicStatus"];
                this.dbdStatus = true;
              } else {
                console.log("ไม่พบข้อมูลนิติบุคคล (ข้อมูลจำลอง)");
                this.error = true;
                this.employerStatus = null;
                this.dbdStatus = false;
              }
            });

          await Promise.all([fetchDataPromise, delay(1000)]);
        } finally {
          this.loading = false;
        }
      }
      this.vm.dbd_lastverifydate = this.savedDate;
      this.vm.dbd_organizationjuristicstatus = this.employerStatus;
      this.vm.dbd_status = this.dbdStatus;

      await employerProfileDataProvider
        .updateEmployerProfile(this.vm)
        .then((result) => {
          console.log(this.vm);
        });
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()} ${
        this.thaiMonths[current.getMonth()]
      } ${current.getFullYear() + 543}`;
      const time = `${current.getHours()}:${current
        .getMinutes()
        .toString()
        .padStart(2, "0")} น.`;
      return `${date} ${time}`;
    },

    convertDateFormat(dbdLastverifydate) {
      return formatDateThai(dbdLastverifydate);
    },
  },
  computed: {
    chipcard_status() {
      let color = "";
      let textcolor = "white";

      if (this.employerStatus === "ร้าง") {
        color = "";
        textcolor = "white";
      } else if (this.employerStatus === "เสร็จการชำระบัญชี") {
        color = "error";
        textcolor = "white";
      } else if (this.employerStatus === "ยังดำเนินกิจการอยู่") {
        color = "success";
        textcolor = "white";
      }
      return { color, textcolor };
    },
  },
  watch: {
    "employerProfile.dbd_status": function (newVal) {
      if (newVal === true) {
        if (this.employerProfile.dbd_organizationjuristicstatus !== null) {
          this.employerStatus =
            this.employerProfile.dbd_organizationjuristicstatus;
        }
      } else if (
        this.employerProfile.dbd_lastverifydate === "0001-01-01T00:00:00"
      ) {
        this.error = false;
      } else {
        this.error = true;
      }
    },
  },
  async created() {
    this.oid = this.$route.params.oid;
    this.host = backendapiUrl;
    await this.initialize();
    await this.loadEmployerProfile();
    await this.loadEmployerBusinessTypes();
    await this.loadDistricts();
    await this.loadTambons();
    await this.onTambonSelected();
  },
};
</script>

<style>
.v-toolbar__title {
  font-size: 16px !important;
}
</style>
