<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="justify-center">
        <v-col lg="10" md="12" sm="12" xs="12">
          <v-card class="elevation-1 pa-3 mt-5">
            <v-card-text class="d-flex justify-center">
              <v-col cols="10" class="mt-0">
                <v-row>
                  <v-col cols="12" class="d-flex justify-start">
                    <h3 class="primary--text">
                      <v-icon class="primary--text">mdi-account-box</v-icon>
                      ข้อมูลบุคลากร
                    </h3>
                  </v-col>
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row class="mt-3">
                  <v-col cols="12" class="custom-col twocolinrow">
                    <!-- <v-text-field label="คำนำหน้าชื่อ" v-model="vm.titlename"></v-text-field> -->
                    <h4 class="primary--text">เพศ</h4>
                    <v-radio-group
                      :rules="[(v) => !!v || 'กรุณาระบุเพศ']"
                      v-model="vm.gender"
                      row
                    >
                      <v-radio label="ชาย" color="blue" value="male"></v-radio>
                      <v-radio
                        label="หญิง"
                        color="pink"
                        value="female"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <!-- <v-col cols="6">
              <v-text-field label="เพศ" v-model="vm.gender"></v-text-field>
              <v-select
                v-model="vm.gender"
                label="เพศ"
                :items="['ชาย', 'หญิง']"
                item-text="gender"
                item-value="gender"
              ></v-select>
            </v-col> -->
                </v-row>
                <v-row>
                  <v-col cols="3" class="custom-col twocolinrow">
                    <h4 class="primary--text mb-2">คำนำหน้าชื่อ</h4>
                    <v-autocomplete
                      filled
                      background-color="input"
                      label="คำนำหน้าชื่อ"
                      :items="titlenamelist"
                      v-model="vm.titlenameid"
                      item-text="titlenameth"
                      item-value="titlenameid"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="custom-col twocolinrow">
                    <h4 class="primary--text mb-2">ชื่อจริง</h4>

                    <v-text-field
                      background-color="input"
                      filled
                      label="ชื่อ"
                      v-model="vm.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" class="custom-col twocolinrow">
                    <h4 class="primary--text mb-2">นามสกุล</h4>

                    <v-text-field
                      background-color="input"
                      filled
                      label="นามสกุล"
                      v-model="vm.lastname"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="6">
              <v-text-field label="เพศ" v-model="vm.gender"></v-text-field>
              <v-select
                v-model="vm.gender"
                label="เพศ"
                :items="['ชาย', 'หญิง']"
                item-text="gender"
                item-value="gender"
              ></v-select>
            </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="12" class="custom-col twocolinrow">
                    <h4 class="primary--text mb-2">ตำแหน่ง</h4>

                    <v-text-field
                      background-color="input"
                      filled
                      label="ตำแหน่ง"
                      v-model="vm.position"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="custom-col twocolinrow">
                    <h4 class="primary--text mb-2">อีเมล</h4>

                    <v-text-field
                      background-color="input"
                      filled
                      label="อีเมล"
                      ref="txtEmail"
                      required
                      :rules="custom_emailRules"
                      v-model="vm.email"
                      @input="checkEmailEdited"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="custom-col twocolinrow">
                    <h4 class="primary--text mb-2">หมายเลขโทรศัพท์</h4>

                    <v-text-field
                      background-color="input"
                      filled
                      label="หมายเลขโทรศัพท์"
                      v-model="vm.phonenumber"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-start">
                    <h3 class="primary--text">
                      <v-icon class="primary--text">mdi-account-box</v-icon>
                      เกี่ยวกับการใช้งาน
                    </h3>
                  </v-col>
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row class="mt-3">
                  <v-col cols="12" class="d-flex justify-start">
                  <CurrentApplicationUserInfo> </CurrentApplicationUserInfo></v-col>
                </v-row>
                <!-- <v-row>
            <v-col col="12">
              <v-text-field label="สถานะ" v-model="vm.isactive"></v-text-field>
            </v-col>
          </v-row> -->
              </v-col>
            </v-card-text>
          </v-card>

          <v-row class="mt-2">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn class="color-buton" @click="save">
            บันทึก <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
        </v-col>
      </v-row>
      
    </v-container>
  </div>
</template>

<script>
import {
  phoneRules,
  emailRules,
  idcardRules,
  nameRules,
} from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import { CreateUpdateSchoolEmployeeProfile_Request } from "@/models/Profile/SchoolEmployeeProfile/CreateUpdateSchoolEmployeeProfile_Request";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import CurrentApplicationUserInfo from "@/components/ApplicationUser/CurrentApplicationUserInfo.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable, CurrentApplicationUserInfo },
  name: "Current_SchoolEmployeeProfileDetailView",
  data() {
    return {
      vm: new CreateUpdateSchoolEmployeeProfile_Request(),
      schoolEmployeeProfileGroupTypes: undefined,
      titlenamelist: [],
      phoneRules: phoneRules,
      emailRules: emailRules,
      idcardRules: idcardRules,
      nameRules: nameRules,
      emailEdited: false, // เพิ่มตัวแปรสำหรับเก็บสถานะการแก้ไขอีเมล
      originalEmail: "", // เพิ่มตัวแปรสำหรับเก็บค่าเดิมของอีเมล
    };
  },
  methods: {
    async initilize() {},

    async loadCurrentSchoolEmployeeProfile() {
      await schoolEmployeeProfileDataProvider
        .getCurrentSchoolEmployeeProfile()
        .then((result) => {
          this.vm = CreateUpdateSchoolEmployeeProfile_Request.fromJson(
            result.data
          );
          // กำหนดค่าเดิมของอีเมล
          this.originalEmail = this.vm.email;
        });
    },

    async save() {
      await schoolEmployeeProfileDataProvider
        .updateSchoolEmployeeProfile(this.vm)
        .then((result) => {
          this.$store.dispatch({ type: "doSetIsLoadingProfile" });
          this.$toast.success("บันทึกข้อมูลเรียบร้อย");
        });
    },

    async loadTitleName() {
      await masterDataProvider
        .getActiveTitleNames()
        .then((response) => {
          this.titlenamelist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    checkEmailEdited() {
      this.emailEdited = this.vm.email !== this.originalEmail;
    },
  },
  computed: {
    custom_emailRules() {
      return [
        ...emailRules,
        (v) =>
          !this.emailEdited ||
          (!this.emailExistResult ? true : "อีเมลนี้ถูกใช้งานแล้ว"), // กฎเพิ่มเติมสำหรับการตรวจสอบความมีอยู่ของอีเมล ตรวจสอบเมื่อมีการแก้ไขอีเมลเท่านั้น
      ];
    },
  },
  watch: {
    "vm.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isEmailUsed(newVal)
            .then((result) => {
              this.emailExistResult = result.data;
              this.$refs.txtEmail.validate();
            });
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.initilize();
    await this.loadCurrentSchoolEmployeeProfile();
    await this.loadTitleName();
    console.log(this.$store.getters.rolecodes)

  },
  
};
</script>

<style scoped>
@media (max-width: 745px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 746px) and (max-width: 964px) {
  .twocolinrow {
    flex-basis: 100%;
    max-width: 50%;
  }
}

.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  color: white !important;
}
</style>
