<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-card>
        <v-card-title>
          <h4><v-icon>mdi-bell</v-icon> ตั้งค่าการแจ้งเตือน</h4>
        </v-card-title>
        <v-card-text v-if="notificationSetups">
          <v-row class="my-3">
            <v-col cols="7" class="custom-col"></v-col>
            <v-col>
              <v-row>
                <v-col class="d-flex justify-center">
                  <h3 style="font-weight: bold">อีเมล</h3>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="d-flex justify-center">
                  <h3 style="font-weight: bold">ผ่านแอปพลิเคชัน</h3>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h3 style="font-weight: bold">
                การแจ้งเตือนเกี่ยวกับการติดตามภาวะการมีงานทำ
              </h3>
            </v-col>
          </v-row>

          <v-row
            v-for="item in notificationSetups.filter(
              (x) =>
                x.notificationeventkey ==
                'GraduatedStudentJobTrackingReportInComming_Notify'
            )"
            :key="item.notificationeventkey"
            class="my-3"
          >
            <v-col cols="7" class="custom-col"
              ><v-row>
                <v-col>
                  <p style="font-weight: bold">
                    {{ item.notificationeventname }}
                  </p>
                  <span style="">{{ item.description }}</span>
                </v-col>
              </v-row></v-col
            >
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useemailnotification"
                @change="updateUseEmailNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useinappnotification"
                @change="updateUseInApplicationNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
          </v-row>

          <v-row
            v-for="item in notificationSetups.filter(
              (x) =>
                x.notificationeventkey ==
                'GraduatedStudentJobTrackingReportStudentImported'
            )"
            :key="item.notificationeventkey"
            class="my-3"
          >
            <v-col cols="7" class="custom-col"
              ><v-row>
                <v-col>
                  <p style="font-weight: bold">
                    {{ item.notificationeventname }}
                  </p>
                  <span style="">{{ item.description }}</span>
                </v-col>
              </v-row></v-col
            >
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useemailnotification"
                @change="updateUseEmailNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useinappnotification"
                @change="updateUseInApplicationNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
          </v-row>

          <v-row
            v-for="item in notificationSetups.filter(
              (x) =>
                x.notificationeventkey ==
                'GraduatedStudentJobTrackingReport15DaysLeft_Notify'
            )"
            :key="item.notificationeventkey"
            class="my-3"
          >
            <v-col cols="7" class="custom-col"
              ><v-row>
                <v-col>
                  <p style="font-weight: bold">
                    {{ item.notificationeventname }}
                  </p>
                  <span style="">{{ item.description }}</span>
                </v-col>
              </v-row></v-col
            >
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useemailnotification"
                @change="updateUseEmailNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useinappnotification"
                @change="updateUseInApplicationNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
          </v-row>

          <v-row
            v-for="item in notificationSetups.filter(
              (x) =>
                x.notificationeventkey ==
                'GraduatedStudentJobTrackingReport7DaysLeft_Notify'
            )"
            :key="item.notificationeventkey"
            class="my-3"
          >
            <v-col cols="7" class="custom-col"
              ><v-row>
                <v-col>
                  <p style="font-weight: bold">
                    {{ item.notificationeventname }}
                  </p>
                  <span style="">{{ item.description }}</span>
                </v-col>
              </v-row></v-col
            >
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useemailnotification"
                @change="updateUseEmailNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useinappnotification"
                @change="updateUseInApplicationNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h3 style="font-weight: bold">
                การแจ้งเตือนเกี่ยวกับระบบศูนย์เครือข่ายกำลังคน
              </h3>
            </v-col>
          </v-row>

          <v-row
            v-for="item in notificationSetups.filter(
              (x) => x.notificationeventkey == 'JobPostingHasNewStudentRegistration'
            )"
            :key="item.notificationeventkey"
            class="my-3"
          >
            <v-col cols="7" class="custom-col"
              ><v-row>
                <v-col>
                  <p style="font-weight: bold">
                    {{ item.notificationeventname }}
                  </p>
                  <span style="">{{ item.description }}</span>
                </v-col>
              </v-row></v-col
            >
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useemailnotification"
                @change="updateUseEmailNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useinappnotification"
                @change="updateUseInApplicationNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
          </v-row>

          <v-row
            v-for="item in notificationSetups.filter(
              (x) => x.notificationeventkey == 'JobPostingNotCompletedStatusNotification'
            )"
            :key="item.notificationeventkey"
            class="my-3"
          >
            <v-col cols="7" class="custom-col"
              ><v-row>
                <v-col>
                  <p style="font-weight: bold">
                    {{ item.notificationeventname }}
                  </p>
                  <span style="">{{ item.description }}</span>
                </v-col>
              </v-row></v-col
            >
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useemailnotification"
                @change="updateUseEmailNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useinappnotification"
                @change="updateUseInApplicationNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
          </v-row>

          <v-row
            v-for="item in notificationSetups.filter(
              (x) => x.notificationeventkey == 'JobApplicationHasChangeStatus'
            )"
            :key="item.notificationeventkey"
            class="my-3"
          >
            <v-col cols="7" class="custom-col"
              ><v-row>
                <v-col>
                  <p style="font-weight: bold">
                    {{ item.notificationeventname }}
                  </p>
                  <span style="">{{ item.description }}</span>
                </v-col>
              </v-row></v-col
            >
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useemailnotification"
                @change="updateUseEmailNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
            <v-col class="d-flex justify-center ml-2">
              <v-switch
                color="success"
                v-model="item.useinappnotification"
                @change="updateUseInApplicationNotifyStatus(item)"
                inset
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { NotificationDataProvider } from "@/services/SignalR/NotificationDataProvider";
const notificationDataProvider = new NotificationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

import { NotificationSetupDataProvider } from "@/services/NotificationSetupDataProvider";
const notificationSetupDataProvider = new NotificationSetupDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

import SearchableDataTable from "../common/SearchableDataTable.vue";
import { UpdateUseInApplicationNotifyStatusRequest } from "@/models/NotificationSetups/UpdateUseInApplicationNotifyStatusRequest";
import { UpdateUseEmailNotifyStatusRequest } from "@/models/NotificationSetups/UpdateUseEmailNotifyStatusRequest";
export default {
  components: { SearchableDataTable },
  name: "NotifySetup",
  data() {
    return {
      notificationSetups: undefined,
      GraduatedStudentJobTrackingReportInComming_Notify_UseInAppNotifyStatus: false,
      GraduatedStudentJobTrackingReportInComming_Notify_UseEmailNotifyStatus: false,
      GraduatedStudentJobTrackingReportStudentImported_UseInAppNotifyStatus: false,
      GraduatedStudentJobTrackingReportStudentImported_UseEmailNotifyStatus: false,
      GraduatedStudentJobTrackingReportDueDate_Notify_UseInAppNotifyStatus: false,
      GraduatedStudentJobTrackingReportDueDate_Notify_UseEmailNotifyStatus: false,
      JobPostingHasNewStudentRegistration_UseEmailNotifyStatus: false,
      JobPostingHasNewStudentRegistration_UseInAppNotifyStatus: false,
      JobApplicationHasChangeStatus_UseEmailNotifyStatus: false,
      JobApplicationHasChangeStatus_UseInAppNotifyStatus: false,
    };
  },
  methods: {
    async initialize() {
      await notificationSetupDataProvider.getNotificationSetups().then((result) => {
        this.notificationSetups = result.data;
      });
    },

    async updateUseInApplicationNotifyStatus(item) {
      const request = new UpdateUseInApplicationNotifyStatusRequest();
      request.NotificationEventKey = item.notificationeventkey;
      request.Status = item.useinappnotification;
      await notificationSetupDataProvider
        .updateUseInApplicationNotifyStatus(request)
        .then((result) => {
          this.$toast.success("บันทึกการเปลี่ยนแปลงเรียบร้อย");
        });
    },
    async updateUseEmailNotifyStatus(item) {
      const request = new UpdateUseEmailNotifyStatusRequest();
      request.NotificationEventKey = item.notificationeventkey;
      request.Status = item.useemailnotification;
      await notificationSetupDataProvider
        .updateUseEmailNotifyStatus(request)
        .then((result) => {
          this.$toast.success("บันทึกการเปลี่ยนแปลงเรียบร้อย");
        });
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style scoped>
@media (max-width: 790px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
