<template>
  <v-container>
    <v-row
      v-if="$vuetify.breakpoint.lgAndUp && $vuetify.breakpoint.mdAndUp"
      outlined
      class="pa-2 mb-3 d-flex align-end"
    >
      <h4 class="primary--text ml-2">หลักฐานการจดทะเบียน</h4>
      <v-spacer></v-spacer>

      <v-btn @click="dialog = true" outlined class="mr-2">
        เพิ่มหลักฐานการจดทะเบียน<v-icon>mdi-plus</v-icon></v-btn
      >
    </v-row>
    <div v-else-if="!$vuetify.breakpoint.lgAndUp && !$vuetify.breakpoint.mdAndUp">
      <v-row outlined class="pa-2 mb-3 d-flex justify-end">
        <v-btn @click="dialog = true" outlined class="mr-2">
          เพิ่มหลักฐานการจดทะเบียน<v-icon>mdi-plus</v-icon></v-btn
        >
      </v-row>
      <v-row outlined class="pa-2 mb-3 d-flex justify-start">
        <h4 class="primary--text ml-2">หลักฐานการจดทะเบียน</h4>
      </v-row>
    </div>
    <SearchableDataTable
      :data_items="fileattachments"
      :datatable_headers="data_table_headers"
    >
      <template v-slot:item.thumbnail="{ item }">
        <div class="ma-1">
          <FileAttachmentBase64Img
            :Oid="item.fileattachment.oid"
          ></FileAttachmentBase64Img>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deleteFileAttachment(item)"
              color="error"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบไฟล์</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
    <v-dialog v-model="dialog" max-width="450">
      <template>
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-col cols="4" class="d-flex justify-center">
                <v-img
                  :src="imageUrl"
                  width="50"
                  v-if="imageUrl"
                  :alt="imageName"
                ></v-img>
              </v-col>
            </v-row>
            <v-file-input
              accept="image/*"
              v-model="file"
              label="Select an image"
              @change="onFileChange"
            ></v-file-input>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn color="success" @click="onBtnUploadClicked()">ยืนยัน</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { FileAttachmentDataProvider } from "@/services/FileAttachmentDataProvider";
import SearchableDataTable from "../common/SearchableDataTable.vue";
import FileAttachmentBase64Img from "../common/FileAttachmentBase64Img.vue";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
const fileAttachmentDataProvider = new FileAttachmentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: { SearchableDataTable, FileAttachmentBase64Img },
  name: "EmployerFileAttachments",
  props: ["EmployerProfile"],
  data() {
    return {
      dialog: false,
      file: undefined,
      imageUrl: "",
      imageName: "",

      employer_oid: "",
      employerProfile: undefined,
      fileattachments: [],
      data_table_headers: [
        { text: "", value: "thumbnail" },
        { text: "ชื่อไฟล์", value: "fileattachment.filename" },
        { text: "", value: "actions" },
      ],
    };
  },
  methods: {
    async initialize() {
      this.file = null;
      this.imageUrl = "";
      await employerProfileDataProvider
        .loadEmployerProfile(this.employer_oid)
        .then((result) => {
          this.employerProfile = result.data;
          this.fileattachments = this.employerProfile.employerfileattachments;
        });
    },

    onFileChange(file) {
      this.file = file;
      this.imageUrl = URL.createObjectURL(this.file);
      this.imageName = this.file.name;
    },

    async onBtnUploadClicked() {
      let uploadFileRequest = new FormData();
      uploadFileRequest.append("oid", this.employerProfile.oid);
      uploadFileRequest.append("filedoc", this.file);

      await employerProfileDataProvider
        .uploadDocumentFile(uploadFileRequest)
        .then((result) => {
          var fileToLoad = this.file;
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(fileToLoad);
          }).then((data) => {
            this.dialog = false;
            this.initialize();
            this.$toast.success("อัปโหลด ข้อมูลการจดทะเบียน เรียบร้อย!");
          });
        })
        .catch((result) => {
          this.dialog = false;
          this.$toast.error("ล้มเหลว!");
        });
    },

    async deleteFileAttachment(item) {
      if (confirm("ยืนยัน?")) {
        const oid = item.fileattachment.oid;
        await employerProfileDataProvider.DeleteDocumentFile(oid).then((result) => {
          this.$toast.warning("ลบไฟล์เรียบร้อย");
          this.initialize();
        });
      }
    },
  },
  async created() {
    this.employer_oid = this.EmployerProfile.oid;
    await this.initialize();
  },
};
</script>

<style></style>
