export class GraduatedStudents_Request {
  school_oid = "";
  entranceyear = "";
  gradautedyearth = "";
  isprofileupdated = false;
  gradenameth = "";
  majorcode = "";
  minorcode = "";
  subjecttypecode = "";
  gender = "";
  page = 1;
  itemPerPage = 10;
  search = "";
  subjecttypenameth = "";
  minornameth = "";
  majornameth = "";
  certificatetype_code = "";
}
