<template>
  <div class="pa-4">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-book</v-icon> สาขาวิชา
      </h3>
    </v-row>
    <SearchableDataTable
      v-if="majors != undefined"
      table_caption="สาขาวิชา"
      :datatable_headers="majors_data_table_headers"
      :data_items="majors"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToDetailView(item)" icon color="info">
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var majorDataProvider = new MajorDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: { SearchableDataTable },
  name: "MajorListView",
  data() {
    return {
      isImporting: false,
      majorsoid: "",
      majors: undefined,
      majors_data_table_headers: [
        //{ text: "รหัส PK สาขาวิชา", value: "majorid" },
        { text: "รหัสสาขาวิชา", value: "majorcode" },
        { text: "รหัสสาขาวิชา (MOE)", value: "majormoecode" },
        { text: "ชื่อสาขาวิชา (ไทย)", value: "majornameth" },
        { text: "ชื่อสาขาวิชา (อังกฤษ)", value: "majornameen" },
        { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    async importMajors() {
      this.isImporting = true;
      await majorDataProvider
        .importMajors()
        .then((result) => {
          this.onImportMajorsCompleted();
        })
        .catch((err) => {
          this.isImporting = false;
          alert(err);
        });
    },
    async loadMajors() {
      await majorDataProvider
        .loadMajors()
        .then((result) => {
          this.majors = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToDetailView: function (item) {
      var majorID = item.majorid;
      this.$router
        .push({ name: "MajorDetailView", params: { majorID: majorID } })
        .catch((err) => {});
    },
    onImportMajorsCompleted() {
      this.isImporting = false;
      this.loadMajors();
    },
  },
  async created() {
    await this.loadMajors();
  },
};
</script>

<style></style>
