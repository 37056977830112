export class CreateUpdateSchoolEmployeeProfile_Request {
  school = undefined
  selected_schoolcode = ""
  oid = ""
  applicationuser_oid = ""
  titlenameid = ""
  name = ""
  lastname = ""
  dateofbirth = ""
  position = ""
  email = ""
  phonenumber = ""
  idcard = ""
  isactive = undefined
  userphoto_base64string = ""
  schoolemployeegrouptypes = []

  username = ""
  password = ""
  confirm_password = ""

  is_center_admin = false
  is_center_manager = false
  is_center_officer = false
  is_insitution_officer = false
  is_provinceschool_officer = false
  is_school_admin = false
  is_school_guidance_teacher = false
  is_school_manager = false
  is_school_partner_officer = false

  insitution_officer_permissions = []
  school_partner_officer_permissions = []
  school_manager_permissions = []
  school_guidance_teacher_permissions = []
  school_admin_permissions = []
  provinceschool_officer_permissions = []

  static fromJson(jsonObject = "") {
    const vm = new CreateUpdateSchoolEmployeeProfile_Request()
    if (jsonObject.school != undefined) {
      vm.school = jsonObject.school
      vm.selected_schoolcode = jsonObject.school.schoolid
    }
    vm.oid = jsonObject.oid
    vm.applicationuser_oid = jsonObject.applicationuser.oid
    vm.username = jsonObject.username
    vm.titlenameid = jsonObject.titlenameid
    vm.name = jsonObject.name
    vm.lastname = jsonObject.lastname
    vm.dateofbirth = jsonObject.dateofbirth
    vm.position = jsonObject.position
    vm.email = jsonObject.email
    vm.phonenumber = jsonObject.phonenumber
    vm.idcard = jsonObject.idcard
    vm.isactive = jsonObject.isactive
    vm.userphoto_base64string = jsonObject.userphoto_base64string

    vm.is_center_admin = jsonObject.is_center_admin
    vm.is_center_manager = jsonObject.is_center_manager
    vm.is_center_officer = jsonObject.is_center_officer
    vm.is_insitution_officer = jsonObject.is_insitution_officer
    vm.is_provinceschool_officer = jsonObject.is_provinceschool_officer
    vm.is_school_admin = jsonObject.is_school_admin
    vm.is_school_guidance_teacher = jsonObject.is_school_guidance_teacher
    vm.is_school_manager = jsonObject.is_school_manager
    vm.is_school_partner_officer = jsonObject.is_school_partner_officer

    vm.insitution_officer_permissions = jsonObject.insitution_officer_permissions
    vm.school_partner_officer_permissions = jsonObject.school_partner_officer_permissions
    vm.school_manager_permissions = jsonObject.school_manager_permissions
    vm.school_guidance_teacher_permissions = jsonObject.school_guidance_teacher_permissions
    vm.school_admin_permissions = jsonObject.school_admin_permissions
    vm.provinceschool_officer_permissions = jsonObject.provinceschool_officer_permissions
    return vm
  }
}
