export class AdminSearchStudentRequest {
  PageNumber = 1;
  ItemsPerPage = 10;
  SubjectTypeCode = "";
  MajorCode = "";
  CertificateTypeCode = "";
  StudentStatusCode = "";
  GraduatedYearTH = "";
  Gender = "";
  keyword = "";
  SchoolType = 0;
  SchoolID = "";
  RegionAsVEC = "";
  ProvinceID = "";
  SortBy = [];
  SortDesc = [];
}
