<template>
  <div>
    <v-container>
      <v-row class="pt-6 px-3">
        <v-icon class="pr-2" color="primary">mdi-trophy</v-icon>
        <h3 class="primary--text">รางวัล</h3>
      </v-row>
      <v-row class="px-3" style="margin-top: 1%; margin-bottom: 2%">
        <v-divider></v-divider>
      </v-row>
      <v-row v-if="reward.length > 0 && $vuetify.breakpoint.lgAndUp && $vuetify.breakpoint.mdAndUp">
        <v-col v-for="(item, index) in reward" :key="index" cols="6">
          <v-card class="py-3">
            <v-row class="px-9 py-5">
              <v-col cols="12" xl="12" lg="6" md="6" sm="12" xs="12">
                <v-row class="pb-3">
                  <h3>{{ item.topic ? item.topic : "ไม่มีชื่อ" }}</h3>
                </v-row>
                <v-row>
                  <span class="pr-2">วันที่ได้รับ</span> <FormatDate v-if="item.rewarddate" :date_value="item.rewarddate"></FormatDate>
                  <span v-else>-</span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="reward.length > 0 && !$vuetify.breakpoint.lgAndUp && !$vuetify.breakpoint.mdAndUp">
        <v-col v-for="(item, index) in reward" :key="index" cols="12">
          <v-card class="py-3">
            <v-row class="px-9 py-5">
              <v-col cols="12" xl="12" lg="6" md="6" sm="12" xs="12">
                <v-row class="pb-3">
                  <h3>{{ item.topic }}</h3>
                </v-row>
                <v-row>
                  <FormatDate :date_value="item.rewarddate"></FormatDate>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="">
        <v-col cols="12">
          <v-card class="py-16 px-3 d-flex justify-center" max-width="100%"
            ><span>ไม่มีข้อมูล</span></v-card
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { StudentDataProvider } from "@/services/StudentDataProvider";
import RewardStudentDialog from "@/components/Student/Reward/RewardStudentDialog.vue";
import FormatDate from "@/components/common/FormatDate.vue";
import { RewardRecord } from "@/models/Student/RewardRecord";

var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  props: ["student_oid", "isInputDisabled"],
  components: {
    FormatDate,
    RewardStudentDialog,
  },
  data() {
    return {
      RewardStudent_Dialog: false,
      reward: [],
      vm: new RewardRecord(),
      Rewardoid: "-1",
    };
  },
  methods: {
    async LoadRewardStudent(student_oid) {
      studentDataProvider
        .getStudentRewardRecords(student_oid)
        .then((res) => {
          this.reward = res.data;
        })
        .catch((res) => {
          console.error("Failed to load rewards", res);
        });
    },

    async saveRewardStudent(value) {
      value.studentoid = this.student_oid;
      studentDataProvider
        .saveStudentRewardRecord(value)
        .then((res) => {
          this.$toast.success("สร้าง ประวัติ เรียบร้อย!");
          this.LoadRewardStudent(this.student_oid);
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว!");
        });
    },

    async onRewardProcessIsCompleted(value, save) {
      if (save) {
        this.RewardStudent_Dialog = false;
        if (value.oid != "-1") {
          await this.UpdateRewardStudent(value);
        } else {
          await this.saveRewardStudent(value);
        }
      } else {
        this.RewardStudent_Dialog = false;
      }
      this.Rewardoid = "-1";
    },

    onclickEditRewardStudent(item) {
      this.Rewardoid = item.oid;
      this.RewardStudent_Dialog = true;
    },

    async UpdateRewardStudent(value) {
      studentDataProvider
        .editStudentRewardRecord(value)
        .then((res) => {
          if (res?.status === 200) {
            this.$toast.success("บันทึกข้อมูลสำเร็จ");
            this.LoadRewardStudent(this.student_oid);
          }
        })
        .catch((res) => {
          this.$toast.error("แก้ไขข้อมูลล้มเหลว! " + res);
        });
    },

    async deleteRewardStudent(item) {
      if (confirm("ต้องการลบรางวัลนี้หรือไม่ ?")) {
        studentDataProvider
          .deleteStudentRewardRecord(item.oid)
          .then(() => {
            this.reward = [];
            this.$toast.success("ลบประวัติรางวัลเรียบร้อย!");
            this.LoadRewardStudent(this.student_oid);
          })
          .catch((res) => {
            this.$toast.error("ลบประวัติรางวัลล้มเหลว! " + res);
          });
      }
    },
  },

  async mounted() {
    await this.LoadRewardStudent(this.student_oid);
  },
};
</script>

<style scoped>
.custom-width {
  gap: 10px;
}
</style>
