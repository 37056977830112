import CreateJobSegmentDetailView from "@/views/JobSegment/CreateJobSegmentDetailView.vue";
import EditJobSegmentDetailView from "@/views/JobSegment/EditJobSegmentDetailView.vue";
import JobSegmentListView from "@/views/JobSegment/JobSegmentListView.vue";
export default [
  {
    path: "/JobSegment",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/JobSegment/CreateJobSegmentDetailView",
        name: "CreateJobSegmentDetailView",
        component: CreateJobSegmentDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "เพิ่มประเภทงาน",
        },
      },
      {
        path: "/JobSegment/EditJobSegmentDetailView",
        name: "EditJobSegmentDetailView",
        component: EditJobSegmentDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "แก้ไขประเภทงาน",
        },
      },
      {
        path: "/JobSegment/JobSegmentListView",
        name: "JobSegmentListView",
        component: JobSegmentListView,
        meta: {
          requiresAuth: true,
          nameTh: "ประเภทงาน",
        },
      },
    ],
  },
];
