<template>
  <div>
    <v-container>
      <v-row class="pt-3 px-2">
        <v-icon class="pr-2" color="primary">mdi-book-education</v-icon>
        <h3 class="primary--text">ข้อมูลการศึกษา</h3>
      </v-row>
      <v-row class="px-3 py-1" style="margin-top: 1%; margin-bottom: 2%">
        <v-divider></v-divider>
      </v-row>
      <v-row v-if="studentEducations.length > 0">
        <v-col v-for="(education, index) in studentEducations" :key="index" cols="12">
          <v-card class="py-3">
            <v-row class="px-9 py-5">
              <v-col cols="12" xl="12" lg="6" md="6" sm="12" xs="12">
                <v-row class="pb-3">
                  <h3>{{ education.schoolname }}</h3>
                </v-row>
                <v-row>{{ education.gradenameth }}</v-row>
                <v-row>
                  <span class="pr-2">คณะ {{ education.majornameth }}</span>
                  <span>สาขา {{ education.minornameth }}</span>
                </v-row>
                <v-row class="pt-3">
                  <v-btn
                    text
                    color="primary"
                    class="pa-0"
                    @click="open_StudyStudentDetailView_Dialog(education)"
                  >
                    รายละเอียดเพิ่มเติม <v-icon>mdi-arrow-top-right</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                xl="12"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="d-flex flex-row-reverse align-center"
              >
                <v-chip :color="getStatusColor(education.studentstatusname)" dark>
                  {{ education.studentstatusname }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="">
        <v-col cols="12">
          <v-card class="py-16 px-3 d-flex justify-center" max-width="100%"
            ><span>ไม่มีข้อมูล</span></v-card
          >
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="StudyStudentDetailView_Dialog" width="700" persistent>
      <v-card v-if="StudyStudentDetailView_Dialog" class="pa-10">
        <v-row>
          <h3 class="primary--text">ข้อมูลการศึกษา</h3>
        </v-row>
        <v-row class="py-5">
          <v-col>
            <v-row class="d-flex justify-center">
              <span>{{ EducationDialog.schoolname }}</span>
            </v-row>
            <v-row class="d-flex justify-center">
              <span>รหัสนักศึกษา : {{ EducationDialog.studentcode }}</span>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <h4 class="primary--text bold">รายละเอียด</h4>
        </v-row>
        <v-row>
          <v-col>
            <v-row class="pb-2">
              <span class="primary--text pr-1">ปีการศึกษา : </span>
              {{ EducationDialog.gradautedyearth }}
            </v-row>
            <v-row class="pb-2">
              <span class="primary--text pr-1">วุฒิการศึกษา : </span>
              {{ EducationDialog.gradenameth }}
            </v-row>
            <v-row class="pb-2">
              <span class="primary--text pr-1">สถานะการศึกษา : </span>
              {{ EducationDialog.studentstatusname }}
            </v-row>
          </v-col>
          <v-col>
            <v-row class="pb-2">
              <span class="primary--text pr-1">สาขาวิชา : </span>
              {{ EducationDialog.majornameth }}
            </v-row>
            <v-row class="pb-2">
              <span class="primary--text pr-1">สาขางาน : </span>
              {{ EducationDialog.minornameth }}
            </v-row>
            <v-row class="pb-2">
              <span class="primary--text pr-1">เกรดเฉลี่ยรวม (GPAX) : </span>
              {{ EducationDialog.gpax }}
            </v-row>
          </v-col>
        </v-row>
        <v-row class="justify-center text-center mt-7">
          <v-col cols="12">
            <v-btn @click="StudyStudentDetailView_Dialog = false" class="ma-2" color="">
              ย้อนกลับ
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
const studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  props: ["cardID"],
  data() {
    return {
      studentEducations: [],
      StudyStudentDetailView_Dialog: false,
      EducationDialog: {},
    };
  },

  methods: {
    async getStudentVECEducationInformations() {
      try {
        const response = await studentDataProvider.getStudentVECEducationInformations(
          this.idcard
        );
        if (response?.data?.length > 0) {
          this.studentEducations = response.data.map((item) => ({
            schoolname: item.school.schoolname,
            gradenameth: item.gradenameth,
            studentstatusname: item.studentstatusname,
            studentcode: item.studentcode,
            majornameth: item.majornameth,
            minornameth: item.minornameth,
            gradautedyearth: item.gradautedyearth,
            gpax: item.gpax,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    },
    open_StudyStudentDetailView_Dialog(item) {
      this.StudyStudentDetailView_Dialog = true;
      this.EducationDialog = item;
    },
    getStatusColor(status) {
      switch (status) {
        case "สำเร็จการศึกษา":
          return "success";
        case "อยู่ระหว่างศึกษา":
          return "warning";
        default:
          return "info";
      }
    },
  },

  async created() {
    this.idcard = this.cardID;
    await this.getStudentVECEducationInformations();
  },
};
</script>

<style></style>
