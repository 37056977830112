<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" class="d-flex justify-start">
        <v-btn
          @click="government_Dialog = true"
          dark
          color="primary"
          class="ma-2"
        >
          <v-icon class="mr-2">mdi-folder-plus</v-icon> เพิ่มหน่วยงานราชการ
        </v-btn>
      </v-col>
    </v-row>
    <SearchableDataTable
      table_caption="หน่วยงานราชการ"
      :data_items="government"
      :datatable_headers="schools_data_table_headers"
    >
      <template class="d‑flex" v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="onclickEditGovernment(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>แก้ไขหน่วยงานราชการ</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deleteGovernment(item)"
              color="error"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบหน่วยงานราชการ</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
    <GovernmentDialog
      :government_Dialog="government_Dialog"
      :oid="Governmentoid"
      @closeDialog="onRewardProcessIsCompleted"
    ></GovernmentDialog>
  </div>
</template>
<script>
// import axios from "axios"
import { backendapiUrl } from "@/services/constants"
// import { SchoolDataProvider } from "@/services/SchoolDataProvider"
// import { MasterDataProvider } from "@/services/MasterDataProvider"
// import { CommonDataProvider } from "@/services/CommonDataProvider"
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
// import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request"

import GovernmentDialog from "@/views/Government/GovernmentDialog.vue"
import { GovernmentDataProvider } from "@/services/GovernmentDataProvider"
var governmentDataProvider = new GovernmentDataProvider(process.env.VUE_APP_BACKEND_API_URL)
import { GovernmentRecord } from "@/models/Government/GovernmentRecord"

export default {
  props: {
      government_oid: String
    },
    components: {
      SearchableDataTable ,
      GovernmentDialog
    },
  data() {
    return {
      search: "",
      schools_data_table_headers: [
        { text: "รายชื่อหน่วยงานราชการ", value: "nameth" },
        { text: "Actions", value: "action" , sortable: false},
      ],
      schools: undefined,
      vm: new GovernmentRecord(),
      Governmentoid: "-1",
      government: [],
      government_Dialog: false,
    };
  },
  methods: {
      async LoadGovernment(government_oid) {
        governmentDataProvider
          .getGovernmentRecords(government_oid)
          .then((res) => {
            this.government = res.data
          })
          .catch((res) => {})
      },

      async saveGovernment(value) {
        value.government_oid = this.government_oid
        governmentDataProvider
          .saveGovernmentRecord(value)
          .then((res) => {
            this.$toast.success("เพิ่ม หน่วยงานราชการ เรียบร้อย!")
            const government_oid = this.government_oid
            this.LoadGovernment(government_oid)
          })
          .catch((res) => {
            this.$toast.error("ล้มเหลว!")
          })
      },

      async onRewardProcessIsCompleted(value, save) {
        if (save) {
          this.government_Dialog = false
          if (value.oid != "-1") {
            await this.UpdateGovernment(value)
          } else {
            await this.saveGovernment(value)
          }
        } else {
          this.government_Dialog = false
        }
        this.Governmentoid = "-1"
      },

      onclickEditGovernment(item) {
        this.Governmentoid = item.oid
        this.government_Dialog = true
      },
      async UpdateGovernment(value) {
        governmentDataProvider
          .editGovernmentRecord(value)
          .then((res) => {
            if (res?.status == 200) {
              this.$toast.success("บันทึกข้อมูลสำเร็จ")
              const government_oid = this.government_oid
              this.LoadGovernment(government_oid)
            }
          })
          .catch((res) => {
            this.$toast.error("แก้ไขข้อมูลล้มเหลว !!!" + res)
          })
      },

      async deleteGovernment(item) {
        if (confirm("ต้องการเพิ่มหน่วยงานราชการหรือไม่ ?")) {
          await governmentDataProvider
            .deleteGovernmentRecord(item.oid)
            .then((res) => {
              this.government = []
              this.$toast.success("ลบหน่วยงานราชการ เรียบร้อย!")
              const government_oid = this.government_oid
              this.LoadGovernment(government_oid)
            })
            .catch((res) => {
              this.$toast.error("ลบหน่วยงานราชการล้มเหลว!" + res)
            })
        }
        
      },
    },
    async mounted() {
      const government_oid = this.government_oid
      await this.LoadGovernment(government_oid)
    }

};
</script>
