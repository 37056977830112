import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { JobRecord } from "@/models/Student/JobRecord";
import { StudyRecord } from "@/models/Student/StudyRecord";
import { RewardRecord } from "@/models/Student/RewardRecord";
export class StudentDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  getCurrentStudent() {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/GetCurrentStudent";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  loadStudentByOid(oid) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/GetStudentByOid/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getStudentFromStudentID(studentID) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/getStudentFromStudentID/" + studentID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getStudentVECEducationInformations(cardID = "") {
    const header = create_default_request_header();
    const endpointUrl =
      "/api/student/GetStudentVECEducationInformations/" + cardID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  loadStudents(schoolID) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/GetStudents/" + schoolID;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, header);
  }

  loadStudentsWithDividePaging(options) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/GetStudentsWithDividePaging";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    const data = JSON.stringify(options);
    return axios.post(combinedURL, data, header);
  }

  getAllEntranceYears(schoolID) {
    const header = create_default_request_header();
    let endpointUrl = "/api/student/getAllEntranceYears/" + schoolID;
    let combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getStudentVECEducationInformationssWithDividePaging(options) {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/student/GetStudentVECEducationInformationssWithDividePaging";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    const data = JSON.stringify(options);
    return axios.post(combinedURL, data, header);
  }

  updateStuydingStudents(schoolid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/UpdateStudyingStudents/" + schoolid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  loadStudent(Oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/GetStudent/" + Oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, header);
  }

  importStudents(schoolID) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/ImportStudentDataFromWebAPI/" + schoolID;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, header);
  }

  importStudentsBackgroundservice(schoolID) {
    var header = create_default_request_header();
    var endpointUrl =
      "/backgroundapi/StudentImport/ImportStudentData/" + schoolID;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, header);
  }

  UpdateStudent(student) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/UpdateStudent";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    var data = {
      studentcode: student.studentcode,
      idcard: student.idcard,
      titlenameid: student.titlenameid,
      birthday: student.birthday,
      firstname: student.firstname,
      surname: student.surname,

      hight: student.hight,
      weight: student.weight,

      marital: student.marital,
      nationalityid: student.nationalityid,
      religionid: student.religionid,
      domicile: student.domicile,
      military: student.military,
      houseNumber: student.housenumber,
      moo: student.moo,
      soi: student.soi,
      street: student.street,
      tambonid: student.tambonid,
      contacthousenumber: student.contacthousenumber,
      contactmoo: student.contactmoo,
      contactsoi: student.contactsoi,
      contactstreet: student.contactstreet,
      contacttambonid: student.contacttambonid,

      email: student.email,
      tel: student.tel,

      publishprofilestatus: student.publishprofilestatus,
      interestedjob1: student.lookingforjob.interestedjob1,
      interestedjob2: student.lookingforjob.interestedjob2,
      interestedjob3: student.lookingforjob.interestedjob3,
      lookingforjobstatus: student.lookingforjob.lookingforjobstatus,
      fulltime: student.lookingforjob.fulltime,
      parttime: student.lookingforjob.parttime,
      internship: student.lookingforjob.internship,
      freelance: student.lookingforjob.freelance,
      byday: student.lookingforjob.byday,
      temporary: student.lookingforjob.temporary,
      workfromhome: student.lookingforjob.workfromhome,
      rangesalary: student.lookingforjob.rangesalary,
    };
    return axios.post(combinedURL, data, header);
  }

  getStudentStudyRecords(oid = "") {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/GetStudentStudyRecords/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getStudentStudyRecord(oid = "") {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/GetStudentStudyRecord/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  saveStudentJobRecord(vm = new JobRecord()) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/SaveStudentJobRecord";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    const vmjson = JSON.stringify(vm);
    return axios.post(combinedURL, vm, { header });
  }

  editStudentJobRecord(vm) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/EditStudentJobRecord";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.put(combinedURL, vm, { header });
  }

  deleteStudentJobRecord(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/DeleteStudentJobRecord/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.delete(combinedURL, header);
  }

  updateStudentJobRecord(vm = new JobRecord()) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/EditStudentJobRecord/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  getStudentJobRecords(oid = "") {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/GetStudentJobRecords/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getStudentJobRecord(oid = "") {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/GetStudentJobRecord/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  saveStudentStudyRecord(vm = new StudyRecord()) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/SaveStudentStudyRecord/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(
      combinedURL,
      {
        student_oid: vm.student_oid,
        graduatedyear: vm.graduatedyear,
        educationalqualification: vm.educationalqualification,
        selected_educationalqualification_code:
          vm.selected_educationalqualification_code,
        schoolname: vm.schoolname,
        majornameth: vm.majornameth,
        minornameth: vm.minornameth,
        gpa: vm.gpa,
      },
      { header }
    );
  }

  editStudentStudyRecord(vm) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/EditStudentStudyRecord";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.put(
      combinedURL,
      {
        oid: vm.oid,
        student_oid: vm.student_oid,
        graduatedyear: vm.graduatedyear,
        educationalqualification: vm.educationalqualification,
        selected_educationalqualification_code:
          vm.selected_educationalqualification_code,
        schoolname: vm.schoolname,
        majornameth: vm.majornameth,
        minornameth: vm.minornameth,
        gpa: vm.gpa,
      },
      { header }
    );
  }

  deleteStudentStudyRecord(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/DeleteStudentStudyRecord/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.delete(combinedURL, header);
  }

  saveStudentRewardRecord(vm = new RewardRecord()) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/CreateStudentReward";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(
      combinedURL,
      {
        studentoid: vm.studentoid,
        topic: vm.topic,
        rewarddate: vm.rewarddate,
      },
      { header }
    );
  }

  editStudentRewardRecord(vm) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/UpdateStudentReward";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.put(
      combinedURL,
      {
        oid: vm.oid,
        studentoid: vm.studentoid,
        topic: vm.topic,
        rewarddate: vm.rewarddate,
      },
      { header }
    );
  }

  deleteStudentRewardRecord(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/DeleteStudentReward/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.delete(combinedURL, header);
  }

  updateStudentRewardRecord(vm = new RewardRecord()) {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/UpdateStudentReward/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  getStudentRewardRecords(oid = "") {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/GetStudentRewards/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getStudentRewardRecord(oid = "") {
    const header = create_default_request_header();
    const endpointUrl = "/api/student/GetStudentReward/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  uploadImageStudent(formData) {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/student/UploadImageStudent";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.post(combinedURL, formData, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }
  uploadResumeFile(formData) {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/student/UploadResumeStudent";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.post(combinedURL, formData, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }

  LoadResumeFile(student_oid = "") {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/GetResumeFile/" + student_oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  DeleteResumeFile(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/DeleteResumeFile/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.delete(combinedURL, header);
  }

  loadCurrentStudentProfile() {
    var header = create_default_request_header();
    var endpointUrl = "/api/student/GetCurrentStudentProfile";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getCompletionPercentage(oid) {
    const header = create_default_request_header();
    const endpointUrl = "/api/Student/CalculateCompletionPercentage/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, null, header);
  }
  LoadCountStudentUpdatedBySchoolId(schoolid) {
    const header = create_default_request_header();
    const endpointUrl =
      "/api/Student/GetCountStudentUpdatedBySchoolId/" + schoolid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  downloadFile(fileAttachmentOid = "") {
    const header = create_default_request_header();
    const endpointUrl =
      "/api/Student/DownloadFile/?fileAttachmentOid=" + fileAttachmentOid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  async downloadFileAndSave(fileAttachmentOid = "") {
    try {
      // Fetch the file data
      const response = await this.downloadFile(fileAttachmentOid);

      // Extract the content type from headers (if available)
      const contentType =
        response.headers["content-type"] || "application/octet-stream";

      // Extract the filename from the Content-Disposition header (if available)
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "downloaded-file";
      if (contentDisposition && contentDisposition.includes("filename=")) {
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      // Create a Blob from the file data
      const blob = new Blob([response.data], { type: contentType });

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName; // Use the extracted or default file name

      // Append the anchor to the body (it's not visible)
      document.body.appendChild(link);

      // Programmatically click the anchor to trigger the download
      link.click();

      // Clean up by removing the anchor from the document
      document.body.removeChild(link);

      // Revoke the Object URL after the download
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }
}
