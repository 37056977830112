<template>
  <v-btn
    color="white"
    @click="addFavorite(student)"
    :class="{ 'is-favorite': isFavorite }"
  >
    <v-icon v-if="isFavorite" color="error">mdi-heart</v-icon>
    <v-icon v-else color="error">mdi-heart-outline</v-icon>
  </v-btn>
</template>

<script>
import { SaveInterestedInStudentBookmark_Request } from "@/models/EmployerUserbookmark/SaveInterestedInStudentBookmark_Request";
import { EmployerUserbookmarkDataProvider } from "@/services/EmployerUserbookmarkDataProvider";
const employerUserbookmarkDataProvider = new EmployerUserbookmarkDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "BtnFavoriteToggle",
  props: ["student"],
  data() {
    return {
      favorites: [],
      isFavorite: false,
    };
  },
  methods: {
    async addFavorite(student) {
      this.isFavorite = !this.isFavorite;
      const oid = student.oid;
      if (this.isFavorite == true) {
        const request = new SaveInterestedInStudentBookmark_Request();
        request.Student_Oid = oid;
        await employerUserbookmarkDataProvider
          .saveInterestedInStudentBookmark(request)
          .then((result) => {
            this.$toast.success("เพิ่มรายชื่อนักศึกษาเรียบร้อย");
          });
      } else {
        const request = new SaveInterestedInStudentBookmark_Request();
        request.Student_Oid = oid;
        await employerUserbookmarkDataProvider
          .deleteInterestedInStudentBookmark(request)
          .then((result) => {
            this.$toast.warning("ลบรายชื่อนักศึกษาเรียบร้อยแล้ว");
          });
      }
    },
  },
  created() {
    if (this.student) {
      employerUserbookmarkDataProvider
        .isStudentExistInBookmark(this.student.oid)
        .then((result) => {
          this.isFavorite = result.data;
        });
    }
  },
};
</script>

<style></style>
