<template>
  <v-container class="mt-4">
    <v-row class="justify-center">
      <h4>ข้อมูลการติดตามสถานะการทำงาน</h4>
    </v-row>
    <v-row class="justify-space-between mt-4 ml-14 mr-14">
      <div>
        <h4 v-if="graudatedStudentJobTrackingReport">
          รายงานการติดตาม : {{ graudatedStudentJobTrackingReport.schoolname }}
        </h4>
        <h4 v-if="graudatedStudentJobTrackingReport">
          ปีการศึกษา : {{ graudatedStudentJobTrackingReport.yearth }}
        </h4>
        <h4 v-if="graudatedStudentJobTrackingReport">
          เทอม : {{ graudatedStudentJobTrackingReport.semester }}
        </h4>
      </div>
      <div>
        <v-btn :loading="isSaving" @click="save()" color="secondary" class="ma-2">
          <v-icon>mdi-content-save</v-icon>บันทึกข้อมูล
        </v-btn>
      </div>
    </v-row>
    <v-row class="justify-center mt-4">
      <vue-excel-editor
        ref="grid"
        v-if="excel_datasource"
        v-model="excel_datasource"
        filter-row
        width="100%"
        key="oid"
        @update="onExcelEditorUpdate"
      >
        <vue-excel-column
          field="oid"
          label="รหัสรายการ"
          width="50px"
          key-field
          readonly
          type="string"
        />
        <vue-excel-column
          field="idcard"
          label="เลขประจำตัวประชาชน"
          width="150px"
          readonly
          type="string"
        />
        <vue-excel-column
          field="firstname"
          label="ชื่อ"
          width="150px"
          readonly
          type="string"
        />
        <vue-excel-column
          field="surname"
          label="นามสกุล"
          width="150px"
          readonly
          type="string"
        />
        <vue-excel-column
          field="corporationtaxid"
          label="เลขที่ผู้เสียภาษีหน่วยงาน"
          width="250px"
          type="string"
          :validate="validateCorporationtaxID"
        />
        <vue-excel-column
          field="corporationname"
          label="ชื่อหน่วยงานที่ทำงาน"
          width="250px"
          type="string"
        />
        <vue-excel-column
          field="jobpositionname"
          label="ตำแหน่ง"
          width="250px"
          type="string"
        />
        <vue-excel-column
          field="salaryrange"
          label="ช่วงเงินเดือน"
          width="250px"
          type="select"
          :options="salaryRanges"
        />
        <vue-excel-column
          field="employmentstatusname"
          label="สถานะการติดตาม"
          width="250px"
          type="select"
          :options="employmentStatusName"
        />
        <vue-excel-column
          field="continuetostudyinrelatedfieldstatusname"
          label="เรียนตรงสาย"
          width="250px"
          type="select"
          :options="continueToStudyInRelatedFieldStatusNames"
        />
        <vue-excel-column
          field="studycoursetypename"
          label="สายการเรียน"
          width="250px"
          type="select"
          :options="studyCourseTypeNames"
        />
        <vue-excel-column
          field="gettingjobthatrelatedwithstudiedfieldstatusname"
          label="ตรงตามวุฒิ"
          width="250px"
          type="select"
          :options="gettingJobThatRelatedWithStudiedFieldStatusNames"
        />
      </vue-excel-editor>
    </v-row>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { ExcelExportService } from "@/services/ExcelExportService";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import "vue-excel-editor";
import VueExcelColumn from "../../../node_modules/vue-excel-editor/src/VueExcelColumn.vue";

require("vue-excel-editor");

var graduatedStudentJobTrackingReportReportDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var excelExportService = new ExcelExportService(process.env.VUE_APP_BACKEND_API_URL);
var masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  name: "GraduatedStudentJobTrackingReportDetailView_ExcelEditor",
  data() {
    return {
      isSaving: false,
      graduatedStudentJobTrackingReportOid: "",
      graudatedStudentJobTrackingReport: undefined,
      graduatedStudentJobTrackingReportStudentDetails: undefined,
      excel_headers: undefined,
      excel_datasource: undefined,

      salaryRanges: undefined,

      employmentStatusName: undefined,
      continueToStudyInRelatedFieldStatusNames: undefined,
      studyCourseTypeNames: undefined,
      gettingJobThatRelatedWithStudiedFieldStatusNames: undefined,
    };
  },
  methods: {
    async loadGraduatedStudentJobTrackingReport(oid = "") {
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          this.graudatedStudentJobTrackingReport = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadStudentDetails(jobTrackingOid = "") {
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReportStudentDetails(jobTrackingOid)
        .then((response) => {
          this.graduatedStudentJobTrackingReportStudentDetails = response.data;
        });
    },

    async loadEmploymentStatusNames() {
      await masterDataProvider
        .loadEmploymentStatusNames()
        .then((result) => {
          var employmentStatusNamesJson = result.data;
          this.employmentStatusName = employmentStatusNamesJson;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadContinueToStudyInRelatedFieldStatusNames() {
      await masterDataProvider
        .loadContinueToStudyInRelatedFieldStatusNames()
        .then((result) => {
          this.continueToStudyInRelatedFieldStatusNames = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadStudyCourseTypeNames() {
      await masterDataProvider
        .loadStudyCourseTypeNames()
        .then((result) => {
          this.studyCourseTypeNames = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGettingJobThatRelatedWithStudiedFieldStatusNames() {
      await masterDataProvider
        .loadGettingJobThatRelatedWithStudiedFieldStatusNames()
        .then((result) => {
          this.gettingJobThatRelatedWithStudiedFieldStatusNames = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadSalaryRanges() {
      await masterDataProvider
        .loadSalaryRanges()
        .then((result) => {
          var salaryRangeJsons = result.data;
          salaryRangeJsons.sort((a, b) => (a.order > b.order ? 1 : -1));
          this.salaryRanges = salaryRangeJsons.map((slr) => slr.salary_range);
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadExcelDataSource(jobTrackingReportOid = "") {
      await excelExportService
        .GetGraduatedStudentJobTrackingReportStudentDetails(jobTrackingReportOid)
        .then((result) => {
          this.excel_headers = result.data.headers;
          this.excel_datasource = result.data.datasource;
        })
        .catch((err) => {
          alert(err);
        });
    },

    validateCorporationtaxID(content, oldContent, record, field) {
      if (content === "") return "กรุณากรอกข้อมูล";
      if (content.length < 13) return "ต้องมี 13 หลัก";
      if (!/^[0-9]{1}-[0-9]{3}-[0-9]{7}$/.test(content))
        return "รูปแบบเลขที่ประจำตัวผู้เสียภาษีหน่วยงานไม่ถูกต้อง";
      return ""; // return empty string if there is no error
    },

    onExcelEditorUpdate(records) {
      records = records.map((rec) => ["hset", rec.keys.join(), rec.name, rec.newVal]);
    },

    async save() {
      this.isSaving = true;
      graduatedStudentJobTrackingReportReportDataProvider
        .saveGraduatedStudentJobTrackingReportStudentDetailsFromExcelEditor(
          this.excel_datasource
        )
        .then((result) => {
          this.$router.back();
        })
        .catch((err) => alert(err));
      this.isSaving = false;
    },
  },
  async created() {
    this.graduatedStudentJobTrackingReportOid = this.$route.params.graduatedStudentJobTrackingReportOid;
    await this.loadSalaryRanges();
    await this.loadEmploymentStatusNames();
    await this.loadContinueToStudyInRelatedFieldStatusNames();
    await this.loadStudyCourseTypeNames();
    await this.loadGettingJobThatRelatedWithStudiedFieldStatusNames();

    await this.loadGraduatedStudentJobTrackingReport(
      this.graduatedStudentJobTrackingReportOid
    );
    await this.loadStudentDetails(this.graduatedStudentJobTrackingReportOid);
    await this.loadExcelDataSource(this.graduatedStudentJobTrackingReportOid);
  },
  components: { VueExcelColumn },
};
</script>
