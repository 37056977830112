<template>
  <div class="pa-4">
    <v-card rounded elevation="1" class="pa-4">
      <v-row class="d-flex justify-center">
        <v-col lg="11" md="12" sm="12" xs="12">
          <v-row class="mt-2">
            <v-col cols="6" sm="12" md="12" class="d-flex flex-wrap">
              <div class="d-flex flex-wrap">
                <ExtendTrackingEndDate
                  v-if="graudatedStudentJobTrackingReport"
                  :GraduatedStudentJobTrackingReport="
                    graudatedStudentJobTrackingReport
                  "
                  @closeExtendTrackingEndDateDialog="
                    closeExtendTrackingEndDateDialogComplete
                  "
                >
                </ExtendTrackingEndDate>

                <BtnAllowEditGraduatedStudentJobTrackingReport
                  v-if="graudatedStudentJobTrackingReport"
                  :GraduatedStudentJobTrackingReportOid="jobTrackingReportOid"
                  :GraduatedStudentJobTrackingReport="
                    graudatedStudentJobTrackingReport
                  "
                ></BtnAllowEditGraduatedStudentJobTrackingReport>
              </div>
            </v-col>
          </v-row>

          <GraduatedStudentJobTrackingReportInfo
            v-if="graudatedStudentJobTrackingReport"
            :graduatedStudentJobTrackingReport="
              graudatedStudentJobTrackingReport
            "
            @onFilteringGraduatedStudentJobTrackingReportInfo="
              onFilteringGraduatedStudentJobTrackingReportInfo
            "
          >
          </GraduatedStudentJobTrackingReportInfo>

          <v-card rounded elevation="1" class="pa-4 mt-5">
            <div class="mx-5">
              <v-row v-if="related_CertificateTypes?.length > 0">
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  class="custom-col mb-0 pb-0"
                >
                  <h4 class="primary--text mb-2">ระดับชั้น</h4>
                  <v-row no-gutters align-self="center" wrap>
                    <v-btn
                      :color="
                        selectedCertificateTypeCode === '' ? 'primary' : ''
                      "
                      @click="
                        selectedCertificateTypeCode = '';
                        onSelected();
                      "
                      class="mr-2 d-flex align-center py-0"
                    >
                      ทั้งหมด
                    </v-btn>

                    <v-col
                      v-for="(certificate, index) in related_CertificateTypes"
                      :key="certificate.code"
                      cols="auto"
                      class="d-flex mb-2"
                    >
                      <v-btn
                        :color="
                          selectedCertificateTypeCode === certificate.code
                            ? 'primary'
                            : 'input'
                        "
                        @click="
                          selectedCertificateTypeCode = certificate.code;
                          onSelected();
                        "
                        class="mr-2"
                      >
                        {{ certificate.nameth }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  lg="6"
                  md="12"
                  sm="12"
                  xs="12"
                  class="custom-col mb-0 pb-0"
                  v-if="studentDetails?.length >= 0"
                >
                  <h4 class="primary--text mb-2">สาขาวิชา</h4>
                  <v-autocomplete
                    clearable
                    :items="related_Majors"
                    item-text="majornameth"
                    item-value="majornameth"
                    label="ทั้งหมด"
                    @change="onSelected()"
                    background-color="input"
                    filled
                    append-icon="mdi-chevron-down"
                    v-model="request.selected_majornameth"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col
                  lg="6"
                  md="12"
                  sm="12"
                  xs="12"
                  class="custom-col mb-0 pb-0"
                  v-if="studentDetails?.length >= 0"
                >
                  <h4 class="primary--text mb-2">สถานะการมีงานทำ</h4>
                  <v-select
                    clearable
                    v-model="selectedEmploymentStatusCode"
                    :items="related_EmploymentStatuses"
                    :label="
                      selectedEmploymentStatusCode ? '' : 'สถานะการมีงานทำ'
                    "
                    item-text="name"
                    item-value="code"
                    @change="onSelected()"
                    background-color="input"
                    filled
                    append-icon="mdi-chevron-down"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <SearchableDataTablePaging
              table_caption="รายชื่อนักศึกษาที่ติดตาม"
              :data_items="studentDetails"
              :itemlength="itemlength"
              :datatable_headers="studentDetails_headers"
              @onkeywordchanged="onKeywordChanged"
              @pageChanged="pageChanged"
              @tableOptionsChanged="tableOptionsChanged"
            >
              <template v-slot:item.employmentstatusname="{ item }">
                <div class="my-3">
                  <span
                    v-if="
                      !isSentValidate || !item.employmentstatus_errormessage
                    "
                  >
                    <!-- <v-icon
                      v-if="
                        ['ศึกษาต่อ', 'บริษัทเอกชน'].includes(
                          item.employmentstatusname
                        )
                      "
                      size="24px"
                      color="green"
                      >mdi-link</v-icon
                    >

                    <span
                      v-if="
                        ['ศึกษาต่อ', 'บริษัทเอกชน'].includes(
                          item.employmentstatusname
                        )
                      "
                    >
                      (ข้อมูลทางการ)
                    </span> -->
                    {{ item.employmentstatusname }}
                  </span>
                  <span v-else class="error-message">
                    {{ item.employmentstatus_errormessage }}
                  </span>
                </div>
              </template>
              <template v-slot:item.student_firstname="{ item }">
                <span
                  >{{ item.student_firstname }} {{ item.student_surname }}</span
                >
              </template>
              <template v-slot:item.editdatedisplay="{ item }">
                <div v-if="item.editdatedisplay">
                  <FormatDate :date_value="item.editdatedisplay"></FormatDate>
                </div>
              </template>
              <template v-slot:item.action="{ item, index }">
                <v-row class="custom-width">
                  <v-btn
                    color="warning"
                    dark
                    @click="
                      (JobTracking_dialog = true),
                        (selectedstudentdetail_oid = item.oid)
                    "
                    icon
                  >
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>

                  <v-tooltip top color="secondary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                        color="secondary"
                        @click="openStudentDetailOverview(item)"
                      >
                        <v-icon>mdi-account</v-icon>
                      </v-btn>
                    </template>
                    <span>รายละเอียด</span>
                  </v-tooltip>
                </v-row>
              </template>
            </SearchableDataTablePaging>

            <DialogFormJobTracking
              :dialog="JobTracking_dialog"
              :oid="selectedstudentdetail_oid"
              @closeDialogFormJobTracking="OnCloseDialogJobTracking"
              @onFilteringEmploymentStatusNames="
                onFilteringEmploymentStatusNames
              "
            ></DialogFormJobTracking>

            <v-dialog v-model="report_dialog" max-width="500px">
              <!-- Dialog content -->
              <v-card>
                <v-card-title>
                  <h3>รายชื่อรายงาน</h3>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li v-for="report in reports" :key="report.id">
                      <a @click="onReportItemClick(report)" target="_blank">{{
                        report.name
                      }}</a>
                    </li>
                  </ul>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="report_dialog = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable_Student from "@/components/common/SearchableDataTable_Student.vue";

import DialogFormJobTracking from "@/components/GraduatedStudentJobTrackingReport/DialogFormJobTracking.vue";
import ExtendTrackingEndDate from "@/components/GraduatedStudentJobTrackingReport/ExtendTrackingEndDate.vue";
import { EmployerSummaryDataProvider } from "@/services/EmployerSummaryDataProvider";

import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { GraduatedStudentJobTrackingReportValidator } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportValidator";
import { ExcelExportService } from "@/services/ExcelExportService";
import { formatDateThai } from "@/DateTimeHelper";
import BtnAllowEditGraduatedStudentJobTrackingReport from "@/components/GraduatedStudentJobTrackingReport/BtnAllowEditGraduatedStudentJobTrackingReport.vue";
import GraduatedStudentJobTrackingReportInfo from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportInfo.vue";
// import XLSX from "xlsx";
// import { RoleCodes } from "@/Constants";
// XLSX = require("xlsx");
import moment from "moment";
import GraduatedStudentJobTrackingReport_JobTracking_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_JobTracking_Status_Label.vue";
import FormatDate from "@/components/common/FormatDate.vue";
import { GraduatedStudentJobTrackingReportsRequest } from "@/models/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReports_Request";

import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { MinorDataProvider } from "@/services/MinorDataProvider";
import { GraduatedStudentJobTrackingReportFilterOptionDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportFilterOptionDataProvider";

const graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
const graduatedStudentJobTrackingReportValidator =
  new GraduatedStudentJobTrackingReportValidator(
    process.env.VUE_APP_BACKEND_API_URL
  );
const excelExportService = new ExcelExportService(
  process.env.VUE_APP_BACKEND_API_URL
);
const headers = { "Content-Type": "application/json" };
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const minorDataProvider = new MinorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

const graduatedStudentJobTrackingReportFilterOptionDataProvider =
  new GraduatedStudentJobTrackingReportFilterOptionDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );

export default {
  components: {
    SearchableDataTable_Student,
    DialogFormJobTracking,
    ExtendTrackingEndDate,
    BtnAllowEditGraduatedStudentJobTrackingReport,
    GraduatedStudentJobTrackingReportInfo,
    GraduatedStudentJobTrackingReport_JobTracking_Status_Label,
    FormatDate,
    SearchableDataTablePaging,
  },
  name: "GraudatedStudentJobTrackingReportDetailView",
  data() {
    return {
      IsComponentCreatedFirstime: false,
      show: true,
      moment: moment,
      jobTrackingReportOid: "",
      graudatedStudentJobTrackingReport: undefined,
      studentDetails_headers: [
        {
          value: "student_veceducationinformation_studentcode",
          text: "รหัสนักศึกษา",
        },
        { value: "student_firstname", text: "ชื่อ-นามสกุล" },

        {
          value: "student_veceducationinformation_gradenameth",
          text: "ระดับการศึกษา",
        },
        {
          value: "student_veceducationinformation_majornameth",
          text: "สาขาวิชา",
        },
        { value: "editdatedisplay", text: "วันที่แก้ไข" },
        { value: "employmentstatusname", text: "ภาวะการมีงานทำ" },
        { value: "action", text: "ตัวเลือก", align: "center", sortable: false },
      ],
      validateobjectresults: undefined,
      excelDataSource: undefined,
      studentDetails: undefined,
      studentDetailsTemp: [],
      isLoading: false,
      isExporting: false,
      excel_importDialog: false,
      inputfile: undefined,
      isImporting: false,
      isSentValidate: undefined,

      JobTracking_dialog: false,
      extendTrackingDate_Dialog: false,
      update_trackingenddate: "",
      report_dialog: false,
      reports: [
        {
          id: 1,
          name: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          report_name:
            "GraduatedStudentJobTrackingReport_ConcludeEmploymentStatus_Report",
        },
        {
          id: 2,
          name: "รายงานภาวะการมีงานทำตามรายชื่อนักเรียน / นักศึกษา",
          report_name: "GraduatedStudentJobTrackingReport_StudentDetail_Report",
        },
      ],
      selectedstudentdetail_oid: "",
      FilterStudents: [],
      disablemajorfilter: true,
      FilterGrades: [],
      SelectedFilterGrade: "",
      FilterMajors: [],
      SelectedFilterMajor: "",
      filtering_jobtracking_statuscode: "",
      filtering_jobtracking_isTracked: undefined,
      FilterEmploymentStatusNames: [],
      SelectedFilterEmploymentStatusNames: "",
      request: new GraduatedStudentJobTrackingReportsRequest(),
      keyword: "",
      search: "",
      itemlength: 0,
      gender: undefined,
      selectedGender: "",
      tableOptions: undefined,
      filterGraduatedStudent: undefined,

      related_Majors: undefined,
      selectedCertificateTypeCode: "",
      related_CertificateTypes: undefined,
      selectedEmploymentStatusCode: "",
      related_EmploymentStatuses: undefined,
    };
  },

  methods: {
    async loadGraduatedStudentJobTrackingReport(oid = "") {
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          this.graudatedStudentJobTrackingReport = result.data;
          this.request.GraduatedStudentJobTrackingReport_Oid =
            this.graudatedStudentJobTrackingReport.oid;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadFilterOptions() {
      await graduatedStudentJobTrackingReportFilterOptionDataProvider
        .GetRelatedCertificateTypes(this.jobTrackingReportOid)
        .then((result) => {
          this.related_CertificateTypes = result.data;
          console.log(this.related_CertificateTypes);
        });

      await majorDataProvider
        .getMajorNamesByGraduatedStudentJobTrackingReport(
          this.jobTrackingReportOid,
          this.selectedCertificateTypeCode
        )
        .then((result) => {
          this.related_Majors = result.data;
        });

      await graduatedStudentJobTrackingReportFilterOptionDataProvider
        .GetRelatedEmploymentStatuses(this.jobTrackingReportOid)
        .then((result) => {
          this.related_EmploymentStatuses = result.data;
        });
    },

    async loadStudentDetails() {
      this.request.GraduatedStudentJobTrackingReport_Oid =
        this.jobTrackingReportOid;
      this.request.search = this.keyword;
      this.request.isTracked = this.filtering_jobtracking_isTracked;
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReportStudentDetailsWithPagination(
          this.request
        )
        .then((response) => {
          console.log(response);
          this.studentDetails = response.data.items;
          console.log(this.studentDetails);
          this.itemlength = response.data.quantity;
        })
        .catch((err) => err);
    },

    async onSelected() {
      this.$store.dispatch("showLoading");
      this.request.selected_certificatetype_code =
        this.selectedCertificateTypeCode;
      this.request.selected_employmentstatus_code =
        this.selectedEmploymentStatusCode;

      await majorDataProvider
        .getMajorNamesByGraduatedStudentJobTrackingReport(
          this.jobTrackingReportOid,
          this.selectedCertificateTypeCode
        )
        .then((result) => {
          this.related_Majors = result.data;
        });
      await this.loadStudentDetails();
      this.$store.dispatch("hideLoading");
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
      this.request.itemPerPage = newtableOptions.itemsPerPage;
    },
    async onKeywordChanged(newKeyword) {
      this.keyword = newKeyword;
      await this.loadStudentDetails();
    },

    pageChanged(pageChangeValue) {
      if (this.IsComponentCreatedFirstime) {
        this.request.page = pageChangeValue;
        this.loadStudentDetails();
      } else {
        this.IsComponentCreatedFirstime = true;
      }
    },

    closeExtendTrackingEndDateDialogComplete(EndDate) {
      this.graudatedStudentJobTrackingReport.trackingenddate = EndDate;
    },
    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },
    openStudentDetailOverview(item) {
      var studentoid = item.student_oid;
      const routeData = this.$router.resolve({
        name: "StudentDetailOverview",
        params: { studentoid: studentoid },
      });
      window.open(routeData.href, "_blank");
    },
    async onFilteringEmploymentStatusNames() {
      this.studentDetailsTemp.forEach((element) => {
        this.FilterEmploymentStatusNames.push(element.employmentstatusname);
      });
    },

    async importGraduatedStudents() {
      store.dispatch({
        type: "doAddedMessage",
        message: {
          id: Math.random(),
          title: this.graudatedStudentJobTrackingReport.schoolname,
          status: "ส่งคำร้อง",
          icon: "mdi-help-box",
        },
      });

      this.isLoading = true;
      await graduatedStudentJobTrackingReportReportDataProvider
        .importGraduatedStudentsBackgroundservice(this.jobTrackingReportOid)
        .then((result) => {
          store.dispatch({
            type: "doAddedMessage",
            message: {
              id: Math.random(),
              title: this.graudatedStudentJobTrackingReport.schoolname,
              status: "รอดำเนินการ..",
              icon: "mdi-help-box",
            },
          });

          let item = [];
          item.jobid = result.data;
          item.schoolname = this.graudatedStudentJobTrackingReport.schoolname;
          store.dispatch({
            type: "doImportContinue",
            item,
          });

          this.loadStudentDetails();
          this.$toast.success("ส่งคำขอนำเข้าข้อมูลเรียบร้อย");
        });
      this.isLoading = false;
    },
    async importPreviousGraduatedStudents() {
      store.dispatch({
        type: "doAddedMessage",
        message: {
          id: Math.random(),
          title: this.graudatedStudentJobTrackingReport.schoolname,
          status: "ส่งคำร้อง",
          icon: "mdi-help-box",
        },
      });

      this.isLoading = true;
      await graduatedStudentJobTrackingReportReportDataProvider
        .importGraduatedStudentsFromPreviousDataBackgroundservice(
          this.jobTrackingReportOid
        )
        .then((result) => {
          store.dispatch({
            type: "doAddedMessage",
            message: {
              id: Math.random(),
              title: this.graudatedStudentJobTrackingReport.schoolname,
              status: "รอดำเนินการ..",
              icon: "mdi-help-box",
            },
          });

          let item = [];
          item.jobid = result.data;
          item.schoolname = this.graudatedStudentJobTrackingReport.schoolname;
          store.dispatch({
            type: "doImportContinue",
            item,
          });

          this.loadStudentDetails();
          this.$toast.success("ส่งคำขอนำเข้าข้อมูลเรียบร้อย");
        });
      this.isLoading = false;
    },

    async getGraduatedStudentJobTrackingReportStudentDetails_ExcelDataSource() {
      if (this.studentDetails != undefined) {
        await excelExportService
          .GetGraduatedStudentJobTrackingReportStudentDetails(
            this.jobTrackingReportOid
          )
          .then((result) => {
            this.excelDataSource = result.data;
          });
      }
    },

    onReportItemClick(item) {
      const report_name = item.report_name;
      this.openReportView(report_name);
    },

    openReportView(report_name = "") {
      this.$router.push({
        name: "GraduatedStudentJobTrackingReportDetailView_Report",
        params: {
          Oid: this.jobTrackingReportOid,
          report_name: report_name,
        },
      });
    },

    async validate() {
      this.isSentValidate = true;
      var oid = this.jobTrackingReportOid;
      await graduatedStudentJobTrackingReportValidator
        .validateGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          const returnData = result.data;
          if (returnData.validateresult == false) {
            this.$toast.error("กรุณากรอกข้อมูลให้ครบถ้วน และถูกต้อง");
          } else {
            this.$toast.success("รายงานการติดตามนี้ถูกต้องและครบถ้วน");
          }
        });
    },
    async assignManagerProfileCertifier() {
      //Validate the report
      await this.loadStudentDetails();

      const oid = this.jobTrackingReportOid;
      await graduatedStudentJobTrackingReportValidator
        .validateGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          const returnData = result.data;
          if (returnData.validateresult == false) {
            this.isSentValidate = true;
            this.$toast.error("กรุณากรอกข้อมูลให้ครบถ้วน และถูกต้อง");
          } else {
            this.$router.push({
              name: "GraduatedStudentJobTrackingReportDetailView_CertificationAndSendReport",
              params: {
                graduatedStudentJobTrackingReportOid: this.jobTrackingReportOid,
              },
            });
          }
        });
    },
    OnCloseDialogJobTracking(isComplete, employmentstatusname) {
      this.JobTracking_dialog = false;
      if (isComplete) {
        let index = this.studentDetails.findIndex(
          (el) => el.oid === this.selectedstudentdetail_oid
        );
        this.studentDetails[index].editdatedisplay = Date.now();
        if (
          this.graudatedStudentJobTrackingReport.countstudentjobtracked == 0
        ) {
          this.graudatedStudentJobTrackingReport.jobtracking_status.statuscode =
            "InProcessOfTracking";
          this.graudatedStudentJobTrackingReport.jobtracking_status.statusgroupname =
            "GraduatedStudentJobTrackingReport_JobTracking";
          this.graudatedStudentJobTrackingReport.jobtracking_status.statusname =
            "อยู่ระหว่างติดตามภาวะการมีงานทำ";
        }
        if (!this.studentDetails[index].employmentstatusname) {
          this.graudatedStudentJobTrackingReport.countstudentjobtracked++;
        }
        this.studentDetails[index].employmentstatusname = employmentstatusname;
      }
    },
    async onFilteringGraduatedStudentJobTrackingReportInfo(isTracked) {
      this.filtering_jobtracking_isTracked = isTracked;
      await this.loadStudentDetails();
    },
  },

  async mounted() {
    this.jobTrackingReportOid = this.$route.params.trackingReportOid;
    await this.loadGraduatedStudentJobTrackingReport(this.jobTrackingReportOid);
    await this.loadStudentDetails();
    await this.loadFilterOptions();
  },
};
</script>
<style scoped>
.custom-width {
  width: 100px;
  gap: 5px;
}

@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
