<template>
  <div class="home-container">
    <appbar></appbar>
    <v-container class="pa-0">
      <v-row class="mt-3">
        <v-col cols="12" class="d-block">
          <div class="d-flex justify-center text-center">
            <h2 class="appInfo">ระบบติดตามภาวะการมีงานทำและการศึกษาต่อ</h2>
          </div>
          <div class="d-flex justify-center text-center">
            <h2 class="appInfo">ของผู้สำเร็จการศึกษาอาชีวศึกษา</h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-hover v-slot="{ hover }">
            <v-card
              color="orange"
              style="cursor: pointer"
              @click="
                $router.push({
                  path: 'Login',
                })
              "
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                src="@/assets/login/i_manager.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)"
                :height="$vuetify.breakpoint.smAndDown ? '240px' : '360px'"
                contain
              >
                <v-card-title
                  v-if="hover"
                  v-text="'ผู้บริหาร'"
                  class="justify-center"
                ></v-card-title>
              </v-img>
              <v-fade-transition>
                <v-overlay
                  :opacity="0.3"
                  v-if="!hover"
                  absolute
                  color="#252525"
                  class="white--text align-end"
                >
                  <v-card-title
                    v-text="'ผู้บริหาร'"
                    class="justify-center"
                  ></v-card-title>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-hover
            @click="
              $router.push({
                path: 'LoginOfficer',
              })
            "
            v-slot="{ hover }"
          >
            <v-card
              color="#833133"
              style="cursor: pointer"
              @click="
                $router.push({
                  path: 'LoginOfficer',
                })
              "
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                src="@/assets/login/i_officer.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)"
                :height="$vuetify.breakpoint.smAndDown ? '240px' : '360px'"
                contain
              >
                <v-card-title
                  v-if="hover"
                  v-text="'บุคลากรส่วนกลาง'"
                  class="justify-center"
                ></v-card-title>
              </v-img>
              <v-fade-transition>
                <v-overlay
                  :opacity="0.3"
                  v-if="!hover"
                  absolute
                  color="#252525"
                  class="white--text align-end"
                >
                  <v-card-title
                    v-text="'บุคลากรส่วนกลาง'"
                    class="justify-center"
                  ></v-card-title>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-hover v-slot="{ hover }">
            <v-card
              color="green"
              style="cursor: pointer"
              @click="
                $router.push({
                  path: 'LoginTeacher',
                })
              "
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              class="mb-5"
            >
              <v-img
                src="@/assets/login/i_teacher.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)"
                :height="$vuetify.breakpoint.smAndDown ? '240px' : '360px'"
                contain
              >
                <v-card-title
                  v-if="hover"
                  v-text="'บุคลากรสถานศึกษา'"
                  class="justify-center"
                ></v-card-title>
              </v-img>
              <v-fade-transition>
                <v-overlay
                  :opacity="0.3"
                  v-if="!hover"
                  absolute
                  color="#252525"
                  class="white--text align-end"
                >
                  <v-card-title
                    v-text="'บุคลากรสถานศึกษา'"
                    class="justify-center"
                  ></v-card-title>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
export default {
  name: "HomeView",
  components: { appbar },
  mounted() {
    let token = localStorage.getItem("token");
    if (token) {
      this.$store.dispatch("showLoading");
      this.$store.dispatch({ type: "restoreLogin" });
    }
    this.$store.dispatch("hideLoading");
  },
};
</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.appInfo {
  color: #3f51b5;
  font-size: 1.5rem;
}

.v-application .indigo {
  background-color: #408442 !important;
  border-color: #408442 !important;
}

@media (max-width: 600px) {
  .appInfo {
    font-size: 1.2rem;
  }
}
</style>
