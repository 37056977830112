import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";

export class FileAttachmentDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  getFileAttachment(oid) {
    var header = create_default_request_header()
    var endpointUrl = "/api/FileAttachment/GetFileAttachment/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

  getFileAttachmentAsFile(oid){
    var header = create_default_request_header()
    var endpointUrl = "/api/FileAttachment/getFileAttachmentAsFile/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

  getFileAttachmentAsBase64(oid){
    var header = create_default_request_header()
    var endpointUrl = "/api/FileAttachment/getFileAttachmentAsBase64/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }
}
