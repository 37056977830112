import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { data } from "jquery";
import { LocalStorageKey_Schools } from "@/LocalStorageDataKeys";
export class SchoolDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  loadSchool(schoolID) {
    const header = create_default_request_header();
    var endpointPath = "/api/school/GetSchool/" + schoolID;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, header);
  }

  getSchoolsBy_CurrentApplicationUser() {
    const header = create_default_request_header();
    const endpointPath = "/api/school/getSchoolsBy_CurrentApplicationUser";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadSchools(getSchoolsRequest = GetSchools_Reqeust) {
    const cachedData = localStorage.getItem(
      LocalStorageKey_Schools + "_" + `${process.env.VUE_APP_VERSION}`
    );
    if (cachedData) {
      const dataschool = JSON.parse(cachedData);
      let resultschool = dataschool;
      if (getSchoolsRequest.SchoolType > 0) {
        resultschool = resultschool.filter(
          (x) => x.schooltype == getSchoolsRequest.SchoolType
        );
      }
      if (getSchoolsRequest.RegionAsVEC) {
        resultschool = resultschool.filter(
          (x) => x.regionasvec == getSchoolsRequest.RegionAsVEC
        );
      }
      if (getSchoolsRequest.ProvinceID) {
        resultschool = resultschool.filter(
          (x) => x.provinceid == getSchoolsRequest.ProvinceID
        );
      }
      data = resultschool;
      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      const endpointPath = "/api/school/GetSchools";
      const request_data = JSON.stringify(getSchoolsRequest);
      const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .post(combinedURL, getSchoolsRequest, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_Schools +
                  "_" +
                  `${process.env.VUE_APP_VERSION}`,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  importSchools() {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/school/ImportSchoolDataFromWebAPI";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.get(combinedURL, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }

  getSchoolsFromGraduatedStudentJobTrackingReportExport(
    schoolType = "",
    provinceID = "",
    regionAsVECName = ""
  ) {
    const header = create_default_request_header();
    let endpointPath =
      "/api/school/GetSchoolsFromGraduatedStudentJobTrackingReportExport";
    let combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    let data = {
      SchoolType: schoolType,
      ProvinceID: provinceID,
      RegionName: regionAsVECName,
    };
    let jsonData = JSON.stringify(data);
    return axios.post(combinedURL, jsonData, header);
  }
}
