<template>
  <v-container>
    <v-row>
      <v-col lg="10" md="10" sm="10">
        <v-autocomplete
          item-text="schoolname"
          item-value="schoolid"
          :items="schools"
          label="สถานศึกษา"
          v-model="selected_schoolID"
          @change="onSelectSchool()"
        ></v-autocomplete>
      </v-col>
      <v-col lg="2" md="2" sm="2">
        <div class="text-center">
          <v-btn @click="loadStudents()" outlined>แสดงข้อมูลนักเรียน</v-btn>
          <v-progress-circular
            class="pl-15"
            indeterminate
            v-if="isLoading"
            color="primary"
          >
          </v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" md="12" sm="12">
        <h3>รายชื่อนักศึกษา : {{ current_school.schoolname }}</h3>
      </v-col>
      <v-col lg="12" md="12" sm="12">
        <v-card-title class="elevation-4 mb-3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา"
            single-line
            hide-details
          >
          </v-text-field>
        </v-card-title>
        <v-data-table
          :headers="students_data_table_headers"
          :items="students"
          :items-per-page="10"
          :search="search"
          class="elevation-4"
        >
          <template v-slot:item.action="{ item }">
            <v-btn outlined @click="openStudentDetailView(item)">รายละเอียด</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
let schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
let studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  name: "Student_ListView_SchoolEmployee",
  data() {
    return {
      isLoading: false,
      current_schoolID: "",
      current_school: [],
      schools: [],
      selected_schoolID: "",
      students_data_table_headers: [
        { text: "รหัสนักศึกษา", value: "studentid" },
        { text: "ชื่อ", value: "firstname" },
        { text: "นามสกุล", value: "surname" },
        { text: "ระดับการศึกษา", value: "gradenameth" },
        { text: "", value: "action" , sortable: false},
      ],
      students: [],
      search: "",
    };
  },
  methods: {
    intiialize() {
      this.school = schoolDataProvider.loadSchool(this.current_schoolID);
    },
    async loadSchools() {
      const getSchools_Request = new GetSchools_Reqeust();
      var response = await schoolDataProvider.loadSchools(getSchools_Request);
      this.schools = response.data;
    },
    onSelectSchool() {},
    async loadStudents() {
      if (this.selected_schoolID != "") {
        this.isLoading = true;
        var response_school = await schoolDataProvider.loadSchool(this.selected_schoolID);
        this.current_school = response_school.data;
        var response = await studentDataProvider.loadStudents(this.selected_schoolID);
        this.students = response.data;
        this.isLoading = false;
      } else {
        alert("กรุณาเลือกสถานที่ศึกษา");
      }
    },
    openStudentDetailView(student) {
      var studentID = student.studentid;
      this.$router.push({ name: "StudentDetailView", params: { studentID: studentID } });
    },
  },
  mounted() {
    this.loadSchools();
  },
};
</script>
