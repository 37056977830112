import axios from "axios";
import { create_default_request_header } from "@/services/constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { CreateSchoolAdminProfile_Request } from "@/models/Profile/SchoolAdminProfile/CreateSchoolAdminProfile_Request"

export class SchoolAdminProfileDataProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }

  createSchoolAdminProfile_School_Admin(
    vm = new CreateSchoolAdminProfile_Request()
  ) {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolAdmin_SchoolEmployeeProfile/CreateSchoolAdminProfile_School_Admin";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  createSchoolAdminProfile_School_Manager(
    vm = new CreateSchoolAdminProfile_Request()
  ) {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolAdmin_SchoolEmployeeProfile/CreateSchoolAdminProfile_School_Manager";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  createSchoolAdminProfile_School_Guidance_Teacher(
    vm = new CreateSchoolAdminProfile_Request()
  ) {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolAdmin_SchoolEmployeeProfile/CreateSchoolAdminProfile_School_Guidance_Teacher";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  createSchoolAdminProfile_School_Partner_Office(
    vm = new CreateSchoolAdminProfile_Request()
  ) {
    var header = create_default_request_header();
    var endpointUrl = "/api/SchoolAdmin_SchoolEmployeeProfile/CreateSchoolAdminProfile_School_Partner_Office";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

}
