<template>
  <div>
    <appbar></appbar>
    <v-container class="">
      <v-row class="justify-center pt-10">
        <LoginForm
          :loadingbtn="loading"
          @submit-login="onsubmitcomplete"
          @recovery-indentify="onclickforgetpassword"
          filename="Lmanager.jpg"
          description="ส่วนกลาง / อาชีวศึกษาจังหวัด / สถาบันการอาชีวศึกษา / สถานศึกษา"
        >
        </LoginForm>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
import LoginForm from "@/components/LoginForm/LoginForm.vue";
import { AuthenticationProvider } from "@/services/AuthenticationProvider";
const authenticationProvider = new AuthenticationProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "Login",

  components: {
    appbar,
    LoginForm,
  },

  data() {
    return {
      loading: false,
      loginpage: "admin",
    };
  },
  methods: {
    async onclickforgetpassword() {
      const loginpage = this.loginpage;
      this.$router.push({
            name: "recoveryindentifySchoolEmployee",
            params: { loginpage },
          });
    },
    async onsubmitcomplete(loginCredential) {
      this.loading = true;
      const isChangePasswordOnLogon = await authenticationProvider.IsThisUserMustChangeFirstLogon(
        loginCredential
      );
      if (isChangePasswordOnLogon.data == true) {
        this.loading = false;
        const username = loginCredential.username;
        this.$router
          .push({
            name: "SetPassword",
            params: { username },
          })
          .catch((err) => {
            this.loading = false;
            this.$toast.error(
              "Incorrect username or password. ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"
            );
          });
      } else {
        this.$store
          .dispatch({ type: "doLogin", loginCredential })
          .then((response) => {
            this.loading = false;
            this.$toast.success("เข้าสู่ระบบเรียบร้อย !");
            this.$store.dispatch({ type: "gotoFirstPage" });
            if (this.lsRememberMe == true) {
              localStorage.setItem("username", loginCredential.username);
            } else {
              localStorage.setItem("username", (loginCredential.username = ""));
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast.error(
              "Incorrect username or password. ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"
            );
          });
      }
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.headertext {
  font-size: 22pt !important;
}
</style>
