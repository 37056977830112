<template>
  <v-card class="mt-5 d-flex justify-center">
    <v-col cols="10" class="">
      <v-card-title>
        <h4>สถานะใบสมัครงาน</h4>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="d-flex flex-row justify-center mt-4">
            <v-col cols="12">
              <h4 class="primary--text mb-2">สถานะ</h4>
              <v-select
                filled
                background-color="input"
                append-icon="mdi-chevron-down"
                label="กรุณาเลือกสถานะ"
                :items="jobapplicationentitystatuses"
                item-text="statusname"
                item-value="statuscode"
                v-model="vm.statuscode"
              ></v-select> </v-col
            ><v-col cols="3"> </v-col>
          </v-row>
          <div v-if="isSettingToInterview">
            <p class="primary--text">นัดหมายสัมภาษณ์</p>
            <v-row>
              <v-col cols="6">
                <TextFieldDatePicker
                  filled
                  background-color="input"
                  v-model="vm.interviewdate"
                  :value="vm.interviewdate"
                  caption="วันที่นัดสัมภาษณ์"
                  @closeAndUpdate="this.OnInterviewdateSelected"
                ></TextFieldDatePicker>
              </v-col>
              <v-col cols="6">
                <TimePicker
                  :value="vm.interviewtime"
                  caption="เวลา"
                  @closeAndUpdate="OninterviewtimeSelected"
                ></TimePicker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h4 class="primary--text mb-2">สถานที่สัมภาษณ์</h4>
                <v-textarea
                  filled
                  background-color="input"
                  label="สถานที่สัมภาษณ์"
                  clearable
                  v-model="vm.interviewlocation"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12">
              <h4 class="primary--text mb-2">ข้อความเพิ่มเติม</h4>
              <v-textarea
                filled
                background-color="input"
                label="ข้อความ"
                hint="ระบุข้อความเพิ่มเติมที่ต้องการแจ้งให้นักศึกษา"
                clearable
                v-model="vm.notifymessage"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row class="justify-end text-end mt-7 mb-4">
            <v-col cols="12">
              <v-btn
                v-if="openBack"
                color=""
                @click="closeDialog()"
                class="mr-2 justify-center"
              >
                ย้อนกลับ</v-btn
              ><v-btn color="secondary" class="color-button2" @click="onSave">
                บันทึก<v-icon>mdi-content-save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-col></v-card
  >
</template>

<script>
import { MasterDataProvider } from "@/services/MasterDataProvider";
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import { SetJobApplicationStatusRequest } from "@/models/JobApplications/SetJobApplicationStatusRequest";
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import TimePicker from "@/components/common/TimePicker.vue";
import { JobApplicationDataProvider } from "@/services/JobApplicationDataProvider";
const jobApplicationDataProvider = new JobApplicationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: { TextFieldDatePicker, TimePicker },
  name: "JobApplicationStatusManagement",
  props: ["JobApplication", "sentopenBack"],
  data() {
    return {
      jobapplication_oid: "",
      jobapplicationentitystatuses: [],
      selected_jobapplicationentitystatus_code: "",
      selected_interviewdate: "",
      vm: new SetJobApplicationStatusRequest(),
      openBack: "true",
    };
  },
  computed: {
    isSettingToInterview() {
      return this.vm.statuscode == "InterviewScheduled";
    },
  },
  methods: {
    async initiailize() {
      await masterDataProvider
        .getJobApplicationEntityStatuses()
        .then((result) => {
          this.jobapplicationentitystatuses = result.data;
        });

      await jobApplicationDataProvider
        .getJobApplication(this.jobapplication_oid)
        .then((result) => {
          this.vm = SetJobApplicationStatusRequest.fromJson(result.data);
        });
    },

    async OninterviewtimeSelected(val) {
      this.vm.interviewtime = val;
    },

    async OnInterviewdateSelected(val) {
      this.vm.interviewdate = val;
    },

    async onSave() {
      employerJobPostingDataProvider
        .setJobApplicationStatus(this.vm)
        .then((result) => {
          this.$toast.success("บันทึกการเปลี่ยนแปลงสถานะเรียบร้อย");
          this.$emit("finished");
        });
    },

    openBackFuction() {
      if (this.sentopenBack !== undefined) {
        this.openBack = false;
      }
    },

    closeDialog() {
      if (this.sentopenBack === false) {
        this.$emit("finished");
      } else {
        this.$router.go(-1);
      }
    },
  },
  async created() {
    this.jobapplication_oid = this.JobApplication.oid;
    // this.openBack = this.sentopenBack;
    this.selected_jobapplicationentitystatus_code =
      this.JobApplication.jobapplicationstatus.statuscode;
    await this.initiailize();
    await this.openBackFuction();
  },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
