<template>
  <div class="d-flex justify-center">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="12" md="12" sm="12" xs="12">
          <v-card>
            <v-form class="elevation-4">
              <v-card-text class="d-flex justify-center">
                <v-col cols="10" class="mt-0 pt-0">
                  <v-row class="primary--text mb-2 mt-8 ml-2">
                    <h3>
                      <v-icon color="primary" class="mr-2">mdi-domain</v-icon
                      >รายละเอียดสถานศึกษา
                    </h3>
                  </v-row>
                  <v-divider class="mt-2 mx-2 mb-4 border-opacity-25"></v-divider>

                  <v-row class="pa-2">
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">รหัสโรงเรียน</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="รหัสโรงเรียน :"
                        v-model="school.schoolid"
                        :rules="passwordSchoolRules"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 pt-0 mt-0">
                    <v-col lg="12" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">ชื่อโรงเรียน (ภาษาไทย)</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="ชื่อโรงเรียน(TH)"
                        v-model="school.schoolname"
                        :rules="nameTHRules"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 pt-0 mt-0">
                    <v-col lg="12" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">ชื่อโรงเรียน (ภาษาอังกฤษ)</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="ชื่อโรงเรียน(EN)"
                        v-model="school.schoolnameen"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 mt-2">
                    <v-col cols="12">
                      <h3 class="primary--text">
                        <v-icon color="primary" class="mr-2">mdi-domain</v-icon>ที่อยู่
                      </h3>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2 mx-2 mb-4 border-opacity-25"></v-divider>

                  <v-row class="pa-2 pt-0 mt-0">
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">เลขที่</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="เลขที่"
                        v-model="school.housenumber"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">หมู่ที่</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="หมู่ที่"
                        v-model="school.villagenumber"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 pt-0 mt-0">
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">ซอย</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="ซอย"
                        v-model="school.soid"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">ถนน</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="ถนน"
                        v-model="school.street"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 pt-0 mt-0">
                    <v-col lg="3" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">จังหวัด</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="จังหวัด"
                        v-model="school.tambon.district.province.provincethai"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col lg="3" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">อำเภอ</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="อำเภอ"
                        v-model="school.tambon.district.districtthaishort"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col lg="3" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">ตำบล</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="ตำบล"
                        v-model="school.tambon.tambonthai"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col lg="3" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">รหัสไปรษณีย์</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="รหัสไปรษณีย์"
                        v-model="school.zipcode"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 mt-2">
                    <v-col cols="12">
                      <h3 class="primary--text">
                        <v-icon color="primary" class="mr-2">mdi-domain</v-icon
                        >ช่องทางการติดต่อ
                      </h3>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2 mx-2 mb-4 border-opacity-25"></v-divider>

                  <v-row class="pa-2 pt-0 mt-0">
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">อีเมล</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="อีเมล"
                        v-model="school.email"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">เบอร์โทร</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="เบอร์โทร"
                        v-model="school.telephone"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-2 pt-0 mt-0">
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">FAX</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="FAX"
                        v-model="school.fax"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col">
                      <h4 class="primary--text mb-2">Website</h4>
                      <v-text-field
                        clearable
                        background-color="input"
                        filled
                        label="Website"
                        v-model="school.website"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="px-5 justify-end">
                    <v-btn class="mr-2" @click="$router.go(-1)">ย้อนกลับ</v-btn>
                    <v-btn dark class="color-button2"
                      >บันทึก<v-icon class="ml-2"> mdi-content-save</v-icon></v-btn
                    >
                  </v-row>
                </v-col>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { passwordSchoolRules, nameTHRules } from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "",
  data() {
    return {
      schoolID: "",
      school: [],
      passwordSchoolRules: passwordSchoolRules,
      nameTHRules: nameTHRules,
    };
  },
  methods: {
    async loadSchool(schoolID = "") {
      await schoolDataProvider
        .loadSchool(schoolID)
        .then((response) => {
          this.school = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  async created() {
    this.schoolID = this.$route.params.schoolID;
    await this.loadSchool(this.schoolID);
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
