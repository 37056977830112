import BackgroundService from "@/views/BackgroundService/BackgroundService.vue";

export default [
  {
    path: "/BackgroundService",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/BackgroundService/BackgroundService",
        name: "BackgroundService",
        component: BackgroundService,
      },
    ],
  },
];
