import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
export class EmailConfigurationDataProvider 
{
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    getCurrentEmailConfiguration() {
        const headers = create_default_request_header();
        var endpointPath = "/api/EmailConfiguration/GetCurrentEmailConfiguration";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, null, headers);
    }

    saveEmailConfiguration(request) {
        var data = JSON.stringify(request);
        const headers = create_default_request_header();
        var endpointPath = "/api/EmailConfiguration/SaveEmailConfiguration";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, data, headers);
    }

    send_TestEmail(targetEmail = ''){
        const headers = create_default_request_header();
        var endpointPath = "/api/EmailConfiguration/Send_TestEmail/" + targetEmail;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, null, headers);
    }
}