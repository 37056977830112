import DashboardView_Admin from "@/views/Dashboard/DashboardView_Admin.vue";
import DashboardView_Manager from "@/views/Dashboard/DashboardView_Manager.vue";
import DashboardView_SchoolStaff from "@/views/Dashboard/DashboardView_SchoolStaff.vue";

import DashboardView_ForAdmin from "@/views/Dashboard/DashboardView_ForAdmin";
export default [
  {
    path: "/Dashboard",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Dashboard/DashboardView_ForAdmin",
        name: "DashboardView_ForAdmin",
        component: DashboardView_ForAdmin,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Dashboard/DashboardView_Admin",
        name: "DashboardView_Admin",
        component: DashboardView_Admin,
      },
      {
        path: "/Dashboard/DashboardView_Manager",
        name: "DashboardView_Manager",
        component: DashboardView_Manager,
      },
      {
        path: "/Dashboard/DashboardView_SchoolStaff",
        name: "DashboardView_SchoolStaff",
        component: DashboardView_SchoolStaff,
      },
    ],
  },
];
