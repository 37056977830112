<template >
    <v-form class="template-container">
      <v-row class="d-flex justify-center pt-5">
        <v-col cols="11">
          <v-card style="border-radius: 10px">
            <v-card-text id="element-to-convert">
              <v-row>
                <v-col cols="12 pl-5">
                  <v-col class="mt-1" cols="12" md="12" sm="12">
                    <v-chip
                      v-if="job.isargent == true"
                      class=""
                      color="error"
                      text-color="white"
                      >ด่วน
                    </v-chip>
                  </v-col>
  
                  <v-row>
                    <v-col
                      class="d-flex flex-column align-center job-content"
                      cols="12"
                    >
                      <v-row class="">
                        <h2 class="pb-2">
                          {{ job.positionname }}
                        </h2>
                      </v-row>
                      <v-row class="">
                        <label>{{ job.employerprofile?.nameth }}</label>
                      </v-row>
                      <v-row class="">
                        <div style="font-style: italic">
                          <v-icon>mdi-map-marker</v-icon
                          ><label>{{
                            job.employerprofile?.district.province.provincethai
                          }}</label>
                        </div>
                      </v-row>
                      <v-row>
                        <v-btn
                          small
                          outlined
                          rounded
                          color="primary"
                          @click="openEmployerProfileInfo"
                          class="ma-2"
                        >
                          <v-icon class="mr-1">mdi-account-search</v-icon>
                          ดูโปรไฟล์บริษัท
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br />
                  <v-col>
                    <v-divider
                      :thickness="3"
                      class="border-opacity-75"
                      color="secondary"
                    >
                    </v-divider>
                  </v-col>
  
                  <br />
                  <v-row class="text1-content">
                    <v-col cols="12" class="pa-2 pt-4 pl-6">
                      <v-row>
                        <label class="unit">อัตราที่รับสมัคร :</label>
                        <label> {{ job.unit }}</label>
                      </v-row>
                      <v-row>
                        <label class="unit">ประเภทงาน :</label>
                        <label v-if="this.job.jobsegmentname_todisplay !== ''">{{
                          job.jobsegmentname_todisplay
                        }}</label>
                        <label v-if="this.job.jobsegmentname_todisplay == ''"
                          >-</label
                        >
                      </v-row>
                      <v-row>
                        <label class="unit">วิธีการรับสมัคร :</label>
                        <label
                          v-if="
                            this.job.employerprofile
                              ?.isrequiredjobapplicanttoregisterthemselforviapost ==
                            true
                          "
                          >สมัครด้วยตนเอง/ส่งใบสมัครทางไปรษณีย์
                          <!-- ให้ผู้สมัครงานไปสมัครด้วยตนเอง หรือ ส่งใบสมัครทางไปรษณีย์ -->
                        </label>
                        <label
                          v-if="
                            this.job.employerprofile
                              ?.isrequiredjobapplicanttoregisterthemselforviapost ==
                              true &&
                            this.job.employerprofile?.isopenforonlineapplicant ==
                              true
                          "
                        >
                          /
                        </label>
                        <label
                          v-if="
                            this.job.employerprofile?.isopenforonlineapplicant ==
                            true
                          "
                        >
                          เปิดรับสมัคร online
                        </label>
                        <label
                          v-if="
                            this.job.employerprofile
                              ?.isrequiredjobapplicanttoregisterthemselforviapost ==
                              false &&
                            this.job.employerprofile?.isopenforonlineapplicant ==
                              false
                          "
                          >-</label
                        >
                      </v-row>
                      <v-row>
                        <label class="unit">เงินเดือน :</label>
                        <label>{{ job.wagetodisplay }} </label>
                      </v-row>
                      <v-row>
                        <label class="unit">ระดับการศึกษาขั้นต่ำ :</label>
                        <label>{{ job.requirecertificatemessage }}</label>
                      </v-row>
                      <v-row>
                        <label class="unit">ปิดรับสมัครวันที่ :</label>
                        <label class="error--text"
                          ><FormatDate :date_value="job.enddate"></FormatDate
                        ></label>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
  
              <div
                style="
                  background-color: rgba(63, 81, 181, 0.8);
                  color: white;
                  border-radius: 20px;
                  padding: 5px 10px;
                  font-size: 16px;
                  margin: 40px 0px 10px;
                "
                class="text-center"
              >
                คุณสมบัติผู้สมัคร
              </div>
  
              <v-row>
                <v-col>
                  <v-row class="benefit pa-2 pt-4 pl-6">
                    <span class="ql-editor"
                      >{{ job.applicant_specification }} </span
                    ><br />
                  </v-row>
                </v-col>
              </v-row>
              <div
                style="
                  background-color: rgba(63, 81, 181, 0.8);
                  color: white;
                  border-radius: 20px;
                  padding: 5px 10px;
                  font-size: 16px;
                  margin: 40px 0px 10px;
                "
                class="text-center"
              >
                หน้าที่และรายละเอียดของงาน
              </div>
  
              <v-row>
                <v-col>
                  <v-row class="benefit pa-2 pt-4 pl-6">
                    <span class="ql-editor">{{ job.jobdescription }} </span><br />
                  </v-row>
                </v-col>
              </v-row>
  
              <div class="html2pdf__page-break"></div>
              <div
                style="
                  background-color: rgba(63, 81, 181, 0.8);
                  color: white;
                  border-radius: 20px;
                  padding: 5px 10px;
                  font-size: 16px;
                  margin: 40px 0px 10px;
                "
                class="text-center"
              >
                สวัสดิการ
              </div>
              <v-row>
                <v-col>
                  <v-row class="benefit pa-2 pt-4 pl-6">
                    <span class="ql-editor"
                      >{{ job.employerprofile?.benefitdescription }} </span
                    ><br />
                  </v-row>
                </v-col>
              </v-row>
  
              <div
                style="
                  background-color: rgba(63, 81, 181, 0.8);
                  color: white;
                  border-radius: 20px;
                  padding: 5px 10px;
                  font-size: 16px;
                  margin: 40px 0px 30px;
                "
                class="text-center"
              >
                ข้อมูลติดต่อ
              </div>
  
              <v-row class="text1-content pa-2 pt-2 pl-6">
                <v-col class="d-flex flex-column align-center pb-7" cols="12">
                  <v-row class="pa-1 pt-2 font-weight-bold">
                    <label>{{ job.employerprofile?.nameth }}</label>
                  </v-row>
                  <v-row
                    v-if="
                      job.employerprofile?.district.province.provincethai !== ''
                    "
                    class="pa-1 pt-2"
                  >
                    <label
                      >{{ job.employerprofile?.tambon.tambonthai }}
                      {{ job.employerprofile?.district.districtthai }}
                      {{ job.employerprofile?.district.province.provincethai }}
                    </label>
                  </v-row>
                  <v-row class="pa-1 pt-2">
                    <label>tel : {{ job.coordinator_phoneno }} </label>
                    <label v-if="job.employerprofile?.phone !== undefined">
                    </label>
                    <label v-else>, {{ job.employerprofile?.phone }} </label>
                  </v-row>
                  <v-row class="pa-1 pt-2">
                    <label>email : {{ job.coordinator_email }} </label>
                    <label v-if="job.employerprofile?.email == undefined">
                    </label>
                    <label v-else>, {{ job.employerprofile?.email }} </label>
                  </v-row>
                  <v-row class="pa-1 pt-2">
                    <a :href="link">{{ job.websiteurl }}</a>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row class="justify-center text-center mt-4">
            <v-col cols="12">
              <span class="">บันทึกเข้าแฟ้ม</span>
              <!-- <div style="cursor:pointer; color: blue;">
                        <v-icon color="primary" class="mr-2">mdi-printer</v-icon>print
                      </div> -->
              <v-btn icon dark color="primary" @click="print" class="ma-2 ml-10">
                <v-icon class="mr-2">mdi-printer</v-icon>print
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="justify-center text-center mb-4">
            <v-col cols="12">
              <v-btn
                :disabled="isRegisted"
                color="primary"
                @click="confirm_dialog = true"
                class="ma-2"
              >
                <v-icon class="mr-2">mdi-email</v-icon>
                สมัครงาน
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="confirm_dialog" width="400">
        <template>
          <v-card class="pa-6">
            <v-row class="justify-center text-center">
              <v-card-title> ยืนยันการสมัครงาน ? </v-card-title>
              <v-col cols="12">
                <v-btn
                  @click="onRegisterJobPostingConfirmed()"
                  class="ma-2"
                  color="success"
                >
                  <v-icon class="mr-2">mdi-check-circle-outline</v-icon>ยืนยัน
                </v-btn>
                <v-btn
                  @click="confirm_dialog = false"
                  class="ma-2"
                  color=""
                  elevation="2"
                  >ยกเลิก</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-dialog>
    </v-form>
  </template>
    
    <script>
  import { JobPostingSearcherDataProvider } from "@/services/JobPostingSearcherDataProvider";
  const jobPostingSearcherDataProvider = new JobPostingSearcherDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );

  import html2pdf from "html2pdf.js";
  import { StudentJobPostingDataProvider } from "@/services/StudentJobPostingDataProvider";
  import FormatDate from "@/components/common/FormatDate.vue";
  
  const studentJobPostingDataProvider = new StudentJobPostingDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
  export default {
    name: "JobPostingListViewDetail",
    components: {  FormatDate },
    props: {
      oid: {
        type: String,
      },
    },
  
    data() {
      return {
        current_applicationuser_rolecodes: undefined,
  
        job: "",
        isFavorite: false,
        isRegisted: false,
        position: "ช่างยนต์ ออโตคลิก แยกสี่กอ กะทู้",
        employer: "บริษัท ออโตคอร์ป โฮลดิ้ง จำกัด (มหาชน)",
        province: "ภูเก็ต",
        recruitmentrate: "3",
        typejob: "งานประจำ (Full Time)",
        Howtoapply: "ติดต่อสถานประกอบการ",
        salary: "12,000++",
        gender: "ชาย",
        age: "ไม่เกิน 30 ปี",
        level: "ปวช. ขึ้นไป สาขา ช่างยนต์, เทคนิคยานยนต์",
        experience: "มีประสบการณ์ฝึกงาน หรือ ทำงาน ซ่อมรถยนต์ จะพิจารณาเป็นพิเศษ",
        etc: "มีใบอนุญาตขับขี่รถยนต์",
        tel: "043306333",
        email: "hr-team@ach.co.th",
        phone: "0620080797",
        address: "",
        link: "https://www.autocorpgroup.com/en",
        detailsjob:
          "ให้บริการซ่อมรถยนต์ของลูกค้าที่เข้ามารับบริการ ตามใบสั่งซ่อม",
        benefit1: "- ประกันสังคม",
        benefit2: "- เบี้ยขยัน",
        benefit3: "- คอมมิชชั่น",
        benefit4: "- เครื่องแบบพนักงาน",
        benefit5: "- กองทุนสำรองเลี้ยงชีพ",
        benefit6: "- ตรวจสุขภาพประจำปี",
        benefit7: "- เงินยินดีมงคลสมรส",
        benefit8: "- เงินอนุโมทนาอุปสมบท",
        benefit9: "- เงินช่วยเหลือฌาปนกิจ",
        benefit10: "- ประกันอุบัติเหตุ",
        benefit11: "- ประกันสุขภาพ",
        benefit12: "- เบี้ยเลี้ยงเดินทางปฏิบัติงาน",
        benefit13: "- ส่วนลดสำหรับพนักงาน",
        confirm_dialog: false,
        logo_photobase64: "",
      };
    },
    computed: {
      display_favorite_btn() {
        if (this.current_applicationuser_rolecodes == undefined) return false;
        return this.current_applicationuser_rolecodes.includes("Employer");
      },
    },
    methods: {
      async initialize() {
        this.current_applicationuser_rolecodes = this.$store.getters.rolecodes;
      },
  
      async loadJobPosting() {
        await jobPostingSearcherDataProvider
          .getJobPosting(this.oid)
          .then((result) => {
            this.job = result.data;
            this.logo_photobase64 = result.data.employerprofile.logo_photobase64;
          });
      },
  
      async checkIsRegistered() {
        const jobposting_oid = this.oid;
      },
  
      oidChange(oid) {
        if (this.oid !== oid) {
          this.loadJobPosting();
        }
      },
      toggleFavorite() {
        this.isFavorite = !this.isFavorite;
      },
      print() {
        html2pdf(document.getElementById("element-to-convert"), {
          margin: 10,
          filename: "JobDetails.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          enableLinks: true,
          pagebreak: { mode: ["css", "legacy"] },
        });
      },
      openEmployerProfileInfo: function () {
        const joboid = this.oid;
        this.$router
          .push({ name: "EmployerProfileInfo", params: { joboid } })
          .catch((err) => {});
      },
      async onRegisterJobPostingConfirmed() {
        const jobposting_oid = this.oid;
        await studentJobPostingDataProvider
          .createJobApplication(jobposting_oid)
          .then((result) => {
            this.$toast.success("ยื่นใบสมัครเรียบร้อย");
            this.loadJobPosting();
            this.checkIsRegistered();
            this.confirm_dialog = false;
          });
      },
    },
    watch: {
      oid: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadJobPosting();
        }
      },
    },
    async created() {
      // this.oid = this.$route.params.oid;
      await this.loadJobPosting();
      await this.checkIsRegistered();
    },
  };
  </script>
    
    <style scoped>
  .shrink {
    /* width: 350px;
    height: 180px; */
    justify-items: center;
    /* box-shadow: 0 0 10px rgba(15, 15, 15, 0.5);
        border: 1px solid grey; */
  }
  
  label,
  .benefit,
  a {
    font-size: 16px;
  }
  .logo-content {
    display: grid;
    place-items: center;
  }
  .job-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1cm;
  }
  .text1-content {
    padding-left: 0.5cm;
  }
  
  .unit {
    margin-right: 4px;
  }
  
  .template-container {
    height: 100vh;
    width: 70vw;
    overflow: auto;
  }
  </style>
    
  