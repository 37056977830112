<template>
  <div>
    <appbar></appbar>
    <v-container>
      <v-row class="justify-center pt-0">
        <LoginForm
          :loadingbtn="loading"
          @submit-login="handleLogin"
          @recovery-indentify="onclickforgetpassword"
          filename="Lemployer.jpg"
          description="สถานประกอบการ"
        >
          <template v-slot:employer>
            <v-btn
              class="rounded-pill mt-2"
              color="#E8AA42"
              type="button"
              @click="gotoRegisteremployer"
              dark
              outlined
            >
              <span>ลงทะเบียนสถานประกอบการ</span>
            </v-btn>
          </template>
        </LoginForm>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
import LoginForm from "@/components/LoginForm/LoginForm.vue";
import { AuthenticationProvider } from "@/services/AuthenticationProvider";
var authenticationProvider = new AuthenticationProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "LoginEmployer",

  components: {
    appbar,
    LoginForm,
  },

  data() {
    return {
      loading: false,
      loginpage: "employee",
    };
  },
  methods: {
    async onclickforgetpassword() {
      const loginpage = this.loginpage;
      this.$router.push({
        name: "recoveryindentifyEmployee",
        params: { loginpage },
      });
    },
    async handleLogin(loginCredential) {
      this.loading = true;
      try {
        const isChangePasswordOnLogon = await authenticationProvider.IsThisUserMustChangeFirstLogon(
          loginCredential
        );
        if (isChangePasswordOnLogon.data) {
          this.loading = false;
          const username = loginCredential.username;
          this.$router.push({
            name: "SetPassword",
            params: { username },
          });
        } else {
          const response = await this.$store.dispatch({
            type: "doLogin",
            loginCredential,
          });
          if (response?.status === 200) {
            this.$toast.success("เข้าสู่ระบบเรียบร้อย !");
            this.$store.dispatch({ type: "gotoFirstPage" });
            if (this.lsRememberMe) {
              localStorage.setItem("username", loginCredential.username);
            } else {
              localStorage.setItem("username", "");
            }
          } else {
            this.$toast.error(
              "Incorrect username or password. ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"
            );
          }
        }
      } catch (err) {
        this.$toast.error(
          "Incorrect username or password. ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"
        );
      } finally {
        this.loading = false;
      }
    },
    gotoRegisteremployer() {
      // this.$router.push({ path: "/RegisterEmployer/RegisterEmployer" });
      this.$router.push({
        name: "RegisterEmployer",
      });
    },
  },
};
</script>

<style scoped>
.headertext {
  font-size: 22pt !important;
}
a {
  text-decoration: none;
}
</style>
