<template>
  <div>
    <appbar></appbar>

    <v-container>
      <v-card elevation="1" class="pa-2 my-0 mb-2">
        <div class="d-flex flex-row">
          <v-row class="d-flex">
            <v-col class="pt-5" xl="2" md="2" lg="2" sm="12" xs="12">
              <h4 class="mb-2 mb-0 ml-2 px-2 primary--text px-2">
                กรองข้อมูล :
              </h4>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="4" md="4" sm="12" xs="12" class="mb-0 pb-0">
            <v-text-field
              class="mr-3 ml-3"
              clearable
              label="ค้นหา : ชื่อตำแหน่ง/ชื่อบริษัท"
              variant="solo-filled"
              v-model="keyword"
            ></v-text-field>
          </v-col>

          <v-col cols="4" md="4" sm="12" xs="12" class="mb-0 pb-0">
            <v-select
              @change="OnSelectedSarary"
              label="ช่วงเงินเดือน"
              item-value="salary_range"
              item-text="salary_range"
              class="mr-3"
              solo
              clearable
              :items="SalaryRanges"
              v-model="tableOptions.SalaryRange"
            >
            </v-select>
          </v-col>
          <v-col cols="4" md="4" sm="12" xs="12" class="mb-0 pb-0">
            <v-select
              @change="onChangeStatus"
              label="สถานะ"
              item-text="statusname"
              item-value="statuscode"
              class="mr-3"
              solo
              clearable
              :items="JobPostingEntityStatuses"
              v-model="tableOptions.Selected_JobPostingStatusCode"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <div class="d-flex">
        <v-data-table
          :headers="jobPosting_data_table_headers"
          :items="jobPosting"
          :server-items-length="itemlength"
          class="elevation-1 mt-5 mb-3 left-content"
          multi-sort
          :options.sync="options"
          disable-pagination
          :footer-props="{ disableItemsPerPage: true, itemsPerPageText: '' }"
        >
          <template v-slot:item="{ item }">
            <tr height="70px" class="cursor">
              <td>
                <v-row class="mx-2" @click="sendOID(item)">
                  <v-avatar color="grey" size="50" class="my-2">
                    <v-img
                      :src="
                        'data:image/jpg;base64,' +
                        item.employerprofile.logo_photobase64
                      "
                    ></v-img>
                  </v-avatar>
                  <v-col>
                    <div
                      v-html="item.employerprofile.nameth"
                      class="font-weight-bold"
                    ></div>
                    <div v-html="item.positionname"></div>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
        <JobPostingListViewDetailVue v-if="oid" :oid="oid" />
      </div>
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
import { CenterStaffJobPostingDataProvider } from "@/services/CenterStaffJobPostingDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import JobPostingListViewDetailVue from "@/components/JobPostings/JobPostingListViewDetail.vue";

const centerStaffJobPostingDataProvider = new CenterStaffJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

import { JobPostingSearcherDataProvider } from "@/services/JobPostingSearcherDataProvider";
const jobPostingSearcherDataProvider = new JobPostingSearcherDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "JobPostingListView",
  components: { JobPostingListViewDetailVue,appbar },
  data() {
    return {
      jobPosting: undefined,
      jobPosting_data_table_headers: [
        {
          text: "สถานประกอบการ",
          value: "employerprofile.nameth",
          align: "center",
        },
      ],
      itemlength: 0,
      tableOptions: {},
      selectedStatus: null,
      SalaryRanges: [],
      JobPostingEntityStatuses: [],
      oid: null,
      keyword: "",
      options: {
        page: 1,
        sortBy: [],
        sortDesc: [],
        SchoolID: "",
      },
    };
  },
  methods: {
    async loadSalaryRanges() {
      masterDataProvider.loadSalaryRanges().then((response) => {
        this.SalaryRanges = response.data;
      });
    },
    async loadJobPostingEntityStatuses() {
      masterDataProvider.getJobPostingEntityStatuses().then((response) => {
        this.JobPostingEntityStatuses = response.data;
      });
    },
    async loadjobPosting() {
      // this.options.SalaryRange = this.tableOptions.SalaryRange;
      await centerStaffJobPostingDataProvider
        .getJobPostins(this.options)
        .then((result) => {
          this.jobPosting = result.data.items;
          this.itemlength = result.data.quantity;
          this.loadjobDetail(this.jobPosting[0].oid);
        })
        .catch((err) => {
          alert(err);
        });
    },

    filterJobPostingsByStatus() {
      if (this.selectedStatus) {
        // กรองงานตำแหน่งตามสถานะที่เลือก
        this.jobPosting = this.jobPosting.filter(
          (posting) =>
            posting.jobpostingstatus.statusname === this.selectedStatus
        );
      } else {
        // หากไม่มีสถานะที่เลือกไว้ ให้โหลดงานตำแหน่งเริ่มต้น
        this.loadjobPosting();
      }
    },
    sendOID(item) {
      this.oid = item.oid;
      if (this.oid !== item.oid) {
        this.loadjobDetail(this.oid);
      }
      this.loadjobDetail(this.oid);
    },

    async loadjobDetail(joboid) {
      await jobPostingSearcherDataProvider
        .getJobPosting(joboid)
        .then((result) => {
          this.job = result.data;
          this.oid = this.job.oid;
          this.logo_photobase64 = result.data.employerprofile.logo_photobase64;
        });
    },
    pageChanged(pageChangeValue) {
      this.options.page = pageChangeValue;
      this.loadjobPosting();
    },

    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
    },
    OnSelectedSarary(val) {
      this.options.SalaryRange = val;
      this.loadjobPosting();
    },
    onChangeStatus(val) {
      this.options.Selected_JobPostingStatusCode = val;
      this.loadjobPosting();
    },
  },
  watch: {
    keyword(newVal) {
      this.options.search = newVal;
      this.loadjobPosting();
    },
    tableOptions: {
      handler(newValue, oldValue) {
        this.loadjobPosting();
      },
      deep: true,
    },

    options: {
      handler(newValue, oldValue) {
        this.$emit("tableOptionsChanged", newValue);
      },
      deep: true,
    },
    "options.page": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.options.page = newValue;
          this.$emit("pageChanged", newValue);
          this.loadjobPosting();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.loadSalaryRanges();
    await this.loadjobPosting();
    await this.loadJobPostingEntityStatuses();
  },
};
</script>

<style scoped>
.left-content {
  width: auto;
}
.cursor {
  cursor: pointer;
}
</style>
