import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "@/views/Home.vue";
import Vcop from "@/views/Vcop.vue";
import HomeMenu from "@/views/HomeMenu.vue";

import Login from "@/router/Login.routes";
import ApplicationUser from "./ApplicationUser.routes";
import GraduateTracking from "@/router/Graduate-Tracking.routes";
import Student from "@/router/Student.routers";
import School from "@/router/School.routes";
import GraduatedStudent from "@/router/GraduatedStudent.routes";
import GraduatedStudentJobTrackingReport from "@/router/GraduatedStudentJobTrackingReport.routes";

import SubjectType from "@/router/SubjectType.routes";
import Minor from "@/router/Minor.routes";
import Major from "@/router/Major.routes";
import Institute from "@/router/Institute.routes";
import Curriculum from "@/router/Curriculum.routes";

import Employer from "@/router/Employer.routes";
import SchoolEmployee from "@/router/SchoolEmployee.routes";
import Profile from "@/router/Profile.routes";
import Dashboard from "@/router/Dashboard.routes";
import Report from "@/router/Report.routes";
import JobPosting from "./JobPosting.routes";

import VerifyManager from "@/router/VerifyManager.routes";

import Government from "@/router/Government.routes";
import Titlename from "@/router/Titlename.routes";
import Province from "@/router/Province.routes";
import District from "@/router/District.routes";
import Tambon from "@/router/Tambon.routes";
import BackgroundService from "@/router/BackgroundService.routes";
import RegisterEmployer from "@/router/RegisterEmployer.routes";
import JobAdvertisement from "@/router/JobAdvertisement.routes";
import DefaultJobPostingSetupRoute from "./DefaultJobPostingSetup.route";
import EmailConfiguration from "./EmailConfiguration.route";

import SearchStudentList from "@/router/SearchStudentList.routes";

import SearchStudentDetails from "@/router/SearchStudentDetails.routes";
import SearchStudentResume from "@/router/SearchStudentResume.routes";

import Salaryranges from "@/router/Salaryranges.routes";
import EmployerMainBusinessTypes from "@/router/EmployerMainBusinessTypes.routes";
import EmployerSummary from "./EmployerSummary.route";
import TitleNames from "@/router/TitleNames.routes";
import Nationalities from "@/router/Nationalities.routes";
import Religions from "@/router/Religions.routes";
import JobSegment from "@/router/JobSegment.routes";
import Notification from "@/router/Notification.routes";

import SearchJob from "@/router/SearchJob.routes";
import JobPostingListView from "@/views/๋JobPostings/JobPostingListView.vue";
import News from "@/router/News.routes";
import VcopCMS from "@/router/Vcop.routes.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/default/DefaultTemplate.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "vcop",
        name: "vcop",
        component: Vcop,
      },
      {
        path: "about",
        name: "About",
        component: () => import("@/views/About.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/JobPosting/JobPostingListView",
        name: "JobPostingListView",
        component: JobPostingListView,
        meta: {
          nameTh: "รายการข้อมูลประกาศงาน",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/homemenu",
        name: "HomeMenu",
        component: HomeMenu,
      },
    ],
  },
  ...Login,
  ...ApplicationUser,
  ...GraduateTracking,
  ...Student,
  ...School,
  ...GraduatedStudent,
  ...GraduatedStudentJobTrackingReport,
  ...SubjectType,
  ...DefaultJobPostingSetupRoute,
  ...EmailConfiguration,
  ...Minor,
  ...Major,
  ...Institute,
  ...Curriculum,
  ...Profile,
  ...Dashboard,
  ...Report,
  ...Employer,
  ...SchoolEmployee,
  ...VerifyManager,
  ...Government,
  ...Titlename,
  ...BackgroundService,
  ...Province,
  ...District,
  ...Tambon,
  ...RegisterEmployer,
  ...JobAdvertisement,
  ...SearchStudentList,
  ...SearchStudentDetails,
  ...SearchStudentResume,
  ...SearchJob,
  ...Salaryranges,
  ...EmployerMainBusinessTypes,
  ...EmployerSummary,
  ...TitleNames,
  ...Nationalities,
  ...Religions,
  ...JobSegment,
  ...JobPosting,
  ...Notification,
  ...News,
  ...VcopCMS,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {

  const isTrackingMenuExpanded = store.getters.isTrackingMenuExpanded;
  if (isTrackingMenuExpanded !== null) {
      if (isTrackingMenuExpanded) {
          store.dispatch('loadTrackingMenuState');
      } else {
          store.dispatch('loadTrackingMenuState');
      }
  } else {
      store.dispatch('loadTrackingMenuState');
  }


  if (to.meta.nameTh != undefined) {
    if (store.getters["historyroutes"].some((x) => to.meta.nameTh === x.name)) {
    } else {
      store.dispatch({
        type: "doAddRoute",
        path: { name: to.meta.nameTh, path: to.fullPath },
      });
    }
  }
  //Back click case
  if (
    store.getters["historyroutes"][store.getters["historyroutes"].length - 2]
      ?.path == to.fullPath
  ) {
    store.dispatch({
      type: "doRemoveRoute",
      namepath:
        store.getters["historyroutes"][
          store.getters["historyroutes"].length - 1
        ]?.name,
    });
    store.dispatch({
      type: "doAddRoute",
      path: { name: to.meta.nameTh, path: to.fullPath },
    });
  }

  document.title = "VEC App - " + to.name;
  const token = await localStorage.getItem("token");
  if (token) {
    store.dispatch("showLoading")
    let currentPath = to.fullPath.toLowerCase()
    if (
      currentPath == "/login" ||
      currentPath == "/loginofficer" ||
      currentPath == "/loginteacher" ||
      currentPath == "/loginstudent" ||
      currentPath == "/loginemployer" ||
      currentPath == "/home" ||
      currentPath == "/vcop" ||
      currentPath == "/" 
    ) {
      store
        .dispatch({ type: "restoreLogin" })
        .then((response) => {
          if (response?.status == 200) {
            store.dispatch({ type: "gotoFirstPage" });
          }
        })
        .catch(() => {
          store.dispatch({ type: "doLogout" });
        })
        .finally(() => {
          store.dispatch("hideLoading");
        });
    } else {
      store.dispatch({ type: "restoreLogin" }).then((response) => {
        if (response?.status == 200) {
          // nothing event
        }
      }).catch(() => {
        store.dispatch({type: "doLogout"})
      }).finally(() => {
        store.dispatch("hideLoading")
      })
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      store.dispatch({ type: "doLogout" });
    }
  }
  next();
});

export default router;
