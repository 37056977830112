<template>
  <div class="pa-4">
    <v-card elevation="1" class="pa-4" ev>
      <h3 class="primary--text">
        <v-icon color="primary" class="mr-2">mdi-file-document-edit</v-icon
        >รายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา
      </h3>
      <v-divider class="mt-4 mb-4 mx-0"></v-divider>
      <v-form>
        <div class="d-flex flex-row">
          <v-row class="d-flex">
            <v-col class="pt-5" xl="2" md="2" lg="2" sm="12" xs="12">
              <h4 class="mb-2 mb-0 ml-3 primary--text px-2">กรองข้อมูล :</h4>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="6" md="6" class="mb-0 pb-0">
            <v-select
              clearable
              :items="yearth_choices"
              label="ปีการศึกษา"
              v-model="input_yearth"
              class="select px-3 pl-5"
              @change="loadTrackingReports()"
              solo
            ></v-select>
          </v-col>
          <v-col cols="6" md="6" class="mb-0 pb-0">
            <v-select
              clearable
              :items="semester_choices"
              label="เทอม"
              v-model="input_semester"
              @change="loadTrackingReports()"
              class="select px-3 pr-5"
              solo
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <!--<v-row class="mt-2 mb-2 pa-2">
            <v-btn @click="open_Popup_CreateGraduatedStudentJobTrackingReport()">+ สร้างรายงาน</v-btn>
        </v-row>-->
    <SearchableDataTable
      :data_items="job_tracking_reports"
      :datatable_headers="datatable_headers"
      table_caption="รายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา"
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          outlined
          @click="openJobTrackingReportDetailView(item)"
          color="secondary"
          class="ma-2"
        >
          <v-icon class="mr-2">mdi-magnify</v-icon>รายละเอียด
        </v-btn>
      </template>
    </SearchableDataTable>

    <Popup_CreateGraduatedJobTrackingReportView
      v-model="display_Popup_CreateGraduatedJobTrackingReportView"
      @closePopup="display_Popup_CreateGraduatedJobTrackingReportView = false"
    >
    </Popup_CreateGraduatedJobTrackingReportView>
  </div>
</template>

<script>
import axios from "axios";
import { backendapiUrl } from "@/services/constants";
import Popup_CreateGraduatedJobTrackingReportView from "@/views/GraduatedStudentJobTrackingReport/Popup_CreateGraduatedJobTrackingReportView.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";

const headers = { "Content-Type": "application/json" };
var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: {
    Popup_CreateGraduatedJobTrackingReportView: () =>
      import(
        "@/views/GraduatedStudentJobTrackingReport/Popup_CreateGraduatedJobTrackingReportView.vue"
      ),
    Popup_CreateGraduatedJobTrackingReportView,
    SearchableDataTable,
  },
  name: "GraduatedStudentTrackingReportListView_SchoolStaff",
  data() {
    return {
      yearth_choices: [],
      semester_choices: [],
      input_yearth: "",
      input_semester: "",
      datatable_headers: [
        { value: "schoolid", text: "รหัสสถานศึกษา" },
        { value: "schoolname", text: "ชื่อสถานศึกษา" },
        { value: "schoolprovincename", text: "จังหวัด" },
        { value: "targetgraduatedyearth", text: "ปีการศึกษาที่จบ" },
        { value: "roundnumber", text: "รอบที่ติดตาม" },
        {
          value: "quantityofgraduatedstudentjobtrackingreport_studentdetails",
          text: "จำนวนนักศึกษาที่สำเร็จการศึกษา",
        },
        { value: "status.statusname", text: "สถานะ" },
        { value: "action", text: "" , sortable: false},
      ],
      job_tracking_reports: [],
      display_Popup_CreateGraduatedJobTrackingReportView: false,
    };
  },
  methods: {
    async loadChoices() {
      //Get yearth choices
      await commonDataProvider.getListOfYearTH().then((result) => {
        this.yearth_choices = result.data;
        this.yearth_choices.sort();
      });

      await commonDataProvider.getListOfSemesters().then((result) => {
        this.semester_choices = result.data;
        this.semester_choices.sort();
      });
    },
    createJsonRequestObject() {
      const data = JSON.stringify({
        yearth: this.input_yearth,
        semester: this.input_semester,
        schoolid: this.schoolid,
      });
      return data;
    },
    loadTrackingReports() {
      var data = this.createJsonRequestObject();
      axios
        .post(
          backendapiUrl +
            "/api/GraduatedStudentJobTrackingReport/GetGraduatedStudentJobTrackingReportsSchoolStaff",
          {
            yearth: this.input_yearth,
            semester: this.input_semester,
            schoolid: this.$route.params.schoolid,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          this.job_tracking_reports = response.data;
        });
    },
    openJobTrackingReportDetailView(jobTrackingReport) {
      var trackingReportOid = jobTrackingReport.oid;
      this.$router
        .push({
          name: "GraduatedStudentJobTrackingReportDetailView",
          params: { trackingReportOid },
        })
        .catch((err) => {});
    },
    open_Popup_CreateGraduatedStudentJobTrackingReport() {
      this.display_Popup_CreateGraduatedJobTrackingReportView = true;
    },
  },
  mounted() {
    this.loadChoices();
    this.loadTrackingReports();
  },
  beforeCreate() {},
};
</script>
