<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2">mdi-file-document-edit-outline</v-icon>
            หลักสูตร</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.curriculumid"
                label="รหัส PK หลักสูตร"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.curiculumtype"
                label="รหัสประเภทหลักสูตร"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.structureyearid"
                label="โครงสร้างหลักสูตรปี"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.year"
                label="ปีหลักสูตร"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="8" lg="8" cols="12">
              <v-text-field
                v-model="curriculum.curriculumyear"
                label="ปีหลักสูตร"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.degreelevelid"
                label="รหัส PK ระดับชั้น"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="8" lg="8" cols="12">
              <v-text-field
                v-model="curriculum.degreelevelcode"
                label="รหัสระดับชั้น"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.subjecttypeid"
                label="รหัส PK ประเภทวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.subjecttypecode"
                label="รหัสประเภทวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.subjecttypename"
                label="ชื่อประเภทวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.majorid"
                label="รหัส PK สาขาวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.majorcode"
                label="รหัสสาขาวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.majorname"
                label="ชื่อสาขาวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.minorid"
                label="รหัส PK สาขางาน"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.minorcode"
                label="รหัสสาขางาน"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="4" lg="4" cols="12">
              <v-text-field
                v-model="curriculum.minorname"
                label="ชื่อสาขาวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="curriculum.remark"
                label="หมายเหตุ"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { CurriculumDataProvider } from "@/services/CurriculumDataProvider";
var curriculumDataProvider = new CurriculumDataProvider(backendapiUrl);
export default {
  name: "CurriculumDetailView",
  data() {
    return {
      curriculumID: "",
      curriculum: [],
    };
  },

  methods: {
    async loadCurriculum(curriculumID = "") {
      await curriculumDataProvider
        .loadCurriculum(curriculumID)
        .then((response) => {
          this.curriculum = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },

  async created() {
    this.curriculumID = this.$route.params.curriculumID;
    await this.loadCurriculum(this.curriculumID);
  },
};
</script>

<style></style>
