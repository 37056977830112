<template>
  <div>
    <template>
      <v-btn
        :disabled="isBtnDialogDisabled"
        v-show="show"
        class="mr-2 mb-4"
        color="warning"
        @click="extendTrackingDate_Dialog = true"
      >
        <v-icon class="mr-1">mdi-clock-outline</v-icon>
        ขยายเวลาวันที่สิ้นสุดการติดตาม</v-btn
      >
    </template>

    <v-dialog v-model="extendTrackingDate_Dialog" width="700">
      <template>
        <div>
          <v-card class="pa-10">
            <h2 class="primary--text">
              <v-icon class="primary--text">mdi-clock-outline</v-icon>
              ขยายเวลาสิ้นสุดการติดตาม
            </h2>
            <v-divider class="mt-4 mb-5 mx-0"></v-divider>
            <v-row>
              <v-col cols="12">
                <label class=""
                  >สามารถขยายวันที่สิ้นสุดการติดตามของรอบการติดตามได้
                  โดยการแก้ไขนี้จะมีผลกับระยะเวลาของการรายงานการติดตามเฉพาะรายสถานศึกษา
                </label>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label
                  >วันที่สิ้นสุดการติดตามเดิม :
                  {{
                    this.convertDateFormat(
                      GraduatedStudentJobTrackingReport.trackingenddate
                    )
                  }}</label
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="GraduatedStudentJobTrackingReport.trackingenddate">
                <TextFieldDatePicker
                  :value="GraduatedStudentJobTrackingReport.trackingenddate"
                  caption="กำหนดวันที่สิ้นสุดการติดตามใหม่"
                  :min="selected_trackingenddate"
                  @closeAndUpdate="this.closeAndUpdateTextFieldDatePicker_TrackingEndDate"
                >
                </TextFieldDatePicker>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 justify-center d-flex">
                <v-btn @click="onCancelClick">ยกเลิก</v-btn>
                <v-btn
                  :loading="isInCreatingProcess"
                  @click="confirm_dialog = true"
                  class="mx-5 color-button2"
                >
                  ยืนยัน <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-dialog v-model="confirm_dialog" width="500">
              <template>
                <v-card class="pa-6">
                  <v-row class="justify-center text-center">
                    <v-card-title>
                      ยืนยันการขยายเวลาวันที่สิ้นสุดการติดตาม ?
                    </v-card-title>
                    <v-col cols="12">
                      <v-btn
                        @click="confirm_dialog = false"
                        class="ma-2"
                        elevation="2"
                        >ยกเลิก</v-btn
                      >
                      <v-btn @click="save()" class="ma-2 color-button2"> ยืนยัน </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-dialog>
          </v-card>
        </div>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { ExtendTrackingEndDate_Request } from "@/models/GraduatedStudentJobTrackingReport/ExtendTrackingEndDate_Request";
import TextFieldDatePicker from "../common/TextFieldDatePicker.vue";

import { formatDateThai } from "@/DateTimeHelper";

const jobTrackingDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  components: { TextFieldDatePicker },
  name: "ExtendTrackingEndDate",
  props: ["GraduatedStudentJobTrackingReport"],
  data() {
    return {
      selected_new_trackingEndDate: undefined,
      selected_trackingenddate: "",
      extendTrackingDate_Dialog: false,
      isInCreatingProcess: false,
      show: true,
      confirm_dialog: false,
    };
  },
  computed: {
    isBtnDialogDisabled() {
      if (this.GraduatedStudentJobTrackingReport == undefined) {
        return true;
      }
      if (
        this.GraduatedStudentJobTrackingReport.jobtracking_status_statuscode == "Verified"
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },

    closeAndUpdateTextFieldDatePicker_TrackingEndDate(selectedDate = "") {
      this.selected_new_trackingEndDate = selectedDate;
    },

    save() {
      const request = new ExtendTrackingEndDate_Request();
      request.graduatedstudentjobtrackingreport_oid = this.GraduatedStudentJobTrackingReport.oid;
      request.selected_new_trackingenddate = this.selected_new_trackingEndDate;

      jobTrackingDataProvider
        .extendTrackingEndDate(request)
        .then((result) => {
          this.$toast.success("บันทึกข้อมูลเปลี่ยนแปลง วันที่สิ้นสุดการติดตามเรียบร้อย");
          this.$emit(
            "closeExtendTrackingEndDateDialog",
            this.selected_new_trackingEndDate
          );
          this.extendTrackingDate_Dialog = false;
          this.graduatedStudentJobTrackingReport.trackingenddate = this.selected_new_trackingEndDate;
        })
        .catch((error) => {
          this.$toast.error(error.response.data);
        });
    },
    onCancelClick() {
      this.extendTrackingDate_Dialog = false;
      this.$emit("close_Dialog", false);
    },
  },
  async created() {
    jobTrackingDataProvider
      .loadGraduatedStudentJobTrackingReport(this.GraduatedStudentJobTrackingReport.oid)
      .then((result) => {
        this.graduatedStudentJobTrackingReport = result.data;
        this.selected_trackingenddate = this.GraduatedStudentJobTrackingReport.trackingenddate;
      });
  },
};
</script>

<style></style>
