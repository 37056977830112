import axios from "axios"
import { create_default_request_header } from "./constants"
import { createEndpointUrl } from "@/common/api_connectivity_helper"

import { GovernmentRecord } from "@/models/Government/GovernmentRecord"
export class GovernmentDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL
  }

  saveGovernmentRecord(vm = new GovernmentRecord()) {
    const header = create_default_request_header()
    const endpointUrl = "/api/Government/CreateGovernment/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.post(combinedURL, {
      governmentoid: vm.governmentoid,
      nameth: vm.nameth,
    }, {header})
  }

  editGovernmentRecord(vm) {
    const header = create_default_request_header()
    const endpointUrl = "/api/Government/UpdateGovernment/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.put(combinedURL, {
      oid: vm.oid,
      governmentoid: vm.governmentoid,
      nameth: vm.nameth,
    }, {header})
  }

  deleteGovernmentRecord(oid) {
    var header = create_default_request_header()
    var endpointUrl = "/api/Government/DaleteGovernment/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.delete(combinedURL, header)
  }

    updateGovernmentRecord(vm = new GovernmentRecord()) {
    const header = create_default_request_header()
    const endpointUrl = "/api/Government/UpdateGovernment/";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.post(combinedURL, vm, header)
  }

  getGovernmentRecords(oid = '') {
    const header = create_default_request_header()
    const endpointUrl = "/api/Government/GetGovernments/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

  getGovernmentRecord(oid = '') {
    const header = create_default_request_header()
    const endpointUrl = "/api/Government/GetGovernment/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

}
