export class CreateSchoolAdminProfile_Request {
  schoolid = ""
  oid = ""
  applicationuser_oid = ""
  titlenameid = ""
  name = ""
  lastname = ""
  gender = ""
  dateofbirth = ""
  position = ""
  email = ""
  phonenumber = ""
  idcard = ""
  isactive = undefined
  userphoto_base64string = ""

  username = ""
  password = ""
  confirm_password = ""

}
