<template>
    <v-container class="pa-5">
        <v-row class="pa-3 justify-space-between">
            <h4>สถาบันการอาชีวศึกษา</h4>
            <v-btn @click="importInstitutes" :loading="isImporting">นำเข้าข้อมูล</v-btn>
        </v-row>
        <SearchableDataTable v-if="(institutes != undefined)" table_caption="สถาบันการอาชีวศึกษา"
            :datatable_headers="institutes_data_table_headers" :data_items="institutes">
            <template v-slot:item.action="{ item }">
                <v-btn outlined @click="goToDetailView(item)">รายละเอียดสถาบันการอาชีวศึกษา</v-btn>
            </template>
        </SearchableDataTable>
    </v-container>
</template>
  
<script>
import { backendapiUrl } from '@/services/constants';
import { InstituteDataProvider } from '@/services/InstituteDataProvider';
import SearchableDataTable from '@/components/common/SearchableDataTable.vue';
var instituteDataProvider = new InstituteDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
    components: { SearchableDataTable },
    name: 'InstituteListView',
    data() {
        return {
            isImporting: false,
            institutesoid: '',
            institutes: undefined,
            institutes_data_table_headers: [
                { text: "email", value: "email" },
                { text: "fax", value: "fax" },
                { text: 'houseNumber', value: 'housenumber' },
                { text: "instituteCode", value: "institutecode" },
                { text: "instituteID", value: "instituteid" },
                { text: "instituteNameTh", value: "institutenameth" },
                { text: "soi", value: "soi" },
                { text: "street", value: "street" },
                { text: "telephone", value: "telephone" },
                { text: "villageNumber", value: "villagenumber" },
                { text: "website", value: "website" },
                { text: "zipcode", value: "zipcode" },
                { text: "", value: "action" , sortable: false},
            ],
        }
    },
    methods: {
        async importInstitutes() {
            this.isImporting = true;
            await instituteDataProvider.importInstitutes().then(result => {
                this.onImportInstitutesCompleted();
            }).catch(err => { 
                this.isImporting = false;
                alert(err); });
        },
        async loadInstitutes() {
            await instituteDataProvider.loadInstitutes().then(result => {
                this.institutes = result.data;
            }).catch(err => { alert(err); });
        },
        goToDetailView: function (item) {
            var instituteID = item.instituteid;
            this.$router.push({ name: "InstituteDetailView", params: { instituteID: instituteID } }).catch(err => { });
        },
        onImportInstitutesCompleted() {
            this.isImporting = false;
            this.loadInstitutes();
        }
    },
    async created() {
        await this.loadInstitutes();
    }
}
</script>
  
<style>

</style>
  
  