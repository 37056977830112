export class GraduatedStudentJobTrackingReportMain {
    yearth = ''
    semesters = ''
    trackingstartdate = ''
    trackingenddate = ''

    static fromJson(jsonObject = '') {
        var graduatedStudentJobTrackingReportMain = new GraduatedStudentJobTrackingReportMain();
        var convertedJson = JSON.stringify(jsonObject);
        graduatedStudentJobTrackingReportMain = JSON.parse(convertedJson);
        return graduatedStudentJobTrackingReportMain;
    }

    static toJson() {
        var json = JSON.stringify(this);
        return json;
    }
}

export class GraduatedStudentJobTrackingReport {

}

export class GraduatedStudentJobTrackingReport_StudentDetail {
    
}
