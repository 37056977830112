import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";

export class SearchStudentService {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  searchStudents(request) {
    const header = create_default_request_header();
    // console.log(header);
    const endpointUrl = "/api/SearchStudent/SearchStudents";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(
      combinedURL,
      {
        page: request.page,
        itemperpage: request.itemperpage,
        SortBy: [],
        SortDesc: [],
        provinceid: request.province,
        schoolcode: request.schoolid,
        gradelevel: request.gradelevel,
        majorcode: [request.majorcode],
        fulltime: request.fulltime,
        parttime: request.parttime,
        internship: request.internship,
        freelance: request.freelance,
        byday: request.byday,
        temporary: request.temporary,
        workfromhome: request.workfromhome,
        graduatedyear: request.graduateyear,
        gender: request.gender,
        lookingforjob: request.lookingforjob,
      },
      header
    );
  }
}
