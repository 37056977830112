import axios from "axios"
import { create_default_request_header } from "./constants"
import { createEndpointUrl } from "@/common/api_connectivity_helper"
export class VerifyManagerDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL
  }

  loadgetVerifyManagerList(schoolid) {
    var header = create_default_request_header()
    var endpointUrl = "/api/VerifyManager/GetVerifyManagers/" + schoolid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

  getVerifyManagers_ByCurrentSchoolEmployeeProfile(){
    const header = create_default_request_header()
    const endpointUrl = "/api/VerifyManager/GetVerifyManagers_ByCurrentSchoolEmployeeProfile";  
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

  loadgetVerifyManager(oid) {
    var header = create_default_request_header()
    var endpointUrl = "/api/VerifyManager/GetVerifyManager/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }

  createVerifyManager(manager) {
    var header = create_default_request_header()
    var endpointUrl = "/api/VerifyManager/CreateVerifyManager"
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.post(
      combinedURL,
      {
        schoolid: manager.schoolid,
        name: manager.name,
        position: manager.position,
        surname: manager.surname,
        titlename: manager.titlename,
        titlename_oid : manager.titlename_oid,
      },
      header
    )
  }

  updateVerifyManager(manager) {
    const header = create_default_request_header()
    const endpointUrl = "/api/VerifyManager/UpdateVerifyManager"
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.put(combinedURL,
      {
        oid: manager.oid,
        name: manager.name,
        position: manager.position,
        surname: manager.surname,
        titlename: manager.titlename,
        titlename_oid : manager.titlename_oid,
      }, header)
  }

  deleteVerifyManager(oid) {
    var header = create_default_request_header()
    var endpointUrl = "/api/VerifyManager/DaleteVerifyManager/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.delete(combinedURL, header)
  }
}
