<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="10" md="12" sm="12" xs="12">
          <v-card>
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >เพิ่มข้อมูลผู้ดูแลระบบ</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="py-6 px-12 d-flex justify-center">
              <v-col cols="10" class="my-7">
                <v-row class="">
                  <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                  <h3 class="primary--text">ข้อมูลบุคลากร</h3>
                </v-row>
                <v-row class="py-2" style="margin-top: 1%; margin-bottom: 2%">
                  <v-divider></v-divider>
                </v-row>
                <v-row>
                  <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      ชื่อจริง<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      required
                      filled
                      clearable
                      placeholder="ชื่อจริง"
                      :rules="nameRules"
                      v-model="vm.firstname"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      นามสกุล<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      required
                      filled
                      clearable
                      placeholder="นามสกุล"
                      :rules="nameRules"
                      v-model="vm.lastname"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-0 py-0">
                  <v-col lg="12" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      อีเมล<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      required
                      filled
                      clearable
                      ref="txtEmail"
                      placeholder="อีเมล"
                      :rules="custom_emailRules"
                      v-model="vm.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                  <h3 class="primary--text">เกี่ยวกับการใช้งาน</h3>
                </v-row>
                <v-row class="py-2" style="margin-top: 1%; margin-bottom: 2%">
                  <v-divider></v-divider>
                </v-row>
                <v-row class="">
                  <v-col cols="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      ชื่อผู้ใช้งาน (Username)<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      filled
                      clearable
                      ref="txtUsername"
                      placeholder="ชื่อบัญชีผู้ใช้ (username) *"
                      :rules="custom_usernameRules"
                      v-model="vm.username"
                      autocomplete="new-password"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      รหัสผ่าน (Password)<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      filled
                      autocomplete="new-password"
                      v-model="vm.password"
                      name="password"
                      :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isShowPassword = !isShowPassword"
                      :type="isShowPassword ? 'text' : 'password'"
                      :rules="passwordRules"
                      placeholder="รหัสผ่าน"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      ยืนยันรหัสผ่าน (Confirm Password)<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      filled
                      v-model="vm.confirm_password"
                      :append-icon="confirmisShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="confirmisShowPassword = !confirmisShowPassword"
                      :type="confirmisShowPassword ? 'text' : 'password'"
                      :rules="[
                        (v) => !!v || 'กรุณายืนยันรหัสผ่าน (Confirmpassword)',
                        (v) => v === vm.password || 'รหัสผ่านไม่ตรง',
                      ]"
                      name="confirmPassword"
                      placeholder="ยืนยันรหัสผ่าน"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>
          <v-col class="d-flex justify-end px-0">
            <v-btn @click="$router.go(-1)" class="mx-2"> ย้อนกลับ</v-btn>
            <v-btn class="color-button2" @click="save">
              บันทึก <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <!-- <v-container class="mt-3 mb-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="pa-4 mt-5">
        <div class="pa-3">
          <v-row>
            <v-col cols="12" class="d-flex justify-start">
              <h3 class="primary--text">
                <v-icon class="primary--text">mdi-account</v-icon>
                ข้อมูลผู้ดูแลระบบ
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field label="ชื่อ" v-model="vm.firstname"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="นามสกุล" v-model="vm.lastname"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Email" v-model="vm.email"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="ชื่อผู้ใช้งาน (username)"
                v-model="vm.username"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                type="password"
                label="รหัสผ่าน"
                v-model="vm.password"
                :rules="[
                  (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                  (v) => (v && v.length >= 6) || 'ต้องมีอย่างน้อย 6 ตัวอักษร',
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                type="password"
                label="ยืนยันรหัสผ่าน"
                v-model="vm.confirm_password"
                :rules="[
                  (v) => !!v || 'กรุณายืนยันรหัสผ่าน',
                  (v) => v === vm.password || 'รหัสผ่านไม่ตรง',
                ]"
              ></v-text-field>
              <p style="color: red" v-if="passwordMismatch">รหัสผ่านไม่ตรงกัน!</p>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-row class="justify-center text-center mt-4 mb-4">
        <v-col cols="12">
          <v-btn color="secondary" @click="save">
            <v-icon>mdi-content-save</v-icon> บันทึก
          </v-btn>
          <v-btn color="" @click="$router.go(-1)" class="ma-2 justify-center">
            ย้อนกลับ</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container> -->
</template>

<script>
import {
  usernameRules,
  passwordRules,
  emailRules,
  nameRules
} from "@/ValidationHelper";
import { AdministratorProfileDataProvider } from "@/services/AdministratorProfileDataProvider";
import { CreateAdministratorProfile_Request } from "@/models/AdministratorProfiles/CreateAdministratorProfile_Request";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const administratorProfileDataProvider = new AdministratorProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "CreateAdministratorProfileDetailView",
  data() {
    return {
      vm: new CreateAdministratorProfile_Request(),
      passwordMismatch: false,
      valid: true,
      nameRules: nameRules,
      emailRules: emailRules,
      passwordRules: passwordRules,
      usernameRules: usernameRules,
      isShowPassword: false,
      confirmisShowPassword: false,
    };
  },
  methods: {
    validatePassword() {
      if (this.vm.password != this.vm.confirm_password) {
        this.passwordMismatch = true;
      }
    },
    async save() {
      this.validatePassword();
      if (this.passwordMismatch == false) {
        await administratorProfileDataProvider
          .createAdministratorProfile(this.vm)
          .then((result) => {
            this.$store.dispatch({ type: "doSetIsLoadingProfile" });
            this.$toast.success("เพิ่มข้อมูลเรียบร้อย");
            this.$router.push("AdministratorProfileListView");
          });
      } else {
        this.$toast.error("กรุณาตรวจสอบข้อมูลให้ถูกต้อง");
      }
    },
  },
  computed: {
    custom_usernameRules() {
      return [
        ...usernameRules,
        (v) =>
          this.usernameExistResult === false || "ชื่อผู้ใช้งาน (username) ถูกใช้งานแล้ว",
      ];
    },
    custom_emailRules() {
      return [
        ...emailRules,
        (v) => this.emailExistResult === false || "อีเมลนี้ถูกใช้งานแล้ว",
      ];
    },
  },
  watch: {
    "vm.username": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isUsernameUsed(newVal).then((result) => {
            this.usernameExistResult = result.data;
            this.$refs.txtUsername.validate();
          });
        }
      },
      deep: true,
    },
    "vm.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isEmailUsed(newVal).then((result) => {
            this.emailExistResult = result.data;
            this.$refs.txtEmail.validate();
          });
        }
      },
      deep: true,
    }
  },
  async Created() {},
};
</script>

<style></style>
