export class CreateEmployerSummaryRequest 
{
    identityid  = ''
    nameth = '' 
    branchno  = ''
    address  = ''
    selected_province_id = ''
    dontknowidentityid = false

    generateFormData() {
        const formData = new FormData();
        formData.append("identityid", this.identityid);
        formData.append("nameth", this.nameth);
        formData.append("branchno", this.branchno);
        formData.append("address", this.address);
        formData.append("selected_province_id", this.selected_province_id);
        formData.append("dontknowidentityid", this.dontknowidentityid);

        return formData;
    }
}