<template>
  <div class="">
    <v-card color="white" variant="text">
      <v-toolbar class="mb-10 color-HeaderCard" color="primary" dark
        >ตัวอย่างการแสดงผล<v-spacer></v-spacer
        ><v-btn icon color="white" @click="closeDialog"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-toolbar
      >
      <div class="pa-5">
        <v-img
          height="450px"
          :src="'data:image/jpeg;base64,' + NewsContent.bannerthumbnailbase64"
          contain
          class="mb-3"
        ></v-img>
        <div class="post-date">
          <span class="day">{{ formattedDateday }}</span>
          <span class="month">{{ formattedDatemonth }}</span>
        </div>
        <v-card-title>
          {{ NewsContent.title }}
        </v-card-title>
        <v-card-subtitle class="mt-3">
          <svg-icon class="custom-icon" type="mdi" :path="pathcalen"></svg-icon>
          {{ formattedDate }} &nbsp; By<svg-icon
            class="custom-icon"
            type="mdi"
            :path="pathacc"
          ></svg-icon>
          {{ NewsContent.writerfullname }} &nbsp;
          <svg-icon class="custom-icon" type="mdi" :path="pathfolder"></svg-icon
          >{{ NewsContent.category?.name }} &nbsp;
          <svg-icon class="custom-icon" type="mdi" :path="pathtag"></svg-icon>
          <span v-for="(tag, index) in NewsTags" :key="index">
            {{ tag }}
            <template v-if="index !== NewsTags.length - 1">, </template>
          </span>
          &nbsp;
          <svg-icon class="custom-icon" type="mdi" :path="pathview"></svg-icon
          >{{ NewsContent.viewcouter }} viewer
          <br />

          <!-- <div id="dnn_ctr388_View_ArticleRatingDefault3888" class="rating">
                <div
                  id="dnn_ctr388_View_ctl01"
                  style="cursor: pointer; width: 100px"
                >
                  <br />
                  <img
                    :src="require('@/assets/star-score.png')"
                    alt="1"
                    title="Very Bad"
                    style="width: 15px"
                  />&nbsp;<img
                    :src="require('@/assets/star-score.png')"
                    alt="2"
                    title="Poor"
                    style="width: 15px"
                  />&nbsp;<img
                    :src="require('@/assets/star-score.png')"
                    alt="3"
                    title="Fair"
                    style="width: 15px"
                  />&nbsp;<img
                    :src="require('@/assets/star-score.png')"
                    alt="4"
                    title="Good"
                    style="width: 15px"
                  />&nbsp;<img
                    :src="require('@/assets/star-score.png')"
                    alt="5"
                    title="Excellent"
                    style="width: 15px"
                  />
                </div>
              </div> -->
        </v-card-subtitle>
        <v-card-text>
          <div
            class="ql-editor no-word-wrap"
            v-html="NewsContent.htmlcontent"
          ></div>

          <!-- <div class="Related-Articles">
          <h2>ข่าวที่เกี่ยวข้อง</h2>
          <RelatedArticles class="mb-5" :oid="oid" />
          <v-img
            width="30px"
            height="30px"
            style="float: left"
            :src="require('@/assets/logo.png')"
            cover
          ></v-img
          ><span class="text-face"> &nbsp;แชร์ไปยัง Facebook</span><br /><br />
        </div> -->
        </v-card-text>
      </div></v-card
    >
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiAccount,
  mdiFolderOpenOutline,
  mdiCalendarBlank,
  mdiTagMultiple,
  mdiEye,
} from "@mdi/js";

import { ref } from "vue";
import { backendapiUrl } from "@/services/constants";

// import RelatedArticles from "@/components/News/RelatedArticles.vue";
import { NewsDataProvider } from "@/services/NewsDataProvider";
const newsDataProvider = new NewsDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "NewsDetail",
  props: {
    oid: {
      type: String,
      required: true,
    },
    previewdialog: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    // RelatedArticles,
    SvgIcon,
  },
  data() {
    const date = ref();
    return {
      oid: "",
      host: "",
      NewsContent: [],
      NewsTags: [],
      itemlength: 0,
      pathacc: mdiAccount,
      pathcalen: mdiCalendarBlank,
      pathfolder: mdiFolderOpenOutline,
      pathtag: mdiTagMultiple,
      pathview: mdiEye,
      date,
    };
  },
  methods: {
    async loadNewDetail(oid = "") {
      await newsDataProvider
        .loadNewsDetail(oid)
        .then((result) => {
          this.oid = result.data.oid;
          this.NewsContent = result.data;
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadNewsTags(oid = "") {
      await newsDataProvider
        .getNewsTag(oid)
        .then((result) => {
          this.oid = result.data.oid;
          this.NewsTags = result.data;
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        });
    },
    closeDialog() {
      this.$emit("update:previewdialog", false);
    },
  },
  async created() {
    this.oid = this.$route.params.oid;
    this.host = backendapiUrl;
    await this.loadNewDetail(this.oid);
    await this.loadNewsTags(this.oid);
  },
  computed: {
    formattedDate() {
      if (!this.NewsContent) return "";
      const date = new Date(this.NewsContent.createddate);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleString("th-TH", options);
    },
    formattedDateday() {
      if (!this.NewsContent) return "";
      const date = new Date(this.NewsContent.createddate);
      const options = { day: "numeric" };
      return date.toLocaleString("th-TH", options);
    },
    formattedDatemonth() {
      if (!this.NewsContent) return "";
      const date = new Date(this.NewsContent.createddate);
      const options = { month: "long" };
      return date.toLocaleString("th-TH", options);
    },
  },
};
</script>

<style scoped>
.v-card {
  width: 100%;
  /* height: auto; */
}
h4 {
  color: #a5a5a5;
  margin-top: 20px;
  padding-bottom: 20px;
}
.v-img {
  border-radius: 20px;
  margin-bottom: 5%;
}
.v-card-title {
  color: rgb(33, 150, 243);
  font-size: 32px;
  white-space: normal;
  line-height: 1.2;
}
.v-card-subtitle,
.v-list-item-title,
.show-text-all {
  white-space: normal;
  overflow: auto;
}
.v-card-text {
  display: inline;
  color: rgb(0, 0, 0);
}

.v-card-title:hover,
.v-img:hover {
  cursor: pointer;
}
.v-card-title:hover {
  color: rgb(82, 159, 222);
}
.post-date {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  float: left;
  margin-right: 10px;
  text-align: center;
  margin-top: 10px;
}
.post-date .day {
  background: #f4f4f4;
  border-radius: 2px 2px 0 0;
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  color: rgb(82, 159, 222);
}
.post-date .month {
  background: rgb(82, 159, 222);
  border-radius: 0 0 2px 2px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07) inset;
  color: #fff;
  font-size: 0.9em;
  padding: 0 10px 2px;
}
.content-news h2 {
  font-weight: normal;
  margin: 20px 0 20px 0;
}
.Related-Articles h2 {
  font-weight: normal;
  margin: 30px 0 0 0;
}
.custom-icon {
  margin-bottom: -1%;
}
</style>
