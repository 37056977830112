var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"pa-4 my-4"},[_c('v-card',{staticClass:"elevation-2 pa-4"},[_c('h3',{staticClass:"primary--text"},[_vm._v("เปลี่ยนรหัสผ่าน")]),_c('v-divider',{staticClass:"mt-4 mb-4 mx-0"}),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"disabled":"","rules":[(v) => !!v || 'กรุณาระบุชื่อผู้ใช้'],"label":"ชื่อบัญชีผู้ใช้","prepend-icon":"mdi-account","required":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_c('v-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"รหัสผ่าน","name":"password","prepend-icon":"mdi-lock","type":"password","rules":[
                (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                (v) => (v && v.length >= 6) || 'ต้องมีอย่างน้อย 6 ตัวอักษร',
              ]},model:{value:(_vm.account.password),callback:function ($$v) {_vm.$set(_vm.account, "password", $$v)},expression:"account.password"}})],1),_c('v-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"ยืนยันรหัสผ่าน","rules":[
                (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                (v) => (v && v.length >= 6) || 'ต้องมีอย่างน้อย 6 ตัวอักษร',
                (v) => v === _vm.account.password || 'รหัสผ่านไม่ตรง',
              ],"name":"confirmPassword","prepend-icon":"mdi-lock","type":"password"},model:{value:(_vm.account.confirmPassword),callback:function ($$v) {_vm.$set(_vm.account, "confirmPassword", $$v)},expression:"account.confirmPassword"}})],1)],1),_c('v-row',{staticClass:"justify-center text-center"},[_c('v-col',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success"},on:{"click":_vm.submit}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check-circle-outline")]),_vm._v("ยืนยัน")],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"","text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("ย้อนกลับ")])],1)],1)],1)],1),_c('CustomSnackbar',{attrs:{"color":_vm.colorsnack,"text":_vm.textsnack,"snackbar":_vm.statussnack},on:{"close-snackbar":_vm.closesnackbar}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }