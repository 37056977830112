import JobPostingInfo from "@/views/๋JobPostings/JobPostingInfo.vue";
import JobApplicationDetailView from "@/views/๋JobPostings/JobApplicationDetailView.vue";
import JobApplicationInfo from "@/views/๋JobPostings/JobApplicationInfo.vue";
import JobApplicationJobPostingDetailView from "@/views/๋JobPostings/JobApplicationJobPostingDetailView.vue";
import CenterStaffJobPostingListView from "@/views/๋JobPostings/CenterStaffJobPostingListView.vue";
import CenterStaffJobPostingDetailView from "@/views/๋JobPostings/CenterStaffJobPostingDetailView.vue";
import CenterStaffJobApplicationListView from "@/views/๋JobPostings/CenterStaffJobApplicationListView.vue";
import CenterStaffJobApplicationDetailView from "@/views/๋JobPostings/CenterStaffJobApplicationDetailView.vue";

export default [
  {
    path: "/JobPosting",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/JobPosting/JobPostingInfo/:oid",
        name: "JobPostingInfo",
        component: JobPostingInfo,
        meta: {
          requiresAuth: true,
          nameTh: "ประกาศงาน",
        },
      },
      {
        path: "/JobPosting/JobApplicationDetailView/:oid",
        name: "JobApplicationDetailView",
        component: JobApplicationDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดใบสมัครงาน",
        },
      },

      {
        path: "/JobPosting/JobApplicationInfo/:oid",
        name: "JobApplicationInfo",
        component: JobApplicationInfo,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดใบสมัครงาน",
        },
      },
      {
        path: "/JobPosting/JobApplicationJobPostingDetailView/:oid",
        name: "JobApplicationJobPostingDetailView",
        component: JobApplicationJobPostingDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดประกาศงาน",
        },
      },
      {
        path: "/JobPosting/CenterStaffJobPostingListView",
        name: "CenterStaffJobPostingListView",
        component: CenterStaffJobPostingListView,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลประกาศงาน",
        },
      },
      {
        path: "/JobPosting/CenterStaffJobPostingDetailView/:oid",
        name: "CenterStaffJobPostingDetailView",
        component: CenterStaffJobPostingDetailView,
        meta: {
          nameTh: "รายละเอียดข้อมูลประกาศงาน",
        },
      },
      {
        path: "/JobPosting/CenterStaffJobApplicationListView",
        name: "CenterStaffJobApplicationListView",
        component: CenterStaffJobApplicationListView,
        meta: {
          nameTh: "ข้อมูลการใบสมัครงาน",
        },
      },
      {
        path: "/JobPosting/CenterStaffJobApplicationDetailView/:oid",
        name: "CenterStaffJobApplicationDetailView",
        component: CenterStaffJobApplicationDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดใบสมัครงาน",
        },
      },
    ],
  },
];
