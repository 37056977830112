import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
import { SaveInterestedInStudentBookmark_Request } from '@/models/EmployerUserbookmark/SaveInterestedInStudentBookmark_Request'
 export  class EmployerUserbookmarkDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    saveInterestedInStudentBookmark(request = new SaveInterestedInStudentBookmark_Request()) {
        var header = create_default_request_header()
        var endpointUrl = "/api/EmployerUserbookmark/SaveInterestedInStudentBookmark";
        var data = JSON.stringify(request);
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.post(combinedURL,data, header)
    }

    loadInterestedInStudentBookmarksAsStudentData() {
        var header = create_default_request_header()
        var endpointUrl = "/api/EmployerUserbookmark/GetInterestedInStudentBookmarks_As_StudentData"
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.get(combinedURL, header)
    }

    loadGetInterestedInStudentBookmarks() {
        var header = create_default_request_header()
        var endpointUrl = "/api/EmployerUserbookmark/GetInterestedInStudentBookmarks"
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.get(combinedURL, header)   
    }

    deleteInterestedInStudentBookmark(request = new SaveInterestedInStudentBookmark_Request()) {
        var header = create_default_request_header()
        var endpointUrl = "/api/EmployerUserbookmark/DeleteInterestedInStudentBookmark"
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        var data = JSON.stringify(request);
        return axios.post(combinedURL,data, header)
    }

    isStudentExistInBookmark(student_oid = ''){
        var header = create_default_request_header()
        var endpointUrl = "/api/EmployerUserbookmark/IsStudentExistInBookmark/" + student_oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return axios.post(combinedURL, null, header)
    }
}