<template>
    <div>
        <v-btn :disabled="isBtnDisabled" color="success" class="mr-2 mb-4" @click="onSubmit()"><v-icon>mdi-check-circle</v-icon>
            ประกาศรับสมัครงาน</v-btn>
    </div>
</template>

<script>
import { EmployerJobPostingDataProvider } from '@/services/EmployerJobPostingDataProvider'
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
    name: 'Btn_SetJobPostingStatus_Active',
    props: ['JobPosting'],
    data() {
        return {

        }
    },
    computed: {
        isBtnDisabled() {
            if (this.JobPosting.jobpostingstatus.statuscode == 'Active' ||
                this.JobPosting.jobpostingstatus.statuscode == 'Closed' ||
                this.JobPosting.jobpostingstatus.statuscode == 'Cancel'
                ) {
                return true;
            }
            return false;
        }
    },
    methods: {
        async onSubmit() {
            if (confirm('ยืนยัน?')) {
                const oid = this.JobPosting.oid;
                await employerJobPostingDataProvider.setJobPosting_ActiveStatus(oid).then(result => {
                    this.$toast.success('ประกาศรับสมัครงานเรียบร้อย');
                    this.$emit('finished');
                    this.$router.push("/Employer/EmployerJobPostingListView_InProcess");
                });
            }
        }
    }
}
</script>

<style></style>