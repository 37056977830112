import SchoolGraduatedStudentListView from "@/views/GraduatedStudent/SchoolGraduatedStudentListView";
import GraduatedStudentListView from "@/views/GraduatedStudent/GraduatedStudentListView.vue";
import CreateSchoolGraduatedStudentView from "@/views/GraduatedStudent/CreateSchoolGraduatedStudentView";
import SchoolGraduatedStudentDetailView from "@/views/GraduatedStudent/SchoolGraduatedStudentDetailView";

export default [
  {
    path: "/graduatedstudent",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/graduatedstudent/schoolgraduatedstudentlistview",
        name: "SchoolGraduatedStudentListView",
        component: SchoolGraduatedStudentListView,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลนักศึกษาที่สำเร็จการศึกษา",
        },
      },
      {
        path: "/graduatedstudent/CreateSchoolGraduatedStudentView",
        name: "CreateSchoolGraduatedStudentView",
        component: CreateSchoolGraduatedStudentView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/graduatedstudent/SchoolGraduatedStudentDetailView/:schoolOid/:graduatedYearTH",
        name: "SchoolGraduatedStudentDetailView",
        component: SchoolGraduatedStudentDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "นักศึกษาที่สำเร็จการศึกษา",
        },
      },
      {
        path: "/graduatedstudent/GraduatedStudentListView/:schoolid",
        name: "GraduatedStudentListView",
        component: GraduatedStudentListView,
        meta: {
          requiresAuth: true,
          nameTh: "นักศึกษาที่สำเร็จการศึกษา",
        },
      },
    ],
  },
];
