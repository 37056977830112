import StudentListView from "@/views/Student/StudentListView.vue";
import Student_ListView_SchoolEmployee from "@/views/Student/Student_ListView_SchoolEmployee.vue";
import RegisteredJobPostingListView from "@/views/Student/RegisteredJobPostingListView.vue";
import StudentEditProfileView from "@/views/Student/StudentEditProfileView.vue";
import StudentSearchJobPostingView from "@/views/Student/StudentSearchJobPostingView.vue";
import StudentSearchJobPosting_Argent_View from "@/views/Student/StudentSearchJobPosting_Argent_View.vue";
import StudentSearchJobPosting_Monthly_View from "@/views/Student/StudentSearchJobPosting_Monthly_View.vue";
import StudentAndEmployerInteractions from "@/views/Student/StudentAndEmployerInteractions.vue";
import StudentBookmarkedJobPostings_ListView from "@/views/Student/StudentBookmarkedJobPostings_ListView.vue";
import StudentTrackCurrentJobApplicationsView from "@/views/Student/StudentTrackCurrentJobApplicationsView.vue";
export default [
  {
    path: "/Student",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Student/StudentEditProfileView/:oid",
        name: "StudentEditProfileView",
        component: StudentEditProfileView,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลส่วนตัว",
        },
      },

      {
        path: "/Student/StudentListView/:schoolID",
        name: "StudentListView",
        component: StudentListView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Student/Student_ListView_SchoolEmployee",
        name: "Student_ListView_SchoolEmployee",
        component: Student_ListView_SchoolEmployee,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Student/StudentListViewExcel",
        name: "StudentListViewExcel",
        component: require("@/views/Student/StudentListViewExcel.vue").default,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Student/RegisteredJobPostingListView",
        name: "RegisteredJobPostingListView",
        component: RegisteredJobPostingListView,
        meta: {
          requiresAuth: true,
          nameTh: "ตำแหน่งงานที่สมัคร",
        },
      },
      {
        path: "/Student/StudentSearchJobPostingView",
        name: "StudentSearchJobPostingView",
        component: StudentSearchJobPostingView,
        meta: {
          requiresAuth: true,
          nameTh: "ค้นหาตำแหน่งงาน/ฝึกงาน",
        },
      },
      {
        path: "/Student/StudentSearchJobPosting_Argent_View",
        name: "StudentSearchJobPosting_Argent_View",
        component: StudentSearchJobPosting_Argent_View,
        meta: {
          requiresAuth: true,
          nameTh: "ค้นหาตำแหน่งงาน/ฝึกงาน (ด่วน)",
        },
      },
      {
        path: "/Student/StudentSearchJobPosting_Monthly_View",
        name: "StudentSearchJobPosting_Monthly_View",
        component: StudentSearchJobPosting_Monthly_View,
        meta: {
          requiresAuth: true,
          nameTh: "ค้นหาตำแหน่งงาน/ฝึกงาน (ประจำเดือน)",
        },
      },
      {
        path: "/Student/StudentAndEmployerInteractions",
        name: "StudentAndEmployerInteractions",
        component: StudentAndEmployerInteractions,
        meta: {
          requiresAuth: true,
          nameTh: "การติดต่อจากสถานประกอบการ",
        },
      },
      {
        path: "/Student/StudentBookmarkedJobPostings_ListView",
        name: "StudentBookmarkedJobPostings_ListView",
        component: StudentBookmarkedJobPostings_ListView,
        meta: {
          requiresAuth: true,
          nameTh: "ประกาศงานที่สนใจ",
        },
      },

      {
        path: "/Student/StudentTrackCurrentJobApplicationsView",
        name: "StudentTrackCurrentJobApplicationsView",
        component: StudentTrackCurrentJobApplicationsView,
        meta: {
          nameTh: "ติดตามสถานะประกาศงานที่ยื่น",
        },
      },
    ],
  },
];
