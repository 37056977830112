<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" class="d-flex justify-start">
        <v-btn color="primary" @click="gotoEmployerCreateJobPostingView()"
          ><v-icon>mdi-plus</v-icon> เพิ่มประกาศรับสมัครงาน</v-btn
        >
      </v-col>
    </v-row>
    <SearchableDataTable
      table_caption="ประกาศรับสมัครงาน"
      :datatable_headers="employerJobPostings_data_table_headers"
      :data_items="employerJobPostings"
    >
      <template v-slot:item.quantity="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <a v-bind="attrs" v-on="on" href="">0/{{ item.quantity }}</a>
          </template>
          <span>รายละเอียดผู้สมัคร</span>
        </v-tooltip>
      </template>
      <template v-slot:item.enddate="{ item }">
        {{ moment(item.enddate).add(543, "year").locale("TH").format("LL") }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 'ใช้งาน'" class="success--text">{{
          item.status
        }}</span>
        <span v-if="item.status == 'ไม่ใช้งาน'" class="red--text">{{ item.status }}</span>
      </template>
      <template v-slot:item.poststatus="{ item }">
        <h4 class="success--text">{{ item.poststatus }}</h4>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openEditEmployerJobPostingView(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file-edit</v-icon>
            </v-btn>
          </template>
          <span>แก้ไขประกาศรับสมัครงาน</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="(dialog = true), (selectedjobposting = item)"
              color="error"
              v-bind="attrs"
              v-on="on"
              icon
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <span>ลบ</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h6"> ต้องการลบการรับสมัครงานหรือไม่? </v-card-title>
        <v-card-actions class="center">
          <v-btn dark color="green lignten-1" text @click="deleteEmployerJobPosting">
            ตกลง (ทำการลบการรับสมัครงาน)
          </v-btn>
          <v-btn text @click="dialog = false"> ยกเลิก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import moment from "moment";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
// var studentDataProvider = new StudentDataProvider(
//   process.env.VUE_APP_BACKEND_API_URL
// )
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "JobPostedListView",
  data() {
    return {
      moment: moment,
      employerJobPostings: undefined,
      employerJobPostings_data_table_headers: [
        { text: "รหัสประกาศงาน", value: "jobposting_id" },
        { text: "ตำแหน่งงาน", value: "positionname" },
        { text: "อัตราที่รับสมัคร", value: "unit" },
        { text: "ลักษณะงาน", value: "selected_employmentcategory_code" },
        { text: "ระดับชั้น", value: "selected_applicant_grade_code" },
        { text: "สิ้นสุดประกาศ", value: "enddate" },
        { text: "สถานะ", value: "status" },
        { text: "ประกาศ", value: "poststatus" },
        { text: "", value: "action" },
      ],
      dialog: false,
      selectedjobposting: [],
    };
  },
  methods: {
    async loadEmployerJobPostings() {
      await employerJobPostingDataProvider.loadEmployerJobPostings().then((result) => {
        this.employerJobPostings = result.data;
      });
    },
    openEditEmployerJobPostingView(item) {
      var oid = item.oid;
      this.$router.push({ name: "EmployerUpdateJobPostingView", params: { oid } });
    },
    async deleteEmployerJobPosting(item) {
      if (confirm("ยืนยัน")) {
        await employerJobPostingDataProvider
          .deleteEmployerJobPosting(oid)
          .then((result) => {
            this.loadEmployerJobPostings();
          });
      }
    },

    gotoEmployerCreateJobPostingView() {
      this.$router.push("EmployerCreateJobPostingView");
    },
  },
  async created() {
    await this.loadEmployerJobPostings();
  },
};
</script>

<style></style>
