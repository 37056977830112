<template>
  <div>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-form>
      <v-row class="d-flex justify-center py-5">
        <v-col cols="10">
          <div id="element-to-convert">
            <v-card
              style="border-radius: 15px"
              class="mt-5 d-flex justify-center"
            >
              <v-col cols="10" class="">
                <v-card-text>
                  <v-row class="py-5" justify="center">
                    <v-col cols="12" lg="3" md="12">
                      <v-row
                        v-if="logo_photobase64"
                        justify="center"
                        align-self="center"
                      >
                        <v-img
                          alt="test"
                          class="shrink fill-height"
                          contain
                          transition="scale-transition"
                          aspect-ratio="1/1"
                          cover
                          v-bind:lazy-src="
                            'data:image/jpg;base64,' + logo_photobase64
                          "
                          v-bind:src="
                            'data:image/jpg;base64,' + logo_photobase64
                          "
                        />
                      </v-row>
                      <v-row v-else justify="center" align-self="center">
                        <v-img
                          alt="test"
                          class="shrink fill-height"
                          contain
                          transition="scale-transition"
                          aspect-ratio="1/1"
                          cover
                          :src="require(`@/assets/user-img.jpg`)"
                        />
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="9" md="12" class="px-5">
                      <v-row
                        class="pb-2"
                        align="center"
                        justify="center"
                        justify-lg="start"
                      >
                        <v-col class="pa-0" cols="auto">
                          <v-list-item-title class="text-h5 font-weight-bold">
                            {{ jobapplication.student.firstname }}
                            {{ jobapplication.student.surname }}
                          </v-list-item-title>
                        </v-col>
                      </v-row>

                      <v-row
                        v-if="jobapplication.student.birthday"
                        justify="center"
                        justify-lg="start"
                      >
                        <v-col class="pa-0 ma-0" cols="auto">
                          <label class="pr-2"
                            >{{
                              moment(jobapplication.student.birthday)
                                .add(543, "year")
                                .locale("th")
                                .format("DD MMMM YYYY")
                            }}
                            (
                            {{ calculateAge(jobapplication.student.birthday) }}
                            ปี )
                          </label>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="jobapplication.student.tel"
                        justify="center"
                        justify-lg="start"
                      >
                        <v-col class="pa-0 ma-0" cols="auto">
                          <v-icon>mdi-phone</v-icon>
                          <label class="ml-1">{{
                            jobapplication.student.tel
                          }}</label>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="jobapplication.student.email"
                        justify="center"
                        justify-lg="start"
                      >
                        <v-col class="pa-0 ma-0" cols="auto">
                          <v-icon>mdi-email</v-icon>
                          <label class="ml-1">{{
                            jobapplication.student.email
                          }}</label>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="pt-2 px-6">
                    <v-icon class="pr-2" color="primary"
                      >mdi-account-box</v-icon
                    >
                    <h3 class="primary--text" style="font-weight: bold">
                      ข้อมูลส่วนตัว
                    </h3>
                  </v-row>
                  <v-row
                    class="pl-6 pr-6 pt-1"
                    style="margin-top: 2%; margin-bottom: 2%"
                  >
                    <v-divider></v-divider>
                  </v-row>

                  <v-row class="pl-6 pr-6">
                    <v-col>
                      <v-row class="pb-5">
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">รหัสนักศึกษา</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.studentcode
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">วุฒิการศึกษา</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.gradenameth
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">เกรดเฉลี่ย</h4>
                          </v-row>
                          <v-row>
                            <span>{{ jobapplication.student.gpax }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">เพศ</h4>
                          </v-row>
                          <v-row>
                            <label
                              v-if="this.jobapplication.student.gender == 'F'"
                              >หญิง</label
                            >
                            <label
                              v-if="this.jobapplication.student.gender == 'M'"
                              >ชาย</label
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="pb-5">
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">สัญชาติ</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.nationality.nationalityth
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">ศาสนา</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.religion.religionth
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">ส่วนสูง</h4>
                          </v-row>
                          <v-row>
                            <span
                              >{{
                                jobapplication.student.hight
                                  ? jobapplication.student.hight
                                  : "-"
                              }}
                              ซม.</span
                            >
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">น้ำหนัก</h4>
                          </v-row>
                          <v-row>
                            <span
                              >{{
                                jobapplication.student.weight
                                  ? jobapplication.student.weight
                                  : "-"
                              }}
                              กก.</span
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="pb-5">
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">สถานภาพทางทหาร</h4>
                          </v-row>
                          <v-row>
                            <!-- <label
                              v-if="this.jobapplication.student.military == '0'"
                            ></label> -->
                            {{
                              getMilitaryStatus(
                                this.jobapplication.student.military
                              )
                            }}
                          </v-row>
                        </v-col>
                        <!-- <v-col cols="3">
                          <v-row>
                            <h4 class="primary--text">สถานะความพิการ</h4>
                          </v-row>
                          <v-row>
                            <label class=""
                              >{{ jobapplication.student.disability }}
                            </label>
                          </v-row>
                        </v-col> -->
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">สถานภาพ</h4>
                          </v-row>
                          <v-row>
                            <label class=""
                              >{{
                                getMaritalStatus(
                                  this.jobapplication.student.marital
                                )
                              }}
                            </label>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row class="pt-5 pl-6 pr-6">
                    <v-icon class="pr-2" color="primary">mdi-map-marker</v-icon>
                    <h3 class="primary--text" style="font-weight: bold">
                      ที่อยู่
                    </h3>
                  </v-row>
                  <v-row
                    class="pl-6 pr-6 pt-1"
                    style="margin-top: 2%; margin-bottom: 2%"
                  >
                    <v-divider></v-divider>
                  </v-row>

                  <v-row class="pl-6 pr-6">
                    <v-col>
                      <v-row class="pb-5">
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">เลขที่อยู่</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.housenumber
                                ? jobapplication.student.housenumber
                                : "-"
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">หมู่ที่</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.moo
                                ? jobapplication.student.moo
                                : "-"
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">ซอย</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.soi
                                ? jobapplication.student.soi
                                : "-"
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">ถนน</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.street
                                ? jobapplication.student.street
                                : "-"
                            }}</span>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="pb-5">
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">แขวง / ตำบล</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.tambon.tambonthai
                            }}</span>
                          </v-row>
                        </v-col>

                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">อำเภอ / เขต</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.tambon.district
                                .districtthaishort
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">จังหวัด</h4>
                          </v-row>
                          <v-row>
                            <span>{{
                              jobapplication.student.tambon.district.province
                                .provincethai
                            }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" xs="12">
                          <v-row>
                            <h4 class="primary--text">รหัสไปรษณีย์</h4>
                          </v-row>
                          <v-row>
                            <span
                              >{{ jobapplication.student.tambon.postcodemain }}
                            </span>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-btn
                          outlined
                          rounded
                          width="100%"
                          @click="moreInformation()"
                          style="font-weight: bold"
                          ><v-icon class="mr-1">mdi-account-search</v-icon>
                          ข้อมูลนักศึกษาเพิ่มเติม</v-btn
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text></v-col
              >
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { JobApplicationDataProvider } from "@/services/JobApplicationDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
import moment from "moment";
import FormatDate from "@/components/common/FormatDate.vue";

const jobApplicationDataProvider = new JobApplicationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

import JobApplicatiionStatusManagement from "./JobApplicatiionStatusManagement.vue";
import { MaritalStatus, MilitaryStatus } from "@/services/EnumHelper";

export default {
  name: "JobApplicationDetailView",
  components: { JobApplicatiionStatusManagement, FormatDate },
  data() {
    return {
      jobapplication_oid: "",
      jobapplication: undefined,
      jobapplicationentitystatuses: undefined,
      selected_jobapplicationentitystatus_code: "",
      logo_photobase64: "",
      moment: moment,
      militaryStatus: "",
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider
        .getJobApplicationEntityStatuses()
        .then((result) => {
          this.jobapplicationentitystatuses = result.data;
        });

      await jobApplicationDataProvider
        .getJobApplication(this.jobapplication_oid)
        .then((result) => {
          this.jobapplication = result.data;
          this.selected_jobapplicationentitystatus_code =
            this.jobapplication.jobapplicationstatus.statuscode;
          this.logo_photobase64 = result.data.student.base64thrumnail;
        });
      console.log(this.jobapplication, "DATA");
    },
    calculateAge(birthday) {
      if (!birthday) return "";
      console.log(birthday);
      const birthdayDate = moment(birthday, "DD MMMM YYYY");
      const age = moment().diff(birthdayDate, "years");
      return age;
    },
    getMilitaryStatus(statusValue) {
      const status = MilitaryStatus.find(
        (status) => status.value === statusValue
      );
      return status ? status.nameTH : "ไม่ทราบสถานะ";
    },
    getMaritalStatus(statusValue) {
      const status = MaritalStatus.find(
        (status) => status.value === statusValue
      );
      return status ? status.nameTH : "ไม่ทราบสถานะ";
    },
    moreInformation() {
      var oid = this.jobapplication?.student?.oid;
      console.log(oid);
      this.$router.push({
        name: "StudentDetailOverview",
        params: { studentoid: oid },
      });
    },
  },
  mounted() {},
  async created() {
    if (this.$route.params.oid != undefined) {
      this.jobapplication_oid = this.$route.params.oid;
      await this.initialize();
    }
  },
};
</script>

<style scoped>
.shrink {
  border-radius: 200px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 10px rgba(15, 15, 15, 0.5);
  border: 1px solid grey;
}
</style>
