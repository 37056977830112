<template>
  <label>
    {{
      moment(date_value).add(543, "year").locale("TH").format("D MMM YY")
    }}</label
  >
</template>

<script>
import moment from "moment";
export default {
  name: "FormatDate",
  data() {
    return {
      moment: moment,
      local_isDisplay: this.isDisplay,
      local_value: this.value,
      local_min: this.min,
    };
  },
  watch: {
    value: {
      handler(val, oldVal) {
        this.local_value = val;
      },
    },
  },
  props: ["date_value"],
  methods: {},
  mounted() {},
};
</script>

<style></style>
