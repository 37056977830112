import SearchStudentDetails from "@/views/SearchStudent/SearchStudentDetails.vue";

export default [
  {
    path: "/SearchStudent",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/SearchStudent/SearchStudentDetails/:student_oid",
        name: "SearchStudentDetails",
        component: SearchStudentDetails,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดนักศึกษา",
        },
      },
    ],
  },
];
