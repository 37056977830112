<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2">mdi-file-document-edit-outline</v-icon
            >ศาสนา</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="religionsDetail.religionid"
                label="รหัสศาสนา"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="religionsDetail.religionth"
                label="ชื่อศาสนา (ไทย)"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="religionsDetail.religioneng"
                label="ชื่อศาสนา (อังกฤษ)"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "ReligionsDetailView",
  data() {
    return {
      religionOid: "",
      religionsDetail: {},
    };
  },

  methods: {
    async loadReligionDetails() {
      await masterDataProvider
        .getReligionByOid(this.religionOid)
        .then((result) => {
          this.religionsDetail = result.data;
          console.log(this.religionsDetail, "DETAIL");
        })
        .catch((err) => {
          alert(err);
        });
    },
  },

  async created() {
    this.religionOid = this.$route.params.oid;
    await this.loadReligionDetails();
  },
};
</script>
<style></style>
