<template>
<div class="container-fluid pa-5">
    <div class="row justify-end ma-3 p-3">
       <button @click="exportAsExcel()">Export excel</button> 
    </div>
    <div >
    <vue-excel-editor ref="grid"  v-model="jsonData" filter-row width="100%" @update="onExcelEditorUpdate" >
        <vue-excel-column field="firstname"   label="firstname"  width="250px"  readonly type="string"  />
        <vue-excel-column field="surname"   label="surname"    width="250px"  readonly type="string"  />
        <vue-excel-column field="idcard"  key-field label="idcard" width="250px"  readonly type="string"  />
        <vue-excel-column field="email" label="email"    width="250px"  type="select" :options="emailList" />
    </vue-excel-editor>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import 'vue-excel-editor' 
import axios from 'axios';
export default {
    name: "StudentListViewExcel",
    data() {
        return {
            jsonData: [],
            emailList :['xxxx','aaaaa','bbbbb']
        };
    },
    methods: {
        loadJsonData: function () {
            const headers = { "Content-Type": "application/json" };
            axios.post("/api/student/getstudents", headers)
                .then(response => {
                    this.jsonData = response.data;
            });
        },
        exportAsExcel () {
            const format = 'xlsx'
            const exportSelectedOnly = true
            const filename = 'student'
            this.$refs.grid.exportTable(format,exportSelectedOnly, filename)
        },
        onExcelEditorUpdate (records) {
            let updatedRecord = records.map((rec) => { return {cardid : rec.keys[0], fieldname : rec.name, value : rec.newVal }});
            this.updateStudent(updatedRecord[0]);
        },
        updateStudent(updatedRecord){
            let data = JSON.stringify({
                cardid: updatedRecord.cardid,
                fieldname: updatedRecord.fieldname,
                value: updatedRecord.value
            });
            const headers = { "Content-Type": "application/json" };
            axios.post("/api/student/updatestudent", data, headers)
        }
    },
    mounted() {
        this.loadJsonData();
    },
}
</script>

