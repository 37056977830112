GraduatedStudentJobTrackingReportDetailView<template>
  <div>
    <div class="d-flex">
      <v-text-field
        :value="value"
        @input="updateValue"
        :placeholder="text_name"
        :prepend-inner-icon="front_icon"
        :rules="rules"
        filled
        background-color="input"
      >
      </v-text-field>
      <v-btn style="width: 50px; height: 50px" icon @click="show = !show"
        ><v-icon style="font-size: 30px" color="grey-lighten-2">
          {{ back_icon }}
        </v-icon></v-btn
      >
    </div>
    <v-expand-transition>
      <div v-show="show">
        <span class="pl-1 pt-0 error--text">
          {{ text_warn }}
        </span>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: "WarningTextbox",
  props: [
    "front_icon",
    "text_name",
    "back_icon",
    "text_warn",
    "value",
    "rules",
  ],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
  created() {},
};
</script>

<style></style>
