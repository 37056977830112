import { ApplicationRoleCodes, MenuProfileNames, RoleCodes } from "@/Constants";
import { NavigationItems } from "./NavigationItems";
import { addNavigationItemIfNotExisting } from "./NavigationItemsCreatorHelper";
import { NavigationItemStructure } from "./NavigationItemStructure";
import store from "@/store";
export class NavigationItemsCreatorService {
  constructor(userRoleCodes = []) {
    this.userRoleCodes = userRoleCodes;
    this.NavigationItems = [];
  }

  NavigationItems = [];

  Create_Administrator_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(NavigationItems.School_Nav_SchoolListView);
    navigationItems.push(NavigationItems.Search_Nav_SearchStudent);
    navigationItems.push(
      NavigationItems.SchoolEmployee_Nav_SchoolEmployer_Listview
    );
    navigationItems.push(
      NavigationItems.SchoolGraduatedStudent_Nav_SchoolGraduatedStudent_ListView
    );

    navigationItems.push(NavigationItems.Notification_Nav_CommonNotification);

    navigationItems.push(NavigationItems.Employer_Nav_EmployerProfileListView);
    navigationItems.push(
      NavigationItems.Center_Nav_CenterStaffJobPostingListView
    );
    navigationItems.push(
      NavigationItems.Center_Nav_CenterStaffJobApplicationListView
    );
    navigationItems.push(
      NavigationItems.EmployerSummary_Nav_EmployerSummaryListView
    );
    navigationItems.push(
      NavigationItems.GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportMainListView
    );

    navigationItems.push(NavigationItems.MasterData_Nav_ProvinceListView);
    navigationItems.push(NavigationItems.MasterData_Nav_CurriculumListView);
    navigationItems.push(NavigationItems.MasterData_Nav_MajorListView);
    navigationItems.push(NavigationItems.MasterData_Nav_MinorListView);
    navigationItems.push(NavigationItems.MasterData_Nav_SubjectTypeListView);
    navigationItems.push(NavigationItems.MasterData_Nav_SalaryrangesListView);
    navigationItems.push(
      NavigationItems.MasterData_Nav_EmployerMainBusinessTypesListView
    );
    navigationItems.push(NavigationItems.MasterData_Nav_TitleNamesListView);
    navigationItems.push(NavigationItems.MasterData_Nav_NationalitiesListView);
    navigationItems.push(NavigationItems.MasterData_Nav_ReligionsListView);
    navigationItems.push(NavigationItems.MasterData_Nav_JobSegmentListView);

    navigationItems.push(
      NavigationItems.Setup_Nav_DefaultJobPostingSetupDetailView
    );
    navigationItems.push(
      NavigationItems.Setup_Nav_EmailConfigurationDetailView
    );
    navigationItems.push(NavigationItems.Setup_Nav_NotifySetup);

    navigationItems.push(NavigationItems.UserRole_Nav_ProfileOverview);
    navigationItems.push(
      NavigationItems.UserRole_Nav_AdministratorProfileListView
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_CenterAdmin
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_Center_Manager
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_School_Manager
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_School_Admin
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_School_Guidance_Teacher
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_Center_Officer
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_Insitution_Officer
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_ProvinceSchool_Officer
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_School_Partner_Officer
    );

    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);

    navigationItems.push(NavigationItems.NewsManagementListView);

    return navigationItems;
  }

  Create_Center_Admin_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );

    navigationItems.push(NavigationItems.School_Nav_SchoolListView);
    navigationItems.push(NavigationItems.Search_Nav_SearchStudent);
    navigationItems.push(NavigationItems.Employer_Nav_EmployerProfileListView);
    navigationItems.push(
      NavigationItems.Center_Nav_CenterStaffJobPostingListView
    );
    navigationItems.push(
      NavigationItems.Center_Nav_CenterStaffJobApplicationListView
    );
    navigationItems.push(
      NavigationItems.EmployerSummary_Nav_EmployerSummaryListView
    );
    navigationItems.push(
      NavigationItems.GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportMainListView
    );
    navigationItems.push(NavigationItems.UserRole_Nav_ProfileOverview);
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_CenterAdmin
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_Center_Manager
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_School_Admin
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_School_Guidance_Teacher
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_Center_Officer
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_Insitution_Officer
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_ProvinceSchool_Officer
    );
    navigationItems.push(
      NavigationItems.UserRole_Nav_SchoolEmployeeProfileListView_School_Partner_Officer
    );
    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);
    navigationItems.push(NavigationItems.NewsManagementListView);

    return navigationItems;
  }

  Create_Center_Manager_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );

    navigationItems.push(
      NavigationItems.EmployerSummary_Nav_EmployerSummaryListView
    );

    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);

    navigationItems.push(NavigationItems.NewsManagementListView);

    return navigationItems;
  }

  Create_School_Manager_NavigationStructures() {
    let navigationItems = [];

    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );
    navigationItems.push(NavigationItems.School_Nav_StudentListView);
    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);

    navigationItems.push(NavigationItems.NewsManagementListView_SchoolEmployee);

    return navigationItems;
  }

  Create_Center_Officer_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );

    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);

    navigationItems.push(NavigationItems.NewsManagementListView);

    return navigationItems;
  }

  Create_Insitution_Officer_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );

    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);

    navigationItems.push(NavigationItems.NewsManagementListView_SchoolEmployee);

    return navigationItems;
  }

  Create_ProvinceSchool_Officer_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );

    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);

    navigationItems.push(NavigationItems.NewsManagementListView_SchoolEmployee);

    return navigationItems;
  }

  Create_School_Admin_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );
    navigationItems.push(NavigationItems.School_Nav_StudentListView);

    navigationItems.push(
      NavigationItems.SchoolAdmin_Nav_SchoolEmployeeProfileListView_School_Manager
    );
    navigationItems.push(
      NavigationItems.SchoolAdmin_Nav_SchoolEmployeeProfileListView_School_Guidance_Teacher
    );
    navigationItems.push(
      NavigationItems.SchoolAdmin_Nav_SchoolEmployeeProfileListView_School_Admin
    );

    navigationItems.push(
      NavigationItems.SchoolAdmin_Nav_SchoolEmployeeProfileListView_School_Partner_Officer
    );

    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);
    navigationItems.push(NavigationItems.NewsManagementListView_SchoolEmployee);

    return navigationItems;
  }

  Create_School_Guidance_Teacher_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );
    navigationItems.push(NavigationItems.School_Nav_StudentListView);
    navigationItems.push(
      NavigationItems.EmployerSummary_Nav_EmployerSummaryListView
    );
    navigationItems.push(
      NavigationItems.VerifyManager_Nav_VerifyManagerListView
    );
    navigationItems.push(
      NavigationItems.GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportListView_GuidanceTeacher_InProcess
    );
    navigationItems.push(
      NavigationItems.GraduatedStudentJobTrackingReport_Nav_GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified
    );

    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);

    navigationItems.push(NavigationItems.NewsManagementListView_SchoolEmployee);

    return navigationItems;
  }

  Create_School_Partner_Officer_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.SchoolEmployeeProfile_CurrentSchoolEmployeeProfile
    );
    navigationItems.push(
      NavigationItems.EmployerSummary_Nav_EmployerSummaryListView
    );
    navigationItems.push(
      NavigationItems.School_Partner_Officer_Nav_EmployerProfileListView
    );

    navigationItems.push(NavigationItems.Report_Nav_ReportGroupListView);

    navigationItems.push(NavigationItems.NewsManagementListView_SchoolEmployee);

    return navigationItems;
  }

  Create_Employer_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(
      NavigationItems.Employer_Nav_EmployerEditTheirProfileView
    );
    navigationItems.push(
      NavigationItems.Employer_Nav_SearchStudentResumeListView
    );

    navigationItems.push(
      NavigationItems.Employer_Nav_EmployerBookmarkedStudentListView
    );

    navigationItems.push(NavigationItems.Employer_Nav_JobPostingListView_Draft);

    navigationItems.push(
      NavigationItems.Employer_Nav_JobPostingListView_InProcess
    );

    navigationItems.push(
      NavigationItems.Employer_Nav_JobPostingListView_Expired
    );

    navigationItems.push(
      NavigationItems.Employer_Nav_JobPostingListView_History
    );

    return navigationItems;
  }

  Create_Student_NavigationStructures() {
    let navigationItems = [];
    navigationItems.push(NavigationItems.Student_Nav_StudentDetailOverview);

    navigationItems.push(
      NavigationItems.Student_Nav_StudentSearchJobPostingView
    );
    navigationItems.push(
      NavigationItems.Student_Nav_StudentSearchJobPosting_Argent_View
    );
    navigationItems.push(
      NavigationItems.Student_Nav_StudentSearchJobPosting_Monthly_View
    );

    navigationItems.push(
      NavigationItems.Student_Nav_RegisteredPostingJobListView
    );

    navigationItems.push(
      NavigationItems.Student_Nav_BookmarkedJobPostings_ListView
    );
    return navigationItems;
  }

  create() {
    this.userRoleCodes.forEach((role) => {
      switch (role) {
        case ApplicationRoleCodes.Administrator: {
          let navigationItems =
            this.Create_Administrator_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.Center_Admin: {
          let navigationItems = this.Create_Center_Admin_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.Center_Manager: {
          let navigationItems =
            this.Create_Center_Manager_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.School_Manager: {
          let navigationItems =
            this.Create_School_Manager_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.Center_Officer: {
          let navigationItems =
            this.Create_Center_Officer_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }

        case ApplicationRoleCodes.Insitution_Officer: {
          let navigationItems =
            this.Create_Insitution_Officer_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.ProvinceSchool_Officer: {
          let navigationItems =
            this.Create_ProvinceSchool_Officer_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.School_Admin: {
          let navigationItems = this.Create_School_Admin_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.School_Guidance_Teacher: {
          let navigationItems =
            this.Create_School_Guidance_Teacher_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.School_Partner_Officer: {
          let navigationItems =
            this.Create_School_Partner_Officer_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.Employer: {
          let navigationItems = this.Create_Employer_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
        case ApplicationRoleCodes.Student: {
          let navigationItems = this.Create_Student_NavigationStructures();
          navigationItems.forEach((item) => {
            this.NavigationItems.push(item);
          });
          break;
        }
      }
    });
    return this.NavigationItems;
  }

  generateNavigationItems(navigationItemArray) {
    const navigationItems = [];
    const itemMap = new Map();

    navigationItemArray.forEach((navItem) => {
      const segments = navItem.navigationitem_names.split("|");
      let parent = null;

      segments.forEach((segment, index) => {
        const id = segments.slice(0, index + 1).join("/");
        const existingItem = itemMap.get(id);

        if (existingItem) {
          parent = existingItem;
        } else {
          const newItem = {
            id,
            mdi_icon: navItem.mdi_icon,
            label: segment,
            url: navItem.route,
            children: [],
            navigationitem_names: navItem.navigationitem_names,
          };

          if (parent) {
            parent.children.push(newItem);
          } else {
            navigationItems.push(newItem);
          }

          parent = newItem;
          itemMap.set(id, newItem);
        }
      });
    });

    return navigationItems;
  }
}
