import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
export class MajorDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  loadMajors() {
    try {
      const cachedData = localStorage.getItem(
        `Majors_${process.env.VUE_APP_VERSION}`
      );
      if (cachedData) {
        const data = JSON.parse(cachedData);

        return new Promise((resolve) => {
          let response = [];
          response.status = 200;
          response.data = data;
          resolve(response);
        });
      } else {
        var header = create_default_request_header();
        var endpointUrl = "/api/Major/GetMajors";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return new Promise((resolve, reject) => {
          axios
            .get(combinedURL, header)
            .then((response) => {
              if (response?.status == 200) {
                resolve(response);
                localStorage.setItem(
                  `Majors_${process.env.VUE_APP_VERSION}`,
                  JSON.stringify(response.data)
                );
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }

  getMajorDistinctByMajorMoeCode() {
    const header = create_default_request_header();
    const endpointPath = "/api/Major/GetMajorDistinctByMajorMoeCode";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getMajorsFromVECEducationInformationBySchoolID(schoolID = "") {
    const header = create_default_request_header();
    const endpointPath =
      "/api/Major/GetMajorsFromVECEducationInformationBySchoolID/" + schoolID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getMajorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
    schoolID = "",
    subjectTypeNameTh = ""
  ) {
    const header = create_default_request_header();
    const endpointPath =
      "/api/Major/GetMajorThNamesFromGraduatedStudentInVECEducationInformationBySchool";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = { SchoolID: schoolID, SubjectTypeNameTh: subjectTypeNameTh };
    const jsonData = JSON.stringify(data);
    return axios.post(combinedURL, jsonData, header);
  }

  getMajorsNameThFromVECEducationInformationBySchoolID(schoolID = "") {
    const header = create_default_request_header();
    const endpointPath =
      "/api/Major/GetMajorsNameThFromVECEducationInformationBySchoolID/" +
      schoolID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getMajorNamesByGraduatedStudentJobTrackingReport(
    graduatedStudentJobTrackingReportOid = "",
    certificateType_code = ""
  ) {
    const header = create_default_request_header();
    const endpointPath =
      "/api/Major/GetMajorNamesByGraduatedStudentJobTrackingReport";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = {
      GraduatedStudentJobTrackingReport_Oid:
        graduatedStudentJobTrackingReportOid,
      CertificateType_Code: certificateType_code,
    };
    return axios.post(combinedURL, data, header);
  }

  getMajorThNamesFromGraduatedStudentInVECEducationInformation() {
    const header = create_default_request_header();
    const endpointPath =
      "/api/Major/GetMajorThNamesFromGraduatedStudentInVECEducationInformation";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadMajor(majorID) {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/Major/GetMajor/" + majorID;
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.get(combinedURL, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }
  importMajors() {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/Major/ImportMajorDataFromWebAPI";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.get(combinedURL, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }
}
