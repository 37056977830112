<template>
  <div class="pa-5">
    <v-card rounded elevation="1" class="px-5 py-5">
      <template>
        <div class="">
          <v-row>
            <v-col lg="3" md="12" sm="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">สังกัด</h4>

              <v-row no-gutters align-content="end">
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="selectedSchooltype === 0 ? 'primary' : 'input'"
                    @click="
                      selectedSchooltype = 0;
                      schoolGraduatedStudentsFilter.schooltype =
                        selectedSchooltype;
                      loadFilterSchoolGraduatedStudents();
                    "
                    style="height: 50px"
                    width="100%"
                  >
                    ทั้งหมด
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="selectedSchooltype === 1 ? 'primary' : 'input'"
                    @click="
                      selectedSchooltype = 1;
                      schoolGraduatedStudentsFilter.schooltype =
                        selectedSchooltype;
                      loadFilterSchoolGraduatedStudents();
                    "
                    style="height: 50px"
                    width="100%"
                  >
                    รัฐบาล
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="selectedSchooltype === 2 ? 'primary' : 'input'"
                    @click="
                      selectedSchooltype = 2;
                      schoolGraduatedStudentsFilter.schooltype =
                        selectedSchooltype;
                      loadFilterSchoolGraduatedStudents();
                    "
                    style="height: 50px"
                    width="100%"
                  >
                    เอกชน
                  </v-btn>
                </v-col>
              </v-row>

              <!-- <v-select
                :items="schooltypelist"
                v-model="selectedSchooltype"
                text="text"
                value="value"
                @change="
                  (schoolGraduatedStudentsFilter.schooltype =
                    selectedSchooltype),
                    loadFilterSchoolGraduatedStudents()
                "
                solo
              ></v-select> -->
            </v-col>
            <v-col lg="3" md="4" sm="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">ปีการศึกษา</h4>

              <v-select
                label="ปีการศึกษา"
                v-model="schoolGraduatedStudentsFilter.yearth"
                :items="yearthlist"
                @change="loadFilterSchoolGraduatedStudents()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                dense
                clearable
              >
              </v-select>
            </v-col>
            <v-col lg="3" md="4" sm="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">ภูมิภาค</h4>

              <v-select
                label="ภูมิภาค"
                :items="regionsNames"
                clearable
                v-model="schoolGraduatedStudentsFilter.RegionAsVEC"
                @change="loadFilterSchoolGraduatedStudents()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                dense
              >
              </v-select>
            </v-col>
            <v-col lg="3" md="4" sm="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">จังหวัด</h4>

              <v-autocomplete
                label="จังหวัด"
                :items="provincelist"
                item-text="provincethai"
                item-value="provinceid"
                clearable
                v-model="schoolGraduatedStudentsFilter.province"
                @change="loadFilterSchoolGraduatedStudents()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </template>
      <!-- <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <div>
            <v-btn
              @click="loadFilterSchoolGraduatedStudents()"
              block
              dark
              color="info"
              class="mt-2"
            >
              <v-icon>mdi-filter-variant</v-icon> กรองข้อมูล
            </v-btn>
          </div>
        </v-col>
      </v-row> -->
    </v-card>
    <SearchableDataTablePaging
      table_caption="ข้อมูลนักศึกษาที่สำเร็จการศึกษา"
      :data_items="schoolGraduatedStudents"
      :datatable_headers="schoolGraduatedStudents_data_table_headers"
      :itemlength="itemlength"
      :search="keyword"
      @pageChanged="pageChanged"
      @onkeywordchanged="onKeywordChanged"
      @tableOptionsChanged="tableOptionsChanged"
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          @click="openSchoolGraduatedStudentDetailView(item)"
          color="info"
          icon
        >
          <v-icon>mdi-account-group</v-icon>
        </v-btn>
      </template>
    </SearchableDataTablePaging>

    <!-- <SearchableDataTable
      table_caption="ข้อมูลนักศึกษาที่สำเร็จการศึกษา"
      :data_items="schoolGraduatedStudents"
      :datatable_headers="schoolGraduatedStudents_data_table_headers"
      :datatable_groupby="groupBy"
      :is_disable_pagination="false"
    >
      <template v-slot:group.header="{ items, group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>
          </v-btn>
          {{ group }} ({{ items.length }})
        </td>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn @click="openSchoolGraduatedStudentDetailView(item)" color="info" icon>
          <v-icon>mdi-account-group</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable> -->
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { GraduatedStudentDataProvider } from "@/services/GraduatedStudentDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";

var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var graduatedStudentDataProvider = new GraduatedStudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "SchoolGraduatedStudentListView",
  props: [],
  data() {
    return {
      keyword: "",
      schoolGraduatedStudentsFilter: [],
      yearthlist: [],
      provincelist: [],
      regionsNames: [],
      schoolGraduatedStudents: undefined,
      schooltypelist: [
        { text: "ภาครัฐ/ภาคเอกชน", value: 0 },
        { text: "ภาครัฐ", value: 1 },
        { text: "ภาคเอกชน", value: 2 },
      ],
      selectedSchooltype: 0,
      groupBy: [],
      // schoolGraduatedStudents_data_table_headers: [
      //   { text: "สถานศึกษา", value: "school.schoolname" },
      //   { text: "ปีการศึกษา", value: "graduatedyearth" },
      //   { text: "เทอม", value: "graduatedsemesters" },
      //   { text: "สังกัดสถานศึกษา", value: "school.schooltypename" },
      //   {
      //     text: "ภาค",
      //     value: "school.tambon.district.province.regionasvec",
      //   },
      //   {
      //     text: "จังหวัด",
      //     value: "school.tambon.district.province.provincethai",
      //   },

      //   {
      //     text: "จำนวนนักศึกษาที่สำเร็จการศึกษา",
      //     value: "quantityofgraduatedstudent",
      //   },
      //   { text: "", value: "action" },
      // ],

      schoolGraduatedStudents_data_table_headers: [
        { text: "สถานศึกษา", value: "schoolname" },
        { text: "ปีการศึกษา", value: "graduatedyearth" },
        { text: "สังกัดสถานศึกษา", value: "schooltypename" },
        {
          text: "ภาค",
          value: "regionasvec",
        },
        {
          text: "จังหวัด",
          value: "provincethai",
        },

        {
          text: "จำนวนนักศึกษาที่สำเร็จการศึกษา",
          value: "quantityofgraduatedstudent",
          align: "center",
        },
        { text: "ตัวเลือก", value: "action", align: "center" , sortable: false},
      ],
      schoolID: "",
      Selectedprovince: "",
      itemlength: 0,
      pageNumber: 1,
      school: undefined,
      request_school_data: new GetSchools_Reqeust(),
    };
  },
  watch: {},
  methods: {
    async loadRegions() {
      await commonDataProvider.getRegionAsVECNames().then((result) => {
        this.regionsNames = result.data;
      });
    },
    async loadSchoolGraduatedStudents() {
      await graduatedStudentDataProvider
        .loadSchoolGraduatedStudents()
        .then((result) => {
          this.schoolGraduatedStudents = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadSchoolData() {
      await schoolDataProvider
        .loadSchools(this.request_school_data)
        .then((result) => {
          this.school = result.data;
          console.log(this.school);
        });
    },

    openCreateSchoolGraduatedStudentView() {
      this.$router.push({ name: "CreateSchoolGraduatedStudentView" });
    },

    openSchoolGraduatedStudentDetailView(item) {
      console.log(item);
      let schoolOid = item.oid;
      let graduatedYearTH = item.graduatedyearth;
      console.log(graduatedYearTH);
      this.$router.push({
        name: "SchoolGraduatedStudentDetailView",
        params: { schoolOid: schoolOid, graduatedYearTH: graduatedYearTH },
      });
    },

    async loadFilterSchoolGraduatedStudents() {
      this.schoolGraduatedStudentsFilter.page = this.pageNumber;
      this.schoolGraduatedStudentsFilter.search = this.keyword;

      console.log(this.schoolGraduatedStudentsFilter);
      this.$store.dispatch("showLoading");
      await graduatedStudentDataProvider
        .loadFilterSchoolGraduatedStudents(this.schoolGraduatedStudentsFilter)
        .then((result) => {
          this.schoolGraduatedStudents = result.data.items;
          this.itemlength = result.data.quantity;
          console.log(result.data.items);
          if (this.schoolGraduatedStudentsFilter.RegionAsVEC) {
            masterDataProvider
              .loadGetProvincesByRegionAsVECName(
                this.schoolGraduatedStudentsFilter.RegionAsVEC
              )
              .then((result) => {
                this.provincelist = result.data;
              });
          } else if (!this.schoolGraduatedStudentsFilter.RegionAsVEC) {
            masterDataProvider.loadGetProvinces().then((result) => {
              this.provincelist = result.data;
            });
          }
        })
        .catch((err) => {
          // alert(err);
          this.schoolGraduatedStudents = [];
          this.itemlength = 0;
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },

    async loadProvinces() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provincelist = result.data;
      });
    },
    async genyear() {
      var year = new Date().getFullYear() + 543;
      for (var i = 0; i < 10; i++) this.yearthlist.push(year - i);
    },

    pageChanged(pageChangeValue) {
      if (this.pageNumber !== pageChangeValue) {
        this.pageNumber = pageChangeValue;
        this.loadFilterSchoolGraduatedStudents();
      }
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
      this.schoolGraduatedStudentsFilter.itemPerPage =
        newtableOptions.itemsPerPage;
      this.schoolGraduatedStudentsFilter.sortBy = newtableOptions.sortBy;
      this.schoolGraduatedStudentsFilter.sortDesc = newtableOptions.sortDesc;
      this.loadFilterSchoolGraduatedStudents();
    },
    async onKeywordChanged(newKeyword) {
      this.keyword = newKeyword;
      this.pageNumber = 1;
      await this.loadRegions();
      await this.loadProvinces();
      await this.loadSchoolData();
      await this.loadFilterSchoolGraduatedStudents();
    },
  },
  async created() {
    await this.loadFilterSchoolGraduatedStudents();
    await this.genyear();
    await this.loadRegions();
    await this.loadProvinces();
    await this.loadSchoolData();
  },
  components: { SearchableDataTable, SearchableDataTablePaging },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
}

@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
