<template>
  <div>
    <v-btn @click="onBtnDisplayDialogClicked()"
      ><v-icon color="success">mdi-check-circle-outline</v-icon> ยืนยันอีเมล</v-btn
    >
    <v-dialog v-model="dialog">
      <template>
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <p>
                  ระบบจะทำการส่งอีเมลที่ใช้สำหรับยืนยันข้อมูลตามอีเมลที่ท่านได้ให้ไว้ในระบบ
                </p>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <h3 class="primary--text">{{ EmployerProfile.email }}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  :loading="isProcessing"
                  color="primary"
                  @click="onSendEmailClicked()"
                  >ส่งอีเมล</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { EmailSenderDataProvider } from "@/services/EmailSenderDataProvider";
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const emailSenderDataProvider = new EmailSenderDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "BtnVerifyEmployerEmail",
  props: ["EmployerProfile"],
  data() {
    return {
      isProcessing: false,
      dialog: false,
    };
  },
  methods: {
    onBtnDisplayDialogClicked() {
      this.dialog = true;
    },
    async onSendEmailClicked() {
      this.isProcessing = true;
      await emailSenderDataProvider.currentEmployerVerifyEmailAddress().then((result) => {
        this.$toast.success("ส่งอีเมลที่ใช้สำหรับยืนยันเรียบร้อย");
      });
      this.isProcessing = false;
    },
  },
};
</script>

<style></style>
