<template>
  <label
    ><v-icon :color="dynamicColor">{{ dynamicIcon }}</v-icon>
    {{ dynamicStatusName }}</label
  >
</template>

<script>
import { isDateBetween, IsDateLaterThanEndDate } from "@/DateTimeHelper";
import {
  isInCaseOfCurrentDateLaterThanTrackingEndDate,
  isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate,
} from "@/GraduatedStudentJobTrackingReport_TimeChecker";
import { IsInAllowedToEditEmploymentStatus } from "@/GraduatedStudentJobTrackingReport_StatusChecker";
export default {
  name: "GraduatedStudentJobTrackingReport_JobTracking_Status_Label",
  data() {
    return {
      color:'',
      icon:'',
      statusname:'',
    };
  },
  computed: {
    dynamicStatusName() {
      this.dynamicStatusNameFunc()
  return this.statusname
    },
    dynamicIcon() {
      this.dynamicIconFunc()
      return this.icon
    },
    dynamicColor() {
      this.dynamicColorFunc()
      return this.color
    },
  },
  methods:{
    async dynamicColorFunc(){
      if (!IsInAllowedToEditEmploymentStatus(this.jobtrackingreport)) {
        switch (this.jobtrackingreport.jobtracking_status_statuscode) {
          case "Verified":
            this.color= "success"
            return "success";
          case "NotInTime":
             this.color= "error"
            return "error";
          case "AllowedToEdit":
            this.color= "info"
            return "info";
          default:
            this.color= ""
            return "";
        }
      } else {
        if (await isInCaseOfCurrentDateLaterThanTrackingEndDate(this.jobtrackingreport)) {
          this.color= "error"
          return "error";
        }
        if (!
          await isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate(
            this.jobtrackingreport
          )
        ) {
          this.color= "error"
          return "error";
        }
        switch (this.jobtrackingreport.jobtracking_status_statuscode) {
          case "NotStartedTrackingProcess":
            this.color= "grey"
            return "grey";
          case "InProcessOfTracking":
            this.color= "info"
            return "info";
          default:
            this.color= ""
            return "";
        }
      }
    },
    async dynamicIconFunc(){
      if (!IsInAllowedToEditEmploymentStatus(this.jobtrackingreport)) {
        switch (this.jobtrackingreport.jobtracking_status_statuscode) {
          case "Verified":
            this.icon= "mdi-check-decagram"
            return "mdi-check-decagram";
          case "NotInTime":
            this.icon= "mdi-timer-sand-complete"
            return "mdi-timer-sand-complete";
          case "AllowedToEdit":
            return "mdi-pencil-circle";
          default:
            this.icon= ""
            return "";
        }
      } else {
        if (await isInCaseOfCurrentDateLaterThanTrackingEndDate(this.jobtrackingreport)) {
          this.icon= "mdi-timer-sand-complete"
          return "mdi-timer-sand-complete";
        }
        if (!
          await isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate(
            this.jobtrackingreport
          )
        ) {
             this.icon= "mdi-alarm-off"
          return "mdi-alarm-off";
        }
        switch (this.jobtrackingreport.jobtracking_status_statuscode) {
          case "NotStartedTrackingProcess":
            this.icon= "mdi-clock-edit"
            return "mdi-clock-edit";
          case "InProcessOfTracking":
            this.icon= "mdi-progress-pencil"
            return "mdi-progress-pencil";
          default:
            this.icon= ""
            return "";
        }
      }
    },
   async  dynamicStatusNameFunc(){
      if (!IsInAllowedToEditEmploymentStatus(this.jobtrackingreport)) {
        this.statusname= this.jobtrackingreport.jobtracking_status_statusname
        return this.jobtrackingreport.jobtracking_status_statusname;
      } else {
        if (await isInCaseOfCurrentDateLaterThanTrackingEndDate(this.jobtrackingreport)) {
          this.statusname= "หมดเวลาในการติดตาม"
          return "หมดเวลาในการติดตาม";
        }
        if (
          !await isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate(
            this.jobtrackingreport
          )
        ) {
          this.statusname= "ไม่อยู่ในช่วงเวลาที่อนุญาตให้บันทึกข้อมูล"
          return "ไม่อยู่ในช่วงเวลาที่อนุญาตให้บันทึกข้อมูล";
        }
        this.statusname= this.jobtrackingreport.jobtracking_status_statusname
        return this.jobtrackingreport.jobtracking_status_statusname;
      }
    }
  },
  props: ["jobtrackingreport"],
  created() {},
};
</script>

<style></style>
