<template>
  <v-container class="">
    <v-row class="justify-center pt-10">
      <v-card class="" elevation="" style="width: 400px">
        <v-img src="@/assets/blog-adaptive-auth.jpg" height="200" dark>
          <v-card-title primary-title class="justify-center pt-10">
            <h2>ลงทะเบียนผู้ใช้งาน</h2>
            <h3>(Registration)</h3>
          </v-card-title>
        </v-img>

        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submit" lazy-validation>
            <v-text-field
              v-model="account.fullname"
              label="ชื่อ-นามสกุล (Display name)"
              required
              placeholder="ชื่อ-นามสกุล (Display name)"
              :rules="fullnameRules"
            >
            </v-text-field>
            <v-text-field
              v-model="account.username"
              label="ชื่อผู้ใช้ (Username)"
              counter
              required
              placeholder="ชื่อผู้ใช้ (Username)"
              :rules="usernameRules"
            >
            </v-text-field>
            <v-text-field
              v-model="account.email"
              label="อีเมล (e-Mail)"
              required
              :rules="emailRules"
              placeholder="อีเมล (e-Mail)"
            >
            </v-text-field>
            <v-text-field
              v-model="account.mobile"
              label="เบอร์โทรศัพท์ (Mobile)"
              required
              :rules="mobileRules"
              placeholder="(012) 345-6789"
              v-mask="['(###) ###-####']"
            >
            </v-text-field>
            <!-- visibility , visibility_off คือชื่อ icon -->
            <v-text-field
              v-model="account.password"
              label="รหัสผ่าน (Password)"
              placeholder="รหัสผ่าน (Password)"
              :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
              @click:append="isShowPassword = !isShowPassword"
              :type="isShowPassword ? 'text' : 'password'"
              counter
              required
              :rules="passwordRules"
            >
            </v-text-field>

            <v-row class="justify-space-between px-10 py-10">
              <v-btn color="success" type="submit" dark>
                <span>ยืนยันลงทะเบียน</span>
              </v-btn>
              <v-btn text color="" @click.prevent="$router.push('/login')" dark>
                ยกเลิก
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text>{{ account }}</v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "RegisterView",
  directives: { mask },
  data() {
    return {
      isShowPassword: false,
      valid: true,
      allowSpaces: false,
      account: {
        username: "",
        password: "",
        fullname: "",
        email: "",
        mobile: "",
      },
      usernameRules: [
        (v) => !!v || "กรุณาป้อนชื่อผู้ใช้ (Username)",
        (v) => (v || "").indexOf(" ") < 0 || "ชื่อผู้ใช้ (Username) ต้องไม่มีเว้นวรรค",
        (v) =>
          /^[A-Za-z0-9]+$/.test(v) ||
          "ชื่อผู้ใช้ (Username) ต้องเป็นภาษาอังกฤกษหรือตัวเลขเท่านั้น",
      ],
      passwordRules: [
        (v) => !!v || "กรุณาป้อนรหัสผ่าน (Password)",
        (v) => (v || "").indexOf(" ") < 0 || "รหัสผ่าน (Password) ต้องไม่มีเว้นวรรค",
        (v) => (v && v.length >= 8) || "รหัสผ่านขั้นต่ำ 8 หลัก",
        (v) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s)/.test(v) ||
          "รหัสผ่านไม่ปลอดภัย (รหัสผ่านต้องมี ตัวอักษรอังกฤษพิมพ์เล็ก/ใหญ่ ตัวเลข และอักขระพิเศษ รวมกันอย่างน้อย 8 หลัก)",
      ],
      fullnameRules: [(v) => !!v || "กรุณาป้อนชื่อ-นามสกุล (Display name)"],
      emailRules: [
        (v) => !!v || "กรุณาป้อนอีเมล (e-Mail)",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "รูปแบบอีเมลไม่ถูกต้อง",
      ],
      mobileRules: [(v) => !!v || "กรุณาป้อนเบอร์โทรศัพท์ (Mobile)"],
    };
  },
  methods: {
    submit() {
      alert(JSON.stringify(this.account));
    },
  },
  components: {},
};
</script>

<style scoped>
.headertext {
  font-size: 22pt !important;
}
</style>
