<template>
  <div class="pa-4">
    <v-card elevation="1" class="pa-4">
      <template>
        <div class="mx-5">
          <v-row>
            <v-col lg="3" md="6" sm="6" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">ตำแหน่ง</h4>
              <v-select
                label="ตำแหน่ง"
                filled
                background-color="input"
                clearable
                :items="applicationRole"
                item-text="description"
                item-value="code"
                v-model="selectedApplicationRole"
                @change="onSelected"
              ></v-select>
            </v-col>

            <v-col lg="3" md="6" sm="6" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">สถานศึกษา</h4>
              <v-autocomplete
                label="สถานศึกษา"
                filled
                background-color="input"
                clearable
                :items="school"
                item-text="schoolname"
                item-value="schoolid"
                v-model="selectedSchool"
                @change="onSelected"
              >
              </v-autocomplete>
            </v-col>

            <v-col lg="3" md="6" sm="6" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">สถาบัน</h4>
              <v-autocomplete
                label="สถาบัน"
                filled
                background-color="input"
                clearable
                :items="institute"
                item-text="institutenameth"
                item-value="instituteid"
                v-model="selectedInstitute"
                @change="onSelected"
              >
              </v-autocomplete>
            </v-col>

            <v-col lg="3" md="6" sm="6" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">จังหวัด</h4>
              <v-autocomplete
                label="จังหวัด"
                filled
                background-color="input"
                clearable
                :items="province"
                item-text="provincethai"
                item-value="provinceid"
                v-model="selectedProvince"
                @change="onSelected"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-card>

    <v-row>
      <v-col class="ml-0 pl-1">
        <v-btn
          dark
          color="primary"
          class="mt-5 mx-2"
          @click="open_createSchoolEmployeeProfileDetailView"
        >
          <v-icon class="mr-2">mdi-account-plus</v-icon>
          เพิ่มข้อมูลบุคลากร
        </v-btn>
      </v-col>
    </v-row>

    <SearchableDataTablePaging
      :datatable_headers="profileOverview_table_headers"
      :data_items="allSchoolEmployeeProfiles"
      table_caption="ข้อมูลบุคลากรทั้งหมด"
      :is_disable_pagination="false"
      :itemlength="itemlength"
      :search="keywords"
      @pageChanged="pageChanged"
      @onkeywordchanged="onKeywordChanged"
      @tableOptionsChanged="tableOptionsChanged"
    >
      <template v-slot:item.action="{ item }">
        <v-row class="custom-width">
          <ChangePasswordWhenLogon
            :SchoolEmployeeProfileOid="item.oid"
          ></ChangePasswordWhenLogon>

          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="open_RoleAndPermissionDialog(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-group</v-icon>
              </v-btn>
            </template>
            <span>จัดการสิทธิใช้งาน</span>
          </v-tooltip>

          <v-tooltip top color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="open_SchoolEmployeeProfileDetailView(item)"
                color="warning"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขข้อมูล</span>
          </v-tooltip>

          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="deleteSchoolEmployeeProfile(item)"
                color="error"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ลบ</span>
          </v-tooltip>
        </v-row>
      </template>
    </SearchableDataTablePaging>
    <RoleAndPermission_ManagementVue
      :SchoolEmployeeProfileOid="currentSchoolEmployeeProfile_Oid"
      :dialog="roleAndPermission_Management_Dialog"
      @closeDialog="onSaveIsComplete"
    >
    </RoleAndPermission_ManagementVue>
  </div>
</template>

<script>
import { InstituteDataProvider } from "@/services/InstituteDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { SearchAllSchoolEmployeeProfiles_Request } from "@/models/Profile/SearchAllSchoolEmployeeProfiles_Request";

import ChangePasswordWhenLogon from "@/components/SchoolEmployeeProfiles/ChangePasswordWhenLogon.vue";
import RoleAndPermission_ManagementVue from "../../components/SchoolEmployeeProfiles/RoleAndPermission_Management";
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";

const instituteDataProvider = new InstituteDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "ProfileOverview",
  components: {
    SearchableDataTable,
    ChangePasswordWhenLogon,
    RoleAndPermission_ManagementVue,
    SearchableDataTablePaging,
  },
  data() {
    return {
      profileOverview: undefined,
      profileOverview_table_headers: [
        { text: "ชื่อ", value: "name" },
        { text: "นามสกุล", value: "lastname" },
        { text: "เลขประจำตัวประชาชน", value: "idcard" },
        { text: "ตำแหน่ง", value: "position" },
        { text: "ชื่อผู้ใช้งาน", value: "username" },
        { text: "สิทธิ์", value: "permissions" },
        { text: "ตัวเลือก", value: "action", align: "center" , sortable: false},
      ],
      school: undefined,
      selectedSchool: "",
      institute: undefined,
      selectedInstitute: "",
      province: undefined,
      selectedProvince: "",
      applicationRole: undefined,
      selectedApplicationRole: "",
      request_school_data: new GetSchools_Reqeust(),
      allSchoolEmployeeProfiles: undefined,
      request_data: new SearchAllSchoolEmployeeProfiles_Request(),

      currentSchoolEmployeeProfile_Oid: "",
      roleAndPermission_Management_Dialog: false,
      changePasswordDialog: false,

      itemlength: 0,
      keywords: "",
      tableOptions: undefined,
      initialized: false,
    };
  },
  methods: {
    async initialize() {
      await instituteDataProvider.loadInstitutes().then((result) => {
        this.institute = result.data;
      });
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.province = result.data;
      });
      await schoolDataProvider
        .loadSchools(this.request_school_data)
        .then((result) => {
          this.school = result.data;
        });
      await masterDataProvider.getApplicationRoles().then((result) => {
        this.applicationRole = result.data;
      });
    },
    async getAllSchoolEmployeeProfiles() {
      this.$store.dispatch("showLoading");
      this.tableOptions.search = this.keywords;
      await schoolEmployeeProfileDataProvider
        .getAllSchoolEmployeeProfiles(this.request_data)
        .then((result) => {
          this.allSchoolEmployeeProfiles = result.data.items;
          this.itemlength = result.data.quantity;
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },
    debounceGetAllSchoolEmployeeProfiles() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.getAllSchoolEmployeeProfiles();
      }, 1000);
    },
    async onSelected() {
      this.request_data.SchoolID = this.selectedSchool;
      this.request_data.InstituteID = this.selectedInstitute;
      this.request_data.ProvinceID = this.selectedProvince;
      this.request_data.ApplicationRoleCode = this.selectedApplicationRole;

      this.getAllSchoolEmployeeProfiles();
    },

    open_RoleAndPermissionDialog(item) {
      var oid = item.oid;
      this.currentSchoolEmployeeProfile_Oid = oid;
      this.roleAndPermission_Management_Dialog = true;
    },
    open_SchoolEmployeeProfileDetailView(item) {
      const oid = item.oid;
      this.$router
        .push({ name: "SchoolEmployeeProfileDetailView", params: { oid: oid } })
        .catch((err) => {});
    },
    async deleteSchoolEmployeeProfile(item) {
      if (confirm("ยืนยันการลบบัญชีผู้ใช้งาน ?")) {
        var oid = item.oid;
        await schoolEmployeeProfileDataProvider
          .deleteSchoolEmployeeProfile(oid)
          .then((result) => {
            this.$toast.success("ลบข้อมูลเรียบร้อย");
            this.getAllSchoolEmployeeProfiles();
          });
      }
    },

    onSaveIsComplete() {
      this.roleAndPermission_Management_Dialog = false;
    },

    open_createSchoolEmployeeProfileDetailView() {
      this.$router
        .push({ name: "CreateSchoolEmployeeProfileDetailView" })
        .catch((err) => {});
    },

    pageChanged(pageChangeValue) {
      if (this.initialized) {
        this.request_data.PageNumber = pageChangeValue;
        this.getAllSchoolEmployeeProfiles();
      }
    },
    async onKeywordChanged(newKeyword) {
      this.request_data.Keyword = newKeyword;
      await this.debounceGetAllSchoolEmployeeProfiles();
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
      this.request_data.ItemsPerPage = newtableOptions.itemsPerPage;
    },
  },
  async created() {
    await this.initialize().then(() => {
      this.initialized = true;
    });

    await this.getAllSchoolEmployeeProfiles();
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.custom-width {
  width: 150px;
}
</style>
