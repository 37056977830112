import DefaultJobPostingSetupDetailView from "@/views/DefaultJobPostingSetups/DefaultJobPostingSetupDetailView.vue";

export default [
  {
    path: "/DefaultJobPostingSetup",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/DefaultJobPostingSetup/DefaultJobPostingSetupDetailView",
        name: "DefaultJobPostingSetupDetailView",
        component: DefaultJobPostingSetupDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "ตั้งค่าเกี่ยวกับการประกาศงาน",
        },
      },
    ],
  },
];
