<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="10" md="12" sm="12" xs="12">
          <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >เพิ่มข้อมูลบุคลากร</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="py-6 px-12 d-flex justify-center">
              <v-col cols="10" class="my-7">
                <v-row class="">
                  <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                  <h3 class="primary--text">ข้อมูลบุคลากร</h3>
                </v-row>
                <v-row class="py-2" style="margin-top: 1%; margin-bottom: 2%">
                  <v-divider></v-divider>
                </v-row>
                <!--<v-row class="px-0 py-0">
                  <v-col class="py-0">
                    <span class="primary--text" style="font-weight: bold"
                      >เพศ<span style="color: red">*</span></span
                    >
                  </v-col>
                </v-row>
                 <v-row class="px-0 py-0">
                  <v-col class="py-0">
                    <v-radio-group
                      :rules="[(v) => !!v || 'กรุณาระบุเพศ']"
                      v-model="vm.gender"
                      row
                    >
                      <template v-slot:label>
                        <div><strong>เพศ</strong></div>
                      </template>
                      <v-radio label="ชาย" color="blue" value="male"></v-radio>
                      <v-radio label="หญิง" color="pink" value="female"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      คำนำหน้าชื่อ<span style="color: red">*</span>
                    </h4>
                    <v-autocomplete
                      clearable
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                      required
                      :items="titlenamelist"
                      item-text="titlenameth"
                      item-value="titlenameid"
                      placeholder="คำนำหน้าชื่อ"
                      :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                      v-model="vm.titlenameid"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      ชื่อจริง<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      required
                      filled
                      clearable
                      placeholder="ชื่อจริง"
                      :rules="firstnameRules"
                      v-model="vm.name"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      นามสกุล<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                     background-color="input"
                      required
                      filled
                      clearable
                      placeholder="นามสกุล"
                      :rules="lastnameRules"
                      v-model="vm.lastname"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-0 py-0">
                  <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      ตำแหน่ง<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      required
                      filled
                      clearable
                      placeholder="ตำแหน่ง"
                      :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                      v-model="vm.position"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      เลขประจำตัวประชาชน<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      required
                      filled
                      clearable
                      placeholder="เลขประจำตัวประชาชน"
                      :rules="idRules"
                      ref="txtIdentityid"
                      v-model="vm.idcard"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      อีเมล<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      required
                      filled
                      clearable
                      ref="txtEmail"
                      placeholder="อีเมล"
                      :rules="custom_emailRules"
                      v-model="vm.email"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">หมายเลขโทรศัพท์<span style="color: red">*</span></h4>
                    <v-text-field
                      background-color="input"
                      required
                      filled
                      clearable
                      placeholder="หมายเลขโทรศัพท์"
                      :rules="phoneRules"
                      v-model="vm.phonenumber"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                  <h3 class="primary--text">เกี่ยวกับการใช้งาน</h3>
                </v-row>
                <v-row class="py-2" style="margin-top: 1%; margin-bottom: 2%">
                  <v-divider></v-divider>
                </v-row>
                <v-row class="">
                  <v-col cols="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      ชื่อบัญชีผู้ใช้ (Username)<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      filled
                      autocomplete="new-password"
                      ref="txtUsername"
                      placeholder="ชื่อบัญชีผู้ใช้ (username)"
                      :rules="custom_usernameRules"
                      clearable
                      v-model="vm.username"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      รหัสผ่าน (Password)<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      filled
                      autocomplete="new-password"
                      v-model="vm.password"
                      name="password"
                      :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isShowPassword = !isShowPassword"
                      :type="isShowPassword ? 'text' : 'password'"
                      :rules="passwordRules"
                      placeholder="รหัสผ่าน"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="custom-col mb-0 pb-0">
                    <h4 class="primary--text mb-2">
                      ยืนยันรหัสผ่าน (Confirm Password)<span style="color: red">*</span>
                    </h4>
                    <v-text-field
                      background-color="input"
                      filled
                      v-model="vm.confirm_password"
                      :append-icon="isConfirmShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isConfirmShowPassword = !isConfirmShowPassword"
                      :type="isConfirmShowPassword ? 'text' : 'password'"
                      :rules="[
                        (v) => !!v || 'กรุณายืนยันรหัสผ่าน (Confirmpassword)',
                        (v) => v === vm.password || 'รหัสผ่านไม่ตรง',
                      ]"
                      name="confirmPassword"
                      placeholder="ยืนยันรหัสผ่าน"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" class="mb-5 pr-0">
                    <v-btn
                      style="
                        border-radius: 5px;
                        
                        display: flex;
                        justify-content: center;
                      "
                      outlined
                      append-icon="mdi-account-group"
                      @click="open_RoleAndPermissionDialog"
                      class="mx-0 justify-center"
                    >
                      <v-icon class="mr-2" color="primary" large v-if="isChecked"
                        >mdi-toggle-switch</v-icon
                      >
                      <v-icon class="mr-2" large color="error" v-else
                        >mdi-toggle-switch-off-outline</v-icon
                      >
                      จัดการสิทธิการใช้งาน
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" v-if="validisChecked">
                    <p
                      style="font-size: 14px; display: flex; justify-content: center"
                      class="red--text"
                    >
                      *กรุณากำหนดสิทธิการใช้งาน*
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>
        </v-form>
          <v-col class="d-flex justify-end px-0">
            <v-btn @click="$router.go(-1)" class="mx-2"> ย้อนกลับ</v-btn>
            <v-btn class="color-button2" @click="save">
              บันทึก <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>

      <v-dialog v-model="dialogvalidisChecked" max-width="290">
      <v-card>
        <v-card-title class="text-h7"> กรุณากำหนดสิทธิการใช้งาน </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogvalidisChecked = false">
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <RoleAndPermission_ManagementVue
      :dialog="roleAndPermission_Management_Dialog"
      @closeDialog="onSaveIsComplete"
    >
    </RoleAndPermission_ManagementVue>
    </v-container>
  </div>
  <!-- <v-container class="mt-3 mb-3">
    <v-row class="justify-center">
      <v-col cols="8">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="elevation-1 pa-5 mt-5">
            <v-row>
              <v-col cols="12" class="d-flex justify-start">
                <h3 class="primary--text">
                  <v-icon class="primary--text">mdi-account</v-icon>
                  ข้อมูลบุคลากรสถานศึกษา
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row class="d-flex align-end">
              <v-col cols="6" class="d-flex justify-center">
                <v-radio-group
                  :rules="[(v) => !!v || 'กรุณาระบุเพศ']"
                  v-model="vm.gender"
                  row
                >
                  <template v-slot:label>
                    <div><strong>เพศ</strong></div>
                  </template>
                  <v-radio label="ชาย" color="blue" value="male"></v-radio>
                  <v-radio label="หญิง" color="pink" value="female"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="vm.titlenameid"
                  :items="titlenamelist"
                  :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                  label="เลือกคำนำหน้าชื่อ"
                  item-text="titlenameth"
                  item-value="titlenameid"
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="ชื่อ"
                  required
                  :rules="nameRules"
                  v-model="vm.name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="นามสกุล"
                  required
                  :rules="nameRules"
                  v-model="vm.lastname"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="ตำแหน่ง"
                  required
                  :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                  v-model="vm.position"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  ref="txtIdentityid"
                  label="เลขประจำตัวประชาชน"
                  required
                  :rules="idRules"
                  v-model="vm.idcard"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="อีเมล"
                  ref="txtEmail"
                  required
                  :rules="custom_emailRules"
                  v-model="vm.email"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="หมายเลขโทรศัพท์"
                  required
                  :rules="phoneRules"
                  v-model="vm.phonenumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="elevation-1 pa-5 mt-7">
            <v-row>
              <v-col cols="12" class="d-flex justify-start">
                <h3 class="primary--text">
                  <v-icon class="primary--text">mdi-account</v-icon>
                  เกี่ยวกับการเข้าใช้งาน
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  ref="txtUsername"
                  label="ชื่อบัญชีผู้ใช้ (username) *"
                  :rules="custom_usernameRules"
                  v-model="vm.username"
                  autocomplete="new-password"
                  prepend-icon="mdi-account"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  autocomplete="new-password"
                  v-model="vm.password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :rules="passwordRules"
                  label="รหัสผ่าน"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.confirm_password"
                  :rules="[
                    (v) => !!v || 'กรุณายืนยันรหัสผ่าน (Confirmpassword)',
                    (v) => v === vm.password || 'รหัสผ่านไม่ตรง',
                  ]"
                  name="confirmPassword"
                  prepend-icon="mdi-lock"
                  type="password"
                  label="ยืนยันรหัสผ่าน"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="mb-5">
                <v-btn
                  style="border-radius: 20px"
                  dark
                  outlined
                  :color="colorbutton"
                  append-icon="mdi-account-group"
                  @click="open_RoleAndPermissionDialog"
                  class="ma-2 justify-center"
                >
                  <v-icon class="mr-2" v-if="isChecked"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                  <v-icon class="mr-2" v-else>mdi-checkbox-blank-circle-outline</v-icon>
                  จัดการสิทธิการใช้งาน</v-btn
                >
                <p v-if="validisChecked" style="font-size: 14px" class="red--text ml-7">
                  *กรุณากำหนดสิทธิการใช้งาน*
                </p>
              </v-col>
            </v-row>
          </v-card>

          <v-row class="justify-center text-center mt-4 mb-4">
            <v-col cols="12">
              <v-btn color="secondary" @click="save">
                <v-icon>mdi-content-save</v-icon> บันทึก
              </v-btn>
              <v-btn color="" @click="$router.go(-1)" class="ma-2 justify-center">
                ย้อนกลับ</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogvalidisChecked" max-width="290">
      <v-card>
        <v-card-title class="text-h7"> กรุณากำหนดสิทธิการใช้งาน </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialogvalidisChecked = false">
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <RoleAndPermission_ManagementVue
      :dialog="roleAndPermission_Management_Dialog"
      @closeDialog="onSaveIsComplete"
    >
    </RoleAndPermission_ManagementVue>
  </v-container> -->
</template>

<script>
import {
  usernameRules,
  passwordRules,
  emailRules,
  phoneRules,
  nameRules,
  firstnameRules,
  lastnameRules,
  idcardRules,
} from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import { CreateUpdateSchoolEmployeeProfile_Request } from "@/models/Profile/SchoolEmployeeProfile/CreateUpdateSchoolEmployeeProfile_Request";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import RoleAndPermission_ManagementVue from "@/components/SchoolEmployeeProfiles/RoleAndPermission_Management.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: { SearchableDataTable, RoleAndPermission_ManagementVue },
  name: "CreateSchoolEmployeeProfileDetailView",
  data() {
    return {
      oid: "",
      passwordMismatch: false,
      vm: new CreateUpdateSchoolEmployeeProfile_Request(),
      titlenamelist: [],
      schoolEmployeeProfile_SchoolRights_table_headers: [
        { text: "สถานศึกษา", value: "school.schoolname" },
        {
          text: "จังหวัด",
          value: "school.tambon.district.province.provincethai",
        },
        { text: "", value: "action" , sortable: false},
      ],
      schools: undefined,
      selected_schoolCode: undefined,
      valid: true,
      roleAndPermission_Management_Dialog: false,
      roleAndPermission_request: [],
      isChecked: false,
      colorbutton: "success",
      validisChecked: false,
      dialogvalidisChecked: false,
      emailRules: emailRules,
      phoneRules: phoneRules,
      passwordRules: passwordRules,
      usernameRules: usernameRules,
      nameRules: nameRules,
      idcardRules: idcardRules,
      firstnameRules: firstnameRules,
      lastnameRules: lastnameRules,
      isShowPassword: false,
      isConfirmShowPassword: false,
      usernameExistResult: undefined,
      emailExistResult: false,
      identityidExistResult: false,
      idRules: [
        (v) => !!v || "กรุณาระบุเลขประจำตัวประชาชน",
        (v) => this.checkID(v) || "รูปแบบหมายเลขไม่ถูกต้อง",
        (v) => this.identityidExistResult !== true || "หมายเลขที่กรอกไม่ถูกต้อง",
      ],
    };
  },
  methods: {
    onSaveIsComplete(roleAndPermission, isSave) {
      this.roleAndPermission_Management_Dialog = false;
      if (isSave) {
        this.vm.is_center_admin = roleAndPermission.is_center_admin;
        this.vm.is_center_manager = roleAndPermission.is_center_manager;
        this.vm.is_center_officer = roleAndPermission.is_center_officer;
        this.vm.is_insitution_officer = roleAndPermission.is_insitution_officer;
        this.vm.is_provinceschool_officer = roleAndPermission.is_provinceschool_officer;
        this.vm.is_school_admin = roleAndPermission.is_school_admin;
        this.vm.is_school_guidance_teacher = roleAndPermission.is_school_guidance_teacher;
        this.vm.is_school_manager = roleAndPermission.is_school_manager;
        this.vm.is_school_partner_officer = roleAndPermission.is_school_partner_officer;

        this.vm.insitution_officer_permissions =
          roleAndPermission.insitution_officer_permissions;
        this.vm.school_partner_officer_permissions =
          roleAndPermission.school_partner_officer_permissions;
        this.vm.school_manager_permissions = roleAndPermission.school_manager_permissions;
        this.vm.school_guidance_teacher_permissions =
          roleAndPermission.school_guidance_teacher_permissions;
        this.vm.school_admin_permissions = roleAndPermission.school_admin_permissions;
        this.vm.provinceschool_officer_permissions =
          roleAndPermission.provinceschool_officer_permissions;
        if (
          roleAndPermission.is_center_admin ||
          roleAndPermission.is_center_manager ||
          roleAndPermission.is_center_officer ||
          roleAndPermission.is_insitution_officer ||
          roleAndPermission.is_provinceschool_officer ||
          roleAndPermission.is_school_admin ||
          roleAndPermission.is_school_guidance_teacher ||
          roleAndPermission.is_school_manager ||
          roleAndPermission.is_school_partner_officer
        ) {
          this.isChecked = true;
          this.validisChecked = false;
        } else {
          this.isChecked = false;
          this.validisChecked = true;
        }
        // this.colorbutton = this.colorbutton === "black" ? "success" : "black";
      } else {
        this.isChecked = false;
        this.validisChecked = true;
      }
    },
    async initilize() {
      await this.loadTitleName();
      const request = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(request).then((result) => {
        this.schools = result.data;
      });
    },
    async loadTitleName() {
      await masterDataProvider
        .getActiveTitleNames()
        .then((response) => {
          this.titlenamelist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    validatePassword() {
      if (this.vm.password != this.vm.confirm_password) {
        this.passwordMismatch = true;
      }
    },
    async save() {
      if (this.isChecked == false) {
        this.validisChecked = true;
        this.dialogvalidisChecked = true;
      } else {
        this.validisChecked = false;
      }
      if (this.$refs.form.validate() && this.isChecked == true) {
        if (confirm("ต้องการบันทึกหรือไม่?")) {
          this.validatePassword();

          if (this.passwordMismatch == false) {
            await schoolEmployeeProfileDataProvider
              .createSchoolEmployeeProfile(this.vm)
              .then((result) => {
                this.$store.dispatch({ type: "doSetIsLoadingProfile" });
                this.$toast.success("บันทึกข้อมูลเรียบร้อย");
                this.$router.back();
              })
              .catch((err) => {
                this.$toast.error(err);
              });
          } else {
            this.$toast.error("กรุณาตรวจสอบข้อมูลให้ถูกต้อง");
          }
        }
      }
    },

    onSelectSchool() {
      if (this.vm.selected_schoolcode != undefined) {
        const selectedSchool = this.schools.find(
          (x) => x.schoolid == this.vm.selected_schoolcode
        );
        this.vm.school = selectedSchool;
      }
    },
    open_RoleAndPermissionDialog() {
      this.roleAndPermission_Management_Dialog = true;
    },
    checkID(id) {
      if (id.length !== 13) return false;
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        sum += parseFloat(id.charAt(i)) * (13 - i);
      }
      const test = (11 - (sum % 11)) % 10 === parseFloat(id.charAt(12));
      return test;
    },
  },
  watch: {
    "vm.username": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isUsernameUsed(newVal).then((result) => {
            this.usernameExistResult = result.data;
            this.$refs.txtUsername.validate();
          });
        }
      },
      deep: true,
    },
    "vm.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isEmailUsed(newVal).then((result) => {
            this.emailExistResult = result.data;
            this.$refs.txtEmail.validate();
          });
        }
      },
      deep: true,
    },
    "vm.idcard": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isIdentityIDUsed(newVal).then((result) => {
            this.identityidExistResult = result.data;
            this.$refs.txtIdentityid.validate();
          });
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.initilize();
  },
  computed: {
    custom_usernameRules() {
      return [
        ...usernameRules,
        (v) =>
          this.usernameExistResult === false || "ชื่อผู้ใช้งาน (username) ถูกใช้งานแล้ว",
      ];
    },
    custom_emailRules() {
      return [
        ...emailRules,
        (v) => this.emailExistResult === false || "อีเมลนี้ถูกใช้งานแล้ว",
      ];
    },
  },
};
</script>

<style scoped>
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
