export function NotificationHelper(notificationItem) {
  switch (notificationItem) {
    case "CommonNotify": {
      return "#2196f3";
    }
    case "GraduatedStudentJobTrackingReportInComming_Notify": {
      return "#00afa1";
    }
    case "GraduatedStudentJobTrackingReport7DaysLeft_Notify": {
      return "#00afa1";
    }
    case "GraduatedStudentJobTrackingReport15DaysLeft_Notify": {
      return "#00afa1";
    }
    case "GraduatedStudentJobTrackingReportStudentImported": {
      return "#00afa1";
    }
    case "JobPostingHasNewStudentRegistration":
    case "JobPostingNotCompletedStatusNotification":
    case "JobPostingHasNewStudentRegistration_Employer":
    case "JobPostingHasNewStudentRegistration_Student": {
      return "#a4c126";
    }
    case "JobApplicationHasChangeStatus": {
      return "#a4c126";
    }
    case "GeneratedReportNotify": {
      return "#186F65";
    }
  }
}
