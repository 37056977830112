<template>
  <div>
    <v-btn
      :disabled="isBtnDialogDisabled"
      color="warning"
      class="mr-2 mb-4"
      @click="confirm_dialog = true"
      ><v-icon class="mr-1">mdi-pencil</v-icon> อนุญาตให้แก้ไข</v-btn
    >

    <v-dialog v-model="confirm_dialog" width="600">
      <template>
        <v-card class="pa-6">
          <v-row class="justify-center text-center">
            <v-card-title> ยืนยันการอนุญาตให้แก้ไข ? </v-card-title>
            <label class="mt-1 mb-3">
              ระบบจะคืนสถานะเพื่อให้สถานศึกษาแก้ไขข้อมูล
              และส่งรายงานการติดตามใหม่
            </label>
            <v-col cols="12">
              <v-btn
                @click="confirm_dialog = false"
                class="ma-2 back-button"
                elevation="2"
                >ยกเลิก</v-btn
              >
              <v-btn @click="allowEditStudentDetail" class="ma-2 color-button2">
                ยืนยัน <v-icon>mdi-check-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";

const graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
export default {
  data() {
    return {
      confirm_dialog: false,
    };
  },
  props: {
    GraduatedStudentJobTrackingReportOid: "",
    GraduatedStudentJobTrackingReport: undefined,
  },
  computed: {
    isBtnDialogDisabled() {
      if (this.GraduatedStudentJobTrackingReport == null) {
        return true;
      }
      if (
        this.GraduatedStudentJobTrackingReport.is_trackingprocessiscompleted ==
        true
      ) {
        return true;
      }
      if (
        this.GraduatedStudentJobTrackingReport.jobtracking_status_statuscode !=
        "Verified"
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async allowEditStudentDetail() {
      await graduatedStudentJobTrackingReportReportDataProvider
        .allowEditStudentDetail(this.GraduatedStudentJobTrackingReport.oid)
        .then((result) => {
          this.confirm_dialog = false;
          if (result?.status == 200) {
            this.$toast.success("เปลี่ยนสถานะเรียบร้อย !");
            this.$router.back();
          }
        });
    },
  },
};
</script>

<style></style>
