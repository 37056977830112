import EmployerProfileListView from "@/views/Employer/EmployerProfileListView.vue";
import CreateEmployerProfileView from "@/views/Employer/CreateEmployerProfileView.vue";
import UpdateEmployerProfileView from "@/views/Employer/UpdateEmployerProfileView.vue";
import EmployerBookmarkedStudentListView from "@/views/Employer/EmployerBookmarkedStudentListView.vue";
import EmployerEditTheirProfileView from "@/views/Employer/EmployerEditTheirProfileView.vue";
import EmployerCreateJobPostingView from "@/views/Employer/EmployerCreateJobPostingView.vue";
import EmployerUpdateJobPostingView from "@/views/Employer/EmployerUpdateJobPostingView.vue";
import SchoolPartnerOfficer_EmployerProfileListView from "@/views/Employer/SchoolPartnerOfficer_EmployerProfileListView.vue";
import SchoolPartnerOfficer_CreateEmployerProfileView from "@/views/Employer/SchoolPartnerOfficer_CreateEmployerProfileView.vue";
import SchoolPartnerOfficer_UpdateEmployerProfileView from "@/views/Employer/SchoolPartnerOfficer_UpdateEmployerProfileView.vue";

import JobPostedListView from "@/views/Employer/JobPostedListView.vue";
import EmployerJobPostingListView_Draft from "@/views/Employer/EmployerJobPostingListView_Draft.vue";
import EmployerJobPostingListView_InProcess from "@/views/Employer/EmployerJobPostingListView_InProcess.vue";
import EmployerJobPostingListView_Expired from "@/views/Employer/EmployerJobPostingListView_Expired.vue";
import EmployerJobPostingListView_History from "@/views/Employer/EmployerJobPostingListView_History.vue";
import EmployerJobPosting_Applications_View from "@/views/Employer/EmployerJobPosting_Applications_View.vue";
import EmployerProfileInfo from "@/views/Employer/EmployerProfileInfo.vue";
import VerifyEmail from "@/views/Employer/VerifyEmail.vue";

export default [
  {
    path: "/Employer",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Employer/EmployerEditTheirProfileView",
        name: "EmployerEditTheirProfileView",
        component: EmployerEditTheirProfileView,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลส่วนตัว",
        },
      },

      {
        path: "/Employer/EmployerProfileListView",
        name: "EmployerProfileListView",
        component: EmployerProfileListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายชื่อสถานประกอบการ",
        },
      },
      {
        path: "/Employer/CreateEmployerProfileView",
        name: "CreateEmployerProfileView",
        component: CreateEmployerProfileView,
        meta: {
          requiresAuth: true,
          nameTh: "เพิ่มข้อมูลสถานประกอบการ",
        },
      },
      {
        path: "/Employer/UpdateEmployerProfileView/:oid",
        name: "UpdateEmployerProfileView",
        component: UpdateEmployerProfileView,
        meta: {
          requiresAuth: true,
          nameTh: "แก้ไขข้อมูลสถานประกอบการ",
        },
      },
      {
        path: "/Employer/EmployerCreateJobPostingView",
        name: "EmployerCreateJobPostingView",
        component: EmployerCreateJobPostingView,
        meta: {
          requiresAuth: true,
          nameTh: "ประกาศรับสมัครงาน",
        },
      },
      {
        path: "/Employer/EmployerUpdateJobPostingView/:oid",
        name: "EmployerUpdateJobPostingView",
        component: EmployerUpdateJobPostingView,
        meta: {
          requiresAuth: true,
          nameTh: "แก้ไขประกาศรับสมัครงาน",
        },
      },

      {
        path: "/Employer/EmployerBookmarkedStudentListView",
        name: "EmployerBookmarkedStudentListView",
        component: EmployerBookmarkedStudentListView,
        meta: {
          requiresAuth: true,
          nameTh: "ประวัติคนหางานที่จัดเก็บไว้",
        },
      },

      {
        path: "/Employer/SchoolPartnerOfficer_EmployerProfileListView",
        name: "SchoolPartnerOfficer_EmployerProfileListView",
        component: SchoolPartnerOfficer_EmployerProfileListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายชื่อสถานประกอบการ",
        },
      },

      {
        path: "/Employer/JobPostedListView",
        name: "JobPostedListView",
        component: JobPostedListView,
        meta: {
          requiresAuth: true,
          nameTh: "ประวัติประกาศรับสมัครงาน",
        },
      },

      {
        path: "/Employer/EmployerJobPosting_Applications_View/:oid",
        name: "EmployerJobPosting_Applications_View",
        component: EmployerJobPosting_Applications_View,
        meta: {
          requiresAuth: true,
          nameTh: "ติดตามการสมัครงาน",
        },
      },

      {
        path: "/Employer/EmployerJobPostingListView_Draft",
        name: "EmployerJobPostingListView_Draft",
        component: EmployerJobPostingListView_Draft,
        meta: {
          requiresAuth: true,
          nameTh: "ประกาศรับสมัครงานที่ร่างไว้",
        },
      },

      {
        path: "/Employer/EmployerJobPostingListView_InProcess",
        name: "EmployerJobPostingListView_InProcess",
        component: EmployerJobPostingListView_InProcess,
        meta: {
          requiresAuth: true,
          nameTh: "ประกาศรับสมัครที่อยู่ระหว่างรับสมัคร",
        },
      },

      {
        path: "/Employer/EmployerJobPostingListView_Expired",
        name: "EmployerJobPostingListView_Expired",
        component: EmployerJobPostingListView_Expired,
        meta: {
          requiresAuth: true,
          nameTh: "ประกาศที่หมดอายุ",
        },
      },

      {
        path: "/Employer/EmployerJobPostingListView_History",
        name: "EmployerJobPostingListView_History",
        component: EmployerJobPostingListView_History,
        meta: {
          requiresAuth: true,
          nameTh: "ประวัติประกาศรับสมัครงาน",
        },
      },

      {
        path: "/Employer/SchoolPartnerOfficer_CreateEmployerProfileView",
        name: "SchoolPartnerOfficer_CreateEmployerProfileView",
        component: SchoolPartnerOfficer_CreateEmployerProfileView,
        meta: {
          requiresAuth: true,
          nameTh: "เพิ่มข้อมูลสถานประกอบการ",
        },
      },

      {
        path: "/Employer/SchoolPartnerOfficer_UpdateEmployerProfileView/:oid",
        name: "SchoolPartnerOfficer_UpdateEmployerProfileView",
        component: SchoolPartnerOfficer_UpdateEmployerProfileView,
        meta: {
          requiresAuth: true,
          nameTh: "แก้ไขข้อมูลสถานประกอบการ",
        },
      },

      {
        path: "/Employer/EmployerProfileInfo/:oid",
        name: "EmployerProfileInfo",
        component: EmployerProfileInfo,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลสถานประกอบการ",
        },
      },

      {
        path: "/Employer/VerifyEmail/:Token",
        name: "VerifyEmail",
        component: VerifyEmail,
        meta: {
          nameTh: "ยืนยันอีเมล",
        },
      },
    ],
  },
];
