s
<template>
  <div class="pa-4 mb-5">
    <v-row class="my-2">
      <v-col cols="12" class="d-flex align-center mb-0 pb-0">
        <v-btn
          :disabled="btndisable"
          v-if="graudatedStudentJobTrackingReport"
          @click="assignManagerProfileCertifier()"
          class="ma-1"
          color="success"
        >
          <v-icon class="mr-1">mdi-reminder</v-icon> ส่งรายงานการติดตาม
        </v-btn>

        <!-- <v-btn v-if="isJobTracking" :loading="isExporting" @click="onExport()" dark color="teal" class="ma-2">
            <v-icon class="mr-1">mdi-file-excel</v-icon>ส่งออกต้านแบบรายงาน
            </v-btn> -->
      </v-col>
      <v-col v-show="!btndisable" cols="12" class="mx-2">
        <v-icon>mdi-alert-circle-outline</v-icon>
        <span class="red--text">
          สถานศึกษาต้องทำการ "รับรองรายงานการติดตาม" เพื่อเป็นการยืนยันข้อมูลและ
          ส่งรายงาน ซึ่งสถานะการติดตามจะเปลี่ยนเป็น "รับรองรายงานแล้ว"
          จึงจะถือว่าสถานศึกษาได้ทำการส่งรายงานการติดตามเป็นที่เรียบร้อยแล้ว
        </span></v-col
      >
      <!-- <v-sheet
        :disabled="btndisable"
        class="d-flex align-center justify-center flex-wrap text-center mx-auto pa-2 ma-5"
        elevation="3"
        rounded
        max-width="800"
        width="100%"
      >
        
      </v-sheet> -->
    </v-row>

    <GraduatedStudentJobTrackingReportInfo
      v-if="graudatedStudentJobTrackingReport"
      :graduatedStudentJobTrackingReport="graudatedStudentJobTrackingReport"
      @onFilteringGraduatedStudentJobTrackingReportInfo="
        onFilteringGraduatedStudentJobTrackingReportInfo
      "
    >
    </GraduatedStudentJobTrackingReportInfo>

    <div>
      <v-card rounded elevation="1" class="pa-4 my-5">
        <div class="mx-5">
          <v-row v-if="related_CertificateTypes.length > 0">
            <v-col lg="12" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">ระดับชั้น</h4>
              <v-row no-gutters align="center" wrap>
                <v-btn
                  :color="selectedCertificateTypeCode === '' ? 'primary' : ''"
                  @click="
                    selectedCertificateTypeCode = '';
                    onSelected();
                  "
                  class="mr-2 d-flex align-center py-0"
                >
                  ทั้งหมด
                </v-btn>
                <v-col
                  v-for="(certificate, index) in related_CertificateTypes"
                  :key="certificate.code"
                  cols="auto"
                  class="d-flex"
                >
                  <v-btn
                    :color="
                      selectedCertificateTypeCode === certificate.code
                        ? 'primary'
                        : ''
                    "
                    @click="
                      selectedCertificateTypeCode = certificate.code;
                      onSelected();
                    "
                    class="mr-2"
                    style="background-color: #f2f4f8"
                  >
                    {{ certificate.nameth }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              lg="6"
              md="12"
              sm="12"
              xs="12"
              class="custom-col mb-0 pb-0"
              v-if="studentDetails"
            >
              <h4 class="primary--text mb-2">สาขาวิชา</h4>
              <v-autocomplete
                clearable
                :items="related_Majors"
                item-text="majornameth"
                item-value="majornameth"
                label="ทั้งหมด"
                @change="onSelected()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="selectedMajorNameTh"
              >
              </v-autocomplete>
            </v-col>

            <v-col
              lg="6"
              md="12"
              sm="12"
              xs="12"
              class="custom-col mb-0 pb-0"
              v-if="studentDetails"
            >
              <h4 class="primary--text mb-2">สถานะการมีงานทำ</h4>
              <v-select
                clearable
                v-model="selectedEmploymentStatusCode"
                :items="related_EmploymentStatuses"
                label="ทั้งหมด"
                item-text="name"
                item-value="code"
                @change="onSelected()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <SearchableDataTablePaging
        table_caption="รายชื่อนักศึกษาที่ติดตาม"
        :data_items="studentDetails"
        :datatable_headers="studentDetails_headers"
        :itemlength="itemlength"
        :search="keyword"
        @onkeywordchanged="onKeywordChanged"
        @pageChanged="pageChanged"
        @tableOptionsChanged="tableOptionsChanged"
      >
        <template v-slot:item.employmentstatusname="{ item }">
          <v-text-field
            readonly
            v-model="item.employmentstatusname"
            :error-messages="
              isSentValidate == true ? item.employmentstatus_errormessage : ''
            "
          >
          </v-text-field>
        </template>
        <template v-slot:item.student_firstname="{ item }">
          <span>{{ item.student_firstname }} {{ item.student_surname }}</span>
        </template>
        <template v-slot:item.editdatedisplay="{ item }">
          <div v-if="item.editdatedisplay">
            <span
              >{{
                moment(item.editdatedisplay)
                  .add(543, "year")
                  .locale("TH")
                  .format("lll")
              }}
            </span>
          </div>
        </template>
        <template v-slot:item.action="{ item, index }">
          <v-row class="custom-width">
            <v-btn
              color="warning"
              dark
              @click="
                (JobTracking_dialog = true),
                  (selectedstudentdetail_oid = item.oid)
              "
              icon
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                  color="secondary"
                  @click="openStudentDetailView(item)"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <span>รายละเอียด</span>
            </v-tooltip></v-row
          >
        </template>
      </SearchableDataTablePaging>
    </div>

    <DialogFormJobTracking
      :dialog="JobTracking_dialog"
      :oid="selectedstudentdetail_oid"
      @closeDialogFormJobTracking="OnCloseDialogJobTracking"
    ></DialogFormJobTracking>

    <v-dialog v-model="report_dialog" max-width="500px">
      <!-- Dialog content -->
      <v-card>
        <v-card-title>
          <h3>รายชื่อรายงาน</h3>
        </v-card-title>
        <v-card-text>
          <ul>
            <li v-for="report in reports" :key="report.id">
              <a @click="onReportItemClick(report)" target="_blank">{{
                report.name
              }}</a>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="report_dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";

import DialogFormJobTracking from "@/components/GraduatedStudentJobTrackingReport/DialogFormJobTracking.vue";
import ExtendTrackingEndDate from "@/components/GraduatedStudentJobTrackingReport/ExtendTrackingEndDate.vue";

import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { GraduatedStudentJobTrackingReportValidator } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportValidator";
import { ExcelExportService } from "@/services/ExcelExportService";
import { formatDateThai } from "@/DateTimeHelper";
import GraduatedStudentJobTrackingReportInfo from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportInfo.vue";
// import XLSX from "xlsx";
// import { RoleCodes } from "@/Constants";
// XLSX = require("xlsx");
import moment from "moment";
import GraduatedStudentJobTrackingReport_JobTracking_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_JobTracking_Status_Label.vue";
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { GraduatedStudentJobTrackingReportsRequest } from "@/models/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReports_Request";
import { GraduatedStudentJobTrackingReportFilterOptionDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportFilterOptionDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";

const graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
const graduatedStudentJobTrackingReportValidator =
  new GraduatedStudentJobTrackingReportValidator(
    process.env.VUE_APP_BACKEND_API_URL
  );

const headers = { "Content-Type": "application/json" };

const excelExportService = new ExcelExportService(
  process.env.VUE_APP_BACKEND_API_URL
);

const graduatedStudentJobTrackingReportFilterOptionDataProvider =
  new GraduatedStudentJobTrackingReportFilterOptionDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );

const majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: {
    SearchableDataTable,
    DialogFormJobTracking,
    ExtendTrackingEndDate,
    GraduatedStudentJobTrackingReportInfo,
    GraduatedStudentJobTrackingReport_JobTracking_Status_Label,
    SearchableDataTablePaging,
  },
  name: "GraudatedStudentJobTrackingReportDetailView_GuidanceTeacher",
  data() {
    return {
      show: true,
      moment: moment,
      jobTrackingReportOid: "",
      graudatedStudentJobTrackingReport: undefined,
      studentDetails_headers: [
        {
          value: "student_veceducationinformation_studentcode",
          text: "รหัสนักศึกษา",
        },
        { value: "student_firstname", text: "ชื่อ-นามสกุล" },
        { value: "student_surname", align: " d-none" },
        {
          value: "student_veceducationinformation_gradenameth",
          text: "ระดับชั้น",
        },
        {
          value: "student_veceducationinformation_majornameth",
          text: "สาขาวิชา",
        },
        { value: "editdatedisplay", text: "วันที่แก้" },
        { value: "employmentstatusname", text: "ภาวะการมีงานทำ" },
        { value: "action" },
      ],
      validateobjectresults: undefined,
      excelDataSource: undefined,
      studentDetails: undefined,
      studentDetailsTemp: [],
      isLoading: false,
      isExporting: false,
      excel_importDialog: false,
      inputfile: undefined,
      isImporting: false,
      isSentValidate: undefined,

      JobTracking_dialog: false,
      update_trackingenddate: "",
      report_dialog: false,
      reports: [
        {
          id: 1,
          name: "รายงานภาวะการมีงานทำและศึกษาต่อของผู้สำเร็จการศึกษา",
          report_name:
            "GraduatedStudentJobTrackingReport_ConcludeEmploymentStatus_Report",
        },
        {
          id: 2,
          name: "รายงานภาวะการมีงานทำตามรายชื่อนักเรียน / นักศึกษา",
          report_name: "GraduatedStudentJobTrackingReport_StudentDetail_Report",
        },
      ],
      selectedstudentdetail_oid: "",
      FilterStudents: [],
      disablemajorfilter: true,
      FilterGrades: [],
      SelectedFilterGrade: "",
      FilterMajors: [],
      SelectedFilterMajor: "",
      BtnAssignDisabled: true,
      request: new GraduatedStudentJobTrackingReportsRequest(),
      keyword: "",
      search: "",
      itemlength: 0,
      gender: undefined,
      selectedGender: "",
      tableOptions: undefined,
      filterGraduatedStudent: undefined,
      IsComponentCreatedFirstime: false,

      selectedMajorNameTh: "",
      related_Majors: undefined,
      selectedCertificateTypeCode: "",
      related_CertificateTypes: undefined,
      selectedEmploymentStatusCode: "",
      related_EmploymentStatuses: undefined,
      filtering_jobtracking_isTracked: undefined,
    };
  },
  computed: {
    btndisable() {
      if (this.graudatedStudentJobTrackingReport == undefined) {
        return true;
      }
      if (
        this.graudatedStudentJobTrackingReport.jobtracking_status_statuscode ==
          "NotInTime" ||
        this.graudatedStudentJobTrackingReport.jobtracking_status_statuscode ==
          "Verified"
      ) {
        return true;
      }

      return (
        this.graudatedStudentJobTrackingReport.countstudentjobtracked !==
        this.graudatedStudentJobTrackingReport
          .quantityofgraduatedstudentjobtrackingreport_studentdetails
      );
    },
  },
  methods: {
    async loadFilterOptions() {
      await graduatedStudentJobTrackingReportFilterOptionDataProvider
        .GetRelatedCertificateTypes(this.jobTrackingReportOid)
        .then((result) => {
          this.related_CertificateTypes = result.data;
        });

      await majorDataProvider
        .getMajorNamesByGraduatedStudentJobTrackingReport(
          this.jobTrackingReportOid,
          this.selectedCertificateTypeCode
        )
        .then((result) => {
          this.related_Majors = result.data;
        });

      await majorDataProvider
        .getMajorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
          this.graudatedStudentJobTrackingReport.schoolid
        )
        .then((result) => {
          this.related_Majors = result.data;
          console.log(this.related_Majors);
        });

      await graduatedStudentJobTrackingReportFilterOptionDataProvider
        .GetRelatedEmploymentStatuses(this.jobTrackingReportOid)
        .then((result) => {
          this.related_EmploymentStatuses = result.data;
        });
    },

    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },
    openStudentDetailView(item) {
      var studentoid = item.student_oid;
      this.$router.push({
        name: "StudentDetailView",
        params: { studentoid: studentoid },
      });
    },
    async loadGraduatedStudentJobTrackingReport(oid = "") {
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          this.graudatedStudentJobTrackingReport = result.data;
          console.log(this.graudatedStudentJobTrackingReport);
          this.request.GraduatedStudentJobTrackingReport_Oid =
            this.graudatedStudentJobTrackingReport.oid;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadStudentDetails() {
      this.request.search = this.keyword;
      this.request.isTracked = this.filtering_jobtracking_isTracked;
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReportStudentDetailsWithPagination(
          this.request
        )
        .then((response) => {
          this.studentDetails = response.data.items;
          this.itemlength = response.data.quantity;
        })
        .catch((err) => err);
    },

    async onSelected() {
      this.$store.dispatch("showLoading");
      this.request.selected_certificatetype_code =
        this.selectedCertificateTypeCode;
      this.request.selected_majornameth = this.selectedMajorNameTh;
      this.request.selected_employmentstatus_code =
        this.selectedEmploymentStatusCode;

      await majorDataProvider
        .getMajorNamesByGraduatedStudentJobTrackingReport(
          this.jobTrackingReportOid,
          this.selectedCertificateTypeCode
        )
        .then((result) => {
          this.related_Majors = result.data;
        });

      await this.loadStudentDetails();
      this.$store.dispatch("hideLoading");
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
      this.request.itemPerPage = newtableOptions.itemsPerPage;
    },
    async onKeywordChanged(newKeyword) {
      this.keyword = newKeyword;
      await this.loadStudentDetails();
    },
    pageChanged(pageChangeValue) {
      if (this.IsComponentCreatedFirstime) {
        this.request.page = pageChangeValue;
        this.loadStudentDetails();
      } else {
        this.IsComponentCreatedFirstime = true;
      }
    },

    async getGraduatedStudentJobTrackingReportStudentDetails_ExcelDataSource() {
      if (this.studentDetails != undefined) {
        await excelExportService
          .GetGraduatedStudentJobTrackingReportStudentDetails(
            this.jobTrackingReportOid
          )
          .then((result) => {
            this.excelDataSource = result.data;
          });
      }
    },

    async onExport() {
      this.isExporting = true;
      await excelExportService
        .GetGraduatedStudentJobTrackingReportStudentDetails(
          this.jobTrackingReportOid
        )
        .then((result) => {
          excelExportService.createExcelFromJson(result.data);
        });
      this.isExporting = false;
    },

    onFileInputChange(file) {
      this.inputfile = file;
    },

    async onImportExcelBtnClick() {
      if (this.inputfile) {
        await this.getGraduatedStudentJobTrackingReportStudentDetails_ExcelDataSource(
          this.jobTrackingReportOid
        );
        this.readExcelFile(this.inputfile);
      }
    },

    onReportItemClick(item) {
      const report_name = item.report_name;
      this.openReportView(report_name);
    },

    openReportView(report_name = "") {
      this.$router.push({
        name: "GraduatedStudentJobTrackingReportDetailView_Report",
        params: {
          Oid: this.jobTrackingReportOid,
          report_name: report_name,
        },
      });
    },

    async validate() {
      this.isSentValidate = true;
      var oid = this.jobTrackingReportOid;
      await graduatedStudentJobTrackingReportValidator
        .validateGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          const returnData = result.data;
          if (returnData.validateresult == false) {
            this.$toast.error("กรุณากรอกข้อมูลให้ครบถ้วน และถูกต้อง");
          } else {
            this.$toast.success("รายงานการติดตามนี้ถูกต้องและครบถ้วน");
          }
        });
    },
    async assignManagerProfileCertifier() {
      //Validate the report
      await this.loadStudentDetails(this.jobTrackingReportOid);

      const oid = this.jobTrackingReportOid;
      await graduatedStudentJobTrackingReportValidator
        .validateGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          const returnData = result.data;
          if (returnData.validateresult == false) {
            this.isSentValidate = true;
            this.$toast.error("กรุณากรอกข้อมูลให้ครบถ้วน และถูกต้อง");
          } else {
            this.$router.push({
              name: "GraduatedStudentJobTrackingReportDetailView_CertificationAndSendReport",
              params: {
                graduatedStudentJobTrackingReportOid: this.jobTrackingReportOid,
              },
            });
          }
        });
    },

    OnCloseDialogJobTracking(isComplete, employmentstatusname) {
      this.JobTracking_dialog = false;
      if (isComplete) {
        let index = this.studentDetails.findIndex(
          (el) => el.oid === this.selectedstudentdetail_oid
        );
        this.studentDetails[index].editdatedisplay = Date.now();
        if (
          this.graudatedStudentJobTrackingReport.countstudentjobtracked == 0
        ) {
          this.graudatedStudentJobTrackingReport.jobtracking_status_statuscode =
            "InProcessOfTracking";
          this.graudatedStudentJobTrackingReport.jobtracking_status_statusgroupname =
            "GraduatedStudentJobTrackingReport_JobTracking";
          this.graudatedStudentJobTrackingReport.jobtracking_status_statusname =
            "อยู่ระหว่างติดตามภาวะการมีงานทำ";
        }
        if (!this.studentDetails[index].employmentstatusname) {
          this.graudatedStudentJobTrackingReport.countstudentjobtracked++;
        }
        this.studentDetails[index].employmentstatusname = employmentstatusname;
      }
    },
    async onFilteringGraduatedStudentJobTrackingReportInfo(isTracked) {
      this.filtering_jobtracking_isTracked = isTracked;
      await this.loadStudentDetails();
    },
  },

  async created() {
    this.$store.dispatch("showLoading");
    this.jobTrackingReportOid = this.$route.params.trackingReportOid;
    await this.loadGraduatedStudentJobTrackingReport(this.jobTrackingReportOid);
    await this.loadStudentDetails();
    await this.loadFilterOptions();
    this.$store.dispatch("hideLoading");
  },
};
</script>

<style scoped>
.custom-width {
  width: 120px;
  gap: 20px;
}

@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
