import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
export class EmailSenderDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    SendNewAccountEmail(receiveremail,displayname,username) {
        const headers = create_default_request_header();
        var data = JSON.stringify({ receiveremail: receiveremail, displayname: displayname ,username: username});
        var endpointPath = "/api/Email/SendNewAccountEmail";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, data, headers);
    }

    SendForgotPasswordEmail(receiveremail,displayname,url) {
        const headers = create_default_request_header();
        var data = JSON.stringify({ receiveremail: receiveremail, displayname: displayname ,url: url});
        var endpointPath = "/api/Email/SendForgotPasswordEmail";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, data, headers);
    }

    currentEmployerVerifyEmailAddress() {
        const headers = create_default_request_header();
        var endpointPath = "/api/Email/currentEmployerVerifyEmailAddress";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, null, headers);
    }
}