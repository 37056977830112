<template>
  <v-card class="pa-10">
    <h2 class="primary--text pt-5">
      <v-icon class="primary--text">mdi-folder-plus</v-icon>
      สร้างรายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา
    </h2>
    <v-divider class="mt-4 mb-5 mx-0"></v-divider>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-select
            :rules="[(v) => !!v || 'กรุณาเลือกเป้าหมายปีการศึกษาที่จะติดตาม']"
            label="เป้าหมายปีการศึกษาที่จะติดตาม"
            :items="yearth_choices"
            v-model="selected_yearth"
            @change="getNextRoundNumber()"
            background-color="input"
            filled
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12">
          <v-text-field
            label="รอบที่"
            readonly
            v-model="round_number"
            background-color="input"
            filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <TextFieldDatePicker
            :rules="[(v) => !!v || 'กรุณาระบุวันที่เริ่มติดตาม']"
            :max="selected_trackingenddate"
            :value="selected_trackingstartdate"
            caption="วันที่เริ่มติดตาม"
            @closeAndUpdate="
              this.closeAndUpdateTextFieldDatePicker_TrackingStartDate
            "
          >
          </TextFieldDatePicker>
        </v-col>
        <v-col cols="6">
          <TextFieldDatePicker
            :rules="[(v) => !!v || 'กรุณาระบุวันที่สิ้นสุดการติดตาม']"
            :value="selected_trackingenddate"
            :min="selected_trackingstartdate"
            caption="วันที่สิ้นสุดการติดตาม"
            @closeAndUpdate="
              this.closeAndUpdateTextFieldDatePicker_TrackingEndDate
            "
          >
          </TextFieldDatePicker>
        </v-col>
      </v-row>
      <v-row class="align-content-center text-center mt-3">
        <v-col>
          <v-btn
            class="ma-2 back-button"
            @click="onCreatingProcessIsCompleted()"
            >ยกเลิก</v-btn
          >
          <v-btn
            :loading="isInCreatingProcess"
            @click="confirm_dialog = true"
            class="ma-2 color-button2"
          >
            ยืนยัน <v-icon class="ml-2">mdi-check-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="confirm_dialog" width="400">
        <template>
          <v-card class="pa-6">
            <v-row class="justify-center text-center">
              <v-card-title> ยืนยันสร้างรายงานการติดตาม ? </v-card-title>
              <v-col cols="12">
                <v-btn @click="confirm_dialog = false" class="ma-2 back-button"
                  >ยกเลิก</v-btn
                >
                <v-btn
                  @click="onSubmitButtonClick()"
                  class="ma-2 color-button2"
                >
                  ยืนยัน
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-dialog>
    </v-form>
  </v-card>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import TextFieldDatePicker from "../common/TextFieldDatePicker.vue";
import { CreateGraduatedStudentJobTrackingReportMain_Request } from "@/models/GraduatedStudentJobTrackingReport/CreateGraduatedStudentJobTrackingReportMain_Request";
var jobTrackingDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "CreateGraduatedStudentJobTrackingReportMain",
  data() {
    return {
      yearth_choices: [],
      selected_yearth: "",
      semesters_choices: [],
      selected_semesters: "",
      round_number: 1,
      quantity_of_school: 0,
      quantity_of_student: 0,
      selected_trackingstartdate: "",
      selected_trackingenddate: "",
      isInCreatingProcess: false,
      confirm_dialog: false,
      confirm_result: false,
      valid: true,
    };
  },
  methods: {
    loadYearTH_Choices() {
      commonDataProvider
        .getListOfYearTH()
        .then((result) => {
          this.yearth_choices = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    loadSemesters_Choices() {
      commonDataProvider
        .getListOfSemesters()
        .then((result) => {
          this.semesters_choices = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    getQuantityOfSchool() {
      commonDataProvider
        .getQuantityOfSchool()
        .then((result) => {
          this.quantity_of_school = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    getQuantityOfStudent() {
      var yearTH = this.selected_yearth;
      var semesters = this.selected_semesters;
      if (yearTH != "" && semesters != "") {
        commonDataProvider
          .getQuantityOfStudent(yearTH, semesters)
          .then((result) => {
            this.quantity_of_student = result.data;
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    getNextRoundNumber() {
      var yearTH = this.selected_yearth;
      commonDataProvider.getNextNumberOfRound(yearTH).then((result) => {
        this.round_number = result.data;
      });
    },

    closeAndUpdateTextFieldDatePicker_TrackingStartDate(selectedDate = "") {
      this.selected_trackingstartdate = selectedDate;
    },

    closeAndUpdateTextFieldDatePicker_TrackingEndDate(selectedDate = "") {
      this.selected_trackingenddate = selectedDate;
    },

    createJobTrackingReportMainRequest() {
      //Get selected value
      var request = new CreateGraduatedStudentJobTrackingReportMain_Request();
      request.targetgraduatedyearth = this.selected_yearth;
      request.roundnumber = this.round_number;
      request.trackingstartdate = this.selected_trackingstartdate;
      request.trackingenddate = this.selected_trackingenddate;
      return request;
    },

    onSubmitButtonClick() {
      if (this.$refs.form.validate()) {
        this.isInCreatingProcess = true;
        this.confirm_dialog = false;

        var request = this.createJobTrackingReportMainRequest();
        jobTrackingDataProvider
          .CreateGraduatedStudentJobTrackingReportMain(request)
          .then((result) => {
            this.$toast.success("สร้างรายงานเรียบร้อย");
            this.selected_yearth = "";
            this.round_number = "";
            this.selected_trackingstartdate = "";
            this.selected_trackingenddate = "";
            this.onCreatingProcessIsCompleted();
          })
          .catch((err) => {
            alert(err);
          });
      }
    },

    onCreatingProcessIsCompleted() {
      this.isInCreatingProcess = false;

      this.$emit("onEmitCreatingProcessCompleted", "");
    },

    onCreatingProcessIsError() {
      this.isInCreatingProcess = false;

      this.$emit("onCreatingProcessIsError", "");
    },
  },
  mounted() {
    this.loadYearTH_Choices();
    this.loadSemesters_Choices();
    this.getQuantityOfSchool();
  },
  components: { TextFieldDatePicker },
};
</script>

<style></style>
