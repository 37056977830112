<template>
  <v-menu
    v-model="local_isDisplay"
    :aria-readonly="readonly"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        @input="local_value"
        :label="caption"
        :disabled="disabled"
        prepend-icon="mdi-calendar"
        :value="
          local_value
            ? moment(local_value).add(543, 'year').locale('TH').format('LL')
            : ''
        "
        :rules="rules"
        readonly
        v-bind="attrs"
        v-on="on"
        background-color="input"
        filled
      >
      </v-text-field>
    </template>
    <v-date-picker
      :min="min"
      :max="max"
      :readonly="readonly"
      mode="date"
      locale="th"
      v-model="local_value"
      @input="closeAndUpdateTextFieldDatePicker()"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  name: "TextFieldDatePicker",
  data() {
    return {
      moment: moment,
      local_isDisplay: this.isDisplay,
      local_value: this.value,
      local_min: this.min,
    };
  },
  watch: {
    value: {
      handler(val, oldVal) {
        this.local_value = val;
      },
      immediate: true,
    },
    isDisplay: {
      handler(val) {
        if (val) {
          this.local_value = "";
        }
        this.local_isDisplay = false;
      },
      immediate: true,
    },
  },
  props: [
    "caption",
    "value",
    "isDisplay",
    "disabled",
    "readonly",
    "rules",
    "min",
    "max",
  ],
  methods: {
    closeAndUpdateTextFieldDatePicker() {
      this.$emit("closeAndUpdate", this.local_value);
      this.local_isDisplay = false;
    },
  },
  mounted() {
    this.local_isDisplay = false;
  },
};
</script>

<style>
</style>