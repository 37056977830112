import GovernmentListView from "@/views/Government/GovernmentListView.vue";

export default [
  {
    path: "/Government",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Government/GovernmentListView",
        name: "GovernmentListView",
        component: GovernmentListView,
        meta: {
          requiresAuth: true,
          nameTh: "หน่วยงานราชการ",
        },
      },
    ],
  },
];
