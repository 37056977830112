<template>
  <v-container class="d-flex justify-center">
    <v-col lg="10">
      <v-card class="elevation-1 pa-3 mt-5">
        <v-row class="justify-center mb-4 mt-2">
          <h4>ตั้งค่าเกี่ยวกับการประกาศงาน</h4>
        </v-row>
        <v-divider class="mt-4 mx-0 mb-4"></v-divider>

        <v-row class="d-flex justify-center pa-2">
          <v-col
            cols="12"
            md="6"
            lg="3"
            v-for="item in defaultjobpostinglifespans"
            :key="item.value"
          >
            <v-btn
              style="height: 50px"
              block
              :color="vm.defaultlifespan === item.value ? 'color-buton' : 'input'"
              @click="vm.defaultlifespan = item.value"
            >
              {{ item.text }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center mb-2 pa-2">
          <v-col cols="12" md="4" lg="2">
            <v-btn block @click="onSave" class="color-button2"
              ><v-icon color="white">mdi-content-save</v-icon> บันทึก</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import { DefaultJobPostingSetupDataProvider } from "@/services/DefaultJobPostingSetupDataProvider";
import { EditDefaultJobPostingSetup_Request } from "@/models/DefaultJobPostingSetups/EditDefaultJobPostingSetup_Request";
const defaultJobPostingSetupDataProvider = new DefaultJobPostingSetupDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "DefaultJobPostingSetupDetailView",
  data() {
    return {
      defaultjobpostinglifespans: [
        { text: "1 เดือน", value: 1 },
        { text: "3 เดือน", value: 2 },
        { text: "6 เดือน", value: 3 },
        { text: "ไม่กำหนดวันหมดอายุ", value: 99 },
      ],
      vm: new EditDefaultJobPostingSetup_Request(),
    };
  },
  methods: {
    async initialize() {
      await defaultJobPostingSetupDataProvider
        .getDefaultJobPostingSetup()
        .then((result) => {
          this.vm.defaultlifespan = result.data.defaultlifespan;
        });
    },
    async onSave() {
      await defaultJobPostingSetupDataProvider
        .editDefaultJobPostingSetup(this.vm)
        .then((result) => {
          this.$toast.success("บันทึกการตั้งค่าเรียบร้อย");
        });
      // console.log(this.vm.defaultlifespan);
    },
    
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.color-buton {
  background: #3f51b5 !important;
  color: white !important;
}
.color-btnsave {
  background: linear-gradient(
    150deg,
    rgb(17, 168, 255) 5%,
    rgb(46, 126, 220) 45%,
    rgb(62, 83, 183) 100%
  );
  color: white !important;
}
</style>
