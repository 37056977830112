<template>
  <div>
    <v-tooltip top color="success">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="open_ChangePasswordDialog()"
          color="success"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-lock-reset</v-icon>
        </v-btn>
      </template>
      <span>คลิกเพื่อรีเซ็ตรหัสผ่านใหม่</span>
    </v-tooltip>

    <v-dialog v-model="dialog" width="500" persistent>
      <template>
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-container class="pa-3">
              <v-row>
                <v-col cols="12">
                  <p>
                    การรีเซ็ตรหัสผ่านคือการล้าง "รหัสผ่าน" เดิมของผู้ใช้งานออก
                    เพื่อให้ผู้ใช้งานตั้งรหัสผ่านใหม่ในการเข้าใช้งานครั้งต่อไป
                    โดยในครั้งต่อไปที่เข้าใช้งานให้กรอกแต่ในส่วนของ "ชื่อผู้ใช้งาน
                    (username) เท่านั้น จากนั้นระบบจะเปิดหน้าจอสำหรับตั้งรหัสผ่านให้"
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <h2><v-icon>mdi-lock-reset</v-icon> ยืนยันการรีเซ็ตรหัสผ่านใหม่</h2>
                </v-col>
              </v-row>

              <v-row class="mt-2">
                <v-col cols="12" class="d-flex justify-center">
                  <v-btn class="ma-2" @click="save" color="success"
                    ><v-icon>mdi-check-circle-outline</v-icon> ยืนยัน</v-btn
                  >
                  <v-btn class="ma-2" @click="dialog = false">ยกเลิก</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { InstituteDataProvider } from "@/services/InstituteDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import SearchableTable from "@/components/common/SearchableDataTable.vue";
import { ApplicationRoleCodes } from "@/Constants";

const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

const instituteDataProvider = new InstituteDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  components: { SearchableTable },
  name: "SetChangePasswordWhenLogon",
  props: ["EmployeeProfileOid"],
  data() {
    return {
      changePasswordWhenLogon: [],
      dialog: false,
    };
  },
  methods: {
    open_ChangePasswordDialog() {
      this.dialog = true;
    },
    CreateUpdateSchoolEmployeeProfile_Request() {
      var request = new employerProfileDataProvider();
      request.EmployeeProfileOid = this.EmployeeProfileOid;
      return request;
    },
    save() {
      // this.isInCreatingProcess = true;
      // this.confirm_dialog = false;
      // var request = this.changePasswordRequest();
      employerProfileDataProvider
        .set_ChangePasswordWhenLogon(this.EmployeeProfileOid)

        .then((response) => {
          if (response?.status == 200) {
            this.$toast.success("ยืนยันการเปลี่ยนรหัส");
            this.dialog = false;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
};
</script>
