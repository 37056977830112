import axios from "axios";
import { AdminSearchStudentRequest } from "@/models/Student/AdminSearchStudentRequest";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";

export class AdminSearchStudentService {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }

  searchStudent(request = new AdminSearchStudentRequest()) {
    const header = create_default_request_header();
    var endpointPath = "/api/AdminSearchStudent/SearchStudent";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    var json = JSON.stringify(request);
    return axios.post(combinedURL, request, header);
  }
}
