export class StudyRecord {
    oid = '-1'
    student_oid = ''
    graduatedyear = ''
    educationalqualification = undefined
    selected_educationalqualification_code = ''
    schoolname = ''
    majornameth = ''
    minornameth = ''
    gpa = ''
}