import InstituteListView from "@/views/Institute/InstituteListView.vue";
import InstituteDetailView from "@/views/Institute/InstituteDetailView.vue";
export default [
  {
    path: "/Institute",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Institute/InstituteListView",
        name: "InstituteListView",
        component: InstituteListView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Institute/InstituteDetailView/:instituteID",
        name: "InstituteDetailView",
        component: InstituteDetailView,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
