import axios from "axios";
import { create_default_request_header } from "../constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { CreateCommonNotificationsRequest } from "@/models/Notifications/CreateCommonNotificationsRequest";
import { GetNotificationByUser_Request } from "@/models/Notifications/GetNotificationByUser_Request";

export class NotificationDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }
  PostMessage(message) {
    const header = create_default_request_header();
    const endpointUrl =
      "/SignalRApi/Notification/PostNotificationToAll/" + message;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  loadNotificationByUser() {
    const header = create_default_request_header();
    const endpointUrl = "/SignalRApi/Notification/GetNotificationByUser";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }
  getNotificationByUserPagination(
    request = new GetNotificationByUser_Request()
  ) {
    const header = create_default_request_header();
    const endpointUrl =
      "/SignalRApi/Notification/GetNotificationByUserPagination";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, request, header);
  }

  loadNotificationAll() {
    const header = create_default_request_header();
    const endpointUrl = "/SignalRApi/Notification/GetNotificationAll";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  MarkAsRead(Id) {
    const header = create_default_request_header();
    const endpointUrl = "/SignalRApi/Notification/MarkAsReadNoti/" + Id;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  GetCountUnReadNoti() {
    const header = create_default_request_header();
    const endpointUrl = "/SignalRApi/Notification/GetCountUnReadNoti";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  GetMiniNotificationByUser(itemPerPage) {
    const header = create_default_request_header();
    const endpointUrl =
      "/SignalRApi/Notification/GetMiniNotificationByUser/" + itemPerPage;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  MarkAllAsReadNoti() {
    const header = create_default_request_header();
    const endpointUrl = "/SignalRApi/Notification/MarkAllAsReadNoti";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  CreateCommonNotifications(request = new CreateCommonNotificationsRequest()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/Notification/CreateCommonNotifications/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, request, header);
  }
}
