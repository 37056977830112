<template>
  <div class="pa-4">
    <v-card rounded elevation="1" class="pa-2 my-0">
      <div class="d-flex flex-row">
        <v-row class="d-flex">
          <v-col class="pt-5" xl="2" md="2" lg="2" sm="12" xs="12">
            <h4 class="mb-2 mb-0 ml-3 primary--text px-2">กรองข้อมูล :</h4>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="6" md="6" class="mb-0 pb-0">
          <v-select
            :items="yearth_choices"
            label="ปีการศึกษาที่สำเร็จการศึกษา"
            v-model="input_yearth"
            class="select px-3 pl-5"
            @change="loadTrackingReports()"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6" md="6" class="mb-0 pb-0">
          <v-select
            clearable
            :items="semester_choices"
            label="รอบการติดตาม"
            v-model="input_round_number"
            @change="loadTrackingReports()"
            class="select px-3 pr-5"
            solo
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <v-container>
      <!-- <v-progress-linear v-if="isLoading == true" indeterminate color="success"></v-progress-linear> -->
    </v-container>
    <SearchableDataTable
      :data_items="job_tracking_reports"
      :datatable_headers="datatable_headers"
      table_caption="รายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา"
    >
      <template
        v-slot:item.quantityofgraduatedstudentjobtrackingreport_studentdetails="{ item }"
      >
        <span
          :class="[
            item.countstudentjobtracked ==
            item.quantityofgraduatedstudentjobtrackingreport_studentdetails
              ? 'success--text'
              : 'red--text',
          ]"
          >{{ item.countstudentjobtracked }}</span
        >/{{ item.quantityofgraduatedstudentjobtrackingreport_studentdetails }}
      </template>
      <template v-slot:item.status.statusname="{ item }">
        <JobTrackingReportStatus :status="item.status"></JobTrackingReportStatus>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openJobTrackingReportDetailView(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-folder-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>รายงานการติดตาม</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import axios from "axios";
import Popup_CreateGraduatedJobTrackingReportView from "@/views/GraduatedStudentJobTrackingReport/Popup_CreateGraduatedJobTrackingReportView.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { GetGraduatedStudentJobTrackingReports_Request } from "@/models/GraduatedStudentJobTrackingReport/GetGraduatedStudentJobTrackingReports_Request";
import JobTrackingReportStatus from "@/components/GraduatedStudentJobTrackingReport/JobTrackingReportStatus.vue";
const headers = { "Content-Type": "application/json" };
const commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const graduatedStudentJobTrackingReportReportDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: {
    Popup_CreateGraduatedJobTrackingReportView: () =>
      import(
        "@/views/GraduatedStudentJobTrackingReport/Popup_CreateGraduatedJobTrackingReportView.vue"
      ),
    Popup_CreateGraduatedJobTrackingReportView,
    SearchableDataTable,
    JobTrackingReportStatus,
  },
  name: "GraduatedStudentTrackingReportListView",
  data() {
    return {
      yearth_choices: [],
      semester_choices: [],
      input_yearth: "2564",
      input_round_number: "",
      datatable_headers: [
        { value: "schoolid", text: "รหัสสถานศึกษา" },
        { value: "schoolname", text: "ชื่อสถานศึกษา" },
        { value: "schoolprovincename", text: "จังหวัด" },
        { value: "targetgraduatedyearth", text: "ปีการศึกษาที่จบ" },
        { value: "roundnumber", text: "รอบที่ติดตาม" },
        {
          value: "quantityofgraduatedstudentjobtrackingreport_studentdetails",
          text: "จำนวนนักศึกษาที่สำเร็จการศึกษา",
        },
        { value: "status.statusname", text: "สถานะ" },
        { value: "action", text: "" , sortable: false},
      ],
      job_tracking_reports: [],
      display_Popup_CreateGraduatedJobTrackingReportView: false,
      isLoading: false,
    };
  },
  methods: {
    async loadChoices() {
      //Get yearth choices
      await commonDataProvider.getListOfYearTH().then((result) => {
        this.yearth_choices = result.data;
        this.yearth_choices.sort();
      });

      await commonDataProvider.getListOfSemesters().then((result) => {
        this.semester_choices = result.data;
        this.semester_choices.sort();
      });
    },
    async loadTrackingReports() {
      this.isLoading = true;
      this.job_tracking_reports = undefined;
      const request = new GetGraduatedStudentJobTrackingReports_Request();
      request.targetgraduatedyearth = this.input_yearth;
      if (this.input_round_number != "") {
        request.roundnumber = this.input_round_number;
      }
      await graduatedStudentJobTrackingReportReportDataProvider
        .LoadReports(request)
        .then((result) => {
          this.job_tracking_reports = result.data;
        });
      this.isLoading = false;
    },
    openJobTrackingReportDetailView(jobTrackingReport) {
      var trackingReportOid = jobTrackingReport.oid;
      this.$router
        .push({
          name: "GraduatedStudentJobTrackingReportDetailView",
          params: { trackingReportOid },
        })
        .catch((err) => {});
    },
    open_Popup_CreateGraduatedStudentJobTrackingReport() {
      this.display_Popup_CreateGraduatedJobTrackingReportView = true;
    },
  },
  mounted() {
    this.loadChoices();
    this.loadTrackingReports();
  },
  beforeCreate() {},
};
</script>
