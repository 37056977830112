<template>
  <v-container class="pa-10">
    <v-row class="d-flex justify-center mb-5">
      <h4 class="primary--text">หน้าจอสำหรับออกรายงาน</h4>
    </v-row>
    <v-row>
      <v-text-field
        label="ชื่อรายงาน"
        :value="reportTHName"
        filled
        background-color="input"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-select
        label="ประเภทไฟล์"
        :items="fileTypes"
        item-text="text"
        item-value="value"
        v-model="selectedFileTypeValue"
        filled
        background-color="input"
      ></v-select>
    </v-row>
    <v-row v-if="isSuccess" class="d-flex justify-center">
      <v-col class="">
        <p style="color: green">ดาวโหลดไฟล์รายงานเรียบร้อย</p>
        <p style="color: ">
          ชื่อไฟล์ : <strong>{{ message }}</strong>
        </p>
        <p style="color: ">
          หมายเหตุ : ไฟล์จะถูกจัดเก็บไว้ที่โฟลเดอร์ ตาม Browser ที่ท่านใช้งาน
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center" v-if="isError">
      <p style="color: red">{{ message }}</p>
    </v-row>
    <v-row v-if="isRetrying" class="d-flex justify-center">
      <p style="color: orange">Retrying... Attempt {{ retryAttempt }} of 3</p>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-btn
        :loading="isDownloading"
        @click="exportReport()"
        class="color-buton"
        >ดาวน์โหลด</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import { ReportServerProvider } from "@/services/ReportServerProvider";
import { create_default_request_header_for_blob_response } from "@/Constants";
const reportServerProvider = new ReportServerProvider(
  process.env.VUE_APP_REPORT_URL
);
export default {
  name: "ReportExport",
  props: ["report_name", "report_parameters"],
  data() {
    return {
      fileTypes: [
        { text: "PDF", value: "application/pdf", extension: ".pdf" },
        { text: "CSV", value: "text/csv", extension: ".csv" },
        {
          text: "Excel",
          value:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          extension: ".xls",
        },
      ],
      selectedFileTypeValue: "application/pdf",
      isDownloading: false,
      isSuccess: false,
      isError: false,
      isRetrying: false,
      maxAttempt: 3,
      retryAttempt: 0,
      message: "",
    };
  },
  computed: {
    reportTHName() {
      switch (this.report_name) {
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report":
          return "รายงานภาวะการมีงานทำ (รายภาค)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report":
          return "รายงานภาวะการมีงานทำ (รายจังหวัด)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report":
          return "รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report":
          return "รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report":
          return "รายงานภาวะการมีงานทำ (รายสถานศึกษา)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report":
          return "รายงานภาวะการมีงานทำ (รายประเภทวิชา)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report":
          return "รายงานการติดตามภาวะผู้มีงานทำของสถานศึกษา (จำแนกตามสาขาวิชา)";
        case "GraduatedStudentJobTrackingReportMain_StudentDetail_Report":
          return "รายงานสรุปผลการติดตาม (รายบุคคล)";
        case "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report":
          return "รายงานภาวะการมีงานทำ (รายประเภทวิชา)";
        case "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report":
          return "รายงานการติดตามภาวะผู้มีงานทำของสถานศึกษา (จำแนกตามสาขาวิชา)";
        case "School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report":
          return "รายงานสรุปผลการติดตาม (รายบุคคล)";
        case "GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince":
          return "รายงานสรุปผลการส่งรายงาน รายจังหวัด";
        case "GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion":
          return "รายงานสรุปผลการส่งรายงาน รายภาค";
        case "SchoolStudentList_Report":
          return "รายงานรายชื่อนักศึกษา";
      }
      return "";
    },
  },
  methods: {
    reset() {
      this.isSuccess = false;
      this.isError = false;
      this.message = "";
      this.retryAttempt = 0;
      this.isRetrying = false;
    },
    convertReportParametersToQueryString(reportParams = []) {
      return reportParams.join("&");
    },
    async exportReport() {
      this.reset();
      this.isDownloading = true;
      const convertedReportParamters =
        this.convertReportParametersToQueryString(this.report_parameters);
      const extensionName = this.fileTypes.find(
        (x) => x.value == this.selectedFileTypeValue
      ).extension;

      // Retry up to 3 times
      for (let attempt = 1; attempt <= this.maxAttempt; attempt++) {
        try {
          this.retryAttempt = attempt;
          this.isRetrying = true;
          const result = await reportServerProvider.getReportExport(
            this.report_name,
            this.selectedFileTypeValue,
            convertedReportParamters
          );

          // Create a blob from the response data
          const blob = new Blob([result.data], {
            type: this.selectedFileTypeValue,
          });

          // Create a link element and simulate a click to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const currentDate = new Date();
          const formattedDate =
            currentDate
              .toISOString()
              .slice(0, 19)
              .replace(/[-T]/g, "_")
              .replace(/:/g, "-") + "_";
          const fileName = this.report_name + formattedDate + extensionName;

          link.download = fileName;
          this.isDownloading = false;
          this.isSuccess = true;
          this.message = fileName;
          link.click();

          // Release the object URL
          window.URL.revokeObjectURL(link.href);

          // Exit the loop if successful
          break;
        } catch (error) {
          // Log the error and retry if not on the last attempt
          console.error(`Error downloading PDF (Attempt ${attempt}):`, error);
          if (attempt < 3) {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
          } else {
            // Set error state if reached maximum attempts
            this.isDownloading = false;
            this.isError = true;
            this.message = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งภายหลัง";
          }
        } finally {
          this.isRetrying = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  color: white !important;
}
</style>
