<template>
  <v-container>
    <div class="pa-4 my-4">
      <v-card class="elevation-2 pa-4">
        <h3 class="primary--text">เปลี่ยนรหัสผ่าน</h3>
        <v-divider class="mt-4 mb-4 mx-0"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col sm="12" md="12" lg="12">
              <v-text-field
                v-model="user"
                disabled
                :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้ใช้']"
                label="ชื่อบัญชีผู้ใช้"
                prepend-icon="mdi-account"
                required
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" md="12" lg="12">
              <v-text-field
                v-model="account.password"
                label="รหัสผ่าน"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                :rules="[
                  (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                  (v) => (v && v.length >= 6) || 'ต้องมีอย่างน้อย 6 ตัวอักษร',
                ]"
              />
            </v-col>
            <v-col sm="12" md="12" lg="12">
              <v-text-field
                v-model="account.confirmPassword"
                label="ยืนยันรหัสผ่าน"
                :rules="[
                  (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                  (v) => (v && v.length >= 6) || 'ต้องมีอย่างน้อย 6 ตัวอักษร',
                  (v) => v === account.password || 'รหัสผ่านไม่ตรง',
                ]"
                name="confirmPassword"
                prepend-icon="mdi-lock"
                type="password"
              />
            </v-col>
          </v-row>
          <v-row class="justify-center text-center">
            <v-col>
              <v-btn color="success" @click="submit" class="ma-2"
                ><v-icon class="mr-2">mdi-check-circle-outline</v-icon>ยืนยัน</v-btn
              >
              <v-btn color="" text @click="$router.go(-1)" class="ma-2">ย้อนกลับ</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <CustomSnackbar
        v-on:close-snackbar="closesnackbar"
        :color="colorsnack"
        :text="textsnack"
        :snackbar="statussnack"
      >
      </CustomSnackbar>
    </div>
  </v-container>
</template>
<script>
import { backendapiUrl } from "@/services/constants";
import { AccountProfileDataProvider } from "@/services/Profile/AccountProfileDataProvider";
var accountProfileDataProvider = new AccountProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import CustomSnackbar from "@/components/common/CustomSnackbar.vue";
export default {
  components: {
    CustomSnackbar,
  },
  data: () => ({
    statussnack: false,
    colorsnack: "",
    textsnack: "",
    valid: true,
    account: [],
    user: "",
  }),
  computed: {},
  methods: {
    async submit() {
      var validate = this.$refs.form.validate();
      if (validate) {
        this.account.username = this.user;
        await accountProfileDataProvider
          .updateAccountProfile(this.account)
          .then((result) => {
            if (result.status) {
              if (result.status == 200) {
                this.textsnack = "อัปเดตรหัสผ่านเรียบร้อย!";
                this.colorsnack = "success";
                this.statussnack = true;
              }
            }
          })
          .catch((err) => {
            this.textsnack = "ล้มเหลว!" + err;
            this.colorsnack = "red";
            this.statussnack = true;
          });
      }
    },
    closesnackbar: function (value) {
      if (value) {
        this.statussnack = false;
      }
    },
  },
  async created() {
    this.user = this.$route.params.username;
    if (this.user != "") {
      this.account.oid = this.$store.getters["applicationuser_oid"];
      this.account.username = this.$store.getters["username"];
    }
  },
};
</script>
<style></style>
