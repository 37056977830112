import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue2Filters from "vue2-filters"
import VueExcelEditor from 'vue-excel-editor'
import "jquery-ui/themes/base/all.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import 'quill/dist/quill.snow.css';
import Loading from './components/common/Loading.vue';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import { NewsStatusCodes}  from '@/Constants'

Vue.filter("NewsStatus", function (item) {
  switch(item) {
    case NewsStatusCodes.Draft:
      return "ร่าง"
    case NewsStatusCodes.WaitingApprove:
      return "รอการอนุมัติ"
    case NewsStatusCodes.Publish:
      return "เผยแพร่"
    case NewsStatusCodes.unPublish:
      return "ยกเลิกการเผยแพร่"
    default:
      return ""
  }
});




Vue.config.productionTip = false;

Vue.component('Loading', Loading);

new Vue({
  router,
  store,
  vuetify,
  VueToast,
  render: h => h(App)
}).$mount('#app')

Vue.use(Vue2Filters);
Vue.use(VueExcelEditor);
Vue.use(VueToast, { duration: 2000, position: 'top' });
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyCvN8hPvYMUiVSbtqzUmpnPGvpLay16tqA',
    key: 'AIzaSyBeoAEPJRwZ4aHG8olLyoud79nDsr9vJq4',
    libraries: 'places',
  },
})


