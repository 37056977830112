<template>
    <v-container class="">
        <v-row>
            <v-col>
                <h1>Create page</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id voluptatibus unde explicabo provident
                    maiores, quidem vel assumenda ratione tempora, rerum, illum natus consectetur possimus eius totam
                    iste optio enim eum.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {

}
</script>

<style>
</style>