<template>
  <div>
    <v-container>
      <v-row class="pt-6 px-3">
        <v-icon class="pr-2" color="primary">mdi-book-education</v-icon>
        <h3 class="primary--text">ประวัติการศึกษา</h3>
      </v-row>
      <v-row class="px-3" style="margin-top: 1%; margin-bottom: 2%">
        <v-divider></v-divider>
      </v-row>
      <v-row class="" v-if="study.length > 0 && $vuetify.breakpoint.lgAndUp && $vuetify.breakpoint.mdAndUp">
        <v-col
          v-for="(item, index) in study"
          :key="index"
          cols="6"
          
        >
          <v-card class="py-3">
            <v-row class="px-9 py-5">
              <v-col cols="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <v-row class="pb-3">
                  <h3>{{ item.schoolname ? item.schoolname : "ไม่มีชื่อ" }}</h3>
                </v-row>
                <v-row>
                  <span>{{ item.educationalqualificationnameth }}</span>
                </v-row>
                <v-row>
                  <span>ปีที่สำเร็จการศึกษา: {{ item.graduatedyear ? item.graduatedyear : "-" }}</span>
                </v-row>
                <v-row>
                  <span>คณะ: {{ item.majornameth ? item.majornameth : "-" }}</span>
                </v-row>
                <v-row>
                  <span>สาขา: {{ item.minornameth ? item.minornameth : "-" }}</span>
                </v-row>
                <v-row>
                  <span>เกรดเฉลี่ย: {{ item.gpa ? item.gpa : "-" }}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="" v-else-if="study.length > 0 && !$vuetify.breakpoint.lgAndUp && !$vuetify.breakpoint.mdAndUp">
        <v-col
          v-for="(item, index) in study"
          :key="index"
          cols="12"
          
        >
          <v-card class="py-3">
            <v-row class="px-9 py-5">
              <v-col cols="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <v-row class="pb-3">
                  <h3>{{ item.schoolname ? item.schoolname : "ไม่มีชื่อ" }}</h3>
                </v-row>
                <v-row>
                  <span>{{ item.educationalqualificationnameth }}</span>
                </v-row>
                <v-row>
                  <span>ปีที่สำเร็จการศึกษา: {{ item.graduatedyear ? item.graduatedyear : "-" }}</span>
                </v-row>
                <v-row>
                  <span>คณะ: {{ item.majornameth ? item.majornameth : "-" }}</span>
                </v-row>
                <v-row>
                  <span>สาขา: {{ item.minornameth ? item.minornameth : "-" }}</span>
                </v-row>
                <v-row>
                  <span>เกรดเฉลี่ย: {{ item.gpa ? item.gpa : "-" }}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      
      <v-row v-else class="">
        <v-col cols="12">
          <v-card class="py-16 px-3 d-flex justify-center" max-width="100%"
            ><span>ไม่มีข้อมูล</span></v-card
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { StudentDataProvider } from "@/services/StudentDataProvider";
import EducationStudentDialog from "@/components/Student/Education/EducationStudentDialog.vue";

var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  props: ["studentoid", "isInputDisabled"],
  components: {
    EducationStudentDialog,
  },
  data() {
    return {
      study: [], // Array to hold study data
    };
  },
  methods: {
    async LoadStudentStudy(studentoid) {
      try {
        const res = await studentDataProvider.getStudentStudyRecords(studentoid);
        if (res?.data?.length > 0) {
          this.study = res.data.map((item) => ({
            educationalqualificationnameth: item.educationalqualification.nameth,
            gpa: item.gpa,
            graduatedyear: item.graduatedyear,
            majornameth: item.majornameth,
            minornameth: item.minornameth,
            oid: item.oid,
            schoolname: item.schoolname,
          }));
        }
      } catch (error) {
        console.error("Failed to load student study records", error);
      }
    },
  },
  async mounted() {
    await this.LoadStudentStudy(this.studentoid);
  },
};
</script>

<style scoped>
.v-card {
  width: 100%;
  margin: 0 auto;
}
</style>
