<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2"> mdi-file-document-edit-outline</v-icon
            >สัญชาติ</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nationalityDetail.nationalityid"
                label="รหัสสัญชาติ"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nationalityDetail.nationalityth"
                label="ชื่อสัญชาติ (ไทย)"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nationalityDetail.nationalityeng"
                label="ชื่อสัญชาติ (อังกฤษ)"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "NationalitiesDetailView",
  data() {
    return {
      nationalityDetail: {},
      oid: "",
    };
  },

  methods: {
    async loadNationalityDetails() {
      await masterDataProvider.getNationalityByOid(this.oid).then((result) => {
        this.nationalityDetail = result.data;
      });
    },
  },

  async created() {
    this.oid = this.$route.params.oid;
    await this.loadNationalityDetails();
  },
};
</script>
<style></style>
