<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <!-- <v-row class="d-flex justify-center mt-2 mb-2">
        <h3 class="primary--text">เพิ่มข้อมูลสถานประกอบการ</h3>
      </v-row> -->

      <v-row class="d-flex justify-center">
        <v-col lg="10" md="12" sm="12" xs="12">
          <v-card>
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >เพิ่มข้อมูลสถานประกอบการ</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="d-flex justify-center">
              <v-col cols="10" class="mt-0">
                <div>
                  <v-tabs
                    v-if="!isMobile"
                    fixed-tabs
                    slider-size="5"
                    v-model="tab"
                    active-class="tab--text"
                  >
                    <v-tab>1. ข้อมูลทั่วไป</v-tab>
                    <v-tab>2. ผู้ติดต่อ / ผู้ประสานงาน</v-tab>
                    <v-tab>3. บัญชีผู้ใช้งาน</v-tab>
                    <v-tabs-slider
                      :style="{ height: tabIndicatorHeight + 'px' }"
                    ></v-tabs-slider>
                  </v-tabs>

                  <v-tabs v-if="isMobile" v-model="tab" show-arrows>
                    <v-tab>1. ข้อมูลทั่วไป</v-tab>
                    <v-tab>2. ผู้ติดต่อ / ผู้ประสานงาน</v-tab>
                    <v-tab>3. บัญชีผู้ใช้งาน</v-tab>
                  </v-tabs>

                  <v-tabs-items :transition="false" v-model="tab" class="mt-5">
                    <v-tab-item :transition="false"
                      ><v-form ref="generalForm">
                        <v-row
                          class="d-flex justify-space-between align-center"
                        >
                          <v-col cols="12" lg="6" md="6" sm="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ข้อมูลทั่วไป
                            </h3>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="d-flex justify-end"
                          >
                            <v-btn class="mx-3" outlined @click="fetchData"
                              >ดึงข้อมูลจาก
                              <v-img
                                alt="VEC Logo"
                                class="shrink ml-2"
                                contain
                                src="@/assets/DBDlogo.png"
                                transition="scale-transition"
                                width="40"
                            /></v-btn>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-5">
                          <v-col cols="12" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              ชื่อสถานประกอบการ <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              :label="
                                formData.nameth ? '' : 'ชื่อสถานประกอบการ'
                              "
                              :rules="[
                                (v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ',
                              ]"
                              v-model="formData.nameth"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              ประเภทสถานประกอบการ
                              <span class="text-red">*</span>
                            </h4>
                            <v-select
                              filled
                              background-color="input"
                              :rules="[
                                (v) => !!v || 'กรุณาระบุประเภทสถานประกอบการ',
                              ]"
                              :label="
                                formData.selected_employerprofiletypecode
                                  ? ''
                                  : 'กรุณาระบุประเภทสถานประกอบการ'
                              "
                              :items="employerprofiletypes"
                              item-value="code"
                              item-text="nameth"
                              clearable
                              v-model="
                                formData.selected_employerprofiletypecode
                              "
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              ทะเบียนการค้าเลขที่ / ทะเบียน ภพ.20
                              <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtIdentityid"
                              :rules="custom_profiletypecodeRules"
                              :label="
                                formData.identityid
                                  ? ''
                                  : 'ทะเบียนการค้าเลขที่ / ทะเบียน ภพ.20'
                              "
                              v-model="formData.identityid"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              อีเมล <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtEmail"
                              :label="formData.email ? '' : 'อีเมล'"
                              :rules="custom_emailRules"
                              v-model="formData.email"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-expand-transition>
                          <div v-show="emailExists">
                            <v-icon class="error--text"
                              >mdi-alert-circle</v-icon
                            >
                            <span class="pl-0 error--text">
                              อีเมลนี้มีผู้ใช้งานแล้ว
                            </span>
                          </div>
                        </v-expand-transition>

                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              ประเภทวิสาหกิจ <span class="text-red">*</span>
                            </h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              :label="
                                formData.selected_employermainbusinesstypecode
                                  ? ''
                                  : 'กรุณาระบุประเภทวิสาหกิจ'
                              "
                              :rules="[(v) => !!v || 'กรุณาระบุประเภทวิสาหกิจ']"
                              :items="employerMainBusinessTypes"
                              item-value="code"
                              item-text="nameth"
                              v-model="
                                formData.selected_employermainbusinesstypecode
                              "
                              @change="loadEmployerBusinessTypes"
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              ประเภทธุรกิจ <span class="text-red">*</span>
                            </h4>
                            <v-autocomplete
                              :disabled="
                                formData.selected_employermainbusinesstypecode ==
                                ''
                              "
                              filled
                              background-color="input"
                              clearable
                              :label="
                                formData.selected_employerbussinesstypecode
                                  ? ''
                                  : 'กรุณาระบุประเภทประเภทธุรกิจ'
                              "
                              :rules="[
                                (v) => !!v || 'กรุณาระบุประเภทประเภทธุรกิจ',
                              ]"
                              :items="employerBusinessTypes"
                              item-value="code"
                              item-text="nameth"
                              v-model="
                                formData.selected_employerbussinesstypecode
                              "
                            ></v-autocomplete>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              รายละเอียดสถานประกอบการ (ลักษณะงาน)
                              <span class="text-red">*</span>
                            </h4>
                            <v-textarea
                              filled
                              background-color="input"
                              :rules="[
                                (v) =>
                                  !!v || 'กรุณาระบุรายละเอียดสถานประกอบการ',
                              ]"
                              label="รายละเอียดสถานประกอบการ (ลักษณะงาน)"
                              v-model="formData.description"
                            >
                            </v-textarea>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              สวัสดิการ <span class="text-red">*</span>
                            </h4>
                            <v-textarea
                              filled
                              background-color="input"
                              label="สวัสดิการ"
                              :rules="[(v) => !!v || 'กรุณาระบุสวัสดิการ']"
                              v-model="formData.benefit_description"
                            >
                            </v-textarea>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              หมายเลขประกันสังคม <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtIdentityid"
                              :label="
                                formData.social_security_number
                                  ? ''
                                  : 'หมายเลขประกันสังคม'
                              "
                              :rules="security_numberRules"
                              v-model="formData.social_security_number"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">เว็บไซต์</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              :label="formData.websiteurl ? '' : 'เว็บไซต์'"
                              v-model="formData.websiteurl"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="12" class="my-0 py-0">
                            <h4 class="primary--text mb-4">
                              รูปแบบการรับสมัคร
                            </h4>
                            <v-checkbox
                              class="ml-3 my-0 py-0"
                              label="เปิดรับสมัครทาง online"
                              v-model="formData.isopenforonlineapplicant"
                            ></v-checkbox>
                            <v-checkbox
                              class="ml-3 my-0 py-0"
                              label="ผู้สมัครงานดำเนินการสมัครได้ด้วยตนเอง หรือส่งใบสมัครทางไปรษณีย์"
                              v-model="
                                formData.isrequiredjobapplicanttoregisterthemselforviapost
                              "
                            ></v-checkbox>
                          </v-col>
                        </v-row>

                        <v-row class="mt-4">
                          <v-col cols="12" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              ที่อยู่ <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtEmail"
                              :rules="[(v) => !!v || 'กรุณาระบุที่อยู่']"
                              :label="formData.address_no ? '' : 'ที่อยู่'"
                              v-model="formData.address_no"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            lg="3"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              จังหวัด <span class="text-red">*</span>
                            </h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              :label="
                                formData.selected_province_id ? '' : 'จังหวัด'
                              "
                              :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                              :items="provinces"
                              item-text="provincethai"
                              item-value="provinceid"
                              @change="onProvinceSelected"
                              v-model="formData.selected_province_id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="3"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              อำเภอ <span class="text-red">*</span>
                            </h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              :label="
                                formData.selected_district_id ? '' : 'อำเภอ'
                              "
                              :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                              :items="districts"
                              item-text="districtthai"
                              item-value="districtid"
                              @change="onDistrictSelected"
                              v-model="formData.selected_district_id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="3"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              ตำบล <span class="text-red">*</span>
                            </h4>
                            <v-select
                              filled
                              background-color="input"
                              :label="formData.selected_tambon_id ? '' : 'ตำบล'"
                              clearable
                              :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                              :items="tambons"
                              item-text="tambonthai"
                              item-value="tambonid"
                              v-model="formData.selected_tambon_id"
                              @change="onTambonSelected"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="3"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">รหัสไปรษณีย์</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              v-model="postcode"
                              readonly
                              placeholder="รหัสไปรษณีย์"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>

                    <v-tab-item :transition="false">
                      <v-form ref="contactForm">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ผู้ติดต่อ / ผู้ประสานงาน
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-5">
                          <v-col
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              คำนำหน้าชื่อ <span class="text-red">*</span>
                            </h4>
                            <v-select
                              filled
                              background-color="input"
                              :label="
                                formData.coordinator_titlename_id
                                  ? ''
                                  : 'คำนำหน้าชื่อ'
                              "
                              :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                              :items="titlenames"
                              item-text="titlenameth"
                              item-value="titlenameid"
                              v-model="formData.coordinator_titlename_id"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="5"
                            md="4"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              ชื่อจริง <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              :label="
                                formData.coordinator_firstname ? '' : 'ชื่อจริง'
                              "
                              :rules="firstnameRules"
                              v-model="formData.coordinator_firstname"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="5"
                            md="4"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              นามสกุล <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              :label="
                                formData.coordinator_lastname ? '' : 'นามสกุล'
                              "
                              :rules="lastnameRules"
                              v-model="formData.coordinator_lastname"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="12" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              อีเมล <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              :label="formData.coordinator_email ? '' : 'อีเมล'"
                              :rules="custom_emailRules"
                              v-model="formData.coordinator_email"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">แฟกซ์</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              :label="
                                formData.coordinator_fax
                                  ? ''
                                  : 'กรุณาระบุหมายเลขแฟกซ์ (ถ้ามี)'
                              "
                              v-model="formData.coordinator_fax"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              เบอร์โทร<span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtEmail"
                              :label="
                                formData.coordinator_phone
                                  ? ''
                                  : 'กรุณาระบุเบอร์โทร'
                              "
                              :rules="phoneRules"
                              v-model="formData.coordinator_phone"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>

                    <v-tab-item :transition="false">
                      <v-form ref="accountForm">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >บัญชีผู้ใช้งาน
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-5">
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-0 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              ชื่อบัญชีผู้ใช้ (Username)
                              <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              autocomplete="new-password"
                              ref="txtUsername"
                              :label="
                                formData.username
                                  ? ''
                                  : 'กรุณาระบุบัญชีผู้ใช้งาน'
                              "
                              :rules="custom_usernameRules"
                              v-model="formData.username"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-2 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              รหัสผ่าน (Password)
                              <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              :label="
                                formData.password ? '' : 'กรุณาระบุรหัสผ่าน'
                              "
                              autocomplete="new-password"
                              v-model="formData.password"
                              required
                              :append-icon="
                                isShowPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              @click:append="isShowPassword = !isShowPassword"
                              :type="isShowPassword ? 'text' : 'password'"
                              :rules="passwordRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            class="my-2 py-0"
                          >
                            <h4 class="primary--text mb-2">
                              ยืนยันรหัสผ่าน (Confirm Password)
                              <span class="text-red">*</span>
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              :label="
                                formData.confirm_password
                                  ? ''
                                  : 'กรุณายืนยันรหัสผ่าน'
                              "
                              autocomplete="new-password"
                              v-model="formData.confirm_password"
                              :append-icon="
                                isConfirmShowPassword
                                  ? 'mdi-eye'
                                  : 'mdi-eye-off'
                              "
                              @click:append="
                                isConfirmShowPassword = !isConfirmShowPassword
                              "
                              :type="
                                isConfirmShowPassword ? 'text' : 'password'
                              "
                              required
                              :rules="[
                                (v) =>
                                  !!v ||
                                  'กรุณายืนยันรหัสผ่าน (Confirmpassword)',
                                (v) =>
                                  v === formData.password ||
                                  'รหัสผ่านไม่ตรงกัน กรุณากรอกใหม่อีกครั้ง',
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-col>
            </v-card-text>
          </v-card>

          <v-card v-if="tab == 2" class="mt-5">
            <v-card-text class="d-flex justify-center">
              <v-col cols="10" class="mt-0">
                <v-row class="d-flex align-center justify-space-between">
                  <h3 class="primary--text">ผู้ประสานงานของสถานศึกษา</h3>
                  <v-btn @click="openPopupCreateContact" outlined class="ma-2">
                    เพิ่มผู้ประสานงาน<v-icon class="ml-2">mdi-plus</v-icon>
                  </v-btn>
                </v-row>
                <SearchableDataTable
                  class="mt-2"
                  :data_items="formData.employerschoolcontactrequests"
                  :datatable_headers="
                    formData_employerschoolcontactrequests_data_table_headers
                  "
                >
                  <template v-slot:item.action="{ item }">
                    <v-tooltip top color="error">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="deleteContact(item)"
                          color="error"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>ลบ</span>
                    </v-tooltip>
                  </template>
                </SearchableDataTable>
              </v-col></v-card-text
            >
          </v-card>

          <!-- <v-card class="mt-5">
              <v-toolbar flat color="primary" dark>
                <v-toolbar-title>ผู้ติดต่อ/ผู้ประสานงาน</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      label="คำนำหน้าชื่อ"
                      :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                      :items="titlenames"
                      item-text="titlenameth"
                      item-value="titlenameid"
                      v-model="formData.coordinator_titlename_id"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="ชื่อ"
                      :rules="nameRules"
                      v-model="formData.coordinator_firstname"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="นามสกุล"
                      :rules="nameRules"
                      v-model="formData.coordinator_lastname"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="อีเมล"
                      :rules="custom_emailRules"
                      v-model="formData.coordinator_email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="เบอร์โทร"
                      :rules="phoneRules"
                      v-model="formData.coordinator_phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Fax"
                      v-model="formData.coordinator_fax"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mt-5">
              <v-toolbar flat color="primary" dark>
                <v-toolbar-title>บัญชีผู้ใช้งาน</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      autocomplete="new-password"
                      ref="txtUsername"
                      label="ชื่อบัญชีผู้ใช้ (username) *"
                      :rules="custom_usernameRules"
                      v-model="formData.username"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      autocomplete="new-password"
                      label="รหัสผ่าน"
                      v-model="formData.password"
                      required
                      :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isShowPassword = !isShowPassword"
                      :type="isShowPassword ? 'text' : 'password'"
                      :rules="passwordRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      autocomplete="new-password"
                      label="ยืนยันรหัสผ่าน"
                      v-model="formData.confirm_password"
                      :append-icon="isConfirmShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isConfirmShowPassword = !isConfirmShowPassword"
                      :type="isConfirmShowPassword ? 'text' : 'password'"
                      required
                      :rules="[
                        (v) => !!v || 'กรุณายืนยันรหัสผ่าน (Confirmpassword)',
                        (v) => v === formData.password || 'รหัสผ่านไม่ตรง',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row class="mt-2">
              <v-col cols="12">
                <div class="d-flex justify-start">
                  <v-btn color="primary" @click="openPopupCreateContact"
                    ><v-icon> mdi-plus </v-icon>เพิ่มผู้ประสานงาน</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <SearchableDataTable
              class="mt-2"
              table_caption="ผู้ประสานงานของสถานศึกษา"
              :data_items="formData.employerschoolcontactrequests"
              :datatable_headers="
                formData_employerschoolcontactrequests_data_table_headers
              "
            >
              <template v-slot:item.action="{ item }">
                <v-tooltip top color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="deleteContact(item)"
                      color="error"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>ลบ</span>
                </v-tooltip>
              </template>
            </SearchableDataTable> -->

          <v-row class="my-5 justify-end">
            <v-btn v-if="tab != 0" class="mx-3" @click="tab--">ย้อนกลับ</v-btn>
            <v-btn
              v-if="tab != 2"
              class="mx-3 color-buton"
              dark
              :disabled="emailExists"
              @click="handleNext"
              >ถัดไป<v-icon class="ml-2"> mdi-arrow-right </v-icon></v-btn
            >
            <v-btn
              v-if="tab == 2"
              class="mx-3 color-buton"
              dark
              :disabled="emailExists"
              @click="onSaveAndGotoUpdateView()"
              >บันทึกและเพิ่มข้อมูลสำคัญอื่น ๆ<v-icon class="ml-2"
                >mdi-content-save
              </v-icon></v-btn
            >
            <v-btn
              v-if="tab == 2"
              dark
              class="mx-3 color-buton"
              :disabled="emailExists"
              @click="onSave()"
              >บันทึก<v-icon class="ml-2"> mdi-content-save</v-icon></v-btn
            >
          </v-row>
        </v-col>
      </v-row>

      <!-- <v-row class="d-flex justify-center">
          <v-col class="justify-end text-center mt-4 mb-4">
            <v-btn
              color="primary"
              class="ma-2"
              @click="onSave()"
              :disabled="emailExists"
              ><v-icon> mdi-content-save </v-icon>บันทึก</v-btn
            >
            <v-btn
              color="secondary"
              @click="onSaveAndGotoUpdateView()"
              :disabled="emailExists"
              ><v-icon> mdi-content-save </v-icon>บันทึกและเพิ่มข้อมูลสำคัญอื่น ๆ
            </v-btn>
            <v-btn
              color=""
              @click="$router.push({ name: 'EmployerProfileListView' })"
              class="ma-2 justify-center"
            >
              ย้อนกลับ</v-btn
            >
          </v-col>
        </v-row> -->
    </v-container>
    <v-dialog v-model="isOpenCreateContactDialog" width="700">
      <template>
        <v-card class="pa-6">
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-form
              ref="formCoordinator"
              v-model="validCoordinator"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :label="
                      newContact.selected_schoolid ? '' : 'กรุณาระบุสถานศึกษา'
                    "
                    :items="schools"
                    item-text="schoolname"
                    item-value="schoolid"
                    required
                    :rules="[(v) => !!v || 'กรุณาระบุสถานศึกษา']"
                    v-model="newContact.selected_schoolid"
                    @change="onSchoolSelected"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="newContact.fullname ? '' : 'ชื่อจริง-นามสกุล'"
                    required
                    :rules="nameRules"
                    v-model="newContact.fullname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="newContact.phone ? '' : 'เบอร์ติดต่อ'"
                    required
                    :rules="phoneRules"
                    v-model="newContact.phone"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12" class="justify-center text-center">
                  <v-btn @click="createContact" class="ma-2" color="success">
                    <v-icon class="mr-2">mdi-check-circle-outline</v-icon>ยืนยัน
                  </v-btn>
                  <v-btn
                    @click="isOpenCreateContactDialog = false"
                    class="ma-2"
                    color=""
                    elevation="2"
                    >ยกเลิก</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {
  usernameRules,
  passwordRules,
  profiletypecodeRules,
  emailRules,
  security_numberRules,
  nameRules,
  phoneRules,
  firstnameRules,
  lastnameRules,
} from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { EmployerSchoolContactRequest } from "@/models/Employer/EmployerSchoolContactRequest";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { CreateEmployerProfileRequest } from "@/models/Employer/CreateEmployerProfileRequest";
import { formatArrayBuffer } from "@microsoft/signalr/dist/esm/Utils";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
import { EmployerExternalApiDataProvider } from "@/services/EmployerExternalApiDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerProfileDataProvider = new EmployerProfileDataProvider(
  backendapiUrl
);
const employerExternalApiDataProvider = new EmployerExternalApiDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "CreateEmployerProfileView",
  components: { SearchableDataTable },
  data() {
    return {
      formData: new CreateEmployerProfileRequest(),
      formData_employerschoolcontactrequests_data_table_headers: [
        { text: "สถานศึกษา", value: "selected_schoolname" },
        { text: "ชื่อ-นามสกุล", value: "fullname" },
        { text: "เบอร์ติดต่อ", value: "phone" },
        { text: "", value: "action"  , sortable: false},
      ],
      employerProfileStatuses: undefined,
      employerprofiletypes: undefined,
      employerMainBusinessTypes: [],
      employerBusinessTypes: undefined,
      provinces: [],
      districts: [],
      tambons: [],
      postcode: [],
      isOpenCreateContactDialog: false,
      schools: undefined,
      newContact: new EmployerSchoolContactRequest(),
      CreateEmployerData: new FormData(),
      logo_photobase64: "",
      logoFiles: [],
      previewphotoBase64: "",
      documentFiles: [],
      photoFiles: [],
      logoFiles: [],
      valid: true,
      validCoordinator: true,
      usernameRules: usernameRules,
      passwordRules: passwordRules,
      emailRules: emailRules,
      profiletypecodeRules: profiletypecodeRules,
      security_numberRules: security_numberRules,
      nameRules: nameRules,
      firstnameRules: firstnameRules,
      lastnameRules: lastnameRules,
      phoneRules: phoneRules,
      isShowPassword: false,
      isConfirmShowPassword: false,
      emailExists: false,
      titlenames: undefined,
      usernameExistResult: undefined,
      emailExistResult: false,
      identityidExistResult: false,
      provinceText: "",
      districtText: "",
      tambonText: "",
      selectedProvinceID: "",
      selectedDistrictID: "",
      selectedTambonID: "",
      tab: 0,
      tabIndicatorHeight: 5,
      drawer: false,
      isMobile: false,
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider.loadEmployerProfileStatuses().then((result) => {
        this.employerProfileStatuses = result.data;
      });

      await masterDataProvider.loadEmployerProfileTypes().then((result) => {
        this.employerprofiletypes = result.data;
        //Set default value.
        this.formData.selected_employerprofiletypecode = "Organization";
        this.formData.selected_employermainbusinesstypecode = "";
      });

      await masterDataProvider
        .loadEmployerMainBusinessTypes()
        .then((result) => {
          this.employerMainBusinessTypes = result.data;
        });

      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
      await masterDataProvider.getActiveTitleNames().then((result) => {
        this.titlenames = result.data;
      });

      const getSchoolRequest = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(getSchoolRequest).then((result) => {
        this.schools = result.data;
      });
    },

    async loadEmployerBusinessTypes() {
      if (this.formData.selected_employermainbusinesstypecode != "") {
        await masterDataProvider
          .loadEmployerBusinessTypes(
            this.formData.selected_employermainbusinesstypecode
          )
          .then((result) => {
            this.employerBusinessTypes = result.data;
          });
      }
    },

    async onProvinceSelected() {
      if (this.formData.selected_province_id) {
        this.formData.selected_district_id = "";
        this.formData.selected_tambon_id = "";
        await masterDataProvider
          .loadGetDistricts(this.formData.selected_province_id)
          .then((result) => {
            this.districts = result.data;
          });
      } else {
        this.formData.selected_province_id = "";
        this.formData.selected_district_id = "";
        this.formData.selected_tambon_id = "";
      }
    },

    async onDistrictSelected() {
      if (this.formData.selected_district_id) {
        await masterDataProvider
          .loadGetTambons(this.formData.selected_district_id)
          .then((result) => {
            this.tambons = result.data;
          });
      }
    },

    async onTambonSelected() {
      if (this.formData.selected_tambon_id) {
        const vm_selected_tambon_id = this.formData.selected_tambon_id;
        const selected_tambon = this.tambons.find(
          (x) => x.tambonid == vm_selected_tambon_id
        );
        this.postcode = selected_tambon.postcodemain;
      }
    },

    async openPopupCreateContact() {
      this.newContact = new EmployerSchoolContactRequest();
      this.isOpenCreateContactDialog = true;
    },

    async onSchoolSelected() {
      if (this.newContact.selected_schoolid != "") {
        const selectedID = this.newContact.selected_schoolid;
        const school = this.schools.find((x) => x.schoolid == selectedID);
        this.newContact.selected_schoolname = school.schoolname;
      }
    },

    async createContact() {
      if (this.$refs.formCoordinator.validate()) {
        this.formData.employerschoolcontactrequests.push(this.newContact);
        this.isOpenCreateContactDialog = false;
      }
    },

    async deleteContact(item) {
      const index = this.formData.employerschoolcontactrequests.indexOf(item);
      this.formData.employerschoolcontactrequests.splice(index, 1);
    },

    async onFileChange() {
      this.CreateEmployerData.append("filedoc", this.documentFiles);
    },
    async onlogoPhotoChange() {
      this.CreateEmployerData.append("filelogo", this.logoFiles);
      var fileToLoad = this.logoFiles;
      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        const base64 = data.split(",")[1];
        this.logo_photobase64 = base64;
      });
    },
    async onPhotoChange() {
      this.CreateEmployerData.append("filephoto", this.photoFiles);
      var fileToLoad = this.photoFiles;
      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        const base64 = data.split(",")[1];
        this.previewphotoBase64 = base64;
      });
    },

    async onSave() {
      const isGeneralFormValid = this.$refs.generalForm.validate();
      const isContactFormValid = this.$refs.contactForm.validate();
      const isAccountFormValid = this.$refs.accountForm.validate();

      if (isGeneralFormValid && isContactFormValid && isAccountFormValid) {
        if (confirm("ยืนยัน")) {
          this.$store.dispatch("showLoading");
          const formdata = this.formData.generateFormData();
          formdata.append("filedoc", this.documentFiles);
          formdata.append("filelogo", this.logoFiles);
          formdata.append("filephoto", this.photoFiles);
          await employerProfileDataProvider
            .createEmployerProfile(formdata)
            .then((result) => {
              this.$toast.success("บันทึกข้อมูลสถานประกอบการเรียบร้อย");
              this.$router.push({ name: "EmployerProfileListView" });
            })
            .catch((result) => {
              this.$toast.error("เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล");
              console.log(result);
            })
            .finally((result) => {
              this.$store.dispatch("hideLoading");
            });
        }
      }
    },

    async onSaveAndGotoUpdateView() {
      const isGeneralFormValid = this.$refs.generalForm.validate();
      const isContactFormValid = this.$refs.contactForm.validate();
      const isAccountFormValid = this.$refs.accountForm.validate();

      if (isGeneralFormValid && isContactFormValid && isAccountFormValid) {
        if (confirm("ยืนยัน")) {
          this.$store.dispatch("showLoading");
          const formdata = this.formData.generateFormData();
          formdata.append("filedoc", this.documentFiles);
          formdata.append("filelogo", this.logoFiles);
          formdata.append("filephoto", this.photoFiles);
          await employerProfileDataProvider
            .createEmployerProfile(formdata)
            .then((result) => {
              let registedEmployerProfileOid = result.data;
              this.$toast.success("บันทึกข้อมูลสถานประกอบการเรียบร้อย");
              this.$router.push({
                name: "UpdateEmployerProfileView",
                params: { oid: registedEmployerProfileOid },
              });
            })
            .catch((result) => {
              this.$toast.error("เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล");
              console.log(result);
            })
            .finally((result) => {
              this.$store.dispatch("hideLoading");
            });
        }
      }
    },

    async assignDataFromExtenalAPI(fetchedData) {
      console.log(fetchedData);

      const juristicPerson =
        fetchedData.data[0]["cdOrganizationJuristicPerson"];
      const address =
        juristicPerson["cdOrganizationJuristicAddress"]["crAddressType"];

      this.formData.identityid = juristicPerson["cdOrganizationJuristicID"];
      this.formData.nameth = juristicPerson["cdOrganizationJuristicNameTH"];
      this.formData.nameen = juristicPerson["cdOrganizationJuristicNameEN"];
      this.formData.juristictype = juristicPerson["cdOrganizationJuristicType"];
      this.formData.registerdate =
        juristicPerson["cdOrganizationJuristicRegisterDate"];
      this.formData.juristicstatus =
        juristicPerson["cdOrganizationJuristicStatus"];
      this.formData.objectivecode =
        juristicPerson["cdOrganizationJuristicObjective"][
          "tdJuristicObjective"
        ]["juristicObjectiveCode"];
      this.formData.objectivetextth =
        juristicPerson["cdOrganizationJuristicObjective"][
          "tdJuristicObjective"
        ]["juristicObjectiveTextTH"];
      this.formData.objectivetexten =
        juristicPerson["cdOrganizationJuristicObjective"][
          "tdJuristicObjective"
        ]["juristicObjectiveTextEN"];
      this.formData.registercapital =
        juristicPerson["cdOrganizationJuristicRegisterCapital"];
      this.formData.branchname =
        juristicPerson["cdOrganizationJuristicBranchName"];

      this.formData.address_no = address["cdAddressNo"];
      this.formData.moo = address["cdMoo"];
      this.formData.road = address["cdRoad"];
      this.formData.soi = address["cdSoi"];

      this.provinceText =
        address["cdCountrySubDivision"]["crCountrySubDivisionTextTH"];

      if (this.provinceText) {
        const province = this.provinces.find(
          (p) => p.provincethai === this.provinceText
        );
        if (province) {
          this.selectedProvinceID = province.provinceid;
          this.formData.selected_province_id = this.selectedProvinceID;
          // this.formData.selected_district_id = "";
          // this.formData.selected_tambon_id = "";
          // this.postcode = "";
          await masterDataProvider
            .loadGetDistricts(this.selectedProvinceID)
            .then((result) => {
              this.districts = result.data;

              this.formData.selected_district_id =
                address["cdCity"]["crCityCode"];
            });
        }
      }
      if (this.formData.selected_district_id) {
        await masterDataProvider
          .loadGetTambons(this.formData.selected_district_id)
          .then((result) => {
            this.tambons = result.data;
          });

        const findTambon =
          address["crCitySubDivision"]["crCitySubDivisionTextTH"];
        if (findTambon) {
          const resultTambon = this.tambons.find(
            (x) => x.tambonthai == findTambon || x.tambonthaishort == findTambon
          );

          if (resultTambon) {
            this.formData.selected_tambon_id = resultTambon.tambonid;
          }
        }
      }
      if (this.formData.selected_tambon_id) {
        this.onTambonSelected();
      }

      //crCitySubDivisionCode

      // this.districtText = address["cdCity"]["crCityTextTH"];
      // this.tambonText = address["crCitySubDivision"]
      //   ? address["crCitySubDivision"]["crCitySubDivisionTextTH"]
      //   : "";
      // this.postcode = address["cdCity"]["crCityCode"];

      // await this.onProvinceSelected();
      // await this.onDistrictSelected();
      // await this.onTambonSelected();
    },

    async fetchData() {
      if (this.formData.identityid != "") {
        this.$store.dispatch("showLoading");
        console.log(this.formData.identityid);
        await employerProfileDataProvider
          .getPublicEmployerInformationByJuristicID(this.formData.identityid)
          .then((result) => {
            console.log(result.data);
            this.$toast.success("ดึงข้อมูลจาก dbd");
            this.assignDataFromExtenalAPI(result.data);
          })
          .catch((err) => {
            this.$toast.warning("ไม่พบข้อมูลประกาศ");
          });
        this.$store.dispatch("hideLoading");
      } else {
        this.$refs.txtIdentityid.validate();
        this.$toast.error("กรุณากรอกข้อมูลทะเบียนการค้าเลขที่ / ทะเบียน ภพ.20");
      }
    },
    handleNext() {
      let isValid = false;

      if (this.tab === 0) {
        isValid = this.$refs.generalForm.validate();
      } else if (this.tab === 1) {
        isValid = this.$refs.contactForm.validate();
      } else if (this.tab === 2) {
        isValid = this.$refs.accountForm.validate();
      }

      if (isValid) {
        this.tab++;
      }
    },
    // async beforeTabChange(newTab) {
    //   console.log(newTab)
    //   let isValid = false;
    //   console.log(this.tab)

    //   // ตรวจสอบฟอร์มของแท็บปัจจุบันก่อนเปลี่ยนไปแท็บใหม่
    //   if (this.tab === 0) {
    //     isValid = await this.$refs.generalForm.validate();
    //   } else if (this.tab === 1) {
    //     isValid = await this.$refs.contactForm.validate();
    //   } else if (this.tab === 2) {
    //     isValid = await this.$refs.accountForm.validate();
    //   }
    //   console.log(isValid)
    //   if (isValid) {
    //     // อนุญาตให้เปลี่ยนแท็บ
    //     this.tab = newTab;
    //   } else {
    //     // แสดงข้อความแจ้งเตือนหากฟอร์มไม่ถูกต้อง
    //     this.$toast.error("กรุณากรอกข้อมูลให้ถูกต้องก่อนย้ายแท็บ");
    //     this.tab = this.tab
    //   }
    // },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  async created() {
    await this.initialize();
  },

  watch: {
    "formData.username": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isUsernameUsed(newVal)
            .then((result) => {
              this.usernameExistResult = result.data;
              this.$refs.txtUsername.validate();
            });
        }
      },
      deep: true,
    },
    "formData.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isEmailUsed(newVal)
            .then((result) => {
              this.emailExistResult = result.data;
              this.$refs.txtEmail.validate();
            });
        }
      },
      deep: true,
    },
    "formData.identityid": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isIdentityIDUsed(newVal)
            .then((result) => {
              console.log(result.data);
              this.identityidExistResult = result.data;
              this.$refs.txtIdentityid.validate();
            });
        }
      },
      deep: true,
    },
    async tab(newTab, oldTab) {
      let isValid = false;
      if (newTab === 0) {
        console.log(newTab);
      } else if (newTab === 1) {
        if (oldTab === 0) {
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        }
      } else {
        if (oldTab === 0) {
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        } else if (oldTab === 1) {
          isValid = await this.$refs.contactForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error(
              "กรุณากรอกข้อมูลผู้ติดต่อ/ผู้ประสานงานให้ครบถ้วน"
            );
            this.tab = oldTab;
          }
        } else if (oldTab === 2) {
          isValid = await this.$refs.accountForm.validate();
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลบัญชีผู้ใช้งานให้ครบถ้วน");
            this.tab = oldTab;
          }
        }
      }
    },
  },
  computed: {
    identityid_caption() {
      switch (this.formData.selected_employerprofiletypecode) {
        case "Organization":
          return "ทะเบียนการค้าเลขที่/ทะเบียน ภพ.20";
        case "Personal":
          return "เลขบัตรประชาชน";
        case "Government":
          return "เลขที่ผู้เสียภาษี";
        default:
          return "";
      }
    },
    custom_usernameRules() {
      return [
        ...usernameRules,
        (v) =>
          this.usernameExistResult === false ||
          "ชื่อผู้ใช้งาน (username) ถูกใช้งานแล้ว",
      ];
    },
    custom_emailRules() {
      return [
        ...emailRules,
        (v) => this.emailExistResult === false || "อีเมลนี้ถูกใช้งานแล้ว",
      ];
    },
    custom_profiletypecodeRules() {
      return [
        ...profiletypecodeRules,
        (v) =>
          this.identityidExistResult !== true || "หมายเลขที่กรอกไม่ถูกต้อง",
      ];
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped>
.v-toolbar__title {
  font-size: 16px !important;
}

/* .v-tabs-bar {
  position: relative;
}

.v-tabs-bar::before {
  display:block;
  content: '';
  position: absolute;
  bottom: -40px;
  top:0;
  left: 0;
  right: 0;
  z-index: 5;
}
.v-tabs-bar::after {
  display:block;
  content: '';
  position: absolute;
  bottom: -40px;
  top:0;
  left: 0;
  right: 0;
  z-index: 5;
  border-radius: 8px;
} */

.v-tabs-slider {
  border-radius: 10px;
  bottom: -40px;
}

.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
.text-red {
  color: #b00020;
}
</style>
