<template>
  <div>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <VueCircle
    :progress="percen"
    :size="150"
    :reverse="false"
    empty-fill="rgba(0, 0, 0, .1)"
    :animation-start-value="0.0"
    :start-angle="300.2"
    insert-mode="append"
    :thickness="15"
    :show-percent="true"
    :fill="fill"
  >
  </VueCircle>
  </div>
  
  <!-- <v-card
    style="border-radius: 15px"
    class="mx-auto d-flex flex-column align-center"
    variant="outlined"
    max-width="250"
  >
    <label class="mt-3">ความสมบูรณ์ของประวัติ :</label>
    <v-progress-circular :rotate="-90" :size="70" :width="10" :value="50" color="teal">
      50%
    </v-progress-circular></v-card
  > -->
</template>

<script>
import VueCircle from "vue2-circle-progress";
export default {
  props: ["percen"],
  components: {
    VueCircle,
  },
  data() {
    return {
      fill: { gradient: ['rgba(63, 81, 181, 1)', 'rgba(33, 150, 243, 1)'] }
    };
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
