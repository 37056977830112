<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-form v-if="request" ref="form" lazy-validation>
        <v-row class="d-flex justify-center mt-0 pt-0">
          <v-col lg="8" md="12" sm="12" xs="12">
            <v-card class="mt-5">
              <v-toolbar class="color-HeaderCard" flat dark>
                <v-toolbar-title>แจ้งเตือนทั่วไป</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="request.Title"
                      label="Title / เรื่อง"
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col id="vueEditor">
                    <!--<v-textarea v-model="request.Message" label="Message / ข้อความ">
                    </v-textarea> -->
                    <vue-editor
                      v-model="request.Message"
                      :editor-toolbar="customToolbar"
                      placeholder="ข้อความ"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-cebter">
                    <span class="mt-1">ช่องทางการแจ้งเตือน :</span>
                    <v-checkbox
                      class="mb-2 ml-3 mr-2"
                      label="ผ่าน Email"
                      v-model="request.IsNotifyByEmailNotification"
                    ></v-checkbox>
                    <v-checkbox
                      label="ผ่านระบบ"
                      class="mb-2 ml-3"
                      v-model="request.IsNotifybyInAppNotification"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-card class="mt-5">
                  <v-tabs color="deep-purple accent-4" v-model="currentTab">
                    <v-tab value="SchoolEmployee">เจ้าหน้าที่</v-tab>
                    <v-tab value="Student">นักศึกษา</v-tab>
                    <v-tab value="Employer">สถานประกอบการ</v-tab>
                  </v-tabs>
                  <v-row class="ma-2">
                    <v-col class="d-flex justify-end" cols="12">
                      <v-btn class="mr-4" color="" @click="onselectAll(currentTab)">
                        ทั้งหมด</v-btn
                      >
                      <v-btn class="mr-4" color="" @click="openDialog(currentTab)">
                        เลือก</v-btn
                      ><v-btn color="" @click="Clear()"> ล้างค่า</v-btn></v-col
                    ></v-row
                  >
                  <v-row class="ma-2">
                    <v-col cols="12">
                      <span v-if="selectedUser.length > 0"
                        >ที่เลือก({{ selectedUser.length }}) :
                        <span>{{ selectedUser }}</span></span
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="justify-center text-center mt-4 mb-4">
            <v-btn color="primary" class="ma-2" @click="onSave()"
              ><v-icon class="mr-2"> mdi-bullhorn </v-icon> ประกาศ</v-btn
            >
            <v-btn
              color=""
              @click="$router.push({ name: 'EmployerProfileListView' })"
              class="ma-2 justify-center"
            >
              ย้อนกลับ</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="dialogSchoolEmployee" persistent max-width="1000">
      <v-card>
        <v-card-title class="d-flex justify-center">เลือกเจ้าหน้าที่</v-card-title>
        <v-data-table
          v-model="selectedschoolEmployee"
          :headers="schoolEmployeeProfiles_table_headers"
          :items="schoolEmployeeProfiles"
          item-key="oid"
          show-select
          class="elevation-1 ma-3"
          :search="search"
          ><template v-slot:top>
            <div class="pb-2 pt-2">
              <v-toolbar flat dense>
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  color="info"
                ></v-text-field>
              </v-toolbar></div></template
        ></v-data-table>
        <!-- <SearchableDataTableSelect
          :data_items="employerprofiles"
          :datatable_headers="employerprofiles_data_table_headers"
          table_caption="รายชื่อสถานประกอบการ"
          :item_key="itemKey"
          v-model="selected"
          @item-selected="handleItemSelected"
        ></SearchableDataTableSelect> -->
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" @click="onDialogschoolEmployeeConfirm">ตกลง</v-btn>
          <v-btn @click="dialogSchoolEmployee = false">ยกเลิก</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStudent" persistent max-width="1000">
      <v-card>
        <v-card-title class="d-flex justify-center">เลือกนักศึกษา</v-card-title>
        <v-data-table
          v-model="selectedStudents"
          :headers="schools_data_table_headers"
          :items="schools"
          item-key="schoolid"
          show-select
          class="elevation-1 ma-3"
          :search="search"
          ><template v-slot:top>
            <div class="pb-2 pt-2">
              <v-toolbar flat dense>
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  color="info"
                ></v-text-field>
              </v-toolbar></div></template
        ></v-data-table>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" @click="onDialogStudentsConfirm">ตกลง</v-btn>
          <v-btn @click="dialogStudent = false">ยกเลิก</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEmployer" persistent max-width="1000">
      <v-card>
        <v-card-title class="d-flex justify-center">เลือกสถานประกอบการ</v-card-title>
        <v-data-table
          v-model="selectedEmployer"
          :headers="employerprofiles_data_table_headers"
          :items="employerprofiles"
          item-key="oid"
          show-select
          class="elevation-1 ma-3"
          :search="search"
          ><template v-slot:top>
            <div class="pb-2 pt-2">
              <v-toolbar flat dense>
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  color="info"
                ></v-text-field>
              </v-toolbar></div></template
        ></v-data-table>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" @click="onDialogEmployerConfirm">ตกลง</v-btn>
          <v-btn @click="dialogEmployer = false">ยกเลิก</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import SearchableDataTableSelect from "@/components/common/SearchableDataTableSelect.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { ApplicationRoleCodes } from "@/Constants";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { CreateCommonNotificationsRequest } from "@/models/Notifications/CreateCommonNotificationsRequest";
import { NotificationDataProvider } from "@/services/SignalR/NotificationDataProvider";
import { VueEditor, Quill } from "vue2-editor";

const schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var notificationDataProvider = new NotificationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "CommonNotifyRequest",
  components: { VueEditor, SearchableDataTableSelect },
  data() {
    return {
      search: "",
      tab: null,
      dialogSchoolEmployee: false,
      dialogStudent: false,
      dialogEmployer: false,
      selectedschoolEmployee: [],
      selectedStudents: [],
      selectedEmployer: [],
      itemKey: null,
      currentTab: null,
      schools: [],
      employerprofiles: [],
      request_data: new GetSchools_Reqeust(),
      schoolEmployeeProfiles: [],
      schools_data_table_headers: [
        { text: "รหัส", value: "schoolid" },
        { text: "ชื่อ", value: "schoolname" },
        { text: "Email", value: "email" },
      ],
      employerprofiles_data_table_headers: [
        { text: "ชื่อสถานประกอบการ", value: "nameth" },
        { text: "จังหวัด", value: "province.provincethai" },
        { text: "ผู้ติดต่อ", value: "coordinator_firstname" },
        { text: "ชื่อบัญชีผู้ใช้งาน", value: "username" },
      ],
      schoolEmployeeProfiles_table_headers: [
        { text: "ชื่อ", value: "name" },
        { text: "นามสกุล", value: "lastname" },
        { text: "ตำแหน่ง", value: "position" },
        { text: "ชื่อผู้ใช้งาน", value: "applicationuser.username" },
      ],
      selectedUser: [],
      CommonNotifyRequest: [],
      request: new CreateCommonNotificationsRequest(),
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["clean"],
        ["template-partediario"],
      ],
    };
  },
  methods: {
    openDialog(tabValue) {
      this.search = "";
      if (tabValue === 0) {
        this.dialogSchoolEmployee = true;
      } else if (tabValue === 1) {
        this.dialogStudent = true;
      } else if (tabValue === 2) {
        this.dialogEmployer = true;
      }
    },
    onselectAll(tabValue) {
      if (tabValue === 0) {
        this.request.IsMustSendToAllSchoolEmployers = true;
        this.insertSeclectedUser("เลือกเจ้าหน้าทั้งหมด");
      } else if (tabValue === 1) {
        this.request.IsMustSendToAllStudents = true;
        this.insertSeclectedUser("เลือกนักศึกษาทั้งหมด");
      } else if (tabValue === 2) {
        this.request.IsMustSendToAllEmployerProfiles = true;
        this.insertSeclectedUser("เลือกสถานประกอบการทั้งหมด");
      }
    },
    insertSeclectedUser(selectedUser = "") {
      if (!this.selectedUser.includes(selectedUser)) {
        this.selectedUser.push(selectedUser);
      }
    },
    async loadEmployerProfiles() {
      await employerProfileDataProvider.loadEmployerProfiles().then((result) => {
        this.employerprofiles = result.data;
        this.itemKey = result.data.oid;
      });
    },
    async loadSchools() {
      await schoolDataProvider
        .loadSchools(this.request_data)
        .then((result) => {
          this.schools = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadSchoolEmployeeProfiles() {
      const applicationRoleCodes = [];
      applicationRoleCodes.push(ApplicationRoleCodes.School_Guidance_Teacher);
      applicationRoleCodes.push(ApplicationRoleCodes.School_Manager);
      applicationRoleCodes.push(ApplicationRoleCodes.Center_Admin);
      applicationRoleCodes.push(ApplicationRoleCodes.Center_Manager);
      await schoolEmployeeProfileDataProvider
        .getSchoolEmployeeProfile_By_ApplicationRoleCodes(applicationRoleCodes)
        .then((result) => {
          this.schoolEmployeeProfiles = result.data;
        });
    },
    onDialogschoolEmployeeConfirm() {
      this.dialogSchoolEmployee = false;

      if (this.selectedschoolEmployee !== "") {
        this.selectedschoolEmployee.forEach((x) => {
          this.insertSeclectedUser(x.oid),
            this.request.insertSchoolEmployerProfileOid(x.oid);
        });
      }
    },
    onDialogStudentsConfirm() {
      this.dialogStudent = false;
      if (this.selectedStudents !== "") {
        this.selectedStudents.forEach((x) => {
          this.insertSeclectedUser(x.oid), this.request.insertStudentOid(x.oid);
        });
      }
    },
    onDialogEmployerConfirm() {
      this.dialogEmployer = false;
      if (this.selectedEmployer !== "") {
        this.selectedEmployer.forEach((x) => {
          this.insertSeclectedUser(x.oid), this.request.insertEmployerProfilesOid(x.oid);
        });
      }
    },

    handleItemSelected(selectedItems) {
      this.selected = selectedItems;
    },

    async onSave() {
      if (this.$refs.form.validate()) {
        if (confirm("ยืนยัน")) {
          await notificationDataProvider
            .CreateCommonNotifications(this.request)
            .then((result) => {
              this.$toast.success("ประกาศเรียบร้อย");
              this.Clear();
            });
        }
      }
    },
    Clear() {
      this.selectedUser = [];
      this.request = new CreateCommonNotificationsRequest();
    },
  },
  async created() {
    await this.loadEmployerProfiles();
    await this.loadSchools();
    await this.loadSchoolEmployeeProfiles();
  },
};
</script>

<style scoped>
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgb(62, 83, 183) 20%,
    rgb(66, 81, 167) 30%,
    rgb(88, 123, 201) 50%,
    rgb(46, 126, 220) 60%,
    rgb(84, 170, 235) 80%,
    rgb(127, 202, 246) 100%
  );
}
</style>
