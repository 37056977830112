<template>
  <v-container>
    <v-row class="pt-3 px-3">
      <h4 class="primary--text">ชื่อรายงานและประเภทไฟล์</h4>
    </v-row>
    <v-row class="px-3 py-2" style="margin-top: 1%; margin-bottom: 4%">
      <v-divider></v-divider>
    </v-row>
    <v-row v-if="!isDownloading && !isSuccess && !isError">
      <v-col cols="12" class="custom-col mb-0 pb-0">
        <span class="primary--text mb-2" style="font-weight: bold">ชื่อรายงาน</span>
        <v-text-field filled :value="reportTHName"></v-text-field>
      </v-col>
    </v-row>

    <!-- โชว์ก่อน click ยืนยันการออกรายการ -->
    <v-row v-if="!isDownloading && !isSuccess && !isError">
      <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="custom-col px-5 py-6">
        <v-row class="pl-1">
          <span class="primary--text mb-2 font-weight-bold">ประเภทไฟล์</span>
        </v-row>
        <v-row>
          <v-col class="px-0 py-0">
            <v-btn
              v-for="(fileType, index) in fileTypes"
              :key="index"
              :color="selectedFileTypeValue === fileType.value ? 'primary' : 'default'"
              @click="selectedFileTypeValue = fileType.value"
              class="mx-2 my-2"
              :block="$vuetify.breakpoint.smAndDown"
              >
              <v-img
                :alt="fileType.text"
                class="shrink mr-2"
                contain
                :src="fileType.image"
                transition="scale-transition"
                width="30"
              />{{ fileType.text }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- โชว์หลัง click ยืนยันการออกรายการ -->
    <v-row
      class="d-flex justify-center align-center"
      v-if="isDownloading || isSuccess || isError"
    >
      <v-col class="text-center">
        <!-- Loading Spinner -->
        <v-progress-circular
          v-if="isDownloading"
          indeterminate
          color="primary"
          class="mb-4"
          style="width: 85px; height: 85px"
        ></v-progress-circular>

        <!-- Success Checkmark Animation -->
        <div v-if="isSuccess" class="checkmark-container">
          <v-row class="d-flex flex-column" style="">
            <v-col style="display: flex; justify-content: center">
              <div class="check-icon"></div>
            </v-col>

            <v-col style="display: flex; justify-content: center">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  color: #4caf50;
                  font-weight: bold;
                "
              >
                ส่งข้อมูลการขอออกรายงานเรียบร้อย
              </div>
            </v-col>
            <v-col class="pb-0" style="display: flex; justify-content: start">
              <div>หมายเหตุ : ระบบจะแจ้งเตือนทันทีที่ทำการออกรายงานเสร็จสิ้น</div>
            </v-col>
            <v-col class="py-0" style="display: flex; justify-content: start">
              <div>
                ผู้ใช้งานสามารถทำงาน หรือ ใช้งานเมนูอื่นได้ระหว่างที่ระบบกำลังออกรายงาน
              </div>
            </v-col>
            <v-col class="pt-16 d-flex justify-end px-3">
              <v-btn @click="onCancelClick()" class="mr-3">กลับหน้าแรก</v-btn>
              <v-btn dark class="color-btnCard" @click="goToMessages()">
                หน้าการแจ้งเตือน<v-icon class="ml-2">mdi-logout</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <!-- Error Cross Animation -->
        <div v-if="isError" class="error-container">
          <v-row class="d-flex flex-column" style="">
            <v-col style="display: flex; justify-content: center">
              <div class="error-cross"></div>
            </v-col>
            <v-col style="display: flex; justify-content: center">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  color: #ff3333;
                  font-weight: bold;
                "
              >
                เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งภายหลัง
              </div>
            </v-col>
            <v-col class="pt-16 d-flex justify-end px-3">
              <v-btn @click="onCancelClick()" class="mr-3">กลับหน้าแรก</v-btn>
              <!-- <v-btn dark class="color-btnCard" @click="goToMessages()">
                หน้าการแจ้งเตือน<v-icon class="ml-2">mdi-logout</v-icon>
              </v-btn> -->
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="!isDownloading && !isSuccess && !isError"
      class="pt-16 d-flex justify-end px-3"
    >
      <v-btn @click="onCancelClick()" class="mr-3">ยกเลิก</v-btn>
      <v-btn dark class="color-btnCard" @click="exportReport()">
        ยืนยันการออกรายงาน<v-icon class="ml-2">mdi-logout</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { ReportServerProvider } from "@/services/ReportServerProvider";
import { create_default_request_header_for_blob_response } from "@/Constants";
const reportServerProvider = new ReportServerProvider(process.env.VUE_APP_REPORT_URL);
export default {
  name: "ReportExportFromQueue",
  props: ["report_name", "report_parameters"],
  data() {
    return {
      fileTypes: [
        {
          text: "PDF",
          value: "application/pdf",
          extension: ".pdf",
          image: require("@/assets/pdfLogo.png"),
        },
        {
          text: "CSV",
          value: "text/csv",
          extension: ".csv",
          image: require("@/assets/csvLogo.png"),
        },
        {
          text: "Excel",
          value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          extension: ".xls",
          image: require("@/assets/excelLogo.png"),
        },
      ],
      selectedFileTypeValue: "application/pdf",
      isDownloading: false,
      isSuccess: false,
      isError: false,
      isChecked: false,
      isRetrying: false,
      maxAttempt: 3,
      retryAttempt: 0,
      message: "",
    };
  },
  computed: {
    reportTHName() {
      switch (this.report_name) {
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByRegion_Report":
          return "รายงานภาวะการมีงานทำ (รายภาค)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByProvince_Report":
          return "รายงานภาวะการมีงานทำ (รายจังหวัด)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByInstitute_Report":
          return "รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report":
          return "รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report":
          return "รายงานภาวะการมีงานทำ (รายสถานศึกษา)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report":
          return "รายงานภาวะการมีงานทำ (รายประเภทวิชา)";
        case "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report":
          return "รายงานการติดตามภาวะผู้มีงานทำของสถานศึกษา (จำแนกตามสาขาวิชา)";
        case "GraduatedStudentJobTrackingReportMain_StudentDetail_Report":
          return "รายงานสรุปผลการติดตาม (รายบุคคล)";
        case "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySubjectType_Report":
          return "รายงานภาวะการมีงานทำ (รายประเภทวิชา)";
        case "School_GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_ByMajor_Report":
          return "รายงานการติดตามภาวะผู้มีงานทำของสถานศึกษา (จำแนกตามสาขาวิชา)";
        case "School_GraduatedStudentJobTrackingReportMain_StudentDetail_Report":
          return "รายงานสรุปผลการติดตาม (รายบุคคล)";
        case "GraduatedStudentJobTrackingReport_SchoolConclusion_ByProvince":
          return "รายงานสรุปผลการส่งรายงาน รายจังหวัด";
        case "GraduatedStudentJobTrackingReport_SchoolConclusion_ByRegion":
          return "รายงานสรุปผลการส่งรายงาน รายภาค";
        case "SchoolStudentList_Report":
          return "รายงานรายชื่อนักศึกษา";
      }
      return "";
    },
  },
  methods: {
    reset() {
      this.isSuccess = false;
      this.isError = false;
      this.message = "";
      this.retryAttempt = 0;
      this.isRetrying = false;
    },
    convertReportParametersToQueryString(reportParams = []) {
      return reportParams.join("&");
    },
    // async exportReport() {
    //   this.reset();
    //   this.isDownloading = true;
    //   const convertedReportParamters = this.convertReportParametersToQueryString(
    //     this.report_parameters
    //   );
    //   const extensionName = this.fileTypes.find(
    //     (x) => x.value == this.selectedFileTypeValue
    //   ).extension;
    //   // Retry up to 3 times
    //   try {
    //     let applicationUser_Oid = this.$store.getters["applicationuser_oid"];
    //     reportServerProvider
    //       .getReportByQueue(
    //         this.report_name,
    //         this.selectedFileTypeValue,
    //         convertedReportParamters,
    //         applicationUser_Oid
    //       )
    //       .then((result) => {
    //         this.$toast.success(
    //           "ส่งคำขอสำหรับออกรายงานเรียบร้อย ระบบจะแจ้งเตือนทันทีที่ทำการออกรายงานเสร็จสิ้น"
    //         );
    //         this.isDownloading = false;
    //         this.isSuccess = true;
    //         return;
    //       })
    //       .catch((error) => {
    //         this.isDownloading = false;
    //         this.isError = true;
    //         this.message = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งภายหลัง";
    //       });
    //   } catch (error) {
    //     // Log the error and retry if not on the last attempt
    //     console.error(`Error downloading PDF (Attempt ${attempt}):`, error);
    //     // Set error state if reached maximum attempts
    //     this.isDownloading = false;
    //     this.isError = true;
    //     this.message = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งภายหลัง";
    //   } finally {
    //     this.isRetrying = false;
    //   }
    // },
    async exportReport() {
      this.reset();
      this.isDownloading = true;
      const convertedReportParamters = this.convertReportParametersToQueryString(
        this.report_parameters
      );
      const extensionName = this.fileTypes.find(
        (x) => x.value == this.selectedFileTypeValue
      ).extension;

      try {
        let applicationUser_Oid = this.$store.getters["applicationuser_oid"];
        const result = await reportServerProvider.getReportByQueue(
          this.report_name,
          this.selectedFileTypeValue,
          convertedReportParamters,
          applicationUser_Oid
        );

        // console.log("Result status:", result.status);
        if (result.status == 200) {
          setTimeout(() => {
            this.$toast.success(
              "ส่งคำขอสำหรับออกรายงานเรียบร้อย ระบบจะแจ้งเตือนทันทีที่ทำการออกรายงานเสร็จสิ้น"
            );
            this.isSuccess = true;
          }, 2000);
        } else {
          throw new Error("เกิดข้อผิดพลาดในการขอออกรายงาน");
        }
      } catch (error) {
        setTimeout(() => {
          this.isError = true;
          this.message = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งภายหลัง";
          console.error("Error generating report:", error);
        }, 2000);
      } finally {
        setTimeout(() => {
          this.isDownloading = false;
          this.isRetrying = false;
        }, 2000);
      }
    },
    // async generateReport() {
    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       const success = Math.random() > 0.5;
    //       success ? resolve() : reject(new Error("Report generation failed"));
    //     }, 2000);
    //   });
    // },
    onCancelClick() {
      this.$emit("close_Dialog", false);
      this.reset();
    },
    async goToMessages() {
      this.$router.push({ name: "NotificationHistory" }).catch((err) => {});
    },
  },
};
</script>

<style scoped>
.color-btnCard {
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 60%,
    rgba(63, 81, 181, 1) 100%
  );
}
.checkmark-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-icon {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #4caf50;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.check-icon::before,
.check-icon::after {
  position: absolute;
  content: "";
  top: 55%;
  left: 12px;
  transform: scaleX(-1) rotate(135deg);
  height: 56px;
  width: 28px;
  border-top: 10px solid #fff;
  border-right: 10px solid #fff;
  transform-origin: left top;
  animation: check-icon 0.8s ease;
}

@keyframes check-icon {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 28px;
    opacity: 1;
  }

  40% {
    height: 56px;
    width: 28px;
    opacity: 1;
  }

  100% {
    height: 56px;
    width: 28px;
    opacity: 1;
  }
}

/* Error Cross Animation */
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-cross {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #f44336;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.error-cross::before,
.error-cross::after {
  position: absolute;
  content: "";
  width: 30px;
  height: 4px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.error-cross::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
