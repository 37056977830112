import axios from "axios"
import { create_default_request_header } from "./constants"
import { createEndpointUrl } from "@/common/api_connectivity_helper"
import { EditDefaultJobPostingSetup_Request } from "@/models/DefaultJobPostingSetups/EditDefaultJobPostingSetup_Request";

export class DefaultJobPostingSetupDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  getDefaultJobPostingSetup(){
      var header = create_default_request_header()
      var endpointUrl = "/api/DefaultJobPostingSetup/GetDefaultJobPostingSetup";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
      return axios.get(combinedURL, header)
  }

  editDefaultJobPostingSetup(request = new EditDefaultJobPostingSetup_Request()){
      var header = create_default_request_header()
      var endpointUrl = "/api/DefaultJobPostingSetup/EditDefaultJobPostingSetup";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
      const data = JSON.stringify(request);
      return axios.post(combinedURL, data, header)
  }
}
