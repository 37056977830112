import axios from "axios"
import { create_default_request_header } from "./constants"
import { createEndpointUrl } from "@/common/api_connectivity_helper"
export class CurriculumDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL
  }

  loadCurriculums() {
    try {
      const cachedData = localStorage.getItem(
        `Curriculums_${process.env.VUE_APP_VERSION}`
      )
      if (cachedData) {
        const data = JSON.parse(cachedData)

        return new Promise((resolve) => {
          let response = []
          response.status = 200
          response.data = data
          resolve(response)
        })
      } else {
        var header = create_default_request_header()
        var endpointUrl = "/api/Curriculum/GetCurriculums"
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
        return new Promise((resolve, reject) => {
          axios.get(combinedURL, header)
            .then((response) => {
              if (response?.status == 200) {
                resolve(response)
                localStorage.setItem(
                  `Curriculums_${process.env.VUE_APP_VERSION}`,
                  JSON.stringify(response.data)
                )
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" })
        }
        //  error: AxiosError<any, any>
        return error.message
      } else {
        return "An unexpected error occurred"
      }
    }
  }

  loadCurriculum(curriculumID) {
    try {
      var header = create_default_request_header()
      var endpointUrl = "/api/Curriculum/GetCurriculum/" + curriculumID
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
      return axios.get(combinedURL, header)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" })
        }
        //  error: AxiosError<any, any>
        return error.message
      } else {
        return "An unexpected error occurred"
      }
    }
  }
  importCurriculums() {
    try {
      var header = create_default_request_header()
      var endpointUrl = "/api/Curriculum/ImportCurriculumDataFromWebAPI"
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
      return axios.get(combinedURL, header)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" })
        }
        //  error: AxiosError<any, any>
        return error.message
      } else {
        return "An unexpected error occurred"
      }
    }
  }

  getRelatedIndustries(oid = "") {
    var header = create_default_request_header()
    var endpointUrl = "/api/Curriculum/GetRelatedIndustries/" + oid
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
    return axios.get(combinedURL, header)
  }
}
