var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center"},[_c('v-container',{staticClass:"ma-4"},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"lg":"11","md":"12","sm":"12","xs":"12"}},[_c('v-card',[_c('v-toolbar',{staticClass:"color-HeaderCard",attrs:{"height":"50px","flat":"","dark":""}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-circle ")]),_vm._v("ติดตามสถานะการรับสมัครงาน")],1)],1),(_vm.jobposting)?_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"10"}},[_c('h2',[_vm._v(_vm._s(_vm.jobposting.positionname))]),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"mr-2",attrs:{"cols":"auto"}},[_c('v-avatar',{staticClass:"my-2",attrs:{"color":"grey","size":"50"}},[_c('v-img',{attrs:{"src":'data:image/jpg;base64,' +
                        _vm.jobposting.employerprofile.logo_photobase64}})],1)],1),_c('v-col',[_c('div',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.jobposting.employerprofile.nameth))]),_c('v-icon',{staticClass:"ml-1 mr-0"},[_vm._v("mdi-map-marker-outline")]),_c('span',[_vm._v(" "+_vm._s(_vm.jobposting.workingplace_district.districtthaishort)+", "+_vm._s(_vm.jobposting.workingplace_province.provincethai)+" ")])],1),_c('div',{staticClass:"mt-1"},[_c('v-chip',{staticStyle:{"background-color":"rgba(63, 81, 181, 0.2)"},attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.jobposting.employmentcategory.name)+" ")])],1)]),_c('v-col',{staticClass:"text-right"},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":"","justify":"end"}},[_c('h4',{staticClass:"mr-2 primary--text"},[_vm._v("สถานะ :")]),_c('v-chip',{attrs:{"color":_vm.getStatusColor(
                          _vm.jobposting?.jobpostingstatus.statuscode
                        ),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.jobposting.jobpostingstatus.statusname)+" ")])],1),_c('div',{staticClass:"mt-1"},[_c('span',[_vm._v(" วันที่ประกาศ : ")]),_c('FormatDateView',{attrs:{"date_value":_vm.jobposting.startdate}}),_vm._v(" - "),_c('FormatDateView',{attrs:{"date_value":_vm.jobposting.enddate}})],1)],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("รหัสประกาศงาน : ")]),_c('a',{on:{"click":function($event){return _vm.openEmployerUpdateJobPostingView()}}},[_vm._v(_vm._s(_vm.jobposting.jobposting_id))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("ประเภทงาน : "+_vm._s(_vm.jobposting.jobsegmentname_todisplay))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("อัตราที่รับ : "+_vm._s(_vm.jobposting.unit_todisplay)+" คน")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("เงินเดือน : "+_vm._s(_vm.jobposting.wagetodisplay))])])],1),_c('v-row',{staticClass:"my-3"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mb-4 pa-2 light-gray-border",staticStyle:{"border-radius":"8px","height":"66.66%","width":"100%"},attrs:{"rounded":"","outlined":_vm.activeButton !== 1,"color":_vm.activeButton === 1 ? 'primary' : ''},on:{"click":function($event){_vm.setActive(1);
                      _vm.filterJobApplicationsByJobApplicationStatusCode('');}}},[_c('span',{class:{ 'primary--text': _vm.activeButton !== 1 }},[_vm._v("จำนวนใบสมัครทั้งหมด "+_vm._s(_vm.quantity_of_all_jobapplication))])])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mb-2 pa-2 light-gray-border justify-end",staticStyle:{"border-radius":"8px","height":"33.33%","width":"100%","text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"},attrs:{"rounded":"","outlined":_vm.activeButton !== 2,"color":_vm.activeButton === 2 ? 'primary' : ''},on:{"click":function($event){_vm.setActive(2);
                      _vm.filterJobApplicationsByJobApplicationStatusCode(
                        'Pending'
                      );}}},[_c('span',{class:{ 'primary--text': _vm.activeButton !== 2 }},[_vm._v("ยื่นใบสมัคร/รอการตรวจสอบ "+_vm._s(_vm.quantity_of_pending_jobapplication)+" คน")])]),_c('v-btn',{staticClass:"mb-2 pa-2 light-gray-border justify-end",staticStyle:{"border-radius":"8px","height":"33.33%","width":"100%"},attrs:{"rounded":"","outlined":_vm.activeButton !== 3,"color":_vm.activeButton === 3 ? 'primary' : ''},on:{"click":function($event){_vm.setActive(3);
                      _vm.filterJobApplicationsByJobApplicationStatusCode(
                        'UnderReview'
                      );}}},[_c('span',{class:{ 'primary--text': _vm.activeButton !== 3 }},[_vm._v("อยู่ระหว่างพิจารณา "+_vm._s(_vm.quantity_of_underreview_jobapplication)+" คน")])]),_c('v-btn',{staticClass:"pa-2 light-gray-border justify-end",staticStyle:{"border-radius":"8px","height":"33.33%","width":"100%"},attrs:{"rounded":"","outlined":_vm.activeButton !== 4,"color":_vm.activeButton === 4 ? 'primary' : ''},on:{"click":function($event){_vm.setActive(4);
                      _vm.filterJobApplicationsByJobApplicationStatusCode(
                        'InterviewScheduled'
                      );}}},[_c('span',{class:{ 'primary--text': _vm.activeButton !== 4 }},[_vm._v("นัดหมายสัมภาษณ์ "+_vm._s(_vm.quantity_of_interviewscheduled_jobapplication)+" คน")])])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mb-4 pa-2 light-gray-border justify-end",staticStyle:{"border-radius":"8px","height":"50%","width":"100%"},attrs:{"rounded":"","outlined":_vm.activeButton !== 5,"color":_vm.activeButton === 5 ? 'primary' : ''},on:{"click":function($event){_vm.setActive(5);
                      _vm.filterJobApplicationsByJobApplicationStatusCode(
                        'Accecpted'
                      );}}},[_c('span',{class:{ 'primary--text': _vm.activeButton !== 5 }},[_vm._v("รับเข้าทำงาน "+_vm._s(_vm.quantity_of_accecpted_jobapplication)+" คน")])]),_c('v-btn',{staticClass:"pa-2 light-gray-border justify-end",staticStyle:{"border-radius":"8px","height":"50%","width":"100%"},attrs:{"rounded":"","outlined":_vm.activeButton !== 6,"color":_vm.activeButton === 6 ? 'primary' : ''},on:{"click":function($event){_vm.setActive(6);
                      _vm.filterJobApplicationsByJobApplicationStatusCode(
                        'Rejected'
                      );}}},[_c('span',{class:{ 'primary--text': _vm.activeButton !== 6 }},[_vm._v("ไม่ผ่านการพิจารณา "+_vm._s(_vm.quantity_of_rejected_jobapplications)+" คน")])])],1)],1),_c('SearchableDataTable',{attrs:{"table_caption":"ใบสมัครงาน","data_items":_vm.filteredItems,"datatable_headers":_vm.datatable_headers},scopedSlots:_vm._u([{key:"item.createddate",fn:function({ item }){return [_c('FormatDate',{attrs:{"date_value":item.createddate}})]}},{key:"item.jobapplicationstatus.statusname",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('JobApplicationStatus_Label',{attrs:{"JobApplication":item}})],1)]}},{key:"item.student.firstname",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.student.firstname))]),_vm._v(" "+_vm._s(item.student.surname)+" ")]}},{key:"item.student.birthday",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.moment().diff(item.student.birthday, "years")))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""},on:{"click":function($event){return _vm.openJobApplicationDetailView(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file")])],1)]}}],null,true)},[_c('span',[_vm._v("แสดงรายละเอียดใบสมัครงาน")])])]}}],null,false,437397607)}),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('JobApplicatiionStatusManagement',{attrs:{"JobApplication":_vm.selected_jobapplication,"sentopenBack":false},on:{"finished":_vm.onJobApplicationStatusManagementFinished}})]],2)],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }