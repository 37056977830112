<template>
  <v-row justify="center">
    <v-dialog v-model="CertificationConfirm_Dialog" persistent width="600">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-system-bar color="green darken-2" class="py-6" dark>
            <v-card-title class="">
              <v-icon large>mdi-check-circle-outline</v-icon>
              ยืนยันการส่งรายงาน
            </v-card-title>
          </v-system-bar>
          <v-card-text
            ><h3>
              <strong>รับรองโดย :</strong>
              {{ verifymanager.name }}
              {{ verifymanager.surname }}
              <!-- &nbsp;<strong>ตำแหน่ง :</strong>&nbsp;
              {{ verifymanager.position }} -->
            </h3></v-card-text
          >
          <v-card-text
            ><h3><strong>ตำแหน่ง :</strong> {{ verifymanager.position }}</h3></v-card-text
          >
          <v-card-text
            ><h3>
              <strong>ส่งรายงานโดย :</strong>
              <span v-if="!sender">{{ $store.getters["name"] + " " }}</span>
              <span v-if="!sender">{{ $store.getters["surname"] }}</span>
              <span v-if="sender">{{ sender.name }}</span>
              <span v-if="sender">{{ sender.lastname }}</span>
            </h3></v-card-text
          >
          <v-card-text
            ><h3>
              <label readonly caption="วันที่" v-show="date">
                <strong>วันที่ :</strong>
                <!-- {{ date }} -->
                {{ currentDate() }}
                เวลา {{ moment().format("HH:mm") }} นาที
              </label>
            </h3>
          </v-card-text>
          <!-- @closeAndUpdate="this.closeAndUpdateTextFieldDatePicker_certificatedDate" -->

          <v-card-actions class="justify-center">
            <!-- <v-spacer></v-spacer> -->
            <v-btn class="success" @click="onConfirmClick()"> ตกลง </v-btn>
            <v-btn @click="onCancelClick()"> ยกเลิก </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import moment from "moment";
export default {
  name: "DialogCertificationConfirm",
  name: "CurrentDate",
  props: {
    CertificationConfirm_Dialog: Boolean,
    verifymanager: [],
    msg: String,
    sender: [],
  },
  data() {
    return {
      moment: moment,
      valid: true,
      selected_certificatedDate: "",
      date: "",
      month: "",
      currentYear: "",
    };
  },
  components: {
    TextFieldDatePicker,
  },
  methods: {
    // closeAndUpdateTextFieldDatePicker_certificatedDate(value) {
    //   this.selected_certificatedDate = value;
    // },
    onConfirmClick() {
      if (this.$refs.form.validate()) {
        this.$emit("closeDialog", this.selected_certificatedDate, this.sender, true);
      }
    },
    onCancelClick() {
      this.$emit("closeDialog", "", false);
    },
    getDate: function () {
      return new Date().toLocaleDateString();
    },
    getMonth: function () {
      return new Date().getMonth();
    },
    getCurrentYear: function () {
      return new Date().getFullYear();
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth() + 1}/${
        current.getFullYear() + 543
      }`;
      return date;
    },
  },
  mounted: function () {
    this.date = this.getDate();
    this.month = this.getMonth();
    this.currentYear = this.getCurrentYear();
  },
};
</script>

<style></style>
