import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
import { JobPostingSearch_Request } from '@/models/JobPostingSearcher/JobPostingSearch_Request';
import { HotJobPostingSearch_Request } from '@/models/JobPostingSearcher/HotJobPostingSearch_Request';
import { MonthlyJobPostingSearch_Request } from '@/models/JobPostingSearcher/MonthlyJobPostingSearch_Request';
export class JobPostingSearcherDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    loadJobPostings(vm = new JobPostingSearch_Request) {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPostingSearcher/GetJobPostings";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,vm, header);
    }

    getJobPosting(oid = ''){
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPostingSearcher/GetJobPosting/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header);
    }

    loadHotJobPostings(vm = new HotJobPostingSearch_Request) {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPostingSearcher/GetHotJobPostings";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,vm, header);
    }

    loadMonthlyJobPostings(vm = new MonthlyJobPostingSearch_Request) {
        var header = create_default_request_header();
        var endpointUrl = "/api/JobPostingSearcher/GetMonthlyJobPostings";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,vm, header);
    }
}