import DistrictListView from "@/views/Province/DistrictListView";
export default [
  {
    path: "/District",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/District/DistrictListView/:provinceID",
        name: "DistrictListView",
        component: DistrictListView,
        meta: {
          requiresAuth: true,
          nameTh: "อำเภอ",
        },
      },
    ],
  },
];
