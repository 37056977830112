import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
export class StudentJobPostingDataProvider 
{
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    getJobApplications() {
        var header = create_default_request_header();
        var endpointUrl = "/api/StudentJobPosting/GetJobApplications";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header);
    }   

    createJobApplication(jobposting_oid = ''){
        var header = create_default_request_header();
        var endpointUrl = "/api/StudentJobPosting/CreateJobApplication/" + jobposting_oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header);
    }

    isRegisted(jobposting_oid = ''){
        var header = create_default_request_header();
        var endpointUrl = "/api/StudentJobPosting/isRegisted/" + jobposting_oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header);
    }

    deleteJobApplication(jobapplication_oid = ''){
        var header = create_default_request_header();
        var endpointUrl = "/api/StudentJobPosting/DeleteJobApplication/" + jobapplication_oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header);
    }

    markReadNotifyMessage(jobapplication_oid = ''){
        var header = create_default_request_header();
        var endpointUrl = "/api/StudentJobPosting/MarkReadNotifyMessage/" + jobapplication_oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header);
    }
}