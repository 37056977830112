import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';

export class RegisterEmployeeProfileDataProvider 
{
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

   register(vm = new FormData()){
        const header = create_default_request_header();
        const endpointUrl = "/api/RegisterEmployerProfile/Register";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, vm, header);
   } 
}