<style></style>
<template>
  <div
    ref="viewer"
    style="position: absolute; left: 0; right: 0; top: 0; bottom: 0"
    data-bind="dxReportViewer: $data"
  ></div>
</template>

<script>
import ko from "knockout";
import "devexpress-reporting/dx-webdocumentviewer";

export default {
  name: "DocumentViewer",
  props: ["report_name", "report_parameters"],
  data() {
    return {};
  },
  methods: {
    convertReportParametersToQueryString(reportParams = []) {
      return reportParams.join("&");
    },
  },
  mounted() {
    var viewerOptions = {
      reportUrl: ko.observable(
        this.report_name +
          this.convertReportParametersToQueryString(this.report_parameters)
      ), // The URL of a report that is opened in the Document Viewer when the application starts.
      requestOptions: {
        host: process.env.VUE_APP_REPORT_URL,
        invokeAction: "DXXRDV",
      },
    };
    ko.applyBindings(viewerOptions, this.$refs.viewer);
  },
  beforeDestroy() {
    ko.cleanNode(this.$refs.viewer);
  },
};
</script>
