export class EmployerProfilesRequest {
    RegionAsVEC = "";
    ProvinceID = "";
    DistrictID = "";
    CheckDBD = false
    PageNumber = 1;
    ItemsPerPage = 10;
    Keyword = "";
    SortBy = [];
    SortDesc = [];
}