<template>
    <v-snackbar :value=snackbar absolute bottom :color=color text>
        {{ text }}
    </v-snackbar>
</template>
<script>
export default {
    name: 'CustomSnackbar',
    props: {
        text: [String],
        snackbar: { type: [Boolean], default: false },
        color:{ type: [String], default: 'success' },
    },
    data() {
        return {
            local_text: this.text,
            local_snackbar: this.snackbar,
            local_color: this.color,
        }
    },
    watch: {
        snackbar: function (newVal, /*oldVal*/) {
            if (newVal) {
                setTimeout(() => {
                    this.$emit('close-snackbar', newVal);
                }, 1000)
            }
        }
    },
    methods: {},
    mounted() {
        this.local_text = this.text;
        this.local_snackbar = this.snackbar;
        this.local_color = this.color;
    },
}
</script>
<style>

</style>