<template>
  <v-app>
    <loading></loading>
    <router-view />
  </v-app>
</template>

<script>
import store from "@/store";
import { event } from "jquery";
import Loading from "./components/common/Loading.vue";
import {
  LocalStorageKey_JobApplicationEntityStatuses,
  LocalStorageKey_CertificateTypes,
  LocalStorageKey_JobSegments,
  LocalStorageKey_Nationalities,
  LocalStorageKey_Provinces,
  LocalStorageKey_Schools,
} from "./LocalStorageDataKeys";
export default {
  name: "App",
  components: {},
  computed: {},
  async mounted() {
    console.log(
      "process.env.VUE_APP_BACKEND_API_URL = " +
        process.env.VUE_APP_BACKEND_API_URL
    );
    // if (localStorage.getItem("darktheme")) {
    //   let darkMode = JSON.parse(localStorage.getItem("darktheme"));
    //   this.$vuetify.theme.dark = darkMode;
    // }
  },
  data() {
    return {};
  },
  methods: {
    clearLocalStorages() {
      localStorage.removeItem(LocalStorageKey_Schools);
      localStorage.removeItem(LocalStorageKey_Provinces);
      localStorage.removeItem(LocalStorageKey_JobSegments);
      localStorage.removeItem(LocalStorageKey_Nationalities);
      localStorage.removeItem(LocalStorageKey_JobApplicationEntityStatuses);
      localStorage.removeItem(LocalStorageKey_CertificateTypes);
    },
  },
  created() {
    if (window.performance.getEntriesByType("navigation")[0].type == "reload") {
      this.clearLocalStorages();
    }
  },
};
</script>

<style>
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li,
label,
a,
div {
  font-family: Prompt, Kanit, sans-serif;
}

th {
  font-size: 14px !important;
  font-weight: 300 !important;
}

span.v-list-item__action-text {
  font-size: 14px !important;
  font-weight: 400 !important;
}

canvas {
  font-family: Prompt, Kanit, sans-serif !important;
}

h1 {
  font-size: 1.9em;
  font-weight: 500;
}

h2 {
  font-size: 1.65em;
  font-weight: 400;
}

h3 {
  font-size: 1.35em;
  font-weight: 400;
}

/*    input[type="text"][readonly], input[type="text"][disabled] {
        color: lightgrey !important;
    }*/

.v-toast__text {
  color: white !important;
}
.v-application--wrap {
  background: radial-gradient(
    592px at 48.2% 50%,
    rgba(255, 255, 249, 0.1) 0%,
    rgba(160, 199, 254, 0.1) 74.6%
  );
}
.color-button {
  color: white !important;
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
.color-button2 {
  background: linear-gradient(90deg, #2196f3 0%, #3f51b5 100%);
  color: white !important;
}
.back-button {
  background-color: white !important;
}
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgb(62, 83, 183) 20%,
    rgb(66, 81, 167) 30%,
    rgb(88, 123, 201) 50%,
    rgb(46, 126, 220) 60%,
    rgb(84, 170, 235) 80%,
    rgb(127, 202, 246) 100%
  );
  color: white !important;
}

.toolbar-Header {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  color: white !important;
}
</style>
