<template>
  <v-container class="pa-4 mt-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="pa-4 mt-5">
            <div class="pa-3">
              <v-row>
                <v-col cols="12" class="d-flex justify-start">
                  <h3 class="primary--text">
                    <v-icon class="primary--text">mdi-account</v-icon>
                    ข้อมูลผู้ดูแลระบบ
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">ชื่อ</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    label="ชื่อ"
                    v-model="vm.firstname"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h4 class="primary--text mb-2">นามสกุล</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    label="นามสกุล"
                    v-model="vm.lastname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h4 class="primary--text mb-2">อีเมล</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    label="Email"
                    v-model="vm.email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h4 class="primary--text mb-2">ชื่อผู้ใช้งาน (username)</h4>

                  <v-text-field
                    background-color="input"
                    filled
                    readonly
                    label="ชื่อผู้ใช้งาน (username)"
                    v-model="vm.username"
                  ></v-text-field>
                </v-col>
              </v-row></div
          ></v-card>
          <v-card class="pa-4 mt-5">
            <div class="pa-3">
              <v-row>
                <v-col cols="12" class="d-flex justify-start">
                  <h3 class="primary--text">
                    <v-icon class="primary--text">mdi-account</v-icon>
                    เกี่ยวกับการเข้าใช้งาน
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12">
                  <CurrentApplicationUserInfo> </CurrentApplicationUserInfo>
                </v-col>
              </v-row></div
          ></v-card>
        </v-col>
      </v-row>

      <v-row class="justify-center text-center mt-4 mb-4">
        <v-col cols="12">
          <v-btn color="" @click="$router.go(-1)" class="ma-2 justify-center">
            ย้อนกลับ</v-btn
          >
          <v-btn color="color-buton" @click="save">
             บันทึก<v-icon>mdi-content-save</v-icon>
          </v-btn>
          
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { UpdateAdministratorProfile_Request } from "@/models/AdministratorProfiles/UpdateAdministratorProfile_Request";
import { AdministratorProfileDataProvider } from "@/services/AdministratorProfileDataProvider";
import CurrentApplicationUserInfo from "@/components/ApplicationUser/CurrentApplicationUserInfo.vue";
const administratorProfileDataProvider = new AdministratorProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: { CurrentApplicationUserInfo },
  name: "UpdateAdministratorProfileDetailView",
  data() {
    return {
      oid: "",
      vm: new UpdateAdministratorProfile_Request(),
    };
  },
  methods: {
    async getAdministratorProfile() {
      await administratorProfileDataProvider
        .getAdministratorProfile(this.oid)
        .then((result) => {
          this.vm.oid = result.data.oid;
          this.vm.firstname = result.data.firstname;
          this.vm.lastname = result.data.lastname;
          this.vm.email = result.data.email;
          this.vm.username = result.data.username;
        });
    },
    async save() {
      await administratorProfileDataProvider
        .updateAdministratorProfile(this.vm)
        .then((result) => {
          this.$store.dispatch({ type: "doSetIsLoadingProfile" });
          this.$router.back();
        });
    },
  },
  async created() {
    this.oid = this.$route.params.oid;
    await this.getAdministratorProfile();
  },
};
</script>

<style scoped>

.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  color: white !important;
}
</style>
