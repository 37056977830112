import { createEndpointUrl } from '@/common/api_connectivity_helper';
import { CreateGraduatedStudentJobTrackingReportMain_Request } from '@/models/GraduatedStudentJobTrackingReport/CreateGraduatedStudentJobTrackingReportMain_Request';
import { GetGraduatedStudentJobTrackingReports_Request } from '@/models/GraduatedStudentJobTrackingReport/GetGraduatedStudentJobTrackingReports_Request';
import axios from 'axios';
import { create_default_request_header } from '../constants';
export class GraduatedStudentJobTrackingReportValidator {
    apiBaseURL = '';
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    validateGraduatedStudentJobTrackingReport(oid = '') {
        const headers = create_default_request_header();
        var endpointPath = "/api/GraduatedStudentJobTrackingReport/ValidateGraduatedStudentJobTrackingReport/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL, headers);
    }
}
