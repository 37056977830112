import CurrentApplicationUserChangeThierOwnPassword from "@/views/ApplicationUser/CurrentApplicationUserChangeThierOwnPassword.vue";
export default [
  {
    path: "/ApplicationUser",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/ApplicationUser/CurrentApplicationUserChangeThierOwnPassword",
        name: "CurrentApplicationUserChangeThierOwnPassword",
        component: CurrentApplicationUserChangeThierOwnPassword,
        meta: {
          requiresAuth: true,
          nameTh: "เปลี่ยนแปลงรหัสผ่าน",
        },
      },
    ],
  },
];
