<template>
  <v-btn
    icon
    dark
    color="error"
    @click="addFavorite(JobPosting)"
    :class="{ 'is-favorite': isFavorite }"
  >
    <v-icon v-if="isFavorite">mdi-heart</v-icon>
    <v-icon v-else>mdi-heart-outline</v-icon>
  </v-btn>
</template>

<script>
import { StudentUserBookmarkDataProvider } from "@/services/StudentUserBookmarkDataProvider";
import { SaveInterestedJobPostingBookmark_Request } from "@/models/StudentUserBookmarks/SaveInterestedJobPostingBookmark_Request";
import { DeleteInterestedInJobPostingBookmark_Request } from "@/models/StudentUserBookmarks/DeleteInterestedInJobPostingBookmark_Request";
const studentUserBookmarkDataProvider = new StudentUserBookmarkDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "BtnFavoriteJobPosting",
  props: ["JobPosting"],
  data() {
    return {
      isFavorite: false,
    };
  },
  methods: {
    async initialize() {
      try {
        const result = await studentUserBookmarkDataProvider.isJobPostingExistInBookmark(
          this.JobPosting.oid
        );
        this.isFavorite = result.data;
      } catch (error) {
        console.log(
          "Error initializing favorite status:",
          error.response ? error.response.data : error.message
        );
      }
      // await studentUserBookmarkDataProvider.isJobPostingExistInBookmark(this.JobPosting.oid).then(result => {
      //     this.isFavorite = result.data;
      // });
    },
    async addFavorite(jobPosting) {
      try {
        this.isFavorite = !this.isFavorite;
        const jobposting_oid = this.JobPosting.oid;
        if (this.isFavorite) {
          let request = new SaveInterestedJobPostingBookmark_Request();
          request.JobPosting_Oid = jobposting_oid;
          await studentUserBookmarkDataProvider.saveInterestedJobPostingBookmark(request);
          this.$toast.success("เพิ่มข้อมูลประกาศงานที่สนใจเรียบร้อย");
        } else {
          let request = new DeleteInterestedInJobPostingBookmark_Request();
          request.JobPosting_Oid = jobposting_oid;
          await studentUserBookmarkDataProvider.DeleteInterestedInJobPostingBookmark(
            request
          );
          this.$toast.warning("ลบข้อมูลประกาศงานที่สนใจเรียบร้อย");
        }
      } catch (error) {
        console.error(
          "Error adding/removing favorite job posting:",
          error.response ? error.response.data : error.message
        );
        this.$toast.error("ไม่สามารถเพิ่ม/ลบข้อมูลประกาศงานที่สนใจได้");
      }

      //   this.isFavorite = !this.isFavorite;
      //   const jobposting_oid = this.JobPosting.oid;
      //   if (this.isFavorite == true) {
      //     let request = new SaveInterestedJobPostingBookmark_Request();
      //     request.JobPosting_Oid = jobposting_oid;
      //     await studentUserBookmarkDataProvider
      //       .saveInterestedJobPostingBookmark(request)
      //       .then((result) => {
      //         this.$toast.success("เพิ่มข้อมูลประกาศงานที่สนใจเรียบร้อย");
      //       });
      //   } else {
      //     let request = new DeleteInterestedInJobPostingBookmark_Request();
      //     request.JobPosting_Oid = jobposting_oid;
      //     await studentUserBookmarkDataProvider
      //       .DeleteInterestedInJobPostingBookmark(request)
      //       .then((result) => {
      //         this.$toast.warning("ลบข้อมูลประกาศงานที่สนใจเรียบร้อย");
      //       });
      //   }
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style></style>
