export class SetJobApplicationStatusRequest 
{
    oid = ''
    statuscode = ''
    interviewdate = ''
    interviewtime = ''
    interviewlocation = ''
    notifymessage = ''

    static fromJson(json){
        let request = new SetJobApplicationStatusRequest();
        request.oid = json.oid;
        request.statuscode = json.jobapplicationstatus.statuscode; 
        request.interviewdate = json.interviewdate; 
        request.interviewtime = json.interviewtime; 
        request.interviewlocation = json.interviewlocation; 
        request.notifymessage = json.notifymessage;
        return request;
    }
}