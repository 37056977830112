<template>
    <v-card v-if="Student" class="animated-card">
        <v-container>
            <v-row>
                <v-col class="d-flex justify-end mb-3">
                    <BtnFavoriteToggle :student="Student"></BtnFavoriteToggle>
                </v-col>
            </v-row>
            <v-row>
                <v-row>
                    <v-col cols="7" class="">
                        <v-row>
                            <v-col>
                                <v-btn text dark color="success">
                                    <h3 v-if="Student">
                                        <b>{{ Student.titlename.titlenameth }} {{ Student.firstname }}
                                            {{ Student.surname }}</b>
                                    </h3>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-col class="ml-1">
                            เพศ :
                            <span v-if="Student.gender == 'F'" class="pink--text mr-4">
                                <v-icon class="pink--text">mdi-gender-female</v-icon>หญิง
                            </span>
                            <span v-if="Student.gender == 'M'" class="blue--text mr-4">
                                <v-icon class="blue--text mr-1">mdi-gender-male</v-icon>ชาย
                            </span>
                            <br /><br />

                            <span>อายุ : {{ moment().diff(Student.birthday, "years") }}</span><br /><br />

                            <span>ระดับชั้น : {{ Student.gradenameth }}</span><br /><br />

                            <span>สาขาวิชา : {{ Student.majornameth }}</span><br /><br />
                            <span>จังหวัด : {{ school.tambon.district.province.provincethai }}</span>
                        </v-col>

                    </v-col>
                </v-row>
                <v-col cols="5">
                    <v-row>
                        <v-divider vertical></v-divider>
                        <v-col>
                            ประเภทงานที่ต้องการ :
                            <span v-if="!Student.lookingforjob?.fulltime">งานประจำ</span><br /><br />
                            ตำแหน่งงานที่ต้องการสมัคร :<br />
                            <span>1.{{ Student.lookingforjob?.interestedjob1 }}</span><br />
                            <span>2.{{ Student.lookingforjob?.interestedjob2 }}</span><br />
                            <span>3.{{ Student.lookingforjob?.interestedjob3 }}</span><br /><br />
                            <span>เงินเดือน : {{ Student.lookingforjob?.rangesalary }}</span><br /><br />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="d-flex justify-center ma-3">
                        <span>ความสมบูรณ์ของเรซูเม่</span>
                    </v-row>
                    <v-row class="d-flex justify-center ma-3">
                        <CompletionPercentage v-if="Student" :percen="Student.completionpercentage"></CompletionPercentage>
                    </v-row>
                    <v-row class="d-flex justify-center ma-3">
                        <v-col class="d-flex justify-end mb-3">
                            <v-btn block style="background-cokir" color="primary"
                                @click="goToSearchStudentDetails(Student)"><v-icon>mdi-account</v-icon>รายละเอียดเพิ่มเติม</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import BtnFavoriteToggle from "@/components/SearchStudentResume/BtnFavoriteToggle.vue";
import CompletionPercentage from "../Profile/CompletionPercentage.vue";
import moment from "moment";
import { SchoolDataProvider } from '@/services/SchoolDataProvider';
const schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
    components: { BtnFavoriteToggle,CompletionPercentage },
    name: 'StudentSimpleResumeCard',
    props: ['Student'],
    data() {
        return {
            school : undefined,
            moment: moment,
        }
    },
    methods: {
        async initilize(){
            let schoolid = this.Student.schoolid;
           await schoolDataProvider.loadSchool(schoolid).then(result => {
            this.school = result.data;
           });
        },
        goToSearchStudentDetails(item) {
            this.$router
                .push({
                    name: "SearchStudentDetails",
                    params: { student_oid: item.oid },
                })
                .catch((err) => { });
        },
    },
    async created(){
        this.daysDifference = moment().diff("2000-02-28", "years");
        await this.initilize();
    }
}
</script>

<style>
.animated-card {
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.animated-card:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>