import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  // theme: dark, light
  dark: true,
  theme: {
    themes: {
      light: {
        primary: "#374389",
        secondary: "#2196f3",
        accent: "#009688",
        // error: '#f44336',
        // error: '#ff3333',
        error: "#B00020",
        warning: "#ff9800",
        info: "#03a9f4",
        success: "#4caf50",
        userhiglignt: "#9CCC65",
        card: "#f1efef",
        chip:'#D9DCF0',
        input: '#F2F4F8',
        tab: "#000"
      },
      dark: {
        //primary: colors.blue.lighten3,
        primary: colors.blue.lighten1,
        secondary: colors.lightBlue.darken1,
        accent: colors.lightGreen.lighten2,
        error: colors.red.lighten1,
        warning: colors.orange.lighten2,
        info: colors.lightBlue.lighten2,
        success: colors.green.lighten1,
        userhiglignt: "#43A047",
        card: "#bab9b9",
      },
    },
  },
  icons: {
    //'mdiSvg' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    iconfont: "mdi",
    iconfont: "fa",
    iconfont: "mdiSvg",
  },
});
