import MinorListView from "@/views/Minor/MinorListView.vue";
import MinorDetailView from "@/views/Minor/MinorDetailView.vue";
export default [
  {
    path: "/Minor",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Minor/MinorListView",
        name: "MinorListView",
        component: MinorListView,
        meta: {
          requiresAuth: true,
          nameTh: "สาขางาน",
        },
      },
      {
        path: "/Minor/MinorDetailView/:minorID",
        name: "MinorDetailView",
        component: MinorDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดสาขางาน",
        },
      },
    ],
  },
];
