<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="10" md="12" sm="12" xs="12">
          <v-card>
            <v-toolbar class="color-HeaderCard" dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >เพิ่มข้อมูลสถานประกอบการ</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="d-flex justify-center">
              <v-col cols="10" class="mt-0">
                <v-tabs
                  fixed-tabs
                  v-model="tab"
                  slider-size="5"
                  active-class="black--text"
                >
                  <v-tabs-slider
                    :style="{ height: tabIndicatorHeight + 'px' }"
                  ></v-tabs-slider>
                  <v-tab :ripple="false">1. ข้อมูลทั่วไป</v-tab>
                  <v-tab>2. ผู้ติดต่อ / ผู้ประสานงาน</v-tab>
                  <v-tab>3. บัญชีผู้ใช้งาน</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="mt-5">
                  <!-- ข้อมูลทั่วไป -->
                  <v-tab-item>
                    <v-form ref="generalForm">
                      <v-row class="d-flex justify-space-between align-center">
                        <v-col cols="6">
                          <h3 class="primary--text">
                            <v-icon color="primary" class="mr-1"
                              >mdi-account-box</v-icon
                            >ข้อมูลทั่วไป
                          </h3>
                        </v-col>
                        <v-col class="d-flex justify-end">
                          <v-btn class="mx-3" outlined @click="fetchedData"
                            >ดึงข้อมูลจาก
                            <v-img
                              alt="VEC Logo"
                              class="shrink ml-2"
                              contain
                              src="@/assets/DBDlogo.png"
                              transition="scale-transition"
                              width="40"
                          /></v-btn>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-2"></v-divider>

                      <v-row class="mt-5">
                        <v-col cols="8" class="my-0 py-0">
                          <h4 class="primary--text mb-2">ชื่อสถานประกอบการ</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="ชื่อสถานประกอบการ"
                            :rules="[
                              (v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ',
                            ]"
                            v-model="vm.nameth"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="my-0 py-0">
                          <h4 class="primary--text mb-2">ประเภทสมาชิก</h4>
                          <v-select
                            filled
                            background-color="input"
                            :rules="[(v) => !!v || 'กรุณาเลือกประเภทสมาชิก']"
                            placeholder="ประเภทสมาชิก"
                            :items="employerProfileTypes"
                            item-value="code"
                            item-text="nameth"
                            clearable
                            v-model="vm.selected_employerprofiletypecode"
                          ></v-select>
                        </v-col>
                      </v-row>

                      <!-- ประเภทสถานประกอบการ -->
                      <v-row class="mt-2">
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-2">
                            ทะเบียนการค้าเลขที่ / ทะเบียน ภพ.20
                          </h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            ref="txtIdentityid"
                            :rules="custom_profiletypecodeRules"
                            :placeholder="identityid_caption"
                            v-model="vm.identityid"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-2">หมายเลขประกันสังคม</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            ref="txtIdentityid"
                            placeholder="หมายเลขประกันสังคม"
                            :rules="security_numberRules"
                            v-model="vm.social_security_number"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <h3 class="primary--text">
                            <v-icon color="primary" class="mr-1"
                              >mdi-account-box</v-icon
                            >ที่อยู่สถานประกอบการ
                          </h3>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-2"></v-divider>
                      <v-row class="mt-4">
                        <v-col cols="12" class="my-0 py-0">
                          <h4 class="primary--text mb-2">ที่อยู่</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            ref="txtEmail"
                            :rules="[(v) => !!v || 'กรุณาระบุที่อยู่']"
                            placeholder="ที่อยู่"
                            v-model="vm.address_no"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="mt-2">
                        <v-col cols="3" class="my-0 py-0">
                          <h4 class="primary--text mb-2">จังหวัด</h4>
                          <v-autocomplete
                            filled
                            background-color="input"
                            clearable
                            placeholder="จังหวัด"
                            :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                            :items="provinces"
                            item-text="provincethai"
                            item-value="provinceid"
                            @change="loadDisticts"
                            v-model="vm.selected_province_id"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3" class="my-0 py-0">
                          <h4 class="primary--text mb-2">อำเภอ</h4>
                          <v-autocomplete
                            filled
                            background-color="input"
                            clearable
                            placeholder="อำเภอ"
                            :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                            :items="districts"
                            item-text="districtthai"
                            item-value="districtid"
                            @change="loadTambons"
                            v-model="vm.selected_district_id"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3" class="my-0 py-0">
                          <h4 class="primary--text mb-2">ตำบล</h4>
                          <v-select
                            filled
                            background-color="input"
                            placeholder="ตำบล"
                            clearable
                            :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                            :items="tambons"
                            item-text="tambonthaishort"
                            item-value="tambonid"
                            v-model="vm.selected_tambon_id"
                            @change="onTambonSelected"
                          ></v-select>
                        </v-col>
                        <v-col cols="3" class="my-0 py-0">
                          <h4 class="primary--text mb-2">รหัสไปรษณีย์</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="รหัสไปรษณีย์"
                            v-model="postcode"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                  <!-- ผู้ติดต่อ/ผู้ประสานงาน -->
                  <v-tab-item>
                    <v-form ref="contactForm">
                      <v-row>
                        <v-col cols="12">
                          <h3 class="primary--text">
                            <v-icon color="primary" class="mr-1"
                              >mdi-account-box</v-icon
                            >ข้อมูลบุคลากร
                          </h3>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-2"></v-divider>

                      <v-row class="mt-5">
                        <v-col cols="4" class="my-0 py-0">
                          <h4 class="primary--text mb-2">คำนำหน้าชื่อ</h4>
                          <v-select
                            filled
                            background-color="input"
                            placeholder="คำนำหน้าชื่อ"
                            :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                            :items="titlename"
                            item-text="titlenameth"
                            item-value="titlenameid"
                            v-model="vm.coordinator_titleName_id"
                          ></v-select>
                        </v-col>
                        <v-col cols="4" class="my-0 py-0">
                          <h4 class="primary--text mb-2">ชื่อ</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="ชื่อ"
                            :rules="[(v) => !!v || 'กรุณาระบุชื่อ']"
                            v-model="vm.coordinator_firstName"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="my-0 py-0">
                          <h4 class="primary--text mb-2">นามสกุล</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="นามสกุล"
                            :rules="[(v) => !!v || 'กรุณาระบุนามสกุล']"
                            v-model="vm.coordinator_lastName"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-5">
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-2">ตำแหน่ง</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="ตำแหน่ง"
                            :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                            v-model="vm.coordinator_positionname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-2">โทรศัพท์</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="โทรศัพท์"
                            :rules="phoneRules"
                            v-model="vm.coordinator_phone"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-5">
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-2">อีเมล</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="อีเมล"
                            :rules="custom_emailRules"
                            v-model="vm.coordinator_email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-2">Fax</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="Fax"
                            v-model="vm.coordinator_fax"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                  <!-- บัญชีผู้ใช้งาน -->
                  <v-tab-item>
                    <v-form ref="accountForm">
                      <v-row>
                        <v-col cols="12">
                          <h3 class="primary--text">
                            <v-icon color="primary" class="mr-1"
                              >mdi-account-box</v-icon
                            >บัญชีผู้ใช้งาน
                          </h3>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-2"></v-divider>

                      <v-row class="mt-5">
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-0 inline-block">
                            ชื่อบัญชีผู้ใช้(username)
                            <p class="red-text inline-block">*</p>
                          </h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            prepend-inner-icon="mdi-account-outline"
                            placeholder="กรุณาระบุชื่อบัญชีผู้ใช้"
                            :rules="custom_usernameRules"
                            v-model="vm.username"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-5">
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-0" inline-block>
                            รหัสผ่าน (Password)
                            <p class="red-text inline-block">*</p>
                          </h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="กรุณาระบุรหัสผ่าน"
                            :append-icon="
                              isShowPassword ? 'visibility' : 'visibility_off'
                            "
                            @click:append="isShowPassword = !isShowPassword"
                            :type="isShowPassword ? 'text' : 'password'"
                            :rules="passwordRules"
                            v-model="vm.password"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-0" inline-block>
                            ยืนยันรหัสผ่าน (Confirn Password)
                            <p class="red-text inline-block">*</p>
                          </h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="กรุณายืนยันรหัสผ่าน"
                            :append-icon="
                              isComfirmPasswordShowPasword
                                ? 'visibility'
                                : 'visibility_off'
                            "
                            @click:append="
                              isComfirmPasswordShowPasword =
                                !isComfirmPasswordShowPasword
                            "
                            :type="
                              isComfirmPasswordShowPasword ? 'text' : 'password'
                            "
                            :rules="confirmPasswordRules"
                            v-model="vm.confirm_password"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-5">
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-0 inline-block">
                            อีเมล
                            <p class="red-text inline-block">*</p>
                          </h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            ref="txtEmail"
                            placeholder="อีเมล"
                            :rules="custom_emailRules"
                            v-model="vm.email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="my-0 py-0">
                          <h4 class="primary--text mb-0 inline-block">
                            ยืนยันอีเมล
                            <p class="red-text inline-block">*</p>
                          </h4>
                          <v-text-field
                            background-color="input"
                            filled
                            clearable
                            placeholder="ยืนยันอีเมล"
                            :rules="
                              ([(v) => !!v || 'กรุณายืนยันอีเมล'],
                              [(v) => v === this.vm.email || 'อีเมลไม่ตรงกัน'])
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-card-text>
          </v-card>
          <v-row class="my-5 justify-end">
            <v-btn v-if="tab > 0" class="mx-3" @click="prevTab">
              ย้อนกลับ
            </v-btn>
            <v-btn
              v-if="tab < 2"
              class="mx-3 color-btnCard"
              dark
              @click="nextTab"
            >
              ถัดไป
              <v-icon class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn
              v-if="tab === 2"
              class="mx-3 color-btnCard"
              dark
              type="button"
              @click="save"
            >
              <v-icon>mdi-check-circle-outline</v-icon> ยืนยันลงทะเบียน
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  usernameRules,
  passwordRules,
  confirmPasswordRules,
  emailRules,
  isUserNameExisting,
  testIsUsernameExisting,
  security_numberRules,
  phoneRules,
  profiletypecodeRules,
} from "@/ValidationHelper.js";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { RegisterEmployerRequest } from "@/models/RegisterEmployer/RegisterEmployerRequest";
import { RegisterEmployeeProfileDataProvider } from "@/services/RegisterEmployeeProfileDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import UsernameTextField from "@/components/common/UsernameTextField.vue";
import EmailTextField from "@/components/common/EmailTextField.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
import { EmployerExternalApiDataProvider } from "@/services/EmployerExternalApiDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

const registerEmployerProfileDataProvider =
  new RegisterEmployeeProfileDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerExternalApiDataProvider = new EmployerExternalApiDataProvider();

export default {
  components: { UsernameTextField, EmailTextField },
  name: "RegisterEmployer",
  data() {
    return {
      vm: new RegisterEmployerRequest(),
      provinces: undefined,
      districts: undefined,
      tambons: undefined,
      postcode: undefined,
      employerProfileTypes: undefined,
      selected_employerProfileType_Code: undefined,
      employerMainBusinessTypes: undefined,
      employerBusinessTypes: [],
      titlename: undefined,

      CreateEmployerData: new FormData(),
      previewPhoto: "",
      previewLogo: "",
      previewMap: "",
      documentFiles: [],
      logoFiles: [],
      mapFiles: [],
      valid: true,
      usernameRules: usernameRules,
      usernameExistResult: undefined,
      passwordRules: passwordRules,
      emailRules: emailRules,
      security_numberRules: security_numberRules,
      phoneRules: phoneRules,
      email: "",
      emailExistResult: false,
      isShowPassword: false,
      isComfirmPasswordShowPasword: false,
      isLoading: false,
      identityidExistResult: false,
      // idRules: [
      //   (v) => !!v || "กรุณาระบุเลขประจำตัวประชาชน/เลขทะเบียนการค้า/เลขผู้เสียภาษี",
      //   (v) => this.checkID(v) || "รูปแบบหมายเลขไม่ถูกต้อง",
      //   (v) => this.identityidExistResult !== true || "หมายเลขที่กรอกไม่ถูกต้อง",
      // ],
      profiletypecodeRules: profiletypecodeRules,
      tab: 0,
      tabIndicatorHeight: 5,
    };
  },
  watch: {
    "vm.username": {
      async handler(newVal, oldVal) {
        if (newVal !== "") {
          const result = await applicationUserDataProvider.isUsernameUsed(
            newVal
          );
          this.usernameExistResult = result.data;
          if (this.$refs.txtUsername) {
            this.$refs.txtUsername.validate();
          }
        }
      },
    },
    // "vm.username": {
    //   async handler(newVal, oldVal) {
    //     if (newVal != "") {
    //       await applicationUserDataProvider.isUsernameUsed(newVal).then((result) => {
    //         this.usernameExistResult = result.data;
    //         this.$refs.txtUsername.validate();
    //       });
    //     }
    //   },
    //   deep: true,
    // },
    "vm.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isEmailUsed(newVal)
            .then((result) => {
              console.log(result.data);
              this.emailExistResult = result.data;
              this.$refs.txtEmail.validate();
            });
        }
      },
      deep: true,
    },
    "vm.identityid": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isIdentityIDUsed(newVal)
            .then((result) => {
              console.log(result.data);
              this.identityidExistResult = result.data;
              this.$refs.txtIdentityid.validate();
            });
        }
      },
      deep: true,
    },
    async tab(newTab, oldTab) {
      let isValid = false;
      if (newTab === 0) {
        console.log(newTab);
      } else if (newTab === 1) {
        if (oldTab === 0) {
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        }
      } else {
        if (oldTab === 0) {
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        } else if (oldTab === 1) {
          isValid = await this.$refs.contactForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error(
              "กรุณากรอกข้อมูลผู้ติดต่อ/ผู้ประสานงานให้ครบถ้วน"
            );
            this.tab = oldTab;
          }
        } else if (oldTab === 2) {
          isValid = await this.$refs.accountForm.validate();
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลบัญชีผู้ใช้งานให้ครบถ้วน");
            this.tab = oldTab;
          }
        }
      }
    },
  },
  computed: {
    identityid_caption() {
      switch (this.vm.selected_employerprofiletypecode) {
        case "Organization":
          return "ทะเบียนการค้าเลขที่/ทะเบียน ภพ.20";
        case "Personal":
          return "เลขบัตรประชาชน";
        case "Government":
          return "เลขที่ผู้เสียภาษี";
        default:
          return "";
      }
    },
    custom_usernameRules() {
      return [
        ...usernameRules,
        (v) =>
          this.usernameExistResult === false ||
          "ชื่อผู้ใช้งาน (username) ถูกใช้งานแล้ว",
      ];
    },
    custom_emailRules() {
      return [
        ...emailRules,
        (v) => this.emailExistResult === false || "อีเมลนี้ถูกใช้งานแล้ว",
      ];
    },
    confirmPasswordRules() {
      return [
        ...confirmPasswordRules,
        (v) => v === this.vm.password || "รหัสผ่านไม่ตรงกัน",
      ];
    },

    custom_profiletypecodeRules() {
      return [
        ...profiletypecodeRules,
        (v) =>
          this.identityidExistResult !== true || "หมายเลขที่กรอกไม่ถูกต้อง",
      ];
    },
  },
  methods: {
    async initialize() {
      await masterDataProvider.loadEmployerProfileTypes().then((result) => {
        this.employerProfileTypes = result.data;
        //Set default value.
        this.vm.selected_employerprofiletypecode = "Organization";
        this.vm.selected_employermainbusinesstypecode = "ไม่ระบุ";
      });
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
      await masterDataProvider
        .loadEmployerMainBusinessTypes()
        .then((result) => {
          this.employerMainBusinessTypes = result.data;
        });
      await masterDataProvider.getActiveTitleNames().then((result) => {
        this.titlename = result.data;
      });
    },
    async loadDisticts() {
      console.log(this.vm.selected_province_id);
      if (this.vm.selected_province_id != undefined) {
        await masterDataProvider
          .loadGetDistricts(this.vm.selected_province_id)
          .then((result) => {
            this.districts = result.data;
          });
      }
    },
    async loadTambons() {
      if (this.vm.selected_district_id != undefined) {
        await masterDataProvider
          .loadGetTambons(this.vm.selected_district_id)
          .then((result) => {
            this.tambons = result.data;
          });
      }
    },
    async onTambonSelected() {
      if (this.vm.selected_tambon_id != "") {
        const vm_selected_tambon_id = this.vm.selected_tambon_id;
        const selected_tambon = this.tambons.find(
          (x) => x.tambonid == vm_selected_tambon_id
        );
        this.postcode = selected_tambon.postcodemain;
      }
    },
    async loadEmployerBusinessTypes() {
      if (this.vm.selected_employermainbusinesstypecode) {
        await masterDataProvider
          .loadEmployerBusinessTypes(
            this.vm.selected_employermainbusinesstypecode
          )
          .then((result) => {
            this.employerBusinessTypes = result.data;
          });
      }
    },
    async onFileChange() {
      this.CreateEmployerData.append("filedoc", this.documentFiles);
    },

    async onPhotoChange() {
      this.CreateEmployerData.append("filemap", this.mapFiles);
      var fileToLoad = this.mapFiles;
      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        const base64 = data.split(",")[1];
        this.previewPhoto = base64;
      });
    },
    async clearPhoto() {
      this.previewPhoto = "";
    },

    async onLogoChange() {
      // this.CreateEmployerData.append("filelogo", this.logoFiles)
      var fileToLoad = this.logoFiles;
      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        const base64 = data.split(",")[1];
        this.previewLogo = base64;
      });
    },
    async clearLogo() {
      this.previewLogo = "";
    },

    async onMapChange() {
      // this.CreateEmployerData.append("filemap", this.mapFiles)
      var fileToLoad = this.mapFiles;
      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        const base64 = data.split(",")[1];
        this.previewMap = base64;
      });
    },
    async clearMap() {
      this.previewMap = "";
    },
    mapper() {
      this.CreateEmployerData = this.vm.generateFormData();
      this.CreateEmployerData.append("filedoc", this.documentFiles);
      this.CreateEmployerData.append("filemap", this.mapFiles);
      this.CreateEmployerData.append("filelogo", this.logoFiles);
    },
    async save() {
      const isGeneralFormValid = this.$refs.generalForm.validate();
      const isContactFormValid = this.$refs.contactForm.validate();
      const isAccountFormValid = this.$refs.accountForm.validate();

      if (isGeneralFormValid && isContactFormValid && isAccountFormValid) {
        if (confirm("ยืนยัน")) {
          this.mapper();
          await registerEmployerProfileDataProvider
            .register(this.CreateEmployerData)
            .then((result) => {
              this.$toast.success("ลงทะเบียนสถานประกอบการเรียบร้อย");
              let oid = result.data;

              this.$router.push({
                name: "RedirectCountdown",
                params: { oid },
              });
            });
        }
      }
    },
    checkID(id) {
      if (this.vm.selected_employerprofiletypecode != "Personal") return true;
      if (id.length !== 13) return false;
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        sum += parseFloat(id.charAt(i)) * (13 - i);
      }
      const test = (11 - (sum % 11)) % 10 === parseFloat(id.charAt(12));
      return test;
    },
    async assignDataFromExtenalAPI(fetchedData) {
      console.log(fetchedData);

      this.vm.nameth =
        fetchedData["data"][0]["cdOrganizationJuristicPerson"][
          "cdOrganizationJuristicNameTH"
        ];
      this.vm.address_no =
        (fetchedData["data"][0]["cdOrganizationJuristicPerson"][
          "cdOrganizationJuristicAddress"
        ]["crAddressType"]["cdAddressNo"] || "-") +
        " หมู่ที่ " +
        (fetchedData["data"][0]["cdOrganizationJuristicPerson"][
          "cdOrganizationJuristicAddress"
        ]["crAddressType"]["cdMoo"] || "-") +
        " ถนน " +
        (fetchedData["data"][0]["cdOrganizationJuristicPerson"][
          "cdOrganizationJuristicAddress"
        ]["crAddressType"]["cdRoad"] || "-") +
        " ซอย " +
        (fetchedData["data"][0]["cdOrganizationJuristicPerson"][
          "cdOrganizationJuristicAddress"
        ]["crAddressType"]["cdSoi"] || "-");

      const findProvince =
        fetchedData["data"][0]["cdOrganizationJuristicPerson"][
          "cdOrganizationJuristicAddress"
        ]["crAddressType"]["cdCountrySubDivision"][
          "crCountrySubDivisionTextTH"
        ];
      if (findProvince) {
        const resultProvince = this.provinces.find(
          (x) => x.provincethai == findProvince
        );
        this.vm.selected_province_id = resultProvince.provinceid;
      }

      if (this.vm.selected_province_id) {
        await this.loadDisticts();

        const findDistrict =
          fetchedData["data"][0]["cdOrganizationJuristicPerson"][
            "cdOrganizationJuristicAddress"
          ]["crAddressType"]["cdCity"]["crCityTextTH"];
        if (findDistrict) {
          const resultDistrict = this.districts.find(
            (x) => x.districtthai == findDistrict || x.districtthaishort == findDistrict
          );
          if (resultDistrict ) {
            this.vm.selected_district_id = resultDistrict.districtid;
          }
        }
      }

      if (this.vm.selected_district_id) {
        await this.loadTambons();

        const findTambon =
          fetchedData["data"][0]["cdOrganizationJuristicPerson"][
            "cdOrganizationJuristicAddress"
          ]["crAddressType"]["crCitySubDivision"]["crCitySubDivisionTextTH"];
        if (findTambon) {
          const resultTambon = this.tambons.find(
            (x) => x.tambonthai == findTambon || x.tambonthaishort == findTambon
          );
          if (resultTambon) {
            this.vm.selected_tambon_id = resultTambon.tambonid;
          }
        }
      }

      if (this.vm.selected_tambon_id) {
        this.onTambonSelected();
      }

      // this.vm.selected_tambon_id =
      //   fetchedData["data"][0]["cdOrganizationJuristicPerson"][
      //     "cdOrganizationJuristicAddress"
      //   ]["crAddressType"]["cdCitySubDivision"]["crCitySubDivisionTextTH"];

      // this.districts =
      //   fetchedData["data"][0]["cdOrganizationJuristicPerson"][
      //     "cdOrganizationJuristicAddress"
      //   ]["crAddressType"]["cdCity"]["crCityTextTH"];
      // this.tambons =
      //   fetchedData["data"][0]["cdOrganizationJuristicPerson"][
      //     "cdOrganizationJuristicAddress"
      //   ]["crAddressType"]["cdCitySubDivision"]["crCitySubDivisionTextTH"];
      // this.postcode =
      //   fetchedData["data"][0]["cdOrganizationJuristicPerson"][
      //     "cdOrganizationJuristicAddress"
      //   ]["crAddressType"]["cdCity"]["crCityCode"];
    },
    prevTab() {
      if (this.tab > 0) {
        this.tab--;
      }
    },
    nextTab() {
      if (this.tab < 2) {
        let isValid = false;

        if (this.tab === 0) {
          isValid = this.$refs.generalForm.validate();
        } else if (this.tab === 1) {
          isValid = this.$refs.contactForm.validate();
        } else if (this.tab === 2) {
          isValid = this.$refs.accountForm.validate();
        }

        if (isValid) {
          this.tab++;
        }
      }
    },

    async fetchedData() {
      if (this.vm.identityid != "") {
        const result =
          await employerProfileDataProvider.getPublicEmployerInformationByJuristicID(
            this.vm.identityid
          );

        if (result.status == 200) {
          this.assignDataFromExtenalAPI(result.data);
        }

        // employerExternalApiDataProvider
        //   .loadEmployerData(this.vm.identityid)
        //   .then((result) => {
        //     this.assignDataFromExtenalAPI(result.data);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     this.$toast.error("ไม่พบข้อมูลในฐานข้อมูล DBD");
        //   });
      } else {
        this.$refs.txtIdentityid.validate();
        this.$toast.error("กรุณากรอกข้อมูลทะเบียนการค้าเลขที่ / ทะเบียน ภพ.20");
      }
    },

    fetchTestData() {
      if (this.vm.identityid != "") {
        try {
          const result = employerExternalApiDataProvider.getTestEmployerData();
          console.log(result);
          this.assignDataFromExtenalAPI(result);
        } catch (err) {
          console.error("Error fetching data:", err);
          this.$toast.error("ไม่พบข้อมูลในระบบ");
        }
      } else {
        this.$refs.txtIdentityid.validate();
        this.$toast.error("กรุณากรอกข้อมูลทะเบียนการค้าเลขที่ / ทะเบียน ภพ.20");
      }
    },
  },

  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
.color-btnCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
.inline-block {
  display: inline-block;
}
.red-text {
  color: red;
  margin-left: 5px;
}
.custom-tab {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}
.full-width-tab {
  flex-grow: 1;
}

.v-tabs-slider {
  border-radius: 10px;
  bottom: -40px;
}
</style>
