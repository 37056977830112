<template>
  <div class="d-flex justify-center">
    <v-container class="mt-4">
      <v-form>
        <v-row
          class="d-flex justify-center pt-5"
          v-if="job && job.employerprofile"
        >
          <v-col cols="11">
            <v-card
              class="pt-6 pb-6 pl-12 pr-12 mb-12"
              style="border-radius: 10px"
            >
              <v-card-text id="element-to-convert">
                <!-- <v-row>
                  <v-col
                    class="pa-0"
                    cols="12"
                    style="display: flex; justify-content: end"
                  >
                    <div class="display-center">
                      ลงประกาศ ณ วันที่
                      <label style="margin-left: 4px">
                        <FormatDateView
                          :date_value="job.startdate"
                        ></FormatDateView>
                      </label>
                    </div>
                  </v-col>
                </v-row> -->
                <v-row justify-lg="space-between" justify-md="start">
                  <v-col class="pa-0 display-center" cols="12" lg="6" md="12">
                    <h2 style="align-self: center">
                      {{ job.positionname }}
                    </h2>

                    <v-chip
                      v-if="job.isargent"
                      color="#B00020"
                      style="color: white"
                      class="ml-4"
                    >
                      รับสมัครด่วน
                    </v-chip>
                  </v-col>
                  <v-col cols="12" lg="6" md="12" class="px-0">
                    <div class="display-center justify-lg-end">
                      ลงประกาศ ณ วันที่
                      <label style="margin-left: 4px">
                        <FormatDateView
                          :date_value="job.startdate"
                        ></FormatDateView>
                      </label>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto">
                    <div class="rounded-circle display-center">
                      <v-img
                        :src="'data:image/jpg;base64,' + logo_photobase64"
                        aspect-ratio="1"
                        contain
                      ></v-img>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                      "
                    >
                      <v-row style="padding: 8px">
                        <div
                          class="mr-3"
                          style="color: #3f51b5; font-size: 18px"
                        >
                          {{ job.employerprofile.nameth }}
                        </div>
                        <DBDStatusDisplay
                          v-if="job.employerprofile"
                          :employer-profile="job.employerprofile"
                        ></DBDStatusDisplay>
                        <div class="display-center" style="font-style: italic">
                          <v-icon>mdi-map-marker</v-icon>
                          <label>{{
                            job.employerprofile.district.province.provincethai
                          }}</label>
                        </div>
                      </v-row>
                      <v-row>
                        <v-chip color="#3F51B533">
                          {{ job.employmentcategory.name }}
                        </v-chip>
                      </v-row>
                      <v-row class="d-flex pa-0 no-print">
                        <v-btn
                          small
                          outlined
                          rounded-lg
                          width="100%"
                          @click="openEmployerProfileInfo"
                          class="my-2 equal-height-btn display-center"
                        >
                          <v-icon class="mr-1">mdi-account-search</v-icon>
                          โปรไฟล์บริษัท
                        </v-btn>
                      </v-row>
                      <!-- <v-row class="">
                        <v-btn
                          small
                          outlined
                          rounded
                          color="primary"
                          @click="openEmployerProfileInfo"
                          class="ma-2"
                        >
                          <v-icon class="mr-1">mdi-account-search</v-icon>
                          ดูโปรไฟล์บริษัท
                        </v-btn>
                      </v-row> -->
                    </div>
                  </v-col>
                </v-row>
                <!-- <v-row class="pt-5">
                  <v-chip color="#3F51B533">
                    {{ job.employmentcategory.name }}
                  </v-chip>
                </v-row> -->

                <v-row>
                  <v-col>
                    <v-row class="text1-content">
                      <v-col cols="12" class="pa-2 pt-5 pl-6">
                        <v-row>
                          <label class="unit" style="margin-right: 4px"
                            >อัตราที่รับสมัคร :
                          </label>
                          <label>{{ job.unit }}</label>
                        </v-row>
                        <v-row>
                          <label class="unit" style="margin-right: 4px"
                            >ประเภทงาน :
                          </label>
                          <label
                            v-if="this.job.jobsegmentname_todisplay !== ''"
                          >
                            {{ job.jobsegmentname_todisplay }}</label
                          >
                          <label v-if="this.job.jobsegmentname_todisplay == ''"
                            >-</label
                          >
                        </v-row>
                        <v-row>
                          <label class="unit" style="margin-right: 4px"
                            >วิธีการรับสมัคร :
                          </label>
                          <label
                            v-if="
                              this.job.employerprofile
                                .isrequiredjobapplicanttoregisterthemselforviapost ==
                              true
                            "
                            >สมัครด้วยตนเอง/ส่งใบสมัครทางไปรษณีย์
                          </label>
                          <label
                            v-if="
                              this.job.employerprofile
                                .isrequiredjobapplicanttoregisterthemselforviapost ==
                                true &&
                              this.job.employerprofile
                                .isopenforonlineapplicant == true
                            "
                          >
                            /
                          </label>
                          <label
                            v-if="
                              this.job.employerprofile
                                .isopenforonlineapplicant == true
                            "
                          >
                            เปิดรับสมัคร online
                          </label>
                          <label
                            v-if="
                              this.job.employerprofile
                                .isrequiredjobapplicanttoregisterthemselforviapost ==
                                false &&
                              this.job.employerprofile
                                .isopenforonlineapplicant == false
                            "
                            >-</label
                          >
                        </v-row>
                        <v-row>
                          <label class="unit" style="margin-right: 4px"
                            >เงินเดือน :
                          </label>
                          <label> {{ job.wagetodisplay }} </label>
                        </v-row>
                        <v-row>
                          <label class="unit" style="margin-right: 4px"
                            >ระดับการศึกษาขั้นต่ำ :
                          </label>
                          <label> {{ job.requirecertificatemessage }}</label>
                        </v-row>
                        <v-row style="color: #b00020">
                          <label class="unit" style="margin-right: 4px">
                            ปิดรับสมัครวันที่ :
                          </label>
                          <label>
                            <FormatDateView
                              :date_value="job.enddate"
                            ></FormatDateView>
                          </label>
                        </v-row>
                        <v-row>
                          <v-col class="d-flex justify-end pa-0 no-print">
                            <!-- <v-btn
                              :disabled="isRegisted"
                              @click="confirm_dialog = true"
                              class="color-btnCard mr-2 mt-2 equal-height-btn display-center"
                              dark
                            >
                              <v-icon class="mr-2">mdi-email</v-icon>
                              สมัครงาน
                            </v-btn> -->
                            <v-btn
                              fab
                              outlined
                              tile
                              v-if="checkBtnCondition()"
                              class="equal-height-btn display-center"
                              style="border-radius: 10px"
                              ><BtnFavoriteJobPosting
                                :JobPosting="job"
                              ></BtnFavoriteJobPosting>
                            </v-btn>
                            <v-btn
                              class="ml-2 equal-height-btn display-center"
                              fab
                              outlined
                              tile
                              style="border-radius: 10px"
                              ><v-icon @click="print" color="primary"
                                >mdi-printer</v-icon
                              ></v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row style="margin-top: 5%; margin-bottom: 3%">
                  <v-divider></v-divider>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row>
                      <h4 style="color: #3f51b5">รายละเอียดงาน (ลักษณะงาน)</h4>
                    </v-row>
                    <v-row style="margin-top: 0%">
                      <span class="ql-editor">{{ job.jobdescription }} </span
                      ><br />
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row>
                      <h4 style="color: #3f51b5">คุณสมบัติผู้สมัครงาน</h4>
                    </v-row>
                    <v-row style="margin-top: 0%">
                      <span class="ql-editor"
                        >{{ job.applicant_specification }} </span
                      ><br />
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row>
                      <h4 style="color: #3f51b5">สวัสดิการ</h4>
                    </v-row>
                    <v-row style="margin-top: 0%">
                      <span class="ql-editor"
                        >{{ job.employerprofile.benefitdescription }} </span
                      ><br />
                    </v-row>
                  </v-col>
                </v-row>
                <v-row style="margin-top: 3%; margin-bottom: 3%">
                  <v-divider></v-divider>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row>
                      <h4 style="color: #3f51b5">ติดต่อ</h4>
                    </v-row>
                    <v-row style="margin-bottom: 0%">
                      <v-col>
                        <div>{{ job.employerprofile.nameth }}</div>
                        <div>{{ job.coordinator_phoneno }}</div>
                        <div>{{ job.coordinator_email }}</div>
                        <div
                          v-if="
                            job.employerprofile.district.province
                              .provincethai !== ''
                          "
                        >
                          {{ job.employerprofile.tambon.tambonthai }},
                          {{ job.employerprofile.district.districtthai }},
                          {{
                            job.employerprofile.district.province.provincethai
                          }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex pa-0 no-print">
                    <v-btn @click="$router.go(-1)" class="mr-2">
                      ย้อนกลับ
                    </v-btn>

                    <v-btn
                      :disabled="isRegisted"
                      @click="confirm_dialog = true"
                      class="color-button2 mr-2"
                      dark
                      v-if="checkBtnCondition()"
                    >
                      <!-- <v-icon class="mr-2">mdi-email</v-icon> -->
                      สมัครงาน
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <!-- <v-row class="justify-center text-center mt-4">
          <v-col cols="12">
            <BtnFavoriteJobPosting :JobPosting="job"></BtnFavoriteJobPosting
            ><span class="">บันทึกเข้าแฟ้ม</span>

            <v-btn icon dark color="primary" @click="print" class="ma-2 ml-10">
              <v-icon class="mr-2">mdi-printer</v-icon>print
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="justify-center text-center mb-4">
          <v-col cols="12">
            <v-btn
              :disabled="isRegisted"
              color="primary"
              @click="confirm_dialog = true"
              class="ma-2"
            >
              <v-icon class="mr-2">mdi-email</v-icon>
              สมัครงาน
            </v-btn>
            <v-btn color="" @click="$router.go(-1)" class="ma-2"> ย้อนกลับ</v-btn>
          </v-col>
        </v-row> -->
          </v-col>
        </v-row>
        <v-dialog v-model="confirm_dialog" width="400">
          <template>
            <v-card class="pa-6">
              <v-row class="justify-center text-center">
                <v-card-title> ยืนยันการสมัครงาน ? </v-card-title>
                <v-col cols="12">
                  <v-btn
                    @click="confirm_dialog = false"
                    class="ma-2 back-button"
                    elevation="2"
                    >ยกเลิก</v-btn
                  >
                  <v-btn
                    @click="onRegisterJobPostingConfirmed()"
                    class="ma-2 color-button2"
                  >
                    ยืนยัน
                    <v-icon class="ml-2">mdi-check-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-dialog>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { JobPostingSearcherDataProvider } from "@/services/JobPostingSearcherDataProvider";
const jobPostingSearcherDataProvider = new JobPostingSearcherDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import BtnFavoriteJobPosting from "@/components/JobPostings/BtnFavoriteJobPosting.vue";
import html2pdf from "html2pdf.js";
import { StudentJobPostingDataProvider } from "@/services/StudentJobPostingDataProvider";
import FormatDate from "@/components/common/FormatDate.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
import FormatDateView from "@/components/common/FormatDateView.vue";
import { ApplicationRoleCodes } from "@/Constants";
import DBDStatusDisplay from "@/components/Employer/DBDStatusDisplay.vue";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

const studentJobPostingDataProvider = new StudentJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "JobPostingInfo",
  components: { BtnFavoriteJobPosting, FormatDateView, DBDStatusDisplay },
  data() {
    return {
      current_applicationuser_rolecodes: undefined,
      oid: "",
      job: "",
      isFavorite: false,
      isRegisted: false,
      position: "ช่างยนต์ ออโตคลิก แยกสี่กอ กะทู้",
      employer: "บริษัท ออโตคอร์ป โฮลดิ้ง จำกัด (มหาชน)",
      province: "ภูเก็ต",
      recruitmentrate: "3",
      typejob: "งานประจำ (Full Time)",
      Howtoapply: "ติดต่อสถานประกอบการ",
      salary: "12,000++",
      gender: "ชาย",
      age: "ไม่เกิน 30 ปี",
      level: "ปวช. ขึ้นไป สาขา ช่างยนต์, เทคนิคยานยนต์",
      experience: "มีประสบการณ์ฝึกงาน หรือ ทำงาน ซ่อมรถยนต์ จะพิจารณาเป็นพิเศษ",
      etc: "มีใบอนุญาตขับขี่รถยนต์",
      tel: "043306333",
      email: "hr-team@ach.co.th",
      phone: "0620080797",
      address: "",
      link: "https://www.autocorpgroup.com/en",
      detailsjob:
        "ให้บริการซ่อมรถยนต์ของลูกค้าที่เข้ามารับบริการ ตามใบสั่งซ่อม",
      benefit1: "- ประกันสังคม",
      benefit2: "- เบี้ยขยัน",
      benefit3: "- คอมมิชชั่น",
      benefit4: "- เครื่องแบบพนักงาน",
      benefit5: "- กองทุนสำรองเลี้ยงชีพ",
      benefit6: "- ตรวจสุขภาพประจำปี",
      benefit7: "- เงินยินดีมงคลสมรส",
      benefit8: "- เงินอนุโมทนาอุปสมบท",
      benefit9: "- เงินช่วยเหลือฌาปนกิจ",
      benefit10: "- ประกันอุบัติเหตุ",
      benefit11: "- ประกันสุขภาพ",
      benefit12: "- เบี้ยเลี้ยงเดินทางปฏิบัติงาน",
      benefit13: "- ส่วนลดสำหรับพนักงาน",
      confirm_dialog: false,
      logo_photobase64: "",
      roles: [],
      showFavBtn: true,
      applyBtn: true,
    };
  },
  computed: {
    display_favorite_btn() {
      if (this.current_applicationuser_rolecodes == undefined) return false;
      return this.current_applicationuser_rolecodes.includes("Employer");
    },
  },
  methods: {
    async initialize() {
      this.current_applicationuser_rolecodes = this.$store.getters.rolecodes;
    },
    async loadJobPosting() {
      try {
        const result = await jobPostingSearcherDataProvider.getJobPosting(
          this.oid
        );
        this.job = result.data;
        this.logo_photobase64 = result.data.employerprofile.logo_photobase64;
      } catch (error) {
        console.log("ไม่สามารถโหลดข้อมูลได้");
      }
    },

    async checkIsRegistered() {
      try {
        const result = await studentJobPostingDataProvider.isRegisted(this.oid);
        this.isRegisted = result.data;
      } catch (error) {
        console.log("ไม่สามารถตรวจสอบสถานะการสมัครได้");
      }
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
    },
    print() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: "JobDetails.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        enableLinks: true,
        pagebreak: { mode: ["css", "legacy"] },
      });
    },
    openEmployerProfileInfo: function () {
      const joboid = this.oid;
      this.$router
        .push({ name: "EmployerProfileInfo", params: { joboid } })
        .catch((err) => {});
    },
    async onRegisterJobPostingConfirmed() {
      const jobposting_oid = this.oid;
      await studentJobPostingDataProvider
        .createJobApplication(jobposting_oid)
        .then((result) => {
          this.$toast.success("ยื่นใบสมัครเรียบร้อย");
          this.loadJobPosting();
          this.checkIsRegistered();
          this.confirm_dialog = false;
          this.$router.push("/Student/StudentTrackCurrentJobApplicationsView");
        });
    },
    checkBtnCondition() {
      if (this.roles.includes(ApplicationRoleCodes.Student)) {
        return (this.showFavBtn = true);
      } else {
        return (this.showFavBtn = false);
      }
    },
  },
  async created() {
    this.roles = this.$store.getters.rolecodes;
    this.oid = this.$route.params.oid;
    await this.loadJobPosting();
    await this.checkIsRegistered();
  },
};
</script>

<style scoped>
.rounded-circle {
  border-radius: 50%;
  overflow: hidden;
  width: 10vw;
  height: 10vw;
  max-width: 60px;
  max-height: 60px;
}
.color-btnCard {
  background: linear-gradient(
    90deg,
    rgb(127, 202, 246) 20%,
    rgb(46, 126, 220) 60%,
    rgb(62, 83, 183) 100%
  );
}
.ql-editor {
  margin-top: 8px;
}
.no-print {
  display: none;
}
@media print {
  .no-print {
    display: none !important;
  }
  .v-card-text {
    font-size: 12pt;
  }
  .v-row,
  .v-col {
    page-break-inside: avoid;
  }
}
.equal-height-btn {
  display: flex;
  align-items: center;
  height: 50px;
}
.display-center {
  display: flex;
  align-items: center;
}
</style>
