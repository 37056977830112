import axios from 'axios';
import { create_default_request_header } from '@/services/constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
export class AccountProfileDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    loadCurrentAccountProfile() {
        const header = create_default_request_header();
        const endpointUrl = "/api/AccountProfile/GetCurrentAccountProfile";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header);
    }

    loadAccountProfile(username) {
        try {
            var header = create_default_request_header();
            var endpointUrl = "/api/AccountProfile/GetAccountProfile/" + username;
            var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
            return axios.get(combinedURL, header);
        }
        catch (error) {

            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    store.dispatch({ type: "doLogout" });
                }
                //  error: AxiosError<any, any>
                return error.message;
            } else {
                return 'An unexpected error occurred';
            }
        }
    }
    updateAccountProfile(account) {
        try {
            var header = create_default_request_header();
            var endpointUrl = "/api/AccountProfile/UpdateAccountProfile/";
            var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
            return axios.post(combinedURL, {
                "oid": account.oid,
                "username": account.username,
                "password": account.password,
            }, header);
        }
        catch (error) {

            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    store.dispatch({ type: "doLogout" });
                }
                //  error: AxiosError<any, any>
                return error.message;
            } else {
                return 'An unexpected error occurred';
            }
        }
    }
    getPermissionList() {
    var header = create_default_request_header();
    var endpointUrl = "/api/AccountProfile/GetPermissionList";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header);
    }
}