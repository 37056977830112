<template>
  <v-tooltip top :color="percentageColor">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="openStudentDetailView(item)"
        color="info"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <span
          v-if="student_veceducationinformation.student_isprofileupdated == false"
          style="color: rgb(158, 158, 158); font-weight: bold"
          >{{
            Math.ceil(student_veceducationinformation.student_completionpercentage)
          }}%</span
        >
        <span v-else style="color: rgb(23, 202, 16); font-weight: bold"
          >{{
            Math.ceil(student_veceducationinformation.student_completionpercentage)
          }}%</span
        >
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
import { formatDateThai } from "@/DateTimeHelper";

export default {
  props: {
    completionPercentage: Number,
    student_veceducationinformation: undefined,
  },
  computed: {
    percentageColor() {
      if (this.student_veceducationinformation.student_isprofileupdated) {
        return "green";
      } else {
        return "grey";
      }
    },
    tooltipText() {
      if (
        this.student_veceducationinformation.student_profileupdateddate ===
        "0001-01-01T00:00:00"
      ) {
        return "ยังไม่มีการอัปเดตโปรไฟล์";
      } else if (this.student_veceducationinformation.student_profileupdateddate) {
        return (
          "อัปเดตโปรไฟล์ล่าสุดวันที่ : " +
          this.convertDateFormat(
            this.student_veceducationinformation.student_profileupdateddate
          )
        );
      } else {
        return "ยังไม่มีการอัปเดตโปรไฟล์";
      }
    },
  },
  methods: {
    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },
  },
};
</script>

<style>
.completion {
  display: flex;
  flex-direction: column;
}

.percentage {
  font-size: 18px; /* Smaller font size */
  font-weight: normal;
  cursor: help; /* Use a help cursor to indicate a tooltip */
}

.label {
  font-size: 14px;
  color: #888;
}
</style>
