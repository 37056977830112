<template>
  <div class="d-flex justify-center">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="10" md="12" sm="12" xs="12">
          <!-- <v-row class="d-flex justify-end">
            <v-btn class="mx-3 color-buton" dark @click="checkEmployerProfile"
              >ตรวจสอบข้อมูล
              <v-img
                alt="VEC Logo"
                class="shrink ml-2"
                contain
                src="@/assets/DBDlogo.png"
                transition="scale-transition"
                width="40"
            /></v-btn>
          </v-row> -->
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="onSubmit"
          >
            <v-card class="mt-5">
              <v-toolbar class="color-HeaderCard" height="50px" flat dark>
                <v-toolbar-title
                  ><v-icon class="mr-2"> mdi-account-circle </v-icon
                  >แก้ไขข้อมูลสถานประกอบการ</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text class="d-flex justify-center">
                <v-col cols="10" class="mt-0">
                  <v-row class="d-flex align-center justify-space-between">
                    <v-col cols="12" lg="11" md="11" sm="11" xs="11">
                      <h4 class="primary--text mb-2">เลขประจำตัวผู้เสียภาษี</h4>

                      <v-text-field
                        label="เลขประจำตัวผู้เสียภาษี"
                        v-model="vm.identityid"
                        ref="txtIdentityid"
                        :readonly="true"
                        :rules="custom_identityidRules"
                        @input="checkIdentityidEdited"
                        :disabled="vm.dontknowidentityid"
                        background-color="input"
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="1"
                      md="1"
                      sm="1"
                      xs="1"
                      class="d-flex justify-end"
                    >
                      <v-tooltip top color="success" v-if="employerData">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon style="font-size: 30px"
                              >mdi-information-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>ได้รับการตรวจสอบจากกรมพัฒนาธุรกิจการค้า</span>
                      </v-tooltip>
                      <v-tooltip top color="error" v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon style="font-size: 30px"
                              >mdi-information-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span
                          >ยังไม่ได้รับการตรวจสอบจากกรมพัฒนาธุรกิจการค้า</span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="12" class="my-0 pt-0">
                      <v-checkbox
                        label="ไม่มีเลขทะเบียนนิติบุคคล/เลขผู้เสียภาษี"
                        v-model="vm.dontknowidentityid"
                        @change="ClearIdentityID"
                      ></v-checkbox>
                      <div style="padding-left: 33px">
                        <label class="v-label"
                          >*ตัวเลือกนี้ควรเลือกใช้เฉพาะในกรณีที่ไม่สามารถระบุ
                          "เลขทะเบียนนิติบุคคล/เลขผู้เสียภาษี" ได้จริง ๆ
                          เท่านั้น</label
                        >
                      </div></v-col
                    >
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="12" class="my-0 py-0">
                      <h4 class="primary--text mb-2">ชื่อสถานประกอบการ</h4>

                      <v-text-field
                        label="ชื่อสถานประกอบการ"
                        v-model="vm.nameth"
                        :rules="[(v) => !!v || 'กรุณากรอกชื่อสถานประกอบการ']"
                        background-color="input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12" class="my-0 py-0">
                      <h4 class="primary--text mb-2">ที่อยู่</h4>
                      <v-text-field
                        label="ที่อยู่"
                        v-model="vm.address"
                        :rules="[(v) => !!v || 'กรุณากรอกชื่อสถานประกอบการ']"
                        background-color="input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12" class="my-0 py-0">
                      <h4 class="primary--text mb-2">จังหวัด</h4>
                      <v-autocomplete
                        label="จังหวัด"
                        :items="provinces"
                        item-text="provincethai"
                        item-value="provinceid"
                        v-model="vm.selected_province_id"
                        background-color="input"
                        filled
                        append-icon="mdi-chevron-down"
                      ></v-autocomplete
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-btn @click="$router.go(-1)" color="" class="mx-3">
                        <span class="mx-3">ยกเลิก</span>
                      </v-btn>
                      <v-btn
                        @click="onSubmit"
                        color="secondary"
                        class="mx-3 color-button2"
                      >
                        บันทึก<v-icon class="ml-2"
                          >mdi-content-save</v-icon
                        ></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>

            <!-- โค้ดเก่า -->
            <!-- <div class="d-sm-none">
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-if="
                    dbdStatus === false && dbdLastverifydate !== '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) :
                  {{
                    moment(this.vm.dbd_lastverifydate)
                      .add(543, "year")
                      .locale("TH")
                      .format("lll")
                  }}
                  น.
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="
                    dbdStatus === false && dbdLastverifydate === '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) : -
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="
                    dbdStatus === true && dbdLastverifydate !== '0001-01-01T00:00:00'
                  "
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) :
                  {{
                    moment(dbdLastverifydate).add(543, "year").locale("TH").format("lll")
                  }}
                </p>
                <p
                  class="mb-2 mb-0 ml-2 primary--text px-2"
                  v-else-if="dbdStatus === true"
                >
                  อัปเดตวันที่ตรวจสอบ(DBD) :
                  {{ savedDate }}
                </p>
              </div> -->
            <!-- <v-card class="elevation-1 pa-3 mt-5">
              <v-row>
                <v-col cols="12" class="d-flex align-center justify-start">
                  <div class="text-center">
                    <v-chip
                      v-if="employerStatus"
                      :color="chipcard_status.color"
                      :text-color="chipcard_status.textcolor"
                      >{{ employerStatus }}</v-chip
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <h3 class="primary--text">แก้ไขข้อมูลสถานประกอบการ</h3>
                </v-col>
              </v-row>
              <v-row class="d-flex" style="justify-content: space-between">
                <v-col cols="12" sm="12" md="8" lg="6" xl="6" class="pt-5">
                  <div class="d-sm-none">
                    อัปเดตวันที่ตรวจสอบ(DBD) : {{ dbdUpdateDate }}
                  </div>
                  

                  <div class="d-none d-sm-flex align-center">
                    อัปเดตวันที่ตรวจสอบ(DBD) : {{ dbdUpdateDate }}
                  </div>
                  
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="6"
                  xl="6"
                  class="pt-5 d-flex justify-end"
                >
                  <v-btn
                    @click="checkEmployerProfile"
                    color="secondary"
                    style=""
                    >ตรวจสอบ(DBD)</v-btn
                  >
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-alert :type="dbdAlertStatus.typeStatus">{{
                    dbdAlertStatus.textStatus
                  }}</v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="11">
                  <v-text-field
                    label="เลขประจำตัวผู้เสียภาษี"
                    v-model="vm.identityid"
                    ref="txtIdentityid"
                    :rules="custom_identityidRules"
                    @input="checkIdentityidEdited"
                    :disabled="vm.dontknowidentityid"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="d-flex align-center">
                  <v-tooltip top color="success" v-if="employerData">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon style="font-size: 30px"
                          >mdi-information-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>ได้รับการตรวจสอบจากกรมพัฒนาธุรกิจการค้า</span>
                  </v-tooltip>
                  <v-tooltip top color="error" v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon style="font-size: 30px"
                          >mdi-information-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>ยังไม่ได้รับการตรวจสอบจากกรมพัฒนาธุรกิจการค้า</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="d-block justify-start align-end">
                  <v-checkbox
                    label="ไม่มีเลขทะเบียนนิติบุคคล/เลขผู้เสียภาษี"
                    v-model="vm.dontknowidentityid"
                    @change="ClearIdentityID"
                  ></v-checkbox>
                  <div style="padding-left: 33px">
                    <label class="theme--light v-label"
                      >ตัวเลือกนี้ควรเลือกใช้เฉพาะในกรณีที่ไม่สามารถระบุ
                      "เลขทะเบียนนิติบุคคล/เลขผู้เสียภาษี" ได้จริง ๆ
                      เท่านั้น</label
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ชื่อสถานประกอบการ"
                    v-model="vm.nameth"
                    :rules="[(v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ที่อยู่"
                    v-model="vm.address"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="จังหวัด"
                    :items="provinces"
                    item-text="provincethai"
                    item-value="provinceid"
                    v-model="vm.selected_province_id"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center text-center mb-4">
                <v-col cols="12">
                  <v-btn @click="onSubmit" color="secondary"
                    ><v-icon>mdi-content-save</v-icon> บันทึก</v-btn
                  >
                  <v-btn color="" @click="$router.go(-1)" class="ma-2">
                    ย้อนกลับ</v-btn
                  >
                </v-col>
              </v-row>
            </v-card> -->
          </v-form>
        </v-col></v-row
      >
      <BtnVerifyEmployerDBD
        v-if="vm.identityid"
        :dialogFound="this.dialogFound"
        :employerName="vm.nameth"
        :status="vm.dbd_organizationjuristicstatus"
        :dialogNotFound="this.dialogNotFound"
        :dialogFail="this.dialogFail"
      ></BtnVerifyEmployerDBD>
    </v-container>
  </div>
</template>

<script>
import { identityidRules } from "@/ValidationHelper.js";
import { CreateEmployerSummaryRequest } from "@/models/EmployerSummaries/CreateEmployerSummaryRequest";
import { EmployerSummaryDataProvider } from "@/services/EmployerSummaryDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { UpdateEmployerSummaryRequest } from "@/models/EmployerSummaries/UpdateEmployerSummaryRequest";
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import { EmployerExternalApiDataProvider } from "@/services/EmployerExternalApiDataProvider";
const employerSummaryDataProvider = new EmployerSummaryDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import moment from "moment";
import { formatDateThai } from "@/DateTimeHelper";
import BtnVerifyEmployerDBD from "@/components/Employer/BtnVerifyEmployerDBD.vue";

export default {
  name: "EditEmployerSummaryView",
  components: {
    BtnVerifyEmployerDBD,
  },
  data() {
    return {
      moment: moment,
      provinces: undefined,
      oid: "",
      vm: new UpdateEmployerSummaryRequest(),
      valid: true,
      identityidRules: identityidRules,
      identityidEdited: false,
      originalidentityid: "",
      identityidExistingResult: false,
      employerData: null,
      loading: false,
      error: undefined,
      employerExternalApiDataProvider: new EmployerExternalApiDataProvider(),
      employerStatus: undefined,
      savedDate: null,
      thaiMonths: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      dbdLastverifydate: undefined,
      dbdStatus: true,
      dialogFound: false,
      dialogNotFound: false,
      dialogFail: false,
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });

      await employerSummaryDataProvider
        .getEmployerSummaryByOid(this.oid)
        .then((result) => {
          console.log(result.data);
          this.vm.oid = result.data.oid;
          this.vm.identityid = result.data.identityid;
          this.vm.nameth = result.data.nameth;
          this.vm.address = result.data.address;
          this.originalidentityid = this.vm.identityid;
          this.vm.dontknowidentityid = result.data.dontknowidentityid;
          // console.log(result.data.dbd_lastverifydate);
          // this.dbdLastverifydate = result.data.dbd_lastverifydate;
          // this.employerStatus = result.data.dbd_organizationjuristicstatus;
          // this.dbdStatus = result.data.dbd_status;
          // // this.vm.dbdStatus = result.data.dbd_status;
          // console.log(this.employerData);

          // if (this.dbdStatus == true) {
          //   this.employerData = result.data.identityid;
          // } else if (
          //   this.dbdStatus == false &&
          //   this.dbdLastverifydate != "0001-01-01T00:00:00"
          // ) {
          //   this.error = true;
          // }
          // console.log(this.dbdStatus);
          // console.log(this.employerData);

          // if (result.data.province_code) {
          //   this.vm.selected_province_id = result.data.province_code;
          // }
          // console.log(this.dbdLastverifydate);

          this.vm.dbd_lastverifydate = result.data.dbd_lastverifydate;

          this.vm.dbd_status = result.data.dbd_status;

          if (result.data.dbd_organizationjuristicstatus) {
            this.vm.dbd_organizationjuristicstatus =
              result.data.dbd_organizationjuristicstatus;
          } else {
            this.error = true;
          }
        });
    },

    async onSubmit() {
      if (this.$refs.form.validate()) {
        if (confirm("ต้องการบันทึกข้อมูลหรือไม่")) this.identityidEdited = true;
        await employerSummaryDataProvider
          .updateEmployerSummary(this.vm)
          .then((result) => {
            this.$toast.success("อัปเดตข้อมูลสถานประกอบการเรียบร้อย");
            this.$router.back();
          })
          .catch((error) => {
            this.$toast.error(error.message);
          });
      }
    },
    checkIdentityidEdited() {
      this.identityidEdited = this.vm.identityid !== this.originalidentityid;
    },
    async isCheck() {
      this.vm.dontknowidentityid = false;
    },
    ClearIdentityID() {
      if (this.vm.dontknowidentityid) {
        this.vm.identityid = "";
      }
    },
    async checkEmployerProfile() {
      if (this.vm.identityid) {
        try {
          this.$store.dispatch("showLoading");
          this.error = false;
          this.employerData = null;

          console.log(this.vm.oid);

          const result =
            await employerProfileDataProvider.VerifyProfileWithDBDOpenAPI(
              this.vm.oid
            );
          console.log(result.data);

          if (result.data.value.detail) {
            // this.$toast.error("ไม่พบข้อมูลสถานประกอบการกับ DBD");
            this.dialogNotFound = true;

            this.error = true;
          } else {
            const organizationData =
              result.data.value?.data[0]["cd:organizationjuristicperson"];
            console.log(organizationData);

            if (
              organizationData &&
              organizationData["cd:organizationjuristicid"] ===
                this.vm.identityid
            ) {
              // this.$toast.success("ตรวจสอบสถานะสถานประกอบการกับ DBD เรียบร้อย");
              this.dialogFound = true;
              this.vm.dbd_status = true;
              this.vm.dbd_lastverifydate = this.dbdUpdateDate;
              this.vm.dbd_organizationjuristicstatus =
                organizationData["cd:organizationjuristicstatus"];
              console.log(this.vm.dbd_organizationjuristicstatus);

              const updateResult =
                await employerProfileDataProvider.updateEmployerProfile(
                  this.vm
                );
              console.log(updateResult);
            }
          }
        } catch (error) {
          console.error(
            "An error occurred while verifying the employer profile:",
            error
          );

          if (error.response && error.response.status === 500) {
            alert("DBD ล้มเหลว");
            this.dialogFail = true;
          } else {
            this.$toast.error("เกิดข้อผิดพลาดในการตรวจสอบสถานประกอบการ");
            // this.dialogFail = true;
          }
        } finally {
          this.$store.dispatch("hideLoading");
        }
      }
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()} ${
        this.thaiMonths[current.getMonth()]
      } ${current.getFullYear() + 543}`;
      const time = `(${current.getHours()}:${current
        .getMinutes()
        .toString()
        .padStart(2, "0")} น.)`;
      return `${date} ${time}`;
    },
    convertDateFormat(dbdLastverifydate) {
      return formatDateThai(dbdLastverifydate);
    },
  },
  computed: {
    custom_identityidRules() {
      return [
        (v) =>
          !!v ||
          this.vm.dontknowidentityid ||
          "กรุณาระบุเลขประจำตัวผู้เสียภาษี",
        (v) =>
          !this.identityidExistingResult ||
          this.vm.dontknowidentityid ||
          !this.identityidEdited ||
          "เลขประจำตัวผู้เสียภาษีนี้ถูกใช้งานแล้ว",
      ];
    },
    chipcard_status() {
      let color = "";
      let textcolor = "white";

      if (this.vm.dbd_organizationjuristicstatus === "ร้าง") {
        color = "";
        textcolor = "white";
      } else if (
        this.vm.dbd_organizationjuristicstatus === "เสร็จการชำระบัญชี"
      ) {
        color = "error";
        textcolor = "white";
      } else if (
        this.vm.dbd_organizationjuristicstatus === "ยังดำเนินกิจการอยู่"
      ) {
        color = "success";
        textcolor = "white";
      }
      return { color, textcolor };
    },
    dbdUpdateDate() {
      console.log(this.vm.dbd_status);
      console.log(this.vm.dbd_lastverifydate);
      if (
        this.vm.dbd_status === false &&
        this.vm.dbd_lastverifydate !== "0001-01-01T00:00:00"
      ) {
        return `${this.moment(this.vm.dbd_lastverifydate)
          .add(543, "year")
          .locale("TH")
          .format("lll")} น.`;
      } else if (
        this.vm.dbd_status === false &&
        this.vm.dbd_lastverifydate === "0001-01-01T00:00:00"
      ) {
        return "-";
      } else if (
        this.vm.dbd_status === true &&
        this.vm.dbd_lastverifydate !== "0001-01-01T00:00:00"
      ) {
        // return this.vm.dbd_lastverifydate;

        return `${this.moment(this.vm.dbd_lastverifydate)
          .add(543, "year")
          .locale("TH")
          .format("lll")} น.`;
      } else if (this.vm.dbd_status === true) {
        return this.vm.dbd_lastverifydate;
        // return this.currentDate();
      }
    },

    dbdAlertStatus() {
      let textStatus = "";
      let typeStatus = "";
      if (this.loading) {
        textStatus = "กำลังค้นหาข้อมูล...";
        typeStatus = "info";
      } else if (this.vm.dbd_organizationjuristicstatus) {
        console.log(this.vm.dbd_organizationjuristicstatus);
        textStatus = "พบข้อมูลนิติบุคคล";
        typeStatus = "success";
      } else if (this.error) {
        textStatus = "ไม่พบข้อมูลนิติบุคคล";
        typeStatus = "error";
      }
      return { textStatus, typeStatus };
    },
  },
  // watch: {
  //   "vm.identityid": {
  //     async handler(newVal, oldVal) {
  //       if (newVal != "") {
  //         await employerSummaryDataProvider.IsIdentityIDUsed(newVal).then((result) => {
  //           this.identityidExistingResult = result.data;
  //           console.log("ผู้เสียภาษี" + this.identityidExistingResult);
  //           this.$refs.txtIdentityid.validate();
  //         });
  //       }
  //     },
  //     deep: true,
  //   },

  //   "vm.dbd_status": function (newVal) {
  //     if (newVal === true) {
  //       if (result.data.dbd_organizationjuristicstatus !== null) {
  //         this.employerStatus = result.data.dbd_organizationjuristicstatus;
  //       }
  //     } else if (result.data.dbd_lastverifydate === "0001-01-01T00:00:00") {
  //       this.error = false;
  //     } else {
  //       this.error = true;
  //     }
  //   },
  // },
  async created() {
    if (this.$route.params.oid) {
      this.oid = this.$route.params.oid;
    }
    await this.initialize();
  },
};
</script>

<style scoped>
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
