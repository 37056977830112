export class SaveEmailConfigurationRequest 
{
    fromEmailAddress  = ''
    fromEmailDisplayName = '' 
    smtpHost  = ''
    smtpPort  = ''
    smtpAuthen = false
    smtpSSL = false
    smtpUser = ''
    smtpPassword = ''

    static fromJson(jsonObject = '') {
        var request = new SaveEmailConfigurationRequest();
        request.fromEmailAddress = jsonObject.fromemailaddress;
        request.fromEmailDisplayName =  jsonObject.fromemaildisplayname;
        request.smtpHost = jsonObject.smtphost;
        request.smtpPort = jsonObject.smtpport;
        request.smtpAuthen = jsonObject.smtpauthen;
        request.smtpSSL = jsonObject.smtpssl;
        request.smtpUser = jsonObject.smtpuser;
        request.smtpPassword = jsonObject.smtppassword;
        return request;
    }
}