export function createColorCodeArr(length = 0) {
  const colorArrayResult = [];
  for (i = 0; i <= length; i++) {
    const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
    const randomRGB = () =>
      `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
  }
}

export const default_colors = ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC']
