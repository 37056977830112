import { server } from "@/services/constants";
import axios from "axios";
import { backendapiUrl } from "@/services/constants";
import { create_default_request_header } from "./constants";


const isLoggedIn = () => {
  const token = localStorage.getItem(server.TOKEN_KEY);
  return token != null;
};

const login = async (account) => {
    const header = create_default_request_header();
    return await axios.post(process.env.VUE_APP_BACKEND_API_URL + "/api/Authentication/Authenticate", account,header);
};
const userinfo = async () => {
  try {
    return await axios.get(process.env.VUE_APP_BACKEND_API_URL + "/api/UserInfo/GetUserInfo", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(server.TOKEN_KEY)}`,
      },
    });
  } catch (error) {
  }
};
const userimage = async () => {
  try {
    return await axios.get(process.env.VUE_APP_BACKEND_API_URL + "/api/UserInfo/GetUserImage", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(server.TOKEN_KEY)}`,
      },
    });
  } catch (error) {
  }
};
const logoff = async () => {
  const header = create_default_request_header();
  localStorage.removeItem("imageuser");
  localStorage.removeItem(server.TOKEN_KEY);
  return await axios.post(process.env.VUE_APP_BACKEND_API_URL + "/api/Authentication/Logout",null ,header);
};

const loginstudent = async (account) => {
  try {
    return await axios.post(process.env.VUE_APP_BACKEND_API_URL + "/api/Authentication/Student_Authenticate", account);
  } catch (error) {
    return error;
  }
};
export default {
  logoff,
  login,
  //register,
  isLoggedIn,
  userinfo,
  userimage,
  loginstudent
};
