import { NotificationDataProvider } from "@/services/SignalR/NotificationDataProvider";
import { title } from "devexpress-reporting/dx-webdocumentviewer";
var notificationDataProvider = new NotificationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  state: {
    notification: [],
    selectedIndex: -1,
    countUnRead: 0,
  },
  getters: {
    notification(state) {
      return state.notification;
    },
    selectedIndex(state) {
      return state.selectedIndex;
    },
    countUnRead(state) {
      return state.countUnRead;
    },
  },
  mutations: {
    SET_COUNT_UNREAD_NOTI(state, count) {
      state.countUnRead = count;
    },
    SET_ADD_HIS_NOTI(state, notificationdata) {
      state.notification.push(notificationdata);
    },
    SET_ADD_NEW_NOTI(state, notificationdata) {
      state.notification.unshift(notificationdata);
      // state.notification.pop();
    },
    SET_MARK_AS_READ_NOTI(state, id) {
      var notification = state.notification.find(function (n) {
        return n.id === id;
      });
      if (notification) {
        if (notification.isread == false) {
          if (state.countUnRead > 0) {
            state.countUnRead--;
          }
        }
        notification.isread = true;
      }
    },
    SET_MARKALL_AS_READ_NOTI(state) {
      state.notification.forEach(function (notification) {
        notification.isread = true;
      });
    },
    SET_INDEX_NOTI(state, index) {
      state.selectedIndex = index;
    },
    SET_TO_DEFAULT(state) {
      state.countUnRead = 0;
      state.notification = [];
    },
  },

  actions: {
    doAddedNotificationIndex({ commit }, { index }) {
      commit("SET_INDEX_NOTI", index);
    },
    doAddedNewNotification({ commit }, { notificationdata }) {
      notificationDataProvider
        .GetCountUnReadNoti()
        .then((result) => {
          commit("SET_COUNT_UNREAD_NOTI", result.data);
        })
        .catch(() => {
        });
      commit("SET_ADD_NEW_NOTI", notificationdata);
    },
    doUpdateCountUnReadNoti({ commit }) {
      notificationDataProvider
        .GetCountUnReadNoti()
        .then((result) => {
          commit("SET_COUNT_UNREAD_NOTI", result.data);
        })
        .catch(() => {});
    },
    doAddedHisNotification({ commit }, { notificationdata }) {
      commit("SET_ADD_HIS_NOTI", notificationdata);
    },
    doMarkAsReadNotification({ commit }, { id }) {
      commit("SET_MARK_AS_READ_NOTI", id);
    },
    doMarkAllAsReadNotification({ commit }) {
      commit("SET_MARKALL_AS_READ_NOTI");
    },
    doResetNotiStore({ commit }) {
      commit("SET_TO_DEFAULT");
    },
  },
  modules: {},
};
