<template>
    <v-container class="">
        <v-row>
            <v-col>
                <h1>Edit page</h1>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas error ea corporis explicabo animi
                    dolorem? Dignissimos tempore laborum, placeat quas nesciunt labore aliquam inventore quae
                    doloremque. Cum error quibusdam repellat!</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {

}
</script>

<style>
</style>