<template>
  <div class="pa-4">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-book</v-icon> หลักสูตร
      </h3>
    </v-row>
    <SearchableDataTable
      table_caption="หลักสูตร"
      :datatable_headers="curriculums_data_table_headers"
      :data_items="curriculums"
    >
      <template v-slot:item.quantityofrelatedindustry="{ item }">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <a class="text-info" @click="OpenDisplayRelatedIndustriesDialog(item)"
              >{{ item.quantityofrelatedindustry }} อุตสาหกรรมเป้าหมาย</a
            >
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.quantityofrelatedjobposition="{ item }">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <a class="text-info" @click="gotoCurriculumJobPositionMatchingView(item)"
              >{{ item.quantityofrelatedjobposition }} ตำแหน่งงาน</a
            >
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToDetailView(item)" icon color="info">
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
    <v-dialog v-model="isOpenDisplayRelatedIndustriesDialog" width="950">
      <template>
        <v-card class="pa-6">
          <v-card-title> </v-card-title>
          <v-card-text>
            <SearchableDataTable
              table_caption="อุตสหกรรมเป้าหมาย"
              :data_items="relatedindustries"
              :datatable_headers="relatedindustries_data_table_headers"
            ></SearchableDataTable>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { CurriculumDataProvider } from "@/services/CurriculumDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var curriculumDataProvider = new CurriculumDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "CurriculumListView",
  data() {
    return {
      isImporting: false,
      isOpenDisplayRelatedIndustriesDialog: false,
      relatedindustries: undefined,
      relatedindustries_data_table_headers: [
        { text: "ชื่ออุตสาหกรรมเป้าหมาย", value: "industrynameth" },
      ],

      curriculumsoid: "",
      curriculums: undefined,
      curriculums_data_table_headers: [
        { text: "รหัสประเภทหลักสูตร", value: "curiculumtype" },
        { text: "ปีหลักสูตร", value: "year" },
        { text: "รหัสระดับชั้น", value: "degreelevelcode" },
        //{ text: "รหัส PK ระดับชั้น", value: "degreelevelid" },
        // { text: "รหัสประเภทวิชา", value: "subjecttypecode" },
        { text: "ชื่อประเภทวิชา", value: "subjecttypename" },
        //{ text: "รหัส PK ประเภทวิชา", value: "subjecttypeid" },
        // { text: "รหัสสาขาวิชา", value: "majorcode" },
        { text: "ชื่อสาขาวิชา", value: "majorname" },
        //{ text: "รหัส PK สาขาวิชา", value: "majorid" },
        // { text: "รหัสสาขางาน", value: "minorcode" },
        { text: "ชื่อสาขางาน", value: "minorname" },
        //{ text: "รหัส PK สาขางาน", value: "minorid" },
        //{ text: "รหัส PK หลักสูตร", value: "curriculumid" },
        { text: "ปีหลักสูตร", value: "curriculumyear" },
        { text: "โครงสร้างหลักสูตรปี", value: "structureyearid" },
        //{ text: "หมายเหตุ", value: "remark" },
        { text: "10 อุตสาหกรรมเป้าหมาย", value: "quantityofrelatedindustry" },
        { text: "ความต้องการแรงงาน (ตำแหน่งงาน)", value: "quantityofrelatedjobposition" },
        { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    async importCurriculums() {
      this.isImporting = true;
      await curriculumDataProvider
        .importCurriculums()
        .then((result) => {
          this.onImportCurriculumsCompleted();
          this.curriculums = result.data;
        })
        .catch((err) => {
          this.isImporting = false;
          alert(err);
        });
    },
    async loadCurriculums() {
      await curriculumDataProvider
        .loadCurriculums()
        .then((result) => {
          this.curriculums = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToDetailView: function (item) {
      var curriculumID = item.curriculumid;
      this.$router
        .push({ name: "CurriculumDetailView", params: { curriculumID: curriculumID } })
        .catch((err) => {});
    },

    gotoCurriculumJobPositionMatchingView(item) {
      var curriculumID = item.curriculumid;
      this.$router
        .push({
          name: "CurriculumJobPositionMatchingView",
          params: { curriculumID: curriculumID },
        })
        .catch((err) => {});
    },

    onImportCurriculumsCompleted() {
      this.isImporting = false;
      this.loadCurriculums();
    },

    async loadRelatedIndustries(oid) {
      await curriculumDataProvider.getRelatedIndustries(oid).then((result) => {
        this.relatedindustries = result.data;
      });
    },

    async OpenDisplayRelatedIndustriesDialog(item) {
      await this.loadRelatedIndustries(item.oid);
      this.isOpenDisplayRelatedIndustriesDialog = true;
    },
  },
  async created() {
    await this.loadCurriculums();
  },
};
</script>

<style></style>
