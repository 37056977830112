<template>
  <v-container fluid class="mt-4">
    <v-row>
      <v-col cols="12" class="d-flex justify-start">
        <v-btn color="primary" @click="OnVerifyManagerDetailView_DialogClick"
          ><v-icon>mdi-plus</v-icon>เพิ่มข้อมูลผู้รับรอง</v-btn
        >
      </v-col>
    </v-row>
    <SearchableDataTable
      table_caption="ข้อมูลผู้รับรอง"
      :data_items="managers"
      :datatable_headers="managers_data_table_headers"
    >
      <template class="d‑flex" v-slot:item.action="{ item }">
        <v-row class="custom-width">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="OnUpdateVerifyManagerDetailView_DialogClick(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขข้อมูลผู้บริหาร</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="OnDeleteVerifyManagerDetailView_DialogClick(item)"
                color="error"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ลบผู้บริหาร</span>
          </v-tooltip></v-row
        >
      </template>
    </SearchableDataTable>
    <DialogCreateVerifyManagerForm
      :VerifyManagerDetailView_Dialog="VerifyManagerDetailView_Dialog"
      :oid="manageroid"
      @closeDialog="OnVerifyManagerDialogclose"
    ></DialogCreateVerifyManagerForm>
  </v-container>
</template>

<script>
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
// import { MasterDataProvider } from "@/services/MasterDataProvider"
import { VerifyManagerDataProvider } from "@/services/VerifyManagerDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import DialogCreateVerifyManagerForm from "@/components/VerifyManager/DialogCreateVerifyManagerForm.vue";
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
// const masterDataProvider = new MasterDataProvider(
//   process.env.VUE_APP_BACKEND_API_URL
// )
const verifyManagerDataProvider = new VerifyManagerDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "VerifyManagerListView",
  components: { SearchableDataTable, DialogCreateVerifyManagerForm },
  data() {
    return {
      school: [],
      managers: undefined,
      managers_data_table_headers: [
        { text: "คำนำหน้า", value: "titlename" },
        { text: "ชื่อ", value: "name" },
        { text: "นามสกุล", value: "surname" },
        { text: "ตำแหน่ง", value: "position" },
        { text: "ตัวเลือก", value: "action", sortable: false },
      ],
      manageroid: "-1",
      VerifyManagerDetailView_Dialog: false,
    };
  },
  methods: {
    async OnVerifyManagerDialogclose(value, confirm) {
      if (confirm) {
        if (value.oid == "-1") {
          await this.CreateVerifyManager(value);
        } else {
          await this.UpdateVerifyManager(value);
        }
        await this.loadVerifyManagers();
      }
      this.VerifyManagerDetailView_Dialog = false;
    },
    OnVerifyManagerDetailView_DialogClick() {
      this.VerifyManagerDetailView_Dialog = true;
      this.manageroid = "-1";
    },
    OnUpdateVerifyManagerDetailView_DialogClick(item) {
      this.VerifyManagerDetailView_Dialog = true;
      this.manageroid = item.oid;
    },
    async loadVerifyManagers() {
      await verifyManagerDataProvider
        .getVerifyManagers_ByCurrentSchoolEmployeeProfile()
        .then((result) => {
          this.managers = result.data;
        });
    },
    async CreateVerifyManager(value) {
      await verifyManagerDataProvider
        .createVerifyManager(value)
        .then((result) => {
          this.$toast.success("สร้างรายชื่อผู้บริหารเรียบร้อย!");
        })
        .catch((err) => {
          this.$toast.error("ล้มเหลว!" + err);
        });
    },
    async UpdateVerifyManager(value) {
      await verifyManagerDataProvider
        .updateVerifyManager(value)
        .then((result) => {
          this.$toast.success("บันทึกสำเร็จแล้ว!");
        })
        .catch((err) => {
          this.$toast.error("ล้มเหลว!" + err);
        });
    },
    async DeleteVerifyManager(oid) {
      await verifyManagerDataProvider
        .deleteVerifyManager(oid)
        .then((result) => {
          this.$toast.success("ลบรายชื่อผู้บริหารสำเร็จแล้ว!");
        })
        .catch((err) => {
          this.$toast.error("ล้มเหลว!" + err);
        });
    },
    async OnDeleteVerifyManagerDetailView_DialogClick(item) {
      const oid = item.oid;
      if (confirm("ต้องการลบรายชื่อผู้บริหารหรือไม่ ?")) {
        await this.DeleteVerifyManager(oid);
        await this.loadVerifyManagers();
      }
    },
  },

  async created() {
    await this.loadVerifyManagers();
  },
};
</script>

<style scoped>
.custom-width {
  width: 120px;
  gap: 20px;
}
</style>
