import CurriculumListView from "@/views/Curriculum/CurriculumListView.vue";
import CurriculumDetailView from "@/views/Curriculum/CurriculumDetailView.vue";
import CurriculumJobPositionMatchingView from "@/views/Curriculum/CurriculumJobPositionMatchingView.vue";

export default [
  {
    path: "/Curriculum",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Curriculum/CurriculumListView",
        name: "CurriculumListView",
        component: CurriculumListView,
        meta: {
          requiresAuth: true,
          nameTh: "หลักสูตร",
        },
      },
      {
        path: "/Curriculum/CurriculumDetailView/:curriculumID",
        name: "CurriculumDetailView",
        component: CurriculumDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดหลักสูตร",
        },
      },
      {
        path: "/Curriculum/CurriculumJobPositionMatchingView/:curriculumID",
        name: "CurriculumJobPositionMatchingView",
        component: CurriculumJobPositionMatchingView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดหลักสูตร",
        },
      },
    ],
  },
];
