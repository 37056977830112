<template>
  <v-dialog v-model="StudyStudentDetailView_Dialog" width="850" persistent>
    <v-card class="pa-10">
      <h2 class="primary--text">ประวัติการศึกษา</h2>
      <v-divider class="mt-4 mb-5 mx-0"></v-divider>
      <v-card-text class="px-0">
        <v-form ref="form" v-model="valid">
          <v-row class="align-center mt-2 mx-0" height="10">
            <v-select
              label="ปีที่สำเร็จการศึกษา"
              v-model="vm.graduatedyear"
              item-text="txtyear"
              item-value="year"
              :items="yearsth_Choice"
              :rules="[(v) => !!v || 'กรุณาระบุปีที่สำเร็จการศึกษา']"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-row>
          <v-row class="align-center mx-0">
            <v-select
              label="วุฒิการศึกษา"
              v-model="vm.selected_educationalqualification_code"
              item-text="nameth"
              item-value="code"
              :items="educationalqualification_Choice"
              :rules="[(v) => !!v || 'กรุณาระบุวุฒิการศึกษา']"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-row>
          <v-row class="align-center mx-0">
            <v-text-field
              label="ชื่อสถานศึกษา"
              filled
              v-model="vm.schoolname"
              :rules="[(v) => !!v || 'กรุณาระบุชื่อสถานศึกษา']"
              background-color="input"
            >
            </v-text-field>
          </v-row>
          <v-row class="align-center mx-0">
            <v-text-field
              label="คณะวิชา"
              filled
              background-color="input"
              v-model="vm.majornameth"
            >
            </v-text-field>
          </v-row>
          <v-row class="align-center mx-0">
            <v-text-field
              label="สาขาวิชา"
              filled
              background-color="input"
              v-model="vm.minornameth"
            >
            </v-text-field>
          </v-row>

          <v-row class="align-center mx-0">
            <v-text-field
              label="เกรดเฉลี่ย"
              filled
              background-color="input"
              v-model="vm.gpa"
            >
            </v-text-field>
          </v-row>
          <v-row class="justify-center text-center mt-7">
            <v-col cols="12">
              <v-btn @click="onCancelClick()" class="mx-3"> ย้อนกลับ </v-btn>
              <v-btn
                :loading="isInCreatingProcess"
                @click="onSaveClick()"
                dark
                class="mx-3 color-buton"
                >บันทึก<v-icon class="ml-2"> mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="confirm_dialog" width="400">
            <template>
              <v-card class="pa-6">
                <v-row class="justify-center text-center">
                  <v-card-title> ยืนยันเพิ่มประวัติการศึกษา ? </v-card-title>
                  <v-col cols="12">
                    <v-btn
                      @click="confirm_dialog = false"
                      class="ma-2 back-button"
                      elevation="2"
                      >ยกเลิก</v-btn
                    >
                    <v-btn @click="onButtonClick" class="ma-2 color-button2">
                      ยืนยัน
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-dialog>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";

import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import { StudyRecord } from "@/models/Student/StudyRecord";
var studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "EducationStudentDetailView",
  props: { StudyStudentDetailView_Dialog: Boolean, oid: String },
  data() {
    return {
      vm: new StudyRecord(),
      isInCreatingProcess: false,
      confirm_dialog: false,
      confirm_result: false,
      dialog: false,
      yearsth_Choice: [],
      educationalqualification_Choice: [],
      valid: false,
    };
  },
  watch: {
    StudyStudentDetailView_Dialog(value) {
      if (value) {
        this.resetFormValidation();
        if (this.oid != "-1") {
          this.loadStudentStudyRecord(this.oid);
        } else {
          this.vm = new StudyRecord();
        }
      } else {
        this.resetFormValidation();
      }
    },
  },
  methods: {
    async initialize() {
      this.loadyear();
      this.loadEducationQualifications();
    },
    loadStudentStudyRecord() {
      studentDataProvider.getStudentStudyRecord().then((res) => {
        this.vm = res.data;
      });
    },
    async loadStudentStudyRecord(oid) {
      await studentDataProvider
        .getStudentStudyRecord(oid)
        .then((response) => {
          console.log(response.data);
          this.vm.oid = response.data.oid;
          this.vm.selected_educationalqualification_code =
            response.data.educationalqualification.code;
          this.vm.graduatedyear = response.data.graduatedyear;
          this.vm.schoolname = response.data.schoolname;
          this.vm.majornameth = response.data.majornameth;
          this.vm.minornameth = response.data.minornameth;
          this.vm.gpa = response.data.gpa;

          // console.log(
          //   this.vm.selected_educationalqualification_code,
          //   "EDUCATION"
          // );
        })
        .catch((response) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    async loadyear() {
      await masterDataProvider.getListOfAllYearTH().then((response) => {
        this.yearsth_Choice = response.data;
      });
    },
    async loadEducationQualifications() {
      await masterDataProvider
        .GetEducationalQualifications()
        .then((response) => {
          this.educationalqualification_Choice = response.data;
        });
    },

    onButtonClick() {
      if (this.$refs.form.validate()) {
        this.isInCreatingProcess = false;
        this.$emit("closeDialog", this.vm, true);
      }
    },
    onCancelClick() {
      this.$emit("closeDialog", this.vm, false);
    },
    onSaveClick() {
      if (this.$refs.form.validate()) {
        this.confirm_dialog = true;
      }
    },
    resetFormValidation() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.vm = new StudyRecord();
    },
  },

  async created() {
    await this.initialize();
  },
  mounted() {},

  components: { TextFieldDatePicker },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
