<template>
  <div>
    <v-row outlined class="pa-2 mb-3 mt-2 d-flex align-end">
      <h4 class="primary--text ml-2">ประวัติการทำงาน</h4>
      <v-spacer></v-spacer>
      <v-btn
        @click="ExperienceStudentDetailView_Dialog = true"
        outlined
        class="mr-2"
        :disabled="isInputDisabled"
      >
        เพิ่มข้อมูล<v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>

    <v-data-table
      :headers="experience_headers"
      :items="experience"
      hide-default-footer
      class="elevation-1 custom-header-gradient mx-1"
    >
      <template v-slot:item.startdate="{ item }">
        <span>
          <FormatDate :date_value="item.startdate" />
        </span>
      </template>
      <template v-slot:item.enddate="{ item }">
        <span>
          <FormatDate :date_value="item.enddate" />
        </span>
      </template>

      <template v-slot:item.jobdescription="{ item }">
        <span>
          {{ item.jobdescription || "-" }}
        </span>
      </template>
      <template v-slot:item.salaryrange.salary_range="{ item }">
        <span>
          {{ item.salaryrange?.salary_range || "-" }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-row class="custom-width">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="onclickEditExperienceStudent(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขประวัติการทำงาน</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="deleteExperienceStudent(item)"
                color="error"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ลบ</span>
          </v-tooltip></v-row
        >
      </template>
    </v-data-table>

    <ExperienceJobStudentDialog
      :ExperienceStudentDetailView_Dialog="ExperienceStudentDetailView_Dialog"
      :oid="Experienceoid"
      :selected_startdate="experience.startdate"
      :selected_enddate="experience.enddate"
      @closeDialog="onExperienceProcessIsCompleted"
    ></ExperienceJobStudentDialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
var studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import ExperienceJobStudentDialog from "@/components/Student/Experience/ExperienceJobStudentDialog.vue";
import { JobRecord } from "@/models/Student/JobRecord";
import FormatDate from "@/components/common/FormatDate.vue";
export default {
  props: ["studentoid", "isInputDisabled"],
  components: {
    ExperienceJobStudentDialog,
    FormatDate,
  },
  data() {
    return {
      ExperienceStudentDetailView_Dialog: false,
      experience_headers: [
        { text: "วันที่เริ่ม", value: "startdate" },
        { text: "วันที่สิ้นสุด", value: "enddate" },
        { text: "สถานประกอบการ", value: "organizationname" },
        { text: "ตำแหน่งงาน", value: "positionname" },
        { text: "รายละเอียดงาน", value: "jobdescription" },
        { text: "เงินเดือนที่ได้รับ", value: "salaryrange.salary_range" },
        { text: "", value: "action" , sortable: false},
      ],
      experience: [],
      Experienceoid: -1,
    };
  },
  methods: {
    async LoadExperienceStudent(studentoid) {
      studentDataProvider
        .getStudentJobRecords(studentoid)
        .then((res) => {
          this.experience = res.data;
          if (res.data?.lookingforexperience) {
            this.lookingforexperience = res.data.lookingforexperience;
          }
        })
        .catch((res) => {});
    },
    async saveExperienceStudent(value) {
      console.log(value);
      value.student_oid = this.studentoid;
      studentDataProvider
        .saveStudentJobRecord(value)
        .then((res) => {
          this.$toast.success("สร้าง ประวัติ เรียบร้อย!");
          const studentoid = this.studentoid;
          this.LoadExperienceStudent(studentoid);
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว!");
        });
    },

    async onExperienceProcessIsCompleted(value, save) {
      if (save) {
        this.ExperienceStudentDetailView_Dialog = false;
        if (value.oid != -1) {
          await this.UpdateExperienceStudent(value);
        } else {
          await this.saveExperienceStudent(value);
        }
      } else {
        this.ExperienceStudentDetailView_Dialog = false;
      }
      this.Experienceoid = -1;
    },

    onclickEditExperienceStudent(item) {
      this.Experienceoid = item.oid;
      this.ExperienceStudentDetailView_Dialog = true;
    },
    async UpdateExperienceStudent(value) {
      studentDataProvider
        .editStudentJobRecord(value)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("บันทึกข้อมูลสำเร็จ");
            const studentoid = this.studentoid;
            this.LoadExperienceStudent(studentoid);
          }
        })
        .catch((res) => {
          this.$toast.error("แก้ไขข้อมูลล้มเหลว !!!" + res);
        });
    },

    async deleteExperienceStudent(item) {
      if (confirm("ต้องการประวัติการทำงานหรือไม่ ?")) {
        await studentDataProvider
          .deleteStudentJobRecord(item.oid)
          .then((res) => {
            this.experience = [];
            this.$toast.success("ลบประวัติการทำงาน เรียบร้อย!");
            const studentoid = this.studentoid;
            this.LoadExperienceStudent(studentoid);
          })
          .catch((res) => {
            this.$toast.error("ลบประวัติการทำงานล้มเหลว!" + res);
          });
      }
    },
  },
  async mounted() {
    const studentoid = this.studentoid;
    await this.LoadExperienceStudent(studentoid);
  },
};
</script>

<style scoped>
.custom-width {
  width: 100px;
  gap: 10px;
}
.custom-header-gradient .v-data-table-header {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.custom-header-gradient th {
  color: white !important;
}
</style>
