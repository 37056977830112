export default {
  state: {
    currentchatdata: [],
    notichatalert: false,
    newmessagehighlight: []
  },
  getters: {
    currentchatdata(state) {
      return state.currentchatdata
    },
    notichatalert(state) {
      return state.notichatalert
    },
    newmessagehighlight(state) {
      return state.newmessagehighlight
    }
  },
  mutations: {
    SET_MAP_CURRENTCHAT(state, chatdata) {
      if (state.currentchatdata.length > 0) {
        state.currentchatdata.push(chatdata)
      } else {
        state.currentchatdata = chatdata
      }
    },
    SET_CLEAR_CURRENTCHAT(state) {
      state.currentchatdata = []
    },
    SET_ALERT_NOTI(state) {
      state.notichatalert = true
    },
    SET_REMOVE_ALERT_NOTI(state) {
      state.notichatalert = false
    },
    SET_ADD_HIGHTLIGHT_MESSAGE(state, username) {
      var index = state.newmessagehighlight.findIndex((x) => x == username)
      if (index === -1) {
        state.newmessagehighlight.push(username)
      }
    },
    SET_REMOVE_HIGHTLIGHT_MESSAGE(state, username) {
      let removeduserlist = state.newmessagehighlight.filter(
        (e) => e !== username
      )
      state.newmessagehighlight = removeduserlist
    },
    SET_TO_DEFAULT(state) {
      state.currentchatdata = []
      state.notichatalert = false
      state.newmessagehighlight = []
    }
  },

  actions: {
    // added message
    doUpdateCurrentChat({ commit }, { chatdata }) {
      commit("SET_MAP_CURRENTCHAT", chatdata)
    },
    doClearCurrentChat({ commit }) {
      commit("SET_CLEAR_CURRENTCHAT")
    },
    // noti alert
    doAlertNotiChat({ commit }) {
      commit("SET_ALERT_NOTI")
    },
    doRemoveAlertNotiChat({ commit }) {
      commit("SET_REMOVE_ALERT_NOTI")
    },

    //hightlight
    doHightLightToUser({ commit }, { username }) {
      commit("SET_ADD_HIGHTLIGHT_MESSAGE", username)
    },
    doRemoveHightLightToUser({ commit }, { username }) {
      commit("SET_REMOVE_HIGHTLIGHT_MESSAGE", username)
    },
    doResetLiveChatStore({ commit }) {
      commit("SET_TO_DEFAULT")
    }
  },
  modules: {}
}
