export function IsInAllowedToEditEmploymentStatus(jobTrackingReport){
  if(jobTrackingReport.jobtracking_status_statuscode)
    {
      const statusCode = jobTrackingReport.jobtracking_status_statuscode;
      const statusCodes = [
        "NotStartedTrackingProcess",
        "InProcessOfTracking",
      ];
      return statusCodes.includes(statusCode);
    }
    else
    {
      const statusCode = jobTrackingReport.jobtracking_status.statuscode;
      const statusCodes = [
        "NotStartedTrackingProcess",
        "InProcessOfTracking",
      ];
      return statusCodes.includes(statusCode);
    }

}