<template>
  <div class="pa-4 mt-4 d-flex justify-center">
    <v-row class="d-flex justify-center pt-5">
      <v-col cols="12">
        <v-card class="px-5 py-10" style="border-radius: 10px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-3 px-2">
              <v-icon class="pr-2">mdi-chart-areaspline-variant</v-icon>
              <h4>รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)</h4>
            </v-row>
            <v-row class="px-3 py-1" style="margin-top: 1%; margin-bottom: 2%">
              <v-divider></v-divider>
            </v-row>
            <v-row class="pb-6 pl-3">
              <span style="font-weight: bold">เกณฑ์การค้นหา</span>
            </v-row>
            <v-row>
              <v-col cols="12" class="custom-col mb-0 pb-0">
                <span class="mb-2 primary--text font-weight-bold">สถาบัน</span>

                <v-autocomplete
                  placeholder="กรุณาเลือกสถาบัน"
                  :rules="[(v) => !!v || 'กรุณาเลือกสถาบัน']"
                  item-text="institutenameth"
                  item-value="instituteid"
                  :items="institutes"
                  v-model="selected_institue_id"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="mb-2 primary--text font-weight-bold"
                  >ปีที่สำเร็จการศึกษา<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาเลือกปีที่สำเร็จการศึกษา"
                  @change="onYearChanged()"
                  :rules="[(v) => !!v || 'กรุณาระบุปีที่สำเร็จการศึกษา']"
                  v-model="selectedYearTH"
                  :items="yearTH_Choices"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col lg="6" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
                <span class="mb-2 primary--text font-weight-bold"
                  >รอบที่ติดตาม<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาระบุรอบการติดตาม"
                  :rules="[(v) => !!v || 'กรุณาระบุรอบ']"
                  v-model="selectedRound"
                  :items="Round_Choices"
                  :disabled="selectedYearTH ? false : true"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="py-0">
                  <v-col
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                    class="custom-col mb-0 py-0"
                  >
                    <span class="primary--text py-0" style="font-weight: bold"
                      >ระดับการศึกษา</span
                    >
                    <v-autocomplete
                      class="d-flex align-center"
                      :items="certificateTypes"
                      placeholder="กรุณาระบุระดับการศึกษา"
                      v-model="selectedCertificateTypeCode"
                      item-text="nameth"
                      item-value="code"
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                      clearable
                      dense
                      persistent-hint
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    lg="8"
                    md="8"
                    sm="12"
                    xs="12"
                    class="custom-col mb-0 py-0"
                  >
                    <v-row>
                      <!-- <v-col cols="12" class="custom-col mb-0 pb-0">
                        <span class="mb-2 primary--text font-weight-bold"
                          >สถานะการรับรองรายงาน</span
                        >
                        <v-select
                          :items="jobtrackingstatuses"
                          placeholder="กรุณาระบุสถานะการรับรองรายงาน"
                          v-model="selected_jobtrackingstatuscode"
                          item-value="StatusCode"
                          item-text="StatusName"
                          background-color="input"
                          filled
                          append-icon="mdi-chevron-down"
                          clearable
                          dense
                          persistent-hint
                        ></v-select>
                      </v-col> -->
                      <!-- <v-col class="pb-0">
                        <v-select
                          :items="subjecttypes"
                          placeholder="กรุณาระบุประเภทวิชา"
                          v-model="selectedSubJectTypeId"
                          item-value="subjecttypeid"
                          item-text="subjecttypenameth"
                          background-color="input"
                          filled
                          append-icon="mdi-chevron-down"
                          clearable
                          dense
                          persistent-hint
                          @change="loadStudentMajors()"
                        ></v-select>
                      </v-col> -->
                    </v-row>
                    <!-- <v-row>
                      <v-col class="py-0 my-0">
                        <v-select
                          :items="majors"
                          placeholder="กรุณาระบุสาขาวิชา"
                          v-model="selectedmajorId"
                          item-value="majorid"
                          item-text="majorname"
                          background-color="input"
                          filled
                          append-icon="mdi-chevron-down"
                          clearable
                          dense
                          persistent-hint
                          :disabled="selectedSubJectTypeId ? false : true"
                        ></v-select>
                      </v-col>
                    </v-row> -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row> </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  color=""
                  @click="$router.go(-1)"
                  class="justify-center mr-3"
                  style="border-radius: 10px"
                >
                  ย้อนกลับ</v-btn
                >
                <v-btn
                  dark
                  class="color-btnCard"
                  style="border-radius: 10px"
                  v-on:click="create_reportParameters()"
                  >แสดงรายงาน<v-icon>mdi-logout-variant</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="document_viewer_dialog" width="50%">
      <template>
        <v-card height="auto" class="px-10 py-10">
          <ReportExportFromQueue
            :report_name="report_name"
            :report_parameters="report_parameters"
            @close_Dialog="document_viewer_dialog = false"
          ></ReportExportFromQueue>
        </v-card>
      </template>
    </v-dialog>
  </div>
  <!-- <div class="pa-7">
    <v-row class="d-flex pa-3 pb-4">
      <h3 class="primary--text">
        <i class="fa fa-line-chart"></i>รายงานภาวะการมีงานทำ (รายสถาบันการอาชีวศึกษา)
      </h3>
    </v-row>
    <v-row>
      <v-card class="col-12 pa-4">
        <v-card-title>
          <p>เกณฑ์การค้นหา</p>
        </v-card-title>
        <v-divider class="mt-0 mx-0 mb-4"></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="custom-col mb-0 pb-0">
                <v-select
                  label="สถาบัน"
                  disabled
                  :items="institutes"
                  item-text="institutenameth"
                  item-value="instituteid"
                  v-model="selected_institue_id"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  hint="ปีที่สำเร็จการศึกษา"
                  :rules="[(v) => !!v || 'กรุณาระบุปีที่สำเร็จการศึกษา']"
                  persistent-hint
                  clearable
                  v-model="selectedYearTH"
                  :items="yearTH_Choices"
                  @change="onYearChanged"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  hint="รอบที่"
                  :rules="[(v) => !!v || 'กรุณาระบุรอบ']"
                  persistent-hint
                  v-model="selectedRound"
                  :items="Round_Choices"
                  clearable
                  :disabled="selectedYearTH ? false : true"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  hint="ระดับการศึกษา"
                  persistent-hint
                  item-text="nameth"
                  item-value="code"
                  :items="certificateTypes"
                  clearable
                  v-model="selectedCertificateTypeCode"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  :items="subjecttypes"
                  v-model="selectedSubJectTypeId"
                  hint="ประเภทวิชา"
                  item-value="subjecttypeid"
                  item-text="subjecttypenameth"
                  persistent-hint
                  clearable
                  @change="loadStudentMajors"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="selectedmajorId"
                  :items="majors"
                  hint="สาขาวิชา"
                  item-value="majorid"
                  item-text="majornameth"
                  persistent-hint
                  clearable
                  :disabled="selectedSubJectTypeId ? false : true"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="jobtrackingstatuses"
                  hint="สถานะการรับรองรายงาน"
                  item-value="StatusCode"
                  item-text="StatusName"
                  persistent-hint
                  clearable
                  v-model="selected_jobtrackingstatuscode"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="mt-9">
      <v-col cols="12 text-center">
        <v-btn color="warning" v-on:click="create_reportParameters()">แสดงรายงาน</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="document_viewer_dialog" width="80%">
      <template>
        <v-card height="8000">
          <DocumentViewer
            :key="document_viewer_key"
            :report_name="report_name"
            :report_parameters="report_parameters"
          >
          </DocumentViewer>
        </v-card>
      </template>
    </v-dialog>
  </div> -->
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import DocumentViewer from "../../../components/Report/DocumentViewer.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { InstituteDataProvider } from "@/services/InstituteDataProvider";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import ReportExportFromQueue from "@/components/Report/ReportExportFromQueue.vue";

var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const instituteDataProvider = new InstituteDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report_DetailView",
  props: [],
  data() {
    return {
      document_viewer_key: 1,
      document_viewer_dialog: false,
      report_name:
        "GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_PerInstitute_Report",
      report_parameters: [],
      yearTH_Choices: undefined,
      selectedYearTH: undefined,
      certificateTypes: undefined,
      selectedCertificateTypeCode: "",
      majors: undefined,
      subjecttypes: undefined,
      institutes: undefined,
      selected_institue_id: "",
      schooltypelist: [
        { text: "ภาครัฐ/ภาคเอกชน", value: "" },
        { text: "ภาครัฐ", value: "ภาครัฐ" },
        { text: "ภาคเอกชน", value: "ภาคเอกชน" },
      ],
      valid: true,
      Round_Choices: [],
      selectedRound: "",
      selectedSubJectTypeId: "",
      selectedMajor: [],
      selectedmajorId: "",
      selectedSchoolType: "",
      jobtrackingstatuses: [
        { StatusCode: "Verified", StatusName: "รับรองรายงานแล้ว" },
        { StatusCode: "", StatusName: "ทั้งหมด" },
      ],
      selected_jobtrackingstatuscode: "",
    };
  },
  methods: {
    async initialize() {
      await instituteDataProvider.loadInstitutes().then((result) => {
        this.institutes = result.data;
      });
      await schoolEmployeeProfileDataProvider
        .getCurrentPermissionInstituteIDs()
        .then((result) => {
          this.selected_institue_id = result.data[0];
        });
      await masterDataProvider.getCertificateTypes().then((result) => {
        this.certificateTypes = result.data;
      });
      await majorDataProvider.loadMajors().then((result) => {
        this.majors = result.data;
      });
      await subjectTypeDataProvider.loadSubjectTypes().then((result) => {
        this.subjecttypes = result.data;
      });
      this.loadListOfGraduatedYearFromJobTrackingReport();
    },
    loadStudentMajors() {
      masterDataProvider
        .getStudentMajorsFromSubjectTypeID(this.selectedSubJectTypeId)
        .then((res) => {
          this.selectedMajor = [];
          this.selectedmajorId = "";
          this.majors = res.data;
        });
    },
    onYearChanged() {
      this.loadListOfJobTrackingRoundFromGraduatedYearTH();
    },
    loadListOfGraduatedYearFromJobTrackingReport() {
      commonDataProvider
        .loadListOfGraduatedYearFromJobTrackingReport()
        .then((resp) => {
          this.yearTH_Choices = resp.data;
        });
    },

    loadListOfJobTrackingRoundFromGraduatedYearTH() {
      commonDataProvider
        .loadListOfJobTrackingRoundFromGraduatedYearTH(this.selectedYearTH)
        .then((resp) => {
          this.Round_Choices = resp.data;
        });
    },

    create_reportParameters() {
      if (this.$refs.form.validate()) {
        this.document_viewer_key += 1;
        this.report_parameters = [];

        if (this.selected_institue_id != undefined) {
          this.report_parameters.push(
            "prm_Institue_ID=" + this.selected_institue_id
          );
        }

        if (this.selectedSchoolType != undefined) {
          this.report_parameters.push(
            "prm_SchoolType=" + this.selectedSchoolType
          );
        }
        if (this.selectedYearTH != undefined) {
          this.report_parameters.push("prm_YearTH=" + this.selectedYearTH);
        }
        if (this.selectedRound > 0) {
          this.report_parameters.push("prm_Round=" + this.selectedRound);
        }
        if (this.selectedCertificateTypeCode) {
          this.report_parameters.push(
            "prm_CertificateType=" + this.selectedCertificateTypeCode
          );
        }
        if (this.selected_jobtrackingstatuscode != "") {
          this.report_parameters.push(
            "prm_JobTracking_StatusCode=" + this.selected_jobtrackingstatuscode
          );
        }

        if (this.selectedSubJectTypeId) {
          this.report_parameters.push(
            "prm_SubjectTypeCode=" + this.selectedSubJectTypeId
          );
        }
        if (this.selectedmajorId) {
          this.report_parameters.push("prm_MajorID=" + this.selectedmajorId);
        }
        this.document_viewer_dialog = true;
      }
    },
  },
  watch: {
    tableOptions: {
      handler(newValue, oldValue) {
        this.loadListOfGraduatedYearFromJobTrackingReport();
      },
      deep: true,
    },
  },
  async created() {
    await this.initialize();
  },
  components: { DocumentViewer, ReportExportFromQueue },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.color-btnCard {
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 60%,
    rgba(63, 81, 181, 1) 100%
  );
}
</style>
