<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="10" md="12" sm="12" xs="12">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
              <v-toolbar class="color-HeaderCard" height="50px" flat dark>
                <v-toolbar-title
                  ><v-icon class="mr-2"> mdi-account-circle </v-icon
                  >เพิ่มข้อมูลบุคลากร</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text class="py-6 px-12 d-flex justify-center">
                <v-col cols="10" class="my-7">
                  <v-row class="">
                    <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                    <h3 class="primary--text">ข้อมูลผู้บริหารสถานศึกษา</h3>
                  </v-row>
                  <v-row class="py-2" style="margin-top: 1%; margin-bottom: 2%">
                    <v-divider></v-divider>
                  </v-row>
                  <v-row>
                    <v-col lg="12" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                      <v-autocomplete
                        v-model="selected_schoolCode"
                        item-value="schoolid"
                        item-text="schoolname"
                        :items="schools"
                        background-color="input"
                        filled
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="px-0 py-0">
                    <v-col class="py-0">
                      <v-radio-group
                        :rules="[(v) => !!v || 'กรุณาระบุเพศ']"
                        v-model="vm.gender"
                        row
                      >
                        <template v-slot:label>
                          <div><strong>เพศ</strong></div>
                        </template>
                        <v-radio label="ชาย" color="blue" value="male"></v-radio>
                        <v-radio label="หญิง" color="pink" value="female"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        คำนำหน้าชื่อ<span style="color: red">*</span>
                      </h4>
                      <v-autocomplete
                        v-model="vm.titlenameid"
                        :items="titlenamelist"
                        :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                        placeholder="คำนำหน้าชื่อ"
                        item-text="titlenameth"
                        item-value="titlenameid"
                        background-color="input"
                        append-icon="mdi-chevron-down"
                        required
                        clearable
                        filled
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        ชื่อจริง<span style="color: red">*</span>
                      </h4>
                      <v-text-field
                        :rules="nameRules"
                        v-model="vm.name"
                        placeholder="ชื่อจริง"
                        background-color="input"
                        required
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col lg="4" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        นามสกุล<span style="color: red">*</span>
                      </h4>
                      <v-text-field
                        :rules="nameRules"
                        v-model="vm.lastname"
                        placeholder="นามสกุล"
                        background-color="input"
                        required
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="px-0 py-0">
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        ตำแหน่ง<span style="color: red">*</span>
                      </h4>
                      <v-text-field
                        background-color="input"
                        required
                        filled
                        clearable
                        placeholder="ตำแหน่ง"
                        :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                        v-model="vm.position"
                      ></v-text-field>
                    </v-col>
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        เลขประจำตัวประชาชน<span style="color: red">*</span>
                      </h4>
                      <v-text-field
                        placeholder="เลขประจำตัวประชาชน"
                        :rules="idRules"
                        ref="txtIdentityid"
                        v-model="vm.idcard"
                        background-color="input"
                        required
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0">
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        อีเมล<span style="color: red">*</span>
                      </h4>
                      <v-text-field
                        background-color="input"
                        required
                        filled
                        clearable
                        ref="txtEmail"
                        placeholder="อีเมล"
                        :rules="custom_emailRules"
                        v-model="vm.email"
                      ></v-text-field>
                    </v-col>
                    <v-col lg="6" md="12" sm="12" xs="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">หมายเลขโทรศัพท์</h4>
                      <v-text-field
                        background-color="input"
                        required
                        filled
                        clearable
                        placeholder="หมายเลขโทรศัพท์"
                        :rules="phoneRules"
                        v-model="vm.phonenumber"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                    <h3 class="primary--text">เกี่ยวกับการใช้งาน</h3>
                  </v-row>
                  <v-row class="py-2" style="margin-top: 1%; margin-bottom: 2%">
                    <v-divider></v-divider>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        ชื่อผู้ใช้งาน (Username)<span style="color: red">*</span>
                      </h4>
                      <v-text-field
                        background-color="input"
                        filled
                        clearable
                        ref="txtUsername"
                        placeholder="ชื่อบัญชีผู้ใช้ (username) *"
                        :rules="custom_usernameRules"
                        v-model="vm.username"
                        autocomplete="new-password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        รหัสผ่าน (Password)<span style="color: red">*</span>
                      </h4>
                      <v-text-field
                        background-color="input"
                        filled
                        autocomplete="new-password"
                        v-model="vm.password"
                        name="password"
                        :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="isShowPassword = !isShowPassword"
                        :type="isShowPassword ? 'text' : 'password'"
                        :rules="passwordRules"
                        placeholder="รหัสผ่าน"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="custom-col mb-0 pb-0">
                      <h4 class="primary--text mb-2">
                        ยืนยันรหัสผ่าน (Confirm Password)<span style="color: red">*</span>
                      </h4>
                      <v-text-field
                        background-color="input"
                        filled
                        v-model="vm.confirm_password"
                        :append-icon="isConfirmShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="isConfirmShowPassword = !isConfirmShowPassword"
                        :type="isConfirmShowPassword ? 'text' : 'password'"
                        :rules="[
                          (v) => !!v || 'กรุณายืนยันรหัสผ่าน (Confirmpassword)',
                          (v) => v === vm.password || 'รหัสผ่านไม่ตรง',
                        ]"
                        name="confirmPassword"
                        placeholder="ยืนยันรหัสผ่าน"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>
          </v-form>
          <v-col class="d-flex justify-end px-0">
            <v-btn @click="$router.go(-1)" class="mx-2"> ย้อนกลับ</v-btn>
            <v-btn class="color-button2" @click="save()">
              บันทึก <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  usernameRules,
  passwordRules,
  emailRules,
  phoneRules,
  nameRules,
  idcardRules,
} from "@/ValidationHelper.js";
import { CreateSchoolAdminProfile_Request } from "@/models/Profile/SchoolAdminProfile/CreateSchoolAdminProfile_Request";
import { SchoolAdminProfileDataProvider } from "@/services/Profile/SchoolAdminProfile/SchoolAdminProfileDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolAdminProfileDataProvider = new SchoolAdminProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: { SearchableDataTable },
  name: "CreateSchoolAdmin_SchoolEmployeeProfileDetailView_School_Manager",
  data() {
    return {
      oid: "",
      vm: new CreateSchoolAdminProfile_Request(),
      titlenamelist: [],
      schoolEmployeeProfile_SchoolRights_table_headers: [
        { text: "สถานศึกษา", value: "school.schoolname" },
        {
          text: "จังหวัด",
          value: "school.tambon.district.province.provincethai",
        },
        { text: "", value: "action" , sortable: false},
      ],
      schools: [],
      selected_schoolCode: undefined,
      valid: true,
      roleAndPermission_request: [],
      colorbutton: "success",
      emailRules: emailRules,
      phoneRules: phoneRules,
      passwordRules: passwordRules,
      usernameRules: usernameRules,
      nameRules: nameRules,
      idcardRules: idcardRules,
      isShowPassword: false,
      isConfirmShowPassword: false,
      usernameExistResult: undefined,
      emailExistResult: false,
      identityidExistResult: false,
      idRules: [
        (v) => !!v || "กรุณาระบุเลขประจำตัวประชาชน",
        (v) => this.checkID(v) || "รูปแบบหมายเลขไม่ถูกต้อง",
        (v) => this.identityidExistResult !== true || "หมายเลขที่กรอกไม่ถูกต้อง",
      ],
    };
  },

  methods: {
    async initilize() {
      const request = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(request).then((result) => {
        this.schools = result.data;
      });
      await this.loadTitleName();
      await this.loadSchoolsBy_CurrentApplicationUser();
    },
    async loadSchoolsBy_CurrentApplicationUser() {
      await schoolDataProvider.getSchoolsBy_CurrentApplicationUser().then((result) => {
        this.schools = result.data;
        if (this.schools.length === 1) {
          this.selected_schoolCode = this.schools[0].schoolid;
        }
      });
    },
    async loadTitleName() {
      await masterDataProvider
        .getActiveTitleNames()
        .then((response) => {
          this.titlenamelist = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    validatePassword() {
      if (this.vm.password != this.vm.confirm_password) {
        this.passwordMismatch = true;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (confirm("ต้องการบันทึกหรือไม่?")) {
          await schoolAdminProfileDataProvider
            .createSchoolAdminProfile_School_Manager(this.vm)
            .then((result) => {
              this.$store.dispatch({ type: "doSetIsLoadingProfile" });
              this.$toast.success("บันทึกข้อมูลเรียบร้อย");
              this.$router.push(
                "/Profile/SchoolAdmin_SchoolEmployeeProfileListView_School_Manager"
              );
            })
            .catch((err) => {
              this.$toast.error(err);
            });
        }
      }
    },
    checkID(id) {
      if (id.length !== 13) return false;
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        sum += parseFloat(id.charAt(i)) * (13 - i);
      }
      const test = (11 - (sum % 11)) % 10 === parseFloat(id.charAt(12));
      return test;
    },
  },
  watch: {
    selected_schoolCode(newvalue) {
      if (newvalue) {
        this.vm.schoolid = newvalue;
      }
    },

    "vm.username": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isUsernameUsed(newVal).then((result) => {
            this.usernameExistResult = result.data;
            this.$refs.txtUsername.validate();
          });
        }
      },
      deep: true,
    },
    "vm.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isEmailUsed(newVal).then((result) => {
            this.emailExistResult = result.data;
            this.$refs.txtEmail.validate();
          });
        }
      },
      deep: true,
    },
    "vm.idcard": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isIdentityIDUsed(newVal).then((result) => {
            this.identityidExistResult = result.data;
            this.$refs.txtIdentityid.validate();
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    custom_usernameRules() {
      return [
        ...usernameRules,
        (v) =>
          this.usernameExistResult === false || "ชื่อผู้ใช้งาน (username) ถูกใช้งานแล้ว",
      ];
    },
    custom_emailRules() {
      return [
        ...emailRules,
        (v) => this.emailExistResult === false || "อีเมลนี้ถูกใช้งานแล้ว",
      ];
    },
  },
  async created() {
    await this.initilize();
  },
};
</script>

<style></style>
