import axios from "axios";
import {
  create_default_request_header,
  create_default_uploadfile_request_header,
} from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { CreateEmployerSummaryRequest } from "@/models/EmployerSummaries/CreateEmployerSummaryRequest";
import { UpdateEmployerSummaryRequest } from "@/models/EmployerSummaries/UpdateEmployerSummaryRequest";

export class EmployerSummaryDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  getEmployerSummaryByKeyword(keyword = "") {
    var header = create_default_request_header();
    var endpointUrl =
      "/api/EmployerSummary/GetEmployerSummaryByKeyword/" + keyword;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  getEmployerSummaryByid(id = "") {
    var header = create_default_request_header();
    var endpointUrl = "/api/EmployerSummary/GetEmployerSummaryById/" + id;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  getEmployerSummaryByOid(oid = "") {
    var header = create_default_request_header();
    var endpointUrl = "/api/EmployerSummary/getEmployerSummaryByOid/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  IsIdentityIDUsed(identityID = "") {
    var header = create_default_request_header();
    var endpointUrl = "/api/EmployerSummary/IsIdentityIDUsed/" + identityID;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  getEmployerSummary() {
    var header = create_default_request_header();
    var endpointUrl = "/api/EmployerSummary/getEmployerSummary";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  getEmployerSummaryCount(keyword = "") {
    var request = new Object();
    request.Keyword = keyword;
    var data = JSON.stringify(request);
    var header = create_default_request_header();
    var endpointUrl = "/api/EmployerSummary/getEmployerSummaryCount";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, data, header);
  }

  getEmployerSummaryPagination(keyword, pageNumber, ItemsPerPage,regionAsVEC,provinceID) {
    var request = new Object();
    request.Keyword = keyword;
    request.PageNumber = pageNumber;
    request.ItemsPerPage = ItemsPerPage;
    request.RegionAsVEC = regionAsVEC;
    request.ProvinceID = provinceID;
    var data = JSON.stringify(request);
    var header = create_default_request_header();
    var endpointUrl = "/api/EmployerSummary/getEmployerSummaryPagination";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, data, header);
  }

  createEmployerSummary(request = new CreateEmployerSummaryRequest()) {
    const header = create_default_request_header();
    const endpointUrl = "/api/EmployerSummary/CreateEmployerSummary";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    const data = JSON.stringify(request);
    return axios.post(combinedURL, data, header);
  }

  updateEmployerSummary(request = new UpdateEmployerSummaryRequest()) {
    const header = create_default_request_header();
    const endpointUrl = "/api/EmployerSummary/UpdateEmployerSummary";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    const data = JSON.stringify(request);
    return axios.post(combinedURL, data, header);
  }

  deleteEmployerSummary(oid = "") {
    var header = create_default_request_header();
    var endpointUrl = "/api/EmployerSummary/DeleteEmployerSummary/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  uploadExcelFile(selectedFile) {
    const formData = new FormData();
    formData.append("file", selectedFile);

    var header = create_default_uploadfile_request_header();
    var endpointUrl = "/api/EmployerSummary/UploadExcelFile";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, formData, header);
  }

  VerifyProfileWithDBDOpenAPI(oid) {
    const header = create_default_request_header();
    const endpointUrl = "/api/EmployerProfile/VerifyProfileWithDBDOpenAPI/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  getPublicEmployerInformationByJuristicID(juristicID) {
    const header = create_default_request_header();
    const endpointUrl = "/api/EmployerProfile/GetPublicEmployerInformationByJuristicID/" + juristicID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, null, header);
  }

  isEmployerSummaryUsed(employername = ''){
    if (employername == '') employername = '-';
    const header = create_default_request_header();
    var endpointPath = "/api/EmployerSummary/IsEmployerSummaryUsed/" + employername;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL,null, header);
  }
}
