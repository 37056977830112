<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="caption"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        :rules="rules"
        v-bind="attrs"
        v-on="on"
        suffix="นาฬิกา"
      ></v-text-field>
    </template>
    <v-time-picker
      @change="closeAndUpdateTextFieldDatePicker"
      v-if="menu2"
      v-model="time"
      full-width
      :readonly="readonly"
      format="24hr"
      @click:minute="$refs.menu.save(time)"
      :max="max"
      :min="min"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false
    }
  },
  watch: {
    value: {
      handler(val, oldVal) {
        this.time = val
      }
    }
  },
  props: [
    "caption",
    "value",
    "disabled",
    "readonly",
    "rules",
    "min",
    "max"
  ],
  methods: {
    closeAndUpdateTextFieldDatePicker() {
      this.$emit("closeAndUpdate", this.time)
    }
  },
  mounted() {
    this.time = this.value;
  }
}
</script>

<style></style>
