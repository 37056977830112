<template>
  <!-- <v-parallax
    dark
    src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <h1 class="text-h4 font-weight-thin mb-4">
          Vuetify
        </h1>
        <h4 class="subheading">
          Build your application today!
        </h4>
      </v-col>
    </v-row>
  </v-parallax> -->

  <!-- <v-parallax src="@/assets/parallax-image.jpg"> -->
  <div class="parallax">
    <h1 style="color: white; padding-top: 5%; text-align: center" class="mb-5">
      ข่าวแนะแนว
    </h1>
    <v-container v-if="NewsContent.length">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="item in NewsContent"
          :key="item.oid"
          :data_items="NewsContent"
        >
          <v-card
            style="cursor: pointer; background-color: aliceblue"
            class="mx-auto"
            max-width="340"
            max-height="auto"
          >
            <v-img
              height="230px"
              :src="'data:image/jpeg;base64,' + item.bannerthumbnailbase64"
              cover
              @click="goToNewsDetail(item.oid)"
            ></v-img>

            <v-card-title
              class="three-line-title"
              @click="goToNewsDetail(item.oid)"
            >
              {{ item.title }}
            </v-card-title>
            <v-card-subtitle
              @click="goToNewsDetail(item.oid)"
              class="mt-2 two-line-content"
            >
              {{ item.description }}
            </v-card-subtitle>
            <v-divider :thickness="8" class="border-opacity-75 mt-4 mx-3">
            </v-divider>
            <v-card-text
              @click="goToNewsDetail(item.oid)"
              class="d-flex justify-end mt-0 py-2"
            >
              {{ formatArticleAge(item.createddate) }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <br />
      <v-btn dark @click="goToNewsReadmore"> อ่านเพิ่มเติม >> </v-btn>
    </v-container>
  </div>
</template>

<script>
import { NewsDataProvider } from "@/services/NewsDataProvider";
import moment from "moment";

const newsDataProvider = new NewsDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "VcopNewsRecommend",
  data() {
    return {
      oid: "",
      NewsContent: [],
      itemlength: 0,
    };
  },
  methods: {
    getImageUrl(img) {
      return require(`@/assets/${img}`);
    },
    goToNewsDetail(item) {
      this.$router.push({ name: "VcopNewsDetailView", params: { oid: item } });
    },
    goToNewsReadmore() {
      this.$router.push({ name: "VcopNewsListView" });
    },
    async loadRecommendNews() {
      await newsDataProvider
        .loadRecommendNewsPublishList()
        .then((result) => {
          console.log(result.data);
          this.NewsContent = result.data;
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        });
    },
    formatArticleAge(createdDate) {
      return moment(createdDate).add(543, "years").locale("th-TH").format("LL");
    },
  },
  mounted() {
    this.loadRecommendNews();
  },
};
</script>

<style scoped>
.two-line-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 40px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.three-line-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 115px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  color: #000;
}

.parallax {
  /* The image used */
  background-image: url("@/assets/parallax-image.jpg");

  /* Set a specific height */
  min-height: 850px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(10px);
  
}
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
}
.v-card {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}
.v-img {
  border: solid 4px #fff;
  border-radius: 10px;
}
.v-img:hover {
  cursor: pointer;
}

.v-btn {
  left: 86.5%;
  border: solid 2px rgb(33, 150, 243);
  color: white;
  background-color: #ffffff00;
}
.v-btn:hover {
  background-color: rgb(33, 150, 243);
}
</style>
