import axios from "axios";
import { create_default_request_header,create_formdata_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";

export class EntityTagDataProvider
{
   constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }
  GetTagsOnlyText(){
    let headers = create_default_request_header();
    const endpointUrl = "/api/EntityTag/GetTagsOnlyText";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL , headers);
  }
}