<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row class="d-flex justify-center pt-5">
      <v-col cols="11">
        <v-card style="border-radius: 15px">
          <!-- <h4 class="mt-4 mx-0 mb-4">ข้อมูลนักศึกษา</h4>
                <v-divider></v-divider> -->
          <v-card-text id="element-to-convert">
            <v-row>
              <v-col cols="12 pl-5">
                <div>
                  <v-col class="d-flex align-center justify-center">
                    <v-img
                      alt="test"
                      class="shrink mr-2"
                      contain
                      src="@/assets/nologo.jpg"
                      aspect-ratio="1/1"
                      cover
                    />
                  </v-col>
                </div>
                <v-row>
                  <v-col
                    class="d-flex flex-column align-center pa-2 pt-8 pb-4"
                    cols="12"
                  >
                    <v-row class="">
                      <h2 class="pb-2">
                        {{ job.jobposition }}
                      </h2>
                    </v-row>
                    <v-row class="">
                      <label>{{ job.companyname }}</label>
                    </v-row>
                    <v-row class="">
                      <div style="font-style: italic">
                        <v-icon>mdi-map-marker</v-icon
                        ><label>{{ job.provincethai }}</label>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
                <br />
                <v-col>
                  <v-divider
                    :thickness="3"
                    class="border-opacity-75"
                    color="secondary"
                  >
                  </v-divider>
                </v-col>

                <br/>
                <v-row>
                  <v-col class="d-flex flex-column align-end" cols="3">
                    <v-row class="pa-1 ">
                      <label>อัตราที่รับสมัคร :</label>
                    </v-row>
                    <v-row class="pa-1">
                      <label>ลักษณะงาน :</label>
                    </v-row>
                    <v-row class="pa-1">
                      <label>วิธีการรับสมัครของสถานประกอบการ :</label>
                    </v-row>
                    <v-row class="pa-1">
                      <label>เงินเดือน :</label>
                    </v-row>
                  </v-col>

                  <v-col cols="9">
                    <v-row class="pa-1 ">
                      <label>{{ job.quantity_of_position }}</label>
                    </v-row>
                    <v-row class="pa-1">
                      <label>{{ typejob }}</label>
                    </v-row>
                    <v-row class="pa-1">
                      <label>{{ Howtoapply }}</label>
                    </v-row>
                    <v-row class="pa-1">
                      <label>{{ job.salary }}</label>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <div
              style="
                background-color: rgba(63, 81, 181, 0.8);
                color: white;
                border-radius: 20px;
                padding: 5px 10px;
                font-size: 16px;
                margin: 40px 0px 30px;
              "
              class="text-center"
            >
              คุณสมบัติผู้สมัคร
            </div>
            <v-row>
              <v-col class="d-flex flex-column align-end ml-2" cols="3">
                <v-row class="pa-1 pt-2">
                  <label>เพศ :</label>
                </v-row>
                <v-row class="pa-1">
                  <label>อายุ :</label>
                </v-row>
                <v-row class="pa-1">
                  <label>ระดับการศึกษา :</label>
                </v-row>
                <v-row class="pa-1">
                  <label>ประสบการณ์ :</label>
                </v-row>
                <v-row class="pa-1">
                  <label>อื่นๆ :</label>
                </v-row>
              </v-col>

              <v-col cols="8">
                <v-row class="pa-1 pt-2">
                  <label>{{ gender }}</label>
                </v-row>
                <v-row class="pa-1">
                  <label>{{ age }}</label>
                </v-row>
                <v-row class="pa-1">
                  <label>{{ level }}</label>
                </v-row>
                <v-row class="pa-1">
                  <label>{{ experience }}</label>
                </v-row>
                <v-row class="pa-1">
                  <label>{{ etc }}</label>
                </v-row>
              </v-col>
            </v-row>

            <div
              style="
                background-color: rgba(63, 81, 181, 0.8);
                color: white;
                border-radius: 20px;
                padding: 5px 10px;
                font-size: 16px;
                margin: 40px 0px 30px;
              "
              class="text-center"
            >
              หน้าที่และรายละเอียดของงาน
            </div>
            <v-row>
              <v-col>
                <v-row class="pa-2">
                  <div class="d-flex flex-column pl-4">
                    <label class=""> {{ detailsjob }} </label>
                  </div>
                </v-row>
              </v-col>
            </v-row>

            <div class="html2pdf__page-break"></div>
            <div
              style="
                background-color: rgba(63, 81, 181, 0.8);
                color: white;
                border-radius: 20px;
                padding: 5px 10px;
                font-size: 16px;
                margin: 40px 0px 30px;
              "
              class="text-center"
            >
              สวัสดิการ
            </div>
            <v-row>
              <v-col>
                <v-row class="benefit pa-2 d-flex flex-column pl-5">
                  {{ benefit1 }} <br />
                  {{ benefit2 }} <br />
                  {{ benefit3 }} <br />
                  {{ benefit4 }} <br />
                  {{ benefit5 }} <br />
                  {{ benefit6 }} <br />
                  {{ benefit7 }} <br />
                  {{ benefit8 }} <br />
                  {{ benefit9 }} <br />
                  {{ benefit10 }} <br />
                  {{ benefit11 }} <br />
                  {{ benefit12 }} <br />
                  {{ benefit13 }} <br />
                </v-row>
              </v-col>
            </v-row>

            
            <div
              style="
                background-color: rgba(63, 81, 181, 0.8);
                color: white;
                border-radius: 20px;
                padding: 5px 10px;
                font-size: 16px;
                margin: 40px 0px 30px;
              "
              class="text-center"
            >
              ข้อมูลติดต่อ
            </div>

            <v-row>
              <v-col class="d-flex flex-column align-center pb-7" cols="12">
                <v-row class="pa-1 pt-2">
                  <label>{{ employer }}</label>
                </v-row>
                <v-row v-if="address != ''" class="pa-1 pt-2">
                  <label>{{ address }}</label>
                </v-row>
                <v-row class="pa-1">
                  <label>tel : {{ tel }}</label>
                </v-row>
                <v-row class="pa-1">
                  <label>email : {{ email }}</label>
                </v-row>
                <v-row class="pa-1">
                  <a :href="link">{{ link }}</a>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row class="justify-center text-center mt-4">
          <v-col cols="12">
            <v-btn
              icon
              dark
              color="red"
              @click="toggleFavorite"
              :class="{ 'is-favorite': isFavorite }"
            >
              <v-icon v-if="isFavorite">mdi-heart</v-icon>
              <v-icon v-else>mdi-heart-outline</v-icon> </v-btn
            ><span class="">บันทึกเข้าแฟ้ม</span>
            <!-- <div style="cursor:pointer; color: blue;">
                  <v-icon color="primary" class="mr-2">mdi-printer</v-icon>print
                </div> -->
            <v-btn icon dark color="primary" @click="print" class="ma-2 ml-10">
              <v-icon class="mr-2">mdi-printer</v-icon>print
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="justify-center text-center mb-4">
          <v-col cols="12">
            <v-btn dark color="primary" @click="confirm_dialog = true" class="ma-2">
              <v-icon class="mr-2">mdi-email</v-icon>
              สมัครงาน
            </v-btn>
            <v-btn
              color=""
              @click="$router.go(-1)"
              class="ma-2"
            >
              ย้อนกลับ</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="confirm_dialog" width="400">
        <template>
          <v-card class="pa-6">
            <v-row class="justify-center text-center">
              <v-card-title> ยืนยันการสมัครงาน ? </v-card-title>
              <v-col cols="12">
                <v-btn @click="onSubmitButtonClick()" class="ma-2" color="success">
                  <v-icon class="mr-2">mdi-check-circle-outline</v-icon>ยืนยัน
                </v-btn>
                <v-btn @click="confirm_dialog = false" class="ma-2" color="" elevation="2">ยกเลิก</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-dialog>
  </v-form>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "SearchJobDetails",
  data() {
    return {
      job: "",
      isFavorite: false,
      position: "ช่างยนต์ ออโตคลิก แยกสี่กอ กะทู้",
      employer: "บริษัท ออโตคอร์ป โฮลดิ้ง จำกัด (มหาชน)",
      province: "ภูเก็ต",
      recruitmentrate: "3",
      typejob: "งานประจำ (Full Time)",
      Howtoapply: "ติดต่อสถานประกอบการ",
      salary: "12,000++",
      gender: "ชาย",
      age: "ไม่เกิน 30 ปี",
      level: "ปวช. ขึ้นไป สาขา ช่างยนต์, เทคนิคยานยนต์",
      experience: "มีประสบการณ์ฝึกงาน หรือ ทำงาน ซ่อมรถยนต์ จะพิจารณาเป็นพิเศษ",
      etc: "มีใบอนุญาตขับขี่รถยนต์",
      tel: "043306333",
      email: "hr-team@ach.co.th",
      phone: "0620080797",
      address: "",
      link: "https://www.autocorpgroup.com/en",
      detailsjob:
        "ให้บริการซ่อมรถยนต์ของลูกค้าที่เข้ามารับบริการ ตามใบสั่งซ่อม",
      benefit1: "- ประกันสังคม",
      benefit2: "- เบี้ยขยัน",
      benefit3: "- คอมมิชชั่น",
      benefit4: "- เครื่องแบบพนักงาน",
      benefit5: "- กองทุนสำรองเลี้ยงชีพ",
      benefit6: "- ตรวจสุขภาพประจำปี",
      benefit7: "- เงินยินดีมงคลสมรส",
      benefit8: "- เงินอนุโมทนาอุปสมบท",
      benefit9: "- เงินช่วยเหลือฌาปนกิจ",
      benefit10: "- ประกันอุบัติเหตุ",
      benefit11: "- ประกันสุขภาพ",
      benefit12: "- เบี้ยเลี้ยงเดินทางปฏิบัติงาน",
      benefit13: "- ส่วนลดสำหรับพนักงาน",
      confirm_dialog: false,
    };
  },
  methods: {
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
    },
    print() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: "JobDetails.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        enableLinks: true,
        pagebreak: { mode: ["css", "legacy"] },
      });
    },
  },
  async created() {
    this.job = this.$route.params.jobdata;
  },
};
</script>

<style scoped>
.shrink {
  width: 350px;
  height: 180px;
  /* box-shadow: 0 0 10px rgba(15, 15, 15, 0.5);
  border: 1px solid grey; */
}

label, .benefit, a{
  font-size: 16px;
}
</style>
};
</script>

<style></style>
