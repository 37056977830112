<template>
  <div>
    <v-col>
      <v-switch
        @change="OnPostJobStatusChange"
        v-model="postjobstatus"
        :label="`สถานะการประกาศหางาน: ${postjobstatus ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}`"
      >
      </v-switch>
    </v-col>
    <v-col class="mt-0 pt-0">
      <span style="color: red"
        >*สถานะการประกาศหางาน หมายถึง การอนุญาตและยินยอมให้สถานประกอบการสามารถสืบค้นเพื่อเข้าถึงข้อมูลส่วนบุคคลของผู้ประกาศหางาน ตามที่จัดเก็บอยู่ในฐานข้อมูลของระบบ เพื่อนำไปใช้ในการติดต่อและพิจารณาคุณสมบัติในการรับสมัครงานเท่านั้น</span
      >
    </v-col>
  </div>
</template>

<script>
import { PostLookingForJobDataProvider } from "@/services/StudentAction/PostLookingForJobDataProvider";
var postLookingForJobDataProvider = new PostLookingForJobDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  props: ["student_oid", "formRef"],
  data() {
    return {
      postjobstatus: false,
    };
  },
  methods: {
    OnPostJobStatusChange() {
      // ตรวจสอบฟอร์มก่อนที่จะทำการอัปเดตสถานะ
      let validate = this.formRef.validate();
      if (validate) {
        if (this.postjobstatus) {
          postLookingForJobDataProvider
            .enablePostStudentDetail(this.student_oid)
            .then(() => {
              this.$emit("onupdatepost");
            })
            .catch((err) => {
              this.postjobstatus = !this.postjobstatus;
            });
        } else {
          postLookingForJobDataProvider
            .disablePostStudentDetail(this.student_oid)
            .then(() => {
              this.$emit("onupdatepost");
            })
            .catch((err) => {
              this.postjobstatus = !this.postjobstatus;
            });
        }
      } else {
        this.$toast.error("กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน");
        this.postjobstatus = false;
      }
    },
    async loadPostedJobStatus() {
      postLookingForJobDataProvider
        .loadPostLookingForJobStatus(this.student_oid)
        .then((result) => {
          this.postjobstatus = result.data;
        })
        .catch((err) => {
          this.postjobstatus = false;
        });
    },
  },
  mounted() {
    this.loadPostedJobStatus();
  },
};
</script>

<style></style>
