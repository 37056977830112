import axios from "axios";
import { create_default_request_header } from "@/services/constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { UpdateJobSegment_Request } from '@/models/JobSegment/UpdateJobSegment_Request';
import { CreateJobSegment_Request } from '@/models/JobSegment/CreateJobSegment_Request';
export class JobSegmentDataProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }
  getJobSegmentList() {
    var header = create_default_request_header();
    var endpointUrl = "/api/JobSegment/GetJobSegments";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }
  getJobSegmentById(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/JobSegment/GetJobSegmentById/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  createJobSegment(vm = new CreateJobSegment_Request()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/JobSegment/CreateJobSegment";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  updateJobSegment(vm = new UpdateJobSegment_Request()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/JobSegment/UpdateJobSegment";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.put(combinedURL, vm, header);
  }

  deleteJobSegment(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/JobSegment/DeleteJobSegment/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.delete(combinedURL, header);
  }
  
}
