import VcopNewsListView from "@/views/VcopCMS/VcopNewsListView.vue";
import VcopNewsDetailView from "@/views/VcopCMS/VcopNewsDetailView.vue";
import VcopNewsRecommend from "@/views/VcopCMS/VcopNewsRecommend.vue"
import VcopCarouselNews from "@/views/VcopCMS/VcopCarouselNews.vue"
export default [
  {
    path: "/News",
    component: () => import("@/layouts/vcop/VcopTemplate.vue"),
    children: [
      {
        path: "/News/VcopNewsListView",
        name: "VcopNewsListView",
        component: VcopNewsListView,
      },
      {
        path: "/News/VcopNewsDetailView/:oid",
        name: "VcopNewsDetailView",
        component: VcopNewsDetailView,
      },
    ],
  },
  {
    path: "/News",
    component: () => import("@/layouts/vcop/Content.vue"),
    children: [
      {
        path: "/News/VcopNewsRecommend",
        name: "VcopNewsRecommend",
        component: VcopNewsRecommend,
      },
      
    ],
  },
  {
    path: "/News",
    component: () => import("@/layouts/vcop/VcopTemplate.vue"),
    children: [
      {
        path: '/News/VcopCarouselNews',
        name: 'VcopCarouselNews',
        component: VcopCarouselNews,
      },
    ],
  },
];
