<template>
  <div>
    <template>
      <v-btn :disabled="isImportDisable" @click="dialog = true" color="info">
        <v-icon>mdi-database-import</v-icon>
        นำเข้าข้อมูลนักศึกษา
      </v-btn>
    </template>
    <v-dialog v-model="dialog" max-width="650px">
      <template>
        <v-card class="pa-8">
          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12" class="d-flex justify-center">
                <h3>
                  <v-icon>mdi-database-import</v-icon>
                  เริ่มการนำเข้าข้อมูลนักศึกษาที่สำเร็จการศึกษาให้กับทุกสถานศึกษา
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn @click="onCancelClick">ยกเลิก</v-btn>
                <v-btn @click="onConfirmClick" class="ml-2 color-button2">
                  ยืนยัน <v-icon>mdi-check-circle-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
const graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
export default {
  name: "BtnStartImportStudentDetailsFromGraduatedStudentJobTrackingMain",
  props: ["graduatedStudentJobTrackingReportMain"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isImportDisable() {
      if (this.graduatedStudentJobTrackingReportMain == undefined) {
        return true;
      }
      if (
        this.graduatedStudentJobTrackingReportMain
          .isimportstudentprocessstarted == true
      ) {
        return true;
      }
      if (
        this.graduatedStudentJobTrackingReportMain
          .importstudentdata_status_statuscode !=
        "WaitingToStartImportingProcess"
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async navigateToGraduatedStudentJobTrackingReportMainListView() {
      this.$router.push(
        "GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainListView"
      );
    },
    async onConfirmClick() {
      this.$store.dispatch("showLoading");
      await graduatedStudentJobTrackingReportReportDataProvider
        .importStudentDetailsFromGraduatedStudentJobTrackingMain(
          this.graduatedStudentJobTrackingReportMain.oid
        )
        .then((result) => {
          this.dialog = false;
          this.$toast.success("เริ่มกระบวนการนำเข้าข้อมูลแล้ว");
          this.$emit("start_importstudentdata_completed");
        })
        .catch((err) => {})
        .finally(() => this.$store.dispatch("hideLoading"));
    },
    async onCancelClick() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
