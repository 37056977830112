<template>
  <div class="d-flex justify-center">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-container v-if="employerProfile" class="ma-4">
      <v-row class="d-flex justify-end">
        <v-btn class="mx-3 color-buton" dark @click="checkEmployerProfile"
          >ตรวจสอบข้อมูล
          <v-img
            alt="VEC Logo"
            class="shrink ml-2"
            contain
            src="@/assets/DBDlogo.png"
            transition="scale-transition"
            width="40"
        /></v-btn>
      </v-row>
      <v-row>
        <v-col cols="12" lg="9" md="12" sm="12" xs="12">
          <v-card class="fill-height">
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >ข้อมูลสถานประกอบการ</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="pa-7 pl-15">
              <v-row class="align-start">
                <v-col class="mt-0 align-center" cols="12" lg="2">
                  <div v-if="logo_photobase64">
                    <v-row
                      class="d-flex text-center"
                      style="width: 109px; height: 109px; margin: auto"
                    >
                      <v-img
                        width="80px"
                        class="rounded-circle"
                        contain
                        v-bind:lazy-src="
                          'data:image/jpg;base64,' + logo_photobase64
                        "
                        v-bind:src="'data:image/jpg;base64,' + logo_photobase64"
                      >
                      </v-img>
                    </v-row>
                  </div>

                  <div v-else>
                    <v-row
                      class="d-flex text-center"
                      style="width: 109px; height: 109px; margin: auto"
                    >
                      <v-img
                        src="../../assets/user-img.jpg"
                        width="80px"
                        class="rounded-circle"
                      ></v-img
                    ></v-row>
                  </div>
                  <v-row class="d-flex justify-center ma-0 pa-0">
                    <v-btn
                      dark
                      @click="openFileInput"
                      prepend-icon="mdi-image-plus"
                      dense
                      class="ma-5 color-buton"
                      small
                    >
                      อัปโหลดรูปภาพ
                    </v-btn>
                    <input
                      type="file"
                      ref="fileInput"
                      accept="image/png, image/jpeg"
                      style="display: none"
                      @change="onlogoPhotoChange"
                    />
                    <!-- <v-file-input
                        accept="image/png, image/jpeg"
                        label="คลิกเพื่อเปลี่ยน"
                        @change="onlogoPhotoChange"
                        v-model="logoFiles"
                      ></v-file-input> -->
                  </v-row>
                </v-col>
                <v-col cols="12" lg="5">
                  <v-row class="mt-lg-5 justify-center justify-lg-start">
                    <h3>{{ vm.nameth }}</h3>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="5" class="mt-0">
                  <v-row class="d-flex align-center justify-end">
                    <h5 class="mr-3">ข้อมูล ณ วันที่ {{ dbdUpdateDate }}</h5>

                    <v-chip
                      label
                      dark
                      v-if="vm.dbd_organizationjuristicstatus"
                      :color="chipcard_status.color"
                      :text-color="chipcard_status.textcolor"
                    >
                      {{ vm.dbd_organizationjuristicstatus }}
                    </v-chip>
                  </v-row>
                  <v-row class="d-flex justify-lg-end justify-center mt-5">
                    <v-card color="#E8E8E8" class="pa-2">
                      <v-row class="align-center" no-gutters>
                        <v-col>
                          <span class="primary--text float-start"
                            >ข้อมูลจาก</span
                          >

                          <v-img
                            alt="VEC Logo"
                            class="float-start ml-2"
                            contain
                            src="@/assets/DBDlogo.png"
                            transition="scale-transition"
                            width="50"
                          />
                          &nbsp;<span
                            class="d-lg-block d-xs-block primary--text"
                          >
                            กรมพัฒนาธุรกิจการค้า</span
                          >
                        </v-col>
                      </v-row>
                    </v-card></v-row
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <span>ประเภทสถานประกอบการ :</span>
                  <h5
                    v-if="employerProfile.employerprofiletype"
                    class="primary--text"
                  >
                    {{ employerProfile.employerprofiletype.nameth }}
                  </h5>
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <span>หมายเลขบัตรประชาชน / นิติบุคคล :</span>
                  <h5 class="primary--text">
                    {{ employerProfile.identityid }}
                  </h5>
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <span>วันที่จดทะเบียนนิติบุคคลกรมพัฒฯ :</span>
                  <h5 class="primary--text">
                    {{
                      employerProfile.dbd_organizationjuristicregisterdate
                        ? employerProfile.dbd_organizationjuristicregisterdate
                        : "-"
                    }}
                  </h5>
                </v-col>

                <v-col cols="12" lg="4" md="6" sm="12">
                  <span>ประเภทวิสาหกิจ :</span>
                  <h5 v-if="employerProfile.businesstype" class="primary--text">
                    {{ employerProfile.businesstype.nameth }}
                  </h5>
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <span>ประเภทธุรกิจ :</span>
                  <h5 v-if="employerProfile.businesstype" class="primary--text">
                    {{ employerProfile.businesstype.nameth }}
                  </h5>
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <span>วัตถุประสงค์ :</span>
                  <h5 class="primary--text">
                    {{
                      vm.dbd_juristicobjectivetextth
                        ? vm.dbd_juristicobjectivetextth
                        : "-"
                    }}
                  </h5>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="3" md="12" sm="12" xs="12">
          <v-card class="fill-height">
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >ความสมบูรณ์</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="mt-5">
              <v-row class="d-flex justify-center align-center">
                <v-col class="text-center">
                  <CompletionPercentage
                    v-if="this.completionPercentage"
                    :percen="this.completionPercentage"
                  >
                  </CompletionPercentage>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center align-center mt-2">
                <v-col class="text-center">
                  แก้ไขล่าสุด : {{ formattedLastUpdatedDate }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col lg="12" md="12" sm="12" xs="12">
          <v-card v-if="!detailEmployer" class="px-15">
            <v-card-text>
              <v-row class="d-flex justify-space-between">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="px-0 pb-0"
                  align-self="center"
                >
                  <h4 class="primary--text">
                    <v-icon color="primary" class="mr-2">
                      mdi-account-box </v-icon
                    >รายละเอียด
                  </h4>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="d-flex justify-end px-0 pb-0"
                >
                  <v-btn
                    text
                    class="primary--text px-0"
                    @click="detailEmployer = true"
                    >แก้ไขข้อมูล<v-icon color="primary">
                      mdi-border-color
                    </v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-divider :thickness="3" class="border-opacity-75">
                </v-divider>
              </v-row>

              <v-row class="mt-7">
                <h4 class="primary--text">
                  รายละเอียดสถานประกอบการ (ลักษณะงาน)
                </h4>
              </v-row>
              <v-row>
                {{
                  employerProfile.description
                    ? employerProfile.description
                    : "-"
                }}
              </v-row>

              <v-row class="mt-5">
                <v-col cols="12" class="ql-editor1 px-0">
                  <h4 class="primary--text" style="font-size: 14px">
                    สวัสดิการ
                  </h4>
                  <label style="font-size: 14px">
                    {{
                      employerProfile.benefitdescription
                        ? employerProfile.benefitdescription
                        : "-"
                    }}
                  </label>
                </v-col>
              </v-row>

              <v-row class="d-flex justify-space-between mt-10">
                <v-col cols="12" lg="3" sm="6" xs="12" class="px-0">
                  <h4 class="primary--text">หมายเลขประกันสังคม</h4>
                  <span>{{ employerProfile.socialsecuritynumber }}</span>
                </v-col>
                <v-col cols="12" lg="3" sm="6" xs="12" class="px-0">
                  <h4 class="primary--text">ผู้ติดต่อ / ผู้ประสานงาน</h4>
                  <span
                    >{{ employerProfile.coordinator_titlename.titlenameth }}
                    {{ employerProfile.coordinator_firstname }}
                    {{ employerProfile.coordinator_lastname }}</span
                  >
                </v-col>
                <v-col cols="12" lg="3" sm="6" xs="12" class="px-0">
                  <h4 class="primary--text">เว็บไซต์</h4>
                  <span>{{
                    employerProfile.websiteurl
                      ? employerProfile.websiteurl
                      : "-"
                  }}</span>
                </v-col>
                <v-col cols="12" lg="3" sm="6" xs="12" class="px-0">
                  <h4 class="primary--text">อีเมล</h4>
                  <span>{{
                    employerProfile.email ? employerProfile.email : "-"
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-divider :thickness="3" class="border-opacity-75">
                </v-divider>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="12" class="px-0">
                  <h4 class="primary--text">ที่อยู่</h4>
                  <span>{{
                    employerProfile.addressno ? employerProfile.addressno : "-"
                  }}</span>
                </v-col>
              </v-row>

              <v-row class="d-flex justify-space-between mt-10">
                <v-col cols="12" lg="3" sm="6" xs="12" class="px-0">
                  <h4 class="primary--text">แขวง/ตำบล</h4>
                  <span>{{ employerProfile.tambon.tambonthaishort }}</span>
                </v-col>
                <v-col cols="12" lg="3" sm="6" xs="12" class="px-0">
                  <h4 class="primary--text">อำเภอ/เขต</h4>
                  <span>{{ employerProfile.district.districtthaishort }}</span>
                </v-col>
                <v-col cols="12" lg="3" sm="6" xs="12" class="px-0">
                  <h4 class="primary--text">จังหวัด</h4>
                  <span>{{ employerProfile.province.provincethai }}</span>
                </v-col>
                <v-col cols="12" lg="3" sm="6" xs="12" class="px-0">
                  <h4 class="primary--text">รหัสไปรษณีย์</h4>
                  <span>{{ employerProfile.tambon.postcodemain }}</span>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="12" class="px-0">
                  <h4 class="primary--text">ผู้ประสานงานของสถานศึกษา</h4>
                </v-col>
              </v-row>
              <SearchableDataTable
                class="mt-2 custom-header-gradient"
                table_caption="ผู้ประสานงานของสถานศึกษา"
                :data_items="vm.employerschoolcontactrequests"
                :datatable_headers="
                  vm_employerschoolcontactrequests_data_table_headers
                "
              >
                <template v-slot:item.action="{ item }">
                  <v-tooltip top color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="deleteContact(item)"
                        color="error"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>ลบ</span>
                  </v-tooltip>
                </template>
              </SearchableDataTable>
              <v-row class="d-flex justify-space-between mt-10">
                <v-col lg="6" md="12" sm="12" xs="12" class="px-0">
                  <v-row class="px-3 py-3">
                    <v-col lg="12" md="12" sm="12" xs="12" class="px-0">
                      <v-col lg="6" md="12" sm="12" xs="12" class="px-0">
                        <h4 class="primary--text">แผนที่</h4>
                        <p></p>
                      </v-col>
                      <v-card
                        style="
                          height: 314px;
                          width: 100%;
                          background-color: #f2f4f8;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          overflow: hidden;
                        "
                      >
                        <v-img
                          style="width: 100%; height: 315px"
                          contain
                          :lazy-src="
                            'data:image/jpg;base64,' + location_photobase64
                          "
                          :src="'data:image/jpg;base64,' + location_photobase64"
                        ></v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col lg="6" md="12" sm="12" xs="12" class="px-0">
                  <v-row class="px-3 py-3">
                    <v-col lg="12" md="12" sm="12" xs="12">
                      <v-row>
                        <v-col lg="6" md="12" sm="12" xs="12">
                          <h4 class="primary--text">ละติจูด</h4>
                          <p>
                            {{
                              vm.location_latitude ? vm.location_latitude : "-"
                            }}
                          </p>
                        </v-col>
                        <v-col lg="6" md="12" sm="12" xs="12">
                          <h4 class="primary--text">ลองจิจูด</h4>
                          <p>
                            {{
                              vm.location_longtitude
                                ? vm.location_longtitude
                                : "-"
                            }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-card
                        style="
                          height: 314px;
                          width: 100%;
                          background-color: #f2f4f8;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <MarkerGmap
                          @OnMarkedMap="OnMarkMapComplete"
                        ></MarkerGmap>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-else class="px-15">
            <v-card-text>
              <v-row class="mt-2 ml-1">
                <h3>แก้ไขข้อมูล</h3>
              </v-row>

              <v-tabs
                class="mt-10"
                v-model="tab"
                active-class="active-tab white--text"
                grow
                slider-size="10"
                center-active
              >
                <v-tabs-slider class="custom-slider"></v-tabs-slider>
                <v-tab class="z-index-10" :ripple="false"
                  ><v-icon class="mr-2">mdi-account-box</v-icon
                  >ข้อมูลทั่วไป</v-tab
                >
                <v-tab class="z-index-10" :ripple="false"
                  ><v-icon class="mr-2">mdi-account-multiple</v-icon>ผู้ติดต่อ /
                  ผู้ประสานงาน</v-tab
                >
                <v-tab class="z-index-10" :ripple="false"
                  ><v-icon class="mr-2">mdi-file-document</v-icon
                  >ข้อมูลการจดทะเบียน</v-tab
                >
                <v-tab class="z-index-10" :ripple="false"
                  ><v-icon class="mr-2">mdi-google-maps</v-icon
                  >สถานที่ตั้ง</v-tab
                >
              </v-tabs>

              <v-divider :thickness="8" class="border-opacity-75"> </v-divider>

              <v-tabs-items v-model="tab">
                <v-tab-item class="my-5">
                  <v-form ref="tabForm1">
                    <v-row class="mt-2">
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">
                          ชื่อบัญชีผู้ใช้ (Username)
                        </h4>
                        <v-text-field
                          readonly
                          background-color="input"
                          filled
                          label="Username"
                          v-model="vm.username"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">ชื่อสถานประกอบการ</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          label="ชื่อสถานประกอบการ"
                          :rules="[(v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ']"
                          v-model="vm.nameth"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">ประเภทสถานประกอบการ</h4>
                        <v-select
                          filled
                          background-color="input"
                          :rules="[
                            (v) => !!v || 'กรุณาระบุประเภทสถานประกอบการ',
                          ]"
                          label="ประเภทสถานประกอบการ"
                          :items="employerprofiletypes"
                          item-value="code"
                          item-text="nameth"
                          clearable
                          v-model="vm.selected_employerprofiletypecode"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">
                          เลขประจำตัวประชาชน/เลขนิติบุคคล
                        </h4>
                        <v-text-field
                          background-color="input"
                          filled
                          clearable
                          v-model="vm.identityid"
                          :rules="identityidRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">อีเมล</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          label="อีเมล"
                          ref="txtEmail"
                          v-model="vm.email"
                          :rules="[(v) => !!v || 'กรุณาระบุอีเมล']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">ประเภทวิสาหกิจ</h4>
                        <v-select
                          filled
                          background-color="input"
                          label="ประเภทวิสาหกิจ"
                          :rules="[(v) => !!v || 'กรุณาระบุประเภทวิสาหกิจ']"
                          :items="employerMainBusinessTypes"
                          item-value="code"
                          item-text="nameth"
                          v-model="vm.selected_employermainbusinesstypecode"
                          @change="loadEmployerBusinessTypes"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">ประเภทธุรกิจ</h4>
                        <v-select
                          filled
                          background-color="input"
                          label="ประเภทธุรกิจ"
                          :rules="[(v) => !!v || 'กรุณาระบุประเภทประเภทธุรกิจ']"
                          :items="employerBusinessTypes"
                          item-value="code"
                          item-text="nameth"
                          v-model="vm.selected_employerbussinesstypecode"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">
                          รายละเอียดสถานประกอบการ (ลักษณะงาน)
                        </h4>
                        <v-textarea
                          filled
                          background-color="input"
                          :rules="[
                            (v) => !!v || 'กรุณาระบุรายละเอียดสถานประกอบการ',
                          ]"
                          label="รายละเอียดสถานประกอบการ (ลักษณะงาน)"
                          v-model="vm.description"
                        >
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">สวัสดิการ</h4>
                        <v-textarea
                          filled
                          background-color="input"
                          label="สวัสดิการ"
                          v-model="vm.benefit_description"
                          :rules="[(v) => !!v || 'กรุณาระบุสวัสดิการ']"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">หมายเลขประกันสังคม</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          label="หมายเลขประกันสังคม"
                          v-model="vm.social_security_number"
                          :rules="security_numberRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">เว็บไซต์</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          label="เว็บไซต์"
                          v-model="vm.websiteurl"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      <v-col class="my-0 py-0" cols="12">
                        <h4 class="primary--text mb-2">รูปแบบการรับสมัคร</h4>
                        <v-checkbox
                          class="ml-3 my-0 py-0"
                          label="เปิดรับสมัครทาง online"
                          v-model="vm.isopenforonlineapplicant"
                        ></v-checkbox>
                        <v-checkbox
                          class="ml-3 my-0 py-0"
                          label="ผู้สมัครงานดำเนินการสมัครได้ด้วยตนเอง หรือส่งใบสมัครทางไปรษณีย์"
                          v-model="
                            vm.isrequiredjobapplicanttoregisterthemselforviapost
                          "
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row class="mt-4">
                      <v-col cols="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">ที่อยู่</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          clearable
                          label="ที่อยู่"
                          v-model="vm.address_no"
                          :rules="[(v) => !!v || 'กรุณาระบุที่อยู่']"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      <v-col cols="12" lg="" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">จังหวัด</h4>
                        <v-autocomplete
                          filled
                          background-color="input"
                          clearable
                          label="จังหวัด"
                          :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                          :items="provinces"
                          item-text="provincethai"
                          item-value="provinceid"
                          @change="loadDistricts"
                          v-model="vm.selected_province_id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="3" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">อำเภอ</h4>
                        <v-autocomplete
                          filled
                          background-color="input"
                          clearable
                          label="อำเภอ"
                          :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                          :items="districts"
                          item-text="districtthai"
                          item-value="districtid"
                          @change="loadTambons"
                          v-model="vm.selected_district_id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="3" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">ตำบล</h4>
                        <v-select
                          filled
                          background-color="input"
                          clearable
                          label="ตำบล"
                          :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                          :items="tambons"
                          item-text="tambonthai"
                          item-value="tambonid"
                          v-model="vm.selected_tambon_id"
                          @change="onTambonSelected"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="3" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">รหัสไปรษณีย์</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          clearable
                          label="รหัสไปรษณีย์"
                          readonly
                          v-model="postcode"
                        ></v-text-field>
                      </v-col>
                    </v-row> </v-form
                ></v-tab-item>
                <v-tab-item class="my-5">
                  <v-form ref="tabForm2">
                    <v-row class="mt-5">
                      <v-col cols="12" lg="3" md="3" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">คำนำหน้าชื่อ</h4>
                        <v-select
                          filled
                          background-color="input"
                          label="คำนำหน้าชื่อ"
                          :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                          :items="titlename"
                          item-text="titlenameth"
                          item-value="titlenameid"
                          v-model="vm.coordinator_titlename_id"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="4" md="4" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">ชื่อจริง</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          clearable
                          label="ชื่อจริง"
                          :rules="nameRules"
                          v-model="vm.coordinator_firstname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="5" md="5" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">นามสกุล</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          clearable
                          label="นามสกุล"
                          :rules="nameRules"
                          v-model="vm.coordinator_lastname"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">อีเมล</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          clearable
                          label="อีเมล"
                          :rules="emailRules"
                          v-model="vm.coordinator_email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">เบอร์โทร</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          clearable
                          label="เบอร์โทร"
                          :rules="phoneRules"
                          v-model="vm.coordinator_phone"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <v-col cols="12" class="my-0 py-0">
                        <h4 class="primary--text mb-2">แฟกซ์</h4>
                        <v-text-field
                          background-color="input"
                          filled
                          clearable
                          label="กรุณาระบุหมายเลขแฟกซ์ (ถ้ามี)"
                          v-model="vm.coordinator_fax"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- <v-row class="d-flex align-center justify-space-between ma-2">
                    <h3 class="primary--text">ผู้ประสานงานของสถานศึกษา</h3>
                    <v-btn
                      @click="openPopupCreateContact"
                      outlined
                      color="black"
                      class="ma-2"
                    >
                      เพิ่มผู้ประสานงาน<v-icon class="ml-2">mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                  <SearchableDataTable
                    class="ma-2"
                    :data_items="vm.employerschoolcontactrequests"
                    :datatable_headers="
                      vm_employerschoolcontactrequests_data_table_headers
                    "
                  >
                    <template v-slot:item.action="{ item }">
                      <v-tooltip top color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="deleteContact(item)"
                            color="error"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>ลบ</span>
                      </v-tooltip>
                    </template>
                  </SearchableDataTable> -->
                  </v-form></v-tab-item
                >
                <v-tab-item>
                  <EmployerFileAttachments
                    class="mt-8"
                    v-if="employerProfile"
                    :EmployerProfile="employerProfile"
                  >
                  </EmployerFileAttachments>
                </v-tab-item>

                <v-tab-item>
                  <v-row class="d-flex justify-space-between mt-10">
                    <v-col lg="6" md="12" sm="12" xs="12">
                      <v-row>
                        <v-col lg="12" md="12" sm="12" xs="12">
                          <h4 class="primary--text">แผนที่</h4>
                          <v-text-field
                            prepend-inner-icon="mdi-link-variant"
                            background-color="input"
                            filled
                            label="รูปภาพแผนที่"
                            clearable
                            @click="openMapInput"
                            v-model="photoFilesName"
                          />

                          <input
                            type="file"
                            ref="locationFileInput"
                            accept="image/png, image/jpeg"
                            style="display: none"
                            @change="onPhotoChange"
                          />
                          <v-card
                            style="
                              height: 314px;
                              width: 100%;
                              background-color: #f2f4f8;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              overflow: hidden;
                            "
                          >
                            <v-img
                              style="width: 100%; height: 315px"
                              contain
                              :lazy-src="
                                'data:image/jpg;base64,' + location_photobase64
                              "
                              :src="
                                'data:image/jpg;base64,' + location_photobase64
                              "
                            ></v-img>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col lg="6" md="12" sm="12" xs="12">
                      <v-row>
                        <v-col lg="6" md="12" sm="12" xs="12">
                          <h4 class="primary--text">ละติจูด</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            label="ละติจูด"
                            v-bind:value="vm.location_latitude"
                          ></v-text-field>
                        </v-col>
                        <v-col lg="6" md="12" sm="12" xs="12">
                          <h4 class="primary--text">ละติจูด</h4>
                          <v-text-field
                            background-color="input"
                            filled
                            label="ลองติจูด"
                            v-bind:value="vm.location_longtitude"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-card
                        style="
                          height: 314px;
                          width: 100%;
                          background-color: #f2f4f8;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <MarkerGmap
                          @OnMarkedMap="OnMarkMapComplete"
                        ></MarkerGmap>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
          <v-row v-if="detailEmployer" class="my-5 justify-end">
            <!-- <v-btn v-if="tab != 0" class="mx-3" @click="tab--">ย้อนกลับ</v-btn> -->
            <v-btn class="mx-3" @click="cancelEdit()">ยกเลิก</v-btn>
            <v-btn dark class="mx-3 color-buton" @click="onSave"
              >บันทึก<v-icon class="ml-2"> mdi-content-save</v-icon></v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <BtnVerifyEmployerDBD
        v-if="vm.identityid"
        :dialogFound="this.dialogFound"
        :employerName="vm.nameth"
        :status="vm.dbd_organizationjuristicstatus"
        :dialogNotFound="this.dialogNotFound"
        :dialogFail="this.dialogFail"
      ></BtnVerifyEmployerDBD>
    </v-container>

    <!-- โค้ดเก่าาา -->
  </div>
</template>

<script>
import {
  emailRules,
  phoneRules,
  nameRules,
  security_numberRules,
  identityidRules
} from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { EmployerEditTheirProfileRequest } from "@/models/Employer/EmployerEditTheirProfileRequest";
import { EmployerSchoolContactRequest } from "@/models/Employer/EmployerSchoolContactRequest";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import MarkerGmap from "@/components/GoogleMap/MarkerGmap.vue";
import CompletionPercentage from "@/components/Profile/CompletionPercentage.vue";
import BtnVerifyEmployerEmail from "@/components/Employer/BtnVerifyEmployerEmail.vue";
import { FileAttachmentDataProvider } from "@/services/FileAttachmentDataProvider";
import EmployerFileAttachments from "@/components/Employer/EmployerFileAttachments.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
import { EmployerExternalApiDataProvider } from "@/services/EmployerExternalApiDataProvider";
import BtnVerifyEmployerDBD from "@/components/Employer/BtnVerifyEmployerDBD.vue";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const fileAttachmentDataProvider = new FileAttachmentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import moment from "moment";
import { formatDateThai } from "@/DateTimeHelper";
import BtnVerifyEmployerDBDVue from "../../components/Employer/BtnVerifyEmployerDBD.vue";

export default {
  name: "EmployerEditTheirProfileView",
  components: {
    MarkerGmap,
    CompletionPercentage,
    BtnVerifyEmployerEmail,
    EmployerFileAttachments,
    SearchableDataTable,
    BtnVerifyEmployerDBD,
  },
  data() {
    return {
      moment: moment,
      oid: undefined,
      vm: new EmployerEditTheirProfileRequest(),
      employerProfile: undefined,
      employerProfileStatuses: undefined,

      employerprofiletypes: undefined,

      employerMainBusinessTypes: undefined,

      employerBusinessTypes: undefined,

      provinces: undefined,

      districts: undefined,

      tambons: undefined,

      postcode: undefined,

      documentFiles: [],
      photoFiles: [],
      UpdateLocationData: new FormData(),
      UpdateDocumentData: new FormData(),
      location_photobase64: "",
      FileDocument_photobase64: "",
      logoFiles: [],
      UpdateLogoData: new FormData(),
      logo_photobase64: "",
      listFileDocument: [],
      host: "",
      completionPercentage: "",
      titlename: undefined,
      emailRules: emailRules,
      phoneRules: phoneRules,
      nameRules: nameRules,
      emailExistResult: false,
      security_numberRules: security_numberRules,
      identityidRules: identityidRules,
      employerData: null,
      loading: false,
      error: undefined,
      employerStatus: undefined,
      employerExternalApiDataProvider: new EmployerExternalApiDataProvider(),
      savedDate: null,
      thaiMonths: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      dbdLastverifydate: undefined,
      dbdStatus: true,
      detailEmployer: false,
      tab: null,
      tabIndicatorHeight: 10,
      vm_employerschoolcontactrequests_data_table_headers: [
        {
          text: "สถานศึกษา",
          value: "selected_schoolname",
          class: "my-header-style",
        },
        { text: "ชื่อ-นามสกุล", value: "fullname", class: "my-header-style" },
        { text: "เบอร์ติดต่อ", value: "phone", color: "white" },
        { text: "", value: "action" , sortable: false},
      ],
      photoFilesName: "",
      dialogFound: false,
      dialogNotFound: false,
      dialogFail: false,
      employerProfile: {},
      originalData: null,
    };
  },
  methods: {
    goToChangePasswordView() {
      this.$router.push(
        "/ApplicationUser/CurrentApplicationUserChangeThierOwnPassword"
      );
    },
    OnMarkMapComplete(position) {
      if (position) {
        this.vm.location_latitude = position.lat;
        this.vm.location_longtitude = position.lng;
      }
    },
    async initialize() {
      await masterDataProvider.loadEmployerProfileStatuses().then((result) => {
        this.employerProfileStatuses = result.data;
      });

      await masterDataProvider.loadEmployerProfileTypes().then((result) => {
        this.employerprofiletypes = result.data;
      });

      await masterDataProvider
        .loadEmployerMainBusinessTypes()
        .then((result) => {
          this.employerMainBusinessTypes = result.data;
        });

      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });

      const getSchoolRequest = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(getSchoolRequest).then((result) => {
        this.schools = result.data;
      });

      await masterDataProvider.getActiveTitleNames().then((result) => {
        this.titlename = result.data;
      });
      await this.getCurrentEmployerProfile();
      await this.loadEmployerBusinessTypes();
      await this.loadDistricts();
      await this.loadTambons();
      await this.onTambonSelected();
    },

    async getCurrentEmployerProfile() {
      await employerProfileDataProvider
        .getCurrentEmployerProfile()
        .then((result) => {
          this.employerProfile = result.data;
          this.oid = this.employerProfile.oid;
          this.vm = EmployerEditTheirProfileRequest.fromJson(result.data);
          this.vm.username = this.employerProfile.username;
          this.location_photobase64 = this.employerProfile.location_photobase64;
          this.FileDocument_photobase64 =
            this.employerProfile.employerfileattachments;
          this.listFileDocument = this.employerProfile.employerfileattachments;
          this.logo_photobase64 = this.employerProfile.logo_photobase64;
          this.vm.dbd_lastverifydate = this.employerProfile.dbd_lastverifydate;

          this.vm.dbd_status = this.employerProfile.dbd_status;

          if (this.employerProfile.dbd_organizationjuristicstatus) {
            this.vm.dbd_organizationjuristicstatus =
              this.employerProfile.dbd_organizationjuristicstatus;
          } else {
            this.error = true;
          }
          this.originalData = JSON.parse(JSON.stringify(this.vm));
        })
        .catch((err) => {});

      await employerProfileDataProvider
        .getCompletionPercentage(this.oid)
        .then((result) => {
          this.completionPercentage = result.data;
        });
    },

    async loadEmployerBusinessTypes() {
      if (this.vm.selected_employermainbusinesstypecode != "") {
        await masterDataProvider
          .loadEmployerBusinessTypes(
            this.vm.selected_employermainbusinesstypecode
          )
          .then((result) => {
            this.employerBusinessTypes = result.data;
          });
      }
    },

    async loadDistricts() {
      if (this.vm.selected_province_id) {
        await masterDataProvider
          .loadGetDistricts(this.vm.selected_province_id)
          .then((result) => {
            this.districts = result.data;
          });
      }
    },

    async loadTambons() {
      if (this.vm.selected_district_id) {
        await masterDataProvider
          .loadGetTambons(this.vm.selected_district_id)
          .then((result) => {
            this.tambons = result.data;
          });
      }
    },

    async onTambonSelected() {
      if (this.vm.selected_tambon_id != "") {
        const vm_selected_tambon_id = this.vm.selected_tambon_id;
        const selected_tambon = this.tambons.find(
          (x) => x.tambonid == vm_selected_tambon_id
        );
        this.postcode = selected_tambon.postcodemain;
      }
    },

    async openPopupCreateContact() {
      this.newContact = new EmployerSchoolContactRequest();
      this.isOpenCreateContactDialog = true;
    },

    async onSchoolSelected() {
      if (this.newContact.selected_schoolid != "") {
        const selectedID = this.newContact.selected_schoolid;
        const school = this.schools.find((x) => x.schoolid == selectedID);
        this.newContact.selected_schoolname = school.schoolname;
      }
    },

    async createContact() {
      this.vm.employerschoolcontactrequests.push(this.newContact);
      this.isOpenCreateContactDialog = false;
    },

    async deleteContact(item) {
      if (confirm("ยืนยัน")) {
        await employerProfileDataProvider
          .deleteEmployerSchoolContact(item.oid)
          .then((result) => {
            const index = this.vm.employerschoolcontactrequests.indexOf(item);
            this.vm.employerschoolcontactrequests.splice(index, 1);
          });
      }
    },
    async onFileChange() {
      this.UpdateDocumentData.append("oid", this.oid);
      this.UpdateDocumentData.append("filedoc", this.documentFiles);

      await employerProfileDataProvider
        .uploadDocumentFile(this.UpdateDocumentData)
        .then((result) => {
          var fileToLoad = this.documentFiles;
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(fileToLoad);
          }).then((data) => {
            const base64 = data.split(",")[1];
            this.FileDocument_photobase64 = base64;
          });

          this.$toast.success("อัปโหลด ข้อมูลการจดทะเบียน เรียบร้อย!");
          this.loadEmpDocumentFile();
        })
        .catch((result) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    async onlogoPhotoChange() {
      const fileToLoad = event.target.files[0];
      this.logoFiles = fileToLoad;
      this.UpdateLogoData.append("oid", this.oid);
      this.UpdateLogoData.append("filelogo", this.logoFiles);

      await employerProfileDataProvider
        .uploadLogoPhoto(this.UpdateLogoData)
        .then((result) => {
          var fileToLoad = this.logoFiles;
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(fileToLoad);
          }).then((data) => {
            const base64 = data.split(",")[1];
            this.logo_photobase64 = base64;
          });

          this.$toast.success("อัปโหลด โลโก้ เรียบร้อย!");
        })
        .catch((result) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    openMapInput() {
      this.$refs.locationFileInput.click();
    },
    async onPhotoChange() {
      const file = event.target.files[0];
      this.photoFiles = file;
      this.photoFilesName = file.name;
      this.UpdateLocationData.append("oid", this.oid);
      this.UpdateLocationData.append("filephoto", this.photoFiles);
      await employerProfileDataProvider
        .uploadLocationPhoto(this.UpdateLocationData)
        .then((result) => {
          var fileToLoad = this.photoFiles;
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(fileToLoad);
          }).then((data) => {
            const base64 = data.split(",")[1];
            this.location_photobase64 = base64;
          });

          this.$toast.success("อัปโหลด สถานที่ตั้ง เรียบร้อย!");
        })
        .catch((result) => {
          this.$toast.error("ล้มเหลว!");
        });
    },
    async DeleteDocumentFile(oid) {
      if (confirm("ต้องการลบ ข้อมูลการจดทะเบียน หรือไม่ ?")) {
        await employerProfileDataProvider
          .DeleteDocumentFile(oid)
          .then((result) => {
            this.$toast.success("ลบ ข้อมูลการจดทะเบียน เรียบร้อย!");
            this.loadEmpDocumentFile();
            this.FileDocument_photobase64 = "";
            this.documentFiles = "";
          })
          .catch((result) => {
            this.$toast.error("ล้มเหลว!");
          });
      }
    },
    async loadEmpDocumentFile() {
      await employerProfileDataProvider
        .LoadDocumentFile(this.oid)
        .then((result) => {
          this.listFileDocument = result.data;
        });
    },
    async onSave() {
      if (confirm("ยืนยัน")) {
        try {
          this.$store.dispatch("showLoading");
          await employerProfileDataProvider.updateEmployerProfile(this.vm);
          this.$toast.success("บันทึกข้อมูลเรียบร้อย");
          await this.getCurrentEmployerProfile();
        } catch (error) {
          this.$toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        } finally {
          this.detailEmployer = false;
          this.$store.dispatch("hideLoading");
        }
      }
    },

    cancelEdit() {
      try {
        this.$store.dispatch("showLoading");
        this.vm = { ...this.originalData };
        this.detailEmployer = false;
      } catch (error) {
        console.log("Error cancelEdit()");
      } finally {
        this.$store.dispatch("hideLoading");
      }
    },
    async checkEmployerProfile() {
      // if (!this.vm.identityid) {
      //   return; // ถ้า identityid ไม่มีข้อมูล ก็ออกจากฟังก์ชัน
      // }

      // this.$store.dispatch("showLoading");
      // this.error = false;
      // this.employerData = null;
      // console.log(this.vm.oid);

      // try {
      //   // throw new Error("Network Error"); //ทดสอบการเชื่อมต่อ DBD ล้มเหลว

      //   //ตรวจสอบการเชื่อมต่อ DBD
      //   await this.testConnection();
      //   // เชื่อมต่อกับ API ได้สำเร็จ
      //   const result = await employerProfileDataProvider.VerifyProfileWithDBDOpenAPI(
      //     this.vm.oid
      //   );
      //   console.log(result.data);

      //   this.getCurrentEmployerProfile();

      //   if (result.data.value.detail) {
      //     this.$toast.error("ไม่พบข้อมูลสถานประกอบการกับ DBD");
      //     this.error = true;
      //   } else {
      //     const organizationData =
      //       result.data.value?.data[0]["cd:organizationjuristicperson"];
      //     if (organizationData) {
      //       console.log(organizationData);

      //       if (organizationData["cd:organizationjuristicid"] === this.vm.identityid) {
      //         this.$toast.success("ตรวจสอบสถานะสถานประกอบการกับ DBD เรียบร้อย");
      //         this.vm.dbd_status = true;
      //         this.vm.dbd_lastverifydate = this.dbdUpdateDate;
      //         this.vm.dbd_organizationjuristicstatus =
      //           organizationData["cd:organizationjuristicstatus"];
      //         console.log(this.vm.dbd_organizationjuristicstatus);

      //         await employerProfileDataProvider.updateEmployerProfile(this.vm);
      //         console.log("Employer profile updated successfully");
      //       }
      //     }
      //   }
      // } catch (error) {
      //   console.error(error);
      //   if (!error.response) {
      //     // เชื่อมต่อกับ API ไม่ได้
      //     this.$toast.error("การเชื่อมต่อ DBD ล้มเหลว");
      //   } else {
      //     this.$toast.error("เกิดข้อผิดพลาดในการตรวจสอบข้อมูล2");
      //   }
      // } finally {
      //   this.$store.dispatch("hideLoading");
      // }
      if (this.vm.identityid) {
        try {
          this.$store.dispatch("showLoading");
          this.error = false;
          this.employerData = null;

          console.log(this.vm.oid);

          const result =
            await employerProfileDataProvider.VerifyProfileWithDBDOpenAPI(
              this.vm.oid
            );
          console.log(result.data);

          if (result.data.value.detail) {
            // this.$toast.error("ไม่พบข้อมูลสถานประกอบการกับ DBD");
            this.dialogNotFound = true;
            this.error = true;
          } else {
            const organizationData =
              result.data.value?.data[0]["cd:organizationjuristicperson"];
            console.log(organizationData);

            if (
              organizationData &&
              organizationData["cd:organizationjuristicid"] ===
                this.vm.identityid
            ) {
              // this.$toast.success("ตรวจสอบสถานะสถานประกอบการกับ DBD เรียบร้อย");
              this.dialogFound = true;
              this.vm.dbd_status = true;
              this.vm.dbd_lastverifydate = this.dbdUpdateDate;
              this.vm.dbd_organizationjuristicstatus =
                organizationData["cd:organizationjuristicstatus"];
              console.log(this.vm.dbd_organizationjuristicstatus);

              const updateResult =
                await employerProfileDataProvider.updateEmployerProfile(
                  this.vm
                );
              console.log(updateResult);
            }
          }
        } catch (error) {
          console.error(
            "An error occurred while verifying the employer profile:",
            error
          );

          if (error.response && error.response.status === 500) {
            // alert("DBD ล้มเหลว");
            this.dialogFail = true;
          } else {
            this.$toast.error("เกิดข้อผิดพลาดในการตรวจสอบสถานประกอบการ");
          }
        } finally {
          this.$store.dispatch("hideLoading");
        }

        // this.vm.dbd_lastverifydate = this.currentDate();
        // this.dbdLastverifydate = this.dbd_lastverifydate;
        // const result = this.employerExternalApiDataProvider.getTestEmployerData();

        // if (result.data.value.detail) {
        //   console.log("ไม่พบข้อมูลนิติบุคคล");
        //   this.$toast.error("ไม่พบข้อมูลสถานประกอบการกับ DBD ");
        //   this.error = true;
        //   this.vm.dbd_organizationjuristicstatus = null;
        //   this.vm.dbd_status = false;
        // } else if (result.data.value.data[0]["cd:organizationjuristicperson"]) {
        //   const organizationData =
        //     result.data.value?.data[0]["cd:organizationjuristicperson"];
        //   console.log(result.data.value.data[0]["cd:organizationjuristicperson"]);

        //   if (organizationData["cd:organizationjuristicid"] === this.vm.identityid) {
        //     console.log("พบข้อมูลนิติบุคคล:", organizationData);
        //     this.$toast.success("ตรวจสอบสถานะสถานประกอบการกับ DBD เรียบร้อย");
        //     this.vm.identityid = organizationData["cd:organizationjuristicid"];
        //     this.vm.dbd_organizationjuristicstatus =
        //       organizationData["cd:organizationjuristicstatus"];
        //     this.vm.dbd_status = true;
        //   }
        // }
        // });

        // this.$store.dispatch("hideLoading");
        // try {
        //   const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        //   const fetchDataPromise = this.employerExternalApiDataProvider
        //     .loadEmployerData(this.vm.identityid)
        //     .then((response) => {
        //       this.savedDate = this.currentDate();
        //       this.dbdLastverifydate = this.dbd_lastverifydate;
        //       const result = this.employerExternalApiDataProvider.getTestEmployerData();
        //       const organizationData = result.data[0]["cd:organizationjuristicperson"];

        //       if (organizationData["cd:organizationjuristicid"] === this.vm.identityid) {
        //         console.log("พบข้อมูลนิติบุคคล:", organizationData);
        //         this.employerData = organizationData["cd:organizationjuristicid"];
        //         this.employerStatus = organizationData["cd:organizationjuristicstatus"];
        //         this.dbdStatus = true;
        //       } else {
        //         console.log("ไม่พบข้อมูลนิติบุคคล");
        //         this.error = true;
        //         this.employerStatus = null;
        //         this.dbdStatus = false;
        //       }
        //     })
        //     .catch((err) => {
        //       console.error("Error fetching data:", err);
        //       this.savedDate = this.currentDate();
        //       this.dbdLastverifydate = this.dbd_lastverifydate;
        //       console.log(this.loading, "MIDDLE");

        //       const result = this.employerExternalApiDataProvider.getTestEmployerData();
        //       const organizationData = result.data[0]["cd:organizationjuristicperson"];

        //       if (organizationData["cd:organizationjuristicid"] === this.vm.identityid) {
        //         console.log("พบข้อมูลนิติบุคคล (ข้อมูลจำลอง):", organizationData);
        //         this.employerData = organizationData["cd:organizationjuristicid"];
        //         this.employerStatus = organizationData["cd:organizationjuristicstatus"];
        //         this.dbdStatus = true;
        //       } else {
        //         console.log("ไม่พบข้อมูลนิติบุคคล (ข้อมูลจำลอง)");
        //         this.error = true;
        //         this.employerStatus = null;
        //         this.dbdStatus = false;
        //       }
        //     });

        //   await Promise.all([fetchDataPromise, delay(1000)]);
        // } finally {
        //   this.$store.dispatch("showLoading");
        //   console.log(this.loading, "AFTER");
        // }
      }
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()} ${
        this.thaiMonths[current.getMonth()]
      } ${current.getFullYear() + 543}`;
      const time = `${current.getHours()}:${current
        .getMinutes()
        .toString()
        .padStart(2, "0")} น.`;
      return `${date} ${time}`;
    },
    convertDateFormat(dbdLastverifydate) {
      return formatDateThai(dbdLastverifydate);
    },
  },
  computed: {
    custom_emailRules() {
      return [
        ...emailRules,
        (v) => this.emailExistResult === false || "อีเมลนี้ถูกใช้งานแล้ว",
      ];
    },
    chipcard_status() {
      let color = "";
      let textcolor = "white";

      if (this.employerStatus === "ร้าง") {
        color = "";
        textcolor = "white";
      } else if (this.employerStatus === "เสร็จการชำระบัญชี") {
        color = "error";
        textcolor = "white";
      } else if (this.employerStatus === "ยังดำเนินกิจการอยู่") {
        color = "success";
        textcolor = "white";
      }
      return { color, textcolor };
    },
    formattedLastUpdatedDate() {
      return moment(this.employerProfile.lastupdateddate)
        .add(543, "year")
        .locale("th")
        .format("D MMMM YYYY");
    },
    dbdUpdateDate() {
      console.log(this.vm.dbd_status);
      console.log(this.vm.dbd_lastverifydate);
      if (
        this.vm.dbd_status === false &&
        this.vm.dbd_lastverifydate !== "0001-01-01T00:00:00"
      ) {
        return `${this.moment(this.vm.dbd_lastverifydate)
          .add(543, "year")
          .locale("TH")
          .format("ll เวลา HH:mm")} น.`.replace(/(\d{2})(\d{2})/, "$2");
      } else if (
        this.vm.dbd_status === false &&
        this.vm.dbd_lastverifydate === "0001-01-01T00:00:00"
      ) {
        return "-";
      } else if (
        this.vm.dbd_status === true &&
        this.vm.dbd_lastverifydate !== "0001-01-01T00:00:00"
      ) {
        // return this.vm.dbd_lastverifydate;

        return `${this.moment(this.vm.dbd_lastverifydate)
          .add(543, "year")
          .locale("TH")
          .format("ll เวลา HH:mm")} น.`.replace(/(\d{2})(\d{2})/, "$2");
      } else if (this.vm.dbd_status === true) {
        return this.vm.dbd_lastverifydate;
        // return this.currentDate();
      }
    },
  },
  watch: {
    "vm.email": {
      async handler(newVal, oldVal) {
        if (oldVal != "") {
          await applicationUserDataProvider
            .isEmailUsed(oldVal)
            .then((result) => {
              this.emailExistResult = result.data;
              if (this.$refs.txtEmail) {
                this.$refs.txtEmail.validate();
              }
            });
        }
      },
      deep: true,
    },
    "employerProfile.dbd_status": function (newVal) {
      if (newVal === true) {
        if (this.employerProfile.dbd_organizationjuristicstatus !== null) {
          this.employerStatus =
            this.employerProfile.dbd_organizationjuristicstatus;
        }
      } else if (
        this.employerProfile.dbd_lastverifydate === "0001-01-01T00:00:00"
      ) {
        this.error = false;
      } else {
        this.error = true;
      }
    },
    async tab(newTab, oldTab) {
      let isValid = false;

      if (newTab === 0) {
        console.log(newTab);
      } else if (newTab === 1) {
        if (oldTab === 0) {
          isValid = await this.$refs.tabForm1.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        }
      } else if (newTab === 2) {
        if (oldTab === 0) {
          isValid = await this.$refs.tabForm1.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        } else if (oldTab === 1) {
          isValid = await this.$refs.tabForm2.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error(
              "กรุณากรอกข้อมูลผู้ติดต่อ/ผู้ประสานงานให้ครบถ้วน"
            );
            this.tab = oldTab;
          }
        }
      } else if (newTab === 3) {
        if (oldTab === 0) {
          isValid = await this.$refs.tabForm1.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        } else if (oldTab === 1) {
          isValid = await this.$refs.tabForm2.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error(
              "กรุณากรอกข้อมูลผู้ติดต่อ/ผู้ประสานงานให้ครบถ้วน"
            );
            this.tab = oldTab;
          }
        }
      }
    },
  },

  async created() {
    this.host = backendapiUrl;
    await this.initialize();
  },
};
</script>

<style scoped>
.v-toolbar__title {
  font-size: 16px !important;
}

.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.z-index-10 {
  z-index: 10;
}

.v-tabs-bar {
  position: relative;
}

.active-tab {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  z-index: 10;
}

.v-tab {
  transition: all 0.3s ease;
  /* ทำให้การเปลี่ยนแปลงมีความนุ่มนวล */
}

.v-tabs-bar::before {
  display: block;
  content: "";

  background-color: black;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  z-index: 0;
}

.v-tabs-bar::after {
  display: block;
  content: "";

  background: rgba(33, 150, 243, 1);
  background: -moz-linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c32294", endColorstr="#fd2da8", GradientType=1);
  mix-blend-mode: lighten;
  position: absolute;
  bottom: -40px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.v-slide-group__wrapper {
  /* overflow: visible; */
  contain: unset;
}

.custom-slider {
  bottom: -40px;
  position: relative;
  background-color: transparent;
}
.custom-slider::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -78px;
  height: 48px;
  background-color: black;
  border-radius: 8px 8px 0 0;
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
.custom-slider::after {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
}

.ql-editor1 {
  box-sizing: border-box;
  line-height: 22px;
  height: 100%;
  outline: none;
  overflow: auto;
  tab-size: 4;
  text-align: left;
  white-space: pre-line;
  word-wrap: break-word;
}
</style>
