<template>
  <div class="pa-5">
    <v-row class="d-flex justify-space-between">
      <v-col class="d-flex justify-end">
        <v-btn class="font-weight-bold" @click="showFilter = !showFilter">
          <v-icon class="mr-2" v-if="!showFilter">mdi-filter-variant</v-icon>
          <v-icon class="mr-2" v-else>mdi-restart</v-icon>
          {{ showFilter ? "ซ่อนการกรอง" : "กรองข้อมูล" }}
        </v-btn>
      </v-col>
    </v-row>
    <transition name="slide-up">
      <v-card v-if="showFilter" rounded elevation="1" class="mt-3 mb-5 pa-5">
        <div v-if="tableOptions">
          <v-row class="d-flex justify-space-between mx-2">
            <v-col cols="12" lg="4" md="6" sm="12" xs="12">
              <h4 class="primary--text mb-2">วุฒิการศึกษา</h4>
              <v-select
                :items="certificateTypes"
                item-text="nameth"
                item-value="code"
                text="text"
                value="value"
                @change="loadjobApplication()"
                label="วุฒิการศึกษา"
                v-model="tableOptions.Selected_CertificateType_Code"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                clearable
              ></v-select>
            </v-col>

            <v-col cols="12" lg="4" md="6" sm="12" xs="12">
              <h4 class="primary--text mb-2">สาขาวิชา</h4>
              <v-autocomplete
                label="สาขาวิชา"
                :items="majors"
                item-value="majormoecode"
                item-text="majornameth"
                clearable
                @change="loadjobApplication()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="tableOptions.Selected_MajorName"
              >
              </v-autocomplete>
            </v-col>
            <v-col lg="4" md="6" sm="12" xs="12">
              <h4 class="primary--text mb-2">เพศ</h4>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="
                      !tableOptions.Selected_GenderCode === true ||
                      tableOptions.Selected_GenderCode === ''
                        ? 'primary'
                        : 'input'
                    "
                    @click="
                      tableOptions.Selected_GenderCode = '';
                      loadjobApplication();
                    "
                    height="56px"
                    width="100%"
                  >
                    ทั้งหมด
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="
                      tableOptions.Selected_GenderCode === 'M'
                        ? 'primary'
                        : 'input'
                    "
                    @click="
                      tableOptions.Selected_GenderCode = 'M';
                      loadjobApplication();
                    "
                    height="56px"
                    width="100%"
                  >
                    ชาย
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
                >
                  <v-btn
                    :color="
                      tableOptions.Selected_GenderCode === 'F'
                        ? 'primary'
                        : 'input'
                    "
                    @click="
                      tableOptions.Selected_GenderCode = 'F';
                      loadjobApplication();
                    "
                    height="56px"
                    width="100%"
                  >
                    หญิง
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <h4 class="primary--text mb-2">สถานประกอบการ</h4>
              <v-text-field
                background-color="input"
                filled
                clearable
                label="สถานประกอบการ"
                v-model="tableOptions.EmployerName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12">
              <h4 class="primary--text mb-2">ประเภทงาน</h4>
              <v-autocomplete
                label="ประเภทงาน"
                :items="jobsegments"
                item-text="name"
                item-value="name"
                clearable
                @change="loadjobApplication()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="tableOptions.PositionType"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <h4 class="primary--text mb-2">ตำแหน่งงาน</h4>
              <v-text-field
                background-color="input"
                filled
                clearable
                label="ตำแหน่งงาน"
                v-model="tableOptions.PositionName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12">
              <h4 class="primary--text mb-2">จังหวัด</h4>
              <v-autocomplete
                label="จังหวัด"
                :items="provinces"
                item-text="provincethai"
                item-value="provinceid"
                clearable
                @change="loadjobApplication()"
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="tableOptions.Selected_Employer_ProvinceCode"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" class="py-0">
              <h4 class="primary--text mb-2">สถานะใบสมัครงาน</h4>
            </v-col>
            <v-col
              class="px-lg-2 px-md-1 py-lg-0 py-sm-1"
              cols="12"
              lg="2"
              md="4"
            >
              <v-btn
                :color="
                  !tableOptions.Selected_JobApplicationStatusCode === true ||
                  tableOptions.Selected_JobApplicationStatusCode === ''
                    ? 'primary'
                    : ''
                "
                @click="
                  tableOptions.Selected_JobApplicationStatusCode = '';
                  loadjobApplication();
                "
                background-color="input"
                width="100%"
                height="45px"
              >
                ทั้งหมด
              </v-btn>
            </v-col>

            <v-col
              class="px-lg-2 px-md-1 py-lg-0 py-sm-1"
              cols="12"
              lg="2"
              md="4"
            >
              <v-btn
                :color="
                  tableOptions.Selected_JobApplicationStatusCode === 'Pending'
                    ? 'primary'
                    : 'input'
                "
                @click="
                  () => {
                    tableOptions.Selected_JobApplicationStatusCode = 'Pending';
                    loadjobApplication();
                  }
                "
                width="100%"
                height="45px"
              >
                <template
                  v-if="
                    $vuetify.breakpoint.width <= 1800 &&
                    $vuetify.breakpoint.width >= 1260
                  "
                >
                  ยื่นใบสมัคร /<br />รอการตรวจสอบ
                </template>
                <template v-else> ยื่นใบสมัคร / รอการตรวจสอบ </template>
              </v-btn>
            </v-col>
            <v-col
              class="px-lg-2 px-md-1 py-lg-0 py-sm-1"
              cols="12"
              lg="2"
              md="4"
            >
              <v-btn
                :color="
                  tableOptions.Selected_JobApplicationStatusCode ===
                  'UnderReview'
                    ? 'primary'
                    : 'input'
                "
                @click="
                  () => {
                    tableOptions.Selected_JobApplicationStatusCode =
                      'UnderReview';
                    loadjobApplication();
                  }
                "
                width="100%"
                height="45px"
              >
                <template
                  v-if="
                    $vuetify.breakpoint.width <= 1800 &&
                    $vuetify.breakpoint.width >= 1260
                  "
                >
                  อยู่ระหว่าง <br />
                  ตรวจสอบ
                </template>
                <template v-else> อยู่ระหว่างตรวจสอบ </template>
              </v-btn>
            </v-col>
            <v-col
              class="px-lg-2 px-md-1 py-lg-0 py-sm-1"
              cols="12"
              lg="2"
              md="4"
            >
              <v-btn
                :color="
                  tableOptions.Selected_JobApplicationStatusCode ===
                  'InterviewScheduled'
                    ? 'primary'
                    : 'input'
                "
                @click="
                  () => {
                    tableOptions.Selected_JobApplicationStatusCode =
                      'InterviewScheduled';
                    loadjobApplication();
                  }
                "
                width="100%"
                height="45px"
              >
                นัดหมายสัมภาษณ์
              </v-btn>
            </v-col>
            <v-col
              class="px-lg-2 px-md-1 py-lg-0 py-sm-1"
              cols="12"
              lg="2"
              md="4"
            >
              <v-btn
                :color="
                  tableOptions.Selected_JobApplicationStatusCode === 'Accecpted'
                    ? 'primary'
                    : 'input'
                "
                @click="
                  () => {
                    tableOptions.Selected_JobApplicationStatusCode =
                      'Accecpted';
                    loadjobApplication();
                  }
                "
                width="100%"
                height="45px"
              >
                รับเข้าทำงาน
              </v-btn>
            </v-col>
            <v-col
              class="px-lg-2 px-md-1 py-lg-0 mb-5 py-sm-1"
              cols="12"
              lg="2"
              md="4"
            >
              <v-btn
                :color="
                  tableOptions.Selected_JobApplicationStatusCode === 'Rejected'
                    ? 'primary'
                    : 'input'
                "
                @click="
                  () => {
                    tableOptions.Selected_JobApplicationStatusCode = 'Rejected';
                    loadjobApplication();
                  }
                "
                width="100%"
                height="45px"
              >
                ไม่ผ่านการพิจารณา
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </transition>
    <SearchableDataTablePaging
      table_caption="ข้อมูลการใบสมัครงาน"
      :datatable_headers="jobApplication_data_table_headers"
      :data_items="jobApplication"
      :itemlength="itemlength"
      class="elevation-1"
      :search="keyword"
      @pageChanged="pageChanged"
      @onkeywordchanged="onKeywordChanged"
      @tableOptionsChanged="tableOptionsChanged"
    >
      <template v-slot:item.student.base64thrumnail="{ item }">
        <v-avatar color="grey" size="50" class="my-4">
          <v-img
            :src="'data:image/jpg;base64,' + item.student.base64thrumnail"
          ></v-img>
        </v-avatar>
      </template>

      <template v-slot:item.student.veceducationinformations="{ item }">
        <span
          v-for="educationInfo in item.student.veceducationinformations"
          :key="educationInfo.oid"
          >{{ educationInfo.certificatetype.nameth }} <br />
          ({{ educationInfo.major.majornameth }}) <br />
        </span>
      </template>

      <template v-slot:item.jobposting.employerprofile.nameth="{ item }">
        <span>{{ item.jobposting.employerprofile.nameth }} </span>
        <span v-if="item.jobposting.employerprofile.province.provincethai">
          <br />
          จังหวัด :
          {{ item.jobposting.employerprofile.province.provincethai }}</span
        >
      </template>

      <template v-slot:item.student.displayname="{ item }">
        <span class="mr-1">{{ item.student.displayname }}</span>
        <v-icon v-if="item.student.gender == 'M'" color="blue"
          >mdi-gender-male</v-icon
        >
        <v-icon v-else color="pink">mdi-gender-female</v-icon>
      </template>

      <template v-slot:item.jobposting="{ item }">
        <span>{{ item.jobposting.jobsegmentname_todisplay }}</span>
        <span v-if="item.jobposting.employmentcategory.name">
          <br />
          รูปแบบการจ้างงาน : {{ item.jobposting.employmentcategory.name }}</span
        >
        <span v-if="item.jobposting.wagetodisplay">
          <br />
          เงินเดือน : {{ item.jobposting.wagetodisplay }}</span
        >
      </template>

      <template v-slot:item.createddate="{ item }">
        <FormatDate :date_value="item.createddate"></FormatDate>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="gotoSearchStudentDetails(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file</v-icon>
            </v-btn>
          </template>
          <span>แสดงข้อมูลนักศึกษา</span>
        </v-tooltip>
      </template>
    </SearchableDataTablePaging>
  </div>
</template>

<script>
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CenterStaffJobPostingDataProvider } from "@/services/CenterStaffJobPostingDataProvider";
import FormatDate from "@/components/common/FormatDate.vue";

var majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

var centerStaffJobPostingDataProvider = new CenterStaffJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "CenterStaffJobApplicationListView",
  components: { SearchableDataTablePaging, FormatDate },
  data() {
    return {
      keyword: "",
      pageNumber: 1,
      itemlength: 0,
      tableOptions: undefined,
      jobApplication: undefined,
      jobApplication_data_table_headers: [
        { text: "", value: "student.base64thrumnail" },
        { text: "ชื่อ-นามสกุล", value: "student.displayname" },
        {
          text: "วุฒิการศึกษา",
          value: "student.veceducationinformations",
        },
        { text: "สถานประกอบการ", value: "jobposting.employerprofile.nameth" },
        { text: "ประเภทงาน", value: "jobposting" },
        { text: "ตำแหน่งงาน", value: "jobposting.positionname" },
        { text: "วันที่สมัคร", value: "createddate" },
        { text: "สถานะใบสมัคร", value: "jobapplicationstatus.statusname" },
        { text: "ตัวเลือก", value: "action", align: "center" , sortable: false},
      ],
      majors: undefined,
      genderlist: undefined,
      provinces: undefined,
      jobsegments: [],
      schooltypelist: "",
      status: undefined,
      certificateTypes: undefined,
      showFilter: true,
    };
  },
  watch: {
    tableOptions: {
      handler(newValue, oldValue) {
        this.loadjobApplication();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      await masterDataProvider.getCertificateTypes().then((result) => {
        this.certificateTypes = result.data;
      });
      await majorDataProvider
        .getMajorDistinctByMajorMoeCode()
        .then((result) => {
          this.majors = result.data;
        });
      await masterDataProvider.getGenders().then((result) => {
        this.genderlist = result.data;
      });
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
      await masterDataProvider.getJobSegment().then((result) => {
        this.jobsegments = result.data;
      });
      await masterDataProvider
        .getJobApplicationEntityStatuses()
        .then((result) => {
          this.status = result.data;
        });
    },
    async loadjobApplication() {
      this.tableOptions.search = this.keyword;
      await centerStaffJobPostingDataProvider
        .getJobApplications(this.tableOptions)
        .then((result) => {
          this.jobApplication = result.data.items;
          console.log(this.jobApplication);
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        });
    },
    pageChanged(pageChangeValue) {
      this.pageNumber = pageChangeValue;
      this.loadjobApplication();
    },
    async onKeywordChanged(newKeyword) {
      this.keyword = newKeyword;
      await this.loadjobApplication();
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
    },
    gotoStudentTrackCurrentJobApplicationsView(item) {
      this.$router
        .push({
          name: "StudentTrackCurrentJobApplicationsView",
          params: { student_oid: item.oid },
        })
        .catch((err) => {});
    },
    gotoSearchStudentDetails(item) {
      var studentOid = item.student.oid;

      this.$router.push({
        name: "StudentDetailOverview",
        params: { studentoid: studentOid },
      });
    },
  },
  async created() {
    await this.initialize();
    await this.loadjobApplication();
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s ease-out;
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}
</style>
