<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="11" md="12" sm="12" xs="12">
          <v-card>
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >ติดตามสถานะการรับสมัครงาน</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text v-if="jobposting" class="d-flex justify-center">
              <v-col cols="10" class="mt-5">
                <h2>{{ jobposting.positionname }}</h2>
                <v-row no-gutters align="center" class="mt-2">
                  <v-col cols="auto" class="mr-2">
                    <v-avatar color="grey" size="50" class="my-2">
                      <v-img
                        :src="
                          'data:image/jpg;base64,' +
                          jobposting.employerprofile.logo_photobase64
                        "
                      ></v-img>
                    </v-avatar>
                  </v-col>

                  <v-col>
                    <div>
                      <span class="primary--text">{{
                        jobposting.employerprofile.nameth
                      }}</span>
                      <v-icon class="ml-1 mr-0">mdi-map-marker-outline</v-icon>
                      <span>
                        {{
                          jobposting.workingplace_district.districtthaishort
                        }},
                        {{ jobposting.workingplace_province.provincethai }}
                      </span>
                    </div>
                    <div class="mt-1">
                      <v-chip
                        style="background-color: rgba(63, 81, 181, 0.2)"
                        small
                      >
                        {{ jobposting.employmentcategory.name }}
                      </v-chip>
                    </div>
                  </v-col>

                  <v-col class="text-right">
                    <v-row no-gutters class="align-center" justify="end">
                      <h4 class="mr-2 primary--text">สถานะ :</h4>
                      <v-chip
                        :color="
                          getStatusColor(
                            jobposting?.jobpostingstatus.statuscode
                          )
                        "
                        text-color="white"
                      >
                        {{ jobposting.jobpostingstatus.statusname }}
                      </v-chip>
                    </v-row>
                    <div class="mt-1">
                      <span> วันที่ประกาศ : </span>
                      <FormatDateView
                        :date_value="jobposting.startdate"
                      ></FormatDateView>
                      -
                      <FormatDateView
                        :date_value="jobposting.enddate"
                      ></FormatDateView>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-3">
                  <v-col cols="12">
                    <span>รหัสประกาศงาน : </span>
                    <a @click="openEmployerUpdateJobPostingView()">{{
                      jobposting.jobposting_id
                    }}</a>
                  </v-col>
                  <v-col cols="12">
                    <span
                      >ประเภทงาน :
                      {{ jobposting.jobsegmentname_todisplay }}</span
                    >
                  </v-col>
                  <v-col cols="12">
                    <span
                      >อัตราที่รับ : {{ jobposting.unit_todisplay }} คน</span
                    >
                  </v-col>
                  <v-col cols="12">
                    <span>เงินเดือน : {{ jobposting.wagetodisplay }}</span>
                  </v-col>
                </v-row>

                <v-row class="my-3">
                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-4 pa-2 light-gray-border"
                      rounded
                      :outlined="activeButton !== 1"
                      :color="activeButton === 1 ? 'primary' : ''"
                      style="border-radius: 8px; height: 66.66%; width: 100%"
                      @click="
                        setActive(1);
                        filterJobApplicationsByJobApplicationStatusCode('');
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 1 }"
                        >จำนวนใบสมัครทั้งหมด
                        {{ quantity_of_all_jobapplication }}</span
                      >
                    </v-btn>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-2 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 2"
                      :color="activeButton === 2 ? 'primary' : ''"
                      style="
                        border-radius: 8px;
                        height: 33.33%;
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                      "
                      @click="
                        setActive(2);
                        filterJobApplicationsByJobApplicationStatusCode(
                          'Pending'
                        );
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 2 }"
                        >ยื่นใบสมัคร/รอการตรวจสอบ
                        {{ quantity_of_pending_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="mb-2 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 3"
                      :color="activeButton === 3 ? 'primary' : ''"
                      style="border-radius: 8px; height: 33.33%; width: 100%"
                      @click="
                        setActive(3);
                        filterJobApplicationsByJobApplicationStatusCode(
                          'UnderReview'
                        );
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 3 }"
                        >อยู่ระหว่างพิจารณา
                        {{ quantity_of_underreview_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 4"
                      :color="activeButton === 4 ? 'primary' : ''"
                      style="border-radius: 8px; height: 33.33%; width: 100%"
                      @click="
                        setActive(4);
                        filterJobApplicationsByJobApplicationStatusCode(
                          'InterviewScheduled'
                        );
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 4 }"
                        >นัดหมายสัมภาษณ์
                        {{ quantity_of_interviewscheduled_jobapplication }}
                        คน</span
                      >
                    </v-btn>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn
                      class="mb-4 pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 5"
                      :color="activeButton === 5 ? 'primary' : ''"
                      style="border-radius: 8px; height: 50%; width: 100%"
                      @click="
                        setActive(5);
                        filterJobApplicationsByJobApplicationStatusCode(
                          'Accecpted'
                        );
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 5 }"
                        >รับเข้าทำงาน
                        {{ quantity_of_accecpted_jobapplication }} คน</span
                      >
                    </v-btn>
                    <v-btn
                      class="pa-2 light-gray-border justify-end"
                      rounded
                      :outlined="activeButton !== 6"
                      :color="activeButton === 6 ? 'primary' : ''"
                      style="border-radius: 8px; height: 50%; width: 100%"
                      @click="
                        setActive(6);
                        filterJobApplicationsByJobApplicationStatusCode(
                          'Rejected'
                        );
                      "
                    >
                      <span :class="{ 'primary--text': activeButton !== 6 }"
                        >ไม่ผ่านการพิจารณา
                        {{ quantity_of_rejected_jobapplications }}
                        คน</span
                      >
                    </v-btn>
                  </v-col>
                </v-row>
                <SearchableDataTable
                  table_caption="ใบสมัครงาน"
                  :data_items="filteredItems"
                  :datatable_headers="datatable_headers"
                >
                  <template v-slot:item.createddate="{ item }">
                    <FormatDate :date_value="item.createddate"></FormatDate>
                  </template>
                  <template
                    v-slot:item.jobapplicationstatus.statusname="{ item }"
                  >
                    <div class="d-flex justify-center">
                      <JobApplicationStatus_Label
                        :JobApplication="item"
                      ></JobApplicationStatus_Label>
                    </div>
                  </template>
                  <template v-slot:item.student.firstname="{ item }">
                    <span>{{ item.student.firstname }}</span>
                    {{ item.student.surname }}
                  </template>

                  <template v-slot:item.student.birthday="{ item }">
                    <span>{{
                      moment().diff(item.student.birthday, "years")
                    }}</span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top color="info">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openJobApplicationDetailView(item)"
                          color="info"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-file</v-icon>
                        </v-btn>
                      </template>
                      <span>แสดงรายละเอียดใบสมัครงาน</span>
                    </v-tooltip>
                  </template>
                </SearchableDataTable>
                <v-dialog v-model="dialog" max-width="700px">
                  <template>
                    <JobApplicatiionStatusManagement
                      :JobApplication="selected_jobapplication"
                      :sentopenBack="false"
                      @finished="onJobApplicationStatusManagementFinished"
                    ></JobApplicatiionStatusManagement>
                  </template>
                </v-dialog>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
import JobPostingStatus_Label from "@/components/JobPostings/JobPostingStatus_Label.vue";
import JobApplicationStatus_Label from "@/components/JobPostings/JobApplicationStatus_Label.vue";
import JobApplicatiionStatusManagement from "../๋JobPostings/JobApplicatiionStatusManagement.vue";
import moment from "moment";
import { JobPostingStatus } from "@/services/EnumHelper";
import FormatDateView from "@/components/common/FormatDateView.vue";

const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: {
    SearchableDataTable,
    JobPostingStatus_Label,
    JobApplicationStatus_Label,
    JobApplicatiionStatusManagement,
    FormatDateView,
  },
  name: "CenterStaffJobPostingDetailView",
  data() {
    return {
      oid: "",
      dialog: false,
      jobposting: undefined,
      jobapplications: [],
      selected_jobapplication: undefined,
      jobapplication_statuscode: "",
      quantity_of_all_jobapplication: 0,
      quantity_of_pending_jobapplication: 0,
      quantity_of_underreview_jobapplication: 0,
      quantity_of_interviewscheduled_jobapplication: 0,
      quantity_of_accecpted_jobapplication: 0,
      quantity_of_rejected_jobapplications: 0,
      datatable_headers: [
        { value: "createddate", text: "วันที่ยื่นใบสมัคร" },
        { value: "student.firstname", text: "ชื่อ-นามสกุล" },
        { value: "student.gendername_todisplay", text: "เพศ" },
        { value: "student.birthday", text: "อายุ" },
        { value: "student.majornameth", text: "สาขาวิชา" },
        { value: "jobapplicationstatus.statusname", text: "สถานะใบสมัคร" },
        { value: "actions", text: "ตัวเลือก", sortable: false },
      ],
      moment: moment,
      birthday: "",
      activeButton: null,
    };
  },
  computed: {
    filteredItems() {
      if (this.jobapplication_statuscode == "") {
        return this.jobapplications;
      } else {
        return this.jobapplications.filter(
          (x) =>
            x.jobapplicationstatus.statuscode == this.jobapplication_statuscode
        );
      }
    },
  },
  methods: {
    async loadJobPosting() {
      await employerJobPostingDataProvider
        .getJobPosting(this.oid)
        .then((result) => {
          this.jobposting = result.data;
          console.log(this.jobposting);
        });
    },
    async loadJobApplications() {
      await employerJobPostingDataProvider
        .getJobApplicationsByJobPostingOid(this.oid)
        .then((result) => {
          this.jobapplications = result.data;
        });
    },
    // openJobApplicationInfo(item) {
    //   const oid = item.oid;
    //   this.$router.push({ name: "JobApplicationInfo", params: { oid } });
    // },
    openJobApplicationDetailView(item) {
      const oid = item.oid;
      this.$router.push({
        name: "CenterStaffJobApplicationDetailView",
        params: { oid, sentopenBack: true },
      });
    },
    openJobApplicationStatusManagementDialog(item) {
      this.selected_jobapplication = item;
      this.dialog = true;
    },
    setActive(buttonNumber) {
      this.activeButton = buttonNumber;
    },
    async onJobApplicationStatusManagementFinished() {
      this.dialog = false;
      this.filterJobApplicationsByJobApplicationStatusCode("");
      await this.loadJobApplications();
    },
    updateAboutQuantityOfJobApplications() {
      this.quantity_of_all_jobapplication = this.jobapplications.length;
      this.quantity_of_pending_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Pending"
      ).length;
      this.quantity_of_interviewscheduled_jobapplication =
        this.jobapplications.filter(
          (x) => x.jobapplicationstatus.statuscode == "InterviewScheduled"
        ).length;
      this.quantity_of_underreview_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "UnderReview"
      ).length;
      this.quantity_of_accecpted_jobapplication = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Accecpted"
      ).length;
      this.quantity_of_rejected_jobapplications = this.jobapplications.filter(
        (x) => x.jobapplicationstatus.statuscode == "Rejected"
      ).length;
    },

    filterJobApplicationsByJobApplicationStatusCode(statuscode = "") {
      this.jobapplication_statuscode = statuscode;
    },
    openEmployerUpdateJobPostingView() {
      const oid = this.oid;
      this.$router.push({
        name: "EmployerUpdateJobPostingView",
        params: { oid },
      });
    },
    getStatusColor(statusCode) {
      const status = JobPostingStatus.find(
        (status) => status.statuscode === statusCode
      );
      return status ? status.color : "gray";
    },
  },
  async created() {
    if (this.$route.params.oid != undefined) {
      this.oid = this.$route.params.oid;
    }
    await this.loadJobPosting();
    await this.loadJobApplications();
    this.updateAboutQuantityOfJobApplications();
  },
  mounted() {
    this.daysDifference = moment().diff("1999-07-28", "years");
  },
};
</script>

<style scoped>
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.light-gray-border {
  border-color: #d3d3d3; /* หรือสีเทาอ่อนที่ต้องการ */
}
</style>
