<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" class="d-flex justify-start">
        <v-btn @click="openCreatEmployerProfileView()" dark color="primary" class="my-2">
          <v-icon class="mr-2">mdi-plus</v-icon> เพิ่มข้อมูลสถานประกอบการ
        </v-btn>
      </v-col>
    </v-row>
    <SearchableDataTable
      :data_items="employerprofiles"
      :datatable_headers="employerprofiles_data_table_headers"
      table_caption="รายชื่อสถานประกอบการ"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openEditEmployerProfileView(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file-edit</v-icon>
            </v-btn>
          </template>
          <span>แสดงรายละเอียด</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deleteEmployerProfile(item)"
              color="error"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบรายงานการติดตาม</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";

var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "EmployerProfileListView",
  components: { SearchableDataTable },
  data() {
    return {
      employerprofile_types: undefined,
      selected_employerprofile_type: undefined,
      employerprofiles: undefined,
      employerprofiles_data_table_headers: [
        // { "text": 'วันที่ลงทะเบียน', "value": 'createddate' },
        { text: "ชื่อสถานประกอบการ", value: "nameth" },
        { text: "จังหวัด", value: "province.provincethai" },
        { text: "ผู้ติดต่อ", value: "coordinator_firstname" },
        { text: "ชื่อบัญชีผู้ใช้งาน", value: "username" },
        { text: "สถานะ", value: "employerprofilestatus.nameth" },
        { text: "", value: "action" , sortable: false},
      ],
      search: "",
    };
  },
  methods: {
    async loadEmployerProfiles() {
      await employerProfileDataProvider
        .getEmployerProfilesBySchoolParterOfficerRole()
        .then((result) => {
          this.employerprofiles = result.data;
        });
    },

    async openCreatEmployerProfileView() {
      this.$router
        .push({ name: "SchoolPartnerOfficer_CreateEmployerProfileView" })
        .catch((err) => {});
    },

    async openEditEmployerProfileView(item) {
      var oid = item.oid;
      this.$router.push({
        name: "UpdateEmployerProfileView",
        params: { oid },
      });
    },

    async deleteEmployerProfile(item) {
      if (confirm("ยืนยัน")) {
        const oid = item.oid;
        await employerProfileDataProvider.deleteEmployerProfile(oid).then((result) => {
          this.loadEmployerProfiles();
        });
      }
    },
  },
  async created() {
    await this.loadEmployerProfiles();
  },
};
</script>

<style></style>
