<template>
  <v-container class="pa-2">
    <v-form>
      <v-autocomplete
        label="สถานศึกษา"
        :items="school_choices"
        v-model="selected_schoolID"
        item-text="schoolname"
        item-value="schoolid"
        :readonly="isSelectingFixed"
      ></v-autocomplete>
      <v-select
        label="ปีการศึกษา"
        :items="yearth_choices"
        v-model="selected_yearth"
        :readonly="isSelectingFixed"
      >
      </v-select>
      <v-select
        label="เทอม"
        :items="semesters_choices"
        v-model="selected_semesters"
        :readonly="isSelectingFixed"
      >
      </v-select>
      <v-row class="justify-center">
        <v-btn :loading="isImporting" @click="importGraduatedStudents()"
          ><v-icon>mdi-check-circle-outline</v-icon>ยืนยัน</v-btn
        >
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { GraduatedStudentDataProvider } from "@/services/GraduatedStudentDataProvider";
import { ImportGraduatedStudentRequest } from "@/models/GraduatedStudent/ImportGraduatedStudentsRequest";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
var schoolDataProvider = new SchoolDataProvider(backendapiUrl);
var commonDataProvider = new CommonDataProvider(backendapiUrl);
var graduatedStudentDataProvider = new GraduatedStudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "ImportGraduatedStudent",
  props: ["schoolGraduatedStudentOid", "schoolID", "yearth", "semesters"],
  data() {
    return {
      isImporting: false,
      isSelectingFixed: false,
      school_choices: undefined,
      selected_schoolID: "",
      yearth_choices: undefined,
      selected_yearth: "",
      semesters_choices: undefined,
      selected_semesters: "",
      request_data: new GetSchools_Reqeust(),
    };
  },
  methods: {
    async loadSchools() {
      await schoolDataProvider
        .loadSchools(this.request_data)
        .then((result) => {
          this.school_choices = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadYearth() {
      await commonDataProvider
        .getListOfYearTH()
        .then((result) => {
          this.yearth_choices = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadSemesters() {
      await commonDataProvider
        .getListOfSemesters()
        .then((result) => {
          this.semesters_choices = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },

    createImportGraduatedStudentsRequest() {
      let importGraduatedStudentsRequest = new ImportGraduatedStudentRequest();
      importGraduatedStudentsRequest.schoolID = this.selected_schoolID;
      importGraduatedStudentsRequest.yearth = this.selected_yearth;
      importGraduatedStudentsRequest.semesters = this.selected_semesters;
      return importGraduatedStudentsRequest;
    },

    async importGraduatedStudents() {
      var request = this.createImportGraduatedStudentsRequest();
      this.isImporting = true;
      await graduatedStudentDataProvider
        .importGraduatedStudents(request)
        .then((result) => {
          this.onImportingCompleted();
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {});
      this.isImporting = false;
    },

    onImportingCompleted() {
      this.$emit("emit_onImportingCompleted", "");
    },
  },
  async created() {
    await this.loadSchools();
    await this.loadYearth();
    await this.loadSemesters();

    if (
      this.schoolID != undefined &&
      this.yearth != undefined &&
      this.semesters != undefined
    ) {
      this.selected_schoolID = this.schoolID;
      this.selected_yearth = this.yearth;
      this.selected_semesters = this.semesters;
      this.isSelectingFixed = true;
    }
  },
};
</script>
