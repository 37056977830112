import TitlenameListView from "@/views/Titlename/TitlenameListView.vue";

export default [
  {
    path: "/Titlename",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Titlename/TitlenameListView",
        name: "TitlenameListView",
        component: TitlenameListView,
        meta: {
          requiresAuth: true,
          nameTh: "คำนำหน้าชื่อ",
        },
      },
    ],
  },
];
