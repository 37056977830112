<template>
  <div>
    <v-container v-if="NewsContent">
      <v-row>
        <v-col cols="12">
          <v-row class="d-flex align-center">
            <v-col cols="12" lg="7" md="6" sm="12" class="d-flex flex-column justify-start">
              <h1>ข่าวประชาสัมพันธ์</h1>
              <h4>ข่าวประชาสัมพันธ์ ข่าวแนะแนว</h4>
            </v-col>
            <v-col
              cols="12"
              lg="5"
              md="6"
              sm="12"
              class="d-flex  justify-end text-end"
            >
              <!-- <div style="width: 30%;"> -->
              <v-text-field
                filled
                rounded
                dense
                label="search.."
                v-model="keyword"
                append-icon="mdi-magnify"
              ></v-text-field>
              <!-- </div> -->
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row v-if="filteredNewsContents.length">
            <v-col
              cols="12"
              lg="3"
              md="6"
              sm="12"
              v-for="(item, index) in filteredNewsContents"
              :key="index"
              class="mb-5"
            >
              <v-card height="550px" class="d-flex flex-column">
                <v-container class="fill-height d-flex flex-row">
                  <v-row justify="center"
                    ><v-col
                      v-if="item.bannerthumbnailbase64"
                      class="d-flex align-center justify-center pa-0"
                      cols="12"
                    >
                      <v-card
                        flat
                        style="
                          height: 250px;
                          width: 100%;
                          background-color: #f2f4f8;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          overflow: hidden;
                        "
                      >
                        <v-img
                          alt="test"
                          class="shrink fill-height"
                          contain
                          transition="scale-transition"
                          aspect-ratio="1/1"
                          cover
                          v-bind:lazy-src="
                            'data:image/jpg;base64,' +
                            item.bannerthumbnailbase64
                          "
                          v-bind:src="
                            'data:image/jpg;base64,' +
                            item.bannerthumbnailbase64
                          "
                        />
                      </v-card>
                    </v-col>
                    <v-col v-else class="d-flex justify-center" cols="12">
                      <v-img
                        alt="test"
                        class="shrink fill-height"
                        contain
                        transition="scale-transition"
                        aspect-ratio="1/1"
                        cover
                        :src="require(`@/assets/user-img.jpg`)"
                      /> </v-col
                  ></v-row>
                  <v-row class="" style="height: 50%">
                    <v-col class="px-8" cols="12">
                      <v-row class="d-flex justify-start mb-2">
                        <span
                          text
                          class="grey--text text--darken-1"
                          style="float: left; padding-top: 5px; font-size: 12px"
                        >
                          <v-icon small>mdi-calendar-blank</v-icon>
                          &nbsp;{{ convertDateFormat(item.startdate) }}</span
                        >
                      </v-row>
                      <v-row>
                        <h4 class="primary--text" style="font-weight: bold">
                          {{ truncatedTitle(item.title) }}
                        </h4>
                      </v-row>
                      <v-row>
                        {{ truncatedDescription(item.description) }}
                      </v-row>
                      <!-- <v-row class="mt-5">
                        <v-col class="pa-0">
                          <p class="grey--text text--darken-1">
                            <v-icon>mdi-calendar-blank</v-icon>
                            {{ formattedDate }}
                          </p>
                          <p>
                            <v-icon>mdi-account</v-icon>
                            <span class="hover-underline">{{
                              item.writerfullname
                            }}</span>
                          </p>

                          <p>
                            <v-icon>mdi-folder</v-icon>
                            <span class="hover-underline">{{
                              item.category.name
                            }}</span>
                          </p>
                          <p>
                            <v-icon>mdi-tag</v-icon> {{ item.writerfullname }}
                          </p>
                          <p>
                            <v-icon>mdi-forum</v-icon>
                            <span class="hover-underline">Comment</span>
                          </p>
                        </v-col>
                      </v-row> -->
                    </v-col>
                  </v-row>
                </v-container>
                <v-row justify="center" class="mt-auto mb-1">
                  <!-- <v-col class="d-flex justify-start">
                    <span
                      text
                      class="secondary--text"
                      style="float: left; padding-top: 5px"
                    >
                      <v-icon color="primary">mdi-facebook</v-icon>
                      &nbsp;แชร์ไปยัง Facebook</span
                    >
                  </v-col> -->
                  <v-col>
                    <v-spacer></v-spacer>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      text
                      @click="goToNewsDetail(item.oid)"
                      color="primary"
                      >อ่านต่อ>></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <!-- <div v-if="filteredNewsContents.length">
            <div
              v-for="(item, index) in filteredNewsContents"
              :key="index"
              :data_items="NewsContent"
            >
              <br />
              <v-card
                class="text-start"
                max-width="700"
                max-height="1100"
                variant="text"
                rounded
                elevation="0"
              >
                <v-img
                  height="500"
                  contain
                  :src="'data:image/jpeg;base64,' + item.bannerthumbnailbase64"
                  @click="goToNewsDetail(item.oid)"
                ></v-img>
                <v-card-title
                  style="cursor: pointer"
                  class="primary--text two-line-title"
                  @click="goToNewsDetail(item.oid)"
                >
                  {{ item.title }}
                </v-card-title>
                <v-card-subtitle class="mt-2 two-line-content">
                  {{ item.description }}
                </v-card-subtitle>
                <v-card-subtitle style="overflow: hidden" class="secondary--text">
                  <v-icon>mdi-calendar-blank</v-icon>
                  <span class="grey--text text--darken-1"> {{ formattedDate }} By </span>

                  <v-icon>mdi-account</v-icon>
                 <span class="hover-underline" >{{ item.writerfullname }}</span>&nbsp; <v-icon>mdi-folder</v-icon
                  > <span class="hover-underline">{{ item.category.name }}</span> &nbsp;
                  <v-icon>mdi-tag</v-icon>
                  &nbsp;<span class="hover-underline" ></span>
                  <v-icon>mdi-forum</v-icon>
                  <span class="hover-underline">Comment</span>
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col class="d-flex justify-start">
                      <span text class="secondary--text" style="float: left; padding-top: 5px" >
                        <v-icon color="primary">mdi-facebook</v-icon>
                        &nbsp;แชร์ไปยัง Facebook</span
                      >
                    </v-col>
                    <v-col>
                      <v-spacer></v-spacer>
                    </v-col>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        text
                        @click="goToNewsDetail(item.oid)"
                        color="primary"
                       
                        >อ่านต่อ>></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <br />
                <hr />
              </v-card>
            </div>
          </div> -->
          <v-row justify="center" class="my-7">
            <span class="pt-2">จำนวนข่าวต่อหน้า</span>&nbsp; &nbsp;
            <div style="width: 7%">
              <v-select
                v-model="itemsPerPage"
                :items="itemNews"
                item-text="text"
                item-value="value"
                dense
                outlined
              ></v-select>
            </div>
            &nbsp; &nbsp;
            <div class="pt-1">
              &nbsp;
              <span>
                จำนวนข่าว {{ (currentPage - 1) * itemsPerPage + 1 }} -
                {{ Math.min(currentPage * itemsPerPage, totalItems) }} of
                {{ totalItems }}</span
              >
              &nbsp;
              <v-btn @click="prevPage" icon :disabled="currentPage === 1"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >

              <v-btn @click="nextPage" icon :disabled="!hasMorePages"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </div>
          </v-row>
        </v-col>
        <!-- ----------------------------------------------------------------------- -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { NewsDataProvider } from "@/services/NewsDataProvider";
import { formatDateThai } from "@/DateTimeHelper";

const newsDataProvider = new NewsDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "VcopNewsListView",
  components: {},
  data() {
    return {
      oid: "",
      NewsContent: [],
      itemlength: 0,
      newsOid: undefined,
      keyword: "",
      itemsPerPage: 5,
      currentPage: 1,
      itemNews: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
      ],
      totalItems: 0,
    };
  },
  methods: {
    goToNewsDetail(item) {
      this.$router.push({ name: "VcopNewsDetailView", params: { oid: item } });
    },
    async loadNews() {
      const requestData = {
        search: this.keyword,
        itemperpage: this.itemsPerPage,
        page: this.currentPage,
      };
      await newsDataProvider
        .loadNewsPaginationList(requestData)
        .then((result) => {
          this.NewsContent = result.data.news;
          this.totalItems = result.data.total;
        })
        .catch((err) => {
          alert(err);
        });
    },
    nextPage() {
      if (this.hasMorePages) {
        this.currentPage++;
        this.loadNews();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadNews();
      }
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.loadNews();
    },
    convertDateFormat(dateStr) {
      return formatDateThai(dateStr);
    },
  },

  async mounted() {
    await this.loadNews();
    this.NewsContent.length = this.itemlength;
  },
  computed: {
    formattedDate() {
      if (!this.NewsContent) return "";
      const date = new Date(this.NewsContent[0].createddate);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleString("th-TH", options);
    },
    filteredNewsContents() {
      if (!this.keyword) {
        this.NewsContent.filter((news) => {
          const newstitle = news.title.toLowerCase().includes(this.keyword);
          const namecategory = news.category?.name
            .toLowerCase()
            .includes(this.keyword);
          if (this.keyword.length === 1) {
            return newstitle || namecategory;
          }
        });
      }
      return this.NewsContent;
    },
    hasMorePages() {
      return this.currentPage * this.itemsPerPage < this.totalItems;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    truncatedTitle() {
      return (title) => {
        if (title.length > 120) {
          return title.substring(0, 120) + "...";
        }
        return title;
      };
    },
    truncatedDescription() {
      return (title) => {
        if (title.length > 80) {
          return title.substring(0, 80) + "...";
        }
        return title;
      };
    },
  },
  watch: {
    keyword() {
      this.currentPage = 1;
      this.loadNews();
    },
    itemsPerPage() {
      this.currentPage = 1;
      this.loadNews();
    },
  },
};
</script>

<style scoped>
h4 {
  color: #a5a5a5;
  margin-bottom: 20px;
}
.v-img {
  border-radius: 20px;
  margin-bottom: 5%;
}
.two-line-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 40px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.three-line-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 115px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.hover-underline:hover {
  cursor: pointer;
  text-decoration-line: underline;
}
</style>
