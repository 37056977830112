export function generateRouteObject(notificationItem) {
  const entitykey = notificationItem.entitykey;
  const entityreferencekey = notificationItem.entityreferencekey;
  const viewType = notificationItem.viewtype;
  switch (notificationItem.notificationeventkey) {
    case "CommonNotify": {
      return null;
    }
    case "GraduatedStudentJobTrackingReportInComming_Notify": {
      let routeObject = {
        name: "GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher",
        params: { trackingReportOid: entitykey },
      };
      return routeObject;
    }
    case "GraduatedStudentJobTrackingReportDueDate_Notify": {
      let routeObject = {
        name: "GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher",
        params: { trackingReportOid: entitykey },
      };
      return routeObject;
    }
    case "GraduatedStudentJobTrackingReportStudentImported": {
      let routeObject = {
        name: "GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher",
        params: { trackingReportOid: entitykey },
      };
      return routeObject;
    }
    case "JobPostingHasNewStudentRegistration": {
      let routeObject = {
        name: "JobApplicationDetailView",
        params: { oid: entitykey },
      };
      return routeObject;
    }
    case "JobApplicationHasChangeStatus": {
      let routeObject = {
        name: "JobApplicationDetailView",
        params: { oid: entitykey },
      };
      return routeObject;
    }
  }
}
