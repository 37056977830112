import { ApplicationUserDataProvider } from "./services/ApplicationUserDataProvider";

export const usernameRules = [
  (v) => !!v || "กรุณาป้อนชื่อผู้ใช้ (Username)",
  (v) =>
    (v || "").indexOf(" ") < 0 || "ชื่อผู้ใช้ (Username) ต้องไม่มีเว้นวรรค",
  (v) => /^[\w\s]+$/.test(v) || "กรุณากรอกภาษาอังกฤษ หรือ ตัวเลขเท่านั้น",
  (v) => (v && v.length >= 6) || "กรุณากรอกข้อมูลอย่างน้อย 6 ตัว",
  // (v) => !/(.)\1{4,}/.test(v) || "ชื่อผู้ใช้ไม่ควรมีตัวอักษรหรือตัวเลขเดียวกันต่อเนื่องกันเกิน 4 ตัว",
];

export const userStudentRules = [
  (v) => !!v || "กรุณาป้อนชื่อผู้ใช้ (Username)",
  (v) =>
    (v || "").indexOf(" ") < 0 || "ชื่อผู้ใช้ (Username) ต้องไม่มีเว้นวรรค",
  (v) => /^[\w\s]+$/.test(v) || "กรุณากรอกภาษาอังกฤษ หรือ ตัวเลขเท่านั้น",
  // (v) => !/(.)\1{4,}/.test(v) || "ชื่อผู้ใช้ไม่ควรมีตัวอักษรหรือตัวเลขเดียวกันต่อเนื่องกันเกิน 4 ตัว",
];

export const passwordRules = [
  (v) => !!v || "กรุณาป้อนรหัสผ่าน (Password)",
  (v) => (v && v.length >= 6) || "ต้องมีอย่างน้อย 6 ตัวอักษร",
  (v) => (v || "").indexOf(" ") < 0 || "รหัสผ่าน (Password) ต้องไม่มีเว้นวรรค",
];

export const confirmPasswordRules = [
  (v) => !!v || "กรุณายืนยันรหัสผ่าน (Confirmpassword)",
  (v) => (v || "").indexOf(" ") < 0 || "รหัสผ่าน (Password) ต้องไม่มีเว้นวรรค",
];

export const emailRules = [
  (v) => !!v || "กรุณาระบุอีเมล",
  (v) =>
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v
    ) || "อีเมลไม่ถูกต้อง",
];

export const phoneRules = [
  (v) => !!v || "กรุณาระบุเบอร์โทร",
  // (v) => /^\d{10}$/.test(v) || "เบอร์โทรไม่ถูกต้อง"
];

export const phoneStudentRules = [
  (v) => !!v || "กรุณาระบุเบอร์โทร",
  (v) => /^\d{10}$/.test(v) || "เบอร์โทรไม่ถูกต้อง",
];

export const idStudendRules = [
  (v) => !!v || "กรุณาระบุรหัสนักศึกษา",
  (v) => /^\d+$/.test(v) || "รหัสนักศึกษาต้องเป็นตัวเลขเท่านั้น",
  (v) => (v || "").indexOf(" ") < 0 || "รหัสนักศึกษา ต้องไม่มีเว้นวรรค",
  (v) => (v && v.length == 10) || "รหัสนักศึกษา 10 หลัก",
];

export const nameRules = [
  (v) => !!v || "กรุณาระบุข้อมูล",
  // (v) => /^[\u0E00-\u0E7F a-zA-Z]+$/.test(v) || "ข้อมูลไม่ถูกต้อง"
];
export const firstnameRules = [
  (v) => !!v || "กรุณาระบุชื่อ",
  // (v) => /^[\u0E00-\u0E7F a-zA-Z]+$/.test(v) || "ข้อมูลไม่ถูกต้อง"
];
export const lastnameRules = [
  (v) => !!v || "กรุณาระบุนามสกุล",
  // (v) => /^[\u0E00-\u0E7F a-zA-Z]+$/.test(v) || "ข้อมูลไม่ถูกต้อง"
];

export const hightStudendRules = [
  (v) => !!v || "กรุณาระบุส่วนสูง (ซม.)",
  (v) => /^\d+$/.test(v) || "ระบุเป็นตัวเลขเท่านั้น",
];

export const weightStudendRules = [
  (v) => !!v || "กรุณาระบุน้ำหนัก (กก.)",
  (v) => /^\d+$/.test(v) || "ระบุเป็นตัวเลขเท่านั้น",
];

export const wage = [
  (v) =>
    /^[1-9]\d{0,5}$|^0$/.test(v) ||
    "กรุณาระบุเงินเดือนมากกว่า 0 และเป็นจำนวนเต็มเท่านั้น",
];

export const unit = [
  (v) => !!v || "กรุณาระบุอัตราที่รับสมัคร",
  (v) =>
    (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999) ||
    "กรุณาระบุอัตราที่รับสมัครได้ไม่เกิน 4 หลัก",
  (v) =>
    /^[1-9]\d{0,5}$|^0$/.test(v) ||
    "กรุณาระบุอัตราที่รับสมัครมากกว่า 0 และเป็นจำนวนเต็มเท่านั้น",
];

export const profiletypecodeRules = [
  (v) => !!v || "กรุณาระบุเลขประจำตัวประชาชน/เลขนิติบุคคล",
  (v) =>
    !/(.)\1{4,}/.test(v) ||
    "เลขประจำตัวประชาชน/เลขนิติบุคคลไม่ควรมีตัวอักษรหรือตัวเลขเดียวกันต่อเนื่องกันเกิน 4 ตัว",
  (v) =>
    /^(?:\d{10}|\d{13})$/.test(v) ||
    "เลขประจำตัวประชาชน/เลขนิติบุคคลไม่ถูกต้อง",
];

export const identityidRules = [
  (v) => !!v || "กรุณาระบุเลขประจำตัวประชาชน/เลขนิติบุคคล",
  (v) =>
    /^\d+$/.test(v) || "เลขประจำตัวประชาชน/เลขนิติบุคคล ต้องเป็นตัวเลขเท่านั้น",
  (v) =>
    (v || "").indexOf(" ") < 0 ||
    "เลขประจำตัวประชาชน/เลขนิติบุคคล ต้องไม่มีเว้นวรรค",
  // (v) => /^(?:\d{10}|\d{13})$/.test(v) || "เลขประจำตัวประชาชน/เลขนิติบุคคลไม่ถูกต้อง"
];

export const security_numberRules = [
  (v) => !!v || "กรุณาระบุหมายเลขประกันสังคม",
  (v) => /^\d{10}$|^\d{13}$/.test(v) || "หมายเลขประกันสังคมไม่ถูกต้อง",
];

export const passwordSchoolRules = [
  (v) => !!v || "กรุณาระบุรหัสโรงเรียน",
  (v) => /^\d{10}$/.test(v) || "รหัสโรงเรียนไม่ถูกต้อง",
];

export const nameTHRules = [
  (v) => !!v || "กรุณาระบุข้อมูลเป็นภาษาไทย",
  (v) => /^[\u0E00-\u0E7F0-9]+$/.test(v) || "ข้อมูลไม่ถูกต้อง",
];

export const nameENRules = [
  (v) => !!v || "กรุณาระบุข้อมูลเป็นภาษาอังกฤษ",
  (v) => /^[a-zA-Z0-9]+$/.test(v) || "ข้อมูลไม่ถูกต้อง",
];

export const idcardRules = [
  (v) => !!v || "กรุณาระบุเลขประจำตัวประชาชน",
  (v) => /^\d{13}$/.test(v) || "รูปแบบหมายเลขบัตรประจำตัวประชนไม่ถูกต้อง",
];
