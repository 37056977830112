export class NavigationItemStructure {
    constructor(mdi_icon,route,navigationitem_names){
        this.mdi_icon = mdi_icon;
        this.route = route;
        this.navigationitem_names = navigationitem_names;
    }

    mdi_icon = ''
    navigationitem_names = ''
    route = ''
}