<template>
  <v-container class="pa-5">
    <SearchableDataTable
      table_caption="รายการตำแหน่งงาน"
      :data_items="bookmarkJobpostings"
      :datatable_headers="bookmarkedJob_table_headers"
    >
      <template v-slot:item.status="{ item }">
        <v-chip class="ma-2" dark :color="getColor(item.status)">
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="onOpenJobPostingInfo(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file</v-icon>
            </v-btn>
          </template>
          <span>รายละเอียดประกาศงาน</span>
        </v-tooltip>
        <v-tooltip top color="red">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="onDeleteInterestedJobPosting(item)"
              color="red"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>ลบ</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
  </v-container>
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { StudentUserBookmarkDataProvider } from "@/services/StudentUserBookmarkDataProvider";
import { DeleteInterestedInJobPostingBookmark_Request } from "@/models/StudentUserBookmarks/DeleteInterestedInJobPostingBookmark_Request";
import { deleteSelection } from "@devexpress/analytics-core/analytics-internal";

const studentUserBookmarkDataProvider = new StudentUserBookmarkDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "StudentBookmarkedJobPostings_ListView",
  data: () => ({
    schoolType: undefined,
    selected_schoolType: undefined,
    search: "",
    isImporting: false,

    search: "",

    bookmarkedJob_table_headers: [
      { text: "ตำแหน่งงาน", value: "positionname" },
      { text: "บริษัท", value: "employerprofile.nameth" },
      { text: "จังหวัด", value: "employerprofile.province.provincethai" },
      { text: "อัตรา", value: "unit", align: "center" },
      { text: "เงินเดือน", value: "wagetodisplay", align: "center" },
      { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
    ],
    bookmarkJobpostings: undefined,
    schools: undefined,
    dialog: false,
    selectedbookmarkedJob: [],
  }),
  methods: {
    openJobApplicationInfo(item) {
      const oid = item.oid;
      this.$router.push({ name: "JobApplicationInfo", params: { oid, item } });
    },
    async initialize() {
      await studentUserBookmarkDataProvider
        .getInterestedJobPostingBookmarks_As_JobPostingData()
        .then((result) => {
          console.log(result.data);
          this.bookmarkJobpostings = result.data;
        });
    },
    getColor(status) {
      if (status == "นัดสัมภาษณ์") return "info";
      else if (status == "กำลังพิจารณา") return "orange";
      else if (status == "รับเข้าทำงาน") return "green";
      else if (status == "ปฏิเสธการรับเข้าทำงาน") return "red";
      else return "black";
    },
    goToSearchJobDetails: function () {
      this.$router.push({ name: "SearchJobDetails" }).catch((err) => {});
    },
    async onDeleteInterestedJobPosting(item) {
      if (confirm("ยืนยัน?")) {
        const jobposting_oid = item.oid;
        const request = new DeleteInterestedInJobPostingBookmark_Request();
        request.JobPosting_Oid = jobposting_oid;
        await studentUserBookmarkDataProvider
          .DeleteInterestedInJobPostingBookmark(request)
          .then((result) => {
            this.$toast.warning("ลบข้อมูลประกาศงานที่สนใจเรียบร้อย");
            this.initialize();
          });
      }
    },
    async onOpenJobPostingInfo(item) {
      let oid = item.oid;
      this.$router.push({ name: "JobPostingInfo", params: { oid } });
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.red-text {
  color: red;
}
</style>
