import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import {
  LocalStorageKey_CertificateTypes,
  LocalStorageKey_ContinueToStudyInRelatedFieldStatusNames,
  LocalStorageKey_EmployerMainBusinessTypes,
  LocalStorageKey_EmployerProfileStatuses,
  LocalStorageKey_EmployerProfileTypes,
  LocalStorageKey_EmploymentCategories,
  LocalStorageKey_EmploymentStatusNames,
  LocalStorageKey_Genders,
  LocalStorageKey_JobApplicationEntityStatuses,
  LocalStorageKey_JobApplicationStatuses,
  LocalStorageKey_JobSegments,
  LocalStorageKey_Nationalities,
  LocalStorageKey_PayFrequencies,
  LocalStorageKey_PayRates,
  LocalStorageKey_Provinces,
  LocalStorageKey_Religions,
  LocalStorageKey_SalaryRages,
  LocalStorageKey_StudyCourseTypeNames,
  LocalStorageKey_TitleNames,
  LocalStorageKey_ActiveTitleNames,
} from "@/LocalStorageDataKeys";

import { TitlenameActive } from "@/models/Titlename/TitlenameActive";
export class MasterDataProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }

  getApplicationRoles() {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetApplicationRoles/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadSalaryRanges() {
    const cachedData = localStorage.getItem(LocalStorageKey_SalaryRages);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/getsalaryranges/";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .post(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_SalaryRages,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  loadEmployerProfileTypes() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_EmployerProfileTypes
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      const endpointPath = "/api/masterdata/GetEmployerProfileTypes";
      const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_EmployerProfileTypes,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  loadEmployerMainBusinessTypes() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_EmployerMainBusinessTypes
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetEmployerMainBusinessTypes";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_EmployerMainBusinessTypes,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  loadEmployerBusinessTypes(employerMainBusinessTypeCode) {
    const header = create_default_request_header();
    var endpointPath =
      "/api/masterdata/GetEmployerBusinessTypes/" +
      employerMainBusinessTypeCode;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getEmployerBusinessTypesByOid(oid = "") {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetEmployerBusinessTypeByOid/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, header);
  }

  loadEmployerProfileStatuses() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_EmployerProfileStatuses
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetEmployerProfileStatuses";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_EmployerProfileStatuses,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  loadEmploymentStatuses() {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetEmploymentStatuses";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadEmploymentStatusNames() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_EmploymentStatusNames
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetEmploymentStatusNames";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_EmploymentStatusNames,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }
  loadContinueToStudyInRelatedFieldStatusNames() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_ContinueToStudyInRelatedFieldStatusNames
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath =
        "/api/masterdata/GetContinueToStudyInRelatedFieldStatusNames";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_ContinueToStudyInRelatedFieldStatusNames,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  loadStudyCourseTypeNames() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_StudyCourseTypeNames
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetStudyCourseTypeNames/";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_StudyCourseTypeNames,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  loadGettingJobThatRelatedWithStudiedFieldStatusNames() {
    const cachedData = localStorage.getItem(
      `JobThatRelatedWithStudiedFieldStatusNames_${process.env.VUE_APP_VERSION}`
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath =
        "/api/masterdata/GetGettingJobThatRelatedWithStudiedFieldStatusNames/";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                `JobThatRelatedWithStudiedFieldStatusNames_${process.env.VUE_APP_VERSION}`,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  loadGetCertificationManagers(schoolid) {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetCertificationManagers/" + schoolid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadGetVerifyManagers(schoolid) {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetVerifyManagers/" + schoolid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadGetProvinces() {
    const cachedData = localStorage.getItem(LocalStorageKey_Provinces);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetProvinces";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      axios.get(combinedURL, header);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_Provinces,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  loadGetProvincesByRegionAsVECName(regionAsVECName = "") {
    const header = create_default_request_header();
    var endpointPath =
      "/api/masterdata/GetProvincesByRegionAsVECName/" + regionAsVECName;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadGetDistricts(provinceid) {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetDistricts/" + provinceid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadGetTambons(districtid) {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetTambons/" + districtid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadGetAddressByPostCode(postcode) {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetAddressByPostCode/" + postcode;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  loadGetCorporates() {
    const cachedData = localStorage.getItem(
      `Corporates_${process.env.VUE_APP_VERSION}`
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetCorporates";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                `Corporates_${process.env.VUE_APP_VERSION}`,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  // getTitleNames() {
  //   const cachedData = localStorage.getItem(LocalStorageKey_TitleNames);
  //   if (cachedData) {
  //     const data = JSON.parse(cachedData);
  //     return Promise.resolve({
  //       status: 200,
  //       data: data
  //     });
  //   } else {
  //     const header = create_default_request_header();
  //     const endpointPath = "/api/masterdata/GetTitleNames";
  //     const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);

  //     return axios
  //         .get(combinedURL, header)
  //         .then((response) => {
  //           if (response?.status == 200) {
  //             resolve(response);
  //             localStorage.setItem(
  //               LocalStorageKey_TitleNames,
  //               JSON.stringify(response.data)
  //             );
  //           }
  //         })
  //         .catch((err) => {
  //           return Promise.reject(err);
  //         });
  //   }
  // }

  getTitleNames() {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetTitleNames";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getTitleNameByOid(oid = "") {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetTitleNameByOid/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, header);
  }

  setTitleNameActiveStatus(request = new TitlenameActive()) {
    const data = JSON.stringify(request);
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/SetTitleNameActiveStatus";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, data, header);
  }

  // setTitleNameActiveStatus(vm = new TitlenameActive()) {
  //   const header = create_default_request_header()
  //   const endpointUrl = "/api/masterdata/SetTitleNameActiveStatus";
  //   const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl)
  //   return axios.post(combinedURL, {
  //     titlenameid: vm.titlenameid,
  //     titlenameth: vm.titlenameth,
  //     titlenameeng: vm.titlenameeng,
  //     isactive: vm.isactive,
  //   }, {headers: header})
  //   .then((response) => response)
  //   .catch((err) => {
  //     return Promise.reject(err);
  //   })
  // }

  getActiveTitleNames() {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetActiveTitleNames";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  // getActiveTitleNames() {
  //   const cachedData = localStorage.getItem(LocalStorageKey_ActiveTitleNames);
  //   if (cachedData) {
  //     const data = JSON.parse(cachedData);
  //     return Promise.resolve({
  //       status: 200,
  //       data: data
  //     });
  //   } else {
  //     const header = create_default_request_header();
  //     var endpointPath = "/api/masterdata/GetActiveTitleNames";
  //     var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);

  //     return axios
  //         .get(combinedURL, header)
  //         .then((response) => {
  //           if (response?.status == 200) {
  //             resolve(response);
  //             localStorage.setItem(
  //               LocalStorageKey_ActiveTitleNames,
  //               JSON.stringify(response.data)
  //             );
  //           }
  //         })
  //         .catch((err) => {
  //           return Promise.reject(err);
  //         });
  //   }
  // }

  getNationalities() {
    const cachedData = localStorage.getItem(LocalStorageKey_Nationalities);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetNationalities";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_Nationalities,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getNationalityByOid(oid = "") {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetNationalityByOid/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, header);
  }

  getReligions() {
    const cachedData = localStorage.getItem(LocalStorageKey_Religions);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetReligions";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_Religions,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getReligionByOid(oid = "") {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetReligionByOid/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, header);
  }

  getJobSegment() {
    const cachedData = localStorage.getItem(LocalStorageKey_JobSegments);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetJobSegment";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_JobSegments,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }
  getEmploymentCategories() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_EmploymentCategories
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetEmploymentCategories";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_EmploymentCategories,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getJobApplicationStatuses() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_JobApplicationStatuses
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetJobApplicationStatuses";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_JobApplicationStatuses,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getGenders() {
    const cachedData = localStorage.getItem(LocalStorageKey_Genders);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetGenders";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_Genders,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getPayRates() {
    const cachedData = localStorage.getItem(LocalStorageKey_PayRates);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetPayRates";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_PayRates,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getPayFrequencies() {
    const cachedData = localStorage.getItem(LocalStorageKey_PayFrequencies);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetPayFrequencies";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_PayFrequencies,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getCertificateTypes() {
    const cachedData = localStorage.getItem(LocalStorageKey_CertificateTypes);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/GetCertificateTypes";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_CertificateTypes,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getCertificateTypesByVECEducationInformationsFromSchoolId(schoolid = "") {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetCertificateTypesByVECEducationInformationsFromSchoolId/" +
      schoolid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, null, header);
  }
  GetCertificationTypeFromGraduatedStudentInVECEducationInformationBySchool(
    schoolid = "",
    graduatedYear = ""
  ) {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetCertificationTypeFromGraduatedStudentInVECEducationInformationBySchool";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = {
      SchoolID: schoolid,
      GradautedYearTH: graduatedYear,
    };
    const jsonData = JSON.stringify(data);
    console.log(jsonData);
    return axios.post(combinedURL, jsonData, header);
  }

  getStudentMajors() {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetStudentMajors";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getMajorsBySubjectTypeCode(subjectTypeCode = "") {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetMajorsBySubjectTypeCode/" + subjectTypeCode;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getMajorsBySubjectTypeNameTh(subjectTypeNameTh = "") {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetMajorsBySubjectTypeNameTh";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    // Sending data as the second argument in axios.post
    const data = JSON.stringify({ subjectTypeNameTh: subjectTypeNameTh });
    return axios.post(combinedURL, data, header);
  }

  getMajorsBySubjectTypeNameThBySchoolID(
    schoolID = "",
    subjectTypeNameTh = ""
  ) {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetMajorsBySubjectTypeNameThBySchoolID";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    // Sending data as the second argument in axios.post
    const data = JSON.stringify({
      schoolID: schoolID,
      subjectTypeNameTh: subjectTypeNameTh,
    });
    return axios.post(combinedURL, data, header);
  }

  getStudentMinors() {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetStudentMinors";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getStudentMinorsFromSubjectTypeID(subjectTypeID = "") {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetStudentMinorsFromSubjectTypeID/" + subjectTypeID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getMinorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
    schoolID = "",
    majorNameTh = "",
    subjectTypeNameTh = ""
  ) {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetMinorThNamesFromGraduatedStudentInVECEducationInformationBySchool";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = {
      SchoolID: schoolID,
      MajorNameTh: majorNameTh,
      SubjectTypeNameTh: subjectTypeNameTh,
    };
    const jsonData = JSON.stringify(data);
    return axios.post(combinedURL, jsonData, header);
  }

  getStudentMajorsFromSubjectTypeID(subjectTypeID = "") {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetStudentMajorsFromSubjectTypeID/" + subjectTypeID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getMinorsByMajorNameTh(majorNameTh = "") {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetMinorsByMajorNameTh";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    const data = JSON.stringify({ majorNameTh });
    return axios.post(combinedURL, data, header);
  }

  getMinorsByMajorNameThBySchoolID(
    schoolID = "",
    majorNameTh = "",
    subjectTypeNameTh = ""
  ) {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetMinorsByMajorNameThBySchoolID";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    // Sending data as the second argument in axios.post
    const data = JSON.stringify({
      schoolID: schoolID,
      majorNameTh: majorNameTh,
      subjectTypeNameTh: subjectTypeNameTh,
    });
    return axios.post(combinedURL, data, header);
  }

  getStudentUpdateProfileStatuses() {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetStudentUpdateProfileStatuses";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  GetEducationalQualifications() {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetEducationalQualifications";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getJobPostingEntityStatuses() {
    const header = create_default_request_header();
    const endpointPath = "/api/masterdata/GetJobPostingEntityStatuses";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getJobApplicationEntityStatuses() {
    const cachedData = localStorage.getItem(
      LocalStorageKey_JobApplicationEntityStatuses
    );
    if (cachedData) {
      const data = JSON.parse(cachedData);

      return new Promise((resolve) => {
        let response = [];
        response.status = 200;
        response.data = data;
        resolve(response);
      });
    } else {
      const header = create_default_request_header();
      var endpointPath = "/api/masterdata/getJobApplicationEntityStatuses";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
      return new Promise((resolve, reject) => {
        axios
          .get(combinedURL, header)
          .then((response) => {
            if (response?.status == 200) {
              resolve(response);
              localStorage.setItem(
                LocalStorageKey_JobApplicationEntityStatuses,
                JSON.stringify(response.data)
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  getAllGraduatedStudentJobTrackingReportMainTHYears() {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetAllGraduatedStudentJobTrackingReportMainTHYears";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getAllGraduatedStudentJobTrackingReportMainRoundNumbers(yearTh) {
    const header = create_default_request_header();
    const endpointPath =
      "/api/masterdata/GetAllGraduatedStudentJobTrackingReportMainRoundNumbers/" +
      yearTh;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getListOfAllYearTH() {
    const header = create_default_request_header();
    var endpointPath = "/api/masterdata/GetListOfAllYearTH";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, header);
  }
}
