<template>
  <v-dialog v-model="RewardStudent_Dialog" width="850" persistent>
    <v-card class="pa-10">
      <h2 class="primary--text pt-5">รางวัล</h2>
      <v-divider class="mt-4 mb-5 mx-0"></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="align-center">
          <v-text-field
            label="ชื่อรางวัล"
            filled
            background-color="input"
            dense
            v-model="vm.topic"
          >
          </v-text-field>
        </v-row>
        <v-row class="align-center" height="10">
          <TextFieldDatePicker
            :value="selected_RewardDate"
            caption="วันที่ได้รับ"
            @closeAndUpdate="this.closeAndUpdateTextFieldDatePicker_RewardDate"
            :rules="[(v) => !!v || 'กรุณาระบุวันที่']"
          >
          </TextFieldDatePicker>
        </v-row>
        <v-row class="justify-center text-center mt-7 mb-4">
          <v-col cols="12">
            <v-btn @click="onCancelClick()" class="mx-2" color=""> ยกเลิก </v-btn>
            <v-btn
              :loading="isInCreatingProcess"
              dark
              @click="onSaveClick()"
              class="mx-3 color-buton"
              >บันทึก<v-icon class="ml-2"> mdi-content-save</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-dialog v-model="confirm_dialog" width="400">
          <template>
            <v-card class="pa-6">
              <v-row class="justify-center text-center">
                <v-card-title> ยืนยันเพิ่มรายการรางวัล ? </v-card-title>
                <v-col cols="12">
                  <v-btn @click="onButtonClick()" class="ma-2" color="success">
                    ยืนยัน
                  </v-btn>
                  <v-btn
                    @click="confirm_dialog = false"
                    class="ma-2"
                    color=""
                    elevation="2"
                    >ยกเลิก</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-dialog>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import { CreateExperienceStudentDetailViewMain_Request } from "@/models/School/CreateExperienceStudentDetailViewMain_Request";
import { RewardRecord } from "@/models/Student/RewardRecord";
var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  name: "RewardStudentDialog",
  props: { RewardStudent_Dialog: Boolean, oid: String },
  data() {
    return {
      vm: new RewardRecord(),
      isInCreatingProcess: false,
      confirm_dialog: false,
      confirm_result: false,
      selected_RewardDate: "",
      valid: false,
    };
  },
  watch: {
    RewardStudent_Dialog(value) {
      if (value) {
        if (this.oid != "-1") {
          this.loadStudentRewardRecord(this.oid);
        } else {
          this.vm = new RewardRecord();
        }
      }
    },
  },
  methods: {
    closeAndUpdateTextFieldDatePicker_RewardDate(selectedDate = "") {
      this.vm.rewarddate = selectedDate;
    },
    async loadStudentRewardRecord(oid) {
      await studentDataProvider
        .getStudentRewardRecord(oid)
        .then(async (response) => {
          this.vm = response.data;
          //bind start date
          this.selected_RewardDate = this.vm.rewarddate;
        })
        .catch((response) => {
          this.$toast.error("ล้มเหลว!!");
        });
    },
    onSaveClick() {
      if (this.$refs.form.validate()) {
        this.confirm_dialog = true;
      }
    },
    onButtonClick() {
      if (this.$refs.form.validate()) {
        this.isInCreatingProcess = false;
        this.$emit("closeDialog", this.vm, true);
        this.resetForm();
      }
    },
    onCancelClick() {
      this.resetForm();
      this.$emit("closeDialog", this.vm, false);
    },
    resetForm() {
      this.vm = new RewardRecord();
      this.selected_RewardDate = "";
      this.$refs.form.reset();
    },
  },
  watch: {},
  mounted() {},
  components: { TextFieldDatePicker },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
