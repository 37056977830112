export const ComponentNames_To_Exclude =  
['AccountDetailView','CenterStaffProfileDetailView','SchoolEmployeeProfileDetailView',
 'SchoolEmployeeProfileDetailView'];

export const ComponentNames_To_Include = [
    'SchoolListView','SchoolGraduatedStudentListView','SchoolEmployee_SchoolListView',
    ,'GraduatedStudentJobTrackingReportListView',
    'GraduatedStudentJobTrackingReportListView_ForCertification',
    'CurriculumListView','SubjectTypeListView','MajorListView','MinorListView','GovernmentListView','TitlenameListView',
    
]