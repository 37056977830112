var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"pt-6 px-3"},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"primary"}},[_vm._v("mdi-briefcase")]),_c('h3',{staticClass:"primary--text"},[_vm._v("ประวัติการทำงาน")])],1),_c('v-row',{staticClass:"px-3",staticStyle:{"margin-top":"1%","margin-bottom":"2%"}},[_c('v-divider')],1),(
        _vm.experience.length > 0 &&
        _vm.$vuetify.breakpoint.lgAndUp &&
        _vm.$vuetify.breakpoint.mdAndUp
      )?_c('v-row',_vm._l((_vm.experience),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-card',{staticClass:"py-3"},[_c('v-row',{staticClass:"px-9 py-5"},[_c('v-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c('v-row',{staticClass:"pb-3"},[_c('h3',[_vm._v(_vm._s(item.organizationname ? item.organizationname : "-"))])]),_c('v-row',[_c('span',[_vm._v(" ระยะเวลา : "),(item.startdate && item.enddate)?[_vm._v(" "+_vm._s(_vm.formatDate(item.startdate))+" - "+_vm._s(_vm.formatDate(item.enddate))+" ")]:(item.startdate || item.enddate)?[_vm._v(" "+_vm._s(_vm.formatDate(item.startdate || item.enddate))+" - ไม่ระบุ ")]:[_vm._v(" ไม่ระบุ ")]],2)]),_c('v-row',[_c('span',[_vm._v("ตำแหน่ง : "+_vm._s(item.positionname ? item.positionname : "-"))])]),_c('v-row',[_c('span',[_vm._v("เงินเดือน : "+_vm._s(item.salaryrange ? item.salaryrange.salary_range : "-")+" ")])]),_c('v-row',[_c('span',[_vm._v("หน้าที่รับผิดชอบ : "+_vm._s(item.jobdescription ? item.jobdescription : "-"))])])],1)],1)],1)],1)}),1):(
        _vm.experience.length > 0 &&
        !_vm.$vuetify.breakpoint.lgAndUp &&
        !_vm.$vuetify.breakpoint.mdAndUp
      )?_c('v-row',_vm._l((_vm.experience),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-3"},[_c('v-row',{staticClass:"px-9 py-5"},[_c('v-col',{attrs:{"cols":"6","xl":"6","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('v-row',{staticClass:"pb-3"},[_c('h3',[_vm._v(_vm._s(item.organizationname ? item.organizationname : "-"))])]),_c('v-row',[_c('span',[_vm._v(" ระยะเวลา : "),(item.startdate && item.enddate)?[_vm._v(" "+_vm._s(_vm.formatDate(item.startdate))+" - "+_vm._s(_vm.formatDate(item.enddate))+" ")]:(item.startdate || item.enddate)?[_vm._v(" "+_vm._s(_vm.formatDate(item.startdate || item.enddate))+" - ไม่ระบุ ")]:[_vm._v(" ไม่ระบุ ")]],2)]),_c('v-row',[_c('span',[_vm._v("ตำแหน่ง : "+_vm._s(item.positionname))])]),_c('v-row',[_c('span',[_vm._v("เงินเดือน : "+_vm._s(item.salaryrange ? item.salaryrange.salary_range : "ไม่ระบุ")+" ")])]),_c('v-row',[_c('span',[_vm._v("หน้าที่รับผิดชอบ : "+_vm._s(item.jobdescription))])])],1)],1)],1)],1)}),1):_c('v-row',{},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-16 px-3 d-flex justify-center",attrs:{"max-width":"100%"}},[_c('span',[_vm._v("ไม่มีข้อมูล")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }