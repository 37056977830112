<template>
  <v-container class="pa-5">
    <SearchableDataTable
      table_caption="ประวัติคนหางานที่เก็บไว้"
      :data_items="dataInterestedInStudentBookmarks"
      :datatable_headers="bookmarkedStudent_table_headers"
    >
      <template v-slot:item.firstname="{ item }">
        <span>{{ item.firstname }}</span> {{ item.surname }}
      </template>

      <template v-slot:item.gender="{ item }">
        <span v-if="item.gender == 'F'">หญิง</span>
        <span v-if="item.gender == 'M'">ชาย</span>
      </template>

      <template v-slot:item.birthday="{ item }">
        <span>{{ moment().diff(item.birthday, "years") }}</span>
      </template>

      <template v-slot:item.action="{ item }">
        <v-row class="custom-width">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openStudentDetailOverview(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <span>รายละเอียด</span>
          </v-tooltip>

          <v-tooltip top color="red">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="(dialog = true), (selectedbookmarkedStudent = item)"
                color="red"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>ลบ</span>
          </v-tooltip></v-row
        >
      </template>
    </SearchableDataTable>
    <v-dialog v-model="dialog" persistent max-width="450">
      <template>
        <v-card class="pa-6">
          <v-row class="justify-center text-center">
            <v-card-title>
              ต้องการลบประวัติคนหางานที่เก็บไว้หรือไม่ ?
            </v-card-title>
            <v-col cols="12">
              <v-btn
                @click="onclickdeleteconfirm()"
                class="ma-2"
                dark
                color="red"
              >
                ตกลง
              </v-btn>
              <v-btn @click="dialog = false" class="ma-2" color="" elevation="2"
                >ยกเลิก</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { EmployerUserbookmarkDataProvider } from "@/services/EmployerUserbookmarkDataProvider.js";
import { SaveInterestedInStudentBookmark_Request } from "@/models/EmployerUserbookmark/SaveInterestedInStudentBookmark_Request";
import moment from "moment";

const employerUserbookmarkDataProvider = new EmployerUserbookmarkDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "EmployerBookmarkedStudentListView",
  data: () => ({
    request: new SaveInterestedInStudentBookmark_Request(),
    search: "",
    bookmarkedStudent_table_headers: [
      { text: "ชื่อ-สกุล", value: "firstname" },
      { text: "เพศ", value: "gender" },
      { text: "อายุ", value: "birthday" },
      { text: "ระดับชั้น", value: "gradenameth" },
      { text: "สาขาวิชา", value: "majornameth" },
      { text: "จังหวัด", value: "tambon.district.province.provincethai" },
      { text: "ตัวเลือก", value: "action", align: "center" , sortable: false},
    ],
    dialog: false,
    selectedbookmarkedStudent: [],
    dataInterestedInStudentBookmarks: [],
    moment: moment,
  }),
  methods: {
    openStudentDetailOverview(item) {
      var studentOid = item.oid;
      const routeData = this.$router.resolve({
        name: "StudentDetailOverview",
        params: { studentoid: studentOid },
      });
      window.open(routeData.href, "_blank");
    },
    async onclickdeleteconfirm() {
      // this.Index = this.bookmarkedStudent.indexOf(this.selectedbookmarkedStudent);
      // this.bookmarkedStudent.splice(this.Index, 1);
      this.request.Student_Oid = this.selectedbookmarkedStudent.oid;
      this.dialog = false;
      await employerUserbookmarkDataProvider
        .deleteInterestedInStudentBookmark(this.request)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("ลบประวัติคนหางานที่เก็บไว้สำเร็จ");
            this.loadInterestedInStudentBookmarks();
          }
        })
        .catch((res) => {
          this.$toast.error("ลบประวัติคนหางานที่เก็บไว้ล้มเหลว !!!" + res);
        });
    },

    async loadInterestedInStudentBookmarks() {
      employerUserbookmarkDataProvider
        .loadInterestedInStudentBookmarksAsStudentData()
        .then((result) => {
          this.dataInterestedInStudentBookmarks = result.data;
          if (result.data.gender == "F") {
            this.dataInterestedInStudentBookmarks.gender = "หญิง";
          } else this.dataInterestedInStudentBookmarks.gender = "ชาย";
        });
    },
  },
  components: { SearchableDataTable },
  async created() {
    await this.loadInterestedInStudentBookmarks();
  },
  mounted() {
    this.daysDifference = moment().diff("1999-07-28", "years");
  },
};
</script>

<style scoped>
.custom-width {
  width: 120px;
  gap: 10px;
}
</style>
