import CreateJobAdvertisement from "@/views/JobAdvertisement/CreateJobAdvertisement.vue";
import EditJobAdvertisement from "@/views/JobAdvertisement/EditJobAdvertisement.vue";

export default [
  {
    path: "/JobAdvertisement",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/JobAdvertisement/CreateJobAdvertisement",
        name: "CreateJobAdvertisement",
        component: CreateJobAdvertisement,
      },
      {
        path: "/JobAdvertisement/EditJobAdvertisement",
        name: "EditJobAdvertisement",
        component: EditJobAdvertisement,
      },
    ],
  },
];
