<template>
  <v-container class="pa-5">
    <v-form class="elevation-1" ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2"> mdi-briefcase </v-icon
            >เพิ่มประเภทงาน</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col sm="12" md="12" lg="12">
              <v-text-field
                v-model="vm.name"
                label="ชื่อประเภทงาน"
                :rules="[(v) => !!v || 'กรุณาระบุชื่อประเภทงาน']"
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center mb-4 mt-2">
            <v-btn @click="$router.go(-1)" class="ma-2 back-button"
              >ย้อนกลับ</v-btn
            >
            <v-btn class="ma-2 color-button2" @click="CreateJobSegment"
              >บันทึก <v-icon>mdi-content-save</v-icon></v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { JobSegmentDataProvider } from "@/services/JobSegmentDataProvider";
import { CreateJobSegment_Request } from "@/models/JobSegment/CreateJobSegment_Request";
var jobSegmentDataProvider = new JobSegmentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "CreateJobSegmentDetailView",
  data() {
    return {
      valid: true,
      vm: new CreateJobSegment_Request(),
    };
  },

  methods: {
    async CreateJobSegment() {
      if (this.$refs.form.validate()) {
        await jobSegmentDataProvider
          .createJobSegment(this.vm)
          .then((response) => {
            this.$toast.success("เพิ่มประเภทงานเรียบร้อย !");
            this.$router.go(-1);
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
  },

  async created() {},
};
</script>

<style>
input[type="text"][disabled] {
  color: black;
}
</style>
