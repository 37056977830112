<template>
  <div class="pa-4 mt-2">
    <v-row>
      <v-col class="col-12 justify-center">
        <PanelNotSendJobTrackingReport></PanelNotSendJobTrackingReport>
      </v-col>
      <v-col class="col-12 d-flex justify-center">
        <div>
          <div class="pa-3">
            <v-row class="optionSelectRow">
              <v-col cols="12">
                <h4>แสดงภาพรวม</h4>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="ปีการศึกษาที่จบการศึกษา"
                  :items="TargetGraduatedYearTHChoices"
                  v-model="SelectedTargetGraduatedYearTH"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="รอบ"
                  :items="RoundNumberChoices"
                  v-model="SelectedRoundNumber"
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col cols="6">
                <PanelSendingReportStatus
                  :SelectedTargetGraduatedYearTH="SelectedTargetGraduatedYearTH"
                  :SelectedRoundNumber="SelectedRoundNumber"
                /> </v-col
              ><v-col cols="6">
                <PanelJobTrackingStatus
                  :SelectedTargetGraduatedYearTH="SelectedTargetGraduatedYearTH"
                  :SelectedRoundNumber="SelectedRoundNumber"
                />
              </v-col>
              <v-col cols="12">
                <DoughnutJobTrackingStatus
                  :SelectedTargetGraduatedYearTH="SelectedTargetGraduatedYearTH"
                  :SelectedRoundNumber="SelectedRoundNumber"
                >
                </DoughnutJobTrackingStatus>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col>
                <BarChartJobTrackingByRegion
                  :SelectedTargetGraduatedYearTH="SelectedTargetGraduatedYearTH"
                  :SelectedRoundNumber="SelectedRoundNumber"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BarChartJobTrackingByRegion from "@/components/Chart/BarChartJobTrackingByRegion.vue";
import PanelJobTrackingStatus from "@/components/Chart/PanelJobTrackingStatus.vue";
import PanelSendingReportStatus from "@/components/Chart/PanelSendingReportStatus.vue";
import DoughnutJobTrackingStatus from "@/components/Chart/DoughnutJobTrackingStatus.vue";
import PanelNotSendJobTrackingReport from "@/components/Chart/PanelNotSendJobTrackingReport.vue";
import { backendapiUrl } from "@/services/constants";
import { CommonDataProvider } from "@/services/CommonDataProvider";
var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  components: {
    BarChartJobTrackingByRegion,
    PanelSendingReportStatus,
    PanelJobTrackingStatus,
    DoughnutJobTrackingStatus,
    PanelNotSendJobTrackingReport,
  },
  data() {
    return {
      TargetGraduatedYearTHChoices: [],
      SelectedTargetGraduatedYearTH: "",
      RoundNumberChoices: [],
      SelectedRoundNumber: "",
    };
  },
  mounted() {
    this.loadTargetGraduatedYearTHChoices();
    this.RoundNumberChoices = ["1", "2", "3", "4"];
  },
  methods: {
    async loadTargetGraduatedYearTHChoices() {
      await commonDataProvider.getListOfYearTH().then((result) => {
        this.TargetGraduatedYearTHChoices = result.data.reverse();
      });
    },
    // async loadSemesterChoices() {
    //   await commonDataProvider.getListOfSemesters().then((result) => {
    //     this.SemesterChoices = result.data;
    //   });
    // },
  },
};
</script>

<style></style>
