<template>
  <div class="pa-4">
    <v-card class="pa-4">
      <h3 class="primary--text">ตรวจสอบและส่งรายงานติดตามภาวะการมีงานทำ</h3>
      <v-divider class="mt-4 mb-4 mx-0"></v-divider>
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="graduatedStudentJobTrackingReport.schoolname"
            label="โรงเรียน"
            readonly
            :rules="[(v) => !!v || 'ไม่พบข้อมูล']"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="graduatedStudentJobTrackingReport.targetgraduatedyearth"
            label="ปีการศึกษาจบที่ติดตาม"
            readonly
            :rules="[(v) => !!v || 'ไม่พบข้อมูล']"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="graduatedStudentJobTrackingReport.roundnumber"
            label="รอบ"
            readonly
            :rules="[(v) => !!v || 'ไม่พบข้อมูล']"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-app-bar class="mt-4">
      <div>
        <div class="secondary--text">สถานะการส่ง</div>
        <div v-if="graduatedStudentJobTrackingReport.iscertified === true">
          <label color="warning" class="success--text">
            <v-icon class="mr-1" color="success">mdi-file-sign</v-icon>
            <strong>ยืนยันส่งรายงาน</strong>
          </label>
          <label class="ma-5" v-if="graduatedStudentJobTrackingReport.verifymanager">
            <strong>ส่งโดย </strong
            >{{ graduatedStudentJobTrackingReport.verifymanager.name }}
            {{ graduatedStudentJobTrackingReport.verifymanager.surname }}
            <strong>ตำแหน่ง </strong>
            {{ graduatedStudentJobTrackingReport.verifymanager.position }}
          </label>
          <label class="ma-5" v-if="graduatedStudentJobTrackingReport.sendertocomplete">
            <strong>ส่งรายงานโดย </strong
            >{{ graduatedStudentJobTrackingReport.sendertocomplete.name }}
            {{ graduatedStudentJobTrackingReport.sendertocomplete.lastname }}
          </label>
        </div>
        <div v-else>
          <label color="warning" class="warning--text">
            <v-icon class="mr-1" color="warning">mdi-progress-clock</v-icon>
            <strong>รอยืนยันการส่งรายงาน</strong>
          </label>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-col sm="12" md="6" lg="12">
          <v-btn
            v-if="showbtn_iscertified"
            @click="certifyPopup()"
            color="success"
            class="ma-2"
          >
            <v-icon class="mr-1">mdi-file-sign</v-icon>ยืนยันส่งรายงาน
          </v-btn>
          <v-btn color="" class="ma-2" @click="$router.go(-1)">
            <span>ย้อนกลับ</span>
          </v-btn>
        </v-col>
      </div>
    </v-app-bar>
    <div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <SearchableDataTable
          v-if="studentDetails != undefined"
          table_caption="รายชื่อนักศึกษาที่ต้องติดตาม"
          :data_items="studentDetails"
          :datatable_headers="studentDetails_headers"
        >
        </SearchableDataTable>
      </v-form>
    </div>
    <DialogCertificationConfirm
      v-if="verifymanager"
      @closeDialog="onCertificationIsCompleted"
      :CertificationConfirm_Dialog="CertificationConfirm_Dialog"
      :verifymanager="verifymanager"
    ></DialogCertificationConfirm>
    <CustomSnackbar
      v-on:close-snackbar="closesnackbar"
      :color="colorsnack"
      :text="textsnack"
      :snackbar="statussnack"
    >
    </CustomSnackbar>
  </div>
</template>
<script>
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import DialogCertificationConfirm from "@/components/GraduatedStudentJobTrackingReport/DialogCertificationConfirm.vue";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import CustomSnackbar from "@/components/common/CustomSnackbar.vue";
var graduatedStudentJobTrackingReportReportDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: {
    CustomSnackbar,
    SearchableDataTable,
    DialogCertificationConfirm,
  },
  name: "GraduatedStudentJobTrackingReportDetailView_ForCertification",
  data() {
    return {
      statussnack: false,
      colorsnack: "",
      textsnack: "",

      valid: true,
      studentDetails: undefined,
      studentDetails_headers: [
        { value: "student.studentcode", text: "รหัสนักศึกษา" },
        { value: "student.firstname", text: "ชื่อ" },
        { value: "student.surname", text: "นามสกุล" },
        { value: "student.idcard", text: "เลขประจำตัวประชาชน" },
        {
          value: "corporationtaxid",
          text: "เลขที่ผู้เสียภาษีหน่วยงานที่ทำงาน",
        },
        { value: "corporationname", text: "ชื่อหน่วยงานที่ทำงาน" },
        { value: "jobpositionname", text: "ตำแหน่ง" },
        { value: "salaryrange", text: "ช่วงเงินเดือน" },
        { value: "employmentstatusname", text: "สถานะการติดตาม" },
        {
          value: "continuetostudyinrelatedfieldstatusname",
          text: "เรียนตรงสาย",
        },
        { value: "studycoursetypename", text: "สายการเรียน" },
        {
          value: "gettingjobthatrelatedwithstudiedfieldstatusname",
          text: "ตรงตามวุฒิ",
        },
        { value: "action" },
      ],
      graduatedStudentJobTrackingReport: [],
      CertificationConfirm_Dialog: false,
      verifymanager: [],
      showbtn_iscertified: false,
    };
  },
  methods: {
    async loadGraduatedStudentJobTrackingReport(oid = "") {
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          this.verifymanager = result.data.verifymanager;
          this.graduatedStudentJobTrackingReport = result.data;
          if (!result.data.iscertified) {
            this.showbtn_iscertified = true;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadStudentDetails(jobTrackingOid = "") {
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReportStudentDetails(jobTrackingOid)
        .then((response) => {
          this.studentDetails = response.data;
        });
    },

    async certifyPopup() {
      this.CertificationConfirm_Dialog = true;
    },
    async onCertificationIsCompleted(certifydate, value) {
      if (value) {
        await graduatedStudentJobTrackingReportReportDataProvider
          .CerifyGraduatedStudentJobTrackingReport(
            this.graduatedStudentJobTrackingReport.oid,
            this.$store.getters["profileoid"],
            certifydate
          )
          .then((result) => {
            this.graduatedStudentJobTrackingReport.iscertified = true;
            this.showbtn_iscertified = false;
            this.textsnack = "ส่งรายงานเรียบร้อย";
            this.colorsnack = "success";
            this.statussnack = true;
          })
          .catch((err) => {
            alert(err);
          });
      }
      this.CertificationConfirm_Dialog = false;
    },
    async uncerify() {
      await graduatedStudentJobTrackingReportReportDataProvider
        .unCerifyGraduatedStudentJobTrackingReport(
          this.graduatedStudentJobTrackingReport.oid
        )
        .then((result) => {
          this.graduatedStudentJobTrackingReport.iscertified = false;
          this.showbtn_iscertified = true;
          this.textsnack = "ยกเลิกการส่งเรียบร้อย";
          this.colorsnack = "success";
          this.statussnack = true;
        })
        .catch((err) => {
          alert(err);
        });
    },
    closesnackbar: function (value) {
      if (value) {
        this.statussnack = false;
      }
    },
  },

  async created() {
    this.jobTrackingReportOid = this.$route.params.oid;
    await this.loadGraduatedStudentJobTrackingReport(this.jobTrackingReportOid);
    await this.loadStudentDetails(this.jobTrackingReportOid);
  },
};
</script>
<style></style>
