<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="11">
        <div class="pa-4 my-4">
          <v-card class="elevation-2">
            <v-card-title class="color-HeaderCard">
              <h4>รายละเอียดข่าวประชาสัมพันธ์</h4>

              <v-dialog v-model="previewdialog" max-width="60%">
                <NewsDetail :oid="oid" :previewdialog.sync="previewdialog" />
                <!-- <v-card-actions>
<v-btn color="red" @click="previewdialog = false">ปิด</v-btn>
</v-card-actions> -->
              </v-dialog>
              <!-- ---------------------------------- -->
            </v-card-title>
            <v-form
              v-if="newsEditRequest"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row justify="end" class="mx-4">
                <v-col
                  align-self="center"
                  cols="12"
                  lg="5"
                  sm="12"
                  class="pa-0 d-flex justify-end"
                >
                  <h4 class="d-flex align-center mr-3">สถานะการเผยแพร่</h4>
                  <v-switch
                    class="mt-5"
                    v-model="isapproved"
                    @change="OnSwitchChange"
                    :label="`${isapproved ? 'เผยแพร่' : 'หยุดการเผยแพร่'}`"
                  ></v-switch>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="preview(oid)"
                        dark
                        text
                        color="primary"
                        class="mt-4"
                      >
                        <v-icon>mdi-file-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>แสดงตัวอย่างการแสดงผล</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="mx-4">
                <v-col col="12">
                  <v-checkbox
                    v-model="newsEditRequest.isbignews"
                    label="ข่าวสำคัญ"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="mx-4 mt-2">
                <v-col col="6">
                  <v-autocomplete
                    v-if="$store.getters['rolecodes']"
                    @change="onSelectedNewsOrigin"
                    label="ข่าวเกี่ยวกับ"
                    :rules="[(v) => !!v || 'กรุณาระบุความเกี่ยวข้อง']"
                    :items="newsOriginTypeSelection"
                    v-model="selectedneworigincode"
                    item-text="name"
                    item-value="code"
                    clearable
                    background-color="input"
                    filled
                  ></v-autocomplete>
                </v-col>
                <v-col col="6">
                  <v-autocomplete
                    v-if="selectedneworigincode == newsOriginTypeCodes.School"
                    label="สถานศึกษา"
                    :rules="[(v) => !!v || 'กรุณาระบุสถานศึกษา']"
                    :items="schools"
                    v-model="selectedreforiginid"
                    item-text="schoolname"
                    item-value="schoolid"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="
                      selectedneworigincode == newsOriginTypeCodes.Institute
                    "
                    label="สถาบันอาชีวะ"
                    :rules="[(v) => !!v || 'กรุณาระบุสถาบันอาชีวะ']"
                    :items="institutes"
                    v-model="selectedreforiginid"
                    item-text="institutenameth"
                    item-value="instituteid"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="
                      selectedneworigincode ==
                      newsOriginTypeCodes.ProvinceSchool
                    "
                    label="จังหวัด"
                    :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                    :items="provinces"
                    v-model="selectedreforiginid"
                    item-text="provincethai"
                    item-value="provinceid"
                    background-color="input"
                    filled
                  ></v-autocomplete>
                  <!-- <v-autocomplete v-if="selectedneworigincode == newsOriginTypeCodes.Employer"
                    label="สถานประกอบการ"
                   :rules="[(v) => !!v || 'กรุณาระบุสถานประกอบการ']"
                    :items="companys"
                    v-model="selectedreforiginid"
                    item-text="nameth"
                    item-value="identityid"
                  ></v-autocomplete> -->
                </v-col>
              </v-row>
              <v-row class="mx-4">
                <v-col col="12" lg="6" sm="12">
                  <TextFieldDatePicker
                    v-model="selected_startdate"
                    :rules="[(v) => !!v || 'กรุณาระบุวันที่ประกาศข่าว']"
                    :value="selected_startdate"
                    caption="วันที่ประกาศข่าว"
                    @closeAndUpdate="
                      this.closeAndUpdateTextFieldDatePicker_StartDate
                    "
                  >
                  </TextFieldDatePicker>
                </v-col>
                <v-col col="12" lg="6" sm="12">
                  <TextFieldDatePicker
                    v-model="selected_expiredate"
                    :rules="[(v) => !!v || 'กรุณาระบุวันที่สิ้นสุดการประกาศ']"
                    :value="selected_expiredate"
                    caption="วันที่สิ้นสุดการประกาศ"
                    @closeAndUpdate="
                      this.closeAndUpdateTextFieldDatePicker_ExpireDate
                    "
                  >
                  </TextFieldDatePicker>
                </v-col>
              </v-row>

              <v-row class="mx-4">
                <v-col sm="12" md="12" lg="12">
                  <v-text-field
                    v-model="newsEditRequest.title"
                    label="หัวข้อ"
                    :rules="[
                      (v) => !!v || 'กรุณาระบุหัวข้อ',
                      (v) =>
                        (v && v.length <= 120) ||
                        'กรุณาระบุหัวข้อเพียง 120 ตัวอักษร',
                    ]"
                    filled
                    background-color="input"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="12" md="12" lg="12">
                  <div v-if="bannerthumbnailbase64" class="position-relative">
                    <div class="bgcolorphotozone">
                      <v-btn
                        icon
                        color="white"
                        class="close-btn"
                        @click="clearImage"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                      <v-img
                        contain
                        height="360"
                        :src="'data:image/png;base64,' + bannerthumbnailbase64"
                      />
                    </div>
                  </div>

                  <div
                    v-if="newBannerthumbnailbase64"
                    class="position-relative"
                  >
                    <div class="bgcolorphotozone">
                      <v-btn
                        icon
                        color="white"
                        class="close-btn"
                        @click="clearImage"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                      <v-img
                        contain
                        height="360"
                        :src="newBannerthumbnailbase64"
                      />
                    </div>
                  </div>

                  <div
                    v-if="!newBannerthumbnailbase64 && !bannerthumbnailbase64"
                  >
                    <v-card
                      rounded="8px"
                      color="#D9D9D9"
                      width="auto"
                      height="360px"
                      outlined
                      class="d-flex flex-column align-center justify-center black-border"
                      @click="triggerFileInput"
                    >
                      <v-btn icon class="mr-2">
                        <v-icon size="100px" color="white"
                          >mdi-link-variant</v-icon
                        >
                      </v-btn>
                      <p class="text-center mt-8">เพิ่มรูปข่าวประชาสัมพันธ์</p>
                    </v-card>
                  </div>

                  <input
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    @change="onFileChange($event.target.files[0])"
                  />
                  <!-- <div class="bgcolorphotozone">
                    <v-img
                      contain
                      height="360"
                      :src="'data:image/png;base64,' + bannerthumbnailbase64"
                    />
                  </div>
                  <v-file-input
                    v-model="imageBanner"
                    @change="onFileChange"
                    small-chips
                    truncate-length="15"
                    filled
                    background-color="input"
                    :rules="[(v) => !!v || 'กรุณาแนบไฟล์']"
                    class="mt-4"
                  ></v-file-input> -->
                </v-col>
                <v-col sm="12" md="12" lg="12">
                  <v-text-field
                    v-model="newsEditRequest.description"
                    label="รายละเอียด"
                    :rules="[(v) => !!v || 'กรุณาระบุรายละเอียด']"
                    filled
                    background-color="input"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <v-text-field
                    v-model="newsEditRequest.writerfullname"
                    label="ชื่อผู้เขียน (หากไม่ระบุ จะแสดงเป็นชื่อ-นามสกุลผู้ใช้งาน)"
                    :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้เขียน']"
                    filled
                    background-color="input"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <v-autocomplete
                    :items="categoryselector"
                    item-text="name"
                    item-value="name"
                    v-model="newsEditRequest.category"
                    label="หมวดหมู่"
                    :rules="[(v) => !!v || 'กรุณาระบุหมวดหมู่']"
                    filled
                    background-color="input"
                    dense
                  >
                    <template slot="prepend-item" class="grey--text">
                      <v-row class="align-center justify-center">
                        <v-col col="8" class="ml-3">
                          <v-text-field
                            :rules="[(v) => !!v || 'กรุณาระบุ ประเภท/หมวดหมู่']"
                            label="เพิ่ม ประเภท/หมวดหมู่"
                            v-model="newcategory"
                          ></v-text-field>
                        </v-col>
                        <v-col col="4" class="mr-3">
                          <v-btn
                            @click="OnclickaddedCategory"
                            block
                            color="success"
                            >ตกลง</v-btn
                          >
                        </v-col>
                      </v-row>
                    </template>
                    <!-- <template slot="selection" slot-scope="{ item, selected }"></template> -->
                    <template slot="item" slot-scope="{ item, tile }">
                      <div>
                        {{ item.name }}
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    v-if="!htmlmode"
                    color="primary"
                    @click="htmlmode = true"
                    >สลับโหมด html</v-btn
                  >
                  <v-btn
                    v-if="htmlmode"
                    color="primary"
                    @click="htmlmode = false"
                    >สลับโหมด Preview</v-btn
                  >
                </v-col>
                <v-col cols="12" v-if="!htmlmode">
                  <vue-editor
                    v-model="newsEditRequest.htmlcontent"
                    placeholder="เนื้อหา"
                    id="editor"
                    use-custom-image-handler
                    @image-added="handleImageAdded"
                  />
                </v-col>
                <v-col cols="12" v-else>
                  <v-textarea
                    v-model="newsEditRequest.htmlcontent"
                    label="Html-Syntax"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="px-0"
                  ><EntityHashtagManagement
                    v-if="IsLoadingComplete"
                    :selected_tags="selected_tags"
                    @update_tags="onSelectedTag"
                  >
                  </EntityHashtagManagement
                ></v-col>
              </v-row>
              <v-row class="justify-center text-center">
                <v-col>
                  <v-btn @click="$router.go(-1)" class="ma-2"> ยกเลิก </v-btn>
                  <v-btn @click="submit" class="ma-- color-button2" dark>
                    บันทึก <v-icon class="ml-2">mdi-content-save</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";

import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import { NewsDataProvider } from "@/services/NewsDataProvider";
import { NewsEditRequest } from "@/models/News/NewsEditRequest";
import EntityHashtagManagement from "@/components/EntityHashtag/EntityHashtagManagement.vue";
import NewsDetail from "@/views/News/NewsDetail.vue";
import { NewsStatusCodes } from "@/Constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { InstituteDataProvider } from "@/services/InstituteDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import {
  ApplicationRoleCodes,
  NewsOriginTypeCodes,
  NewsOriginTypeSelection,
} from "@/Constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { EmployerProfilesRequest } from "@/models/Employer/EmployerProfilesRequest";

const newsDataProvider = new NewsDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const instituteDataProvider = new InstituteDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

import { createCanvas, loadImage } from "canvas";
export default {
  name: "NewsManagementDetailView",
  data() {
    return {
      oid: "",
      request: [],
      publishstatus: "",
      categoryselector: [],
      requestFormData: new FormData(),
      valid: "",
      imageBanner: undefined,
      imageBannerUrl: "",
      bannerthumbnailbase64: "",
      newsEditRequest: new NewsEditRequest(),
      newcategory: "",
      selected_expiredate: "",
      selected_startdate: "",
      selected_tags: [],
      IsLoadingComplete: false,
      previewdialog: false,
      isapproved: false,
      htmlmode: false,
      newBannerthumbnailbase64: "",
      getSchools_Reqeust: new GetSchools_Reqeust(),
      applicationRoleCodes: ApplicationRoleCodes,
      newsoriginlist: NewsOriginTypeSelection,
      selectedneworigincode: "",
      newsOriginTypeCodes: NewsOriginTypeCodes,
      selectedreforiginid: "",
      companys: [],
      provinces: [],
      institutes: [],
      schools: [],
    };
  },
  components: {
    VueEditor,
    TextFieldDatePicker,
    EntityHashtagManagement,
    NewsDetail,
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation) {
      var vheight = prompt(
        "กรุณาระบุขนาดรูปภาพ (pixel) *หากไม่ระบุ ขนาดจะอยู่ที่ 720 pixel ?"
      );
      var conf = confirm(
        "ยืนยันขนาดภาพ: " + (vheight != null ? vheight : "720") + " pixel"
      );
      if (conf === true && vheight != null && vheight < 2000) {
        const maxWidth = vheight ? vheight : 720;
        const maxHeight = vheight ? vheight : 720;

        let reader = new FileReader();
        reader.onload = (e) => {
          //---
          const imagew = new Image();
          imagew.src = reader.result;
          imagew.onload = async () => {
            const ratio = Math.min(
              maxWidth / imagew.width,
              maxHeight / imagew.height
            );
            const width = (imagew.width * ratio + 0.5) | 0;
            const height = (imagew.height * ratio + 0.5) | 0;
            const canvas = createCanvas(width, height); // Set your desired dimensions
            const ctx = canvas.getContext("2d");
            ctx.drawImage(imagew, 0, 0, width, height); // Resize the image

            // Convert canvas to base64
            const resizedBase64 = canvas.toDataURL("image/jpeg");

            Editor.insertEmbed(cursorLocation, "image", resizedBase64);
          };
          // let image = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.$toast.error("ขนาดของรูปภาพใหญ่เกินไป !!!");
      }
    },
    createImage(file) {
      this.requestFormData.append("File", file);
      const reader = new FileReader();

      reader.onload = (e) => {
        this.newBannerthumbnailbase64 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    // ----------------------------
    preview(oid) {
      this.previewdialog = true;
      // this.$router.push({ name: "NewsDetail", params: { oid: item } });
    },
    // ----------------------
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);

      this.requestFormData.append("oid", this.oid);
      newsDataProvider
        .uploadBannerPhotofile(this.requestFormData)
        .then((result) => {
          this.$toast.success("อัปโหลดรูปภาพเรียบร้อย");
        })
        .catch((err) => {
          this.$toast.error("ล้มเหลว" + err);
        });
      file = "";
    },
    clearImage() {
      this.bannerthumbnailbase64 = "";
      this.newBannerthumbnailbase64 = "";
      this.$refs.fileInput.value = "";
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async loadSchools() {
      await schoolDataProvider
        .loadSchools(this.request_data)
        .then((result) => {
          this.schools = result.data;
          console.log(result.data);
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadInstitutes() {
      await instituteDataProvider
        .loadInstitutes()
        .then((result) => {
          this.institutes = result.data;
          console.log(result.data);
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadProvinces() {
      await masterDataProvider
        .loadGetProvinces()
        .then((result) => {
          this.provinces = result.data;
          console.log(result.data);
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadCompany() {
      let request = new EmployerProfilesRequest();
      await employerProfileDataProvider
        .loadEmployerProfiles(request)
        .then((result) => {
          this.companys = result.data.items;
        })
        .catch((err) => {
          alert(err);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.newsEditRequest.newsoriginid = this.selectedreforiginid;

        newsDataProvider
          .updateNewsContent(this.newsEditRequest)
          .then((result) => {
            this.$toast.success("บันทึกข้อมูลเรียบร้อย");
            this.$router.go(-1);
          })
          .catch((err) => {
            this.$toast.error("ล้มเหลว" + err);
          });
      }
    },
    OnclickaddedCategory() {
      this.categoryselector.push({ name: this.newcategory });
      this.newsEditRequest.category = this.newcategory;
      this.newcategory = "";
    },
    loadCategory() {
      newsDataProvider
        .getCategoryList()
        .then((result) => {
          this.categoryselector = result.data;
        })
        .catch((err) => {});
    },
    OnSwitchChange() {
      if (this.isapproved) {
        newsDataProvider
          .ApproveToPublish(this.oid)
          .then((result) => {
            this.$toast.success("ทำการเผยแพร่ข้อมูลเรียบร้อย");
            // this.isapproved = false;
          })
          .catch((err) => {
            this.$toast.error("ล้มเหลว" + err);
          });
      } else {
        newsDataProvider
          .ApproveToPublish(this.oid)
          .then((result) => {
            this.$toast.success("ยกเลิกเผยแพร่ข้อมูลเรียบร้อย");
            // this.isapproved = true;
          })
          .catch((err) => {
            this.$toast.error("ล้มเหลว" + err);
          });
      }
    },
    closeAndUpdateTextFieldDatePicker_ExpireDate(selectedDate = "") {
      this.selected_expiredate = selectedDate;
      this.newsEditRequest.expiredate = this.selected_expiredate;
    },
    closeAndUpdateTextFieldDatePicker_StartDate(selectedDate = "") {
      this.selected_startdate = selectedDate;
      this.newsEditRequest.startdate = this.selected_startdate;
    },
    async LoadCurrentNewsTags() {
      await newsDataProvider
        .getNewsTag(this.oid)
        .then((result) => {
          this.selected_tags = result.data;
          this.IsLoadingComplete = true;
        })
        .catch((err) => {});
    },
    onSelectedTag(val) {
      this.newsEditRequest.tags = val;
    },
    onSelectedNewsOrigin() {
      if (this.selectedneworigincode == NewsOriginTypeCodes.Institute) {
        this.loadInstitutes();
      }
      if (this.selectedneworigincode == NewsOriginTypeCodes.ProvinceSchool) {
        this.loadProvinces();
      }
      if (this.selectedneworigincode == NewsOriginTypeCodes.School) {
        this.loadSchools();
      }
      if (this.selectedneworigincode == NewsOriginTypeCodes.Employer) {
        this.loadCompany();
      }
      this.newsEditRequest.newsorigintypecode = this.selectedneworigincode;
    },
    onSelectedNewsOriginOid() {
      this.newsEditRequest.newsoriginid = this.selectedreforiginid;
    },
  },
  watch: {
    "newsEditRequest.isbignews"(newVal) {
      this.submit();
      console.log(newVal);
    },
  },
  computed: {
    newsOriginTypeSelection() {
      let Selection = [];
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Administrator
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Center_Admin
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Center_Officer
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Center_Manager
        )
      ) {
        Selection = this.newsoriginlist;
      }
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Employer
        )
      ) {
        Selection.push({
          code: "Employer",
          name: "สถานประกอบการ",
          color: "#03a9f4",
        });
      }
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.School_Manager
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.School_Admin
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.School_Partner_Officer
        ) ||
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.School_Guidance_Teacher
        )
      ) {
        Selection.push({ code: "School", name: "สถานศึกษา", color: "#03a9f4" });
      }
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.ProvinceSchool_Officer
        )
      ) {
        Selection.push({
          code: "ProvinceSchool",
          name: "อาชีวศึกษาจังหวัด",
          color: "#03a9f4",
        });
      }
      if (
        this.$store.getters["rolecodes"].includes(
          this.applicationRoleCodes.Insitution_Officer
        )
      ) {
        Selection.push({
          code: "Institute",
          name: "อาชีวศึกษา",
          color: "#03a9f4",
        });
      }

      return Selection;
    },
  },
  async mounted() {
    this.oid = this.$route.params.oid;
    this.loadCategory();
    await newsDataProvider
      .loadNewsDetail(this.oid)
      .then((result) => {
        this.newsEditRequest.oid = this.oid;

        this.LoadCurrentNewsTags();

        this.newsEditRequest.title = result.data.title;
        this.newsEditRequest.htmlcontent = result.data.htmlcontent;
        this.newsEditRequest.description = result.data.description;
        this.newsEditRequest.category = result.data.category.name;

        this.newsEditRequest.isbignews = result.data.isbignews;
        this.selected_startdate = result.data.startdate.split("T")[0];
        this.newsEditRequest.startdate = this.selected_startdate;
        this.selected_expiredate = result.data.expiredate.split("T")[0];
        this.newsEditRequest.expiredate = this.selected_expiredate;

        this.newsEditRequest.writerfullname = result.data.writerfullname;
        this.bannerthumbnailbase64 = result.data.bannerthumbnailbase64;
        this.publishstatus = result.data.publishstatus;

        this.newsEditRequest.newsoriginid = result.data.reforiginoid;
        this.newsEditRequest.newsorigintypecode = result.data.reforigincode;
        this.selectedneworigincode = result.data.reforigincode;

        if (this.publishstatus == NewsStatusCodes.Publish) {
          this.isapproved = true;
        }
        this.onSelectedNewsOrigin();
        this.selectedreforiginid = result.data.reforiginoid;
      })
      .catch((err) => {});
  },
};
</script>

<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.bgcolorphotozone {
  background-color: rgb(209, 209, 209);
  border-radius: 1%;
}

/* .full-size-card {
  width: 100vw;
  height: 100%;
  overflow: auto;
  padding-left: 25%;
} */
</style>
