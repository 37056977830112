<template>
  <v-container class="p-4">
    <DocumentViewer
      :report_name="'GraduatedStudentJobTrackingReportMain_ConcludeEmploymentStatus_BySchool_Report?'"
      :report_parameters="['prm_Oid=' + this.Oid]"
    >
    </DocumentViewer>
  </v-container>
</template>

<script>
import DocumentViewer from "@/components/Report/DocumentViewer.vue";
export default {
  components: { DocumentViewer },
  name: "GraduatedStudentJobTrackingReportMainDetailView_Report.vue",
  data() {
    return {
      Oid: "",
    };
  },
  created() {
    this.Oid = this.$route.params.Oid;
  },
};
</script>

<style></style>
