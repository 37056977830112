<template>
  <v-container class="elevation-1 pa-2 mt-4">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <h4>จัดการหลักสูตรกับความต้องการแรงงาน / ตำแหน่งงาน</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="ชื่อประเภทวิชา"
          readonly
          v-model="curriculum.subjecttypename"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="ชื่อสาขาวิชา"
          readonly
          v-model="curriculum.majorname"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="ชื่อสาขางาน"
          readonly
          v-model="curriculum.minorname"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="selected_jobpositionname_items"
          :items="jobpositionname_items"
          label="ความต้องการแรงงาน/ตำแหน่งงาน"
          multiple
          chips
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn @click="save" color="secondary"
          ><v-icon> mdi-content-save </v-icon>บันทึก</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { CurriculumDataProvider } from "@/services/CurriculumDataProvider";
import { JobPositionMatchingDataProvider } from "@/services/JobPositionMatchingDataProvider";
import { GetJobPositionMatching_Request } from "@/models/JobPositionMatching/GetJobPositionMatching_Request";
import { SaveJobPositionMatching_Request } from "@/models/JobPositionMatching/SaveJobPositionMatching_Request";
const curriculumDataProvider = new CurriculumDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const jobPositionMatchingDataProvider = new JobPositionMatchingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "CurriculumJobPositionMatchingView",
  components: {},
  data() {
    return {
      curriculumID: "",
      curriculum: undefined,

      jobpositionname_items: [],
      selected_jobpositionname_items: [],
    };
  },
  methods: {
    async loadCurriculum() {
      await curriculumDataProvider
        .loadCurriculum(this.curriculumID)
        .then((response) => {
          this.curriculum = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async getAllJobPositionName() {
      await jobPositionMatchingDataProvider
        .loadAllDistinctPositionNames()
        .then((result) => {
          this.jobpositionname_items = result.data;
        });
    },

    async loadJobPositionMatchingNames() {
      const request = new GetJobPositionMatching_Request();
      request.major_code = this.curriculum.majorcode;
      request.minor_code = this.curriculum.minorcode;
      request.subjecttype_code = this.curriculum.subjecttypecode;

      await jobPositionMatchingDataProvider
        .getInsertedJobPositionMatchingNames(request)
        .then((result) => {
          this.selected_jobpositionname_items = result.data;
        });
    },

    async save() {
      if (confirm("ยืนยัน?")) {
        const requests = [];
        this.selected_jobpositionname_items.forEach((x) => {
          const request = new SaveJobPositionMatching_Request();
          request.subjecttype_code = this.curriculum.subjecttypecode;
          request.major_code = this.curriculum.majorcode;
          request.minor_code = this.curriculum.minorcode;
          request.jobposition_name = x;
          requests.push(request);
        });
        await jobPositionMatchingDataProvider
          .saveJobPositionMatchings(requests)
          .then((result) => {
            this.$toast.success("บันทึกข้อมูลเรียบร้อย");
          });
      }
    },
  },
  async created() {
    this.curriculumID = this.$route.params.curriculumID;
    await this.loadCurriculum();
    await this.getAllJobPositionName();
    await this.loadJobPositionMatchingNames();
  },
};
</script>

<style></style>
