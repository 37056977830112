import axios from "axios"
import { create_default_request_header } from "./constants"
import { createEndpointUrl } from "@/common/api_connectivity_helper"
import { UpdateUseInApplicationNotifyStatusRequest } from "@/models/NotificationSetups/UpdateUseInApplicationNotifyStatusRequest"
import { UpdateUseEmailNotifyStatusRequest } from "@/models/NotificationSetups/UpdateUseEmailNotifyStatusRequest"

export class NotificationSetupDataProvider{
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL
  }

  getNotificationSetups(){
    const header = create_default_request_header()
    var endpointPath = "/api/NotificationSetup/GetNotificationSetups/"; 
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath)
    return axios.get(combinedURL, header)
  }

  updateUseInApplicationNotifyStatus(request = new UpdateUseInApplicationNotifyStatusRequest()){
    const header = create_default_request_header()
    var endpointPath = "/api/NotificationSetup/UpdateUseInApplicationNotifyStatus"; 
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath)
    const data = JSON.stringify(request);
    return axios.post(combinedURL,data, header)
  }

  updateUseEmailNotifyStatus(request = new UpdateUseEmailNotifyStatusRequest()){
    const header = create_default_request_header()
    var endpointPath = "/api/NotificationSetup/updateUseEmailNotifyStatus"; 
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath)
    const data = JSON.stringify(request);
    return axios.post(combinedURL,data, header)
  }
}
