<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2"> mdi-file-document-edit-outline </v-icon
            >ประเภทวิชา</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="subjecttype.subjecttypeid"
                label="รหัส PK สาขาวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="subjecttype.subjecttypecode"
                label="รหัสสาขาวิชา"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="subjecttype.subjecttypenameth"
                label="ชื่อสาขาวิชา (ไทย)"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="subjecttype.subjecttypenameen"
                label="ชื่อสาขาวิชา (อังกฤษ)"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "SubjectTypeDetailView",
  data() {
    return {
      subjecttypeID: "",
      subjecttype: [],
    };
  },

  methods: {
    async loadSubjectType(subjecttypeID = "") {
      await subjectTypeDataProvider
        .loadSubjectType(subjecttypeID)
        .then((response) => {
          this.subjecttype = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },

  async created() {
    this.subjecttypeID = this.$route.params.subjecttypeID;
    await this.loadSubjectType(this.subjecttypeID);
  },
};
</script>

<style></style>
