<template>
  <v-container class="p-4">
    <DocumentViewer :report_name="this.report_name + '?'"
      :report_parameters="['prm_Oid=' + this.Oid]">
    </DocumentViewer>
  </v-container>
</template>

<script>
import DocumentViewer from "@/components/Report/DocumentViewer.vue";
export default {
  components: { DocumentViewer },
  name: "GraduatedStudentJobTrackingReportDetailView_Report.vue",
  data() {
    return {
      Oid: "",
      report_name: ''
    };
  },
  created() {
    this.Oid = this.$route.params.Oid;
    if (this.$route.params.report_name != undefined) {
      this.report_name = this.$route.params.report_name;
    }
  },
};
</script>

<style></style>
