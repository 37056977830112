//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE = "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE = "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE = "An error has occurred. The photo was unable to upload.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

export const apiUrl = "http://localhost:3000/api/v1";
export const imageUrl = "http://localhost:3000/images";
export const backendapiUrl = process.env.VUE_APP_BACKEND_API_URL;

export const server = {
  LOGIN_URL: 'login',
  REGISTER_URL: 'register',
  PRODUCT_URL: 'product',
  TRANSACTION_URL: 'transaction',
  REPORT_URL: 'report',
  TOKEN_KEY: 'token',
  USERNAME: 'username'
};

export const RoleCodes = {
  ADM: 'ADM',
  MNG: 'MNG',
  STC: 'STC',
  STS: 'STS',
  STT: 'STT',
  GTC: 'GTC',
  STD: 'STD',
  COM: 'COM'
};

export function create_default_request_header_no_token() {
  const config = { headers: { "Accept": "application/json", "Content-Type": "application/json" } };
  return config;
}

export function create_default_request_header() {
  const token = localStorage.getItem("token");
  const config = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${ token }` } };
  return config;
}

export function create_default_request_header_for_blob_response(){
  const token = localStorage.getItem("token");
  const config = { headers: { 'Accept': 'application/json','Content-Type': 'application/json','Authorization': `Bearer ${ token }` }, responseType: 'blob' };
  return config;
}

export function create_default_uploadfile_request_header() {
  const token = localStorage.getItem("token");
  const config = { headers: { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${ token }` } };
  return config;
}

export function create_default_token_request_header() {
  const config = { "Authorization": `Bearer ${ localStorage.getItem("token") } ` };
  return config;
}
