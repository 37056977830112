import {EmployerSchoolContactRequest} from "./EmployerSchoolContactRequest";
export class EmployerEditTheirProfileRequest {
  oid = "";
  selected_employerprofilestatuscode = "";
  selected_employerprofiletypecode = "";
  identityid = "";
  nameth = "";
  email = "";
  selected_employermainbusinesstypecode = "";
  selected_employerbussinesstypecode = "";
  description = "";
  benefit_description = "";
  social_security_number = "";
  websiteurl = "";
  isrequiredjobapplicanttoregisterthemselforviapost = false;
  isopenforonlineapplicant = false;
  address_no = "";
  selected_province_id = "";
  selected_district_id = "";
  selected_tambon_id = "";
  location_latitude = "";
  location_longtitude = "";
  employerschoolcontactrequests = [];

  coordinator_email = "";
  coordinator_titlename_id = "";
  coordinator_firstname = "";
  coordinator_lastname = "";
  coordinator_phone = "";
  coordinator_fax = "";
  coordinator_positionname = "";

  dbd_lastverifydate = "";
  dbd_organizationjuristicstatus = "";

  static fromJson(jsonObject = "") {
    const vm = new EmployerEditTheirProfileRequest();
    var convertedJson = JSON.stringify(jsonObject);
    console.log(jsonObject);
    vm.oid = jsonObject.oid;
    if (jsonObject.nameth) {
      vm.nameth = jsonObject.nameth;
    }
    if (jsonObject.employerprofilestatus) {
      vm.selected_employerprofilestatuscode =
        jsonObject.employerprofilestatus.code;
    }
    if (jsonObject.employerprofiletype) {
      vm.selected_employerprofiletypecode = jsonObject.employerprofiletype.code;
    }
    if (jsonObject.identityid) {
      vm.identityid = jsonObject.identityid;
    }
    
    if (jsonObject.email) {
      vm.email= jsonObject.email;
    }
    if (jsonObject.employermainbusinesstype) {
      vm.selected_employermainbusinesstypecode =
        jsonObject.employermainbusinesstype.code;
    }
    if (jsonObject.businesstype) {
      vm.selected_employerbussinesstypecode = jsonObject.businesstype.code;
    }
    if (jsonObject.description) {
      vm.description = jsonObject.description;
    }
    if (jsonObject.benefitdescription) {
      vm.benefit_description = jsonObject.benefitdescription;
    }
    if (jsonObject.socialsecuritynumber) {
      vm.social_security_number = jsonObject.socialsecuritynumber;
    }
    if (jsonObject.websiteurl) {
      vm.websiteurl = jsonObject.websiteurl;
    }
    if (jsonObject.isrequiredjobapplicanttoregisterthemselforviapost) {
      vm.isrequiredjobapplicanttoregisterthemselforviapost =
        jsonObject.isrequiredjobapplicanttoregisterthemselforviapost;
    }
    if (jsonObject.isopenforonlineapplicant) {
      vm.isopenforonlineapplicant = jsonObject.isopenforonlineapplicant;
    }
    if (jsonObject.addressno) {
      vm.address_no = jsonObject.addressno;
    }
    if (jsonObject.province) {
      vm.selected_province_id = jsonObject.province.provinceid;
    }
    if (jsonObject.district) {
      vm.selected_district_id = jsonObject.district.districtid;
    }
    if (jsonObject.tambon) {
      vm.selected_tambon_id = jsonObject.tambon.tambonid;
    }
    if (jsonObject.location_latitude) {
      vm.location_latitude = jsonObject.location_latitude;
    }
    if (jsonObject.location_longtitude) {
      vm.location_longtitude = jsonObject.location_longtitude;
    }

    if (jsonObject.employerschoolcontacts) {
      jsonObject.employerschoolcontacts.forEach((x) => {
        const contact = new EmployerSchoolContactRequest();
        contact.oid = x.oid;
        if (x.school) {
          contact.selected_schoolid = x.school.schoolid;
          contact.selected_schoolname = x.school.schoolname;
        }
        contact.fullname = x.fullname;
        contact.phone = x.phone;
        vm.employerschoolcontactrequests.push(contact);
      });
    }

    if (jsonObject.coordinator_email) {
      vm.coordinator_email = jsonObject.coordinator_email;
    }
    if (jsonObject.coordinator_fax) {
      vm.coordinator_fax = jsonObject.coordinator_fax;
    }
    if (jsonObject.coordinator_firstname) {
      vm.coordinator_firstname = jsonObject.coordinator_firstname;
    }
    if (jsonObject.coordinator_lastname) {
      vm.coordinator_lastname = jsonObject.coordinator_lastname;
    }
    if (jsonObject.coordinator_phone) {
      vm.coordinator_phone = jsonObject.coordinator_phone;
    }
    if (jsonObject.coordinator_titlename) {
      vm.coordinator_titlename_id =
        jsonObject.coordinator_titlename.titlenameid;
    }
    if (jsonObject.coordinator_positionname) {
      vm.coordinator_positionname = jsonObject.coordinator_positionname;
    }

    if (jsonObject.dbd_lastverifydate) {
      vm.dbd_lastverifydate = jsonObject.dbd_lastverifydate;
    }
    if (jsonObject.dbd_organizationjuristicstatus) {
      vm.dbd_organizationjuristicstatus =
        jsonObject.dbd_organizationjuristicstatus;
    }
    return vm;
  }
}
