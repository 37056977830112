import { createEndpointUrl } from '@/common/api_connectivity_helper';
import axios from "axios";
import { create_default_request_header } from "../constants";
export class GraduatedStudentJobTrackingReportFilterOptionDataProvider {
  apiBaseURL = "";
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  GetRelatedMajors(graduatedStudentJobTrackingReportOid = ''
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReportFilterOption/GetRelatedMajors/" + graduatedStudentJobTrackingReportOid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  GetRelatedEmploymentStatuses(graduatedStudentJobTrackingReportOid = ''
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReportFilterOption/GetRelatedEmploymentStatuses/" + graduatedStudentJobTrackingReportOid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }

  GetRelatedCertificateTypes(graduatedStudentJobTrackingReportOid = ''
  ) {
    const headers = create_default_request_header();
    const endpointPath =
      "/api/GraduatedStudentJobTrackingReportFilterOption/GetRelatedCertificateTypes/" + graduatedStudentJobTrackingReportOid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.post(combinedURL, null, headers);
  }
}
