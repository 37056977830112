export class UpdateJobPosting_Request {
  oid = "";
  jobposting_id = "";
  selected_jobsegment_code = "";
  selected_employmentcategory_code = "";
  isotherjobsegment = false;
  otherjobsegmentname = "";
  positionname = "";
  isargent = false;
  jobdescription = "";
  workingtimestart = "";
  workingtimeend = "";
  unit = 0;
  isnotspecifiedunit = false;
  minimumwage = 0;
  maximumwage = 0;
  selected_payrate_code = "";
  notspecifiedwage = false;
  workingplace_address = "";
  selected_workingplace_province_id = "";
  selected_workingplace_district_id = "";
  selected_workingplace_tambon_id = "";
  selected_workingplace_zipcode = "";
  no_require_certificate = false;
  selected_applicant_requiredcertificatetype_code = "";
  applicant_requiredgenderchoice = "";
  applicant_specification = "";
  startdate = "";
  enddate = "";
  coordinator_fullname = "";
  coordinator_phoneno = "";
  coordinator_email = "";

  static fromJSON(jsonObject = "") {
    let vm = new UpdateJobPosting_Request();
    vm.oid = jsonObject.oid;
    vm.jobposting_id = jsonObject.jobposting_id;
    if (jsonObject.jobsegment != undefined){
        vm.selected_jobsegment_code = jsonObject.jobsegment.code;
    }
    if (jsonObject.employmentcategory != undefined){
        vm.selected_employmentcategory_code = jsonObject.employmentcategory.code;
    }
    vm.isotherjobsegment = jsonObject.isotherjobsegment;
    vm.otherjobsegmentname = jsonObject.otherjobsegmentname;
    vm.positionname = jsonObject.positionname;
    vm.isargent = jsonObject.isargent;
    vm.jobdescription = jsonObject.jobdescription;
    vm.workingtimestart = jsonObject.workingtimestart;
    vm.workingtimeend = jsonObject.workingtimeend;
    vm.unit = jsonObject.unit;
    vm.isnotspecifiedunit = jsonObject.isnotspecifiedunit;
    vm.minimumwage = jsonObject.minimumwage;
    vm.maximumwage = jsonObject.maximumwage;
    if (jsonObject.payrate != undefined)
    {
        vm.selected_payrate_code = jsonObject.payrate.code;
    }
    vm.notspecifiedwage = jsonObject.notspecifiedwage;
    vm.workingplace_address = jsonObject.workingplace_address;
    if (jsonObject.workingplace_province != undefined)
    {
        vm.selected_workingplace_province_id = jsonObject.workingplace_province.provinceid;
    }
    if (jsonObject.workingplace_district != undefined){
        vm.selected_workingplace_district_id = jsonObject.workingplace_district.districtid;
    }
    if (jsonObject.workingplace_tambon != undefined){
        vm.selected_workingplace_tambon_id = jsonObject.workingplace_tambon.tambonid;
        vm.selected_workingplace_zipcode = jsonObject.workingplace_tambon.postcodeall;
    }
    if (jsonObject.applicant_requiredcertificatetype != undefined){
        vm.selected_applicant_requiredcertificatetype_code = jsonObject.applicant_requiredcertificatetype.code; 
    }
    vm.no_require_certificate = jsonObject.norequirecertificate;
    vm.applicant_requiredgenderchoice = jsonObject.applicant_requiredgenderchoice;
    vm.applicant_specification = jsonObject.applicant_specification;
    vm.startdate = jsonObject.startdate;
    vm.enddate = jsonObject.enddate;
    vm.coordinator_fullname = jsonObject.coordinator_fullname;
    vm.coordinator_email = jsonObject.coordinator_email;
    vm.coordinator_phoneno = jsonObject.coordinator_phoneno;
    return vm;
  }
}
