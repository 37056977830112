<template>
  <div class="container">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-account-hard-hat</v-icon>
        ประเภทวิสาหกิจ
      </h3>
    </v-row>
    <SearchableDataTable
      v-if="employerMainBusinessTypes"
      table_caption="วิสาหกิจ"
      :datatable_headers="employerMainBusinessTypes_data_table_headers"
      :data_items="employerMainBusinessTypes"
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          @click="goToEmployerMainBusinessTypesDetailView(item)"
          icon
          color="info"
        >
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "EmployerMainBusinessTypesDetailViewVue",
  data() {
    return {
      employerMainBusinessTypes: [],
      employerMainBusinessTypes_data_table_headers: [
        // { text: "Code", value: "code" },
        { text: "ชื่อประเภทวิสาหกิจ", value: "nameth" },
        { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    async loadEmployerMainBusinessType() {
      await masterDataProvider
        .loadEmployerMainBusinessTypes()
        .then((result) => {
          this.employerMainBusinessTypes = result.data;
          console.log(this.employerMainBusinessTypes);
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToEmployerMainBusinessTypesDetailView: function (item) {
      //   var Code = item.code;
      this.$router
        .push({
          name: "EmployerMainBusinessTypesDetailView",
          params: { oid: item.oid },
        })
        .catch((err) => {});
    },
  },
  async created() {
    await this.loadEmployerMainBusinessType();
  },
};
</script>

<style></style>
