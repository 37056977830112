<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2">mdi-file-document-edit-outline </v-icon
            >สาขางาน</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="minor.minorid"
                label="รหัส PK สาขางาน"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="minor.minorcode"
                label="รหัสสาขางาน"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="minor.minormoecode"
                label="รหัสสาขางาน (MOE)"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="minor.minornameth"
                label="ชื่อสาขางาน (ไทย)"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="minor.minornameen"
                label="ชื่อสาขางาน (อังกฤษ)"
                readonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MinorDataProvider } from "@/services/MinorDataProvider";
var minorDataProvider = new MinorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "MinorDetailView",
  data() {
    return {
      minorID: "",
      minor: [],
    };
  },

  methods: {
    async loadMinor(minorID = "") {
      await minorDataProvider
        .loadMinor(minorID)
        .then((response) => {
          this.minor = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },

  async created() {
    this.minorID = this.$route.params.minorID;
    await this.loadMinor(this.minorID);
  },
};
</script>

<style></style>
