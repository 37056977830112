import axios from "axios";
import { create_default_request_header } from "@/services/constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
import { NewsPagination_request } from "@/models/News/NewsPagination_request";
export class NewsDataProvider {
  constructor(apiBaseURL = "") {
    this.apiBaseURL = apiBaseURL;
  }
  loadRecommendNewsPublishList() {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetRecommendNewsList";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }
  loadNewsPublishList() {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetNewsList";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  loadNewsPublishDetail(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetNewsDetail/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  loadRecommendNewsList() {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetManageRecommendNewsList";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }
  loadNewsList() {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetManageNewsList";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }
  loadNewsListForSchoolEmployee() {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetManageNewsListForSchoolEmployee";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }
  loadNewsDetail(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetManageNewsDetail/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  createNewsContent(vm = new FormData()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/CreateNews";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  uploadBannerPhotofile(vm = new FormData()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/UploadBannerPhotoNews";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  updateNewsContent(vm) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/UpdateNews";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, vm, header);
  }

  deleteNewsContent(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/DeleteNews/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.delete(combinedURL, header);
  }

  ApproveToPublish(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/NewsApprove/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  getNewsTag(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/getTags/"+oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  //category
  getCategoryList() {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/getCategory";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  CreateCategory(name) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/CreateCategory";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL,{
      namecategory : name    },header);
  }

  GetNewsSendingForApprove(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/NewsSendingForApprove/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }  
  loadNewsDetailForSchoolEmployee(oid) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetManageNewsDetailForSchoolEmployee/" + oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  //pagination list
  loadNewsPaginationList(request = NewsPagination_request()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetNewsPaginationList";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL,request, header);
  }
  //สำหรับ สถาบัน ครูแนะแนว
  loadNewsPaginationListForSchoolEmployee(request = NewsPagination_request()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetNewsPaginationListForSchoolEmployee";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL,request, header);
  }

  // สำหรับ admin 
  loadNewsManagePaginationList(request = NewsPagination_request()) {
    var header = create_default_request_header();
    var endpointUrl = "/api/News/GetNewsManagePaginationList";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL,request, header);
  }
}
