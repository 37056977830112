<template>
  <v-row align-self="center">
    <v-col cols="6" class="custom-col">
      <h4 class="primary--text mb-2">ชื่อผู้ใช้งาน (Username)</h4>

      <v-text-field
        background-color="input"
        filled
        readonly
        label="ชื่อผู้ใช้งาน (Username)"
        v-if="applicationUser"
        v-model="applicationUser.username"
      ></v-text-field>
    </v-col>
    <v-col cols="6" class="custom-col">
      <h4 class="primary--text mb-2">รหัสผ่าน (Password)</h4>

      <v-btn class="color-button2 mt-2" @click="goToChangePasswordView()">
        เปลี่ยนรหัสผ่าน<v-icon class="ml-2">mdi-lock-reset</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "CurrentApplicationUserInfo",
  data() {
    return {
      applicationUser: undefined,
    };
  },
  methods: {
    goToChangePasswordView() {
      this.$router.push(
        "/ApplicationUser/CurrentApplicationUserChangeThierOwnPassword"
      );
    },
    async initialize() {
      await applicationUserDataProvider
        .GetCurrentApplicationUser()
        .then((result) => {
          console.log(result.data);
          this.applicationUser = result.data;
        });
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
@media (max-width: 1342px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  color: white !important;
}
</style>
