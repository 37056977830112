<template>
    <div>
        <v-btn :disabled="isBtnDisabled" color="grey" class="mr-2 mb-4"
            @click="onSubmit()"><v-icon>mdi-stop-circle</v-icon>
            หยุดประกาศชั่วคราว</v-btn>
    </div>
</template>

<script>
import { EmployerJobPostingDataProvider } from '@/services/EmployerJobPostingDataProvider'
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
    name: 'Btn_SetJobPostingStatus_Active',
    props: ['JobPosting'],
    data() {
        return {

        }
    },
    computed: {
        isBtnDisabled() {
            if (this.JobPosting.jobpostingstatus.statuscode != 'Active') {
                return true;
            }
            return false;
        }
    },
    methods: {
        async onSubmit() {
            if (confirm('ยืนยัน?')) {
                const oid = this.JobPosting.oid;
                await employerJobPostingDataProvider.setJobPosting_OnHoldStatus(oid).then(result => {
                    this.$toast.success('ปิดการประกาศชั่วคราว');
                    this.$emit('finished');
                    this.$router.go();
                });
            }
        }
    }
}
</script>

<style></style>