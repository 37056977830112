import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
export class InstituteDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    loadInstitutes() {
        try {
            var header = create_default_request_header();
            var endpointUrl = "/api/Institute/GetInstitutes";
            var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
            return axios.get(combinedURL, header);
        }
        catch (error) {

            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    store.dispatch({ type: "doLogout" });
                }
                //  error: AxiosError<any, any>
                return error.message;
            } else {
                return 'An unexpected error occurred';
            }
        }
    }

    loadInstitute(instituteID) {
        try {
            var header = create_default_request_header();
            var endpointUrl = "/api/Institute/GetInstitute/" + instituteID;
            var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
            return axios.get(combinedURL, header);
        }
        catch (error) {

            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    store.dispatch({ type: "doLogout" });
                }
                //  error: AxiosError<any, any>
                return error.message;
            } else {
                return 'An unexpected error occurred';
            }
        }
    }
    importInstitutes() {
        try {
            var header = create_default_request_header();
            var endpointUrl = "/api/Institute/ImportInstituteDataFromWebAPI";
            var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
            return axios.get(combinedURL, header);
        }
        catch (error) {

            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    store.dispatch({ type: "doLogout" });
                }
                //  error: AxiosError<any, any>
                return error.message;
            } else {
                return 'An unexpected error occurred';
            }
        }
    }
}