import RegisterEmployer from "@/views/RegisterEmployer/RegisterEmployer.vue";
import UpdateRegisterEmployer from "@/views/RegisterEmployer/UpdateRegisterEmployer.vue";
import RedirectCountdown from "@/views/RegisterEmployer/RedirectCountdown.vue";

export default [
  {
    path: "/RegisterEmployer",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/RegisterEmployer/UpdateRegisterEmployer",
        name: "UpdateRegisterEmployer",
        component: UpdateRegisterEmployer,
        meta: {
          requiresAuth: true,
          nameTh: "ข้อมูลสถานประกอบการ",
        },
      },
      {
        path: "/RegisterEmployer/RedirectCountdown/:oid",
        name: "RedirectCountdown",
        component: RedirectCountdown,
      },
    ],
  },
  {
    path: "/RegisterEmployer",
    component: () => import("@/layouts/default/DefaultTemplate.vue"),
    children: [
      {
        path: "/RegisterEmployer/RegisterEmployer",
        name: "RegisterEmployer",
        component: RegisterEmployer,
      },
    ],
  },
];
