<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="justify-center">
          <v-col lg="10" md="12" sm="12" xs="12">
            <v-card class="elevation-1 pa-3 mt-5">
              <v-card-text class="d-flex justify-center">
                <v-col cols="10" class="mt-0">
                  <v-row>
                    <v-col cols="12" class="d-flex justify-start">
                      <h3 class="primary--text">
                        <v-icon class="primary--text">mdi-account-box</v-icon>
                        เปลี่ยนรหัสผ่าน
                      </h3>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2"></v-divider>
                  <v-row class="mt-3">
                    <v-col cols="12">
                      <h4 class="primary--text mb-2">
                        ชื่อผู้ใช้งาน (username)
                      </h4>
                      <span>{{ applicationUser.username }}</span>
                      <!-- <v-text-field
                        label="ชื่อผู้ใช้งาน (username)"
                        readonly
                        v-model="applicationUser.username"
                      ></v-text-field> -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h4 class="primary--text mb-2">
                        รหัสผ่านเดิม (Current Password)
                      </h4>

                      <v-text-field
                        filled
                        background-color="input"
                        label="รหัสผ่านเดิม"
                        v-model="request.old_password"
                        :append-icon="
                          isShowOldPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="isShowOldPassword = !isShowOldPassword"
                        :type="isShowOldPassword ? 'text' : 'password'"
                        :rules="[
                          (v) => !!v || 'กรุณากรอกรหัสผ่านเดิม',
                          (v) =>
                            (v && v.length >= 6) ||
                            'ต้องมีอย่างน้อย 6 ตัวอักษร',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h4 class="primary--text mb-2">
                        รหัสผ่านใหม่ (New Password)
                      </h4>

                      <v-text-field
                        filled
                        background-color="input"
                        label="รหัสผ่านใหม่"
                        v-model="request.new_password"
                        :append-icon="
                          isShowNewPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="isShowNewPassword = !isShowNewPassword"
                        :type="isShowNewPassword ? 'text' : 'password'"
                        :rules="[
                          (v) => !!v || 'กรุณากรอกรหัสผ่านใหม่',
                          (v) =>
                            (v && v.length >= 6) ||
                            'ต้องมีอย่างน้อย 6 ตัวอักษร',
                          (v) => v !== request.old_password || 'รหัสผ่านซ้ำ',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h4 class="primary--text mb-2">
                        ยืนยันรหัสผ่านใหม่ (Confirm New Password)
                      </h4>

                      <v-text-field
                        filled
                        background-color="input"
                        label="ยืนยันรหัสผ่านใหม่"
                        v-model="confirm_new_password"
                        :append-icon="
                          isShowConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="
                          isShowConfirmNewPassword = !isShowConfirmNewPassword
                        "
                        :type="isShowConfirmNewPassword ? 'text' : 'password'"
                        :rules="[
                          (v) => !!v || 'กรุณายืนยันรหัสผ่านใหม่',
                          (v) =>
                            v === request.new_password || 'รหัสผ่านไม่ตรงกัน',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row> </v-col></v-card-text
            ></v-card>
            <v-row class="mt-5">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn @click="onCancelClick" color="" class=""
                  ><v-icon></v-icon> ยกเลิก</v-btn
                ><v-btn @click="onSubmitClick" class="ml-2 color-buton">
                  บันทึก<v-icon>mdi-content-save</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
import { CurrentUserChangeThierOwnPasswordRequest } from "@/models/ApplicationUsers/CurrentUserChangeThierOwnPasswordRequest";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "CurrentApplicationUserChangeThierOwnPassword",
  data() {
    return {
      applicationUser: undefined,
      request: new CurrentUserChangeThierOwnPasswordRequest(),
      isShowOldPassword: false,
      isShowNewPassword: false,
      isShowConfirmNewPassword: false,
      valid: "",
    };
  },
  methods: {
    async initialize() {
      await applicationUserDataProvider
        .GetCurrentApplicationUser()
        .then((result) => {
          this.applicationUser = result.data;
        });
    },
    async onSubmitClick() {
      var validate = this.$refs.form.validate();
      if (validate) {
        await applicationUserDataProvider
          .currentUserChangeThierOwnPassword(this.request)
          .then((result) => {
            this.$toast.success("เปลี่ยนแปลงรหัสผ่านเรียบร้อย");
          })
          .catch((err) => {
            this.$toast.error(err.response.data);
          });
      }
    },
    onCancelClick() {
      this.$router.back();
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
  color: white !important;
}
</style>
