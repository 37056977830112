<template>
  <div class="pa-4">
    <v-row class="pa-3 justify-space-between">
      <h3 class="primary--text">
        <v-icon class="primary--text">mdi-book</v-icon> สัญชาติ
      </h3>
    </v-row>
    <SearchableDataTable
      v-if="nationalities != undefined"
      table_caption="สัญชาติ"
      :datatable_headers="majors_data_table_headers"
      :data_items="nationalities"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="goToDetailView(item)" icon color="info">
          <v-icon>mdi-file-eye</v-icon>
        </v-btn>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable },
  name: "NationalitiesListView",
  data() {
    return {
      isImporting: false,
      nationalities: undefined,
      majors_data_table_headers: [
        //{ text: "รหัส PK สาขาวิชา", value: "majorid" },
        { text: "รหัสสัญชาติ", value: "nationalityid" },
        { text: "ชื่อสัญชาติ (ไทย)", value: "nationalityth" },
        { text: "ชื่อสัญชาติ (อังกฤษ)", value: "nationalityeng" },
        { text: "ตัวเลือก", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    async importNationalities() {
      this.isImporting = true;
      await masterDataProvider
        .getNationalities()
        .then((result) => {
          this.onImportNationalitiesCompleted();
        })
        .catch((err) => {
          this.isImporting = false;
          alert(err);
        });
    },
    async loadNationalities() {
      await masterDataProvider
        .getNationalities()
        .then((result) => {
          this.nationalities = result.data;
          console.log(this.nationalities);
        })
        .catch((err) => {
          alert(err);
        });
    },
    goToDetailView: function (item) {
      this.$router
        .push({
          name: "NationalitiesDetailView",
          params: { oid: item.oid },
        })
        .catch((err) => {});
    },
    onImportNationalitiesCompleted() {
      this.isImporting = false;
      this.loadNationalities();
    },
  },
  async created() {
    await this.loadNationalities();
  },
};
</script>

<style></style>
