<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" class="d-flex justify-start">
        <v-btn @click="titlename_Dialog = true" dark color="primary" class="ma-2">
          <v-icon class="mr-2">mdi-folder-plus</v-icon> เพิ่มคำนำหน้าชื่อ
        </v-btn>
      </v-col>
    </v-row>
    <SearchableDataTable
      table_caption="คำนำหน้าชื่อ"
      :data_items="titlename"
      :datatable_headers="titlename_data_table_headers"
    >
      <template class="d‑flex" v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="onclickEditTitlename(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>แก้ไขคำนำหน้าชื่อ</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deleteTitlename(item)"
              color="error"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบคำนำหน้าชื่อ</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
    <TitlenameDialog
      :titlename_Dialog="titlename_Dialog"
      :oid="Titlenameoid"
      @closeDialog="onTitleProcessIsCompleted"
    ></TitlenameDialog>
  </div>
</template>
<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";

import TitlenameDialog from "@/views/Titlename/TitlenameDialog.vue";
import { TitlenameDataProvider } from "@/services/TitlenameDataProvider";
var titlenameDataProvider = new TitlenameDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import { TitlenameRecord } from "@/models/Titlename/TitlenameRecord";

export default {
  props: {
    titlename_oid: String,
  },
  components: {
    SearchableDataTable,
    TitlenameDialog,
  },
  data() {
    return {
      search: "",
      titlename_data_table_headers: [
        { text: "คำนำหน้าชื่อ", value: "titlenameth" },
        { text: "คำนำหน้าชื่อ(Eng)", value: "titlenameend" },
        { text: "รหัสคำนำหน้าชื่อ", value: "titlenameid" },
        { text: "Actions", value: "action" },
      ],
      schools: undefined,
      vm: new TitlenameRecord(),
      Titlenameoid: "-1",
      titlename: [],
      titlename_Dialog: false,
    };
  },
  methods: {
    async LoadTitlename(titlename_oid) {
      titlenameDataProvider
        .getTitlenameRecords(titlename_oid)
        .then((res) => {
          this.titlename = res.data;
        })
        .catch((res) => {});
    },

    async saveTitlename(value) {
      value.titlename_oid = this.titlename_oid;
      titlenameDataProvider
        .saveTitlenameRecord(value)
        .then((res) => {
          this.$toast.success("เพิ่ม หน่วยงานราชการ เรียบร้อย!");
          const titlename_oid = this.titlename_oid;
          this.LoadTitlename(titlename_oid);
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว!");
        });
    },

    async onTitleProcessIsCompleted(value, save) {
      if (save) {
        this.titlename_Dialog = false;
        if (value.oid != "-1") {
          await this.UpdateTitlename(value);
        } else {
          await this.saveTitlename(value);
        }
      } else {
        this.titlename_Dialog = false;
      }
      this.Titlenameoid = "-1";
    },

    onclickEditTitlename(item) {
      this.Titlenameoid = item.oid;
      this.titlename_Dialog = true;
    },
    async UpdateTitlename(value) {
      titlenameDataProvider
        .editTitlenameRecord(value)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("บันทึกข้อมูลสำเร็จ");
            const titlename_oid = this.titlename_oid;
            this.LoadTitlename(titlename_oid);
          }
        })
        .catch((res) => {
          this.$toast.error("แก้ไขข้อมูลล้มเหลว !!!" + res);
        });
    },

    async deleteTitlename(item) {
      if (confirm("ต้องการเพิ่มหน่วยงานราชการหรือไม่ ?")) {
        await titlenameDataProvider
          .deleteTitlenameRecord(item.oid)
          .then((res) => {
            this.titlename = [];
            this.$toast.success("ลบหน่วยงานราชการ เรียบร้อย!");
            const titlename_oid = this.titlename_oid;
            this.LoadTitlename(titlename_oid);
          })
          .catch((res) => {
            this.$toast.error("ลบหน่วยงานราชการล้มเหลว!" + res);
          });
      }
    },
  },
  async mounted() {
    const titlename_oid = this.titlename_oid;
    await this.LoadTitlename(titlename_oid);
  },
};
</script>
