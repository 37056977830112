import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
export class JobApplicationDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  getJobApplication(oid = "") {
    const header = create_default_request_header();
    const endpointUrl = "/api/JobApplication/GetJobApplication/" + oid;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }


}
