import SearchStudentList from "@/views/Search/SearchStudentList.vue";

export default [
  {
    path: "/Search",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Search/SearchStudentList",
        name: "SearchStudentList",
        component: SearchStudentList,
        meta: {
          requiresAuth: true,
          nameTh: "ค้นหานักศึกษา",
        },
      },
    ],
  },
];
