export default {
  state: {
    historyroutes: []
  },
  getters: {
    historyroutes(state) {
      return state.historyroutes
    }
  },
  mutations: {
    SET_HISTORYROUTES(state, historyroutes) {
      //set to state
      state.historyroutes.push(historyroutes)
      //save to store
      let historyroutes_js = JSON.stringify(state.historyroutes)
      localStorage.setItem("historyroutes", historyroutes_js)
    },
    SET_TEMP_HISTORYROUTES(state, historyroutes) {
      state.historyroutes = historyroutes
    },
    SET_HISTORYROUTES_CLEAR(state) {
      state.historyroutes = []
      localStorage.setItem("historyroutes", [])
    },
    SET_HISTORYROUTES_MAINONLY(state) {
      state.historyroutes = state.historyroutes.slice(0, 1)
    },
    REMOVE_HISTORYROUTES(state, namepath) {
      if (state.historyroutes.some((x) => namepath === x.name)) {
        state.historyroutes = state.historyroutes.slice(0, 1)
        let historyroutes_js = JSON.stringify(state.historyroutes)
        localStorage.setItem("historyroutes", historyroutes_js)
      }
    }
  },

  actions: {
    doAddRoute({ commit }, { path }) {
      if (localStorage.getItem("historyroutes")) {
        let temphistoryroutes = JSON.parse(
          localStorage.getItem("historyroutes")
        )
        commit("SET_TEMP_HISTORYROUTES", temphistoryroutes)
        if (temphistoryroutes.filter(n => n).some((x) => path.name === x.name)) {
        }else{
          commit("SET_HISTORYROUTES", path)
        }
      } else {
        commit("SET_HISTORYROUTES", path)
      }
    },
    doRemoveRoute({ commit }, { namepath }) {
      commit("REMOVE_HISTORYROUTES", namepath)
    },
    doClearRoute({ commit }) {
      commit("SET_HISTORYROUTES_CLEAR")
    },
    doRemoveToMainOnly({ commit }) {
      commit("SET_HISTORYROUTES_MAINONLY")
    }
  },
  modules: {}
}
