<template>
  <v-container class="pa-4 mt-4">
    <v-row>
      <h3><i class="fa fa-line-chart"></i>รายชื่อนักเรียนผู้สำเร็จการศึกษา</h3>
    </v-row>
    <v-row>
      <v-card class="col-12">
        <v-card-title>
          <p>เกณฑ์การค้นหา</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="schoolName"
                hint="สถานศึกษา"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                hint="ปีการศึกษา"
                persistent-hint
                v-model="selectedYearTH"
                :items="yearTH_Choices"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                hint="ระดับการศึกษา"
                persistent-hint
                :items="mainGradeTHName_Choices"
                v-model="selectedMainGradeTHName"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                hint="สาขาวิชา"
                persistent-hint
                :items="group_Choices"
                v-model="selected_groupCode"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="12 text-center">
        <v-btn
          color="warning"
          v-on:click="create_reportParameters(), (document_viewer_dialog = true)"
          >แสดงรายงาน</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog v-model="document_viewer_dialog" width="1200">
      <template>
        <v-card height="8000" width="1200">
          <DocumentViewer
            :key="document_viewer_key"
            :report_name="report_name"
            :report_parameters="report_parameters"
          >
          </DocumentViewer>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import DocumentViewer from "../../../components/Report/DocumentViewer.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { SchoolUserProfileDataProvider } from "@/services/Profile/SchoolUserProfileDataProvider";

var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var schoolUserProfileDataProvider = new SchoolUserProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "",
  props: [],
  data() {
    return {
      document_viewer_key: 1,
      document_viewer_dialog: false,
      report_name: "school_report1?",
      report_parameters: [],
      schoolStaffProfile: undefined,
      schoolID: undefined,
      schoolName: undefined,
      yearTH_Choices: undefined,
      selectedYearTH: undefined,
      mainGradeTHName_Choices: undefined,
      selectedMainGradeTHName: undefined,
      group_Choices: undefined,
      selected_groupCode: undefined,
    };
  },
  methods: {
    async initialize() {
      // await schoolUserProfileDataProvider.loadSchoolUserProfile()
      //   .then((result) => {
      //     this.schoolStaffProfile = result.data;
      //     this.schoolID = this.schoolStaffProfile.school.schoolid;
      //     this.schoolName = this.schoolStaffProfile.school.schoolname;
      //   });

      await commonDataProvider.getListOfYearTH().then((result) => {
        this.yearTH_Choices = result.data;
      });
      await commonDataProvider.getMainGradeTHNames().then((result) => {
        this.mainGradeTHName_Choices = result.data;
      });
    },

    create_reportParameters() {
      this.document_viewer_key += 1;
      this.report_parameters = [];
      if (this.schoolID != undefined) {
        this.report_parameters.push("prm_schoolID=" + this.schoolID);
      }
      if (this.selectedYearTH != undefined) {
        this.report_parameters.push("prm_yearTH=" + this.selectedYearTH);
      }
      if (this.selectedMainGradeTHName != undefined) {
        this.report_parameters.push(
          "prm_mainGradeTHName=" + this.selectedMainGradeTHName
        );
      }
      if (this.selected_groupCode != undefined) {
        this.report_parameters.push("prm_groupCode=" + this.selected_groupCode);
      }
    },
  },
  async created() {
    await this.initialize();
  },
  components: { DocumentViewer },
};
</script>

<style></style>
