<template>
    <label><v-icon :color="dynamicColor">{{ dynamicIcon }}</v-icon> {{ dynamicStatusName }}</label>
</template>

<script>
import { isDateBetween, IsDateLaterThanEndDate } from '@/DateTimeHelper';
import { isInCaseOfCurrentDateLaterThanTrackingEndDate, isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate } from '@/GraduatedStudentJobTrackingReport_TimeChecker';
import { IsInAllowedToEditEmploymentStatus } from '@/GraduatedStudentJobTrackingReport_StatusChecker';
export default {
    name: 'GraduatedStudentJobTrackingReportMain_JobTracking_Status_Label',
    data() {
        return {}
    },
    computed: {
        dynamicStatusName() {
            return this.jobtrackingreport.jobtracking_status_statusname;
        },
        dynamicIcon() {
            switch (this.jobtrackingreport.jobtracking_status_statuscode) {
                case 'NotStartedTrackingProcess':
                    return 'mdi-clock-edit';
                case 'InProcessOfTracking':
                    return 'mdi-progress-pencil';
                case 'TrackingProcessIsCompleted':
                    return 'mdi-check-decagram';
                default:
                    return '';
            }
        },
        dynamicColor() {
           switch (this.jobtrackingreport.jobtracking_status_statuscode) {
                case 'NotStartedTrackingProcess':
                    return 'grey';
                case 'InProcessOfTracking':
                    return 'info';
                case 'TrackingProcessIsCompleted':
                    return 'success';
                default:
                    return '';
            } 
        }
    },
    props: ['jobtrackingreport'],
    created() {

    }
}
</script>

<style></style>