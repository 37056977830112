import EmployerSummaryListView from "@/views/EmployerSummary/EmployerSummaryListView.vue";
import CreateEmployerSummaryView from "@/views/EmployerSummary/CreateEmployerSummaryView.vue";
import EditEmployerSummaryView from "@/views/EmployerSummary/EditEmployerSummaryView.vue";
export default [
  {
    path: "/EmployerSummary",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/EmployerSummary/EmployerSummaryListView",
        name: "EmployerSummaryListView",
        component: EmployerSummaryListView,
        meta: {
          requiresAuth: true,
          nameTh: "สถานประกอบการ",
        },
      },
      {
        path: "/EmployerSummary/CreateEmployerSummaryView",
        name: "CreateEmployerSummaryView",
        component: CreateEmployerSummaryView,
        meta: {
          requiresAuth: true,
          nameTh: "เพิ่มสถานประกอบการ",
        },
      },
      {
        path: "/EmployerSummary/EditEmployerSummaryView/:oid",
        name: "EditEmployerSummaryView",
        component: EditEmployerSummaryView,
        meta: {
          requiresAuth: true,
          nameTh: "แก้ไขข้อมูลสถานประกอบการ",
        },
      },
    ],
  },
];
