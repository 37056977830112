<template>
  <div>
    <appbar></appbar>
    <v-container class="">
      <v-row class="justify-center pt-10">
        <LoginForm
          :loadingbtn="loading"
          @submit-login="onsubmitcomplete"
          @recovery-indentify="onclickforgetpassword"
          filename="Lteacher.jpg"
          description="ครูผู้สอน / ครูที่ปรึกษา"
        ></LoginForm>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import appbar from "@/components/appbar.vue";
import LoginForm from "@/components/LoginForm/LoginForm.vue";
import { AuthenticationProvider } from "@/services/AuthenticationProvider";
var authenticationProvider = new AuthenticationProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "LoginTeacher",

  components: {
    appbar,
    LoginForm,
  },

  data() {
    return {
      loading: false,
      loginpage: "teacher",
    };
  },
  methods: {
    async onclickforgetpassword() {
      const loginpage = this.loginpage;
      this.$router.push({
        name: "recoveryindentifySchoolEmployee",
        params: { loginpage },
      });
    },
    async onsubmitcomplete(loginCredential) {
      this.loading = true;
      const isChangePasswordOnLogon = await authenticationProvider.IsThisUserMustChangeFirstLogon(
        loginCredential
      );
      if (isChangePasswordOnLogon.data == true) {
        this.loading = false;
        const username = loginCredential.username;
        this.$router.push("/SetPassword");
        this.$router
          .push({
            name: "SetPassword",
            params: { username },
          })
          .catch((err) => {
            this.loading = false;
            this.$toast.error(
              "Incorrect username or password. ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"
            );
          });
      } else {
        this.$store
          .dispatch({ type: "doLogin", loginCredential })
          .then((response) => {
            if (response?.status == 200) {
              this.loading = false;
              this.$toast.success("เข้าสู่ระบบเรียบร้อย !");
              this.$store.dispatch({ type: "gotoFirstPage" });
              if (this.lsRememberMe == true) {
                localStorage.setItem("username", loginCredential.username);
              } else {
                localStorage.setItem("username", (loginCredential.username = ""));
              }
            } else {
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast.error(
              "Incorrect username or password. ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"
            );
          });
      }
    },
  },
  async created() {},
};
</script>

<style scoped>
.headertext {
  font-size: 22pt !important;
}
</style>
