<template>
  <v-container class="">
    <v-row>
      <v-col cols="12" class="d-flex justify-start">
        <v-btn color="primary" @click="gotoEmployerCreateJobPostingView()"
          ><v-icon>mdi-plus</v-icon> เพิ่มประกาศรับสมัครงาน</v-btn
        >
      </v-col>
    </v-row>
    <SearchableDataTable
      table_caption="ประกาศรับสมัครงานที่ร่างไว้"
      :data_items="jobpostings"
      :datatable_headers="jobpostings_data_table_headers"
    >
      <template v-slot:item.jobpostingstatus.statusname="{ item }">
        <JobPostingStatus_Label :JobPosting="item"></JobPostingStatus_Label>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row class="custom-width">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openEmployerJobPosting_Applications_View(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-file</v-icon>
              </v-btn>
            </template>
            <span>ติดตามสถานะประกาศงาน</span>
          </v-tooltip>
          <v-tooltip top color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openEditEmployerJobPostingView(item)"
                color="warning"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-file-edit</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขประกาศรับสมัครงาน</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="onDeleteJobPosting(item)"
                color="error"
                v-bind="attrs"
                v-on="on"
                icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>ลบ</span>
          </v-tooltip></v-row
        >
      </template>
    </SearchableDataTable>
  </v-container>
</template>
<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import moment from "moment";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
import JobPostingStatus_Label from "@/components/JobPostings/JobPostingStatus_Label.vue";
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable, JobPostingStatus_Label },
  name: "EmployerJobPostingListView_Draft",
  data() {
    return {
      jobpostings: undefined,
      jobpostings_data_table_headers: [
        { text: "รหัสประกาศงาน", value: "jobposting_id" },
        { text: "ตำแหน่ง", value: "positionname" },
        { text: "รูปแบบการจ้าง", value: "employmentcategory.name" },
        { text: "อัตราที่รับสมัคร", value: "unit" },
        { text: "สถานะ", value: "jobpostingstatus.statusname" },
        { text: "ตัวเลือก", value: "actions", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    async getJobPostings() {
      await employerJobPostingDataProvider.getInDraftJobPostings().then((result) => {
        this.jobpostings = result.data;
      });
    },
    openEmployerJobPosting_Applications_View(item) {
      const oid = item.oid;
      this.$router.push({
        name: "EmployerJobPosting_Applications_View",
        params: { oid },
      });
    },
    gotoEmployerCreateJobPostingView() {
      this.$router.push("EmployerCreateJobPostingView");
    },
    openEditEmployerJobPostingView(item) {
      var oid = item.oid;
      this.$router.push({
        name: "EmployerUpdateJobPostingView",
        params: { oid },
      });
    },
    async onDeleteJobPosting(item) {
      if (confirm("ยืนยันการลบประกาศ?")) {
        await employerJobPostingDataProvider
          .deleteEmployerJobPosting(item.oid)
          .then((result) => {
            this.$toast.success("ลบข้อมูลประกาศเรียบร้อย");
            this.getJobPostings();
          });
      }
    },
  },
  async created() {
    await this.getJobPostings();
  },
};
</script>

<style scoped>
.custom-width {
  width: 150px;
  gap: 10px;
}
</style>
