<template>
  <v-dialog v-model="dialog" persistent max-width="600px" v-if="jobtrackingdetail">
    <v-card class="pa-5">
      <v-card-title>
        <h5 class="primary--text">ภาวะการมีงานทำของผู้สำเร็จการศึกษา</h5>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-title>
        <v-col cols="12">
          <h5 class="primary--text">
            รหัสนักศึกษา:
            {{ jobtrackingdetail.student_veceducationinformation?.studentcode }}
          </h5>
        </v-col>

        <v-col cols="12" class="mt-0 pt-0">
          <span class="primary--text">
            <v-icon class="mr-1" color="primary">mdi-account</v-icon
            >{{ jobtrackingdetail.student?.firstname }}
            {{ jobtrackingdetail.student?.surname }}
          </span>
        </v-col>
      </v-card-title>

      <!-- <v-spacer></v-spacer> -->

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <h4 class="primary--text mb-2">ภาวะการมีงานทำ<span style="color: red">*</span></h4>
              <v-select
                :rules="[(v) => !!v || 'กรุณาเลือกสถานะการติดตาม']"
                v-model="jobtrackingdetail.employmentstatusname"
                v-on:change="changedemploymentstatusname"
                :items="EmploymentStatusNames"
                placeholder="ภาวะการมีงานทำ*"
                required
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                dense
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              v-if="jobtrackingdetail.employmentstatusname == 'ติดตามไม่ได้'"
            >
              <h4 class="primary--text mb-2">หมายเหตุ</h4>
              <v-text-field
                v-model="jobtrackingdetail.unknown_remark"
                placeholder="หมายเหตุ (ติดตามไม่ได้)"
                required
                background-color="input"
                filled
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" v-if="jobtrackingdetail.employmentstatusname == 'ศึกษาต่อ'">
              <h4 class="primary--text mb-2">ประเภทวิชา/คณะ<span style="color: red">*</span></h4>

              <v-text-field
                :rules="[(v) => !!v || 'กรุณาระบุ ประเภทวิชา/คณะ']"
                v-model="jobtrackingdetail.major"
                placeholder="ประเภทวิชา/คณะ "
                required
                background-color="input"
                filled
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" v-if="jobtrackingdetail.employmentstatusname == 'ศึกษาต่อ'">
              <h4 class="primary--text mb-2">สาขา<span style="color: red">*</span></h4>

              <v-text-field
                :rules="[(v) => !!v || 'กรุณาระบุสาขา']"
                v-model="jobtrackingdetail.minor"
                placeholder="สาขา"
                required
                background-color="input"
                filled
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" v-if="jobtrackingdetail.employmentstatusname == 'ศึกษาต่อ'">
              <h4 class="primary--text mb-2">สถานศึกษา<span style="color: red">*</span></h4>

              <v-combobox
                :rules="[(v) => !!v || 'กรุณาระบุสถานศึกษา']"
                v-model="jobtrackingdetail.schoolname"
                :items="corporates"
                item-text="nameth"
                placeholder="สถานศึกษา"
                required
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                @change="OnselectedSchool"
                dense
                :search-input.sync="search"
                cache-items
                :loading="loadingSchool"
              >
                <template v-slot:no-data>
                  <v-list-item rounded>
                    <v-list-item-title>
                      ค้นหา
                      <strong>สถานศึกษา</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" v-if="jobtrackingdetail.employmentstatusname == 'ศึกษาต่อ'">
              <v-spacer></v-spacer>
              <h4 class="primary--text mb-2">ศึกษาต่อในระดับ<span style="color: red">*</span></h4>

              <v-radio-group
                :rules="[(v) => !!v || 'กรุณาระบุศึกษาต่อในระดับ']"
                v-model="jobtrackingdetail.continuetostudylevel"
                row
              >
                <v-radio label="สายอาชีพ" color="blue" :value="1"></v-radio>
                <v-radio label="อื่นๆ" color="pink" :value="2"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-if="jobtrackingdetail.employmentstatusname == 'ศึกษาต่อ'">
              <v-spacer></v-spacer>
              <h4 class="primary--text mb-2">สาขาที่สมัคร<span style="color: red">*</span></h4>

              <v-radio-group
                :rules="[(v) => !!v || 'กรุณาระบุสาขาที่สมัคร']"
                v-model="jobtrackingdetail.continuetostudyinrelatedfieldstatusname"
                row
              >
                <v-radio label="ตรงตามสาขา" color="blue" value="ตรง"></v-radio>
                <v-radio label="ไม่ตรงตามสาขา" color="pink" value="ไม่ตรง"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              cols="12"
              class="px-6"
              v-if="
                jobtrackingdetail.employmentstatusname == 'รัฐวิสาหกิจ' ||
                jobtrackingdetail.employmentstatusname == 'บริษัทเอกชน' ||
                jobtrackingdetail.employmentstatusname == 'หน่วยงานราชการ'
              "
            >
              <v-row>
                <v-col class=" primary--text py-0 px-0" align="start"><h4>หน่วยงาน/สถานประกอบการ<span style="color: red">*</span></h4></v-col>
                <v-col class="py-0 px-0" align="end"
                  ><div>
                    <v-tooltip top color="secondary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          color="secondary"
                          @click="openDialogCreateEmployerSummaryView = true"
                        >
                          <v-icon class="mb-5">mdi-office-building-plus-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>เพิ่มข้อมูลสถานประกอบการ</span>
                    </v-tooltip>
                  </div></v-col
                >
              </v-row>

              <v-row>
                <v-autocomplete
                  @change="OnselectedCorporation"
                  :rules="require_corporation_conditionalRule"
                  v-model="jobtrackingdetail.employersummary_oid"
                  :items="corporates"
                  item-value="oid"
                  item-text="nameth"
                  placeholder="หน่วยงาน/สถานประกอบการ"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  dense
                  :search-input.sync="search"
                  cache-items
                  :loading="loadingCorporation"
                  clearable
                  :disabled="jobtrackingdetail.iscorporationunknown"
                  style="width: 500px"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        ค้นหา
                        <strong>บริษัท/หน่วยงานราชการ</strong>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row class="mt-0 py-0"
                ><v-checkbox
                  class="mt-2 py-0"
                  v-model="jobtrackingdetail.iscorporationunknown"
                  label="ไม่ทราบข้อมูลสถานประกอบการ"
                  @change="ClearEmployersummary"
                ></v-checkbox
              ></v-row>
            </v-col>
            <!-- <v-col
              cols="12"
              class="d-flex align-center justify-space-between mb-0 pb-0"
              v-if="
                jobtrackingdetail.employmentstatusname == 'รัฐวิสาหกิจ' ||
                jobtrackingdetail.employmentstatusname == 'บริษัทเอกชน' ||
                jobtrackingdetail.employmentstatusname == 'หน่วยงานราชการ'
              "
            >
            <h4 class="primary--text mb-2">หน่วยงาน/สถานประกอบการ</h4>
              <div>
                <div>
                  <v-checkbox
                    v-model="jobtrackingdetail.iscorporationunknown"
                    label="ไม่ทราบข้อมูลสถานประกอบการ"
                  ></v-checkbox>
                </div>
              </div>

              <div>
                <v-autocomplete
                  @change="OnselectedCorporation"
                  :rules="[(v) => !!v || 'กรุณาระบุหน่วยงาน/สถานประกอบการ']"
                  v-model="jobtrackingdetail.employersummary_oid"
                  :items="corporates"
                  item-value="oid"
                  item-text="nameth"
                  label="หน่วยงาน/สถานประกอบการ"
                  :required="jobtrackingdetail.iscorporationunknown"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  dense
                  :search-input.sync="search"
                  cache-items
                  :loading="loadingCorporation"
                  style="width: 500px"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        ค้นหา
                        <strong>บริษัท/หน่วยงานราชการ</strong>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-tooltip top color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      color="secondary"
                      @click="openDialogCreateEmployerSummaryView = true"
                    >
                      <v-icon class="mb-5">mdi-office-building-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>เพิ่มข้อมูลสถานประกอบการ</span>
                </v-tooltip>
              </div>
            </v-col> -->
            <span class="pl-8 pt-0 mt-0" v-if="selectcoraddress && !jobtrackingdetail.iscorporationunknown"
              >{{ selectcoraddress }}
            </span>
            <!-- <span v-if="selectcorprovince">{{ selectcorprovince }} </span> -->
            <!-- <span v-if="selectcorzipcode">{{ selectcorzipcode }}</span> -->
            <!-- </v-col> -->

            <v-col
              cols="12"
              v-if="
                jobtrackingdetail.employmentstatusname == 'รัฐวิสาหกิจ' ||
                jobtrackingdetail.employmentstatusname == 'บริษัทเอกชน' ||
                jobtrackingdetail.employmentstatusname == 'หน่วยงานราชการ'
              "
              class="mt-3"
            >
              <h4 class="primary--text mb-2">ประเภทงาน<span style="color: red">*</span></h4>

              <v-autocomplete
                :rules="[(v) => !!v || 'ประเภทงาน']"
                v-model="jobtrackingdetail.jobdescription"
                :items="JobSegment"
                placeholder="ประเภทงาน"
                item-text="name"
                item-value="name"
                required
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                dense
                cache-items
                :loading="loadingjobdescription"
              >
              </v-autocomplete>
              <!-- <span class="pl-5" v-if="selectcoraddress">{{ selectcoraddress }} </span>
              <span v-if="selectcorprovince">{{ selectcorprovince }} </span>
              <span v-if="selectcorzipcode">{{ selectcorzipcode }}</span> -->
            </v-col>

            <v-col
              cols="12"
              v-if="
                jobtrackingdetail.employmentstatusname == 'หน่วยงานราชการ' ||
                jobtrackingdetail.employmentstatusname == 'รัฐวิสาหกิจ' ||
                jobtrackingdetail.employmentstatusname == 'บริษัทเอกชน'
              "
            >
              <h4 class="primary--text mb-2">ตำแหน่ง<span style="color: red">*</span></h4>

              <v-autocomplete
                v-if="!checkanotherposition"
                :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                v-model="jobtrackingdetail.jobpositioncode"
                :items="jobpositions"
                item-value="code"
                item-text="positionname"
                placehlder="ตำแหน่ง"
                required
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                dense
                :search-input.sync="searchjobposition"
                cache-items
                :loading="loadingjobposition"
                class="py-0 my-0"
              >
                <!-- <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      ค้นหา
                      <strong>ตำแหน่ง1</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template> -->
              </v-autocomplete>
              <v-checkbox
                class="pa-0 my-0"
                v-model="checkanotherposition"
                @change="oncheckpositionanother"
                label="ตำแหน่งอื่นๆ"
                rounded
              ></v-checkbox>
              <v-text-field
                v-if="checkanotherposition"
                v-model="jobtrackingdetail.jobpositionname"
                placeholder="กรุณาระบุชื่อตำแหน่ง"
                background-color="input"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              v-if="
                jobtrackingdetail.employmentstatusname == 'หน่วยงานราชการ' ||
                jobtrackingdetail.employmentstatusname == 'รัฐวิสาหกิจ' ||
                jobtrackingdetail.employmentstatusname == 'บริษัทเอกชน' ||
                jobtrackingdetail.employmentstatusname == 'อาชีพอิสระ'
              "
            >
              <h4 class="primary--text mb-2">เงินเดือน<span style="color: red">*</span></h4>

              <v-select
                :rules="[(v) => !!v || 'กรุณาระบุเงินเดือน']"
                v-model="jobtrackingdetail.salaryrange"
                :items="SalaryRanges"
                item-value="salary_range"
                item-text="salary_range"
                placeholder="เงินเดือน"
                required
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                dense
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              v-if="
                jobtrackingdetail.employmentstatusname == 'หน่วยงานราชการ' ||
                jobtrackingdetail.employmentstatusname == 'รัฐวิสาหกิจ' ||
                jobtrackingdetail.employmentstatusname == 'บริษัทเอกชน' ||
                jobtrackingdetail.employmentstatusname == 'อาชีพอิสระ'
              "
            >
              <h4 class="primary--text mb-2">งานที่ทำตรงกับวุฒิหรือไม่<span style="color: red">*</span></h4>

              <v-spacer></v-spacer>
              <v-radio-group
                :rules="[(v) => !!v || 'กรุณาระบุงานที่ทำตรงกับวุฒิ']"
                v-model="
                  jobtrackingdetail.gettingjobthatrelatedwithstudiedfieldstatusname
                "
                row
              >
                <v-radio label="ตรงตามวุฒิ" color="blue" value="ตรง"></v-radio>
                <v-radio label="ไม่ตรงตามวุฒิ" color="pink" value="ไม่ตรง"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-if="jobtrackingdetail.employmentstatusname == 'อื่นๆ'">
              <h4 class="primary--text mb-2">คำอธิบาย<span style="color: red">*</span></h4>

              <v-textarea
                :rules="[(v) => !!v || 'กรุณาระบุคำอธิบาย']"
                v-model="jobtrackingdetail.other_description"
                placeholder="คำอธิบาย "
                required
                background-color="input"
                filled
                dense
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="warningMessagetext != ''">
            <v-col cols="12" class="d-flex justify-center">
              <label class="textWarning"
                >ไม่สามารถบันทึกการติดตามได้ {{ warningMessagetext }}
              </label>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="justify-center text-center">
          <div>
            <v-btn color="" @click="closedialog(false)" class="ma-2 back-button">
              ย้อนกลับ
            </v-btn>
            <v-btn
              :disabled="isSaveBtnDisableBool"
              :loading="loadingbtnsave"
              @click="save"
              class="ma-2 color-button2"
            >
              บันทึก <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card-text>
      <v-dialog v-model="openDialogCreateEmployerSummaryView" max-width="500px">
        <!-- Dialog content -->
        <v-form
          ref="formEmployerSummary"
          v-model="validEmployerSummary"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-card class="elevation-1 pa-5 px-10">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <h3 class="primary--text">เพิ่มข้อมูลสถานประกอบการ</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mb-0 pb-0">
                <h4 class="primary--text mb-2">เลขประจำตัวผู้เสียภาษี<span style="color: red">*</span></h4>

                <v-text-field
                  ref="txtIdentityid"
                  placeholder="เลขประจำตัวผู้เสียภาษี"
                  background-color="input"
                  filled
                  v-model="vm.identityid"
                  :rules="custom_identityidRules"
                  :disabled="vm.dontknowidentityid"
                ></v-text-field>
                <div class="mt-0 pt-0">
                  <v-checkbox
                    label="ไม่มีเลขทะเบียนนิติบุคคล/เลขผู้เสียภาษี"
                    v-model="vm.dontknowidentityid"
                    @change="ClearIdentityID"
                    class="ma-0 pa-0"
                  ></v-checkbox>
                  <div>
                    <label class="" style="color:red; font-size: smaller;"
                      >*ตัวเลือกนี้ควรเลือกใช้เฉพาะในกรณีที่ไม่สามารถระบุ
                      "เลขทะเบียนนิติบุคคล/เลขผู้เสียภาษี" ได้จริง ๆ เท่านั้น</label
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mb-0 pb-0 mt-2">
                <h4 class="primary--text mb-2">ชื่อสถานประกอบการ<span style="color: red">*</span></h4>

                <v-text-field
                  ref="txtEmployername"
                  placeholder="ชื่อสถานประกอบการ"
                  v-model="vm.nameth"
                  :rules="custom_employername"
                  background-color="input"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <h4 class="primary--text mb-2">ที่อยู่</h4>

                <v-text-field
                  placeholder="ที่อยู่"
                  v-model="vm.address"
                  background-color="input"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <h4 class="primary--text mb-2">จังหวัด<span style="color: red">*</span></h4>

                <v-autocomplete
                  placeholder="จังหวัด"
                  :items="provinces"
                  item-text="provincethai"
                  item-value="provinceid"
                  v-model="vm.selected_province_id"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  :rules="[(v) => !!v || 'กรุณาเลือกจังหวัด']"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn @click="onSubmit" class="color-button2">
                  บันทึก <v-icon>mdi-content-save</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { GovernmentDataProvider } from "@/services/GovernmentDataProvider";
import { EmployerSummaryDataProvider } from "@/services/EmployerSummaryDataProvider";
import { JobPositionDataProvider } from "@/services/jobPositionDataProvider";
import { IsInAllowedToEditEmploymentStatus } from "@/GraduatedStudentJobTrackingReport_StatusChecker";
import {
  isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate,
  isInCaseOfCurrentDateLaterThanTrackingEndDate,
} from "@/GraduatedStudentJobTrackingReport_TimeChecker";
import { identityidRules } from "@/ValidationHelper.js";
import { CreateEmployerSummaryRequest } from "@/models/EmployerSummaries/CreateEmployerSummaryRequest";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";

const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const employerSummaryDataProvider = new EmployerSummaryDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var jobPositionDataProvider = new JobPositionDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

var graduatedStudentJobTrackingReportDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var governmentDataProvider = new GovernmentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  name: "DialogFormJobTracking",
  props: {
    dialog: Boolean,
    oid: "",
  },
  data: () => ({
    valid: false,
    provinces: undefined,
    vm: new CreateEmployerSummaryRequest(),
    identityidRules: identityidRules,
    identityidExistingResult: false,
    originalidentityid: "",
    openDialogCreateEmployerSummaryView: false,
    valid: "",
    validEmployerSummary: "",
    student: [],
    EmploymentStatusNames: [],
    SalaryRanges: [],
    corporates: [],
    school: [],
    governments: [],

    loadingbtnsave: false,

    loading: false,
    loadingjobdescription: false,
    loadingSchool: false,
    loadingCorporation: false,
    search: null,

    jobtrackingdetail: [],
    jobpositions: [],
    loadingjobposition: false,
    jobdescription_loading: false,
    searchjobposition: null,

    selectcoraddress: "",
    selectcorprovince: "",
    selectcorzipcode: "",
    JobSegment: [],
    checkanotherposition: false,
    warningMessagetext: "",
    isSaveBtnDisableBool: true,
    request_school_data: new GetSchools_Reqeust(),
  }),
  computed: {
    custom_identityidRules() {
      return [
        // (v) =>
        //   this.identityidResult === false || "เลขประจำตัวผู้เสียภาษีนี้ถูกใช้งานแล้ว",
        (v) => !!v || this.vm.dontknowidentityid || "กรุณาระบุเลขประจำตัวผู้เสียภาษี",
        (v) =>
          !this.identityidExistingResult ||
          this.vm.dontknowidentityid ||
          "เลขประจำตัวผู้เสียภาษีนี้ถูกใช้งานแล้ว",
      ];
    },
    require_corporation_conditionalRule() {
      const rule = [(v) => !!v || "กรุณาระบุหน่วยงาน/สถานประกอบการ"];
      return this.jobtrackingdetail.iscorporationunknown === false ? rule : [];
    },
    custom_employername() {
      return [
        (v) => !!v || "กรุณากรอกชื่อสถานประกอบการ",
        (v) => !v.startsWith("-") || "กรุณากรอกเป็นตัวอักษร",
        (v) => !v.startsWith(".") || "กรุณากรอกเป็นตัวอักษร",
        (v) => v.length >= 3 || "ชื่อสถานประกอบการต้องมีอย่างน้อย 3 ตัวอักษร",
        (v) =>
          this.employernameExistResult === false || "ชื่อสถานประกอบการนี้ถูกใช้งานแล้ว",
      ];
    },
  },
  methods: {
    async initialize() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
      await schoolDataProvider.loadSchools(this.request_school_data).then((result) => {
        this.school = result.data;
        console.log(this.school);
      });
    },

    async isSaveBtnDisable() {
      if (
        this.jobtrackingdetail.graduatedstudentjobtrackingreport == undefined ||
        this.loadingbtnsave == true
      ) {
        return true;
      }
      if (
        this.jobtrackingdetail.graduatedstudentjobtrackingreport.jobtracking_status
          .statuscode == "AllowedToEdit"
      ) {
        return false;
      }

      if (
        await isInCaseOfCurrentDateLaterThanTrackingEndDate(
          this.jobtrackingdetail.graduatedstudentjobtrackingreport
        )
      ) {
        return true;
      }
      if (
        !(await isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate(
          this.jobtrackingdetail.graduatedstudentjobtrackingreport
        ))
      ) {
        return true;
      }
      if (
        !IsInAllowedToEditEmploymentStatus(
          this.jobtrackingdetail.graduatedstudentjobtrackingreport
        )
      ) {
        return true;
      }
      return false;
    },
    async warningMessage() {
      if (this.jobtrackingdetail.graduatedstudentjobtrackingreport == undefined) {
        return "";
      }
      if (
        this.jobtrackingdetail.graduatedstudentjobtrackingreport.jobtracking_status
          .statuscode == "AllowedToEdit"
      ) {
        return "";
      }
      if (
        !IsInAllowedToEditEmploymentStatus(
          this.jobtrackingdetail.graduatedstudentjobtrackingreport
        )
      ) {
        return "เนื่องจากไม่อยู่ในสถานะที่อนุญาตให้แก้ไขข้อมูล";
      }
      if (
        await isInCaseOfCurrentDateLaterThanTrackingEndDate(
          this.jobtrackingdetail.graduatedstudentjobtrackingreport
        )
      ) {
        console.log(
          isInCaseOfCurrentDateLaterThanTrackingEndDate(
            this.jobtrackingdetail.graduatedstudentjobtrackingreport
          )
        );
        return "เนื่องจากหมดเวลาในการติดตาม";
      }
      if (
        !(await isInCaseOfCurrentDateBetweenTrackingStartDateAndTrackingEndDate(
          this.jobtrackingdetail.graduatedstudentjobtrackingreport
        ))
      ) {
        return "เนื่องจากไม่อยู่ในช่วงเวลาของวันที่เปิดให้ติดตาม";
      }

      return "";
    },
    oncheckpositionanother() {
      if (this.checkanotherposition) {
        this.jobtrackingdetail.jobpositioncode = "another";
      }
    },
    async save() {
      console.log(this.jobtrackingdetail);
      var validate = this.$refs.form.validate();
      if (this.jobtrackingdetail.iscorporationunknown) {
        this.jobtrackingdetail.employersummary_oid = "";
      }
      if (validate) {
        const jsonData = JSON.stringify(this.jobtrackingdetail);
        this.loadingbtnsave = true;
        console.log("jobtrackingdetail: ", this.jobtrackingdetail);
        await graduatedStudentJobTrackingReportDataProvider
          .SaveGraduatedStudentJobTrackingReportStudentDetail(this.jobtrackingdetail)
          .then((response) => {
            this.jobtrackingdetail.editdatedisplay = new Date();
            this.$toast.success("บันทึกเรียบร้อย !");
            this.loadingbtnsave = false;
            this.closedialog(true);
            this.$emit("onFilteringEmploymentStatusNames");
          })
          .catch((err) => {
            this.$toast.error("ล้มเหลว !" + err.response.data);
            this.loadingbtnsave = false;
          });
      } else {
        console.log("กรอกข้อมูลให้ครบถ้วน");
      }
    },

    async loadStudentDetails() {
      await graduatedStudentJobTrackingReportDataProvider
        .loadGraduatedStudentDetail(this.oid)
        .then((response) => {
          this.jobtrackingdetail = response.data;
          console.log(this.jobtrackingdetail);
          if (this.jobtrackingdetail.employersummary_oid) {
            const employersummary_oid = this.jobtrackingdetail.employersummary_oid;
            employerSummaryDataProvider
              .getEmployerSummaryByOid(employersummary_oid)
              .then((result) => {
                this.jobtrackingdetail.employersummary_oid = result.data.oid;
              });
          }
          if (this.jobtrackingdetail.jobpositioncode == "another") {
            this.checkanotherposition = true;
          } else {
            this.checkanotherposition = false;
          }
        });
    },
    closedialog(val) {
      this.$emit(
        "closeDialogFormJobTracking",
        val,
        this.jobtrackingdetail.employmentstatusname
      );
    },
    async loadJobSegment() {
      masterDataProvider.getJobSegment().then((response) => {
        if (response?.status == 200) {
          this.JobSegment = response.data;
        }
      });
    },
    changedemploymentstatusname(item) {
      this.jobtrackingdetail.note = "";
      this.jobtrackingdetail.schoolname = "";
      this.jobtrackingdetail.continuetostudyinrelatedfieldstatusname = "";
      this.jobtrackingdetail.corporationname = "";
      // this.jobtrackingdetail.iscorporationunknown = false;
      this.jobtrackingdetail.employersummary_oid = "";
      this.jobtrackingdetail.jobpositioncode = "";
      this.jobtrackingdetail.jobpositionname = "";
      this.jobtrackingdetail.jobdescription = "";
      this.jobtrackingdetail.salaryrange = "";
      this.jobtrackingdetail.other_description = "";
      this.jobtrackingdetail.unknown_remark = "";
      this.jobtrackingdetail.gettingjobthatrelatedwithstudiedfieldstatusname = "";
      this.jobtrackingdetail.continuetostudylevel = "";
    },

    loadonfilterEmployerSummary(val) {
      if (val) {
        employerSummaryDataProvider.getEmployerSummaryByKeyword(val).then((response) => {
          console.log("ress.data: ", response.data);
          return (this.corporates = response.data);
        });
      }
    },
    OnselectedCorporation(oid) {
      employerSummaryDataProvider.getEmployerSummaryByOid(oid).then((response) => {
        this.selectcoraddress = response.data?.address;
        this.selectcorprovince = response.data?.province_name;
        this.selectcorzipcode = response.data?.zipcode;
      });
      // console.log("item: ", item);
      // if (item) {
      //   this.jobtrackingdetail.corporationname = item.nameth;
      //   this.jobtrackingdetail.corporationtaxid = item.identityid;
      // }
    },
    OnselectedSchool(item) {
      if (item.nameth) {
        this.jobtrackingdetail.schoolname = item.nameth;
        this.jobtrackingdetail.schoolid = item.identityid;
      } else {
        this.jobtrackingdetail.schoolname = item;
      }
    },
    querySelections(v) {
      this.loading = true;
      setTimeout(() => {
        this.corporates = this.loadonfilterEmployerSummary(v);
        this.loading = false;
      }, 500);
    },
    loadonfilterJobPosition(keyword, maxrow) {
      if (keyword) {
        jobPositionDataProvider.loadAllJobPosition(keyword, maxrow).then((response) => {
          this.jobpositions = response.data;
        });
      }
    },
    querySelectionsJobPosition(v) {
      this.loadingjobposition = true;
      setTimeout(() => {
        this.jobpositions = this.loadonfilterJobPosition(v, 100);
        this.loadingjobposition = false;
      }, 500);
    },

    async onSubmit() {
      if (this.$refs.formEmployerSummary.validate()) {
        if (confirm("ต้องการบันทึกข้อมูลหรือไม่ ?")) {
          await employerSummaryDataProvider
            .createEmployerSummary(this.vm)
            .then((result) => {
              this.search = result.data.nameth;
              this.jobtrackingdetail.employersummary_oid = result.data.oid;
              this.$toast.success("เพิ่มข้อมูลสถานประกอบการเรียบร้อย");
              this.ClearCreateEmployerSummaryRequest();
              this.OnselectedCorporation(this.jobtrackingdetail.employersummary_oid);
              this.openDialogCreateEmployerSummaryView = false;
            })
            .catch((error) => {
              this.$toast.error(error.message);
            });
        }
      }
    },
    ClearCreateEmployerSummaryRequest() {
      this.vm.identityid = "";
      this.vm.nameth = "";
      this.vm.branchno = "";
      this.vm.address = "";
      this.vm.selected_province_id = "";
      this.vm.dontknowidentityid = false;
    },
    async isCheck() {
      this.vm.dontknowidentityid = false;
    },
    ClearIdentityID() {
      if (this.vm.dontknowidentityid) {
        this.vm.identityid = "";
      }
    },
    ClearEmployersummaryOid() {
      if (this.iscorporationunknown) {
        this.jobtrackingdetail.employersummary_oid = "";
      }
    },
    ClearEmployersummary() {
      if (this.jobtrackingdetail.iscorporationunknown) {
        this.jobtrackingdetail.employersummary_oid = "";
      }
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    searchjobposition(val) {
      val && val !== this.selectjobposition && this.querySelectionsJobPosition(val);
    },
    async dialog(visible) {
      if (visible) {
        this.selectcoraddress = "";
        this.selectcorprovince = "";
        this.selectcorzipcode = "";

        await this.loadStudentDetails();
        await this.loadJobSegment();
        await masterDataProvider.loadEmploymentStatusNames().then((response) => {
          this.EmploymentStatusNames = response.data;
        });
        await masterDataProvider.loadSalaryRanges().then((response) => {
          this.SalaryRanges = response.data;
        });

        // masterDataProvider.loadGetCorporates().then((response) => {
        //   this.corporates = response.data;
        // });
        if (this.jobtrackingdetail.corporationname) {
          await employerSummaryDataProvider
            .getEmployerSummaryByKeyword(this.jobtrackingdetail.corporationname)
            .then((response) => {
              this.corporates = response.data;
              this.OnselectedCorporation(this.jobtrackingdetail.employersummary_oid);
            });
        }

        if (this.jobtrackingdetail.schoolname) {
          await employerSummaryDataProvider
            .getEmployerSummaryByKeyword(this.jobtrackingdetail.schoolname)
            .then((response) => {
              this.school = response.data;
              // this.OnselectedSchool(this.jobtrackingdetail);
            });
        }

        if (this.jobtrackingdetail.jobpositionname) {
          await jobPositionDataProvider
            .loadAllJobPosition(this.jobtrackingdetail.jobpositionname, 10)
            .then((response) => {
              this.jobpositions = response.data;
            });
        }
        await governmentDataProvider.getGovernmentRecords().then((response) => {
          this.governments = response.data;
        });
        this.warningMessagetext = await this.warningMessage();
        this.isSaveBtnDisableBool = await this.isSaveBtnDisable();
        console.log(this.isSaveBtnDisableBool);
      } else {
      }
    },
    "vm.identityid": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await employerSummaryDataProvider.IsIdentityIDUsed(newVal).then((result) => {
            this.identityidExistingResult = result.data;
            console.log(this.identityidExistingResult);
            this.$refs.txtIdentityid.validate();
          });
        }
      },
      deep: true,
    },
    "jobtrackingdetail.iscorporationunknown"(newVal) {
      if (newVal === true) {
        this.jobtrackingdetail.employersummary_oid = "";
      }
    },
    "vm.nameth": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await employerSummaryDataProvider
            .isEmployerSummaryUsed(newVal)
            .then((result) => {
              this.employernameExistResult = result.data;
              this.$refs.txtEmployername.validate();
            });
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.textWarning {
  color: red;
}
</style>
