<template>
  <div class="pa-4">
    <SearchableDataTable
      :data_items="job_tracking_reports"
      :datatable_headers="datatable_headers"
      table_caption="รายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษา"
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          outlined
          @click="openJobTrackingReportDetailView(item)"
          color="secondary"
          class="ma-2"
        >
          <v-icon class="mr-2">mdi-magnify</v-icon>รายละเอียด
        </v-btn>
      </template>
      <template v-slot:item.importstudentdata_status_statusname="{ item }">
        <GraduatedStudentJobTrackingReport_ImportStudent_Status_Label
          :importstudentdata_status="item.importstudentdata_status_statuscode"
          :importstudentdata_status_statusname="item.importstudentdata_status_statusname"
        >
        </GraduatedStudentJobTrackingReport_ImportStudent_Status_Label>
      </template>
      <template v-slot:item.jobtracking_status_statusname="{ item }">
        <GraduatedStudentJobTrackingReport_JobTracking_Status_Label
          v-if="item"
          :jobtrackingreport="item"
        >
        </GraduatedStudentJobTrackingReport_JobTracking_Status_Label>
      </template>
    </SearchableDataTable>
    <Popup_CreateGraduatedJobTrackingReportView
      v-model="display_Popup_CreateGraduatedJobTrackingReportView"
      @closePopup="display_Popup_CreateGraduatedJobTrackingReportView = false"
    >
    </Popup_CreateGraduatedJobTrackingReportView>
  </div>
</template>

<script>
import axios from "axios";
import { backendapiUrl } from "@/services/constants";
import Popup_CreateGraduatedJobTrackingReportView from "@/views/GraduatedStudentJobTrackingReport/Popup_CreateGraduatedJobTrackingReportView.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { GuidanceTeacher_GraduatedStudentJobTrackingDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GuidanceTeacher_GraduatedStudentJobTrackingDataProvider";
import GraduatedStudentJobTrackingReport_ImportStudent_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_ImportStudent_Status_Label.vue";
import GraduatedStudentJobTrackingReport_JobTracking_Status_Label from "@/components/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReport_JobTracking_Status_Label.vue";

const headers = { "Content-Type": "application/json" };
const commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);

const guidanceTeacher_GraduatedStudentJobTrackingDataProvider = new GuidanceTeacher_GraduatedStudentJobTrackingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: {
    Popup_CreateGraduatedJobTrackingReportView: () =>
      import(
        "@/views/GraduatedStudentJobTrackingReport/Popup_CreateGraduatedJobTrackingReportView.vue"
      ),
    Popup_CreateGraduatedJobTrackingReportView,
    SearchableDataTable,
    GraduatedStudentJobTrackingReport_JobTracking_Status_Label,
    GraduatedStudentJobTrackingReport_ImportStudent_Status_Label,
  },
  name: "GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified",
  data() {
    return {
      yearth_choices: [],
      semester_choices: [],
      input_yearth: "",
      input_semester: "",
      datatable_headers: [
        { value: "schoolid", text: "รหัสสถานศึกษา" },
        { value: "schoolname", text: "ชื่อสถานศึกษา" },
        { value: "targetgraduatedyearth", text: "ปีการศึกษาที่จบ" },
        { value: "roundnumber", text: "รอบที่ติดตาม" },
        {
          value: "quantityofgraduatedstudentjobtrackingreport_studentdetails",
          text: "จำนวนนักศึกษาที่สำเร็จการศึกษา",
        },
        { value: "importstudentdata_status_statusname", text: "สถานะการนำเข้าข้อมูล" },
        { value: "jobtracking_status_statusname", text: "สถานะการติดตาม" },
        { value: "action", text: "", sortable: false },
      ],
      job_tracking_reports: [],
      display_Popup_CreateGraduatedJobTrackingReportView: false,
    };
  },
  methods: {
    async loadChoices() {
      //Get yearth choices
      await commonDataProvider.getListOfYearTH().then((result) => {
        this.yearth_choices = result.data;
        this.yearth_choices.sort();
      });

      await commonDataProvider.getListOfSemesters().then((result) => {
        this.semester_choices = result.data;
        this.semester_choices.sort();
      });
    },
    createJsonRequestObject() {
      const data = JSON.stringify({
        yearth: this.input_yearth,
        semester: this.input_semester,
        schoolid: this.schoolid,
      });
      return data;
    },
    async loadTrackingReports() {
      await guidanceTeacher_GraduatedStudentJobTrackingDataProvider
        .get_Verified_GraduatedStudentJobTrackingReports()
        .then((result) => {
          this.job_tracking_reports = result.data;
        });
    },
    openJobTrackingReportDetailView(jobTrackingReport) {
      var trackingReportOid = jobTrackingReport.oid;
      this.$router
        .push({
          name: "GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher_Verified",
          params: { trackingReportOid },
        })
        .catch((err) => {});
    },
    open_Popup_CreateGraduatedStudentJobTrackingReport() {
      this.display_Popup_CreateGraduatedJobTrackingReportView = true;
    },
  },
  async created() {
    await this.loadChoices();
    await this.loadTrackingReports();
  },
  beforeCreate() {},
};
</script>
