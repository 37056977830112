import Vue from "vue";
import Vuex from "vuex";
import authenticateProvider from "@/services/AuthenticateProvider";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { server } from "@/services/constants";
import router from "@/router/index";
import { backendapiUrl } from "@/services/constants";
import { AccountProfileDataProvider } from "@/services/Profile/AccountProfileDataProvider";
import notification from "@/store/notification.store";
import historyroute from "@/store/historyroute.store";
import livechat from "@/store/livechat.store";
import { NavigationItemsCreatorService } from "@/services/Navigation/NavigationItemsCreatorService";
import { ApplicationRoleCodes } from "@/Constants";

const accountProfileDataProvider = new AccountProfileDataProvider(
  backendapiUrl
);

var studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogged: false,
    username: "",
    rolecodes: [],
    token: "",
    image: "",
    profileoid: "",
    applicationuser_oid: "",
    name: "",
    surname: "",
    isLoadingProfile: true,
    isLoading: false,
    LoginPath: false,
    oid: "",
  },
  getters: {
    isLogin(state) {
      return state.isLogged;
    },
    username(state) {
      return state.username;
    },
    rolecodes(state) {
      return state.rolecodes;
    },
    image(state) {
      return state.image;
    },
    profileoid(state) {
      return state.profileoid;
    },
    applicationuser_oid(state) {
      return state.applicationuser_oid;
    },
    name(state) {
      return state.name;
    },
    surname(state) {
      return state.surname;
    },
    isLoadingProfile(state) {
      return state.isLoadingProfile;
    },
    isTrackingMenuExpanded(state) {
      return state.isTrackingMenuExpanded;
    },
    collapseTrackingMenu(state) {
      return state.collapseTrackingMenu;
    },
    selectedDropdown: (state) => state.selectedDropdown,
    oid(state) {
      return state.oid;
    },
    LoginPath: (state) => state.LoginPath,
  },
  mutations: {
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    SET_LOGGED_IN(state) {
      state.isLogged = true;
    },
    SET_LOGGED_OUT(state) {
      state.isLogged = false;
    },
    SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_ROLE_CODES(state, roleCodes) {
      state.rolecodes = roleCodes;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_IMAGE(state, image) {
      state.image = image;
    },
    SET_PROFILE_OID(state, profileoid) {
      state.profileoid = profileoid;
    },
    SET_APPLICATIONUSER_OID(state, applicationuser_oid) {
      state.applicationuser_oid = applicationuser_oid;
    },
    SET_NAME(state, name) {
      state.name = name;
    },
    SET_SURNAME(state, surname) {
      state.surname = surname;
    },
    SET_IS_LOAD_PROFILE_FALSE(state) {
      state.isLoadingProfile = false;
    },
    SET_IS_LOAD_PROFILE_TRUE(state) {
      state.isLoadingProfile = true;
    },
    SET_EXPAND_TRACKING_MENU(state) {
      localStorage.setItem("expandTrackingMenu", true);
      state.isTrackingMenuExpanded = true;
      state.collapseTrackingMenu = false;
    },
    SET_COLLAPSE_TRACKING_MENU(state) {
      localStorage.setItem("expandTrackingMenu", false);
      state.isTrackingMenuExpanded = false;
      state.collapseTrackingMenu = true;
    },
    SET_TRACKING_MENU_STATE(state, isExpanded) {
      localStorage.setItem("expandTrackingMenu", isExpanded);
      state.isTrackingMenuExpanded = isExpanded;
      state.collapseTrackingMenu = !isExpanded;
    },
    SET_DARK_THEME(state, isDark) {
      localStorage.setItem("darktheme", isDark);
      state.isDarktheme = isDark;
    },
    setSelectedDropdown({ commit }, value) {
      commit("SET_SELECTED_DROPDOWN", value);
    },
    SET_LOGIN_PATH(state, isLoginPath) {
      state.LoginPath = isLoginPath;
    },
    SET_OID(state, oid) {
      state.oid = oid;
    },
  },

  actions: {
    showLoading({ commit }) {
      commit("setLoading", true);
    },
    hideLoading({ commit }) {
      commit("setLoading", false);
    },
    restoreLogin({ commit, dispatch }) {
      if (authenticateProvider.isLoggedIn() == true) {
        return new Promise((resolve, reject) => {
          if (this.state.isLoadingProfile) {
            authenticateProvider
              .userinfo()
              .then((response) => {
                if (response?.status) {
                  commit("SET_LOGGED_IN");
                  console.log(response.data.rolecodes);
                  commit("SET_USERNAME", response.data.username);
                  commit("SET_ROLE_CODES", response.data.rolecodes);

                  if (
                    response.data.rolecodes.includes(
                      ApplicationRoleCodes.Employer
                    ) ||
                    response.data.rolecodes.includes(
                      ApplicationRoleCodes.Student
                    )
                  ) {
                    commit("SET_COLLAPSE_TRACKING_MENU");
                  }
                  if (response.data.rolecodes == "Student")
                    studentDataProvider
                      .loadCurrentStudentProfile()
                      .then((result) => {
                        commit("SET_OID", result.data.oid);
                      });
                  commit("SET_IS_LOAD_PROFILE_FALSE");
                  resolve(response);
                } else {
                  dispatch("doLogout", {});
                }
              })
              .catch();
            accountProfileDataProvider
              .loadCurrentAccountProfile()
              .then((result) => {
                commit("SET_IMAGE", result.data.userphoto_base64string);
                commit("SET_PROFILE_OID", result.data.profileoid);
                commit(
                  "SET_APPLICATIONUSER_OID",
                  result.data.applicationuser_oid
                );
                commit("SET_NAME", result.data.name);
                commit("SET_SURNAME", result.data.lastname);
                commit("SET_IS_LOAD_PROFILE_FALSE");
              });
          } else {
            resolve();
          }
        });
      } else {
        dispatch("doLogout", {});
      }
    },
    doLogin({ commit, dispatch }, { loginCredential }) {
      return new Promise((resolve, reject) => {
        authenticateProvider.login(loginCredential).then(
          (response) => {
            if (response?.status) {
              commit("SET_LOGGED_IN");
              commit("SET_TOKEN", response.data);
              localStorage.setItem(server.TOKEN_KEY, response.data.token);
              commit(
                "SET_LOGIN_PATH",
                router.currentRoute.fullPath === "/home"
              );
              this.dispatch({ type: "restoreLogin" }).then((response) => {
                if (response?.status) {
                  resolve(response);
                }
              });
              //router.push("/home");
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    doLoginStudent({ commit, dispatch }, { account }) {
      return new Promise((resolve, reject) => {
        authenticateProvider
          .loginstudent(account)
          .then((response) => {
            if (response?.status) {
              commit("SET_LOGGED_IN");
              commit("SET_TOKEN", response.data);
              localStorage.setItem(server.TOKEN_KEY, response.data.token);
              commit(
                "SET_LOGIN_PATH",
                router.currentRoute.fullPath === "/home"
              );
              //for user info
              this.dispatch({ type: "restoreLogin" }).then((response) => {
                if (response?.status) {
                  resolve(response);
                }
              });
              //router.push("/home");
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    doLogout({ commit }) {
      authenticateProvider.logoff();
      commit("SET_LOGGED_OUT");
      commit("SET_USERNAME", "");
      commit("SET_ROLE_CODES", []);
      commit("SET_TOKEN", "");
      commit("SET_IMAGE", "");
      commit("SET_PROFILE_OID", "");
      commit("SET_NAME", "");
      commit("SET_SURNAME", "");
      commit("SET_HISTORYROUTES", undefined);
      commit("SET_IS_LOAD_PROFILE_TRUE");
      commit("SET_LOGIN_PATH", false);

      commit("SET_EXPAND_TRACKING_MENU");
      localStorage.removeItem("expandTrackingMenu");

      this.dispatch({ type: "doClearRoute" });
      this.dispatch({ type: "doResetLiveChatStore" });
      this.dispatch({ type: "doResetNotiStore" });
      if (router.currentRoute.fullPath != "/home") {
        router.push("/home");
      }
    },
    gotoFirstPage({ state, commit }) {
      const navigationItemsCreatorService = new NavigationItemsCreatorService(
        state.rolecodes
      );
      const navigationItemsStructures = navigationItemsCreatorService.create();
      if (navigationItemsStructures[0]) {
        const firstNav = navigationItemsStructures[0];
        if (router.path !== firstNav.route) {
          router.push(firstNav.route);
        }
      }
    },
    doSetIsLoadingProfile({ commit }) {
      commit("SET_IS_LOAD_PROFILE_TRUE");
    },

    loadTrackingMenuState({ commit }) {
      const isExpanded = JSON.parse(localStorage.getItem("expandTrackingMenu"));
      if (isExpanded !== null) {
        commit("SET_TRACKING_MENU_STATE", isExpanded);
      }
    },

    //open Navigationbar from HomeMenu
    expandTrackingMenu({ commit, state }) {
      commit("SET_EXPAND_TRACKING_MENU");
    },

    collapseTrackingMenu({ commit, state }) {
      commit("SET_COLLAPSE_TRACKING_MENU");
    },
    setDarkTheme({ commit }, isDark) {
      commit("SET_DARK_THEME", isDark);
    },
    SET_SELECTED_DROPDOWN(state, value) {
      state.selectedDropdown = value;
    },
    setLoginPath({ commit }, isLoginPath) {
      commit("SET_LOGIN_PATH", isLoginPath);
    },
    updateCollapseTrackingMenu({ commit }, isCollapsed) {
      if (isCollapsed) {
        commit("SET_COLLAPSE_TRACKING_MENU");
      } else {
        commit("SET_EXPAND_TRACKING_MENU");
      }
    },
  },
  modules: {
    Notification: notification,
    Historyroute: historyroute,
    Livechat: livechat,
  },
});
