import SubjectTypeListView from "@/views/SubjectType/SubjectTypeListView.vue";
import SubjectTypeDetailView from "@/views/SubjectType/SubjectTypeDetailView.vue";

export default [
  {
    path: "/SubjectType",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/SubjectType/SubjectTypeListView",
        name: "SubjectTypeListView",
        component: SubjectTypeListView,
        meta: {
          requiresAuth: true,
          nameTh: " ประเภทวิชา",
        },
      },
      {
        path: "/SubjectType/SubjectTypeDetailView/:subjecttypeID",
        name: "SubjectTypeDetailView",
        component: SubjectTypeDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดประเภทวิชา",
        },
      },
    ],
  },
];
