<template>
  <div class="pa-4">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-card rounded elevation="1" class="pa-4">
      <div class="mx-5 my-2">
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12" class="mb-0 pb-0 custom-col">
            <h4 class="primary--text mb-2">ชื่อตำแหน่ง</h4>

            <v-text-field
              clearable
              :label="keywords ? '' : 'ค้นหา : ชื่อตำแหน่ง/ชื่อบริษัท'"
              variant="solo-filled"
              v-model="keywords"
              background-color="input"
              filled
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="6" md="6" sm="12" class="mb-0 pb-0 custom-col">
            <h4 class="primary--text mb-2">สถานที่ทำงาน</h4>

            <v-autocomplete
              @change="debounceLoadJobPostings"
              :label="vm.provinceid ? '' : 'สถานที่ทำงานทั้งหมด'"
              item-text="provincethai"
              item-value="provinceid"
              :items="provincelist"
              v-model="vm.provinceid"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
              clearable
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" lg="4" md="6" sm="12" class="mb-0 pb-0 custom-col">
            <h4 class="primary--text mb-2">ประเภทการจ้างงาน</h4>

            <v-select
              @change="debounceLoadJobPostings"
              :label="
                vm.employmentcategorycode ? '' : 'ประเภทการจ้างงานทั้งหมด'
              "
              v-model="vm.employmentcategorycode"
              :items="employmentType"
              item-text="name"
              item-value="code"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
              clearable
            >
            </v-select>
          </v-col>

          <v-col cols="12" lg="4" md="6" sm="12" class="mb-0 pb-0 custom-col">
            <h4 class="primary--text mb-2">ระดับชั้น</h4>

            <v-select
              @change="debounceLoadJobPostings"
              :label="vm.certificatetypecode ? '' : 'ระดับชั้นทั้งหมด'"
              v-model="vm.certificatetypecode"
              item-text="nameth"
              item-value="code"
              :items="certificateTypes"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
              clearable
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="6"
            sm="12"
            xs="12"
            class="custom-col mb-0 pb-0"
          >
            <h4 class="primary--text mb-2">
              เฉพาะที่มีข้อมูลกรมพัฒนาธุรกิจการค้า
            </h4>
            <!-- <v-checkbox
            class="ml-3 my-0 py-0"
            label="รับสมัครงานด่วน"
          ></v-checkbox> -->
            <v-row no-gutters align-content="end">
              <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1 mb-2"
              >
                <v-btn
                  :color="selectedDBD === true ? 'primary' : 'input'"
                  @click="
                    selectedDBD = !selectedDBD;
                    debounceLoadJobPostings();
                  "
                  height="52px"
                  width="100%"
                >
                  <!-- <v-icon left v-if="selectedIsargent === true">
                  mdi-checkbox-marked </v-icon
                ><v-icon left v-else> mdi-checkbox-blank-outline </v-icon> -->
                  <v-img
                    alt="VEC Logo"
                    class="shrink ml-2"
                    contain
                    src="@/assets/DBDlogo.png"
                    transition="scale-transition"
                    width="40"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col class="pt-5" xl="12" md="12" lg="12" sm="12" xs="12">
          <h4 class="mb-2 mb-0 ml-2 primary--text px-2">
            ตำแหน่งงานทั้งหมด {{ count }} รายการ
          </h4>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-for="item in foundJobs" :key="item.oid" cols="12">
        <v-col>
          <v-card class="pa-4">
            <v-container>
              <v-row>
                <v-col cols="12" lg="2" md="12" sm="12" xs="12">
                  <LogoEmployer
                    v-if="item.employerprofile"
                    :oid="item.employerprofile.oid"
                  ></LogoEmployer>
                </v-col>

                <v-col cols="12" lg="10" md="12" sm="12" xs="12">
                  <v-row class="d-flex justify-space-between align-center">
                    <v-col
                      class="d-flex align-center mx-0 px-0"
                      cols="12"
                      lg="auto"
                      md="12"
                    >
                      <h3 style="font-weight: bold">
                        {{ item.positionname }}
                      </h3>
                      <v-chip
                        v-if="item.employmentcategorycode !== ''"
                        class="ml-2"
                      >
                        {{
                          getEmploymentTypeStatus(item.employmentcategorycode)
                        }}
                      </v-chip>
                      <v-chip
                        v-if="item.isurgent === true"
                        class="ml-2"
                        color="error"
                        text-color="white"
                        >ด่วน
                      </v-chip>
                    </v-col>
                    <v-col
                      class="d-flex align-right mx-0 px-0"
                      cols="12"
                      lg="auto"
                      md="12"
                    >
                      <span>
                        ลงประกาศ :
                        <FormatDateView
                          :date_value="item.createddate"
                        ></FormatDateView>
                      </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      class="ma-0 pa-0"
                      cols="12"
                      lg="5"
                      md="6"
                      sm="10"
                      xs="12"
                    >
                      <div class="d-flex align-center">
                        <p
                          @click="goToEmployerProfile(item)"
                          id="employer-name"
                          class="primary--text employer-link mb-0"
                        >
                          {{ item.employerprofile.nameth }}
                        </p>
                        <DBDStatusDisplay
                          v-if="item.employerprofile"
                          :employer-profile="item.employerprofile"
                          class="ml-2"
                        ></DBDStatusDisplay>
                      </div>
                      <p>
                        <v-icon color="black">mdi-map-marker</v-icon>
                        {{
                          item.employerprofile.district.province.provincethai
                        }}
                      </p>
                      <p>
                        วุฒิการศึกษาขั้นต่ำ :
                        {{ item.requirecertificatemessage }}
                      </p>
                      <p>
                        เพศ :
                        {{ item.applicant_requiredgenderchoice_todisplay }}
                      </p>

                      <!-- <p v-if="item.employerprofile.email == undefined"></p>
<p v-else>, {{ item.employerprofile.email }} </p> -->
                    </v-col>

                    <v-col
                      class="ma-0 pa-0"
                      cols="12"
                      lg="4"
                      md="6"
                      sm="10"
                      xs="12"
                    >
                      <p>
                        <v-icon color="black">mdi-phone</v-icon>
                        {{ item.coordinator_phoneno }}
                      </p>
                      <p v-if="item.employerprofile.phone !== undefined"></p>
                      <p v-else>, {{ item.employerprofile.phone }}</p>

                      <p>
                        <v-icon color="black">mdi-email</v-icon>
                        {{ item.coordinator_email }}
                      </p>
                      <p>
                        <v-icon color="black">mdi-account</v-icon> จำนวน
                        {{ item.unit }} อัตรา
                      </p>
                      <p>
                        <v-icon color="black">mdi-currency-thb</v-icon
                        >{{ item.wagetodisplay }}
                      </p>

                      <!-- <p
                    >คุณสมบัติผู้สมัคร : {{ item.applicant_specification }}</p
                  ><br /> -->
                    </v-col>
                    <v-col
                      cols="12"
                      lg="3"
                      md="12"
                      sm="12"
                      xs="12"
                      class="d-flex flex-row justify-lg-end justify-md-center justify-sm-center align-end"
                    >
                      <v-btn
                        outlined
                        class="equal-height-btn display-center mr-2"
                        style="border-radius: 8px"
                      >
                        <BtnFavoriteJobPosting
                          :JobPosting="item"
                        ></BtnFavoriteJobPosting>
                      </v-btn>

                      <v-btn
                        outlined
                        style="border-radius: 8px"
                        class="color-button2"
                        @click="goToSearchJobDetails(item)"
                      >
                        รายละเอียดเพิ่มเติม
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { SearchStudentService } from "@/services/SearchStudentService.js";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { TempDataHelper } from "@/TempDataHelper";
import { JobPostingSearcherDataProvider } from "@/services/JobPostingSearcherDataProvider";
import { JobPostingSearch_Request } from "@/models/JobPostingSearcher/JobPostingSearch_Request";
import BtnFavoriteToggle from "@/components/SearchStudentResume/BtnFavoriteToggle.vue";
import BtnFavoriteJobPosting from "@/components/JobPostings/BtnFavoriteJobPosting.vue";
import LogoEmployer from "@/components/Employer/LogoEmployer.vue";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { StudentUserBookmarkDataProvider } from "@/services/StudentUserBookmarkDataProvider";
import FormatDateView from "@/components/common/FormatDateView.vue";
import { employmentType } from "@/services/EnumHelper";
import DBDStatusDisplay from "@/components/Employer/DBDStatusDisplay.vue";

const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var searchStudentService = new SearchStudentService(
  process.env.VUE_APP_BACKEND_API_URL
);
const studentUserBookmarkDataProvider = new StudentUserBookmarkDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const jobPostingSearcherDataProvider = new JobPostingSearcherDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "StudentSearchJobPostingView",
  components: {
    BtnFavoriteToggle,
    LogoEmployer,
    BtnFavoriteJobPosting,
    FormatDateView,
    DBDStatusDisplay,
  },
  props: [],
  data() {
    return {
      provincelist: [],
      vm: new JobPostingSearch_Request(),
      keywords: "",
      employmentType: [],
      certificateTypes: [],
      foundJobs: [],
      count: 0,
      selectedDBD: "",
    };
  },
  watch: {
    keywords(value) {
      this.vm.keywords = value;
      // this.loadJobPostings();
      this.debounceLoadJobPostings();
    },
  },
  methods: {
    async loadJobPostings() {
      if (this.selectedDBD == true) {
        this.vm.checkDBD = this.selectedDBD;
      } else {
        this.vm.checkDBD = false;
      }
      await jobPostingSearcherDataProvider
        .loadJobPostings(this.vm)
        .then((result) => {
          console.log(result)
          this.foundJobs = result.data;
          this.count = this.foundJobs.length;
        });
    },
    debounceLoadJobPostings() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.loadJobPostings();
      }, 1000);
    },
    async loadProvinces() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provincelist = result.data;
      });
    },
    async loadCertificateTypes() {
      await masterDataProvider.getCertificateTypes().then((result) => {
        this.certificateTypes = result.data;
      });
    },
    async loadEmploymentCategories() {
      await masterDataProvider.getEmploymentCategories().then((result) => {
        this.employmentType = result.data;
      });
    },
    goToSearchJobDetails: function (item) {
      const oid = item.oid;
      this.$router
        .push({ name: "JobPostingInfo", params: { oid: oid } })
        .catch((err) => {});
    },
    goToEmployerProfile(item) {
      const oid = item.oid;
      this.$router
        .push({ name: "EmployerProfileInfo", params: { oid: oid } })
        .catch((err) => {});
    },
    getEmploymentTypeStatus(statusValue) {
      const normalizedStatusValue = statusValue.toLowerCase();
      const status = employmentType.find(
        (status) => status.code === normalizedStatusValue
      );
      // console.log(status);
      return status && status.nameth;
    },
  },
  async created() {
    await this.loadJobPostings();
    await this.loadProvinces();
    await this.loadCertificateTypes();
    await this.loadEmploymentCategories();
    
  },
};
</script>
<style scoped>
/* กำหนดความกว้างเริ่มต้นสำหรับทุกขนาดหน้าจอ */
#employer-name {
  display: inline-block;
  max-width: 350px;
  /* สำหรับขนาดที่ไม่ระบุ */
}

/* @media (min-width: 1200px) {

  #employer-name {
    width: 450px;
  }
} */
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}

p {
  color: black;
}
.employer-link {
  cursor: pointer; /* Changes the cursor to a pointer when hovering */
  color: #1e88e5; /* Optional: Change text color to look like a link */
}

.employer-link:hover {
  text-decoration: underline; /* Underline text on hover */
}
</style>
