<template>
    <div class="row justify-center">
        <v-dialog v-model="value" max-width="550" persistent>
            <v-card>
                <v-card-title>
                    สร้างรายงานการติดตามภาวะการมีงานทำ
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col lg="6" sm="6" md="6">
                                <v-select :items="choice_yearth" label="ปีการศึกษา"></v-select>
                            </v-col>
                            <v-col lg="6" sm="6" md="6">
                                <v-select :items="choice_semesters" label="เทอม"></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div class="text-center">
                        <v-btn class="mr-2">สร้าง</v-btn>
                        <v-btn @click="$emit('closePopup', '')">ปิด</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { CommonDataProvider } from '@/services/CommonDataProvider'
import { backendapiUrl } from '@/services/constants';
export default {
    name: "Popup_CreateGraduatedJobTrackingReportView",
    data() {
        return {
            choice_yearth: [],
            selected_yearth: '',
            choice_semesters: [],
            selected_semesters: ''
        }
    },
    props: ['value'],
    methods: {
        getListOfYearTH() {
            var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
            commonDataProvider.getListOfYearTH().then(result => {
                this.choice_yearth = result.data;
            })
        },
        getListOfSemesters() {
            var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
            commonDataProvider.getListOfSemesters().then(result => {
                this.choice_semesters = result.data;
            });
        },
        createGraduatedStudentJobTrackingReportMain() {

        },

    },
    mounted() {
        this.getListOfYearTH();
        this.getListOfSemesters();
    }
}
</script>

<style>
</style>