<template>
  <div>
    <template>
      <v-btn :disabled="IsDisabled" @click="dialog = true" color="success"
        ><v-icon>mdi-check-decagram</v-icon> เสร็จสิ้นการติดตามภาวะการมีงานทำ</v-btn
      ></template
    >
    <v-dialog v-model="dialog" max-width="700px">
      <template>
        <div>
          <v-card class="pa-10 d-flex flex-column align-center">
            <h3 class="primary--text">
              <v-icon size="x-large" color="warning">mdi-alert-box</v-icon>
              ยืนยันเสร็จสิ้นการติดตามภาวะการมีงานทำ
            </h3>
            <label class="mt-5"
              >การบันทึกสถานะ "เสร็จสิ้นการติดตามภาวะการมีงานทำ"
              จะเป็นการปิดรอบรายงานการติดตามนี้ ซึ่งทุกสถานศึกษาจะไม่สามารถบันทึก
              แก้ไขข้อมูล
              หรือส่งรายงานการติดตามภาวะการมีงานทำของผู้สำเร็จการศึกษาได้</label
            >
            <v-row>
              <v-col cols="12" class="d-flex justify-center mt-5">
                <v-btn @click="onCancelClick">ยกเลิก</v-btn>
                <v-btn
                  @click="onConfirmClick"
                  :loading="isInSavingProcess"
                  class="mx-5 color-button2"
                  >ยืนยัน <v-icon>mdi-check-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";

const graduatedStudentJobTrackingReportReportDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "BtnMark_TrackingProcessIsCompleted",
  props: ["graduatedStudentJobTrackingReportMain"],
  data() {
    return {
      dialog: false,
      isInSavingProcess: false,
    };
  },
  computed: {
    IsDisabled() {
      return (
        this.graduatedStudentJobTrackingReportMain.jobtracking_status_statuscode ==
        "TrackingProcessIsCompleted"
      );
    },
  },
  methods: {
    onConfirmClick() {
      this.isInSavingProcess = true;
      setTimeout(() => {
        graduatedStudentJobTrackingReportReportDataProvider
          .mark_GraduatedStudentJobTrackingReportMain_TrackingProcessIsCompleted(
            this.graduatedStudentJobTrackingReportMain.oid
          )
          .then((result) => {
            this.dialog = false;
            this.$toast.success("เสร็จสิ้นการติดตามภาวะการมีงานทำ");
            this.$emit("Marked_TrackingProcessIsCompleted");
          })
          .finally(() => {
            this.isInSavingProcess = false;
          });
      }, 2000);
    },
    async onCancelClick() {
      this.dialog = false;
    },
  },
  created() {},
};
</script>

<style></style>
