<template>
  <v-data-table
    v-model="selected"
    v-bind="$attrs"
    v-on="$listeners"
    :search="search"
    :items="data_items"
    :headers="datatable_headers"
    multi-sort
    :loading="data_items == undefined"
    loading-text="Loading"
    dense
    :disablePagination="is_disable_pagination"
    class="elevation-1 ma-3"
    show-select
    :item-key="item_key"
  >
    <!-- pass through scoped slots -->
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      v-slot:[scopedSlotName]="slotData"
    >
      <slot :name="scopedSlotName" v-bind="slotData"></slot>
    </template>

    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName"></slot>
    </template>

    <template v-slot:top>
      <div class="pb-5 pt-3">
        <v-toolbar flat dense>
          <v-row>
            <v-col>
              <h3 class="primary--text">{{ local_table_caption }}</h3>
            </v-col>
          </v-row>
          <div class="mr-10"></div>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            color="info"
          ></v-text-field>
        </v-toolbar>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "SearchableDataTableSelect",
  props: {
    datatable_headers: undefined,
    data_items: undefined,
    table_caption: undefined,
    is_disable_pagination: { type: Boolean },
    item_key: undefined,
  },
  data() {
    return {
      search: "",
      local_table_caption: this.table_caption,
      local_datatable_headers: this.datatable_headers,
      local_data_items: this.data_items,
      selected: [],
    };
  },
  computed: {
    search_computed() {
      return this.search;
    },
  },

  methods: {
    onItemSelect(item) {
      this.$emit("item-selected", item);
    },
  },
  mounted() {
    this.local_data_items = this.data_items;
    this.local_datatable_headers = this.datatable_headers;
  },
};
</script>

<style></style>
