export class NewsEditRequest 
{
    oid = ''
    title = ''
    htmlcontent = ''
    description = ''
    startdate = ''
    expiredate = ''
    category = ''
    writerfullname = ''
    isbignews = false
    newsoriginid = ''
    newsorigintypecode = ''
    tags = []
}