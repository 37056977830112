<template>
  <div class="pa-5">
    <v-card rounded elevation="1" class="px-5 py-5">
      <div class="">
        <v-row>
          <v-col lg="4" md="12" sm="12" class="custom-col mb-0 pb-0">
            <h4 class="primary--text mb-2">สังกัด</h4>
            <v-row no-gutters align-content="end">
              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="12"
                class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
              >
                <v-btn
                  :color="request_data.SchoolType === 0 ? 'primary' : 'input'"
                  @click="
                    request_data.SchoolType = 0;
                    loadSchools();
                  "
                  height="52px"
                  width="100%"
                >
                  ทั้งหมด
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="12"
                class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
              >
                <v-btn
                  :color="request_data.SchoolType === 1 ? 'primary' : 'input'"
                  @click="
                    request_data.SchoolType = 1;
                    loadSchools();
                  "
                  height="52px"
                  width="100%"
                >
                  ภาครัฐ
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="12"
                class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1"
              >
                <v-btn
                  :color="request_data.SchoolType === 2 ? 'primary' : 'input'"
                  @click="
                    request_data.SchoolType = 2;
                    loadSchools();
                  "
                  height="52px"
                  width="100%"
                >
                  ภาคเอกชน
                </v-btn>
              </v-col>
            </v-row>

            <!-- <v-select
              :items="schooltypelist"
              v-model="request_data.SchoolType"
              text="text"
              value="value"
              @change="loadSchools()"
              solo
            ></v-select> -->
          </v-col>
          <v-col lg="4" md="6" sm="12" class="mb-0 pb-0">
            <h4 class="primary--text mb-2">ภูมิภาค</h4>

            <v-select
              :label="request_data.RegionAsVEC ? '' : 'ภูมิภาค'"
              :items="regionsNames"
              clearable
              v-model="request_data.RegionAsVEC"
              @change="loadSchools()"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            >
            </v-select>
          </v-col>
          <v-col lg="4" md="6" sm="12" class="custom-col mb-0 pb-0">
            <h4 class="primary--text mb-2">จังหวัด</h4>

            <v-autocomplete
              :label="request_data.ProvinceID ? '' : 'จังหวัด'"
              :items="provinces"
              item-text="provincethai"
              item-value="provinceid"
              clearable
              v-model="request_data.ProvinceID"
              @change="loadSchools()"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <SearchableDataTable
      v-if="schools != undefined"
      table_caption="รายชื่อสถานศึกษา"
      :data_items="schools"
      :datatable_headers="schools_data_table_headers"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="goToSchoolEmployee_SchoolEmployeesView(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
          </template>
          <span>รายชื่อบุคลากร</span>
        </v-tooltip>
      </template>
    </SearchableDataTable>
  </div>
</template>
<script>
import axios from "axios";
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";

var schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "SchoolEmployee_SchoolListView",
  data() {
    return {
      schoolType: undefined,
      selected_schoolType: undefined,
      regionsNames: undefined,
      selected_regionName: undefined,
      provinces: undefined,
      selected_province: undefined,
      request_data: new GetSchools_Reqeust(),
      search: "",
      schooltypelist: [
        { text: "ภาครัฐ/ภาคเอกชน", value: 0 },
        { text: "ภาครัฐ", value: 1 },
        { text: "ภาคเอกชน", value: 2 },
      ],
      schools_data_table_headers: [
        { text: "รหัสสถานศึกษา", value: "schoolid" },
        { text: "ชื่อสถานศึกษา (ไทย)", value: "schoolname" },
        { text: "จังหวัด", value: "provincethai" },
        { text: "อีเมล", value: "email" },
        { text: "สังกัดสถานศึกษา", value: "schooltypename" },
        { text: "ตัวเลือก", value: "action", sortable: false },
      ],
      schools: undefined,
    };
  },
  methods: {
    async loadRegions() {
      await commonDataProvider.getRegionAsVECNames().then((result) => {
        this.regionsNames = result.data;
      });
    },

    async loadProvinces() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
    },

    async loadSchools() {
      localStorage.removeItem("Schools");
      this.$store.dispatch("showLoading");
      await schoolDataProvider
        .loadSchools(this.request_data)
        .then((result) => {
          this.schools = result.data;
          if (this.request_data.RegionAsVEC) {
            masterDataProvider
              .loadGetProvincesByRegionAsVECName(this.request_data.RegionAsVEC)
              .then((result) => {
                this.provinces = result.data;
              });
          } else if (!this.request_data.RegionAsVEC) {
            masterDataProvider.loadGetProvinces().then((result) => {
              this.provinces = result.data;
            });
          }
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },

    createRequest_Data() {},

    goToSchoolEmployee_SchoolEmployeesView(item) {
      var schoolID = item.schoolid;
      this.$router
        .push({
          name: "SchoolEmployee_SchoolEmployeesView",
          params: { schoolID: schoolID },
        })
        .catch((err) => {});
    },
  },
  async created() {
    await this.loadRegions();
    await this.loadProvinces();
    await this.loadSchools();
    this.request_data.SchoolType = 0;
  },
  components: { SearchableDataTable },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
