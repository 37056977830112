<template>
  <div ref="document" style="background: #eeeeee">
    <v-container class="ma-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="d-flex justify-center mt-2 mb-2">
          <h2 class="black--text">ข้อมูลนักศึกษา</h2>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <v-card style="border-radius: 15px">
              <!-- <h4 class="mt-4 mx-0 mb-4">ข้อมูลนักศึกษา</h4>
                <v-divider></v-divider> -->
              <v-card-text id="element-to-convert">
                <v-row>
                  <v-col
                    v-if="logo_photobase64"
                    xl="4"
                    lg="4"
                    sm="12"
                    class="d-flex flex-column align-center justify-center"
                    ><label class="mb-3"
                      >วันที่แก้ไขข้อมูลล่าสุด :
                      <label class=""> {{ this.dataStudent.birthday }}</label>
                    </label>
                    <v-img
                      alt="test"
                      class="shrink fill-height mr-2"
                      contain
                      transition="scale-transition"
                      aspect-ratio="1/1"
                      cover
                      v-bind:lazy-src="
                        'data:image/jpg;base64,' + logo_photobase64
                      "
                      v-bind:src="'data:image/jpg;base64,' + logo_photobase64"
                    />
                  </v-col>

                  <v-col
                    v-else
                    xl="4"
                    lg="4"
                    sm="12"
                    class="d-flex flex-column align-center justify-center"
                  >
                    <label class="mb-3"
                      >วันที่แก้ไขข้อมูลล่าสุด :
                      <label class=""> {{ this.dataStudent.birthday }}</label>
                    </label>
                    <v-img
                      alt="test"
                      class="shrink fill-height mr-2"
                      contain
                      transition="scale-transition"
                      aspect-ratio="1/1"
                      cover
                      :src="require(`@/assets/user-img.jpg`)"
                    />
                  </v-col>
                  <v-col xl="8" lg="8" sm="12">
                    <v-col>
                      <v-row class="pa-2 pt-5 pl-6">
                        <label class="name"
                          >{{ this.dataStudent.titlename?.titlenameth }}
                          {{ this.dataStudent.firstname }}
                          <label class="name"
                            >{{ this.dataStudent.surname }}
                          </label>
                        </label>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-divider
                        :thickness="3"
                        class="border-opacity-75"
                        color="secondary"
                      >
                      </v-divider>
                    </v-col>
                    <v-col>
                      <v-row class="pa-2 pl-6">
                        <label class=""
                          >อีเมล :
                          <!-- <label v-if="this.dataStudent.email == ''"> - </label>
                          <label v-else-if="this.dataStudent.email != ''"> {{ this.dataStudent.email }}</label> -->
                          <label> {{ this.dataStudent.email }}</label>
                        </label>
                      </v-row>

                      <v-row class="pa-2 pl-6">
                        <label class=""
                          >โทรศัพท์ :
                          <label class="">{{ this.dataStudent.tel }} </label>
                        </label>
                      </v-row>

                      <v-row class="pa-2 pl-6">
                        <label class=""
                          >ที่อยู่ :
                          <label class="">
                            {{ this.dataStudent.fulladdress }}</label
                          >
                        </label>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
                <div
                  style="
                    background-color: rgba(63, 81, 181, 0.8);
                    color: white;
                    border-radius: 20px;
                    padding: 5px 10px;
                    font-size: 16px;
                    margin: 40px 0px 30px;
                  "
                  class="text-center"
                >
                  <v-icon>mdi-account-card-details</v-icon>
                  รายละเอียดส่วนตัว
                </div>
                <v-row>
                  <v-col cols="6 pl-10">
                    <v-row class="pa-2">
                      <label class=""
                        >เพศ :
                        <label v-if="this.dataStudent.gender == 'F'"
                          >หญิง</label
                        >
                        <label v-if="this.dataStudent.gender == 'M'">ชาย</label>
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label class=""
                        >วัน-เดือน-ปี เกิด :
                        <span>
                          <FormatDate
                            :date_value="this.dataStudent.birthday"
                          ></FormatDate>
                          <!-- {{
                          moment(this.dataStudent.birthday)
                            .add("year")
                            .locale("TH")
                            .format("LL")
                        }} -->
                        </span>
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label class=""
                        >อายุ :
                        <span>
                          {{
                            moment().diff(this.dataStudent.birthday, "years")
                          }}
                          <!-- {{
                            new Date().getFullYear() -
                            (parseInt(this.dataStudent.birthday) - 543) -
                            (new Date().getMonth() <
                              new Date(this.dataStudent.birthday).getMonth() ||
                            (new Date().getMonth() ===
                              new Date(this.dataStudent.birthday).getMonth() &&
                              new Date().getDate() <
                                new Date(this.dataStudent.birthday).getDate())
                              ? 1
                              : 0)
                          }} -->
                        </span>
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label class=""
                        >สัญชาติ :
                        <label class=""
                          >{{ this.dataStudent.nationality?.nationalityth }}
                        </label>
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label class=""
                        >ศาสนา :
                        <label v-if="this.dataStudent.religion" class="">
                          {{ this.dataStudent.religion.religionth }}</label
                        >
                      </label>
                    </v-row>
                  </v-col>

                  <v-col cols="6">
                    <v-row class="pa-2">
                      <label class=""
                        >ส่วนสูง (เซ็นติเมตร) :
                        <label class="">{{ this.dataStudent.hight }} </label>
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label class=""
                        >น้ำหนัก (กิโลกรัม) :
                        <label class="">{{ this.dataStudent.weight }} </label>
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label class=""
                        >สถานภาพ :
                        <label class="">{{ status }} </label>
                      </label>
                    </v-row>

                    <v-row v-if="this.dataStudent.gender == 'M'" class="pa-2">
                      <label class=""
                        >สถานภาพทางทหาร :
                        <label v-if="this.dataStudent.military == '0'"
                          >ยังไม่ผ่านการเกณฑ์ทหาร</label
                        >
                        <label v-if="this.dataStudent.military == '1'"
                          >ผ่านการเกณฑ์ทหาร</label
                        >
                        <label v-if="this.dataStudent.military == '2'"
                          >อยู่ระหว่างรับราชการทหาร</label
                        >
                        <label v-if="this.dataStudent.military == '3'"
                          >ได้รับการยกเว้น (สำหรับหลักสูตรรักษาดินแดน)</label
                        >
                        <label v-if="this.dataStudent.military == '4'"
                          >ได้รับการยกเว้น (จับฉลากได้ใบดำ หรือ
                          ร่างการไม่ได้ขนาด)</label
                        >
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label class=""
                        >สถานะความพิการ :
                        <label class="">{{ disability }} </label>
                      </label>
                    </v-row>
                  </v-col>
                </v-row>

                <div
                  style="
                    background-color: rgba(63, 81, 181, 0.8);
                    color: white;
                    border-radius: 20px;
                    padding: 5px 10px;
                    font-size: 16px;
                    margin: 40px 0px 30px;
                  "
                  class="text-center"
                >
                  ลักษณะงานที่ต้องการ
                </div>
                <v-row>
                  <v-col cols="12 pl-10">
                    <v-row class="pa-2">
                      <label class=""
                        >ตำแหน่งงานที่ต้องการสมัคร :
                        <label class=""
                          ><br />1.
                          {{ this.dataStudent.lookingforjob?.interestedjob1 }}
                        </label>
                        <label
                          v-if="this.dataStudent.lookingforjob?.interestedjob2"
                          class=""
                          ><br />2.
                          {{ this.dataStudent.lookingforjob?.interestedjob2 }}
                        </label>
                        <label
                          v-if="this.dataStudent.lookingforjob?.interestedjob3"
                          class=""
                          ><br />3.
                          {{ this.dataStudent.lookingforjob?.interestedjob3 }}
                        </label>
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label
                        v-if="this.dataStudent.lookingforjob?.rangesalary"
                        class=""
                        >เงินเดือนที่ต้องการ :
                        <label class="">
                          {{
                            this.dataStudent.lookingforjob?.rangesalary
                          }}</label
                        >
                      </label>

                      <label v-else class=""
                        >เงินเดือนที่ต้องการ :
                        <label class=""> -</label>
                      </label>
                    </v-row>

                    <v-row class="pa-2">
                      <label class=""
                        >ประเภทงานที่ต้องการ :
                        <label
                          v-if="
                            this.dataStudent.lookingforjob?.fulltime == true
                          "
                          >งานประจำ (Full Time)</label
                        >
                        <label
                          v-if="
                            this.dataStudent.lookingforjob?.parttime == true
                          "
                          >งานนอกเวลา (Part Time)</label
                        >
                        <label
                          v-if="
                            this.dataStudent.lookingforjob?.internship == true
                          "
                          >ฝึกงาน</label
                        >
                        <label
                          v-if="
                            this.dataStudent.lookingforjob?.freelance == true
                          "
                          >งานอิสระ (Freelance)</label
                        >
                        <label
                          v-if="this.dataStudent.lookingforjob?.byday == true"
                          >งานจ้างรายวัน</label
                        >
                        <label
                          v-if="
                            this.dataStudent.lookingforjob?.temporary == true
                          "
                          >งานจ้างชั่วคราว</label
                        >
                        <label
                          v-if="
                            this.dataStudent.lookingforjob?.workfromhome == true
                          "
                          >รับงานไปทำที่บ้าน</label
                        >
                      </label>
                    </v-row>
                  </v-col>
                </v-row>

                <div
                  style="
                    background-color: rgba(63, 81, 181, 0.8);
                    color: white;
                    border-radius: 20px;
                    padding: 5px 10px;
                    font-size: 16px;
                    margin: 40px 0px 30px;
                  "
                  class="text-center"
                >
                  ประวัติการศึกษา
                </div>
                <v-row class="mx-1 d-flex flex-row">
                  <v-col
                    :xl="studentEducation.length >= 2 ? 6 : 12"
                    :lg="studentEducation.length >= 2 ? 6 : 12"
                    :xs="12"
                    v-for="(
                      {
                        school,
                        studentid,
                        gradenameth,
                        major,
                        minornameth,
                        majornameth,
                        studentstatusname,
                        gradautedyearth,
                      },
                      oid
                    ) in studentEducation"
                    :key="oid"
                  >
                    <v-card
                      style="border-radius: 15px; width: 100%"
                      class="mx-auto"
                      variant="outlined"
                    >
                      <v-card-text
                        class="d-flex flex-column"
                        style="border: 1px solid grey"
                      >
                        <v-row class="ma-1"
                          ><label style="font-size: 20px" class="">{{
                            school.schoolname
                          }}</label></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >รหัสนักศึกษา : {{ studentid }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >ระดับชั้น : {{ gradenameth }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >สาขาวิชา : {{ minornameth }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >ประเภทวิชา : {{ major.majornameth }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >สาขางาน : {{ majornameth }}</label
                          ></v-row
                        >
                        <v-row
                          v-if="studentstatusname"
                          class="ma-1 d-flex align-center"
                        >
                          <v-chip class="text-center primary--text">
                            {{ studentstatusname }} </v-chip
                          ><label
                            v-if="studentstatusname == 'อยู่ระหว่างศึกษา'"
                            class="ml-2"
                          ></label
                          ><label v-else class="ml-2"
                            >ปีที่จบการศึกษา : {{ gradautedyearth }}</label
                          >
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- <v-row class="mx-1 d-flex flex-row">
                  <v-col
                    cols="6"
                    v-for="(
                      {
                        schoolname,
                        educationalqualification,
                        minornameth,
                        majornameth,
                      },
                      oid
                    ) in studentStudy"
                    :key="oid"
                  >
                    <v-card
                      style="border-radius: 15px"
                      class="mx-auto"
                      variant="outlined"
                    >
                      <v-card-text
                        class="d-flex flex-column"
                        style="border: 1px solid grey"
                      >
                        <v-row class="ma-1"
                          ><label style="font-size: 20px" class="">{{
                            schoolname
                          }}</label></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >ระดับชั้น : {{ educationalqualification }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >สาขาวิชา : {{ minornameth }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >ประเภทวิชา : {{ majornameth }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >สาขางาน : {{ majornameth }}</label
                          ></v-row
                        >
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row> -->

                <div class="html2pdf__page-break"></div>
                <!-- แบ่งหน้า pdf -->
                <div
                  style="
                    background-color: rgba(63, 81, 181, 0.8);
                    color: white;
                    border-radius: 20px;
                    padding: 5px 10px;
                    font-size: 16px;
                    margin: 40px 0px 30px;
                  "
                  class="text-center"
                >
                  ประวัติการทำงาน/รายการสมัครฝึกงาน
                </div>
                <v-row class="mx-1 d-flex flex-row">
                  <v-col
                    :xl="studentEducation.length >= 2 ? 6 : 12"
                    :lg="studentEducation.length >= 2 ? 6 : 12"
                    :xs="12"
                    v-for="(
                      {
                        organizationname,
                        startdate,
                        enddate,
                        positionname,
                        salaryrange,
                        jobdescription,
                      },
                      oid
                    ) in studentJob"
                    :key="oid"
                  >
                    <v-card
                      style="border-radius: 15px"
                      class="mx-auto"
                      variant="outlined"
                    >
                      <v-card-text
                        class="d-flex flex-column"
                        style="border: 1px solid grey"
                      >
                        <v-row class="ma-1">
                          <label style="font-size: 20px" class=""
                            >{{ organizationname }}
                          </label></v-row
                        >
                        <v-row class="ma-1">
                          <label class=""
                            >ระยะเวลา : {{ startdate }} - {{ enddate }}</label
                          ></v-row
                        >
                        <v-row class="ma-1">
                          <label class=""
                            >ตำแหน่ง : {{ positionname }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >เงินเดือน : {{ salaryrange.salary_range }}</label
                          ></v-row
                        >
                        <v-row class="ma-1"
                          ><label class=""
                            >หน้าที่รับผิดชอบ : {{ jobdescription }}</label
                          ></v-row
                        >
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row class="justify-center text-center mt-4">
              <v-col cols="12">
                <v-btn
                  v-if="display_favorite_btn"
                  icon
                  dark
                  color="red"
                  @click="toggleFavorite"
                  :class="{ 'is-favorite': isFavorite }"
                  class="mr-8"
                >
                  <v-icon v-if="isFavorite">mdi-heart</v-icon>
                  <v-icon v-else>mdi-heart-outline</v-icon
                  ><span class="black--text">บันทึกเข้าแฟ้ม</span></v-btn
                >
                <v-btn
                  icon
                  dark
                  color="primary"
                  @click="print"
                  class="ma-2 ml-10"
                >
                  <v-icon class="mr-2">mdi-printer</v-icon>print
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="justify-center text-center mb-4">
              <v-col cols="12">
                <!-- <v-btn dark color="primary" @click="contact" class="ma-2">
                  <v-icon class="mr-2">mdi-contacts</v-icon>
                  ติดต่อ
                </v-btn> -->
                <v-btn color="" @click="$router.go(-1)" class="ma-2">
                  ย้อนกลับ</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import html2pdf from "html2pdf.js";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import { EmployerUserbookmarkDataProvider } from "@/services/EmployerUserbookmarkDataProvider.js";
import { SaveInterestedInStudentBookmark_Request } from "@/models/EmployerUserbookmark/SaveInterestedInStudentBookmark_Request";
import FormatDate from "@/components/common/FormatDate.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerUserbookmarkDataProvider = new EmployerUserbookmarkDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "SearchStudentDetails",
  data() {
    return {
      request: new SaveInterestedInStudentBookmark_Request(),
      current_applicationuser_rolecodes: undefined,
      moment: moment,
      student_oid: "",
      idcard: "",
      Job: [
        {
          jobname: "ช่างกุ้งรับซ่อมจักรยานยนต์",
          startdate: "มี.ค. 2559",
          enddate: "พ.ค. 2559",
          position: "ม.4 ต.ธานี อ.เมือง จ.สุโขทัย",
          salary: "0",
          description: "รับซ่อมรถจักรยานยนต์ และอุปกรณ์การเกษตรทั่วไป",
        },
        {
          jobname: "บจก.เมกาโล่เอนจิเนียริ่ง",
          startdate: "มี.ค. 2560",
          enddate: "พ.ค. 2560",
          position: "ช่างติดตั้งไฟฟ้า",
          salary: "14,000",
          description: "ติดตั้งอุปกรณ์ไฟฟ้าใน รพ.ระยอง และเรือนจำ ระยอง",
        },
      ],
      EducationStudent: [
        {
          schoolname: "วิทยาลัยเทคโนโลยีพลพณิชยการ",
          educationalqualification: "ปวช.1",
          minorname: "คอมพิวเตอร์ธุรกิจ",
          subjecttype: "พาณิชยกรรม/บริหารธุรกิจ",
          minorjob: "คอมพิวเตอร์ธุรกิจ",
        },
        {
          schoolname: "วิทยาลัยเทคโนโลยีพลพณิชยการ",
          educationalqualification: "ปวช.2",
          minorname: "คอมพิวเตอร์ธุรกิจ",
          subjecttype: "พาณิชยกรรม/บริหารธุรกิจ",
          minorjob: "คอมพิวเตอร์ธุรกิจ",
        },
        {
          schoolname: "วิทยาลัยเทคโนโลยีพลพณิชยการ",
          educationalqualification: "ปวช.3",
          minorname: "คอมพิวเตอร์ธุรกิจ",
          subjecttype: "พาณิชยกรรม/บริหารธุรกิจ",
          minorjob: "คอมพิวเตอร์ธุรกิจ",
        },
      ],
      isFavorite: false,
      name: "สุธารินี",
      surname: "พรมคำบุตร",
      id: "5831040046",
      email: "dreammymy566@gmail.com",
      phone: "0620080797",
      address:
        "206/11 หมู่7 ซอย ถนน แขวง/ตำบล ธานี เขต/อำเภอ เมืองสุโขทัย จังหวัดสุโขทัย 64000",
      gender: "หญิง",
      birthday: "01-10-2546",
      age: "20",
      nationality: "ไทย",
      religion: "พุทธ",
      height: "166",
      weight: "52",
      status: "โสด",
      military: "-",
      disability: "-",
      joblookingfor: "ช่างยนต์/ช่างกลโรงงาน/ช่างเทคนิคอุตสาหกรรม",
      salarylookingfor: "9,500",
      jobtypelookingfor:
        "งานประจำ (Full Time) , งานนอกเวลา (Part Time) , งานจ้างรายวัน , รับงานไปทำที่บ้าน",
      dataStudent: [],
      daysDifference: 0,
      studentJob: [],
      studentStudy: [],
      valid: false,
      logo_photobase64: "",
      studentEducation: [],
    };
  },
  computed: {
    display_favorite_btn() {
      if (this.current_applicationuser_rolecodes == undefined) return false;
      return this.current_applicationuser_rolecodes.includes("Employer");
    },
  },
  methods: {
    async initialize() {
      this.current_applicationuser_rolecodes = this.$store.getters.rolecodes;
    },
    contact() {},
    toggleFavorite() {
      this.request.Student_Oid = this.student_oid;
      if (this.isFavorite == false) {
        employerUserbookmarkDataProvider
          .saveInterestedInStudentBookmark(this.request)
          .then((res) => {
            if (res?.status == 200) {
              this.$toast.success("บันทึกเข้าแฟ้มสำเร็จ");
              this.isFavorite = !this.isFavorite;
            }
          })
          .catch((res) => {
            this.$toast.error("บันทึกเข้าแฟ้มล้มเหลว !!!" + res);
          });
      } else {
        employerUserbookmarkDataProvider
          .deleteInterestedInStudentBookmark(this.request)
          .then((res) => {
            if (res?.status == 200) {
              this.$toast.warning("ลบออกจากแฟ้มสำเร็จ");
              this.isFavorite = !this.isFavorite;
            }
          })
          .catch((res) => {
            this.$toast.error("ลบออกจากแฟ้มล้มเหลว !!!" + res);
          });
      }
    },
    print() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: "Resume.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        enableLinks: true,
        pagebreak: { mode: ["css", "legacy"] },
      });
    },
    loadStudent() {
      studentDataProvider.loadStudentByOid(this.student_oid).then((result) => {
        this.dataStudent = result.data;
        this.logo_photobase64 = result.data.base64thrumnail;
        this.loadStudentVECEducationInformations(result.data.idcard);
      });
      studentDataProvider.getStudentJobRecords(this.student_oid).then((res) => {
        this.studentJob = res.data;
      });
      studentDataProvider
        .getStudentStudyRecords(this.student_oid)
        .then((res) => {
          this.studentStudy = res.data;
        });
    },
    loadStudentVECEducationInformations(idcard) {
      studentDataProvider
        .getStudentVECEducationInformations(idcard)
        .then((res) => {
          this.studentEducation = res.data;
        });
    },

    loadStudentBookmarks() {
      employerUserbookmarkDataProvider
        .loadGetInterestedInStudentBookmarks()
        .then((res) => {
          if (res?.status == 200) {
            let bookmarks = res.data;
            if (bookmarks.find((e) => e.bookmarkvalue === this.student_oid)) {
              this.isFavorite = true;
            }
          }
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว !!!" + res);
        });
    },
  },
  mounted() {
    // var datenow = new Date
    this.daysDifference = moment().diff("2566-09-28", "years");
  },
  async created() {
    this.student_oid = this.$route.params.student_oid;
    await this.initialize();
    await this.loadStudent();
    if (this.display_favorite_btn == true) {
      await this.loadStudentBookmarks();
    }
  },
  components: {
    // VueHtml2pdf
    FormatDate,
  },
};
</script>

<style scoped>
label {
  font-size: 16px;
}

.shrink {
  border-radius: 200px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 10px rgba(15, 15, 15, 0.5);
  border: 1px solid grey;
}
.name {
  font-size: 26px;
  color: #3f51b5;
}
</style>
