<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <template>
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-container class="pa-3">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <h2><v-icon>mdi-account-group</v-icon> จัดการสิทธิการใช้งาน</h2>
              </v-col>
            </v-row>

            <v-list>
              <v-card class="mt-5">
                <v-list-item-group
                  v-if="isUsedForSchoolAdmin == false"
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <template>
                    <v-list-item>
                      <template
                        v-slot:default="{
                          /*active*/
                        }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            inset
                            v-model="
                              roleAndPermission_Management.is_center_admin
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            >ผู้ดูแลระบบส่วนกลาง</v-list-item-title
                          >

                          <!-- <v-list-item-subtitle class="text--primary"
                  >Brunch this weekend?</v-list-item-subtitle
                > -->

                          <v-list-item-subtitle
                            >ผู้ดูแลระบบมีหน้าที่ในการจัดการข้อมูลพื้นฐานต่าง ๆ
                            ในระบบ รวมถึงสามารถจัดการข้อมูลผู้ใช้งานระบบ<br />ทั้งหมดในระบบได้</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider></v-divider>
                  </template>
                </v-list-item-group>

                <v-list-item-group
                  v-if="isUsedForSchoolAdmin == false"
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <template>
                    <v-list-item>
                      <template
                        v-slot:default="{
                          /*active*/
                        }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            inset
                            v-model="
                              roleAndPermission_Management.is_center_officer
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>บุคลากรส่วนกลาง</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider></v-divider>
                  </template>
                </v-list-item-group>

                <v-list-item-group
                  v-if="isUsedForSchoolAdmin == false"
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <v-list-item>
                    <template
                      v-slot:default="{
                        /*active*/
                      }"
                    >
                      <v-list-item-action>
                        <v-checkbox
                          inset
                          v-model="
                            roleAndPermission_Management.is_center_manager
                          "
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>ผู้บริหารส่วนกลาง</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-divider></v-divider>
                </v-list-item-group>

                <v-list-item-group
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <template>
                    <v-list-item>
                      <template
                        v-slot:default="{
                          /*active*/
                        }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            inset
                            v-model="
                              roleAndPermission_Management.is_school_manager
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div class="d-flex justify-end mt-2">
                            <v-list-item-title
                              >ผู้บริหารสถานศึกษา</v-list-item-title
                            >
                          </div>
                          <div class="d-flex justify-start mt-2">
                            <v-btn
                              v-if="
                                roleAndPermission_Management.is_school_manager
                              "
                              color="primary"
                              @click="school_manager_permissions_dialog = true"
                              ><v-icon>mdi-plus</v-icon> เลือกสถานศึกษา</v-btn
                            >
                          </div>
                          <SearchableTable
                            v-if="
                              roleAndPermission_Management.is_school_manager
                            "
                            table_caption="สิทธิผู้บริหารสถานศึกษา"
                            :data_items="
                              roleAndPermission_Management.school_manager_permissions
                            "
                            :datatable_headers="
                              school_manager_permissions_data_table_header
                            "
                          >
                            <template v-slot:item.permissionvalue="{ item }">
                              <span>{{
                                convert_SchoolID_To_SchoolName(
                                  item.permissionvalue
                                )
                              }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-tooltip top color="info">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="
                                      delete_school_manager_permissions(item)
                                    "
                                    color="error"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>ลบ</span>
                              </v-tooltip>
                            </template>
                          </SearchableTable>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-list-item-group>

                <v-list-item-group
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <template>
                    <v-list-item>
                      <template
                        v-slot:default="{
                          /*active*/
                        }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            inset
                            v-model="
                              roleAndPermission_Management.is_school_admin
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div class="d-flex justify-end mt-5">
                            <v-list-item-title
                              >บุคลากรในสถานศึกษา
                              (ศูนย์ข้อมูลสารสนเทศ)</v-list-item-title
                            >
                          </div>
                          <div class="d-flex justify-start mt-2">
                            <v-btn
                              v-if="
                                roleAndPermission_Management.is_school_admin
                              "
                              color="primary"
                              @click="school_admin_permissions_dialog = true"
                              ><v-icon>mdi-plus</v-icon>เลือกสถานศึกษา</v-btn
                            >
                          </div>
                          <SearchableTable
                            v-if="roleAndPermission_Management.is_school_admin"
                            table_caption="สิทธิบุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)"
                            :data_items="
                              roleAndPermission_Management.school_admin_permissions
                            "
                            :datatable_headers="
                              school_admin_permissions_data_table_header
                            "
                          >
                            <template v-slot:item.permissionvalue="{ item }">
                              <span>{{
                                convert_SchoolID_To_SchoolName(
                                  item.permissionvalue
                                )
                              }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-tooltip top color="info">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="
                                      delete_school_admin_permissions(item)
                                    "
                                    color="error"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>ลบ</span>
                              </v-tooltip>
                            </template>
                          </SearchableTable>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-list-item-group>

                <v-list-item-group
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <template>
                    <v-list-item>
                      <template
                        v-slot:default="{
                          /*active*/
                        }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            inset
                            v-model="
                              roleAndPermission_Management.is_school_partner_officer
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div class="d-flex justify-end mt-2">
                            <v-list-item-title
                              >บุคลากรในสถานศึกษา (ทวิภาคี)</v-list-item-title
                            >
                          </div>
                          <div class="d-flex justify-start mt-2">
                            <v-btn
                              v-if="
                                roleAndPermission_Management.is_school_partner_officer
                              "
                              color="primary"
                              @click="
                                school_partner_officer_permissions_dialog = true
                              "
                              ><v-icon>mdi-plus</v-icon>เลือกสถานศึกษา</v-btn
                            >
                          </div>
                          <SearchableTable
                            v-if="
                              roleAndPermission_Management.is_school_partner_officer
                            "
                            table_caption="สิทธิบุคลากรในสถานศึกษา (ทวิภาคี)"
                            :data_items="
                              roleAndPermission_Management.school_partner_officer_permissions
                            "
                            :datatable_headers="
                              school_partner_officer_permissions_data_table_header
                            "
                          >
                            <template v-slot:item.permissionvalue="{ item }">
                              <span>{{
                                convert_SchoolID_To_SchoolName(
                                  item.permissionvalue
                                )
                              }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-tooltip top color="info">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="
                                      delete_school_partner_officer_permissions(
                                        item
                                      )
                                    "
                                    color="error"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>ลบ</span>
                              </v-tooltip>
                            </template>
                          </SearchableTable>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-list-item-group>

                <v-list-item-group
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <template>
                    <v-list-item>
                      <template
                        v-slot:default="{
                          /*active*/
                        }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            inset
                            v-model="
                              roleAndPermission_Management.is_school_guidance_teacher
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div class="d-flex justify-end mt-2">
                            <v-list-item-title
                              >บุคลากรในสถานศึกษา (ครูแนะแนว)</v-list-item-title
                            >
                          </div>
                          <div class="d-flex justify-start mt-2">
                            <v-btn
                              v-if="
                                roleAndPermission_Management.is_school_guidance_teacher
                              "
                              color="primary"
                              @click="
                                school_guidance_teacher_permissions_dialog = true
                              "
                              ><v-icon>mdi-plus</v-icon>เลือกสถานศึกษา</v-btn
                            >
                          </div>
                          <SearchableTable
                            v-if="
                              roleAndPermission_Management.is_school_guidance_teacher
                            "
                            table_caption="สิทธิบุคลากรในสถานศึกษา (ครูแนะแนว)"
                            :data_items="
                              roleAndPermission_Management.school_guidance_teacher_permissions
                            "
                            :datatable_headers="
                              school_guidance_teacher_permissions_data_table_header
                            "
                          >
                            <template v-slot:item.permissionvalue="{ item }">
                              <span>{{
                                convert_SchoolID_To_SchoolName(
                                  item.permissionvalue
                                )
                              }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-tooltip top color="info">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="
                                      delete_school_guidance_teacher_permissions(
                                        item
                                      )
                                    "
                                    color="error"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>ลบ</span>
                              </v-tooltip>
                            </template>
                          </SearchableTable>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-list-item-group>

                <v-list-item-group
                  v-if="isUsedForSchoolAdmin == false"
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <template>
                    <v-list-item>
                      <template
                        v-slot:default="{
                          /*active*/
                        }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            inset
                            v-model="
                              roleAndPermission_Management.is_provinceschool_officer
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div class="d-flex justify-end mt-2">
                            <v-list-item-title
                              >บุคลากรในอาชีวศึกษาจังหวัด</v-list-item-title
                            >
                          </div>
                          <div class="d-flex justify-start mt-2">
                            <v-btn
                              v-if="
                                roleAndPermission_Management.is_provinceschool_officer
                              "
                              color="primary"
                              @click="
                                provinceschool_officer_permissions_dialog = true
                              "
                              ><v-icon>mdi-plus</v-icon> เลือกจังหวัด</v-btn
                            >
                          </div>
                          <SearchableTable
                            v-if="
                              roleAndPermission_Management.is_provinceschool_officer
                            "
                            table_caption="สิทธิบุคลากรในอาชีวศึกษาจังหวัด"
                            :data_items="
                              roleAndPermission_Management.provinceschool_officer_permissions
                            "
                            :datatable_headers="
                              provinceschool_officer_permissions_data_table_header
                            "
                          >
                            <template v-slot:item.permissionvalue="{ item }">
                              <span>{{
                                convert_ProvinceID_To_ProvinceName(
                                  item.permissionvalue
                                )
                              }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-tooltip top color="info">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="
                                      delete_provinceschool_officer_permissions(
                                        item
                                      )
                                    "
                                    color="error"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>ลบ</span>
                              </v-tooltip>
                            </template>
                          </SearchableTable>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-list-item-group>

                <v-list-item-group
                  v-if="isUsedForSchoolAdmin == false"
                  active-class="primary--text"
                  style="border-color: blue"
                  multiple
                >
                  <template>
                    <v-list-item>
                      <template
                        v-slot:default="{
                          /*active*/
                        }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            inset
                            v-model="
                              roleAndPermission_Management.is_insitution_officer
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div class="d-flex justify-end mt-2">
                            <v-list-item-title
                              >บุคลากรในสถาบันการอาชีวศึกษา</v-list-item-title
                            >
                          </div>
                          <div class="d-flex justify-start mt-2">
                            <v-btn
                              v-if="
                                roleAndPermission_Management.is_insitution_officer
                              "
                              color="primary"
                              @click="
                                insitution_officer_permissions_dialog = true
                              "
                              ><v-icon>mdi-plus</v-icon>
                              เลือกสถาบันการอาชีวศึกษา</v-btn
                            >
                          </div>
                          <SearchableTable
                            v-if="
                              roleAndPermission_Management.is_insitution_officer
                            "
                            table_caption="สิทธิบุคลากรในสถาบันการอาชีวศึกษา"
                            :data_items="
                              roleAndPermission_Management.insitution_officer_permissions
                            "
                            :datatable_headers="
                              insitution_officer_permissions_data_table_header
                            "
                          >
                            <template v-slot:item.permissionvalue="{ item }">
                              <span>{{
                                convert_InsitutionID_To_InsitutionName(
                                  item.permissionvalue
                                )
                              }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-tooltip top color="info">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="
                                      delete_insitution_officer_permissions(
                                        item
                                      )
                                    "
                                    color="error"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>ลบ</span>
                              </v-tooltip>
                            </template>
                          </SearchableTable>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-list-item-group>
              </v-card>
            </v-list>
            <v-row class="mt-2">
              <v-col cols="12" class="d-flex justify-center">
                <v-btn class="ma-2" @click="onclosedialogclick">ยกเลิก</v-btn>
                <v-btn class="ma-2 color-button2" @click="save"
                  ><v-icon>mdi-content-save</v-icon> บันทึก</v-btn
                >
              </v-col>
            </v-row>

            <v-dialog v-model="school_manager_permissions_dialog" width="500">
              <template>
                <v-card>
                  <v-card-title class="d-flex justify-center">
                    <h4>สิทธิผู้บริหารสถานศึกษา</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          :items="schools"
                          item-text="schoolname"
                          item-value="schoolid"
                          v-model="
                            selected_schoolid_for_school_manager_permission
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          class="color-button2"
                          @click="add_school_manager_permissions()"
                        >
                          บันทึก <v-icon>mdi-content-save</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>

            <v-dialog v-model="school_admin_permissions_dialog" width="500">
              <template>
                <v-card>
                  <v-card-title class="d-flex justify-center">
                    <h4>สิทธิบุคลากรในสถานศึกษา (ศูนย์ข้อมูลสารสนเทศ)</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          :items="schools"
                          item-text="schoolname"
                          item-value="schoolid"
                          v-model="
                            selected_schoolid_for_school_admin_permissions
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          class="color-button2"
                          @click="add_school_admin_permissions()"
                        >
                          บันทึก <v-icon>mdi-content-save</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>

            <v-dialog
              v-model="school_partner_officer_permissions_dialog"
              width="500"
            >
              <template>
                <v-card>
                  <v-card-title class="d-flex justify-center">
                    <h4>สิทธิบุคลากรในสถานศึกษา (ทวิภาคี)</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          :items="schools"
                          item-text="schoolname"
                          item-value="schoolid"
                          v-model="
                            selected_schoolid_for_school_partner_officer_permissions
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          class="color-button2"
                          @click="add_school_partner_officer_permissions()"
                        >
                          บันทึก <v-icon>mdi-content-save</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>

            <v-dialog
              v-model="school_guidance_teacher_permissions_dialog"
              width="500"
            >
              <template>
                <v-card>
                  <v-card-title class="d-flex justify-center">
                    <h4>สิทธิบุคลากรในสถานศึกษา (ครูแนะแนว)</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          :items="schools"
                          item-text="schoolname"
                          item-value="schoolid"
                          v-model="
                            selected_schoolid_for_school_guidance_teacher_permissions
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          class="color-button2"
                          @click="add_school_guidance_teacher_permissions()"
                          >บันทึก <v-icon>mdi-content-save</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>

            <v-dialog
              v-model="provinceschool_officer_permissions_dialog"
              width="500"
            >
              <template>
                <v-card>
                  <v-card-title class="d-flex justify-center">
                    <h4>สิทธิบุคลากรในอาชีวศึกษาจังหวัด</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          :items="provinces"
                          item-text="provincethai"
                          item-value="provinceid"
                          v-model="
                            selected_provinceid_for_provinceschool_officer_permissions
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          class="color-button2"
                          @click="add_provinceschool_officer_permissions()"
                        >
                          บันทึก <v-icon>mdi-content-save</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>

            <v-dialog
              v-model="insitution_officer_permissions_dialog"
              width="500"
            >
              <template>
                <v-card>
                  <v-card-title class="d-flex justify-center">
                    <h4>สิทธิบุคลากรในสถาบันการอาชีวศึกษา</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          :items="institues"
                          item-text="institutenameth"
                          item-value="instituteid"
                          v-model="
                            selected_insitutionid_for_insitution_officer_permissions
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          class="color-button2"
                          @click="add_insitution_officer_permissions()"
                        >
                          บันทึก <v-icon>mdi-content-save</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>
          </v-container>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { InstituteDataProvider } from "@/services/InstituteDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import SearchableTable from "@/components/common/SearchableDataTable.vue";
import { ApplicationRoleCodes } from "@/Constants";

const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

const instituteDataProvider = new InstituteDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: { SearchableTable },
  name: "RoleAndPermission_Management",
  data() {
    return {
      provinces: [],
      institues: [],
      schools: [],

      roleAndPermission_Management: [],

      school_manager_permissions_data_table_header: [
        { value: "permissionvalue", text: "สิทธิ" },
        { value: "actions", text: "ตัวเลือก" },
      ],
      school_admin_permissions_data_table_header: [
        { value: "permissionvalue", text: "สิทธิ" },
        { value: "actions", text: "ตัวเลือก" },
      ],
      school_partner_officer_permissions_data_table_header: [
        { value: "permissionvalue", text: "สิทธิ" },
        { value: "actions", text: "ตัวเลือก" },
      ],
      school_guidance_teacher_permissions_data_table_header: [
        { value: "permissionvalue", text: "สิทธิ" },
        { value: "actions", text: "ตัวเลือก" },
      ],
      provinceschool_officer_permissions_data_table_header: [
        { value: "permissionvalue", text: "สิทธิ" },
        { value: "actions", text: "ตัวเลือก" },
      ],
      insitution_officer_permissions_data_table_header: [
        { value: "permissionvalue", text: "สิทธิ" },
        { value: "actions", text: "ตัวเลือก" },
      ],

      school_manager_permissions_dialog: false,
      school_admin_permissions_dialog: false,
      school_partner_officer_permissions_dialog: false,
      school_guidance_teacher_permissions_dialog: false,
      provinceschool_officer_permissions_dialog: false,
      insitution_officer_permissions_dialog: false,

      selected_schoolid_for_school_manager_permission: "",
      selected_schoolid_for_school_admin_permissions: "",
      selected_schoolid_for_school_partner_officer_permissions: "",
      selected_schoolid_for_school_guidance_teacher_permissions: "",
      selected_provinceid_for_provinceschool_officer_permissions: "",
      selected_insitutionid_for_insitution_officer_permissions: "",
    };
  },
  props: {
    SchoolEmployeeProfileOid: {
      type: Number,
    },
    dialog: {
      type: Boolean,
    },
    isUsedForSchoolAdmin: {
      type: Boolean,
      default: false,
    },
    fixedSchoolPermissionCodes: {
      type: Array,
    },
  },
  computed: {
    oid() {
      return this.SchoolEmployeeProfileOid;
    },
  },
  watch: {
    dialog(newVal, oldVal) {
      if (newVal) {
        this.initiailize();
        if (this.SchoolEmployeeProfileOid) {
          //create case
          this.loadRoleAndPermission_Management();
        } else {
          //defualt
          this.setToDefaultRequest();
        }
      } else {
      }
    },
  },
  methods: {
    async initiailize() {
      await instituteDataProvider.loadInstitutes().then((result) => {
        this.institues = result.data;
      });

      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });

      const getSchools_Reqeust = new GetSchools_Reqeust();
      await schoolDataProvider
        .loadSchools(getSchools_Reqeust)
        .then((result) => {
          this.schools = result.data;
          console.log(this.schools);
        });

      if (this.fixedSchoolPermissionCodes != undefined) {
        if (this.fixedSchoolPermissionCodes.length > 0) {
          this.schools = this.schools.filter((x) =>
            this.fixedSchoolPermissionCodes.includes(x.schoolid)
          );
        }
      }
    },
    setToDefaultRequest() {
      this.roleAndPermission_Management.is_center_admin = false;
      this.roleAndPermission_Management.is_center_manager = false;
      this.roleAndPermission_Management.is_center_officer = false;
      this.roleAndPermission_Management.is_insitution_officer = false;
      this.roleAndPermission_Management.is_provinceschool_officer = false;
      this.roleAndPermission_Management.is_school_admin = false;
      this.roleAndPermission_Management.is_school_guidance_teacher = false;
      this.roleAndPermission_Management.is_school_manager = false;
      this.roleAndPermission_Management.is_school_partner_officer = false;

      this.roleAndPermission_Management.insitution_officer_permissions = [];
      this.roleAndPermission_Management.school_partner_officer_permissions = [];
      this.roleAndPermission_Management.school_manager_permissions = [];
      this.roleAndPermission_Management.school_guidance_teacher_permissions =
        [];
      this.roleAndPermission_Management.school_admin_permissions = [];
      this.roleAndPermission_Management.provinceschool_officer_permissions = [];
    },
    async loadRoleAndPermission_Management() {
      await schoolEmployeeProfileDataProvider
        .getSchoolEmployeeProfile_RoleAndPermission_Management(this.oid)
        .then((result) => {
          this.roleAndPermission_Management = result.data;
          console.log(this.roleAndPermission_Management);
        });
    },

    convert_ProvinceID_To_ProvinceName(provinceID = "") {
      const province = this.provinces.find((x) => x.provinceid == provinceID);
      if (province == undefined) return "";
      return province.provincethai;
    },

    convert_SchoolID_To_SchoolName(schoolID = "") {
      const school = this.schools.find((x) => x.schoolid == schoolID);
      if (school == undefined) return "";
      return school.schoolname;
    },

    convert_InsitutionID_To_InsitutionName(insitutionID = "") {
      const insitution = this.institues.find(
        (x) => x.instituteid == insitutionID
      );
      if (insitution == undefined) return "";
      return insitution.institutenameth;
    },

    add_school_manager_permissions() {
      const schoolID = this.selected_schoolid_for_school_manager_permission;

      const applicationRoleCode = ApplicationRoleCodes.School_Manager;
      const applicationrolename = "";
      const permissiondatatypename = "";
      const permissiondisplayvalue = "";
      const permissionvalue = schoolID;

      const item = {
        applicationRoleCode: applicationRoleCode,
        applicationrolename: applicationrolename,
        permissiondatatypename: permissiondatatypename,
        permissiondisplayvalue: permissiondisplayvalue,
        permissionvalue: permissionvalue,
      };
      const existItem =
        this.roleAndPermission_Management.school_manager_permissions.find(
          (p) => p.permissionvalue === permissionvalue
        );
      if (existItem == null) {
        this.roleAndPermission_Management.school_manager_permissions.push(item);
        this.school_manager_permissions_dialog = false;
      }
    },

    delete_school_manager_permissions(item) {
      const itemIndex =
        this.roleAndPermission_Management.school_manager_permissions.indexOf(
          item
        );
      this.roleAndPermission_Management.school_manager_permissions.splice(
        itemIndex,
        1
      );
    },

    add_school_admin_permissions() {
      const schoolID = this.selected_schoolid_for_school_admin_permissions;

      const applicationRoleCode = ApplicationRoleCodes.School_Admin;
      const applicationrolename = "";
      const permissiondatatypename = "";
      const permissiondisplayvalue = "";
      const permissionvalue = schoolID;

      const item = {
        applicationRoleCode: applicationRoleCode,
        applicationrolename: applicationrolename,
        permissiondatatypename: permissiondatatypename,
        permissiondisplayvalue: permissiondisplayvalue,
        permissionvalue: permissionvalue,
      };
      const existItem =
        this.roleAndPermission_Management.school_admin_permissions.find(
          (p) => p.permissionvalue === permissionvalue
        );
      if (existItem == null) {
        this.roleAndPermission_Management.school_admin_permissions.push(item);
        this.school_admin_permissions_dialog = false;
      }
    },

    delete_school_admin_permissions(item) {
      const itemIndex =
        this.roleAndPermission_Management.school_admin_permissions.indexOf(
          item
        );
      this.roleAndPermission_Management.school_admin_permissions.splice(
        itemIndex,
        1
      );
    },

    add_school_partner_officer_permissions() {
      const schoolID =
        this.selected_schoolid_for_school_partner_officer_permissions;

      const applicationRoleCode = ApplicationRoleCodes.School_Partner_Officer;
      const applicationrolename = "";
      const permissiondatatypename = "";
      const permissiondisplayvalue = "";
      const permissionvalue = schoolID;

      const item = {
        applicationRoleCode: applicationRoleCode,
        applicationrolename: applicationrolename,
        permissiondatatypename: permissiondatatypename,
        permissiondisplayvalue: permissiondisplayvalue,
        permissionvalue: permissionvalue,
      };
      const existItem =
        this.roleAndPermission_Management.school_partner_officer_permissions.find(
          (p) => p.permissionvalue === permissionvalue
        );
      if (existItem == null) {
        this.roleAndPermission_Management.school_partner_officer_permissions.push(
          item
        );
        this.school_partner_officer_permissions_dialog = false;
      }
    },

    delete_school_partner_officer_permissions(item) {
      const itemIndex =
        this.roleAndPermission_Management.school_partner_officer_permissions.indexOf(
          item
        );
      this.roleAndPermission_Management.school_partner_officer_permissions.splice(
        itemIndex,
        1
      );
    },

    add_school_guidance_teacher_permissions() {
      const schoolID =
        this.selected_schoolid_for_school_guidance_teacher_permissions;

      const applicationRoleCode = ApplicationRoleCodes.School_Guidance_Teacher;
      const applicationrolename = "";
      const permissiondatatypename = "";
      const permissiondisplayvalue = "";
      const permissionvalue = schoolID;

      const item = {
        applicationRoleCode: applicationRoleCode,
        applicationrolename: applicationrolename,
        permissiondatatypename: permissiondatatypename,
        permissiondisplayvalue: permissiondisplayvalue,
        permissionvalue: permissionvalue,
      };
      const existItem =
        this.roleAndPermission_Management.school_guidance_teacher_permissions.find(
          (p) => p.permissionvalue === permissionvalue
        );
      if (existItem == null) {
        this.roleAndPermission_Management.school_guidance_teacher_permissions.push(
          item
        );
        this.school_guidance_teacher_permissions_dialog = false;
      }
    },

    delete_school_guidance_teacher_permissions(item) {
      const itemIndex =
        this.roleAndPermission_Management.school_guidance_teacher_permissions.indexOf(
          item
        );
      this.roleAndPermission_Management.school_guidance_teacher_permissions.splice(
        itemIndex,
        1
      );
    },

    add_provinceschool_officer_permissions() {
      const provinceID =
        this.selected_provinceid_for_provinceschool_officer_permissions;

      const applicationRoleCode = ApplicationRoleCodes.ProvinceSchool_Officer;
      const applicationrolename = "";
      const permissiondatatypename = "";
      const permissiondisplayvalue = "";
      const permissionvalue = provinceID;

      const item = {
        applicationRoleCode: applicationRoleCode,
        applicationrolename: applicationrolename,
        permissiondatatypename: permissiondatatypename,
        permissiondisplayvalue: permissiondisplayvalue,
        permissionvalue: permissionvalue,
      };
      const existItem =
        this.roleAndPermission_Management.provinceschool_officer_permissions.find(
          (p) => p.permissionvalue === permissionvalue
        );
      if (existItem == null) {
        this.roleAndPermission_Management.provinceschool_officer_permissions.push(
          item
        );
        this.provinceschool_officer_permissions_dialog = false;
      }
    },

    delete_provinceschool_officer_permissions(item) {
      const itemIndex =
        this.roleAndPermission_Management.provinceschool_officer_permissions.indexOf(
          item
        );
      this.roleAndPermission_Management.provinceschool_officer_permissions.splice(
        itemIndex,
        1
      );
    },

    add_insitution_officer_permissions() {
      const insitutionID =
        this.selected_insitutionid_for_insitution_officer_permissions;

      const applicationRoleCode = ApplicationRoleCodes.Insitution_Officer;
      const applicationrolename = "";
      const permissiondatatypename = "";
      const permissiondisplayvalue = "";
      const permissionvalue = insitutionID;

      const item = {
        applicationRoleCode: applicationRoleCode,
        applicationrolename: applicationrolename,
        permissiondatatypename: permissiondatatypename,
        permissiondisplayvalue: permissiondisplayvalue,
        permissionvalue: permissionvalue,
      };
      const existItem =
        this.roleAndPermission_Management.insitution_officer_permissions.find(
          (p) => p.permissionvalue === permissionvalue
        );
      if (existItem == null) {
        this.roleAndPermission_Management.insitution_officer_permissions.push(
          item
        );
        this.insitution_officer_permissions_dialog = false;
      }
    },

    delete_insitution_officer_permissions(item) {
      const itemIndex =
        this.roleAndPermission_Management.insitution_officer_permissions.indexOf(
          item
        );
      this.roleAndPermission_Management.insitution_officer_permissions.splice(
        itemIndex,
        1
      );
    },

    async save() {
      if (this.SchoolEmployeeProfileOid) {
        await schoolEmployeeProfileDataProvider
          .SaveSchoolEmployeeProfile_RoleAndPermission_Management_Response(
            this.roleAndPermission_Management
          )
          .then((result) => {
            this.$store.dispatch({ type: "doSetIsLoadingProfile" });
            this.$toast.success("บันทึกข้อมูลสำเร็จ");
            this.$emit("closeDialog", this.roleAndPermission_Management, true);
          });
      } else {
        this.$emit("closeDialog", this.roleAndPermission_Management, true);
      }
    },
    onclosedialogclick() {
      this.$emit("closeDialog", "ทดสอบ", false);
    },
  },
  async created() {
    console.log(this.$store.getters.rolecodes);
    console.log(this.$store.dispatch.rolecodes);
  },
};
</script>

<style>
.tr-bg {
  background-color: #cedade !important;
  /* width: 98%;
    display: flex;
    justify-content: center; */
}

tr,
col {
  padding: 2em;
}

.light-grey-font {
  color: #b9bcbd;
}

.background {
  border-color: #cedade;
}

.custom-background {
  background-color: yellow;
}
</style>
