var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('appbar'),_c('v-container',{},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"d-block",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('h2',{staticClass:"appInfo"},[_vm._v("ระบบศูนย์เครือข่ายกำลังคนอาชีวศึกษา")])]),_c('div',{staticClass:"d-flex justify-center"},[_c('h2',{staticClass:"appInfo"},[_vm._v("Vocational Co-operation Center (VCOP)")])])])],1),_c('v-row',[_c('v-col',{class:[_vm.$vuetify.breakpoint.smAndDown ? 'col-6' : 'col-3'],on:{"click":function($event){return _vm.$router.push({
            path: 'Login',
          })}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"cursor":"pointer"},attrs:{"color":"orange","elevation":hover ? 12 : 2}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/login/i_manager.png"),"gradient":"to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)","height":"360px","contain":""}},[(hover)?_c('v-card-title',{staticClass:"justify-center",domProps:{"textContent":_vm._s('ผู้บริหาร')}}):_vm._e()],1),_c('v-fade-transition',[(!hover)?_c('v-overlay',{staticClass:"white--text align-end",attrs:{"opacity":0.3,"absolute":"","color":"#252525"}},[_c('v-card-title',{staticClass:"justify-center",domProps:{"textContent":_vm._s('ผู้บริหาร')}})],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{class:[_vm.$vuetify.breakpoint.smAndDown ? 'col-6' : 'col-3'],on:{"click":function($event){return _vm.$router.push({
            path: 'LoginOfficer',
          })}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"cursor":"pointer"},attrs:{"color":"#833133","elevation":hover ? 12 : 2}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/login/i_officer.png"),"gradient":"to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)","height":"360px","contain":""}},[(hover)?_c('v-card-title',{staticClass:"justify-center",domProps:{"textContent":_vm._s('บุคลากรส่วนกลาง')}}):_vm._e()],1),_c('v-fade-transition',[(!hover)?_c('v-overlay',{staticClass:"white--text align-end",attrs:{"opacity":0.3,"absolute":"","color":"#252525"}},[_c('v-card-title',{staticClass:"justify-center",domProps:{"textContent":_vm._s('บุคลากรส่วนกลาง')}})],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{class:[_vm.$vuetify.breakpoint.smAndDown ? 'col-6' : 'col-3'],on:{"click":function($event){return _vm.$router.push({
            path: 'LoginTeacher',
          })}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"cursor":"pointer"},attrs:{"color":"green","elevation":hover ? 12 : 2}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/login/i_teacher.png"),"gradient":"to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)","height":"360px","contain":""}},[(hover)?_c('v-card-title',{staticClass:"justify-center",domProps:{"textContent":_vm._s('บุคลากรสถานศึกษา')}}):_vm._e()],1),_c('v-fade-transition',[(!hover)?_c('v-overlay',{staticClass:"white--text align-end",attrs:{"opacity":0.3,"absolute":"","color":"#252525"}},[_c('v-card-title',{staticClass:"justify-center",domProps:{"textContent":_vm._s('บุคลากรสถานศึกษา')}})],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{class:[_vm.$vuetify.breakpoint.smAndDown ? 'col-6' : 'col-3'],on:{"click":function($event){return _vm.$router.push({
            path: 'LoginStudent',
          })}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","elevation":hover ? 12 : 2}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/login/i_student.png"),"gradient":"to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0)","height":"360px","contain":""}},[(hover)?_c('v-card-title',{staticClass:"justify-center",domProps:{"textContent":_vm._s('นักศึกษา')}}):_vm._e()],1),_c('v-fade-transition',[(!hover)?_c('v-overlay',{staticClass:"white--text align-end",attrs:{"opacity":0.3,"absolute":"","color":"#252525"}},[_c('v-card-title',{staticClass:"justify-center",domProps:{"textContent":_vm._s('นักศึกษา')}})],1):_vm._e()],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }