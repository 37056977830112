import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
export class SubjectTypeDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  loadSubjectTypes() {
    try {
      const cachedData = localStorage.getItem(
        `SubjectTypes_${process.env.VUE_APP_VERSION}`
      );
      if (cachedData) {
        const data = JSON.parse(cachedData);

        return new Promise((resolve) => {
          let response = [];
          response.status = 200;
          response.data = data;
          resolve(response);
        });
      } else {
        var header = create_default_request_header();
        var endpointUrl = "/api/SubjectType/GetSubjectTypes";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return new Promise((resolve, reject) => {
          axios
            .get(combinedURL, header)
            .then((response) => {
              if (response?.status == 200) {
                resolve(response);
                localStorage.setItem(
                  `SubjectTypes_${process.env.VUE_APP_VERSION}`,
                  JSON.stringify(response.data)
                );
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }

  loadSubjectType(subjectTypeID) {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/SubjectType/GetSubjectType/" + subjectTypeID;
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.get(combinedURL, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }

  getSubjectTypesFromVECEducationInformationBySchoolID(schoolID = "") {
    const header = create_default_request_header();
    const endpointPath =
      "/api/SubjectType/GetSubjectTypesFromVECEducationInformationBySchoolID/" +
      schoolID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getSubjectTypesNameThFromVECEducationInformationBySchoolID(schoolID = "") {
    const header = create_default_request_header();
    const endpointPath =
      "/api/SubjectType/GetSubjectTypesNameThFromVECEducationInformationBySchoolID/" +
      schoolID;
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  importSubjectTypes() {
    try {
      var header = create_default_request_header();
      var endpointUrl = "/api/SubjectType/ImportSubjectTypeDataFromWebAPI";
      var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
      return axios.get(combinedURL, header);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          store.dispatch({ type: "doLogout" });
        }
        //  error: AxiosError<any, any>
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  }
}
