<template>
  <v-card height="300"
    ><v-system-bar color="pink darken-2" dark></v-system-bar
    ><v-card-title>สถานะการส่งรายงาน</v-card-title>
    <v-list-item v-if="item.label" v-for="item in SendingReport" :key="item.label">
      <v-list-item-title>
        {{ item.label }}
      </v-list-item-title>
      <v-list-item-title>{{ item.count }} รายการ</v-list-item-title>
    </v-list-item>
  </v-card>
</template>

<script>
import { DashboardDataSourceProvider } from "@/services/DashboardDataSourceProvider";
import { backendapiUrl } from "@/services/constants";
import { GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource_Request } from "@/models/Dashboard/GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource_Request";

var dashboardDataSourceProvider = new DashboardDataSourceProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import { JobTrackingReportStatusCodes } from "@/Constants";
export default {
  props: {
    SelectedTargetGraduatedYearTH: {
      default: "",
      type: String,
    },
    SelectedRoundNumber: {
      default: "",
      type: String,
    },
  },
  watch: {
    SelectedTargetGraduatedYearTH(newValue, OldValue) {
      this.loadSendingReportStatus();
    },
    SelectedRoundNumber(newValue, OldValue) {
      this.loadSendingReportStatus();
    },
  },
  data() {
    return {
      SendingReport: [
        { label: "", count: 0 },
        { label: "", count: 0 },
        { label: "", count: 0 },
      ],
    };
  },
  methods: {
    async initialize() {
      this.targetJobTrackingReportStatusCodes = [];
      this.targetJobTrackingReportStatusCodes.push(
        JobTrackingReportStatusCodes.FILLING_STUDENT_DATA
      );
      this.targetJobTrackingReportStatusCodes.push(JobTrackingReportStatusCodes.SENT);
      this.targetJobTrackingReportStatusCodes.push(JobTrackingReportStatusCodes.NOT_SENT);
    },
    async loadSendingReportStatus() {
      const request = new GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource_Request();
      request.TargetGraduatedYearTH = this.SelectedTargetGraduatedYearTH;
      request.RoundNumber = this.SelectedRoundNumber;
      request.TargetJobTrackingReportStatusCodes = this.targetJobTrackingReportStatusCodes;
      await dashboardDataSourceProvider
        .GetGraduatedStudentJobTrackingReport_SendingReportStatusDashboardDataSource(
          request
        )
        .then((result) => {
          for (var index = 0; index < result.data.labels.length; index++) {
            (this.SendingReport[index].label = result.data.labels[index]),
              (this.SendingReport[index].count = result.data.data[index]);
          }
        });
    },
  },
  mounted() {
    this.initialize();
    this.loadSendingReportStatus();
  },
};
</script>

<style></style>
