<template>
  <div>
    <v-data-table
      :headers="studentEducation_headers"
      :items="studentEducations"
      hide-default-footer
      class="elevation-1 custom-header-gradient mx-1"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="open_StudyStudentDetailView_Dialog(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-book-outline</v-icon>
            </v-btn>
          </template>
          <span>แสดงรายละเอียด</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="StudyStudentDetailView_Dialog" width="700" persistent>
      <v-card
        v-if="EducationDialog && StudyStudentDetailView_Dialog == true"
        class="pa-10"
      >
        <v-row class="d-flex flex-column mb-1 bg-surface-variant align-item-center">
          <v-col class="d-flex justify-center">
            <h2 class="primary--text">ข้อมูลการศึกษา</h2>
          </v-col>
          <v-col class="d-flex justify-center">
            <h4 class="primary--text">
              สถานศึกษา : {{ EducationDialog.school.schoolname }}
            </h4>
          </v-col>
          <v-col class="d-flex justify-center">
            <h4 class="primary--text mb-2">
              รหัสนักศึกษา : {{ EducationDialog.studentcode }}
            </h4>
          </v-col>
          <div
            style="
              background-color: rgba(63, 81, 181, 0.8);
              color: white;
              border-radius: 20px;
              padding: 5px 10px;
              font-size: 16px;
            "
            class="mb-2"
          >
            รายละเอียด
          </div>
          <label class="primary--text ml-3 mt-2"
            >ปีการศึกษา : {{ EducationDialog.gradautedyearth }}</label
          >
          <label class="primary--text ml-3 mt-2"
            >วุฒิการศึกษา : {{ EducationDialog.gradenameth }}</label
          >
          <label class="primary--text ml-3 mt-2"
            >สถานะการศึกษา :
            <v-chip> {{ EducationDialog.studentstatusname }}</v-chip></label
          >
          <label class="primary--text ml-3 mt-2"
            >สาขาวิชา : {{ EducationDialog.majornameth }}</label
          >
          <label class="primary--text ml-3 mt-2"
            >สาขางาน : {{ EducationDialog.minornameth }}</label
          >

          <label class="primary--text ml-3 mt-2"
            >เกรดเฉลี่ยรวม (GPAX) : {{ EducationDialog.gpax }}</label
          >
        </v-row>

        <v-row class="justify-center text-center mt-7">
          <v-col cols="12">
            <v-btn @click="StudyStudentDetailView_Dialog = false" class="ma-2" color="">
              ยกเลิก
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
var studentDataProvider = new StudentDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  props: ["cardID"],
  components: {
    // EducationStudentDialog,
  },
  data() {
    return {
      studentEducation_headers: [
        { text: "สถานศึกษา", value: "school.schoolname" },
        { text: "ระดับชั้น", value: "gradenameth" },
        { text: "สถานะการศึกษา	", value: "studentstatusname" },
        { text: "", value: "action" , sortable: false},
      ],

      Education: [],
      idcard: "",
      studentEducations: [],
      StudyStudentDetailView_Dialog: false,
      gradenameth: "",
      studentstatusname: "",
      EducationDialog: [],
    };
  },

  methods: {
    async getStudentVECEducationInformations() {
      await studentDataProvider
        .getStudentVECEducationInformations(this.idcard)
        .then((response) => {
          this.studentEducations = response.data;
          this.Education = response.data;
          // this.schoolname = response.data.school.schoolname;
          // this.gradenameth = response.data.gradenameth;
          // this.studentstatusname = response.data.studentstatusname;
        });
    },
    open_StudyStudentDetailView_Dialog(item) {
      this.StudyStudentDetailView_Dialog = true;
      this.EducationDialog = item;
    },
  },

  async created() {
    this.host = backendapiUrl;
    this.idcard = this.cardID;
    await this.getStudentVECEducationInformations();
  },
};
</script>

<style scoped>
.custom-header-gradient .v-data-table-header {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.custom-header-gradient th {
  color: white !important;
}
</style>
