<template>
    <v-container class="mt-5">
        <v-row class="d-flex justify-center">
            <v-col cols="12">

                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col class="d-flex justify-center">
                                <h4>ยืนยันอีเมล</h4>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center">
                                <p v-show="isProcessing">กรุณารอสักครู่ระบบกำลังตรวจสอบข้อมูลการยืนยันอีเมล</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center">
                                <v-progress-circular indeterminate color="success"
                                    v-show="isProcessing"></v-progress-circular>
                            </v-col>
                            <v-col v-if="tokenValid == true" cols="12" class="d-flex justify-center">
                                <h3 class="success--text"><v-icon color="success">mdi-check-circle</v-icon> {{ resultMsg }}
                                </h3>
                            </v-col>
                            <v-col v-if="tokenValid == false" cols="12" class="d-flex justify-center">
                                <h3 v-if="tokenValid == false" class="error--text"><v-icon
                                        color="error">mdi-close-circle</v-icon> {{ resultMsg }}</h3>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { EmployerProfileDataProvider } from '@/services/EmployerProfileDataProvider';
const employerProfileDataProvider = new EmployerProfileDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
    name: 'VerifyEmail',
    data() {
        return {
            countdown: 5,
            tokenValid: undefined,
            token: '',
        }
    },
    computed: {
        isProcessing() {
            return this.resultMsg == '';
        },
        resultMsg() {
            if (this.tokenValid == true) {
                return 'ยืนยันอีเมลเรียบร้อย';
            } else if (this.tokenValid == false) {
                return 'ไม่สามารถยืนยันอีเมลได้ กรุณาลองใหม่ภายหลัง!!';
            }
            return '';
        }
    },
    methods: {
        startCountdown() {
            const timer = setInterval(() => {
                this.countdown--;
                if (this.countdown <= 0) {
                    clearInterval(timer);
                    this.verifyToken();
                }
            }, 1000);
        },

        async verifyToken() {
            await employerProfileDataProvider.verifyEmail(this.token).then(result => {
                this.tokenValid = true;
            }).catch(err => {
                this.tokenValid = false;
            });
        }
    },
    async created() {
        this.token = this.$route.params.Token;
        this.startCountdown();
    }
}
</script>

<style></style>