<template>
  <v-form>
    <v-row class="d-flex justify-center ma-5">
      <v-col cols="11">
        <div id="element-to-convert">
          <v-card style="border-radius: 15px" v-if="jobapplication">
            <v-card-text>
              <v-row class="justify-center">
                <v-col cols="10" class="py-10">
                  <v-row>

                    <v-col
                      class="d-flex flex-column align-lg-start align-sm-center pt-3"
                      cols="12"
                      lg="6"
                      md="6"
                      sm="12"
                    >
                      <h4 class="error--text">สถานะใบสมัคร :&nbsp;</h4>
                      <JobApplicationStatus_Label
                        :JobApplication="jobapplication"
                      ></JobApplicationStatus_Label>
                    </v-col>
                    <v-col
                      class="d-flex flex-column align-lg-end align-sm-center pt-3"
                      cols="12"
                      lg="6"
                      md="6"
                      sm="12"
                    >
                      <h4>
                        รหัสประกาศงาน :
                        {{ jobapplication.jobposting?.jobposting_id }}
                      </h4>
                    </v-col>

                    <v-col cols="12" lg="12">
                      <h1 class="">
                        {{ jobapplication.jobposting?.positionname }}
                      </h1>
                    </v-col>
                    <v-col cols="12">
                      <label>{{
                        jobapplication.jobposting?.employerprofile.nameth
                      }}</label>

                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-icon>mdi-map-marker</v-icon
                      ><label>{{
                        jobapplication.jobposting?.employerprofile.district
                          .province.provincethai
                      }}</label>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <label
                        >อัตราที่รับสมัคร :
                        {{ jobapplication.jobposting?.unit }}</label
                      >
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <label
                        v-if="
                          this.jobapplication.jobposting
                            ?.jobsegmentname_todisplay !== ''
                        "
                        >ประเภทงาน :
                        {{
                          jobapplication.jobposting?.jobsegmentname_todisplay
                        }}</label
                      >
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <label>เงินเดือน : {{ jobapplication.jobposting?.wagetodisplay }}</label>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <label>ระดับการศึกษาขั้นต่ำ : {{
                          jobapplication.jobposting
                            ?.applicant_requiredcertificatetype.nameth
                        }}</label>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <label>วันที่ประกาศ : {{ jobapplication.jobposting?.startdate }} ถึง
                        {{ jobapplication.jobposting?.enddate }}</label>
                    </v-col>
                    <v-col class="d-flex justify-end" cols="12">
                      <v-btn
                              class="ml-2 equal-height-btn display-center"
                              fab
                              outlined
                              tile
                              style="border-radius: 10px"
                              ><v-icon @click="print" color="primary"
                                >mdi-printer</v-icon
                              ></v-btn
                            >

                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>

                  
                    <v-col cols="12">
                      <h4 class="primary--text">คุณสมบัติผู้สมัครงาน</h4> <br>
                      {{ jobapplication.jobposting?.applicant_specification }}
                    </v-col>

                    <v-col cols="12">
                      <h4 class="primary--text">หน้าที่และรายละเอียดของงาน</h4> <br>
                      {{ jobapplication.jobposting?.jobdescription }}
                    </v-col>

                    <v-col cols="12">
                      <h4 class="primary--text">สวัสดิการ</h4> <br>
                      {{
                      jobapplication.jobposting?.employerprofile
                        .benefitdescription}}
                    </v-col>

                    
                    <v-col cols="12">
                      <h4 class="primary--text">ข้อมูลผู้ประสานงานของสถานประกอบการ</h4> <br>
                      ชื่อ-นามสกุล :&nbsp;{{ jobapplication.jobposting?.coordinator_fullname }} <br>
                      เบอร์โทรศัพท์ :&nbsp;{{ jobapplication.jobposting?.coordinator_phoneno }} <br>
                      อีเมล :&nbsp;{{ jobapplication.jobposting?.coordinator_email }}
                    </v-col>




                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <v-row class="justify-center text-center mt-4">
          <v-col cols="12">

          </v-col>
        </v-row>
        <v-row class="justify-center text-center mb-4">
          <v-col cols="12">
            <v-btn color="" @click="$router.go(-1)" class="ma-2">
              ย้อนกลับ</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { JobApplicationDataProvider } from "@/services/JobApplicationDataProvider";
import moment from "moment";
import JobApplicationStatus_Label from "@/components/JobPostings/JobApplicationStatus_Label.vue";

const jobApplicationDataProvider = new JobApplicationDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import html2pdf from "html2pdf.js";

export default {
  name: "JobApplicationInfo",
  components: {
    JobApplicationStatus_Label,
  },
  data() {
    return {
      jobapplication_oid: "",
      jobapplication: undefined,
      logo_photobase64: "",
      moment: moment,
      statuscode: "",
    };
  },
  methods: {
    async initialize() {
      await jobApplicationDataProvider
        .getJobApplication(this.jobapplication_oid)
        .then((result) => {
          this.jobapplication = result.data;
          console.log(this.jobapplication )
          this.logo_photobase64 = result.data.student.base64thrumnail;
        });
    },
    print() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: "JobApplicationInfo.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        enableLinks: true,
        pagebreak: { mode: ["css", "legacy"] },
      });
    },
    openSearchStudentDetails: function () {
      const student_oid = this.jobapplication.student.oid;
      this.$router
        .push({ name: "SearchStudentDetails", params: { student_oid } })
        .catch((err) => {});
    },
  },
  mounted() {
    // var datenow = new Date
    this.daysDifference = moment().diff("2000-02-28", "years");
  },
  async created() {
    if (this.$route.params.oid != undefined) {
      this.jobapplication_oid = this.$route.params.oid;
      this.statuscode = this.$route.params.item;
      await this.initialize();
    }
  },
};
</script>

<style scoped>
.shrink {
  border-radius: 200px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 10px rgba(15, 15, 15, 0.5);
  border: 1px solid grey;
}
</style>
