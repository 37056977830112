<template>
  <div>
    <v-row outlined class="pa-2 mb-3 mt-2 d-flex align-end">
      <h4 class="primary--text ml-2">รางวัล
      </h4>
      <v-spacer></v-spacer>
      <v-btn
        @click="RewardStudent_Dialog = true"
        outlined
        class="mr-2"
        :disabled="isInputDisabled"
      >
        เพิ่มรางวัลที่ได้รับ<v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>

          <v-data-table
            :headers="reward_headers"
            :items="reward"
            hide-default-footer
            class="custom-header-gradient"
          >
            <template class="d‑flex" v-slot:item.rewarddate="{ item }">
              <FormatDate :date_value="item.rewarddate"></FormatDate>
            </template>
            <template class="d‑flex" v-slot:item.action="{ item }">
              <v-row class="custom-width">
                <v-tooltip top color="info">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="onclickEditRewardStudent(item)"
                      color="info"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>แก้ไขประวัติรางวัล</span>
                </v-tooltip>
                <v-tooltip top color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="deleteRewardStudent(item)"
                      color="error"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>ลบ</span>
                </v-tooltip></v-row
              >
            </template>
          </v-data-table>

    <RewardStudentDialog
      :RewardStudent_Dialog="RewardStudent_Dialog"
      :oid="Rewardoid"
      @closeDialog="onRewardProcessIsCompleted"
    ></RewardStudentDialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { StudentDataProvider } from "@/services/StudentDataProvider";
var studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import RewardStudentDialog from "@/components/Student/Reward/RewardStudentDialog.vue";
import { RewardRecord } from "@/models/Student/RewardRecord";
import FormatDate from "@/components/common/FormatDate.vue";

export default {
  props: ["student_oid", "isInputDisabled"],
  components: {
    FormatDate,
    RewardStudentDialog,
  },
  data() {
    return {
      RewardStudent_Dialog: false,
      reward_headers: [
        { text: "รายการรางวัล", value: "topic" },
        { text: "วันที่ได้รับ", value: "rewarddate" },
        { text: "", value: "action" , sortable: false },
      ],
      reward: [],
      vm: new RewardRecord(),
      Rewardoid: "-1",
    };
  },
  methods: {
    async LoadRewardStudent(student_oid) {
      studentDataProvider
        .getStudentRewardRecords(student_oid)
        .then((res) => {
          this.reward = res.data;
        })
        .catch((res) => {});
    },

    async saveRewardStudent(value) {
      value.studentoid = this.student_oid;
      studentDataProvider
        .saveStudentRewardRecord(value)
        .then((res) => {
          this.$toast.success("สร้าง ประวัติ เรียบร้อย!");
          const student_oid = this.student_oid;
          this.LoadRewardStudent(student_oid);
        })
        .catch((res) => {
          this.$toast.error("ล้มเหลว!");
        });
    },

    async onRewardProcessIsCompleted(value, save) {
      if (save) {
        this.RewardStudent_Dialog = false;
        if (value.oid != "-1") {
          await this.UpdateRewardStudent(value);
        } else {
          await this.saveRewardStudent(value);
        }
      } else {
        this.RewardStudent_Dialog = false;
      }
      this.Rewardoid = "-1";
    },

    onclickEditRewardStudent(item) {
      this.Rewardoid = item.oid;
      this.RewardStudent_Dialog = true;
    },
    async UpdateRewardStudent(value) {
      studentDataProvider
        .editStudentRewardRecord(value)
        .then((res) => {
          if (res?.status == 200) {
            this.$toast.success("บันทึกข้อมูลสำเร็จ");
            const student_oid = this.student_oid;
            this.LoadRewardStudent(student_oid);
          }
        })
        .catch((res) => {
          this.$toast.error("แก้ไขข้อมูลล้มเหลว !!!" + res);
        });
    },

    async deleteRewardStudent(item) {
      if (confirm("ต้องการประวัติการทำงานหรือไม่ ?")) {
        await studentDataProvider
          .deleteStudentRewardRecord(item.oid)
          .then((res) => {
            this.reward = [];
            this.$toast.success("ลบประวัติการทำงาน เรียบร้อย!");
            const student_oid = this.student_oid;
            this.LoadRewardStudent(student_oid);
          })
          .catch((res) => {
            this.$toast.error("ลบประวัติการทำงานล้มเหลว!" + res);
          });
      }
    },
  },
  async mounted() {
    const student_oid = this.student_oid;
    await this.LoadRewardStudent(student_oid);
  },
};
</script>

<style scoped>
.custom-width {
  width: 100px;
  gap: 10px;
}
</style>
