import axios from "axios";
import { create_default_request_header } from "./constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";
export class CenterStaffJobPostingDataProvider 
{
constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  getJobPostins(options){
    const header = create_default_request_header();
    const data = JSON.stringify(options);
    const endpointUrl = "/api/JobPosting/CenterStaff_GetJobPostings";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL,data, header);
  }
 

  getJobApplications(options){
    const header = create_default_request_header();
    const data = JSON.stringify(options);
    const endpointUrl = "/api/JobApplication/CenterStaff_GetJobApplications";
    const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL,data, header);
  }

  getWorkingplace_provinceCenterStaff_GetJobPostings() {
    const header = create_default_request_header();
    var endpointPath = "/api/JobPosting/GetWorkingplace_provinceCenterStaff_GetJobPostings/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }
  

  getEmploymentcategory() {
    const header = create_default_request_header();
    var endpointPath = "/api/JobPosting/GetEmploymentcategory/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }

  getJobPostingStatus() {
    const header = create_default_request_header();
    var endpointPath = "/api/JobPosting/GetJobPostingStatus/";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    return axios.get(combinedURL, header);
  }
}



  