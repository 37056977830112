import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
import { CreateEmployerProfileRequest } from '@/models/Employer/CreateEmployerProfileRequest';
import { EditEmployerProfileRequest, UpdateEmployerProfileRequest } from '@/models/Employer/UpdateEmployerProfileRequest';
import { EmployerResetPasswordVerifyRequest } from '@/models/Employer/ResetPasswordRequest';
import { EmployerProfilesRequest } from '@/models/Employer/EmployerProfilesRequest';
export class EmployerProfileDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    isEmailAddressUsed(email = ''){
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/IsEmailAddressUsed/" + email;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null, header );
    }

    loadEmployerProfiles(request = new EmployerProfilesRequest()) {
        const data = JSON.stringify(request);
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/GetEmployerProfiles";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, data, header );
    }

    getEmployerProfilesBySchoolParterOfficerRole(){
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/GetEmployerProfilesBySchoolParterOfficerRole";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null, header );
    }

    loadEmployerProfile(oid) {
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/GetEmployerProfile/" + oid;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null ,header );
    }

    getCurrentEmployerProfile() {
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/GetCurrentEmployerProfile";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL ,null,  header );
    }

    verifyEmail(token = ''){
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/VerifyEmail/" + token;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL ,null,  header );
    }

    createEmployerProfile(vm = new FormData()) {
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/CreateEmployerProfile";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, vm,  header );
    }

    CreateEmployerProfilesBySchoolParterOfficerRole(vm = new FormData()){
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/CreateEmployerProfilesBySchoolParterOfficerRole";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, vm,  header );
    }

    updateEmployerProfile(vm = new UpdateEmployerProfileRequest()) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/UpdateEmployerProfile";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, vm,  header );
    }

    deleteEmployerProfile(oid) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/DeleteEmployerProfile/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null, header );
    }

    deleteEmployerSchoolContact(oid) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/DeleteEmployerSchoolContact/" + oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,  null,header );
    }
    uploadLocationPhoto(vm = new FormData()) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/UpdateLocationPhoto";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,vm,  header );
    }
    uploadLogoPhoto(vm = new FormData()) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/UpdateLogoPhoto";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,vm,  header );
    }
    loadLogoPhoto(oid) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/LoadLogoPhoto/"+ oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL,  header );
    }
    LoadDocumentFile(oid) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/GetDocumentFile/"+ oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, header);
    }

    uploadDocumentFile(vm = new FormData()) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/UpdateDocumentFile";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,vm, header);
    }

    DeleteDocumentFile(oid) {
        var header = create_default_request_header();
        var endpointUrl = "/api/EmployerProfile/DeleteDocumentFile/"+ oid;
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.delete(combinedURL, header);
    }

    getCompletionPercentage(oid) {
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/CalculateCompletionPercentage/" + oid;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, null ,header );
    }

    resetPasswordVerify(request = new ResetPasswordRequest()){
        const data = JSON.stringify(request);
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/ResetPassword";
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, data,header );
    }

    set_ChangePasswordWhenLogon(oid = ''){
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/Set_ChangePasswordWhenLogon/" + oid;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL, null,header );
    }

    VerifyProfileWithDBDOpenAPI(oid){
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/VerifyProfileWithDBDOpenAPI/" + oid;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header);
    }

    getPublicEmployerInformationByJuristicID(juristicID){
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/GetPublicEmployerInformationByJuristicID/" + juristicID;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header);
    }

    updateDBDInformation(juristicID){
        const header = create_default_request_header();
        const endpointUrl = "/api/EmployerProfile/UpdateDBDInformation/" + juristicID;
        const combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,null, header);
    }

}