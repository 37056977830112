import GraduateTrackingList from "@/views/Graduate-Tracking/List.vue";
import GraduateTrackingList_BySchool from "@/views/Graduate-Tracking/ListBySchool.vue";
import GraduateTrackingCreate from "@/views/Graduate-Tracking/Create.vue";
import GraduateTrackingEdit from "@/views/Graduate-Tracking/Edit.vue";

export default [
  {
    path: "/graduate",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/graduate/tracking",
        name: "Graduate-Tracking",
        component: GraduateTrackingList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/graduate/tracking/school",
        name: "Graduate-Tracking-by-school",
        component: GraduateTrackingList_BySchool,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/graduate/tracking/create",
        name: "Graduate-Tracking-Create",
        component: GraduateTrackingCreate,
        meta: {
          requiresAuth: true,
        },
      },
      {
        // ระบุ path แบบที่มีการส่ง parameter
        path: "/graduate/tracking/edit/:id",
        name: "Graduate-Tracking-Edit",
        component: GraduateTrackingEdit,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
