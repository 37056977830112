import { render, staticRenderFns } from "./SchoolStudentListView.vue?vue&type=template&id=f64eb642&scoped=true"
import script from "./SchoolStudentListView.vue?vue&type=script&lang=js"
export * from "./SchoolStudentListView.vue?vue&type=script&lang=js"
import style0 from "./SchoolStudentListView.vue?vue&type=style&index=0&id=f64eb642&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f64eb642",
  null
  
)

export default component.exports