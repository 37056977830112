<template>
  <div class="pa-5">
    <v-card rounded elevation="1" class="px-5 py-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <h3 class="primary--text">นักศึกษาที่สำเร็จการศึกษา</h3></v-col
        >

        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          class="d-flex justify-lg-end justify-md-end justify-sm-start"
        >
          <!-- <v-btn :loading="isImporting" outlined @click="dialog = true"
            ><v-icon class="mr-2">mdi-import</v-icon
            >นำเข้าข้อมูลนักศึกษาที่สำเร็จการศึกษา</v-btn
          > -->
        </v-col>
        <v-col cols="12" lg="6" sm="12" class="d-flex align-center my-0 py-0">
          <label class="mr-4" v-if="schoolGraduatedStudent != undefined"
            >สถานศึกษา :
            <label v-if="schoolGraduatedStudent != undefined" class=""
              >{{ schoolGraduatedStudent.schoolid }}
              {{ schoolGraduatedStudent.schoolname }}</label
            >
          </label>
        </v-col>
        <v-col
          v-if="graduatedStudentRequest.gradautedyearth"
          cols="12"
          class="d-flex align-center mt-1 pt-1"
        >
          <label class="mr-4"
            >ปีที่สำเร็จการศึกษา :
            <label class="">{{
              graduatedStudentRequest.gradautedyearth
            }}</label>
          </label>
        </v-col>
      </v-row>
      <v-divider :thickness="3" class="border-opacity-75 my-5"> </v-divider>
      <v-row>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
          <h4 class="primary--text mb-2">ปีที่เข้าศึกษา</h4>

          <v-select
            label="ปีที่เข้าศึกษา"
            v-model="selectedEntranceyear"
            :items="yearthlist"
            @change="loadGraduatedStudents()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            dense
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
          <h4 class="primary--text mb-2">ปีที่สำเร็จการศึกษา</h4>

          <v-select
            label="ปีที่สำเร็จการศึกษา"
            v-model="selectedGradautedyearth"
            :items="yearthlist"
            @change="loadGraduatedStudents()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            dense
            clearable
          >
          </v-select>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
          <h4 class="primary--text mb-2">สถานะการอัปเดตโปรไฟล์</h4>
          <v-row no-gutters align-content="end">
            <!-- <v-btn
                :color="
                  tableOptions.selected_studentupdateprofilevalue === ''
                    ? 'primary'
                    : ''
                "
                @click="
                  tableOptions.selected_studentupdateprofilevalue = '';
                  loadStudentsWithDividePaging();
                "
                :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                style="background-color: #f2f4f8"
              >
                ทั้งหมด
              </v-btn> -->
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1 mb-1"
            >
              <v-btn
                :color="selectedIsprofileupdated === true ? 'primary' : 'input'"
                @click="
                  selectedIsprofileupdated = true;
                  loadGraduatedStudents();
                "
                :class="['mr-3', { 'px-2 mr-2': $vuetify.breakpoint.lg }]"
                height="52px"
                width="100%"
              >
                อัปเดตแล้ว
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1 mb-1"
            >
              <v-btn
                class="{ 'px-2': $vuetify.breakpoint.lg }"
                :color="
                  selectedIsprofileupdated === false ? 'primary' : 'input'
                "
                @click="
                  selectedIsprofileupdated = false;
                  loadGraduatedStudents();
                "
                height="52px"
                width="100%"
              >
                ยังไม่มีการอัปเดต
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-select
              label="สถานะการอัปเดตโปรไฟล์"
              clearable
              :items="studentUpdatedProfileStatus"
              item-text="name"
              item-value="value"
              v-model="graduatedStudentRequest.isprofileupdated"
              @change="loadGraduatedStudents()"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
              dense
            >
            </v-select> -->
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
          <h4 class="primary--text mb-2">ระดับชั้น</h4>

          <v-autocomplete
            label="ระดับชั้น"
            :items="certificateType"
            item-text="nameth"
            item-value="code"
            v-model="selectedCertificationType"
            clearable
            @change="loadGraduatedStudents()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
          <h4 class="primary--text mb-2">ประเภทวิชา</h4>

          <v-autocomplete
            :items="subjecttypes"
            v-model="selectedSubjecttypeNameTh"
            item-text="subjecttypenameth"
            item-value="subjecttypenameth"
            label="ประเภทวิชา"
            clearable
            @change="loadGraduatedStudents()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
          <h4 class="primary--text mb-2">สาขาวิชา</h4>

          <v-autocomplete
            :items="majors"
            v-model="selectedMajorNameTh"
            item-text="majornameth"
            item-value="majornameth"
            label="สาขาวิชา"
            clearable
            @change="loadGraduatedStudents()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
          <h4 class="primary--text mb-2">สาขางาน</h4>

          <v-autocomplete
            label="สาขางาน"
            :items="minor"
            item-text="minornameth"
            item-value="minornameth"
            v-model="selectedMinorNameTh"
            clearable
            @change="loadGraduatedStudents()"
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" class="mb-0 pb-0">
          <h4 class="primary--text mb-2">เพศ</h4>
          <v-row no-gutters>
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
            >
              <v-btn
                :color="selectedGender === '' ? 'primary' : 'input'"
                @click="
                  selectedGender = '';
                  loadGraduatedStudents();
                "
                height="52px"
                width="100%"
              >
                ทั้งหมด
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
            >
              <v-btn
                :color="selectedGender === 'M' ? 'primary' : 'input'"
                @click="
                  selectedGender = 'M';
                  loadGraduatedStudents();
                "
                height="52px"
                width="100%"
              >
                ชาย
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="4"
              class="px-lg-1 px-md-1 py-lg-0 py-sm-1"
            >
              <v-btn
                :color="selectedGender === 'F' ? 'primary' : 'input'"
                @click="
                  selectedGender = 'F';
                  loadGraduatedStudents();
                "
                height="52px"
                width="100%"
              >
                หญิง
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-autocomplete
              label="เพศ"
              :items="genderlist"
              item-text="name"
              item-value="code"
              v-model="graduatedStudentRequest.gender"
              clearable
              @change="loadGraduatedStudents()"
              background-color="input"
              filled
              append-icon="mdi-chevron-down"
              dense
            ></v-autocomplete> -->
        </v-col>
      </v-row>
      <SearchableDataTablePaging
        :data_items="graduatedStudents"
        :datatable_headers="graduatedStudents_data_table_headers"
        table_caption="รายชื่อนักศึกษาที่สำเร็จการศึกษา"
        :itemlength="itemlength"
        :search="keyword"
        @pageChanged="pageChanged"
        @onkeywordchanged="onKeywordChanged"
      >
        <!-- <template v-slot:item.fullname="{ item }">
        <span>{{ item.firstname }} {{ item.surname }}</span>
      </template> -->
        <template v-slot:item.action="{ item }">
          <v-row class="custom-width">
            <v-tooltip top color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openStudentDetailView(item)"
                  color="info"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <span>แสดงรายละเอียด</span>
            </v-tooltip>
          </v-row>
        </template>
      </SearchableDataTablePaging>
    </v-card>

    <!-- โค้ดเก่า -->
    <!-- <v-row class="mb-2">
      <v-col cols="12">
        <div class="d-flex justitfy-content-between">
          <v-btn @click="dialog = true" dark color="success">
            <v-icon>mdi-import</v-icon> นำเข้าข้อมูลนักศึกษาที่สำเร็จการศึกษา
          </v-btn>
        </div>
      </v-col>
    </v-row> -->
    <!-- <v-card class="pa-4">
      <v-row class="">
        <v-col cols="12" class="d-flex align-center mb-0 pb-0">
          <h3 class="primary--text mt-2 mb-3">นักศึกษาที่สำเร็จการศึกษา</h3>
        </v-col>
        <v-col cols="12" class="d-flex align-center">
          <label class="mr-4" v-if="schoolGraduatedStudent != undefined"
            >สถานศึกษา :
            <label v-if="schoolGraduatedStudent != undefined" class=""
              >{{ schoolGraduatedStudent.schoolid }}
              {{ schoolGraduatedStudent.schoolname }}</label
            >
          </label>
          <v-spacer></v-spacer>
          <label class="mr-4" v-if="schoolGraduatedStudent != undefined"
            >ปีที่สำเร็จการศึกษา :
            <label v-if="schoolGraduatedStudent != undefined" class="">{{
              schoolGraduatedStudent.graduatedyearth
            }}</label>
          </label>
          <label v-if="schoolGraduatedStudent != undefined" class="">{{
            schoolGraduatedStudent.schoolname
          }}</label>
        </v-col>
      </v-row>
    </v-card> -->
    <!-- <v-card rounded elevation="1" class="pa-2 mt-3">
      <div class="d-flex flex-row">
        <v-row class="d-flex">
          <v-col class="pt-5" xl="2" md="2" lg="2" sm="12" xs="12">
            <h4 class="mb-2 mb-0 ml-2 primary--text px-2">กรองข้อมูล :</h4>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-select
            label="ปีที่เข้าศึกษา"
            v-model="graduatedStudentRequest.entranceyear"
            :items="yearthlist"
            @change="loadGraduatedStudents()"
            solo
            clearable
          ></v-select>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-select
            label="ปีที่สำเร็จการศึกษา"
            v-model="graduatedStudentRequest.gradautedyearth"
            :items="yearthlist"
            @change="loadGraduatedStudents()"
            solo
            clearable
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-select
            label="สถานะการอัปเดตโปรไฟล์"
            clearable
            :items="studentUpdatedProfileStatus"
            item-text="name"
            item-value="value"
            v-model="graduatedStudentRequest.isprofileupdated"
            @change="loadGraduatedStudents()"
            solo
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-autocomplete
            label="ระดับชั้น"
            :items="certificateType"
            item-text="name"
            item-value="code"
            v-model="graduatedStudentRequest.gradenameth"
            clearable
            @change="loadGraduatedStudents()"
            class="select px-3 pr-5"
            solo
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-autocomplete
            :items="subjecttypes"
            v-model="graduatedStudentRequest.subjecttypenameth"
            label="ประเภทวิชา"
            item-text="subjecttypenameth"
            item-value="subjecttypeid"
            clearable
            @change="loadGraduatedStudents()"
            class="select px-3 pr-5"
            solo
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-autocomplete
            :items="majors"
            v-model="selectedMajorNameTh"
            label="สาขาวิชา"
            item-text="majornameth"
            item-value="majorcode"
            clearable
            @change="loadGraduatedStudents()"
            class="select px-3 pr-5"
            solo
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-autocomplete
            label="สาขางาน"
            :items="minor"
            item-text="minornameth"
            item-value="minorcode"
            v-model="selectedMinorNameTh"
            clearable
            @change="loadGraduatedStudents()"
            class="select px-3 pr-5"
            solo
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="3" class="mb-0 pb-0">
          <v-autocomplete
            label="เพศ"
            :items="genderlist"
            item-text="name"
            item-value="code"
            v-model="graduatedStudentRequest.gender"
            clearable
            @change="loadGraduatedStudents()"
            class="select px-3 pr-5"
            solo
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card> -->

    <!-- 
        <v-col cols="12" class="d-flex align-center">
          <label class="mr-7" v-if="schoolGraduatedStudent != undefined"
            >เทอม :
            <label class="">{{
              schoolGraduatedStudent.graduatedsemesters
            }}</label>
          </label>
        </v-col> -->
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentDataProvider } from "@/services/GraduatedStudentDataProvider";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import ImportGraduatedStudent from "@/components/GraduatedStudent/ImportGraduatedStudent.vue";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { MinorDataProvider } from "@/services/MinorDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { GraduatedStudents_Request } from "@/models/GraduatedStudent/GraduatedStudents_Request";

const commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

const graduatedStudentDataProvider = new GraduatedStudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const minorDataProvider = new MinorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "SchoolGraduatedStudentDetailView",
  props: [],
  data() {
    return {
      dialog: false,
      schoolOid: undefined,
      schoolGraduatedStudent: [],
      graduatedStudents: [],
      graduatedStudents_data_table_headers: [
        { text: "รหัสนักศึกษา", value: "oid" },
        { text: "ชื่อ", value: "firstname" },
        { text: "นามสกุล", value: "surname" },
        { text: "ระดับชั้นการศึกษา", value: "gradenameth" },
        { text: "ปีที่เข้าศึกษา", value: "entranceyear" },
        { text: "ปีที่สำเร็จการศึกษา", value: "graduatedyearth" },
        { text: "สถานะ", value: "studentstatusname" },
        { text: "ประเภทวิชา", value: "subjecttypenameth" },
        { text: "สาขา", value: "majornameth" },
        { text: "สาขางาน", value: "minornameth" },
        { text: "ตัวเลือก", value: "action", sortable: false },
      ],
      graduatedStudentRequest: new GraduatedStudents_Request(),
      itemlength: 0,
      keyword: "",
      pageNumber: 1,
      yearthlist: [],
      studentUpdatedProfileStatus: undefined,
      certificateType: undefined,
      subjecttypes: [],
      majors: undefined,
      minor: undefined,
      genderlist: [
        {
          name: "ชาย",
          code: "M",
        },
        {
          name: "หญิง",
          code: "F",
        },
      ],
      // certificateType: [
      //   {
      //     name: "ประกาศนียบัตรวิชาชีพ 1",
      //     code: "ประกาศนียบัตรวิชาชีพ 1",
      //   },
      //   {
      //     name: "ประกาศนียบัตรวิชาชีพ 2",
      //     code: "ประกาศนียบัตรวิชาชีพ 2",
      //   },
      //   {
      //     name: "ประกาศนียบัตรวิชาชีพ 3",
      //     code: "ประกาศนียบัตรวิชาชีพ 3",
      //   },
      //   {
      //     name: "ประกาศนียบัตรวิชาชีพชั้นสูง 1",
      //     code: "ประกาศนียบัตรวิชาชีพชั้นสูง 1",
      //   },
      //   {
      //     name: "ประกาศนียบัตรวิชาชีพชั้นสูง 2",
      //     code: "ประกาศนียบัตรวิชาชีพชั้นสูง 2",
      //   },
      //   {
      //     name: "ปริญญาตรีเทคโนโลยีบัณฑิต 1",
      //     code: "ปริญญาตรีเทคโนโลยีบัณฑิต 1",
      //   },
      //   {
      //     name: "ปริญญาตรีเทคโนโลยีบัณฑิต 2",
      //     code: "ปริญญาตรีเทคโนโลยีบัณฑิต 2",
      //   },
      //   {
      //     name: "ประกาศนียบัตรครูเทคนิคชั้นสูง 1",
      //     code: "ประกาศนียบัตรครูเทคนิคชั้นสูง 1",
      //   },
      //   {
      //     name: "ประกาศนียบัตรครูเทคนิคชั้นสูง 2",
      //     code: "ประกาศนียบัตรครูเทคนิคชั้นสูง 2",
      //   },
      // ],
      selectedSchool_oid: "",
      selectedEntranceyear: "",
      selectedGradautedyearth: "",
      selectedIsprofileupdated: "",
      selectedCertificationType: "",
      selectedMajorNameTh: "",
      selectedMinorNameTh: "",
      selectedSubjecttypeNameTh: "",
      selectedGender: "",
    };
  },

  methods: {
    tableoption: function (value) {
      if (value) {
        this.options = value;
      }
      this.options.SchoolID = this.schoolID;
      this.loadGraduatedStudents(this.schoolOid);
    },
    async loadSchoolGraduatedStudent(schoolOid = "") {
      await graduatedStudentDataProvider
        .loadSchoolGraduatedStudent(schoolOid)
        .then((result) => {
          this.schoolGraduatedStudent = result.data;
        })
        .catch((err) => {
          alert(err);
        });
    },

    async loadGraduatedStudents() {
      this.$store.dispatch("showLoading");
      this.graduatedStudentRequest.school_oid = this.schoolOid;
      this.graduatedStudentRequest.majornameth = this.selectedMajorNameTh;
      this.graduatedStudentRequest.minornameth = this.selectedMinorNameTh;
      this.graduatedStudentRequest.itemPerPage = 10;
      this.graduatedStudentRequest.certificatetype_code =
        this.selectedCertificationType;
      this.graduatedStudentRequest.search = this.keyword;
      this.graduatedStudentRequest.sortBy = [];
      this.graduatedStudentRequest.sortDesc = [];
      this.graduatedStudentRequest.page = this.pageNumber;
      this.graduatedStudentRequest.gender = this.selectedGender;
      this.graduatedStudentRequest.subjecttypenameth =
        this.selectedSubjecttypeNameTh;
      this.graduatedStudentRequest.entranceyear = this.selectedEntranceyear;
      this.graduatedStudentRequest.isprofileupdated =
        this.selectedIsprofileupdated;

      await majorDataProvider
        .getMajorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
          this.schoolGraduatedStudent?.schoolid,
          this.selectedSubjecttypeNameTh
        )
        .then((result) => {
          this.majors = result.data;
        });

      await masterDataProvider
        .getMinorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
          this.schoolGraduatedStudent?.schoolid,
          this.selectedMajorNameTh,
          this.selectedSubjecttypeNameTh
        )
        .then((result) => {
          this.minor = result.data;
        });

      await graduatedStudentDataProvider
        .loadGraduatedStudents(this.graduatedStudentRequest)
        .then((result) => {
          this.graduatedStudents = result.data.items;
          console.log(this.graduatedStudents);

          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },

    async openStudentDetailView(graduatedStudent) {
      var studentid = graduatedStudent.student_oid;

      const routeData = this.$router.resolve({
        name: "StudentDetailOverview",
        params: { studentoid: studentid },
      });
      window.open(routeData.href, "_blank");
    },
    onImportingCompleted() {
      this.dialog = false;
      this.loadGraduatedStudents(this.schoolOid);
    },
    async genyear() {
      await commonDataProvider.getListOfYearTH().then((result) => {
        this.yearthlist = result.data;
      });
    },
    async initialize() {
      await masterDataProvider
        .getStudentUpdateProfileStatuses()
        .then((result) => {
          this.studentUpdatedProfileStatus = result.data;
        });

      await masterDataProvider
        .GetCertificationTypeFromGraduatedStudentInVECEducationInformationBySchool(
          this.schoolGraduatedStudent?.schoolid,
          this.graduatedStudentRequest.gradautedyearth
        )
        .then((result) => {
          this.certificateType = result.data;
          console.log(this.certificateType, "CER");
        });

      await subjectTypeDataProvider
        .getSubjectTypesNameThFromVECEducationInformationBySchoolID(
          this.schoolGraduatedStudent?.schoolid
        )
        .then((result) => {
          this.subjecttypes = result.data;
        });
      await majorDataProvider
        .getMajorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
          this.schoolGraduatedStudent?.schoolid,
          this.selectedSubjecttypeNameTh
        )
        .then((result) => {
          this.majors = result.data;
        });

      await masterDataProvider
        .getMinorThNamesFromGraduatedStudentInVECEducationInformationBySchool(
          this.schoolGraduatedStudent?.schoolid,
          this.selectedMajorNameTh
        )
        .then((result) => {
          this.minor = result.data;
        });
    },
    pageChanged(pageChangeValue) {
      // if (this.pageNumber !== pageChangeValue) {
      //   this.pageNumber = pageChangeValue;
      //   this.loadGraduatedStudents();
      // }
      this.pageNumber = pageChangeValue;
      this.loadGraduatedStudents(this.schoolOid);
    },
    async onKeywordChanged(newKeyword) {
      this.keyword = newKeyword;
      this.pageNumber = 1;
      await this.loadSchoolGraduatedStudent(this.schoolOid);
      await this.loadGraduatedStudents(this.schoolOid);
    },
  },
  async created() {
    this.schoolOid = this.$route.params.schoolOid;
    if (this.$route.params.graduatedYearTH != undefined) {
      this.graduatedStudentRequest.gradautedyearth =
        this.$route.params.graduatedYearTH;
    }
    await this.genyear();
    await this.loadSchoolGraduatedStudent(this.schoolOid);
    await this.loadGraduatedStudents(this.schoolOid);
    await this.initialize();
  },
  components: {
    SearchableDataTable,
    ImportGraduatedStudent,
    SearchableDataTablePaging,
  },
};
</script>
<style scoped>
.auto-width-table .v-data-table__wrapper td {
  white-space: nowrap;
}

.auto-width-table .v-data-table__wrapper th {
  white-space: nowrap;
}
</style>
