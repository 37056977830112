<template>
  <v-container class="pa-5">
    <v-form class="elevation-1">
      <v-card>
        <v-toolbar class="color-HeaderCard" height="50px" flat dark>
          <v-toolbar-title
            ><v-icon class="mr-2"> mdi-currency-usd </v-icon
            >ระดับเงินเดือน</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <!-- <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="Order.order"
                label="รหัส"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="Order.salary_range"
                label="ช่วงเงินเดือน"
                reaonly
                background-color="input"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { MajorDataProvider } from "@/services/MajorDataProvider";
var majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "SalaryrangesDetailView",
  data() {
    return {
      Order: "",
      salaryrange: [],
    };
  },

  methods: {
    async loadSalaryranges(Order = "") {
      await majorDataProvider
        .loadSalaryranges(Order)
        .then((response) => {
          this.salaryrange = response.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },

  async created() {
    this.Order = this.$route.params.Order;
    // await this.loadSalaryranges(this.Order);
  },
};
</script>
<style></style>
