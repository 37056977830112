<template>
  <v-card class="mt-5">
    <v-data-table
      v-bind="$attrs"
      v-on="$listeners"
      :items="local_data_items"
      :headers="local_datatable_headers"
      multi-sort
      :loading="data_items == undefined"
      :group-by="datatable_groupby"
      loading-text="Loading"
      dense
      :disablePagination="is_disable_pagination"
    >
      <!-- pass through scoped slots -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData"></slot>
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName"></slot>
      </template>

      <template v-slot:top>
        <div class="pb-5 pt-3">
          <v-toolbar flat dense>
            <v-row>
              <v-col>
                <h3 class="primary--text">{{ local_table_caption }}</h3>
              </v-col>
            </v-row>
            <div class="mr-10"></div>
            <v-text-field
              v-model="search"
              @input="filterData"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              color="info"
            ></v-text-field>
          </v-toolbar>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "SearchableDataTable_Student",
  props: {
    datatable_headers: undefined,
    data_items: undefined,
    table_caption: undefined,
    datatable_groupby: undefined,
    is_disable_pagination: { type: Boolean },
  },
  data() {
    return {
      search: "",
      local_table_caption: this.table_caption,
      local_datatable_headers: this.datatable_headers,
      local_data_items: this.data_items,
    };
  },

  methods: {
    filterData() {
      const searchTerm = this.search.trim().toLowerCase();
      const searchTerms = searchTerm.split(" ");

      this.local_data_items = this.data_items?.filter((item) => {
        const fullName = `${
          item.student_veceducationinformation_studentcode
        } ${item.student_firstname.toLowerCase()} ${item.student_surname.toLowerCase()}  ${item.student_veceducationinformation_gradenameth.toLowerCase()} ${item.student_veceducationinformation_majornameth.toLowerCase()}`;
        const firstNameMatch = item.student_firstname
          .toLowerCase()
          .includes(searchTerm);
        const surnameMatch = item.student_surname
          .toLowerCase()
          .includes(searchTerm);
        const studentCodeMatch =
          item.student_veceducationinformation_studentcode.includes(searchTerm);
        const gradenamethMatch =
          item.student_veceducationinformation_gradenameth
            .toLowerCase()
            .includes(searchTerm);
        const majornameth = item.student_veceducationinformation_majornameth
          .toLowerCase()
          .includes(searchTerm);
        if (searchTerms.length === 1) {
          return (
            studentCodeMatch ||
            firstNameMatch ||
            surnameMatch ||
            gradenamethMatch ||
            majornameth
          );
        } else if (searchTerms.length > 1) {
          return searchTerms.every((term) => fullName.includes(term));
        }
      });
    },
  },
  watch: {
    data_items: {
      handler(newData) {
        this.local_data_items = newData;
        this.filterData();
      },
      immediate: true,
      deep: true,
    },
    search: "filterData",
  },
  mounted() {
    this.local_data_items = this.data_items;
    this.local_datatable_headers = this.datatable_headers;
    this.filterData();
  },
};
</script>

<style></style>
