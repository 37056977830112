<template>
  <v-container>
    <v-row justify="center mt-5">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="d-flex justify-center">รีเซ็ตรหัสผ่าน</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="verifyData" class="pa-3">
              <v-row>
                <v-text-field v-model="vm.Email" label="Email"
                  hint="กรอกอีเมลที่ได้ลงทะเบียนไว้กับระบบ หากข้อมูลถูกต้องระบบจะจัดส่งข้อมูลในการเข้าใช้งานทางอีเมล"
                  required type="email" persistent-hint></v-text-field>
              </v-row>
              <v-row class="d-flex justify-center">
                <p v-if="verificationResult === true" style="color: green;"><v-icon
                    style="color: green;">mdi-check-circle</v-icon> ผ่านการตรวจสอบข้อมูล</p>
                <p v-if="verificationResult === false" style="color: red;"><v-icon
                    style="color: red;">mdi-close-circle</v-icon> ไม่ผ่านการตรวจสอบ</p>
              </v-row>
              <v-row  class="d-flex justify-center mt-10 mb-2">
                <v-btn type="submit" color="primary" class="" :loading="isProcessing">
                  <v-icon>mdi-restart</v-icon>
                  รีเซ็ตรหัสผ่าน
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EmployerProfileDataProvider } from '@/services/EmployerProfileDataProvider';
const employerProfileDataProvider = new EmployerProfileDataProvider(process.env.VUE_APP_BACKEND_API_URL);
import { ResetPasswordRequest } from '@/models/Employer/ResetPasswordRequest';
export default {
  name: 'EmployerResetPassword',
  data() {
    return {
      isVerificationValid: false,
      verificationResult: '',
      isProcessing: false,
      vm: new ResetPasswordRequest(),
    };
  },
  methods: {
    async verifyData() {
      this.isProcessing = true;
      await employerProfileDataProvider.resetPasswordVerify(this.vm).then(result => {
        this.$toast.success('ทำการรีเซ็ตรหัสผ่านเรียบร้อยแล้ว กรุณาตรวจสอบข้อมูลตามที่อยู่อีเมลที่ให้ไว้เพื่อเข้าใช้งานระบบ');
        new Promise((resolve) => setTimeout(resolve, 2000));
        this.$router.push('LoginEmployer');
      }).catch(error => {
        this.$toast.error(error.response.data);
      })
      this.isProcessing = false;
      this.isVerificationValid = true;
    },
  },
};
</script>

<style scoped>/* Add your custom styles here */</style>
