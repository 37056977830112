import { createEndpointUrl } from "@/common/api_connectivity_helper";
import axios from "axios";
import { create_default_request_header } from "../constants";
import { CheckIsThisSchoolHavingReportNotSented_Request } from "@/models/GraduatedStudentJobTrackingReport/CheckIsThisSchoolHavingReportNotSented_Request";
import { GetNotSentJobTrackingReport_Request } from "@/models/GraduatedStudentJobTrackingReport/GetNotSentJobTrackingReport_Request";
export class GraduatedStudentJobTrackingReportReminderService {
  apiBaseURL = "";
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }

  checkIsThisSchoolHavingReportNotSented(
    request = new CheckIsThisSchoolHavingReportNotSented_Request()
  ) {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudentJobTrackingReportReminder/CheckIsThisSchoolHavingReportNotSented";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    var data = JSON.stringify(request);
    return axios.post(combinedURL, data, headers);
  }

  getNotSentJobTrackingReport(
    request = new GetNotSentJobTrackingReport_Request()
  ) {
    const headers = create_default_request_header();
    var endpointPath =
      "/api/GraduatedStudentJobTrackingReportReminder/GetNotSentJobTrackingReport";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
    var data = JSON.stringify(request);
    return axios.post(combinedURL, data, headers);
  }
}
