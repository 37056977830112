<template>
  <v-container class="pa-3 mt-5 elevation-4">
    <v-form>
      <v-autocomplete
        label="สถานศึกษา"
        :items="school_choices"
        item-text="schoolname"
        item-value="schoolid"
        v-model="selected_schoolID"
      >
      </v-autocomplete>
      <v-select label="ปีการศึกษา" :items="yearth_choices" v-model="selected_yearth">
      </v-select>
      <v-select
        label="เทอม"
        :items="semesters_choices"
        v-model="selected_semesters"
      ></v-select>
      <v-row class="justify-center pa-2">
        <v-btn @click="onSubmitButtonClick()">+ สร้าง</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GraduatedStudentDataProvider } from "@/services/GraduatedStudentDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { CreateSchoolGraduatedStudentRequest } from "@/models/GraduatedStudent/CreateSchoolGraduatedStudentRequest";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";

var schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
var graduatedStudentDataProvider = new GraduatedStudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
  name: "CreateSchoolGraduatedStudentView",
  props: [],
  data() {
    return {
      school_choices: undefined,
      selected_schoolID: "",
      yearth_choices: undefined,
      selected_yearth: "",
      semesters_choices: undefined,
      selected_semesters: "",
    };
  },
  methods: {
    async loadSchools() {
      const getSchools_Request = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(getSchools_Request).then((result) => {
        this.school_choices = result.data;
      });
    },

    async loadYearTHs() {
      await commonDataProvider.getListOfYearTH().then((result) => {
        this.yearth_choices = result.data;
      });
    },

    async loadSemesters() {
      await commonDataProvider.getListOfSemesters().then((result) => {
        this.semesters_choices = result.data;
      });
    },

    async onSubmitButtonClick() {
      let createSchoolGraduatedStudentRequest = new CreateSchoolGraduatedStudentRequest();
      createSchoolGraduatedStudentRequest.yearth = this.selected_yearth;
      createSchoolGraduatedStudentRequest.semesters = this.selected_semesters;
      createSchoolGraduatedStudentRequest.schoolid = this.selected_schoolID;
      await graduatedStudentDataProvider
        .createSchoolGraduatedStudent(createSchoolGraduatedStudentRequest)
        .then((result) => {
          this.goToSchoolGraduatedStudentListView();
        })
        .catch((err) => {
          alert(err);
        });
    },

    goToSchoolGraduatedStudentListView() {
      this.$router.back();
    },
  },
  async created() {
    await this.loadSchools();
    await this.loadYearTHs();
    await this.loadSemesters();
  },
};
</script>

<style></style>
