export class CreateCommonNotificationsRequest 
{
    Title  = ''
    Message = '' 
    IsNotifyByEmailNotification  = false
    IsNotifybyInAppNotification  = false
    IsMustSendToAllSchoolEmployers = false
    SchoolEmployeeProfileOids = [];
    IsMustSendToAllStudents = false
    StudentOids = [];
    IsMustSendToAllEmployerProfiles = false
    EmployerProfileOids = [];

    insertStudentOid(studentOid = "") {
        if (!this.StudentOids.includes(studentOid)){
            this.StudentOids.push(studentOid);
        }
    }

    insertEmployerProfilesOid(employerProfileOid = '') {
        if (!this.EmployerProfileOids.includes(employerProfileOid)){
            this.EmployerProfileOids.push(employerProfileOid);
        }
    }

    insertSchoolEmployerProfileOid(schoolEmployerProfileOid = '') {
        if (!this.SchoolEmployeeProfileOids.includes(schoolEmployerProfileOid)){
            this.SchoolEmployeeProfileOids.push(schoolEmployerProfileOid);
        }
    }

    static fromJson(jsonObject = '') {
        var request = new CreateCommonNotificationsRequest();
        request.Title = jsonObject.Title;
        request.Message =  jsonObject.Message;
        request.IsNotifyByEmailNotification = jsonObject.IsNotifyByEmailNotification;
        request.IsNotifybyInAppNotification = jsonObject.IsNotifybyInAppNotification;
        request.IsMustSendToAllSchoolEmployers = jsonObject.IsMustSendToAllSchoolEmployers;
        request.SchoolEmployeeProfileOids = jsonObject.SchoolEmployeeProfileOids;
        request.IsMustSendToAllStudents = jsonObject.IsMustSendToAllStudents;
        request.StudentOids = jsonObject.StudentOids;
        request.IsMustSendToAllEmployerProfiles = jsonObject.IsMustSendToAllEmployerProfiles;
        request.EmployerProfileOids = jsonObject.EmployerProfileOids;
        return request;
    }
}