<template>
  <div class="pa-5">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-card v-if="tableOptions" elevation="1" class="px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          class="custom-col mb-0 pb-0"
        >
          <h4 class="primary--text mb-2">ค้นหาชื่อตำแหน่งงาน</h4>
          <v-text-field
            clearable
            label="ชื่อตำแหน่ง"
            filled
            v-model="tableOptions.PositionName"
            background-color="input"
          ></v-text-field>
        </v-col>

        <!-- <v-col md="4" class="custom-col mb-0 pb-0">
          <h4 class="primary--text mb-2">ค้นหาสถานประกอบการ</h4>
          <v-text-field
            clearable
            label="ชื่อสถานประกอบการ"
            filled
            v-model="tableOptions.EmployerName"
            background-color="input"
          ></v-text-field>
          <h4 class="primary--text mb-2">ช่วงเงินเดือน</h4>
          <v-select
            label="ช่วงเงินเดือน"
            item-value="salary_range"
            item-text="salary_range"
            clearable
            filled
            background-color="input"
            :items="SalaryRanges"
            v-model="tableOptions.SalaryRanges"
          >
          </v-select>
        </v-col> -->
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          class="custom-col mb-0 pb-0"
        >
          <h4 class="primary--text mb-2">จังหวัด</h4>
          <v-autocomplete
            label="จังหวัด"
            :items="provinces"
            item-text="provincethai"
            item-value="provinceid"
            clearable
            background-color="input"
            filled
            append-icon="mdi-chevron-down"
            v-model="Selected_Provinces"
            @change="loadjobPosting()"
          >
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          md="5"
          sm="12"
          xs="12"
          class="custom-col mb-0 pb-0"
        >
          <h4 class="primary--text mb-2">สถานะ</h4>
          <v-select
            v-model="Selected_JobPostingStatusCode"
            label="สถานะ"
            item-text="statusname"
            item-value="statuscode"
            background-color="input"
            filled
            clearable
            :items="JobPostingStatuses"
            @change="loadjobPosting()"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          md="5"
          sm="12"
          xs="12"
          class="custom-col mb-0 pb-0"
        >
          <h4 class="primary--text mb-2">ประเภทการจ้างงาน</h4>
          <v-select
            v-model="Selected_employmentcategories"
            label="ประเภทการจ้างงาน"
            item-text="name"
            item-value="code"
            background-color="input"
            filled
            clearable
            :items="employmentcategories"
            @change="loadjobPosting()"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="12"
          xs="12"
          class="custom-col mb-0 pb-0"
        >
          <h4 class="primary--text mb-2">รับสมัครงานด่วน</h4>
          <!-- <v-checkbox
            class="ml-3 my-0 py-0"
            label="รับสมัครงานด่วน"
          ></v-checkbox> -->
          <v-row no-gutters align-content="end">
            <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="px-lg-1 px-md-1 py-lg-0 py-md-0 py-sm-1 mb-2"
            >
              <v-btn
              
                :color="selectedIsargent === true ? 'primary' : 'input'"
                @click="
                  selectedIsargent = !selectedIsargent;
                  loadjobPosting();
                "
                height="52px"
                width="100%"
              >
                <!-- <v-icon left v-if="selectedIsargent === true">
                  mdi-checkbox-marked </v-icon
                ><v-icon left v-else> mdi-checkbox-blank-outline </v-icon> -->
                รับสมัครงานด่วน
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <SearchableDataTablePaging
      :datatable_headers="jobPosting_data_table_headers"
      :data_items="jobPosting"
      :itemlength="itemlength"
      class="elevation-1"
      :search="keywords"
      table_caption="ประกาศงาน"
      @pageChanged="pageChanged"
      @onkeywordchanged="onKeywordChanged"
      @tableOptionsChanged="tableOptionsChanged"
    >
      <template v-slot:item.employerprofile.logo_photobase64="{ item }">
        <v-badge avatar inline color="#B00020" :value="item.isargent" overlap>
          <template v-slot:badge>
            <v-chip
              v-if="item.isargent"
              color="#B00020"
              style="color: white"
              small
            >
              รับสมัครด่วน
            </v-chip>
          </template>
          <v-avatar color="grey" size="50" class="my-2">
            <v-img
              :src="
                'data:image/jpg;base64,' + item.employerprofile.logo_photobase64
              "
            ></v-img>
          </v-avatar>
        </v-badge>
      </template>

      <template v-slot:item.start_end_dates="{ item }">
        <div>
          <FormatDate :date_value="item.startdate"></FormatDate>
          -</div>
          <FormatDate :date_value="item.enddate"></FormatDate>
        
      </template>

      <template v-slot:item.positionname="{ item }">
        <div>
          <span>{{ item.positionname }}</span>
        </div>
        (
        <span>{{ item.jobapplicationscount }}</span> /
        <span>{{ item.unit }}</span> )
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openCenterStaffJobPostingDetailView(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file</v-icon>
            </v-btn>
          </template>
          <span>ติดตามสถานะประกาศงาน</span>
        </v-tooltip>
        <v-tooltip top color="warning">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="goToSearchJobDetails(item)"
              color="warning"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file-eye</v-icon>
            </v-btn>
          </template>
          <span>ใบประกาศงาน</span>
        </v-tooltip>
      </template>
    </SearchableDataTablePaging>
  </div>
</template>

<script>
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import { JobPostingSearcherDataProvider } from "@/services/JobPostingSearcherDataProvider";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
import JobPostingStatus_Label from "@/components/JobPostings/JobPostingStatus_Label.vue";
import FormatDate from "@/components/common/FormatDate.vue";
import { CenterStaffJobPostingDataProvider } from "@/services/CenterStaffJobPostingDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";

const centerStaffJobPostingDataProvider = new CenterStaffJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const jobPostingSearcherDataProvider = new JobPostingSearcherDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: { SearchableDataTablePaging, JobPostingStatus_Label, FormatDate },
  name: "CenterStaffJobPostingListView",
  data() {
    return {
      jobPosting: undefined,
      jobPosting_data_table_headers: [
        {
          text: "",
          value: "employerprofile.logo_photobase64",
        },
        { text: "สถานประกอบการ", value: "employerprofile.nameth" },
        { text: "ตำแหน่ง (จำนวนผู้สมัคร)", value: "positionname" },

        {
          text: "จังหวัด",
          value: "workingplace_province.provincethai",
          align: "center",
        },
        {
          text: "ปรเภทการจ้างงาน",
          value: "employmentcategory.name",
          align: "center",
        },
        { text: "วันที่รับสมัคร", value: "start_end_dates" },
        { text: "สถานะ", value: "jobpostingstatus.statusname" },
        { text: "ตัวเลือก", value: "actions", width: "9%" , sortable: false},
      ],

      itemlength: 0,
      keywords: "",
      tableOptions: undefined,
      selectedStatus: null,
      SalaryRanges: [],
      JobPostingEntityStatuses: [],
      provinces: undefined,
      allProvinces: undefined,
      selectedProvince: undefined,
      Selected_Provinces: undefined,
      Selected_JobPostingStatusCode: undefined,
      Selected_employmentcategories: undefined,
      employmentcategories: undefined,
      selectedIsargent: "",
      JobPostingStatuses: undefined
    };
  },
  methods: {
    async loadSalaryRanges() {
      masterDataProvider.loadSalaryRanges().then((response) => {
        this.SalaryRanges = response.data;
        console.log(this.SalaryRanges);
      });
    },
    // async loadJobPostingEntityStatuses() {
    //   masterDataProvider.getJobPostingEntityStatuses().then((response) => {
    //     this.JobPostingEntityStatuses = response.data;
    //   });
    // },
    async loadProvince() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.allProvinces = result.data;
      });
      await centerStaffJobPostingDataProvider
        .getWorkingplace_provinceCenterStaff_GetJobPostings()
        .then((result) => {
          this.provinces = result.data;
        });
    },
    async loadEmploymentcategory() {
      await centerStaffJobPostingDataProvider
        .getEmploymentcategory()
        .then((result) => {
          this.employmentcategories = result.data;
          console.log(result);
        });
    },
    async loadJobPostingStatuses() {
      await centerStaffJobPostingDataProvider
        .getJobPostingStatus()
        .then((result) => {
          this.JobPostingStatuses = result.data;
          console.log(result);
        });
    },
    async loadjobPosting() {
      this.tableOptions.search = this.keywords;

      if (this.allProvinces && this.Selected_Provinces) {
        this.selectedProvince = this.allProvinces.find(
          (x) => x.provinceid == this.Selected_Provinces
        );

        if (this.selectedProvince) {
          this.tableOptions.workingPlace_Province =
            this.selectedProvince.provincethai;
          console.log(this.tableOptions.workingPlace_Province);
        }
      } else if (!this.Selected_Provinces) {
        this.tableOptions.workingPlace_Province = "";
      }
      this.tableOptions.Selected_employmentcategories =
        this.Selected_employmentcategories;
      this.tableOptions.Selected_JobPostingStatusCode =
        this.Selected_JobPostingStatusCode;

      if (this.selectedIsargent) {
        this.tableOptions.Selected_Isargent = this.selectedIsargent;
      } else {
        this.tableOptions.Selected_Isargent = false;
      }

      console.log(this.tableOptions);
      this.$store.dispatch("showLoading");
      await centerStaffJobPostingDataProvider
        .getJobPostins(this.tableOptions)
        .then((result) => {
          this.jobPosting = result.data.items;
          console.log(result.data);
          this.itemlength = result.data.quantity;
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          this.$store.dispatch("hideLoading");
        });
    },

    filterJobPostingsByStatus() {
      if (this.selectedStatus) {
        // กรองงานตำแหน่งตามสถานะที่เลือก
        this.jobPosting = this.jobPosting.filter(
          (posting) =>
            posting.jobpostingstatus.statusname === this.selectedStatus
        );
      } else {
        // หากไม่มีสถานะที่เลือกไว้ ให้โหลดงานตำแหน่งเริ่มต้น
        this.loadjobPosting();
      }
    },
    openCenterStaffJobPostingDetailView(item) {
      const oid = item.oid;
      this.$router.push({
        name: "CenterStaffJobPostingDetailView",
        params: { oid },
      });
    },
    goToSearchJobDetails: function (item) {
      const oid = item.oid;
      this.$router
        .push({ name: "JobPostingInfo", params: { oid: oid } })
        .catch((err) => {});
    },

    pageChanged(pageChangeValue) {
      if (this.pageNumber !== pageChangeValue) {
        this.pageNumber = pageChangeValue;
        // console.log("pageChanged:", pageChangeValue);
        this.loadjobPosting();
      }
    },
    async onKeywordChanged(newKeyword) {
      this.keywords = newKeyword;
      await this.loadjobPosting();
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
    },
  },
  watch: {
    tableOptions: {
      handler(newValue, oldValue) {
        this.loadjobPosting();
      },
      deep: true,
    },
    keywords(value) {
      this.vm.keywords = value;
      this.loadjobPosting();
    },
  },
  async created() {
    await this.loadSalaryRanges();
    await this.loadjobPosting();
    await this.loadProvince();
    // await this.loadJobPostingEntityStatuses();
    await this.loadEmploymentcategory();
    await this.loadJobPostingStatuses();
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
