<template>
  <div>
    <template>
      <v-btn
        :disabled="isOpenDialogDisabled"
        @click="editTrackingDate_Dialog = true"
        color="primary"
        class="mt-2 mr-3 color-buton"
      >
        <v-icon class="mr-2">mdi-pencil</v-icon> แก้ไขวันที่เปิดให้ติดตาม
      </v-btn>
    </template>
    <v-dialog v-model="editTrackingDate_Dialog" width="700">
      <template>
        <div>
          <v-card class="pa-10">
            <h2 class="primary--text pt-5">
              <v-icon class="primary--text">mdi-pencil</v-icon>
              แก้ไขวันที่ติดตาม
            </h2>
            <v-divider class="mt-4 mb-5 mx-0"></v-divider>
            <label class=""
              >สามารถแก้ไขวันที่เริ่มติดตาม หรือวันที่สิ้นสุดการติดตามของรอบการติดตามได้
              โดยการแก้ไขนี้จะมีผลกับระยะเวลาของการรายงานการติดตามของทุกสถานศึกษา</label
            >

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row class="mt-3">
                <v-col cols="6">
                  <TextFieldDatePicker
                    v-model="selected_trackingstartdate"
                    :rules="[(v) => !!v || 'กรุณาระบุวันที่เริ่มติดตาม']"
                    :max="selected_trackingenddate"
                    :value="selected_trackingstartdate"
                    caption="วันที่เริ่มติดตาม"
                    @closeAndUpdate="
                      this.closeAndUpdateTextFieldDatePicker_TrackingStartDate
                    "
                  >
                  </TextFieldDatePicker>
                </v-col>
                <v-col cols="6">
                  <TextFieldDatePicker
                    v-model="selected_trackingenddate"
                    :rules="[(v) => !!v || 'กรุณาระบุวันที่สิ้นสุดการติดตาม']"
                    :value="selected_trackingenddate"
                    :min="selected_trackingstartdate"
                    caption="วันที่สิ้นสุดการติดตาม"
                    @closeAndUpdate="
                      this.closeAndUpdateTextFieldDatePicker_TrackingEndDate
                    "
                  >
                  </TextFieldDatePicker>
                </v-col>
              </v-row>
              <v-row class="align-content-center text-center mt-3">
                <v-col>
                  <v-btn @click="onCancelClick" >ยกเลิก</v-btn>
                  <v-btn
                    :loading="isInCreatingProcess"
                    @click="confirm_dialog = true"
                    class="mx-5 color-button2"
                    dark
                  >
                    ยืนยัน <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-dialog v-model="confirm_dialog" width="400">
                <template>
                  <v-card class="pa-6">
                    <v-row class="justify-center text-center">
                      <v-card-title> ยืนยันการแก้วันที่เปิดให้ติดตาม ? </v-card-title>
                      <v-col cols="12">
                        <v-btn
                          @click="confirm_dialog = false"
                          class="ma-2"
                          elevation="2"
                          >ยกเลิก</v-btn
                        >
                        <v-btn @click="onSubmitButtonClick()" class="ma-2 color-button2">
                          ยืนยัน
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
              </v-dialog>
            </v-form>
          </v-card>
        </div>
      </template>
    </v-dialog>
  </div>
</template>

<script>
// import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";

// import { CommonDataProvider } from "@/services/CommonDataProvider";
import TextFieldDatePicker from "../common/TextFieldDatePicker.vue";
import { EditTrackingMainDate_Request } from "@/models/GraduatedStudentJobTrackingReport/EditTrackingMainDate_Request";
var jobTrackingDataProvider = new GraduatedStudentJobTrackingReportReportDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
// var commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  name: "EditTrackingDate",
  props: ["GraduatedStudentJobTrackingReportMain", "editTrackingDate_DialogStatus"],
  data() {
    return {
      GraduatedStudentJobTrackingReportMainOid: "",
      editTrackingDate_Dialog: false,
      selected_trackingstartdate: "",
      selected_trackingenddate: "",
      isInCreatingProcess: false,
      confirm_dialog: false,
      confirm_result: false,
      valid: true,
      startdate: "",
      enddate: "",
    };
  },
  computed: {
    isOpenDialogDisabled() {
      if (this.GraduatedStudentJobTrackingReportMain == undefined) {
        return true;
      }
      return (
        this.GraduatedStudentJobTrackingReportMain.jobtracking_status_statuscode ==
        "TrackingProcessIsCompleted"
      );
    },
  },
  methods: {
    closeAndUpdateTextFieldDatePicker_TrackingStartDate(selectedDate = "") {
      this.selected_trackingstartdate = selectedDate;
    },

    closeAndUpdateTextFieldDatePicker_TrackingEndDate(selectedDate = "") {
      this.selected_trackingenddate = selectedDate;
    },

    EditTrackingMainDate_Request() {
      var request = new EditTrackingMainDate_Request();
      request.jobtrackingmainreport_oid = this.GraduatedStudentJobTrackingReportMainOid;
      request.main_tracking_startdate = this.selected_trackingstartdate;
      request.main_tracking_enddate = this.selected_trackingenddate;
      return request;
    },

    onSubmitButtonClick() {
      if (this.$refs.form.validate()) {
        this.isInCreatingProcess = true;
        this.confirm_dialog = false;
        var request = this.EditTrackingMainDate_Request();
        jobTrackingDataProvider
          .EditTrackingMainDate(request)
          .then((response) => {
            if (response?.status == 200) {
              this.$toast.success("บันทึกข้อมูลเปลี่ยนแปลง เรียบร้อย");
              this.$emit(
                "closeEditTrackingDate_Dialog",
                this.selected_trackingstartdate,
                this.selected_trackingenddate,
                true
              );
              this.isInCreatingProcess = false;
              this.editTrackingDate_Dialog = false;
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    onCancelClick() {
      this.editTrackingDate_Dialog = false;
      this.$emit("close_Dialog", false);
    },
  },
  async created() {
    this.GraduatedStudentJobTrackingReportMainOid = this.GraduatedStudentJobTrackingReportMain.oid;
  },
  watch: {
    editTrackingDate_DialogStatus(newVal) {
      this.editTrackingDate_Dialog = newVal;
    },
    async editTrackingDate_Dialog(newVal, oldVal) {
      if (newVal) {
        await jobTrackingDataProvider
          .LoadGraduatedStudentJobTrackingReportMain(
            this.GraduatedStudentJobTrackingReportMainOid
          )
          .then((result) => {
            this.trackingReportMain = result.data;
            this.selected_trackingstartdate = result.data.trackingstartdate;
            this.selected_trackingenddate = result.data.trackingenddate;
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        this.component_dialog = false;
        this.$emit("close_Dialog", false);
      }
    },
  },

  components: { TextFieldDatePicker },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    270deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
