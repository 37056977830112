<template>
    <v-container class="">
        <v-row>
            <v-col>
                <h1>My Profile Page.</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi voluptatum aliquam soluta animi quos,
                    perferendis inventore unde obcaecati. Omnis porro architecto sunt repellendus dolores impedit,
                    nesciunt temporibus minima eum harum!</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ProfileView',
}
</script>

<style>
</style>