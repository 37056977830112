import axios from 'axios';
import { create_default_request_header } from './constants';
import { createEndpointUrl } from '@/common/api_connectivity_helper';
export class PasswordManagementDataProvider {
    constructor(apiBaseURL) {
        this.apiBaseURL = apiBaseURL;
    }

    GenerateTokenForSchoolEmployee(username) {
        var header = create_default_request_header();
        var endpointUrl = "/api/PasswordManagement/GenerateTokenForSchoolEmployee";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,{
            "username": username
        }, header  );
    }
    GenerateTokenForEmployee(username) {
        var header = create_default_request_header();
        var endpointUrl = "/api/PasswordManagement/GenerateTokenForEmployer";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,{
            "username": username
        }, header  );
    }

    GenerateTokenForStudent(username,schoolCode) {
        var header = create_default_request_header();
        var endpointUrl = "/api/PasswordManagement/GenerateTokenForStudent";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,{
            "username":username,
            "schoolCode":schoolCode
        }, header);
    }
    GenerateTokenForEmployer(username) {
        var header = create_default_request_header();
        var endpointUrl = "/api/PasswordManagement/GenerateTokenForEmployer";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.post(combinedURL,{
            "username": username
        }, header  );
    }

    validationToken(token) {
        const configHeader = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${ token }` } };
        var endpointUrl = "/api/PasswordManagement/validationToken";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
        return axios.get(combinedURL, configHeader);
    }

    resetpassword(account,token) {
        const configHeader = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${ token }` } };
        var endpointPath = "/api/PasswordManagement/resetpassword";
        var combinedURL = createEndpointUrl(this.apiBaseURL, endpointPath);
        return axios.post(combinedURL,{
            "password":account.newpassword
        }, configHeader);
    }
}