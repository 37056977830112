<template>
  <div class="pa-4">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <v-card rounded elevation="1" class="px-5 py-5">
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-row>
            <v-col lg="6" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">ภูมิภาค</h4>
              <v-select
                :label="selectRegion ? '' : 'ภูมิภาค'"
                :items="regionsNames"
                clearable
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="selectRegion"
                @change="onSelected"
              >
              </v-select>
            </v-col>
            <v-col lg="6" md="6" sm="12" xs="12" class="custom-col mb-0 pb-0">
              <h4 class="primary--text mb-2">จังหวัด</h4>
              <v-autocomplete
                :label="selectprovinceid ? '' : 'จังหวัด'"
                :items="provinces"
                item-text="provincethai"
                item-value="provinceid"
                clearable
                background-color="input"
                filled
                append-icon="mdi-chevron-down"
                v-model="selectprovinceid"
                @change="onSelected"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row> </v-card
    ><v-row class="mt-1">
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <div>
          <v-btn @click="onCreateBtnClick()" color="primary" class="my-2"
            ><v-icon class="mr-2">mdi-plus</v-icon>
            เพิ่มข้อมูลสถานประกอบการ</v-btn
          >
        </div>
        <!-- <div class="d-flex align-center justify-around">
          <v-file-input
            style="width: 300px"
            @change="handleFileChange"
            accept=".xlsx"
            label="เลือกไฟล์ Excel"
          ></v-file-input>
          <v-btn
            color="success"
            :loading="isUploading"
            @click="uploadFile"
            class="mb-2 ml-2"
            >อัปโหลดไฟล์</v-btn
          >
        </div> -->
      </v-col>
    </v-row>
    <!-- <v-row class="d-flex justify-end">
            <div class="col-2">
                <v-text-field v-model="keyword" label="ค้นหา" @keyup="onKeywordChanged"></v-text-field>
            </div>
        </v-row> -->
    <SearchableDataTablePaging
      table_caption="ข้อมูลตั้งต้นสถานประกอบการ/สถานศึกษา"
      :data_items="employerSummaries"
      :datatable_headers="employerSummaries_data_table_header"
      :itemlength="itemCount"
      :search="keyword"
      @pageChanged="pageChanged"
      @onkeywordchanged="onKeywordChanged"
      @tableOptionsChanged="tableOptionsChanged"
    >
      <template class="d‑flex" v-slot:item.action="{ item }">
        <v-tooltip top color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="onEditBtnClicked(item)"
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>แก้ไขข้อมูลสถานประกอบการ</span>
        </v-tooltip>
        <!-- <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="onDeleteBtnClicked(item)"
              color="error"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบข้อมูลสถานประกอบการ</span>
        </v-tooltip> -->
      </template>
    </SearchableDataTablePaging>
  </div>
</template>

<script>
import { EmployerSummaryDataProvider } from "@/services/EmployerSummaryDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import SearchableDataTablePaging from "@/components/common/SearchableDataTablePaging.vue";
import moment from "moment";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";

const employerSummaryDataProvider = new EmployerSummaryDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { SearchableDataTable, SearchableDataTablePaging },
  name: "EmployerSummaryListView",
  data() {
    return {
      moment: moment,
      selectedFile: null,
      isUploading: false,
      keyword: "",
      pageNumber: 1,
      itemsPerPage: 10,
      itemCount: 0,
      employerSummaries: undefined,
      employerSummaries_data_table_header: [
        { text: "เลขที่ผู้เสียภาษี", value: "identityid" },
        { text: "รายชื่อสถานประกอบการ", value: "nameth" },
        { text: "จังหวัด", value: "province_name" },
        { text: "ตัวเลือก", value: "action", sortable: false },
      ],
      tableOptions: undefined,
      regionsNames: undefined,
      provinces: undefined,
      selectRegion: "",
      selectprovinceid: "",
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.employerSummaries.length / this.itemsPerPage);
    },
  },
  methods: {
    async initialize() {
      await employerSummaryDataProvider
        .getEmployerSummaryPagination(
          this.keyword,
          this.pageNumber,
          this.itemsPerPage,
          this.selectRegion,
          this.selectprovinceid
        )
        .then((result) => {
          this.employerSummaries = result.data.items;
          this.itemsPerPage = result.data.length;
        });
      await employerSummaryDataProvider
        .getEmployerSummaryCount(this.keyword)
        .then((result) => {
          this.itemCount = result.data;
        });
      await commonDataProvider.getRegionAsVECNames().then((result) => {
        this.regionsNames = result.data;
      });
      if (!this.selectRegion) {
        await masterDataProvider.loadGetProvinces().then((result) => {
          this.provinces = result.data;
        });
      }
    },
    async onKeywordChanged(newKeyword) {
      this.keyword = newKeyword;
      this.pageNumber = 1;
      await this.initialize();
    },
    async onCreateBtnClick() {
      this.$router.push({ name: "CreateEmployerSummaryView" });
    },
    async onEditBtnClicked(item) {
      const oid = item.oid;
      this.$router.push({ name: "EditEmployerSummaryView", params: { oid } });
    },
    async onDeleteBtnClicked(item) {
      if (confirm("ยืนยัน")) {
        const oid = item.oid;
        await employerSummaryDataProvider
          .deleteEmployerSummary(oid)
          .then((result) => {
            this.$toast.success("ลบข้อมูลเรียบร้อย");
            this.initialize();
          });
      }
    },
    pageChanged(pageChangeValue) {
      this.pageNumber = pageChangeValue;
      this.initialize();
    },
    handleFileChange(file) {
      this.selectedFile = file;
    },
    async uploadFile() {
      if (!this.selectedFile) {
        alert("Please select an Excel file.");
        return;
      }
      this.isUploading = true;
      await employerSummaryDataProvider
        .uploadExcelFile(this.selectedFile)
        .then((result) => {
          if (result.data) {
            this.$toast.success("อัปโหลดข้อมูลเรียบร้อย");
            this.initialize();
          } else {
            this.$toast.error("File upload failed.");
          }
          this.isUploading = false;
        })
        .catch((error) => {
          this.isUploading = false;
          alert("An error occurred: " + error.message);
        });
      this.selectedFile = null;
    },
    tableOptionsChanged(newtableOptions) {
      this.tableOptions = newtableOptions;
      this.itemsPerPage = newtableOptions.itemsPerPage;
    },
    async onSelected() {
      if (this.selectRegion) {
        masterDataProvider
          .loadGetProvincesByRegionAsVECName(this.selectRegion)
          .then((result) => {
            this.provinces = result.data;
          });
      } else if (!this.selectRegion) {
        masterDataProvider.loadGetProvinces().then((result) => {
          this.provinces = result.data;
        });
      }

      this.initialize();
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style></style>
