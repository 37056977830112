import GraduatedStudentJobTrackingReportMainListView from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainListView";
import GraduatedStudentJobTrackingReportMainDetailView from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainDetailView";
import GraduatedStudentJobTrackingReportListView from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView.vue";
import GraduatedStudentTrackingReportListView_SchoolStaff from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_SchoolStaff.vue";
import GraduatedStudentJobTrackingReportListView_GuidanceTeacher_InProcess from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_GuidanceTeacher_InProcess.vue";
import GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified.vue";
import GraduatedStudentJobTrackingReportDetailView from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView.vue";
import GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher.vue";
import GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher_Verified from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher_Verified.vue";
import GraduatedStudentJobTrackingReportDetailView_ExcelEditor from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_ExcelEditor.vue";
import GraduatedStudentJobTrackingReportDetailView_CertificationAndSendReport from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_CertificationAndSendReport.vue";
import GraduatedStudentJobTrackingReportListView_ForCertification from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_ForCertification.vue";
import GraduatedStudentJobTrackingReportDetailView_ForCertification from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_ForCertification.vue";
import GraduatedStudentJobTrackingReportDetailView_Report from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_Report.vue";
import GraduatedStudentJobTrackingReportMainDetailView_Report from "@/views/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainDetailView_Report.vue";

export default [
  {
    path: "/GraduatedStudentJobTrackingReport",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainListView",
        name: "GraduatedStudentJobTrackingReportMainListView",
        component: GraduatedStudentJobTrackingReportMainListView,
        meta: {
          requiresAuth: true,
          nameTh: "สร้างรายงานการติดตาม",
        },
      },
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainDetailView/:oid",
        name: "GraduatedStudentJobTrackingReportMainDetailView",
        component: GraduatedStudentJobTrackingReportMainDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานการติดตามทั้งหมด",
        },
      },
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentTrackingReportListView_SchoolStaff/:schoolid",
        name: "GraduatedStudentTrackingReportListView_SchoolStaff",
        component: GraduatedStudentTrackingReportListView_SchoolStaff,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานติดตามภาวะการมีงานทำ",
        },
      },

      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_GuidanceTeacher_InProcess",
        name: "GraduatedStudentJobTrackingReportListView_GuidanceTeacher_InProcess",
        component:
          GraduatedStudentJobTrackingReportListView_GuidanceTeacher_InProcess,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานติดตามภาวะการมีงานทำที่กำลังดำเนินการ",
        },
      },

      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified",
        name: "GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified",
        component:
          GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานติดตามภาวะการมีงานทำที่รับรองแล้ว",
        },
      },

      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView",
        name: "GraduatedStudentJobTrackingReportListView",
        component: GraduatedStudentJobTrackingReportListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานการติดตามแต่ละสถานศึกษา",
        },
      },
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView/:trackingReportOid",
        name: "GraduatedStudentJobTrackingReportDetailView",
        component: GraduatedStudentJobTrackingReportDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "ติดตามภาวะการมีงานทำ",
        },
      },

      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher/:trackingReportOid",
        name: "GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher",
        component: GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher,
        meta: {
          requiresAuth: true,
          nameTh: "ติดตามภาวะการมีงานทำ",
        },
      },

      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher_Verified/:trackingReportOid",
        name: "GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher_Verified",
        component:
          GraduatedStudentJobTrackingReportDetailView_GuidanceTeacher_Verified,
        meta: {
          requiresAuth: true,
          nameTh: "ติดตามภาวะการมีงานทำ",
        },
      },

      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_ExcelEditor/:graduatedStudentJobTrackingReportOid",
        name: "GraduatedStudentJobTrackingReportDetailView_ExcelEditor",
        component: GraduatedStudentJobTrackingReportDetailView_ExcelEditor,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_CertificationAndSendReport/:graduatedStudentJobTrackingReportOid",
        name: "GraduatedStudentJobTrackingReportDetailView_CertificationAndSendReport",
        component:
          GraduatedStudentJobTrackingReportDetailView_CertificationAndSendReport,
        meta: {
          requiresAuth: true,
          nameTh: "รับรองรายงานการติดตาม",
        },
      },
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_ForCertification",
        name: "Graduated_StudentJobTrackingReportListView_ForCertification",
        component: GraduatedStudentJobTrackingReportListView_ForCertification,
        meta: {
          requiresAuth: true,
          nameTh: "รายงานการรับรองการติดตาม",
        },
      },
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_ForCertification/:oid",
        name: "GraduatedStudentJobTrackingReportDetailView_ForCertification",
        component: GraduatedStudentJobTrackingReportDetailView_ForCertification,
        meta: {
          requiresAuth: true,
          nameTh: "ตรวจสอบและรับรองรายงานติดตามภาวะการมีงานทำ",
        },
      },
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDetailView_Report/:oid",
        name: "GraduatedStudentJobTrackingReportDetailView_Report",
        component: GraduatedStudentJobTrackingReportDetailView_Report,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportMainDetailView_Report/:oid",
        name: "GraduatedStudentJobTrackingReportMainDetailView_Report",
        component: GraduatedStudentJobTrackingReportMainDetailView_Report,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
