<template>
  <nav>
    <ul>
      <li v-for="(crumb, index) in breadcrumbs" :key="index">
        <router-link :to="crumb.to" @click="navigate(crumb.to)">{{ crumb.label }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    breadcrumbs() {
      const matchedRoutes = this.$route.matched;
      return matchedRoutes.map(route => ({
        to: route.path,
        label: route.meta.breadcrumbLabel || route.name
      }));
    }
  },
  methods: {
    navigate(to) {
      this.$router.push(to);
    }
  }
};
</script>
