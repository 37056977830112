<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <label class="ql-editor">{{ notification.message }}</label>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" dark large @click="downloadReport()">
          <v-icon left> mdi-file-download </v-icon>
          ดาวน์โหลดรายงาน
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p style="color: red" class="text-danger body-2">
          รายงานจะถูกจัดเก็บในระบบเป็นระยะเวลา 3 ชั่วโมง
          หลังจากนั้นผู้ใช้ใช้จำเป็นต้องไปกดออกรายงานใหม่ที่เมนู "รายงาน"
          อีกครั้ง
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ReportServerProvider } from "@/services/ReportServerProvider";
import { create_default_request_header_for_blob_response } from "@/Constants";
const reportServerProvider = new ReportServerProvider(
  process.env.VUE_APP_REPORT_URL
);

export default {
  name: "GeneratedReportNotify",
  data() {
    return {
      message: "",
      extensionName: ".pdf",
      getReportRequest: undefined,
    };
  },
  props: ["notification"],
  methods: {
    getFileExtension(exportType) {
      switch (exportType) {
        case "application/pdf":
          return ".pdf";
        case "text/csv":
          return ".csv";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return ".xls";
        default:
          return "";
      }
    },
    async downloadReport() {
      try {
        console.log(this.getReportRequest);
        reportServerProvider
          .getReportFromCache(this.getReportRequest)
          .then((result) => {
            // Create a blob from the response data
            let fileExtension = this.getFileExtension(
              this.getReportRequest.ExportFileType
            );
            const blob = new Blob([result.data], { type: fileExtension });

            // Create a link element and simulate a click to trigger the download
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            const currentDate = new Date();
            const formattedDate =
              currentDate
                .toISOString()
                .slice(0, 19)
                .replace(/[-T]/g, "_")
                .replace(/:/g, "-") + "_";
            const fileName =
              this.getReportRequest.ReportName + formattedDate + fileExtension;

            link.download = fileName;
            this.message = fileName;
            link.click();

            // Release the object URL
            window.URL.revokeObjectURL(link.href);
          })
          .catch((error) => {
            if (error.response.status == "404") {
              alert(
                "ไม่พบรายงาน หรือ รายงานอาจหมดอายุแล้ว กรุณาดำเนินการออกรายงานใหม่ได้ที่เมนู รายงาน"
              );
            } else {
              alert(error);
            }
          });
      } catch (error) {
        // Log the error and retry if not on the last attempt
        this.message = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งภายหลัง";
      } finally {
      }
    },
  },
  mounted() {
    this.getReportRequest = JSON.parse(this.notification.hiddenmessage);
  },
};
</script>

<style></style>