<template>
  <div>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <v-img
      height="150px"
      contain
      v-bind:lazy-src="'data:image/jpg;base64,' + logo_photobase64"
      v-bind:src="'data:image/jpg;base64,' + logo_photobase64"
    >
    </v-img>
  </div>
</template>

<script>
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
const employerProfileDataProvider = new EmployerProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "LogoEmployer",
  props: ["oid"],
  data() {
    return {
      logo_photobase64: "",
    };
  },
  methods: {
    async loadlogoEmployer() {
      employerProfileDataProvider
        .loadLogoPhoto(this.oid)
        .then((result) => {
          this.logo_photobase64 = result.data;
        })
        .catch((err) => {});
    },
  },
  async mounted() {
    await this.loadlogoEmployer();
  },
};
</script>

<style></style>
