import ReligionsDetailView from "@/views/Religions/ReligionsDetailView.vue";
import ReligionsListView from "@/views/Religions/ReligionsListView.vue";

export default [
  {
    path: "/Religions",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/Religions/ReligionsListView",
        name: "ReligionsListView",
        component: ReligionsListView,
        meta: {
          requiresAuth: true,
          nameTh: "ศาสนา",
        },
      },
      {
        path: "/Religions/ReligionsDetailView/:oid",
        name: "ReligionsDetailView",
        component: ReligionsDetailView,
        meta: {
          requiresAuth: true,
          nameTh: "รายละเอียดศาสนา",
        },
      },
    ],
  },
];
