<template>
  <div class="pa-4">
    <v-card rounded elevation="1" class="pa-4">
      <div class="ma-5">
        <v-row>
          <v-col cols="6">
            <h3>รายชื่อบุคลากร: {{ school.schoolname }}</h3></v-col
          >
          <v-spacer></v-spacer>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              :loading="isImporting"
              outlined
              @click="importSchoolEmployees()"
              ><v-icon class="mr-2">mdi-import</v-icon
              >นำเข้าข้อมูลบุคลากร</v-btn
            ></v-col
          >
        </v-row>
        <SearchableDataTable
          table_caption="รายชื่อบุคลากร"
          :data_items="schoolEmployees"
          :datatable_headers="schoolEmployees_data_table_headers"
        >
        </SearchableDataTable>
      </div>
    </v-card>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { SchoolEmployeeDataProvider } from "@/services/SchoolEmployeeDataProvider";
import { StudentDataProvider } from "@/services/StudentDataProvider";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";

const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeDataProvider = new SchoolEmployeeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "SchoolEmployee_SchoolEmployeesView",
  components: { SearchableDataTable },
  data() {
    return {
      schoolID: "",
      school: [],
      isImporting: false,
      schoolEmployees: undefined,
      schoolEmployees_data_table_headers: [
        { text: "คำนำหน้า", value: "prefix" },
        { text: "ชื่อ", value: "firstname" },
        { text: "นามสกุล", value: "lastname" },
        { text: "เลขที่บัตรประจำตัวประชาชน", value: "idcard" },
        { text: "ประเภทบุคลากร", value: "schoolemployeetype.nameth" },
        { text: "", value: "" },
      ],
    };
  },
  methods: {
    async importSchoolEmployees() {
      this.isImporting = true;
      await schoolEmployeeDataProvider
        .importSchoolEmployees(this.schoolID)
        .then((result) => {
          this.loadSchoolEmployees();
        });
      this.isImporting = false;
    },
    async loadSchoolEmployees() {
      this.schoolEmployees = undefined;
      await schoolEmployeeDataProvider
        .loadSchoolEmployees(this.schoolID)
        .then((result) => {
          this.schoolEmployees = result.data;
        });
    },
    async loadSchool() {
      await schoolDataProvider.loadSchool(this.schoolID).then((result) => {
        this.school = result.data;
      });
    },
  },

  async created() {
    this.schoolID = this.$route.params.schoolID;
    await this.loadSchool();
    await this.loadSchoolEmployees();
  },
};
</script>

<style></style>
