import SchoolEmployee_SchoolListView from "@/views/SchoolEmployee/SchoolEmployee_SchoolListView";
import SchoolEmployee_SchoolEmployeesView from "@/views/SchoolEmployee/SchoolEmployee_SchoolEmployeesView";

export default [
  {
    path: "/SchoolEmployee",
    component: () => import("@/layouts/user/UserTemplate.vue"),
    children: [
      {
        path: "/SchoolEmployee/SchoolEmployee_SchoolEmployeesView/:schoolID",
        name: "SchoolEmployee_SchoolEmployeesView",
        component: SchoolEmployee_SchoolEmployeesView,
        meta: {
          requiresAuth: true,
          nameTh: "รายชื่อบุคลากร",
        },
      },
      {
        path: "/SchoolEmployee/SchoolEmployee_SchoolListView",
        name: "SchoolEmployee_SchoolListView",
        component: SchoolEmployee_SchoolListView,
        meta: {
          requiresAuth: true,
          nameTh: "รายชื่อสถานศึกษา",
        },
      },
    ],
  },
];
