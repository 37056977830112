<template>
  <div>
    <v-btn color="primary" @click="OngetRecurringJobDetailsClick"
      ><v-icon>mdi-refresh</v-icon>รีโหลดข้อมูล</v-btn
    >
    <SearchableDataTable
      v-if="RecurringJobDetails.length > 0"
      :datatable_headers="datatable_headers"
      :data_items="RecurringJobDetails"
    >
      <template v-slot:item.NextExecution="{ item }">
        <span>{{
          moment(item.NextExecution).add(543, "year").locale("TH").format("LL")
        }}</span>
      </template>
      <template v-slot:item.LastExecution="{ item }">
        <span>{{
          moment(item.LastExecution).add(543, "year").locale("TH").format("LL")
        }}</span>
      </template>
      <template v-slot:item.CreatedAt="{ item }">
        <span>{{
          moment(item.CreatedAt).add(543, "year").locale("TH").format("LL")
        }}</span>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
// import { importServiceHubConnection } from "@/services/SignalR/ImportServiceHub";
// var importServiceHubconnection = new importServiceHubConnection(
//   process.env.VUE_APP_BACKEND_API_URL + "/importServiceHub"
// );
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import moment from "moment";
export default {
  components: { SearchableDataTable },
  name: "RecurringJob",
  data() {
    return {
      moment: moment,
      datatable_headers: [
        { value: "Id", text: "ไอดี" },
        { value: "Cron", text: "เงื่อนไข" },
        { value: "NextExecution", text: "ทำงานครั้งถัดไป" },
        { value: "LastExecution", text: "ทำงานครั้งล่าสุด" },
        { value: "CreatedAt", text: "สร้างตอน" },
      ],
      RecurringJobDetails: [],
    };
  },
  async mounted() {
    // this.OngetRecurringJobDetailsClick();
  },
  methods: {
    // OngetRecurringJobDetailsClick() {
    //   const jobID = importServiceHubconnection
    //     .getRecurringJobDetails()
    //     .then((data) => {
    //       this.RecurringJobDetails = JSON.parse(data);
    //     })
    //     .catch((err) => {});
    // },
  },
};
</script>

<style></style>
