<template>
  <v-container class="pt-8 pa-4">
    <v-row class="justify-center">
      <v-col cols="8">
        <v-card class="elevation-3">
          <v-col class="pt-8 d-flex px-6">
            <v-icon class="primary--text pr-2">mdi-clipboard-text</v-icon>
            <h3 class="primary--text">ส่งรายงานการติดตาม</h3></v-col
          >
          <v-divider class="mx-4"></v-divider>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-col sm="12" md="12" lg="12">
                <strong>สถานศึกษา : </strong>
                {{ AssignCertify.schoolname }}

                <!-- <v-text-field
                  v-model="AssignCertify.schoolname"
                  label="สถานศึกษา"
                  readonly
                  :rules="[(v) => !!v || 'ไม่พบข้อมูล']"
                >
                </v-text-field> -->
              </v-col>
              <v-col class="d-flex">
                <strong>ปีการศึกษาจบที่ติดตาม :&nbsp; </strong>
                {{ AssignCertify.targetgraduatedyearth }}

                <!-- <v-text-field
                  class="pr-2"
                  v-model="AssignCertify.targetgraduatedyearth"
                  label="ปีการศึกษาจบที่ติดตาม"
                  readonly
                  :rules="[(v) => !!v || 'ไม่พบข้อมูล']"
                >
                </v-text-field> -->

                <!-- <v-text-field
                  class="pl-2"
                  v-model="AssignCertify.roundnumber"
                  label="รอบที่ติดตาม"
                  readonly
                  :rules="[(v) => !!v || 'ไม่พบข้อมูล']"
                >
                </v-text-field> -->
              </v-col>

              <v-col sm="12" md="12" lg="12">
                <strong>รอบที่ติดตาม : </strong>
                {{ AssignCertify.roundnumber }}
              </v-col>
              <v-col sm="12" md="12" lg="12">
                <v-autocomplete
                  v-model="AssignCertify.verifymanageroid"
                  :items="managers"
                  item-value="oid"
                  item-text="name"
                  label="เลือกผู้รับรองข้อมูล"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  hide-details
                  :rules="[(v) => !!v || 'กรูณาเลือกผู้รับรองข้อมูล']"
                  @change="onSelectedVerifymanager"
                  clearable
                  @input.native="course = $event.srcElement.value"
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    <strong>ชื่อ-นามสกุล</strong>&nbsp; {{ data.item.name }}
                    {{ data.item.surname }} &nbsp;<strong>ตำแหน่ง</strong>&nbsp;
                    {{ data.item.position }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    <strong>ชื่อ-นามสกุล</strong>&nbsp; {{ data.item.name }}
                    {{ data.item.surname }} &nbsp;<strong>ตำแหน่ง</strong>&nbsp;
                    {{ data.item.position }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="pt-5">
                <strong>ผู้ดำเนินการส่งรายงานฯ : </strong>
                <span v-if="!this.sender">{{
                  $store.getters["name"] + " "
                }}</span>
                <span v-if="!this.sender">{{ $store.getters["surname"] }}</span>
                <span v-if="this.sender">{{ " " + sender.name + " " }}</span>
                <span v-if="this.sender">{{ sender.lastname }}</span>

                <div class="pt-2" v-if="AssignCertify">
                  <strong> สถานะการนำเข้าข้อมูลนักศึกษา : </strong>

                  <v-icon :color="importstudentdata_status_dynamicColor">{{
                    importstudentdata_status_dynamicIcon
                  }}</v-icon>
                  {{ AssignCertify.importstudentdata_status_statusname }}

                  ({{
                    `${this.moment(
                      AssignCertify.importstudentdata_status_updateddate
                    )
                      .add(543, "year")
                      .locale("TH")
                      .format("ll เวลา HH:mm")} น.`.replace(
                      /(\d{2})(\d{2})/,
                      "$2"
                    )
                  }})
                </div>
                <div class="pt-2" v-if="AssignCertify">
                  <strong>จำนวนการติดตาม : </strong>
                  {{ AssignCertify.countstudentjobtracked }}
                  /
                  {{
                    AssignCertify.quantityofgraduatedstudentjobtrackingreport_studentdetails
                  }}
                </div>
              </v-col>

              <v-row justify="center" class="my-4">
                <v-btn class="back-button mx-2" @click="$router.go(-1)"
                  >ย้อนกลับ</v-btn
                >
                <v-btn
                  style="width: 190px"
                  class="color-button2 mx-2"
                  @click="assigncertify()"
                  color="success"
                  ><v-icon class="pr-2">mdi-check-circle-outline</v-icon
                  >ยืนยันการส่งรายงาน</v-btn
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <DialogCertificationConfirm
      v-if="selectedmanager"
      @closeDialog="onCertificationIsCompleted"
      :CertificationConfirm_Dialog="CertificationConfirm_Dialog"
      :verifymanager="selectedmanager"
      :sender="sender"
    ></DialogCertificationConfirm>
    <CustomSnackbar
      v-on:close-snackbar="closesnackbar"
      :color="colorsnack"
      :text="textsnack"
      :snackbar="statussnack"
    >
    </CustomSnackbar>
  </v-container>
</template>
<script>
import DialogCertificationConfirm from "@/components/GraduatedStudentJobTrackingReport/DialogCertificationConfirm.vue";
import { backendapiUrl } from "@/services/constants";
import { GraduatedStudentJobTrackingReportReportDataProvider } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import CustomSnackbar from "@/components/common/CustomSnackbar.vue";
import { VerifyManagerDataProvider } from "@/services/VerifyManagerDataProvider";
import FormatDateView from "@/components/common/FormatDateView.vue";
import moment from "moment";

const verifyManagerDataProvider = new VerifyManagerDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

var graduatedStudentJobTrackingReportReportDataProvider =
  new GraduatedStudentJobTrackingReportReportDataProvider(
    process.env.VUE_APP_BACKEND_API_URL
  );
var masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  components: { CustomSnackbar, DialogCertificationConfirm, FormatDateView },
  name: "GraduatedStudentJobTrackingReportDetailView_CertificationAndSendReport",
  data() {
    return {
      moment: moment,
      statussnack: false,
      colorsnack: "",
      textsnack: "",
      valid: true,
      AssignCertify: [],
      managers: [],
      CertificationConfirm_Dialog: false,
      sender: [],
      selectedmanager: [],
    };
  },
  methods: {
    onSelectedVerifymanager(item) {
      if (item) {
        this.selectedmanager = this.managers.find((x) => x.oid === item);
      } else {
        this.selectedmanager = [];
      }
    },
    async onCertificationIsCompleted(certifydate, sender, save) {
      if (save) {
        this.AssignCertify.profileoid = this.$store.getters["profileoid"];
        await graduatedStudentJobTrackingReportReportDataProvider
          .assignManagerProfile(this.AssignCertify)
          .then((result) => {
            this.sender = sender;
            this.textsnack = "ส่งรายงานเรียบร้อย";
            this.colorsnack = "success";
            this.statussnack = true;

            this.$router.push(
              "/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportListView_GuidanceTeacher_Verified"
            );
          })
          .catch((err) => {
            this.textsnack = err.response.data;
            this.colorsnack = "red";
            this.statussnack = true;
            // alert(err.message);
          });
      }
      this.CertificationConfirm_Dialog = false;
    },
    async assigncertify() {
      if (await this.$refs.form.validate()) {
        this.CertificationConfirm_Dialog = true;
      }
    },
    async loadGraduatedStudentJobTrackingReport(oid = "") {
      await graduatedStudentJobTrackingReportReportDataProvider
        .loadGraduatedStudentJobTrackingReport(oid)
        .then((result) => {
          this.AssignCertify = result.data;
          this.sender = result.data.sendertoconfirm;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async loadGetVerifyManagers() {
      await verifyManagerDataProvider
        .loadgetVerifyManagerList(this.AssignCertify.schoolid)
        .then((result) => {
          this.managers = result.data;
        })
        .catch((err) => {
          this.textsnack = "ไม่พบข้อมูลผู้ส่งที่เกี่ยวข้อง";
          this.colorsnack = "red";
          this.statussnack = true;
          alert(err);
        });
    },
    closesnackbar: function (value) {
      if (value) {
        this.statussnack = false;
      }
    },
  },
  computed: {
    importstudentdata_status_dynamicIcon() {
      if (this.AssignCertify == undefined) {
        return "";
      }
      switch (this.AssignCertify.importstudentdata_status_statuscode) {
        case "WaitingToStartImportingProcess":
          return "mdi-progress-clock";
        case "Imported":
          return "mdi-check-circle";
        case "NoGraduateStudent":
          return "mdi-account-alert";
        default:
          return "";
      }
    },
    importstudentdata_status_dynamicColor() {
      if (this.AssignCertify == undefined) {
        return "";
      }
      switch (this.AssignCertify.importstudentdata_status_statuscode) {
        case "WaitingToStartImportingProcess":
          return "info";
        case "Imported":
          return "success";
        case "NoGraduateStudent":
          return "warning";
        default:
          return "";
      }
    },
    jobtrackingreport_status_dynamicIcon() {
      if (this.AssignCertify == undefined) {
        return "";
      }
      switch (this.AssignCertify.jobtracking_status_statuscode) {
        case "NotStartedTrackingProcess":
          return "mdi-clock-edit";
        case "InProcessOfTracking":
          return "mdi-progress-pencil";
        case "Verified":
          return "mdi-check-decagram";
        case "NotInTime":
          return "mdi-timer-sand-complete";
        case "AllowedToEdit":
          return "mdi-folder-edit";
        default:
          return "";
      }
    },
    jobtrackingreport_status_dynamicColor() {
      if (this.AssignCertify == undefined) {
        return "";
      }
      switch (this.AssignCertify.jobtracking_status_statuscode) {
        case "NotStartedTrackingProcess":
          return "grey";
        case "InProcessOfTracking":
          return "info";
        case "Verified":
          return "success";
        case "NotInTime":
          return "error";
        case "AllowedToEdit":
          return "info";
        default:
          return "";
      }
    },
  },

  async created() {
    this.jobTrackingReportOid =
      this.$route.params.graduatedStudentJobTrackingReportOid;
    await this.loadGraduatedStudentJobTrackingReport(this.jobTrackingReportOid);
    await this.loadGetVerifyManagers();
  },
};
</script>
<style scoped></style>
