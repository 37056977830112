<template>
  <v-card rounded class="cols-12" v-if="IsShowThisCard">
    <v-system-bar color="blue darken-2" dark></v-system-bar>
    <v-card-title>
      <v-row v-if="jobtrackingReport">
        <v-col cols="12" class="text-center">
          <h3 class="">
            <v-icon>mdi-school</v-icon> สถานศึกษา {{ jobtrackingReport.schoolname }}
          </h3>
          <h4>
            ระยะเวลาในการส่งรายงานการติดตาม ปีที่จบการศึกษา
            {{ jobtrackingReport.targetgraduatedyearth }} รอบที่
            {{ jobtrackingReport.roundnumber }}
          </h4>
          <h4 class="">
            ตั้งแต่
            {{
              moment(jobtrackingReport.trackingstartdate)
                .add(543, "year")
                .locale("TH")
                .format("LL")
            }}
            ถึง
            {{
              moment(jobtrackingReport.trackingenddate)
                .add(543, "year")
                .locale("TH")
                .format("LL")
            }}
          </h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="IsCheckNotSend && this.timeleft > 0">
      <v-row>
        <v-col cols="12" class="text-center">
          <h3><v-icon>mdi-timer-sand</v-icon>เหลือเวลาอีก</h3>
        </v-col>
        <v-col cols="12" class="text-center">
          <h1 class="redText">
            {{ days }} วัน {{ hours }} ชั่วโมง {{ minutes }} นาที {{ seconds }} วินาที
          </h1>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="text-center" v-if="IsCheckNotSend && this.timeleft == 0">
      <h1 class="redText">
        <v-icon color="red">mdi-alert-outline</v-icon> หมดเวลาส่งรายงานการติดตามแล้ว !
      </h1>
    </v-card-text>
    <v-card-text class="text-center" v-if="!IsCheckNotSend && this.timeleft > 0">
      <h1 style="color: green">
        <v-icon color="success">mdi-check-circle-outline</v-icon>
        ส่งรายงานเรียบร้อย
      </h1>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
import { GraduatedStudentJobTrackingReportReminderService } from "@/services/GraduatedStudentJobTrackingReport/GraduatedStudentJobTrackingReportReminderService";
var graduatedStudentJobTrackingReportReminderService = new GraduatedStudentJobTrackingReportReminderService(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  data() {
    return {
      moment: moment,
      dateOptions: { year: "numeric", month: "numeric", day: "numeric" },
      countDown: 10,

      countDownDate: "",
      now: 0,
      timeleft: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,

      jobtrackingReport: [],
      IsCheckNotSend: false,
      IsShowThisCard: true,
      IsSchool: false,
    };
  },
  methods: {
    countDownRemainingDate(reportendtime) {
      this.countDownDate = new Date(reportendtime);
      this.now = new Date();

      if (this.now < this.countDownDate) {
        setTimeout(() => {
          this.timeleft = this.countDownDate - this.now;
          this.days = Math.floor(this.timeleft / (1000 * 60 * 60 * 24));
          this.hours = Math.floor(
            (this.timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          this.minutes = Math.floor((this.timeleft % (1000 * 60 * 60)) / (1000 * 60));
          this.seconds = Math.floor((this.timeleft % (1000 * 60)) / 1000);
          this.countDownRemainingDate(reportendtime);
        }, 1000);
      }
    },
  },
  created() {
    this.countDownRemainingDate();
  },
  async mounted() {
    if(!IsUserAdministrator())
    {
    await graduatedStudentJobTrackingReportReminderService
      .checkIsThisSchoolHavingReportNotSented()
      .then((response) => {
        this.IsCheckNotSend = response.data.checknotsend;
        this.IsSchool = response.data.isschool;
        if (response.data.isschool) {
          graduatedStudentJobTrackingReportReminderService
            .getNotSentJobTrackingReport()
            .then((response) => {
              this.jobtrackingReport = response.data;
              this.countDownRemainingDate(this.jobtrackingReport.trackingenddate);
            });
        }
      })
      .catch((response) => {
        this.IsShowThisCard = false;
      });
    }
    else{
      this.IsShowThisCard = false;
    }
  },
};
</script>

<style></style>
