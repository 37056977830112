import axios from "axios";
import { create_default_request_header } from "../constants";
import { createEndpointUrl } from "@/common/api_connectivity_helper";

export class PostLookingForJobDataProvider {
  constructor(apiBaseURL) {
    this.apiBaseURL = apiBaseURL;
  }
  
  loadPostLookingForJobStatus(student_oid = "") {
    var header = create_default_request_header();
    var endpointUrl = "/api/PostLookingForJob/LoadPostLookingForJobStatus/"+ student_oid;
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.get(combinedURL, header);
  }

  enablePostStudentDetail(student_oid = "") {
    var header = create_default_request_header();
    var endpointUrl = "/api/PostLookingForJob/EnablePostStudentDetail";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, {Student_Oid:student_oid}, header);
  }

  disablePostStudentDetail(student_oid = "") {
    var header = create_default_request_header();
    var endpointUrl = "/api/PostLookingForJob/DisablePostStudentDetail";
    var combinedURL = createEndpointUrl(this.apiBaseURL, endpointUrl);
    return axios.post(combinedURL, {Student_Oid:student_oid}, header);
  }
}
