<template>
  <div class="pa-4 mt-4 d-flex justify-center">
    <v-row class="d-flex justify-center pt-5">
      <v-col cols="12">
        <v-card class="px-5 py-10" style="border-radius: 10px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-3 px-2">
              <v-icon class="pr-2">mdi-chart-areaspline-variant</v-icon>
              <h4>รายงานรายชื่อนักศึกษา</h4>
            </v-row>
            <v-row class="px-3 py-1" style="margin-top: 1%; margin-bottom: 2%">
              <v-divider></v-divider>
            </v-row>

            <v-row class="pb-6 pl-3">
              <span style="font-weight: bold">เกณฑ์การค้นหา</span>
            </v-row>
            <v-row>
              <v-col cols="12" class="custom-col mb-0 pb-0">
                <span class="primary--text mb-2" style="font-weight: bold"
                  >สถานศึกษา<span style="color: red">*</span></span
                >
                <v-autocomplete
                  placeholder="กรุณาเลือกสถานศึกษา"
                  v-model="selected_schoolid"
                  @change="onSelectedSchoolChanged()"
                  item-text="schoolname"
                  item-value="schoolid"
                  :items="schools"
                  :rules="[(v) => !!v || 'กรุณาเลือกสถานศึกษา']"
                  background-color="input"
                  filled
                  append-icon="mdi-chevron-down"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="py-0">
                  <v-col
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                    class="custom-col mb-0 py-0"
                  >
                    <span class="primary--text py-0" style="font-weight: bold"
                      >ระดับการศึกษา</span
                    >
                    <v-autocomplete
                      class="d-flex align-center"
                      :items="certificateTypes"
                      placeholder="กรุณาระบุระดับการศึกษา"
                      v-model="selectedCertificateTypeCode"
                      @change="onSelectedSchoolChanged()"
                      item-text="nameth"
                      item-value="code"
                      background-color="input"
                      filled
                      append-icon="mdi-chevron-down"
                      clearable
                      dense
                      persistent-hint
                      :disabled="selected_schoolid ? false : true"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    lg="8"
                    md="8"
                    sm="12"
                    xs="12"
                    class="custom-col mb-0 py-0"
                  >
                    <v-row>
                      <v-col class="pb-0">
                        <span
                          class="primary--text py-0"
                          style="font-weight: bold"
                          >ประเภทวิชา</span
                        >
                        <v-autocomplete
                          :items="subjecttypes"
                          placeholder="กรุณาระบุประเภทวิชา"
                          v-model="selected_subjecttypenameth"
                          item-value="subjecttypenameth"
                          item-text="subjecttypenameth"
                          background-color="input"
                          filled
                          @change="onSelectedSchoolChanged()"
                          append-icon="mdi-chevron-down"
                          clearable
                          dense
                          persistent-hint
                          :disabled="selected_schoolid ? false : true"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0 my-0">
                        <span
                          class="primary--text py-0"
                          style="font-weight: bold"
                          >สาขาวิชา</span
                        >
                        <v-autocomplete
                          :items="majors"
                          placeholder="กรุณาระบุสาขาวิชา"
                          v-model="selected_majorNameTH"
                          item-value="majornameth"
                          item-text="majornameth"
                          @change="onSelectedSchoolChanged()"
                          background-color="input"
                          filled
                          append-icon="mdi-chevron-down"
                          clearable
                          dense
                          persistent-hint
                          :disabled="selected_subjecttypenameth ? false : true"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="6"
                md="12"
                sm="12"
                xs="12"
                class="custom-col px-5 py-6"
              >
                <v-row class="pl-1">
                  <span class="primary--text font-weight-bold"
                    >สถานะนักศึกษา</span
                  >
                </v-row>
                <v-row>
                  <v-col class="px-0 py-0">
                    <v-btn
                      v-for="status in studentstatuslist"
                      :key="status.value"
                      class="mx-1 my-1"
                      :color="
                        selected_studentstatuscode === status.value
                          ? 'primary'
                          : 'input'
                      "
                      @click="OnstudentStatusesChange(status.value)"
                      :block="$vuetify.breakpoint.smAndDown"
                      @change="onSelectedSchoolChanged()"
                      :disabled="selected_schoolid ? false : true"
                    >
                      {{ status.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                lg="6"
                md="12"
                sm="12"
                xs="12"
                class="custom-col px-5 py-6"
              >
                <v-row class="pl-1">
                  <span class="primary--text font-weight-bold"
                    >สถานะการอัปเดตโปรไฟล์</span
                  >
                </v-row>
                <v-row>
                  <v-col class="px-0 py-0">
                    <v-btn
                      v-for="item in studentUpdateprofilelist"
                      :key="item.value"
                      :color="
                        selected_studentupdateprofilevalue === item.value
                          ? 'primary'
                          : 'input'
                      "
                      @click="OnStudentupdateprofileChange(item.value)"
                      class="mx-1 my-1"
                      :block="$vuetify.breakpoint.smAndDown"
                    >
                      {{ item.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  color=""
                  @click="$router.go(-1)"
                  class="justify-center mr-3"
                  style="border-radius: 10px"
                >
                  ย้อนกลับ</v-btn
                >
                <v-btn
                  dark
                  class="color-btnCard"
                  style="border-radius: 10px"
                  v-on:click="create_reportParameters()"
                  >แสดงรายงาน<v-icon>mdi-logout</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="document_viewer_dialog" width="50%">
      <template>
        <v-card height="auto" class="px-10 py-10">
          <ReportExportFromQueue
            :report_name="report_name"
            :report_parameters="report_parameters"
            @close_Dialog="document_viewer_dialog = false"
          >
          </ReportExportFromQueue>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { backendapiUrl } from "@/services/constants";
import DocumentViewer from "../../components/Report/DocumentViewer.vue";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { SubjectTypeDataProvider } from "@/services/SubjectTypeDataProvider";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { MajorDataProvider } from "@/services/MajorDataProvider";
import { SchoolEmployeeProfileDataProvider } from "@/services/Profile/SchoolEmployeeProfileDataProvider";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";

import ReportExport from "@/components/Report/ReportExport.vue";
import ReportExportFromQueue from "@/components/Report/ReportExportFromQueue.vue";

var commonDataProvider = new CommonDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var subjectTypeDataProvider = new SubjectTypeDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolEmployeeProfileDataProvider = new SchoolEmployeeProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var majorDataProvider = new MajorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  name: "SchoolStudentList_Report",
  props: [],
  data() {
    return {
      document_viewer_key: 1,
      document_viewer_dialog: false,
      report_name: "SchoolStudentList_Report",
      report_parameters: [],
      yearTH_Choices: [],
      selected_schoolid: undefined,
      selectedYearTH: undefined,
      certificateTypes: undefined,
      selectedCertificateTypeCode: "",
      regionsNames: undefined,
      studentStatuses: undefined,
      studentupdateprofilestatuses: undefined,
      subjecttypes: undefined,
      majors: undefined,
      studentstatuslist: [
        { text: "ทั้งหมด", value: "" },
        { text: "สำเร็จการศึกษา", value: "09" },
        { text: "อยู่ระหว่างศึกษา", value: "03" },
      ],
      selected_studentstatuscode: "",
      studentUpdateprofilelist: [
        { text: "ทั้งหมด", value: "" },
        { text: "อัปเดตแล้ว", value: "true" },
        { text: "ยังไม่มีการอัปเดต", value: "false" },
      ],
      selected_studentupdateprofilevalue: "",
      provinces: undefined,
      getSchools_Reqeust: new GetSchools_Reqeust(),
      schools: undefined,
      currentPermissionSchoolIDs: [],
      regionAsVEC: undefined,
      valid: true,
      Round_Choices: [],
      selectedRound: undefined,
      selected_subjecttypenameth: undefined,
      selected_majorNameTH: undefined,

      selectedRegion: undefined,
      selectedProvince: undefined,
      schoolID: undefined,
      selectedMajor: [],
    };
  },
  computed: {
    filtered_schools() {
      if (this.schools != undefined) {
        return this.schools.filter((x) =>
          this.currentPermissionSchoolIDs.includes(x.schoolid)
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    async onSelectedSchoolChanged() {
      await masterDataProvider
        .getCertificateTypesByVECEducationInformationsFromSchoolId(
          this.selected_schoolid
        )
        .then((result) => {
          this.certificateTypes = result.data;
        });

      if (this.selected_schoolid && this.selected_studentstatuscode === "09") {
        await subjectTypeDataProvider
          .getSubjectTypesNameThFromVECEducationInformationBySchoolID(
            this.selected_schoolid
          )
          .then((result) => {
            this.subjecttypes = result.data;
          });
      } else {
        await subjectTypeDataProvider
          .getSubjectTypesFromVECEducationInformationBySchoolID(
            this.selected_schoolid
          )
          .then((result) => {
            this.subjecttypes = result.data;
          });
      }

      if (this.selected_subjecttypenameth) {
        await masterDataProvider
          .getMajorsBySubjectTypeNameThBySchoolID(
            this.selected_schoolid,
            this.selected_subjecttypenameth
          )
          .then((result) => {
            this.majors = result.data;
          });
      } else {
        await majorDataProvider
          .getMajorsFromVECEducationInformationBySchoolID(
            this.selected_schoolid
          )
          .then((result) => {
            this.majors = result.data;
          });
      }
    },

    async initialize() {
      await schoolDataProvider
        .loadSchools(this.getSchools_Reqeust)
        .then((result) => {
          this.schools = result.data;
        });

      await commonDataProvider.getStudentStatuses().then((result) => {
        this.studentStatuses = result.data;
      });

      await masterDataProvider
        .getStudentUpdateProfileStatuses()
        .then((result) => {
          this.studentupdateprofilestatuses = result.data;
        });

      await schoolEmployeeProfileDataProvider
        .getCurrentPermissionSchoolIDs()
        .then((result) => {
          this.currentPermissionSchoolIDs = result.data;
          if (this.currentPermissionSchoolIDs.length > 0) {
            this.selected_schoolid = this.currentPermissionSchoolIDs[0];
          }
        });
    },
    create_reportParameters() {
      if (this.$refs.form.validate()) {
        this.document_viewer_key += 1;
        this.report_parameters = [];
        if (this.selected_schoolid != undefined) {
          this.report_parameters.push("prm_SchoolID=" + this.selected_schoolid);
        }
        if (this.selectedCertificateTypeCode != undefined) {
          this.report_parameters.push(
            "prm_CertificateTypeCode=" + this.selectedCertificateTypeCode
          );
        }
        if (this.selected_studentupdateprofilevalue != "") {
          this.report_parameters.push(
            "prm_IsProfileUpdated=" + this.selected_studentupdateprofilevalue
          );
        }
        if (this.selected_subjecttypenameth != undefined) {
          this.report_parameters.push(
            "prm_SubjectTypeNameTh=" + this.selected_subjecttypenameth
          );
        }
        if (this.selected_majorNameTH != undefined) {
          this.report_parameters.push(
            "prm_MajorNameTh=" + this.selected_majorNameTH
          );
        }
        if (this.selected_studentstatuscode) {
          this.report_parameters.push(
            "prm_StudentStatusCode=" + this.selected_studentstatuscode
          );
        }

        this.document_viewer_dialog = true;
        console.log(this.report_parameters, "   this.report_parameters ");
      }
    },

    OnstudentStatusesChange(value) {
      this.selected_studentstatuscode = value;
      this.onSelectedSchoolChanged();
    },
    OnStudentupdateprofileChange(value) {
      this.selected_studentupdateprofilevalue = value;
    },
  },

  async created() {
    await this.initialize();
  },
  components: { DocumentViewer, ReportExport, ReportExportFromQueue },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.color-btnCard {
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 60%,
    rgba(63, 81, 181, 1) 100%
  );
}
</style>
