<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center">
        <v-col lg="11" md="12" sm="12" xs="12">
          <v-card  v-if="job && job.employerprofile">
              <v-toolbar class="color-HeaderCard" dark>
                <v-toolbar-title
                  ><v-icon class="mr-2"> mdi-account-circle </v-icon
                  >ข้อมูลสถานประกอบการ</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text class="pa-12" id="element-to-convert">
                <v-row class="mb-2">
                  <v-col cols="11">
                    <v-row>
                      <v-col cols="auto">
                        <div class="rounded-circle display-center">
                          <v-img
                            :src="'data:image/jpg;base64,' + logo_photobase64"
                            aspect-ratio="1"
                            contain
                          ></v-img>
                        </div>
                      </v-col>
                      <v-col cols="auto">
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                          "
                        >
                          <v-row style="padding: 8px">
                            <div class="mr-3">{{ job.employerprofile.nameth }}</div>
                            <DBDStatusDisplay :employer-profile="job.employerprofile" v-if="job.employerprofile.dbd_organizationjuristicstatus" :status="job.employerprofile.dbd_organizationjuristicstatus" ></DBDStatusDisplay>
                          </v-row>
                          <v-row>
                            <div class="display-center" style="font-style: italic">
                              <v-icon>mdi-map-marker</v-icon>
                              <label>{{
                                job.employerprofile.district.province.provincethai
                              }}</label>
                            </div>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="1" class="d-flex justify-end pa-0 off-print">
                    <v-btn
                      class="ml-2 equal-height-btn display-center"
                      fab
                      outlined
                      tile
                      style="border-radius: 10px"
                      ><v-icon @click="print">mdi-printer</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="12" sm="6" md="4" lg="4" xl="4">
                    <v-row>ประเภทสถานประกอบการ : </v-row>
                    <v-row style="font-weight: bold; color: #3f51b5">{{
                      job.employerprofile.employerprofiletype.nameth
                    }}</v-row>
                  </v-col>
                  <v-col xs="12" sm="6" md="4" lg="4" xl="4">
                    <v-row>หมายเลขบัตรประชาชน / นิติบุคคล : </v-row>
                    <v-row style="font-weight: bold; color: #3f51b5">{{
                      job.employerprofile.identityid
                    }}</v-row>
                  </v-col>
                  <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-row>email : </v-row>
                    <v-row style="font-weight: bold; color: #3f51b5">{{
                      job.coordinator_email
                    }}</v-row>
                  </v-col>
                </v-row>

                <v-row style="padding-top: 3%">
                  <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <v-row>ประเภทวิสาหกิจ : </v-row>
                    <v-row class="text-container">
                      <div class="" style="font-weight: bold; color: #3f51b5">
                        {{ job.employerprofile.employermainbusinesstype.nameth }}
                      </div>
                      <div class="tooltip">
                        {{ job.employerprofile.employermainbusinesstype.nameth }}
                      </div>
                    </v-row>
                  </v-col>
                  <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <v-row>ประเภทธุรกิจ : </v-row>
                    <v-row class="text-container" v-if="job.employerprofile.businesstype">
                      <div class="" style="font-weight: bold; color: #3f51b5">
                        {{ job.employerprofile.businesstype.nameth }}
                      </div>
                      <div class="tooltip">
                        {{ job.employerprofile.businesstype.nameth }}
                      </div>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row style="padding-top: 5%">
                  <h3 style="font-weight: bold; color: #3f51b5">
                    <v-icon color="#3f51b5" class="mr-1">mdi-account-box</v-icon
                    >ข้อมูลทั่วไป
                  </h3>
                </v-row>
                <v-row style="margin-top: 2%; margin-bottom: 4%">
                  <v-divider></v-divider>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row><h4 style="color: #3f51b5">รายละเอียดสถานประกอบการ</h4></v-row>
                    <v-row
                      ><label style="margin-top: 10px">{{
                        job.employerprofile.description
                      }}</label></v-row
                    >
                  </v-col>
                </v-row>
                <v-row style="padding-top: 4%">
                  <v-col cols="12">
                    <v-row><h4 style="color: #3f51b5">สวัสดิการ</h4></v-row>
                    <v-row
                      ><label class="ql-editor"
                        >{{ job.employerprofile.benefitdescription }}
                      </label></v-row
                    >
                  </v-col>
                </v-row>
                <v-row style="margin-top: 4%; margin-bottom: 4%">
                  <v-divider></v-divider>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <h4 style="color: #3f51b5">ติดต่อ</h4>
                    </v-row>

                    <v-row class="d-flex flex-column" style="padding-left: 0px">
                      <label style="padding-top: 8px">{{
                        job.employerprofile.nameth
                      }}</label>
                    </v-row>
                    <v-row class="d-flex flex-column" style="padding-left: 0px">
                      <label>{{ job.coordinator_phoneno }}</label>
                    </v-row>
                    <v-row class="d-flex flex-column" style="padding-left: 0px">
                      <label>{{ job.coordinator_email }}</label>
                    </v-row>
                    <v-row class="d-flex flex-column" style="padding-left: 0px">
                      <label v-if="!job.employerprofile.email">,</label>
                      <label v-else>{{ job.employerprofile.email }}</label>
                    </v-row>
                    <v-row class="d-flex flex-column" style="padding-left: 0px">
                      <label>
                        {{ job.employerprofile.addressno }}
                        {{ job.employerprofile.tambon.tambonthai }}
                        {{ job.employerprofile.district.districtthai }}
                        {{ job.employerprofile.district.province.provincethai }}
                        {{ job.employerprofile.tambon.postcodemain }}
                      </label>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-col style="display: flex; justify-content: flex-end" cols="12">
              <v-btn color="" @click="$router.go(-1)"> ย้อนกลับ</v-btn>
            </v-col>
          </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import { JobPostingSearcherDataProvider } from "@/services/JobPostingSearcherDataProvider";
import DBDStatusDisplay from "@/components/Employer/DBDStatusDisplay.vue";
const jobPostingSearcherDataProvider = new JobPostingSearcherDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
import html2pdf from "html2pdf.js";
export default {
  components : {
      DBDStatusDisplay
  },
  name: "EmployerProfileInfo",
  data() {
    return {
      oid: "",
      job: "",
      isFavorite: false,
      position: "ช่างยนต์ ออโตคลิก แยกสี่กอ กะทู้",
      employer: "บริษัท ออโตคอร์ป โฮลดิ้ง จำกัด (มหาชน)",
      province: "ภูเก็ต",
      recruitmentrate: "3",
      typejob: "งานประจำ (Full Time)",
      Howtoapply: "ติดต่อสถานประกอบการ",
      salary: "12,000++",
      gender: "ชาย",
      age: "ไม่เกิน 30 ปี",
      level: "ปวช. ขึ้นไป สาขา ช่างยนต์, เทคนิคยานยนต์",
      experience: "มีประสบการณ์ฝึกงาน หรือ ทำงาน ซ่อมรถยนต์ จะพิจารณาเป็นพิเศษ",
      etc: "มีใบอนุญาตขับขี่รถยนต์",
      tel: "043306333",
      email: "hr-team@ach.co.th",
      phone: "0620080797",
      address: "",
      link: "https://www.autocorpgroup.com/en",
      detailsjob: "ให้บริการซ่อมรถยนต์ของลูกค้าที่เข้ามารับบริการ ตามใบสั่งซ่อม",
      benefit1: "- ประกันสังคม",
      benefit2: "- เบี้ยขยัน",
      benefit3: "- คอมมิชชั่น",
      benefit4: "- เครื่องแบบพนักงาน",
      benefit5: "- กองทุนสำรองเลี้ยงชีพ",
      benefit6: "- ตรวจสุขภาพประจำปี",
      benefit7: "- เงินยินดีมงคลสมรส",
      benefit8: "- เงินอนุโมทนาอุปสมบท",
      benefit9: "- เงินช่วยเหลือฌาปนกิจ",
      benefit10: "- ประกันอุบัติเหตุ",
      benefit11: "- ประกันสุขภาพ",
      benefit12: "- เบี้ยเลี้ยงเดินทางปฏิบัติงาน",
      benefit13: "- ส่วนลดสำหรับพนักงาน",
      confirm_dialog: false,
      logo_photobase64: "",
      valid: false,
    };
  },
  methods: {
    async loadJobPosting() {
      try {
        const result = await jobPostingSearcherDataProvider.getJobPosting(this.oid);
        this.job = result.data || {};
        // console.log(this.job);
        this.logo_photobase64 = this.job.employerprofile?.logo_photobase64 || "";
      } catch (error) {
        console.error("Error loading job posting:", error);
      }
      // await jobPostingSearcherDataProvider.getJobPosting(this.oid).then((result) => {
      //   this.job = result.data;
      //   console.log(this.job);
      //   this.logo_photobase64 = result.data.employerprofile.logo_photobase64;
      // });
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
    },
    print() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: "EmployerProfile.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        enableLinks: true,
        pagebreak: { mode: ["css", "legacy"] },
      });
    },
  },

  async created() {
    // this.oid = this.$route.params.joboid;
    this.oid = this.$route.params.oid;
    await this.loadJobPosting();
  },
};
</script>

<style scoped>
.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgb(62, 83, 183) 20%,
    rgb(66, 81, 167) 30%,
    rgb(88, 123, 201) 50%,
    rgb(46, 126, 220) 60%,
    rgb(84, 170, 235) 80%,
    rgb(127, 202, 246) 100%
  );
}
.rounded-circle {
  border-radius: 50%;
  overflow: hidden;
  width: 10vw;
  height: 10vw;
  max-width: 60px;
  max-height: 60px;
}
.equal-height-btn {
  display: flex;
  align-items: center;
  height: 50px;
}
.display-center {
  display: flex;
  align-items: center;
}
.ql-editor {
  margin-top: 2px;
  padding-left: 0px;
}
.text-container {
  position: relative;
  display: inline-block;
}
/* ทำ... */
.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  width: max-content;
  max-width: 300px; /* adjust as needed */
  background-color: #fff;
  color: #000;
  text-align: left;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  bottom: 125%; /* Adjust this value if needed */
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  word-wrap: break-word;
}

.text-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.off-print {
  display: none;
}

@media print {
  .text-ellipsis {
    display: block;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    overflow: visible;
    text-overflow: unset;
    white-space: normal;
  }
  .tooltip {
    display: none;
  }
  .off-print {
    display: none;
  }
}
</style>
