<template>
  <div>
    <v-app-bar color="indigo" app dark>
      <div class="d-flex align-center">
        <router-link to="/home">
          <v-img
            alt="VEC Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="50"
          />
        </router-link>
        <router-link to="/home">
          <v-img
            alt="สำนักงานคณะกรรมการการอาชีวศึกษา"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            src="@/assets/logo-name.png"
            width="100%"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <template>
        <div class="pr-3" v-if="$vuetify.theme.dark">
          <v-switch
            v-model="$vuetify.theme.dark"
            label="โหมดกลางคืน"
            inset
            persistent-hint
            hint="สลับโหมดกลางวัน/กลางคืน"
            color="primary"
          >
          </v-switch>
        </div>
        <div class="pr-3" v-else>
          <v-switch
            v-model="$vuetify.theme.dark"
            label="โหมดกลางวัน"
            inset
            persistent-hint
            hint="สลับโหมดกลางวัน/กลางคืน"
            color="primary"
          >
          </v-switch>
        </div>
      </template>
    </v-app-bar>
    <v-container>
      <v-card
        elevation="2"
        class="mx-auto my-12 justify-center text-center pa-4"
        max-width="450"
      >
        <div class="pt-5">
          <h3>กำหนดรหัสผ่าน</h3>
          <v-divider class="mt-4 mb-4 mx-0"></v-divider>
          <div class="py-4">
            <span class="secondary--text font-size:16px"
              >ป้อนรหัสผ่านใหม่ของคุณ</span
            >
          </div>
        </div>

        <div>
          <v-alert
            v-if="isChangePasswordSuccess == true"
            class="mb-10"
            outlined
            type="success"
            text
          >
            <span>เปลี่ยนรหัสผ่านเรียบร้อยแล้ว</span>
          </v-alert>
          <v-alert
            v-if="isChangePasswordSuccess == false"
            class="mb-10"
            outlined
            type="warning"
            prominent
            border="left"
          >
            <span>เปลี่ยนรหัสผ่านไม่สำเร็จ</span>
          </v-alert>
        </div>

        <div>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            lazy-validation
          >
            <div class="mt-4 mb-4">
              <v-text-field
                v-model="username"
                label="ชื่อผู้ใช้ (Username)"
                required
                prepend-icon="mdi-account"
                readonly
                :rules="usernameRules"
              >
              </v-text-field>
              <v-text-field
                label="รหัสผ่าน"
                persistent-hint
                prepend-icon="mdi-key"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :rules="[
                  (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                  (v) => (v && v.length >= 6) || 'ต้องมีอย่างน้อย 6 ตัวอักษร',
                ]"
                append-icon=""
              >
                <template #append>
                  <v-btn icon @click="showPassword = !showPassword">
                    <v-icon>{{
                      showPassword ? "mdi-eye" : "mdi-eye-off"
                    }}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-text-field
                label="ยืนยันรหัสผ่าน"
                persistent-hint
                prepend-icon="mdi-key"
                :type="showConfirmPassword ? 'text' : 'password'"
                v-model="confirmpassword"
                :rules="[
                  (v) => !!v || 'กรุณายืนยันรหัสผ่าน',
                  (v) => v === password || 'รหัสผ่านไม่ตรง',
                ]"
                append-icon=""
              >
              <template #append>
                  <v-btn icon @click="showConfirmPassword = !showConfirmPassword">
                    <v-icon>{{
                      showConfirmPassword ? "mdi-eye" : "mdi-eye-off"
                    }}</v-icon>
                  </v-btn>
                </template>
            </v-text-field>
            </div>
            <div class="mt-10 mb-5">
              <v-btn
                color="success"
                @click="savePassword()"
                block
                type="submit"
                dark
                class="my-4"
                ><v-icon color="white">mdi-check-circle-outline</v-icon> &nbsp;
                <span>ยืนยัน</span>
              </v-btn>
              <v-btn
                color="success"
                text
                block
                dark
                class="my-4"
                @click="gotoLogin()"
              >
                <span class="secondary--text"
                  >เข้าสู่ระบบติดตามภาวะการมีงานทำ</span
                >
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { AuthenticationProvider } from "@/services/AuthenticationProvider";

var authenticationProvider = new AuthenticationProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "SetPassword",
  data() {
    return {
      usernameRules: undefined,
      valid: undefined,
      account: undefined,
      username: "",
      password: "",
      confirmpassword: "",
      isChangePasswordSuccess: undefined,
      showPassword:false,
      showConfirmPassword: false,
    };
  },
  methods: {
    async savePassword() {
      await authenticationProvider
        .SetPassword(this.username, this.password)
        .then((result) => {
          this.isChangePasswordSuccess = true;
          const username = this.username;
          setTimeout(() => {
            this.$router.push({
            name: "RedirectLogin",
            params: { loginpage },
          })
          }, 1000);
        })
        .catch((error) => {
          this.isChangePasswordSuccess = false;
        });
    },
    gotoLogin() {
      this.$router.push("/home");
    },
  },
  created() {
    this.username = this.$route.params.username;
  },
};
</script>

<style></style>
