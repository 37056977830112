<template>
    <v-container class="pa-5" v-if="jobapplication">
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <h2>สถานะใบสมัครงาน</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <label>สถานะใบสมัครงาน : </label>
                <JobApplicationStatus_Label :JobApplication="jobapplication"></JobApplicationStatus_Label>
            </v-col>
        </v-row>
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-container v-if="jobapplication.jobapplicationstatus.statuscode == 'InterviewScheduled'">
            <p class="primary--text">นัดหมายสัมภาษณ์</p>
            <v-row>
                <v-col cols="6">
                    <TextFieldDatePicker readonly v-model="jobapplication.interviewdate"
                        :value="jobapplication.interviewdate" caption="วันที่นัดสัมภาษณ์"></TextFieldDatePicker>
                </v-col>
                <v-col cols="6">
                    <TimePicker readonly v-model="jobapplication.interviewtime" :value="jobapplication.interviewtime"
                        caption="เวลา">
                    </TimePicker>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea readonly label="สถานที่สัมภาษณ์" v-model="jobapplication.interviewlocation">

                    </v-textarea>
                </v-col>
            </v-row>
        </v-container>
        <v-row>
            <v-col cols="12">
                <v-textarea readonly label="ข้อความจากสถานประกอบการ" v-model="jobapplication.notifymessage">

                </v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <v-btn :disabled="!jobapplication.mustnotify" @click="onReadClicked()" class="mr-2"
                    color="primary"><v-icon>mdi-check-circle</v-icon> อ่านแล้ว</v-btn>
                <v-btn @click="onClosed()" class="mr-2"><v-icon>mdi-close</v-icon>ปิด</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import JobApplicationStatus_Label from './JobApplicationStatus_Label.vue';
import { JobApplicationDataProvider } from '@/services/JobApplicationDataProvider'
const jobApplicationDataProvider = new JobApplicationDataProvider(process.env.VUE_APP_BACKEND_API_URL);
import { StudentJobPostingDataProvider } from '@/services/StudentJobPostingDataProvider';
import TextFieldDatePicker from '../common/TextFieldDatePicker.vue';
import TimePicker from '../common/TimePicker.vue';
const studentJobPostingDataProvider = new StudentJobPostingDataProvider(process.env.VUE_APP_BACKEND_API_URL);
export default {
    components: { TextFieldDatePicker, TimePicker ,JobApplicationStatus_Label},
    name: 'StudentJobApplicationStatusInfo',
    props: ['JobApplication'],
    data() {
        return {
            jobapplication: undefined,
        }
    },
    watch :{
        'JobApplication.jobapplicationstatus.statuscode'(newValue , oldVallue) {
            this.initialize();
        },
        'JobApplication.mustnotify'(newValue , oldVallue) {
            this.initialize();
        },
    },
    methods: {
        async initialize() {
            await jobApplicationDataProvider.getJobApplication(this.JobApplication.oid).then(result => {
                this.jobapplication = result.data;
            });
        },
        async onReadClicked() {
            await studentJobPostingDataProvider.markReadNotifyMessage(this.jobapplication.oid).then(result => {
                this.$toast.success('บันทึกการอ่านเรียบร้อย');
                this.initialize();
                this.$emit("readclicked");
            }).catch(error => {
                this.$toast.error(error);
            })
        },

        async onClosed() {
            this.$emit("closed");
        }
    },
    async created() {
        await this.initialize();
    }
}
</script>

<style></style>