<template>
  <label
    ><v-icon :color="dynamicColor">{{ dynamicIcon }}</v-icon>
    {{ dynamicStatusName }}</label
  >
</template>
<script>
export default {
  name: "JobApplicationStatus_Label",
  props: ["JobApplication"],
  data() {
    return {};
  },
  computed: {
    dynamicStatusName() {
      return this.JobApplication.jobapplicationstatus.statusname;
    },
    dynamicIcon() {
      switch (this.JobApplication.jobapplicationstatus.statuscode) {
        case "Pending":
          return "mdi-file-document-outline";
        case "UnderReview":
          return "mdi-progress-clock";
        case "InterviewScheduled":
          return "mdi-calendar";
        case "Accecpted":
          return "mdi-check-circle";
        case "Rejected":
          return "mdi-close-octagon";
        default:
          return "";
      }
    },
    dynamicColor() {
      switch (this.JobApplication.jobapplicationstatus.statuscode) {
        case "Pending":
          return "";
        case "UnderReview":
          return "blue";
        case "InterviewScheduled":
          return "info";
        case "Accecpted":
          return "success";
        case "Rejected":
          return "error";
        default:
          return "";
      }
    },
  },
  methods: {},
  async created() {
  },
};
</script>

<style></style>
