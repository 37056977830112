<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" class="ml-0 pl-1">
        <v-btn
          @click="open_createAdministratorProfileDetailView"
          dark
          color="primary"
          class="ma-2"
        >
          <v-icon class="mr-2">mdi-account-plus</v-icon> เพิ่มข้อมูลผู้ดูแลระบบ
        </v-btn>
      </v-col>
    </v-row>
    <SearchableDataTable
      table_caption="ข้อมูลผู้ดูแลระบบ"
      :data_items="administratorProfiles"
      :datatable_headers="administratorProfiles_data_table_headers"
    >
      <template v-slot:item.action="{ item }">
        <v-row class="custom-width">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="open_updateAdministratorProfileDetailView(item)"
                color="info"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขข้อมูล</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="deleteAdministratorProfile(item)"
                color="error"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ลบ</span>
          </v-tooltip>
        </v-row>
      </template>
    </SearchableDataTable>
  </div>
</template>

<script>
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { AdministratorProfileDataProvider } from "@/services/AdministratorProfileDataProvider";

const administratorProfileDataProvider = new AdministratorProfileDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: { SearchableDataTable },
  name: "AdministratorProfileListView",
  data() {
    return {
      administratorProfiles: [],
      administratorProfiles_data_table_headers: [
        { text: "ชื่อ", value: "firstname" },
        { text: "นามสกุล", value: "lastname" },
        { text: "Email", value: "email" },
        { text: "ชื่อผู้ใช้งาน", value: "username" },
        { text: "", value: "action" , sortable: false},
      ],
    };
  },
  methods: {
    async loadAdministratorProfiles() {
      await administratorProfileDataProvider
        .getAdministratorProfiles()
        .then((result) => {
          this.administratorProfiles = result.data;
        });
    },
    async open_createAdministratorProfileDetailView() {
      this.$router.push("CreateAdministratorProfileDetailView");
    },
    async open_updateAdministratorProfileDetailView(item) {
      const oid = item.oid;
      this.$router.push({
        name: "UpdateAdministratorProfileDetailView",
        params: { oid: oid },
      });
    },
    async deleteAdministratorProfile(item) {
      if (confirm("ยืนยันการลบบัญชีผู้ใช้งาน ?")) {
        const oid = item.oid;
        await administratorProfileDataProvider
          .deleteAdministratorProfile(oid)
          .then((result) => {
            this.$toast.success("ลบข้อมูลเรียบร้อย");
            this.loadAdministratorProfiles();
          });
      }
    },
  },
  async created() {
    await this.loadAdministratorProfiles();
  },
};
</script>

<style scoped>
.custom-width {
  width: 100px;
  gap: 20px;
}
</style>
