<template>
  <v-row class="d-flex flex-row justify-center align-center">
    <v-col cols="10">
      <v-container class="pa-10 px-15">
        <v-row class="d-flex justify-end">
          <v-col cols="4" class="d-flex justify-end">
            <v-text-field
              label="ระบุรหัสประกาศที่ต้องการคัดลอก"
              v-model="jopostingid_to_copy"
              append-icon="mdi-content-paste"
              @click:append="onBtnCopyJostPostingClicked()"
              @keyup.enter="onBtnCopyJostPostingClicked()"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- @click="onBtnCopyJostPostingClicked()" -->
        <v-form class="elevation-4" ref="form" v-model="valid" lazy-validation>
          <v-card class="pa-6">
            <v-row>
              <v-col col="12">
                <h4 class="primary--text">สร้างประกาศรับสมัครงาน</h4>
              </v-col>
            </v-row>

            <!-- <div class="primary--text mt-5 mb-2">
              <h3>เกี่ยวกับตำแหน่งงาน</h3>
            </div> -->
            <v-row>
              <v-col cols="12">
                <h4 class="primary--text">
                  <v-icon color="primary" class="mr-1">mdi-account-box</v-icon
                  >เกี่ยวกับตำแหน่งงาน
                </h4>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mx-0 mb-4"></v-divider>
            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="12" lg="12">
                <h4 class="primary--text mb-2">สถานะ</h4>
                <v-checkbox
                  v-model="vm.isargent"
                  label="รับสมัครด่วน"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="12" lg="12">
                <h4 class="primary--text mb-2">ประเภทงาน</h4>
                <v-autocomplete
                  filled
                  background-color="input"
                  clearable
                  label="ประเภทงาน"
                  :items="jobsegments"
                  item-text="name"
                  item-value="code"
                  v-model="vm.selected_jobsegment_code"
                  :disabled="vm.isotherjobsegment"
                  append-icon="mdi-chevron-down"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-checkbox
                  label="อื่น ๆ"
                  v-model="vm.isotherjobsegment"
                  @change="ClearJobsement"
                ></v-checkbox>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  :disabled="!vm.isotherjobsegment"
                  label="กรุณาระบุประเภทงานอื่น ๆ"
                  v-model="vm.otherjobsegmentname"
                  filled
                  background-color="input"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="6" sm="12" md="6" lg="6">
                <h4 class="primary--text mb-2">ลักษณะการจ้างงาน<span class="ml-1" style="color: red">*</span></h4>
                <v-autocomplete
                  filled
                  background-color="input"
                  clearable
                  append-icon="mdi-chevron-down"
                  label="ลักษณะการจ้างงาน"
                  :items="employmentCategories"
                  item-text="name"
                  item-value="code"
                  v-model="vm.selected_employmentcategory_code"
                  :rules="[(v) => !!v || 'ลักษณะการจ้างงาน']"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="12" md="6" lg="6">
                <h4 class="primary--text mb-2">
                  ตำแหน่ง<span class="ml-1" style="color: red">*</span>
                </h4>
                <v-text-field
                  filled
                  background-color="input"
                  v-model="vm.positionname"
                  label="ตำแหน่ง"
                  :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="ลักษณะการจ้างงาน"
                  :items="employmentCategories"
                  item-text="name"
                  item-value="code"
                  v-model="vm.selected_employmentcategory_code"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.positionname"
                  label="ตำแหน่ง *"
                  :rules="[(v) => !!v || 'กรุณาระบุตำแหน่ง']"
                ></v-text-field>
              </v-col>
            </v-row> -->

            <v-row>
              <v-col cols="12">
                <h4 class="primary--text mb-2">หน้าที่และรายละเอียดของงาน</h4>
                <v-textarea
                  filled
                  background-color="input"
                  v-model="vm.jobdescription"
                  label="หน้าที่และรายละเอียดของงาน"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col col="6">
                <TimePicker
                  filled
                  background-color="input"
                  :value="vm.workingtimestart"
                  :caption="'เวลาทำงานตั้งแต่'"
                  @closeAndUpdate="OnSelectedWorkingTimeStart"
                ></TimePicker>
              </v-col>
              <v-col col="6">
                <TimePicker
                  :value="vm.workingtimeend"
                  :caption="'ถึงเวลา'"
                  @closeAndUpdate="OnSelectedWorkingTimeEnd"
                ></TimePicker>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="primary--text">
                  <v-icon color="primary" class="mr-1">mdi-account-box</v-icon
                  >อัตราที่รับสมัคร
                </h4>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mx-0 mb-4"></v-divider>
            <v-row class="d-flex align-center mt-2">
              <v-col cols="3">
                <!-- <v-text-field
                  type="number"
                  v-model="vm.unit"
                  :rules="[
                    (v) => !!v || 'กรุณาระบุอัตราที่รับสมัคร',
                    (v) =>
                      (!isNaN(parseFloat(v)) && v >= 0 && v <= 999999) ||
                      'กรุณาระบุอัตราที่รับสมัครเป็นตัวเลขเท่านั้น',
                  ]"
                  label="ระบุอัตราที่รับสมัคร *"
                  :disabled="vm.isnotspecifiedunit"
                ></v-text-field> -->
                <h4 class="primary--text mb-2">
                  อัตราที่รับสมัคร<span class="ml-1" style="color: red">*</span>
                </h4>
                <v-text-field
                  filled
                  background-color="input"
                  type="number"
                  v-model="vm.unit"
                  :rules="unit"
                  label="ระบุอัตราที่รับสมัคร"
                  :disabled="vm.isnotspecifiedunit"
                ></v-text-field>
              </v-col>
              <v-col cols="9" class="d-flex justify-start align-end">
                <v-checkbox
                  label="ไม่ระบุ"
                  v-model="vm.isnotspecifiedunit"
                  @change="ClearUnit"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="primary--text">
                  <v-icon color="primary" class="mr-1">mdi-account-box</v-icon
                  >ค่าตอบแทน/เงินเดือน
                </h4>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mx-0 mb-4"></v-divider>

            <v-row class="d-flex align-center mt-2">
              <v-col col="3">
                <!-- <v-text-field
                  type="number"
                  :rules="[
                    (v) =>
                      (!isNaN(parseFloat(v)) && v >= 0 && v <= 999999) ||
                      'กรุณาระบุค่าจ้างเป็นตัวเลขเท่านั้น',
                  ]"
                  label="ขั้นต่ำ"
                  v-model="vm.minimumwage"
                  :disabled="vm.notspecifiedwage"
                ></v-text-field> -->
                <h4 class="primary--text mb-2">เงินเดือนขั้นต่ำ</h4>
                <v-text-field
                  :rules="wage"
                  filled
                  background-color="input"
                  type="number"
                  label="ขั้นต่ำ"
                  v-model="vm.minimumwage"
                  :disabled="vm.notspecifiedwage"
                ></v-text-field>
              </v-col>
              <v-col col="3">
                <!-- <v-text-field
                  type="number"
                  :rules="[
                    (v) =>
                      (!isNaN(parseFloat(v)) && v >= 0 && v <= 999999) ||
                      'กรุณาระบุค่าจ้างเป็นตัวเลขเท่านั้น',
                  ]"
                  label="สูงสุด"
                  v-model="vm.maximumwage"
                  :disabled="vm.notspecifiedwage"
                ></v-text-field> -->
                <h4 class="primary--text mb-2">เงินเดือนสูงสุด</h4>
                <v-text-field
                  :rules="wage"
                  filled
                  background-color="input"
                  type="number"
                  label="สูงสุด"
                  v-model="vm.maximumwage"
                  :disabled="vm.notspecifiedwage"
                ></v-text-field>
              </v-col>
              <v-col col="3">
                <h4 class="primary--text mb-2">รูปแบบการจ่ายค่าตอบแทน</h4>
                <v-select
                  filled
                  background-color="input"
                  label="ต่อเดือน"
                  :items="payrates"
                  item-text="name"
                  item-value="code"
                  v-model="vm.selected_payrate_code"
                  :disabled="vm.notspecifiedwage"
                  append-icon="mdi-chevron-down"
                ></v-select>
              </v-col>

              <v-col col="3" class="d-flex justify-center align-end">
                <v-checkbox
                  label="ไม่ระบุ"
                  v-model="vm.notspecifiedwage"
                  @change="ClearPayrate"
                >
                </v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="primary--text">
                  <v-icon color="primary" class="mr-1">mdi-account-box</v-icon
                  >วันที่เริ่ม-สิ้นสุด การประกาศ
                </h4>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mx-0 mb-4"></v-divider>
            <v-row class="mt-2">
              <v-col cols="6">
                <h4 class="primary--text mb-2">
                  วันที่เริ่มประกาศ<span class="ml-1" style="color: red"
                    >*</span
                  >
                </h4>
                <TextFieldDatePicker
                  :rules="[(v) => !!v || 'กรุณาระบุวันที่เริ่มประกาศ']"
                  filled
                  background-color="input"
                  v-model="vm.startdate"
                  :value="vm.startdate"
                  caption="กรุณาระบุวันที่เริ่มประกาศ"
                  :max="vm.enddate"
                  @closeAndUpdate="this.startDateSelected"
                ></TextFieldDatePicker>
              </v-col>
              <v-col cols="6">
                <h4 class="primary--text mb-2">
                  วันที่สิ้นสุดประกาศ<span class="ml-1" style="color: red"
                    >*</span
                  >
                </h4>
                <TextFieldDatePicker
                  :rules="[(v) => !!v || 'กรุณาระบุวันที่สิ้นสุดประกาศ']"
                  filled
                  background-color="input"
                  v-model="vm.enddate"
                  :min="vm.startdate"
                  :value="vm.enddate"
                  caption="กรุณาระบุวันที่สิ้นสุดประกาศ"
                  @closeAndUpdate="this.endDateSelected"
                ></TextFieldDatePicker>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="primary--text">
                  <v-icon color="primary" class="mr-1">mdi-account-box</v-icon
                  >สถานที่ปฏิบัติงาน
                </h4>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mx-0 mb-4"></v-divider>

            <v-row class="mt-2">
              <v-col col="12">
                <h4 class="primary--text mb-2">
                  ที่อยู่/ที่ตั้ง<span class="ml-1" style="color: red">*</span>
                </h4>
                <v-text-field
                  filled
                  background-color="input"
                  label="กรุณาระบุที่อยู่"
                  v-model="vm.workingplace_address"
                  :rules="[(v) => !!v || 'กรุณาระบุที่อยู่']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <h4 class="primary--text mb-2">
                  จังหวัด<span class="ml-1" style="color: red">*</span>
                </h4>
                <v-autocomplete
                  filled
                  background-color="input"
                  append-icon="mdi-chevron-down"
                  label="กรุณาเลือกจังหวัด"
                  @change="onProvinceSelected"
                  :items="provinces"
                  item-text="provincethai"
                  item-value="provinceid"
                  v-model="vm.selected_workingplace_province_id"
                  :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <h4 class="primary--text mb-2">อำเภอ</h4>
                <v-autocomplete
                  filled
                  background-color="input"
                  append-icon="mdi-chevron-down"
                  label="กรุณาเลือกอำเภอ"
                  @change="onDistrictSelected"
                  :items="districts"
                  item-text="districtthai"
                  item-value="districtid"
                  v-model="vm.selected_workingplace_district_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <h4 class="primary--text mb-2">ตำบล</h4>
                <v-autocomplete
                  filled
                  background-color="input"
                  append-icon="mdi-chevron-down"
                  label="กรุณาเลือกตำบล"
                  @change="onTambonSelected"
                  :items="tambons"
                  item-text="tambonthai"
                  item-value="tambonid"
                  v-model="vm.selected_workingplace_tambon_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <h4 class="primary--text mb-2">รหัสไปรษณีย์</h4>
                <v-text-field
                  filled
                  background-color="input"
                  readonly
                  label="กรุณาระบุรหัสไปรษณีย์"
                  v-model="vm.selected_workingplace_zipcode"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="primary--text">
                  <v-icon color="primary" class="mr-1">mdi-account-box</v-icon
                  >คุณสมบัติผู้สมัคร
                </h4>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mx-0 mb-4"></v-divider>
            <v-row class="mt-2">
              <v-col cols="12">
                <h4 class="primary--text mb-2">
                  เพศ<span class="ml-1" style="color: red">*</span>
                </h4>
                <v-radio-group
                  row
                  v-model="vm.applicant_requiredgenderchoice"
                  :rules="[(v) => v !== null && (v === 0 || v === 1 || v === 2) || 'กรุณาระบุเพศหากต้องการระบุเพศ']"
                >
                  <v-radio label="ชาย" :value="1"></v-radio>
                  <v-radio label="หญิง" :value="2"></v-radio>
                  <v-radio label="ไม่ระบุ" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="d-flex align-center">
              <v-col col="8">
                <h4 class="primary--text mb-2">
                  วุฒิการศึกษาขั้นต่ำ<span class="ml-1" style="color: red"
                    >*</span
                  >
                </h4>
                <v-select
                  filled
                  background-color="input"
                  append-icon="mdi-chevron-down"
                  label="กรุณาเลือกวุฒิการศึกษาขั้นต่ำ"
                  :items="certificatetypes"
                  item-value="code"
                  item-text="nameth"
                  clearable
                  v-model="vm.selected_applicant_requiredcertificatetype_code"
                  :rules="[
                    (v) =>
                      this.vm.no_require_certificate == true ||
                      !!v ||
                      'กรุณาระบุวุฒิการศึกษาขั้นต่ำ',
                  ]"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="vm.no_require_certificate"
                  @change="
                    vm.selected_applicant_requiredcertificatetype_code = ''
                  "
                  label="ไม่ระบุ"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="primary--text mb-2">คุณสมบัติผู้สมัคร</h4>

                <v-textarea
                  filled
                  background-color="input"
                  v-model="vm.applicant_specification"
                  label="กรุณาระบุคุณสมบัติผู้สมัคร"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="primary--text">
                  <v-icon color="primary" class="mr-1">mdi-account-box</v-icon
                  >ข้อมูลผู้ประสานงานของสถานประกอบการ
                </h4>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mx-0 mb-4"></v-divider>

            <v-row class="mt-2">
              <v-col cols="12">
                <h4 class="primary--text mb-2">
                  ชื่อ-นามสกุล<span class="ml-1" style="color: red">*</span>
                </h4>

                <v-text-field
                  filled
                  background-color="input"
                  v-model="vm.coordinator_fullname"
                  :rules="[(v) => !!v || 'กรุณาระบุชื่อ-นามสกุล']"
                  label="กรุณาระบุชื่อ - นามสกุล"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <h4 class="primary--text mb-2">
                  เบอร์โทรศัพท์<span class="ml-1" style="color: red">*</span>
                </h4>

                <v-text-field
                  filled
                  background-color="input"
                  v-model="vm.coordinator_phoneno"
                  :rules="phoneRules"
                  label="กรุณาระบุหมายเลขโทรศัพท์"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <h4 class="primary--text mb-2">
                  อีเมล<span class="ml-1" style="color: red">*</span>
                </h4>

                <v-text-field
                  filled
                  background-color="input"
                  v-model="vm.coordinator_email"
                  :rules="emailRules"
                  label="กรุณาระบุอีเมล (ถ้ามี)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
        <v-row class="">
          <v-col cols="12" class="mt-8 justify-end text-end">
            <!-- <v-btn color="success" class="ma-2"
                  ><v-icon>mdi-content-save</v-icon> บันทึกและเปิดรับสมัครงานทันที</v-btn
                > -->
            <v-btn color="" @click="$router.go(-1)" class="justify-center mr-2">
              ย้อนกลับ</v-btn
            ><v-btn @click="onSave" class="color-buton">
              บันทึก<v-icon>mdi-content-save</v-icon></v-btn
            >
          </v-col></v-row
        >
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { emailRules, phoneRules, unit, wage } from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import TextFieldDatePicker from "@/components/common/TextFieldDatePicker.vue";
import TimePicker from "@/components/common/TimePicker.vue";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { EmployerJobPostingDataProvider } from "@/services/EmployerJobPostingDataProvider";
import { CreateJobPosting_Request } from "@/models/EmployerJobPosting/CreateJobPosting_Request";
import { MinorDataProvider } from "@/services/MinorDataProvider";
import { JobPositionDataProvider } from "@/services/jobPositionDataProvider";
const minorDataProvider = new MinorDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const masterDataProvider = new MasterDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const employerJobPostingDataProvider = new EmployerJobPostingDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);

export default {
  components: { TextFieldDatePicker, TimePicker },
  name: "EmployerCreateJobPostingView",
  data() {
    return {
      jopostingid_to_copy: "",
      jobsegments: [],
      provinces: [],
      districts: [],
      tambons: [],
      payrates: [],
      certificatetypes: [],
      employmentCategories: undefined,
      valid: true,
      vm: new CreateJobPosting_Request(),
      phoneRules: phoneRules,
      emailRules: emailRules,
      unit: unit,
      wage: wage
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider.getJobSegment().then((result) => {
        this.jobsegments = result.data;
      });

      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });

      await masterDataProvider.getEmploymentCategories().then((result) => {
        this.employmentCategories = result.data;
      });

      await masterDataProvider.getPayRates().then((result) => {
        this.payrates = result.data;
      });

      await masterDataProvider.getCertificateTypes().then((result) => {
        this.certificatetypes = result.data;
      });
    },
    OnSelectedWorkingTimeStart(val) {
      this.vm.workingtimestart = val;
    },
    OnSelectedWorkingTimeEnd(val) {
      this.vm.workingtimeend = val;
    },
    startDateSelected(selectedDate = "") {
      this.vm.startdate = selectedDate;
    },

    endDateSelected(selectedDate = "") {
      this.vm.enddate = selectedDate;
    },

    async onProvinceSelected() {
      let provinceID = this.vm.selected_workingplace_province_id;
      this.vm.selected_workingplace_district_id = "";
      this.vm.selected_workingplace_tambon_id = "";
      this.vm.selected_workingplace_zipcode = "";
      await masterDataProvider.loadGetDistricts(provinceID).then((result) => {
        this.districts = result.data;
      });
    },

    async onDistrictSelected() {
      let districtID = this.vm.selected_workingplace_district_id;
      this.vm.selected_workingplace_tambon_id = "";
      this.vm.selected_workingplace_zipcode = "";
      await masterDataProvider.loadGetTambons(districtID).then((result) => {
        this.tambons = result.data;
      });
    },

    async onTambonSelected() {
      let tambonID = this.vm.selected_workingplace_tambon_id;
      let tambon = this.tambons.find((x) => {
        return x.tambonid == tambonID;
      });
      this.vm.selected_workingplace_zipcode = tambon.postcodeall;
    },

    async onBtnCopyJostPostingClicked() {
      if (this.jopostingid_to_copy != "") {
        console.log(this.jopostingid_to_copy);
        await employerJobPostingDataProvider
          .getJobPostingByJobPostingID(this.jopostingid_to_copy)
          .then((result) => {
            console.log(result.data);
            this.$toast.success("คัดลอกข้อมูลเสร็จสิ้น");
            this.vm = CreateJobPosting_Request.fromJSON(result.data);

            if (this.vm.selected_workingplace_province_id)
              if (this.vm.selected_workingplace_province_id != undefined) {
                masterDataProvider
                  .loadGetDistricts(this.vm.selected_workingplace_province_id)
                  .then((result) => {
                    this.districts = result.data;
                  });
              }

            if (this.vm.selected_workingplace_district_id) {
              masterDataProvider
                .loadGetTambons(this.vm.selected_workingplace_district_id)
                .then((result) => {
                  this.tambons = result.data;
                });
            }
          })
          .catch((err) => {
            this.$toast.warning("ไม่พบข้อมูลประกาศ");
          });
      }
    },

    async onSave() {
      if (this.$refs.form.validate()) {
        if (confirm("ยืนยัน")) {
          console.log(this.vm);
          await employerJobPostingDataProvider
            .createEmployerJobPosting(this.vm)
            .then((result) => {
              this.$toast.success("เพิ่มข้อมูลประกาศงานเรียบร้อย");
              this.$router.push("/Employer/EmployerJobPostingListView_Draft");
            });
        }
      } else {
        this.$toast.error("กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน");
      }
    },
    // async onSave() {

    //   employerJobPostingDataProvider.createEmployerJobPosting(this.vm).then((result) => {
    //     this.$toast.success("เพิ่มข้อมูลประกาศงานเรียบร้อย");
    //   });
    // },
    async isCheck() {
      this.vm.isotherjobsegment = false;
      this.vm.isnotspecifiedunit = false;
      this.vm.notspecifiedwage = false;
    },
    ClearUnit() {
      if (this.vm.isnotspecifiedunit) {
        this.vm.unit = 0;
      }
    },
    ClearPayrate() {
      if (this.vm.notspecifiedwage) {
        this.vm.minimumwage = 0;
        this.vm.maximumwage = 0;
        this.vm.selected_payrate_code = "";
      }
    },
    ClearJobsement() {
      if (this.vm.isotherjobsegment) {
        this.vm.selected_jobsegment_code = "";
      } else {
        this.vm.otherjobsegmentname = "";
      }
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
