<template>
  <div>
    <v-app-bar color="indigo" app dark>
      <div class="d-flex align-center">
        <router-link to="/home">
          <v-img
            alt="VEC Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="50"
          />
        </router-link>
        <router-link to="/home">
          <v-img
            alt="สำนักงานคณะกรรมการการอาชีวศึกษา"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            src="@/assets/logo-name.png"
            width="100%"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <template>
        <div class="pr-3" v-if="$vuetify.theme.dark">
          <v-switch
            v-model="$vuetify.theme.dark"
            label="โหมดกลางคืน"
            inset
            persistent-hint
            hint="สลับโหมดกลางวัน/กลางคืน"
            color="primary"
          >
          </v-switch>
        </div>
        <div class="pr-3" v-else>
          <v-switch
            v-model="$vuetify.theme.dark"
            label="โหมดกลางวัน"
            inset
            persistent-hint
            hint="สลับโหมดกลางวัน/กลางคืน"
            color="primary"
          >
          </v-switch>
        </div>
      </template>
    </v-app-bar>
    <v-container>
      <v-card
        elevation="24"
        class="mx-auto py-10 justify-center text-center custom-card-shadow"
        width="40%"
        color="#EEF5FF"
        rounded
      >
        <v-icon color="success" size="80px">mdi-check-circle</v-icon>

        <div class="my-5" style="color: #374389">
          <strong>รีเซ็ทรหัสผ่านเรียบร้อย</strong>
        </div>
        <div>
          <span class="grey--text"
            >อีก {{ countdown }} วินาที เราจะส่งคุณไปที่หน้า Login</span
          >
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
import { AuthenticationProvider } from "@/services/AuthenticationProvider";
var applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
var authenticationProvider = new AuthenticationProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
export default {
  name: "RedirectLogin",
  data() {
    return {
      countdown: 5,
      loginpage: "",
    };
  },
  methods: {
    startCountdown() {
      this.loginpage = this.$route.params.loginpage;
      const loginrole = this.loginpage;

      if (loginrole == "admin") {
        const interval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(interval);
            this.gotoLogin();
          }
        }, 1000);
      } else if (loginrole == "officer") {
        const interval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(interval);
            this.gotoLoginOfficer();
          }
        }, 1000);
      } else if (loginrole == "teacher") {
        const interval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(interval);
            this.gotoLoginTeacher();
          }
        }, 1000);
      } else if (loginrole == "employee") {
        const interval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(interval);
            this.gotoLoginEmployee();
          }
        }, 1000);
      }
    },

    gotoLogin() {
      this.$router.push("/Login");
    },
    gotoLoginOfficer() {
      this.$router.push("/LoginOfficer");
    },
    gotoLoginTeacher() {
      this.$router.push("/LoginTeacher");
    },
    gotoLoginEmployee() {
      this.$router.push("/LoginEmployer");
    },
  },
  created() {
    this.startCountdown();
  },
};
</script>

<style scoped>
.custom-card-shadow {
  box-shadow: 0px 4px 20px rgba(104, 147, 240, 0.2) !important;
}
</style>
