import { EmployerSchoolContactRequest } from "./EmployerSchoolContactRequest"

export class UpdateEmployerProfileRequest {
    oid = ''
    selected_employerprofilestatuscode = ''
    selected_employerprofiletypecode = ''
    identityid = ''
    nameth = ''
    email = ''
    selected_employermainbusinesstypecode = ''
    selected_employerbussinesstypecode = ''
    description = ''
    benefit_description = ''
    social_security_number = ''
    websiteurl = ''
    isrequiredjobapplicanttoregisterthemselforviapost = false
    isopenforonlineapplicant = false
    address_no = ''
    selected_province_id = ''
    selected_district_id = ''
    selected_tambon_id = ''
    location_latitude = ''
    location_longtitude = ''
    employerschoolcontactrequests = []


    coordinator_email = ''
    coordinator_titlename_id = ''
    coordinator_firstname = ''
    coordinator_lastname = ''
    coordinator_phone = ''
    coordinator_fax = ''

    dbd_organizationjuristicstatus=''
    dbd_lastverifydate=''
    dbd_status=''

    static fromJson(jsonObject = '') {
        const vm = new UpdateEmployerProfileRequest();
        var convertedJson = JSON.stringify(jsonObject);
        vm.oid = jsonObject.oid;
        if (jsonObject.employerprofilestatus != undefined) {
            vm.selected_employerprofilestatuscode = jsonObject.employerprofilestatus.code;
        }
        if (jsonObject.employerprofiletype != undefined) {
            vm.selected_employerprofiletypecode = jsonObject.employerprofiletype.code;
        }
        if (jsonObject.identityid != undefined) {
            vm.identityid = jsonObject.identityid;
        }
        if (jsonObject.nameth != undefined) {
            vm.nameth = jsonObject.nameth;
        }
        if (jsonObject.email != undefined){
            vm.email = jsonObject.email;
        }
        if (jsonObject.employermainbusinesstype != undefined) {
            vm.selected_employermainbusinesstypecode = jsonObject.employermainbusinesstype.code;
        }
        if (jsonObject.businesstype != undefined) {
            vm.selected_employerbussinesstypecode = jsonObject.businesstype.code;
        }
        if (jsonObject.description != undefined) {
            vm.description = jsonObject.description;
        }
        if (jsonObject.benefitdescription != undefined) {
            vm.benefit_description = jsonObject.benefitdescription;
        }
        if (jsonObject.socialsecuritynumber != undefined) {
            vm.social_security_number = jsonObject.socialsecuritynumber;
        }
        if (jsonObject.websiteurl != undefined) {
            vm.websiteurl = jsonObject.websiteurl;
        }
        if (jsonObject.isrequiredjobapplicanttoregisterthemselforviapost != undefined) {
            vm.isrequiredjobapplicanttoregisterthemselforviapost = jsonObject.isrequiredjobapplicanttoregisterthemselforviapost;
        }
        if (jsonObject.isopenforonlineapplicant != undefined) {
            vm.isopenforonlineapplicant = jsonObject.isopenforonlineapplicant;
        }
        if (jsonObject.addressno != undefined) {
            vm.address_no = jsonObject.addressno;
        }
        if (jsonObject.province != undefined) {
            vm.selected_province_id = jsonObject.province.provinceid;
        }
        if (jsonObject.district != undefined) {
            vm.selected_district_id = jsonObject.district.districtid;
        }
        if (jsonObject.tambon != undefined) {
            vm.selected_tambon_id = jsonObject.tambon.tambonid;
        }
        if (jsonObject.location_latitude != undefined) {
            vm.location_latitude = jsonObject.location_latitude;
        }
        if (jsonObject.location_longtitude != undefined) {
            vm.location_longtitude = jsonObject.location_longtitude;
        }
        if (jsonObject.employerschoolcontacts!= undefined) {
            jsonObject.employerschoolcontacts.forEach(x => {
                const contact = new EmployerSchoolContactRequest();
                contact.oid = x.oid;
                if (x.school != undefined) {
                    contact.selected_schoolid = x.school.schoolid;
                    contact.selected_schoolname = x.school.schoolname;
                }
                contact.fullname = x.fullname;
                contact.phone = x.phone;
                vm.employerschoolcontactrequests.push(contact);
            });
        }
        
        if (jsonObject.coordinator_email != undefined){
            vm.coordinator_email= jsonObject.coordinator_email;
        }
        if (jsonObject.coordinator_fax != undefined){
            vm.coordinator_fax = jsonObject.coordinator_fax;
        }
        if (jsonObject.coordinator_firstname != undefined){
            vm.coordinator_firstname = jsonObject.coordinator_firstname;
        }
        if (jsonObject.coordinator_lastname != undefined){
            vm.coordinator_lastname = jsonObject.coordinator_lastname;
        }
        if (jsonObject.coordinator_phone){
            vm.coordinator_phone = jsonObject.coordinator_phone;
        }
        if (jsonObject.coordinator_titlename != undefined){
            vm.coordinator_titlename_id = jsonObject.coordinator_titlename.titlenameid;
        }
        if (jsonObject.dbd_organizationjuristicstatus != undefined){
            vm.dbd_organizationjuristicstatus = jsonObject.dbd_organizationjuristicstatus;
        }
        if (jsonObject.dbd_lastverifydate != undefined){
            vm.dbd_lastverifydate = jsonObject.dbd_lastverifydate;
        }
        if (jsonObject.dbd_status != undefined){
            vm.dbd_status = jsonObject.dbd_status;
        }
        
        return vm;
    }
}