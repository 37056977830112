<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <v-row class="d-flex justify-center mt-2 mb-2">
        <h2 class="">แก้ไขประกาศรับสมัครงาน</h2>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <v-card>
            <v-toolbar
              class="d-flex align-center"
              style="height: 50px"
              flat
              color="primary"
              dark
            >
              <v-toolbar-title class="d-flex align-center">ตำแหน่งงาน</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <v-text-field label="ตำแหน่ง *"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :rules="[
                      (v) => !!v || 'กรุณาระบุอัตราที่รับสมัคร',
                      (v) =>
                        (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) ||
                        'กรุณาระบุอัตราที่รับสมัครเป็นตัวเลขเท่านั้น',
                    ]"
                    label="อัตราที่รับสมัคร *"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="เงินเดือน"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea label="หน้าที่และรายละเอียดของงาน"> </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox v-model="lsRememberMe" label="งานรับสมัครด่วน"></v-checkbox
                ></v-col>
              </v-row>

              <v-row>
                <v-col class="ml-1"><h3>ลักษณะงาน :</h3></v-col>
              </v-row>
              <v-radio-group row>
                <v-radio
                  class="mx-2 mb-2"
                  label="งานประจำ (Full Time)"
                  color="primary"
                  :value="0"
                ></v-radio>
                <v-radio
                  class="mx-2 mb-2"
                  label="งานนอกเวลา (Part Time)"
                  color="primary"
                  :value="1"
                >
                </v-radio>
                <v-radio class="mx-2 mb-2" label="ฝึกงาน" color="primary" :value="2">
                </v-radio>
                <v-radio
                  class="mx-2 mb-2"
                  label="งานอิสระ (Freelance)"
                  color="primary"
                  :value="3"
                ></v-radio>
                <v-radio class="mx-2" label="งานจ้างรายวัน" color="primary" :value="4">
                </v-radio>
                <v-radio class="mx-2" label="งานจ้างชั่วคราว" color="primary" :value="5">
                </v-radio>
                <v-radio
                  class="mx-2"
                  label="รับงานไปทำที่บ้าน"
                  color="primary"
                  :value="6"
                >
                </v-radio>
              </v-radio-group>

              <v-row>
                <v-col cols="6" sm="12">
                  <v-autocomplete
                    clearable
                    label="จังหวัด"
                    :items="provinces"
                    item-text="provincethai"
                    item-value="provinceid"
                    @change="loadDisticts"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <v-card>
            <v-toolbar
              class="d-flex align-center"
              style="height: 50px"
              flat
              color="primary"
              dark
            >
              <v-toolbar-title>คุณสมบัติผู้สมัคร</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-textarea label="คุณสมบัติผู้สมัคร *"> </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    clearable
                    label="ระดับชั้น *"
                    :items="[
                      'ไม่ระบุ',
                      'ปวช.',
                      'หลักสูตรระยะสั้น',
                      'ปทส.',
                      'ปวส.',
                      'ปริญญาตรี',
                    ]"
                    item-text="provincethai"
                    item-value="provinceid"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="สาขาวิชา *"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <v-card>
            <v-toolbar
              class="d-flex align-center"
              style="height: 50px"
              flat
              color="primary"
              dark
            >
              <v-toolbar-title>ระยะเวลาการเปิดรับสมัคร</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        v-model="date"
                        label="เริ่มประกาศ"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="" @click="menu = false"> ยกเลิก </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(date)">
                        ตกลง
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menuend"
                    v-model="menuend"
                    :close-on-content-click="false"
                    :return-value.sync="dateend"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        v-model="dateend"
                        label="สิ้นสุดประกาศ"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateend" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="" @click="menuend = false"> ยกเลิก </v-btn>
                      <v-btn text color="primary" @click="$refs.menuend.save(dateend)">
                        ตกลง
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <v-card>
            <v-toolbar
              class="d-flex align-center"
              style="height: 50px"
              flat
              color="primary"
              dark
            >
              <v-toolbar-title>ข้อมูลผู้ประสานงานของสถานประกอบการ</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="ชื่อ *"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="โทรศัพท์"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="อีเมล *"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-switch
                    v-model="ex11"
                    label="เปิดใช้งาน"
                    color="success"
                    value="success"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row class="justify-center text-center mt-4 mb-4">
            <v-col cols="12">
              <v-btn color="secondary" class="ma-2"
                ><v-icon>mdi-content-save</v-icon> บันทึก
              </v-btn>
              <v-btn v-show="show" color="" @click="$router.go(-1)" class="ma-2">
                ย้อนกลับ</v-btn
              >
              <v-btn v-once text color="primary" @click="show = !show"> ตกลง </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { MasterDataProvider } from "@/services/MasterDataProvider";

const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);

export default {
  name: "RegisterCompany",
  data() {
    return {
      show: true,
      menu: undefined,
      menuend: undefined,
      provinces: undefined,
    };
  },
  methods: {
    async initialize() {
      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
      await masterDataProvider.loadEmployerMainBusinessTypes().then((result) => {
        this.employerMainBusinessTypes = result.data;
      });
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style scoped>
.v-file-input {
  cursor: pointer;
}
</style>
