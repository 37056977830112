<template>
  <div class="d-flex justify-center">
    <v-container class="ma-4">
      <!-- <v-row class="d-flex justify-center mt-2 mb-2">
        <h3 class="primary--text">เพิ่มข้อมูลสถานประกอบการ (ทวิภาคี)</h3>
      </v-row> -->
      <v-row class="d-flex justify-center">
        <v-col lg="10" md="12" sm="12" xs="12">
          <v-card>
            <v-toolbar class="color-HeaderCard" height="50px" flat dark>
              <v-toolbar-title
                ><v-icon class="mr-2"> mdi-account-circle </v-icon
                >เพิ่มข้อมูลสถานประกอบการ (ทวิภาคี)</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="d-flex justify-center">
              <v-col cols="10" class="mt-0">
                <div>
                  <v-tabs
                    fixed-tabs
                    slider-size="5"
                    v-model="tab"
                    active-class="tab--text"
                  >
                    <v-tab>1. ข้อมูลทั่วไป</v-tab>
                    <v-tab>2. ผู้ติดต่อ / ผู้ประสานงาน</v-tab>
                    <v-tab>3. บัญชีผู้ใช้งาน</v-tab>
                    <v-tabs-slider
                      :style="{ height: tabIndicatorHeight + 'px' }"
                    ></v-tabs-slider>
                  </v-tabs>
                  <v-tabs-items v-model="tab" class="mt-5">
                    <v-tab-item>
                      <v-form ref="generalForm">
                        <v-row
                          class="d-flex justify-space-between align-center"
                        >
                          <v-col cols="6">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ข้อมูลทั่วไป
                            </h3>
                          </v-col>
                          <!-- <v-col class="d-flex justify-end">
                            <v-btn class="mx-3" outlined @click="fetchData"
                              >ดึงข้อมูลจาก
                              <v-img
                                alt="VEC Logo"
                                class="shrink ml-2"
                                contain
                                src="@/assets/DBDlogo.png"
                                transition="scale-transition"
                                width="40"
                            /></v-btn>
                          </v-col> -->
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-5">
                          <v-col cols="12" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              ชื่อสถานประกอบการ
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="ชื่อสถานประกอบการ"
                              :rules="[
                                (v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ',
                              ]"
                              v-model="vm.nameth"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              ประเภทสถานประกอบการ
                            </h4>
                            <v-select
                              filled
                              background-color="input"
                              :rules="[
                                (v) => !!v || 'กรุณาระบุประเภทสถานประกอบการ',
                              ]"
                              label="ประเภทสถานประกอบการ"
                              :items="employerprofiletypes"
                              item-value="code"
                              item-text="nameth"
                              clearable
                              v-model="vm.selected_employerprofiletypecode"
                            ></v-select>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              ทะเบียนการค้าเลขที่ / ทะเบียน ภพ.20
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtIdentityid"
                              :rules="custom_profiletypecodeRules"
                              :label="identityid_caption"
                              v-model="vm.identityid"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">อีเมล</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtEmail"
                              label="อีเมล"
                              :rules="custom_emailRules"
                              v-model="vm.email"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-expand-transition>
                          <div v-show="emailExists">
                            <v-icon class="error--text"
                              >mdi-alert-circle</v-icon
                            >
                            <span class="pl-0 error--text">
                              อีเมลนี้มีผู้ใช้งานแล้ว
                            </span>
                          </div>
                        </v-expand-transition>

                        <v-row class="mt-2">
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">ประเภทวิสาหกิจ</h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              label="ประเภทวิสาหกิจ"
                              :rules="[(v) => !!v || 'กรุณาระบุประเภทวิสาหกิจ']"
                              :items="employerMainBusinessTypes"
                              item-value="code"
                              item-text="nameth"
                              v-model="vm.selected_employermainbusinesstypecode"
                              @change="loadEmployerBusinessTypes"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">ประเภทธุรกิจ</h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              label="ประเภทธุรกิจ"
                              :rules="[
                                (v) => !!v || 'กรุณาระบุประเภทประเภทธุรกิจ',
                              ]"
                              :items="employerBusinessTypes"
                              item-value="code"
                              item-text="nameth"
                              v-model="vm.selected_employerbussinesstypecode"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              รายละเอียดสถานประกอบการ (ลักษณะงาน)
                            </h4>
                            <v-textarea
                              filled
                              background-color="input"
                              :rules="[
                                (v) =>
                                  !!v || 'กรุณาระบุรายละเอียดสถานประกอบการ',
                              ]"
                              label="รายละเอียดสถานประกอบการ (ลักษณะงาน)"
                              v-model="vm.description"
                            >
                            </v-textarea>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">สวัสดิการ</h4>
                            <v-textarea
                              filled
                              background-color="input"
                              label="สวัสดิการ"
                              :rules="[(v) => !!v || 'กรุณาระบุสวัสดิการ']"
                              v-model="vm.benefit_description"
                            >
                            </v-textarea>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              หมายเลขประกันสังคม
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtIdentityid"
                              label="หมายเลขประกันสังคม"
                              :rules="security_numberRules"
                              v-model="vm.social_security_number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">เว็บไซต์</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="เว็บไซต์"
                              v-model="vm.websiteurl"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              รูปแบบการรับสมัคร
                            </h4>
                            <v-checkbox
                              class="ml-3 my-0 py-0"
                              label="เปิดรับสมัครทาง online"
                              v-model="vm.isopenforonlineapplicant"
                            ></v-checkbox>
                            <v-checkbox
                              class="ml-3 my-0 py-0"
                              label="ผู้สมัครงานดำเนินการสมัครได้ด้วยตนเอง หรือส่งใบสมัครทางไปรษณีย์"
                              v-model="
                                vm.isrequiredjobapplicanttoregisterthemselforviapost
                              "
                            ></v-checkbox>
                          </v-col>
                        </v-row>

                        <v-row class="mt-4">
                          <v-col cols="12" class="my-0 py-0">
                            <h4 class="primary--text mb-2">ที่อยู่</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              ref="txtEmail"
                              :rules="[(v) => !!v || 'กรุณาระบุที่อยู่']"
                              label="ที่อยู่"
                              v-model="vm.address_no"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="3" class="my-0 py-0">
                            <h4 class="primary--text mb-2">จังหวัด</h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              label="จังหวัด"
                              :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                              :items="provinces"
                              item-text="provincethai"
                              item-value="provinceid"
                              @change="loadDisticts"
                              v-model="vm.selected_province_id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="3" class="my-0 py-0">
                            <h4 class="primary--text mb-2">อำเภอ</h4>
                            <v-autocomplete
                              filled
                              background-color="input"
                              clearable
                              label="อำเภอ"
                              :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                              :items="districts"
                              item-text="districtthai"
                              item-value="districtid"
                              @change="loadTambons"
                              v-model="vm.selected_district_id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="3" class="my-0 py-0">
                            <h4 class="primary--text mb-2">ตำบล</h4>
                            <v-select
                              filled
                              background-color="input"
                              label="ตำบล"
                              clearable
                              :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                              :items="tambons"
                              item-text="tambonthai"
                              item-value="tambonid"
                              v-model="vm.selected_tambon_id"
                              @change="onTambonSelected"
                            ></v-select>
                          </v-col>
                          <v-col cols="3" class="my-0 py-0">
                            <h4 class="primary--text mb-2">รหัสไปรษณีย์</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="รหัสไปรษณีย์"
                              v-model="postcode"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>

                    <v-tab-item>
                      <v-form ref="contactForm">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >ผู้ติดต่อ / ผู้ประสานงาน
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-5">
                          <v-col cols="3" class="my-0 py-0">
                            <h4 class="primary--text mb-2">คำนำหน้าชื่อ</h4>
                            <v-select
                              filled
                              background-color="input"
                              label="คำนำหน้าชื่อ"
                              :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                              :items="titlename"
                              item-text="titlenameth"
                              item-value="titlenameid"
                              v-model="vm.coordinator_titlename_id"
                            ></v-select>
                          </v-col>
                          <v-col cols="4" class="my-0 py-0">
                            <h4 class="primary--text mb-2">ชื่อจริง</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="ชื่อจริง"
                              :rules="nameRules"
                              v-model="vm.coordinator_firstname"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5" class="my-0 py-0">
                            <h4 class="primary--text mb-2">นามสกุล</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="นามสกุล"
                              :rules="nameRules"
                              v-model="vm.coordinator_lastname"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="12" class="my-0 py-0">
                            <h4 class="primary--text mb-2">อีเมล</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="อีเมล"
                              :rules="custom_emailRules"
                              v-model="vm.coordinator_email"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">แฟกซ์</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="กรุณาระบุหมายเลขแฟกซ์ (ถ้ามี)"
                              v-model="vm.coordinator_fax"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">เบอร์โทร</h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="เบอร์โทร"
                              :rules="phoneRules"
                              v-model="vm.coordinator_phone"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>

                    <v-tab-item>
                      <v-form ref="accountForm">
                        <v-row>
                          <v-col cols="12">
                            <h3 class="primary--text">
                              <v-icon color="primary" class="mr-1"
                                >mdi-account-box</v-icon
                              >บัญชีผู้ใช้งาน
                            </h3>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-5">
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              ชื่อบัญชีผู้ใช้ (username)
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              autocomplete="new-password"
                              ref="txtUsername"
                              label="กรุณาระบุชื่อบัญชีผู้ใช้"
                              :rules="custom_usernameRules"
                              v-model="vm.username"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-2">
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              รหัสผ่าน (Password)
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="กรุณาระบุรหัสผ่าน"
                              autocomplete="new-password"
                              v-model="vm.password"
                              required
                              :append-icon="
                                isShowPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              @click:append="isShowPassword = !isShowPassword"
                              :type="isShowPassword ? 'text' : 'password'"
                              :rules="passwordRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <h4 class="primary--text mb-2">
                              ยืนยันรหัสผ่าน (Confirm Password)
                            </h4>
                            <v-text-field
                              background-color="input"
                              filled
                              clearable
                              label="กรุณาระบุยืนยันรหัสผ่าน"
                              autocomplete="new-password"
                              v-model="vm.confirm_password"
                              :append-icon="
                                isConfirmShowPassword
                                  ? 'mdi-eye'
                                  : 'mdi-eye-off'
                              "
                              @click:append="
                                isConfirmShowPassword = !isConfirmShowPassword
                              "
                              :type="
                                isConfirmShowPassword ? 'text' : 'password'
                              "
                              required
                              :rules="[
                                (v) =>
                                  !!v ||
                                  'กรุณายืนยันรหัสผ่าน (Confirmpassword)',
                                (v) => v === vm.password || 'รหัสผ่านไม่ตรง',
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-col>
            </v-card-text>
          </v-card>
          <v-card v-if="tab == 2" class="mt-5">
            <v-card-text class="d-flex justify-center">
              <v-col cols="10" class="mt-0">
                <v-row class="d-flex align-center justify-space-between">
                  <h3 class="primary--text">ผู้ประสานงานของสถานศึกษา</h3>
                  <v-btn
                    @click="openPopupCreateContact"
                    outlined
                    class="ma-2"
                  >
                    เพิ่มผู้ประสานงาน<v-icon class="ml-2">mdi-plus</v-icon>
                  </v-btn>
                </v-row>
                <SearchableDataTable
                  class="mt-2"
                  table_caption="ผู้ประสานงานของสถานศึกษา"
                  :data_items="vm.employerschoolcontactrequests"
                  :datatable_headers="
                    vm_employerschoolcontactrequests_data_table_headers
                  "
                >
                  <template v-slot:item.action="{ item }">
                    <v-tooltip top color="error">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="deleteContact(item)"
                          color="error"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>ลบ</span>
                    </v-tooltip>
                  </template>
                </SearchableDataTable>
              </v-col></v-card-text
            >
          </v-card>
          <v-row class="my-5 justify-end">
            <v-btn v-if="tab != 0" class="mx-3" @click="tab--">ย้อนกลับ</v-btn>
            <v-btn
              v-if="tab != 2"
              class="mx-3 color-buton"
              dark
              :disabled="emailExists"
              @click="handleNext"
              >ถัดไป<v-icon class="ml-2"> mdi-arrow-right </v-icon></v-btn
            >
            <v-btn
              v-if="tab == 2"
              class="mx-3 color-buton"
              dark
              :disabled="emailExists"
              @click="onSaveAndOpenUpdateView()"
              >บันทึกและเพิ่มข้อมูลสำคัญอื่น ๆ<v-icon class="ml-2"
                >mdi-content-save
              </v-icon></v-btn
            >
            <v-btn
              v-if="tab == 2"
              dark
              class="mx-3 color-buton"
              :disabled="emailExists"
              @click="onSave()"
              >บันทึก<v-icon class="ml-2"> mdi-content-save</v-icon></v-btn
            >
          </v-row>
        </v-col>
      </v-row>

      <!-- โค้ดเก่า -->
      <!-- <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="d-flex justify-center">
          <v-col lg="8" md="12" sm="12" xs="12">
            <v-card>
              <v-toolbar flat color="primary" dark>
                <v-toolbar-title>ข้อมูลทั่วไป</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :rules="[(v) => !!v || 'กรุณาระบุประเภทสถานประกอบการ']"
                      label="ประเภทสถานประกอบการ"
                      :items="employerprofiletypes"
                      item-value="code"
                      item-text="nameth"
                      clearable
                      v-model="vm.selected_employerprofiletypecode"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :rules="profiletypecodeRules"
                      :label="identityid_caption"
                      v-model="vm.identityid"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :rules="[(v) => !!v || 'กรุณาระบุชื่อสถานประกอบการ']"
                      label="ชื่อสถานประกอบการ"
                      v-model="vm.nameth"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      ref="txtEmail"
                      :rules="custom_emailRules"
                      label="อีเมล"
                      v-model="vm.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <div v-show="emailExists">
                    <v-icon class="error--text">mdi-alert-circle</v-icon>
                    <span class="pl-0 error--text"> อีเมลนี้มีผู้ใช้งานแล้ว </span>
                  </div>
                </v-expand-transition>
                <v-row>
                  <v-col cols="6" sm="12">
                    <v-select
                      label="ประเภทวิสาหกิจ"
                      :rules="[(v) => !!v || 'กรุณาระบุประเภทวิสาหกิจ']"
                      :items="employerMainBusinessTypes"
                      item-value="code"
                      item-text="nameth"
                      v-model="vm.selected_employermainbusinesstypecode"
                      @change="loadEmployerBusinessTypes"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" sm="12">
                    <v-select
                      label="ประเภทธุรกิจ"
                      :rules="[(v) => !!v || 'กรุณาระบุประเภทประเภทธุรกิจ']"
                      :items="employerBusinessTypes"
                      item-value="code"
                      item-text="nameth"
                      v-model="vm.selected_employerbussinesstypecode"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      :rules="[(v) => !!v || 'กรุณาระบุรายละเอียดสถานประกอบการ']"
                      label="รายละเอียดสถานประกอบการ (ลักษณะงาน)"
                      v-model="vm.description"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="สวัสดิการ"
                      :rules="[(v) => !!v || 'กรุณาระบุสวัสดิการ']"
                      v-model="vm.benefit_description"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12" md="6" lg="6">
                    <v-text-field
                      label="หมายเลขประกันสังคม"
                      :rules="security_numberRules"
                      v-model="vm.social_security_number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col sm="12" md="6" lg="6">
                    <v-text-field label="เว็บไซต์" v-model="vm.websiteurl">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12" md="6" lg="6">
                    <v-checkbox
                      label="ให้ผู้สมัครงานไปสมัครด้วยตนเอง หรือ ส่งใบสมัครทางไปรษณีย์"
                      v-model="vm.isrequiredjobapplicanttoregisterthemselforviapost"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="12" md="6" lg="6">
                    <v-checkbox
                      label="เปิดรับสมัคร online"
                      v-model="vm.isopenforonlineapplicant"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :rules="[(v) => !!v || 'กรุณาระบุที่อยู่']"
                      label="ที่อยู่"
                      v-model="vm.address_no"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      clearable
                      label="จังหวัด"
                      :rules="[(v) => !!v || 'กรุณาระบุจังหวัด']"
                      :items="provinces"
                      item-text="provincethai"
                      item-value="provinceid"
                      @change="loadDisticts"
                      v-model="vm.selected_province_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      clearable
                      label="อำเภอ"
                      :rules="[(v) => !!v || 'กรุณาระบุอำเภอ']"
                      :items="districts"
                      item-text="districtthai"
                      item-value="districtid"
                      @change="loadTambons"
                      v-model="vm.selected_district_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      clearable
                      label="ตำบล"
                      :rules="[(v) => !!v || 'กรุณาระบุตำบล']"
                      :items="tambons"
                      item-text="tambonthai"
                      item-value="tambonid"
                      v-model="vm.selected_tambon_id"
                      @change="onTambonSelected"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="รหัสไปรษณีย์"
                      readonly
                      v-model="postcode"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mt-5">
              <v-toolbar flat color="primary" dark>
                <v-toolbar-title>ผู้ติดต่อ/ผู้ประสานงาน</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      label="คำนำหน้าชื่อ"
                      :rules="[(v) => !!v || 'กรุณาระบุคำนำหน้าชื่อ']"
                      :items="titlename"
                      item-text="titlenameth"
                      item-value="titlenameid"
                      v-model="vm.coordinator_titlename_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="ชื่อ"
                      :rules="nameRules"
                      v-model="vm.coordinator_firstname"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="นามสกุล"
                      :rules="nameRules"
                      v-model="vm.coordinator_lastname"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="อีเมล"
                      :rules="custom_emailRules"
                      v-model="vm.coordinator_email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="เบอร์โทร"
                      :rules="phoneRules"
                      v-model="vm.coordinator_phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Fax" v-model="vm.coordinator_fax"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mt-5">
              <v-toolbar flat color="primary" dark>
                <v-toolbar-title>บัญชีผู้ใช้งาน</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      autocomplete="new-password"
                      ref="txtUsername"
                      label="ชื่อผู้ใช้งาน (username)"
                      v-model="vm.username"
                      :rules="custom_usernameRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="รหัสผ่าน"
                      autocomplete="new-password"
                      v-model="vm.password"
                      required
                      :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isShowPassword = !isShowPassword"
                      :type="isShowPassword ? 'text' : 'password'"
                      :rules="passwordRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="ยืนยันรหัสผ่าน"
                      autocomplete="new-password"
                      v-model="vm.confirm_password"
                      :append-icon="confirmisShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="confirmisShowPassword = !confirmisShowPassword"
                      :type="confirmisShowPassword ? 'text' : 'password'"
                      required
                      :rules="[
                        (v) => !!v || 'กรุณายืนยันรหัสผ่าน (Confirmpassword)',
                        (v) => v === vm.password || 'รหัสผ่านไม่ตรง',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row class="mt-2">
              <v-col cols="12">
                <div class="d-flex justify-start">
                  <v-btn color="primary" @click="openPopupCreateContact"
                    ><v-icon> mdi-plus </v-icon>เพิ่มผู้ประสานงาน</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <SearchableDataTable
              class="mt-2"
              table_caption="ผู้ประสานงานของสถานศึกษา"
              :data_items="vm.employerschoolcontactrequests"
              :datatable_headers="vm_employerschoolcontactrequests_data_table_headers"
            >
              <template v-slot:item.action="{ item }">
                <v-tooltip top color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="deleteContact(item)"
                      color="error"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>ลบ</span>
                </v-tooltip>
              </template>
            </SearchableDataTable>
          </v-col>
        </v-row>
        <v-row class="justify-center text-center mt-4 mb-4">
          <v-col cols="12">
            <v-btn color="primary" class="mr-2" @click="onSave()" :disabled="emailExists"
              ><v-icon> mdi-content-save </v-icon>บันทึก</v-btn
            >
            <v-btn
              color="secondary"
              @click="onSaveAndOpenUpdateView()"
              :disabled="emailExists"
              ><v-icon> mdi-content-save </v-icon>บันทึกและเพิ่มข้อมูลสำคัญอื่น</v-btn
            >
            <v-btn
              color=""
              @click="
                $router.push('/Employer/SchoolPartnerOfficer_EmployerProfileListView')
              "
              class="ma-2 justify-center"
            >
              ย้อนกลับ</v-btn
            >
          </v-col>
        </v-row>
      </v-form> -->
    </v-container>
    <v-dialog v-model="isOpenCreateContactDialog" width="700">
      <template>
        <v-card class="pa-6">
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-form ref="formCoordinator" v-model="validCoordinator" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    label="สถานศึกษา"
                    :items="schools"
                    item-text="schoolname"
                    item-value="schoolid"
                    required
                    :rules="[(v) => !!v || 'กรุณาระบุสถานศึกษา']"
                    v-model="newContact.selected_schoolid"
                    @change="onSchoolSelected"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ชื่อ-นามสกุล"
                    required
                    :rules="nameRules"
                    v-model="newContact.fullname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="เบอร์ติดต่อ"
                    required
                    :rules="[(v) => !!v || 'กรุณาระบุเบอร์ติดต่อ']"
                    v-model="newContact.phone"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="justify-center text-center">
                  <v-btn @click="createContact" class="ma-2" color="success">
                    <v-icon class="mr-2">mdi-check-circle-outline</v-icon>ยืนยัน
                  </v-btn>
                  <v-btn
                    @click="isOpenCreateContactDialog = false"
                    class="ma-2"
                    color=""
                    elevation="2"
                    >ยกเลิก</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {
  usernameRules,
  passwordRules,
  emailRules,
  profiletypecodeRules,
  security_numberRules,
  nameRules,
  phoneRules,
} from "@/ValidationHelper.js";
import { backendapiUrl } from "@/services/constants";
import SearchableDataTable from "@/components/common/SearchableDataTable.vue";
import { EmployerSchoolContactRequest } from "@/models/Employer/EmployerSchoolContactRequest";
import { SchoolDataProvider } from "@/services/SchoolDataProvider";
import { GetSchools_Reqeust } from "@/models/School/GetSchools_Request";
import { MasterDataProvider } from "@/services/MasterDataProvider";
import { CommonDataProvider } from "@/services/CommonDataProvider";
import { EmployerProfileDataProvider } from "@/services/EmployerProfileDataProvider";
import { CreateEmployerProfileRequest } from "@/models/Employer/CreateEmployerProfileRequest";
import { formatArrayBuffer } from "@microsoft/signalr/dist/esm/Utils";
import MarkerGmap from "@/components/GoogleMap/MarkerGmap.vue";
import { ApplicationUserDataProvider } from "@/services/ApplicationUserDataProvider";
const applicationUserDataProvider = new ApplicationUserDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
);
const commonDataProvider = new CommonDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const masterDataProvider = new MasterDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const schoolDataProvider = new SchoolDataProvider(process.env.VUE_APP_BACKEND_API_URL);
const employerProfileDataProvider = new EmployerProfileDataProvider(backendapiUrl);
export default {
  name: "CreateEmployerProfileView",
  components: { SearchableDataTable, MarkerGmap },
  data() {
    return {
      vm: new CreateEmployerProfileRequest(),
      vm_employerschoolcontactrequests_data_table_headers: [
        { text: "สถานศึกษา", value: "selected_schoolname" },
        { text: "ชื่อ-นามสกุล", value: "fullname" },
        { text: "เบอร์ติดต่อ", value: "phone" },
        { text: "", value: "action" , sortable: false},
      ],
      employerProfileStatuses: [],
      employerprofiletypes: [],
      employerMainBusinessTypes: [],
      employerBusinessTypes: [],
      provinces: [],
      districts: [],
      tambons: [],
      postcode: undefined,
      isOpenCreateContactDialog: false,
      schools: undefined,
      newContact: new EmployerSchoolContactRequest(),
      CreateEmployerData: new FormData(),
      previewphotoBase64: "",
      documentFiles: [],
      photoFiles: [],
      logo_photobase64: "",
      logoFiles: [],
      valid: true,
      validCoordinator: true,
      usernameRules: usernameRules,
      passwordRules: passwordRules,
      emailRules: emailRules,
      profiletypecodeRules: profiletypecodeRules,
      security_numberRules: security_numberRules,
      nameRules: nameRules,
      isShowPassword: false,
      confirmisShowPassword: false,
      emailExists: false,
      titlename: "",
      emailExistResult: false,
      phoneRules: phoneRules,
      usernameExistResult: undefined,
      tab: 0,
      tabIndicatorHeight: 5,
      identityidExistResult: false,
    };
  },
  methods: {
    OnMarkMapComplete(position) {
      this.vm.location_latitude = position.lat;
      this.vm.location_longtitude = position.lng;
    },
    async initialize() {
      await masterDataProvider.loadEmployerProfileStatuses().then((result) => {
        this.employerProfileStatuses = result.data;
      });

      await masterDataProvider.loadEmployerProfileTypes().then((result) => {
        this.employerprofiletypes = result.data;
      });

      await masterDataProvider.loadEmployerMainBusinessTypes().then((result) => {
        this.employerMainBusinessTypes = result.data;
      });

      await masterDataProvider.loadGetProvinces().then((result) => {
        this.provinces = result.data;
      });
      await masterDataProvider.getActiveTitleNames().then((result) => {
        this.titlename = result.data;
      });

      const getSchoolRequest = new GetSchools_Reqeust();
      await schoolDataProvider.loadSchools(getSchoolRequest).then((result) => {
        this.schools = result.data;
      });
    },

    async loadEmployerBusinessTypes() {
      if (this.vm.selected_employermainbusinesstypecode != "") {
        await masterDataProvider
          .loadEmployerBusinessTypes(this.vm.selected_employermainbusinesstypecode)
          .then((result) => {
            this.employerBusinessTypes = result.data;
          });
      }
    },

    async loadDisticts() {
      if (this.vm.selected_province_id != undefined) {
        await masterDataProvider
          .loadGetDistricts(this.vm.selected_province_id)
          .then((result) => {
            this.districts = result.data;
          });
      }
    },

    async loadTambons() {
      if (this.vm.selected_district_id != undefined) {
        await masterDataProvider
          .loadGetTambons(this.vm.selected_district_id)
          .then((result) => {
            this.tambons = result.data;
          });
      }
    },

    async onTambonSelected() {
      if (this.vm.selected_tambon_id != "") {
        const vm_selected_tambon_id = this.vm.selected_tambon_id;
        const selected_tambon = this.tambons.find(
          (x) => x.tambonid == vm_selected_tambon_id
        );
        this.postcode = selected_tambon.postcodemain;
      }
    },

    async openPopupCreateContact() {
      this.newContact = new EmployerSchoolContactRequest();
      this.isOpenCreateContactDialog = true;
    },

    async onSchoolSelected() {
      if (this.newContact.selected_schoolid != "") {
        const selectedID = this.newContact.selected_schoolid;
        const school = this.schools.find((x) => x.schoolid == selectedID);
        this.newContact.selected_schoolname = school.schoolname;
      }
    },

    async createContact() {
      if (this.$refs.formCoordinator.validate()) {
        this.vm.employerschoolcontactrequests.push(this.newContact);
        this.isOpenCreateContactDialog = false;
      }
    },

    async deleteContact(item) {
      const index = this.vm.employerschoolcontactrequests.indexOf(item);
      this.vm.employerschoolcontactrequests.splice(index, 1);
    },

    async onFileChange() {
      this.CreateEmployerData.append("filedoc", this.documentFiles);
    },
    async onlogoPhotoChange() {
      this.CreateEmployerData.append("filelogo", this.logoFiles);
      var fileToLoad = this.logoFiles;
      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        const base64 = data.split(",")[1];
        this.logo_photobase64 = base64;
      });
    },

    async onPhotoChange() {
      this.CreateEmployerData.append("filephoto", this.photoFiles);
      var fileToLoad = this.photoFiles;
      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileToLoad);
      }).then((data) => {
        const base64 = data.split(",")[1];
        this.previewphotoBase64 = base64;
      });
    },

    async onSave() {
      const isGeneralFormValid = this.$refs.generalForm.validate();
      const isContactFormValid = this.$refs.contactForm.validate();
      const isAccountFormValid = this.$refs.accountForm.validate();

      if (isGeneralFormValid && isContactFormValid && isAccountFormValid) {
        if (confirm("ยืนยัน")) {
          this.$store.dispatch("showLoading");
          const formdata = this.vm.generateFormData();
          formdata.append("filedoc", this.documentFiles);
          formdata.append("filelogo", this.logoFiles);
          formdata.append("filephoto", this.photoFiles);
          await employerProfileDataProvider
            .CreateEmployerProfilesBySchoolParterOfficerRole(formdata)
            .then((result) => {
              this.$toast.success("บันทึกข้อมูลสถานประกอบการเรียบร้อย");
              this.$router.push(
                "/Employer/SchoolPartnerOfficer_EmployerProfileListView"
              );
            })
            .catch((result) => {
              this.$toast.error("เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล");
              console.log(result);
            })
            .finally((result) => {
              this.$store.dispatch("hideLoading");
            });
        }
      }
    },
    async onSaveAndOpenUpdateView() {
      const isGeneralFormValid = this.$refs.generalForm.validate();
      const isContactFormValid = this.$refs.contactForm.validate();
      const isAccountFormValid = this.$refs.accountForm.validate();

      if (isGeneralFormValid && isContactFormValid && isAccountFormValid) {
        if (confirm("ยืนยัน")) {
          this.$store.dispatch("showLoading");
          const formdata = this.vm.generateFormData();
          formdata.append("filedoc", this.documentFiles);
          formdata.append("filelogo", this.logoFiles);
          formdata.append("filephoto", this.photoFiles);
          await employerProfileDataProvider
            .CreateEmployerProfilesBySchoolParterOfficerRole(formdata)
            .then((result) => {
              let oid = result.data;
              this.$toast.success("บันทึกข้อมูลสถานประกอบการเรียบร้อย");
              this.$router.push({
                name: "SchoolPartnerOfficer_UpdateEmployerProfileView",
                params: { oid: oid },
              });
            })
            .catch((result) => {
              this.$toast.error("เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล");
              console.log(result);
            })
            .finally((result) => {
              this.$store.dispatch("hideLoading");
            });
        }
      }
    },
    async checkEmail() {
      await employerProfileDataProvider
        .isEmailAddressUsed(this.vm.employer_email)
        .then((result) => {
          this.emailExists = result.data;
        });
    },
    handleNext() {
      let isValid = false;

      if (this.tab === 0) {
        isValid = this.$refs.generalForm.validate();
      } else if (this.tab === 1) {
        isValid = this.$refs.contactForm.validate();
      } else if (this.tab === 2) {
        isValid = this.$refs.accountForm.validate();
      }

      if (isValid) {
        this.tab++;
      }
    },
  },
  watch: {
    "vm.username": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isUsernameUsed(newVal)
            .then((result) => {
              this.usernameExistResult = result.data;
              this.$refs.txtUsername.validate();
            });
        }
      },
      deep: true,
    },
    "vm.email": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider.isEmailUsed(newVal).then((result) => {
            this.emailExistResult = result.data;
            this.$refs.txtEmail.validate();
          });
        }
      },
      deep: true,
    },
    "vm.identityid": {
      async handler(newVal, oldVal) {
        if (newVal != "") {
          await applicationUserDataProvider
            .isIdentityIDUsed(newVal)
            .then((result) => {
              console.log(result.data);
              this.identityidExistResult = result.data;
              this.$refs.txtIdentityid.validate();
            });
        }
      },
      deep: true,
    },
    async tab(newTab, oldTab) {
      let isValid = false;
      if (newTab === 0) {
        console.log(newTab);
      } else if (newTab === 1) {
        if (oldTab === 0) {
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        }
      } else {
        if (oldTab === 0) {
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน");
            this.tab = oldTab;
          }
        } else if (oldTab === 1) {
          isValid = await this.$refs.contactForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error(
              "กรุณากรอกข้อมูลผู้ติดต่อ/ผู้ประสานงานให้ครบถ้วน"
            );
            this.tab = oldTab;
          }
        } else if (oldTab === 2) {
          isValid = await this.$refs.accountForm.validate();
          isValid = await this.$refs.generalForm.validate();
          if (isValid) {
            this.tab = newTab;
          } else {
            this.$toast.error("กรุณากรอกข้อมูลบัญชีผู้ใช้งานให้ครบถ้วน");
            this.tab = oldTab;
          }
        }
      }
    },
  },
  computed: {
    identityid_caption() {
      switch (this.vm.selected_employerprofiletypecode) {
        case "Organization":
          return "ทะเบียนการค้าเลขที่/ทะเบียน ภพ.20";
        case "Personal":
          return "เลขบัตรประชาชน";
        case "Government":
          return "เลขที่ผู้เสียภาษี";
        default:
          return "";
      }
    },
    custom_usernameRules() {
      return [
        ...usernameRules,
        (v) =>
          this.usernameExistResult === false ||
          "ชื่อผู้ใช้งาน (username) ถูกใช้งานแล้ว",
      ];
    },
    custom_emailRules() {
      return [
        ...emailRules,
        (v) => this.emailExistResult === false || "อีเมลนี้ถูกใช้งานแล้ว",
      ];
    },
    custom_profiletypecodeRules() {
      return [
        ...profiletypecodeRules,
        (v) =>
          this.identityidExistResult !== true || "หมายเลขที่กรอกไม่ถูกต้อง",
      ];
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style>
.v-toolbar__title {
  font-size: 16px !important;
}
.v-tabs-slider {
  border-radius: 10px;
  bottom: -40px;
}

.color-HeaderCard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}

.color-buton {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 35%,
    rgba(33, 150, 243, 1) 100%
  );
}
</style>
