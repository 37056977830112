<template>
  <v-form class="mx-auto" min-width="450">
    <v-img
      src="/img/import-data-image.jpg"
      height="200px"
      width="100%"
      :aspect-ratio="16 / 9"
    >
    </v-img>
    <!--<v-divider class="mt-6 mx-4"></v-divider>-->
    <div class="pa-10">
      <div class="justify-center text-center">
        <div class="mb-3">
          <h3>นำเข้าข้อมูลสถานะศึกษา</h3>
        </div>
        <v-text-field
          v-if="(school != undefined)"
          label="สถานศึกษา"
          v-model="school.schoolname"
          readonly
        >
        </v-text-field>
      </div>
      <div class="justify-center text-center">
        <v-btn
          @click="importStudents(schoolID)"
          :loading="isImporting"
          dark
          color="teal"
        >
          <v-icon>mdi-import</v-icon> นำเข้าข้อมูล
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import { backendapiUrl } from "@/services/constants"
import { SchoolDataProvider } from "@/services/SchoolDataProvider"
import { StudentDataProvider } from "@/services/StudentDataProvider"
import store from "@/store"
var schoolDataProvider = new SchoolDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
)
var studentDataProvider = new StudentDataProvider(
  process.env.VUE_APP_BACKEND_API_URL
)
export default {
  name: "ImportStudents",
  props: ["schoolID"],
  data() {
    return {
      isImporting: false,
      school: undefined
    }
  },
  methods: {
    async loadSchool(schoolID = "") {
      await schoolDataProvider
        .loadSchool(schoolID)
        .then((result) => {
          this.school = result.data
        })
        .catch((err) => {
          alert(err)
        })
    },

    // async importStudents(schoolID = '') {
    //     this.isImporting = true;
    //     await studentDataProvider.importStudents(schoolID).then(result => {
    //         this.onImportStudentsCompleted();
    //     }).catch(err => {
    //         this.isImporting = false;
    //         alert(err);
    //     });
    // },

    async importStudents(schoolID = "") {
      store.dispatch({
        type: "doAddedMessage",
        message: {
          id: Math.random(),
          title: this.school.schoolname,
          status: "ส่งคำร้อง",
          icon: "mdi-help-box"
        }
      })

      this.isImporting = true
      await studentDataProvider
        .importStudentsBackgroundservice(schoolID)
        .then((result) => {

          store.dispatch({
            type: "doAddedMessage",
            message: {
              id: Math.random(),
              title: this.school.schoolname,
              status: "รอดำเนินการ..",
              icon: "mdi-help-box"
            }
          })

          let item = []
          item.jobid = result.data
          item.schoolname = this.school.schoolname
          store.dispatch({
            type: "doImportContinue",
            item
          })

          this.onImportStudentsCompleted()
        })
        .catch((err) => {
          this.isImporting = false
          alert(err)
        })
    },

    onImportStudentsCompleted() {
      this.isImporting = false
      this.$emit("emit_onImportStudentsCompleted", "")
    }
  },
  async created() {
    if (this.schoolID != undefined) {
      await this.loadSchool(this.schoolID)
    }
  }
}
</script>
